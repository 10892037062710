import { HttpService } from '@services/HttpService'
import { storage } from '@services/storageService'
import { config } from '@config'
import { RestServiceEndpoints } from '@constants'
import { IGetTopBarForecastKpi } from '../../constants/forecastTopBarKpiInterface'

export class ForecastGlobalTopBarDataStore {
  private httpService = new HttpService()

  getForecastSummary = async (productForecast: IGetTopBarForecastKpi) => {
    const { buyingSessionGroupId, ...restParams } = productForecast
    const accessToken = storage.getItem('access_token')
    const response = await this.httpService.get(
      `${config.buyingBoardServiceRestEndpoint}${RestServiceEndpoints.FORECAST}/${RestServiceEndpoints.GET_FORECAST_SUMMARY}/buyingSessionGroupId/${buyingSessionGroupId}`,
      {
        params: restParams,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
    )
    return response?.data
  }
}
