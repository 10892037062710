import GET_CLIENTS_DISCOUNTS from './clientDiscount.graphql'
import GET_SPEC_ITEMS_BY_SUBTYPE from './findSpecItemDefinitions.graphql'
import UPSERT_DISCOUNT from './upsertDiscount.graphql'
import DELETE_DISCOUNT from './deleteDiscount.graphql'

export * from './hooks'

export {
  GET_CLIENTS_DISCOUNTS,
  GET_SPEC_ITEMS_BY_SUBTYPE,
  UPSERT_DISCOUNT,
  DELETE_DISCOUNT
}
