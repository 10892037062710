import { config } from '@config'
import { HttpService } from '@services/HttpService'
import { storage } from '@services/storageService'
import axios from 'axios'
import sortObjectValues from '@utils/sorting-object-values'
import { PRODUCT_PRESENTATION_SERVICE_ENDPOINTS } from '../constants/apiConstants'
import sortArrayofObjectUsingProperty from '@utils/string-sort'

export class ProductPresentationService {
  httpService = new HttpService()
  getHierarchy = (bsgId, clientId, filterData = [], searchKey = '') => {
    const url = `${config.buyingBoardServiceRestEndpoint}${PRODUCT_PRESENTATION_SERVICE_ENDPOINTS.GET_COLLECTION_PRODUCTS_HIERARCHY}/buyingSessionGroupId/${bsgId}/clientId/${clientId}`
    const accessToken: string = storage.getItem('access_token')
    const body = {
      filter: filterData
    }
    return this.httpService
      .post(url, body, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params: {
          ...(searchKey ? { searchKey } : {})
        }
      })
      .then(response => response.data?.data?.productHierarchy?.categories)
  }

  fetchProductsByHierarchy = (
    bsgId: string,
    clientId: string,
    filterData = {},
    searchKey = ''
  ) => {
    const url = `${config.buyingBoardServiceRestEndpoint}${PRODUCT_PRESENTATION_SERVICE_ENDPOINTS.GET_PRODUCTS}/buyingSessionGroupId/${bsgId}/clientId/${clientId}`
    const accessToken: string = storage.getItem('access_token')
    const body = {
      filter: filterData
    }
    return this.httpService
      .post(url, body, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params: {
          ...(searchKey ? { searchKey } : {})
        }
      })
      .then(response => response.data?.data?.products)
  }

  fetchFilterAttributes = (bsgId: string, clientId: string, filterData = {}) => {
    const url = `${config.buyingBoardServiceRestEndpoint}${PRODUCT_PRESENTATION_SERVICE_ENDPOINTS.GET_FILTER_ATTRIBUTES}/buyingSessionGroup/${bsgId}/client/${clientId}`
    const accessToken: string = storage.getItem('access_token')
    const body = {
      filter: filterData
    }
    return this.httpService
      .post(url, body, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        const sortingKeys = ['wholesaleFreeTag', 'retailFreeTag']

        sortObjectValues(
          sortingKeys,
          response.data?.data?.filterAttributes,
          'label',
          sortArrayofObjectUsingProperty
        )
        return response.data?.data?.filterAttributes
      })
  }

  toggleProductFavoriteStatus = async (productId: string) => {
    try {
      const url = `${config.buyingBoardServiceRestEndpoint}${PRODUCT_PRESENTATION_SERVICE_ENDPOINTS.COLLECTION}/buyingSessionProduct/${productId}`
      const accessToken: string = storage.getItem('access_token')
      const response: any = await axios.patch(
        url,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        }
      )
      return response
    } catch (e) {
      console.log(e)
      throw e
    }
  }

  createOrderFromWishList = async (
    bsgId: string,
    clientId: string,
    doors: string[],
    orderName: string,
    uncheckedProducts: string[],
    checkedProducts: string[],
    filterData = {},
    searchKey = ''
  ) => {
    try {
      const url = `${config.buyingBoardServiceRestEndpoint}${PRODUCT_PRESENTATION_SERVICE_ENDPOINTS.ORDER}/buyingSessionGroup/${bsgId}/client/${clientId}`
      const accessToken: string = storage.getItem('access_token')
      const body = {
        doors,
        orderName,
        isWishlist: true,
        uncheckedProducts,
        checkedProducts,
        filter: filterData
      }
      const response: any = await axios.post(url, body, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params: {
          ...(searchKey ? { searchKey } : {})
        }
      })
      return response.data?.data?.clientOrderId
    } catch (e) {
      console.log(e)
      throw e
    }
  }

  clearWishList = async (bsgId: string) => {
    try {
      const url = `${config.buyingBoardServiceRestEndpoint}${PRODUCT_PRESENTATION_SERVICE_ENDPOINTS.WISHLIST}/clearWishlist`
      const accessToken: string = storage.getItem('access_token')
      const body = {
        buyingSessionGroup: bsgId
      }
      const response = await axios.post(url, body, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      return response
    } catch (e) {
      console.log(e)
      throw e
    }
  }

  getProductPrices = async (buyingSessionGroupId, clientId) => {
    try {
      const url = `${config.buyingBoardServiceRestEndpoint}${PRODUCT_PRESENTATION_SERVICE_ENDPOINTS.PRICE}/buyingSessionGroup/${buyingSessionGroupId}/client/${clientId}`
      const accessToken: string = storage.getItem('access_token')

      const response: any = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      return response?.data?.data
    } catch (e) {
      console.log(e)
    }
  }
}
