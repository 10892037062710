import { config } from '@config'
import { storage } from '@services/storageService'
import axios from 'axios'

export class OrdersListService {
  deleteOrder = async (orderId, orderType) => {
    try {
      const url = `${config.buyingBoardServiceRestEndpoint}order/${orderId}?orderType=${orderType}`
      const accessToken: string = storage.getItem('access_token')

      const response = await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      return response
    } catch (e) {
      console.log(e)
      // throw e
    }
  }
  createOrder = async (
    buyingSessionGroupId: string,
    clientId: string,
    orderName: string,
    doorIds: Array<string>
  ) => {
    try {
      const url = `${config.buyingBoardServiceRestEndpoint}order/buyingSessionGroup/${buyingSessionGroupId}/client/${clientId}`
      const accessToken: string = storage.getItem('access_token')

      const body = {
        orderName,
        doors: doorIds
      }
      const response = await axios.post(url, body, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      return response
    } catch (e) {
      console.log(e)
    }
  }

  updateOrderStatus = async (orderIds, status, clientId?) => {
    try {
      const url = `${config.buyingBoardServiceRestEndpoint}order/updateStatus`
      const accessToken: string = storage.getItem('access_token')

      const body = {
        status: status,
        orderIds,
        clientId
      }
      const response = await axios.patch(url, body, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      return response
    } catch (e) {
      throw e
    }
  }

  getEmptyClientOrders = async clientOrderIds => {
    try {
      const url = `${config.buyingBoardServiceRestEndpoint}order/getOrderInfoForConfirmStatus/clientOrder`
      const accessToken: string = storage.getItem('access_token')

      const body = {
        clientOrderIds
      }
      const response = await axios.post(url, body, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      return response
    } catch (e) {
      console.log(e)
    }
  }

  getEmptyDoorOrders = async doorOrderIds => {
    try {
      const url = `${config.buyingBoardServiceRestEndpoint}order/getOrderInfoForConfirmStatus/doorOrder`
      const accessToken: string = storage.getItem('access_token')

      const body = {
        doorOrderIds
      }
      const response = await axios.post(url, body, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      return response
    } catch (e) {
      console.log(e)
    }
  }

  getMyOrders = async (buyingSessionGroupId: string, clientId: string) => {
    try {
      const url = `${config.buyingBoardServiceRestEndpoint}order/myOrders/buyingSessionGroup/${buyingSessionGroupId}/client/${clientId}`
      const accessToken: string = storage.getItem('access_token')

      return await axios.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
    } catch (e) {
      console.log(e)
    }
  }
}

export const ordersListService = new OrdersListService()
