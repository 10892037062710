import React from 'react'
import classNames from 'classnames'
import { SvgIcon } from '@material-ui/core'
const icons = {
  store: (
    <path
      id='a'
      d='M20 4H4v2h16V4zm1 10v-2l-1-5H4l-1 5v2h1v6h10v-6h4v6h2v-6h1zm-9 4H6v-4h6v4z'
    />
  ),
  collection: (
    <path
      d='M10.2040919,5 C9.67191131,5.14117433 7.49940413,5.98170087 7.15371214,6.28932248 C7.05656424,6.37584609 5.56819722,9.93772118 5.00124625,10.7223092 C4.93460295,10.829196 7.56089626,11.8174969 8.04872843,12.0101024 L8.04872843,18.3723644 L15.9512716,18.3723644 L15.9512716,12.0101024 C16.4391037,11.8174969 19.0653971,10.829196 18.9987538,10.7223092 C18.4318028,9.93772118 16.9434358,6.37584609 16.8462879,6.28932248 C16.5005959,5.98170087 14.3280887,5.14117433 13.7959081,5 C13.4057873,6.48015734 10.5939713,6.47927198 10.2040919,5 Z'
      id='Path'
      stroke='#000000'
    />
  ),
  moreHoriz: (
    <path
      id='a'
      d='M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z'
    />
  ),
  cardView: (
    <path
      id='a'
      d='M1 0h14a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1zm5 6l-4 4h12l-3-3.2-2.03 2.168L6 6zm8-2.5c0-.83-.67-1.5-1.5-1.5S11 2.67 11 3.5 11.67 5 12.5 5 14 4.33 14 3.5zM0 14h16v2H0v-2zm0 4h13.714v2H0v-2z'
    />
  ),
  clear: (
    <path d='M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z' />
  ),
  flag: <path fill='red' d='M7,2H9V22H7V2M19,9L11,14.6V3.4L19,9Z' />,
  imageUpload: (
    <path
      fill='#000000'
      d='M14,2L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2H14M18,20V9H13V4H6V20H18M17,13V19H7L12,14L14,16M10,10.5A1.5,1.5 0 0,1 8.5,12A1.5,1.5 0 0,1 7,10.5A1.5,1.5 0 0,1 8.5,9A1.5,1.5 0 0,1 10,10.5Z'
    />
  ),
  tags: (
    <path d='M21.41 11.58l-9-9C12.05 2.22 11.55 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58.55 0 1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41 0-.55-.23-1.06-.59-1.42zM5.5 7C4.67 7 4 6.33 4 5.5S4.67 4 5.5 4 7 4.67 7 5.5 6.33 7 5.5 7z' />
  ),
  unfreeze: (
    <path
      fill='#827d7d'
      d='M10,16.5V7.5L16,12M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z'
    />
  ),
  freeze: (
    <path
      fill='#827d7d'
      d='M15,16H13V8H15M11,16H9V8H11M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z'
    />
  ),
  iOSArrow: <path d='M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z' />
}
const Icons = props => {
  const { iconname, className } = props
  return (
    <SvgIcon {...props} className={classNames(['icon', className])}>
      {icons[iconname]}
    </SvgIcon>
  )
}

export { Icons }
