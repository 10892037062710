import { Button, ButtonGroup } from '@blueprintjs/core'
import { StyledProps } from '@components/3rd-party/mui'
import Tooltip from '@material-ui/core/Tooltip'
import { useObserver } from 'mobx-react-lite'
import React from 'react'
import styled from 'styled-components'
import { useAssortmentSplitStore } from '.'

//region Styled Components
const Root = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 4px;
`

const RightButtons = styled(ButtonGroup).attrs(() => ({ minimal: true }))`
  margin-left: auto;
`

const TooltipButton = styled(({ disabled, title, onClick, ...p }) => (
  <Tooltip title={title}>
    <Button aria-disabled={disabled} {...p} onClick={disabled ? undefined : onClick} />
  </Tooltip>
))`
  .bp3-icon > svg {
    width: 24px;
    height: 24px;
  }
`

const ScrollLeftButton = styled(TooltipButton).attrs(p => ({
  title: 'Scroll Left',
  icon: 'chevron-left',
  ...p
}))``
const ScrollRightButton = styled(TooltipButton).attrs(p => ({
  title: 'Scroll Right',
  icon: 'chevron-right',
  ...p
}))``

//endregion

//region <AssortmentSplit_Toolbar />
interface MyProps extends StyledProps {}

export const AssortmentSplit_Toolbar: React.FunctionComponent<MyProps> = ({
  className
}) => {
  return useObserver(function useHook() {
    const { canScrollLeft, canScrollRight, scrollLeft, scrollRight } =
      useAssortmentSplitStore()

    return (
      <Root className={className}>
        <RightButtons>
          <ScrollLeftButton disabled={!canScrollLeft} onClick={scrollLeft} />
          <ScrollRightButton disabled={!canScrollRight} onClick={scrollRight} />
        </RightButtons>
      </Root>
    )
  })
}
//endregion
