import { config } from '@config'
import { OrderDataKeys, OrderType } from '@modules/common/models/enums'
import { VIPClientType } from '@modules/common/models/enums/VIPClientType'
import { stores } from '@stores'
import cloneDeep from 'lodash/cloneDeep'
import forEach from 'lodash/forEach'
import includes from 'lodash/includes'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'
import memoize from 'memoize-one'
import { action, computed, observable, reaction } from 'mobx'
import { computedFn } from 'mobx-utils'
import qs from 'query-string'

import { SAOrdersTableService } from '../common/SAOrdersService'
import { ordersDataStore } from './ordersDataStore'

const CLIENT_ORDER_ROW_HEIGHT = 80
export const DOOR_ORDER_ROW_HEIGHT = 70

export class OrdersUIStore {
  @observable expandMap = {}
  @observable buyingGroupDeliveryWindow = null
  @observable disableControls = null
  @observable ordersTableData = []
  @observable macroOrdersData = null
  @observable clientOrdersLoading = null
  @observable rowHeights = {}
  @observable listRef = null
  @observable orderIdIndexes = {}
  @observable showSearchInput = false
  @observable searchTerm = ''
  @observable shortListedOrders = []
  @observable isBSGClosed = false

  @action
  initOrderStore = async () => {
    let {
      nav: {
        buyingSessionGroupId,
        queryParams: { clientId, clientType }
      }
    } = stores
    this.clientOrdersLoading = true
    const { data: macroOrdersData } = await ordersDataStore.getMyOrders(
      buyingSessionGroupId,
      clientId
    )

    this.clientOrdersLoading = false
    const buyingSessionGroup = macroOrdersData?.buyingSessionGroup

    this.buyingGroupDeliveryWindow = {
      Start: buyingSessionGroup?.defaultDeliveryWindow?.startDate,
      End: buyingSessionGroup?.defaultDeliveryWindow?.endDate
    }

    const buyingSessionStatusValues =
      config?.appConfig?.enumerations?.buyingSessionStatus?.BUYING_SESSION_STATUS_VALUES
    const retailBuyingSessionStatusValues =
      config?.appConfig?.enumerations?.retailBuyingSessionStatus
        .BUYING_SESSION_STATUS_VALUES

    this.isBSGClosed = buyingSessionGroup?.status === buyingSessionStatusValues?.CLOSED

    this.disableControls =
      !this.clientOrdersLoading &&
      (!includes(
        VIPClientType[clientType] === VIPClientType.Internal
          ? [
              retailBuyingSessionStatusValues.BUYING,
              retailBuyingSessionStatusValues.CLOSED
            ]
          : [buyingSessionStatusValues?.CLOSED, buyingSessionStatusValues?.BUYING],
        VIPClientType[clientType] === VIPClientType.Internal
          ? buyingSessionGroup.retailStatus
          : buyingSessionGroup?.status
      ) ||
        buyingSessionGroup?.externalClients?.find(({ id }) => id === clientId))

    this.setOrderData(macroOrdersData?.macroOrders)
  }

  @action
  setOrderData = macroOrdersData => {
    this.macroOrdersData = macroOrdersData
    this.ordersTableData = SAOrdersTableService.buildSAOrdersTableData(
      macroOrdersData,
      OrderType.Client
    )
  }

  @action refetchOrderData = async () => {
    let {
      nav: {
        buyingSessionGroupId,
        queryParams: { clientId }
      }
    } = stores
    this.clientOrdersLoading = true
    const { data: macroOrdersData } = await ordersDataStore.getMyOrders(
      buyingSessionGroupId,
      clientId
    )
    this.clientOrdersLoading = false
    this.setOrderData(macroOrdersData?.macroOrders)
  }
  /**
   * If any order status is other than inprocess or booking export is not allowed
   */
  canExportOrderData = memoize(
    orders => {
      const orderStatusEditableValues =
        config?.appConfig?.enumerations?.orderStatus?.ALLOWED_EDIT_FOR_VALUES
      let canExportOrders = false
      forEach(orders, order => {
        if (order?.client?.master) {
          if (includes(orderStatusEditableValues, order?.statusWithName?.value))
            canExportOrders = true
          return
        } else if (order?.doorOrders?.length) {
          forEach(order?.doorOrders, doorOrder => {
            if (includes(orderStatusEditableValues, doorOrder?.statusWithName?.value)) {
              canExportOrders = true
              return
            }
          })
        }
      })
      return canExportOrders
    },
    (oldParams, newParams) => isEqual(oldParams[0], newParams[0])
  )

  @action onActionClick = clientOrderId => (event?) => {
    const {
      nav: {
        history,
        queryParams: { clientId, buyingSessionGroupId, clientType }
      }
    } = stores
    history.push({
      pathname: `/showroom/clients/buyingSessionGroup/activities`,
      search: qs.stringify({
        clientId,
        buyingSessionGroupId,
        orderId: clientOrderId,
        clientType: clientType
      })
    })
  }

  getOrderRow = computedFn(index => {
    return this.shortListedOrders[index] ?? undefined
  })

  @action toggleRow = (orderId, index) => {
    this.expandMap[orderId] = !this.expandMap[orderId]
    this.setRowHeight(index)
  }

  @computed get hasDeliveryWindowPassed() {
    let hasDelivWindowPassed = false
    if (this.buyingGroupDeliveryWindow && this.buyingGroupDeliveryWindow.End) {
      const deliveryWindowCancelDate = new Date(
        this.buyingGroupDeliveryWindow.End
      ).setHours(0, 0, 0, 0)
      const currentDate = new Date().setHours(0, 0, 0, 0)
      hasDelivWindowPassed =
        new Date(currentDate).getTime() > new Date(deliveryWindowCancelDate).getTime()
    }
    return hasDelivWindowPassed
  }

  getItemSize = computedFn(index => {
    return this.rowHeights[index] || CLIENT_ORDER_ROW_HEIGHT
  })

  @action
  setRowHeight = (index, doorLength?) => {
    const order = this.shortListedOrders[index]
    const isOpen = this.expandMap[order?.id]
    this.rowHeights[index] = isOpen
      ? DOOR_ORDER_ROW_HEIGHT * (doorLength ?? order?.doorOrders?.length) +
        CLIENT_ORDER_ROW_HEIGHT
      : CLIENT_ORDER_ROW_HEIGHT
    this.listRef?.current?.resetAfterIndex(0)
  }

  @action
  setRef = ref => {
    this.listRef = ref
  }

  getDoorOrderRow = computedFn((orderIndex, doorIndex) => {
    const clientOrder = this.shortListedOrders[orderIndex]
    return clientOrder?.doorOrders[doorIndex]
  })

  @action
  updateClientOrder = (id, value, field) => {
    const orders = cloneDeep(this.ordersTableData)
    const oldOrder = orders.find(ele => ele.id === id)
    oldOrder[field].value = value
    this.ordersTableData = orders
  }

  @action
  updateDoorOrder = (id, value, field) => {
    const orders = cloneDeep(this.ordersTableData)
    orders.forEach(order => {
      const doorOrder = order?.doorOrders?.find(ele => ele.id === id)
      if (doorOrder) {
        doorOrder[field].value = value
      }
    })
    this.ordersTableData = orders
  }

  @action
  deleteDoorOrder = async id => {
    let updatedOrder = null
    this.ordersTableData.forEach(order => {
      const doorOrderIndex = order?.doorOrders?.findIndex(ele => ele.id === id)
      if (doorOrderIndex > -1) {
        updatedOrder = order
      }
    })
    await this.refetchOrderData()
    const orderIndex = this.ordersTableData.findIndex(ele => ele.id === updatedOrder?.id)
    if (orderIndex > -1) {
      this.setRowHeight(orderIndex, updatedOrder?.doorOrders?.length - 1)
    } else {
      this.resetRowHeight()
    }
  }

  @action
  deleteClientOrder = id => {
    const orders = cloneDeep(this.ordersTableData)
    const oldOrderIndex = orders.findIndex(ele => ele.id === id)
    orders.splice(oldOrderIndex, 1)
    this.ordersTableData = orders
  }

  @action
  applyToAll = (id, field) => {
    const orders = cloneDeep(this.ordersTableData)
    const oldOrder = orders.find(ele => ele.id === id)
    const valueToUpdate = oldOrder[field].value
    oldOrder?.doorOrders?.forEach(order => {
      order[field].value = valueToUpdate
    })
    this.ordersTableData = orders
  }

  @action
  updateClientOrderStatus = async (id, statusValueToBeUpdated) => {
    const orderStatusConfig = config?.appConfig?.enumerations?.orderStatus
    const getStatusName = statusValue =>
      (Object.keys(orderStatusConfig?.ORDER_STATUS_VALUES) || []).find(
        key => statusValue === orderStatusConfig.ORDER_STATUS_VALUES?.[key]
      )

    const macroOrders = cloneDeep(this.macroOrdersData)
    const indexToBeUpdated = (macroOrders || []).findIndex(
      macroOrder => id === macroOrder?.id
    )
    if (indexToBeUpdated !== -1) {
      macroOrders[indexToBeUpdated].statusWithName = {
        name: getStatusName(statusValueToBeUpdated),
        value: statusValueToBeUpdated,
        possibleUpdateOptions: (
          orderStatusConfig?.POSSIBLE_UPDATE_OPTIONS_MAP_FOR_SA?.[
            statusValueToBeUpdated
          ] || []
        ).map(possibleUpdateOption => ({
          value: possibleUpdateOption,
          name: getStatusName(possibleUpdateOption)
        }))
      }
      this.setOrderData(macroOrders)
    }
  }

  @action
  updateDoorOrderStatus = async (id, statusValueToBeUpdated) => {
    // if door is master, update client order status as well
    // otherwise just update the door order status

    const orderStatusConfig = config?.appConfig?.enumerations?.orderStatus
    const getStatusName = statusValue =>
      (Object.keys(orderStatusConfig?.ORDER_STATUS_VALUES) || []).find(
        key => statusValue === orderStatusConfig.ORDER_STATUS_VALUES?.[key]
      )

    const macroOrders = cloneDeep(this.macroOrdersData)
    if (macroOrders?.length) {
      for (let i = 0; i < macroOrders.length; i++) {
        const indexToBeUpdated = (macroOrders[i].doorOrders || []).findIndex(
          doorOrder => id === doorOrder?.id
        )
        if (indexToBeUpdated !== -1) {
          macroOrders[i].doorOrders[indexToBeUpdated].statusWithName = {
            name: getStatusName(statusValueToBeUpdated),
            value: statusValueToBeUpdated,
            possibleUpdateOptions: (
              orderStatusConfig?.POSSIBLE_UPDATE_OPTIONS_MAP_FOR_SA?.[
                statusValueToBeUpdated
              ] || []
            ).map(possibleUpdateOption => ({
              value: possibleUpdateOption,
              name: getStatusName(possibleUpdateOption)
            }))
          }
          this.setOrderData(macroOrders)
          break
        }
      }
    }
  }

  @action
  resetOrderData = () => {
    this.buyingGroupDeliveryWindow = null
    // this.uniqActivities = null
    this.disableControls = null
    this.ordersTableData = []
    this.macroOrdersData = null
    this.clientOrdersLoading = null
    this.resetSearch()
    this.shortListedOrders = []
    this.rowHeights = {}
    this.expandMap = {}
  }

  @action
  resetSearch = () => {
    this.showSearchInput = false
    this.searchTerm = ''
  }

  @action
  toggleShowSearchInput = () => {
    this.showSearchInput = !this.showSearchInput
    this.searchTerm = ''
    if (!this.showSearchInput) {
      this.shortListedOrders = cloneDeep(this.ordersTableData)
      this.resetRowHeight()
    }
  }

  @action
  resetRowHeight = () => {
    this.expandMap = {}
    this.rowHeights = {}
    this.listRef?.current?.resetAfterIndex(0)
  }

  checkDoorOrdersForSearch = (doorOrders, searchKey) => {
    return doorOrders.filter(doorOrder => {
      return (
        doorOrder?.[OrderDataKeys.Name]?.toLowerCase().includes(searchKey) ||
        doorOrder?.[OrderDataKeys.OrderNumber]?.toLowerCase().includes(searchKey)
      )
    })
  }

  @action
  searchData = searchTerm => {
    this.searchTerm = searchTerm
    const searchKey = this.searchTerm.toLowerCase()
    const isClientMaster = this.ordersTableData[0]?.[OrderDataKeys.IsClientMaster]
    let filteredData = []
    if (isClientMaster) {
      filteredData = this.ordersTableData.filter(order => {
        return (
          order?.[OrderDataKeys.Name]?.toLowerCase().includes(searchKey) ||
          order?.[OrderDataKeys.OrderNumber]?.toLowerCase().includes(searchKey)
        )
      })
    } else {
      this.ordersTableData.forEach(order => {
        const doorOrders = this.checkDoorOrdersForSearch(order.doorOrders, searchKey)
        if (doorOrders.length) {
          filteredData.push({
            doorOrders: doorOrders,
            ...omit(order, 'doorOrders')
          })
        }
        if (!filteredData.length) {
          filteredData = this.ordersTableData.filter(order => {
            return order?.[OrderDataKeys.Name]?.toLowerCase().includes(searchKey)
          })
        }
      })
    }
    if (filteredData.length) {
      this.rowHeights = {}
      filteredData.forEach((order, index) => {
        const isOpen = this.expandMap[order?.id]
        this.rowHeights[index] = isOpen
          ? DOOR_ORDER_ROW_HEIGHT * order?.doorOrders?.length + CLIENT_ORDER_ROW_HEIGHT
          : CLIENT_ORDER_ROW_HEIGHT
      })
      this.listRef?.current?.resetAfterIndex(0)
    }
    this.shortListedOrders = filteredData
  }

  updateShortlistedOrdersOnOrdersTableDataChange = reaction(
    () => {
      return this.ordersTableData
    },
    () => {
      if (this.searchTerm) {
        this.searchData(this.searchTerm)
      } else {
        this.shortListedOrders = cloneDeep(this.ordersTableData)
      }
    }
  )

  onCreateNewOrder = async orderData => {
    let {
      nav: {
        history,
        buyingSessionGroupId,
        queryParams: { clientId, clientType }
      }
    } = stores
    const {
      data: { clientOrderId }
    } = (await ordersDataStore.createOrder(
      buyingSessionGroupId,
      clientId,
      orderData?.name,
      orderData?.doors
    )) as any
    history.push({
      pathname: `/showroom/clients/buyingSessionGroup/activities/order`,
      search: qs.stringify({
        clientId,
        buyingSessionGroupId,
        orderId: clientOrderId,
        isAddProductToOrderModalOpen: true, // TODO: Remove isAddProductToOrderModalOpen along with old order code.
        isAddProductToOrderView: true,
        orderType: OrderType.Client,
        clientType: clientType
      })
    })
  }

  /**
   *
   * @param order
   * @returns weather the status of order is validated, confirmed and sent to sap
   */

  isOrderConfirmed = order => {
    const orderStatusEditableValues =
      config?.appConfig?.enumerations?.orderStatus?.VALUES_FOR_CONFIRMED_ORDER
    const isClientMaster = order?.[OrderDataKeys.IsClientMaster]
    if (isClientMaster) {
      return orderStatusEditableValues.includes(order?.statusWithName?.value)
    } else {
      return (
        order?.doorOrders?.length &&
        order?.doorOrders?.every(doorOrder => {
          return orderStatusEditableValues.includes(doorOrder?.statusWithName?.value)
        })
      )
    }
  }

  /**
   *  this is to check for the status of order.
   */
  @computed get orderStatusConfirmed() {
    return this.ordersTableData?.every(order => {
      return this.isOrderConfirmed(order)
    })
  }
}

export const ordersUIStore = new OrdersUIStore()
