import { withStyles } from '@material-ui/core/styles'
import React from 'react'

const styles = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'inherit',
    alignItems: 'center',
    paddingTop: theme.spacing(1)
  },
  item: {
    display: 'flex',
    alignItems: 'center'
  }
})

const BuyingSessionActionsContainer = ({ classes, children }) => {
  return <div className={classes.container}>{children}</div>
}

export const BuyingSessionActions = withStyles(styles)(BuyingSessionActionsContainer)
