import { constants } from '@modules/retail/buyingSession'
import isEqual from 'lodash/isEqual'
import memoize from 'memoize-one'

const getBuyingSessionName = (gender, activity, collection) => {
  const genderName = gender.description
  const activityName = activity.description
  const collectionName = collection.description
  return `${genderName} ${activityName} ${collectionName}`
}

const getActiveAndOtherSeasons = seasons => {
  const activeSeasons = []
  const otherSeasons = []
  seasons.forEach(season => {
    if (season.status === 'ACTIVE') {
      activeSeasons.push(season)
    } else {
      otherSeasons.push(season)
    }
  })

  return { activeSeasons, otherSeasons }
}

const getSelectedSeasonId = (selectedSeason, seasonId, activeSeasons, otherSeasons) => {
  let selectedSeasonId = ''
  if (selectedSeason && selectedSeason.id) {
    selectedSeasonId = selectedSeason.id
  } else if (seasonId) {
    selectedSeasonId = seasonId
  } else if (activeSeasons && activeSeasons.length) {
    selectedSeasonId = activeSeasons[0].id
  } else if (otherSeasons && otherSeasons.length) {
    selectedSeasonId = otherSeasons[0].id
  }
  return selectedSeasonId
}

const getBuyingSessionClosedStatus = memoize(buyingSession => {
  let bsIsClosedOrFrozen = buyingSession
    ? buyingSession.status === constants.CLOSED ||
      buyingSession.status === constants.FROZEN
    : false
  let bsIsClosed = buyingSession ? buyingSession.status === constants.CLOSED : false
  return { bsIsClosedOrFrozen, bsIsClosed }
}, isEqual)

export {
  getBuyingSessionName,
  getActiveAndOtherSeasons,
  getSelectedSeasonId,
  getBuyingSessionClosedStatus
}
