import { RootInfo, StandardTreeView } from '@components/UI-Components/StandardTreeView'
import { CategoryRow } from '@modules/collection/components/CategoryRow'
import HeaderRow from '@modules/collection/components/HeaderRow'
import {
  ListViewColumnDefinition,
  ListViewRowSelectionConf
} from '@components/UI-Components/StandardTableView/models/ListviewColumnDefinition'
import { OrderListView } from '@modules/common/models/enums/OrderListView'
import { ZoneCounterOffer } from '@modules/common/models/interfaces/ZoneCounterOffer'
import { WsAssortmentProductRowWrapper } from '@modules/wholesale/assortmentProductWrapper/container/WsAssortmentProductRowWrapper'
import { CollectionProductRowWrapper as BBCollectionProductRowWrapper } from '@modules/wholesale/buyingSessionGroupProductWrapper/containers/CollectionProductRowWrapper'
import { sortListUsingOrderList } from '@services/commonServices'
import { getProcessedKPI, KPITreeViewNodeTypes } from '@services/kpiTreeViewService'
import {
  getKPIColDef,
  getRowWidth,
  getStickyColumnsStyle
} from '@services/tableViewService'
import { IVIPAllotment } from '@services/wholesale/productService'
import { stores, strings } from '@stores'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import { useObserver } from 'mobx-react-lite'
import React, { memo, useEffect } from 'react'
import {
  STANDARDRIGHTSIDENAVTOP,
  STICKYROWHEIGHT,
  WHOLESALEPRODUCTROWHEIGHT
} from 'src/constant/ProductLayoutViews'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { forecastGlobalTopBarStore } from '@modules/wholesale/buyingSessionGroupProductWrapper/stores/ForecastGlobalTopBar/ForecastGlobalTopBarUIStore'
import { BuyingSessionAssortmentType, ChannelSubSection, Views } from '@modules/common'
import { isRetailChannel } from '@services/wholesale/wholesaleBuyingSessionGroupsServices'

interface ProductListContainerProps {
  hierarchyKPIs: any
  colDef: Array<ListViewColumnDefinition>
  zoneCounterOffer?: Array<ZoneCounterOffer>

  /**
   * Following props will only be set in case of assortment grid view
   */
  assortment?: {}
  buyingSession?: any
  // only for list view
  zone?: {}

  /**
   * Following props will be set in case of list view for both collection
   * and assortment
   */
  betBuyData?: Array<{}>
  rangePlans?: Array<{}>
  isCategoryDataLoading?: boolean
  isShowroomView?: boolean
  orderView?: OrderListView
  hideRightSideNav?: boolean
  fillToAvailableWidth?: boolean

  /**
   *
   * Holds configuration for
   * row selection
   */
  rowSelectionConf?: ListViewRowSelectionConf

  /**
   *  following prop is for SA KPI view
   */
  viewType?: string

  clientAllotmentItems?: Array<IVIPAllotment>
  buyingSessionGroupName: string
  channels?: any
}

const getLeafRowHeight = () => (node: any, availableWidth: number) => {
  let heightOfRow = 0
  if (node && availableWidth) {
    const heightOfProductRow = WHOLESALEPRODUCTROWHEIGHT
    heightOfRow = node.products * heightOfProductRow
  }
  return heightOfRow
}

const rootInfo: RootInfo = {
  root: {
    id: 'ROOTHEADER',
    height: 34,
    isSticky: true,
    stickTop: 0,
    zIndex: 30
  },
  renderRoot: true
}

export const ProductListContainer: React.FunctionComponent<ProductListContainerProps> =
  memo(
    ({
      assortment,
      buyingSession,
      hierarchyKPIs,
      colDef,
      zone,
      zoneCounterOffer,
      betBuyData,
      rangePlans,
      isCategoryDataLoading,
      isShowroomView,
      orderView,
      hideRightSideNav,
      rowSelectionConf,
      fillToAvailableWidth,
      viewType,
      clientAllotmentItems,
      buyingSessionGroupName,
      channels
    }) => {
      return useObserver(function useHook() {
        const {
          nav: {
            params: { view },
            queryParams: {
              assortment: wholesaleAssortmentId,
              productLayout,
              buyingSessionGroupId,
              zoneId,
              buyingSession,
              subSection,
              assortmentType,
              assortment
            }
          },
          collection: { buildColumnViewDefinitionForCounterOffer },
          product: { isBuyMetricSort, sortBy },
          listView: {
            rootSelections,
            sortCategoryData,
            sortCategoryNavData,
            setRootSelectionStatus,
            cleanupViewListener,
            listenToViewChange,
            listenToSortOrderCleanupEvents,
            cleanupSortOrderWiperOnEventListener
          },
          showroomHeader: { onCategoryClick, anchorForNav, onCategoryClose }
        } = stores

        const { initialize } = forecastGlobalTopBarStore
        const isKPIView =
          view === strings.kpi ||
          productLayout === strings.kpi ||
          viewType === strings.kpiView

        const isRetailListView =
          productLayout === Views.List &&
          isRetailChannel(channels) &&
          assortmentType !== BuyingSessionAssortmentType.cluster &&
          (subSection ? subSection === ChannelSubSection.Retail && assortment : true)

        useEffect(() => {
          if (isRetailListView) {
            initialize({
              buyingSessionGroupId,
              buyingSessionId: buyingSession,
              zoneId
            })
          }
        }, [buyingSession, buyingSessionGroupId, initialize, zoneId, isRetailListView])

        // Clean counter offer list view definition on unmount
        useEffect(
          () => {
            listenToSortOrderCleanupEvents()

            // If KPI view, set all root nodes to checked
            // status on mounting
            listenToViewChange()
            if (isKPIView) {
              const status = tableData[rootInfo.root.id].children.map(id => {
                return {
                  id,
                  visible: true
                }
              })
              setRootSelectionStatus(status)
            }
            return () => {
              cleanupViewListener()
              cleanupSortOrderWiperOnEventListener()
              buildColumnViewDefinitionForCounterOffer()
            }
          },
          // eslint-disable-next-line react-hooks/exhaustive-deps
          []
        )

        // Update column definition for counter offer tab when counter offer data in query result is updated
        // FIXME: RM - Code for building view definition should be in parent container since this component is being
        // re-used for collection and assortment view

        if (zoneCounterOffer) {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useDeepCompareEffect(() => {
            buildColumnViewDefinitionForCounterOffer(zoneCounterOffer)
          }, [zoneCounterOffer])
        }

        let { tableData, navData } = getProcessedKPI({
          kpiData: hierarchyKPIs,
          rootInfo: rootInfo,
          isKPIView
        })

        if (isBuyMetricSort) {
          tableData = sortCategoryData(
            cloneDeep(tableData),
            sortBy,
            isCategoryDataLoading
          )
          navData = sortCategoryNavData({
            key: 'ROOTHEADER',
            children: navData
          })
        }

        const listColDef = isKPIView ? getKPIColDef(colDef) : colDef
        const tableWidth = getRowWidth(listColDef, rowSelectionConf)
        const stickyStyle = getStickyColumnsStyle(listColDef)

        const treeRowRender = ({ id, style, availableWidth }) => {
          const node = tableData[id]
          if (!node) return

          const nodeType = node.type
          const canTableFitInView = tableWidth > availableWidth
          /**
           * Calculate width of empty column if
           * fillToAvailableWidth is set. This will
           * add empty column at the end of table so that
           * table spans to available width.
           */
          const emptyColWidth = fillToAvailableWidth
            ? availableWidth - tableWidth
            : undefined
          if (nodeType === KPITreeViewNodeTypes.Header) {
            return (
              <HeaderRow
                kpiNode={node}
                rowSelectionConf={rowSelectionConf}
                key={id}
                emptyColWidth={emptyColWidth}
                style={style}
                headers={listColDef as any}
                stickyStyle={stickyStyle as any}
                canTableFitInView={canTableFitInView}
              />
            )
          } else if (isKPIView || node.children) {
            return (
              <CategoryRow
                kpiNode={node}
                emptyColWidth={emptyColWidth}
                rowSelectionConf={rowSelectionConf}
                key={node.key}
                style={style}
                headers={listColDef as any}
                stickyStyle={stickyStyle as any}
                canTableFitInView={canTableFitInView}
                onClick={onCategoryClick}
              />
            )
          } else {
            if (wholesaleAssortmentId) {
              return (
                <WsAssortmentProductRowWrapper
                  node={node}
                  key={node.key}
                  style={style}
                  listHeaders={listColDef as any}
                  stickyStyle={stickyStyle}
                  canTableFitInView={canTableFitInView}
                />
              )
            } else {
              return (
                <BBCollectionProductRowWrapper
                  node={node}
                  key={node.key}
                  style={style}
                  stickyStyle={stickyStyle}
                  listHeaders={listColDef as any}
                  canTableFitInView={canTableFitInView}
                />
              )
            }
          }
        }

        /**
         * toggleRootNode
         * Toggles root items status when
         * user check them off from side nav
         * @params rootNodeId - key for root node
         */
        const toggleRootNode = rootNodeId => {
          if (isEmpty(rootSelections) || !rootNodeId) return

          const rootNodeStatus = rootSelections.map(item => {
            if (item.id === rootNodeId) {
              return {
                ...item,
                visible: !item.visible
              }
            } else {
              return {
                ...item
              }
            }
          })
          setRootSelectionStatus(rootNodeStatus)
        }

        /**
         * scrollPlugin
         * List view input uses blur event to sync it's changes to DB.
         * It has been observed that focusing and then scrolling the view
         * doesn't triggers blur and hence save. This function forces blur
         * programmatically to ensure correct updates.
         */
        const scrollPlugin = () => {
          const activeElement: any = document.activeElement
          if (
            activeElement &&
            activeElement.tagName === 'INPUT' &&
            activeElement.name === strings.listViewInputField
          ) {
            activeElement.blur()
          }
        }

        const getSortedRootNodesWithStatus = () => {
          return sortListUsingOrderList(
            rootSelections,
            ['id'],
            tableData[rootInfo.root.id].children
          )
        }

        return (
          <StandardTreeView
            navData={{
              data: navData,
              rootNodeStatus: (rootSelections && getSortedRootNodesWithStatus()) as any,
              toggleRootNode: toggleRootNode,
              viewName: buyingSessionGroupName,
              top: STANDARDRIGHTSIDENAVTOP,
              anchorForNav: anchorForNav,
              onBackDrop: onCategoryClose
            }}
            treeTable={{
              scrollPluginHandler: (!isKPIView && scrollPlugin) as any,
              fillToAvailableWidth,
              tableWidth: tableWidth,
              tableData: tableData,
              treeRowRender: treeRowRender,
              overScanCount: isKPIView ? 100 : 1,
              stickyRowHeight: 26,
              leafRowHeight: isKPIView ? () => STICKYROWHEIGHT : getLeafRowHeight(),
              rootInfo
            }}
          />
        )
      })
    }
  )
