import { config } from '@config'
import { OrderDataKeys, OrderType } from '@modules/common/models/enums'
import { SAOrdersTableConfig } from '@modules/showroom/basketAndOrders/common/SAOrdersTableConstants'
import { getUTCFromTimeStamp } from '@services/commonServices'
import isEqual from 'lodash/isEqual'
import some from 'lodash/some'
import memoize from 'memoize-one'
// to be deprecated
class SAOrdersService {
  buildSAOrdersTableData = memoize((ordersNew, orderType, isClientActive = true) => {
    const processedOrders = []
    ordersNew.forEach(order => {
      const processedOrder = {}
      SAOrdersTableConfig.Cells.forEach(Cell => {
        let data: {}

        switch (Cell.dataKey) {
          case OrderDataKeys.Status:
            data = this.getOrderStatusByDataValue(order[Cell.dataKey], order, orderType)
            break

          case OrderDataKeys.DeliveryDate:
            data = {
              value: order[Cell.dataKey]
                ? getUTCFromTimeStamp(order[Cell.dataKey], 'DD-MMM-YYYY')
                : '',
              isEditable: true
            }
            break

          case OrderDataKeys.PORef:
          case OrderDataKeys.Notes:
            data = {
              value: order[Cell.dataKey],
              isEditable: this.isNotesAndPORefEditable(order)
            }
            break
          case OrderDataKeys.CreatedDateOfOrder:
            data = {
              value: order[Cell.dataKey]
                ? getUTCFromTimeStamp(order[Cell.dataKey], 'DD-MMM-YYYY')
                : '',
              isEditable: true
            }
            break
          case OrderDataKeys.CreatedBy:
            data = {
              value: order[Cell.dataKey],
              isEditable: true
            }
            break

          case OrderDataKeys.Name:
            data = orderType === OrderType.Door ? order?.door?.name : order[Cell.dataKey]
            break

          default:
            data = order[Cell.dataKey]
        }
        processedOrder[Cell.dataKey] = data
      })
      processedOrder[OrderDataKeys.Type] = orderType
      processedOrder[OrderDataKeys.Id] = order[OrderDataKeys.Id]
      processedOrder[OrderDataKeys.IsClientMaster] =
        order?.client?.master ?? !order?.door?.master ?? false
      processedOrder[OrderDataKeys.CanUpdateOrderStatus] = this.canUpdateOrderStatus(
        order,
        isClientActive,
        orderType
      )
      processedOrder[OrderDataKeys.buyingSessionId] = order?.buyingSession?.id || ''
      processedOrder[OrderDataKeys.HasInactiveProductOrSizeWithQty] =
        order.HasInactiveProductOrSizeWithQty
      processedOrder[OrderDataKeys.IsActivityEditable] = this.getIsActivityEditable(order)
      processedOrder[OrderDataKeys.DoorOrders] =
        orderType === OrderType.Client
          ? this.buildSAOrdersTableData(
              order?.doorOrders || [],
              OrderType.Door,
              order?.client?.active
            )
          : []

      processedOrders.push(processedOrder)
    })
    return processedOrders
  }, isEqual)

  isNotesAndPORefEditable = (order): boolean => {
    const orderStatusConfig = config?.appConfig?.enumerations?.orderStatus
    const sentToSAPStatus = orderStatusConfig.ORDER_STATUS_VALUES['Sent to SAP']
    let hasSentToSAP: boolean

    if (order?.client?.master === false) {
      hasSentToSAP = some(
        order?.doorOrders ?? [],
        doorOrder => doorOrder.statusWithName === sentToSAPStatus
      )
    } else {
      hasSentToSAP = order[OrderDataKeys.Status] === sentToSAPStatus
    }
    return !hasSentToSAP
  }

  getIsActivityEditable = order => {
    const orderStatusEditableValues =
      config?.appConfig?.enumerations?.orderStatus?.ALLOWED_EDIT_FOR_VALUES
    const isClientMaster = order?.client?.master && !order?.door?.master
    if (isClientMaster) {
      return orderStatusEditableValues.includes(order?.statusWithName?.value)
    } else {
      return (
        order?.doorOrders?.length &&
        order?.doorOrders?.every(doorOrder =>
          orderStatusEditableValues.includes(doorOrder?.statusWithName?.value)
        )
      )
    }
  }

  canUpdateOrderStatus = (order, isClientActive = true, orderType) => {
    if (orderType === OrderType.Client) {
      return order?.client?.active && !order?.hasInactiveDoor
    }
    // Check if client is active for door orders
    if (orderType === OrderType.Door) {
      return order?.door?.active && isClientActive
    }
  }

  getOrderStatusByDataValue = (orderStatusDataValue, order, orderType: OrderType) => {
    let orderStatus = {
      displayText: '',
      color: '',
      value: '',
      possibleUpdateOptions: []
    }
    const orderStatusConfig = config?.appConfig?.enumerations?.orderStatus
    if (orderType === OrderType.Client && !order?.client?.master) {
      if (
        (order.doorOrders || []).some(
          doorOrder =>
            doorOrder.statusWithName?.value !==
            order.doorOrders?.[0]?.statusWithName?.value
        )
      ) {
        return {
          displayText: orderStatusConfig.MULTIPLE_VALUE,
          value: orderStatusConfig.MULTIPLE_VALUE,
          color: orderStatusConfig.VALUE_COLOR_MAP[orderStatusConfig.MULTIPLE_VALUE]
        }
      } else {
        const value = order.doorOrders?.[0]?.statusWithName?.value || ''
        return {
          displayText: order.doorOrders?.[0]?.statusWithName?.name,
          value,
          color: orderStatusConfig.VALUE_COLOR_MAP[value]
        }
      }
    }

    if (orderStatusDataValue?.value) {
      orderStatus = {
        displayText: orderStatusDataValue.name,
        color: orderStatusConfig.VALUE_COLOR_MAP[orderStatusDataValue.value],
        value: orderStatusDataValue.value,
        possibleUpdateOptions: (orderStatusDataValue?.possibleUpdateOptions || []).map(
          ({ value, name }) => ({
            value,
            displayText: name
          })
        )
      }
    }
    return orderStatus
  }
}

export const SAOrdersTableService = new SAOrdersService()
