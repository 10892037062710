export enum ClientTableViewColumnIdentifiers {
  ClientCode = 'ClientCode',
  Active = 'Active',
  Name = 'Name',
  CustomerGroup = 'CustomerGroup',
  Master = 'Master',
  ReorderStatus = 'ReorderStatus',
  Country = 'Country',
  Zone = 'Zone',
  Doors = 'Doors',
  Open = 'Open',
  PriceGroup = 'PriceGroup',
  SalesDistrict = 'SalesDistrict',
  Channel = 'Channel',
  Actions = 'Actions'
}
