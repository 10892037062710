import InputBase from '@material-ui/core/InputBase'
import React from 'react'
import styled from 'styled-components'

const StyledBootStrapInput = styled(
  ({ index, isInputInactive = false, hasGlobalRow, ...rest }) => {
    return <InputBase {...rest} variant='outlined' />
  }
)(props => {
  return {
    width: props.width || 154,
    height: props.height || 24,
    borderRadius: 1,
    position: 'relative',
    backgroundColor: 'white',
    opacity: props.isInputInactive ? '0.5' : '1',
    border: '1px solid #ced4da',
    font: props.font ? `${props.font} !important` : 'var(--typography-button) !important',
    letterSpacing: 0.14,
    padding: '4px',
    marginTop: 5,
    fontFamily: 'Roboto',
    input: {
      textAlign: props.alignment || 'left'
    },
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
})
export default StyledBootStrapInput
