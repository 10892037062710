import { QueryResult } from '@apollo/client/react'
import { Mutation } from '@apollo/client/react/components/Mutation'
import { Query } from '@apollo/client/react/components/Query'
import { StyledProps } from '@components/3rd-party/mui'
import { GET_SEASONS } from '@modules/retail/navigations/graphql'
import { withStyles } from '@material-ui/core/styles'
import { observer } from 'mobx-react'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { CreateBuyingSession } from '../component/CreateBuyingSession'
import { CREATE_BUYING_SESSION } from '../graphql/createbuyingsession'
import { GET_COLLECTIONS_BY_SEASON_AND_ACTIVITES } from '../graphql/getCollectionsBySeason'
import { UPDATE_BUYING_SESSION } from '../graphql/updateBuyingSession'

const styles = theme => ({
  root: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    overflow: 'auto',
    height: '100vh'
  }
})

interface Props extends StyledProps {
  open?: boolean
  match?: any
  refetch?: any
  buyingSessions?: any
  selectedBuyingSession?: any
}

@((withStyles as any)(styles))
@(withRouter as any)
@observer
export class CreateSessionComponent extends React.Component<Props> {
  render() {
    const {
      props: { open, match, refetch, buyingSessions, selectedBuyingSession }
    } = this
    return (
      <Query
        query={GET_COLLECTIONS_BY_SEASON_AND_ACTIVITES}
        variables={{ seasonId: match.params.seasonId }}
      >
        {({ loading, error, data }: QueryResult) => {
          if (loading) return null
          if (error) return null
          return (
            <Mutation
              mutation={CREATE_BUYING_SESSION}
              refetchQueries={[{ query: GET_SEASONS }]}
            >
              {createBuyingSession => (
                <Mutation mutation={UPDATE_BUYING_SESSION}>
                  {updateBuyingSession => (
                    <CreateBuyingSession
                      onSubmit={createBuyingSession}
                      onUpdateBuyingSession={updateBuyingSession}
                      open={open}
                      // salesYears={data.salesYears || []}
                      collections={data ? data.collections || [] : []}
                      activities={data ? data.activities || [] : []}
                      refetch={refetch}
                      buyingSessions={buyingSessions}
                      selectedBuyingSession={selectedBuyingSession}
                    />
                  )}
                </Mutation>
              )}
            </Mutation>
          )
        }}
      </Query>
    )
  }
}
