import { centricUploadDocument, deleteDocument } from '@services/uploadFileService'
import { stores, strings } from '@stores'
import { action, computed, observable } from 'mobx'

export class BuyingSessionDocumentStore {
  file = undefined
  @observable uploadInProgress = false
  @observable selectedChannel = ''
  @observable canUserEdit = false
  @observable uploadError: string = ''

  queries: {
    documentQuery: Function
  } = { documentQuery: null }

  @computed get whereInputForDocuments() {
    const {
      nav: {
        queryParams: { buyingSessionGroupId }
      },
      buyingSessionDocumentStore: { selectedChannel }
    } = stores
    return {
      bsgId: buyingSessionGroupId,
      channel: selectedChannel
    }
  }

  @action
  handleChanges = file => {
    this.file = file
    this.uploadError = ''
  }

  @action
  handleCancel = () => {
    const {
      collectionBrief: { toggleDialogOpen }
    } = stores
    toggleDialogOpen()
    this.file = undefined
    this.uploadError = ''
  }

  @action
  handleSubmit = async URL => {
    const {
      collectionBrief: { toggleDialogOpen },
      buyingSessionDocumentStore: { selectedChannel }
    } = stores
    if (this.file) {
      try {
        this.uploadInProgress = true
        await centricUploadDocument(this.file, URL, null, selectedChannel)
      } finally {
        this.uploadInProgress = false
        this.queries.documentQuery()
        this.file = undefined
        this.uploadError = ''
        toggleDialogOpen()
      }
    } else {
      this.uploadError = `${strings.errorMsgFailedToUploadDoc}`
    }
  }

  @action
  deleteDocument = async URL => {
    try {
      this.uploadInProgress = true
      await deleteDocument(URL)
    } finally {
      this.queries.documentQuery()
      this.uploadInProgress = false
      this.file = undefined
    }
  }

  @action setSelectedChannel = channel => {
    this.selectedChannel = channel
  }

  @action setCanUserEdit = canUserEdit => {
    this.canUserEdit = canUserEdit
  }
}

export const buyingSessionDocumentStore = new BuyingSessionDocumentStore()
