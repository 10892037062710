import Button from '@components/std-components/Button'
import Typography from '@components/std-components/Typography'
import MdArrowDropdown from '@meronex/icons/ios/MdArrowDropdown'
import MdArrowDropup from '@meronex/icons/ios/MdArrowDropup'
import '@styles/Collapsible/style.css'
import React, { useState } from 'react'

interface ICollapsible {
  title: string
  isOpen?: boolean
  onClick?: (...rest: any[]) => void
  type?: 'full' | 'normal'
  onRowClick?: () => void
  onIconClick?: () => void
  variant?: any
  customTitleClass?: any
  onButtonClick?: () => void
  rowDisabled?: boolean
}

export const Collapsible: React.FC<ICollapsible> = props => {
  const {
    title,
    isOpen: _isOpen,
    onClick,
    children,
    type = 'normal',
    onRowClick,
    onIconClick,
    variant,
    customTitleClass,
    onButtonClick,
    rowDisabled
  } = props

  const [isOpen, setIsOpen] = useState(!!_isOpen)

  return (
    <div className={'collapsible-panel'}>
      <Button
        variant={'icon'}
        theme={'light'}
        className={
          type === 'normal' ? 'collapsible-panel-title' : 'collapsible-panel-title-full'
        }
        onClick={() => {
          onButtonClick && onButtonClick()
          onButtonClick && setIsOpen(isOpen => !isOpen)
        }}
      >
        <Typography
          onClick={() => {
            !onButtonClick && !onIconClick && onClick && onClick()
            !onButtonClick && !onIconClick && setIsOpen(isOpen => !isOpen)
            !rowDisabled && onRowClick && onRowClick()
          }}
          classes={customTitleClass}
          variant={variant ? variant : type === 'normal' ? 'h2' : 'subHeading2'}
          disabled={rowDisabled}
        >
          {title}
        </Typography>
        {isOpen ? (
          <MdArrowDropup
            onClick={() => {
              onIconClick ? onIconClick() : onClick && onClick()
              !onButtonClick && setIsOpen(false)
            }}
            size={14}
            className={'collapsible-panel-title-icon'}
          />
        ) : (
          <MdArrowDropdown
            onClick={() => {
              onIconClick ? onIconClick() : onClick && onClick()
              !onButtonClick && setIsOpen(true)
            }}
            size={14}
            className={'collapsible-panel-title-icon'}
          />
        )}
      </Button>
      {isOpen && <div>{children}</div>}
    </div>
  )
}
