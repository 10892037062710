import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { strings } from '@stores'
import classNames from 'classnames'
import { useObserver } from 'mobx-react-lite'
import React from 'react'
import { CardIcon, KPIIcon, ListIcon } from './svg'
import * as css from './ViewSelector.css'

interface IViewSelector {
  pathname: string
  onViewClick: Function
  isWholesaleView?: boolean
}

export const ViewSelector: React.FunctionComponent<IViewSelector> = ({
  pathname,
  onViewClick,
  isWholesaleView
}) => {
  return useObserver(function useHook() {
    const [anchorEl, setAnchorEl] = React.useState(null)

    const handleClick = event => {
      setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
      setAnchorEl(null)
    }

    const viewClickHandler = e => {
      onViewClick(e.currentTarget.id)
    }

    const getIconSrc = () => {
      if (pathname && pathname.includes(strings.grid)) {
        return CardIcon
      } else if (pathname && pathname.includes(strings.list)) {
        return ListIcon
      } else {
        return KPIIcon
      }
    }
    // const hideKPIViewSelector =
    //   assortmentType === strings.cluster ||
    //   clusterId ||
    //   subSection === ChannelSubSection.Wholesale
    return (
      <div className={css.main}>
        <Tooltip title={strings.viewSelector}>
          <div className={classNames([css.icon])}>
            <img src={getIconSrc()} onClick={handleClick} alt='' />
          </div>
        </Tooltip>
        <Menu
          id='view-selector'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          PopoverClasses={{ paper: isWholesaleView ? css.wholesalePopOver : css.popOver }}
          MenuListProps={{
            style: {
              padding: 0,
              // height: isWholesaleView ? 126 : 189,
              height: 126,
              width: 64
            }
          }}
        >
          <MenuItem
            classes={{
              root: css.menuItemRoot,
              selected: css.menuItemSelector
            }}
            disableGutters={true}
            onClick={handleClose}
          >
            <div id={strings.grid} className={css.menuItem} onClick={viewClickHandler}>
              <img src={CardIcon} alt={strings.cardView} />
              <Typography
                className={classNames([
                  css.menuText,
                  pathname && pathname.includes(strings.grid)
                    ? css.boldFont
                    : css.normalFont
                ])}
              >
                {strings.cardView}
              </Typography>
            </div>
          </MenuItem>
          <MenuItem
            classes={{
              root: css.menuItemRoot,
              selected: css.menuItemSelector
            }}
            disableGutters={true}
            onClick={handleClose}
          >
            <div id={strings.list} className={css.menuItem} onClick={viewClickHandler}>
              <img src={ListIcon} alt={strings.listView} />
              <Typography
                className={classNames([
                  css.menuText,
                  pathname && pathname.includes(strings.list)
                    ? css.boldFont
                    : css.normalFont
                ])}
              >
                {strings.listView}
              </Typography>
            </div>
          </MenuItem>
          {/* Removing KPI view as part of GBB-

          In case we add this in future, we would need discussion on retail BUY KPIs since recommended assortment and order
          could have different set of products. And this would affect KPIs like ACs, No. of stores, Avg depth, SKU Efficiency,
          etc. */}
          {/* {!isWholesaleView ? (
            <MenuItem
              classes={{
                root: hideKPIViewSelector
                  ? `${css.menuItemRoot} ${css.disableMenuRoot}`
                  : css.menuItemRoot,
                selected: css.menuItemSelector
              }}
              disableGutters={true}
              onClick={handleClose}
            >
              <div id={strings.kpi} className={css.menuItem} onClick={viewClickHandler}>
                <img src={KPIIcon} alt={strings.kpiView} />
                <Typography
                  className={classNames([
                    css.menuText,
                    pathname && pathname.includes(strings.kpi)
                      ? css.boldFont
                      : css.normalFont
                  ])}
                >
                  {strings.kpiView}
                </Typography>
              </div>
            </MenuItem>
          ) : null} */}
        </Menu>
      </div>
    )
  })
}
