import { ForecastChangeTypeEnum } from '@modules/wholesale/buyingSessionGroupProductWrapper/constants/productForecastInterface'
import { filter, find, map, reduce } from 'lodash'
import cloneDeep from 'lodash/cloneDeep'

export class ForecastService {
  getPayloadForUpsertForecast = ({
    productToBeUpdated,
    clientToBeUpdated,
    recordToBeUpdated,
    updatedValues,
    changeType
  }) => {
    return {
      buyingSessionGroupId: productToBeUpdated?.buyingSessionGroupId,
      buyingSessionId: productToBeUpdated?.buyingSessionId,
      clientId: clientToBeUpdated?.id,
      buyingSessionProductId: productToBeUpdated?.id,
      activityId: productToBeUpdated?.activityId,
      productCat1Id: productToBeUpdated?.productCat1Id,
      productCat2Id: productToBeUpdated?.productCat2Id,
      seasonId: productToBeUpdated?.seasonId,
      genderId: productToBeUpdated?.genderId,
      collectionId: productToBeUpdated?.collectionId,
      styleId: productToBeUpdated?.styleId,
      forecastQuantity: recordToBeUpdated?.betQty,
      salesQuantity: recordToBeUpdated?.salesQuantity,
      price: clientToBeUpdated?.price,
      colorwayId: productToBeUpdated?.colorwayId,
      currency: clientToBeUpdated?.currency,
      avgWeeklySales: recordToBeUpdated?.aws,
      avgDepth: recordToBeUpdated?.depth,
      ...this.getChangedRecord({ updatedValues, changeType }),
      changeType
    }
  }
  getPayloadForMultiUpsertForecast = ({
    productToBeUpdated,
    recordToBeUpdated,
    otbValues,
    changeType
  }) => {
    const sumOfOtbValues = () =>
      reduce(
        filter(productToBeUpdated?.clients, client => client?.id !== 'GLOBAL'),
        (acc, item) => acc + Number(otbValues?.zoneWiseOtbValue?.[item?.id]),
        0
      )

    const globalForecastQuantity = find(
      productToBeUpdated?.clients,
      client => client.id === 'GLOBAL'
    )?.forecastQuantity

    return map(productToBeUpdated?.clients || [], zone => {
      const forecastQuantity =
        zone?.id === 'GLOBAL'
          ? recordToBeUpdated?.betQty
          : otbValues?.zoneWiseOtbValue[zone?.id]
          ? (otbValues?.zoneWiseOtbValue[zone?.id] / sumOfOtbValues()) *
            globalForecastQuantity
          : 0
      return {
        buyingSessionGroupId: productToBeUpdated?.buyingSessionGroupId,
        buyingSessionId: productToBeUpdated?.buyingSessionId,
        clientId: zone?.id,
        buyingSessionProductId: productToBeUpdated?.id,
        activityId: productToBeUpdated?.activityId,
        productCat1Id: productToBeUpdated?.productCat1Id,
        productCat2Id: productToBeUpdated?.productCat2Id,
        seasonId: productToBeUpdated?.seasonId,
        genderId: productToBeUpdated?.genderId,
        collectionId: productToBeUpdated?.collectionId,
        styleId: productToBeUpdated?.styleId,
        forecastQuantity: forecastQuantity,
        salesQuantity: recordToBeUpdated?.salesQuantity,
        price: zone?.price,
        colorwayId: productToBeUpdated?.colorwayId,
        currency: zone?.currency,
        avgWeeklySales: recordToBeUpdated?.aws,
        avgDepth: recordToBeUpdated?.depth,
        changeType
      }
    })
  }

  isRecordUpdated = ({ recordToBeUpdated, updatedValues, changeType }) => {
    switch (changeType) {
      case ForecastChangeTypeEnum.FORECAST_QUANTITY:
        return updatedValues?.selectedProductQuantity !== recordToBeUpdated?.betQty
      case ForecastChangeTypeEnum.AVG_DEPTH:
        return updatedValues?.selectedProductAvgDepthValue !== recordToBeUpdated?.depth
      case ForecastChangeTypeEnum.AVG_WEEKLY_SALES:
        return updatedValues?.selectedProductAWS !== recordToBeUpdated?.aws
    }
  }

  getChangedRecord = ({ updatedValues, changeType }) => {
    switch (changeType) {
      case ForecastChangeTypeEnum.FORECAST_QUANTITY:
        return {
          forecastQuantity: updatedValues?.selectedProductQuantity
        }
      case ForecastChangeTypeEnum.AVG_DEPTH:
        return {
          avgDepth: updatedValues?.selectedProductAvgDepthValue
        }
      case ForecastChangeTypeEnum.AVG_WEEKLY_SALES:
        return {
          avgWeeklySales: updatedValues?.selectedProductAWS
        }
    }
  }

  processUpsertForecastResponseTotal = ({
    forecastResponseData,
    updatedTotalIds,
    storeTotal
  }) => {
    const processedStoreTotal = cloneDeep(storeTotal)
    updatedTotalIds?.forEach(totalId => {
      if (totalId) {
        const indexOfUpdatedTotal = processedStoreTotal?.findIndex(
          total => totalId === total?.id
        )
        if (-1 !== indexOfUpdatedTotal) {
          processedStoreTotal[indexOfUpdatedTotal] = forecastResponseData.find(
            total => totalId === total?.id
          )
        } else {
          processedStoreTotal.push(
            forecastResponseData.find(total => totalId === total?.id)
          )
        }
      }
    })
    return processedStoreTotal
  }
}
