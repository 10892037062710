import { MutationFunctionOptions } from '@apollo/client/react/types/types'
import { CriteriaOperationType } from '@models'
import { storage } from '@services/storageService'
import { apolloClient, stores, strings } from '@stores'
import { ExecutionResult } from 'graphql/execution'
import cloneDeep from 'lodash/cloneDeep'
import includes from 'lodash/includes'
import meanBy from 'lodash/meanBy'
import map from 'lodash/map'
import isEqual from 'lodash/isEqual'
import { action, computed, observable } from 'mobx'
import { GET_BUYING_SESSION_PRODUCT_DETAILS } from '../graphql/findBuyingSessionProductDetails'
import { GET_BUYING_SESSION_PRODUCT_COMMENTS } from '../graphql/getBuyingSessionProductComments'
import { GET_BUYING_SESSION_PRODUCT_RATING } from '../graphql/getBuyingSessionProductRating'
import {
  IDeleteProductComment,
  IExcludeClientFromBuyingSessionProduct,
  IExcludeClusterFromBSP,
  IProductComment,
  IProductRating,
  IRemoveClientFromBuyingSessionProductExclusion,
  IUpdateProductComment
} from '../graphql/hooks'
import { computedFn } from 'mobx-utils'
import difference from 'lodash/difference'
import { getEnumValue } from '@utils/get-enum-value'
import { config } from '@config'
import axios from 'axios'
import { deleteForecastFromOrderMgmtSvc } from '../../../../services/productDetailService'
import { productForecastUIStore } from '@modules/wholesale/buyingSessionGroupProductWrapper/stores/productForecastUIStore'

export class ProductDetailStore {
  @observable buyingSessionGroupId: string
  @observable buyingSessionProductId: string
  @observable commentText = ''
  @observable commentBeingSaved = new Map()
  @observable selectedCommentId = null
  @observable editedCommentText = ''
  @observable isSaveCommentInProgress = false
  @observable clientExclusionPopOverAnchorEl = false
  @observable selectedRows = []
  @observable isClientExclusionInProcess = false
  @observable clientsRemovedFromExclusion = new Map()
  // Cluster Exclusion
  @observable isClusterExclusionInProcess = false

  @observable addChannelPopupAnchorEl = false
  @observable zoneExclusionPopOverAnchorEl = false

  mutations: {
    updateBuyingSessionProductRating?: (
      options?: MutationFunctionOptions<any, IProductRating>
    ) => Promise<void | ExecutionResult>
    addBuyingSessionProductComment?: (
      options?: MutationFunctionOptions<any, IProductComment>
    ) => Promise<void | ExecutionResult>
    updateBuyingSessionProductComment?: (
      options?: MutationFunctionOptions<any, IUpdateProductComment>
    ) => Promise<void | ExecutionResult>
    deleteBuyingSessionProductComment?: (
      options?: MutationFunctionOptions<any, IDeleteProductComment>
    ) => Promise<void | ExecutionResult>
    excludeClientsFromBuyingSessionProduct?: (
      options?: MutationFunctionOptions<any, IExcludeClientFromBuyingSessionProduct>
    ) => Promise<void | ExecutionResult>
    removeClientFromBuyingSessionProductExclusion?: (
      options?: MutationFunctionOptions<
        any,
        IRemoveClientFromBuyingSessionProductExclusion
      >
    ) => Promise<void | ExecutionResult>
    addUpdateExcludedClustersForBSP?: (
      options?: MutationFunctionOptions<any, IExcludeClusterFromBSP>
    ) => Promise<void | ExecutionResult>
  } = {}

  initStore = (buyingSessionGroupId, buyingSessionProductId) => {
    this.buyingSessionGroupId = buyingSessionGroupId
    this.buyingSessionProductId = buyingSessionProductId
  }

  clearStore = () => {
    this.buyingSessionGroupId = undefined
    this.buyingSessionProductId = undefined
    this.selectedCommentId = null
    this.commentText = ''
    this.editedCommentText = ''
  }

  @computed get buyingSessionProductWhere() {
    const {
      nav: {
        queryParams: { bspId }
      }
    } = stores
    if (bspId) {
      return { Id: { operation: CriteriaOperationType.EQ, value: bspId } }
    } else {
      return null
    }
  }

  /**
   * updateBuyingSessionProductRating
   * @param buyingSessionProductId - Product id
   * @param ratingType - rating type
   *@param ratingValue - rating value
   */
  @action updateBuyingSessionProductRating = async ({
    buyingSessionProduct,
    ratingType,
    ratingValue,
    previousValue
  }) => {
    const {
      showroomProductDetailStore: { updateProcessedProductRating }
    } = stores
    try {
      const value = ratingValue === previousValue && ratingValue === 1 ? 0 : ratingValue
      const currentUser: { id: string } = storage.getItem('user')
      const refetchQueries = [
        {
          query: GET_BUYING_SESSION_PRODUCT_RATING,
          variables: {
            userId: currentUser.id,
            buyingSessionProductId: buyingSessionProduct
          }
        }
      ]

      await this.mutations.updateBuyingSessionProductRating({
        variables: {
          buyingSessionProduct,
          ratingType,
          ratingValue: value
        },
        refetchQueries,
        update: async proxy => {
          const { data } = await apolloClient.query({
            query: GET_BUYING_SESSION_PRODUCT_RATING,
            variables: {
              buyingSessionProductId: buyingSessionProduct
            },
            fetchPolicy: 'network-only'
          })
          if (data) {
            const ratings = data?.findVIPRating?.Items ?? null
            // note: average is considered for non-zero ratings only
            const nonZeroRatings = ratings.filter(rating => rating.Value !== 0)
            const averageRating = meanBy(nonZeroRatings, 'Value') || 0

            // update prod detail rating first -> positive outlook
            updateProcessedProductRating(ratingType, ratingValue)

            const buyingSessionProductData = apolloClient.readQuery({
              query: GET_BUYING_SESSION_PRODUCT_DETAILS,
              variables: {
                where: this.buyingSessionProductWhere
              }
            })
            const productData = cloneDeep(buyingSessionProductData)
            productData.findVIPBuyingSessionProduct.Items[0].averageRating = averageRating
            proxy.writeQuery({
              query: GET_BUYING_SESSION_PRODUCT_DETAILS,
              variables: {
                where: this.buyingSessionProductWhere
              },
              data: productData
            })
          }
        }
      })
    } catch (err) {
      // revert prod detail rating in case of failure
      updateProcessedProductRating(ratingType, previousValue)
    }
  }

  @action
  addBuyingSessionProductComment = async () => {
    const {
      nav: {
        queryParams: { bspId: _bspId }
      }
    } = stores
    const bspId = this.buyingSessionProductId || _bspId
    if (bspId) {
      try {
        this.isSaveCommentInProgress = true
        const refetchQuery = [
          {
            query: GET_BUYING_SESSION_PRODUCT_COMMENTS,
            variables: {
              buyingSessionProductId: bspId
            }
          }
        ]

        await this.mutations.addBuyingSessionProductComment({
          variables: {
            buyingSessionProduct: bspId,
            comment: this.commentText
          },
          refetchQueries: refetchQuery,
          update: async proxy => {
            const buyingSessionProductData = apolloClient.readQuery({
              query: GET_BUYING_SESSION_PRODUCT_DETAILS,
              variables: {
                where: this.buyingSessionProductWhere
              }
            })
            const productData = cloneDeep(buyingSessionProductData)
            productData.findVIPBuyingSessionProduct.Items[0].hasComments = true
            proxy.writeQuery({
              query: GET_BUYING_SESSION_PRODUCT_DETAILS,
              variables: {
                where: this.buyingSessionProductWhere
              },
              data: productData
            })
          }
        })
      } finally {
        this.commentText = ''
        this.isSaveCommentInProgress = false
      }
    }
  }

  @action
  updateBuyingSessionProductComment = async () => {
    const {
      nav: {
        queryParams: { bspId: _bspId }
      }
    } = stores
    const bspId = this.buyingSessionProductId || _bspId
    if (bspId) {
      try {
        const refetchQuery = [
          {
            query: GET_BUYING_SESSION_PRODUCT_COMMENTS,
            variables: {
              buyingSessionProductId: bspId
            }
          }
        ]
        await this.mutations.updateBuyingSessionProductComment({
          variables: {
            commentId: this.selectedCommentId,
            commentText: this.editedCommentText
          },
          refetchQueries: refetchQuery
        })
      } finally {
        this.selectedCommentId = null
        this.editedCommentText = ''
      }
    }
  }

  @action
  deleteBuyingSessionProductComment = async commentId => {
    const {
      nav: {
        queryParams: { bspId: _bspId }
      }
    } = stores
    const bspId = this.buyingSessionProductId || _bspId
    if (commentId) {
      try {
        await this.mutations.deleteBuyingSessionProductComment({
          variables: {
            commentId
          },
          update: async proxy => {
            const { data } = await apolloClient.query({
              query: GET_BUYING_SESSION_PRODUCT_COMMENTS,
              variables: {
                buyingSessionProductId: bspId
              },
              fetchPolicy: 'network-only'
            })
            if (data) {
              const comment =
                data?.getVIPBuyingSessionProduct?.DocumentsAndComments?.Comments ?? null
              const hasComments = !!comment
              const buyingSessionProductData = apolloClient.readQuery({
                query: GET_BUYING_SESSION_PRODUCT_DETAILS,
                variables: {
                  where: this.buyingSessionProductWhere
                }
              })
              const productData = cloneDeep(buyingSessionProductData)
              productData.findVIPBuyingSessionProduct.Items[0].hasComments = hasComments
              proxy.writeQuery({
                query: GET_BUYING_SESSION_PRODUCT_DETAILS,
                variables: {
                  where: this.buyingSessionProductWhere
                },
                data: productData
              })
            }
          }
        })
      } catch (e) {
        console.error('Error while deleting comment for buying session product', e)
      }
    }
  }

  @action
  handleCommentTextChange = commentId => event => {
    if (commentId) {
      this.editedCommentText = event.target.value
    } else {
      this.commentText = event.target.value
    }
  }

  @action handleEditComment = (commentId, commentText) => {
    this.selectedCommentId = commentId
    this.editedCommentText = commentText
  }

  @action openClientExclusionPopOver = event => {
    this.clientExclusionPopOverAnchorEl = event.currentTarget
  }

  @action closeClientExclusionPopOver = () => {
    this.clientExclusionPopOverAnchorEl = null
  }

  @action openZoneExclusionPopOver = event => {
    this.zoneExclusionPopOverAnchorEl = event.currentTarget
  }

  @action closeZoneExclusionPopOver = () => {
    this.zoneExclusionPopOverAnchorEl = null
  }

  setSelectedData = selectedRows => {
    this.selectedRows = selectedRows
  }

  filterExcludedClients = (clients, excludedClientsForBSP, excludedClientsForBSG) => {
    const excludedClients = map(
      [...excludedClientsForBSP, ...excludedClientsForBSG],
      'Id'
    )
    const filteredClients = clients.filter(
      client => !includes(excludedClients, client.Id) && client.GIV_VIPClient_Active_bool
    )
    return filteredClients
  }

  @action
  excludeClientsFromBuyingSessionProduct = async () => {
    const {
      clientSearch: { handlePopOverClose }
    } = stores

    if (this.buyingSessionProductId && this.selectedRows.length) {
      this.isClientExclusionInProcess = true
      const clients = map(this.selectedRows, 'id')
      try {
        const refetchQuery = [
          {
            query: GET_BUYING_SESSION_PRODUCT_DETAILS,
            variables: {
              where: this.buyingSessionProductWhere
            }
          }
        ]

        await this.mutations.excludeClientsFromBuyingSessionProduct({
          variables: {
            buyingSessionProduct: this.buyingSessionProductId,
            clients
          },
          refetchQueries: refetchQuery
        })

        if (this.zoneExclusionPopOverAnchorEl) {
          deleteForecastFromOrderMgmtSvc(
            this.buyingSessionGroupId,
            this.buyingSessionProductId,
            clients
          )
        }
      } finally {
        this.selectedRows = []
        handlePopOverClose()
        this.isClientExclusionInProcess = false
      }
    }
  }

  @action
  @action
  removeClientFromBuyingSessionProductExclusion = (clientId: any) => async e => {
    if (this.buyingSessionProductId && clientId) {
      this.clientsRemovedFromExclusion.set(clientId, true)
      try {
        const refetchQuery = [
          {
            query: GET_BUYING_SESSION_PRODUCT_DETAILS,
            variables: {
              where: this.buyingSessionProductWhere
            }
          }
        ]
        await this.mutations.removeClientFromBuyingSessionProductExclusion({
          variables: {
            buyingSessionProduct: this.buyingSessionProductId,
            client: clientId
          },
          awaitRefetchQueries: true,
          refetchQueries: refetchQuery
        })
      } finally {
        this.clientsRemovedFromExclusion.delete(clientId)
      }
    }
  }

  isDeleteInProgress = Id => {
    const client = this.clientsRemovedFromExclusion.get(Id)
    return client ? true : false
  }

  // Cluster Exclusion
  @action
  addUpdateExcludedClustersForBSP = async () => {
    const {
      clusterExclusionMultiSelect: {
        handleClusterPopOverClose,
        selectedClusters,
        newSelectedClusters
      }
    } = stores
    const isEqualClusters = isEqual(selectedClusters, newSelectedClusters)
    if (isEqualClusters) handleClusterPopOverClose()
    else if (this.buyingSessionProductId && !isEqualClusters) {
      this.isClusterExclusionInProcess = true
      const clusters = map(newSelectedClusters, 'Id')
      try {
        const refetchQuery = [
          {
            query: GET_BUYING_SESSION_PRODUCT_DETAILS,
            variables: {
              where: this.buyingSessionProductWhere
            }
          }
        ]
        await this.mutations.addUpdateExcludedClustersForBSP({
          variables: {
            buyingSessionProduct: this.buyingSessionProductId,
            clusters
          },
          refetchQueries: refetchQuery
        })
      } finally {
        handleClusterPopOverClose()
        this.isClusterExclusionInProcess = false
      }
    }
  }

  @action openAddChannelPopup = event => {
    this.addChannelPopupAnchorEl = event.currentTarget
  }

  @action closeAddChannelPopup = () => {
    this.addChannelPopupAnchorEl = null
  }

  getChannelsToAdd = computedFn((bsgChannels, bspChannels) => {
    const bspChannelValues = bspChannels?.map(channel => channel.id)
    const channels = difference(bsgChannels, bspChannelValues)
    return channels?.map(channel => {
      return { name: getEnumValue(channel), value: channel }
    })
  })

  @action handleProductStatusChange = async e => {
    const buyingSessionProductId = this.buyingSessionProductId
    const url = `${config.centricRestEndpoint}${
      e.target.value ? 'markProductAsCore' : 'unmarkProductAsCore'
    }`
    const body = {
      buyingSessionProductId
    }

    const sessionURL: string = storage.getItem('session_url')
    const { data } = await axios.post(url, body, {
      headers: {
        Authorization: `Bearer ${sessionURL}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })

    if (data?.status === strings.mutationResultSuccess) {
      const {
        nav: {
          queryParams: { productLayout }
        }
      } = stores
      const { setForecastData } = productForecastUIStore
      await apolloClient.query({
        query: GET_BUYING_SESSION_PRODUCT_DETAILS,
        variables: {
          where: {
            // get bspId irrespective of the query params GBB-7484
            Id: { operation: CriteriaOperationType.EQ, value: buyingSessionProductId }
          }
        },
        fetchPolicy: 'network-only'
      })
      'list' === productLayout && e.target.value && (await setForecastData())
    }
  }
}

export const productDetail = new ProductDetailStore()
