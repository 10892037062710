import { withApollo } from '@apollo/client/react/hoc/withApollo'
import { StyledProps } from '@components/3rd-party/mui'
import { ConfirmationDialog } from '@components/UI-Components/ConfirmationDialog'
import { MessageDialog } from '@components/UI-Components/MessageDialog'
import { ProductDetailContainer } from '@modules/retail/productDetail/container/ProductDetail'
import { withStyles } from '@material-ui/core/styles'
import { IPermissions } from '@routes'
import { getFilterChipsData } from '@services/collectionServices'
import { stores, strings } from '@stores'
import { observer } from 'mobx-react'
import React from 'react'
import { Route, withRouter } from 'react-router-dom'
import * as css from './Collection.css'
import { ProductCardAssortmentTree } from './ProductCardAssortmentTree'

// interface IMultiProductModel {
//   productId?: string
//   buyingSession: {
//     id: string
//   }
// }

const styles = theme => ({
  iconWithBorder: {
    borderRight: 'solid thin rgba(0, 0, 0, 0.1)',
    padding: '10px 27px 10px 27px'
  },
  collectionContainer: {
    margin: '0 20px 10px 20px',
    overflow: 'hidden',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  collectionContainerIfChipsApplied: {
    marginLeft: 25,
    marginRight: 20,
    overflow: 'hidden',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderTop: 'thin solid #8592a6'
  },
  productContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '18px 24px'
  },
  tableHead: {
    backgroundColor: theme.colors.navBackground
  },
  wrapper: {
    display: 'flex'
  }
})

interface ICollection {
  id?: string
  name?: string
}
interface IImages {
  id?: string
}
interface IAttributes {
  id?: string
  name?: string
  strVal?: string
  intVal?: string
  tag?: string
}
interface IColorways extends IImages, IAttributes {
  id?: string
  name?: string
  code?: string
  active?: boolean
  images?: Array<IImages>
  attributes?: Array<IAttributes>
}
interface IStyle extends ICollection, IColorways {
  id?: string
  name?: string
  collection?: ICollection
  colorways?: IColorways
}
interface ICollections extends IStyle {
  styles?: IStyle
}
interface CollectionProps extends StyledProps, ICollections {
  collections?: ICollections
  handleChange?: any
  buyingSessionProducts?: any
  scrollParentRef?: any
  client?: any
  match?: any
  currentPage?: any
  fetchMore?: any
  permissions?: IPermissions
  hasMore?: any
  location?: any
  history?: any
  loadFunc?: any
  betBuyData?: any
  activities?: any
  rangePlanData?: any
  refetch?: any
}

@((withStyles as any)(styles))
@(withApollo as any)
@(withRouter as any)
@observer
export class CollectionComponent extends React.Component<CollectionProps> {
  toggleDrawer = () => {
    const {
      props: {
        history,
        match: {
          params: { productId }
        }
      }
    } = this
    if (productId) {
      history.goBack()
    }
  }
  public refNode = React.createRef<HTMLDivElement>()

  render() {
    const [
      {
        props: {
          match: { path },
          classes,
          permissions,
          betBuyData,
          activities,
          rangePlanData
        }
      },
      {
        filter: { finalFilterToApply },
        collection: {
          resetSelectedProducts,
          selectedProducts,
          openChannelBulkAddRemoveConfirmationDialog,
          bulkAddDeleteConfirmationMessage,
          bulkAddRemoveChannelFromProductInProgress,
          toggleChannelBulkAddRemoveConfitmationDialog,
          confirmationTitle,
          bulkAddRemoveChannelFromBuyingSesionProducts
        },
        product: {
          handleMessageDialogClose,
          openConfirmationDialog,
          openErrorDialogForChannelDragOnInactiveProduct,
          toggleChannelDeleteConfirmationDialog,
          confirmationMessage,
          openDeleteConfirmationDialog,
          removeChannelFromProduct,
          removeChannelFromProductInProgress
        },
        nav: {
          params: { view }
        }
      }
    ] = [this, stores]

    const { chipsArrayToDisplay } = getFilterChipsData(finalFilterToApply)

    return (
      <div
        ref={this.refNode}
        className={css.root}
        onMouseDown={e => {
          if (
            !e.shiftKey &&
            e.button !== 2 &&
            selectedProducts.length &&
            this.refNode.current.contains(e.target as Node)
          ) {
            resetSelectedProducts()
          }
        }}
      >
        <div
          className={
            !!chipsArrayToDisplay.length
              ? classes.collectionContainerIfChipsApplied
              : classes.collectionContainer
          }
        >
          <ProductCardAssortmentTree
            permissions={permissions}
            betBuyData={betBuyData}
            activities={activities}
            rangePlanData={rangePlanData}
            listViewContainerHeight={!!chipsArrayToDisplay.length ? 200 : 160}
          />
          {openDeleteConfirmationDialog || openChannelBulkAddRemoveConfirmationDialog ? (
            <ConfirmationDialog
              open={
                openDeleteConfirmationDialog || openChannelBulkAddRemoveConfirmationDialog
              }
              title={
                openDeleteConfirmationDialog ? strings.removeChannel : confirmationTitle
              }
              message={
                openDeleteConfirmationDialog
                  ? confirmationMessage
                  : bulkAddDeleteConfirmationMessage
              }
              handleClose={
                openDeleteConfirmationDialog
                  ? toggleChannelDeleteConfirmationDialog
                  : toggleChannelBulkAddRemoveConfitmationDialog
              }
              handleDelete={
                openDeleteConfirmationDialog
                  ? removeChannelFromProduct
                  : bulkAddRemoveChannelFromBuyingSesionProducts
              }
              proceedButtonTitle={strings.yes}
              cancelButtonTitle={strings.no}
              loading={
                openDeleteConfirmationDialog
                  ? removeChannelFromProductInProgress
                  : bulkAddRemoveChannelFromProductInProgress
              }
            />
          ) : null}
          {openConfirmationDialog || openErrorDialogForChannelDragOnInactiveProduct ? (
            <MessageDialog
              open={
                openConfirmationDialog || openErrorDialogForChannelDragOnInactiveProduct
              }
              title={strings.notAllowed}
              message={
                openConfirmationDialog
                  ? strings.cannotAddClusterToNonRetailProduct
                  : openErrorDialogForChannelDragOnInactiveProduct
                  ? strings.cannotAddRetailToInactiveProduct
                  : ''
              }
              handleClose={handleMessageDialogClose}
            />
          ) : null}
        </div>
        <Route
          path={`${path}/${view}/details/:productId`}
          render={routerProps => (
            <ProductDetailContainer {...routerProps} permissions={permissions} />
          )}
        />
      </div>
    )
  }
}
