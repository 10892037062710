import { action, observable } from 'mobx'

export class CurrencySettingsStore {
  @observable selectedSeason = null

  @action
  handleSeasonChange = (event, seasons) => {
    const selectedSeason = seasons.find(season => season.id === event.target.value)
    this.setSelectedSeason(selectedSeason)
  }

  @action
  setSelectedSeason = season => {
    this.selectedSeason = season
  }
}

export const currencySettings = new CurrencySettingsStore()
