import React from 'react'
import Skeleton from 'react-loading-skeleton'
import Typography from '@components/std-components/Typography'
import moment from 'moment'
import { SAAddComment } from '..'
import { strings } from '@stores'
import MdTrash from '@meronex/icons/ios/MdTrash'
import MdCreate from '@meronex/icons/ios/MdCreate'
import { ReadMore } from '@components/std-components/ReadMore'
import { TypoGraphy } from '../SAProductDetailUIService'
import * as css from './SAComment.css'

interface ISAComment {
  comments: any
  currentUser: any
  handleEditComment: (Id: string, Comment: string) => void
  selectedCommentId: string
  onChange: (...rest: any[]) => void
  handleSubmit: () => void
  onDelete: (...rest: any[]) => void
  canEditComment: boolean
  commentText: string
  showAddComment?: boolean
  loading?: boolean
  typographyVariant?: TypoGraphy
}

export const SAComment: React.FC<ISAComment> = props => {
  const {
    comments,
    currentUser,
    handleEditComment,
    selectedCommentId,
    handleSubmit,
    onDelete,
    canEditComment,
    commentText,
    onChange,
    showAddComment,
    loading,
    typographyVariant
  } = props
  const { valueTypography } = typographyVariant

  return (
    <div style={{ height: '100%' }}>
      {comments?.length ? (
        <>
          {comments?.map(comment => {
            return (
              <React.Fragment key={comment.id}>
                <div className={css.commentRowHead}>
                  <Typography variant={valueTypography || 'caption2'} color={'secondary'}>
                    {`${comment?.author?.firstName} ${comment?.author?.lastName}` ?? ''}
                  </Typography>
                  <Typography variant={valueTypography || 'caption2'} color={'secondary'}>
                    {moment(comment.createdAt).format('MM-DD-YYYY HH:mm')}
                  </Typography>
                </div>
                {selectedCommentId === comment.Id ? (
                  <SAAddComment
                    autofocus={canEditComment}
                    canEditComment={canEditComment}
                    onChange={onChange}
                    handleSubmit={handleSubmit}
                    commentText={commentText}
                    commentId={comment.Id}
                    hideButton={true}
                  />
                ) : (
                  <div className={css.commentRowContent}>
                    <ReadMore text={comment.comment} />
                    {comment?.author?.id === currentUser?.id && (
                      <div className={css.commentRowAction}>
                        <MdCreate
                          className={css.commentRowActionIcon}
                          size={22}
                          onClick={
                            canEditComment
                              ? () => handleEditComment(comment?.id, comment?.comment)
                              : null
                          }
                        />
                        <MdTrash
                          className={css.commentRowActionIcon}
                          size={22}
                          onClick={canEditComment ? () => onDelete(comment.id) : null}
                        />
                      </div>
                    )}
                  </div>
                )}
              </React.Fragment>
            )
          })}
        </>
      ) : loading ? (
        <Skeleton />
      ) : !showAddComment ? (
        <Typography variant={valueTypography || 'h3'}>{strings.noComments}</Typography>
      ) : null}
    </div>
  )
}
