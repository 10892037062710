import { UserRoles } from '@modules/common/models/enums/UserRoles'
import React from 'react'
import { calculateUserRoles } from './calculate-user-roles'
import { getHighestUser, IPermissions } from './index'

const BuyingGroupSettings = React.lazy(
  () => import('@modules/wholesale/buyingGroupSettings/container/BuyingGroupSettings')
)
const BuyingGroupSettingToolBar = React.lazy(
  () =>
    import('@modules/wholesale/buyingGroupSettings/container/BuyingGroupSettingsToolBar')
)
const BuyingSessionGroupSettingNavBar = React.lazy(
  () =>
    import(
      '@modules/wholesale/buyingGroupSettings/container/BuyingSessionGroupSettingNavBar'
    )
)
const BuyingSessionGroupSummaryToolBar = React.lazy(
  () =>
    import(
      '@modules/wholesale/buyingGroupSummary/container/BuyingSessionGroupSummaryToolBar'
    )
)
const BuyingSessionGroupSummaryWrapper = React.lazy(
  () =>
    import(
      '@modules/wholesale/buyingGroupSummary/container/BuyingSessionGroupSummaryWrapper'
    )
)
const BuyingSessionSummary = React.lazy(
  () =>
    import('@modules/wholesale/buyingSessionCollection/container/BuyingSessionSummary')
)
const BuyingSessionToolbar = React.lazy(
  () =>
    import('@modules/wholesale/buyingSessionCollection/container/BuyingSessionToolbar')
)
const BuyingSessionGroupToolbar = React.lazy(
  () =>
    import('@modules/wholesale/buyingSessionGroup/containers/BuyingSessionGroupToolbar')
)
const BuyingSessionGroupWrapper = React.lazy(
  () =>
    import('@modules/wholesale/buyingSessionGroup/containers/BuyingSessionGroupWrapper')
)
const CampaignEditorToolbar = React.lazy(
  () => import('@modules/wholesale/campaignEditor/container/CampaignEditorToolbar')
)
const CampaignEditorWrapper = React.lazy(
  () => import('@modules/wholesale/campaignEditor/container/CampaignEditorWrapper')
)
const OrderManagementNav = React.lazy(
  () => import('@modules/wholesale/orderManagement/container/OrderManagementNav')
)
const OrderManagementSummary = React.lazy(
  () => import('@modules/wholesale/orderManagement/container/OrderManagementSummary')
)
const OrderManagementToolbar = React.lazy(
  () => import('@modules/wholesale/orderManagement/container/OrderManagementToolbar')
)
const ReportingNav = React.lazy(
  () => import('@modules/wholesale/reporting/containers/Nav')
)
const ReportingToolbar = React.lazy(
  () => import('@modules/wholesale/reporting/containers/Toolbar/ReportingToolbar')
)
const ReportingWrapper = React.lazy(
  () => import('@modules/wholesale/reporting/containers/ReportingWrapper')
)
const WholesaleGeneralSettingsToolbar = React.lazy(
  () => import('@modules/wholesale/wholesaleSettings/WholesaleGeneralSettingsToolbar')
)
const WholesaleSettings = React.lazy(
  () => import('@modules/wholesale/wholesaleSettings/WholesaleSettings')
)
const BuyingSessionGroupNav = React.lazy(
  () => import('@modules/wholesale/wsNavigations/container/BuyingSessionGroupNav')
)
const BuyingSessionNav = React.lazy(
  () => import('@modules/wholesale/wsNavigations/container/BuyingSessionNav')
)
const WholesaleSettingNavContainer = React.lazy(
  () =>
    import(
      '@modules/wholesale/wsNavigations/container/generalSettings/WholesaleSettingContainer'
    )
)
const SeasonSelectionContainer = React.lazy(
  () => import('@modules/wholesale/wsNavigations/container/SeasonSelection')
)

interface IComponents {
  isExact: boolean
  component: any
}

interface IRoutes {
  path: string | string[]
  routeComponent: {
    [componentType: string]: IComponents
  }
  allowedRoles: {
    [role: string]: IPermissions
  }
}

/**
 * TODO: Refactor code for same object and functions to work for wholesale and retail. Not
 * doing it now since there would be impact on retail
 * @param entity
 */
export const getPermissionsByEntityAndRoleForWholesale = (
  entity: string
): IPermissions => {
  const highestUser = getHighestUser()
  const defaultPermissions = { canEdit: false, canRead: false }

  if (highestUser.length) {
    let highestWeight = highestUser[0].weight
    let allRolesWithHighestWeight = highestUser.filter(
      role => role.weight === highestWeight
    )
    let permissions = null
    const highestRoleWithPermission = allRolesWithHighestWeight.find(role => {
      permissions = entityAndRoleWisePermisions[entity][role.name]
      return permissions
    })
    return highestRoleWithPermission ? permissions : defaultPermissions
  }

  return defaultPermissions
}

const entityAndRoleWisePermisions = {
  buyingSessions: {
    ...calculateUserRoles(UserRoles.ADMINISTRATOR, 1, 1),
    ...calculateUserRoles(UserRoles.CENTRAL_MERCHANDISER_WHOLESALE, 1, 1),
    ...calculateUserRoles(UserRoles.ZONE_AREA_MANAGER, 0, 1),
    ...calculateUserRoles(UserRoles.WHOLESALE_SHOWROOM_ASSISTANT, 0, 1),
    ...calculateUserRoles(UserRoles.EXTERNAL_CLIENT, 0, 1),
    ...calculateUserRoles(UserRoles.CM_ADMIN, 1, 1),
    ...calculateUserRoles(UserRoles.CENTRAL_MERCHANDISER_RETAIL, 1, 1),
    ...calculateUserRoles(UserRoles.REGIONAL_MERCHANDISER, 1, 1)
  },
  collections: {
    ...calculateUserRoles(UserRoles.ADMINISTRATOR, 1, 1),
    ...calculateUserRoles(UserRoles.CENTRAL_MERCHANDISER_WHOLESALE, 1, 1),
    ...calculateUserRoles(UserRoles.ZONE_AREA_MANAGER, 0, 1),
    ...calculateUserRoles(UserRoles.WHOLESALE_SHOWROOM_ASSISTANT, 0, 1),
    ...calculateUserRoles(UserRoles.EXTERNAL_CLIENT, 0, 1),
    ...calculateUserRoles(UserRoles.CM_ADMIN, 0, 1),
    ...calculateUserRoles(UserRoles.CENTRAL_MERCHANDISER_RETAIL, 0, 1),
    ...calculateUserRoles(UserRoles.REGIONAL_MERCHANDISER, 0, 1)
  },
  settings: {
    ...calculateUserRoles(UserRoles.ADMINISTRATOR, 1, 1),
    ...calculateUserRoles(UserRoles.CENTRAL_MERCHANDISER_WHOLESALE, 1, 1),
    ...calculateUserRoles(UserRoles.ZONE_AREA_MANAGER, 1, 0),
    ...calculateUserRoles(UserRoles.WHOLESALE_SHOWROOM_ASSISTANT, 0, 0),
    ...calculateUserRoles(UserRoles.EXTERNAL_CLIENT, 0, 0),
    ...calculateUserRoles(UserRoles.CM_ADMIN, 1, 1),
    ...calculateUserRoles(UserRoles.CENTRAL_MERCHANDISER_RETAIL, 1, 1),
    ...calculateUserRoles(UserRoles.REGIONAL_MERCHANDISER, 0, 0)
  },
  order: {
    ...calculateUserRoles(UserRoles.ADMINISTRATOR, 1, 1),
    ...calculateUserRoles(UserRoles.CENTRAL_MERCHANDISER_WHOLESALE, 1, 1),
    ...calculateUserRoles(UserRoles.ZONE_AREA_MANAGER, 1, 1),
    ...calculateUserRoles(UserRoles.WHOLESALE_SHOWROOM_ASSISTANT, 1, 1),
    ...calculateUserRoles(UserRoles.EXTERNAL_CLIENT, 1, 1),
    ...calculateUserRoles(UserRoles.CM_ADMIN, 1, 1),
    ...calculateUserRoles(UserRoles.CENTRAL_MERCHANDISER_RETAIL, 1, 1),
    ...calculateUserRoles(UserRoles.REGIONAL_MERCHANDISER, 1, 1)
  }
}

export const getWsRoutes = (): IRoutes[] => [
  {
    path: '/wholesale',
    routeComponent: {
      toolBar: {
        isExact: true,
        component: BuyingSessionGroupSummaryToolBar
      },
      nav: {
        isExact: true,
        component: SeasonSelectionContainer
      },
      mainComponent: {
        isExact: true,
        component: BuyingSessionGroupSummaryWrapper
      }
    },
    allowedRoles: entityAndRoleWisePermisions.buyingSessions
  },
  {
    path: '/wholesale/buyingSessionGroup',
    routeComponent: {
      toolBar: {
        isExact: true,
        component: BuyingSessionGroupToolbar
      },
      nav: {
        isExact: true,
        component: BuyingSessionGroupNav
      },
      mainComponent: {
        isExact: true,
        component: BuyingSessionGroupWrapper
      }
    },
    allowedRoles: entityAndRoleWisePermisions.buyingSessions
  },
  {
    path: '/wholesale/buyingSessionGroupSettings',
    routeComponent: {
      toolBar: {
        isExact: true,
        component: BuyingGroupSettingToolBar
      },
      nav: {
        isExact: true,
        component: BuyingSessionGroupSettingNavBar
      },
      mainComponent: {
        isExact: true,
        component: BuyingGroupSettings
      }
    },
    allowedRoles: entityAndRoleWisePermisions.buyingSessions
  },
  {
    path: '/wholesale/settings',
    routeComponent: {
      toolBar: {
        isExact: true,
        component: WholesaleGeneralSettingsToolbar
      },
      nav: {
        isExact: true,
        component: WholesaleSettingNavContainer
      },
      mainComponent: {
        isExact: true,
        component: WholesaleSettings
      }
    },
    allowedRoles: entityAndRoleWisePermisions.settings
  },
  {
    path: '/wholesale/buyingSession',
    routeComponent: {
      toolBar: {
        isExact: true,
        component: BuyingSessionToolbar
      },
      nav: {
        isExact: true,
        component: BuyingSessionNav
      },
      mainComponent: {
        isExact: true,
        component: BuyingSessionSummary
      }
    },
    allowedRoles: entityAndRoleWisePermisions.buyingSessions
  },
  {
    path: '/wholesale/buyingSessionGroup/editor',
    routeComponent: {
      toolBar: {
        isExact: true,
        component: CampaignEditorToolbar
      },
      nav: {
        isExact: true,
        component: BuyingSessionGroupNav
      },
      mainComponent: {
        isExact: true,
        component: CampaignEditorWrapper
      }
    },
    allowedRoles: entityAndRoleWisePermisions.buyingSessions
  },
  {
    path: '/wholesale/buyingSessionGroup/order',
    routeComponent: {
      toolBar: {
        isExact: true,
        component: OrderManagementToolbar
      },
      nav: {
        isExact: true,
        component: OrderManagementNav
      },
      mainComponent: {
        isExact: true,
        component: OrderManagementSummary
      }
    },
    allowedRoles: entityAndRoleWisePermisions.order
  },
  {
    path: '/wholesale/buyingSessionGroup/reports',
    routeComponent: {
      toolBar: {
        isExact: true,
        component: ReportingToolbar
      },
      nav: {
        isExact: true,
        component: ReportingNav
      },
      mainComponent: {
        isExact: true,
        component: ReportingWrapper
      }
    },
    allowedRoles: entityAndRoleWisePermisions.order
  }
]
