import { ProductMedia } from '@components/UI-Components/ProductCardOld/ProductMedia'
import Typography from '@material-ui/core/Typography'
import { DefaultLookImage } from '@resources/images'
import { getImageUrl } from '@services/imageService'
import React from 'react'
import * as css from './ProductLookContainer.css'

const SMALL_IMAGE_STYLE = 211

export const ProductLookContainer = props => {
  const { looks } = props
  return (
    <React.Fragment>
      {looks.map((look, index) => (
        <div key={look.id}>
          <div className={css.lookImage}>
            <ProductMedia
              key={index}
              image={look.image ? getImageUrl(look.image.id, 'small') : DefaultLookImage}
              height={SMALL_IMAGE_STYLE}
              width={SMALL_IMAGE_STYLE}
              imgWidth={SMALL_IMAGE_STYLE}
            />
          </div>
          <div className={css.lookNameMargin}>
            <Typography variant='caption'>{look.name}</Typography>
          </div>
        </div>
      ))}
    </React.Fragment>
  )
}
