import React from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import { strings } from '@stores'

export const NoRecordFound = styled(({ className, leftMargin }) => {
  return (
    <div className={className}>
      <Typography variant='h5'>{strings.noRecordFound}</Typography>
    </div>
  )
})`
  white-space: nowrap;
  margin-top: ${props => (props.topMargin ? props.topMargin : '8px')};
  margin-left: ${props => (props.leftMargin ? props.leftMargin : '0px')};
`
