import { createMuiTheme } from '@material-ui/core/styles'
import { red, green, grey } from '@material-ui/core/colors/index'
import { CentricTheme } from '../mui'

const iconSize = 24
const fontWeightMedium = 500

export const PALETTE_PRIMARY_MAIN = 'rgba(0, 0, 0, 0.87)'

const palette = {
  primary: {
    main: PALETTE_PRIMARY_MAIN,
    light: 'rgba(0, 0, 0, 0.54)',
    dark: 'rgba(0, 0, 0, 0.38)'
  },
  secondary: {
    main: 'rgba(255, 255, 255, 0.87)',
    light: 'rgba(255, 255, 255, 0.54)',
    dark: 'rgba(255, 255, 255, 0.38)'
  },
  text: {
    main: 'rgba(0, 0, 0, 0.87)',
    light: 'rgba(0, 0, 0, 0.54)',
    dark: 'rgba(0, 0, 0, 0.38)'
  },
  background: {
    default: '#424242'
    // modal: '#FAFAFA'
  },
  error: red,
  contrastThreshold: 3,
  tonalOffset: 0.03
}

const typography = {
  fontFamily: 'Roboto',
  textTransform: 'capitalize',
  overline: {
    fontSize: 10,
    fontWeight: fontWeightMedium,
    lineHeight: 1.6,
    letterSpacing: 1.5
  },
  caption: {
    fontSize: 12,
    lineHeight: 1.33,
    letterSpacing: 0.4,
    color: palette.text.main
  },
  body1: {
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: 0.3
  },
  button: {
    fontSize: 14,
    fontWeight: fontWeightMedium,
    lineHeight: 1.36,
    letterSpacing: 0.8
  },
  subtitle2: {
    fontSize: 14,
    fontWeight: fontWeightMedium,
    lineHeight: 1.71,
    letterSpacing: 0.1
  },
  subtitle1: {
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 0.2
  },
  h5: {
    fontSize: 20,
    fontWeight: fontWeightMedium,
    lineHeight: 1.3,
    letterSpacing: 0.1
  },
  h6: {
    fontSize: 20,
    fontWeight: fontWeightMedium,
    lineHeight: 1,
    letterSpacing: 0.4,
    color: '#7B7B7B',
    textTransform: 'uppercase'
  },
  // display3: {
  //   fontSize: 34,
  //   letterSpacing: 0.3,
  //   lineHeight: 1.18,
  //   fontFamily: 'Roboto'
  // },
  // display2: {
  //   fontFamily: 'Roboto',
  //   fontSize: 24,
  //   letterSpacing: 'normal',
  //   lineHeight: 1.17
  // },
  h4: {
    fontFamily: 'Roboto',
    fontWeight: 'normal',
    fontSize: 34,
    letterSpacing: 0.3,
    lineHeight: 1.2,
    color: '#000000'
  }
  // headline: {
  //   fontFamily: 'Roboto',
  //   fontSize: 16,
  //   letterSpacing: 0.1,
  //   lineHeight: 1.5
  // },
  // // TODO - Can remove this
  // title: {
  //   fontFamily: 'Roboto',
  //   fontSize: 16,
  //   letterSpacing: 0.1,
  //   lineHeight: 1.5
  // },
  // body1: {
  //   fontFamily: 'Roboto',
  //   fontSize: 14,
  //   letterSpacing: 0.3,
  //   lineHeight: 1.43
  // },
  // body2: {
  //   fontFamily: 'Roboto',
  //   fontSize: 12,
  //   letterSpacing: 0.2,
  //   lineHeight: 1.67
  // },
  // caption: {
  //   fontFamily: 'Roboto',
  //   fontSize: 10,
  //   letterSpacing: 1.5,
  //   lineHeight: 1.6,
  //   textTransform: 'uppercase',
  //   fontWeight: fontWeightMedium
  // },
  // button: {
  //   fontFamily: 'Roboto',
  //   fontSize: 14,
  //   letterSpacing: 0.5,
  //   fontWeight: fontWeightMedium
  // }
}
const styles: any = {
  components: {
    button: {
      height: 36,
      borderRadius: 2
    },
    icon: {
      width: iconSize,
      height: iconSize
    }
  },
  layout: {},
  widgets: {}
}

export const colors = {
  action: '#FFFFFF',
  success: green[700],
  error: red[700],
  grey1: grey[50],
  grey2: grey[50],
  grey3: grey[100],
  grey4: grey[200],
  grey5: grey[300],
  grey6: grey[400],
  white: '#FFFFFF',
  lightgrey: '#EEEEEE',
  midgrey: '#E0E0E0',
  grey: '#6D6D6D',
  darkgrey: '#424242',
  black: '#000000',
  red: '#F44336',
  green: '#4CAF50',
  yellow: '#FFC107',
  voilet: '#7E57C2',
  blue: '#4184F3',
  navBackground: '#F5F5F5',
  tagBackground: '#BDBDBD',
  mediaBackground: '#F6F6F6',
  tag: '#FAFAFA'
}

const width = {
  drawerWidth: 256
}

export const MainTheme = createMuiTheme({
  palette,
  typography,
  styles,
  colors,
  width
} as any) as CentricTheme
