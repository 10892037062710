import { StyledProps, CentricTheme } from '@components/3rd-party/mui'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import classNames from 'classnames'

const styles = (theme: CentricTheme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 0, 1, 0),
    flexWrap: 'wrap'
  },
  shouldScroll: {
    overflowY: 'auto',
    maxHeight: '450px'
  }
})

interface IProductLook extends StyledProps {
  shouldScroll?: boolean
}

@((withStyles as any)(styles))
export class ProductLook extends React.Component<IProductLook> {
  render() {
    const {
      props: { classes, children, shouldScroll }
    } = this
    return (
      <div
        className={classNames({
          [classes.container]: true,
          [classes.shouldScroll]: shouldScroll ? true : false,
          standardScrollBar: true
        })}
      >
        {children}
      </div>
    )
  }
}
