import {
  BUYING_SESSION_PRODUCT_SALES_PERIOD,
  CLUSTER_STATUS_NAME,
  BUYING_SESSION_PRODUCT_CHANNEL,
  CLUSTER
} from '@constants'
const HIERARCHY = 'Hierarchy'
const COLOR = 'Color'
const ACTIVE = 'Active'
const PRODUCT_FILTERS = 'Product Attributes'
const FLAG_FILTER = 'Flag Filter'
const BUY_QUANTITY_FILTER = 'Buy Quantity Filter'

const CORE_FILTERS = [
  'Status',
  'Favourite Filter',
  'Rating Filter',
  'Core Status',
  FLAG_FILTER,
  BUY_QUANTITY_FILTER,
  CLUSTER_STATUS_NAME,
  BUYING_SESSION_PRODUCT_SALES_PERIOD,
  BUYING_SESSION_PRODUCT_CHANNEL,
  CLUSTER
]

const SORT_BY_FILTERS = [
  {
    name: HIERARCHY,
    disabled: false
  },
  {
    name: COLOR,
    disabled: true
  }
]
const REGION = [
  { name: 'EUR' },
  { name: 'USD' },
  { name: 'JPY' },
  { name: 'CNY' },
  { name: 'AED' }
]
const CORE_BASIC_FILTERS = [
  {
    definition: {
      id: 1,
      name: 'Status',
      displayName: { singular: 'Status' }
    },
    values: [
      {
        label: 'Active',
        value: '1'
      },
      {
        label: 'Inactive',
        value: '2'
      }
    ]
  },
  {
    definition: {
      id: 2,
      name: 'Favourite Filter',
      displayName: { singular: 'Favourite Filter' }
    },
    values: [
      {
        label: 'Favourite',
        value: '1'
      },
      {
        label: 'Unfavourite',
        value: '2'
      }
    ]
  },
  {
    definition: {
      id: 3,
      name: 'Rating Filter',
      displayName: { singular: 'Rating Filter' }
    },
    values: [
      {
        label: 'Rated',
        value: '1'
      },
      {
        label: 'Unrated',
        value: '2'
      }
    ]
  },
  {
    definition: {
      id: 3,
      name: 'Core Status',
      displayName: { singular: 'Core Status' }
    },
    values: [
      {
        label: 'Core',
        value: '1'
      },
      {
        label: 'Non Core',
        value: '2'
      }
    ]
  },
  {
    definition: {
      id: 3,
      name: FLAG_FILTER,
      displayName: { singular: 'Flag Filter' }
    },
    values: [
      {
        label: 'Flagged',
        value: '1'
      },
      {
        label: 'Unflagged',
        value: '2'
      }
    ]
  },
  {
    definition: {
      id: 3,
      name: BUY_QUANTITY_FILTER,
      displayName: { singular: 'Buy Quantity' }
    },
    values: [
      {
        label: 'With Qty',
        value: '1'
      },
      {
        label: 'Without Qty',
        value: '2'
      }
    ]
  }
]

const RESTRICT_PRODUCT_BY_ZONE_TABS = [
  'Zone exclusion',
  'Cluster',
  'Channel',
  'Sales Period',
  'Free tags'
]

const BULK_OPERATION_TABS = [
  { id: 'channel', name: 'Channels' },
  { id: 'wholesaleCluster', name: 'Wholesale Clusters' },
  { id: 'retailCluster', name: 'Retail Clusters' },
  { id: 'retailFreeTags', name: 'Retail Free Tags' },
  { id: 'wholesaleFreeTags', name: 'Wholesale Free Tags' },
  { id: 'drop', name: 'Drops' },
  { id: 'salesPeriod', name: 'Retail Sales Period' },
  { id: 'clientExclusions', name: 'Market Exclusions' },
  { id: 'zoneExclusions', name: 'Zone Exclusions' }
]

const RESTRICT_PRODUCT_BY_ZONE_DEFAULT_TAB_VALUE = 'Zone exclusion'
const RESTRICTED_PRODUCT_MSG =
  '* This product can not be mark as core as it is excluded from one or more zones.'
const NON_RETAIL_CORE_PRODUCT_MSG =
  '* This product can not be mark as core as it does not have retail channel.'

const ASSORTMENT_SELECTION_DEFAULT_TAB_VALUE = 'collection'
const ASSORTMENT_SELECTION_NAV_TABS = [
  { label: 'Collections', value: 'collection', icon: 'collection' },
  { label: 'Tags', value: 'tags', icon: 'tags' }
]
const VIEW_ATTRIBUTE_SELECTORS_TAB_VALUE = 'product_attributes'
const VIEW_ATTRIBUTE_SELECTORS_TABS = [
  { label: 'Product attributes', value: 'product_attributes', icon: '' },
  { label: 'Tags', value: 'tags', icon: '' }
]
const DND_NOT_ALLOWED = 'Cluster drag and drop not allowed.'
export {
  HIERARCHY,
  COLOR,
  ACTIVE,
  SORT_BY_FILTERS,
  REGION,
  PRODUCT_FILTERS,
  CORE_FILTERS,
  CORE_BASIC_FILTERS,
  RESTRICT_PRODUCT_BY_ZONE_TABS,
  RESTRICT_PRODUCT_BY_ZONE_DEFAULT_TAB_VALUE,
  FLAG_FILTER,
  BUY_QUANTITY_FILTER,
  RESTRICTED_PRODUCT_MSG,
  NON_RETAIL_CORE_PRODUCT_MSG,
  ASSORTMENT_SELECTION_DEFAULT_TAB_VALUE,
  ASSORTMENT_SELECTION_NAV_TABS,
  VIEW_ATTRIBUTE_SELECTORS_TABS,
  VIEW_ATTRIBUTE_SELECTORS_TAB_VALUE,
  DND_NOT_ALLOWED,
  BULK_OPERATION_TABS
}
