import React from 'react'
import StartRating from '@components/std-components/Rating'
import Typography from '@components/std-components/Typography'
import IosStar from '@meronex/icons/ios/IosStar'
import { useUpdateBuyingSessionProductRating } from '@modules/wholesale/productDetail/graphql/hooks'
import { stores, strings } from '@stores'
import { useObserver } from 'mobx-react-lite'
import { config } from '@config'
import { useSAProductDetailsContext } from '../../hook/SAProductDetails-Hook'
import * as css from './SAProductDetailRatingForPopUp.css'

// SA Custom Rating Component
export const SAProductDetailRatingForPopUp: React.FC = () => {
  const STAR_SIZE = 16
  return useObserver(function useHook() {
    const {
      productDetail: { mutations }
    } = stores
    const {
      saProductDetailsStore: {
        isBuyingSessionProductExtraInformationLoading,
        product,
        updateUIRating
      }
    } = useSAProductDetailsContext()
    const canEditRating =
      product?.info?.bsgStatus !==
      config?.appConfig?.enumerations?.buyingSessionStatus?.BUYING_SESSION_STATUS_VALUES
        ?.CLOSED

    mutations.updateBuyingSessionProductRating =
      useUpdateBuyingSessionProductRating() as any

    return (
      <>
        {isBuyingSessionProductExtraInformationLoading ? (
          <div>Loading...</div>
        ) : (
          <>
            {product.ratings?.ratingWithTypes?.length ? (
              <div className={css.productDetailRatingContainer}>
                {/* Overall */}
                <div className={css.productDetailRating}>
                  <div className={css.productDetailRatingName}>
                    <Typography variant={'label'} color={'label'}>
                      {strings.overallRating}
                    </Typography>
                  </div>
                  <div className={css.productDetailOverallRatingValue}>
                    <IosStar size={STAR_SIZE} />
                    <Typography variant={'value'} classes={css.productDetailOverallValue}>
                      {product.ratings?.averageRating.toFixed(2)}
                    </Typography>
                  </div>
                </div>
                {/* Rating By Type */}
                {product?.ratings?.ratingWithTypes?.map(
                  ({ id, ratingType, value }, index) => {
                    return (
                      <div className={css.productDetailRating} key={id || index}>
                        <div className={css.productDetailRatingName}>
                          <Typography variant={'label'} color={'label'}>
                            {ratingType?.name}
                          </Typography>
                        </div>
                        <div className={css.productDetailRatingValue}>
                          <StartRating
                            rating={value}
                            stop={9}
                            readonly={!canEditRating || !product?.original?.active}
                            onChangeRating={newRating =>
                              updateUIRating(id, newRating.toString(), ratingType?.id)
                            }
                            onUnselectRating={() =>
                              updateUIRating(id, '0', ratingType?.id)
                            }
                            size={STAR_SIZE}
                            starIconClassName={css.starIconClassName}
                          />
                        </div>
                      </div>
                    )
                  }
                )}
              </div>
            ) : (
              <Typography variant={'h3'}>{strings.notAvailable}</Typography>
            )}
          </>
        )}
      </>
    )
  })
}
