import { useMediaQuery } from 'react-responsive'

export default function TabletMediaQuery({ children }) {
  const matches = useMediaQuery({
    query:
      'only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2)'
  })

  if (typeof children === 'function') {
    return children(matches)
  }
  return matches ? children : null
}
