import { StyledProps } from '@components/3rd-party/mui'
import React from 'react'
import styled from 'styled-components'

const WIDTH = 200
const HEIGHT = 200

interface IProductMedia extends StyledProps {
  height?: number
  width?: number | string
  image: string
  padding?: string
  imgWidth?: number | string
  iconWidth?: number
  margin?: string
  fitStyle?: string
  topRightIcon?: any
  onTopRightIconClick?: any
  bottomRightIcon?: any
  onBottomRightIconClick?: any
  disabled?: boolean
  topLeftIcon?: any
  onTopLeftIconClick?: any
  bottomLeftIcon?: any
  onBottomLeftIconClick?: any
  topRightIconTop?: number
  onClick?: (e, product?: any) => void
  cursorPointer?: boolean
  isRecommended?: boolean
  cursorPointerType?: string
  status?: string
}

export const StyledProductMedia = styled.div<any>`
  position: relative;
  width: ${WIDTH}px;
  height: ${HEIGHT}px;
  display: flex;
  justify-content: center;
  cursor: ${props =>
    props.cursorPointer
      ? props.cursorPointerType
        ? props.cursorPointerType
        : 'pointer'
      : 'auto'};
  @media (max-width: 1366px) {
    .dropTargetClass {
      height: 175;
    }
  }
`
const MediaImage = styled.img<any>`
  width: 171px;
  height: ${HEIGHT}px;
  object-fit: ${props => props.fitStyle || 'contain'};
  padding: ${props => props.padding || '0px'};
  @media (max-width: 1366px) {
    .dropTargetClass {
      height: 175;
    }
  }
`
// const ICON_WIDTH = 24

const IconImage = styled.img<any>(props => ({
  '&&': {
    position: 'absolute',
    top: props.top,
    bottom: props.bottom,
    left: props.left,
    right: props.right,
    cursor: 'pointer',
    opacity: (props.disabled || props.setOpacity) && 0.4,
    pointerEvent: props.disabled && 'none',
    zIndex: 1,
    width: props.width,
    margin: '5px'
  }
}))

export class ProductMedia extends React.Component<IProductMedia> {
  render() {
    const {
      props: {
        height,
        width,
        iconWidth,
        image,
        imgWidth,
        classes,
        fitStyle,
        disabled,
        onClick,
        margin,
        topRightIcon,
        onTopRightIconClick,
        bottomRightIcon,
        onBottomRightIconClick,
        topLeftIcon,
        onTopLeftIconClick,
        bottomLeftIcon,
        onBottomLeftIconClick,
        topRightIconTop,
        cursorPointer,
        cursorPointerType,
        isRecommended,
        status,
        ...otherProps
      }
    } = this
    return (
      <StyledProductMedia
        style={{
          height: height,
          width: width,
          boxShadow:
            isRecommended &&
            `0px 2px 6px -2px var(--grey), 
          1px 0px 13px -8px var(--grey),
          0px 3px 8px -37px var(--grey), 
          10px 3px 6px -29px var(--grey)`,
          margin
        }}
        cursorPointer={cursorPointer ?? true}
        cursorPointerType={cursorPointerType}
        onClick={onClick}
      >
        <MediaImage
          fitStyle={fitStyle}
          src={image}
          style={{
            width: imgWidth,
            height: height
          }}
          {...otherProps}
        />
        {topRightIcon ? (
          <IconImage
            src={topRightIcon}
            right={0}
            top={0}
            onClick={onTopRightIconClick ? onTopRightIconClick : null}
          />
        ) : null}
        {topLeftIcon ? (
          <IconImage
            src={topLeftIcon}
            top={0}
            left={0}
            onClick={onTopLeftIconClick ? onTopLeftIconClick : null}
          />
        ) : null}
        {bottomRightIcon ? (
          <IconImage
            src={bottomRightIcon}
            disabled={disabled}
            bottom={0}
            right={0}
            width={iconWidth}
            onClick={onBottomRightIconClick ? onBottomRightIconClick : null}
          />
        ) : null}
        {bottomLeftIcon ? (
          <IconImage
            src={bottomLeftIcon}
            disabled={disabled}
            left={0}
            bottom={0}
            onClick={onBottomLeftIconClick ? onBottomLeftIconClick : null}
            setOpacity={true}
          />
        ) : null}
      </StyledProductMedia>
    )
  }
}
