
    
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"exchangeRateFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"VIPBuyingSessionGroup"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"CurrencyExchangeTable"},"name":{"kind":"Name","value":"GIV_VIPBuyingSessionGroup_CurrencyExchangeTable_ref"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"Id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"Name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"To"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"Name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"Value"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"Id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"Rates"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"Name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"Value"},"arguments":[],"directives":[]}]}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"ValidFrom"},"arguments":[],"directives":[]}]}}]}}]};
  

    
  var names = {};
  function unique(defs) {
    return defs.filter(function (def) {
      if (def.kind !== 'FragmentDefinition') return true;
      var name = def.name.value;
      if (names[name]) {
        return false;
      } else {
        names[name] = true;
        return true;
      }
    });
  };

  

    module.exports = doc
  
