/*
 * @Author: Raeesaa Metkari
 * @Date: 2020-03-09 23:39:14
 * @Last Modified by: Raeesaa Metkari
 * @Last Modified time: 2020-07-17 02:12:47
 */

import { ErrorNotifier } from '@modules/errors/containers/ErrorNotifier'
import {
  buildErrorMessage,
  getDetailsIfDuplicateBasketCreation,
  getUserFriendlyErrors,
  hasC8SessionExpired,
  isAuthenticationErrorOnLogout
} from '@services/errorService'
import { stores, useApolloNetworkStatusReducer } from '@stores'
import isEmpty from 'lodash/isEmpty'
import { useObserver } from 'mobx-react-lite'
import * as qs from 'query-string'
import React from 'react'
import { ActionTypes } from 'react-apollo-network-status'
import { ABORTED_NETWORK_ERROR } from 'src/constant/Errors'
import { ProgressBar } from './ProgressBar'

interface INetworkStatus {
  type: ActionTypes
  pendingRequest: number
  errors: {
    graphQLErrors: Array<{ message: string }>
  }
}

const initialState: INetworkStatus = {
  errors: null,
  type: undefined,
  pendingRequest: 0
}

/**
 * Custom reducer for apollo network status
 * @param state
 * @param action
 */
const reducer = (state: INetworkStatus, action: any): INetworkStatus => {
  const {
    showProgress,
    showNotifier = true,
    showCancelledRequestError = false
  } = action.payload.operation.getContext() || {}
  if (showProgress !== false) {
    switch (action.type) {
      case ActionTypes.REQUEST:
        state.pendingRequest++
        break
      case ActionTypes.ERROR:
      case ActionTypes.SUCCESS:
      case ActionTypes.CANCEL:
        state.pendingRequest--
        break
    }
  }
  let errors: any = {}
  if (showNotifier) {
    if (action.type === ActionTypes.SUCCESS && action?.payload?.result?.errors?.length) {
      errors.graphQLErrors = action?.payload?.result?.errors ?? []
    } else if (action.type === ActionTypes.ERROR) {
      if (
        showCancelledRequestError &&
        action?.payload?.networkError.name === ABORTED_NETWORK_ERROR
      ) {
      } else {
        errors.networkError = action?.payload?.networkError ?? []
      }
    }
  }
  return {
    ...state,
    errors,
    type: action.type
  }
}

const GlobalLoader: React.FunctionComponent<any> = ({ isLoad }) => {
  return useObserver(function useHook() {
    const {
      axios: { isLoading }
    } = stores
    return (isLoading || isLoad) && <ProgressBar />
  })
}

export const NetworkStatusAndErrorNotifier: React.FunctionComponent<any> = () => {
  const {
    nav: {
      history,
      location: { pathname }
    }
  } = stores
  const networkStatus: INetworkStatus = useApolloNetworkStatusReducer(
    reducer,
    initialState
  )

  const error = isEmpty(networkStatus?.errors) ? undefined : networkStatus?.errors
  const C8SessionExpired = hasC8SessionExpired(error)
  if (C8SessionExpired) {
    window.location.replace('/logout')
    return null
  }

  const details = getDetailsIfDuplicateBasketCreation(error)
  if (!isEmpty(details) && details.clientId && details.bsgId) {
    history.push({
      pathname: `${pathname}/collection`,
      search: qs.stringify({
        clientId: details.clientId,
        buyingSessionGroupId: details.bsgId,
        orderId: details.orderId
      })
    })
    return null
  }
  const userFriendlyErrorsObj = getUserFriendlyErrors(error)
  const isAuthErrorOnLogout = isAuthenticationErrorOnLogout(error)
  return (
    <div>
      <GlobalLoader isLoad={networkStatus.pendingRequest > 0}></GlobalLoader>
      {!userFriendlyErrorsObj && !isAuthErrorOnLogout ? (
        <ErrorNotifier error={buildErrorMessage(error)} />
      ) : null}
    </div>
  )
}
