import { StyledProps } from '@components/3rd-party/mui'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'

const styles = theme => ({
  root: {
    minWidth: 216,
    width: 216,
    minHeight: 108,
    borderRadius: 2,
    backgroundColor: theme.colors.white,
    position: 'relative',
    boxShadow: `0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)`
  },
  cardCursor: {
    cursor: 'pointer',
    justifyContent: 'space-between',
    padding: 10,
    display: 'flex',
    flexDirection: 'column'
  }
})
interface IBuyingSessionCard extends StyledProps {
  active?: boolean
  showTag?: boolean
  onClick?: (e: React.MouseEvent) => void
  href?: string
}
@((withStyles as any)(styles))
export class BuyingSessionCard extends React.Component<IBuyingSessionCard> {
  render() {
    const {
      props: { className, classes, onClick, children, style, href }
    } = this
    return (
      <Link
        className={classNames(className, classes.root, classes.cardCursor)}
        style={style}
        to={href}
        onClick={onClick}
      >
        {children}
      </Link>
    )
  }
}
