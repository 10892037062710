import { ListViewRowSelector } from '@components/UI-Components/ListViewRowSelector'
import { SAQuantity } from '@components/UI-Components/SAQuantity'
import { SASizeInput } from '@components/UI-Components/SASizeInput'
import { StandardAttributeList } from '@components/UI-Components/StandardAttributeList'
import {
  STANDARDLISTVIEWPRODUCTHEIGHT,
  StandardListViewProductInfo
} from '@components/UI-Components/StandardListViewProductInfo'
import { ThreeDotLoader } from '@components/UI-Components/svg'
import {
  Add,
  ProductDetailIcon,
  PulseProgress,
  Remove
} from '@components/UI-Components/svg/wholesale'
import { ListViewColumnDefinition } from '@components/UI-Components/StandardTableView/models/ListviewColumnDefinition'
import { SAListViewProduct } from '@modules/common/models/interfaces/SAListViewProduct'
import { stores, strings } from '@stores'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { Observer } from 'mobx-react-lite'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { WHOLESALEPRODUCTROWHEIGHT } from 'src/constant/ProductLayoutViews'

/**
 * Class with methods for returning components required to render product cell
 */
class SACollectionListViewComponentService {
  getProductInfoComponent = (
    product: SAListViewProduct,
    columnDefinition: ListViewColumnDefinition
  ) => {
    const {
      showroomCollection: { isSAView, toggleBasketProduct, onProductDetailClick }
    } = stores
    const { image, status } = get(product, columnDefinition.product.key)
    const { isRecommendedProduct, isBasketProduct, isActive, isLoading } = product
    /**
     * Using observer hook is failing here, not sure
     * why so using observer component
     */
    return (
      <Observer>
        {() => {
          const {
            showroomCollection: { productActionMap, isClientUnavailable, canEditOrder }
          } = stores
          const basketToggleInProgress =
            productActionMap[product.id]?.basketTogglingInProgress
          const isDisabledActions =
            isClientUnavailable || product.isSessionClosed || !canEditOrder
          return (
            <StandardListViewProductInfo
              image={image}
              fitStyle={image ? null : 'cover'}
              status={status}
              isActive={isActive}
              maxWidth={columnDefinition.width}
              maxHeight={
                isSAView ? WHOLESALEPRODUCTROWHEIGHT : STANDARDLISTVIEWPRODUCTHEIGHT
              }
              bottomRightIcon={
                isLoading
                  ? ThreeDotLoader
                  : basketToggleInProgress
                  ? PulseProgress
                  : isBasketProduct
                  ? Remove
                  : Add
              }
              onBottomRightIconClick={toggleBasketProduct(
                product,
                isBasketProduct,
                isDisabledActions
              )}
              disabled={isDisabledActions}
              topRightIconTop={8}
              iconWidth={32}
              bottomLeftIcon={ProductDetailIcon}
              onbottomLeftIconClick={
                basketToggleInProgress ? null : onProductDetailClick(product)
              }
              cursorPointer={false}
              isRecommended={isRecommendedProduct}
            />
          )
        }}
      </Observer>
    )
  }

  getProductAttributeComponent = (
    product: SAListViewProduct,
    columnDefinition: ListViewColumnDefinition
  ) => {
    return (
      <StandardAttributeList
        productAttributes={get(product, columnDefinition.product.key)}
      />
    )
  }

  getProductQuantityComponent = (
    product: SAListViewProduct,
    columnDefinition: ListViewColumnDefinition
  ) => {
    const {
      showroomCollection: {
        onAllBookChange,
        isClientUnavailable,
        canEditOrder,
        isDoorOrder
      }
    } = stores
    const isDisabled =
      isClientUnavailable || product.isSessionClosed || !canEditOrder || !product.isActive
    const title =
      product.isUnSizedProduct && product.isBasketProduct ? strings.singleSizeProduct : ''
    const BOOK_ALL_UNBOOK_ALL_OPTIONS = [
      {
        label: strings.bookAll,
        value: strings.bookAll,
        isDisabled: product.isBookedInAllDoors
      },
      {
        label: strings.unbookAll,
        value: strings.unbookAll,
        isDisabled: product.isUnbookedInAllDoors,
        warningMessage: strings.bookUnbookAllWarningMessage
      }
    ]
    return (
      <SAQuantity
        items={get(product, columnDefinition.product.key)}
        title={title}
        menuData={{
          title: `${strings.book}/${strings.unbookAll}`,
          options: BOOK_ALL_UNBOOK_ALL_OPTIONS,
          isDisabled: isDisabled,
          isVisible: product.isBasketProduct && !isDoorOrder,
          onChange: onAllBookChange,
          product: product
        }}
      />
    )
  }

  getDoorLevelComponent = (
    product: SAListViewProduct,
    columnDefinition: ListViewColumnDefinition
  ) => {
    const {
      showroomCollection: {
        isClientUnavailable,
        onBookedChange,
        productActionMap,
        handleQtyChange,
        handleKeyDownForOrderProductQtyInput,
        addProductToDoorOrder,
        canEditOrder
      }
    } = stores

    const {
      id: doorOrderProductId,
      doorOrderId,
      isBooked,
      isRecommended,
      isInputDisabled,
      isCheckedDisabled,
      isAddDisabled,
      targetQuantity,
      totalSizedQuantity
    } = get(product, columnDefinition.product.key) || {}
    return (
      <Observer>
        {() => {
          const isBookingInProgress =
            productActionMap[doorOrderProductId]?.doorOrderProductBookingInProgress
          const isGlobalQtyUpdateInProgress =
            productActionMap[doorOrderProductId]?.globalProductQtyUpdateInProgress
          const isAddProductToDoorOrderInProgress =
            productActionMap[`${doorOrderProductId}-${doorOrderId}`]
              ?.addDoorOrderProductInProgress

          if (product?.isLoading) {
            return (
              <div className={'padding-5'}>
                <p>
                  <Skeleton height={45} />
                </p>
                <p>
                  <Skeleton height={145} />
                </p>
              </div>
            )
          }

          if (!product?.orderProductId) {
            return null
          }

          return (
            <SASizeInput
              data={
                doorOrderProductId
                  ? {
                      isChecked: isBooked,
                      inProgress: isBookingInProgress,
                      checkboxLabel: isRecommended ? strings.recommended : strings.booked,
                      isRecommended: isRecommended,
                      inputValue: targetQuantity,
                      targetQuantityLabel:
                        product.isUnSizedProduct && product.isBasketProduct
                          ? strings.singleQuantity
                          : strings.targetQuantity,
                      sizedQuantityLabel: strings.sizedQuantity,
                      totalSizedQuantity
                    }
                  : null
              }
              isInputDisabled={
                isClientUnavailable ||
                isInputDisabled ||
                isGlobalQtyUpdateInProgress ||
                !canEditOrder
              }
              isCheckedDisabled={
                isClientUnavailable ||
                isCheckedDisabled ||
                isBookingInProgress ||
                !canEditOrder
              }
              isAddDisabled={
                isClientUnavailable ||
                isAddDisabled ||
                isAddProductToDoorOrderInProgress ||
                !canEditOrder
              }
              onCheckedChange={onBookedChange(product, doorOrderProductId, !isBooked)}
              handleInputChange={handleQtyChange(
                product,
                doorOrderProductId,
                targetQuantity
              )}
              handleKeyDown={handleKeyDownForOrderProductQtyInput(
                product,
                doorOrderProductId,
                targetQuantity
              )}
              onClickAdd={addProductToDoorOrder(product, doorOrderId)}
              showQuantityLabel={!product.isUnSizedProduct && product.isBasketProduct}
            />
          )
        }}
      </Observer>
    )
  }

  /**
   * Returns component used for selection product row
   * @param product: Data for selected product
   */
  getRowSelector = (product: SAListViewProduct) => {
    const {
      showroomCollection: {
        isClientUnavailable,
        onSizeQtyModalClick,
        selectedProductForSizedQty,
        updatedSelectedProduct,
        canEditOrder
      }
    } = stores

    /**
     * Refresh the selected product data. This is
     * Specifically useful to reflect updates
     * to size modal.
     */
    if (
      !isEmpty(selectedProductForSizedQty) &&
      product?.id === selectedProductForSizedQty?.id
    ) {
      updatedSelectedProduct(product)
    }
    return (
      <ListViewRowSelector
        isVisible={!product.isUnSizedProduct && product.isBasketProduct}
        isDisable={
          !product.isBooked ||
          isClientUnavailable ||
          product.isSessionClosed ||
          !canEditOrder
        }
        text={strings.sizedQuantity.toUpperCase()}
        onClick={onSizeQtyModalClick(product)}
      />
    )
  }
}

export const saCollectionListViewComponentService =
  new SACollectionListViewComponentService()
