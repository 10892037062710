import { useQuery } from '@apollo/client'
import GET_FREE_TAGS from './getFreeTags.graphql'

export default GET_FREE_TAGS

export const useGetFreeTags = (buyingSessionGroupId: string) => {
  const { data, loading } = useQuery(GET_FREE_TAGS, {
    variables: {
      buyingSessionGroupId
    }
  })

  return {
    freeTags: data?.findSpecificationItemDefinition?.Items,
    loading
  }
}
