import { StyledProps } from '@components/3rd-party/mui'
import { PrimaryButton } from '@components/UI-Components/PrimaryButton'
import { TitleBar } from '@components/UI-Components/TitleBar'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import { withStyles } from '@material-ui/core/styles'
import { stores } from '@stores'
import { observer } from 'mobx-react'
import React from 'react'

const styles = theme => ({
  tabsRoot: {
    borderBottom: '1px solid #e8e8e8'
  },
  tabsIndicator: {
    backgroundColor: theme.colors.black
  },
  tabRoot: {
    textTransform: 'initial',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    '&:hover': {
      color: theme.colors.darkgrey,
      opacity: 1
    },
    '&tabSelected': {
      color: theme.colors.black,
      fontWeight: theme.typography.fontWeightMedium
    },
    '&:focus': {
      color: theme.colors.darkgrey
    }
  }
})
interface IStoreHeader extends StyledProps {
  match?: any
  location?: any
  history?: any
}
@((withStyles as any)(styles))
@observer
export class StoreHeaderComponent extends React.Component<IStoreHeader> {
  handleChange = (event, tab) => {
    const {
      storeSettings: { onChangeSelectedTab }
    } = stores
    onChangeSelectedTab(tab)
  }
  render() {
    const {
      props: { classes },
      handleChange
    } = this
    const {
      storeImport: { toggleDialogOpen },
      storeSettings: { selectedTab, tabs }
    } = stores

    return (
      <div>
        <TitleBar
          title={'Stores'}
          actions={
            <PrimaryButton onClick={toggleDialogOpen}> Upload Stores </PrimaryButton>
          }
        />
        <div>
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
          >
            {tabs.map((tab, index) => (
              <Tab
                key={`${tab}_${index}`}
                disableRipple
                classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                label={tab}
                style={{ marginLeft: 44 }}
              />
            ))}
          </Tabs>
        </div>
      </div>
    )
  }
}
