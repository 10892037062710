import { config } from '@config'
import { uploadFile } from '@services/uploadFileService'
import { stores } from '@stores'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import React from 'react'
import { ImportBuyingSessionData } from '../component/ImportBuyingSessionData'

interface MyProps {}
@observer
export class ImportProductData extends React.Component<MyProps> {
  @observable productDataSheet = ''
  @observable productDataSheetUploadProgress
  @observable productDataSheetUploaded = false
  @observable productDataSheetUploadError = ''
  @observable productImages = null
  @observable productImagesUploadProgress = null
  @observable productImagesUploaded = false
  @observable productImagesUploadError = ''
  @observable uploadInProgress = false
  @observable uploadDone = false

  handleChanges = (file, field) => {
    this[field] = file
  }

  updateProgress = (field, progress) => {
    this[`${field}UploadProgress`] = progress
  }

  handleSubmit = async () => {
    const [
      { productDataSheetUploaded, productImagesUploaded },
      {
        buyingSession: { newlyCreateBuyingSessionId, selectedBuyingSession, isEditing }
      }
    ] = [this, stores]

    this.uploadInProgress = true
    this.uploadDone = false
    this.productDataSheetUploadError = ''
    this.productImagesUploadError = ''

    const buyingSessionId = isEditing ? selectedBuyingSession : newlyCreateBuyingSessionId

    try {
      if (!productDataSheetUploaded && this['productDataSheet']) {
        this.productDataSheetUploadProgress = true
        await this.importData(
          'productDataSheet',
          `${config.dataImporter}buyingSessions/${buyingSessionId}/products`,
          'products'
        )
        this.productDataSheetUploadProgress = false
      }

      if (!productImagesUploaded && this['productImages']) {
        this.productImagesUploadProgress = true
        await this.importData(
          'productImages',
          `${config.dataImporter}buyingSessions/${buyingSessionId}/products/images`,
          'images'
        )
        this.productImagesUploadProgress = false
      }

      // redirectToMMCLibrary()
      this.uploadInProgress = false
      this.uploadDone = true
      // handleDialogClose()
    } catch (err) {
      console.error(err)
      this.uploadInProgress = false
    }
  }

  importData = async (field, url, key) => {
    try {
      await uploadFile(url, field, key, this[field], this.updateProgress)
      this.updateProgress(field, null)
      this[`${field}Uploaded`] = true
    } catch (err) {
      console.error(err)
      this[`${field}UploadError`] = err.message
      this.updateProgress(field, null)
      throw err
    }
  }

  render() {
    return <ImportBuyingSessionData {...this} {...this.props} />
  }
}
