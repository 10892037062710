import { GET_ZONE_AND_SEASON_BY_ID } from '@components/UI-Components/Assortment/graphql'
import { ListViewColumnDefinition } from '@components/UI-Components/StandardTableView/models/ListviewColumnDefinition'
import { config } from '@config'
import { ASSOERTMENT, LIST_VIEW_PRODUCT_ATTRIBUTES } from '@constants'
import { User } from '@models'
import { ListViewAssortmentTypes } from '@modules/common/models/enums/ListViewColumnIdentifiers'
import { ListViewFieldMapping } from '@modules/common/models/enums/ListViewFieldMapping'
import { QuantityType } from '@modules/common/models/enums/QuantityType'
import { UserRoles } from '@modules/common/models/enums/UserRoles'
import { Views } from '@modules/common/models/enums/Views'
import { ZoneDisplayNames } from '@modules/common/models/enums/ZoneDisplayNames'
import { Chip, ListViewProduct } from '@modules/common/models/interfaces/ListViewProduct'
import { assortmentStringFragments } from '@modules/retail/assortment/graphql'
import { ADD_PRODUCT_TO_RM_ASSORTMENT } from '@modules/retail/assortment/graphql/addProductToRmAssortment'
import { BULK_ADD_PRODUCTS_TO_ASSORTMENT } from '@modules/retail/assortment/graphql/bulkAddProductsToAssortment'
import { BULK_ADD_PRODUCTS_TO_STORE_ASSORTMENT } from '@modules/retail/assortment/graphql/bulkAddProductsToStoreAssortment'
import { BULK_ADD_PRODUCTS_TO_ZONE_ASSORTMENT } from '@modules/retail/assortment/graphql/bulkAddProductsToZoneAssortment'
import { CLUSTER_ASSORTMENT_PRODUCTS } from '@modules/retail/assortment/graphql/clusterAssortmentProducts'
import { CLUSTER_ASSORTMENT_PRODUCTS_HIERARCHY } from '@modules/retail/assortment/graphql/clusterAssortmentProductsHierarchy'
import { GET_BET_BUY_VALUES_FOR_ACTIVITY } from '@modules/retail/assortment/graphql/getActivityBetBuyValues'
import { GET_BET_BUY_VALUES_FOR_STORE } from '@modules/retail/assortment/graphql/getBetBuyValuesForStore'
import { GET_BET_BUY_VALUES_FOR_ZONE } from '@modules/retail/assortment/graphql/getBetBuyValuesForZone'
import { REMOVE_RM_CLUSTER_FROM_PRODUCT } from '@modules/retail/assortment/graphql/removeClusterFromRMAssortment'
import { REMOVE_PRODUCT_FROM_ASSORTMENT } from '@modules/retail/assortment/graphql/removeProductFromAssortment'
import { REMOVE_PRODUCT_FROM_STORE_ASSORTMENT } from '@modules/retail/assortment/graphql/removeProductFromStoreAssortment'
import { REMOVE_PRODUCT_FROM_ZONE_ASSORTMENT } from '@modules/retail/assortment/graphql/removeProductFromZoneAssortment'
import { STORE_ASSORTMENT_PRODUCTS } from '@modules/retail/assortment/graphql/storeAssortmentProducts'
import { STORE_ASSORTMENT_PRODUCTS_HIERARCHY } from '@modules/retail/assortment/graphql/storeAssortmentProductsHierarchyKPIs'
import { ZONE_ASSORTMENT_PRODUCTS } from '@modules/retail/assortment/graphql/zoneAssortmentProducts'
import { ZONE_ASSORTMENT_PRODUCTS_HIERARCHY } from '@modules/retail/assortment/graphql/zoneAssortmentProductsHierarchyKPIs'
import { constants } from '@modules/retail/buyingSession/constants'
import { BUYING_SESSION_PRODUCT } from '@modules/retail/collection/graphql/buyingSessionProduct'
import { BUYING_SESSION_PRODUCTS_HIERARCHY_KPIS } from '@modules/retail/collection/graphql/buyingSessionProductsHierarchyKPIs'
import { GET_COLLECTION_BET_BUY_VALUES } from '@modules/retail/collection/graphql/getCollectionBetBuyValues'
import { REMOVE_FREE_TAG_FROM_PRODUCT } from '@modules/retail/collection/graphql/removeFreeTagFromProduct'
import { REMOVE_SALES_PERIOD_TAG_FROM_PRODUCT } from '@modules/retail/collection/graphql/removeSalesPeriodFromProduct'
import { REMOVE_ZONE_RESTRICTION } from '@modules/retail/collection/graphql/removeZoneRestriction'
import { UPDATE_PRODUCT_BET_QUANTITY } from '@modules/retail/collection/graphql/updateBuyingSessionProductBetQauntity'
import { UPDATE_PRODUCT_BUY_QUANTITY } from '@modules/retail/collection/graphql/updateBuyingSessionProductBuyQauntity'
import { UPDATE_STORE_PRODUCT_BET_QUANTITY } from '@modules/retail/collection/graphql/updateStoreAssortmentProductBetQauntity'
import { UPDATE_STORE_PRODUCT_BUY_QUANTITY } from '@modules/retail/collection/graphql/updateStoreAssortmentProductBuyQauntity'
import { UPDATE_ZONE_PRODUCT_BET_QUANTITY } from '@modules/retail/collection/graphql/updateZoneAssortmentProductBetQauntity'
import { UPDATE_ZONE_PRODUCT_BUY_QUANTITY } from '@modules/retail/collection/graphql/updateZoneAssortmentProductBuyQauntity'
import { ZONE_COUNTER_OFFER } from '@modules/retail/collection/graphql/zoneCounterOffer'
import { GET_RM_CLUSTERS } from '@modules/retail/navigations/graphql'
import { getPermissionsByEntityAndRole } from '@routes'
import {
  convertToCurrencyLocaleString,
  getAssortmentBetData,
  getAssortmentBuyData,
  resetFilters
} from '@services/assortmentService'
import {
  getCurrencyCode,
  getExchangeRate,
  getNumberOfWeeksFromSalesPeriod,
  getProductClustersWithStatus,
  getProductDetails,
  getSalesPeriodStartAndEndDate,
  getTagsMapForListView,
  isFlagRed
} from '@services/collectionServices'
import { buildSearchQuery } from '@services/commonServices'
import { downloadFile } from '@services/downloadFileService'
import { getImageUrl } from '@services/imageService'
import { getAttributeNameValuePair } from '@services/productDetailService'
import { storage } from '@services/storageService'
import { apolloClient, nav, stores, strings } from '@stores'
import cloneDeep from 'lodash/cloneDeep'
import filter from 'lodash/filter'
import intersectionBy from 'lodash/intersectionBy'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import map from 'lodash/map'
import some from 'lodash/some'
import memoizeOne from 'memoize-one'
import { action, computed, observable } from 'mobx'
import moment from 'moment'

export class AssortmentStore {
  @observable hasMore = true
  @observable drawerOpen = false
  @observable productList = []
  @observable reportingDrawerOpen = false
  @observable selectedClusterAssortment = ''
  @observable exportError = ''
  @observable selectedAssortmentId = null
  @observable rmZoneAssortmentDetails = []
  @observable showField = null
  @observable isOpenDeleteProductConfirmationOnLastRMClusterDeleteDialog = false
  @observable rmZoneAssortmentProductDeleteInProgress = false

  // Collection list view column headers for different tabs
  @observable listViewColumnDefinitionForDistribution: Array<ListViewColumnDefinition> =
    []
  @observable listViewColumnDefinitionForBET: Array<ListViewColumnDefinition> = []
  @observable listViewColumnDefinitionForBUY: Array<ListViewColumnDefinition> = []

  @action
  closeDeleteProductConfirmationOnLastRMClusterDeleteDialog = () => {
    Object.assign(this, {
      isOpenDeleteProductConfirmationOnLastRMClusterDeleteDialog: false
    })
  }

  @action
  openDeleteProductConfirmationOnLastRMClusterDeleteDialog = () => {
    Object.assign(this, {
      isOpenDeleteProductConfirmationOnLastRMClusterDeleteDialog: true
    })
  }

  @action
  confirmDeleteProductConfirmationOnLastRMClusterDeleteDialog = () => {
    this.removeBuyerClusterFromZoneAssortmentProduct(
      this.selectedClusterIdForDeletion,
      this.selectedProductIdForDeletion,
      this.selectedProductForDeletion
    )
  }
  /**
   * Computed map for list view column headers for different tabs
   */
  @computed get listViewColumnDefinitionMap() {
    const {
      nav: {
        params: { view }
      }
    } = stores
    if (view === Views.Kpi) {
      return {
        0: this.listViewColumnDefinitionForBET,
        1: this.listViewColumnDefinitionForBUY
      }
    } else {
      return {
        0: this.listViewColumnDefinitionForDistribution,
        1: this.listViewColumnDefinitionForBET,
        2: this.listViewColumnDefinitionForBUY
      }
    }
  }

  /**
   * Sets list view column definition for given assortment type
   * @param { string } assortmentType Assortment Type
   */
  @action updateListViewColumnDefinitionForAssortmentType = (
    assortmentType: ListViewAssortmentTypes
  ) => {
    this.listViewColumnDefinitionForDistribution = []
    this.listViewColumnDefinitionForBET = []
    this.listViewColumnDefinitionForBUY = []
  }

  @action
  updateRmZoneAssortmentDetails = zoneAssortmentDetails => {
    Object.assign(this, { rmZoneAssortmentDetails: zoneAssortmentDetails })
  }

  setSelectedAssortment = assortmentId => {
    Object.assign(this, { selectedAssortmentId: assortmentId })
  }
  @action
  dropCluster = (id, type, product, assortmentProductId) => {
    const {
      site: { isGlobalMerchandiserOrAdmin },
      sidebar: { isRegionalMerchandiser },
      product: { handleTagDrop: cmHandleTagDrop },
      assortment: { handleTagDrop },
      nav: {
        params: { assortmentType }
      }
    } = stores

    if (isRegionalMerchandiser()) {
      handleTagDrop(assortmentProductId, type, id)
    } else {
      if (isGlobalMerchandiserOrAdmin() && assortmentType !== strings.cluster) {
        cmHandleTagDrop(product, type, id)
      }
    }
  }

  @computed get selectedAssortmentTab() {
    let result = parseInt(nav.queryParams.tab || '0')

    // GBB-1281 - when switching to a cluster from a zone we need to no longer be on the split table tab
    const { selectedClusterId } = this
    if (selectedClusterId && result === 3) {
      result = 0
    }

    return result
  }

  @action
  setSelectedTab = value => {
    // RM - Confirm why this was added as markups don't change with BET / BUY?
    // const {
    //   product: { getMarkupData },
    //   nav: {
    //     params: { gender }
    //   }
    // } = stores
    // if (gender) {
    //   getMarkupData(gender)
    // }
    this.reportingDrawerOpen = false
    nav.updateQueryParams({ tab: value })
  }

  @observable selectedProductId = null
  @observable selectedClusterId = null
  @observable selectedStoreProductAssortmentId = null
  @observable quantity = null
  @observable isAddStoreClicked = false
  @observable totalQuantityCount = 0
  @observable zoneAssortmentProductWhereInput = null
  @observable bulkAdditionAPIFlag = false
  @observable selectedAssortmentSelectionTab = 'collection'

  /**
   * For deleting / removing product from assortment
   */
  @observable openDeleteConfirmationPopUp = false
  @observable selectedClusterIdForDeletion = null
  @observable selectedProductIdForDeletion = null
  @observable selectedProductForDeletion = null

  @action
  setSelectedZoneId = value => {
    nav.updateQueryParams({ zoneId: value, tab: null })
  }

  @action
  onAssortmentSelectionTabChange = (event, value) => {
    Object.assign(this, { selectedAssortmentSelectionTab: value })
  }

  @action
  getRefechQueryForRMZoneAssortment = (
    query,
    match,
    assortmentId,
    zoneAssortmentProductId,
    variablesForWhere,
    genderId,
    variableWhere,
    sortByQuery,
    zoneId
  ) => {
    const {
      assortment: { selectedAssortmentTab },
      filter: { getFlagFilter, finalFilterToApply }
    } = stores
    const assortmentType = this.getAssortmentType(match)
    const flagFilter = getFlagFilter(finalFilterToApply)
    const refetchQuery =
      assortmentType === strings.zone
        ? [
            {
              query: ZONE_ASSORTMENT_PRODUCTS_HIERARCHY,
              variables: {
                assortmentId: assortmentId,
                variablesForWhere,
                genderId,
                quantityType: selectedAssortmentTab === 2 ? 'BUY' : 'BET',
                flagFilter
              }
            },
            {
              query: query,
              variables: {
                assortmentId: assortmentId,
                variableWhere,
                sortBy: sortByQuery,
                ...(assortmentType === strings.store && { zoneId: zoneId }),
                includeZoneAssortmentProduct: assortmentType === strings.store,
                flagFilter
              }
            }
          ]
        : [
            {
              query: query,
              variables: {
                assortmentId: assortmentId,
                variableWhere,
                sortBy: sortByQuery,
                ...(assortmentType === strings.store && { zoneId: zoneId }),
                includeZoneAssortmentProduct: assortmentType === strings.store,
                flagFilter
              }
            }
          ]
    return refetchQuery
  }

  assortmentTypeMapping = {
    [strings.cluster]: {
      bulkAddProductMutation: BULK_ADD_PRODUCTS_TO_ASSORTMENT,
      removeProductMutation: REMOVE_PRODUCT_FROM_ASSORTMENT,
      assortmentQuery: CLUSTER_ASSORTMENT_PRODUCTS,
      hierarchicalKPIsQuery: CLUSTER_ASSORTMENT_PRODUCTS_HIERARCHY
    },
    [strings.zone]: {
      bulkAddProductMutation: BULK_ADD_PRODUCTS_TO_ZONE_ASSORTMENT,
      removeProductMutation: REMOVE_PRODUCT_FROM_ZONE_ASSORTMENT,
      assortmentQuery: ZONE_ASSORTMENT_PRODUCTS,
      updateBetQuantityMutation: UPDATE_ZONE_PRODUCT_BET_QUANTITY,
      updateBuyQuantityMutation: UPDATE_ZONE_PRODUCT_BUY_QUANTITY,
      hierarchicalKPIsQuery: ZONE_ASSORTMENT_PRODUCTS_HIERARCHY,
      bulkAddProductRMMutation: ADD_PRODUCT_TO_RM_ASSORTMENT,
      betBuyValuesQuery: GET_BET_BUY_VALUES_FOR_ZONE
    },
    [strings.store]: {
      bulkAddProductMutation: BULK_ADD_PRODUCTS_TO_STORE_ASSORTMENT,
      removeProductMutation: REMOVE_PRODUCT_FROM_STORE_ASSORTMENT,
      assortmentQuery: STORE_ASSORTMENT_PRODUCTS,
      updateBetQuantityMutation: UPDATE_STORE_PRODUCT_BET_QUANTITY,
      updateBuyQuantityMutation: UPDATE_STORE_PRODUCT_BUY_QUANTITY,
      hierarchicalKPIsQuery: STORE_ASSORTMENT_PRODUCTS_HIERARCHY,
      betBuyValuesQuery: GET_BET_BUY_VALUES_FOR_STORE
    },
    [strings.global]: {
      updateBetQuantityMutation: UPDATE_PRODUCT_BET_QUANTITY,
      updateBuyQuantityMutation: UPDATE_PRODUCT_BUY_QUANTITY,
      hierarchicalKPIsQuery: BUYING_SESSION_PRODUCTS_HIERARCHY_KPIS,
      betBuyValuesQuery: GET_COLLECTION_BET_BUY_VALUES
    },
    [strings.activity]: {
      betBuyValuesQuery: GET_BET_BUY_VALUES_FOR_ACTIVITY
    }
  }

  @action
  prepareAddProductSearchWhere = (
    buyingSessionId,
    assortmentId,
    assortmentType,
    searchKey,
    productWhereInput,
    coreFilters
  ) => {
    const user = storage.getItem<User>('user')
    const isRegionalMerchandiser = some(user.roles, {
      name: UserRoles.REGIONAL_MERCHANDISER
    })
    const {
      product: { attributeDefinitions },
      nav: {
        params: { clusterId },
        queryParams
      }
    } = stores

    // Base query
    let where: any = {
      AND: [
        {
          buyingSession: { id: buyingSessionId },
          ...(assortmentType !== strings.zone &&
            assortmentType !== strings.store && {
              channels_some: { name: strings.retailChannelName }
            }),
          ...(assortmentType === strings.zone && isRegionalMerchandiser && !clusterId
            ? { zoneAssortmentProducts_none: { zoneAssortment: { id: assortmentId } } }
            : assortmentType === strings.cluster
            ? { clusterAssortmentProducts_none: { assortment: { id: assortmentId } } }
            : assortmentType === strings.store
            ? { storeAssortmentProducts_none: { storeAssortment: { id: assortmentId } } }
            : {}),
          ...(isRegionalMerchandiser &&
            clusterId && {
              zoneAssortmentProducts_some: {
                buyerClusters_none: {
                  id: clusterId
                },
                zoneAssortment: {
                  id: assortmentId
                }
              }
            }),
          ...(isRegionalMerchandiser && {
            restrictedZones_every: {
              NOT: {
                id: queryParams.zoneId
              }
            }
          })
        }
      ]
    }

    // Add filter query in case filter is applied
    if (!isEmpty(productWhereInput) || !isEmpty(coreFilters)) {
      where.AND.push({ product: productWhereInput, ...coreFilters })
    }

    // Add search query in case search is applied
    if (searchKey) {
      where.AND.push({
        ...buildSearchQuery(searchKey, attributeDefinitions)
      })
    }

    return where
  }

  @action
  changeLoadingStatus = status => {
    Object.assign(this, { hasMore: status })
  }

  @action
  selectClusterAssortmentName = clusterName => {
    Object.assign(this, { selectedClusterAssortment: clusterName })
  }

  @action
  onStoreSplitClick = id => {
    const { selectedProductId } = this
    Object.assign(this, { selectedClusterId: null })
    Object.assign(this, { isAddStoreClicked: false })
    if (selectedProductId === id) {
      Object.assign(this, { selectedProductId: null })
    } else {
      Object.assign(this, { selectedProductId: id })
    }
  }

  @action
  prepareProductSearchWhere = (
    buyingSessionId,
    assortmentId,
    assortmentType,
    searchKey,
    productWhereInput,
    coreFilters
  ) => {
    const where = {
      ...(coreFilters && { ...coreFilters }),
      buyingSession: { id: buyingSessionId },
      ...(assortmentType === strings.zone
        ? { zoneAssortmentProducts_none: { zoneAssortment: { id: assortmentId } } }
        : assortmentType === strings.cluster
        ? { clusterAssortmentProducts_none: { assortment: { id: assortmentId } } }
        : assortmentType === strings.store
        ? { storeAssortmentProducts_none: { storeAssortment: { id: assortmentId } } }
        : {}),
      product: { ...productWhereInput, ...buildSearchQuery(searchKey, ASSOERTMENT) }
    }
    return where
  }

  //Todo need to improve logic.
  @action
  clearSelectedClusterAssortment = () => {
    Object.assign(this, { selectedClusterAssortment: '' })
  }

  @action
  toogleDrawer = () => {
    Object.assign(this, { drawerOpen: !this.drawerOpen })
  }

  @action
  handleChange = (event, productId) => {
    const { productList } = this
    event.target.checked
      ? productList.push(productId)
      : productList.splice(
          productList.findIndex(({ id }) => id === productId),
          1
        )
    Object.assign(this, { productList })
  }

  getAssortmentType = (match): string => {
    const {
      params: { assortmentType }
    } = match
    return assortmentType
  }

  @computed get shouldSplitBeAvailable() {
    const {
      params: { assortmentType }
    } = nav
    return assortmentType === strings.zone
  }

  @action
  toggleReportingDrawer = () => {
    Object.assign(this, {
      reportingDrawerOpen: !this.reportingDrawerOpen
    })
  }

  getZoneLevelProductValueForQuickReporting = (
    retailPrice,
    quantity,
    markUp,
    role,
    productRetailPrice
  ) => {
    const {
      site: { globalCurrencyCode }
    } = stores
    const currency =
      retailPrice && retailPrice.priceList ? retailPrice.priceList.currency : null
    const markupValue = markUp ? markUp.markup || 1 : 1
    const exchangeRate = getExchangeRate(currency)
    if (role !== UserRoles.REGIONAL_MERCHANDISER) {
      if (exchangeRate) {
        if (exchangeRate.currencyFrom.id === currency.id) {
          return (quantity * retailPrice.price * markupValue) / exchangeRate.value
        } else {
          return quantity * retailPrice.price * markupValue * exchangeRate.value
        }
      }
      if (currency && currency.code === globalCurrencyCode) {
        return quantity * retailPrice.price * markupValue
      } else {
        return productRetailPrice ? quantity * productRetailPrice.price : 0
      }
    }
    // for rm user if exchange rate is not present then consider buying session product price for quick reporting calculations.
    if (!exchangeRate) {
      if (currency && currency.code === globalCurrencyCode) {
        return retailPrice ? quantity * retailPrice.price * markupValue : 0
      }
      return productRetailPrice ? quantity * productRetailPrice.price : 0
    }
    return quantity * (retailPrice ? retailPrice.price * markupValue : 0)
  }

  getZoneReportingBuyData = (
    betBuyData: any = {},
    assortmentType,
    zoneAssortmentProductsConnection,
    rangePlans: Array<any> = [],
    role,
    markUpList,
    zone,
    activityId
  ) => {
    const { betBuyValues } = betBuyData
    let zoneActualValue = 0
    let zoneActualQty = 0
    let zoneAc = 0
    const zoneMarkUp = markUpList.find(({ description }) => description === activityId)
    const markUpValue =
      zoneMarkUp && zoneMarkUp.zones
        ? zoneMarkUp.zones.find(({ id }) => id === zone.id)
        : null
    const currency =
      markUpValue && markUpValue.priceList ? markUpValue.priceList.currency : null

    if (betBuyValues) {
      if (assortmentType === strings.store) {
        const markUp =
          zoneMarkUp && zoneMarkUp.zones
            ? zoneMarkUp.zones.find(({ id }) => id === zone.id)
            : null

        zoneAssortmentProductsConnection &&
          zoneAssortmentProductsConnection.edges &&
          zoneAssortmentProductsConnection.edges.forEach(
            ({ node: { buy, zoneRetailPrice, product } }) => {
              const productRetailPrice =
                product && product.retialPrice ? product.retialPrice : null
              if (buy && buy.quantity > 0) {
                zoneAc++
                zoneActualQty = zoneActualQty + buy.quantity
                zoneActualValue =
                  zoneActualValue +
                  this.getZoneLevelProductValueForQuickReporting(
                    zoneRetailPrice,
                    buy.quantity,
                    markUp,
                    role,
                    productRetailPrice
                  )
              }
            }
          )
      }
      if (assortmentType === strings.zone) {
        betBuyValues.forEach(product => {
          const { retailPrice: productRetailPrice } = getProductDetails(product)

          product.assortmentProducts.forEach(
            ({ buy, retailPrice, assortment: { zone } }) => {
              const markUp =
                zoneMarkUp && zoneMarkUp.zones
                  ? zoneMarkUp.zones.find(({ id }) => id === zone.id)
                  : null
              if (buy && buy.quantity > 0) {
                zoneAc++
                zoneActualQty = zoneActualQty + buy.quantity
                zoneActualValue =
                  zoneActualValue +
                  this.getZoneLevelProductValueForQuickReporting(
                    retailPrice,
                    buy.quantity,
                    markUp,
                    role,
                    productRetailPrice
                  )
              }
            }
          )
        })
      }
    }

    let { zoneOtbValue, zoneOtbQty } = rangePlans.reduce(
      (acc, curr) => {
        acc.zoneOtbValue += curr.OTB
        acc.zoneOtbQty += curr.buyingQty
        return acc
      },
      { zoneOtbValue: 0, zoneOtbQty: 0 }
    )
    const exchangeRate = getExchangeRate(currency)
    const currencyCode = getCurrencyCode(role, exchangeRate, currency)
    zoneOtbValue =
      zoneOtbValue && role === UserRoles.REGIONAL_MERCHANDISER
        ? this.getOtbValue(currency, zoneOtbValue)
        : zoneOtbValue

    return {
      zoneOtbValueWithCurrency: convertToCurrencyLocaleString(
        currencyCode,
        Math.round(zoneOtbValue),
        0
      ),
      zoneValueConsumption: zoneOtbValue
        ? ((zoneActualValue / zoneOtbValue) * 100).toFixed(2)
        : 0,
      zoneActualValueWithCurrency: convertToCurrencyLocaleString(
        currencyCode,
        Math.round(zoneActualValue),
        0
      ),
      zoneOtbQty: zoneOtbQty,
      zoneQtyConsumption: zoneOtbQty
        ? ((zoneActualQty / zoneOtbQty) * 100).toFixed(2)
        : 0,
      zoneActualQty: zoneActualQty,
      zoneAc: zoneAc,
      zoneAsp: convertToCurrencyLocaleString(
        currencyCode,
        zoneActualValue && zoneActualQty
          ? Math.round(zoneActualValue / zoneActualQty)
          : 0,
        0
      ),
      zoneAverageDepth: zoneActualQty ? Math.round(zoneActualQty / zoneAc) : 0,
      currency: currency
    }
  }

  getZoneReportingBetData = (
    betBuyData: any = {},
    assortmentType,
    zoneAssortmentProductsConnection,
    rangePlans: Array<any> = [],
    role,
    markUpList,
    zone,
    activityId
  ) => {
    const { betBuyValues } = betBuyData
    let zoneActualValue = 0
    let zoneActualQty = 0
    let zoneAc = 0
    const zoneMarkUp = markUpList.find(({ description }) => description === activityId)
    const markUpValue =
      zoneMarkUp && zoneMarkUp.zones
        ? zoneMarkUp.zones.find(({ id }) => id === zone.id)
        : null
    const currency =
      markUpValue && markUpValue.priceList ? markUpValue.priceList.currency : null

    if (betBuyValues) {
      if (assortmentType === strings.store) {
        const markUp =
          zoneMarkUp && zoneMarkUp.zones
            ? zoneMarkUp.zones.find(({ id }) => id === zone.id)
            : null

        zoneAssortmentProductsConnection &&
          zoneAssortmentProductsConnection.edges &&
          zoneAssortmentProductsConnection.edges.forEach(
            ({ node: { bet, zoneRetailPrice, product } }) => {
              const productRetailPrice =
                product && product.retialPrice ? product.retialPrice : null
              if (bet && bet.quantity > 0) {
                zoneAc++
                zoneActualQty = zoneActualQty + bet.quantity
                zoneActualValue =
                  zoneActualValue +
                  this.getZoneLevelProductValueForQuickReporting(
                    zoneRetailPrice,
                    bet.quantity,
                    markUp,
                    role,
                    productRetailPrice
                  )
              }
            }
          )
      }
      if (assortmentType === strings.zone) {
        betBuyValues.forEach(product => {
          const { retailPrice: productRetailPrice } = getProductDetails(product)

          product.assortmentProducts.forEach(
            ({ bet, retailPrice, assortment: { zone } }) => {
              const markUp =
                zoneMarkUp && zoneMarkUp.zones
                  ? zoneMarkUp.zones.find(({ id }) => id === zone.id)
                  : null
              if (bet && bet.quantity > 0) {
                zoneAc++
                zoneActualQty = zoneActualQty + bet.quantity
                zoneActualValue =
                  zoneActualValue +
                  this.getZoneLevelProductValueForQuickReporting(
                    retailPrice,
                    bet.quantity,
                    markUp,
                    role,
                    productRetailPrice
                  )
              }
            }
          )
        })
      }
    }

    let { zoneOtbValue, zoneOtbQty } = rangePlans.reduce(
      (acc, curr) => {
        acc.zoneOtbValue += curr.OTB
        acc.zoneOtbQty += curr.buyingQty
        return acc
      },
      { zoneOtbValue: 0, zoneOtbQty: 0 }
    )

    const exchangeRate = getExchangeRate(currency)
    const currencyCode = getCurrencyCode(role, exchangeRate, currency)
    zoneOtbValue =
      zoneOtbValue && role === UserRoles.REGIONAL_MERCHANDISER
        ? this.getOtbValue(currency, zoneOtbValue)
        : zoneOtbValue
    return {
      zoneOtbValueWithCurrency: convertToCurrencyLocaleString(
        currencyCode,
        Math.round(zoneOtbValue),
        0
      ),
      zoneValueConsumption: zoneOtbValue
        ? ((zoneActualValue / zoneOtbValue) * 100).toFixed(2)
        : 0,
      zoneActualValueWithCurrency: convertToCurrencyLocaleString(
        currencyCode,
        Math.round(zoneActualValue),
        0
      ),
      zoneOtbQty: zoneOtbQty,
      zoneQtyConsumption: zoneOtbQty
        ? ((zoneActualQty / zoneOtbQty) * 100).toFixed(2)
        : 0,
      zoneActualQty: zoneActualQty,
      zoneAc: zoneAc,
      zoneAsp: convertToCurrencyLocaleString(
        currencyCode,
        zoneActualValue && zoneActualQty
          ? Math.round(zoneActualValue / zoneActualQty)
          : 0,
        0
      ),
      zoneAverageDepth: zoneActualQty ? Math.round(zoneActualQty / zoneAc) : 0,
      currency: currency
    }
  }

  getOtbValue = (currency, otbValue) => {
    const exchangeRate = getExchangeRate(currency)
    if (exchangeRate) {
      if (exchangeRate.currencyTo.id === currency.id) {
        return otbValue / exchangeRate.value
      } else {
        return otbValue * exchangeRate.value
      }
    } else {
      return otbValue
    }
  }

  getStoreReportingBetData = (
    betBuyData,
    storeOTB,
    role,
    markUpList,
    zone,
    activityId
  ) => {
    const { betBuyValues } = betBuyData
    /**
     * NOTE: Returning store OTB value in central currency for now since we do not have currency
     * mapping for stores yet
     */
    let storeOtbValue = storeOTB ? storeOTB.storeOTB : 0
    let storeActualValue = 0
    let storeActualQty = 0
    let storeAc = 0
    if (betBuyValues) {
      betBuyValues.forEach(product => {
        // eslint-disable-next-line array-callback-return
        product.assortmentProducts.filter(({ bet, retailPrice }) => {
          if (bet && bet.quantity > 0) {
            storeAc++
            storeActualQty = storeActualQty + bet.quantity
            storeActualValue =
              storeActualValue + (retailPrice ? bet.quantity * retailPrice.price : 0)
          }
        })
      })
    }
    const zoneMarkUp = markUpList.find(({ description }) => description === activityId)
    const markUpValue =
      zoneMarkUp && zoneMarkUp.zones
        ? zoneMarkUp.zones.find(({ id }) => id === zone.id)
        : null
    const currency =
      markUpValue && markUpValue.priceList ? markUpValue.priceList.currency : null
    const exchangeRate = getExchangeRate(currency)
    const currencyCode = getCurrencyCode(role, exchangeRate, currency)
    storeOtbValue =
      storeOtbValue && role === UserRoles.REGIONAL_MERCHANDISER
        ? this.getOtbValue(currency, storeOtbValue)
        : storeOtbValue

    return {
      storeOtbValue: convertToCurrencyLocaleString(
        currencyCode,
        Math.round(storeOtbValue),
        0
      ),
      storeValueConsumption: storeOtbValue
        ? ((storeActualValue / storeOtbValue) * 100).toFixed(2)
        : (0).toFixed(2),
      storeActualValueWithCurrency: convertToCurrencyLocaleString(
        currencyCode,
        Math.round(storeActualValue),
        0
      ),
      storeActualQty: storeActualQty,
      storeAc: storeAc,
      storeAsp: convertToCurrencyLocaleString(
        currencyCode,
        storeActualValue && storeActualQty
          ? Math.round(storeActualValue / storeActualQty)
          : 0,
        0
      ),
      storeAverageDepth: storeActualQty ? Math.round(storeActualQty / storeAc) : 0
    }
  }

  getStoreReportingBuyData = (
    betBuyData,
    storeOTB,
    role,
    markUpList,
    zone,
    activityId
  ) => {
    const { betBuyValues } = betBuyData
    /**
     * NOTE: Returning store OTB value in central currency for now since we do not have currency
     * mapping for stores yet
     */
    let storeOtbValue = storeOTB ? storeOTB.storeOTB : 0
    let storeActualValue = 0
    let storeActualQty = 0
    let storeAc = 0
    if (betBuyValues) {
      betBuyValues.forEach(product => {
        // eslint-disable-next-line array-callback-return
        product.assortmentProducts.filter(({ buy, retailPrice }) => {
          if (buy && buy.quantity > 0) {
            storeAc++
            storeActualQty = storeActualQty + buy.quantity
            storeActualValue =
              storeActualValue + (retailPrice ? buy.quantity * retailPrice.price : 0)
          }
        })
      })
    }
    const zoneMarkUp = markUpList.find(({ description }) => description === activityId)
    const markUpValue =
      zoneMarkUp && zoneMarkUp.zones
        ? zoneMarkUp.zones.find(({ id }) => id === zone.id)
        : null
    const currency =
      markUpValue && markUpValue.priceList ? markUpValue.priceList.currency : null
    const exchangeRate = getExchangeRate(currency)
    const currencyCode = getCurrencyCode(role, exchangeRate, currency)
    storeOtbValue =
      storeOtbValue && role === UserRoles.REGIONAL_MERCHANDISER
        ? this.getOtbValue(currency, storeOtbValue)
        : storeOtbValue

    return {
      storeOtbValue: convertToCurrencyLocaleString(
        currencyCode,
        Math.round(storeOtbValue),
        0
      ),
      storeValueConsumption: storeOtbValue
        ? ((storeActualValue / storeOtbValue) * 100).toFixed(2)
        : (0).toFixed(2),
      storeActualValueWithCurrency: convertToCurrencyLocaleString(
        currencyCode,
        Math.round(storeActualValue),
        0
      ),
      storeActualQty: storeActualQty,
      storeAc: storeAc,
      storeAsp: convertToCurrencyLocaleString(
        currencyCode,
        storeActualValue && storeActualQty
          ? Math.round(storeActualValue / storeActualQty)
          : 0,
        0
      ),
      storeAverageDepth: storeActualQty ? Math.round(storeActualQty / storeAc) : 0
    }
  }

  @action
  handleClusterChange = event => {
    Object.assign(this, { selectedClusterId: event.target.value })
  }

  filterStoreData = (clusterID, storeAssortmentProductsByClusters) => {
    const cluster = storeAssortmentProductsByClusters.clusters.filter(
      ({ clusterId }) => clusterId === clusterID
    )[0]
    return cluster ? cluster.storeAssortmentProducts : []
  }

  @action
  handleStoreQtyChange = (e, id) => {
    const value = Number(e.target.value)
    if (!isNaN(value)) {
      Object.assign(this, {
        quantity: e.target.value,
        selectedStoreProductAssortmentId: id
      })
    }
  }

  @action
  selectClusterAssortment = assortmentId => {
    const selectedAssortmentId = cloneDeep(assortmentId)
    Object.assign(this, { selectedClusterId: selectedAssortmentId })
    resetFilters()
  }

  @action
  checkTotalQuantityCount = storeAssortmentProductsByClusters => {
    let totalQuantityCount = 0
    storeAssortmentProductsByClusters.clusters.forEach(({ storeAssortmentProducts }) => {
      storeAssortmentProducts.forEach(({ bet }) => {
        if (bet) {
          totalQuantityCount = totalQuantityCount + bet.quantity
        }
      })
    })
    Object.assign(this, { totalQuantityCount: totalQuantityCount })
  }

  @action
  handleAddStoreClick = () => {
    Object.assign(this, { isAddStoreClicked: !this.isAddStoreClicked })
  }

  @action
  updateExportError = errorMsg => {
    Object.assign(this, { exportError: errorMsg })
  }

  @action
  setBulkAdditionAPIFlag = (flag: boolean) => {
    Object.assign(this, { bulkAdditionAPIFlag: flag })
  }

  getRetailPrice = retailPrice => {
    return retailPrice && retailPrice.priceList && retailPrice.priceList.currency
      ? convertToCurrencyLocaleString(
          retailPrice.priceList.currency.code,
          retailPrice.price
        )
      : 'NA'
  }

  getAssortmentProductsWithStoreSplit = memoizeOne(
    (assortmentProducts: any, zoneAssoremtnetSplitData: any) => {
      const splitData = cloneDeep(zoneAssoremtnetSplitData)
      assortmentProducts &&
        assortmentProducts.forEach(assortmentProduct => {
          let totalBuyQuantityCount = 0
          let totalBetQuantityCount = 0

          const storeAssortmentProductsByClusters = splitData.find(
            ({ id }) => id === assortmentProduct.id
          )
          assortmentProduct.storeAssortmentProductsByClusters =
            storeAssortmentProductsByClusters

          //Calculate
          let clusterLevelCountList = []

          if (storeAssortmentProductsByClusters) {
            storeAssortmentProductsByClusters.clusters.forEach(
              ({ name, storeAssortmentProducts }) => {
                let clusterTotalBuyQuantityCount = 0
                let clusterTotalBetQuantityCount = 0
                storeAssortmentProducts.forEach(({ bet, buy }) => {
                  clusterTotalBuyQuantityCount =
                    clusterTotalBuyQuantityCount + (buy ? buy.quantity : null)
                  clusterTotalBetQuantityCount =
                    clusterTotalBetQuantityCount + (bet ? bet.quantity : null)
                })
                totalBuyQuantityCount =
                  totalBuyQuantityCount + clusterTotalBuyQuantityCount
                totalBetQuantityCount =
                  totalBetQuantityCount + clusterTotalBetQuantityCount
                clusterLevelCountList.push({
                  clusterName: name,
                  clusterTotalBuyQuantityCount: clusterTotalBuyQuantityCount,
                  clusterTotalBetQuantityCount: clusterTotalBetQuantityCount
                })
              }
            )
          }
          assortmentProduct.totalBuyQuantityCount = totalBuyQuantityCount
          assortmentProduct.totalBetQuantityCount = totalBetQuantityCount
          assortmentProduct.clusterLevelCountList = clusterLevelCountList
        })
      return assortmentProducts
    },
    isEqual
  )

  /* Replaced with hyperlinks */
  @action selectAssortment = ({
    id,
    value,
    type,
    seasonId,
    assortmentId,
    activityId,
    buyingSessionId,
    podiumId,
    genderId,
    additionParams
  }: {
    id: string
    value: string
    type: AssortmentType
    seasonId
    genderId
    buyingSessionId
    assortmentId
    podiumId
    activityId
    additionParams?: { id: string; name: string; type: string }
  }) => {
    const {
      nav: {
        params: { view },
        handleStoreAssortmentSelection,
        handleZoneAssortmentSelection,
        clearAllAssortmentSelections
      },
      routing: { history }
    } = stores

    let viewToNavigate = view
    if (type === 'zone') {
      // If user is requesting to load KPI view for cluster assortment
      // redirect him to list view.
      if (additionParams && additionParams.id && view === Views.Kpi) {
        viewToNavigate = strings.list
      }
      handleZoneAssortmentSelection(id)
    } else {
      handleStoreAssortmentSelection(id)
    }
    //Todo need to improve logic.
    this.selectClusterAssortmentName(value)
    if (type === 'global' || type === 'cluster') {
      clearAllAssortmentSelections()
    }

    switch (type) {
      case strings.zone:
        // eslint-disable-next-line no-lone-blocks
        {
          if (additionParams && additionParams.id) {
            history.push({
              pathname: `/collections/season/${seasonId}/gender/${genderId}/buyingSession/${buyingSessionId}/zone/assortments/${id}/${
                additionParams.type && additionParams.type
              }/${
                additionParams.id && additionParams.id
              }/podium/${podiumId}/activity/${activityId}/views/${
                viewToNavigate || 'grid'
              }`
            })
          } else {
            history.push({
              pathname: `/collections/season/${seasonId}/gender/${genderId}/buyingSession/${buyingSessionId}/zone/assortments/${id}/podium/${podiumId}/activity/${activityId}/views/${
                viewToNavigate || 'grid'
              }`,
              search: window.location.search
            })
          }
        }
        break
      case strings.store:
        // eslint-disable-next-line no-lone-blocks
        {
          history.push({
            pathname: `/collections/season/${seasonId}/gender/${genderId}/buyingSession/${buyingSessionId}/store/assortments/${id}/podium/${podiumId}/activity/${activityId}/views/${
              viewToNavigate || 'grid'
            }`,
            search: window.location.search
          })
        }
        break
      case strings.cluster: {
        history.push({
          pathname: `/collections/season/${seasonId}/gender/${genderId}/buyingSession/${buyingSessionId}/cluster/assortments/${id}/podium/${podiumId}/activity/${activityId}/views/${
            viewToNavigate || 'grid'
          }`,
          search: window.location.search
        })
      }
    }

    //clearAllFilters();
    //resetSort()
  }

  getBuyerClusters = memoizeOne((zoneAssortmentProducts: any, zoneCounterOffer: any) => {
    let buyerClusterList = {}
    const data = cloneDeep(zoneAssortmentProducts)
    zoneCounterOffer &&
      zoneCounterOffer.length &&
      zoneCounterOffer.forEach(({ name, zoneId: id }) => {
        let filterProducts = null
        if (data && data.length) {
          filterProducts = data.find(({ zoneAssortment: { zone } }) => zone.id === id)
        }

        buyerClusterList[`${name}_BuyerClusters`] =
          filterProducts && filterProducts.buyerClusters
            ? map(
                filterProducts.buyerClusters,
                (cluster): Chip => ({
                  id: cluster.id,
                  title: cluster.name,
                  color: cluster.colorHex
                })
              )
            : []
      })
    return buyerClusterList
  }, isEqual)

  urlFor = ({ id, name, type }: { id: string; name: string; type: AssortmentType }) => {
    const {
      params,
      params: { season, gender, buyingSession, podium, activity, view },
      queryParams
    } = nav

    // If user is requesting to load KPI view for cluster assortment
    // redirect him to list view.
    let viewToNavigate = view
    if (type === strings.cluster && view === Views.Kpi) {
      viewToNavigate = strings.list
    }

    let result =
      season && gender && buyingSession && id && podium && activity
        ? `/collections/season/${season}/gender/${gender}/buyingSession/${buyingSession}/${
            type === 'global' ? 'cluster' : type
          }/assortments/${id}/podium/${podium}/activity/${activity}/views/${
            viewToNavigate || 'grid'
          }${queryParams && queryParams.zoneId ? `?zoneId=${queryParams.zoneId}` : ''}` //?${qs.stringify({cluster: id})}`
        : undefined
    // switch(type) {
    //   case 'cluster':
    //     result = (season && gender && buyingSession && id && podium && activity)
    //       ? `/collections/season/${season}/gender/${gender}/buyingSession/${buyingSession}/cluster/assortments/${id}/podium/${podium}/activity/${activity}/views/${view || 'grid'}` //?${qs.stringify({cluster: id})}`
    //       : undefined
    //     break
    //   case 'zone':
    //     result = (season && gender && buyingSession && id && podium && activity)
    //              ? `/collections/season/${season}/gender/${gender}/buyingSession/${buyingSessionId}/zone/assortments/${assortmentId}/podium/${podiumId}/activity/${activityId}/views/grid`
    //              : undefined
    //     break
    //
    //   case 'store':
    //     result = `/collections/season/${season}/gender/${gender}/buyingSession/${buyingSessionId}/store/assortments/${assortmentId}/podium/${podiumId}/activity/${activityId}/views/grid`
    //     break
    //
    //   case 'global':
    //   default:
    //     result = `/collections/season/${season}/gender/${gender}/buyingSession/${buyingSessionId}/cluster/assortments/${assortmentId}/podium/${podiumId}/activity/${activityId}/views/grid`
    //
    //     //console.error(`Unhandled type ${type}`)
    //     break
    // }

    !result &&
      console.warn(`Failed to create URL for ${type}`, { id, name, type }, params)
    return result
  }

  /**
   * Assortment product card actions
   */

  @action getProductStatus = (status, isActive) => {
    let productStatus = null
    if (isActive) {
      if (status) {
        if (status !== 'NON_CORE') {
          productStatus = status
        }
      } else {
        productStatus = 'CORE'
      }
    } else {
      productStatus = 'inactive'
    }
    return productStatus
  }

  @action handleTagDrop = async (zoneAssortmentId, type, id) => {
    const user = storage.getItem<User>('user')
    const isGlobalMerchandiser = some(user.roles, {
      name: UserRoles.CENTRAL_MERCHANDISER_RETAIL
    })
    if (!isGlobalMerchandiser) {
      await apolloClient.mutate({
        mutation: ADD_PRODUCT_TO_RM_ASSORTMENT,
        variables: {
          assortmentId: id,
          productIds: [zoneAssortmentId]
        }
      })
    }
  }

  @action deleteAssortmentProductFromGridView = (selectedProductId, selectedProduct) => {
    this.openDeleteConfirmationPopUp = true
    this.selectedProductIdForDeletion = selectedProductId
    this.selectedProductForDeletion = selectedProduct
  }

  @action deleteAssortmentProductFromListView = (e, selectedProduct) => {
    this.openDeleteConfirmationPopUp = true
    this.selectedProductIdForDeletion = selectedProduct.assortmentProductId
    this.selectedProductForDeletion = selectedProduct.buyingSessionProduct
  }

  @action closeDeleteModal = () => {
    this.openDeleteConfirmationPopUp = false
    this.selectedProductId = null
  }

  /**
   * Remove product to cluster assortment
   */
  @action removeAssortmentProduct = async (
    assortmentId,
    assortmentProductId,
    buyingSessionProductId
  ) => {
    const {
      nav: {
        params: { assortmentType, buyingSession: buyingSessionId, podium: podiumId }
      },
      product: {
        getHierarchyMetaFromBuyingSessionProduct,
        buildRefetchQueriesForAttributeHierarchy,
        executeAssortmentRefetchQueries,
        pushKPIRefetchQueryForBetBuyTabsOfCollection
      }
    } = stores

    // Read buying session product fragment from cache
    const buyingSessionProduct = apolloClient.readFragment({
      id: buyingSessionProductId,
      fragment: assortmentStringFragments.buyingSessionProductWithAttributes
    })

    // build refetch query for assortment view on the basis of categories of product and assortment Type
    let refetchQueries = []
    let object = getHierarchyMetaFromBuyingSessionProduct(buyingSessionProduct)
    if (object && assortmentType) {
      refetchQueries = buildRefetchQueriesForAttributeHierarchy([object], assortmentType)
    }

    if (buyingSessionId && podiumId) {
      // Refetch ZONE_COUNTER_OFFER only if on Activity Collection page
      refetchQueries.push({
        query: ZONE_COUNTER_OFFER,
        variables: { buyingSessionId }
      })
    }

    refetchQueries = pushKPIRefetchQueryForBetBuyTabsOfCollection(refetchQueries)

    // Delete Mutation
    await apolloClient
      .mutate({
        mutation: REMOVE_PRODUCT_FROM_ASSORTMENT,
        variables: {
          assortmentId: assortmentId,
          productIds: [assortmentProductId]
        },
        refetchQueries: [
          {
            query: BUYING_SESSION_PRODUCT,
            variables: {
              id: buyingSessionProductId,
              includeUserFavourite: false,
              zoneIdWhere: null
            }
          },
          ...refetchQueries
        ]
      })
      .then(() => {
        // Refresh counts
        executeAssortmentRefetchQueries()
      })
  }

  @action removeZoneRestriction = async (zoneId, buyingSessionProductId) => {
    await apolloClient.mutate({
      mutation: REMOVE_ZONE_RESTRICTION,
      variables: {
        buyingSessionProductId,
        zoneId
      },
      refetchQueries: [
        {
          query: BUYING_SESSION_PRODUCT,
          variables: {
            id: buyingSessionProductId,
            includeUserFavourite: false
          }
        }
      ]
    })
  }

  /**
   * Removes products Sales Period
   */
  @action removeSalesPeriodFromProduct = async buyingSessionProductId => {
    const userId = storage.getItem<User>('user').id

    await apolloClient.mutate({
      mutation: REMOVE_SALES_PERIOD_TAG_FROM_PRODUCT,
      variables: {
        buyingSessionProductId,
        userId
      }
    })
  }

  /**
   * Remove Free Tag from Product
   */
  @action removeFreeTag = async (buyingSessionProductId, freeTagId) => {
    await apolloClient.mutate({
      mutation: REMOVE_FREE_TAG_FROM_PRODUCT,
      variables: { buyingSessionProductId, freeTagId }
    })
  }

  @action
  setProductDetailsForRMLastClusterDelete = (
    clusterId,
    zoneAssortmentProductId,
    buyingSessionProduct
  ) => {
    Object.assign(this, {
      selectedClusterIdForDeletion: clusterId,
      selectedProductIdForDeletion: zoneAssortmentProductId,
      selectedProductForDeletion: buyingSessionProduct
    })
  }
  @action removeBuyerClusterFromZoneAssortmentProduct = async (
    clusterId,
    zoneAssortmentProductId,
    buyingSessionProduct,
    existingBuyerClusters = []
  ) => {
    let refetchQueries = []

    const associatedBuyerClustersOnCurrentZAP =
      await this.getAssociatedBuyerClustersOnCurrentZAP(existingBuyerClusters)
    const existingBuyerClustersAfterRemoval = filter(
      associatedBuyerClustersOnCurrentZAP,
      cluster => cluster.id !== clusterId
    )
    if (
      existingBuyerClustersAfterRemoval.length === 0 &&
      buyingSessionProduct &&
      !this.isOpenDeleteProductConfirmationOnLastRMClusterDeleteDialog
    ) {
      this.setProductDetailsForRMLastClusterDelete(
        clusterId,
        zoneAssortmentProductId,
        buyingSessionProduct
      )
      this.openDeleteProductConfirmationOnLastRMClusterDeleteDialog()
      return null
    }
    if (existingBuyerClustersAfterRemoval.length === 0 && buyingSessionProduct) {
      const {
        product: {
          getHierarchyMetaFromBuyingSessionProduct,
          buildRefetchQueriesForAttributeHierarchy
        },
        nav: {
          params: { assortmentType }
        }
      } = stores
      let object = getHierarchyMetaFromBuyingSessionProduct(buyingSessionProduct)

      if (object) {
        refetchQueries = buildRefetchQueriesForAttributeHierarchy(
          [object],
          assortmentType
        )
      }
    }
    try {
      this.rmZoneAssortmentProductDeleteInProgress = true
      await apolloClient
        .mutate({
          mutation: REMOVE_RM_CLUSTER_FROM_PRODUCT,
          variables: {
            clusterId: clusterId,
            productId: zoneAssortmentProductId
          },
          refetchQueries
        })
        .then(() => {
          // Refetch counts only in case products are being refetched
          if (refetchQueries.length) {
            stores.product.executeAssortmentRefetchQueries()
          }
        })
    } finally {
      Object.assign(this, {
        rmZoneAssortmentProductDeleteInProgress: false
      })
      this.closeDeleteProductConfirmationOnLastRMClusterDeleteDialog()
    }
  }

  async getAssociatedBuyerClustersOnCurrentZAP(existingBuyerClusters) {
    const {
      nav: {
        params: { assortment: assortmentId }
      }
    } = stores

    const associatedClustersData = await apolloClient.query({
      query: GET_RM_CLUSTERS,
      variables: {
        zoneAssortmentId: assortmentId
      },
      fetchPolicy: 'cache-first'
    })

    return associatedClustersData &&
      associatedClustersData.data &&
      associatedClustersData.data.clusters
      ? intersectionBy(
          associatedClustersData.data.clusters,
          existingBuyerClusters,
          (cluster: any) => cluster.id
        )
      : []
  }

  /**
   * isClusterAssortment
   * Returns true if  its a cluster recommendation
   * or cluster assortment
   */
  isClusterView = (): boolean => {
    const {
      sidebar: { isRegionalMerchandiser },
      nav: {
        params: { assortmentType, clusterId }
      }
    } = stores

    return !!(assortmentType === strings.cluster || (isRegionalMerchandiser && clusterId))
  }

  @action onViewClick = (view: Views) => {
    const {
      nav: {
        history,
        params: { season, gender, podium, clusterId, activity, assortment, buyingSession }
      },
      sidebar: { isRegionalMerchandiser }
    } = stores

    if (view === Views.Kpi) {
      this.setSelectedTab(0)
    }

    //zone assortment
    if (history.location.pathname.includes('zone')) {
      if (isRegionalMerchandiser() && clusterId) {
        history.push({
          pathname: `/collections/season/${season}/gender/${gender}/buyingSession/${buyingSession}/zone/assortments/${assortment}/cluster/${clusterId}/podium/${podium}/activity/${activity}/views/${view}`,
          search: history.location.search
        })
      } else {
        history.push({
          pathname: `/collections/season/${season}/gender/${gender}/buyingSession/${buyingSession}/zone/assortments/${assortment}/podium/${podium}/activity/${activity}/views/${view}`,
          search: history.location.search
        })
      }
    }

    //Store assortment
    if (history.location.pathname.includes('store')) {
      history.push({
        pathname: `/collections/season/${season}/gender/${gender}/buyingSession/${buyingSession}/store/assortments/${assortment}/podium/${podium}/activity/${activity}/views/${view}`,
        search: history.location.search
      })
    }

    //Cluster Assortment
    if (!isRegionalMerchandiser() && history.location.pathname.includes('cluster')) {
      history.push({
        pathname: `/collections/season/${season}/gender/${gender}/buyingSession/${buyingSession}/cluster/assortments/${assortment}/podium/${podium}/activity/${activity}/views/${view}`,
        search: history.location.search
      })
    }
  }

  @action showProductDetail = (e, product) => {
    const {
      history,
      location,
      params: { detail }
    } = nav
    let newUrl = location.pathname
    if (detail !== product.id) {
      newUrl = location.pathname.replace(
        `/details/${detail}`,
        `/details/${product.buyingSessionProductId}`
      )
    }
    if (!detail) {
      newUrl = `${location.pathname}/details/${product.buyingSessionProductId}`
    }
    history.push({ pathname: newUrl, search: history.location.search })
  }

  @action onExcelExportClick = async () => {
    const {
      nav: {
        params: { assortment, season }
      }
    } = stores

    const seasonAndZoneData = await apolloClient.query({
      query: GET_ZONE_AND_SEASON_BY_ID,
      variables: { assortmentId: assortment, seasonId: season },
      fetchPolicy: 'network-only'
    })
    const data = seasonAndZoneData && seasonAndZoneData.data && seasonAndZoneData.data

    const zoneName =
      data.zoneAssortment && data.zoneAssortment.zone ? data.zoneAssortment.zone.name : ''
    const zoneDisplayName = ZoneDisplayNames[zoneName] || zoneName

    const fileName = `${
      data && data.season && data.zoneAssortment
        ? data.season.description + '_' + zoneDisplayName
        : 'ZONE'
    }_ASSORTMENT_${moment(new Date()).format('DD/MM/YYYY')}.xlsx`

    const downloadResponse = await downloadFile(
      `${config.dataImporter}assortment/zoneAssortment/${assortment}/export`,
      fileName
    )

    if (downloadResponse.error && downloadResponse.message) {
      this.updateExportError(downloadResponse.message)
    }
  }

  getProcessedAssortmentProductsForListView = ({
    assortmentProducts,
    buyingSession,
    permissions,
    betBuyData,
    rangePlanData,
    zone,
    selectedAssortmentTab
  }) => {
    const {
      product: { getProductStatus, markUpList },
      site: { isGlobalMerchandiserOrAdmin },
      nav: {
        params: { assortmentType, clusterId }
      },
      sidebar: { isRegionalMerchandiser },
      listView: { getAssortmentBuyMetricsForSort }
    } = stores

    const isGMOrAdmin = isGlobalMerchandiserOrAdmin()
    const betPermissions = getPermissionsByEntityAndRole(QuantityType.BET.toLowerCase())
    const buyPermissions = getPermissionsByEntityAndRole(QuantityType.BUY.toLowerCase())
    const bsIsClosedOrFrozen = buyingSession
      ? buyingSession.status === constants.CLOSED ||
        (isRegionalMerchandiser() && buyingSession.status === constants.FROZEN)
      : false
    return map(assortmentProducts, assortmentProduct => {
      const {
        product: buyingSessionProduct,
        retailPrice: assortmentRetailPrice,
        storeCount: assortmentStoreCount
      } = assortmentProduct

      let {
        productStatus,
        clusterStatuses,
        active,
        description,
        channels,
        freeTags,
        image,
        salesPeriod,
        zoneAssortmentProducts,
        retailPrice, // Central Price
        storeCount
      } = getProductDetails(buyingSessionProduct, false, null)

      const canDelete =
        permissions &&
        permissions.canEdit &&
        !bsIsClosedOrFrozen &&
        productStatus !== strings.coreStatus
      const canEditTags = isGMOrAdmin && !bsIsClosedOrFrozen
      const canEditCmCluster = canEditTags && productStatus !== strings.coreStatus
      const canEditBuyerClusters =
        permissions.canEdit &&
        !bsIsClosedOrFrozen &&
        assortmentType === strings.zone &&
        !clusterId &&
        productStatus !== strings.coreStatus

      const cmClusters =
        buyingSessionProduct && buyingSessionProduct.assortmentProducts
          ? getProductClustersWithStatus(
              buyingSessionProduct.assortmentProducts,
              clusterStatuses,
              true
            )
          : []

      const buyerClusters =
        assortmentType === strings.store
          ? zoneAssortmentProducts.length
            ? zoneAssortmentProducts[0].buyerClusters
            : []
          : assortmentProduct.buyerClusters || []

      const hasCounterOffer =
        (assortmentType === strings.store || assortmentType === strings.zone) &&
        buyingSession &&
        buyingSession.status !== strings.buyingSessionPreBuying
          ? isFlagRed(cmClusters, buyerClusters)
          : false

      const priceListValue = isGMOrAdmin ? retailPrice : assortmentRetailPrice

      let zoneWiseData
      let buySortMetric = {}

      /**
       * Zone wise assortment data should be present
       * for all list view tabs except cluster view
       */
      if (!this.isClusterView()) {
        zoneWiseData =
          selectedAssortmentTab === 1
            ? getAssortmentBetData(
                betBuyData,
                assortmentProduct,
                markUpList,
                zone,
                rangePlanData
              )
            : getAssortmentBuyData(
                betBuyData,
                assortmentProduct,
                markUpList,
                zone,
                rangePlanData
              )
        buySortMetric = getAssortmentBuyMetricsForSort(zoneWiseData, assortmentType)
      }

      const listViewProduct: ListViewProduct = {
        id: assortmentProduct.id,
        buyingSessionProductId: buyingSessionProduct.id,
        assortmentProductId: assortmentProduct.id,
        hasGlobalRow: false,
        highlightGlobalQty: false,
        isActive: active,
        hasCounterOffer,
        buyingSessionProduct,
        canEditKPIs:
          (selectedAssortmentTab === 1 &&
            betPermissions &&
            betPermissions.canEdit &&
            !bsIsClosedOrFrozen &&
            active) ||
          (selectedAssortmentTab === 2 &&
            buyPermissions &&
            buyPermissions.canEdit &&
            !bsIsClosedOrFrozen &&
            active),
        canEditOrderQuantity:
          (selectedAssortmentTab === 1 &&
            betPermissions &&
            betPermissions.canEdit &&
            !bsIsClosedOrFrozen &&
            active) ||
          (selectedAssortmentTab === 2 &&
            buyPermissions &&
            buyPermissions.canEdit &&
            !bsIsClosedOrFrozen),
        [ListViewFieldMapping.ProductInfo]: {
          status: getProductStatus(productStatus, active), // Status to be displayed
          description,
          image: getImageUrl(image)
        },
        [ListViewFieldMapping.ProductAttributes]: map(
          LIST_VIEW_PRODUCT_ATTRIBUTES,
          attributeName =>
            getAttributeNameValuePair(
              buyingSessionProduct.product.attributes,
              attributeName
            )
        ),
        canDelete,
        [ListViewFieldMapping.Price]:
          assortmentType === strings.store ? 'NA' : this.getRetailPrice(priceListValue),
        [ListViewFieldMapping.TotalWeeks]: getNumberOfWeeksFromSalesPeriod(salesPeriod),
        /**
         * NOTE: In case of cluster assortment, we show buying session product store count otherwise we pick store count
         * from assortment product
         */
        [ListViewFieldMapping.TotalStores]:
          assortmentType === strings.cluster
            ? storeCount
            : assortmentType === strings.store
            ? 1
            : assortmentStoreCount,
        [ListViewFieldMapping.CMClusters]: cmClusters,
        [`canDelete${ListViewFieldMapping.CMClusters}`]: canEditCmCluster,
        [`canEdit${ListViewFieldMapping.CMClusters}`]: canEditCmCluster,
        [ListViewFieldMapping.Channels]: getTagsMapForListView(channels),
        [`canDelete${ListViewFieldMapping.Channels}`]: false,
        [`canEdit${ListViewFieldMapping.Channels}`]: false,
        [ListViewFieldMapping.FreeTags]: getTagsMapForListView(freeTags),
        [`canDelete${ListViewFieldMapping.FreeTags}`]: canEditTags,
        [`canEdit${ListViewFieldMapping.FreeTags}`]: false,
        [ListViewFieldMapping.SalesPeriod]: salesPeriod
          ? [
              {
                id: salesPeriod.id,
                title: salesPeriod.name,
                tooltip: getSalesPeriodStartAndEndDate(salesPeriod)
              }
            ]
          : [],
        [`canDelete${ListViewFieldMapping.SalesPeriod}`]: canEditTags,
        [`canEdit${ListViewFieldMapping.SalesPeriod}`]: false,
        [ListViewFieldMapping.BuyerClusters]: map(
          buyerClusters,
          (cluster): Chip => ({
            id: cluster.id,
            title: cluster.name,
            color: cluster.colorHex
          })
        ),
        [`canDelete${ListViewFieldMapping.BuyerClusters}`]: canEditBuyerClusters,
        [`canEdit${ListViewFieldMapping.BuyerClusters}`]: false,
        [ListViewFieldMapping.zoneWiseData]: zoneWiseData,
        ...buySortMetric
      }

      return listViewProduct
    }) as Array<ListViewProduct>
  }
}

export type AssortmentType = 'zone' | 'store' | 'global' | 'cluster'

export const assortment = new AssortmentStore()
