/**
 * This service will be responsible for managing local storage actions. The
 * idea is to have separate storageService so that we can use implement it
 * whichever way we want without changing the code (consumer) which is using it.
 *
 * In future we might not use 'lockr' module and can use something else or use
 * localStorage API's directly. I fwe do so we want to have all changes done at
 * one single place.
 */
import * as lockr from 'lockr'
;(lockr as any).prefix = 'Buying-Board-'

export class StorageService {
  /**
   * Sets new key valur pair in local storage
   *
   * @param {string} key
   * @param {string} value
   */
  setItem = (key: string, value) => {
    lockr.set(key, value)
  }

  /**
   * Returns value stored against provided key in localStorage
   *
   * @param {string} key
   * @returns {string}
   */
  getItem<T>(key: string) {
    return lockr.get(key) as T
  }

  /**
   * Removes an item stored against provided key.
   *
   * @param {string} key
   */
  deleteItem = (key: string) => {
    lockr.rm(key)
  }

  /**
   * Clears all the value stored in localStorage
   */
  clearStorage = () => {
    lockr.flush()
  }
}

export const storage = new StorageService()
