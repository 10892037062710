import { nav } from '@stores/nav'
import { strings } from '@stores'
import axios from 'axios'
import has from 'lodash/has'
import isString from 'lodash/isString'
import { action, observable } from 'mobx'
import { toast } from 'react-toastify'

export class AxiosStore {
  @observable isLoading = false
  @observable isVisible = true

  constructor() {
    this.setAxiosInterceptors()
  }

  @action setLoadState = isLoading => {
    this.isLoading = isLoading
  }

  @action setErrToastVisibility = isVisible => {
    this.isVisible = isVisible
  }

  @action setAxiosInterceptors = () => {
    if (axios && !(axios.interceptors.response as any).handlers.length) {
      axios.interceptors.request.use(
        config => {
          this.setLoadState(true)
          return config
        },
        error => {
          this.setLoadState(false)
          this.handleError(error, error?.response?.status, !axios.isCancel(error))
          console.log('err', error)
          return Promise.reject(error)
        }
      )

      axios.interceptors.response.use(
        (res: any) => {
          this.setLoadState(false)
          if (
            has(res?.data, 'Status') &&
            (res?.data?.Status ?? '') !== strings.centricCallSuccess
          ) {
            this.handleError(res.data.ErrorAdmin)
            return Promise.reject(res)
          }
          return res
        },
        err => {
          console.log('err1', { err })
          this.handleError(
            err?.response?.data?.message || strings.genericError,
            err?.response?.status,
            !axios.isCancel(err)
          )
          this.setLoadState(false)
          return Promise.reject(err)
        }
      )
    }
  }

  handleError = (err, statusCode?, showToast = true) => {
    if (
      nav.location.pathname !== '/login' &&
      (err === strings.c8GraphQLSessionExpiredError || statusCode === 401)
    ) {
      nav.history.push('/logout')
      return
    }
    /**
     * NOTE: Axios is throwing error even on successful request due to some reason and therefore,
     * had to add check for status code
     *
     * Open github issue: https://github.com/axios/axios/issues/1178
     */

    if (showToast && err && this.isVisible) {
      toast.error(isString(err) ? err : strings.genericError, {
        position: toast.POSITION.BOTTOM_RIGHT,
        bodyClassName: 'error-toastr',
        progressClassName: 'error-progress-bar-toaster'
      })
    }
  }
}
