import { storage } from '@services/storageService'
import axios from 'axios'
import { config } from '@config'
import { RestServiceEndpoints } from '@constants'

export const ProductForecastKPIsService = {
  getProductForecastKPIs: async (buyingSessionGroupId, buyingSessionId, clientId) => {
    try {
      const url = `${config.buyingBoardServiceRestEndpoint}${RestServiceEndpoints.FORECAST_KPIS}/buyingSessionGroupId/${buyingSessionGroupId}`
      const accessToken: string = storage.getItem('access_token')
      const response: any = await axios.get(url, {
        params: {
          buyingSessionId,
          clientId
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      return response?.data
    } catch (e) {
      console.log(e)
    }
  },
  /**
   * Method for fetching buy KPIs for zone assortment for buying board view
   * @param buyingSessionGroupId - Buying session group id
   * @param buyingSessionId - Buying session id
   * @param clientId - Client id
   * @returns Promise with buy KPI API response
   */
  getBuyKPIsForRetail: async (
    buyingSessionGroupId: string,
    buyingSessionId: string,
    clientId?: string,
    doorId?: string
  ) => {
    try {
      const url = doorId
        ? `${config.buyingBoardServiceRestEndpoint}${RestServiceEndpoints.BUY_KPIS}/buyingSessionGroupId/${buyingSessionGroupId}/buyingSession/${buyingSessionId}/door/${doorId}`
        : clientId
        ? `${config.buyingBoardServiceRestEndpoint}${RestServiceEndpoints.BUY_KPIS}/buyingSessionGroupId/${buyingSessionGroupId}/buyingSession/${buyingSessionId}/client/${clientId}`
        : `${config.buyingBoardServiceRestEndpoint}${RestServiceEndpoints.BUY_KPIS}/buyingSessionGroupId/${buyingSessionGroupId}`
      const accessToken: string = storage.getItem('access_token')
      const response: any = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params: {
          ...(clientId || doorId
            ? null
            : {
                buyingSessionId
              })
        }
      })
      return response?.data
    } catch (e) {
      console.log(e)
    }
  }
}
