import { SelectField } from '@components/UI-Components/SelectField'
import { UserRoles } from '@modules/common/models/enums/UserRoles'
import { constants } from '@modules/retail/buyingSession/constants'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import { product, strings } from '@stores'
import { useObserver } from 'mobx-react-lite'
import React from 'react'
import styled from 'styled-components'

interface GetBuyingSessionProductAttributesDefinitionProps {
  productAttributes: any
  active: boolean
  role: any
  buyingSession: any
  buyingSessionProductAttributes: any
  buyingSessionProductId: string
  buyingSessionProductAttributesDefinitions: any
  loading: boolean
}

const BuyingSessionProductSizeAttributeContainer = styled.div({
  display: 'flex'
})

const StyledFormControl = styled(({ ...props }) => <FormControl {...props} />)(props => {
  return {
    '&&': {
      paddingRight: 5,
      height: 40
    }
  }
})

const defaultValue = (buyingSessionProductAttributes, attributeDefId) => {
  const { sizeFrom, sizeTo } = product
  const { buyingSessionProductSizeAttribute } = strings
  const selectedAttribute = buyingSessionProductAttributes.find(
    attributeDef => attributeDef.definition.id === attributeDefId
  )
  if (
    selectedAttribute &&
    selectedAttribute.definition.name === buyingSessionProductSizeAttribute.sizeFrom
  ) {
    return sizeFrom.value ? sizeFrom.value : selectedAttribute.strVal
  } else if (
    selectedAttribute &&
    selectedAttribute.definition.name === buyingSessionProductSizeAttribute.sizeTo
  ) {
    return sizeTo.value ? sizeTo.value : selectedAttribute.strVal
  } else {
    return ''
  }
}
export const BuyingSessionProductSizeAttribute: React.FunctionComponent<GetBuyingSessionProductAttributesDefinitionProps> =
  ({
    productAttributes,
    active,
    role,
    buyingSession,
    buyingSessionProductAttributes,
    buyingSessionProductId,
    buyingSessionProductAttributesDefinitions,
    loading
  }) => {
    return useObserver(function useHook() {
      const { onSizeChange, getBuyingSessionProductSizeAttributes } = product
      const buyingSessionSizeObj =
        !loading &&
        getBuyingSessionProductSizeAttributes(
          buyingSessionProductAttributesDefinitions,
          productAttributes,
          buyingSessionProductAttributes
        )
      return (
        <React.Fragment>
          {!loading && (
            <BuyingSessionProductSizeAttributeContainer>
              {buyingSessionSizeObj.map(attribute => {
                return (
                  <StyledFormControl
                    key={attribute.id}
                    fullWidth
                    variant='outlined'
                    margin='dense'
                  >
                    <InputLabel>{attribute.displayName.singular}</InputLabel>
                    <SelectField
                      input={
                        <OutlinedInput
                          name='ProductStatus'
                          labelWidth={105}
                          id='outlined-simple'
                        />
                      }
                      options={attribute.options}
                      value={defaultValue(buyingSessionProductAttributes, attribute.id)}
                      disabled={
                        !active ||
                        role === UserRoles.REGIONAL_MERCHANDISER ||
                        (buyingSession && buyingSession.status === constants.CLOSED) ||
                        !attribute.options.length ||
                        !attribute.options[0].id
                      }
                      onChange={e =>
                        onSizeChange(
                          e.target.value,
                          attribute.name,
                          attribute.id,
                          buyingSessionProductId
                        )
                      }
                    />
                  </StyledFormControl>
                )
              })}
            </BuyingSessionProductSizeAttributeContainer>
          )}
        </React.Fragment>
      )
    })
  }
