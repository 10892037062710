import { MutationFunctionOptions } from '@apollo/client/react/types/types'
import { CentricNodeType } from '@modules/common/models/enums/CentricNodeType'
import { getUTCRepresentation, isDistinct } from '@services/commonServices'
import { stores } from '@stores'
import { ExecutionResult } from 'graphql/execution'
import cloneDeep from 'lodash/cloneDeep'
import { action, computed, observable } from 'mobx'

import {
  GET_DELIVERY_WINDOW,
  GET_DELIVERY_WINDOW_FOR_BUYING_SESSION_GROUP,
  IDeliveryWindow
} from '../graphql'

export class DeliveryWindowSettingStore {
  NEW_ROW_ID = 'newRow'

  @observable deliveryWindowName = ''
  @observable deliveryWindowId = null
  @observable deliveryWindowStartDate = null
  @observable deliveryWindowCancelDate = null
  @observable deliveryWindowData = []
  @observable deliveryWindowsBeingSaved = new Map()
  @observable deletedDeliveryWindow = new Map()
  @observable isNameUnique = false

  mutations: {
    createDeliveryWindow?: (
      options?: MutationFunctionOptions<any, IDeliveryWindow>
    ) => Promise<void | ExecutionResult>
    updateDeliveryWindow?: (
      options?: MutationFunctionOptions<any, IDeliveryWindow>
    ) => Promise<void | ExecutionResult>
    deleteDeliveryWindow?: (
      options?: MutationFunctionOptions<any, IDeliveryWindow>
    ) => Promise<void | ExecutionResult>
    setDeliveryWindow?: (
      options?: MutationFunctionOptions<any, IDeliveryWindow>
    ) => Promise<void | ExecutionResult>
  } = {}

  @action addNewRecordToDeliverWindowData = () => {
    this.resetdata()
    this.deliveryWindowData.push({
      Id: this.NEW_ROW_ID
    })
    this.deliveryWindowId = this.NEW_ROW_ID
    this.deliveryWindowName = ''
    this.handleDeliveryWindowStartDateChange(new Date())
    this.handleDeliveryWindowCancelDateChange(this.getMinDate())
  }

  @action setSelectedDeliveryWindow = row => {
    this.deliveryWindowData = []
    this.deliveryWindowId = row.Id
    this.deliveryWindowName = row.Name
    this.deliveryWindowStartDate = row.Start
    this.deliveryWindowCancelDate = row.End
  }

  @action handleDeliveryWindowNameChange = existingDeliveryWindows => e =>
    Object.assign(this, {
      deliveryWindowName: e.target.value,
      isNameUnique: isDistinct(e, existingDeliveryWindows)
    })

  @action handleDeliveryWindowStartDateChange = date => {
    this.deliveryWindowStartDate = getUTCRepresentation(date)
  }

  @action handleDeliveryWindowCancelDateChange = date => {
    this.deliveryWindowCancelDate = getUTCRepresentation(date)
  }

  @computed get deliveryWindowWhereInput() {
    const {
      nav: { buyingSessionGroupId }
    } = stores
    return buyingSessionGroupId
      ? {
          buyingSessionGroupId: buyingSessionGroupId
        }
      : null
  }

  @computed get createDeliverWindowWhereInput() {
    const [
      {
        nav: { buyingSessionGroupId }
      },
      { deliveryWindowName, deliveryWindowStartDate, deliveryWindowCancelDate }
    ] = [stores, this]
    return {
      buyingSessionGroupId: buyingSessionGroupId,
      name: deliveryWindowName.trim(),
      startDate: deliveryWindowStartDate,
      endDate: deliveryWindowCancelDate
    }
  }

  @computed get editDeliverWindowWhereInput() {
    const { deliveryWindowStartDate, deliveryWindowCancelDate, deliveryWindowId } = this
    return {
      deliveryWindowId: deliveryWindowId,
      startDate: deliveryWindowStartDate,
      endDate: deliveryWindowCancelDate
    }
  }

  getDeliveryWindowWhereInput = Id => {
    return {
      deliveryWindowId: Id
    }
  }

  getDefaultDeliveryWindowWhereInput = Id => {
    const {
      nav: { buyingSessionGroupId }
    } = stores
    return {
      buyingSessionGroupId: buyingSessionGroupId,
      deliveryWindowId: Id
    }
  }

  @action
  createDeliveryWindow = async Items => {
    const [
      {
        nav: { buyingSessionGroupId }
      },
      { createDeliverWindowWhereInput, deliveryWindowWhereInput }
    ] = [stores, this]
    this.deliveryWindowsBeingSaved[this.deliveryWindowId || this.NEW_ROW_ID] = true
    if (buyingSessionGroupId) {
      try {
        await this.mutations.createDeliveryWindow({
          variables: createDeliverWindowWhereInput,
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: GET_DELIVERY_WINDOW,
              variables: deliveryWindowWhereInput
            },
            {
              query: GET_DELIVERY_WINDOW_FOR_BUYING_SESSION_GROUP,
              variables: deliveryWindowWhereInput
            }
          ]
        })
      } finally {
        this.resetdata()
      }
    }
  }

  @action
  editDeliveryWindow = async () => {
    const [
      {
        nav: { buyingSessionGroupId }
      },
      {
        editDeliverWindowWhereInput,
        deliveryWindowWhereInput,
        deliveryWindowId,
        deliveryWindowStartDate,
        deliveryWindowCancelDate,
        deliveryWindowName
      }
    ] = [stores, this]
    this.deliveryWindowsBeingSaved[deliveryWindowId] = true
    if (buyingSessionGroupId) {
      try {
        await this.mutations.updateDeliveryWindow({
          variables: editDeliverWindowWhereInput,
          // awaitRefetchQueries: true,
          // To do - Need to check exact solution for this,
          update: cache => {
            const { findVIPDeliveryWindow } = cloneDeep(
              cache.readQuery({
                query: GET_DELIVERY_WINDOW,
                variables: deliveryWindowWhereInput
              })
            )
            if (findVIPDeliveryWindow) {
              const Items = findVIPDeliveryWindow?.Items ?? []

              const updatedDeliveryWindowIndex = Items.findIndex(
                ({ Id }) => Id === deliveryWindowId
              )
              const updateDeilveryWindow = {
                Id: deliveryWindowId,
                Name: deliveryWindowName,
                Start: deliveryWindowStartDate,
                End: deliveryWindowCancelDate,
                __typename: CentricNodeType.DeliveryWindow
              }
              findVIPDeliveryWindow.Items[updatedDeliveryWindowIndex] =
                updateDeilveryWindow
              cache.writeQuery({
                query: GET_DELIVERY_WINDOW,
                data: { findVIPDeliveryWindow: findVIPDeliveryWindow }
              })
            }
          }
        })
      } finally {
        this.resetdata()
      }
    }
  }

  @action
  deleteDeliveryWindow = async Id => {
    const [
      {
        nav: { buyingSessionGroupId }
      },
      { deliveryWindowWhereInput, getDeliveryWindowWhereInput }
    ] = [stores, this]
    if (Id === this.NEW_ROW_ID) {
      this.resetdata()
    } else {
      this.deletedDeliveryWindow.set(Id, true)
      if (buyingSessionGroupId) {
        try {
          await this.mutations.deleteDeliveryWindow({
            variables: getDeliveryWindowWhereInput(Id),
            awaitRefetchQueries: true,
            refetchQueries: [
              {
                query: GET_DELIVERY_WINDOW,
                variables: deliveryWindowWhereInput
              }
            ]
          })
        } finally {
          this.deletedDeliveryWindow.delete(Id)
          this.resetdata()
        }
      }
    }
  }

  @action
  setDefaultDeliveryWindow = async Id => {
    const [
      {
        nav: { buyingSessionGroupId }
      },
      { deliveryWindowWhereInput, getDefaultDeliveryWindowWhereInput }
    ] = [stores, this]
    if (buyingSessionGroupId) {
      await this.mutations.setDeliveryWindow({
        variables: getDefaultDeliveryWindowWhereInput(Id),
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GET_DELIVERY_WINDOW_FOR_BUYING_SESSION_GROUP,
            variables: deliveryWindowWhereInput
          }
        ]
      })
    }
  }

  isDeleteInProgress = Id => {
    const product = this.deletedDeliveryWindow.get(Id)
    return product ? true : false
  }

  getMinDate = (date = new Date()) => {
    const minDate = new Date(new Date(date).setDate(new Date(date).getDate() + 1))
    return minDate
  }

  getDateDiff = (startDate, endDate) => {
    return Math.round(
      (new Date(endDate).getTime() - new Date(startDate).getTime()) /
        (24 * 60 * 60 * 1000)
    )
  }

  disableActionButtons = rowId =>
    this.deliveryWindowsBeingSaved[rowId] || this.isDeleteInProgress(rowId)

  resetdata = () => {
    this.deliveryWindowId = null
    this.deliveryWindowName = ''
    this.deliveryWindowStartDate = null
    this.deliveryWindowCancelDate = null
    this.deliveryWindowData = []
    this.deliveryWindowsBeingSaved = new Map()
  }
}

export const deliveryWindowSetting = new DeliveryWindowSettingStore()
