import { useMutation } from '@apollo/client/react/hooks/useMutation'
import { useQuery } from '@apollo/client/react/hooks/useQuery'
import { stores } from '@stores'
import { CREATE_DROP, DELETE_DROP, GET_DROP, UPDATE_DROP } from '.'

export interface IDrop {
  dropId?: string
  name?: string
  startDate?: string
  endDate?: string
  buyingSessionGroupId?: string
}

/**
 * Hook for fetching drop from buying session group
 */
export const useDrop = buyingSessionGroupId => {
  const {
    dropSetting: { dropWhereInput }
  } = stores

  return useQuery(GET_DROP, {
    fetchPolicy: 'cache-and-network',
    skip: !dropWhereInput || !buyingSessionGroupId,
    variables: dropWhereInput
  })
}

export const useCreateDrop = () => {
  const [ref] = useMutation(CREATE_DROP)
  return ref
}

export const useUpdateDrop = () => {
  const [ref] = useMutation(UPDATE_DROP)
  return ref
}

export const useDeleteDrop = () => {
  const [ref] = useMutation(DELETE_DROP)
  return ref
}
