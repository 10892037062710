/*
 * @Author: Raeesaa Metkari
 * @Date: 2020-08-21 22:35:53
 * @Last Modified by: Raeesaa Metkari
 * @Last Modified time: 2020-09-16 02:22:35
 */
import { config } from '@config'
import { storage } from '@services/storageService'
import { stores, strings } from '@stores'
import { action, observable } from 'mobx'

export class ImportOrdersStore {
  selectedFile

  @observable openImageUploadDialog: boolean = false
  @observable uploadInProgress: boolean = false
  @observable uploadError: string = ''
  @observable importSummary: string = ''

  @action onClickImportExcel = e => {
    const {
      showroomOrderManagementStore: { hideExportMenu }
    } = stores
    hideExportMenu(e)
    this.openImageUploadDialog = true
  }

  @action clearFileUploadData = () => {
    this.uploadInProgress = false
    this.uploadError = ''
  }

  onCloseImportExcelDialog = () => {
    this.openImageUploadDialog = false
    this.importSummary = ''
    this.clearFileUploadData()
  }

  @action onRefreshhandleImportExcelDialog = () => {
    if (this.importSummary) {
      window.location.reload()
    }
    this.onCloseImportExcelDialog()
  }

  @action onOkHandleImportExcelDialog = () => {
    this.onCloseImportExcelDialog()
  }

  @action buildImportSummaryTemplate = (
    importFailedForOrders,
    importPartiallyFailedForRecords
  ) => {
    let template = ''
    if (importFailedForOrders.length || importPartiallyFailedForRecords.length) {
      template +=
        '<p><b>Job completed successfully. Summary of records that failed to import:</b></p>'
      if (importFailedForOrders.length) {
        template += `<div>
          <p>Import failed for order / orders: <b>${importFailedForOrders.join(
            ', '
          )}</b> because of one of the following reasons:</p>
          ${strings.reasonsForCompleteOrderImportFail
            .map(reason => `<li>${reason}</li>`)
            .join('')}
          <br/>
          </div>
        `
      }

      if (importPartiallyFailedForRecords.length) {
        template += `
        <div>
          <p>Import failed for one or more products within order / orders: <b>${importPartiallyFailedForRecords.join(
            ', '
          )}</b> because of one of the following reasons:</p>
          ${strings.reasonsForPartialOrderImportFail
            .map(reason => `<li>${reason}</li>`)
            .join('')}
          </div>
        `
      }
    } else {
      template = `<span>All data imported successfully!<span>`
    }

    this.importSummary = template
  }

  @action handleChange = file => {
    this.selectedFile = file
  }

  @action onClickSave = async () => {
    this.uploadInProgress = true

    const {
      nav: {
        queryParams: { buyingSessionGroupId, clientId }
      }
    } = stores

    try {
      const data = new FormData()
      data.append('order', this.selectedFile)
      const accessToken: string = storage.getItem('access_token')
      if (this.selectedFile) {
        this.uploadError = ``
        let result = await fetch(
          `${config.buyingBoardServiceRestEndpoint}upload/orders/buyingSessionGroup/${buyingSessionGroupId}/client/${clientId}`,
          {
            body: data,
            method: 'POST',
            headers: {
              Authorization: accessToken
            }
          }
        )

        let response = await result.json()

        if (response?.error) {
          this.uploadError = `Failed to import orders: ${response?.error}`
        } else {
          this.buildImportSummaryTemplate(
            response.failedForOrders || [],
            response.partiallyFailedForOrders || []
          )
          this.clearFileUploadData()
        }
        this.uploadInProgress = false
      } else {
        this.uploadError = `Failed to import orders: Please select file.`
        this.uploadInProgress = false
      }
    } catch (err) {
      const errorMessage = err?.error || err?.message
      this.uploadError = `Failed to import orders${
        errorMessage ? ': ' + errorMessage : ''
      }`
      this.uploadInProgress = false
    }
  }
}

export const importOrders = new ImportOrdersStore()
