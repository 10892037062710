import { config } from '@config'
import { storage } from '@services/storageService'
import { ORDER_SERVICE_ENDPOINTS } from '../constants/apiConstants'
import { OrderType } from '@modules/common/models/enums'
import { HttpService } from '@services/HttpService'
import OrderDataService from './OrderDataService'

export class AddProductToOrderService extends OrderDataService {
  httpService = new HttpService()
  addProductToOrder = (orderId, buyingSessionProductId, orderType) => {
    /**
     * GBB-5562: temp fix to change type sequence
     * fallback type will be clientOrder instead of doorOrder
     * when no orderType is present
     */
    const orderTypeParam = orderType === OrderType.Door ? 'doorOrder' : 'clientOrder'
    const url = `${config.buyingBoardServiceRestEndpoint}${ORDER_SERVICE_ENDPOINTS.GET_ORDER_PRODUCT_DETAILS}/${orderTypeParam}/${orderId}/buyingSessionProduct/${buyingSessionProductId}`
    const accessToken: string = storage.getItem('access_token')
    return this.httpService
      .post(
        url,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        }
      )
      .then(response => response.data?.data)
  }
}
