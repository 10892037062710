import { StyledProps } from '@components/3rd-party/mui'
import { CircularLoading } from '@components/UI-Components/CircularLoading'
import { ProductDetailComponent } from '@components/UI-Components/ProductDetail/ProductDetailComponent'
import { IPermissions } from '@routes'
import React from 'react'
import { ProductDetail as ProductDetailWidget } from './ProductDetailWidget'

interface IProps extends StyledProps {
  data: any
  loading: boolean
  handleProductStatusChange: (e, buyingSessionProduct) => void
  updateProductMOQ?: (value) => void
  handleStatusChange?: (isActive) => void
  channels?: any
  ratingTypes?: any
  permissions?: IPermissions
}

export class ProductDetail extends React.Component<IProps> {
  render() {
    const {
      data,
      loading,
      handleProductStatusChange,
      updateProductMOQ,
      handleStatusChange,
      ratingTypes,
      permissions,
      channels
    } = this.props

    return (
      <ProductDetailComponent style={{ display: 'flex', justifyContent: 'space-around' }}>
        {loading ? (
          <CircularLoading />
        ) : (
          <ProductDetailWidget
            handleProductStatusChange={handleProductStatusChange}
            loading={loading}
            data={data}
            updateProductMOQ={updateProductMOQ}
            handleStatusChange={handleStatusChange}
            allChannels={channels}
            ratingTypes={ratingTypes}
            permissions={permissions}
          />
        )}
      </ProductDetailComponent>
    )
  }
}
