import { QueryResult } from '@apollo/client/react'
import { Query } from '@apollo/client/react/components/Query'
import { CentricTheme, StyledProps } from '@components/3rd-party/mui'
import { CircularLoading } from '@components/UI-Components/CircularLoading'
import CloseIcon from '@material-ui/icons/Close'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import { Paper } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { BUYING_SESSION_PRODUCTS_BET } from '@modules/retail/collection/graphql/buyingSessionProductsBetByCollection'
import { GET_RANGE_PLAN_BY_ACTIVITY } from '@modules/retail/collection/graphql/getRangePlanByActivity'
import { stores } from '@stores'
import { observer } from 'mobx-react'
import React from 'react'
import { withRouter } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'

const styles = (theme: CentricTheme) => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'left',
    flex: 1,
    paddingLeft: theme.spacing(3)
  },
  root: {
    width: '100%',
    overflowX: 'auto'
  },
  table: {
    minWidth: 650
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1)
  },
  paper: {
    position: 'absolute',
    border: '3px solid lightgrey',
    marginBottom: theme.spacing(1)
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    zIndex: 99
  }
})

interface IReportingModal extends StyledProps {
  open: boolean
  onClose: () => void
  betBuyData?: any
  activities?: Array<any>
  match?: any
  role?
}
@(withRouter as any)
@observer
export class ReportingModalComponent extends React.Component<IReportingModal> {
  render() {
    const [
      {
        props: { classes, open, onClose, betBuyData, activities, role }
      },
      {
        collection: { getReportingData, prepareRangePlanWhereInput },
        product: { markUpList },
        nav: {
          params: { podium, activity, gender }
        }
      }
    ] = [this, stores]

    const rangePlantWhereInput = prepareRangePlanWhereInput(activities)

    return (
      <Query
        query={BUYING_SESSION_PRODUCTS_BET}
        variables={{ buyingSessionCollectionId: podium }}
        fetchPolicy={'cache-and-network'}
        skip={!activity}
      >
        {({ loading: buyingSessionLoading, data }) => {
          return (
            <Query
              query={GET_RANGE_PLAN_BY_ACTIVITY}
              variables={{
                genderId: gender,
                rangePlantWhereInput: rangePlantWhereInput
              }}
            >
              {({ loading, error, data }: QueryResult) => {
                const rangePlans = data ? data.rangePlans : []
                const buyingSessionProducts =
                  data && data.buyingSessionProducts ? data.buyingSessionProducts : []
                const reportingData = getReportingData(
                  rangePlans,
                  markUpList,
                  role,
                  betBuyData,
                  activities,
                  activity ? buyingSessionProducts : null
                )

                const isLoading = activity
                  ? loading && isEmpty(data)
                    ? true
                    : false
                  : false
                if (error) return <div>Error</div>
                if (buyingSessionLoading)
                  return (
                    <div className={classes.loader}>
                      <CircularLoading />
                    </div>
                  )
                return (
                  <Drawer
                    anchor='bottom'
                    open={open}
                    onClose={onClose}
                    variant='persistent'
                    classes={{ paper: classes.paper }}
                  >
                    <div tabIndex={0} role='button'>
                      <Paper className={classes.root}>
                        <div className={classes.title}>
                          <Typography variant='subtitle2' noWrap>
                            WW BET QUICK REPORT
                          </Typography>
                          <IconButton
                            aria-label='Close'
                            className={classes.closeButton}
                            onClick={onClose}
                          >
                            <CloseIcon />
                          </IconButton>
                        </div>
                        <Table className={classes.table}>
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <Typography variant='subtitle2'>Activity</Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant='subtitle2'>NB ACs BET</Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant='subtitle2'>
                                  Total Bet Value
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant='subtitle2'>
                                  Total OTB Value
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant='subtitle2'>
                                  Bet Consumption Value %
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant='subtitle2'>Total Bet Qty</Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant='subtitle2'>Total OTB Qty</Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant='subtitle2'>
                                  Bet Consumption Qty %
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {reportingData.map((row: any) => (
                              <TableRow key={row?.activity}>
                                <TableCell component='th' scope='row'>
                                  <Typography variant='subtitle2'>
                                    {row.activity}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant='subtitle2'>{row.noAcs}</Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant='subtitle2'>
                                    {row.betVal}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant='subtitle2'>
                                    {row.otbValue}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant='subtitle2'>
                                    {isLoading ? '...' : `${row.betValueWeight} %`}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant='subtitle2'>
                                    {row.betQty}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant='subtitle2'>
                                    {row.otbQty}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant='subtitle2'>
                                    {isLoading ? '...' : `${row.betQtyWeight} %`}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Paper>
                    </div>
                  </Drawer>
                )
              }}
            </Query>
          )
        }}
      </Query>
    )
  }
}

@((withStyles as any)(styles))
export class ReportingModal extends ReportingModalComponent {}
