import { StyledProps } from '@components/3rd-party/mui'
import { TextAlignment } from '@modules/common/models/enums'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { ApplyToFixedIfDecimal, ApplyToFixedIfNumber } from '@services/commonServices'
import { stores, strings } from '@stores'
import { useObserver } from 'mobx-react-lite'
import React, { memo } from 'react'
import styled from 'styled-components'

interface IProps extends StyledProps {
  alignment?: TextAlignment
  data?: any
  canEdit?: boolean
  product?: any
  hasGlobalRow?: boolean
  dataKey?: string
  isFloatingPointValue?: boolean
}

// interface IAverageDepth extends StyledProps {
//   alignment: TextAlignment
//   hasGlobalRow: boolean
//   index?: number
// }

export const StyledAverageDepthComponent = styled(
  ({ index, hasGlobalRow, alignment, ...rest }) => <TextField {...rest} />
)(props => ({
  '&': {
    input: {
      padding: '0px',
      textAlign: props.alignment,
      font:
        props.hasGlobalRow && props.index === 0
          ? 'var(--typography-font-bold)'
          : 'var(--typography-body2)'
    },
    '> div': {
      alignItems: props.alignment
    }
  }
}))

export const StyledTypographyComponent = styled(
  ({ index, font, hasGlobalRow, alignment, ...rest }) => <Typography {...rest} />
)(props => ({
  '&&': {
    font: props.font
      ? props.font
      : props.hasGlobalRow && props.index === 0
      ? 'var(--typography-font-bold1)'
      : 'var(--typography-body3)',
    textAlign: props.alignment,
    width: props.width ? `${props.width}px` : '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
}))

const Root = styled.div({
  height: 'var(--cell-height)',
  borderBottom: 'var(--cell-border-bottom)',
  padding: 'var(--cell-padding)',
  display: 'flex',
  alignItems: 'center'
})

export const AvgDepthTextFieldList: React.FunctionComponent<IProps> = memo(
  (props: IProps) => {
    return useObserver(function useHook() {
      const [
        {
          dataKey,
          data,
          alignment,
          hasGlobalRow,
          canEdit,
          product,
          isFloatingPointValue
        },
        {
          product: {
            handleAvgDepthChange,
            onFocusAvgDepth,
            updateAvgDepth,
            handleKeyDown,
            selectedProducts
          }
        }
      ] = [props, stores]

      return (
        <>
          {data &&
            data.map((record, index) => {
              const selectedProduct = selectedProducts.get(record.id)
              return (
                <Root key={record.id}>
                  {canEdit ? (
                    <StyledAverageDepthComponent
                      name={strings.listViewInputField}
                      value={
                        selectedProduct
                          ? selectedProduct.selectedProductAvgDepthValue === null
                            ? ''
                            : selectedProduct.selectedProductAvgDepthValue
                          : isFloatingPointValue
                          ? ApplyToFixedIfDecimal(record[dataKey!])
                          : record[dataKey!]
                      }
                      alignment={alignment}
                      index={index}
                      hasGlobalRow={hasGlobalRow}
                      onFocus={event => onFocusAvgDepth(event, record)}
                      onChange={event => handleAvgDepthChange(event, record, product)}
                      onBlur={event => updateAvgDepth(event, record, product)}
                      onKeyDown={event => handleKeyDown(event, record, product)}
                    />
                  ) : (
                    <StyledTypographyComponent
                      alignment={alignment}
                      index={index}
                      hasGlobalRow={hasGlobalRow}
                    >
                      {isFloatingPointValue
                        ? ApplyToFixedIfNumber(record[dataKey!])
                        : record[dataKey!]}
                    </StyledTypographyComponent>
                  )}
                </Root>
              )
            })}
        </>
      )
    })
  }
)
