import { SAActionsType } from '@modules/showroom/productDetail/new/component/SAActions'
import isEqual from 'lodash/isEqual'
import meanBy from 'lodash/meanBy'
import sortBy from 'lodash/sortBy'
import memoize from 'memoize-one'

export const getProductInfoAttributes = memoize(
  (productAttributes = [], isPrimaryAttribute = true) => {
    // return filter(productAttributes, { isPrimaryAttribute, isPriceAttribute })
    return productAttributes.filter(
      attr => attr.isPrimaryAttribute === isPrimaryAttribute
    )
  }
)

export const sortProductAttributes = memoize((productAttributes = []) => {
  return sortBy(productAttributes, attr => attr.sortIndex)
})

export const getAverageRating = memoize(ratings => {
  // note: average is considered for non-zero ratings only
  const nonZeroRatings = ratings.filter(rating => rating.Value !== 0)
  return (meanBy(nonZeroRatings, 'Value') || 0).toFixed(1)
}, isEqual)

/**
 * construct fav icon and handler based on processed product
 */
export const getFavouriteActions = memoize(
  (
    isFavorite,
    isActive,
    canUpdateFavorite,
    bspId,
    favCallBack,
    toggleIsFavorite,
    isLookbookView
  ) => {
    const toggleCallBack = async () => {
      try {
        if (favCallBack) {
          favCallBack()
          !isLookbookView && (await toggleIsFavorite())
        }
      } catch (e) {
        if (favCallBack) {
          favCallBack()
        }
      }
    }
    return {
      // if is fav add remove action,
      //  otherwise add to fav action
      actionIcon: isFavorite ? SAActionsType.removeFavourite : SAActionsType.addFavourite,
      // if product is active and user has required edit permission
      onActionIconClick: isActive && canUpdateFavorite ? toggleCallBack : null
    }
  }
)

/**
 * construct order icon and handler based on  product
 */
export const getOrderActions = memoize(
  (
    isOrderProduct,
    isActive,
    isCore,
    isBottomRightIconClickable,
    bspId,
    favCallBack,
    addProductToOrder,
    removeProductToOrder
  ) => {
    const toggleCallBack = async () => {
      try {
        let res
        if (isOrderProduct) {
          res = removeProductToOrder(bspId)
        } else {
          addProductToOrder(bspId)
        }
        if (favCallBack) {
          await favCallBack(bspId, res)()
        }
      } catch (e) {}
    }

    // if is fav add remove action,
    //  otherwise add to fav action
    // isCore: can't remove product
    let actionIcon = null
    if (!isCore && isOrderProduct) {
      actionIcon = SAActionsType.removeFromBasket
    } else if (!isOrderProduct) {
      actionIcon = SAActionsType.addToBasket
    }

    // starts here
    return {
      actionIcon,
      // if product is active or user has required edit permission
      onActionIconClick: isActive || isBottomRightIconClickable ? toggleCallBack : null
    }
  }
)

/**
 * invoke actions constructor fn based on current path
 * returns:
 * false: client-collection/wishlist/look-book
 * true: collection/order
 */
//todo: avoid objects in memoize, fav done, same for active and order product(
// can its id suffice) why is + icon not updating, and why does clicking on - exit detail
export const getBasketActionsByRoute = memoize((pathname): boolean => {
  //  on new pages i.e., client collection/wishlist/look-book
  //  show fav actions
  return !(
    pathname.includes('client-collection') ||
    pathname.includes('client-wishlist') ||
    pathname.includes('lookbook')
  )
}, isEqual)
