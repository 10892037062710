import { StyledProps } from '@components/3rd-party/mui'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import { withStyles } from '@material-ui/core/styles'
import { stores } from '@stores'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import sortBy from 'lodash/sortBy'
import { observer } from 'mobx-react'
import React from 'react'
import { Link } from 'react-router-dom'

import * as css from './BuyingSessionGenderTabs.css'

const styles = theme => ({
  tabsRoot: {
    borderBottom: '1px solid #e8e8e8'
  },
  tabsIndicator: {
    backgroundColor: theme.colors.black
  },
  tabRoot: {
    textTransform: 'initial',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    '&:hover': {
      color: theme.colors.darkgrey,
      opacity: 1
    },
    '&tabSelected': {
      color: theme.colors.black,
      fontWeight: theme.typography.fontWeightMedium
    },
    '&:focus': {
      color: theme.colors.darkgrey
    }
  }
})

interface GenderTabsProps extends StyledProps {
  genderId?: string
  genders?: any
}

@((withStyles as any)(styles))
@observer
export class BuyingSessionGenderTabs extends React.Component<GenderTabsProps> {
  componentDidMount = () => {
    const [
      {
        props: { genders },
        selectGender
      },
      {
        nav: {
          params: { gender: selectedGenderId }
        }
      }
    ] = [this, stores]
    // Todo - computed + autorun
    const sortedGenders = sortBy(genders, 'description')
    setTimeout(() => isEmpty(selectedGenderId) && selectGender(sortedGenders[0]))
  }

  selectGender = gender => {
    const {
      nav: {
        history,
        urlForParams,
        params: { season }
      },
      product: { getMarkupData }
    } = stores
    getMarkupData(gender)
    history.push({
      pathname: urlForParams({ season, gender: gender.id }),
      search: history.location.search
    })
  }

  handleChange = async (event, value) => {
    const {
      nav: {
        history,
        urlForParams,
        params: { season }
      },
      product: { getMarkupData }
    } = stores
    getMarkupData(value)
    history.push({
      pathname: urlForParams({ season, gender: value }),
      search: history.location.search
    })
  }

  render() {
    const [
      {
        props: { classes, genders },
        handleChange
      },
      {
        nav: {
          urlForParams,
          params: { season, gender: selectedGenderId }
        }
      }
    ] = [this, stores]

    // Todo - computed + autorun
    const sortedGenders = sortBy(genders, 'description')

    return (
      <Tabs
        value={selectedGenderId || sortedGenders[0].id}
        onChange={handleChange}
        classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
      >
        {sortedGenders.map((gender, index) => (
          <Tab
            key={index}
            disableRipple
            classes={{
              root: classNames(css.tab, classes.tabRoot),
              selected: classes.tabSelected
            }}
            label={
              <Link
                to={{
                  pathname: urlForParams({ season, gender: gender.id }),
                  search: window.location.search
                }}
              >
                {gender.description}
              </Link>
            }
            style={{ marginLeft: 44 }}
            value={gender.id}
          />
        ))}
      </Tabs>
    )
  }
}
