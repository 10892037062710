import { config } from '@config'
import { storage } from '@services/storageService'
import axios from 'axios'
import { Channel } from '@modules/common'
import { VIPChannel } from '@modules/common/models/enums/VIPChannel'

export class OrderManagementService {
  getActivities = async bsgId => {
    try {
      const url = `${config.buyingBoardServiceRestEndpoint}order/buyingSessionGroup/${bsgId}/activities`
      const accessToken: string = storage.getItem('access_token')

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      return response
    } catch (e) {
      console.log(e)
    }
  }

  getAllOrders = async (bsgId: string, zoneId: string, channel: string) => {
    try {
      const channelType =
        channel === VIPChannel.Retail ? Channel.Retail : Channel.Wholesale
      const quantityType = config.defaultQuantityType[channelType]
      const url = `${config.buyingBoardServiceRestEndpoint}order/buyingSessionGroup/${bsgId}/zoneClientOrders`
      const accessToken: string = storage.getItem('access_token')
      const response: any = await axios.get(url, {
        params: {
          zoneId,
          quantityType,
          channel
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      return response?.data
    } catch (e) {
      console.log(e)
    }
  }

  /**
   * create retail orders for the given bsg
   * @param buyingSessionGroupId
   * onSuccess: returns a success message and
   * created clientOrderIds
   */
  createRetailOrders = async (buyingSessionGroupId: string) => {
    try {
      const url = `${config.buyingBoardServiceRestEndpoint}order/buyingSessionGroup/${buyingSessionGroupId}/createOrders`
      const accessToken: string = storage.getItem('access_token')

      return await axios.post(url, null, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
    } catch (e) {
      console.log(e)
    }
  }

  validateRetailOrder = async (buyingSessionGroupId: string) => {
    try {
      const url = `${config.buyingBoardServiceRestEndpoint}order/buyingSessionGroup/${buyingSessionGroupId}/retail/validateOrders`
      const accessToken: string = storage.getItem('access_token')

      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })

      return res.data
    } catch (e) {
      console.log(e)
    }
  }

  deleteOrder = async (orderId, orderType) => {
    try {
      const url = `${config.buyingBoardServiceRestEndpoint}order/${orderId}?orderType=${orderType}`
      const accessToken: string = storage.getItem('access_token')

      const response = await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      return response
    } catch (e) {
      console.log(e)
      // throw e
    }
  }
}
