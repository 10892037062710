import { QueryResult } from '@apollo/client/react'
import { RestServiceEndpoints } from '@constants'
import GET_BUYING_SESSION_GROUP from '@modules/wholesale/buyingGroupSummary/graphql/getBuyingSessionGroup'
import { WS_UPDATE_BUYING_SESSION_GROUP_DATE } from '@modules/wholesale/BuyingSessionGroupDateUpdate'
import { WholesaleBSPSettingIds } from '@modules/wholesale/syncSetting/constants/syncSettingConstants'
import { getUTCRepresentation } from '@services/commonServices'
import { callBridgeServiceAPI } from '@services/uploadFileService'
import { apolloClient, stores, strings } from '@stores'
import cloneDeep from 'lodash/cloneDeep'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import forEach from 'lodash/forEach'
import has from 'lodash/has'
import reduce from 'lodash/reduce'
import { action, computed, observable, toJS } from 'mobx'

import { SyncData } from '../../syncSetting/services/syncSettings'
import { GeneralSelectionStore } from '@stores/SelectionStore'
import { CreateBuyingSessionAPIService } from '../service/create-buying-session-group'
import { computedFn } from 'mobx-utils'

export class WholesaleBuyingSessionStore {
  private syncAPIService = new SyncData()
  private createBuyingSessionAPIService = new CreateBuyingSessionAPIService()
  @observable selectedCollections: { id: string; name: string }[] = []
  @observable buyingSessionGroupName = ''
  @observable isBuyingSessionGroupNameTouched = false
  @observable isNextClicked = false
  @observable selectedActivityCollectionMap: any = {}
  @observable savedActivityCollectionMap: any = {}
  @observable isSaveInProgress = false
  @observable buyingSessionDate = new Date().getTime()
  @observable buyingSessionDateUpdateInProgress: boolean = false
  @observable openConfirmation: boolean = false
  @observable resultMessage: string = ''
  @observable errorMessage: string = ''
  @observable isBSGCreationFailed = false
  channelSelection = new GeneralSelectionStore()
  @observable collectionAndActivites = []
  @observable isCollectionLoading = true

  queries: {
    buyingSessionGroup: QueryResult<any>
  } = { buyingSessionGroup: null }

  @action onBuyingSessionDateChange = date => {
    this.buyingSessionDate = getUTCRepresentation(date)
  }
  @action resetBuyingSessionDate = () => {
    this.buyingSessionDate = new Date().getTime()
  }
  @action
  openConfirmationDialog = () => {
    this.openConfirmation = true
  }
  @action
  closeConfirmationDialog = () => {
    this.openConfirmation = false
  }
  @action onSubmit = async () => {
    const {
      nav: {
        queryParams: { buyingSessionGroupId }
      }
    } = stores

    if (buyingSessionGroupId && !this.buyingSessionDateUpdateInProgress) {
      this.buyingSessionDateUpdateInProgress = true

      try {
        const priceValidityResult = await apolloClient.mutate({
          mutation: WS_UPDATE_BUYING_SESSION_GROUP_DATE,
          variables: {
            buyingSessionGroupId: buyingSessionGroupId,
            date: this.buyingSessionDate
          },
          update: (cache, mutationResult) => {
            const result = mutationResult?.data

            if (
              result?.VIPBoard_UpdateDateForBuyingSessionGroup?.status ===
              strings.mutationResultSuccess
            ) {
              const { buyingSessionGroup } = cache.readQuery({
                query: GET_BUYING_SESSION_GROUP,
                variables: {
                  id: buyingSessionGroupId
                }
              })
              let buyingSessionGroupClone = cloneDeep(buyingSessionGroup)
              buyingSessionGroupClone.BSGDate = this.buyingSessionDate

              cache.writeQuery({
                query: GET_BUYING_SESSION_GROUP,
                variables: {
                  id: buyingSessionGroupId
                },
                data: {
                  buyingSessionGroup: buyingSessionGroupClone
                }
              })
            }
          }
        })
        if (
          priceValidityResult.data.VIPBoard_UpdateDateForBuyingSessionGroup?.status ===
          strings.mutationResultSuccess
        ) {
          const result: any = await this.syncAPIService.syncBSGData(
            {
              [WholesaleBSPSettingIds.priceValidityDate]: true
            },
            buyingSessionGroupId
          )
          if (result?.message?.toUpperCase() !== strings.mutationResultSuccess) {
            this.openConfirmationDialog()
          }
        }
      } finally {
        this.buyingSessionDateUpdateInProgress = false
      }
    }
  }
  @action
  setCollectionAsSelected = collection => {
    if (!find(this.selectedCollections, ({ id }) => id === collection.id)) {
      this.selectedCollections.push(collection)
    }
  }

  @action
  setCollectionAsUnselected = collection => {
    const collectionIndex = findIndex(
      this.selectedCollections,
      ({ id }) => id === collection.id
    )
    if (collectionIndex !== -1) {
      this.selectedCollections.splice(collectionIndex, 1)
    }
  }

  @action
  setBuyingSessionGroupName = buyingSessionGroupName => {
    if (buyingSessionGroupName !== this.buyingSessionGroupName) {
      Object.assign(this, { buyingSessionGroupName })
    }
  }

  @action
  markBuyingSessionGroupNameAsTouched = () =>
    Object.assign(this, { isBuyingSessionGroupNameTouched: true })

  @action
  setNextClick = isNextClicked => Object.assign(this, { isNextClicked })

  @action
  setActivityAsSelected = (collectionId, activity) => {
    this.setCollectionActivityMap(
      collectionId,
      activity,
      this.selectedActivityCollectionMap
    )
  }

  @action
  setSavedActivity = (collectionId, activity) => {
    this.setCollectionActivityMap(collectionId, activity, this.savedActivityCollectionMap)
  }

  @action
  setCollectionActivityMap = (collectionId, activity, mapObjectToBeSet) => {
    const activityToBeSet = { Id: activity.id, Name: activity.name }
    if (!mapObjectToBeSet[collectionId]) {
      mapObjectToBeSet[collectionId] = [activityToBeSet]
    } else if (
      !find(
        mapObjectToBeSet[collectionId],
        obj => obj && activity && obj.Id === activity.id
      )
    ) {
      mapObjectToBeSet[collectionId].push(activityToBeSet)
    }
  }

  @action
  setActivityAsUnselected = (collectionId, activity) => {
    if (this.selectedActivityCollectionMap[collectionId]) {
      const activityIndex = findIndex(
        this.selectedActivityCollectionMap[collectionId],
        (mapObj: any) => mapObj && activity && mapObj.Id === activity.id
      )
      if (activityIndex !== -1) {
        this.selectedActivityCollectionMap[collectionId].splice(activityIndex, 1)
      }
    }
  }

  @action
  cancelBuyingSessionCreation = () => {
    const [{ nav }, { setIsSaveInProgress }] = [stores, this]
    setIsSaveInProgress(false)
    this.buyingSessionGroupName = ''
    this.selectedCollections = []
    this.channelSelection.setSelectedRows([])
    this.selectedActivityCollectionMap = {}
    this.savedActivityCollectionMap = {}
    this.isNextClicked = false
    this.isBuyingSessionGroupNameTouched = false
    nav.updateQueryParams(
      { addBuyingSessionGroup: undefined, addBuyingSession: undefined },
      'replace'
    )
    this.collectionAndActivites = []
    this.isCollectionLoading = true
  }

  @computed get showCreateBuyingSessionPopup(): boolean {
    const { nav } = stores
    return !!(
      nav.queryParams.addBuyingSession && nav.queryParams.addBuyingSession === 'true'
    )
  }

  @computed get showCreateBuyingSessionGroupPopup(): boolean {
    const { nav } = stores
    return !!(
      nav.queryParams.addBuyingSessionGroup &&
      nav.queryParams.addBuyingSessionGroup === 'true'
    )
  }

  @action
  createBuyingSession = async (gendersData, bsGroupGender) => {
    const [
      {
        nav,
        sidebar: {
          queries: { seasons }
        }
      },
      { cancelBuyingSessionCreation: closePopup, setIsSaveInProgress }
    ] = [stores, this]
    if (nav.buyingSessionGroupId) {
      setIsSaveInProgress(true)
      const gender = bsGroupGender
        ? gendersData.find(({ Id }) => Id === bsGroupGender.Id) || {}
        : {}

      // This will be (No. of selected collections X No. of selected activities)
      // One for buying session group and one for each of the buying sessions (combination of collection and activities) to be created
      const nodeCount = reduce(
        Object.values(this.selectedActivityCollectionMap) as any[],
        (count, currentCollectionActivities: { Id: string; Name: string }[]) =>
          count + currentCollectionActivities.length,
        0
      )
      const collections = this.selectedCollections.map(({ id, name }) => {
        return { Id: id, Name: name }
      })

      const response: any = await callBridgeServiceAPI(
        RestServiceEndpoints.CREATE_BS_OR_BSG,
        {
          nodeCount,
          buyingSessionGroupId: nav.queryParams.buyingSessionGroupId,
          gender: JSON.stringify(toJS(gender)),
          selectedCollections: JSON.stringify(toJS(collections)),
          selectedActivityCollectionMap: JSON.stringify(
            toJS(this.selectedActivityCollectionMap)
          )
        },
        false
      )

      if (response?.Status === 'Successful') {
        await this.queries.buyingSessionGroup.refetch()
        await seasons.refetch()
        this.clearAllotmentsCache()
        closePopup()
      } else {
        this.errorMessage = response?.message
        this.isBSGCreationFailed = true
      }
    }
  }

  clearAllotmentsCache = () => {
    apolloClient.cache.evict({ id: 'ROOT_QUERY', fieldName: 'findVIPAllotment' })
    apolloClient.cache.gc()
  }

  @action
  handleCollectionClick = collection => () => {
    if (find(this.selectedCollections, ({ id }) => collection.id === id)) {
      this.setCollectionAsUnselected(collection)
    } else {
      this.setCollectionAsSelected(collection)
    }
  }

  @action
  handleActivityClick = (collectionId, activity) => () => {
    const isTheActivityAlreadySelected =
      this.selectedActivityCollectionMap[collectionId] &&
      find(
        this.selectedActivityCollectionMap[collectionId],
        ({ Id }) => activity.id === Id
      )

    if (isTheActivityAlreadySelected) {
      this.setActivityAsUnselected(collectionId, activity)
    } else {
      this.setActivityAsSelected(collectionId, activity)
    }
  }

  @action
  addBuyingSessionGroupClickHandler = () => {
    const {
      nav: { queryParams, updateQueryParams }
    } = stores
    if (!has(queryParams, 'addBuyingSessionGroup')) {
      updateQueryParams({ addBuyingSessionGroup: true }, 'push')
    } else {
      updateQueryParams({ addBuyingSessionGroup: true }, 'replace')
    }
  }

  @action
  setBuyingSessionFields = buyingSessionGroup => {
    if (buyingSessionGroup.Name) {
      this.setBuyingSessionGroupName(buyingSessionGroup.Name)
    }
    if (buyingSessionGroup?.Sessions?.length) {
      forEach(buyingSessionGroup.Sessions, buyingSession => {
        this.setSavedActivity(buyingSession?.Collection?.Id, {
          id: buyingSession?.Activity?.Id,
          name: buyingSession?.Activity?.Name
        })
      })
    }
  }

  @action handleErrorDialogClose = () => {
    this.isBSGCreationFailed = false
    this.errorMessage = null
    this.cancelBuyingSessionCreation()
  }

  @action
  createBuyingSessionGroup = async (gendersData = []) => {
    const [
      { cancelBuyingSessionCreation: closePopup, setIsSaveInProgress },
      {
        sidebar: {
          queries: { seasons },
          genderId,
          selectedWholesaleSeason
        },
        nav: { history }
      }
    ] = [this, stores]

    setIsSaveInProgress(true)
    const gender = gendersData.find(({ Id }) => Id === genderId) ?? {}

    // This will be 1 + (No. of selected collections X No. of selected activities)
    // One for buying session group and one for each of the buying sessions (combination of collection and activities) to be created
    const nodeCount = reduce(
      Object.values(this.selectedActivityCollectionMap) as any[],
      (count, currentCollectionActivities: { Id: string; Name: string }[]) =>
        count + currentCollectionActivities.length,
      1
    )
    const collections = this.selectedCollections.map(({ id, name }) => {
      return { Id: id, Name: name }
    })

    const bsgDate = new Date(this.buyingSessionDate)
    let buyingSessionDate =
      new Date(
        Date.UTC(bsgDate.getFullYear(), bsgDate.getMonth(), bsgDate.getDate())
      ).setUTCHours(0, 0, 0, 0) / 1000

    let {
      axios: { setErrToastVisibility }
    } = stores
    setErrToastVisibility(false)

    const response: any = await callBridgeServiceAPI(
      RestServiceEndpoints.CREATE_BS_OR_BSG,
      {
        nodeCount,
        buyingSessionGroupName: this.buyingSessionGroupName,
        seasonId: selectedWholesaleSeason.Id,
        gender: JSON.stringify(toJS(gender)),
        channels: JSON.stringify(Array.from(this.channelSelection.selected)),
        selectedCollections: JSON.stringify(toJS(collections)),
        selectedActivityCollectionMap: JSON.stringify(
          toJS(this.selectedActivityCollectionMap)
        ),
        buyingSessionDate
      },
      false
    )

    if (response?.Status === 'Successful') {
      await seasons.refetch()
      let buyingSessionGroupId = response.newBuyingSessionGroupId
      history.push({
        pathname: '/wholesale',
        search: buyingSessionGroupId
          ? `?buyingSessionGroupId=${buyingSessionGroupId}`
          : ''
      })
      closePopup()
      // Todo: Sync API
    } else {
      this.errorMessage = response?.message
      this.isBSGCreationFailed = true
    }
  }

  @action
  setIsSaveInProgress = isSaveInProgress => Object.assign(this, { isSaveInProgress })

  @action
  fetchCollectionAndActivites = async (seasonId, genderId) => {
    try {
      this.isCollectionLoading = true
      const data = await this.createBuyingSessionAPIService.getCollectionAndActivities(
        seasonId,
        genderId
      )
      if (data?.data?.data?.collections) {
        this.collectionAndActivites = data?.data?.data?.collections
      }
    } finally {
      this.isCollectionLoading = false
    }
  }

  getAvailableActivitiesForCollection = computedFn(collectionId => {
    const collection = this.collectionAndActivites.find(
      collection => collection.id === collectionId
    )
    return collection?.activities || []
  })

  @action
  resetCollectionActivityData = () => {
    this.collectionAndActivites = []
    this.isCollectionLoading = true
  }
}
