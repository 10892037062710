import { useQueryDynamic } from '@components/3rd-party/apollo'
/**
 * Hook for querying clusters
 * @param {string} zoneAssortmentId - Id of zone assortment product
 * @param {string} channelId - Id of channel
 * @param {string} collectionId - Id of selected collection
 */
export const useGetClusters = (
  query: string,
  variables: {
    zoneAssortmentId: string
    channelId?: string
    collectionId?: string
  }
) => {
  return useQueryDynamic(query as any, {
    variables,
    fetchPolicy: 'cache-and-network',
    skip: Object.keys(variables || {}).length === 0
  })
}
