/*
 * @Author: Raeesaa Metkari
 * @Date: 2019-11-20 14:51:54
 * @Last Modified by: Raeesaa Metkari
 * @Last Modified time: 2019-11-21 18:28:30
 */
import Tooltip from '@material-ui/core/Tooltip'
import { KPITreeViewNodeTypes } from '@services/kpiTreeViewService'
import React, { memo } from 'react'
import { isMobile } from 'react-device-detect'
import { areEqual } from 'react-window'
import styled from 'styled-components'
import * as css from './TreeRow.css'

interface MyProps {
  style: any
  kpiNode: any
  onClick?: any
}

export const CategoryLabel = styled(({ kpiNode, className, onClick }) => {
  return (
    <Tooltip
      title={`${kpiNode.name} ${kpiNode.totalProducts} ACs`}
      placement='bottom'
      disableHoverListener={isMobile ? true : false}
      disableTouchListener={isMobile ? false : true}
    >
      <span className={className} onClick={onClick}>
        <span
          className={
            kpiNode.type === KPITreeViewNodeTypes.Category ? 'boldText' : 'normalText'
          }
        >
          {kpiNode.name}{' '}
        </span>
        <span className={'normalText'}>{kpiNode.totalProducts} </span>
        <span className={'countSuffix'}>ACs </span>
      </span>
    </Tooltip>
  )
})`
  && {
    height: 14px;
    font: var(--typography-body2);
    color: #000000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    user-select: none;
    webkit-user-select: none;
    -webkit-touch-callout: none;

    .boldText {
      font-weight: bold;
    }

    .normalText {
      font-weight: normal;
    }

    .countSuffix {
      font-weight: normal;
      letter-spacing: 0.08px;
    }
  }
`

/**
 * Common Category row component which will be used for all 3 categories
 * and in both assortment and collection view
 */
export const CategoryTreeRow: React.FunctionComponent<MyProps> = memo(
  ({ kpiNode, style, onClick }) => {
    return (
      <div style={style} className={css.root}>
        <span className={css.title}>
          <CategoryLabel kpiNode={kpiNode} onClick={onClick} />
        </span>
      </div>
    )
  },
  areEqual
)

CategoryTreeRow.displayName = 'CategoryTreeRow'
