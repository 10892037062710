import { action, observable, ObservableMap } from 'mobx'
import { stores } from '@stores'
import { computedFn } from 'mobx-utils'
import { ProductPresentationService } from '@modules/showroom/productCollection/service/ProductPresentation'

export class ProductPricesStore {
  @observable prices = new ObservableMap()
  private apiService = new ProductPresentationService()
  @observable isPriceLoading = new ObservableMap()
  @observable bsgStatus = ''

  @action fetchPriceData = (clientid, bsgId) => {
    if (!this.prices.has(clientid + bsgId) && this.isPriceLoading) {
      this.isPriceLoading.set(clientid + bsgId, true)
      this.apiService
        .getProductPrices(bsgId, clientid)
        .then(response => {
          this.prices.set(clientid + bsgId, response)
          this.isPriceLoading.set(clientid + bsgId, false)
          this.bsgStatus = response?.buyingSessionGroup?.status
        })
        .catch(error => {
          this.prices.set(clientid + bsgId, null)
          this.isPriceLoading.set(clientid + bsgId, false)
        })
    }
  }

  /**
   * Function which will return the prices of products .
   */
  getPriceForProduct = computedFn(productId => {
    const {
      nav: {
        queryParams: { clientId, buyingSessionGroupId }
      }
    } = stores

    const price = this.prices.get(clientId + buyingSessionGroupId)
    return {
      rtlCurrency: price?.rtlCurrency ?? '',
      whlCurrency: price?.whlCurrency ?? '',
      retailProductPrice: price?.prices?.[productId]?.retailProductPrice,
      wholesaleProductPrice: price?.prices?.[productId]?.wholesaleProductPrice,
      retailRefProductPrice: price?.prices?.[productId]?.retailRefProductPrice,
      rtlRefCurrency: price?.rtlRefCurrency
    }
  })

  getPriceLoading = computedFn((clientId, buyingSessionGroupId) => {
    const isPriceLoading = this.isPriceLoading.get(clientId + buyingSessionGroupId)
    return isPriceLoading
  })

  getWhlCurrency = computedFn(
    (clientId, buyingSessionGroupId) =>
      this.prices.get(clientId + buyingSessionGroupId)?.whlCurrency
  )
}

export const productPriceStore = new ProductPricesStore()
