import appConfig from './appConfig.json'
export const config = {
  centricGraphQLEndpoint: '{{MIDDLEWARE_GRAPHQL_API_GATEWAY}}graphql',
  wsCentricGraphQLEndpoint: '{{MIDDLEWARE_GRAPHQL_API_WS_GATEWAY}}graphql',
  centricRestProxyEndpoint: '{{MIDDLEWARE_REST_API_GATEWAY}}apis/get',
  centricRestEndpoint: '{{MIDDLEWARE_REST_API_GATEWAY}}',
  pdfServiceEndPoint: '{{PDF_SERVICE_ENDPOINT}}',
  authServiceEndPoint: '{{OAUTH_SERVICE_ENDPOINT}}',
  bridgeServiceEndpoint: '{{BRIDGE_SERVICE_ENDPOINT}}',
  buyingBoardServiceRestEndpoint: '{{BUYING_BOARD_SERVICE_MIDDLEWARE_REST_API_GATEWAY}}',
  oAuthClientId: '{{OAUTH_CLIENT_ID}}',
  oAuthClientSecret: '{{OAUTH_CLIENT_SECRET}}',
  customerName: '{{SALES_BOARD_APP_LOGO}}',
  wijmoLicenseKey: '{{WIJMO_LICENSE_KEY}}',
  centralWholesalePriceListGroup: '{{CENTRAL_WHL_PRICELIST_GROUP}}',
  centralWholesalePriceListType: '{{CENTRAL_WHL_PRICELIST_TYPE}}',
  centralSRPPriceListGroup: '{{CENTRAL_SRP_PRICELIST_GROUP}}',
  centralSRPPriceListType: '{{CENTRAL_SRP_PRICELIST_TYPE}}',
  centralRetailPriceListGroup: '{{CENTRAL_RETAIL_PRICELIST_GROUP}}',
  centralRetailPriceListType: '{{CENTRAL_RETAIL_PRICELIST_TYPE}}',
  globalCurrency: '{{GLOBAL_CURRENCY}}',
  environment: `{{ENVIRONMENT}}`,
  jobStatusPollInterval: '{{JOB_STATUS_POLL_INTERVAL}}',
  notificationPollInterval: '{{NOTIFICATION_POLL_INTERVAL}}',
  defaultQuantityType: {
    Wholesale: '{{WHOLESALE_QUANTITY_TYPE}}',
    Retail: '{{RETAIL_QUANTITY_TYPE}}'
  },
  appConfig
}
