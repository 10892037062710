import GET_CLIENT from '@modules/wholesale/clientSettings/graphql/client'
import { action, observable, ObservableMap } from 'mobx'
import { apolloClient } from './apollo'

export class Client {
  @observable data = new ObservableMap()
  @observable loading = new ObservableMap()
  @observable error = new ObservableMap()

  @action
  fetchData = async (clientId: string) => {
    this.loading.set(clientId, true)
    try {
      const { data, error } = await apolloClient.query({
        query: GET_CLIENT,
        variables: {
          id: clientId
        }
      })
      this.data.set(clientId, data)
      this.error.set(clientId, error)
    } catch (e) {
      this.error.set(clientId, e)
    } finally {
      this.loading.set(clientId, false)
    }
    return this.data.get(clientId)
  }

  fetchDataIfNotExist = async (clientId: string) => {
    if (
      this.loading.get(clientId) !== true &&
      !this.error.has(clientId) &&
      !this.data.has(clientId)
    ) {
      return await this.fetchData(clientId)
    }
    return this.data.get(clientId)
  }

  @action
  clear = () => {
    this.data.clear()
    this.loading.clear()
    this.error.clear()
  }
}

const store = new Client()
export default store
