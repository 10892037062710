import Typography from '@material-ui/core/Typography'
import { useLocalStore, useObserver } from 'mobx-react-lite'
import React from 'react'
import TextTruncate from 'react-text-truncate'
import * as css from './ProductComment.css'

interface IReadMore {
  comment?: string
}

const showLess = (comment, onClick) => {
  return (
    <Typography>
      {comment}
      <br />
      <span className={css.anchor} onClick={onClick}>
        {' '}
        show less{' '}
      </span>
    </Typography>
  )
}

export const ReadMore: React.FunctionComponent<IReadMore> = ({ comment }) => {
  return useObserver(function useHook() {
    const state = useLocalStore(() => ({
      isShowLess: false,
      handleShowMore: () => {
        state.isShowLess = true
      },
      handleShowLess: () => {
        state.isShowLess = false
      }
    }))

    if (state.isShowLess) {
      return showLess(comment, state.handleShowLess)
    }

    return (
      <TextTruncate
        line={3}
        element='span'
        truncateText='…'
        text={comment}
        textTruncateChild={
          <>
            <br />
            <span className={css.anchor} onClick={state.handleShowMore}>
              {' '}
              show more{' '}
            </span>
          </>
        }
      />
    )
  })
}
