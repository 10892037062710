/*
 * @Author: Raeesaa Metkari
 * @Date: 2020-05-29 21:33:28
 * @Last Modified by: Raeesaa Metkari
 * @Last Modified time: 2020-07-14 01:39:50
 */
import { ACTIVITY_HIERARCHY_MAP, DEFAULT_ACTIVITY_HIERARCHY } from '@constants'
import { IGridViewProduct } from '@modules/common/models/interfaces/WholesaleGridProduct'
import { apolloClient, nav } from '@stores'
import capitalize from 'lodash/capitalize'
import includes from 'lodash/includes'
import isEqual from 'lodash/isEqual'
import remove from 'lodash/remove'
import { action, observable } from 'mobx'
import { WS_CLUSTER_ASSORTMENT_BY_ID } from '../graphql/clusterAssortmentById'
import { config } from '@config'
import { storage } from '@services/storageService'
import axios from 'axios'

type AttributeHierarchy = {
  activity: string
  childrenMeta: string[]
  childrenMetaValues: string[]
}

export class WholesaleAddProductToAssortmentStore {
  @observable isAddProductDrawerOpen = false
  @observable selectedProducts: string[] = []
  @observable attributeHierarchyForSelectedProduct: AttributeHierarchy[] = []

  @observable isBulkAddProductRequestInProgress: boolean = false

  onAddCb: any = () => {}

  @action toggleSelectedProduct = (event, processedProduct: IGridViewProduct) => {
    const buyingSessionProductId = processedProduct.productId

    if (includes(this.selectedProducts, buyingSessionProductId)) {
      remove(this.selectedProducts, productId => productId === buyingSessionProductId)
    } else {
      this.selectedProducts.push(buyingSessionProductId)

      // Save product hierarchy in store as it will be needed for refetch
      let activity = processedProduct?.node?.Original?.Activity?.Name ?? 'Undefined'
      let childrenMeta = ACTIVITY_HIERARCHY_MAP[activity] || DEFAULT_ACTIVITY_HIERARCHY
      let childrenMetaValues = []

      childrenMeta.forEach(currentCategory => {
        childrenMetaValues.push(
          processedProduct?.node?.Original?.[`${capitalize(currentCategory)}`]?.Name
        )
      })

      let attributeHierarchyObject: AttributeHierarchy = {
        activity,
        childrenMeta,
        childrenMetaValues
      }

      let hierarchyExists = this.attributeHierarchyForSelectedProduct.find(hierarchy =>
        isEqual(hierarchy, attributeHierarchyObject)
      )
      if (!hierarchyExists) {
        this.attributeHierarchyForSelectedProduct.push(attributeHierarchyObject)
      }
    }
  }

  updateOnAddCallback = onAddCb => {
    this.onAddCb = onAddCb
  }

  @action addProductsToClusterAssortment = async () => {
    if (this.isBulkAddProductRequestInProgress) {
      return
    }

    this.isBulkAddProductRequestInProgress = true

    const {
      queryParams: { assortment }
    } = nav

    try {
      // Query cluster assortment and fetch cluster id
      let clusterAssortment = await apolloClient.query({
        query: WS_CLUSTER_ASSORTMENT_BY_ID,
        variables: {
          clusterAssortmentId: assortment
        }
      })
      const body = {
        buyingSessionProductIds: this.selectedProducts,
        clusterIds: clusterAssortment && [
          clusterAssortment?.data?.getVIPClusterAssortment?.Cluster.Id
        ],
        clusterStatuses: [],
        channelIds: [],
        dropIds: [],
        freeTagIds: [],
        retailSalesPeriod: '',
        clientExclusions: []
      }
      const url = `${config.centricRestEndpoint}bulkUpdateBuyingSessionProducts`
      const sessionURL: string = storage.getItem('session_url')
      await axios.post(url, body, {
        headers: {
          Authorization: `Bearer ${sessionURL}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      this.onAddCb(this.attributeHierarchyForSelectedProduct)
    } finally {
      this.isBulkAddProductRequestInProgress = false

      // Clear store and close drawer
      this.closeDrawer()
    }
  }

  @action addAssortmentProduct = () => {
    this.isAddProductDrawerOpen = !this.isAddProductDrawerOpen
  }

  @action closeDrawer = () => {
    this.selectedProducts = []
    this.attributeHierarchyForSelectedProduct = []
    this.isAddProductDrawerOpen = !this.isAddProductDrawerOpen
  }
}

export const wholesaleAddProductToAssortment = new WholesaleAddProductToAssortmentStore()
