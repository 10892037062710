export enum clientOrderManagementTableViewColumnIdentifiers {
  clientName = 'clientName',
  clientCode = 'clientCode',
  country = 'country',
  doors = 'doors',
  shipTo = 'shipTo',
  creationDate = 'date',
  order = 'order',
  zam = 'zam',
  salesAsst = 'salesAsst',
  qty = 'qty',
  globalValue = 'globalValue',
  value = 'value',
  activityGender = 'activityGender',
  status = 'status',
  deliveryDate = 'deliveryDate',
  actions = 'actions',
  sapStatus = 'sapStatus',
  sapOrderNumber = 'sapOrderNumber',
  channel = 'channel'
}
