/*
 * @Author: Raeesaa Metkari
 * @Date: 2020-02-14 17:48:41
 * @Last Modified by: Raeesaa Metkari
 * @Last Modified time: 2020-02-19 17:32:42
 */

export enum UserRoles {
  ADMINISTRATOR = 'VIP Administrator Role',
  CENTRAL_MERCHANDISER_RETAIL = 'Central Merchandiser Retail Role',
  CENTRAL_MERCHANDISER_WHOLESALE = 'Central Merchandiser Wholesale Role',
  ZONE_AREA_MANAGER = 'Zone Area Manager Role',
  WHOLESALE_SHOWROOM_ASSISTANT = 'Wholesale Showroom Assistant Role',
  CM_ADMIN = 'Central Merchandiser Administrator Role',
  REGIONAL_MERCHANDISER = 'Regional Merchandiser Role',
  EXTERNAL_CLIENT = 'External Client Role'
}

export enum RetailRoles {
  CENTRAL_MERCHANDISER_RETAIL = 'Central Merchandiser Retail Role',
  REGIONAL_MERCHANDISER = 'Regional Merchandiser Role'
}

export enum WholesaleRoles {
  CENTRAL_MERCHANDISER_WHOLESALE = 'Central Merchandiser Wholesale Role',
  ZONE_AREA_MANAGER = 'Zone Area Manager Role',
  WHOLESALE_SHOWROOM_ASSISTANT = 'Wholesale Showroom Assistant Role'
}
