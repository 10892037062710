import React from 'react'
import Skeleton from 'react-loading-skeleton'
import * as css from './SAProductDetailsBasicCarousel.css'

export const SAProductDetailsBasicCarousel = () => {
  return (
    <div className={css.carousel}>
      <div className={css.carouselBox}>
        <Skeleton width={70} height={90} />
        <Skeleton width={70} height={90} />
        <Skeleton width={70} height={90} />
        <Skeleton width={70} height={90} />
      </div>
      <div className={css.carouselPreview}>
        <Skeleton width={470} height={455} />
      </div>
    </div>
  )
}
