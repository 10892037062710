import { config } from '@config'
import { tags } from '@constants'
import { ProductCardAttributeEnum } from '@modules/common/models/enums/ProductCardAttributeEnum'
import { Views } from '@modules/common/models/enums/Views'
import { stores, strings } from '@stores'
import cloneDeep from 'lodash/cloneDeep'
import sortBy from 'lodash/sortBy'
import { action, computed, observable } from 'mobx'
import { WholesaleAttributes, WholesaleLookAttributes } from 'src/constant/WholesaleTags'
import { userPreferencesStore } from '@stores/userPreferencesStore'
import isEmpty from 'lodash/isEmpty'

interface IAttributeList {
  name: string
  value: string
  isSelected: boolean
  disabled: boolean
  sortIndex: number
}

export class WholesaleAttributeSelectorStore {
  attributeList: Array<IAttributeList> = []

  @observable anchorEl = null
  @observable open = false
  @observable attributeSelectorOptions = { attributes: [], tags: [] }

  setAttributes = userPreferences => {
    const { attributes: attributesPref = {}, tags: tagsPref = {} } = userPreferences
    const attributeAliasNames = config?.appConfig?.attributes?.VIPProduct
    this.attributeList = attributeAliasNames.map(attribute => ({
      name: attribute.displayName,
      value: attribute.id,
      isSelected: !!attributesPref?.[attribute?.id],
      disabled: false,
      sortIndex: attribute.sortIndex
    }))
    this.attributeSelectorOptions = {
      attributes: [
        ...WholesaleAttributes.map(attribute => ({
          ...attribute,
          isSelected: !!attributesPref?.[attribute?.value]
        })),
        ...sortBy(this.attributeList, ['sortIndex']),
        ...WholesaleLookAttributes.map(lookAttribute => ({
          ...lookAttribute,
          isSelected: !!attributesPref?.[lookAttribute?.value]
        }))
      ],
      tags: tags.map(tag => ({
        ...tag,
        isSelected: !!tagsPref?.[tag?.value]
      }))
    }
  }

  initAttributes = async userPreferences => {
    let { attributes: attributesPref = {}, tags: tagsPref = {} } = userPreferences

    if (isEmpty(attributesPref) && isEmpty(tagsPref)) {
      const attributeAliasNames = config?.appConfig?.attributes?.VIPProduct
      const { savePreferences } = userPreferencesStore
      const initPreferences = {
        ...cloneDeep(userPreferences),
        tags: tags?.reduce(
          (acc, currentTag) => ({ ...acc, [currentTag.value]: currentTag.isSelected }),
          {}
        ),
        attributes: {
          ...WholesaleAttributes.reduce(
            (acc, currentAttr) => ({
              ...acc,
              [currentAttr.value]: currentAttr.isSelected
            }),
            {}
          ),
          ...attributeAliasNames.reduce(
            (acc, currentAttr) => ({
              ...acc,
              [currentAttr.id]: currentAttr.isDefaultSelected
            }),
            {}
          ),
          ...WholesaleLookAttributes.reduce(
            (acc, currentLookAttr) => ({
              ...acc,
              [currentLookAttr.value]: currentLookAttr.isSelected
            }),
            {}
          )
        }
      }
      try {
        await savePreferences(initPreferences)
        return Promise.resolve({
          attributes: initPreferences.attributes,
          tags: initPreferences.tags
        })
      } catch (e) {
        return Promise.resolve({
          attributes: {},
          tags: {}
        })
      }
    }
    return Promise.resolve(userPreferences)
  }

  @computed
  get selectedAttributes() {
    const selectionObj = {}
    const attributeSelectorOptions = cloneDeep(this.attributeSelectorOptions)
    Object.keys(attributeSelectorOptions).forEach(key =>
      attributeSelectorOptions[key]
        .filter(({ isSelected, disabled }) => isSelected && !disabled)
        .forEach(({ value }) => {
          selectionObj[value] = true
        })
    )
    return selectionObj
  }

  @action
  toggleCardOptions = (disabled: boolean) => {
    this.attributeSelectorOptions.attributes.forEach(attribute => {
      if (attribute.attributeName === ProductCardAttributeEnum.actions) {
        if (attribute.disabled !== disabled) {
          attribute.disabled = disabled
        }
      }
    })
  }

  @computed
  get selectedTab() {
    const {
      collection: { selectedViewAttributeSelectorTab }
    } = stores
    if (selectedViewAttributeSelectorTab === strings.defaultTabAttributeSelection) {
      return 'attributes'
    } else {
      return 'tags'
    }
  }

  @action
  onViewClick = (view: Views) => {
    const {
      nav: { updateQueryParams }
    } = stores

    updateQueryParams({
      productLayout: view
    })
  }
  @action
  handleClose = () => {
    this.anchorEl = null
    this.open = false
  }
  @action
  handleClick = event => {
    this.anchorEl = event.currentTarget
    this.open = true
  }
  @action
  updateAttributeSelection = async (value, checked) => {
    let selectedItems = 0
    const { preferences, savePreferences } = userPreferencesStore
    Object.keys(this.attributeSelectorOptions).forEach(key => {
      selectedItems += this.attributeSelectorOptions[key].filter(
        ({ isSelected }) => isSelected
      ).length
    })
    const indexOfAttr = this.attributeSelectorOptions[this.selectedTab]?.findIndex(
      attribute => value === attribute.value
    )
    if ((selectedItems < 10 || !checked) && -1 !== indexOfAttr) {
      try {
        const clonedPreferences = {
          ...cloneDeep(preferences)
        }
        clonedPreferences[this.selectedTab][value] = checked

        await savePreferences(clonedPreferences)
        this.attributeSelectorOptions[this.selectedTab][indexOfAttr].isSelected = checked
      } catch (e) {
        this.attributeSelectorOptions[this.selectedTab][indexOfAttr].isSelected = !checked
      }
    } else {
      this.attributeSelectorOptions[this.selectedTab][indexOfAttr].isSelected = !checked
    }
  }
}

export const wholesaleAttributeSelectorStore = new WholesaleAttributeSelectorStore()
