import { ordersListService } from '../service/orders-list-service'

export class OrdersDataStore {
  deleteOrder = async (orderId, orderType) => {
    const response = await ordersListService.deleteOrder(orderId, orderType)
    if (response?.data) {
      return response.data
    }
    return {}
  }

  createOrder = async (
    buyingSessionGroupId: string,
    clientId: string,
    orderName: string,
    doorIds: Array<string>
  ) => {
    const response = await ordersListService.createOrder(
      buyingSessionGroupId,
      clientId,
      orderName,
      doorIds
    )
    if (response?.data) {
      return response.data
    }
  }

  updateOrderStatus = async (orderIds, status, clientId?) => {
    try {
      const response = await ordersListService.updateOrderStatus(
        orderIds,
        status,
        clientId
      )
      if (response) {
        return response
      }
    } catch (e) {
      throw e
    }
  }

  getEmptyClientOrders = async orderIds => {
    const response: any = await ordersListService.getEmptyClientOrders(orderIds)
    if (response?.data) {
      return response.data?.emptyOrderNumbers
    }
  }

  getEmptyDoorOrders = async orderIds => {
    const response: any = await ordersListService.getEmptyDoorOrders(orderIds)
    if (response?.data) {
      return response.data?.emptyOrderNumbers
    }
  }

  getMyOrders = async (
    buyingSessionGroupId: string,
    clientId: string
  ): Promise<{ data: { macroOrders: any; buyingSessionGroup: any } }> => {
    const response = await ordersListService.getMyOrders(buyingSessionGroupId, clientId)
    if (response?.data) return response.data
  }
}

export const ordersDataStore = new OrdersDataStore()
