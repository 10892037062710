import {
  BUYING_SESSION_PRODUCT_CHANNEL,
  BUYING_SESSION_PRODUCT_SALES_PERIOD,
  CLUSTER,
  CLUSTER_STATUS_NAME,
  LOOK_NAME,
  NO_OF_FILTER_TO_SHOW
} from '@constants'
import { User } from '@models'
import { BUY_QUANTITY_FILTER, CORE_FILTERS } from '@modules/collection/constants'
import {
  getActivityValue,
  getFavouriteFilter,
  getFavouriteValue,
  getFilterValues,
  getProductStatusFilter,
  getRatingFilter
} from '@services/collectionServices'
import {
  getFilterByBuyQtyQueryObject,
  getFilterByClusterQueryObject
} from '@services/commonServices'
import { storage } from '@services/storageService'
import { stores } from '@stores'
import cloneDeep from 'lodash/cloneDeep'
import findIndex from 'lodash/findIndex'
import { action, computed, observable } from 'mobx'

export class FilterStore {
  @observable checkedFilters = {}
  @observable openCollectionFilter = false
  @observable finalFilterToApply = {}
  @observable anchorEl = null
  @observable productWhereInput = {}

  @action
  onFilterCheck = (filterType, filter, event) => {
    const newcheckedFilters = cloneDeep(this.checkedFilters[filterType])
      ? cloneDeep(this.checkedFilters[filterType])
      : []
    if (event.target.checked) {
      newcheckedFilters.push(filter)
    } else {
      const index = findIndex(newcheckedFilters, { value: filter.value })
      newcheckedFilters.splice(index, 1)
    }
    this.checkedFilters[filterType] = newcheckedFilters
    Object.assign(this, { checkedFilters: this.checkedFilters })
  }

  @action
  onFilterChipRemove = filter => {
    const { label, value } = filter
    const finalFilterToApply = cloneDeep(this.checkedFilters)
    Object.keys(finalFilterToApply).forEach(filterType => {
      if (!CORE_FILTERS.includes(filterType)) {
        const index = findIndex(finalFilterToApply[filterType], { value })
        if (index > -1) {
          finalFilterToApply[filterType].splice(index, 1)
        }
      } else {
        const index = findIndex(finalFilterToApply[filterType], { label })
        if (index > -1) {
          finalFilterToApply[filterType].splice(index, 1)
        }
      }
    })
    Object.assign(this, { finalFilterToApply, checkedFilters: finalFilterToApply })
    this.applyFilters()
  }

  @action
  getFilterChipsData = () => {
    const { finalFilterToApply } = this
    const chipsArrayToDisplay = []
    const chipsArrayInDropDown = []

    Object.keys(finalFilterToApply).forEach(filter => {
      finalFilterToApply[filter] &&
        finalFilterToApply[filter].forEach(item => {
          if (chipsArrayToDisplay.length < NO_OF_FILTER_TO_SHOW) {
            chipsArrayToDisplay.push(item)
          } else {
            chipsArrayInDropDown.push(item)
          }
        })
    })
    return {
      chipsArrayToDisplay: chipsArrayToDisplay || [],
      chipsArrayInDropDown: chipsArrayInDropDown || []
    }
  }

  @action
  toggleCollectionFilter = openCollectionFilter => {
    Object.assign(this, { openCollectionFilter })
  }

  @action
  cancelFilltering = () => {
    const checkedFilters = cloneDeep(this.finalFilterToApply)
    Object.assign(this, {
      checkedFilters: checkedFilters,
      openCollectionFilter: false
    })
  }

  getFlagFilter = finalFilterToApply => {
    if (
      finalFilterToApply['Flag Filter'] &&
      finalFilterToApply['Flag Filter'].length === 1
    ) {
      return {
        counterOffer_equals:
          finalFilterToApply['Flag Filter'][0].label === 'Flagged' ? true : false
      }
    }
    return undefined
  }

  @action
  clearAllFilters = () => {
    const {
      assortment: { changeLoadingStatus }
    } = stores
    changeLoadingStatus(true)
    Object.assign(this, {
      finalFilterToApply: {},
      checkedFilters: {},
      productWhereInput: {}
    })
  }

  @action
  applyFilters = () => {
    const {
      assortment: { changeLoadingStatus }
    } = stores
    const checkedFilters = cloneDeep(this.checkedFilters)
    const statusFilterValue = getActivityValue(checkedFilters)
    const coreFilter = []
    if (typeof statusFilterValue !== 'undefined') {
      coreFilter.push({ active: statusFilterValue })
    }
    const attributes = []
    Object.keys(checkedFilters).forEach(filterType => {
      if (!CORE_FILTERS.includes(filterType)) {
        const oR = []
        checkedFilters[filterType].length &&
          checkedFilters[filterType].forEach(({ label, value }) => {
            let attributeFilter
            if (filterType === LOOK_NAME) {
              attributeFilter = {
                OR: [
                  {
                    looks_some: {
                      name: value
                    }
                  },
                  {
                    substitutedInLooks_some: {
                      name: value
                    }
                  }
                ]
              }
            } else {
              attributeFilter = {
                attributes_some: {
                  definition: {
                    name: filterType
                  },
                  OR: [
                    {
                      strVal: value
                    },
                    {
                      tag: {
                        id: value
                      }
                    }
                  ]
                }
              }
            }
            oR.push(attributeFilter)
          })
        if (oR.length) {
          attributes.push({ OR: oR })
        }
      }
    })
    const finalQuery = { AND: [...coreFilter, ...attributes] }
    changeLoadingStatus(true)
    Object.assign(this, {
      finalFilterToApply: checkedFilters,
      productWhereInput: finalQuery,
      openCollectionFilter: false,
      hasMore: true
    })
  }

  @computed get buyingSessionLevelFilter() {
    const user = storage.getItem<User>('user')
    const { finalFilterToApply } = this
    const { ratingFilterNone, ratingFilterSome } = getRatingFilter(finalFilterToApply)
    const { favouriteFilterEvery, favouriteFilterSome } = getFavouriteFilter(
      getFavouriteValue(finalFilterToApply),
      user
    )
    const productStatusFilter = getProductStatusFilter(finalFilterToApply)
    const salesPeriodFilter = getFilterValues(
      finalFilterToApply,
      BUYING_SESSION_PRODUCT_SALES_PERIOD
    )
    const channelFilter = getFilterValues(
      finalFilterToApply,
      BUYING_SESSION_PRODUCT_CHANNEL
    )
    const clusterStatusFilter = getFilterValues(finalFilterToApply, CLUSTER_STATUS_NAME)
    const clusterFilter = getFilterValues(finalFilterToApply, CLUSTER)
    const buyQtyFilter = getFilterValues(finalFilterToApply, BUY_QUANTITY_FILTER)

    // FIXME: Write generic code for building buying session attribute filter query
    const buyingSessionLevelFilter = {
      ...(favouriteFilterEvery && { favourites_every: favouriteFilterEvery }),
      ...(favouriteFilterSome && { favourites_some: favouriteFilterSome }),
      ...(ratingFilterNone && { ratings_none: ratingFilterNone }),
      ...(ratingFilterSome && { ratings_some: ratingFilterSome }),
      ...(productStatusFilter && { productStatus: productStatusFilter }),
      ...(salesPeriodFilter && { salesPeriod: { id_in: salesPeriodFilter } }),
      ...(channelFilter && { channels_some: { id_in: channelFilter } }),
      ...(clusterStatusFilter && {
        productClusterStatus_some: { status: { id_in: clusterStatusFilter } }
      }),
      ...(clusterFilter && getFilterByClusterQueryObject(clusterFilter)),
      ...(buyQtyFilter && getFilterByBuyQtyQueryObject(buyQtyFilter))
    }

    return buyingSessionLevelFilter
  }
}

export const filter = new FilterStore()
