import { StyledProps } from '@components/3rd-party/mui'
import { useObserver } from 'mobx-react-lite'
import React from 'react'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import styled from 'styled-components'
import { stores, strings } from '@stores'
import { memo } from 'react'
import { TextAlignment } from '@modules/common/models/enums'
import { SplitIcon } from '@components/UI-Components/svg'
import { StyledTypographyComponent } from '../AverageDepth/AvgDepthTextFieldList'

interface IProps extends StyledProps {
  alignment?: TextAlignment
  data?: any
  canEdit?: boolean
  product?: any
  hasGlobalRow?: boolean
  dataKey?: string
  highlightGlobalQty?: boolean
}

// interface IStyleProps extends StyledProps {
//   alignment: TextAlignment
//   hasGlobalRow: boolean
//   highlightGlobalQty?: boolean
//   index?: number
//   hightlightBuyQty?: boolean
//   canEdit?: boolean
//   hasZoneAssortment?: boolean
//   record?: any
//   product?: any
// }

// interface IStyleSplitButtonProps {
//   hasZoneAssortment?: boolean
// }

export const StyledQuantityTextFieldComponent = styled(
  ({
    index,
    hasGlobalRow,
    alignment,
    hightlightBuyQty,
    highlightGlobalQty,
    canEdit,
    hasZoneAssortment,
    handleSplitButtonClick,
    record,
    product,
    ...rest
  }) => {
    return (
      <TextField
        {...rest}
        InputProps={{
          endAdornment:
            canEdit && hasGlobalRow && index === 0 ? (
              <InputAdornment position='end'>
                <img
                  src={SplitIcon}
                  alt={strings.splitQuantity}
                  onClick={event =>
                    hasZoneAssortment
                      ? handleSplitButtonClick(event, record, product)
                      : null
                  }
                />
              </InputAdornment>
            ) : null
        }}
      />
    )
  }
)(
  (props: any) =>
    ({
      '&&&&': {
        input: {
          padding: '0px',
          textAlign: props.alignment,
          font:
            props.hasGlobalRow && props.index === 0
              ? 'var(--typography-font-bold)'
              : 'var(--typography-body2)'
        },
        div: {
          alignItems: props.alignment,
          margin: 'var(--input-textfield-margin)',
          backgroundColor:
            props.highlightGlobalQty && props.index === 0 && 'var(--tertiary-yellow)',
          '&:before, &:hover:before, &:after': {
            borderBottom:
              props.hightlightBuyQty && props.index === 0
                ? '2px solid var(--tertiary-yellow)'
                : undefined
          },
          '> img': {
            width: 'var(--split-button-icon)',
            height: 'var(--split-button-icon)',
            cursor: 'pointer',
            marginLeft: '5px',
            // Show split button blur if zone assortment is not present
            opacity: props.hasZoneAssortment ? undefined : 0.5
          }
        }
      }
    } as any)
)

const Root = styled.div({
  height: 'var(--cell-height)',
  display: 'flex',
  flexDirection: 'row',
  borderBottom: 'var(--cell-border-bottom)',
  padding: 'var(--cell-padding)',
  alignItems: 'center',
  justifyContent: 'flex-end'
})

export const OrderQuantityList: React.FunctionComponent<IProps> = memo(
  (props: IProps) => {
    return useObserver(function useHook() {
      const [
        { dataKey, data, alignment, hasGlobalRow, canEdit, product, highlightGlobalQty },
        {
          product: {
            handleQuantityChange,
            updateProductQuantity,
            handleKeyDown,
            handleSplitButtonClick,
            onFocusQuantity,
            selectedProducts
          },
          assortment: { selectedAssortmentTab }
        }
      ] = [props, stores]

      return (
        <>
          {data &&
            data.map((record, index) => {
              const selectedProduct = selectedProducts.get(record.id)

              return (
                <Root key={record.id}>
                  {canEdit &&
                  (selectedAssortmentTab === 2
                    ? product.active || (!product.active && record[dataKey!] > 0)
                    : true) ? (
                    <StyledQuantityTextFieldComponent
                      name={strings.listViewInputField}
                      value={
                        selectedProduct
                          ? selectedProduct.selectedProductQuantity === null
                            ? ''
                            : selectedProduct.selectedProductQuantity
                          : record[dataKey!]
                      }
                      alignment={alignment}
                      index={index}
                      hasGlobalRow={hasGlobalRow}
                      highlightGlobalQty={highlightGlobalQty}
                      hightlightBuyQty={
                        selectedAssortmentTab === 2 && record.betQty && !record.buyQty
                      }
                      canEdit={canEdit}
                      onFocus={event => onFocusQuantity(event, record)}
                      onChange={event => handleQuantityChange(event, record, product)}
                      onBlur={event => updateProductQuantity(event, record, product)}
                      onKeyDown={event => handleKeyDown(event, record, product)}
                      hasZoneAssortment={data.length > 1}
                      record={record}
                      product={product}
                      handleSplitButtonClick={handleSplitButtonClick}
                    />
                  ) : (
                    <StyledTypographyComponent
                      alignment={alignment}
                      index={index}
                      hasGlobalRow={hasGlobalRow}
                    >
                      {record[dataKey!]}
                    </StyledTypographyComponent>
                  )}
                </Root>
              )
            })}
        </>
      )
    })
  }
)
