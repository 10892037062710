import React from 'react'
import StyledBootStrapInput from '../StyledBootStrapInput'
import { validateInteger } from '@services/commonServices'

export const enum AlignmentType {
  RIGHT = 'right',
  LEFT = 'left',
  CENTER = 'center'
}

export interface SASizeInputInterface {
  inputValue?: string
  handleInputChange?: any
  handleKeyDown?: any
  isInputDisabled?: boolean
  tabIndex?: any
  inputref?: any
  width?: number
  height?: number
  alignment?: AlignmentType
  isInputInactive?: boolean // not disabled, but greyed out
}

const INPUT_WIDTH = 45
const INPUT_HEIGHT = 30

export const SANumberInput: React.FunctionComponent<SASizeInputInterface> = ({
  handleInputChange,
  handleKeyDown,
  isInputDisabled,
  inputValue,
  tabIndex,
  inputref,
  width,
  height,
  alignment,
  isInputInactive
}) => {
  const [value, setValue] = React.useState<any>(inputValue || 0)

  React.useEffect(() => {
    setValue(inputValue)
  }, [inputValue])

  const onInputChange = changedValue => {
    if (validateInteger(changedValue)) {
      setValue(changedValue)
    }
  }
  const onFocus = e => {
    if (!parseInt(e.target.value)) {
      setValue('')
    }
  }

  const onBlur = e => {
    if (e?.target?.value === '') {
      setValue('0')
    }
    handleInputChange(e)
  }
  return (
    <StyledBootStrapInput
      alignment={alignment || AlignmentType.RIGHT}
      width={width ?? INPUT_WIDTH}
      height={height ?? INPUT_HEIGHT}
      inputProps={{
        inputMode: 'numeric',
        tabIndex: tabIndex,
        ref: inputref
      }}
      onScroll={e => e.stopPropagation()}
      font={'var(--typography-headline-bold)'}
      disabled={isInputDisabled}
      onChange={e => onInputChange(e.target.value)}
      onBlur={onBlur}
      onKeyDown={e => handleKeyDown(e, value)}
      onFocus={onFocus}
      value={value}
      isInputInactive={isInputInactive}
    />
  )
}
