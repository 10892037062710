import { useMutation } from '@apollo/client/react/hooks/useMutation'
import { useQuery } from '@apollo/client/react/hooks/useQuery'
import { stores } from '@stores'
import {
  CREATE_DELIVERY_WINDOW,
  DELETE_DELIVERY_WINDOW,
  EDIT_DELIVERY_WINDOW,
  GET_DELIVERY_WINDOW,
  GET_DELIVERY_WINDOW_FOR_BUYING_SESSION_GROUP,
  SET_DELIVERY_WINDOW_FOR_BUYING_SESSION_GROUP
} from '.'

export interface IDeliveryWindow {
  deliveryWindowId?: string
  name?: string
  startDate?: any
  endDate?: any
  buyingSessionGroupId?: string
}

/**
 * Hook for fetching delivery window data from buying session group
 */
export const useDeliveryWindow = () => {
  const {
    deliveryWindowSetting: { deliveryWindowWhereInput }
  } = stores
  return useQuery(GET_DELIVERY_WINDOW, {
    fetchPolicy: 'cache-and-network',
    skip: !deliveryWindowWhereInput,
    variables: deliveryWindowWhereInput
  })
}

export const useCreateDeliveryWindow = () => {
  const [ref] = useMutation(CREATE_DELIVERY_WINDOW)
  return ref
}

export const useEditDeliveryWindow = () => {
  const [ref] = useMutation(EDIT_DELIVERY_WINDOW)
  return ref
}

export const useDeleteDeliveryWindow = () => {
  const [ref] = useMutation(DELETE_DELIVERY_WINDOW)
  return ref
}

export const useDeliveryWindowForBuyingSessionGroup = () => {
  const {
    deliveryWindowSetting: { deliveryWindowWhereInput }
  } = stores
  return useQuery(GET_DELIVERY_WINDOW_FOR_BUYING_SESSION_GROUP, {
    fetchPolicy: 'cache-and-network',
    skip: !deliveryWindowWhereInput,
    variables: deliveryWindowWhereInput
  })
}

export const useSetDeliveryWindowForBuyingSessionGroup = () => {
  const [ref] = useMutation(SET_DELIVERY_WINDOW_FOR_BUYING_SESSION_GROUP)
  return ref
}
