export enum WholesaleGlobalSettingIds {
  priceList = 'priceList',
  clientsDataCode = 'clientsDataCode',
  markupOrDiscounts = 'markupOrDiscounts'
}

export enum WholesaleGlobalSettingValues {
  priceList = 'Price List',
  clientsDataCode = 'Clients Data',
  markupOrDiscounts = 'Discounts and Markup'
}

export enum WholesaleBSPSettingIds {
  buyingSessionProduct = 'buyingSessionProduct',
  priceValidityDate = 'priceValidityDate'
}

export enum WholesaleBSPSettingValues {
  buyingSessionProduct = 'Buying Session Product',
  priceValidityDate = 'Price Validity Date'
}

export const bspSyncSettings = [
  {
    Id: WholesaleBSPSettingIds.buyingSessionProduct,
    Name: WholesaleBSPSettingValues.buyingSessionProduct
  },
  {
    Id: WholesaleBSPSettingIds.priceValidityDate,
    Name: WholesaleBSPSettingValues.priceValidityDate
  }
]

export const generalSetting = [
  {
    Id: WholesaleGlobalSettingIds.priceList,
    Name: WholesaleGlobalSettingValues.priceList
  },
  {
    Id: WholesaleGlobalSettingIds.clientsDataCode,
    Name: WholesaleGlobalSettingValues.clientsDataCode
  },
  {
    Id: WholesaleGlobalSettingIds.markupOrDiscounts,
    Name: WholesaleGlobalSettingValues.markupOrDiscounts
  }
]

export enum syncDataTypes {
  buyingSession = 'buyingSession',
  globalLevelSettings = 'globalLevelSettings'
}
