/*
 * @Author: Raeesaa Metkari
 * @Date: 2020-03-20 17:44:58
 * @Last Modified by: Raeesaa Metkari
 * @Last Modified time: 2020-03-27 14:29:15
 */

export enum WholesaleSettingsSection {
  Settings = 'settings',
  Clients = 'clients',
  Discounts = 'discounts',
  WholesaleSettings = 'wholesaleSettings',
  RetailSettings = 'retailSettings',
  Stores = 'stores',
  Memberships = 'memberships',
  MarkupOrDiscount = 'markupOrDiscount',
  SalesPeriods = 'salesPeriods',
  Zones = 'zones'
}

export enum WholesaleSettingsSectionDisplayName {
  Settings = 'General Settings',
  Clients = 'MARKET',
  Discounts = 'Discounts',
  WholesaleSettings = 'Wholesale Settings',
  RetailSettings = 'Retail Settings',
  Stores = 'Stores',
  Memberships = 'Memberships',
  MarkupOrDiscount = 'Markup/Discount',
  SalesPeriods = 'Sales Periods',
  Zones = 'Zones'
}

export enum WholesaleSettingsSectionToolbarTitle {
  settings = 'General Settings',
  clients = 'MARKET Settings',
  zones = 'Zone Settings',
  discounts = 'DISCOUNTS'
}

export enum WholesaleSettingsView {
  Membership = 'membership',
  Clusters = 'clusters',
  Worldwide = 'worldwide',
  SeasonsSyncUp = 'seasonsSyncUp',
  GlobalDataSync = 'globalDataSync',
  ActivitySelection = 'activitySelection',
  Discounts = 'discounts',
  Memberships = 'memberships',
  MarkupOrDiscount = 'markupOrDiscount'
}

export enum WholesaleSettingsViewDisplayName {
  Membership = 'Membership',
  Clusters = 'Clusters',
  Worldwide = 'WORLDWIDE',
  SeasonsSyncUp = 'Seasons Sync Up',
  GlobalDataSync = 'Sync Data',
  Discounts = 'Discounts',
  ActivitySelection = 'Category Selection'
}

/**
 * NOTE: In case view toolbar title is not found, display
 * section toolbar title instead
 */
export enum WholesaleSettingsViewToolbarTitle {
  memberships = 'Memberships',
  clusters = 'Clusters',
  seasonsSyncUp = 'Seasons Sync Up',
  globalDataSync = 'Sync Data',
  activitySelection = 'Category Selection',
  discounts = 'Discounts',
  markup = 'Markup'
}
