import mapValues from 'lodash/mapValues'

const sortObjectValues = (
  sortingKeys: string[],
  data: any,
  property: string,
  sortingFunction: Function
) => {
  mapValues(data, dataObject => {
    if (sortingKeys.includes(dataObject.key)) {
      dataObject.values = sortingFunction(dataObject.values, property)
    }
  })
}
export default sortObjectValues
