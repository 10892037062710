
    
    var doc = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"zones"},"variableDefinitions":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"zones"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"stores"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"storeCode"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"allotments"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"where"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"clusterID_not"},"value":{"kind":"NullValue"}}]}}],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"clusterID"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"activityID"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"genderID"},"arguments":[],"directives":[]}]}}]}}]}}]}}]};
  

    
  var names = {};
  function unique(defs) {
    return defs.filter(function (def) {
      if (def.kind !== 'FragmentDefinition') return true;
      var name = def.name.value;
      if (names[name]) {
        return false;
      } else {
        names[name] = true;
        return true;
      }
    });
  };

  

    module.exports = doc
  
