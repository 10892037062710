import {
  ListViewColumnDefinition,
  ListViewRowSelectionConf
} from '@components/UI-Components/StandardTableView/models/ListviewColumnDefinition'
import { StickyStyle, TextAlignment } from '@modules/common/models'
import styled from 'styled-components'

export interface ListViewRowProps {
  headers: Array<ListViewColumnDefinition>
  style?: any
  kpiNode?: any
  canTableFitInView?: boolean
  loading?: boolean
  products?: Array<any>
  missingProducts?: Array<any>
  stickyStyle?: StickyStyle
  handleDrop?: Function
  isWholesale?: boolean
  isShowroomView?: boolean
  skeletonImageWidth?: number
  emptyColWidth?: number
  rowSelectionConf?: ListViewRowSelectionConf
  onClick?: any
  productRowHeight?: number
}

const LISTVIEWROWBORDER = 2
interface RowProps {
  height?: number
  rowInactive?: boolean
}
export const Row = styled.div<RowProps>(props => {
  return {
    width: '100%',
    height: props.height,
    background: 'var(--background-white)',
    whiteSpace: 'nowrap',
    color: 'rgba(0, 0, 0, 0.05)',
    borderBottom: `solid ${LISTVIEWROWBORDER}px rgba(0, 0, 0, 0.05)`,
    opacity: props.rowInactive && 0.6
  }
})

interface CellProps {
  cellWidth: number
  cellHeight?: number
  cellPad?: string
  alignment?: any
  cellZIndex?: number
  cellTextWrap?: boolean
  isDisabled?: boolean
  showLeftBorder?: boolean
  showRightBorder?: boolean
  stickyLeft?: number
  stickyRight?: number
  isCellShadow?: boolean
  isUpperCase?: boolean
}

export const Cell = styled.div<CellProps>(props => {
  const {
    cellPad,
    alignment,
    cellZIndex,
    cellHeight,
    isDisabled,
    showLeftBorder,
    showRightBorder,
    stickyLeft,
    isCellShadow,
    isUpperCase,
    stickyRight
  } = props

  const isSticky = stickyLeft || stickyRight || stickyLeft === 0 || stickyRight === 0
  return {
    '&&': {
      whiteSpace: 'pre-wrap',
      verticalAlign: 'text-top',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      backgroundColor: 'var(--background-white)',
      display: 'inline-block',
      font: 'var(--typography-body3)',
      letterSpacing: 'var(--label-typography-letter-spacing)',
      color: 'var(--black)',
      textTransform: isUpperCase ? 'uppercase' : 'none',
      width: props.cellWidth,
      height: cellHeight ? `${cellHeight}px` : `calc(100% - ${LISTVIEWROWBORDER}px)`,
      position: isSticky ? 'sticky' : undefined,
      left: isSticky ? `${stickyLeft}px` : 0,
      right: isSticky ? `${stickyRight}px` : 0,
      zIndex: isSticky ? (cellZIndex ? cellZIndex : 2) : undefined,
      padding: cellPad,
      borderLeft: showLeftBorder && 'var(--cell-border-bottom)',
      borderRight: showRightBorder && 'var(--cell-border-bottom)',
      textAlign: alignment ? alignment : TextAlignment.left,
      boxShadow: isCellShadow ? '8px 0 5px -3px rgba(133, 146, 166, 0.2)' : undefined,
      opacity: isDisabled ? 0.3 : undefined
    }
  }
})
