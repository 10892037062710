import { StyledProps } from '@components/3rd-party/mui'
import { StyledTypographyComponent } from '@components/UI-Components/AverageDepth'
import { TextAlignment } from '@modules/common/models/enums'
import TextField from '@material-ui/core/TextField'
import { ApplyToFixedIfDecimal, ApplyToFixedIfNumber } from '@services/commonServices'
import { stores, strings } from '@stores'
import { useObserver } from 'mobx-react-lite'
import React, { memo } from 'react'
import styled from 'styled-components'

interface IProps extends StyledProps {
  alignment?: TextAlignment
  data?: any
  canEdit?: boolean
  product?: any
  hasGlobalRow?: boolean
  dataKey?: string
  isFloatingPointValue?: boolean
}

interface IStyleProps extends StyledProps {
  alignment: TextAlignment
  hasGlobalRow: boolean
  index?: number
}

export const StyledAWSTextFieldComponent = styled(
  ({ index, hasGlobalRow, alignment, ...rest }) => {
    return <TextField {...rest} />
  }
)((props: IStyleProps) => ({
  '&': {
    input: {
      padding: '0px',
      textAlign: props.alignment,
      font:
        props.hasGlobalRow && props.index === 0
          ? 'var(--typography-font-bold)'
          : 'var(--typography-body2)'
    },
    '> div': {
      alignItems: props.alignment
    }
  }
}))

export const Root = styled.div({
  height: 'var(--cell-height)',
  borderBottom: 'var(--cell-border-bottom)',
  padding: 'var(--cell-padding)',
  display: 'flex',
  alignItems: 'center'
})

export const AWSTextFieldList: React.FunctionComponent<IProps> = memo((props: IProps) => {
  return useObserver(function useHook() {
    const [
      { dataKey, data, alignment, hasGlobalRow, canEdit, product, isFloatingPointValue },
      {
        product: {
          handleAWSChange,
          updateAWS,
          handleKeyDown,
          onFocusAWS,
          selectedProducts
        }
      }
    ] = [props, stores]

    return (
      <>
        {data &&
          data.map((record, index) => {
            const selectedProduct = selectedProducts.get(record.id)
            return (
              <Root key={record.id}>
                {canEdit ? (
                  <StyledAWSTextFieldComponent
                    name={strings.listViewInputField}
                    value={
                      selectedProduct
                        ? selectedProduct.selectedProductAWS === null
                          ? ''
                          : selectedProduct.selectedProductAWS
                        : isFloatingPointValue
                        ? ApplyToFixedIfDecimal(record[dataKey!])
                        : record[dataKey!]
                    }
                    alignment={alignment}
                    index={index}
                    hasGlobalRow={hasGlobalRow}
                    onFocus={event => onFocusAWS(event, record)}
                    onChange={event => handleAWSChange(event, record, product)}
                    onBlur={event => updateAWS(event, record, product)}
                    onKeyDown={event => handleKeyDown(event, record, product)}
                  />
                ) : (
                  <StyledTypographyComponent
                    alignment={alignment}
                    index={index}
                    hasGlobalRow={hasGlobalRow}
                  >
                    {isFloatingPointValue
                      ? ApplyToFixedIfNumber(record[dataKey!])
                      : record[dataKey!]}
                  </StyledTypographyComponent>
                )}
              </Root>
            )
          })}
      </>
    )
  })
})
