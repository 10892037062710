import '@styles/Chip/style.css'
import cn from 'classnames'
import React from 'react'
import ReactTooltip, { TooltipProps } from 'react-tooltip'
import { isMobile } from 'react-device-detect'

interface IChip extends TooltipProps {
  tooltip?: string
  className?: string
}

export const Chip: React.FC<IChip> = props => {
  const { tooltip, className, children, ...tooltipProps } = props

  return (
    <div title={tooltip} className={cn('chip', className)} data-tip={tooltip}>
      {tooltip && (
        <ReactTooltip
          clickable={isMobile}
          effect={'solid'}
          // delayHide={3000}
          {...tooltipProps}
        />
      )}
      {children}
    </div>
  )
}
