import { UserRoles } from '@modules/common/models/enums/UserRoles'
import { getHighestUser, IPermissionsDict } from '@routes'
import { isExternalClient, isRegionalMerchandiserRole } from '@services/commonServices'
import { storage } from '@services/storageService'
import { stores, strings } from '@stores'
import { userPreferencesStore } from '@stores/userPreferencesStore'
import includes from 'lodash/includes'
import React, { useContext } from 'react'
import { Redirect } from 'react-router-dom'
import { AuthorizationError } from './AuthorizationError'
import { Content } from './Content'
import { VIPClientType } from '@modules/common/models/enums/VIPClientType'
import { getEnumValue } from '@utils/get-enum-value'

export interface AuthorizationProps {
  component?: React.ReactNode
  roles?: string
  allowedRoles?: Array<any>
  isNavigation?: boolean
}

export const AuthorizationContext = React.createContext<{
  permissions: IPermissionsDict
}>({ permissions: {} })
export const useAuthorizationContext = () => useContext(AuthorizationContext)

export class Authorization extends React.Component<AuthorizationProps> {
  render() {
    const { props } = this
    const highestUser = getHighestUser()
    let permissions = null
    let highestRoleWithPermission = null
    const isExternalClientLoggedIn = isExternalClient()
    const isRegionalMerchandiser = isRegionalMerchandiserRole()
    const user: any = storage.getItem('user')
    userPreferencesStore.fetchPreferences()
    // Check for route and role

    // Ensure access to externalClient who is working with client associated with him.Using any other client id will be Authorization error
    const {
      nav: {
        queryParams: { clientId }
      }
    } = stores

    if (
      includes(
        [
          UserRoles.WHOLESALE_SHOWROOM_ASSISTANT,
          UserRoles.EXTERNAL_CLIENT,
          UserRoles.REGIONAL_MERCHANDISER
        ],
        highestUser?.[0]?.name
      ) &&
      window.location.pathname.includes('/wholesale')
    ) {
      const client = user?.client || ''
      const clientUrl = `/showroom/clients/buyingSessionGroup?clientId=${client}&clientType=${getEnumValue(
        isExternalClientLoggedIn
          ? VIPClientType.External
          : isRegionalMerchandiser
          ? VIPClientType.Internal
          : null
      )}`
      return isExternalClientLoggedIn || isRegionalMerchandiser ? (
        <Redirect to={clientUrl} />
      ) : (
        <Redirect to='/showroom' />
      )
    } else if (
      // Zone area manager and Admin can have access to showroom application
      // GBB-7066: GlobalCM, wholesale CM, retail CM can access to showroom application
      !includes(
        [
          UserRoles.WHOLESALE_SHOWROOM_ASSISTANT,
          UserRoles.ZONE_AREA_MANAGER,
          UserRoles.ADMINISTRATOR,
          UserRoles.EXTERNAL_CLIENT,
          UserRoles.REGIONAL_MERCHANDISER,
          UserRoles.CENTRAL_MERCHANDISER_RETAIL,
          UserRoles.CENTRAL_MERCHANDISER_WHOLESALE,
          UserRoles.CM_ADMIN
        ],
        highestUser?.[0]?.name
      ) &&
      window.location.pathname.includes('/showroom')
    ) {
      return <Redirect to='/wholesale' />
    }

    const {
      nav: { queryParams }
    } = stores

    if (
      (isExternalClientLoggedIn || isRegionalMerchandiser) &&
      'clientId' in queryParams
    ) {
      const client = user?.client || ''
      if (client !== clientId) {
        return (
          <AuthorizationError
            errorMessage={strings.externalClient.externalClientUnauthorizedMessage}
          />
        )
      }
    }

    if (highestUser.length) {
      let highestWeight = highestUser[0].weight
      let allRolesWithHighestWeight = highestUser.filter(
        role => role.weight === highestWeight
      )
      highestRoleWithPermission = allRolesWithHighestWeight.find(role => {
        permissions = props.allowedRoles ? props.allowedRoles[role.name] : null
        return permissions
      })
    }

    return highestRoleWithPermission || !props.allowedRoles ? (
      <AuthorizationContext.Provider value={{ permissions }}>
        <Content permissions={permissions} {...props} />
      </AuthorizationContext.Provider>
    ) : props.isNavigation ? null : (
      <AuthorizationError />
    )
  }
}
