/*
 * @Author: Raeesaa Metkari
 * @Date: 2019-08-20 12:03:11
 * @Last Modified by: Raeesaa Metkari
 * @Last Modified time: 2019-08-21 02:21:19
 */
import { stores } from '@stores'
import isEmpty from 'lodash/isEmpty'
import { useObserver } from 'mobx-react-lite'
import React from 'react'

import { StoreOTBWeightSettingsHeader } from '../component/StoreOTBWeightSettingsHeader'
import { StoreOTBWeightSettingsTable } from '../component/StoreOTBWeightSettingsTable'
import {
  useActivities,
  useGenders,
  useStoreWeightsForZone,
  useZones
} from '../graphql/hooks'
import * as css from './StoreOTBWeightSettings.css'

interface MyProps {}

export const StoreOTBWeightSettings: React.FunctionComponent<MyProps> = () => {
  return useObserver(function useHook() {
    const {
      storeSettings: { selectedZone, getStoresByZone, generateStoreWeightData }
    } = stores
    let { data: zoneResponse, loading: loadingZones } = useZones()
    let { data: activityResponse, loading: loadingActivities } = useActivities()
    let { data: genderResponse, loading: loadingGenders } = useGenders()
    let { data: storeWeightResponse, loading: loadingStoreWeights } =
      useStoreWeightsForZone(selectedZone, !selectedZone)

    const zones = !isEmpty(zoneResponse) ? zoneResponse.zones || [] : []
    const activities = !isEmpty(activityResponse) ? activityResponse.activities || [] : []
    const genders = !isEmpty(genderResponse) ? genderResponse.genders || [] : []
    const storeWeights = !isEmpty(storeWeightResponse)
      ? storeWeightResponse.storeWeights || []
      : []

    let zoneStores = getStoresByZone(selectedZone, zones)
    let storeWeightData = generateStoreWeightData(
      zoneStores as any[],
      activities,
      genders,
      storeWeights
    )

    return (
      <div className={css.root}>
        <StoreOTBWeightSettingsHeader zones={zones} />

        <StoreOTBWeightSettingsTable
          storeWeights={storeWeightData}
          loading={
            loadingZones || loadingActivities || loadingGenders || loadingStoreWeights
          }
        />
      </div>
    )
  })
}
