/*
 * @Author: Raeesaa Metkari
 * @Date: 2020-03-27 13:59:50
 * @Last Modified by: Raeesaa Metkari
 * @Last Modified time: 2020-03-27 14:02:35
 */

export enum GraphQLRootTypes {
  Query = 'Query',
  Mutation = 'Mutation',
  Subscription = 'Subscription'
}

export enum CentricGraphQLStandardTypeNames {
  CustomActionResult = 'CustomActionResult',
  NodeInfo = 'NodeInfo'
}

export enum CentricGraphQLMutationResponseStatus {
  Success = 'SUCCESS'
}
