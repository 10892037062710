/* eslint-disable import/first */
export { strings_en as strings } from './strings'

import { ActivitySettingStore } from '@modules/retail/activitySettings/store/ActivitySettingStore'
import { retailAssortmentStore, RetailAssortmentStore } from '@modules/retail/assortment'
import {
  assortment,
  AssortmentStore
} from '@modules/retail/assortment/store/AssortmentStore'
import { BuyingSessionStore } from '@modules/retail/buyingSession/store/BuyingSessionStore'
import { ClusterStore } from '@modules/retail/cluster/store/ClusterStore'
import {
  ClusterStatusDropDownStore,
  CollectionStore,
  filter,
  FilterStore,
  RestrictProductByZoneModalStore,
  ZoneWiseCommentsStore
} from '@modules/retail/collection/store'
import { CollectionBriefStore } from '@modules/retail/collectionBrief/store/CollectionBriefStore'
import { CollectionImageStore } from '@modules/retail/collectionImage/store/CollectionImageStore'
import {
  currencySettings,
  CurrencySettingsStore
} from '@modules/retail/currencySetting/store/CurrencySettingsStore'
import { lookbook, LookBookStore } from '@modules/retail/lookbook/store/LookBookStore'
import {
  notification,
  NotificationStore
} from '@modules/retail/notifications/stores/Notification'
import { SeasonSyncStore } from '@modules/retail/seasonSync/store/SeasonSyncStore'
import {
  importOrders,
  ImportOrdersStore,
  ordersUIStore,
  showroomOrderManagementStore,
  ShowroomOrderManagementStore
} from '@modules/showroom/basketAndOrders/store'
import { OrdersUIStore } from '@modules/showroom/basketAndOrders/store/ordersUIStore'
import {
  BuyingSessionSelectionStore,
  showroomBuyingSessionSelection
} from '@modules/showroom/buyingSessions/stores/BuyingSessionSelectionStore'
import {
  showroomClientSelection,
  ShowroomClientSelectionStore
} from '@modules/showroom/clients/stores/ShowroomClientSelectionStore'
import {
  showroomCollection,
  ShowRoomCollectionStore,
  sizedQtyStore,
  SizedQtyStore
} from '@modules/showroom/collection/stores'
import {
  showroomDashboardStore,
  ShowroomDashboardStore
} from '@modules/showroom/dashboard/store/ShowroomDashboardStore'
import {
  showroomHeader,
  ShowroomHeaderStore
} from '@modules/showroom/header/stores/ShowroomHeaderStore'
import {
  showroomProductDetailStore,
  ShowroomProductDetailStore
} from '@modules/showroom/productDetail/store/ShowroomProductDetailStore'
import {
  showroomSidebarStore,
  ShowroomSidebarStore
} from '@modules/showroom/Sidebar/stores/sidebarStore'
import { StoreImportStore } from '@modules/stores/stores/StoreImportStore'
import { storeSettings, StoreSettingsStore } from '@modules/stores/stores/StoreSettings'
import {
  activitySelectionSettings,
  ActivitySelectionSettingsStore
} from '@modules/wholesale/activitySelectionSettings/stores/ActivitySelectionSettingsStore'
import {
  wholesaleAddProductToAssortment,
  WholesaleAddProductToAssortmentStore
} from '@modules/wholesale/assortmentProductWrapper/stores/WholesaleAddProductToAssortmentStore'
import { WholesaleAssortmentProductStore } from '@modules/wholesale/assortmentProductWrapper/stores/WholesaleAssortmentProductStore'
import {
  buyingGroupSetting,
  BuyingGroupSettingStore
} from '@modules/wholesale/buyingGroupSettings/stores/BuyingGroupSettingStore'
import {
  campaignStore,
  CampaignStore
} from '@modules/wholesale/buyingSessionCampaign/stores/campaignStore'
import {
  wholesaleAttributeSelectorStore,
  WholesaleAttributeSelectorStore
} from '@modules/wholesale/buyingSessionCollection/stores/WholesaleAttributeSelectorStore'
import {
  buyingSessionDocumentStore,
  BuyingSessionDocumentStore
} from '@modules/wholesale/buyingSessionDocuments/store/BuyingSessionDocumentStore'
import { WSBuyingSessionProductStore } from '@modules/wholesale/buyingSessionGroupProductWrapper/stores/WSBuyingSessionProductStore'
import {
  clientDiscount,
  ClientDiscountStore
} from '@modules/wholesale/clientDiscount/stores/ClientDiscountStore'
import {
  clientSearch,
  ClientSearchStore
} from '@modules/wholesale/clientSearch/stores/ClientSearchStore'
import {
  clientManagementStore,
  ClientManagementStore
} from '@modules/wholesale/clientSettings/stores/ClientManagementStore'
import {
  clusterExclusionMultiSelect,
  ClusterExclusionMultiSelectStore
} from '@modules/wholesale/clusterExclusionMultiSelect/stores/ClusterExclusionMultiSelectStore'
import { WholesaleBuyingSessionStore } from '@modules/wholesale/createBuyingSession/stores/BuyingSessionStore'
import {
  deliveryWindowSetting,
  DeliveryWindowSettingStore
} from '@modules/wholesale/deliveryWindowSettings/stores/DeliveryWindowSettingStore'
import {
  doorManagementStore,
  DoorManagementStore
} from '@modules/wholesale/doorSettings/stores/DoorManagementStore'
import {
  dropSetting,
  DropSettingStore
} from '@modules/wholesale/dropSettings/stores/DropSettingStore'
import { WSOrderManagementStore } from '@modules/wholesale/orderManagement/stores/WsOrderManagementStore'
import {
  productDetail,
  ProductDetailStore
} from '@modules/wholesale/productDetail/stores/ProductDetailStore'
import {
  salesDocTypeStore,
  SalesDocTypeStore
} from '@modules/wholesale/salesDocType/stores/SalesDocTypeStore'
import {
  seasonSyncSettings,
  SeasonSyncSettingsStore
} from '@modules/wholesale/seasonSyncSettings/stores/SeasonSyncSettingsStore'
import {
  syncSettingsStore,
  SyncSettingsStore
} from '@modules/wholesale/syncSetting/stores/SyncSettingsStore'
import {
  buyingSessionGroupSidebar,
  BuyingSessionGroupSidebarStore
} from '@modules/wholesale/wsNavigations/stores/BuyingSessionGroupSidebarStore'
import {
  freeTagListStore,
  FreeTagListStore
} from '@modules/wholesale/wsNavigations/stores/FreeTagListStore'
import {
  wholesaleSettingsSidebar,
  WholesaleSettingsSidebarStore
} from '@modules/wholesale/wsNavigations/stores/WholesaleSettingsStore'
import { RouterStore } from 'mobx-react-router'
import { AxiosStore } from './axios'
import buyingSessionGroup, { BuyingSessionGroupStore } from './BuyingSessionGroup'
import client, { Client } from './Client'
import { filterModalStore, FilterModalStore } from './FilterModalStore'
import { ListViewStore } from './ListViewStore'
import { nav, NavStore } from './nav'
import type { SortOption } from './product'
import { product, ProductStore } from './product'
import { ProductPricesStore, productPriceStore } from './productPricesStore'
import { search, SearchStore } from './searchStore'
import { sidebar, SidebarStore } from './SidebarStore'
import { site, SiteStore } from './SiteStore'
import { toolbar, ToolbarStore } from './ToolbarStore'

interface Stores extends Partial<{ site: SiteStore }> {
  client: Client
  assortment: AssortmentStore
  lookbook: LookBookStore
  filter: FilterStore
  axios: AxiosStore
  nav: NavStore
  product: ProductStore
  routing: RouterStore
  search: SearchStore
  currencySettings: CurrencySettingsStore
  sidebar: SidebarStore
  toolbar: ToolbarStore
  buyingSession: BuyingSessionStore
  cluster: ClusterStore
  collection: CollectionStore
  collectionBrief: CollectionBriefStore
  storeImport: StoreImportStore
  collectionImage: CollectionImageStore
  storeSettings: StoreSettingsStore
  seasonSync: SeasonSyncStore
  notification: NotificationStore
  activitySettings: ActivitySettingStore
  restrictProductByZoneModal: RestrictProductByZoneModalStore
  clusterStatusDropDown: ClusterStatusDropDownStore
  zoneWiseCommentsStore: ZoneWiseCommentsStore

  listView: ListViewStore
  buyingGroupSetting: BuyingGroupSettingStore
  wholesaleBuyingSessionStore: WholesaleBuyingSessionStore
  buyingSessionGroup: BuyingSessionGroupStore
  wholesaleSettingsSidebar: WholesaleSettingsSidebarStore
  deliveryWindowSetting: DeliveryWindowSettingStore
  dropSetting: DropSettingStore
  buyingSessionGroupSidebar: BuyingSessionGroupSidebarStore
  buyingSessionDocumentStore: BuyingSessionDocumentStore
  clientManagement: ClientManagementStore
  doorManagement: DoorManagementStore
  activitySelectionSettings: ActivitySelectionSettingsStore
  wholesaleAttributeSelectorStore: WholesaleAttributeSelectorStore
  showroomClientSelection: ShowroomClientSelectionStore
  showroomHeader: ShowroomHeaderStore
  seasonSyncSettings: SeasonSyncSettingsStore
  wsBuyingSessionProduct: WSBuyingSessionProductStore
  clientDiscount: ClientDiscountStore
  clientSearch: ClientSearchStore
  clusterExclusionMultiSelect: ClusterExclusionMultiSelectStore
  wholesaleAssortmentProductStore: WholesaleAssortmentProductStore
  showroomBuyingSessionSelection: BuyingSessionSelectionStore
  freeTagListStore: FreeTagListStore
  salesDocTypeStore: SalesDocTypeStore
  wholesaleAddProductToAssortment: WholesaleAddProductToAssortmentStore
  showroomCollection: ShowRoomCollectionStore
  productDetail: ProductDetailStore
  orderManagementStore: WSOrderManagementStore
  showroomOrderManagementStore: ShowroomOrderManagementStore
  showroomProductDetailStore: ShowroomProductDetailStore
  importOrders: ImportOrdersStore
  filterModalStore: FilterModalStore
  sizedQtyStore: SizedQtyStore
  campaignStore: CampaignStore
  showroomSidebarStore: ShowroomSidebarStore
  showroomDashboardStore: ShowroomDashboardStore
  ordersUIStore: OrdersUIStore
  syncSettingsStore: SyncSettingsStore
  productPriceStore: ProductPricesStore
  retailAssortmentStore: RetailAssortmentStore
}

export * from './apollo'
export type { SortOption }
export {
  ActivitySelectionSettingsStore,
  assortment,
  BuyingSessionDocumentStore,
  BuyingSessionGroupSidebarStore,
  BuyingSessionGroupStore as BuyingSessionGroup,
  BuyingSessionSelectionStore,
  CampaignStore,
  ClientDiscountStore,
  ClientManagementStore,
  ClientSearchStore,
  ClusterExclusionMultiSelectStore,
  CollectionStore,
  currencySettings,
  DoorManagementStore,
  DropSettingStore,
  filter,
  FreeTagListStore,
  ImportOrdersStore,
  ListViewStore,
  lookbook,
  nav,
  NavStore,
  product,
  ProductDetailStore,
  productPriceStore,
  ProductStore,
  SalesDocTypeStore,
  search,
  SearchStore,
  SeasonSyncSettingsStore,
  ShowroomClientSelectionStore,
  ShowRoomCollectionStore,
  ShowroomDashboardStore,
  ShowroomHeaderStore,
  ShowroomProductDetailStore,
  ShowroomSidebarStore,
  site,
  SiteStore,
  sizedQtyStore,
  SizedQtyStore,
  SyncSettingsStore,
  WholesaleAddProductToAssortmentStore,
  WholesaleAssortmentProductStore,
  WholesaleAttributeSelectorStore,
  WholesaleBuyingSessionStore,
  FilterModalStore,
  WholesaleSettingsSidebarStore,
  WSBuyingSessionProductStore,
  ZoneWiseCommentsStore,
  RetailAssortmentStore
}

export const stores: Stores = {
  client,
  routing: new RouterStore(),
  assortment,
  lookbook,
  filter,
  nav,
  product,
  currencySettings,
  search: search,
  sidebar,
  buyingSession: new BuyingSessionStore(),
  cluster: new ClusterStore(),
  collection: new CollectionStore(),
  collectionBrief: new CollectionBriefStore(),
  storeImport: new StoreImportStore(),
  collectionImage: new CollectionImageStore(),
  seasonSync: new SeasonSyncStore(),
  activitySettings: new ActivitySettingStore(),
  storeSettings,
  notification,
  restrictProductByZoneModal: new RestrictProductByZoneModalStore(),
  clusterStatusDropDown: new ClusterStatusDropDownStore(),
  zoneWiseCommentsStore: new ZoneWiseCommentsStore(),
  listView: new ListViewStore(),
  buyingGroupSetting,
  deliveryWindowSetting,
  wholesaleBuyingSessionStore: new WholesaleBuyingSessionStore(),
  dropSetting,
  axios: new AxiosStore(),
  buyingSessionGroup,
  wholesaleSettingsSidebar,
  buyingSessionGroupSidebar,
  buyingSessionDocumentStore,
  clientManagement: clientManagementStore,
  doorManagement: doorManagementStore,
  activitySelectionSettings,
  wholesaleAttributeSelectorStore,
  seasonSyncSettings,
  wsBuyingSessionProduct: new WSBuyingSessionProductStore(),
  clientDiscount,
  showroomClientSelection,
  showroomHeader,
  showroomBuyingSessionSelection,
  wholesaleAssortmentProductStore: new WholesaleAssortmentProductStore(),
  freeTagListStore,
  salesDocTypeStore,
  wholesaleAddProductToAssortment,
  showroomCollection,
  productDetail,
  orderManagementStore: new WSOrderManagementStore(),
  showroomOrderManagementStore,
  showroomProductDetailStore,
  importOrders,
  filterModalStore,
  clientSearch,
  clusterExclusionMultiSelect,
  sizedQtyStore,
  campaignStore,
  showroomSidebarStore,
  showroomDashboardStore,
  ordersUIStore,
  syncSettingsStore,
  toolbar,
  productPriceStore,
  retailAssortmentStore
}

window['centric'] = stores
