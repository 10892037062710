/*
 * @Author: Raeesaa Metkari
 * @Date: 2019-12-19 00:34:18
 * @Last Modified by: Raeesaa Metkari
 * @Last Modified time: 2020-01-08 19:32:34
 */

import classNames from 'classnames'
import React from 'react'
import styled, { keyframes } from 'styled-components'
import * as css from './ListRowSkeleton.css'
interface MyProps {
  noMargin?: boolean
  imageWidth?: number
}

const Pulse = keyframes`
  0% { background-color: rgba(165, 165, 165, 0.1);}
  50% {background-color: rgba(165, 165, 165, 0.3);}
  100% {background-color: rgba(165, 165, 165, 0.1);}
`

const ImageLoadingLine = styled.div<MyProps>`
  flex: 0px 0px auto;
  width: ${props => (props.imageWidth ? `${props.imageWidth}px` : '204px')};
  margin: 0px 10px 0px 0px;
  animation: ${Pulse} 1s infinite ease-in-out;
`

export const ListRowSkeleton: React.FunctionComponent<MyProps> = props => {
  return (
    <div
      className={classNames(
        css['text-input__loading'],
        props.noMargin ? css['container_margin'] : css['default_margin']
      )}
    >
      <ImageLoadingLine imageWidth={props.imageWidth} />
      <div className={css['row-wrapper']}>
        <div className={css['text-input__loading--line']} />
        <div className={css['text-input__loading--line']} />
        <div className={css['text-input__loading--line']} />
        <div className={css['text-input__loading--line']} />
        <div className={css['text-input__loading--line']} />
        <div className={css['text-input__loading--line']} />
        <div className={css['text-input__loading--line']} />
        <div className={css['text-input__loading--line']} />
        <div className={css['text-input__loading--line']} />
      </div>
    </div>
  )
}
