import reduce from 'lodash/reduce'
import { computed, observable } from 'mobx'
import { CounterOfferDataStore } from './CounterOfferDataStore'

class CounterOfferUIStore {
  private counterOfferDataStore = new CounterOfferDataStore()

  @observable rmClustersData

  setCounterOfferData = async (buyingSessionGroupId: string, buyingSessionId: string) => {
    try {
      this.counterOfferDataStore
        .fetchRMClusters(buyingSessionGroupId, buyingSessionId)
        .then(response => {
          this.rmClustersData = response
        })
        .catch(e => console.log(e))
    } catch (e) {
      console.log(e)
    }
  }

  @computed get zoneColumnMap() {
    return reduce(
      this.rmClustersData,
      (zoneMap, bspData) => {
        return (zoneMap = {
          ...zoneMap,
          ...reduce(
            bspData?.clientMap,
            (bspWiseZoneMap, zone, zoneId) => {
              return (bspWiseZoneMap = {
                ...bspWiseZoneMap,
                [zoneId]: {
                  name: zone?.clientName || 'NA',
                  hasClusterAssociation: zone?.clientHasAllotments,
                  hasCounterOffer:
                    bspWiseZoneMap?.[zoneId]?.hasCounterOffer || zone?.flagProductAtClient
                }
              })
            },
            zoneMap
          )
        })
      },
      {}
    )
  }
}

export const counterOfferUIStore = new CounterOfferUIStore()
