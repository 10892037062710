import { MutationFunctionOptions } from '@apollo/client/react/types/types'
import { ExecutionResult } from 'graphql/execution'
import find from 'lodash/find'
import remove from 'lodash/remove'
import { action, observable } from 'mobx'

import { GET_SEASON_SYNC_METADATA } from '../graphql'

const SYNC_SEASON_METADATA_NAME = 'season-sync-criteria'

export class SeasonSyncSettingsStore {
  @observable selectedSeasons = []
  @observable isSeasonsFiltered = false
  mutations: {
    upsertSeasonSyncMetaData?: (
      options?: MutationFunctionOptions<any, { value: string }>
    ) => Promise<void | ExecutionResult>
  } = {}

  @action
  handleToggle = e => {
    e.target.checked
      ? this.selectedSeasons.push(e.target.value)
      : remove(this.selectedSeasons, Id => Id === e.target.value)
  }

  getSyncedSeasons = (seasons, seasonSyncMetaData) => {
    this.selectedSeasons = []
    const syncedSeasons = find(
      seasonSyncMetaData,
      Item => Item?.Name === SYNC_SEASON_METADATA_NAME
    )
    const existingSyncedSeasons =
      syncedSeasons?.Value.split(',').map(item => item.trim()) ?? []

    seasons.forEach(({ Id, Name }) => {
      if (existingSyncedSeasons.includes(Name)) {
        this.selectedSeasons.push(Id)
      }
    })
    this.isSeasonsFiltered = true
  }

  @action
  handleSubmit = async seasons => {
    const { selectedSeasons } = this
    let syncedSeasons = []
    seasons.forEach(({ Id, Name }) => {
      if (selectedSeasons.includes(Id)) {
        syncedSeasons.push(Name)
      }
    })

    return await this.mutations.upsertSeasonSyncMetaData({
      variables: { value: syncedSeasons.join(',') },
      refetchQueries: [
        {
          query: GET_SEASON_SYNC_METADATA
        }
      ]
    })
  }
}

export const seasonSyncSettings = new SeasonSyncSettingsStore()
