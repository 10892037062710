import { PrivateRoute } from '@components/Utils/PrivateRoute'
import { generateContextId } from '@services/authService'
import { stores } from '@stores'
import { SiteStore } from '@stores/SiteStore'
import { observable } from 'mobx'
import { observer, Provider } from 'mobx-react'
import React, { Suspense } from 'react'
import { DragDropContext } from 'react-dnd'
import MultiBackend from 'react-dnd-multi-backend'
import HTML5toTouch from 'react-dnd-multi-backend/lib/HTML5toTouch'
import { hot } from 'react-hot-loader'
import { Route, Switch, withRouter } from 'react-router-dom'
import './App.css'
import * as css from './App.css'

const ForgotPasswordContainer = React.lazy(
  () => import('@modules/forgotPassword/container/ForgotPasswordContainer')
)
const ResetPasswordContainer = React.lazy(
  () => import('@modules/resetPassword/container/ResetPasswordContainer')
)
const LoginContainer = React.lazy(() => import('@modules/login/container/LoginContainer'))
const SelectionContainer = React.lazy(
  () => import('./modules/workflowSelection/container/SelectionContainer')
)
const LogoutContainer = React.lazy(() => import('@modules/logout/container/Logout'))
const WholesaleDashboard = React.lazy(
  () => import('@modules/wholesale/dashboard/containers/WholesaleDashboard')
)
const ShowroomDashboard = React.lazy(
  () => import('@modules/showroom/dashboard/component/ShowroomDashboard')
)
const Home = React.lazy(() => import('@modules/home/containers/Home'))

@((DragDropContext as any)(MultiBackend(HTML5toTouch)))
@(withRouter as any)
@observer
export class AppInternal extends React.Component<any> {
  @observable.ref site: SiteStore

  componentDidUpdate(prevProps: any) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged()
    }
  }

  componentDidMount() {
    this.onRouteChanged()
  }

  onRouteChanged() {
    generateContextId()
  }

  render() {
    const { site } = this
    // const mobxConfig = site && site.mobxDevToolConfig

    return (
      <div className={css.root}>
        <Suspense fallback={<div>Loading...</div>}>
          <SiteStore
            /* We can't use ref as we have decorated the store */
            onInit={store => {
              if (store) {
                this.site = store
              }
            }}
          />

          {/* <Helmet>{site && <title>{site.title}</title>}</Helmet> */}

          {site && (
            <Provider site={site} {...stores}>
              <Switch>
                <Route exact path='/forgotPassword' component={ForgotPasswordContainer} />
                <Route exact path='/resetPassword' component={ResetPasswordContainer} />
                <Route exact path='/login' component={LoginContainer} />
                <PrivateRoute
                  exact
                  path='/workflowSelection'
                  component={SelectionContainer}
                />
                <Route exact path='/logout' component={LogoutContainer} />
                {/* <Route exact path='/style-guide' component={StyleGuide} /> */}
                <PrivateRoute path='/wholesale' component={WholesaleDashboard} />
                <PrivateRoute path='/showroom' component={ShowroomDashboard} />

                <PrivateRoute path='/' component={Home} />
                <Route path='*' render={() => <div>Otherwise Screen!</div>} />
              </Switch>
            </Provider>
          )}
        </Suspense>
      </div>
    )
  }
}


export const App = hot(module)(AppInternal)
