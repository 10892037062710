import { strings } from '@stores'
import includes from 'lodash/includes'

// Mapping query and mutation names to approriate error text
const operationMap = {
  login: 'signing into application',
  CreateNewBuyingSession: 'creating buying session',
  createSalesGeoArea: 'creating zone',
  CreateSalesRegion: 'creating territory',
  CreateNewAssortment: 'creating assortment',
  deleteOfferFromGlobalAssortment: 'removing offer from assortment',
  addOfferToAssortment: 'adding product into assortment',
  markOfferAsFlex: 'marking offer as flex',
  markOfferAsCore: 'marking offer as core',
  completeAssortment: 'marking assortment as complete',
  updateAssortmentName: 'updating assortment name',
  deleteAssortment: 'deleting assortment',
  AssignMembership: 'assigning membership to user',
  UpdateMembership: 'updating user membership',
  DeleteMembership: 'revoking user membership',
  BuyingSessionForRegionalMerchandiser: 'fetching buying session details',
  GetGlobalAndRegionalAssortments:
    'fetching suggested and regional assortment for given cluster and level',
  StoreTypeAndSize: 'fetching clusters and levels',
  Assortments: 'fetching assortments',
  segments: 'fetching segments',
  products: 'fetching library products',
  assortment: 'fetching assortment details',
  getRangePlan: 'fetching range plan',
  offerProducts: 'fetching offers',
  offerDetail: 'fetching offer details',
  productDetail: 'fetching product details',
  salesRegions: 'fetching territories',
  createFreeTag: 'creating Free Tag',
  syncAll: 'syncing products'
}

interface DuplicateBasketContext {
  orderId: string
  bsgId: string
  clientId: string
}
export const buildErrorMessage = errors => {
  let error = ''

  if (errors) {
    if (errors.operation && errors.operation.operationName) {
      error = `Error in ${
        operationMap[errors.operation.operationName] || errors.operation.operationName
      }: `
    }
    if (errors.networkError) {
      let errorText = errors?.networkError?.message?.split(':') ?? undefined
      if (errorText && errorText.length) {
        errorText = errorText[1] ? errorText[1] : errorText[0]
      } else {
        errorText = 'Something went wrong!'
      }
      error += errorText
    } else if (errors.graphQLErrors && errors.graphQLErrors.length) {
      if (errors.graphQLErrors.length === 1) {
        error += errors.graphQLErrors[0].message
      } else {
        error += 'Something went wrong'
      }
    } else {
      error += 'Something went wrong!'
    }
  }
  return error
}

export const isAuthenticationErrorOnLogout = errors => {
  let errorMessage = ''

  if (errors) {
    if (errors.networkError) {
      errorMessage = errors.networkError.message.split(':')[1] || 'Something went wrong!'
    } else if (errors.graphQLErrors && errors.graphQLErrors.length) {
      if (errors.graphQLErrors.length === 1) {
        errorMessage = errors.graphQLErrors[0].message
      }
    }
  }

  return (
    errorMessage === strings.authenticationError && window.location.pathname === '/login'
  )
}

/**
 * Method for checking if GraphQL error returned in related to C8 session expiry
 * @param errors - Errors returned in GraphQL request
 * @returns { boolean } - Flag specifying if error is related to session expiry
 */
export const hasC8SessionExpired = errors => {
  let C8SessionExpiryErrorMessage = strings.c8GraphQLSessionExpiredError

  if (errors) {
    if (errors.networkError) {
      const error = errors.networkError.message.split(':')[1]
      return includes(error, C8SessionExpiryErrorMessage)
    } else if (errors.graphQLErrors && errors.graphQLErrors.length) {
      if (errors.graphQLErrors.length === 1) {
        const error = errors.graphQLErrors[0].message
        return includes(error, C8SessionExpiryErrorMessage)
      }
    }
  }

  return false
}

export const getUserFriendlyErrors = error => {
  if (
    error &&
    error.graphQLErrors &&
    error.graphQLErrors.length &&
    error.graphQLErrors.length === 1
  ) {
    let isBuyingSessionError
    try {
      isBuyingSessionError = JSON.parse(error.graphQLErrors[0].message)
    } catch (e) {
      isBuyingSessionError = false
    }
    if (
      isBuyingSessionError &&
      (isBuyingSessionError.code === strings.buyingSessionErrors ||
        isBuyingSessionError.code === strings.associationChangeErrors)
    ) {
      return isBuyingSessionError
    }
  }
  return false
}

/**
 * getDetailsIfDuplicateBasketCreation
 * Returns details useful for navigating user to
 * wholesale collection page if user is attempting
 * to create same basket again
 * @param error
 */
export const getDetailsIfDuplicateBasketCreation = (error): DuplicateBasketContext => {
  if (
    error &&
    error.graphQLErrors &&
    error.graphQLErrors.length &&
    error.graphQLErrors.length === 1
  ) {
    const errorText = error?.graphQLErrors[0]?.message ?? ''
    const customMessage = errorText?.split(':') ?? ''
    const matches =
      customMessage && customMessage?.length
        ? customMessage[1]?.match(
            /Basket (\b[a-zA-Z0-9]*\b) already exist for buying session group (\b[a-zA-Z0-9]*\b) and client (\b[a-zA-Z0-9]*\b)/i
          )
        : []
    if (matches && matches.length && matches[1] && matches[2]) {
      return {
        orderId: matches[1],
        bsgId: matches[2],
        clientId: matches[3]
      }
    }
  }
  return null
}
