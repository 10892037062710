import isEqual from 'lodash/isEqual'
import memoize from 'memoize-one'

import {
  RootInfo,
  TreeNavItem,
  TreeTable
} from '../components/UI-Components/StandardTreeView'
import union from 'lodash/union'

enum KPITreeViewNodeTypes {
  Header = 'HEADER',
  Category = 'CATEGORY',
  CategoryLeaf = 'CATEGORY_LEAF',
  Products = 'PRODUCTS'
}

interface KPICategory {
  totalProducts: number
  totalValue: string
  totalQuantity: number
  totalBETQuantity: number
  totalStores: number
  aws: number
  salesQty: number
  mixValue: number
  mixQuantity: number
  name: string
  averageDepth: number
  skuEfficiency: number
  childrenMeta: Array<string>
  children: KPICategory
  data: any
  id?: string
}

interface CategoryMeta {
  categoryIds?: Array<string>
  category: string
  childrenMeta: Array<string>
}

interface KPICategories {
  categories: Array<KPICategory>
}

interface KPIProcessingInput {
  kpiData: KPICategories
  rootInfo: RootInfo
  isKPIView?: boolean
}

interface ProcessedKPIForTreeView {
  tableData: TreeTable
  navData: Array<TreeNavItem>
}

/**
 * getPathToCategoryAsKey
 * Creates unique key for tree item
 * @param childNodeName
 * @param parentNodeName
 */
const getPathToCategoryAsKey = (
  childNodeName: string,
  parentNodeName?: string
): string => {
  return parentNodeName ? `${parentNodeName}:${childNodeName}` : childNodeName
}

/**
 * getTableNodeDef
 * Returns tree item structure that Standard
 * tree view expects
 * @param key
 * @param depth
 * @param node
 * @param children
 */
const getTableNodeDef = (
  key: string,
  depth: number,
  node?: any,
  childrenWithinList?: Array<string>,
  isProductNode?: boolean
) => {
  let type = KPITreeViewNodeTypes.Category
  const childLength = childrenWithinList && childrenWithinList.length
  let def
  if (node && !node.children && (childLength || childLength === 0)) {
    type = KPITreeViewNodeTypes.CategoryLeaf
  } else if (isProductNode) {
    type = KPITreeViewNodeTypes.Products
  }

  def = {
    key,
    type,
    ...node,
    depth
  }
  if (childLength) {
    def.children = childrenWithinList
  }
  return def
}

/**
 * getNavNodeDef
 * Returns Nav item that standard
 * tree view expects
 * @param key
 * @param label
 * @param children
 */
const getNavNodeDef = (
  key: string,
  label: string,
  depth: number,
  children?: Array<TreeNavItem>
): TreeNavItem => {
  return {
    key,
    label,
    depth,
    children
  }
}

/**
 * getProcessedKPI
 * Transforms KPI data into data structures
 * required for loading standard tree view.
 * TODO: Identify KPI independent parts to wrap
 * in independent functions
 * @param kpiData
 * @param rootInfo
 */
const getProcessedKPI = memoize(
  ({ kpiData, rootInfo, isKPIView }: KPIProcessingInput): ProcessedKPIForTreeView => {
    const tableData: TreeTable = {}
    let navData = []
    function _TraverseNode(
      node: KPICategory,
      depth: number,
      categoryMeta: CategoryMeta,
      parentKey: string
    ) {
      const { category, childrenMeta } = categoryMeta
      if (!node.children) {
        const key = getPathToCategoryAsKey(node.name, parentKey)
        let childDepth = ++depth
        const keyTokens = key.split(':')
        const childrenMetaValues = keyTokens.slice(-(keyTokens.length - 1))
        const leafKey = `${key}:PRODUCTS`
        tableData[key] = getTableNodeDef(
          key,
          childDepth,
          node,
          isKPIView ? [] : [leafKey]
        )
        if (!isKPIView) {
          tableData[leafKey] = {
            ...getTableNodeDef(leafKey, ++childDepth),
            isProductNode: true,
            products: node.totalProducts,
            category,
            childrenMeta,
            childrenMetaValues,
            data: node.data,
            // add leaf level category id
            categoryIds: [...categoryMeta?.categoryIds, node?.id]
          }
        }

        return getNavNodeDef(key, node.name, childDepth)
      }

      if (Array.isArray(node.children)) {
        const rootKey = getPathToCategoryAsKey(node.name, parentKey)
        const parentDepth = ++depth
        // concatenate all the parent level category ids
        const categoryIds = union(categoryMeta?.categoryIds, [node?.id])
        const children = node.children.map((child: KPICategory) =>
          _TraverseNode(child, parentDepth, { ...categoryMeta, categoryIds }, rootKey)
        )
        tableData[rootKey] = getTableNodeDef(
          rootKey,
          depth,
          node,
          children.map((item: TreeNavItem) => item && item.key)
        )
        return getNavNodeDef(rootKey, node.name, parentDepth, children)
      }
    }

    if (kpiData && kpiData.categories.length) {
      const categories = kpiData.categories
      const categoriesNumber = categories.length
      let directChildToRoot = []
      for (let i = 0; i < categoriesNumber; i++) {
        directChildToRoot.push(categories[i].name)
        navData.push(
          _TraverseNode(
            categories[i],
            0,
            {
              categoryIds: [categories[i]?.id],
              category: categories[i].name,
              childrenMeta: categories[i].childrenMeta
            },
            undefined
          )
        )
      }

      tableData[rootInfo.root.id] = {
        name: rootInfo.root.id,
        type: KPITreeViewNodeTypes.Header,
        height: rootInfo.root.height,
        children: directChildToRoot,
        depth: 0
      }
    } else {
      // In case no KPI data is present / there are 0 categories, return only root node
      tableData[rootInfo.root.id] = {
        name: rootInfo.root.id,
        type: KPITreeViewNodeTypes.Header,
        height: rootInfo.root.height,
        children: [],
        depth: 0
      }
    }

    return {
      navData,
      tableData
    }
  },
  (oldVal, newVal) => {
    return (
      isEqual(oldVal[0].kpiData, newVal[0].kpiData) &&
      isEqual(oldVal[0].isKPIView, newVal[0].isKPIView)
    )
  }
)

export { getProcessedKPI, KPITreeViewNodeTypes }
