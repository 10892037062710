import { CentricTheme, StyledProps } from '@components/3rd-party/mui'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'

const styles = (theme: CentricTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(4),
    justifyContent: 'flex-start',
    position: 'relative',
    flex: 1
  }
})

interface IProductDescription extends StyledProps {
  children?: any
}

@((withStyles as any)(styles))
export class ProductDescription extends React.Component<IProductDescription> {
  render() {
    const {
      props: { classes, children }
    } = this
    return <div className={classes.container}>{children}</div>
  }
}
