import { QuantityType } from '@modules/common/models'
import { strings } from '@stores'
import { listViewColumnDefinitionService } from '../listViewColumnDefinitionService'

class CollectionKpiViewDefinitionService {
  buildKpiViewDefinitionForForecast = () => {
    return [
      listViewColumnDefinitionService.buildProductInfoColumnDefinitionInstance(),
      listViewColumnDefinitionService.buildAWSColumnDefinitionInstance(),
      listViewColumnDefinitionService.buildMixQtyColumnDefinitionInstance(),
      listViewColumnDefinitionService.buildMixValueColumnDefinitionInstance(),
      listViewColumnDefinitionService.buildAvgDepthColumnDefinitionInstance(),
      listViewColumnDefinitionService.buildSkuEffColumnDefinitionInstance(),
      listViewColumnDefinitionService.buildTotalStoresColumnDefinitionInstance(true),
      listViewColumnDefinitionService.buildValueColumnDefinitionInstance('Forecast'),
      listViewColumnDefinitionService.buildSalesQtyColumnDefinitionInstance(),
      listViewColumnDefinitionService.buildOrderQtyColumnDefinitionInstance(
        QuantityType.BET,
        'Forecast'
      )
    ]
  }

  buildKpiViewDefinitionForBuy = (assortmentType?: string) => {
    const doorAssortment = strings.door.toLowerCase()
    return [
      listViewColumnDefinitionService.buildProductInfoColumnDefinitionInstance(),
      ...(assortmentType !== doorAssortment
        ? [listViewColumnDefinitionService.buildTotalStoresColumnDefinitionInstance(true)]
        : []),
      listViewColumnDefinitionService.buildBuyerBETQuantityColumnDefinitionInstance(
        'Forecast'
      ),
      listViewColumnDefinitionService.buildOrderQtyColumnDefinitionInstance(
        QuantityType.BUY,
        'Order'
      ),
      listViewColumnDefinitionService.buildAWSColumnDefinitionInstance(),
      listViewColumnDefinitionService.buildAvgDepthColumnDefinitionInstance(),
      listViewColumnDefinitionService.buildSkuEffColumnDefinitionInstance(),
      listViewColumnDefinitionService.buildValueColumnDefinitionInstance('Order'),
      ...(assortmentType !== doorAssortment
        ? [listViewColumnDefinitionService.buildSalesQtyColumnDefinitionInstance()]
        : [])
    ]
  }
}

export const collectionKpiViewDefinitionService = new CollectionKpiViewDefinitionService()
