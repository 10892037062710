import { nav } from '@stores/nav'
import { action, observable, computed } from 'mobx'
import { VIPClientType } from '@modules/common/models/enums/VIPClientType'
import {
  hasUserRoles,
  SAClientsAllowedRoles,
  SAZonesAllowedRoles
} from '@services/userRoleService'

const ZONES_CLIENTS = [
  { name: 'Zones', clientType: VIPClientType.Internal },
  { name: 'Clients', clientType: VIPClientType.External }
]

export class ShowroomClientSelectionStore {
  @observable isClientSelectionMounted = false
  @action setClientSelectionMounted = val => (this.isClientSelectionMounted = val)
  @action onClickClient = client => {
    const { history } = nav
    history.push(`/showroom/client/${client.Id}/buyingSessions`)
  }

  @computed get zoneAndClients() {
    return ZONES_CLIENTS.filter(item => {
      if (item.name === 'Zones' && hasUserRoles(SAZonesAllowedRoles)) {
        return item
      }
      if (item.name === 'Clients' && hasUserRoles(SAClientsAllowedRoles)) {
        return item
      }
      return null
    })
  }
}

export const showroomClientSelection = new ShowroomClientSelectionStore()
