import { StyledProps } from '@components/3rd-party/mui'
import {
  Root,
  StyledAWSTextFieldComponent
} from '@components/UI-Components/AverageWeeklySale/AWSTextFieldList'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { UPSERT_ZONE_ASSORTMENT_PRODUCT_COMMENT } from '@modules/retail/collection/graphql/upsertZoneAssortmentProductComment'
import { apolloClient, stores, strings } from '@stores'
import classNames from 'classnames'
import { action, observable } from 'mobx'
import { observer } from 'mobx-react'
import React from 'react'
import * as css from './ZoneWiseComments.css'

interface ZoneWiseCommentsProps extends StyledProps {
  betData?: any
  classes?: any
  userRole?: any
}

@observer
export class ZoneWiseCommentsComponent extends React.Component<ZoneWiseCommentsProps> {
  @observable editedCommentMap = {}
  @observable showTextInputMap = {}

  @action
  commentTyped = (text, zoneAssortmentProductId) => {
    this.editedCommentMap[`${zoneAssortmentProductId}_${this.props.userRole}`] =
      text.substring(0, 150)
  }

  @action
  showCommentTextInput = (zoneAssortmentProductId, comment) => {
    const {
      props: { userRole },
      showTextInputMap,
      editedCommentMap
    } = this
    showTextInputMap[`${zoneAssortmentProductId}_${userRole}`] = true
    editedCommentMap[`${zoneAssortmentProductId}_${userRole}`] = comment
  }

  @action
  updateComment = async (zoneAssortmentProductId, commentObj) => {
    this.editedCommentMap[`${zoneAssortmentProductId}_${this.props.userRole}`] =
      this.editedCommentMap[`${zoneAssortmentProductId}_${this.props.userRole}`].trim()

    const commentText: string =
      this.editedCommentMap[`${zoneAssortmentProductId}_${this.props.userRole}`]
    await apolloClient.mutate({
      mutation: UPSERT_ZONE_ASSORTMENT_PRODUCT_COMMENT,
      variables: {
        zoneAssortmentProductId,
        text: commentText
      }
    })
    if (commentObj) {
      commentObj.text = commentText
    }
    this.hideCommentTextInput(zoneAssortmentProductId)
  }

  isAllowedToAddUpdateComment = (type): boolean => {
    const [
      {
        props: { userRole }
      },
      {
        site: { isGlobalMerchandiser },
        sidebar: { isRegionalMerchandiser }
      }
    ] = [this, stores]
    return (
      type !== 'global' &&
      ((userRole === strings.GlobalMerchaniser && isGlobalMerchandiser()) ||
        (userRole !== strings.GlobalMerchaniser && isRegionalMerchandiser()))
    )
  }

  @action
  hideCommentTextInput = zoneAssortmentProductId => {
    this.showTextInputMap[`${zoneAssortmentProductId}_${this.props.userRole}`] = false
  }

  handleKeyDownForComment = async (
    evt,
    isCommentChanged,
    zoneAssortmentProductId,
    comment
  ) => {
    if (evt.key === 'Enter') {
      if (isCommentChanged) {
        await this.updateComment(zoneAssortmentProductId, comment)
      } else {
        this.hideCommentTextInput(zoneAssortmentProductId)
      }
    }
  }

  render() {
    const [
      {
        props: { betData, userRole },
        editedCommentMap,
        commentTyped,
        updateComment,
        showTextInputMap,
        showCommentTextInput,
        hideCommentTextInput,
        handleKeyDownForComment,
        isAllowedToAddUpdateComment
      },
      {
        zoneWiseCommentsStore: { getZoneWiseCommentsByRole }
      }
    ] = [this, stores]

    return (
      <div>
        {betData.map(({ id: zoneAssortmentProductId, zoneWiseComments, type }) => {
          const commentLocalId = `${zoneAssortmentProductId}_${userRole}`

          const comment =
            zoneWiseComments && zoneWiseComments.length
              ? getZoneWiseCommentsByRole(zoneWiseComments, userRole)
              : null
          const currentText =
            editedCommentMap && editedCommentMap[commentLocalId] !== undefined
              ? editedCommentMap[commentLocalId]
              : comment
              ? comment.text
              : null

          // If non-empty DB comment exists, compare with changed comment to decide if comment is changed,
          // else (DB comment does not exist or empty) check if currentText is not empty
          const isCommentChanged =
            comment && comment.text ? currentText !== comment.text : !!currentText
          return (
            <div
              key={`comment_${commentLocalId}`}
              onClick={
                isAllowedToAddUpdateComment(type)
                  ? () => showCommentTextInput(zoneAssortmentProductId, currentText)
                  : null
              }
            >
              <Root>
                {showTextInputMap && showTextInputMap[commentLocalId] ? (
                  <StyledAWSTextFieldComponent
                    key={`comment_text_input_${commentLocalId}`}
                    value={currentText || ''}
                    onChange={e => commentTyped(e.target.value, zoneAssortmentProductId)}
                    onBlur={() =>
                      isCommentChanged
                        ? updateComment(zoneAssortmentProductId, comment ? comment : null)
                        : hideCommentTextInput(zoneAssortmentProductId)
                    }
                    autoFocus={true}
                    fullWidth={true}
                    onKeyDown={e =>
                      handleKeyDownForComment(
                        e,
                        isCommentChanged,
                        zoneAssortmentProductId,
                        comment ? comment : null
                      )
                    }
                  />
                ) : (
                  <Tooltip
                    key={`comment_tooltip_${commentLocalId}`}
                    title={currentText || ''}
                    disableHoverListener={!currentText}
                  >
                    <Typography
                      key={`comment_text_${commentLocalId}`}
                      variant={'caption'}
                      className={classNames(
                        css.ellipsis,
                        !currentText ? css.helpText : null
                      )}
                    >
                      {currentText
                        ? currentText
                        : isAllowedToAddUpdateComment(type)
                        ? strings.commentsHelpText
                        : null}
                    </Typography>
                  </Tooltip>
                )}
              </Root>
            </div>
          )
        })}
      </div>
    )
  }
}
