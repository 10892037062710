const sortArrayofObjectUsingProperty = (ArrayofObj, property) => {
  ArrayofObj.sort((a, b) => {
    let objA = a[property].toLowerCase(),
      objB = b[property].toLowerCase()
    if (objA < objB) {
      return -1
    }
    if (objA > objB) {
      return 1
    }
    return 0
  })
  return ArrayofObj
}

export default sortArrayofObjectUsingProperty
