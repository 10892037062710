import { sortListUsingOrderList } from '@services/commonServices'
import { stores, strings } from '@stores'
import { VIPChannel } from '@modules/common/models/enums/VIPChannel'
import find from 'lodash/find'

const getActiveAndInActiveSeasons = wholesaleBuyingSessionGroups => {
  const activeSeasons = []
  const inActiveSeasons = []
  if (wholesaleBuyingSessionGroups && wholesaleBuyingSessionGroups.length) {
    wholesaleBuyingSessionGroups.forEach(group => {
      if (group.GIV_VIPSeason_HasActiveBuyingSessions_bool) {
        activeSeasons.push(group)
      } else if (!group.GIV_VIPSeason_HasActiveBuyingSessions_bool) {
        inActiveSeasons.push(group)
      }
    })
  }
  return {
    activeSeasons,
    inActiveSeasons
  }
}

const getBuyingSessionGroupByGender = (buyingSessionGroups, genderId) => {
  if (buyingSessionGroups && buyingSessionGroups.length) {
    return buyingSessionGroups.filter(
      buyingSessionGroup =>
        buyingSessionGroup.Gender && buyingSessionGroup.Gender.Id === genderId
    )
  } else {
    return []
  }
}
const getSortedBuyingSessionGroupsByGenderName = SessionGroups => {
  return sortListUsingOrderList(
    SessionGroups ?? [],
    ['Gender', 'Name'],
    strings.sortOrderForGenderName
  )
}

const getGenderId = gendersData => {
  const {
    sidebar: { genderId, selectedWholesaleSeason, setSelectedGender },
    nav: {
      queryParams: { buyingSessionGroupId }
    }
  } = stores

  if (genderId) {
    return genderId
  }

  const buyingSessionGroup = (selectedWholesaleSeason?.SessionGroups ?? []).find(
    buyingSessionGroup => buyingSessionGroup.Id === buyingSessionGroupId
  )

  let defaultGenderId
  if (buyingSessionGroup) {
    defaultGenderId = buyingSessionGroup?.Gender?.Id
  } else {
    defaultGenderId = gendersData.length ? gendersData[0]?.Id : ''
  }
  setSelectedGender(defaultGenderId)
  return defaultGenderId
}

const isWholesaleChannel = (channels: [string] | Array<String>) => {
  return channels?.includes(VIPChannel.Wholesale)
}

const isRetailChannel = (channels: [string] | Array<String>) => {
  return channels?.includes(VIPChannel.Retail)
}

const isWholesaleChannelUserRole = channels => {
  return !!find(channels, function (channel) {
    return channel?.id === VIPChannel.Wholesale
  })
}

const isRetailChannelUserRole = channels => {
  return !!find(channels, function (channel) {
    return channel?.id === VIPChannel.Retail
  })
}

export {
  getActiveAndInActiveSeasons,
  getBuyingSessionGroupByGender,
  getGenderId,
  getSortedBuyingSessionGroupsByGenderName,
  isWholesaleChannel,
  isWholesaleChannelUserRole,
  isRetailChannel,
  isRetailChannelUserRole
}
