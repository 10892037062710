import { config } from '@config'
import { storage } from '@services/storageService'
import { HttpService } from '@services/HttpService'

class ShowroomDashboardService {
  httpService = new HttpService()
  isActiveLookPresent = async buyingSessionGroupId => {
    const url = `${config.buyingBoardServiceRestEndpoint}lookbook/isActiveLookPresent/buyingSessionGroup/${buyingSessionGroupId}`
    const accessToken: string = storage.getItem('access_token')

    return this.httpService
      .get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(response => response.data)
  }
}

export const showroomDashboardService = new ShowroomDashboardService()
