import { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions'
import { useMutation } from '@apollo/client/react/hooks/useMutation'
import { useQuery } from '@apollo/client/react/hooks/useQuery'
import GET_BUYING_SESSION_PRODUCT_COUNT from './buyingSessionProductsCount'
import ENUM_LIST_BY_NAME from './enumListByName'
import GET_BUYING_SESSION_GROUP from './getBuyingSessionGroup'
import GET_BUYING_SESSION_GROUP_NAME from './getBuyingSessionGroupName'
import UPDATE_BUYING_SESSION_GROUP_STATUS_WHOLESALE from './updateBuyingSessionGroupStatus'
import { ENUM_LIST_WITH_PATTERN } from '../../buyingSessionGroupProductWrapper/graphql/enumListWithPattern'

type CustomQueryOptions = {
  fetchPolicy: WatchQueryFetchPolicy
}

export const useBuyingSessionGroup = (
  buyingSessionGroupId: string,
  options?: CustomQueryOptions
) => {
  const { fetchPolicy } = options || {}
  return useQuery(GET_BUYING_SESSION_GROUP, {
    fetchPolicy: fetchPolicy || 'cache-first',
    skip: !buyingSessionGroupId || buyingSessionGroupId === 'undefined',
    variables: {
      id: buyingSessionGroupId
    }
  })
}

export const useEnumListByName = (
  name: string,
  fetchPolicy: WatchQueryFetchPolicy = 'cache-first'
) => {
  return useQuery(ENUM_LIST_BY_NAME, {
    skip: !name,
    variables: {
      name
    },
    fetchPolicy
  })
}

export const useEnumListByWhereClause = (
  where: any,
  fetchPolicy: WatchQueryFetchPolicy = 'cache-first'
) => {
  return useQuery(ENUM_LIST_WITH_PATTERN, {
    variables: {
      where
    },
    fetchPolicy
  })
}

export const useBuyingSessionProductNumber = (buyingSessionId: string) => {
  return useQuery(GET_BUYING_SESSION_PRODUCT_COUNT, {
    skip: !buyingSessionId,
    variables: { buyingSessionId }
  })
}

export const useUpdateBuyingSessionGroupStatus = () => {
  const [ref] = useMutation(UPDATE_BUYING_SESSION_GROUP_STATUS_WHOLESALE)
  return ref
}

export const useBuyingSessionGroupName = (
  buyingSessionGroupId: string,
  fetchPolicy: WatchQueryFetchPolicy = 'cache-first'
) => {
  return useQuery(GET_BUYING_SESSION_GROUP_NAME, {
    skip: !buyingSessionGroupId,
    variables: {
      id: buyingSessionGroupId
    },
    fetchPolicy
  })
}
