import { CentricTheme, StyledProps } from '@components/3rd-party/mui'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import React from 'react'

const styles = (theme: CentricTheme) => ({
  circle: {
    width: 16,
    height: 16,
    borderRadius: '50%'
  },
  tag: {
    maxWidth: 179,
    height: 28,
    justifyContent: 'space-between',
    display: 'inline-flex',
    transition: `background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
    alignItems: 'center',
    padding: `0 calc(${theme.spacing(1)} / 2)`
  },
  tagStatus: {
    maxWidth: 64,
    height: 28,
    justifyContent: 'space-between',
    display: 'inline-flex',
    transition: `background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
    alignItems: 'center'
  },
  tagLabel: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: 'Roboto',
    fontSize: 12,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2,
    letterSpacing: 0.37,
    color: '#000000',
    padding: '0px 4px 0px 4px',
    maxWidth: 68
  },
  divider: {
    width: '1px',
    height: '14px',
    font: 'var(----typography-font-bold)',
    background: '#000000'
  }
})

interface ITag extends StyledProps {
  name?: string
  color?: any
  clusterStatus?: string
  customeToolTipText?: string
  clusterId?: string
  statusId?: String
  tagStyle?: any
  tooltipPlacement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
}

@((withStyles as any)(styles))
export class Tag extends React.Component<ITag> {
  render() {
    const {
      props: {
        classes,
        name,
        className,
        color,
        clusterStatus,
        customeToolTipText,
        tagStyle,
        tooltipPlacement
      }
    } = this
    return (
      <React.Fragment>
        <div>
          {color && (
            <div
              className={classNames(classes.circle, className)}
              style={{ backgroundColor: color }}
            />
          )}
        </div>
        <Tooltip
          title={`${customeToolTipText ? customeToolTipText : name}  ${
            clusterStatus ? `| ${clusterStatus}` : ''
          }`}
          placement={tooltipPlacement || 'bottom'}
        >
          <div className={classes.tag}>
            <Typography className={classNames(tagStyle, classes.tagLabel)}>
              {name}
            </Typography>
            {clusterStatus && (
              <>
                <div className={classes.divider}></div>
                <Typography className={classNames(classes.tagLabel)}>
                  {clusterStatus}
                </Typography>
              </>
            )}
          </div>
        </Tooltip>
      </React.Fragment>
    )
  }
}
