/*
 * @Author: Raeesaa Metkari
 * @Date: 2020-09-09 20:25:24
 * @Last Modified by: Raeesaa Metkari
 * @Last Modified time: 2020-09-23 00:00:01
 */

export enum ReportColumnLabels {
  name = 'Department / Class / Size',
  nameForClientSummary = 'Department',
  clientName = 'Market',
  zone = 'Zone',
  country = 'Country',
  clientCode = 'Market Code',
  targetValue = 'Budget (Target)',
  targetValueForWorldwideSummary = 'Target Value',
  targetValueForClientReport = 'Budget',
  targetValueForClientSummary = 'Total Budget',
  inProcessValue = 'Order In Process',
  confirmedValue = 'Order Confirmed',
  validatedValue = 'Order Validated',
  totalValue = 'Total Orders',
  totalValueForSummary = 'Actual Value',
  totalVsTargetValue = 'Total Orders vs. Budget',
  totalVsTargetValuePercent = 'Total Orders vs. Budget %',
  targetValueMix = 'Mix Target Value',
  totalValueMix = 'Mix Actual Value',
  targetQuantity = 'Global Order Target QTY',
  inBookingQuantity = 'Draft QTY',
  inProcessQuantity = 'In Process QTY',
  confirmedQuantity = 'Confirmed QTY',
  validatedQuantity = 'Validated QTY',
  totalQuantity = 'Total Order QTY',
  globalQuantity = 'Global Order Target QTY',
  totalVsTargetQuantity = 'QTY Difference',
  totalVsTargetQuantityPercent = 'VAR %',
  targetQuantityMix = 'Mix % Target ',
  totalQuantityMix = 'Mix % Order',
  totalOrderProducts = 'NB ACs Buy ',
  totalProducts = 'NB ACs',
  marketName = 'Market',
  channel = 'Channel',
  marketCode = 'Market Code',
  departMent = 'Department',
  class = 'Class',
  size = 'Size',
  orderQty = 'Order Qty',
  targetQty = 'Target Qty'
}

export enum ReportColumnMapping {
  name = 'name',
  clientName = 'clientName',
  activityName = 'activityName',
  familyName = 'familyName',
  lineName = 'lineName',
  zone = 'zone',
  country = 'country',
  clientCode = 'code',
  targetValue = 'kpis.targetValue',
  inProcessValue = 'kpis.inprocessValue',
  confirmedValue = 'kpis.confirmValue',
  validatedValue = 'kpis.validatedValue',
  totalValue = 'kpis.totalValue',
  totalVsTargetValue = 'kpis.totalVsTargetValue',
  totalVsTargetValuePercent = 'kpis.totalVsTargetValuePerc',
  targetValueMix = 'kpis.mixTargetValue',
  totalValueMix = 'kpis.mixActualValue',
  targetQuantity = 'kpis.targetQuantity',
  inProcessQuantity = 'kpis.inprocessQuantity',
  confirmedQuantity = 'kpis.confirmQuantity',
  validatedQuantity = 'kpis.validatedQuantity',
  totalQuantity = 'kpis.totalQuantity',
  globalQuantity = 'kpis.targetQuantity',
  totalVsTargetQuantity = 'kpis.totalVsTargetQuantity',
  totalVsTargetQuantityPercent = 'kpis.totalVsTargetQuantityPerc',
  targetQuantityMix = 'kpis.mixTargetQuantity',
  totalQuantityMix = 'kpis.mixActualQuantity',
  totalOrderProducts = 'kpis.nbAcsBuy',
  totalProducts = 'totalProducts',
  clientBudgetValue = 'kpis.clientBudgetValue',
  totalVsClientBudgetValuePercent = 'kpis.totalVsClientBudgetValuePerc',
  totalVsClientBudgetValue = 'kpis.totalVsClientBudgetValue',
  draftQuantity = 'kpis.draftQuantity',
  channel = 'channel'
}
