import { CentricTheme, StyledProps } from '@components/3rd-party/mui'
import { CrossIcon } from '@components/UI-Components/svg'
import Chip from '@material-ui/core/Chip'
import { withStyles } from '@material-ui/core/styles'
import { getFilterChipsData } from '@services/collectionServices'
import { stores } from '@stores'
import { observer } from 'mobx-react'
import React from 'react'
import { FilterCollapse } from '../Collection/FilterCollapse'
import isEmpty from 'lodash/isEmpty'

const styles = (theme: CentricTheme) => ({
  root: {
    zIndex: 1006,
    display: 'flex',
    flexWrap: 'wrap',
    padding: 8
  },
  chip: {
    margin: '8px 8px 0px 8px',
    borderRadius: 0,
    justifyContent: 'flex-start'
  },
  chipsRoot: {
    borderRadius: 0,
    justifyContent: 'flex-start'
  }
})

type FilterValue = {
  value: string | boolean
  label: string
}

interface IFliterChipsDescription extends StyledProps {
  children?: any
  chips?: any
  chipsArrayToDisplay?: Array<FilterValue>
  chipsArrayInDropDown?: Array<FilterValue>
  onFilterRemove?: (data: FilterValue) => void
}

/**
 * @Deprecated as retail will be removed
 */

@((withStyles as any)(styles))
@observer
export class FilterChips extends React.Component<IFliterChipsDescription> {
  handleDelete = chipToDelete => {
    const {
      filter: { onFilterChipRemove }
    } = stores
    const {
      props: { onFilterRemove }
    } = this
    if (onFilterRemove) {
      onFilterRemove(chipToDelete)
    } else {
      onFilterChipRemove(chipToDelete)
    }
  }
  render() {
    const [
      {
        props: {
          classes,
          chipsArrayToDisplay: chipsToDisplayedPassedInProps,
          chipsArrayInDropDown: chipsInDropdownPassedInProps
        },
        handleDelete
      },
      {
        filter: { finalFilterToApply }
      }
    ] = [this, stores]
    const { chipsArrayToDisplay, chipsArrayInDropDown } =
      getFilterChipsData(finalFilterToApply)
    let chipsToDisplay = chipsToDisplayedPassedInProps || chipsArrayToDisplay
    let chipsInDropDown = chipsInDropdownPassedInProps || chipsArrayInDropDown
    return isEmpty(chipsToDisplay) ? null : (
      <div className={classes.root}>
        {chipsToDisplay.length
          ? chipsToDisplay.map((data, index) => (
              <Chip
                key={`chipsToDisplay-${index}`}
                label={data.label}
                deleteIcon={<img src={CrossIcon} alt='' />}
                onDelete={() => handleDelete(data)}
                className={classes.chip}
              />
            ))
          : null}
        {chipsInDropDown.length ? (
          <FilterCollapse panelName='More' style={{ zIndex: 2 }} variant='chips'>
            {chipsInDropDown.map((data, index) => (
              <Chip
                key={`chipsInDropDown-${index}`}
                label={data.label}
                deleteIcon={<img src={CrossIcon} alt='' />}
                onDelete={() => handleDelete(data)}
                className={classes.chip}
              />
            ))}
          </FilterCollapse>
        ) : null}
      </div>
    )
  }
}
