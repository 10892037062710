/*
 * @Author: Raeesaa Metkari
 * @Date: 2019-11-22 19:10:21
 * @Last Modified by: Raeesaa Metkari
 * @Last Modified time: 2019-12-04 18:33:16
 */
import { useQuery } from '@apollo/client/react/hooks/useQuery'
import { User } from '@models'
import { constants } from '@modules/retail/buyingSession/constants'
import { IPermissions } from '@routes'
import {
  getAssociatedLooks,
  getProductClustersWithStatus,
  getProductDetails,
  getSubstitutesInLooks
} from '@services/collectionServices'
import { storage } from '@services/storageService'
import { stores, strings } from '@stores'
import map from 'lodash/map'
import memoize from 'memoize-one'
import React, { memo } from 'react'
import { areEqual } from 'react-window'
import { GET_CLUSTER_STATUS } from '../graphql/getClusterStatuses'
import { AssortmentGrid } from './AssortmentGrid'

interface MyProps {
  assortmentProducts: {}
  refetch?: (variables?: any) => Promise<any>
  permissions?: IPermissions
  assortment: {}
  buyingSession: { status: string }
}

const getProcessedAssortmentProducts = memoize((assortmentProducts, assortment) => {
  const user = storage.getItem<User>('user')
  const zones = user.membership && user.membership.zones

  const {
    nav: {
      params: { assortmentType }
    },
    product: { getProductStatus },
    sidebar: { isRegionalMerchandiser }
  } = stores
  return map(
    assortmentProducts,
    ({
      id: assortmentProductId,
      product,
      buyerClusters,
      retailPrice: assortmentRetailPrice
    }) => {
      let processedProduct: any = getProductDetails(
        product,
        false,
        assortmentType === strings.cluster && assortment ? assortment.cluster : null
      )
      processedProduct.productStatusToBeDisplayed = getProductStatus(
        processedProduct.productStatus,
        processedProduct.active
      )

      processedProduct.actualProductObj = product
      // TODO: Rename this field to something more meaningful
      processedProduct.tags =
        product && product.assortmentProducts
          ? getProductClustersWithStatus(
              product.assortmentProducts,
              processedProduct.clusterStatuses
            )
          : []
      processedProduct.associatedLooks = getAssociatedLooks(product)
      processedProduct.substitutesInLooks = getSubstitutesInLooks(product)

      if (isRegionalMerchandiser()) {
        if (processedProduct.zoneRetailPrice) {
          processedProduct.retailPriceToBeDisplayed = processedProduct.zoneRetailPrice
        } else if (assortmentRetailPrice) {
          processedProduct.retailPriceToBeDisplayed = assortmentRetailPrice
        } else if (
          processedProduct.zoneAssortmentProducts.length &&
          processedProduct.zoneAssortmentProducts[0].zoneRetailPrice
        ) {
          processedProduct.retailPriceToBeDisplayed =
            processedProduct.zoneAssortmentProducts[0].zoneRetailPrice
        }
      } else {
        processedProduct.retailPriceToBeDisplayed = processedProduct.retailPrice
      }

      processedProduct.isRestricted =
        isRegionalMerchandiser() &&
        zones &&
        !!zones.find(
          zone =>
            processedProduct.restrictedZones &&
            !!processedProduct.restrictedZones.find(
              restrictedZone => restrictedZone.id === zone.id
            )
        )

      // Buyer clusters
      const zoneAssortmentProductBuyerClusters =
        processedProduct.zoneAssortmentProducts.length &&
        processedProduct.zoneAssortmentProducts[0]
      processedProduct.rmClusters = zoneAssortmentProductBuyerClusters
        ? zoneAssortmentProductBuyerClusters.buyerClusters
        : buyerClusters

      return {
        assortmentProductId,
        buyerClusters,
        product: processedProduct,
        assortmentRetailPrice
      }
    }
  )
})

export const AssortmentProductCardGrid: React.FunctionComponent<MyProps> = memo(props => {
  const { refetch, assortmentProducts, permissions, assortment, buyingSession } = props
  const {
    assortment: {
      getProductStatus,
      handleTagDrop,
      removeBuyerClusterFromZoneAssortmentProduct,
      removeZoneRestriction,
      removeAssortmentProduct,
      removeSalesPeriodFromProduct,
      removeFreeTag,
      deleteAssortmentProductFromGridView
    },
    sidebar: { isRegionalMerchandiser }
  } = stores

  const { data } = useQuery(GET_CLUSTER_STATUS, {
    variables: { channel: 'Retail' },
    fetchPolicy: 'cache-and-network'
  })

  const processedData = getProcessedAssortmentProducts(assortmentProducts, assortment)

  return (
    <>
      {map(
        processedData,
        ({ assortmentProductId, product, buyerClusters, assortmentRetailPrice }) => {
          const isClosedOrFrozen = buyingSession
            ? buyingSession.status === constants.CLOSED ||
              (isRegionalMerchandiser() && buyingSession.status === constants.FROZEN)
            : false
          const canDelete =
            permissions.canEdit && !isClosedOrFrozen && product.productStatus !== 'CORE'
          return (
            <AssortmentGrid
              key={assortmentProductId}
              assortmentProductId={assortmentProductId}
              assortment={assortment}
              permissions={permissions}
              product={product}
              statuses={data ? data.statuses : []}
              getProductStatus={getProductStatus}
              deleteProduct={deleteAssortmentProductFromGridView}
              buyingSession={buyingSession}
              handleTagDrop={handleTagDrop}
              buyerClusters={buyerClusters}
              removeBuyerClusterFromZoneAssortmentProduct={
                removeBuyerClusterFromZoneAssortmentProduct
              }
              removeZoneRestriction={removeZoneRestriction}
              removeAssortmentProduct={removeAssortmentProduct}
              refetch={refetch}
              removeSalesPeriodFromProduct={removeSalesPeriodFromProduct}
              removeFreeTag={removeFreeTag}
              assortmentRetailPrice={assortmentRetailPrice}
              canDelete={canDelete}
            />
          )
        }
      )}
    </>
  )
}, areEqual)
