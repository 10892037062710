export enum KeyCode {
  Backspace = 8,
  Tab = 9,
  Shift = 16,
  Ctrl = 17,
  Alt = 18,
  Space = 32,
  Escape = 27,
  Enter = 13,
  PageUp = 33,
  PageDown = 34,
  End = 35,
  Home = 36,
  Left = 37,
  Up = 38,
  Right = 39,
  Down = 40,
  Delete = 46,
  F12 = 123
}
