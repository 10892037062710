import { gql } from '@apollo/client/core'

class StringGraphQLFragments {
  buyingSessionProductWithAttributes = gql`
    fragment buyingSessionProduct on BuyingSessionProduct {
      id
      productStatus
      product {
        attributes {
          strVal
          definition {
            name
          }
        }
      }
    }
  `

  buyingSessionProductWithAttrAndAssortmentProducts = gql`
    fragment buyingSessionProductDetail on BuyingSessionProduct {
      id
      isActive
      productStatus
      moq
      assortmentProducts: clusterAssortmentProducts {
        id
        assortment {
          id
          cluster {
            id
            name
            colorHex
          }
        }
      }
      zoneAssortmentProducts {
        id
        storeCount
        bet {
          id
          quantity
        }
        buy {
          id
          quantity
        }
        zoneAssortment {
          id
          zone {
            id
            name
          }
        }
        buyerClusters {
          id
          name
          colorHex
        }
        retailPrice: zoneRetailPrice {
          id
          price
          priceList {
            id
            currency {
              id
              code
            }
          }
        }
        comments(orderBy: updatedAt_DESC) {
          id
          text
          zoneAssortmentProduct {
            id
          }
          user {
            id
            roles {
              id
              name
            }
          }
        }
      }
      product {
        id
        name
        image {
          id
        }
        images {
          id
        }
        attributes {
          id
          strVal
          intVal
          tag {
            id
            name
          }
          definition {
            id
            name
            type
            displayName {
              id
              singular
            }
          }
        }
      }
    }
  `
}

export const assortmentStringFragments = new StringGraphQLFragments()
