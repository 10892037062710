import '@styles/RecommendedIcon/style.css'
import React from 'react'
import Icon from '../Icons'
import { RECOMMENDED_ICON } from '../resources'
import Typography from '../Typography'

const SmallRecommendedIcon: React.FC = (): JSX.Element => {
  return (
    <div className={'recommended'}>
      <Typography variant={'body2'} classes={'recommended-text'}>
        Reco
      </Typography>
      <Icon imageSrc={RECOMMENDED_ICON} alt={'recommended'} />
    </div>
  )
}

export default SmallRecommendedIcon
