import { config } from '@config'
import { OrderType } from '@modules/common/models/enums/OrderManagement'
import { HttpService } from '@services/HttpService'
import { storage } from '@services/storageService'
import { ORDER_SERVICE_ENDPOINTS } from '../constants/apiConstants'

export default class OrderDataService {
  httpService = new HttpService()

  getCollectionHierarchy = (
    bsgId,
    orderId,
    orderType,
    filterData = [],
    searchKey = '',
    clientOrderId
  ) => {
    const orderPath = `/${orderType ?? OrderType.Client}Order/${orderId}`
    const url = `${config.buyingBoardServiceRestEndpoint}${ORDER_SERVICE_ENDPOINTS.GET_ORDER_PRODUCTS_HIERARCHY}/buyingSessionGroup/${bsgId}${orderPath}`
    const accessToken: string = storage.getItem('access_token')
    const body = {
      filter: filterData
    }
    return this.httpService
      .post(url, body, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params: {
          ...(searchKey ? { searchKey } : {})
        }
      })
      .then(response => response.data?.data?.productHierarchy?.categories)
  }

  fetchCollectionProductsByHierarchy = (
    bsgId: string,
    orderId: string,
    orderType: string,
    filterData = {},
    searchKey = ''
  ) => {
    const orderPath = `/${orderType ?? OrderType.Client}Order/${orderId}`
    const url = `${config.buyingBoardServiceRestEndpoint}${ORDER_SERVICE_ENDPOINTS.GET_COLLECTION_PRODUCTS}/buyingSessionGroup/${bsgId}${orderPath}`
    const accessToken: string = storage.getItem('access_token')
    const body = {
      filter: filterData
    }
    return this.httpService
      .post(url, body, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params: {
          ...(searchKey ? { searchKey } : {})
        }
      })
      .then(response => response.data?.data)
  }

  fetchCollectionFilterAttributes = (
    bsgId: string,
    orderId: string,
    orderType: OrderType
  ) => {
    const orderPath = `/${orderType ?? OrderType.Client}Order/${orderId}`
    const url = `${config.buyingBoardServiceRestEndpoint}${ORDER_SERVICE_ENDPOINTS.GET_COLLECTION_FILTER_ATTRIBUTES}/buyingSessionGroup/${bsgId}${orderPath}`
    const accessToken: string = storage.getItem('access_token')
    return this.httpService
      .get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(response => response.data?.data?.filterAttributes)
  }

  removeProductToOrder = async (
    orderId: string,
    buyingSessionProductId: string,
    orderType: OrderType,
    includeKPIs = false,
    includeDoorKPIs = false,
    filterData = {},
    searchKey = ''
  ) => {
    try {
      const urlParam = orderType === OrderType.Client ? 'clientOrder' : 'doorOrder'
      const url = `${config.buyingBoardServiceRestEndpoint}${ORDER_SERVICE_ENDPOINTS.GET_ORDER_PRODUCT_DETAILS}/${urlParam}/${orderId}/buyingSessionProduct/${buyingSessionProductId}?includeKPIs=${includeKPIs}&includeDoorKPIs=${includeDoorKPIs}`
      const accessToken: string = storage.getItem('access_token')

      const response = await this.httpService.delete(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params: {
          ...(searchKey ? { searchKey } : {})
        },
        data: {
          filter: filterData
        }
      })
      return response.data?.data
    } catch (e) {
      console.log(e)
      throw e
    }
  }

  addProductsToClientOrder = async (
    orderId: string,
    filterData: any,
    doorOrderIds: string[],
    searchKey: string
  ) => {
    const url = `${config.buyingBoardServiceRestEndpoint}${ORDER_SERVICE_ENDPOINTS.GET_ORDER_PRODUCT_DETAILS}/clientOrder/${orderId}`
    const accessToken: string = storage.getItem('access_token')
    return this.httpService
      .post(
        url,
        {
          doorOrderIds,
          deleteProducts: false,
          isDoorOrder: false,
          filters: filterData
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          params: { searchKey }
        }
      )
      .then(response => response.data?.data)
  }

  removeProductsFromClientOrder = (
    orderId: string,
    filterData = {},
    doorOrderIds,
    searchKey
  ) => {
    const url = `${config.buyingBoardServiceRestEndpoint}${ORDER_SERVICE_ENDPOINTS.GET_ORDER_PRODUCT_DETAILS}/clientOrder/${orderId}`
    const accessToken: string = storage.getItem('access_token')
    return this.httpService
      .post(
        url,
        {
          doorOrderIds,
          deleteProducts: true,
          isDoorOrder: false,
          filters: filterData
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          params: { searchKey }
        }
      )
      .then(response => response.data?.data)
  }

  addProductsToDoorOrder = (orderId: string, filterData = {}, doorOrderId, searchKey) => {
    const url = `${config.buyingBoardServiceRestEndpoint}${ORDER_SERVICE_ENDPOINTS.GET_ORDER_PRODUCT_DETAILS}/clientOrder/${orderId}`
    const accessToken: string = storage.getItem('access_token')
    return this.httpService
      .post(
        url,
        {
          doorOrderIds: [doorOrderId],
          deleteProducts: false,
          isDoorOrder: true,
          filters: filterData
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          params: { searchKey }
        }
      )
      .then(response => response.data?.data)
  }

  removeProductsFromDoorOrder = (
    orderId: string,
    filterData = {},
    doorOrderId,
    searchKey
  ) => {
    const url = `${config.buyingBoardServiceRestEndpoint}${ORDER_SERVICE_ENDPOINTS.GET_ORDER_PRODUCT_DETAILS}/clientOrder/${orderId}`
    const accessToken: string = storage.getItem('access_token')
    return this.httpService
      .post(
        url,
        {
          doorOrderIds: [doorOrderId],
          deleteProducts: true,
          isDoorOrder: true,
          filters: filterData
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          params: { searchKey }
        }
      )
      .then(response => response.data?.data)
  }
}
