import { MessageDialog } from '@components/UI-Components/MessageDialog'
import { PrimaryButton } from '@components/UI-Components/PrimaryButton'
import { ErrorContainer } from '@modules/errors/containers/Error'
import { useBuyingSessionGroup } from '@modules/wholesale/buyingGroupSummary/graphql/hooks'
import Typography from '@material-ui/core/Typography'
import { getUTCDateFromTimeStamp } from '@services/commonServices'
import { stores, strings } from '@stores'
import isEmpty from 'lodash/isEmpty'
import { useObserver } from 'mobx-react-lite'
import React, { useEffect } from 'react'

import { BuyingSessionGroupDateSkeleton } from './BuyingSessionGroupDateSkeleton'
import * as css from './BuyingSessionGroupDateUpdate.css'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'

interface MyProps {}

export const BuyingSessionGroupDateUpdate: React.FunctionComponent<MyProps> = () => {
  return useObserver(function useHook() {
    const {
      nav: { buyingSessionGroupId },
      wholesaleBuyingSessionStore: {
        onBuyingSessionDateChange,
        resetBuyingSessionDate,
        buyingSessionDate,
        buyingSessionDateUpdateInProgress: isSaveInProgress,
        onSubmit,
        openConfirmation,
        closeConfirmationDialog
      },
      buyingGroupSetting: { getBSGCloseStatus }
    } = stores

    const groupId = buyingSessionGroupId ?? undefined
    const {
      loading: groupLoading,
      error: bsGroupError,
      data: buyingSessionGroupData
    } = useBuyingSessionGroup(groupId, {
      fetchPolicy: 'cache-and-network'
    })

    const buyingSessionGroup = buyingSessionGroupData?.buyingSessionGroup
    const isBSGClosed = getBSGCloseStatus(buyingSessionGroup)
    const allowSubmit = buyingSessionGroup?.BSGDate !== buyingSessionDate
    useEffect(() => {
      if (buyingSessionGroup?.BSGDate) {
        onBuyingSessionDateChange(getUTCDateFromTimeStamp(buyingSessionGroup?.BSGDate))
      }

      return () => {
        resetBuyingSessionDate()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buyingSessionGroup?.BSGDate])

    const loading = groupLoading && isEmpty(buyingSessionGroupData)
    if (loading) {
      return <BuyingSessionGroupDateSkeleton />
    }

    if (bsGroupError) return <ErrorContainer />

    return (
      <div className={css.root}>
        <div className={css.titleContainer}>
          <Typography className={css.legend}>{strings.buyingSessionDate}</Typography>
          {isBSGClosed ? (
            <Typography className={css.disabledMessage}>
              {strings.isBuyingSessionGroupDateDisabledMessage}
            </Typography>
          ) : null}
        </div>
        <div className={css.radioGroupContainer}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              className={`${css.bsgDateFieldTitle} ${css.cursor} ${css.text}`}
              name='startDate'
              disablePast
              type='text'
              margin='normal'
              format='dd/MM/yyyy'
              error={buyingSessionDate ? false : true}
              helperText={
                !buyingSessionDate ? strings.buyingSessionDateErrorMessage : undefined
              }
              value={getUTCDateFromTimeStamp(buyingSessionDate)}
              onChange={onBuyingSessionDateChange}
              label={strings.buyingSessionDate}
              disabled={isBSGClosed || isSaveInProgress}
            />
          </MuiPickersUtilsProvider>
        </div>
        <div>
          <PrimaryButton
            disabled={isSaveInProgress || isBSGClosed}
            loading={isSaveInProgress}
            onClick={allowSubmit ? onSubmit : null}
          >
            Apply
          </PrimaryButton>
        </div>
        <MessageDialog
          open={openConfirmation}
          title={'Sync Result'}
          message={strings.priceSyncFailed}
          handleClose={closeConfirmationDialog}
        />
      </div>
    )
  })
}
