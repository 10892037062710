/*
 * @Author: Raeesaa Metkari
 * @Date: 2019-12-11 14:46:15
 * @Last Modified by: Raeesaa Metkari
 * @Last Modified time: 2019-12-12 16:48:06
 */

export enum ListViewColumnIdentifiers {
  ProductInfo = 'ProductInfo',
  ProductAttributes = 'ProductAttributes',
  CMCluster = 'CMCluster',
  RMCluster = 'RMCluster',
  BuyerCluster = 'BuyerCluster',
  Tags = 'Tags',
  ExcludedZones = 'ExcludedZones',
  SalesPeriod = 'SalesPeriod',
  TotalWeeks = 'TotalWeeks',
  TotalStores = 'TotalStores',
  TotalDoors = 'TotalDoors',
  Zones = 'Zones',
  Price = 'Price',
  AvgWeeklySales = 'AVgWeeklySales',
  MixQty = 'MixQty',
  MixValue = 'MixValue',
  AvgDepth = 'AvgDepth',
  SkuEff = 'SkuEff',
  Value = 'Value',
  OrderValue = 'OrderValue',
  SalesQty = 'SalesQty',
  SizedQty = 'SizedQty',
  DraftQty = 'DraftQty',
  OrderQty = 'OrderQty',
  BetQty = 'BetQty',
  CMComments = 'CMComments',
  RMComments = 'RMComments',
  Channels = 'Channels',
  WholesaleFreeTags = 'WholesaleFreeTags',
  RetailFreeTags = 'RetailFreeTags',
  Drops = 'Drops',
  ExcludedClients = 'ExcludedClients',
  ActualMixTargetMix = 'ActualMixTargetMix',
  Quantity = 'Quantity'
}

export enum ListViewAssortmentTypes {
  zone = 'zone',
  store = 'store',
  cluster = 'cluster'
}
