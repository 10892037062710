import { withStyles } from '@material-ui/core/styles'
import React from 'react'

const styles = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  item: {
    display: 'flex',
    alignItems: 'center'
  }
})

const ProductDetailActionsContainer = ({ classes, children }) => (
  <div className={classes.container}>{children}</div>
)

const ProducttDetailActionItemContainer = ({ classes, children }) => (
  <div className={classes.item}>{children}</div>
)

export const ProductDetailActions = withStyles(styles)(
  React.memo(ProductDetailActionsContainer)
)
export const ProductDetailActionItem = withStyles(styles)(
  React.memo(ProducttDetailActionItemContainer)
)
