export enum RestServiceEndpoints {
  INSERT_IMAGES = 'insert-images',
  REPLACE_IMAGES = 'replace-images',
  DELETE_IMAGES = 'delete-images',
  IMAGE_DELETE = 'delete-images',
  CREATE_BS_OR_BSG = 'create-buying-session-or-group',
  CREATE_DRAFT_ORDER = 'create-draft-order',
  IMPORT_ORDERS = 'import-orders',
  DOWNLOAD_CLIENT_ORDERS = 'download/sa-excel/',
  DOWNLOAD_BS_ORDERS = 'download/excel/retail-orders-download/buyingSessionGroup/',
  DOCUMENT_UPLOAD = 'document-upload',
  DOCUMENT_DELETE = 'document-delete',
  ADD_PRODUCT_TO_CLIENT = `addProductToClient`,
  ADD_PRODUCT_TO_DOOR = `addProductToDoor`,
  CONVERT_WISHLIST_TO_ORDER = `convertWishListToOrder`,
  CLEAR_WISHLIST = 'clearWishlist',
  USER_PREFERENCES = 'user/preferences',
  RETAIL_STORE = 'store',
  RETAIL_EXPORT_STORES = 'store/clusters/zone',
  FORECAST_KPIS = 'forecast/kpis',
  FORECAST = 'forecast',
  BUY_KPIS = 'buyKPIs/retail',
  GET_FORECAST_SUMMARY = 'getForecastSummary',
  COUNTER_OFFER = 'rmClusters',
  EXPORT_CLUSTER = 'download/excel/clustering/buyingSessionGroup/'
}
