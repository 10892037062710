import '@styles/MultiLineInput/style.css'
import cn from 'classnames'
import React, { InputHTMLAttributes } from 'react'

interface IMultiLineInput extends InputHTMLAttributes<HTMLTextAreaElement> {
  // define custom props
}

export const MultiLineInput: React.FC<IMultiLineInput> = props => {
  const { placeholder, children, className, ...rest } = props

  return (
    <textarea
      placeholder={placeholder}
      rows={5}
      className={cn('textarea', className)}
      {...rest}
    >
      {children}
    </textarea>
  )
}
