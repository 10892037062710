import MdPlayCircleOutline from '@meronex/icons/md/MdPlayCircleOutline'
import '@styles/Carousel/style.css'
import cn from 'classnames'
import React from 'react'
import Flickity, { FlickityOptions } from 'react-flickity-component'

export interface ICarouselMedia {
  /**
   * Sets id to be passed as key while mapping multiple components
   */
  id: string

  /**
   * Sets image path, thumbnail path in case of a video
   */
  src: string

  /**
   * Decides whether to show video specific icons
   * such as play button, on the video thumbnail
   */
  isVideo?: boolean

  /**
   * Sets url in the video player
   */
  videoUrl?: string
}

interface ICarousel {
  images: ICarouselMedia[]
  options?: FlickityOptions
  className?: string
  cellClassName?: string
  onCellClick?: (index: any, type?: string) => void
}

const Carousel: React.FC<ICarousel> = (props): JSX.Element => {
  const { images, options, className, cellClassName, onCellClick } = props

  const imageSlides = images.map(img => {
    const { src, id, isVideo } = img
    return (
      <div
        key={id}
        className={cn(['carousel-cell', cellClassName])}
        onClick={() => onCellClick && onCellClick(img)}
      >
        <img
          src={src}
          key={id}
          alt={''}
          className={cn('carousel-cell-img', {
            'carousel-cell-img-video-thumb': isVideo
          })}
        />
        {isVideo && (
          <MdPlayCircleOutline className={'carousel-cell-img-video-icon'} size={42} />
        )}
      </div>
    )
  })

  return (
    <div>
      {imageSlides.length && (
        <Flickity
          className={cn(['carousel carousel-nav', className])}
          elementType={'div'} // default 'div'
          options={{
            cellAlign: 'left',
            pageDots: false,
            prevNextButtons: images.length > 4,
            percentPosition: false,
            draggable: false,
            ...options
          }} // takes flickity options {}
        >
          {imageSlides}
        </Flickity>
      )}
    </div>
  )
}

export default Carousel
