import React from 'react'
import Typography from '../Typography'
import { useTranslation } from 'react-i18next'
import '@styles/CoreIcon/style.css'
import classNames from 'classnames'

interface ICoreIconProps {
  isInactiveFlagPresent?: boolean
  isOpenedInASlider?: boolean
}

const CoreIcon: React.FC<ICoreIconProps> = ({
  isInactiveFlagPresent = false,
  isOpenedInASlider = false
}): JSX.Element => {
  const { t } = useTranslation()
  return (
    <div
      className={classNames(
        'core-icon',
        { 'top-shifted-core-icon': isInactiveFlagPresent },
        { 'left-shifted-core-icon': isOpenedInASlider }
      )}
    >
      <Typography variant={'body1'} classes={'core-icon-text'}>
        {t('icons.core')}
      </Typography>
    </div>
  )
}

export default CoreIcon
