import { StyledProps } from '@components/3rd-party/mui'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import classNames from 'classnames'
import Typography from '@material-ui/core/Typography'
import { PRODUCT_STATUS } from '@modules/retail/buyingSession/constants'

const styles = theme => ({
  tag: {
    position: 'absolute !important',
    width: '48px',
    height: '19px',
    textAlign: '-webkit-center'
  },
  core: {
    backgroundColor: '#dcdcdc'
  },
  typographyRoot: {
    color: theme.colors.tag
  },
  inactive: {
    backgroundColor: `${theme.colors.red} !important`,
    width: 58,
    zIndex: 1
  },
  label: {
    height: 11,
    fontFamily: 'Roboto',
    fontSize: 10,
    fontWeight: 'bold' as 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.31px',
    color: '#000000',
    paddingTop: '4px'
  }
})

interface MyProps extends StyledProps {
  status?: string
  statusTagStyle?: any
  classes?
  style?: {}
}

const ProductStatusComponent: React.FunctionComponent<MyProps> = ({
  status,
  classes,
  style,
  statusTagStyle
}) => {
  return (
    <div
      className={classNames(statusTagStyle, classes.tag, classes[status?.toLowerCase()!])}
      style={style}
    >
      <Typography className={classes.label} classes={{ root: classes.typographyRoot }}>
        {(PRODUCT_STATUS as any)[status?.toLowerCase()!]}
      </Typography>
    </div>
  )
}

export const ProductStatus = withStyles(styles as any)(React.memo(ProductStatusComponent))
