import { KeyCode } from '@constants'
import { CentricNode } from '@modules/common/models/interfaces/CentricNode'
import { UPDATE_ORDER_PRODUCT_QTY_IN_BULK } from '@modules/showroom/collection/graphql/updateOrderProductQtyInBulk'
import { SA_DOOR_ORDER_PRODUCT_SUMMARY_DETAILS } from '@modules/showroom/collection/graphql/getDoorOrderProductSummaryDetails'
import { SA_DOOR_ORDER_PRODUCTS } from '@modules/showroom/collection/graphql/getDoorOrderProducts'
import { SA_CLIENT_ORDER_PRODUCT_SUMMARY_DETAILS } from '@modules/showroom/collection/graphql/getClientOrderProductSummaryDetails'
import { getProductsByCategories } from '@services/commonServices'
import { getSizeProductModalTableData } from '@services/showroom/SASizeProductModalServices'
import { apolloClient, stores, strings } from '@stores'
import cloneDeep from 'lodash/cloneDeep'
import filter from 'lodash/filter'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import forEach from 'lodash/forEach'
import has from 'lodash/has'
import isEqual from 'lodash/isEqual'
import sum from 'lodash/sum'
import reduce from 'lodash/reduce'
import { action, computed, observable, toJS } from 'mobx'

interface TableData {
  value: any
  sizeIndex?: number
  doorOrderProductId?: string
  sizeId?: string
  isDisabled: boolean
  isSizeMissing?: boolean
}

interface TableRow {
  rowType: string
  data: Array<TableData>
}

export class SizedQtyStore {
  @observable ref = null
  @observable originalProductData = null
  @observable productDataToProcess = null
  @observable showLoader = false
  @observable parentModalCloseHandler = null

  @action setRefToFocus = refInput => {
    this.ref = refInput
  }

  @action setParentModalCloseHandler = closeHandler => {
    this.parentModalCloseHandler = closeHandler
  }

  @action processProductData = processedProduct => {
    this.originalProductData = cloneDeep(processedProduct)
    if (
      !this.productDataToProcess ||
      this.originalProductData.id !== this.productDataToProcess.id
    ) {
      this.productDataToProcess = cloneDeep(processedProduct)
    }
  }

  @computed get tableData(): { headers: Array<string>; rows: Array<TableRow> } {
    const { productDataToProcess } = this
    if (productDataToProcess) {
      return getSizeProductModalTableData(productDataToProcess)
    } else {
      return {
        rows: [],
        headers: []
      }
    }
  }

  @action updateStoreOnQtyChange = (
    orderProductId: string,
    sizeId: string,
    existingQty: number,
    updatedQty: number
  ) => {
    /**
     * Update store only if qty has changed
     */
    if (existingQty !== updatedQty) {
      forEach(Object.keys(this.productDataToProcess), key => {
        if (
          key.startsWith('door-') &&
          this.productDataToProcess[key].id === orderProductId
        ) {
          if (sizeId) {
            const sizeIndex = findIndex(
              this.productDataToProcess[key]?.sizes || [],
              (size: CentricNode) => size.Id === sizeId
            )
            if (
              sizeIndex > -1 &&
              this.productDataToProcess[key].qtyPerSize &&
              this.productDataToProcess[key].qtyPerSize.length
            ) {
              this.productDataToProcess[key].qtyPerSize[sizeIndex] = updatedQty
              this.productDataToProcess[key].totalSizedQuantity = sum(
                this.productDataToProcess[key].qtyPerSize
              )
            }
          } else {
            this.productDataToProcess[key].targetQuantity = updatedQty
          }

          this.productDataToProcess = Object.assign({}, this.productDataToProcess)
        }
      })
    }
  }

  @action handleQtyChange =
    (orderProductId: string, existingQty: number, sizeId: string) => e => {
      e.preventDefault()
      const quantity: number = e?.target?.value ? parseInt(e?.target?.value) : 0
      this.updateStoreOnQtyChange(orderProductId, sizeId, existingQty, quantity)
    }

  @action handleKeyDown =
    (orderProductId: string, existingQty: number, sizeId: string, isLastIndex: boolean) =>
    (e, updatedQuantity) => {
      if (e.keyCode === KeyCode.Enter) {
        const quantity = updatedQuantity ? parseInt(updatedQuantity) : 0
        this.updateStoreOnQtyChange(orderProductId, sizeId, existingQty, quantity)
      }
      if (e.keyCode === KeyCode.Tab && this.ref && isLastIndex && !e.shiftKey) {
        e.preventDefault()
        this.ref.focus()
      }
    }

  @computed get quantityMap() {
    const { productDataToProcess } = this
    const keys = productDataToProcess ? Object.keys(productDataToProcess) : []
    return reduce(
      keys,
      (acc, curr: string) => {
        if (curr.startsWith('door-')) {
          acc.draftQty += this.productDataToProcess[curr]?.targetQuantity || 0
          acc.sizedQty += sum(this.productDataToProcess[curr]?.qtyPerSize || [])
        }
        return acc
      },
      {
        draftQty: 0,
        sizedQty: 0
      }
    )
  }

  @computed get quantityList() {
    const { draftQty, sizedQty } = this.quantityMap
    return [
      {
        name: strings.totalTargetQuantity,
        value: draftQty
      },
      {
        name: strings.totalSizedQuantity,
        value: sizedQty
      }
    ]
  }

  @computed get updatedQtyMap() {
    let updatedQtyMap = {
      orderProductData: '',
      orderProductIds: []
    }
    const { originalProductData, productDataToProcess } = this
    let doorKeys = filter(Object.keys(productDataToProcess), key =>
      key.startsWith('door-')
    )
    let productData = []

    forEach(doorKeys, (key: string) => {
      let originalData = originalProductData[key]
      let currentData = productDataToProcess[key]

      if (
        !isEqual(originalData.qtyPerSize, currentData.qtyPerSize) ||
        !isEqual(originalData.targetQuantity, currentData.targetQuantity)
      ) {
        productData.push({
          id: currentData.id,
          qtyPerSize: currentData.qtyPerSize,
          totalDraftQty: currentData.targetQuantity
        })
        updatedQtyMap.orderProductIds.push(currentData.id)
      }
    })

    updatedQtyMap.orderProductData = JSON.stringify(productData)
    return updatedQtyMap
  }

  @computed get productNode() {
    return this.originalProductData?.node
  }

  updateCategoryCacheOnQtyChange = (cache, updatedDataArr) => {
    const {
      showroomCollection: { orderProductsWhere }
    } = stores
    const { activity, childrenMeta, childrenMetaValues } = getProductsByCategories(
      this.productNode
    )
    const doorOrderProductsWhere = orderProductsWhere(
      activity,
      childrenMeta,
      childrenMetaValues
    )

    try {
      const doorOrderData = cache.readQuery({
        query: SA_DOOR_ORDER_PRODUCTS,
        variables: {
          where: doorOrderProductsWhere
        }
      })

      let doorOrderDataClone = cloneDeep(doorOrderData)

      forEach(updatedDataArr, orderProductJSON => {
        let updatedObject = find(
          doorOrderDataClone.doorOrderProducts?.Items || [],
          item => item.Id === orderProductJSON.id
        )
        if (updatedObject) {
          updatedObject.qtyPerSize = orderProductJSON.qtyPerSize
          updatedObject.totalQty = orderProductJSON.totalDraftQty
        }
      })
      // Write to cache
      cache.writeQuery({
        query: SA_DOOR_ORDER_PRODUCTS,
        variables: {
          where: doorOrderProductsWhere
        },
        data: doorOrderDataClone
      })
    } catch (err) {
      /**
       * NOTE: Logging error here as there won't be any use-case where this query will not be present in cache
       * and therefore, should be updated correctly
       */
      console.error(err)
    }
  }

  updateOrderSummaryCacheOnQtyChange = (cache, updatedDataArr) => {
    const {
      showroomCollection: { isDoorOrder }
    } = stores

    try {
      const orderSummaryData = cache.readQuery({
        query: isDoorOrder
          ? SA_DOOR_ORDER_PRODUCT_SUMMARY_DETAILS
          : SA_CLIENT_ORDER_PRODUCT_SUMMARY_DETAILS,
        variables: {
          orderId: stores?.nav?.queryParams?.orderId
        }
      })

      let orderSummaryClone = cloneDeep(orderSummaryData)
      let doorOrders = has(orderSummaryClone.orderProductSummary, 'DoorOrders')
        ? orderSummaryClone?.orderProductSummary?.DoorOrders || []
        : orderSummaryClone.orderProductSummary
        ? [orderSummaryClone.orderProductSummary]
        : []

      forEach(updatedDataArr, orderProductJSON => {
        let productIndex = -1
        let updatedOrder = find(doorOrders, doorOrder => {
          productIndex = findIndex(
            doorOrder.OrderLineItems,
            (item: { Id: string }) => item.Id === orderProductJSON.id
          )
          return productIndex > -1 ? true : false
        })

        if (productIndex > -1 && updatedOrder.OrderLineItems[productIndex]) {
          updatedOrder.OrderLineItems[productIndex].qtyPerSize =
            orderProductJSON.qtyPerSize
          updatedOrder.OrderLineItems[productIndex].totalQty =
            orderProductJSON.totalDraftQty
        }
      })

      cache.writeQuery({
        query: isDoorOrder
          ? SA_DOOR_ORDER_PRODUCT_SUMMARY_DETAILS
          : SA_CLIENT_ORDER_PRODUCT_SUMMARY_DETAILS,
        variables: {
          orderId: stores?.nav?.queryParams?.orderId
        },
        data: orderSummaryClone
      })
    } catch (err) {
      /**
       * NOTE: Logging error here as there won't be any use-case where this query will not be present in cache
       * and therefore, should be updated correctly
       */
      console.error(err)
    }
  }

  @action saveQtyChange = async (calledOnClose: boolean = false) => {
    // Map of doororder, size, qty
    if (
      !isEqual(toJS(this.originalProductData), toJS(this.productDataToProcess)) &&
      this.updatedQtyMap.orderProductIds.length
    ) {
      if (calledOnClose) {
        this.showLoader = true
      }
      try {
        // Call new mutation
        const variables = { ...this.updatedQtyMap }

        let result = await apolloClient.mutate({
          mutation: UPDATE_ORDER_PRODUCT_QTY_IN_BULK,
          variables
        })

        // Update cache on successful mutation
        if (
          result?.data?.VIPBoard_UpdateOrderProductQuantityInBulk?.status ===
          strings.mutationResultSuccess
        ) {
          let updatedDataArr = []
          try {
            updatedDataArr = variables?.orderProductData
              ? JSON.parse(variables.orderProductData)
              : []
          } catch (err) {}

          if (updatedDataArr.length) {
            this.updateCategoryCacheOnQtyChange(apolloClient.cache, updatedDataArr)
            this.updateOrderSummaryCacheOnQtyChange(apolloClient.cache, updatedDataArr)
          }
        }
      } finally {
        if (this.showLoader) {
          this.showLoader = false
        }
      }
    }
  }

  /**
   * NOTE: Be careful with event handle handler for idle timer. In most of the cases we should pause this and restart again
   * on activity
   * @param event - Event
   */
  @action handleOnIdle = event => {
    this.saveQtyChange()
  }

  @action handleModalClose = async () => {
    try {
      await this.saveQtyChange(true)
    } finally {
      // Call parent prop
      if (this.parentModalCloseHandler) {
        this.parentModalCloseHandler()
      }
    }
  }

  @action resetStore = () => {
    // TODO: Add handling for cleaning originalProductData from store
    // this.originalProductData = null
    this.productDataToProcess = null
    this.parentModalCloseHandler = null
    this.ref = null
  }
}

export const sizedQtyStore = new SizedQtyStore()
