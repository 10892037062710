import { apolloClient, stores, strings } from '@stores'
import cloneDeep from 'lodash/cloneDeep'
import { action, computed, observable } from 'mobx'

import {
  GET_BUYING_GROUP_SALES_DOC_TYPE,
  SET_BUYING_GROUP_SALES_DOC_TYPE
} from '../graphql'

export class SalesDocTypeStore {
  @observable isInProgress = false
  @observable selectedSalesDocType = ''
  @observable savedSalesDocumentTypes = ''

  @action setValue = value => {
    this.selectedSalesDocType = value
    this.savedSalesDocumentTypes = value
  }

  @action handleSalesDocTypeChange = event => {
    const salesDocType = event.target.value
    this.selectedSalesDocType = salesDocType
  }

  @action
  updateSalesDocumentType = async () => {
    const {
      nav: { buyingSessionGroupId }
    } = stores
    try {
      this.isInProgress = true
      await apolloClient.mutate({
        mutation: SET_BUYING_GROUP_SALES_DOC_TYPE,
        variables: {
          buyingSessionGroupId,
          salesDocType: this.selectedSalesDocType
        },
        update: (cache, mutationResult) => {
          const { VIPBoard_SetSalesDocTypeForBuyingSessionGroup } = mutationResult?.data
          const { buyingSessionGroup } = cache.readQuery({
            query: GET_BUYING_GROUP_SALES_DOC_TYPE,
            variables: this.salesDocTypeWhereInput
          })
          if (
            buyingSessionGroup &&
            VIPBoard_SetSalesDocTypeForBuyingSessionGroup?.status ===
              strings.mutationResultSuccess
          ) {
            this.savedSalesDocumentTypes = this.selectedSalesDocType
            buyingSessionGroup.GIV_VIPBuyingSessionGroup_SalesDocType_enum =
              this.selectedSalesDocType
            cache.writeQuery({
              query: GET_BUYING_GROUP_SALES_DOC_TYPE,
              data: { buyingSessionGroup: cloneDeep(buyingSessionGroup) }
            })
          }
        }
      })
    } finally {
      this.isInProgress = false
    }
  }

  @computed get salesDocTypeWhereInput() {
    const {
      nav: { buyingSessionGroupId }
    } = stores
    return {
      buyingSessionGroupId: buyingSessionGroupId
    }
  }
}

export const salesDocTypeStore = new SalesDocTypeStore()
