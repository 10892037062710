import { ErrorContainer } from '@modules/errors/containers/Error'
import { stores } from '@stores'
import isEmpty from 'lodash/isEmpty'
import { useObserver } from 'mobx-react-lite'
import React from 'react'

import { ProductComment } from '../component/ProductComment'
import { ProductCommentItem } from '../component/ProductCommentItem'
import {
  useAddBuyingSessionProductComment,
  useBuyingSessionProductComments,
  useDeleteBuyingSessionProductComment,
  useUpdateBuyingSessionProductComment
} from '../graphql/hooks'

interface IComment {
  canEdit: boolean
  currentUser?: any
  showUserAddComment?: boolean
}

export const ProductCommentContainer: React.FunctionComponent<IComment> = ({
  canEdit,
  currentUser,
  showUserAddComment
}) => {
  return useObserver(function useHook() {
    const {
      productDetail: {
        buyingSessionProductId,
        handleCommentTextChange,
        commentText,
        addBuyingSessionProductComment,
        updateBuyingSessionProductComment,
        deleteBuyingSessionProductComment,
        mutations,
        handleEditComment,
        selectedCommentId,
        isSaveCommentInProgress,
        editedCommentText
      },
      nav: {
        queryParams: { bspId }
      }
    } = stores
    mutations.addBuyingSessionProductComment = useAddBuyingSessionProductComment() as any
    mutations.updateBuyingSessionProductComment =
      useUpdateBuyingSessionProductComment() as any
    mutations.deleteBuyingSessionProductComment =
      useDeleteBuyingSessionProductComment() as any
    const { loading, error, data } = useBuyingSessionProductComments(
      bspId || buyingSessionProductId
    )
    if (error) return <ErrorContainer error={error} />

    const comments =
      data?.getVIPBuyingSessionProduct?.DocumentsAndComments?.Comments ?? []

    return (
      <>
        <ProductComment
          comments={comments}
          currentUser={currentUser}
          handleEditComment={handleEditComment}
          selectedCommentId={selectedCommentId}
          onChange={handleCommentTextChange}
          handleSubmit={updateBuyingSessionProductComment}
          onDelete={deleteBuyingSessionProductComment}
          canEdit={canEdit}
          commentText={editedCommentText}
          showUserAddComment={showUserAddComment}
          loading={loading && isEmpty(data)}
        />
        {showUserAddComment && (
          <ProductCommentItem
            canEdit={canEdit}
            onChange={handleCommentTextChange}
            handleSubmit={addBuyingSessionProductComment}
            commentText={commentText}
            isSaveCommentInProgress={isSaveCommentInProgress}
          />
        )}
      </>
    )
  })
}
