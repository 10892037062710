import { stores, strings } from '@stores'
import { find, isObject } from 'lodash'
import { action, observable } from 'mobx'
import {
  IForecastSummaryData,
  IGetTopBarForecastKpi
} from '../../constants/forecastTopBarKpiInterface'
import { ForecastGlobalTopBarDataStore } from './ForecastGlobalTopBarDataStore'

class ForecastGlobalTopBarStore {
  private forecastGlobalTopBarDataStore = new ForecastGlobalTopBarDataStore()

  @observable forecastKPIsForTopBar: IForecastSummaryData = {
    totalForecastValue: 0,
    totalForecastQuantity: 0,
    totalOTBValue: 0,
    totalOTBQuantity: 0
  }
  @observable forecastTopBarKpiCategory: IGetTopBarForecastKpi = {}

  initialize = async (forecastTopBarKpiCategory: IGetTopBarForecastKpi) => {
    this.forecastTopBarKpiCategory = forecastTopBarKpiCategory
    await this.setForecastKPIsForTopBar(forecastTopBarKpiCategory)
  }

  setForecastKPIsForTopBar = async (
    forecastTopBarKpiCategory?: IGetTopBarForecastKpi
  ) => {
    const { ...allCategory } = isObject(forecastTopBarKpiCategory)
      ? forecastTopBarKpiCategory
      : this.forecastTopBarKpiCategory
    const totalForecastKpiData =
      await this.forecastGlobalTopBarDataStore.getForecastSummary(allCategory)

    this.forecastKPIsForTopBar = {
      ...this.forecastKPIsForTopBar,
      ...totalForecastKpiData
    }
  }

  @action
  updateForecastTopBarKpis = (record: any, clientToBeUpdated, productToBeUpdated) => {
    const {
      nav: {
        queryParams: { assortmentType }
      }
    } = stores

    // Update quick reporting on zone level
    if (assortmentType === strings.client.toLowerCase()) {
      this.forecastKPIsForTopBar = {
        ...this.forecastKPIsForTopBar,
        totalForecastValue:
          this.forecastKPIsForTopBar.totalForecastValue -
          (clientToBeUpdated?.forecastValue || 0) +
          (find(record?.clients, client => client.id === clientToBeUpdated.id)
            ?.forecastValue || 0),
        totalForecastQuantity:
          this.forecastKPIsForTopBar.totalForecastQuantity -
          (clientToBeUpdated?.forecastQuantity || 0) +
          (find(record?.clients, client => client.id === clientToBeUpdated.id)
            ?.forecastQuantity || 0)
      }
    }
    // Update quick reporting on global level
    else {
      this.forecastKPIsForTopBar = {
        ...this.forecastKPIsForTopBar,
        totalForecastValue:
          this.forecastKPIsForTopBar.totalForecastValue -
          (find(productToBeUpdated?.clients, client => client.id === 'GLOBAL')
            ?.forecastValue || 0) +
          (find(record?.clients, client => client.id === 'GLOBAL')?.forecastValue || 0),
        totalForecastQuantity:
          this.forecastKPIsForTopBar.totalForecastQuantity -
          (find(productToBeUpdated?.clients, client => client.id === 'GLOBAL')
            ?.forecastQuantity || 0) +
          (find(record?.clients, client => client.id === 'GLOBAL')?.forecastQuantity || 0)
      }
    }
  }
}

export const forecastGlobalTopBarStore = new ForecastGlobalTopBarStore()
