import { config } from '@config'
import { HttpService } from '@services/HttpService'
import { storage } from '@services/storageService'
import { ORDER_SERVICE_ENDPOINTS } from '../constants/apiConstants'
import { OrderType } from '@modules/common/models/enums/OrderManagement'
import { orderKPIGlobalTopBarStore } from '../stores/OrderKPIGlobalTopBarStore'
// import addMockClusters from '../mocks/mock-clusters'

export class OrderService {
  httpService = new HttpService()
  getClientOrderSummary = async orderId => {
    const url = `${config.buyingBoardServiceRestEndpoint}${ORDER_SERVICE_ENDPOINTS.GET_CLIENT_ORDER_SUMMARY}/${orderId}`
    const accessToken: string = storage.getItem('access_token')

    return this.httpService
      .get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        const doorOrders = response.data?.doorOrders?.filter(item => item.door.name)
        return { ...response.data, doorOrders }
      })
  }

  getDoorOrderSummary = async orderId => {
    const url = `${config.buyingBoardServiceRestEndpoint}${ORDER_SERVICE_ENDPOINTS.GET_DOOR_ORDER_SUMMARY}/${orderId}`
    const accessToken: string = storage.getItem('access_token')

    return this.httpService
      .get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(response => response.data)
  }

  getClientOrderNames = async (bsgId, clientId) => {
    try {
      const url = `${config.buyingBoardServiceRestEndpoint}${ORDER_SERVICE_ENDPOINTS.GET_ORDER_NAVIGATION_DATA}/buyingSessionGroup/${bsgId}/client/${clientId}`
      const accessToken: string = storage.getItem('access_token')

      const response = await this.httpService.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      return response?.data?.data?.clientOrders
    } catch (e) {
      console.log(e)
      // throw e
    }
  }

  updateSummaryData = async (orderId: string, summary, orderType: string) => {
    const url = `${config.buyingBoardServiceRestEndpoint}${ORDER_SERVICE_ENDPOINTS.UPDATE_SUMMARY_ORDER_DELIVERY_DATE}/${orderId}?orderType=${orderType}`
    const accessToken: string = storage.getItem('access_token')
    const data = {
      orderSummary: summary
    }
    return this.httpService
      .patch(url, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(response => response.data)
  }

  addRemoveDoors = async (
    clientId: string,
    bsgId: string,
    orderId: string,
    doorsToBeAdded: any,
    doorOrdersToBeRemoved: any
  ) => {
    try {
      const url = `${config.buyingBoardServiceRestEndpoint}${ORDER_SERVICE_ENDPOINTS.ADD_REMOVE_DOORS}/buyingSessionGroup/${bsgId}/client/${clientId}/clientOrderId/${orderId}`
      const accessToken: string = storage.getItem('access_token')
      const response = await this.httpService.post(
        url,
        {
          ...(doorsToBeAdded ? { doorsToBeAdded } : {}),
          ...(doorOrdersToBeRemoved ? { doorOrdersToBeRemoved } : {})
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        }
      )
      return response.data?.data
    } catch (e) {
      console.log(e)
      throw e
    }
  }

  getOTBData = async (orderId, orderType) => {
    const url = `${config.buyingBoardServiceRestEndpoint}order/otb/orderId/${orderId}/orderType/${orderType}`
    const accessToken: string = storage.getItem('access_token')

    return this.httpService
      .get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(response => response.data)
  }

  updatePORef = async (orderId: string, poRef: string, orderType: string) => {
    const url = `${config.buyingBoardServiceRestEndpoint}${ORDER_SERVICE_ENDPOINTS.UPDATE_SUMMARY_ORDER_DELIVERY_DATE}/${orderId}?orderType=${orderType}`
    const accessToken: string = storage.getItem('access_token')
    const data = {
      orderSummary: {
        poRef
      }
    }
    return this.httpService
      .patch(url, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(response => response.data)
  }

  updateNotes = async (orderId: string, notes: string, orderType: string) => {
    try {
      const url = `${config.buyingBoardServiceRestEndpoint}${ORDER_SERVICE_ENDPOINTS.UPDATE_SUMMARY_ORDER_DELIVERY_DATE}/${orderId}?orderType=${orderType}`
      const accessToken: string = storage.getItem('access_token')
      const data = {
        orderSummary: {
          notes
        }
      }
      const response = await this.httpService.patch(url, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      return response.data
    } catch (e) {
      console.log(e)
      // throw e
    }
  }

  updateDeliveryDate = async (
    orderId: string,
    deliveryDate: number,
    orderType: string
  ) => {
    try {
      const url = `${config.buyingBoardServiceRestEndpoint}${ORDER_SERVICE_ENDPOINTS.UPDATE_SUMMARY_ORDER_DELIVERY_DATE}/${orderId}?orderType=${orderType}`
      const accessToken: string = storage.getItem('access_token')
      const data = {
        orderSummary: {
          deliveryDate
        }
      }
      const response = await this.httpService.patch(url, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      return response.data
    } catch (e) {
      console.log(e)
      // throw e
    }
  }

  applyToAllSummary = async (clientOrderId: string, attributeToCopy: any) => {
    try {
      const url = `${config.buyingBoardServiceRestEndpoint}${ORDER_SERVICE_ENDPOINTS.APPLY_TO_ALL_SUMMARY}/${clientOrderId}`
      const accessToken: string = storage.getItem('access_token')
      const response = await this.httpService.patch(url, attributeToCopy, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      return response.data
    } catch (e) {
      console.log(e)
    }
  }

  removeProductToOrder = async (
    orderId: string,
    buyingSessionProductId: string,
    orderType: OrderType
  ) => {
    try {
      const urlParam = orderType === OrderType.Client ? 'clientOrder' : 'doorOrder'
      const url = `${config.buyingBoardServiceRestEndpoint}${ORDER_SERVICE_ENDPOINTS.GET_ORDER_PRODUCT_DETAILS}/${urlParam}/${orderId}/buyingSessionProduct/${buyingSessionProductId}`
      const accessToken: string = storage.getItem('access_token')
      const response = await this.httpService.delete(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      const data = response.data?.data
      const { clientOrder, doorOrder } = data
      orderKPIGlobalTopBarStore.updateOrderKPIByOrderTypes({ clientOrder, doorOrder })
      return data
    } catch (e) {
      console.log(e)
      throw e
    }
  }
}
