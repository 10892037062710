import { strings } from '@stores'
import '@styles/ProductMedia/style.css'
import cn from 'classnames'
import React from 'react'

export interface IProductImage extends React.ImgHTMLAttributes<HTMLImageElement> {
  /**
   * custom props here
   */
  mediaVariant?: 'standalone' | 'card'
}

export const ProductImage: React.FC<IProductImage> = props => {
  const {
    alt,
    src,
    className,
    style,
    mediaVariant = 'standalone',
    children,
    ...rest
  } = props

  return (
    <>
      <img
        alt={strings.mediaAlt}
        src={src}
        className={cn(
          'product-media-img',
          { 'product-media-card-img': mediaVariant === 'card' },
          className
        )}
        style={style}
        {...rest}
      />
      {children}
    </>
  )
}
