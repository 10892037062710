/**
 * Returns combined ratings
 *
 * @param {array} ratings
 * @param {array} ratingTypes
 *
 * @returns {array}
 */
export const getRatings = (ratings = [], ratingTypes = []) => {
  const mergedRatings = []

  ratingTypes.forEach(({ id, name: typeName }) => {
    ratings.forEach(({ value, type: { name } }) => {
      if (typeName === name) {
        mergedRatings.push({ id, typeName, value })
      }
    })

    !isRatingExist(mergedRatings, id) && mergedRatings.push({ id, typeName, value: 0 })
  })

  return mergedRatings
}

// Check if rating exists
const isRatingExist = (ratings, ratingId) => ratings.some(({ id }) => ratingId === id)
