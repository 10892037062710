/*
 * @Author: Raeesaa Metkari
 * @Date: 2019-08-21 03:18:52
 * @Last Modified by:   Raeesaa Metkari
 * @Last Modified time: 2019-08-21 02:22:05
 */
import FilledInput from '@material-ui/core/FilledInput'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'
import { stores } from '@stores'
import { useObserver } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import * as css from './StoreOTBWeightSettingsHeader.css'

interface Store {
  id: string
  name: string
}

interface Zone {
  id: string
  name: string
  stores: Array<Store>
}

interface MyProps {
  zones: Array<Zone>
}

export const StoreOTBWeightSettingsHeader: React.FunctionComponent<MyProps> = ({
  zones
}) => {
  return useObserver(function useHook() {
    const {
      storeSettings: { selectedZone, onSelectZone, setSelectedZone }
    } = stores

    useEffect(() => {
      // In case selected zone is not set during initial mount, set it on component update
      if (zones && zones.length && !selectedZone) {
        setSelectedZone(zones[0].id)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [zones])

    return (
      <div className={css.root}>
        <InputLabel htmlFor='filled-age-simple'>
          <Typography variant='button'>Zones</Typography>
        </InputLabel>
        <FormControl variant='filled' className={css.formControl}>
          <Select
            value={selectedZone}
            onChange={event => onSelectZone(event)}
            input={
              <FilledInput
                classes={{ input: css.filledInput }}
                name='age'
                id='filled-age-simple'
              />
            }
            className={css.noPadding}
          >
            {zones.map(value => (
              <MenuItem key={value.id} value={value.id}>
                <Typography className={css.paddingLeft16} variant='caption'>
                  {value.name}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    )
  })
}
