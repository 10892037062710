/*
 * @Author: Raeesaa Metkari
 * @Date: 2020-04-13 22:56:48
 * @Last Modified by: Raeesaa Metkari
 * @Last Modified time: 2020-04-17 17:00:54
 */
import { CriteriaOperationType } from '@modules/models'
import { IDoorTableData } from '@services/wholesale/doorManagementService'
import { stores } from '@stores'
import cloneDeep from 'lodash/cloneDeep'
import filter from 'lodash/filter'
import findIndex from 'lodash/findIndex'
import forEach from 'lodash/forEach'
import { action, computed, observable } from 'mobx'
import * as qs from 'query-string'

export class DoorManagementStore {
  @observable doorData: Array<IDoorTableData>
  @observable hasAtLeastOneNonPreBuyingBS = false

  @computed get whereInputForDoors() {
    const {
      nav: {
        queryParams: { client }
      }
    } = stores
    return {
      __Parent__: {
        Id: {
          operation: CriteriaOperationType.EQ,
          value: client
        }
      }
    }
  }

  @computed get queryStringForClientLink() {
    const {
      nav: {
        location: { search }
      }
    } = stores
    let qsJSON: any = cloneDeep(qs.parse(search))
    delete qsJSON.client

    return qs.stringify(qsJSON)
  }

  @action setDoorData = (doorData: Array<IDoorTableData>) => {
    const existingDoorData = this.doorData
    let expandedRecords = filter(existingDoorData, door => door.isExpanded)

    let newData = cloneDeep(doorData)
    if (expandedRecords.length) {
      // Set expanded flag to true in new data
      forEach(expandedRecords, expandedDoorRecord => {
        let expandedDoorIndex = findIndex(
          newData,
          door => door.id === expandedDoorRecord.id
        )
        if (expandedDoorIndex > -1) {
          newData[expandedDoorIndex].isExpanded = true
        }
      })
    }

    this.doorData = newData
  }

  @action onClickExpandCollapseToggle = (door: IDoorTableData) => event => {
    let doorRecordIndex = findIndex(this.doorData, doorObj => doorObj.id === door.id)

    if (doorRecordIndex > -1 && this.doorData[doorRecordIndex]) {
      let data = cloneDeep(this.doorData)
      data[doorRecordIndex].isExpanded = !this.doorData[doorRecordIndex].isExpanded
      this.doorData = data
    }
  }

  @action clearDoorData = () => {
    this.doorData = []
  }

  @action setHasAtLeastOneNonPreBuyingBS = hasAtLeastOneNonPreBuyingBS =>
    Object.assign(this, { hasAtLeastOneNonPreBuyingBS })
}

export const doorManagementStore = new DoorManagementStore()
