/*
 * @Author: Raeesaa Metkari
 * @Date: 2019-12-11 14:52:08
 * @Last Modified by: Raeesaa Metkari
 * @Last Modified time: 2019-12-12 16:48:24
 */

export enum ListViewColumnLabels {
  ProductInfo = 'Department/ Class/ Size',
  ProductAttributes = 'Attributes',
  CMCluster = 'CM Cluster | Status',
  WsCMCluster = 'Cluster',
  BuyerCluster = 'RM Clusters',
  Tags = 'Tags',
  ExcludedZones = 'Excluded Zones',
  SalesPeriod = 'Sales Period',
  TotalWeeks = 'Total Weeks',
  TotalStores = 'Total Stores',
  TotalDoors = 'Total Doors',
  Zones = 'Zones',
  Price = 'Price',
  AvgWeeklySales = 'Avg WK Sales',
  MixQty = 'Mix Qty',
  MixValue = 'Mix Value',
  AvgDepth = 'Avg Depth',
  SkuEff = 'SKU Eff',
  Value = 'Value',
  SalesQty = 'Sales Qty',
  DraftQty = 'Draft Qty',
  DoorQty = 'Draft Qty',
  SizedQty = 'Sized Qty',
  OrderQty = 'Order Qty',
  OrderValue = 'Order Value',
  BetQty = 'BET Qty',
  CMComments = 'CM Comments',
  RMComments = 'RM Comments',
  RetailFreeTags = 'Retail Free Tags',
  WholesaleFreeTags = 'Wholesale Free Tags',
  Channels = 'Availability',
  Drops = 'Drops',
  ExcludedClients = 'Excluded Markets',
  ActualMixTargetMix = 'Actual Mix / Target Mix',
  Quantity = 'Actual Quantity',
  ActualValue = 'Actual Value',
  WholesaleCluster = 'Wholesale Clusters',
  RetailCluster = 'CM Clusters | Status',
  RMCluster = 'RM Clusters'
}
