import { StyledProps } from '@components/3rd-party/mui'
import { SAListViewProduct } from '@modules/common/models/interfaces/SAListViewProduct'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { SASelectBookUnbookAll } from '../SASelectBookUnbookAll'
import * as css from './SAQuantity.css'

interface IMenuData {
  title?: string
  options: Array<any>
  isDisabled: boolean
  isVisible: boolean
  onChange: any
  product: SAListViewProduct
}
interface ISAQuantity extends StyledProps {
  style?: any
  items?: any
  menuData?: IMenuData
  title?: string
  isSizeQtyInBold?: boolean
  isBookUnbookAllVisible?: boolean
  warningMessage?: boolean
}

export const SAQuantity: React.FunctionComponent<ISAQuantity> = ({
  style,
  items,
  title,
  isSizeQtyInBold,
  menuData
}) => {
  return (
    <React.Fragment>
      {title ? (
        <div className={css.singleProduct}>
          <Typography className={css.singleSizedProduct}>{title}</Typography>
        </div>
      ) : null}
      <div className={css.container}>
        {menuData?.isVisible ? (
          <SASelectBookUnbookAll
            title={menuData?.title}
            items={menuData?.options}
            isDisabled={menuData?.isDisabled}
            onChange={menuData?.onChange}
            product={menuData?.product}
          />
        ) : null}
        <div style={style}>
          {items?.map(({ name, value }, index) => (
            <div key={index}>
              <Typography className={css.label}>{name}</Typography>
              <Typography
                className={
                  index === items.length - 1 && isSizeQtyInBold
                    ? css.boldValue
                    : css.value
                }
              >
                {value}
              </Typography>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  )
}
