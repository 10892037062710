import { config } from '@config'
import {
  ACTIVITY_HIERARCHY_MAP,
  DEFAULT_ACTIVITY_HIERARCHY,
  EXTERNALIMAGEPREFIX
} from '@constants'
import { User } from '@models'
import {
  CentricEnumValue,
  CentricModifiedCNLs,
  CentricNodeType,
  DocumentQueryParams,
  ImageQueryParams,
  IOrderData,
  UserRoles
} from '@modules/common/models'
import { IZone } from '@modules/wholesale/wsNavigations/stores/WholesaleSettingsStore'
import { getHighestUser } from '@routes'
import { VIPOrderProduct } from '@services/wholesale/wholesaleCollectionService'
import { stores, strings } from '@stores'
import capitalize from 'lodash/capitalize'
import cloneDeep from 'lodash/cloneDeep'
import every from 'lodash/every'
import filter from 'lodash/filter'
import find from 'lodash/find'
import get from 'lodash/get'
import includes from 'lodash/includes'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import isPlainObject from 'lodash/isPlainObject'
import isString from 'lodash/isString'
import isNumber from 'lodash/isNumber'
import some from 'lodash/some'
import sortBy from 'lodash/sortBy'
import toNumber from 'lodash/toNumber'
import memoize from 'memoize-one'
import moment from 'moment'
import qs from 'query-string'
import { TabViewConf, ViewTabsMap } from 'src/constant/ViewTabs'

import { storage } from './storageService'
import intersection from 'lodash/intersection'
import { hasUserRoles } from './userRoleService'

const getUpdateZoneList = (zones: Array<IZone>) => {
  let zoneDataList = cloneDeep(zones)
  zoneDataList.unshift({
    Id: strings.worldWide,
    Name: strings.worldWide
  })
  return zoneDataList
}

const getDefaultFilterForBsProducts = clusterFilter => {
  const filterParam = {
    id_in: clusterFilter
  }
  const clusterAssortmentProducts_some = {
    clusterAssortmentProducts_some: {
      assortment: {
        cluster: filterParam
      }
    }
  }
  const clusterAssortmentProducts_every = {
    clusterAssortmentProducts_every: {
      assortment: {
        cluster: {
          id: null
        }
      }
    }
  }

  if (clusterFilter.includes('None')) {
    if (clusterFilter.length === 1) {
      return clusterAssortmentProducts_every
    } else {
      return {
        OR: [clusterAssortmentProducts_some, clusterAssortmentProducts_every]
      }
    }
  } else {
    return clusterAssortmentProducts_some
  }
}

const getZoneAssortmentQueryObjectFilterByCluster = clusterFilter => {
  const {
    nav: { selectedZoneAssortmentId }
  } = stores

  const filterParam = {
    id_in: clusterFilter
  }
  const zoneAssortment = {
    id: selectedZoneAssortmentId
  }
  const buyerClusters_every = {
    id: null
  }
  if (clusterFilter.includes('None')) {
    if (clusterFilter.length === 1) {
      return {
        zoneAssortmentProducts_some: {
          zoneAssortment,
          buyerClusters_every
        }
      }
    } else {
      return {
        zoneAssortmentProducts_some: {
          zoneAssortment,
          OR: [
            {
              buyerClusters_every
            },
            {
              buyerClusters_some: filterParam
            }
          ]
        }
      }
    }
  } else {
    return {
      zoneAssortmentProducts_some: {
        zoneAssortment,
        buyerClusters_some: filterParam
      }
    }
  }
}

const getStoreAssortmentQueryObjectFilterByCluster = clusterFilter => {
  const {
    nav: { selectedZoneAssortmentId },
    assortment: { rmZoneAssortmentDetails }
  } = stores

  const {
    zone: { id: zoneId }
  } = rmZoneAssortmentDetails.find(item => item.id === selectedZoneAssortmentId)

  const filterParam = {
    id_in: clusterFilter
  }
  const zoneAssortment = {
    zone: {
      id: zoneId
    }
  }

  const buyerClusters_every = {
    id: null
  }

  if (clusterFilter.includes('None')) {
    if (clusterFilter.length === 1) {
      return {
        zoneAssortmentProducts_some: {
          zoneAssortment,
          buyerClusters_every
        }
      }
    } else {
      return {
        zoneAssortmentProducts_some: {
          zoneAssortment,
          OR: [
            {
              buyerClusters_every
            },
            {
              buyerClusters_some: filterParam
            }
          ]
        }
      }
    }
  } else {
    return {
      zoneAssortmentProducts_some: {
        zoneAssortment,
        buyerClusters_some: filterParam
      }
    }
  }
}

/**
 * Method for building filter object for buy quantity. This will be passed as an argument at BSP level
 * @param buyQtyFilter - Applied buy qty filter
 * @returns Filter object to be passed as an argument to query (BSP level)
 */
const getFilterByBuyQtyQueryObject = buyQtyFilter => {
  /**
   * NOTE: Added check for buy qty length here. In case it is 2, we need don't need to apply this filter as all
   * products will be returned in this case (since checking both values would mean products with and without qty)
   */
  if (buyQtyFilter && buyQtyFilter.length && buyQtyFilter.length !== 2) {
    const {
      nav: {
        params: { assortment, assortmentType }
      }
    } = stores

    const buyFilter =
      buyQtyFilter[0] === '1'
        ? {
            buy: {
              quantity_not: 0
            }
          }
        : {
            OR: [
              {
                buy: {
                  quantity: 0
                }
              },
              {
                buy: null
              }
            ]
          }

    switch (assortmentType) {
      case strings.zone:
        return assortment
          ? {
              zoneAssortmentProducts_some: {
                zoneAssortment: {
                  id: assortment
                },
                ...buyFilter
              }
            }
          : {}

      case strings.store:
        return assortment
          ? {
              storeAssortmentProducts_some: {
                storeAssortment: {
                  id: assortment
                },
                ...buyFilter
              }
            }
          : {}

      default:
        return buyFilter
    }
  }
  return {}
}

const getFilterByClusterQueryObject = clusterFilter => {
  const {
    sidebar: { isRegionalMerchandiser },
    nav: {
      params: { assortmentType }
    },
    assortment: { drawerOpen }
  } = stores
  if (isRegionalMerchandiser() && !drawerOpen) {
    switch (assortmentType) {
      case strings.zone: {
        return getZoneAssortmentQueryObjectFilterByCluster(clusterFilter)
      }
      case strings.store: {
        return getStoreAssortmentQueryObjectFilterByCluster(clusterFilter)
      }
      default: {
        return getDefaultFilterForBsProducts(clusterFilter)
      }
    }
  } else {
    return getDefaultFilterForBsProducts(clusterFilter)
  }
}

const getSearchByClusterQueryObject = searchKey => {
  const {
    sidebar: { isRegionalMerchandiser },
    nav: {
      params: { assortmentType },
      selectedZoneAssortmentId
    },
    assortment: { drawerOpen, rmZoneAssortmentDetails }
  } = stores

  const searchParam = {
    name_contains: searchKey
  }
  const clusterAssortmentProducts_some = {
    clusterAssortmentProducts_some: {
      assortment: {
        cluster: searchParam
      }
    }
  }

  if (isRegionalMerchandiser() && !drawerOpen) {
    switch (assortmentType) {
      case strings.zone: {
        return {
          zoneAssortmentProducts_some: {
            zoneAssortment: {
              id: selectedZoneAssortmentId
            },
            buyerClusters_some: searchParam
          }
        }
      }
      case strings.store: {
        const zone = rmZoneAssortmentDetails.find(
          item => item.id === selectedZoneAssortmentId
        )
        if (zone && zone.id) {
          const { id: zoneId } = zone
          return {
            zoneAssortmentProducts_some: {
              zoneAssortment: {
                zone: {
                  id: zoneId
                }
              },
              buyerClusters_some: searchParam
            }
          }
        } else {
          return {
            zoneAssortmentProducts_some: {
              zoneAssortment: {
                zone: {
                  id: null
                }
              },
              buyerClusters_some: searchParam
            }
          }
        }
      }
      default: {
        return clusterAssortmentProducts_some
      }
    }
  } else {
    return clusterAssortmentProducts_some
  }
}

const buildSearchQuery = (searchKey, attributes) => {
  const searchParam = {
    name_contains: searchKey
  }

  let finalSearchQuery = searchKey
    ? {
        OR: [
          {
            product: {
              OR: [
                {
                  attributes_some: { strVal_contains: searchKey }
                },
                {
                  attributes_some: { tag: searchParam }
                }
              ]
            }
          },
          {
            salesPeriod: searchParam
          },
          {
            channels_some: searchParam
          },
          {
            productClusterStatus_some: {
              status: searchParam
            }
          },
          { ...getSearchByClusterQueryObject(searchKey) }
        ]
      }
    : null
  return finalSearchQuery
}

const validateNumber = event => {
  var theEvent = event || window.event

  // Handle paste
  if (theEvent.type === 'paste') {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    key = event.clipboardData.getData('text/plain')
  } else {
    // Handle key press
    var key = theEvent.keyCode || theEvent.which
    key = String.fromCharCode(key)
  }
  var regex = /[0-9]|\./
  if (!regex.test(key)) {
    theEvent.returnValue = false
    if (theEvent.preventDefault) theEvent.preventDefault()
  }
}

const validateDecimalNumber = value => {
  // const regex = /^\d+(\.\d{1,3})?$/;
  const regex = /^[0-9]+([.][0-9]+)?$/
  if (!regex.test(value)) {
    return false
  }
  return true
}

const validateInteger = value => {
  return /^[0-9]*$/.test(value)
}

const copyToClipBoard = selectedElementClass => {
  if (window && window.getSelection) {
    const elements = document.getElementsByClassName(selectedElementClass)
    if (elements && elements.length) {
      const range = document.createRange()
      range.selectNode(elements[0])
      window.getSelection().removeAllRanges()
      window.getSelection().addRange(range)
      document.execCommand('copy')
      window.getSelection().removeAllRanges()
    }
  }
}

const validateAlphabetOnly = value => {
  const regex = /^[a-zA-Z]*$/
  if (!regex.test(value)) {
    return false
  }
  return true
}

/**
 * Validate is alpha numeric string or not
 * @param string
 * @returns boolean
 */
const validateAlphaNumeric = (value: string) => {
  const regex = /^[a-zA-Z0-9_ \x7f-\xff ]+$/
  return regex.test(value)
}

/**
 * Helper function for returning empty array of specified length
 * @param { number } length Length of array to be returned
 * @returns { Array } Empty array of specified length
 */
const getEmptyArrayOfLength = memoize((length: number) => {
  return new Array(length || 0)
})

const ApplyToFixedIfNumber = (data: any) => {
  if (isNumber(data)) {
    return data.toFixed(2)
  } else {
    return data
  }
}

const ApplyToFixedIfDecimal = (data: any) => {
  if (isNumber(data)) {
    const value = toNumber(data)
    return data.toFixed(value % 1 && 2)
  } else {
    return data
  }
}

/**
 * Returns map of tab name and its corresponding
 * index for given view (i.e List, KPI)
 * @param view - string indicating view name
 */
const getViewTabIndexMap = (view: string, updatedViewMap = null) => {
  const viewTabsMap = updatedViewMap ? updatedViewMap : ViewTabsMap[view]
  return viewTabsMap
    ? viewTabsMap.reduce((acc, tab, index) => {
        acc[tab.name] = index
        return acc
      }, {})
    : {}
}

/**
 * Returns required tab list according to passed
 * tab config
 * @param view
 * @param tabSettings
 */
const getViewTabsToRender = (
  view: string,
  viewType: string,
  channels,
  subView?: string
): Array<TabViewConf> => {
  const tabs: Array<TabViewConf> = ViewTabsMap[view] ? ViewTabsMap[view] : null
  return tabs
    ? filter(tabs, tab => {
        const channelCheck = intersection(channels, tab.allowedForChannels).length
        const excludedSubiewCheck = subView
          ? !tab.excludedSubviews?.includes(subView)
          : true
        const userRoleCheck = tab.allowedForUsers?.length
          ? hasUserRoles(tab.allowedForUsers)
          : true
        return (
          channelCheck &&
          excludedSubiewCheck &&
          userRoleCheck &&
          tab.view.includes(viewType)
        )
      })
    : []
}

/**
 * Method for converting number to percentage string
 * @param { number } value - Value to be converted to percentage string
 * @returns Converted percentage string
 */
const convertNumberToPercentage = value => {
  try {
    return value.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 })
  } catch {
    return value && !isNaN(value) ? parseFloat(value.toFixed(2)) : ''
  }
}

/**
 * Returns children meta data from activity
 */
const getChildrenMetaData = (activity, family, line) => {
  const {
    nav: {
      params: { activity: activityDescription }
    }
  } = stores
  //Activity hirerarchy should consider only at collection level
  let childrenMeta = activityDescription ? [] : ['activity']
  let childrenMetaValues = activityDescription ? [] : [activity ? activity.value : '']
  if (activity.value === 'SHOES') {
    childrenMeta.push('line')
    childrenMetaValues.push(line ? line.value : '')
  } else if (activity.value === 'LEATHER GOODS') {
    childrenMeta.push('family', 'line')
    childrenMetaValues.push(family ? family.value : '', line ? line.value : '')
  } else {
    childrenMeta.push('family')
    childrenMetaValues.push(family ? family.value : '')
  }
  return { childrenMeta, childrenMetaValues }
}

const getProductsByCategories = memoize((bsProduct: VIPOrderProduct) => {
  // Extract hierarchy for building where object for assortment product query
  let activity = bsProduct.Original?.Activity?.Name ?? 'Undefined'
  let childrenMeta = ACTIVITY_HIERARCHY_MAP[activity] || DEFAULT_ACTIVITY_HIERARCHY
  let childrenMetaValues = []

  childrenMeta.forEach(currentCategory => {
    childrenMetaValues.push(bsProduct.Original?.[`${capitalize(currentCategory)}`]?.Name)
  })

  return {
    activity,
    childrenMeta,
    childrenMetaValues
  }
})

// Filter products by activity / family / line
const getFilterProductsByHierarchy = memoize(
  (betBuyValues, metadata) =>
    betBuyValues &&
    betBuyValues.filter(({ product: { attributes } }) => {
      let attributeList = []
      metadata.childrenMeta.forEach(item => {
        let attribute = attributes.find(({ definition: { name } }) => name === item)
        attribute && attributeList.push(attribute.strVal)
      })
      return isEqual(attributeList, metadata.childrenMetaValues)
    })
)

/**
 * sortListUsingOrderList
 * @param listToSort - Data to sort
 * @param pathToSortKey - Sorting key
 * @param orderedList - Reference list for ideal order
 */
const sortListUsingOrderList = (
  listToSort: Array<any>,
  pathToSortKey: Array<string>,
  orderedList: Array<any>
) => {
  if (isEmpty(listToSort) || isEmpty(orderedList || !pathToSortKey.length)) {
    return listToSort
  }
  return sortBy(listToSort, item => {
    const valueToSort = get(item, pathToSortKey)
    const sortedIndex = orderedList.indexOf(valueToSort)
    return sortedIndex === -1 ? orderedList.length : sortedIndex
  })
}

/**
 * Returns pre-defined zone order
 */
const getZoneOrder = () => {
  return (config.zoneOrder || '').split(',')
}

/**
 * Generic function to decide whether
 * given order should be editable or
 * not.
 */
const isOrderAvailableForUpdates = memoize(
  (orderData: IOrderData) => {
    const orderStatusEditableValues =
      config?.appConfig?.enumerations?.orderStatus?.ALLOWED_EDIT_FOR_VALUES
    if (orderData?.isClientOrderDraft) {
      return true
    } else if (orderData?.isClientMaster) {
      return includes(orderStatusEditableValues, orderData?.clientOrderStatus)
    } else if (orderData?.doorOrders?.length) {
      return every(orderData?.doorOrders, order => {
        return includes(orderStatusEditableValues, order?.status)
      })
    } else {
      return false
    }
  },
  (oldParams, newParams) => isEqual(oldParams[0], newParams[0])
)

/**
 * Function to check unique value
 */
const isDistinct = (event, data) => {
  let name = find(
    data,
    record => record?.Name && record.Name === event.target.value.trim()
  )
  return !name
}

/**
 * Method for resolving enum for given enum object
 * It should return Description if present or fallback to
 * Name.
 * @param value
 * @param enumValues
 */
const resolveEnum = (enumObject: CentricEnumValue) => {
  return enumObject?.Description ? enumObject.Description : enumObject?.Name ?? ''
}

/**
 * Method for fetching description for given enum value
 * @param {string} value - Enum value string with format <EnumName>:<EnumValueName>
 * @param {Array<CentricEnumValue>} enumValues - List of enum value objects
 * @returns { string } Description for enum. In case record is not found in enumValues array, value passed is returned
 */
const getEnumDescriptionFromValue = (
  value: string,
  enumValues: Array<CentricEnumValue>
) => {
  let enumObject = find(enumValues, enumValue => enumValue.Value === value)
  return resolveEnum(enumObject)
}

/**
 * Method for fetching name for given enum value
 * @param {string} value - Enum value string with format <EnumName>:<EnumValueName>
 * @param {Array<CentricEnumValue>} enumValues - List of enum value objects
 * @returns { string } Name for enum. In case record is not found in enumValues array, value passed is returned
 */
const getEnumNameFromValue = (value: string, enumValues: Array<CentricEnumValue>) => {
  let enumObject = find(enumValues, enumValue => enumValue.Value === value)
  return enumObject?.Name ?? value?.split(':')?.[1]
}

/**
 * Method for returning enum map where key is value (i.e. "<EnumListName?>:<Value>") and value is an objecr with keys as label and value.
 * "label" will have description and "value" will have enum value
 * @param enumValues
 */
const getEnumOptionMap = memoize(enumValues => {
  let map = enumValues.reduce((acc, curr) => {
    if (curr.Value) {
      acc[curr.Value] = {
        label: curr?.Description ? curr.Description : curr?.Name ?? '',
        value: curr.Value
      }
    }
    return acc
  }, {})
  return map
})

/**
 * getMediaCentricURI
 * Returns URI for centric media elements like image, docs
 * @params options - object to build query params
 */
const getMediaCentricURI = memoize((options: ImageQueryParams | DocumentQueryParams) => {
  if (isEmpty(options)) return undefined
  const finalOptions = {
    ...options,
    SessionURL: storage.getItem('session_url')
  }

  const centricPostEndpoint = config.centricRestProxyEndpoint
  const queryParams = qs.stringify(finalOptions)
  return centricPostEndpoint + '?' + queryParams
}, isEqual)

/**
 * Function to admin user role
 */
const isAdmin = () => {
  const user = storage.getItem<User>('user')
  const isAdmin = user && some(user.roles, role => role.name === UserRoles.ADMINISTRATOR)
  return isAdmin
}

type ObjectWithDynamicKey = {
  [key: string]: any
}

/**
 * Method for returning search result for an array of flat object based on search key passed
 * @param {Array<ObjectWithDynamicKey>} searchData - Array of objects to be filtered on the basis of search key
 * @param {string} searchKey - Search key
 * @returns {Array<ObjectWithDynamicKey>}
 */
const searchData = (
  data: Array<ObjectWithDynamicKey>,
  searchKey: string,
  whitelistProps?: Array<string>,
  channelEnums?: Array<CentricEnumValue>
) => {
  return filter(data, client => {
    try {
      // eslint-disable-next-line array-callback-return
      return Object.keys(client).some(key => {
        if (
          client.hasOwnProperty(key) &&
          client[key] &&
          (isEmpty(whitelistProps) ? true : whitelistProps.indexOf(key) !== -1)
        ) {
          if (isString(client[key])) {
            return client[key].toLowerCase().includes(searchKey.toLowerCase())
          } else if (isArray(client[key])) {
            /**
             * NOTE: Search will now work for array of strings and array of objects. In case of array of object,
             * search will only be perfomed on "label" key if it exist and is of type string.
             */
            return client[key].some(element => {
              if (isString(element)) {
                return element.toLowerCase().includes(searchKey.toLowerCase())
              } else if (isPlainObject(element)) {
                return isString(element?.label)
                  ? element.label.toLowerCase().includes(searchKey.toLowerCase())
                  : false
              }
              return false
            })
          }

          return false
        }
      })
    } catch (err) {
      console.error('Error in searching data')
      console.error(err)
    }
  })
}
/**
 * Returns required CNL from the success response list
 * of c8 graphql service
 * @param type: CentricNodeType
 * @param CNLList: List of response CNLs
 */
const getModifiedCNL = (type: CentricNodeType, CNLList: Array<CentricModifiedCNLs>) => {
  return CNLList.find(item => {
    return item.Type === type
  })
}

const isZAM = () => {
  const highestUser = getHighestUser()
  const highestUserRoleName = highestUser?.[0]?.name ?? ''
  return highestUserRoleName === UserRoles.ZONE_AREA_MANAGER
}

const isWholesaleCM = () => {
  const highestUser = getHighestUser()
  const highestUserRoleName = highestUser?.[0]?.name ?? ''
  return highestUserRoleName === UserRoles.CENTRAL_MERCHANDISER_WHOLESALE
}

const isRetailCM = () => {
  const highestUser = getHighestUser()
  const highestUserRoleName = highestUser?.[0]?.name ?? ''
  return highestUserRoleName === UserRoles.CENTRAL_MERCHANDISER_RETAIL
}

const hasWholeSaleChannel = (channels = []) =>
  channels.some(
    channel => channel?.id === config.appConfig?.enumerations?.channel?.VALUES?.WHOLESALE
  )

const hasRetailChannel = (channels = []) =>
  channels.some(
    channel => channel?.id === config.appConfig?.enumerations?.channel?.VALUES?.RETAIL
  )

/**
 * Method that return if user is external client
 */
const isExternalClient = () => {
  const highestUser = getHighestUser()
  const highestUserRoleName = highestUser?.[0]?.name ?? ''
  return highestUserRoleName === UserRoles.EXTERNAL_CLIENT
}

const isRegionalMerchandiserRole = () => {
  const highestUser = getHighestUser()
  const highestUserRoleName = highestUser?.[0]?.name ?? ''
  return highestUserRoleName === UserRoles.REGIONAL_MERCHANDISER
}

const isSAUser = () => {
  const highestUser = getHighestUser()
  const highestUserRoleName = highestUser?.[0]?.name ?? ''
  return highestUserRoleName === UserRoles.WHOLESALE_SHOWROOM_ASSISTANT
}

const isWholesaleCMOrAdmin = () => {
  const user = storage.getItem<User>('user')
  return user.roles.find(
    role =>
      role.name === UserRoles.ADMINISTRATOR ||
      role.name === UserRoles.CENTRAL_MERCHANDISER_WHOLESALE
  )
}

const isCMAdmin = () => {
  const user = storage.getItem<User>('user')
  const isCMAdmin = user && some(user.roles, role => role.name === UserRoles.CM_ADMIN)
  return isCMAdmin
}

const hasWindowsOS = () => {
  return navigator.userAgent.indexOf('Win') > 0
}

/**
 * Method that accepts epoch time and sets time to 00:00 hours. If date argument is
 * undefined, it fallsback to current date
 * @param {number} date - Epoch time
 */
const getDateWithoutTime = (date: number) => {
  return date
    ? new Date(date).setUTCHours(0, 0, 0, 0)
    : new Date().setUTCHours(0, 0, 0, 0)
}

/**
 * Returns UTC representation of local date.
 * This is convenient when we want to display
 * absolute date (without time) across all timezones
 * @param date
 */
const getUTCRepresentation = (date: Date) => {
  return Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)
}

/**
 * Returns Epoch representation of local date.
 * This is convenient when we want to display
 * absolute date (without time and ms) across all timezones
 * @param date
 */
const getEpochTimeStamp = (date: Date) => {
  return getUTCRepresentation(date) / 1000
}

/**
 * Returns UTC date from time stamp
 * @param date
 * @param format
 */
const getUTCDateFromTimeStamp = (date: number): Date => {
  const localDate = new Date(date)
  return new Date(
    localDate.getUTCFullYear(),
    localDate.getUTCMonth(),
    localDate.getUTCDate()
  )
}

/**
 * Returns UTC date from time stamp
 * @param date
 * @param format
 */
const getUTCFromTimeStamp = (date: number, format: string) => {
  const utcDate = getUTCDateFromTimeStamp(date)
  return format ? moment(utcDate).format(format) : undefined
}

const handleSpecialCharsForXML = xmlString =>
  xmlString
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&apos;')

const mouseEventPropagationStopper = event => event.stopPropagation()

/**
 * Helper method for converting array into two dimensional array of specified length
 * @param {Array<any>} items - Array of items
 * @param {number} columns - Column size
 */
const covertArrayTo2DMatrix = (items: Array<any>, columns: number) => {
  let data = []
  if (items?.length && columns) {
    const rows = columns ? Math.ceil((items?.length ?? 0) / columns) : 0
    for (let i = 0; i < rows; i++) {
      let row = []
      for (let j = 0; j < columns; j++) {
        const element = items[i + j + i * (columns - 1)]
        if (element) {
          row.push(element)
        }
      }

      data.push(row)
    }
  }
  return data
}

/**
 * It returns image URL at given key
 * key should follow Img_{index} format in general.
 * It fallbacks to 0th image if key is not passed
 * @param images
 * @param key
 */
const getInterfaceImageURLFromKey = (
  images: Array<{ Name: string; Value: string }>,
  key?: string
): string => {
  const imageKey = key ? key : `${EXTERNALIMAGEPREFIX}_${0}`
  const image = images.find(image => image.Name === imageKey)
  return image ? image.Value : ''
}

export {
  buildSearchQuery,
  validateNumber,
  validateDecimalNumber,
  validateInteger,
  validateAlphabetOnly,
  copyToClipBoard,
  getEmptyArrayOfLength,
  getFilterByClusterQueryObject,
  getInterfaceImageURLFromKey,
  getFilterByBuyQtyQueryObject,
  ApplyToFixedIfNumber,
  ApplyToFixedIfDecimal,
  getViewTabIndexMap,
  getViewTabsToRender,
  convertNumberToPercentage,
  getChildrenMetaData,
  getProductsByCategories,
  getFilterProductsByHierarchy,
  sortListUsingOrderList,
  getZoneOrder,
  isDistinct,
  resolveEnum,
  isOrderAvailableForUpdates,
  getEnumDescriptionFromValue,
  getEnumNameFromValue,
  getEnumOptionMap,
  getMediaCentricURI,
  getModifiedCNL,
  isAdmin,
  searchData,
  getUpdateZoneList,
  handleSpecialCharsForXML,
  mouseEventPropagationStopper,
  isZAM,
  isWholesaleCMOrAdmin,
  getDateWithoutTime,
  covertArrayTo2DMatrix,
  getUTCRepresentation,
  getUTCDateFromTimeStamp,
  getUTCFromTimeStamp,
  isExternalClient,
  hasWholeSaleChannel,
  validateAlphaNumeric,
  getEpochTimeStamp,
  isSAUser,
  isRegionalMerchandiserRole,
  isWholesaleCM,
  isRetailCM,
  isCMAdmin,
  hasRetailChannel,
  hasWindowsOS
}
