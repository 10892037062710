import React, { forwardRef } from 'react'
import Menu, { MenuProps } from '@material-ui/core/Menu'
import * as css from './ShowroomMenu.css'

interface ShowroomMenuProps extends MenuProps {
  papermenucss?: any
}

export const ShowroomMenu: React.FunctionComponent<ShowroomMenuProps> = forwardRef(
  (props, ref) => (
    <Menu
      classes={{ paper: props.papermenucss || css.paperMenu }}
      ref={ref}
      MenuListProps={{ classes: { root: css.list } }}
      elevation={0}
      // getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      {...props}
    />
  )
)
