import React from 'react'
import styled from 'styled-components'
import Tooltip from '@material-ui/core/Tooltip'
import { stores, strings } from '@stores'
import { AssortmentTabs } from '@components/UI-Components/Assortment'

const Root = styled.div`
display: flex;
margin: 0px 16px 0px 16px;
height: 48px;
justify-content: space-between;
align-items: center;
border-bottom: thin solid #8592a6;
}
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 auto;

  [class*='MuiIcon-root-'] {
    width: auto;
    height: auto;
  }
`

const Title = styled.div`
  width: 500px;
  height: 20px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: #000000;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export const AssortmentTitleBar: React.FunctionComponent<{
  title?: string
  actions?: React.ReactNode
  hideAssortmentTabs?: boolean
}> = ({ title, actions, hideAssortmentTabs }) => {
  const {
    nav: {
      params: { view, assortmentType, clusterId }
    }
  } = stores

  return (
    <Root>
      {!hideAssortmentTabs &&
      (view === strings.list || view === strings.kpi) &&
      assortmentType !== strings.cluster &&
      !clusterId ? (
        <AssortmentTabs />
      ) : (
        <Tooltip title={title}>
          <Title>{title}</Title>
        </Tooltip>
      )}
      <Actions>{actions}</Actions>
    </Root>
  )
}
