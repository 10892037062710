import { GET_CAMPAIGN_BY_ID } from '@modules/presentation/graphql'
import {
  deleteDocument,
  deleteImages,
  MultipleUploadImageContainerType
} from '@services/uploadFileService'
import { apolloClient, stores } from '@stores'
import map from 'lodash/map'
import { action, computed, observable } from 'mobx'
import { VIPChannel } from '@modules/common/models/enums/VIPChannel'
import {
  isRetailChannel,
  isWholesaleChannel
} from '@services/wholesale/wholesaleBuyingSessionGroupsServices'

// interface ICampaign {
//   Id: string
//   Name: string
//   Clients: any[]
//   ModifiedAt: string
// }

export enum CAMPAIGN_OPERATIONS {
  GET_CAMPAING_LIST,
  ADD_CAMPAIGN,
  EDIT_CAMPAIGN,
  DELETE_CAMPAIGN
}

const mapToServerClientModel = (clients: any[]) =>
  clients?.length ? map(clients, 'id') : clients

const ChannelTabs = [
  {
    id: VIPChannel.Retail,
    name: 'Retail'
  },
  {
    id: VIPChannel.Wholesale,
    name: 'Wholesale'
  }
]

export class CampaignStore {
  @observable loading = false
  @observable isDeleting = false
  @observable isEditing = false
  @observable selectedIdToDelete = null
  @observable selectedCampaignToEdit = null
  @observable selectedChannel = ''
  @observable canUserEdit = false

  actions = {}

  queries: {
    campaignListQuery: Function
  } = { campaignListQuery: null }

  @action
  registerActions = (key: CAMPAIGN_OPERATIONS, mutation: any) => {
    this.actions[key] = mutation
  }

  @computed
  get campaignListParams() {
    const {
      nav: {
        queryParams: { buyingSessionGroupId }
      }
    } = stores
    return {
      buyingSessionGroupId
    }
  }

  @computed
  get isEditDialogOpen() {
    const {
      nav: {
        queryParams: { modal }
      }
    } = stores
    return modal === 'newPresentation' || !!this.selectedCampaignToEdit
  }

  @action
  resetToDefault = () => {
    this.selectedCampaignToEdit = []
    this.actions = {}
  }

  @action
  clearEditDetails = () => {
    this.selectedCampaignToEdit = null
  }

  @action
  setEdit = campaign => {
    this.selectedCampaignToEdit = campaign
  }

  @action
  clearDeleteId = () => {
    this.selectedIdToDelete = null
  }

  @action
  setDeleteId = id => {
    this.selectedIdToDelete = id
  }

  private deleteCampaignImagesAndDocument(campaignId: string) {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: GET_CAMPAIGN_BY_ID,
          variables: {
            campaignId,
            includeImages: true
          },
          fetchPolicy: 'network-only'
        })
        .then(({ data }) => {
          const imageIds = map(data?.getSpecificationItemDefinition?.Images, 'Value.Id')
          const docId = data?.getSpecificationItemDefinition?.Document?.Id
          const deleteDocPromise = docId ? deleteDocument(docId) : Promise.resolve({})
          const deleteImagesPromise = imageIds?.length
            ? deleteImages(
                campaignId,
                MultipleUploadImageContainerType.CAMPAIGN,
                imageIds
              )
            : Promise.resolve({})
          Promise.all([deleteDocPromise, deleteImagesPromise]).then(resolve).catch(reject)
        })
    })
  }

  @action
  deleteCampaign = () => {
    this.isDeleting = true
    return new Promise((resolve, reject) => {
      Promise.all([
        this.deleteCampaignImagesAndDocument(this.selectedIdToDelete),
        this.actions[CAMPAIGN_OPERATIONS.DELETE_CAMPAIGN]({
          variables: { campaignId: this.selectedIdToDelete },
          refetchQueries: this.queries.campaignListQuery
        })
      ])
        .then(resolve)
        .catch(reject)
        .finally(() => {
          this.isDeleting = false
        })
    })
  }

  @action
  updateCampaign = dataToEdit => {
    this.isEditing = true
    return new Promise((resolve, reject) => {
      this.actions[CAMPAIGN_OPERATIONS.EDIT_CAMPAIGN]({
        variables: {
          campaignId: this.selectedCampaignToEdit.id,
          campaignName: dataToEdit.name,
          clients: mapToServerClientModel(dataToEdit.clients),
          linkageUrl: dataToEdit.linkageUrl
        },
        refetchQueries: this.queries.campaignListQuery
      })
        .then(resolve)
        .catch(reject)
        .finally(() => {
          this.isEditing = false
        })
    })
  }

  @action
  createCampaign = dataToCreate => {
    this.isEditing = true
    const clients = mapToServerClientModel(dataToCreate.clients)
    return new Promise((resolve, reject) => {
      this.actions[CAMPAIGN_OPERATIONS.ADD_CAMPAIGN]({
        variables: {
          buyingSessionGroupId: this.campaignListParams.buyingSessionGroupId,
          campaignName: dataToCreate.name,
          clients: clients.length ? clients : null,
          channel: this.selectedChannel,
          linkageUrl: dataToCreate.linkageUrl
        },
        refetchQueries: this.queries.campaignListQuery
      })
        .then(resolve)
        .catch(reject)
        .finally(() => {
          this.isEditing = false
        })
    })
  }

  @action
  setSelectedChannel = channel => {
    const {
      nav: { updateQueryParams }
    } = stores
    this.selectedChannel = channel
    updateQueryParams({ channel: channel })
  }

  @action
  setCanEdit = val => {
    this.canUserEdit = val
  }

  @computed
  get tabs() {
    const {
      wsBuyingSessionProduct: { bsgData }
    } = stores
    const channels = bsgData?.channels || []
    return ChannelTabs.filter(tab => {
      if (tab.id === VIPChannel.Retail) {
        return isRetailChannel(channels) && tab
      } else if (tab.id === VIPChannel.Wholesale) {
        return isWholesaleChannel(channels) && tab
      } else {
        return tab
      }
    })
  }

  @action unmount = () => {
    this.selectedChannel = ''
  }
}

export const campaignStore = new CampaignStore()
