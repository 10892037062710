import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import React from 'react'

const ClusterSetupTableHeadersComponent = ({ titles }) => {
  return titles.map((title, index) => (
    <TableCell className='flex-center' key={`clusterTableHeaders-${index}`}>
      {title && (
        <Typography
          variant='caption'
          style={{
            textTransform: 'uppercase',
            color: 'rgba(0, 0, 0, 0.54)',
            fontWeight: 'bold'
          }}
        >
          {title.name}
        </Typography>
      )}
    </TableCell>
  ))
}

export const ClusterSetupTableHeader = React.memo(ClusterSetupTableHeadersComponent)
