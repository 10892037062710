import { MutationFunctionOptions } from '@apollo/client/react/types/types'
import {
  BuyingGroupSettingsDisplayName,
  BuyingGroupSettingSectionName,
  BuyingGroupSettingViews,
  BuyingSessionAssortmentSection,
  OrderManagementSection,
  UserRoles,
  WholesaleSettingsSection,
  WholesaleSettingsSectionDisplayName,
  WholesaleSettingsView,
  WholesaleSettingsViewDisplayName
} from '@modules/common/models/enums'
import { VIPChannel } from '@modules/common/models/enums/VIPChannel'
import { ClientQueryLink } from '@modules/common/models/interfaces/ClientQueryLink'
import { IZone } from '@modules/wholesale/wsNavigations/stores/WholesaleSettingsStore'
import { isZAM } from '@services/commonServices'
import { hasUserRoles } from '@services/userRoleService'
import { IClientTableData } from '@services/wholesale/clientManagementService'
import { stores, strings } from '@stores'
import { ExecutionResult } from 'graphql/execution'
import cloneDeep from 'lodash/cloneDeep'
import filter from 'lodash/filter'
import find from 'lodash/find'
import intersection from 'lodash/intersection'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import omit from 'lodash/omit'
import { action, observable } from 'mobx'
import { computedFn } from 'mobx-utils'
import * as qs from 'query-string'
import { GET_BUYING_GROUP_WITH_CLIENTS } from '../graphql'
import { config } from '@config'

export class BuyingGroupSettingStore {
  @observable selectedSettingMenu = { section: '' }
  @observable isMenuOpen = true
  @observable openConfirmationDialog = false
  @observable rowData = null
  @observable clientUpdateInProgress = {}
  @observable bulkClientUpdateInProgress = false
  @observable bulkUpdateData = null
  @observable excludeClient = false

  @observable mutations: {
    updateAvailableFlagForClient?: (
      options?: MutationFunctionOptions<
        {},
        { buyingSessionGroup: string; client: string; isExcluded: boolean }
      >
    ) => Promise<void | ExecutionResult<{}>>
    bulkUpdateAvailableFlagForClient?: (
      options?: MutationFunctionOptions<
        {},
        { buyingSessionGroup: string; clients: [string]; isExcluded: boolean }
      >
    ) => Promise<void | ExecutionResult<{}>>
  } = { updateAvailableFlagForClient: null, bulkUpdateAvailableFlagForClient: null }

  @action setMutationsForBSG = mutations => {
    this.mutations = {
      ...this.mutations,
      ...mutations
    }
  }

  @action
  onBackButtonClick = () => {
    const {
      nav: {
        history,
        updateQueryParams,
        buyingSessionGroupId,
        refererUrl,
        clearRefererUrl
      }
    } = stores
    updateQueryParams(
      {
        buyingSessionGroupId: buyingSessionGroupId,
        section: undefined,
        view: undefined,
        referrerBuyingSessionGroup: undefined
      },
      'replace'
    )
    history.push({
      pathname: refererUrl.pathname || `/wholesale`,
      search: refererUrl.search || ''
    })

    const search = refererUrl.search
      ? refererUrl.search
      : buyingSessionGroupId
      ? qs.stringify({ buyingSessionGroupId })
      : ''

    history.push({
      pathname: refererUrl.pathname || `/wholesale`,
      search
    })

    clearRefererUrl()
  }

  @observable buyingSessionGroupSettings = [
    {
      collapseHeader: WholesaleSettingsSectionDisplayName.Settings,
      section: WholesaleSettingsSection.Settings,
      isCollapsable: true,
      isSelected: true,
      view: WholesaleSettingsSection.Settings,
      permission: {},
      menus: [
        {
          collapseHeader: BuyingGroupSettingsDisplayName.Images,
          section: BuyingGroupSettingSectionName.Images,
          displayName: BuyingGroupSettingsDisplayName.Images,
          isCollapsable: false,
          isSelected: true,
          view: BuyingGroupSettingViews.Images,
          menus: null
        },
        {
          collapseHeader: BuyingGroupSettingsDisplayName.SyncBSGData,
          section: BuyingGroupSettingSectionName.SyncBSGData,
          displayName: BuyingGroupSettingsDisplayName.SyncBSGData,
          view: BuyingGroupSettingViews.SyncBSGData,
          isCollapsable: false,
          isSelected: false,
          menus: null
        },
        {
          collapseHeader: BuyingGroupSettingsDisplayName.BuyingSessionDate,
          section: BuyingGroupSettingSectionName.BuyingSessionDate,
          displayName: BuyingGroupSettingsDisplayName.BuyingSessionDate,
          isCollapsable: false,
          isSelected: false,
          view: BuyingGroupSettingViews.BuyingSessionDate,
          menus: null
        }
      ]
    },
    {
      collapseHeader: WholesaleSettingsSectionDisplayName.WholesaleSettings,
      section: WholesaleSettingsSection.WholesaleSettings,
      isCollapsable: true,
      isSelected: false,
      view: WholesaleSettingsSection.WholesaleSettings,
      allowedChannels: [VIPChannel.Wholesale],
      permissions: {
        view: [
          UserRoles.ADMINISTRATOR,
          UserRoles.CENTRAL_MERCHANDISER_WHOLESALE,
          UserRoles.ZONE_AREA_MANAGER,
          UserRoles.CM_ADMIN
        ]
      },
      menus: [
        {
          collapseHeader: BuyingGroupSettingsDisplayName.Clients,
          section: BuyingGroupSettingSectionName.Clients,
          displayName: BuyingGroupSettingsDisplayName.Clients,
          isCollapsable: true,
          isSelected: false,
          view: BuyingGroupSettingSectionName.Clients,
          permissions: {
            view: [
              UserRoles.ADMINISTRATOR,
              UserRoles.CENTRAL_MERCHANDISER_WHOLESALE,
              UserRoles.ZONE_AREA_MANAGER,
              UserRoles.CM_ADMIN
            ]
          },
          menus: [
            {
              displayName: BuyingGroupSettingsDisplayName.WorldWide,
              view: BuyingGroupSettingViews.WorldWide,
              isSelected: false
            }
          ]
        },
        {
          collapseHeader: BuyingGroupSettingsDisplayName.DeliveryWindow,
          section: BuyingGroupSettingSectionName.DeliveryWindow,
          displayName: BuyingGroupSettingsDisplayName.DeliveryWindow,
          isCollapsable: false,
          isSelected: false,
          view: BuyingGroupSettingViews.DeliveryWindow,
          permissions: {
            view: [
              UserRoles.ADMINISTRATOR,
              UserRoles.CENTRAL_MERCHANDISER_WHOLESALE,
              UserRoles.CM_ADMIN
            ]
          },
          menus: null
        },
        {
          collapseHeader: BuyingGroupSettingsDisplayName.Drops,
          section: BuyingGroupSettingSectionName.Drops,
          displayName: BuyingGroupSettingsDisplayName.Drops,
          isCollapsable: false,
          isSelected: false,
          view: BuyingGroupSettingViews.Drops,
          permissions: {
            view: [
              UserRoles.ADMINISTRATOR,
              UserRoles.CENTRAL_MERCHANDISER_WHOLESALE,
              UserRoles.CM_ADMIN
            ]
          },
          menus: null
        },
        {
          collapseHeader: BuyingGroupSettingsDisplayName.SalesDocType,
          section: BuyingGroupSettingSectionName.SalesDocType,
          displayName: BuyingGroupSettingsDisplayName.SalesDocType,
          isCollapsable: false,
          isSelected: false,
          view: BuyingGroupSettingViews.SalesDocType,
          permissions: {
            view: [
              UserRoles.ADMINISTRATOR,
              UserRoles.CENTRAL_MERCHANDISER_WHOLESALE,
              UserRoles.CM_ADMIN
            ]
          },
          menus: null
        }
      ]
    },
    {
      collapseHeader: WholesaleSettingsSectionDisplayName.RetailSettings,
      section: WholesaleSettingsSection.RetailSettings,
      isCollapsable: true,
      isSelected: false,
      view: WholesaleSettingsSection.RetailSettings,
      allowedChannels: [VIPChannel.Retail],
      permissions: {
        view: [
          UserRoles.ADMINISTRATOR,
          UserRoles.CENTRAL_MERCHANDISER_RETAIL,
          UserRoles.CM_ADMIN
        ]
      },
      menus: [
        {
          collapseHeader: WholesaleSettingsSectionDisplayName.Zones,
          section: WholesaleSettingsSection.Zones,
          isCollapsable: true,
          isSelected: false,
          view: WholesaleSettingsSection.Zones,
          displayName: WholesaleSettingsSectionDisplayName.Zones,
          menus: [
            {
              displayName: WholesaleSettingsViewDisplayName.Worldwide,
              view: WholesaleSettingsView.Worldwide,
              isSelected: false
            }
          ]
        },
        {
          collapseHeader: WholesaleSettingsSectionDisplayName.SalesPeriods,
          section: WholesaleSettingsSection.SalesPeriods,
          isCollapsable: false,
          isSelected: false,
          view: WholesaleSettingsSection.SalesPeriods,
          displayName: WholesaleSettingsSectionDisplayName.SalesPeriods,
          menus: null
        }
      ]
    }
  ]

  @action onClickSettingsMenu = view => {
    const { nav } = stores
    nav.updateQueryParams({ view })
  }

  @action
  setSelectedMenu = section => {
    this.selectedSettingMenu = section
  }

  getBSGCloseStatus = bsgData => {
    const isWHL = bsgData?.channels?.includes(VIPChannel.Wholesale)
    const isRTL = bsgData?.channels?.includes(VIPChannel.Retail)
    const buyingGroupStatus =
      config?.appConfig?.enumerations?.buyingSessionStatus?.BUYING_SESSION_STATUS_VALUES
    const retailBuyingGroupStatus =
      config?.appConfig?.enumerations?.retailBuyingSessionStatus
        ?.BUYING_SESSION_STATUS_VALUES
    const currentBSGStatusWHL = bsgData?.BuyingSessionGroupStatus
    const currentBSGStatusRTL = bsgData?.STD_RTLBuyingSessionGroup_Status
    return (
      (isWHL ? currentBSGStatusWHL === buyingGroupStatus.CLOSED : true) &&
      (isRTL ? currentBSGStatusRTL === retailBuyingGroupStatus.CLOSED : true)
    )
  }

  /**
   * This function will return the menu option which needs to be selected at first.
   */
  @action getMenuOptions = () => {
    let menus = cloneDeep(this.buyingSessionGroupSettings)
    if (isZAM()) {
      let menuIndexToBeUdated = menus.findIndex(menu => menu.isSelected === true)
      let menuIndexToBeSelected = menus.findIndex(
        menu => menu.section === WholesaleSettingsSection.WholesaleSettings
      )
      menus[menuIndexToBeUdated].isSelected = false
      menus[menuIndexToBeSelected].isSelected = true
      return menus
    }
    return menus
  }

  @action updateClientMenu = (zones: Array<IZone>) => {
    let {
      nav: {
        queryParams: { view }
      }
    } = stores

    /**
     * Build menu options from zones
     */
    let clientMenuOptions = map(zones, (zone: IZone) => {
      const { Id, Name } = zone
      return {
        displayName: Name,
        view: Id,
        isSelected: view === Id
      }
    })

    /**
     * Add worldwide as first entry in menu options array
     */
    clientMenuOptions.unshift({
      displayName: BuyingGroupSettingsDisplayName.WorldWide,
      view: BuyingGroupSettingViews.WorldWide,
      isSelected: view === BuyingGroupSettingViews.WorldWide
    })

    /**
     * Replace menu options for clients in observable by array computed above
     */
    const wholesaleSettings: any = this.buyingSessionGroupSettings.find(
      setting => setting.section === WholesaleSettingsSection.WholesaleSettings
    )
    let clientSection = find(
      wholesaleSettings.menus,
      setting => setting.section === BuyingGroupSettingSectionName.Clients
    )
    clientSection.menus = clientMenuOptions
  }

  // this function will update stores menu options when the zones data is available
  @action updateRetailZonesMenu = (zones: Array<IZone>) => {
    let {
      nav: {
        queryParams: { view }
      }
    } = stores
    /**
     * Build menu options from zones
     */
    let storeMenuOptions = map(zones, (zone: IZone) => {
      const { Id, Name } = zone
      return {
        displayName: Name,
        view: Id,
        isSelected: view === Id
      }
    })

    /**
     * Add worldwide as first entry in menu options array
     */
    storeMenuOptions.unshift({
      displayName: WholesaleSettingsViewDisplayName.Worldwide,
      view: WholesaleSettingsView.Worldwide,
      isSelected: view === WholesaleSettingsView.Worldwide
    })
    /**
     * Replace menu options for stores in observable by array computed above
     */
    const RetailSettings: any = this.buyingSessionGroupSettings.find(
      setting => setting.section === WholesaleSettingsSection.RetailSettings
    )
    let storeSection = find(
      RetailSettings.menus,
      setting => setting.section === WholesaleSettingsSection.Zones
    )
    storeSection.menus = storeMenuOptions
  }

  queryStringForClientLink(linkObject: ClientQueryLink) {
    const {
      nav: {
        location: { search }
      }
    } = stores

    const { section, view, assortment, assortmentType, productLayout, subSection } =
      linkObject

    let qsJSON: any = omit(cloneDeep(qs.parse(search)), 'toolbar')
    qsJSON.section = section
    qsJSON.view = view
    qsJSON.subSection = subSection
    if (
      section === BuyingSessionAssortmentSection.clientAssortment ||
      section === BuyingSessionAssortmentSection.doorAssortment
    ) {
      let queryParamsToRemove = ['assortment', 'assortmentType']
      view === strings.worldWide ? queryParamsToRemove.push('zone') : (qsJSON.zone = view)
      qsJSON = omit(qsJSON, queryParamsToRemove)
    }
    if (section === BuyingSessionAssortmentSection.clusterAssortment) {
      qsJSON = omit(qsJSON, ['zone'])
    }
    if (assortmentType && assortment) {
      qsJSON.assortment = assortment
      qsJSON.assortmentType = assortmentType
    }

    if (productLayout) {
      qsJSON.productLayout = productLayout
    }

    if (section === OrderManagementSection.clientOrder) {
      let queryParamsToRemove = ['clientId', 'orderId', 'orderType']
      view === strings.worldWide ? queryParamsToRemove.push('zone') : (qsJSON.zone = view)
      qsJSON = omit(qsJSON, queryParamsToRemove)
    }

    qsJSON.client = undefined
    return qs.stringify(qsJSON)
  }

  settingMenus = computedFn(channels => {
    if (this.selectedSettingMenu.section) {
      let appSettings: any = this.buyingSessionGroupSettings
      let menus = []
      appSettings.forEach(menu => {
        const appMenu: any = cloneDeep(menu)
        if (menu.section === this.selectedSettingMenu.section) {
          appMenu.isSelected = true
        } else {
          appMenu.isSelected = false
        }
        menus.push(appMenu)
      })
      return this.filterByChannelAndPermission(menus, channels)
    }
    return []
  })

  filterByChannelAndPermission = computedFn((items, channels) => {
    return map(
      filter(
        items,
        item =>
          (isEmpty(item.allowedChannels)
            ? true
            : !!intersection(item.allowedChannels, channels).length) &&
          (isEmpty(item.permissions?.view)
            ? true
            : hasUserRoles(item.permissions?.view || []))
      ),
      item => ({
        ...omit(item, ['permissions', 'allowedChannels']),
        menus: this.filterByChannelAndPermission(item.menus, channels)
      })
    )
  })

  @action handleBulkStatusConfirmationDialogue = (data, excludeClient) => event => {
    this.bulkUpdateData = data
    this.openConfirmationDialog = true
    this.excludeClient = excludeClient === strings.yes ? false : true
  }

  @action handleStatusConfirmationDialogue =
    (rowData: IClientTableData, buyingSessionGroupId) => event => {
      event.stopPropagation()
      this.rowData = rowData
      this.openConfirmationDialog = true
    }

  @action handleStatusConfirmationDialogueClose = () => {
    this.openConfirmationDialog = false
  }

  @action updateAvailableFlagForClient =
    (client: IClientTableData, buyingSessionGroupId) =>
    async (event, checked: boolean) => {
      this.clientUpdateInProgress[client.id] = true
      this.bulkClientUpdateInProgress = true
      try {
        await this.mutations.updateAvailableFlagForClient({
          variables: {
            buyingSessionGroup: buyingSessionGroupId,
            client: client.id,
            isExcluded: !checked
          },
          refetchQueries: [
            {
              query: GET_BUYING_GROUP_WITH_CLIENTS,
              variables: {
                id: buyingSessionGroupId
              }
            }
          ]
        })
      } finally {
        this.openConfirmationDialog = false
        this.bulkClientUpdateInProgress = false
        this.clientUpdateInProgress[client.id] = false
      }
    }

  @action bulkUpdateAvailableFlagForClient = () => async () => {
    const {
      nav: { buyingSessionGroupId }
    } = stores

    this.bulkClientUpdateInProgress = true
    try {
      await this.mutations.bulkUpdateAvailableFlagForClient({
        variables: {
          buyingSessionGroup: buyingSessionGroupId,
          clients: this.bulkUpdateData,
          isExcluded: this.excludeClient
        },
        refetchQueries: [
          {
            query: GET_BUYING_GROUP_WITH_CLIENTS,
            variables: {
              id: buyingSessionGroupId
            }
          }
        ]
      })
    } finally {
      this.openConfirmationDialog = false
      this.bulkClientUpdateInProgress = false
      this.bulkUpdateData = null
    }
  }
}

export const buyingGroupSetting = new BuyingGroupSettingStore()
