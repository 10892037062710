import { OrderDataKeys, OrderDataHeadersText } from '@modules/common/models/enums'
export const MAX_ALLOWED_ORDER_SELECTION = 40
// to be deprecated
export const SAOrdersTableConfig = {
  Cells: [
    {
      dataKey: '',
      headerText: '',
      widthPercentage: 2
    },
    {
      dataKey: OrderDataKeys.Name,
      headerText: OrderDataHeadersText.Name,
      widthPercentage: 9
    },
    {
      dataKey: OrderDataKeys.OrderNumber,
      headerText: OrderDataHeadersText.OrderNumber,
      widthPercentage: 14
    },
    {
      dataKey: OrderDataKeys.Status,
      headerText: OrderDataHeadersText.Status,
      widthPercentage: 11
    },
    {
      dataKey: OrderDataKeys.SalesDocType,
      headerText: OrderDataHeadersText.SalesDocType,
      widthPercentage: 8
    },
    {
      dataKey: OrderDataKeys.DeliveryDate,
      headerText: OrderDataHeadersText.DeliveryDate,
      widthPercentage: 7
    },
    {
      dataKey: OrderDataKeys.PORef,
      headerText: OrderDataHeadersText.PORef,
      widthPercentage: 12
    },
    {
      dataKey: OrderDataKeys.Notes,
      headerText: OrderDataHeadersText.Notes,
      widthPercentage: 13
    },
    {
      dataKey: OrderDataKeys.CreatedBy,
      headerText: OrderDataHeadersText.CreatedBy,
      widthPercentage: 7
    },
    {
      dataKey: OrderDataKeys.CreatedDateOfOrder,
      headerText: OrderDataHeadersText.CreatedDateOfOrder,
      widthPercentage: 9
    },
    {
      dataKey: OrderDataHeadersText.Action,
      headerText: OrderDataHeadersText.Action,
      widthPercentage: 8
    }
  ]
}
