export enum WholesaleGirdProductFieldMapping {
  // Product attributes
  ProductInfo = 'productInfo',
  Attributes = 'attributes',
  NodeInfo = 'nodeInfo',
  Favorite = 'favorite',
  HasComments = 'hasComments',
  AverageRating = 'averageRating',
  ClusterAssortmentProducts = 'clusterAssortmentProducts'
}
