import { action, observable } from 'mobx'

export class CollectionBriefStore {
  @observable isDialogOpen = false

  @action
  toggleDialogOpen = () => {
    Object.assign(this, { isDialogOpen: !this.isDialogOpen })
  }
}
