import { StyledProps } from '@components/3rd-party/mui'
import CloseIcon from '@material-ui/icons/Close'
import { IconButton, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { nav } from '@stores/nav'
import React from 'react'

const styles = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
})

interface IProductMedia extends StyledProps {
  title?: string
  onClose?: () => void
}

@((withStyles as any)(styles))
export class ProductDetailHeader extends React.Component<IProductMedia> {
  render() {
    const {
      props: { classes, onClose, title }
    } = this
    return (
      <div className={classes.container}>
        <Typography variant='h5'>{title}</Typography>
        <IconButton
          aria-label='Close'
          className={classes.closeButton}
          onClick={onClose ? onClose : nav.closeDetails}
        >
          <CloseIcon />
        </IconButton>
      </div>
    )
  }
}
