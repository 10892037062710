import React from 'react'
import Icon from '@material-ui/core/Icon'
import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'
import css from './ProductCardActionsIcon.css'

interface IProductCardActionsIcon {
  iconName: any
  label?: any
  status?: boolean
  successorColor?: string
  onClick?: (event?) => {}
}

export const StyledProductActionIcon = styled(({ ...rest }) => <Icon {...rest} />)(
  props => ({
    '&': {
      color: props.status ? props.successorcolor : '#000000'
    }
  })
)

export const ProductCardActionsIcon: React.FunctionComponent<IProductCardActionsIcon> = ({
  iconName,
  label,
  status,
  successorColor,
  onClick
}) => {
  return (
    <div className={css.root} onClick={onClick}>
      <StyledProductActionIcon status={status ? 1 : 0} successorcolor={successorColor}>
        {iconName}
      </StyledProductActionIcon>
      <Typography className={css.counts} variant='caption'>
        {label}
      </Typography>
    </div>
  )
}
