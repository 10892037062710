export const STRIPCONTAINERHEIGHT = 55
export const SELECTIONCOUNTROWHEIGHT = 55
export const STRIPHEIGHT = 20
export const LETTERWIDTH = 22
export const HEADERROWBORDERWIDTH = 2
export const TABLEBODERWIDTH = 1
export const MINSKELETONWIDTH = 20
export const SEARCHBARHEIGHT = 49
export const MULTI_SELECT_COLUMN_WIDTH = 30
export const MULTI_SELECT_COLUMN_HEADER_WIDTH = 60
export const LETTERS = [
  '#',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z'
]

export enum RowTypes {
  header = 'header',
  sectionHeader = 'sectionHeader',
  record = 'record'
}
