import { nav } from '@stores/nav'
import { action, computed, observable } from 'mobx'

export class CollectionImageStore {
  @observable uploadInProgress = false
  @observable imageUrlState = null
  @observable selectedBuyingSessionId = ''
  @observable selectedBuyingSessionImage = null

  @computed get selectedImageTab() {
    const result = parseInt(nav.queryParams.tab || '0')
    return result
  }

  @action
  handleTabChange = selectedTab => {
    nav.updateQueryParams({ tab: selectedTab })
  }

  @action
  setImageUploadProgress = () => {
    this.uploadInProgress = !this.uploadInProgress
  }

  @action
  setImageUrlState = result => {
    this.imageUrlState = result
  }

  @action
  handleBuyingSessionChange = id => {
    this.selectedBuyingSessionId = id
  }

  getImage = buyingSessions => {
    let buyingSession = buyingSessions.find(
      ({ id }) => id === this.selectedBuyingSessionId
    )
    return buyingSession ? buyingSession.image : null
  }
}
