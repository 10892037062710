import {
  ComponentServiceTypes,
  ListViewColumnLabels,
  QuantityType
} from '@modules/common'
import { ChannelSubSection, ListViewFieldMapping } from '@modules/common/models'
import { strings } from '@stores'
import filter from 'lodash/filter'
import forEach from 'lodash/forEach'
import includes from 'lodash/includes'
import map from 'lodash/map'
import { listViewColumnDefinitionService } from '../listViewColumnDefinitionService'

class ColDefWithChannel {
  constructor(public colDef, public allowedChannels = []) {}
}

const filterColDefByChannel = (colDefs: ColDefWithChannel[], channel: string) => {
  return map(
    filter(colDefs, colDef =>
      colDef.allowedChannels.length ? includes(colDef.allowedChannels, channel) : true
    ),
    'colDef'
  )
}

class CollectionListViewDefinitionService {
  /**
   * TODO: Currently using component service for retail.
   * Once cell components get standardize, we should pass
   * a flag from here which will then use appropriate
   * component service from listViewDefinitionService
   */
  buildListViewDefinitionForDistribution = (
    subSection: ChannelSubSection,
    assortmentType?
  ) => {
    return filterColDefByChannel(
      [
        new ColDefWithChannel(
          listViewColumnDefinitionService.buildProductInfoColumnDefinitionInstance(
            ComponentServiceTypes.Standard
          )
        ),
        new ColDefWithChannel(
          listViewColumnDefinitionService.buildProductAttributeColumnDefinitionInstance(
            ComponentServiceTypes.Standard
          )
        ),
        new ColDefWithChannel(
          listViewColumnDefinitionService.buildPriceColumnDefinitionInstanceForDistribution(
            false,
            ComponentServiceTypes.Standard
          )
        ),
        new ColDefWithChannel(
          listViewColumnDefinitionService.buildCMClusterColumnDefinitionInstance(
            false,
            false,
            ListViewColumnLabels.WholesaleCluster,
            ComponentServiceTypes.Standard,
            ListViewFieldMapping.WholesaleClusters
          ),
          [ChannelSubSection.Wholesale]
        ),
        new ColDefWithChannel(
          listViewColumnDefinitionService.buildCMClusterColumnDefinitionInstance(
            false,
            false,
            ListViewColumnLabels.RetailCluster,
            ComponentServiceTypes.Standard,
            ListViewFieldMapping.RetailClusters
          ),
          [ChannelSubSection.Retail]
        ),
        ...(assortmentType === 'door' || assortmentType === 'client'
          ? [
              new ColDefWithChannel(
                listViewColumnDefinitionService.buildRMClusterColumnDefinitionInstance(
                  false,
                  false,
                  ListViewColumnLabels.RMCluster,
                  ComponentServiceTypes.Standard,
                  ListViewFieldMapping.RMClusters
                ),
                [ChannelSubSection.Retail]
              )
            ]
          : []),
        new ColDefWithChannel(
          listViewColumnDefinitionService.buildChannelColumnDefinitionInstance(
            ComponentServiceTypes.Standard
          )
        ),
        new ColDefWithChannel(
          listViewColumnDefinitionService.buildWholesaleFreeTagsColumnDefinitionInstance(
            ComponentServiceTypes.Standard
          ),
          [ChannelSubSection.Wholesale]
        ),
        new ColDefWithChannel(
          listViewColumnDefinitionService.buildRetailFreeTagsColumnDefinitionInstance(
            ComponentServiceTypes.Standard
          ),
          [ChannelSubSection.Retail]
        ),
        new ColDefWithChannel(
          listViewColumnDefinitionService.buildExcludedClientColumnDefinitionInstance(
            ComponentServiceTypes.Standard
          ),
          [ChannelSubSection.Wholesale]
        ),
        new ColDefWithChannel(
          listViewColumnDefinitionService.buildExcludedZonesColumnDefinitionInstance(
            ComponentServiceTypes.Standard
          ),
          [ChannelSubSection.Retail]
        ),
        new ColDefWithChannel(
          listViewColumnDefinitionService.buildDropColumnDefinitionInstance(
            ComponentServiceTypes.Standard
          ),
          [ChannelSubSection.Wholesale]
        ),
        new ColDefWithChannel(
          listViewColumnDefinitionService.buildTotalDoorsColumnDefinitionInstanceForDistribution(
            ComponentServiceTypes.Standard
          ),
          [ChannelSubSection.Wholesale]
        ),
        new ColDefWithChannel(
          listViewColumnDefinitionService.buildSalesPeriodColumnDefinitionInstance(
            ComponentServiceTypes.Standard
          ),
          [ChannelSubSection.Retail]
        ),
        new ColDefWithChannel(
          listViewColumnDefinitionService.buildTotalWeeksColumnDefinitionInstance(),
          [ChannelSubSection.Retail]
        ),
        new ColDefWithChannel(
          listViewColumnDefinitionService.buildTotalStoresColumnDefinitionInstanceForDistribution(),
          [ChannelSubSection.Retail]
        )
      ],
      subSection
    )
  }

  buildListViewDefinitionForBuy = (subSection: ChannelSubSection, assortmentType?) => {
    return filterColDefByChannel(
      [
        new ColDefWithChannel(
          listViewColumnDefinitionService.buildProductInfoColumnDefinitionInstance(
            ComponentServiceTypes.Standard
          )
        ),
        new ColDefWithChannel(
          listViewColumnDefinitionService.buildProductAttributeColumnDefinitionInstance(
            ComponentServiceTypes.Standard
          )
        ),
        new ColDefWithChannel(
          listViewColumnDefinitionService.buildCMClusterColumnDefinitionInstance(
            true,
            false,
            ListViewColumnLabels.RetailCluster,
            ComponentServiceTypes.Standard,
            ListViewFieldMapping.RetailClusters
          ),
          [ChannelSubSection.Retail]
        ),
        ...(assortmentType === 'door' || assortmentType === 'client'
          ? [
              new ColDefWithChannel(
                listViewColumnDefinitionService.buildRMClusterColumnDefinitionInstance(
                  true,
                  false,
                  ListViewColumnLabels.RMCluster,
                  ComponentServiceTypes.Standard,
                  ListViewFieldMapping.RMClusters
                ),
                [ChannelSubSection.Retail]
              )
            ]
          : []),
        new ColDefWithChannel(
          listViewColumnDefinitionService.buildZonesColumnDefinitionInstance(),
          [ChannelSubSection.Retail]
        ),
        new ColDefWithChannel(
          listViewColumnDefinitionService.buildPriceColumnDefinitionInstanceForDistribution(
            false,
            ComponentServiceTypes.Standard
          ),
          [ChannelSubSection.Wholesale]
        ),
        new ColDefWithChannel(
          listViewColumnDefinitionService.buildPriceColumnDefinitionInstance(true),
          [ChannelSubSection.Retail]
        ),
        ...(assortmentType === 'door'
          ? []
          : [
              new ColDefWithChannel(
                listViewColumnDefinitionService.buildTotalStoresColumnDefinitionInstance(
                  true
                ),
                [ChannelSubSection.Retail]
              )
            ]),
        new ColDefWithChannel(
          listViewColumnDefinitionService.buildCMClusterColumnDefinitionInstance(
            false,
            false,
            ListViewColumnLabels.WholesaleCluster,
            ComponentServiceTypes.Standard,
            ListViewFieldMapping.WholesaleClusters
          ),
          [ChannelSubSection.Wholesale]
        ),
        new ColDefWithChannel(
          listViewColumnDefinitionService.buildBuyerBETQuantityColumnDefinitionInstance(
            'Forecast'
          ),
          [ChannelSubSection.Retail]
        ),

        new ColDefWithChannel(
          listViewColumnDefinitionService.buildOrderQtyColumnDefinitionInstance(
            QuantityType.BUY,
            strings.order
          ),
          [ChannelSubSection.Retail]
        ),
        new ColDefWithChannel(
          listViewColumnDefinitionService.buildAWSColumnDefinitionInstance(),
          [ChannelSubSection.Retail]
        ),
        new ColDefWithChannel(
          listViewColumnDefinitionService.buildAvgDepthColumnDefinitionInstance(),
          [ChannelSubSection.Retail]
        ),
        new ColDefWithChannel(
          listViewColumnDefinitionService.buildSkuEffColumnDefinitionInstance(),
          [ChannelSubSection.Retail]
        ),
        new ColDefWithChannel(
          listViewColumnDefinitionService.buildValueColumnDefinitionInstance('Order'),
          [ChannelSubSection.Retail]
        ),
        ...(assortmentType === 'door'
          ? []
          : [
              new ColDefWithChannel(
                listViewColumnDefinitionService.buildSalesQtyColumnDefinitionInstance(),
                [ChannelSubSection.Retail]
              ),
              new ColDefWithChannel(
                listViewColumnDefinitionService.buildCMCommentsColumnInstance(
                  ComponentServiceTypes.Standard
                ),
                [ChannelSubSection.Retail]
              ),
              new ColDefWithChannel(
                listViewColumnDefinitionService.buildRMCommentsColumnInstance(
                  ComponentServiceTypes.Standard
                ),
                [ChannelSubSection.Retail]
              )
            ]),
        new ColDefWithChannel(
          listViewColumnDefinitionService.buildOrderValueColumnDefinitionInstanceForWS(),
          [ChannelSubSection.Wholesale]
        ),
        new ColDefWithChannel(
          listViewColumnDefinitionService.buildSalesQtyColumnDefinitionInstanceForWS(),
          [ChannelSubSection.Wholesale]
        ),
        new ColDefWithChannel(
          listViewColumnDefinitionService.buildSizedQtyColumnDefinitionInstanceForWS(),
          [ChannelSubSection.Wholesale]
        ),
        new ColDefWithChannel(
          listViewColumnDefinitionService.buildDraftQtyColumnDefinitionInstanceForWS(),
          [ChannelSubSection.Wholesale]
        )
      ],
      subSection
    )
  }

  buildListViewDefinitionForForecast = (assortmentType?) => {
    return [
      listViewColumnDefinitionService.buildProductInfoColumnDefinitionInstance(
        ComponentServiceTypes.Standard
      ),
      listViewColumnDefinitionService.buildProductAttributeColumnDefinitionInstance(
        ComponentServiceTypes.Standard
      ),
      listViewColumnDefinitionService.buildCMClusterColumnDefinitionInstance(
        true,
        false,
        ListViewColumnLabels.RetailCluster,
        ComponentServiceTypes.Standard,
        ListViewFieldMapping.RetailClusters
      ),
      ...(assortmentType
        ? []
        : [listViewColumnDefinitionService.buildZonesColumnDefinitionInstance()]),
      listViewColumnDefinitionService.buildPriceColumnDefinitionInstance(true),
      listViewColumnDefinitionService.buildAWSColumnDefinitionInstance(),
      listViewColumnDefinitionService.buildMixQtyColumnDefinitionInstance(),
      listViewColumnDefinitionService.buildMixValueColumnDefinitionInstance(),
      listViewColumnDefinitionService.buildAvgDepthColumnDefinitionInstance(),
      listViewColumnDefinitionService.buildSkuEffColumnDefinitionInstance(),
      ...(assortmentType === 'door'
        ? []
        : [
            listViewColumnDefinitionService.buildTotalStoresColumnDefinitionInstance(true)
          ]),
      listViewColumnDefinitionService.buildValueColumnDefinitionInstance('Forecast'),
      ...(assortmentType === 'door'
        ? []
        : [listViewColumnDefinitionService.buildSalesQtyColumnDefinitionInstance()]),
      listViewColumnDefinitionService.buildOrderQtyColumnDefinitionInstance(
        QuantityType.BET,
        'Forecast'
      )
    ]
  }

  buildListViewDefinitionForCounterOffer = (zoneColumnMap?) => {
    let listViewDefinition = [
      listViewColumnDefinitionService.buildProductInfoColumnDefinitionInstance(
        ComponentServiceTypes.Standard
      ),
      listViewColumnDefinitionService.buildProductAttributeColumnDefinitionInstance(
        ComponentServiceTypes.Standard
      ),
      listViewColumnDefinitionService.buildCMClusterColumnDefinitionInstance(
        true,
        false,
        ListViewColumnLabels.RetailCluster,
        ComponentServiceTypes.Standard,
        ListViewFieldMapping.RetailClusters
      )
    ]

    forEach(zoneColumnMap, ({ name, hasClusterAssociation, hasCounterOffer }, id) => {
      listViewDefinition.push(
        listViewColumnDefinitionService.buildZoneCounterOfferColumnInstance(
          ComponentServiceTypes.Standard,
          {
            id,
            name,
            hasClusterAssociation,
            hasCounterOffer
          }
        )
      )
    })

    return listViewDefinition
  }
}

export const collectionListViewDefinitionService =
  new CollectionListViewDefinitionService()
