import FallbackComponent from '@components/std-components/FallbackComponent'
import React, { lazy, Suspense } from 'react'

const OrigSAChannelWiseProductDetail = lazy(() =>
  import(
    '@modules/showroom/productDetail/new/container/SAChannelWiseProductDetails'
  ).then(m => ({
    default: m.SAChannelWiseProductDetails
  }))
)

export interface Props {
  prevNextAction: {
    nextAction: () => void
    previousAction: () => void
    isNextButtonActive: boolean
    isPreviousButtonActive: boolean
  }
  setActiveFocusProductIndex?: () => void
  isFilterOrSearchApplied?: boolean
  productDetailBottomRightCallBack?: (bspId) => void
  showIcon?: boolean
  isBottomRightIconClickable: boolean
}

export const SAChannelWiseProductDetails = (props: Props) => {
  return (
    <Suspense fallback={<FallbackComponent />}>
      <OrigSAChannelWiseProductDetail {...props} />
    </Suspense>
  )
}
