import { StyledProps } from '@components/3rd-party/mui'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import classNames from 'classnames'

const styles = theme => ({
  container: {
    overflow: 'hidden'
  },
  hoverScrollBar: {
    '&:hover': {
      overflowX: 'hidden',
      overflowY: 'scroll',
      '&::-webkit-scrollbar': {
        width: '0.5em'
      },
      '&::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
        background: '#f1f1f1'
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#cecece',
        /* background: #7fb3ff;  */
        borderRadius: 50
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#555',
        cursor: 'pointer'
      }
    }
  }
})

interface IProductDetail extends StyledProps {
  style?: any
  className?: any
}
@((withStyles as any)(styles))
export class ProductDetail extends React.Component<IProductDetail> {
  onScroll = event => {
    event.stopPropagation()
  }
  render() {
    const { classes, children, className, style = {} } = this.props
    return (
      <div
        className={classNames(classes.container, classes.hoverScrollBar, className)}
        onScroll={this.onScroll}
        style={style}
      >
        {children}
      </div>
    )
  }
}
