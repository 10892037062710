import { config } from '@config'
import { LIST_VIEW_PRODUCT_ATTRIBUTES } from '@constants'
import { User } from '@models'
import { ListViewColumnDefinition } from '@components/UI-Components/StandardTableView/models/ListviewColumnDefinition'
import { ListViewFieldMapping } from '@modules/common/models/enums/ListViewFieldMapping'
import { QuantityType } from '@modules/common/models/enums/QuantityType'
import { Views } from '@modules/common/models/enums/Views'
import {
  ListViewProduct,
  ProductKPIsByZone
} from '@modules/common/models/interfaces/ListViewProduct'
import { ZoneCounterOffer } from '@modules/common/models/interfaces/ZoneCounterOffer'
import {
  CHANNEL_OPERATION_OPTIONS,
  constants
} from '@modules/retail/buyingSession/constants'
import { getPermissionsByEntityAndRole, IPermissions } from '@routes'
import { convertToCurrencyLocaleString } from '@services/assortmentService'
import { getBuyingSessionClosedStatus } from '@services/buyingSessionServices'
import {
  getCollectionBetData,
  getCollectionBuyData,
  getNumberOfWeeksFromSalesPeriod,
  getProductClustersWithStatus,
  getProductDetails,
  getSalesPeriodStartAndEndDate,
  getTagsMapForListView,
  getZoneLevelProductValue
} from '@services/collectionServices'
import { getZoneOrder, sortListUsingOrderList } from '@services/commonServices'
import { downloadFile } from '@services/downloadFileService'
import { getImageUrl } from '@services/imageService'
import { getAttributeNameValuePair } from '@services/productDetailService'
import { storage } from '@services/storageService'
import { apolloClient, stores, strings } from '@stores'
import filter from 'lodash/filter'
import find from 'lodash/find'
import map from 'lodash/map'
import uniqBy from 'lodash/uniqBy'
import remove from 'lodash/remove'
import memoize from 'memoize-one'
import { action, computed, observable } from 'mobx'
import moment from 'moment'

import { BULK_ADD_CHANNEL_TO_BUYING_SESSION_PRODUCTS } from '../graphql/bulkAddChannelToBuyingSesionProducts'
import { BULK_REMOVE_CHANNEL_FROM_BUYING_SESSION_PRODUCTS } from '../graphql/bulkRemoveChannelFromBuyinSesionProducts'
import { GET_SEASON_BY_ID } from '../graphql/getSeasonById'

// FIXME: RM - Use alias instead of relative path
interface BuyingSessionProduct {
  productId: string
  buyingSessionId: string
}

export class CollectionStore {
  @observable checkedFilters = {}
  @observable openCollectionFilter = false
  @observable finalFilterToApply = {}
  @observable anchorEl = null
  @observable open = false
  @observable sortFilter = 'Hierarchy'
  @observable selectedRegion = 'EUR'
  @observable currentPage = 0
  @observable hasMore = true
  @observable selectedProductId = ''
  @observable drawerOpen = false
  @observable isAttributeSet = false
  @observable reportingDrawerOpen = false
  @observable selectedViewAttributeSelectorTab = 'product_attributes'
  @observable popOveranchorEl = null
  @observable openChannelBulkAddRemoveConfirmationDialog = false
  @observable bulkAddDeleteConfirmationMessage = null
  @observable confirmationTitle = null
  @observable callBackFunction = null
  @observable isBulkAddSet = false
  @observable bulkAddRemoveChannelFromProductInProgress = false

  // For multiselect actions
  @observable selectedProducts: Array<BuyingSessionProduct> = []

  // Collection list view column headers for different tabs
  @observable listViewColumnDefinitionForDistribution: Array<ListViewColumnDefinition> =
    []
  @observable listViewColumnDefinitionForBET: Array<ListViewColumnDefinition> = []
  @observable listViewColumnDefinitionForBUY: Array<ListViewColumnDefinition> = []
  @observable listViewColumnDefinitionForCounterOffer: Array<ListViewColumnDefinition> =
    []

  // TODO: Add constants for all names and values
  @observable coreAttributes = [
    {
      name: 'Excluded Zones',
      value: 'excludedZones',
      isSelected: true,
      enabled: true
    },
    {
      name: 'Channels',
      value: 'channels',
      isSelected: true,
      enabled: true
    },
    {
      name: 'Retail Clusters',
      value: 'retails_cluster',
      isSelected: true,
      enabled: true
    },
    {
      name: 'Wholesale Cluster',
      value: 'wholesale_cluster',
      isSelected: false,
      enabled: true
    },
    {
      name: 'Sales Period',
      value: 'sales_period',
      isSelected: true,
      enabled: true
    },
    {
      name: 'Free Tags',
      value: 'free_tags',
      isSelected: false,
      enabled: true
    }
  ]
  @observable visibleAttributeSelection = [
    {
      name: 'Price',
      value: 'price',
      isSelected: true,
      enabled: true
    },
    {
      name: 'Actions',
      value: 'actions',
      isSelected: true,
      enabled: true
    },
    ...this.coreAttributes
  ]

  /**
   * Computed map for list view column headers for different tabs
   */
  @computed get listViewColumnDefinitionMap() {
    const {
      nav: {
        params: { view }
      }
    } = stores
    if (view === Views.Kpi) {
      return {
        0: this.listViewColumnDefinitionForBET,
        1: this.listViewColumnDefinitionForBUY
      }
    } else {
      return {
        0: this.listViewColumnDefinitionForDistribution,
        1: this.listViewColumnDefinitionForBET,
        2: this.listViewColumnDefinitionForBUY,
        3: this.listViewColumnDefinitionForCounterOffer
      }
    }
  }

  @action buildColumnViewDefinitionForCounterOffer = (
    zoneCounterOffer?: Array<ZoneCounterOffer>
  ) => {
    if (
      (!zoneCounterOffer &&
        this.listViewColumnDefinitionForCounterOffer &&
        this.listViewColumnDefinitionForCounterOffer.length > 3) ||
      (zoneCounterOffer && zoneCounterOffer.length)
    ) {
      this.listViewColumnDefinitionForCounterOffer = []
    }
  }

  @action onViewAttributeSelectorTabChange = (events, value) => {
    Object.assign(this, { selectedViewAttributeSelectorTab: value })
  }
  @action onSelectProduct = product => {
    const {
      restrictProductByZoneModal: {
        removeFromCoreProductsList,
        addToCoreProductsList,
        addToRetailChannelProductsList,
        removeFromRetailChannelProductsList
      }
    } = stores

    const {
      id,
      buyingSession: { id: buyingSessionId },
      productStatus,
      channels
    } = product
    let existingProduct = find(this.selectedProducts, product => product.productId === id)
    const hasRetailChannel = channels.find(
      channel => channel.name === strings.retailChannelName
    )
    if (existingProduct) {
      remove(this.selectedProducts, product => product.productId === id)
      if (productStatus === 'CORE') {
        removeFromCoreProductsList(existingProduct.productId)
      }
      if (!hasRetailChannel) {
        removeFromRetailChannelProductsList(existingProduct.productId)
      }
    } else {
      // Allow to user to select 15 products maximum
      if (this.selectedProducts.length >= 15) {
        return
      }

      this.selectedProducts.push({
        productId: id,
        buyingSessionId: buyingSessionId
      })
      if (product.productStatus === 'CORE') {
        addToCoreProductsList(product.id)
      }
      if (!hasRetailChannel) {
        addToRetailChannelProductsList(product.id)
      }
    }
  }

  @action resetSelectedProducts = () => {
    this.selectedProducts = []
  }

  @action
  handleClick = event => {
    Object.assign(this, {
      anchorEl: event.currentTarget,
      open: !this.open
    })
  }

  @action
  handleClose = () => {
    Object.assign(this, {
      selectedViewAttributeSelectorTab: 'product_attributes',
      anchorEl: null,
      open: false
    })
  }

  @action
  handleSortChange = e => {
    Object.assign(this, { sortFilter: e.target.value })
  }

  @action
  handleRegionChange = e => {
    Object.assign(this, { selectedRegion: e.target.value })
  }

  @action
  toggleDrawer = () => {
    Object.assign(this, {
      drawerOpen: !this.drawerOpen
    })
  }

  @action
  showProductDetail = id => {
    Object.assign(this, {
      selectedProductId: id,
      drawerOpen: !this.drawerOpen
    })
  }

  @action
  updateAttributeSelection = (name, checked) => {
    const { visibleAttributeSelection } = this
    const selectedItems = visibleAttributeSelection.filter(({ isSelected }) => isSelected)

    Object.assign(this, {
      visibleAttributeSelection:
        selectedItems.length < 10 || !checked
          ? visibleAttributeSelection.map(item => {
              if (item.name === name) {
                item.isSelected = checked
              }
              return item
            })
          : visibleAttributeSelection
    })
  }

  @action
  loadMoreRecords = () => {
    Object.assign(this, { hasMore: false })
  }

  @computed
  get selectedAttributes() {
    const selectionObj = {}

    this.visibleAttributeSelection
      .filter(({ isSelected, enabled }) => isSelected && enabled)
      .forEach(({ value }) => {
        selectionObj[value] = true
      })

    return selectionObj
  }

  @action
  setVisibleAttributes = attributes => {
    attributes.forEach(({ name, displayName: { singular } }) =>
      this.visibleAttributeSelection.push({
        name: singular,
        value: name,
        isSelected: false,
        enabled: true
      })
    )
    this.visibleAttributeSelection.push(
      {
        name: 'Associated Looks',
        value: 'associatedLooks',
        isSelected: false,
        enabled: true
      },
      {
        name: 'Substitutes In Looks',
        value: 'substitutesInLooks',
        isSelected: false,
        enabled: true
      }
    )
    this.isAttributeSet = true
  }

  @action
  toggleReportingDrawer = () => {
    Object.assign(this, {
      reportingDrawerOpen: !this.reportingDrawerOpen
    })
  }

  prepareRangePlanWhereInput = activities => {
    let whereInput = []
    activities.forEach(({ activity: { id }, collection: { id: collectionID } }) => {
      whereInput.push({ activity: { id }, collection: { id: collectionID } })
    })
    return whereInput
  }

  getReportingData = (
    rangePlans,
    markUpList,
    role,
    betBuyData,
    activities = [] as any[],
    buyingSessionProducts?: Array<{ id: string; bet: { id: string; quantity: number } }>
  ) => {
    const { betBuyValues } = betBuyData
    const {
      site: { globalCurrencyCode }
    } = stores
    let totalBetQty = 0
    let totalBetValue = 0
    let reportingData = []
    let activitieList = uniqBy(activities, 'activity')
    if (betBuyValues) {
      /**
       * In case of activity collection, iterate through buyingSessionProducts and calculate total bet quantity and value,
       * else iterate through betBuyValues
       */
      //Get total value from all activities
      ;(buyingSessionProducts ? buyingSessionProducts : betBuyValues).forEach(
        ({
          bet: productBet,
          retailPrice: producRetailPrice,
          assortmentProducts,
          buyingSession: { activity }
        }) => {
          const zoneMarkUp = markUpList.find(
            ({ description }) => description === activity.description
          )
          //If product assortment is present roll up will be summation of value at zone level else consider value at buyings session product level
          if (assortmentProducts && assortmentProducts.length) {
            assortmentProducts.forEach(
              ({ bet, retailPrice, zoneAssortment: { zone } }) => {
                const markUpValue =
                  zoneMarkUp && zoneMarkUp.zones
                    ? zoneMarkUp.zones.find(({ id }) => id === zone.id)
                    : null
                if (bet && bet.quantity) {
                  totalBetValue =
                    totalBetValue +
                    getZoneLevelProductValue(
                      retailPrice,
                      bet.quantity,
                      markUpValue,
                      role,
                      producRetailPrice
                    )
                }
              }
            )
          } else {
            totalBetValue =
              totalBetValue +
              (producRetailPrice && productBet
                ? productBet.quantity * producRetailPrice.price
                : 0)
          }
          totalBetQty = totalBetQty + (productBet ? productBet.quantity : 0)
        }
      )

      activitieList.forEach(({ activity: { id, description }, collection }) => {
        const filterProducts = filter(
          betBuyValues,
          prouduct => prouduct.buyingSession.activity.id === id
        )
        let noAcs = 0
        let betValue = 0
        let betQty = 0
        const activity = description
        const zoneMarkUp = markUpList.find(({ description }) => description === activity)

        const rangePlandata = filter(
          rangePlans,
          data => data.activity.id === id && data.collection.id === collection.id
        )

        let { otbValue, otbQty } = rangePlandata.reduce(
          (acc, curr) => {
            acc.otbValue += curr.OTB
            acc.otbQty += curr.buyingQty
            return acc
          },
          { otbValue: 0, otbQty: 0 }
        )

        if (filterProducts.length) {
          filterProducts.forEach(
            ({ bet: productBet, retailPrice: producRetailPrice, assortmentProducts }) => {
              //If product assortment is present roll up will be summation of value at zone level else consider value at buyings session product level
              if (assortmentProducts && assortmentProducts.length) {
                assortmentProducts.forEach(
                  ({ bet, retailPrice, zoneAssortment: { zone } }) => {
                    const markUpValue =
                      zoneMarkUp && zoneMarkUp.zones
                        ? zoneMarkUp.zones.find(({ id }) => id === zone.id)
                        : null
                    if (bet && bet.quantity) {
                      betValue =
                        betValue +
                        getZoneLevelProductValue(
                          retailPrice,
                          bet.quantity,
                          markUpValue,
                          role,
                          producRetailPrice
                        )
                    }
                  }
                )
              } else {
                betValue =
                  betValue +
                  (producRetailPrice && productBet
                    ? productBet.quantity * producRetailPrice.price
                    : 0)
              }
              if (productBet && productBet.quantity > 0) {
                noAcs++
              }
              betQty = betQty + (productBet ? productBet.quantity : 0)
            }
          )
        }

        let data = {
          activity: activity,
          noAcs: noAcs,
          betVal: betValue
            ? convertToCurrencyLocaleString(globalCurrencyCode, Math.round(betValue), 0)
            : betValue,
          betValueWeight:
            betValue && otbValue
              ? parseFloat(((betValue / otbValue) * 100).toFixed(2))
              : 0,
          betQty: betQty,
          otbValue: otbValue
            ? convertToCurrencyLocaleString(globalCurrencyCode, Math.round(otbValue), 0)
            : otbValue,
          otbQty: otbQty,
          betQtyWeight:
            betQty && otbQty ? parseFloat(((betQty / otbQty) * 100).toFixed(2)) : 0
        }
        reportingData.push(data)
      })
    }
    return reportingData
  }

  @action onExcelExportClick = async () => {
    const {
      nav: {
        params: { buyingSession, season }
      },
      assortment: { updateExportError }
    } = stores
    const seasonData = await apolloClient.query({
      query: GET_SEASON_BY_ID,
      variables: { seasonId: season },
      fetchPolicy: 'network-only'
    })
    const fileName = `${
      seasonData && seasonData.data && seasonData.data.season
        ? seasonData.data.season.description + '_'
        : ''
    }_COLLECTION_${moment(new Date()).format('DD/MM/YYYY')}.xlsx`
    const downloadResponse = await downloadFile(
      `${config.dataImporter}buyingSession/${buyingSession}/export`,
      fileName
    )
    if (downloadResponse.error && downloadResponse.message) {
      updateExportError(downloadResponse.message)
    }
  }

  @action onViewClick = (view: Views) => {
    const {
      nav: {
        history,
        params: { season, gender, podium, activity, buyingSession }
      },
      assortment: { setSelectedTab }
    } = stores

    if (view === Views.Kpi) {
      setSelectedTab(0)
    }

    history.location.pathname.includes('activity')
      ? history.push({
          pathname: `/collections/season/${season}/gender/${gender}/buyingSession/${buyingSession}/podium/${podium}/activity/${activity}/views/${view}`,
          search: history.location.search
        })
      : history.push({
          pathname: `/collections/season/${season}/gender/${gender}/podium/${podium}/views/${view}`,
          search: history.location.search
        })
  }

  @action resetProductSelectionOnMouseDown = refNode => event => {
    if (event.target === refNode.current) {
      this.resetSelectedProducts()
    }
  }

  @action
  handelChannelPopOverClick = event => {
    event.preventDefault()
    event.stopPropagation()
    this.popOveranchorEl = event.currentTarget
  }

  @action
  handelChannelPopOverClose = () => {
    this.popOveranchorEl = null
  }

  /**
   * Bulk Add/Remove channel from all buying session products except core products
   */
  @action bulkAddRemoveChannelFromBuyingSesionProducts = async () => {
    const {
      nav: {
        params: { buyingSession }
      }
    } = stores
    this.bulkAddRemoveChannelFromProductInProgress = true
    await apolloClient.mutate({
      mutation: this.isBulkAddSet
        ? BULK_ADD_CHANNEL_TO_BUYING_SESSION_PRODUCTS
        : BULK_REMOVE_CHANNEL_FROM_BUYING_SESSION_PRODUCTS,
      variables: {
        buyingSessionId: buyingSession
      }
    })
    this.bulkAddRemoveChannelFromProductInProgress = false
    this.toggleChannelBulkAddRemoveConfitmationDialog()
  }

  @action
  toggleChannelBulkAddRemoveConfitmationDialog = (value = null) => {
    this.openChannelBulkAddRemoveConfirmationDialog =
      !this.openChannelBulkAddRemoveConfirmationDialog
    if (value) {
      if (value === CHANNEL_OPERATION_OPTIONS[0].value) {
        this.bulkAddDeleteConfirmationMessage =
          strings.bulkAddChannelMessageForPreBuyingSession
        this.confirmationTitle = strings.addChannel
        this.isBulkAddSet = true
      } else {
        this.confirmationTitle = strings.removeChannel
        this.bulkAddDeleteConfirmationMessage =
          strings.bulkRemoveChannelMessageForPreBuyingSession
        this.isBulkAddSet = false
      }
    }
  }

  /**
   * isProductRestrictedInZone
   * Returns true if product zone exclusion
   * matches with the user's current zones. Useful
   * to indicate inaccessible product to RM user
   */
  isProductRestrictedInZone = (product): boolean => {
    const user = storage.getItem<User>('user')
    const zones = user.membership && user.membership.zones
    return (
      zones &&
      !!zones.find(
        zone =>
          product.restrictedZones &&
          !!product.restrictedZones.find(restrictedZone => restrictedZone.id === zone.id)
      )
    )
  }

  shouldHighlightGlobalQty = (zoneWiseKPIData: Array<ProductKPIsByZone>): boolean => {
    const zoneRecords = filter(zoneWiseKPIData, record => record.type === strings.zone)
    const globalRecord = find(zoneWiseKPIData, data => data.type === strings.global)
    const globalQty = globalRecord ? globalRecord.betQty || 0 : 0

    let totalZoneBetQuantity = zoneRecords.reduce((acc, curr) => {
      acc += curr.betQty || 0
      return acc
    }, 0)
    let highlightGlobalQty = zoneRecords.length
      ? totalZoneBetQuantity !== globalQty
      : false
    return highlightGlobalQty
  }
  // FIXME: Move this to store
  getProcessedBuyingSessionProductsForListView = memoize(
    (
      buyingSessionProducts,
      permissions: IPermissions,
      betBuyData,
      rangePlanData,
      selectedAssortmentTab
    ) => {
      const {
        product: { getProductStatus, markUpList },
        site: { isGlobalMerchandiserOrAdmin },
        assortment: { getRetailPrice },
        sidebar: { isRegionalMerchandiser },
        listView: { getCollectionBuyMetricsForSort }
      } = stores

      const isGMOrAdmin = !!isGlobalMerchandiserOrAdmin()
      const betPermissions = getPermissionsByEntityAndRole(QuantityType.BET.toLowerCase())
      const buyPermissions = getPermissionsByEntityAndRole(QuantityType.BUY.toLowerCase())

      const products = map(buyingSessionProducts, productNode => {
        const { node: buyingSessionProduct } = productNode

        let {
          productStatus,
          active,
          description,
          image,
          channels,
          freeTags,
          clusterStatuses,
          salesPeriod,
          restrictedZones,
          retailPrice,
          zoneRetailPrice,
          storeCount
        } = getProductDetails(buyingSessionProduct, false, null)

        const { bsIsClosedOrFrozen, bsIsClosed } = getBuyingSessionClosedStatus(
          buyingSessionProduct.buyingSession
        )

        const canEditTags = permissions.canEdit && !bsIsClosedOrFrozen
        const priceListValue = isGMOrAdmin ? retailPrice : zoneRetailPrice
        const orderedRestrictedZones = sortListUsingOrderList(
          restrictedZones,
          ['name'],
          getZoneOrder()
        )
        const zoneWiseData =
          selectedAssortmentTab === 1
            ? getCollectionBetData(
                betBuyData,
                buyingSessionProduct,
                markUpList,
                rangePlanData
              )
            : getCollectionBuyData(
                betBuyData,
                buyingSessionProduct,
                markUpList,
                rangePlanData
              )

        const canEditCmCluster =
          isGlobalMerchandiserOrAdmin() &&
          !bsIsClosedOrFrozen &&
          productStatus !== strings.coreStatus

        // Highlight global BET qty?
        let highlightGlobalQty = false
        if (selectedAssortmentTab === 1) {
          highlightGlobalQty = this.shouldHighlightGlobalQty(zoneWiseData)
        }

        const buySortMetric = getCollectionBuyMetricsForSort(zoneWiseData, isGMOrAdmin)
        const listViewProduct: ListViewProduct = {
          id: buyingSessionProduct.id,
          buyingSessionProductId: buyingSessionProduct.id,
          assortmentProductId: null,
          hasGlobalRow: isGMOrAdmin,
          isActive: active,
          highlightGlobalQty,
          buyingSessionProduct,
          canEditKPIs:
            (selectedAssortmentTab === 1 &&
              betPermissions &&
              betPermissions.canEdit &&
              !bsIsClosed &&
              active) ||
            (selectedAssortmentTab === 2 &&
              buyPermissions &&
              buyPermissions.canEdit &&
              !bsIsClosedOrFrozen &&
              active),
          canEditOrderQuantity:
            (selectedAssortmentTab === 1 &&
              betPermissions &&
              betPermissions.canEdit &&
              !bsIsClosed &&
              active) ||
            (selectedAssortmentTab === 2 &&
              buyPermissions &&
              buyPermissions.canEdit &&
              !bsIsClosedOrFrozen),
          [ListViewFieldMapping.ProductInfo]: {
            status: getProductStatus(productStatus, active),
            description,
            image: getImageUrl(image)
          },
          [ListViewFieldMapping.ProductAttributes]: map(
            LIST_VIEW_PRODUCT_ATTRIBUTES,
            attributeName =>
              getAttributeNameValuePair(
                buyingSessionProduct.product.attributes,
                attributeName
              )
          ),
          // Since products cannot be removed from collection
          canDelete: false,
          [ListViewFieldMapping.Price]: getRetailPrice(priceListValue),
          [ListViewFieldMapping.TotalWeeks]: getNumberOfWeeksFromSalesPeriod(salesPeriod),
          [ListViewFieldMapping.TotalStores]: storeCount,
          [ListViewFieldMapping.CMClusters]:
            buyingSessionProduct && buyingSessionProduct.assortmentProducts
              ? getProductClustersWithStatus(
                  buyingSessionProduct.assortmentProducts,
                  clusterStatuses,
                  true
                )
              : [],
          [`canDelete${ListViewFieldMapping.CMClusters}`]: canEditCmCluster,
          [`canEdit${ListViewFieldMapping.CMClusters}`]: canEditCmCluster,
          [ListViewFieldMapping.Channels]: getTagsMapForListView(channels),
          [`canDelete${ListViewFieldMapping.Channels}`]:
            canEditTags && productStatus !== strings.coreStatus,
          [`canEdit${ListViewFieldMapping.Channels}`]: false,
          [ListViewFieldMapping.FreeTags]: getTagsMapForListView(freeTags),
          [`canDelete${ListViewFieldMapping.FreeTags}`]: canEditTags,
          [`canEdit${ListViewFieldMapping.FreeTags}`]: false,
          [ListViewFieldMapping.SalesPeriod]: salesPeriod
            ? [
                {
                  id: salesPeriod.id,
                  title: salesPeriod.name,
                  tooltip: getSalesPeriodStartAndEndDate(salesPeriod)
                }
              ]
            : [],
          [`canDelete${ListViewFieldMapping.SalesPeriod}`]: canEditTags,
          [`canEdit${ListViewFieldMapping.SalesPeriod}`]: false,
          [ListViewFieldMapping.ExcludedZones]: getTagsMapForListView(
            orderedRestrictedZones,
            true
          ),
          [`canDelete${ListViewFieldMapping.ExcludedZones}`]:
            canEditTags && productStatus !== strings.coreStatus,
          [`canEdit${ListViewFieldMapping.ExcludedZones}`]: false,
          [ListViewFieldMapping.zoneWiseData]: zoneWiseData,
          isRestricted:
            isRegionalMerchandiser() &&
            this.isProductRestrictedInZone(buyingSessionProduct),
          ...buySortMetric
        }
        return listViewProduct
      }) as Array<ListViewProduct>
      return products
    }
  )

  /**
   * Method for processing product data so that it can rendered in counter offer tab of list view
   */
  getProcessedBuyingSessionsProductsForCounterOffer = memoize(
    (buyingSessionProducts, zoneCounterOffer) => {
      const {
        product: { getProductStatus },
        assortment: { getBuyerClusters },
        site: { isGlobalMerchandiserOrAdmin }
      } = stores

      return map(buyingSessionProducts, productNode => {
        const { node: buyingSessionProduct } = productNode
        let {
          retailPrice: productRetailPrice,
          buy,
          active,
          zoneAssortmentProducts,
          productStatus,
          description,
          image,
          clusterStatuses
        } = getProductDetails(buyingSessionProduct, false, null)
        const buyerClusterList = getBuyerClusters(
          zoneAssortmentProducts,
          zoneCounterOffer
        )

        let buyingSession = buyingSessionProduct.buyingSession
        let bsIsClosed = buyingSession ? buyingSession.status === constants.CLOSED : false

        const canEditCmCluster =
          isGlobalMerchandiserOrAdmin() &&
          !bsIsClosed &&
          productStatus !== strings.coreStatus

        const counterOfferProduct = {
          id: buyingSessionProduct.id,
          buyingSessionProductId: buyingSessionProduct.id,
          assortmentProductId: null,
          isActive: active,
          buyingSessionProduct,
          [strings.sortByBuyQty]: buy ? buy.quantity : 0,
          [strings.sortByBuyValue]:
            buy && productRetailPrice
              ? parseFloat((buy.quantity * productRetailPrice.price).toFixed(2))
              : 0,
          [ListViewFieldMapping.ProductInfo]: {
            status: getProductStatus(productStatus, active),
            description,
            image: getImageUrl(image)
          },
          [ListViewFieldMapping.ProductAttributes]: map(
            LIST_VIEW_PRODUCT_ATTRIBUTES,
            attributeName =>
              getAttributeNameValuePair(
                buyingSessionProduct.product.attributes,
                attributeName
              )
          ),
          [ListViewFieldMapping.CMClusters]:
            buyingSessionProduct && buyingSessionProduct.assortmentProducts
              ? getProductClustersWithStatus(
                  buyingSessionProduct.assortmentProducts,
                  clusterStatuses,
                  true
                )
              : [],
          [`canDelete${ListViewFieldMapping.CMClusters}`]: canEditCmCluster,
          [`canEdit${ListViewFieldMapping.CMClusters}`]: canEditCmCluster,
          ...buyerClusterList
        }

        return counterOfferProduct
      })
    }
  )
}
