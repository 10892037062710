import { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions'
import { useMutation } from '@apollo/client/react/hooks/useMutation'
import { useQuery } from '@apollo/client/react/hooks/useQuery'
import {
  BULK_UPDATE_AVAILABLE_FLAG_FOR_CLIENTS,
  GET_BUYING_GROUP_WITH_CLIENTS,
  UPDATE_AVAILABLE_FLAG_FOR_CLIENT
} from '.'

export const useBuyingSessionGroupClients = (
  buyingSessionGroupId: string,
  fetchPolicy: WatchQueryFetchPolicy = 'cache-first'
) => {
  return useQuery(GET_BUYING_GROUP_WITH_CLIENTS, {
    skip: !buyingSessionGroupId,
    variables: {
      id: buyingSessionGroupId
    },
    fetchPolicy
  })
}

export const useUpdateAvailableFlagForClient = () => {
  const [ref] = useMutation(UPDATE_AVAILABLE_FLAG_FOR_CLIENT)
  return ref
}

export const useBulkUpdateAvailableFlagForClients = () => {
  const [ref] = useMutation(BULK_UPDATE_AVAILABLE_FLAG_FOR_CLIENTS)
  return ref
}
