import Typography from '@components/std-components/Typography'
import { SAAddComment, SAComment } from '..'
import {
  useAddBuyingSessionProductComment,
  useDeleteBuyingSessionProductComment,
  useUpdateBuyingSessionProductComment
} from '@modules/wholesale/productDetail/graphql/hooks'
import { stores, strings } from '@stores'
import { useObserver } from 'mobx-react-lite'
import React from 'react'
import { TypoGraphy } from '../SAProductDetailUIService'
import { useSAProductDetailsContext } from '../../../hook/SAProductDetails-Hook'
import * as css from './SAComment.css'

interface ISAComment {
  canEditComment?: boolean
  currentUser?: any
  showAddComment?: boolean
  typographyVariant?: TypoGraphy
}

export const SACommentWrapper: React.FC<ISAComment> = props => {
  return useObserver(function useHook() {
    const { canEditComment, currentUser, showAddComment, typographyVariant } = props
    const { labelTypography, labelColor } = typographyVariant
    const {
      productDetail: {
        handleCommentTextChange,
        commentText,
        addBuyingSessionProductComment,
        updateBuyingSessionProductComment,
        deleteBuyingSessionProductComment,
        mutations,
        handleEditComment,
        selectedCommentId,
        isSaveCommentInProgress,
        editedCommentText
      }
      // nav: {
      //   queryParams: { bspId }
      // }
    } = stores
    mutations.addBuyingSessionProductComment = useAddBuyingSessionProductComment() as any
    mutations.updateBuyingSessionProductComment =
      useUpdateBuyingSessionProductComment() as any
    mutations.deleteBuyingSessionProductComment =
      useDeleteBuyingSessionProductComment() as any
    // const { loading, error, data } = useBuyingSessionProductComments(bspId)

    // const comments =
    //   data?.getVIPBuyingSessionProduct?.DocumentsAndComments?.Comments ?? []

    const {
      saProductDetailsStore: { product }
    } = useSAProductDetailsContext()
    return (
      <div className={css.commentContainer}>
        <Typography variant={labelTypography || 'h2'} color={labelColor}>
          {strings.comments}
        </Typography>
        <SAComment
          comments={product?.comments}
          currentUser={currentUser}
          handleEditComment={handleEditComment}
          selectedCommentId={selectedCommentId}
          onChange={handleCommentTextChange}
          handleSubmit={updateBuyingSessionProductComment}
          onDelete={deleteBuyingSessionProductComment}
          canEditComment={canEditComment}
          commentText={editedCommentText}
          showAddComment={showAddComment}
          typographyVariant={typographyVariant}
        />
        {showAddComment && (
          <SAAddComment
            canEditComment={canEditComment}
            onChange={handleCommentTextChange}
            handleSubmit={addBuyingSessionProductComment}
            commentText={commentText}
            isSaveCommentInProgress={isSaveCommentInProgress}
          />
        )}
      </div>
    )
  })
}

SACommentWrapper.defaultProps = {
  canEditComment: false,
  showAddComment: false
}
