/*
 * @Author: Raeesaa Metkari
 * @Date: 2020-07-24 15:23:36
 * @Last Modified by: Raeesaa Metkari
 * @Last Modified time: 2020-07-29 19:22:07
 */
import forEach from 'lodash/forEach'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import omit from 'lodash/omit'
import orderBy from 'lodash/orderBy'
import { CriteriaOperationType } from '@models'
import { config } from '@config'
import { VIPProduct } from '@modules/common/models/interfaces/VIPProduct'
import { convertToCurrencyLocaleString } from '@services/assortmentService'
import { usePricesWithPriceList } from '@modules/wholesale/buyingSessionGroupProductWrapper/graphql/hooks'
import { VIPBuyingSessionProduct } from './productService'

class WholesalePriceService {
  getUniqueArticlesForBuyingSessionProducts = (
    buyingSessionProducts: Array<VIPBuyingSessionProduct>
  ) => {
    let uniqueArticles = []
    forEach(buyingSessionProducts, (bsp: VIPBuyingSessionProduct) => {
      let seasonalProduct: VIPProduct = bsp?.Original
      let article = seasonalProduct?.Style?.Article
      if (!includes(uniqueArticles, article)) {
        uniqueArticles.push(article)
      }
    })

    return uniqueArticles
  }

  getVariableForFetchingPrices = (
    priceGroupMap,
    buyingSessionGroupDate,
    skipArticleFilter,
    articles
  ) => {
    const centralSrpRefPriceListGroup = config.centralRetailRefPriceGroup
    return {
      retailPriceGroup:
        !isEmpty(priceGroupMap) && priceGroupMap?.retailPriceGroup
          ? priceGroupMap.retailPriceGroup
          : config.centralRetailPriceListGroup,
      // for prices in zone/cleint assortment try to get price type from client
      retailPriceType: config.centralRetailPriceListType,
      srpPriceGroup:
        !isEmpty(priceGroupMap) && priceGroupMap?.retailPriceGroup
          ? priceGroupMap.retailPriceGroup
          : config.centralSRPPriceListGroup,
      srpPriceType: config.centralSRPPriceListType,
      whlPriceGroup:
        !isEmpty(priceGroupMap) && priceGroupMap?.wholesalePriceGroup
          ? priceGroupMap.wholesalePriceGroup
          : config.centralWholesalePriceListGroup,
      whlPriceType: config.centralWholesalePriceListType,
      buyingSessionGroupDate: buyingSessionGroupDate,
      articlesOrInput: skipArticleFilter
        ? undefined
        : map(articles, article => ({
            Code: {
              operation: CriteriaOperationType.EQ,
              value: article
            }
          })),
      retailRefPriceGroup:
        !isEmpty(priceGroupMap) && priceGroupMap?.retailRefPriceGroup
          ? priceGroupMap.retailRefPriceGroup
          : centralSrpRefPriceListGroup,
      retailRefPriceType: config.retailRefPriceType
    }
  }

  sortPriceListValuesByExtId = priceListValues => {
    return orderBy(priceListValues || [], priceValue => priceValue?.VIPExternalId, 'desc')
  }

  getFormattedPrice = (value, currency) => {
    return value !== null && value !== undefined
      ? convertToCurrencyLocaleString(currency, value)
      : 'NA'
  }
}

export const usePricesForBuyingSessionProductsDetails = (
  products: Array<VIPBuyingSessionProduct>,
  buyingSessionGroupDate: number,
  priceGroupMap: {
    retailPriceGroup: string
    wholesalePriceGroup: string
  },
  skip: boolean = false,
  productDetails?
) => {
  const articles =
    wholesalePriceService.getUniqueArticlesForBuyingSessionProducts(products)

  const variables = wholesalePriceService.getVariableForFetchingPrices(
    priceGroupMap,
    buyingSessionGroupDate,
    false,
    articles
  )

  return usePricesWithPriceList(
    productDetails
      ? omit(variables, ['srpPriceGroup', 'whlPriceGroup'])
      : (variables as any),
    !articles.length || skip
  )
}

export const usePricesForBuyingSessionProducts = (
  products: Array<VIPBuyingSessionProduct>,
  buyingSessionGroupDate: number,
  priceGroupMap: {
    retailPriceGroup: string
    wholesalePriceGroup: string
  },
  skip: boolean = false,
  skipArticleFilter: boolean = false
) => {
  const articles = skipArticleFilter
    ? []
    : wholesalePriceService.getUniqueArticlesForBuyingSessionProducts(products)

  const variables = wholesalePriceService.getVariableForFetchingPrices(
    priceGroupMap,
    buyingSessionGroupDate,
    skipArticleFilter,
    articles
  )

  return usePricesWithPriceList(
    variables,
    (!skipArticleFilter && !articles.length) || skip
  )
}

export const wholesalePriceService = new WholesalePriceService()
