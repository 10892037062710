import { OrderType } from '@modules/common/models/enums/OrderManagement'
import { stores } from '@stores'
import { action, observable } from 'mobx'

import { OrderService } from '@modules/order/services/orderService'
import { AddProductToOrderService } from '@modules/order/services/addProductToOrder'
import { SAProductDetailsService } from '../service/SAProductDetailsService'
import isNil from 'lodash/isNil'

export class SAProductDetailsDataStore {
  @observable buyingSessionProduct: any = {}
  @observable productBasicInformationLoading = true
  @observable productExtraInformationLoading = true
  @observable productAddedOrRemoved = false

  @action
  getInitialProductInformation = async bspId => {
    const {
      nav: {
        queryParams: { clientId, orderType, orderId }
      }
    } = stores
    const buyingSessionProduct =
      await SAProductDetailsService.getInitialProductInformation(
        bspId,
        clientId,
        orderType,
        orderId
      )
    this.buyingSessionProduct = buyingSessionProduct
    this.productBasicInformationLoading = false
  }

  @action
  getExtraProductInformation = async bspId => {
    const buyingSessionProduct = await SAProductDetailsService.getExtraProductInformation(
      bspId
    )
    this.buyingSessionProduct = { ...this.buyingSessionProduct, ...buyingSessionProduct }
    this.productExtraInformationLoading = false
  }

  @action
  clearStore = () => {
    this.buyingSessionProduct = {}
    this.productBasicInformationLoading = true
    this.productExtraInformationLoading = true
  }

  @action
  toggleProductFavoriteStatusLocally = () => {
    this.buyingSessionProduct.isFavorite = !this.buyingSessionProduct?.isFavorite
  }

  @action
  toggleOrderProductStatusStatusLocally = () => {
    this.buyingSessionProduct.isOrderProductExist =
      !this.buyingSessionProduct?.isOrderProductExist
  }

  toggleProductFavoriteStatus = async () => {
    this.toggleProductFavoriteStatusLocally()
    try {
      await SAProductDetailsService.toggleProductFavoriteStatus(
        this.buyingSessionProduct?.id
      )
    } catch (e) {
      this.toggleProductFavoriteStatusLocally()
    }
  }

  @action
  updateRating = async (ratingId: string, value: string, ratingTypeId: string) => {
    const {
      nav: {
        queryParams: { bspId }
      }
    } = stores
    try {
      this.updateRatingLocally(ratingId, value, ratingTypeId)
      const ratingData = await SAProductDetailsService.updateProductRating(
        bspId,
        ratingId,
        value,
        ratingTypeId
      )
      this.updateRatingLocally(
        ratingData?.ratingWithTypes[0]?.id,
        ratingData?.ratingWithTypes[0]?.value,
        ratingData?.ratingWithTypes[0]?.ratingTypeId,
        ratingData.averageRating
      )
    } catch (error) {
      this.updateRatingLocally(ratingId, value, ratingTypeId)
    }
  }

  @action
  updateRatingLocally = (
    ratingId: string,
    value: string,
    ratingTypeId: string,
    avgRating?: number
  ) => {
    const type = this.buyingSessionProduct?.ratings?.ratingWithTypes?.find(
      type => type.ratingType.id === ratingTypeId
    )
    if (type) {
      type.value = value
      type.id = ratingId
      if (!isNil(avgRating)) this.buyingSessionProduct.ratings.averageRating = avgRating
    }
  }

  // ** Order Product **
  @action
  removeProductToOrder = bspId => {
    const {
      nav: {
        queryParams: { orderId, orderType }
      }
    } = stores
    this.toggleOrderProductStatusStatusLocally()
    try {
      const orderService = new OrderService()
      const response = orderService.removeProductToOrder(
        orderId,
        bspId,
        orderType as OrderType
      )
      this.productAddedOrRemoved = true
      return response
    } catch (e) {
      this.toggleOrderProductStatusStatusLocally()
    }
  }

  @action
  addProductToOrder = async bspId => {
    const {
      nav: {
        queryParams: { orderId, orderType }
      }
    } = stores

    this.toggleOrderProductStatusStatusLocally()

    try {
      const addProductToOrder = new AddProductToOrderService()
      await addProductToOrder.addProductToOrder(orderId, bspId, orderType)
      this.productAddedOrRemoved = true
    } catch (error) {
      this.toggleOrderProductStatusStatusLocally()
    }
  }
}
