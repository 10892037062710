import { MutationFunctionOptions } from '@apollo/client/react/types/types'
import { CentricNodeType } from '@modules/common/models/enums/CentricNodeType'
import { getUTCRepresentation, isDistinct } from '@services/commonServices'
import { stores } from '@stores'
import { ExecutionResult } from 'graphql/execution'
import cloneDeep from 'lodash/cloneDeep'
import { action, computed, observable } from 'mobx'

import { GET_DROP, IDrop } from '../graphql'

export class DropSettingStore {
  NEW_ROW_ID = 'newRow'

  @observable dropName = ''
  @observable dropId = null
  @observable dropStartDate = null
  @observable dropEndDate = null
  @observable dropData = []
  @observable isNameUnique = false
  @observable dropsBeingSaved = new Map()
  @observable deletedDrop = new Map()

  mutations: {
    createDrop?: (
      options?: MutationFunctionOptions<any, IDrop>
    ) => Promise<void | ExecutionResult>
    updateDrop?: (
      options?: MutationFunctionOptions<any, IDrop>
    ) => Promise<void | ExecutionResult>
    deleteDrop?: (
      options?: MutationFunctionOptions<any, IDrop>
    ) => Promise<void | ExecutionResult>
  } = {}

  @action addNewRecordToDropData = () => {
    const {
      deliveryWindowSetting: { getMinDate }
    } = stores
    this.resetdata()
    this.dropData.push({
      Id: this.NEW_ROW_ID
    })
    this.dropId = this.NEW_ROW_ID
    this.dropName = ''
    this.handleDropStartDateChange(new Date())
    this.handleDropEndDateChange(getMinDate())
  }

  @action setSelectedDrop = row => {
    this.dropData = []
    this.dropId = row.Id
    this.dropName = row.Name
    this.dropStartDate = row.Start
    this.dropEndDate = row.End
  }

  @action handleDropNameChange = existingDrops => e =>
    Object.assign(this, {
      dropName: e.target.value,
      isNameUnique: isDistinct(e, existingDrops)
    })

  @action handleDropStartDateChange = date => {
    this.dropStartDate = getUTCRepresentation(date)
  }

  @action handleDropEndDateChange = date => {
    this.dropEndDate = getUTCRepresentation(date)
  }

  getDropWhereInput = Id => {
    return {
      dropId: Id
    }
  }

  @computed get dropWhereInput() {
    const {
      nav: { buyingSessionGroupId }
    } = stores
    return {
      buyingSessionGroupId: buyingSessionGroupId
    }
  }

  @computed get createDropWhereInput() {
    const [
      {
        nav: { buyingSessionGroupId }
      },
      { dropName, dropStartDate, dropEndDate }
    ] = [stores, this]
    return {
      buyingSessionGroupId: buyingSessionGroupId,
      name: dropName.trim(),
      startDate: dropStartDate,
      endDate: dropEndDate
    }
  }

  @computed get updateDropWhereInput() {
    const { dropStartDate, dropEndDate, dropId } = this
    return {
      dropId: dropId,
      startDate: dropStartDate,
      endDate: dropEndDate
    }
  }

  @action
  createDrop = async () => {
    const [
      {
        nav: { buyingSessionGroupId }
      },
      { createDropWhereInput, dropWhereInput }
    ] = [stores, this]
    this.dropsBeingSaved[this.dropId || this.NEW_ROW_ID] = true
    if (buyingSessionGroupId) {
      try {
        await this.mutations.createDrop({
          variables: createDropWhereInput,
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: GET_DROP,
              variables: dropWhereInput
            }
          ]
        })
      } finally {
        this.resetdata()
      }
    }
  }

  @action
  updateDrop = async () => {
    const [
      {
        nav: { buyingSessionGroupId }
      },
      {
        updateDropWhereInput,
        dropWhereInput,
        dropId,
        dropName,
        dropStartDate,
        dropEndDate
      }
    ] = [stores, this]
    this.dropsBeingSaved[dropId] = true
    if (buyingSessionGroupId) {
      try {
        await this.mutations.updateDrop({
          variables: updateDropWhereInput,
          update: cache => {
            const { findVIPSalesPeriod } = cloneDeep(
              cache.readQuery({
                query: GET_DROP,
                variables: dropWhereInput
              })
            )
            if (findVIPSalesPeriod) {
              const Items = findVIPSalesPeriod?.Items ?? []
              const updatedDropIndex = Items.findIndex(({ Id }) => Id === dropId)
              const updateDrop = {
                Id: dropId,
                Name: dropName,
                Start: dropStartDate,
                End: dropEndDate,
                __typename: CentricNodeType.Drop
              }
              findVIPSalesPeriod.Items[updatedDropIndex] = updateDrop
              cache.writeQuery({
                query: GET_DROP,
                data: { findVIPSalesPeriod }
              })
            }
          }
        })
      } finally {
        this.resetdata()
      }
    }
  }

  @action
  deleteDrop = async Id => {
    const [
      {
        nav: { buyingSessionGroupId }
      },
      { dropWhereInput, getDropWhereInput }
    ] = [stores, this]
    if (Id === this.NEW_ROW_ID) {
      this.resetdata()
    } else {
      this.deletedDrop.set(Id, true)
      if (buyingSessionGroupId) {
        try {
          await this.mutations.deleteDrop({
            variables: getDropWhereInput(Id),
            awaitRefetchQueries: true,
            refetchQueries: [
              {
                query: GET_DROP,
                variables: dropWhereInput
              }
            ]
          })
        } finally {
          this.deletedDrop.delete(Id)
          this.resetdata()
        }
      }
    }
  }

  disableActionButtons = rowId =>
    this.dropsBeingSaved[rowId] || this.isDeleteInProgress(rowId)

  isDeleteInProgress = Id => {
    const drop = this.deletedDrop.get(Id)
    return drop ? true : false
  }

  resetdata = () => {
    this.dropId = null
    this.dropName = ''
    this.dropStartDate = null
    this.dropEndDate = null
    this.dropData = []
    this.dropsBeingSaved = new Map()
  }
}

export const dropSetting = new DropSettingStore()
