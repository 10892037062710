// export * from './assortment/models'
// //export * from './createNewBuyingSession/models'
// export * from './buyingSession/models'
// export * from './regionalAssortmentView/models'

export interface SalesYear {
  id: any
  salesSeasons: any
}

export interface ProductAttribute {
  name: string
  intVal?: number
  strVal?: string
}

export interface OfferProduct {
  id: string
  product: {
    active: boolean
    id: string
    __typename: 'StyleColorway'
  }
  __typename: 'OfferProduct'
}

export interface ProductColorway {
  id: any
  offerProducts?: Array<OfferProduct>
}

export interface ProductStyle {
  id?: string
  theme?: {
    id?: string
    name?: string
  }
  collection?: {
    id: string
    name: string
    __typename: 'Collection'
  }
  colorways?: Array<ProductColorway>
}

export interface Product {
  name: string
  id: string
  attributes: Array<ProductAttribute>
  color?: {
    id?: string
    color?: {
      id?: string
      name?: string
      material?: { id?: string; name?: string }
    }
  }
  style?: ProductStyle
}

export interface UserRole {
  name: string
}

export interface Membership {
  id: string
  zones: Array<Zone>
}
export interface Zone {
  id: string
  name: string
}

export interface User {
  id: string
  roles: Array<UserRole>
  username: string
  firstName: string
  lastName: string
  membership: Membership
  client: string
}

export interface ChannelType {
  id: string
  name: string
}

export interface SelectedCollection {
  id: string
  name: string
  podiumId: string
}

export enum CriteriaOperationType {
  B0 = 'B0',
  B1 = 'B1',
  BA = 'BA',
  EQ = 'EQ',
  EX = 'EX',
  FE = 'FE',
  FL = 'FL',
  FS = 'FS',
  FT = 'FT',
  GE = 'GE',
  GT = 'GT',
  LE = 'LE',
  LT = 'LT',
  NE = 'NE',
  NL = 'NL',
  NN = 'NN',
  NX = 'NX',
  RE = 'RE',
  UE = 'UE',
  UL = 'UL',
  US = 'US',
  UT = 'UT',
  UNDEFINED = 'UNDEFINED'
}

export enum ATTR_FOR_APPLY_TO_ALL {
  POREF = 'poRef',
  NOTES = 'notes',
  DELIVERY_DATE = 'deliveryDate'
}
