/*
 * @Author: Raeesaa Metkari
 * @Date: 2020-01-21 18:10:54
 * @Last Modified by: Raeesaa Metkari
 * @Last Modified time: 2020-01-21 18:10:54
 */

export enum ZoneDisplayNames {
  CHINA = 'CHINA MAINLAND',
  APAC = 'HK-MC-TW'
}
