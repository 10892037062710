import { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions'
import { useQuery } from '@apollo/client/react/hooks/useQuery'
import {
  BuyingSessionAssortmentType,
  ChannelSubSection
} from '@modules/common/models/enums/BSGroupAndBSNav'
import { VIPChannel } from '@modules/common/models/enums/VIPChannel'
import { VIPClientType } from '@modules/common/models/enums/VIPClientType'
import { CriteriaOperationType } from '@modules/models'
import GET_CLIENT_SUMMARY from '@modules/wholesale/clientSettings/graphql/clientSummary'
import { wholesaleCollectionService } from '@services/wholesale/wholesaleCollectionService'
import { useMemo } from 'react'
import { WS_CLIENT_ASSORTMENT_BY_ID } from './clientAssortmentById'
import DOOR_SUMMARY from './doorSummary'
import { WS_BUYING_SESSION_PRODUCT_TO_ADD } from './getBuyingSessionProductsToadd'
import { WS_CLIENT_ASSORTMENT_PRODUCTS } from './getClientAssortmentProducts'
import { GET_CLIENT_ASSORTMENT_SUMMARY } from './getClientAssortmentProductsSummary'
import { WS_CLUSTER_ASSORTMENT_PRODUCTS } from './getClusterAssortmentProducts'
import { GET_CLUSTER_ASSORTMENT_SUMMARY } from './getClusterAssortmentProductsSummary'
import { WS_DOOR_ASSORTMENT_PRODUCTS } from './getDoorAssortmentProducts'
import { GET_DOOR_ASSORTMENT_SUMMARY } from './getDoorAssortmentProductsSummary'

const retailItemFilter = items => {
  return items.filter(item => {
    return item?.Original?.clusterAssortmentsProduct?.length
  }, [])
}

export const ASSORTMENT_PRODUCT_SUMMARY_QUERIES_BY_ASSORTMENT_TYPE = {
  [BuyingSessionAssortmentType.cluster]: {
    query: GET_CLUSTER_ASSORTMENT_SUMMARY,
    field: 'findVIPClusterAssortmentProduct'
  },
  [BuyingSessionAssortmentType.client]: {
    query: GET_CLIENT_ASSORTMENT_SUMMARY,
    field: 'findVIPClientAssortmentProduct'
  },
  [BuyingSessionAssortmentType.door]: {
    query: GET_DOOR_ASSORTMENT_SUMMARY,
    field: 'findVIPDoorAssortmentProduct',
    itemFilter: retailItemFilter
  }
}

export const useBuyingSessionAssortmentProductSummary = (
  buyingSessionAssortmentProductSummaryWhere,
  assortmentType,
  fetchPolicy: WatchQueryFetchPolicy = 'cache-first'
) => {
  const queryDetails =
    ASSORTMENT_PRODUCT_SUMMARY_QUERIES_BY_ASSORTMENT_TYPE[assortmentType]
  const { data, loading, refetch } = useQuery(queryDetails.query, {
    variables: {
      buyingSessionAssortmentProductSummaryWhere
    },
    skip: !buyingSessionAssortmentProductSummaryWhere,
    fetchPolicy
  })
  const itemFilter = queryDetails.itemFilter
  const queryItems = data?.[queryDetails.field]?.Items
  const items = useMemo(
    () => (itemFilter ? (queryItems?.length ? itemFilter(queryItems) : []) : queryItems),
    [queryItems, itemFilter]
  )
  return {
    items,
    loading,
    refetch
  }
}

const retailItemMapper = (items, assortmentId) => {
  return items
    .slice()
    .sort((prev, curr) => {
      if (prev.Original.Id === curr.Original.Id) {
        if (curr.Assortment.Id === assortmentId) {
          return 1
        }
        return -1
      }
      return 0
    })
    .reduce((acc, item, currentIndex) => {
      if (
        acc[acc.length - 1]?.Original?.Id !== item.Original.Id &&
        item.Assortment.Id === assortmentId
      ) {
        acc.push({ ...item, totalDoors: 1 })
      }
      if (items[currentIndex + 1]?.Original?.Id === item.Original.Id && acc.length) {
        acc[acc.length - 1].totalDoors += 1
      }
      return acc
    }, [])
}

const ASSORTMENT_PRODUCT_QUERIES = {
  [ChannelSubSection.Wholesale]: {
    [BuyingSessionAssortmentType.cluster]: {
      query: WS_CLUSTER_ASSORTMENT_PRODUCTS,
      field: 'AssortmentProduct',
      inputMapper: (assortmentId, { activity, childrenMeta, childrenMetaValues }) => {
        return {
          GIV_VIPAssortmentProduct_Assortment_ref: {
            VIPClusterAssortment: {
              Id: { operation: CriteriaOperationType.EQ, value: assortmentId }
            }
          },
          Original: {
            Original: wholesaleCollectionService.buildAttributeHierarchyQuery(
              activity,
              childrenMeta,
              childrenMetaValues
            )
          }
        }
      }
    },
    [BuyingSessionAssortmentType.client]: {
      query: WS_CLIENT_ASSORTMENT_PRODUCTS,
      field: 'AssortmentProduct',
      inputMapper: (assortmentId, { activity, childrenMeta, childrenMetaValues }) => {
        return {
          GIV_VIPAssortmentProduct_Assortment_ref: {
            VIPClientAssortment: {
              Id: { operation: CriteriaOperationType.EQ, value: assortmentId }
            }
          },
          Original: {
            Original: wholesaleCollectionService.buildAttributeHierarchyQuery(
              activity,
              childrenMeta,
              childrenMetaValues
            )
          }
        }
      }
    }
  },
  [ChannelSubSection.Retail]: {
    [BuyingSessionAssortmentType.cluster]: {
      query: WS_CLUSTER_ASSORTMENT_PRODUCTS,
      field: 'AssortmentProduct',
      inputMapper: (
        assortmentId,
        { activity, childrenMeta, childrenMetaValues },
        bsId
      ) => {
        return {
          GIV_VIPAssortmentProduct_Assortment_ref: {
            VIPClusterAssortment: {
              Cluster: {
                Channel: {
                  operation: CriteriaOperationType.EQ,
                  value: VIPChannel.Retail
                }
              }
            }
          },
          Original: {
            Original: wholesaleCollectionService.buildAttributeHierarchyQuery(
              activity,
              childrenMeta,
              childrenMetaValues
            ),
            __Parent__: {
              Id: {
                operation: CriteriaOperationType.EQ,
                value: bsId
              }
            }
          }
        }
      },
      itemMapper: retailItemMapper
    },
    [BuyingSessionAssortmentType.client]: {
      query: WS_CLIENT_ASSORTMENT_PRODUCTS,
      field: 'AssortmentProduct',
      inputMapper: (assortmentId, { activity, childrenMeta, childrenMetaValues }) => {
        return {
          GIV_VIPAssortmentProduct_Assortment_ref: {
            VIPClientAssortment: {
              Id: { operation: CriteriaOperationType.EQ, value: assortmentId }
            }
          },
          Original: {
            Original: wholesaleCollectionService.buildAttributeHierarchyQuery(
              activity,
              childrenMeta,
              childrenMetaValues
            )
          }
        }
      }
    },
    [BuyingSessionAssortmentType.door]: {
      query: WS_DOOR_ASSORTMENT_PRODUCTS,
      field: 'AssortmentProduct',
      inputMapper: (
        assortmentId,
        { activity, childrenMeta, childrenMetaValues },
        bsId
      ) => {
        return {
          __Parent__: {
            VIPDoorAssortment: {
              Door: {
                __Parent__: {
                  ClientType: {
                    operation: CriteriaOperationType.EQ,
                    value: VIPClientType.Internal
                  }
                }
              }
            }
          },
          Original: {
            Original: wholesaleCollectionService.buildAttributeHierarchyQuery(
              activity,
              childrenMeta,
              childrenMetaValues
            ),
            __Parent__: {
              Id: {
                operation: CriteriaOperationType.EQ,
                value: bsId
              }
            }
          }
        }
      },
      itemMapper: retailItemMapper
    }
  }
}

export const useAssortmentProduct = (
  subSection,
  assortmentType,
  assortmentId,
  bsId,
  hierarchyDetails
) => {
  const queryDetails = ASSORTMENT_PRODUCT_QUERIES[subSection][assortmentType]
  const { data, loading, refetch } = useQuery(queryDetails.query, {
    variables: {
      where: queryDetails.inputMapper(assortmentId, hierarchyDetails, bsId)
    },
    fetchPolicy: 'cache-and-network',
    skip: !(subSection && assortmentType)
  })
  const itemMapper = queryDetails.itemMapper
  const queryItems = data?.[queryDetails.field]?.Items
  const items = useMemo(
    () =>
      itemMapper
        ? queryItems?.length
          ? itemMapper(queryItems, assortmentId)
          : []
        : queryItems,
    [queryItems, itemMapper, assortmentId]
  )
  return {
    items,
    loading,
    refetch
  }
}

export const useBuyingSessionProductToAdd = (
  BSPToAddWhereQuery,
  fetchPolicy: WatchQueryFetchPolicy = 'cache-first'
) => {
  return useQuery(WS_BUYING_SESSION_PRODUCT_TO_ADD, {
    variables: {
      where: BSPToAddWhereQuery
    },
    fetchPolicy
  })
}

export const useClientAssortmentById = (
  clientAssortmentId,
  fetchPolicy: WatchQueryFetchPolicy = 'cache-first'
) => {
  return useQuery(WS_CLIENT_ASSORTMENT_BY_ID, {
    variables: {
      clientAssortmentId
    },
    fetchPolicy,
    skip: !clientAssortmentId
  })
}

const SUMMARY_QUERIES = {
  [BuyingSessionAssortmentType.cluster]: {
    query: GET_CLIENT_SUMMARY,
    field: 'getVIPClient'
  },
  [BuyingSessionAssortmentType.client]: {
    query: GET_CLIENT_SUMMARY,
    field: 'getVIPClient'
  },
  [BuyingSessionAssortmentType.door]: {
    query: DOOR_SUMMARY,
    field: 'getVIPDoor'
  }
}

export const useSummary = (id: string, assortmentType) => {
  const { data, loading } = useQuery(SUMMARY_QUERIES[assortmentType].query, {
    variables: {
      id
    },
    skip: !id
  })
  return {
    summary: data?.[SUMMARY_QUERIES[assortmentType].field],
    loading
  }
}
