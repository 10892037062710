/*
 * @Author: Raeesaa Metkari
 * @Date: 2019-08-20 12:12:19
 * @Last Modified by: Raeesaa Metkari
 * @Last Modified time: 2019-12-24 18:23:11
 */

import { useQuery } from '@apollo/client/react/hooks/useQuery'

import { GET_GENDERS } from '@modules/retail/buyingSession/graphql/getGenders'

import { GET_ACTIVITIES } from '@modules/retail/clusterSetup'
import { GET_STORE_WEIGHTS, GET_ZONES } from '.'

/**
 * Hook for querying zones
 */
export const useZones = () => {
  return useQuery(GET_ZONES, {
    fetchPolicy: 'cache-and-network'
  })
}

/**
 * Hook for querying activities
 */
export const useActivities = () => {
  return useQuery(GET_ACTIVITIES)
}

/**
 * Hook for querying genders
 */
export const useGenders = () => {
  return useQuery(GET_GENDERS)
}

/**
 * Hook for querying store weights for stores within a zone
 * @param {string} zoneId - Id of zone
 */
export const useStoreWeightsForZone = (zoneId: string, skip: boolean = false) => {
  return useQuery(GET_STORE_WEIGHTS, {
    variables: {
      zoneId
    },
    skip
  })
}
