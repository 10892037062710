import { DocumentNode } from '@apollo/client/core'
import { OperationVariables } from '@apollo/client/core'
import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client/react'
import { useEffect, useRef } from 'react'

export function compose(...funcs) {
  if (funcs.length === 0) {
    return arg => arg
  }

  if (funcs.length === 1) {
    return funcs[0]
  }

  return funcs.reduce(
    (a, b) =>
      (...args) =>
        a(b(...args))
  )
}

/**
 * When the variables inside options changes dynamically, the query is NOT rerunning as of react-apollo beta.5
 * See https://github.com/trojanowski/react-apollo-hooks/issues/83
 *
 * We add a useEffect here to refetch the query if the underlying variables change
 * @param query
 * @param options
 */
export function useQueryDynamic<TData = any, TVariables = OperationVariables>(
  query: DocumentNode,
  options?: QueryHookOptions<TData, TVariables>
): QueryResult<TData, TVariables> {
  const isFirstRun = useRef(true)
  const q = useQuery(query, options)

  useEffect(() => {
    if (!isFirstRun.current) {
      q.refetch()
    } else isFirstRun.current = false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(options?.variables)])

  return q
}
