import { RESTRICT_PRODUCT_BY_ZONE_TABS } from '@modules/collection/constants'
import { apolloClient, stores } from '@stores'
import cloneDeep from 'lodash/cloneDeep'
import findIndex from 'lodash/findIndex'
import { action, computed, observable } from 'mobx'
import { BULK_OPERATIONS_ON_BS_PRODUCTS } from '../graphql/bulkOperationsOnBuyingSessionProducts'
import { BUYING_SESSION_PRODUCT } from '@modules/retail/collection/graphql/buyingSessionProduct'

export class RestrictProductByZoneModalStore {
  @observable toggleRestrictProductByZoneModal = false
  @observable selectedTabForRestricProduct = 'Zone exclusion'
  // For Product Restrict by Zone bulk add functionality
  @observable restrictedZones = []
  @observable bulkAddClusters = []
  @observable bulkAddFreeTags = []
  @observable bulkAddSalesPeriod = ''
  @observable bulkAddChannel = []
  @observable coreProducts = []
  @observable retailChannelProducts = []
  @observable isHandleRestrictProductByZoneModalBulkAddInProgress = false

  @action
  addToCoreProductsList = productId => {
    const coreProducts = cloneDeep(this.coreProducts)
    coreProducts.push(productId)
    Object.assign(this, { coreProducts })
  }

  @action
  removeFromCoreProductsList = productId => {
    const coreProducts = cloneDeep(this.coreProducts)
    const index = coreProducts.indexOf(productId)
    coreProducts.splice(index, 1)
    Object.assign(this, { coreProducts })
  }

  @action
  addToRetailChannelProductsList = productId => {
    const retailChannelProducts = cloneDeep(this.retailChannelProducts)
    retailChannelProducts.push(productId)
    Object.assign(this, { retailChannelProducts })
  }

  @action
  removeFromRetailChannelProductsList = productId => {
    const retailChannelProducts = cloneDeep(this.retailChannelProducts)
    const index = retailChannelProducts.indexOf(productId)
    retailChannelProducts.splice(index, 1)
    Object.assign(this, { retailChannelProducts })
  }

  @action
  handleRestrictProductByZoneModalOpen = () => {
    Object.assign(this, { toggleRestrictProductByZoneModal: true })
  }
  @action
  handleRestrictProductByZoneModalClose = () => {
    this.changeSelectedTabForRestricProduct('')
    this.clearUserTagsSelection()
    Object.assign(this, { toggleRestrictProductByZoneModal: false })
  }

  @action
  addClusterStatus = (clusterId, statusId) => {
    const bulkAddClusters = cloneDeep(this.bulkAddClusters)
    const index = findIndex(bulkAddClusters, { id: clusterId })
    if (index > -1) {
      bulkAddClusters[index]['statusId'] = statusId
    }
    Object.assign(this, { bulkAddClusters })
  }

  @action
  clearUserTagsSelection = () => {
    const {
      collection: { resetSelectedProducts }
    } = stores
    resetSelectedProducts()
    Object.assign(this, {
      restrictedZones: [],
      bulkAddClusters: [],
      bulkAddFreeTags: [],
      bulkAddSalesPeriod: '',
      bulkAddChannel: [],
      coreProducts: [],
      retailChannelProducts: []
    })
  }

  @action removeTags = id => {
    switch (this.getchangeSelectedTabForRestricProduct) {
      case RESTRICT_PRODUCT_BY_ZONE_TABS[0]: {
        const restrictedZones = cloneDeep(this.restrictedZones)
        const index = restrictedZones.indexOf(id)
        if (restrictedZones.length) {
          restrictedZones.splice(index, 1)
        }
        Object.assign(this, { restrictedZones })
        break
      }
      case RESTRICT_PRODUCT_BY_ZONE_TABS[1]: {
        const bulkAddClusters = cloneDeep(this.bulkAddClusters)
        const index = bulkAddClusters.findIndex(
          bulkAddCluster => bulkAddCluster.id === id
        )
        if (bulkAddClusters.length) {
          bulkAddClusters.splice(index, 1)
        }
        Object.assign(this, { bulkAddClusters })
        break
      }
      case RESTRICT_PRODUCT_BY_ZONE_TABS[2]: {
        const bulkAddChannel = cloneDeep(this.bulkAddChannel)
        const index = bulkAddChannel.indexOf(id)
        if (bulkAddChannel.length) {
          bulkAddChannel.splice(index, 1)
        }
        Object.assign(this, { bulkAddChannel })
        break
      }
      case RESTRICT_PRODUCT_BY_ZONE_TABS[3]: {
        Object.assign(this, { bulkAddSalesPeriod: '' })
        break
      }
      case RESTRICT_PRODUCT_BY_ZONE_TABS[4]: {
        const bulkAddFreeTags = cloneDeep(this.bulkAddFreeTags)
        const index = bulkAddFreeTags.indexOf(id)
        if (bulkAddFreeTags.length) {
          bulkAddFreeTags.splice(index, 1)
        }
        Object.assign(this, { bulkAddFreeTags })
        break
      }
      default: {
        const restrictedZones = cloneDeep(this.restrictedZones)
        const index = restrictedZones.indexOf(id)
        if (restrictedZones.length) {
          restrictedZones.splice(index, 1)
        }
        Object.assign(this, { restrictedZones })
        break
      }
    }
  }

  @action preparePayloadToRestricProduct = (type, id) => {
    switch (type) {
      case RESTRICT_PRODUCT_BY_ZONE_TABS[0]: {
        const restrictedZones = cloneDeep(this.restrictedZones)
        if (restrictedZones.indexOf(id) === -1) {
          restrictedZones.push(id)
        }
        Object.assign(this, { restrictedZones })
        break
      }
      case RESTRICT_PRODUCT_BY_ZONE_TABS[1]: {
        const bulkAddClusters = cloneDeep(this.bulkAddClusters)
        if (!bulkAddClusters.find(bulkAddCluster => bulkAddCluster.id === id)) {
          bulkAddClusters.push({ id: id })
        }
        Object.assign(this, { bulkAddClusters })
        break
      }
      case RESTRICT_PRODUCT_BY_ZONE_TABS[2]: {
        const bulkAddChannel = cloneDeep(this.bulkAddChannel)
        if (bulkAddChannel.indexOf(id) === -1) {
          bulkAddChannel.push(id)
        }
        Object.assign(this, { bulkAddChannel })
        break
      }
      case RESTRICT_PRODUCT_BY_ZONE_TABS[3]: {
        Object.assign(this, { bulkAddSalesPeriod: id })
        break
      }
      case RESTRICT_PRODUCT_BY_ZONE_TABS[4]: {
        const bulkAddFreeTags = cloneDeep(this.bulkAddFreeTags)
        if (bulkAddFreeTags.indexOf(id) === -1) {
          bulkAddFreeTags.push(id)
        }
        Object.assign(this, { bulkAddFreeTags })
        break
      }
      default: {
        const restrictedZones = cloneDeep(this.restrictedZones)
        if (restrictedZones.indexOf(id) === -1) {
          restrictedZones.push(id)
        }
        Object.assign(this, { restrictedZones })
      }
    }
  }

  @action changeSelectedTabForRestricProduct = value => {
    Object.assign(this, { selectedTabForRestricProduct: value })
  }

  @computed get getchangeSelectedTabForRestricProduct() {
    return this.selectedTabForRestricProduct
  }
  @action toggleHandleRestrictProductByZoneModalBulkAddProgressStatus = () => {
    this.isHandleRestrictProductByZoneModalBulkAddInProgress =
      !this.isHandleRestrictProductByZoneModalBulkAddInProgress
  }
  /**
   * Adds product to cluster assortment
   */
  @action handleRestrictProductByZoneModalBulkAdd = async () => {
    const {
      collection: { selectedProducts },
      restrictProductByZoneModal: {
        restrictedZones,
        bulkAddClusters,
        bulkAddChannel,
        bulkAddFreeTags,
        bulkAddSalesPeriod
      }
    } = stores
    this.toggleHandleRestrictProductByZoneModalBulkAddProgressStatus()
    const buyingSessionProductIds = selectedProducts.map(product => product.productId)
    const refetchQueries = selectedProducts.map(product => ({
      query: BUYING_SESSION_PRODUCT,
      variables: {
        id: product.productId,
        includeUserFavourite: false,
        zoneIdWhere: null
      }
    }))
    try {
      await apolloClient.mutate({
        mutation: BULK_OPERATIONS_ON_BS_PRODUCTS,
        variables: {
          buyingSessionProductIds,
          ...(restrictedZones.length && { restrictedZones }),
          ...(bulkAddClusters.length && { clusters: bulkAddClusters }),
          ...(bulkAddFreeTags && { freeTags: bulkAddFreeTags }),
          ...(bulkAddChannel && { channels: bulkAddChannel }),
          ...(bulkAddSalesPeriod && { salesPeriod: bulkAddSalesPeriod })
        },
        refetchQueries
      })
    } finally {
      this.handleRestrictProductByZoneModalClose()
      this.toggleHandleRestrictProductByZoneModalBulkAddProgressStatus()
    }
  }
}
