import { ListViewFieldMapping } from '@modules/common/models/enums/ListViewFieldMapping'
import {
  DoorProductKPI,
  SACollectionProductQty
} from '@modules/common/models/interfaces/SAListViewProduct'
import { convertToCurrencyLocaleString } from '@services/assortmentService'
import { convertNumberToPercentage } from '@services/commonServices'
import { DOORPREFIX } from '@services/showroom/SACollectionListViewDefinitionService'
import {
  IVIPAllotment,
  wholeSaleProductService
} from '@services/wholesale/productService'
import { strings } from '@stores'
import isEmpty from 'lodash/isEmpty'
import reduce from 'lodash/reduce'
import {
  DoorProductMap,
  VIPDoorOrders,
  VIPOrderProduct
} from './wholesaleCollectionService'
import { ValueMetadata } from './productService'

export interface DoorCategoryKPI {
  [key: string]: string | number | boolean
}

export interface DoorDataForBSProduct {
  doorWiseData: {
    [key: string]: DoorProductKPI
  }
  isBooked: boolean
  isBookAll: boolean
  isUnbookAll: boolean
  totalQuantity: Array<SACollectionProductQty>
}

/**
 * Returns total target qty for given list of product.
 * Normally used to calculate total target qty at category/KPI
 * level
 * @param products
 */
const getTotalTargetQtyForProducts = (
  products: Array<VIPOrderProduct>,
  valueMeta?: ValueMetadata
) => {
  return reduce(
    products,
    (acc, product) => {
      const totalQty = product?.totalQty ?? 0
      const whlPrice = product?.clientOrderProduct?.whlPrice ?? null
      const discount = product?.discount || 0
      acc.quantity += totalQty

      const localValue = whlPrice ? totalQty * whlPrice?.Price * (1 - discount / 100) : 0
      if (valueMeta && valueMeta.convertToTargetCurrency) {
        let valueInTargetCurrency = valueMeta?.exchangeRate
          ? localValue / valueMeta?.exchangeRate
          : 0
        acc.value += valueInTargetCurrency
      } else {
        acc.value += localValue
      }
      return acc
    },
    { quantity: 0, value: 0 }
  )
}

/**
 * Checks whether product is unsized
 * @param product
 */
const isUnSizedProduct = product => {
  return isEmpty(product?.sizes)
}

/**
 * Returns total sized qty for
 * for given door product
 * @param orderProduct
 */
const getSizedQtyForDoorProduct = (orderProduct: VIPOrderProduct) => {
  /**
   * Consider total qty of unsized product
   * while calculating sized qty
   */
  if (isUnSizedProduct(orderProduct)) {
    return orderProduct?.totalQty ?? 0
  } else {
    return (orderProduct?.qtyPerSize || []).reduce((acc, curr) => (acc += curr || 0), 0)
  }
}

/**
 * Returns total sized qty for given list of product.
 * Normally used to calculate total sized qty at category/KPI
 * level
 * @param products
 */
const getTotalSizedQtyForProducts = (
  products: Array<VIPOrderProduct>,
  valueMeta?: ValueMetadata
) => {
  return reduce(
    products,
    (acc, product) => {
      const totalSizedQty = getSizedQtyForDoorProduct(product)
      const whlPrice = product?.clientOrderProduct?.whlPrice ?? null
      const discount = product?.discount || 0
      acc.quantity += totalSizedQty

      const localValue = whlPrice
        ? totalSizedQty * whlPrice?.Price * (1 - discount / 100)
        : 0
      if (valueMeta && valueMeta.convertToTargetCurrency) {
        let valueInTargetCurrency = valueMeta?.exchangeRate
          ? localValue / valueMeta?.exchangeRate
          : 0
        acc.value += valueInTargetCurrency
      } else {
        acc.value += localValue
      }

      return acc
    },
    { quantity: 0, value: 0 }
  )
}

/**
 * Returns door level kpis at category level
 * @param doorWiseProducts
 * @returns doorKPIs: DoorCategoryKPI
 */
export const getDoorKPISAtCategory = (
  doorWiseProducts: DoorProductMap,
  currency?: string,
  parentTargetQuantity?: number,
  parentTargetValue?: number,
  parentSizedQuantity?: number,
  parentSizedValue?: number,
  buytargetMixKPI?: any,
  valueMeta?: ValueMetadata
): DoorCategoryKPI => {
  let doorKPIs = {}
  let totalTargetQuantity = 0
  let totalSizedQuantity = 0
  let totalTargetValue = 0
  let totalSizedValue = 0

  for (let door in doorWiseProducts) {
    const doorProducts = doorWiseProducts[door] ? doorWiseProducts[door] : null

    const { quantity: targetQty, value: targetValue } = getTotalTargetQtyForProducts(
      doorProducts ?? [],
      valueMeta
    )
    const { quantity: sizedQty, value: sizedValue } = getTotalSizedQtyForProducts(
      doorProducts ?? [],
      valueMeta
    )

    totalTargetQuantity += targetQty
    totalTargetValue += targetValue
    totalSizedQuantity += sizedQty
    totalSizedValue += sizedValue

    doorKPIs[`${DOORPREFIX}-${door}-${ListViewFieldMapping.totalTargetQuantity}`] =
      targetQty
    doorKPIs[`${DOORPREFIX}-${door}-${ListViewFieldMapping.totalTargetValue}`] =
      targetValue
    doorKPIs[
      `${DOORPREFIX}-${door}-${ListViewFieldMapping.totalTargetValueWithCurrency}`
    ] = convertToCurrencyLocaleString(currency, targetValue)
    doorKPIs[`${DOORPREFIX}-${door}-${ListViewFieldMapping.totalSizedQuantity}`] =
      sizedQty
    doorKPIs[`${DOORPREFIX}-${door}-${ListViewFieldMapping.totalSizedValue}`] = sizedValue
    doorKPIs[
      `${DOORPREFIX}-${door}-${ListViewFieldMapping.totalSizedValueWithCurrency}`
    ] = convertToCurrencyLocaleString(currency, sizedValue)
  }

  // Actual draft qty mix
  const draftActualQtyMix = convertNumberToPercentage(
    parentTargetQuantity ? totalTargetQuantity / parentTargetQuantity : 0
  )
  const draftActualValueMix = convertNumberToPercentage(
    parentTargetValue ? totalTargetValue / parentTargetValue : 0
  )

  // Actual sized qty mix
  const sizedActualQtyMix = convertNumberToPercentage(
    parentSizedQuantity ? totalSizedQuantity / parentSizedQuantity : 0
  )
  const sizedActualValueMix = convertNumberToPercentage(
    parentSizedValue ? totalSizedValue / parentSizedValue : 0
  )

  doorKPIs = {
    ...doorKPIs,
    [ListViewFieldMapping.totalTargetQuantity]: totalTargetQuantity,
    [ListViewFieldMapping.totalTargetValue]: totalTargetValue,
    [ListViewFieldMapping.totalTargetValueWithCurrency]: convertToCurrencyLocaleString(
      currency,
      totalTargetValue
    ),
    [ListViewFieldMapping.totalSizedQuantity]: totalSizedQuantity,
    [ListViewFieldMapping.totalSizedValue]: totalSizedValue,
    [ListViewFieldMapping.totalSizedValueWithCurrency]: convertToCurrencyLocaleString(
      currency,
      totalSizedValue
    ),
    [ListViewFieldMapping.mixRatioByTargetQty]: `${draftActualQtyMix} / ${buytargetMixKPI?.buytargetQtyMix}`,
    [ListViewFieldMapping.mixRatioByTargetValue]: `${draftActualValueMix} / ${buytargetMixKPI?.buytargetValueMix}`,
    [ListViewFieldMapping.mixRatioBySizedQty]: `${sizedActualQtyMix} / ${buytargetMixKPI?.buytargetQtyMix}`,
    [ListViewFieldMapping.mixRatioBySizedValue]: `${sizedActualValueMix} / ${buytargetMixKPI?.buytargetValueMix}`
  }

  return doorKPIs
}

const checkExcludedClusterForClientOrderProduct = (
  vipProductId,
  clientOrderProducts: Array<VIPOrderProduct>,
  clientAllotmentItems: Array<IVIPAllotment>,
  doorId?: string
) => {
  const clientOrderProduct = clientOrderProducts?.find(orderProduct => {
    return orderProduct.Original.Id === vipProductId
  })
  // Is Cluster Excluded
  const buyingSessionProduct = clientOrderProduct?.BuyingSessionProduct as any
  return (
    buyingSessionProduct?.clusterExclusion?.length &&
    wholeSaleProductService.isProductExcludedForDoorId(
      clientAllotmentItems,
      buyingSessionProduct,
      doorId
    )
  )
}

/**
 * Returns door level kpis at product level
 * @param doorWiseProducts
 * @returns doorKPIs: DoorKPI
 */
export const getDoorDataForBSProduct = (
  vipProductId: string,
  doorOrders: Array<VIPDoorOrders>,
  doorOrderProducts: Array<VIPOrderProduct>,
  isProductBsClosed: boolean,
  isActive: boolean,
  isUnSizedProduct: boolean,
  isBasketProduct: boolean,
  clientOrderProducts: Array<VIPOrderProduct>,
  clientAllotmentItems: Array<IVIPAllotment>
): DoorDataForBSProduct => {
  let doorKPIs = {}
  let productTotalQuantity = 0
  let productTotalSizedQuantity = 0
  let bookedInAtLeastOneDoor = false
  let isOrderedForADoor = false
  let bookedProducts = 0
  for (let order of doorOrders) {
    const doorId = order.Door.Id
    const orderProduct = doorOrderProducts?.find(orderProduct => {
      return (
        orderProduct.Original.Id === vipProductId &&
        orderProduct.DoorOrder.Door.Id === doorId
      )
    })
    // no orderProduct for excluded clusters
    // Add Disabled if Client order has product with excluded cluster
    let hasExcludedCluster = false
    if (
      !orderProduct &&
      doorId &&
      clientOrderProducts?.length &&
      clientAllotmentItems?.length
    )
      hasExcludedCluster = checkExcludedClusterForClientOrderProduct(
        vipProductId,
        clientOrderProducts,
        clientAllotmentItems,
        doorId
      )

    const sizedQuantity = getSizedQtyForDoorProduct(orderProduct)
    productTotalSizedQuantity = productTotalSizedQuantity + sizedQuantity
    productTotalQuantity = productTotalQuantity + (orderProduct?.totalQty ?? 0)
    const isBooked = orderProduct?.isBooked ?? false
    const isRecommended = orderProduct?.isRecommended ?? false
    isBooked && bookedProducts++
    bookedInAtLeastOneDoor = bookedInAtLeastOneDoor || isBooked
    isOrderedForADoor = isOrderedForADoor || !!orderProduct
    doorKPIs[`${DOORPREFIX}-${doorId}`] = {
      id: orderProduct?.Id ?? null,
      doorId,
      doorOrderId: order.Id,
      doorName: order?.Door?.Name ?? '',
      isBooked,
      isRecommended,
      targetQuantity: orderProduct?.totalQty ?? 0,
      totalSizedQuantity: sizedQuantity,
      checkboxLabel: isRecommended ? strings.recommended : strings.booked,
      isInputDisabled: isProductBsClosed || !isBooked,
      isCheckedDisabled: isProductBsClosed || (!isBooked && !isActive),
      isAddDisabled: isProductBsClosed || !isActive || hasExcludedCluster,
      sizes: orderProduct?.sizes,
      qtyPerSize: orderProduct?.qtyPerSize
    } as DoorProductKPI
  }
  const totalQuantity = isUnSizedProduct
    ? [
        {
          name: strings.singleQuantity,
          value: productTotalQuantity
        }
      ]
    : [
        {
          name: strings.totalTargetQuantity,
          value: productTotalQuantity
        },
        {
          name: strings.totalSizedQuantity,
          value: productTotalSizedQuantity
        }
      ]
  return {
    doorWiseData: doorKPIs,
    isBooked: bookedInAtLeastOneDoor,
    isBookAll: bookedProducts === doorOrders.length,
    isUnbookAll: bookedProducts === 0,
    totalQuantity: isBasketProduct ? totalQuantity : []
  }
}
