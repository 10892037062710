import { IStyledLabelWrapper } from './Interfaces'
import styled from 'styled-components'
import { TextAlignment } from '@modules/common'

const StyledLabelWrapper = styled.div<IStyledLabelWrapper>(props => {
  const { alignment } = props
  let alignLabel = alignment
  switch (alignment) {
    case TextAlignment.left:
      alignLabel = 'flex-start'
      break
    case TextAlignment.center:
      alignLabel = 'center'
      break
    case TextAlignment.right:
      alignLabel = 'flex-end'
      break
  }

  return {
    display: 'flex',
    justifyContent: alignLabel,
    alignItems: 'center',
    height: 'var(--cell-height)',
    borderBottom: 'var(--cell-border-bottom)',
    padding: 'var(--cell-padding)'
  }
})

export default StyledLabelWrapper
