import { ApolloClient } from '@apollo/client/core'
import { StyledProps } from '@components/3rd-party/mui'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import ArrowDropUp from '@material-ui/icons/ArrowDropUp'
import Collapse from '@material-ui/core/Collapse'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import React from 'react'

const styles = theme => ({
  iconStyles: {
    cursor: 'pointer'
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  chipHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#e0e0e0',
    paddingLeft: 8,
    paddingTop: 4,
    marginTop: 8,
    marginLeft: 8
  },
  heading: {
    cursor: 'pointer'
  },
  checkBoxViews: {
    display: 'flex',
    justifyContent: 'flex-start',
    maxHeight: 240,
    overflow: 'auto'
  },
  chipsViews: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    position: 'absolute',
    zIndex: 2,
    maxHeight: 240,
    overflow: 'auto',
    marginLeft: 8,
    paddingBottom: 8,
    backgroundColor: '#e0e0e0'
  }
})
interface FilterCollapseProps extends StyledProps {
  expandMoreAction?: (panelName: string) => void
  headerHeight?: string
  panelName: string
  styles?: any
  client?: ApolloClient<any>
  children?: any
  variant?: string
}

@((withStyles as any)(styles))
@observer
export class FilterCollapse extends React.Component<FilterCollapseProps> {
  @observable expanded = ''

  handleChange = () => {
    const {
      props: { panelName }
    } = this
    if (this.expanded === panelName) {
      this.expanded = ''
    } else {
      this.expanded = panelName
    }
  }

  render() {
    const {
      handleChange,
      expanded,
      props: { children, classes, panelName, headerHeight = '45px', style, variant }
    } = this
    return (
      <Collapse
        in={expanded === panelName}
        collapsedHeight={headerHeight}
        style={style}
        className={classes.headerContainer}
        classes={{
          wrapper: classes.collapseWrapper
        }}
      >
        <div className={classes.paper}>
          <div
            className={variant ? classes.chipHeaderContainer : classes.headerContainer}
          >
            <div
              onClick={() => {
                handleChange()
              }}
            >
              <Typography variant='subtitle1' className={classes.heading}>
                {panelName}
              </Typography>
            </div>
            <div>
              {expanded === panelName ? (
                <ArrowDropUp
                  className={classes.iconStyles}
                  onClick={() => {
                    handleChange()
                  }}
                />
              ) : (
                <ArrowDropDown
                  className={classes.iconStyles}
                  onClick={() => {
                    handleChange()
                  }}
                />
              )}
            </div>
          </div>
          {expanded === panelName && (
            <div className={variant ? classes.chipsViews : classes.checkBoxViews}>
              {children}
            </div>
          )}
        </div>
      </Collapse>
    )
  }
}
