/**
 * Method for displaying currency code with price
 */
const formatCurrencyValue = (
  currencyCode: string,
  value: number,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2
) => {
  try {
    return value.toLocaleString('en', {
      minimumFractionDigits,
      maximumFractionDigits,
      style: 'currency',
      currency: currencyCode,
      currencyDisplay: 'code'
    })
  } catch (err) {
    /**
     * NOTE: toLocaleString will throw error in case currencyCode passed is in  valid or something it does not understand,
     * we will return value with currency code string in such cases
     */
    return currencyCode ? `${value} ${currencyCode}` : `${value}`
  }
}

export default formatCurrencyValue
