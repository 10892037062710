import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  container: {
    paddingBottom: theme.spacing(1)
  },
  textEllipsis: {
    textOverflow: 'ellipsis',
    whiteSpace: 'pre-wrap;',
    overflow: 'hidden'
  }
})

const color = 'rgba(0, 0, 0, 0.38)'

const skuStyle = { color, fontWeight: 400, letterSpacing: 0.4 }

const BuyingSessionInfoComponent = ({
  seasonName,
  sessionName,
  quntityOfProducts,
  classes,
  onClick
}) => {
  return (
    <div className={classes.container} onClick={onClick}>
      <Typography variant='caption' style={{ color }}>
        {seasonName}
      </Typography>
      <Typography
        variant='subtitle1'
        className={classes.textEllipsis}
        title={sessionName}
      >
        {sessionName}
      </Typography>
      <Typography variant='caption' style={skuStyle}>
        {quntityOfProducts}
      </Typography>
    </div>
  )
}

export const BuyingSessionInfo = withStyles(styles as any)(
  React.memo(BuyingSessionInfoComponent)
)
