export enum ClientTableViewFieldMapping {
  Id = 'id',
  Active = 'active',
  Name = 'name',
  Code = 'code',
  Zone = 'zone',
  Country = 'country',
  Master = 'isMaster',
  ReorderStatus = 'reorder',
  CustomerGroup = 'customerGroup',
  Doors = 'doors',
  Open = 'open',
  PriceGroup = 'priceGroup',
  salesDistrict = 'salesDistrict',
  Actions = 'actions',
  Channel = 'channel'
}
