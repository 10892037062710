import { SecondaryButton } from '@components/UI-Components/SecondaryButton'
import { RestrictProductByZoneTabs } from '@modules/retail/collection/component/RestrictProductByZone/RestrictProductByZoneTabs'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'
import { stores } from '@stores'
import { useObserver } from 'mobx-react-lite'
import React from 'react'
import { RestrictProductByZoneDragDropAreaContainer } from '../../container/RestrictProductByZoneDragDropArea'
import * as css from './RestrictProductByZone.css'

interface RestrictProductByZoneModalProps {
  message: string
}
export const RestrictProductByZoneModal: React.FunctionComponent<RestrictProductByZoneModalProps> =
  ({ message }) => {
    return useObserver(function useHook() {
      const {
        restrictProductByZoneModal: {
          handleRestrictProductByZoneModalClose,
          toggleRestrictProductByZoneModal,
          handleRestrictProductByZoneModalBulkAdd,
          isHandleRestrictProductByZoneModalBulkAddInProgress
        },
        collection: { selectedProducts }
      } = stores

      const title = `${
        selectedProducts ? selectedProducts.length : '1'
      } Products selected`
      return (
        <Dialog
          open={toggleRestrictProductByZoneModal}
          onClose={handleRestrictProductByZoneModalClose}
          fullWidth={true}
          maxWidth='md'
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogContent classes={{ root: css.tabHeaders }}>
            <RestrictProductByZoneTabs />
            <Typography className={css.tabProductCount} variant='caption'>
              {title}
            </Typography>
          </DialogContent>
          <DialogContent classes={{ root: css.dragArea }}>
            <RestrictProductByZoneDragDropAreaContainer />
          </DialogContent>
          <DialogActions>
            <SecondaryButton onClick={handleRestrictProductByZoneModalClose}>
              Cancel
            </SecondaryButton>
            <SecondaryButton
              onClick={handleRestrictProductByZoneModalBulkAdd}
              loading={isHandleRestrictProductByZoneModalBulkAddInProgress}
            >
              Save
            </SecondaryButton>
          </DialogActions>
        </Dialog>
      )
    })
  }
