import { QueryResult } from '@apollo/client/react'
import { Query } from '@apollo/client/react/components/Query'
import { withApollo } from '@apollo/client/react/hoc/withApollo'
import { StyledProps } from '@components/3rd-party/mui'
import {
  CREATE_ASSORTMENT_REVIEW_REQUEST,
  LAST_ASSORTMENT_REVIRW_REQUEST
} from '@components/UI-Components/Assortment/graphql'
import Button from '@material-ui/core/Button'
import Popover from '@material-ui/core/Popover'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { nav } from '@stores/nav'
import { strings, stores } from '@stores'
import classNames from 'classnames'
import React from 'react'
import { withRouter } from 'react-router-dom'

const styles = theme => ({
  container: {
    flex: 1,
    padding: 15
  },
  paddingBottom: {
    paddingBottom: 10
  },
  bottomBorder: {
    borderBottom: '1px solid #000000'
  },
  group: {
    margin: theme.spacing(2),
    flexDirection: 'row'
  },
  textField: {
    flex: 1
  },
  commentBox: {
    flex: 1,
    display: 'flex',
    paddingTop: 10,
    flexDirection: 'column'
  },
  justifyRight: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  lastRequestInfoContainer: {
    borderTop: '1px solid black',
    marginTop: '15px',
    paddingTop: '10px',
    marginBottom: '15px'
  },
  lastRequestInfoRow: {
    width: '100%',
    clear: 'both',
    float: 'left',
    marginBottom: '5px'
  },
  comments: {
    width: '100%'
  },
  usersName: {
    fontWeight: 'bolder'
  },
  smallFonts: {
    fontSize: '12px'
  },
  requestType: {
    fontSize: '18px',
    fontWeight: 'bold'
  },
  commentsContainer: {
    paddingBottom: '15px'
  },
  reviewType: {
    float: 'left'
  },
  reviewStatus: {
    float: 'right'
  },
  confirmationPopup: {
    backgroundColor: 'white',
    padding: '10px'
  },
  paperClass: {
    backgroundColor: 'black',
    opacity: '0.4'
  }
})

interface IRMApprovalDialog extends StyledProps {
  zone?: any
  store?: any
  history?: any
  handleApprovalPopupClose: any
  handleApprovalPopupSubmit: any
  client?: any
  match?: any
}

@((withStyles as any)(styles))
@(withApollo as any)
@(withRouter as any)
export class RMApprovalDialog extends React.Component<IRMApprovalDialog> {
  state = {
    comment: '',
    showConfirmation: false,
    showConfirmationPopupAnchorEl: null
  }

  onCommentChanged = event => {
    this.setState({ comment: event.target.value })
  }

  onSubmit = (event, lastRequestData) => {
    const { sendReviewRequest } = this
    if (
      lastRequestData &&
      lastRequestData.status === strings.assortmentReviewStatus.OPEN
    ) {
      this.setState({
        showConfirmation: true,
        showConfirmationPopupAnchorEl: event.target
      })
    } else {
      sendReviewRequest()
    }
  }

  sendReviewRequest = async (cancelExistingRequest = false) => {
    const [
      {
        props: {
          client,
          match,
          match: {
            params: { assortmentId }
          },
          handleApprovalPopupSubmit
        },
        state: { comment }
      },
      {
        assortment: { getAssortmentType }
      }
    ] = [this, stores]
    const assortmentType = getAssortmentType(match)
    await client.mutate({
      mutation: CREATE_ASSORTMENT_REVIEW_REQUEST,
      variables: {
        comment,
        zoneAssortmentId: assortmentType === strings.zone ? assortmentId : undefined,
        storeAssortmentId: assortmentType === strings.store ? assortmentId : undefined,
        cancelExistingRequest
      },
      refetchQueries: [
        {
          query: LAST_ASSORTMENT_REVIRW_REQUEST,
          variables: { assortmentId }
        }
      ]
    })

    handleApprovalPopupSubmit()
  }

  render() {
    const {
      props: { classes },
      state: { comment, showConfirmation, showConfirmationPopupAnchorEl },
      onCommentChanged,
      onSubmit,
      sendReviewRequest
    } = this

    const {
      params: { assortment: assortmentId }
    } = nav
    const isGMOrAdmin = stores.site.isGlobalMerchandiserOrAdmin()

    return (
      <Query
        query={LAST_ASSORTMENT_REVIRW_REQUEST}
        variables={{ assortmentId }}
        fetchPolicy={'cache-and-network'}
        skip={Boolean(isGMOrAdmin)}
      >
        {({ data, error, loading }: QueryResult) => {
          const lastRequestData = data ? data.lastAssortmentReviewRequest : null

          const creationDateObj = lastRequestData
            ? new Date(lastRequestData.createdAt)
            : ''
          const creationCommentDateTime = creationDateObj
            ? `${creationDateObj.getMonth()}/${creationDateObj.getDate()}/${creationDateObj.getFullYear()} ` +
              `${creationDateObj.getHours()}:${creationDateObj.getMinutes()}:${creationDateObj.getSeconds()}`
            : ''

          const updationDateObj = lastRequestData
            ? new Date(lastRequestData.updatedAt)
            : ''
          const updationCommentDateTime = updationDateObj
            ? `${updationDateObj.getMonth()}/${updationDateObj.getDate()}/${updationDateObj.getFullYear()} ` +
              `${updationDateObj.getHours()}:${updationDateObj.getMinutes()}:${updationDateObj.getSeconds()}`
            : ''

          return (
            <>
              <div className={classes.container}>
                <div className={classNames(classes.bottomBorder, classes.paddingBottom)}>
                  <Typography variant='subtitle1'>Share with CM</Typography>
                </div>
                <div className={classes.commentBox}>
                  <Typography variant='caption'>Comment</Typography>
                  <TextField
                    label=''
                    multiline
                    rows='2'
                    placeholder=''
                    className={classes.textField}
                    margin='normal'
                    variant='outlined'
                    onChange={onCommentChanged}
                  />
                </div>
                <div className={classes.justifyRight}>
                  <Button
                    onClick={event => onSubmit(event, lastRequestData)}
                    disabled={Boolean(comment && comment.length > 180)}
                  >
                    Send
                  </Button>
                </div>

                {/* HISTORY SECTION */}
                {lastRequestData ? (
                  <div className={classes.lastRequestInfoContainer}>
                    <span
                      className={classNames(
                        classes.lastRequestInfoRow,
                        classes.requestType
                      )}
                    >
                      <span className={classes.reviewType}>
                        {strings.assortmentReviewTypes[lastRequestData.type]}
                      </span>
                      <span className={classes.reviewStatus}>
                        {strings.assortmentReviewStatus[lastRequestData.status]}
                      </span>
                    </span>

                    {/* Comments */}
                    <span
                      className={classNames(
                        classes.lastRequestInfoRow,
                        classes.smallFonts
                      )}
                    >
                      Request raised on {creationCommentDateTime} by:{' '}
                      <span
                        className={classes.usersName}
                      >{`${lastRequestData.raisedBy.firstName} ${lastRequestData.raisedBy.lastName}`}</span>
                    </span>

                    <span
                      className={classNames(
                        classes.lastRequestInfoRow,
                        classes.smallFonts
                      )}
                    >
                      Comments:
                    </span>
                    <span
                      className={classNames(
                        classes.lastRequestInfoRow,
                        classes.commentsContainer
                      )}
                    >
                      {lastRequestData.comment ? lastRequestData.comment : '--'}
                    </span>
                    {lastRequestData.reviewedBy ? (
                      <div className={classes.replyContainer}>
                        <span
                          className={classNames(
                            classes.lastRequestInfoRow,
                            classes.smallFonts
                          )}
                        >
                          Request reviewed on {updationCommentDateTime} by:{' '}
                          <span
                            className={classes.usersName}
                          >{`${lastRequestData.reviewedBy.firstName} ${lastRequestData.reviewedBy.lastName}`}</span>
                        </span>
                        <span
                          className={classNames(
                            classes.lastRequestInfoRow,
                            classes.smallFonts
                          )}
                        >
                          Comments:
                        </span>
                        <span
                          className={classNames(
                            classes.lastRequestInfoRow,
                            classes.commentsContainer
                          )}
                        >
                          {lastRequestData.reply ? lastRequestData.reply : '--'}
                        </span>
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </div>

              {/* Overwrite confirmation popup */}
              <Popover
                open={showConfirmation}
                anchorEl={showConfirmationPopupAnchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
              >
                <div className={classes.confirmationPopup}>
                  <p>An open request already exists for this assortment.</p>
                  <p>Do you want to overwrite it?</p>
                  <Button
                    onClick={() => {
                      sendReviewRequest(true)
                      this.setState({ showConfirmation: false })
                    }}
                  >
                    Yes
                  </Button>
                  <Button onClick={() => this.setState({ showConfirmation: false })}>
                    No
                  </Button>
                </div>
              </Popover>
            </>
          )
        }}
      </Query>
    )
  }
}
