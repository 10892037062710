import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import React from 'react'

const styles = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  smallBox: {
    width: 20,
    height: 20,
    borderRadius: 2
  }
})

const SmallColorBoxComponent = ({ classes, color, className, ...otherProps }) => {
  return (
    <div className={classes.container}>
      <div
        className={classNames(classes.smallBox, className)}
        style={{ backgroundColor: color }}
        {...otherProps}
      />
    </div>
  )
}

export const SmallColorBox = withStyles(styles)(SmallColorBoxComponent)
