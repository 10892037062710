import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'

const styles = () => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    maxHeight: '60px',
    margin: '15px 20px 15px 25px'
  }
})

const TitleBarComponent = ({ classes, title, actions }) => {
  return (
    <div className={classes.container}>
      <Typography variant='h4'>{title}</Typography>
      {actions ? actions : null}
    </div>
  )
}

export const TitleBar = withStyles(styles)(React.memo(TitleBarComponent))
