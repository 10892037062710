import { config } from '@config'
import { storage } from '@services/storageService'
import axios from 'axios'
import { action, observable, ObservableMap } from 'mobx'
import { computedFn } from 'mobx-utils'

export class ClientAndBSGService {
  getProductCurves = async (
    buyingSessionGroupId: string,
    clientId: string,
    curveType: string
  ) => {
    try {
      const url = `${config.buyingBoardServiceRestEndpoint}curve/buyingSessionGroupId/${buyingSessionGroupId}/curveTypes/${curveType}`
      const accessToken: string = storage.getItem('access_token')

      const response: any = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params: {
          client: clientId
        }
      })
      return response?.data
    } catch (e) {
      console.log(e)
    }
  }

  getZoneWeightCurve = async (buyingSessionGroupId: string, curveType: string) => {
    try {
      const url = `${config.buyingBoardServiceRestEndpoint}curve/buyingSessionGroupId/${buyingSessionGroupId}/curveType/${curveType}`
      const accessToken: string = storage.getItem('access_token')

      const response: any = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      return response?.data
    } catch (e) {
      console.log(e)
    }
  }
}

export class ProductCurvesStore {
  @observable curves = new ObservableMap()
  private apiService = new ClientAndBSGService()
  @observable isCurveLoading = new ObservableMap()

  @action fetchData = (clientId, bsgId) => {
    if (!this.curves.has(clientId + bsgId) && !this.getCurveLoading(clientId, bsgId)) {
      this.isCurveLoading.set(clientId + bsgId, true)
      Promise.all([
        this.apiService.getProductCurves(bsgId, clientId, 'size,door,cmrating,grade')
      ])
        .then(([curves]) => {
          this.curves.set(clientId + bsgId, {
            sizeCurve: curves.sizeCurves[clientId],
            doorCurve: curves.doorCurves[clientId],
            cmRatingCurve: curves.cmratingCurves[clientId],
            gradeCurve: curves.gradeCurves[clientId]
          })
          this.isCurveLoading.set(clientId + bsgId, false)
        })
        .catch(error => {
          this.curves.set(clientId + bsgId, null)
          this.isCurveLoading.set(clientId + bsgId, false)
        })
    }
  }

  /**
   * Function which will return the curves of products .
   */
  getCurvesForProduct = computedFn((productId, clientId, buyingSessionGroupId) => {
    const { sizeCurve, doorCurve, cmRatingCurve, gradeCurve } =
      this.curves.get(clientId + buyingSessionGroupId) || {}
    return {
      sizeCurve: sizeCurve?.[productId] ?? {},
      doorCurve: doorCurve?.[productId] ?? {},
      cmRatingCurve: cmRatingCurve?.[productId] ?? {},
      gradeCurve: gradeCurve?.[productId] ?? {}
    }
  })

  getCurveLoading = computedFn((clientId, buyingSessionGroupId) => {
    const isCurveLoading =
      this.isCurveLoading.has(clientId + buyingSessionGroupId) &&
      this.isCurveLoading.get(clientId + buyingSessionGroupId)
    return isCurveLoading
  })
}

export const productCurveStore = new ProductCurvesStore()
