import React, { CSSProperties, useState } from 'react'
import Slider from 'react-slick'
import ThreeDRotation from '@material-ui/icons/ThreeDRotation'
import Typography from '@material-ui/core/Typography'
import { Icons } from '@icons'
import { strings } from '@stores'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import * as css from './Slider.css'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import { ProductMedia } from '../ProductCardOld/ProductMedia'
import { Add, PulseProgress, Remove } from '../svg/wholesale'
import { MP4 } from '../svg'

const arrowStyles: CSSProperties = {
  position: 'absolute',
  zIndex: 2,
  cursor: 'pointer'
}

const CustomArrow = props => {
  const { onClick, iconName, customStyle } = props
  return (
    <Icons
      iconname={iconName}
      onClick={onClick}
      style={{
        ...arrowStyles,
        ...customStyle
      }}
    />
  )
}

interface IProductSliderProps {
  productMedia: any
}

export const ProductSlider: React.FunctionComponent<IProductSliderProps> = ({
  productMedia
}) => {
  const [nav1, setNav1] = useState()
  const [nav2, setNav2] = useState()
  const [show3D, setShow3D] = useState(false)
  const [showVideo, setShowVideo] = useState(false)

  const {
    productImages,
    IFrameURL,
    videoURL,
    isViewWide,
    orderProductLoading,
    orderProductData,
    basketToggleInProgress,
    orderProduct,
    toggleBasketProduct,
    isDisabledActions,
    addRemoveProduct,
    IFRAME_WIDTH,
    IFRAME_HEIGHT
  } = productMedia

  let regularProductMediaProps: any = {
    className: productImages?.length > 1 ? css.productMediaSlider : css.productMedia,
    height: IFRAME_HEIGHT,
    imgWidth: IFRAME_WIDTH,
    width: IFRAME_WIDTH
  }
  if (isViewWide) {
    regularProductMediaProps = {
      ...regularProductMediaProps,
      disabled: isDisabledActions,
      bottomRightIcon:
        orderProductLoading && isEmpty(orderProductData)
          ? PulseProgress
          : basketToggleInProgress
          ? PulseProgress
          : orderProduct
          ? Remove
          : Add,
      onBottomRightIconClick: toggleBasketProduct(
        addRemoveProduct,
        !!orderProduct,
        isDisabledActions
      ),
      iconWidth: 32
    }
  }

  const settingsRegular = {
    slidesToShow: 1,
    swipeToSlide: true,
    focusOnSelect: true,
    nextArrow: (
      <CustomArrow
        iconName={'iOSArrow'}
        customStyle={{
          right: '5px',
          top: 'calc(50% - 15px)',
          transform: 'translate(-50%, 0) rotate(180deg)'
        }}
      />
    ),
    prevArrow: (
      <CustomArrow
        iconName={'iOSArrow'}
        customStyle={{ left: '5px', top: 'calc(50% - 15px)' }}
      />
    )
  }

  const settingsThumbnail = {
    slidesToShow: Math.min(productImages?.length, 4),
    swipeToSlide: true,
    focusOnSelect: true,
    vertical: true,
    verticalSwiping: true,
    nextArrow: (
      <CustomArrow
        iconName={'iOSArrow'}
        customStyle={{ transform: 'translate(-50%, 0) rotate(-90deg)', left: '50%' }}
      />
    ),
    prevArrow: (
      <CustomArrow
        iconName={'iOSArrow'}
        customStyle={{
          position: 'relative',
          transform: 'translate(-50%, 0) rotate(90deg)',
          left: '50%'
        }}
      />
    )
  }

  const thumbnails = map(productImages, image => {
    return (
      <div key={image.id} className={show3D ? '' : css.pointer360}>
        <ProductMedia
          image={image.src}
          className={css.productMediaSlider}
          height={50}
          imgWidth={50}
          width={50}
          cursorPointerType={show3D ? 'not-allowed' : 'pointer'}
        />
      </div>
    )
  })

  const regularImages = map(productImages, image => {
    return (
      <div key={image.id}>
        <ProductMedia image={image.src} {...regularProductMediaProps} />
      </div>
    )
  })

  return (
    <div style={{ position: 'relative' }}>
      <div className={css.sliderContainer}>
        {thumbnails.length > 1 && (
          <div className={css.productThumbnail}>
            <Slider
              className={css.sliderThumbnail}
              asNavFor={nav1}
              ref={slider2 => setNav2(slider2)}
              {...settingsThumbnail}
            >
              {thumbnails}
            </Slider>
          </div>
        )}
        <div className={css.productRegular}>
          {show3D || showVideo ? (
            show3D ? (
              <iframe
                width={IFRAME_WIDTH}
                height={IFRAME_HEIGHT}
                frameBorder='0'
                allowFullScreen
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                src={IFrameURL}
                title='3D'
              />
            ) : (
              <iframe
                width={IFRAME_WIDTH}
                height={IFRAME_HEIGHT}
                frameBorder='0'
                allowFullScreen
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                src={videoURL}
                title='Video'
              />
            )
          ) : (
            <Slider
              className={isViewWide ? css.sliderRegularWide : css.sliderRegularNarrow}
              asNavFor={nav2}
              ref={slider1 => setNav1(slider1)}
              {...settingsRegular}
            >
              {regularImages}
            </Slider>
          )}
          {!show3D && !showVideo && (IFrameURL || videoURL) ? (
            <>
              {IFrameURL && (
                <ThreeDRotation
                  onClick={() => setShow3D(true)}
                  style={{
                    position: 'absolute',
                    left: '7px',
                    bottom: '7px'
                  }}
                />
              )}
              {videoURL && (
                <img
                  src={MP4}
                  style={{
                    position: 'absolute',
                    left: IFrameURL ? '45px' : '7px',
                    bottom: '7px'
                  }}
                  alt=''
                  onClick={() => {
                    setShowVideo(true)
                  }}
                />
              )}
            </>
          ) : null}
        </div>
      </div>
      {show3D || showVideo ? (
        <Typography
          variant={'button'}
          onClick={() => (show3D ? setShow3D(false) : setShowVideo(false))}
          style={{
            cursor: 'pointer',
            position: 'absolute',
            right: 0
          }}
        >
          {strings.closeIFrame}
        </Typography>
      ) : null}
    </div>
  )
}
