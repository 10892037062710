/*
 * @Author: Raeesaa Metkari
 * @Date: 2019-12-11 13:26:56
 * @Last Modified by: Raeesaa Metkari
 * @Last Modified time: 2020-07-30 23:31:55
 */

export enum ListViewFieldMapping {
  // Common
  aws = 'aws',
  mixQuantity = 'mixQuantity',
  mixValue = 'mixValue',
  averageDepth = 'averageDepth',

  // Product attributes
  ProductInfo = 'productInfo',
  ProductAttributes = 'productAttributes',
  CMClusters = 'cMClusters',
  BuyerClusters = 'buyerClusters',
  FreeTags = 'freeTags',
  RetailFreeTags = 'retailFreeTags',
  WholesaleFreeTags = 'wholesaleFreeTags',
  Channels = 'channels',
  SalesPeriod = 'salesPeriod',
  Drops = 'drops',
  Price = 'price',
  ExcludedZones = 'excludedZones',
  ExcludedClients = 'excludedClients',
  TotalWeeks = 'totalWeeks',
  TotalStores = 'totalStores',
  TotalDoors = 'totalDoors',
  TotalClients = 'totalClients',
  salesQty = 'salesQty',
  draftQty = 'draftQty',
  sizedQty = 'sizedQty',
  skuEfficiency = 'skuEfficiency',
  totalBETQuantity = 'totalBETQuantity',
  totalProducts = 'totalProducts',
  totalQuantity = 'totalQuantity',
  actualQuantityList = 'actualQuantityList',
  totalTargetQuantity = 'totalTargetQuantity',
  totalDraftQuantity = 'totalDraftQuantity',
  totalTargetValue = 'totalTargetValue',
  totalSizedQuantity = 'totalSizedQuantity',
  totalSizedValue = 'totalSizedValue',
  totalTargetValueWithCurrency = 'totalTargetValueWithCurrency',
  totalSizedValueWithCurrency = 'totalSizedValueWithCurrency',
  totalDraftValueWithCurrency = 'totalDraftValueWithCurrency',
  totalStores = 'totalStores',
  totalValue = 'totalValue',
  zoneWiseData = 'zoneWiseData',
  WholesaleClusters = 'wholesaleClusters',
  RetailClusters = 'retailClusters',
  RMClusters = 'rmClusters',

  // Zone level Field Mapping
  zone = 'zone',
  betQty = 'betQty',
  buyQty = 'buyQty',
  storeCount = 'storeCount',
  CM = 'CM',
  RM = 'RM',
  sellThrough = 'sellThrough',
  weeks = 'weeks',
  depth = 'depth',
  value = 'value',
  sku = 'sku',
  retailPrice = 'retailPrice',
  salesQuantity = 'salesQuantity',

  // To hold extra attributes to enforce validations
  Attributes = 'attributes',
  mixRatioByTargetQty = 'mixRatioByTargetQty',
  mixRatioByTargetValue = 'mixRatioByTargetValue',
  mixRatioBySizedQty = 'mixRatioBySizedQty',
  mixRatioBySizedValue = 'mixRatioBySizedValue',
  ClusterAssortmentProducts = 'clusterAssortmentProducts'
}
