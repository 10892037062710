import { withApollo } from '@apollo/client/react/hoc/withApollo'
import TimeAgo from 'react-timeago'
import { CentricTheme, StyledProps } from '@components/3rd-party/mui'
import { MainTheme } from '@components/3rd-party/themes'
import {
  ProductActionItem,
  ProductActions
} from '@components/UI-Components/ProductCardOld/ProductActions'
import { ProductDetail } from '@components/UI-Components/ProductCardOld/ProductDetails'
import { ProductInfo } from '@components/UI-Components/ProductCardOld/ProductInfo'
import { ProductInfoItem } from '@components/UI-Components/ProductCardOld/ProductInfoItem'
import { ProductMedia } from '@components/UI-Components/ProductCardOld/ProductMedia'
import { ProductStatus } from '@components/UI-Components/ProductCardOld/ProductStatus'
import {
  Column,
  FlexGrid,
  FormatItemEventArgs,
  HeadersVisibility,
  SelectionMode
} from '@grapecity/wijmo.grid'
import * as wjGrid from '@grapecity/wijmo.react.grid'
import Icon from '@material-ui/core/Icon'
import { MuiThemeProvider } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { IPermissions } from '@routes'
import { getProductDetails } from '@services/collectionServices'
import { getImageUrl } from '@services/imageService'
import { apolloClient, nav, stores } from '@stores'
import { action, autorun, computed, observable } from 'mobx'
import { disposeOnUnmount, observer } from 'mobx-react'
import React, { ReactElement } from 'react'
import ReactDOMServer from 'react-dom/server'
import { withRouter } from 'react-router-dom'
import * as css from './ProductCards_Wijmo.css'
import { ApolloProvider } from '@apollo/client/react'

const HEIGHT = 137
const IMAGE_WIDTH = 114
const IMAGE_CONTAINER_WIDTH = 145

const bindings = {
  productImage: 'productImage',
  productDetails: 'productDetails',
  cluster: 'cluster',
  tags: 'tags',
  salesPeriod: 'salesPeriod'
}

const styles = (theme: CentricTheme) => ({
  root: {
    overflow: 'auto',
    height: `calc(100vh - ${HEIGHT}px)`
  },
  productCard: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  productWithColorbox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  collectionContainer: {
    height: `calc(100vh - ${HEIGHT}px)`,
    overflow: 'auto'
  },
  productContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  borderRight: {
    borderRight: '1px solid #e1e1e1'
  },
  tableHead: {
    backgroundColor: theme.colors.navBackground
  },
  tableCellWidth: {
    maxWidth: 200,
    paddingTop: 0
  },
  chip: {
    margin: theme.spacing(1)
  },
  cursorPointer: {
    cursor: 'pointer'
  },
  dropAbleArea: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  salesPeriod: {
    margin: 10
  }
})

interface MyProps extends StyledProps {
  product?: any
  client?: any
  genderId?: any
  podiumId?: any
  refetch?: any
  addProductToAssortment: any
  markProductChannelExclusive: any
  buyingSessionProducts: any
  handleStatusChange: (channelId: any, productId: any) => void
  removeFavourite: (channelId: any, productId: any) => void
  markFavourite: (channelId: any, productId: any) => void
  getProductStatus: (status: any, isActive: any) => any
  handleTagDrop: (product: any, type: any, id: any) => any
  removeFreeTag: (productId: any, freeTagId: any) => any
  removeSalesPeriodFromProduct: (buyingSessionProductId: string) => void
  removeProductFromAssortment: (clusterId: any, buyingSessionProductId) => any
  permissions?: IPermissions
  history?: any
  match?: any
  fetchMore?: any
  hasMore?: any
  loadFunc?: any
}

@((withStyles as any)(styles))
@(withApollo as any)
@(withRouter as any)
@observer
export class ProductCardsWijmoGrid extends React.Component<MyProps> {
  render() {
    return (
      <div className={css.root}>
        <wjGrid.FlexGrid
          className={css.grid}
          alternatingRowStep={0}
          autoGenerateColumns={true}
          allowDelete={false}
          preserveSelectedState={true}
          frozenColumns={1}
          selectionMode={SelectionMode.None}
          showSelectedHeaders={HeadersVisibility.Column}
          itemsSource={this.itemsSource.slice()}
          initialized={this.onInitialized}
          formatItem={this.onFormatItem}
          scrollPositionChanged={this.onScrollPositionChanged}
        />
      </div>
    )
  }

  @observable.ref grid: FlexGrid
  @action onInitialized = (grid: FlexGrid) => {
    this.grid = grid
    grid.columnHeaders.rows.defaultSize = 56
    grid.headersVisibility = HeadersVisibility.Column
    grid.cells.rows.defaultSize = 269
    this.loadMore()
  }

  @action loadMore = () => {
    const {
      props: { loadFunc, fetchMore, buyingSessionProducts }
    } = this
    loadFunc(fetchMore, buyingSessionProducts.length)
  }

  @action onScrollPositionChanged = (grid: FlexGrid, e) => {
    /*if (!this.loading && grid.viewRange.bottomRow >= grid.rows.length - 1) {
      this.loadMore()
    }*/
  }

  @disposeOnUnmount updateColumns = autorun(() => {
    const { grid, columns } = this
    if (grid && columns) {
      grid.columns.beginUpdate()
      grid.columns.clear()
      columns.forEach(c => grid.columns.push(c))
      grid.columns.endUpdate()
    }
  })

  @computed get columns() {
    const { grid } = this

    let columns = [
      new Column({
        header: ' ',
        binding: bindings.productImage,
        allowResizing: false,
        width: 145
      }),
      new Column({
        header: ' ',
        binding: bindings.productDetails,
        allowResizing: false,
        width: 145
      }),
      new Column({ header: 'Price', binding: 'price', format: 'c2' }),
      new Column({ header: 'Cluster', binding: 'cluster' }),
      new Column({ header: 'Tags', binding: 'tags' }),
      new Column({ header: 'Sales Period', binding: 'salesPeriod.name' }),
      new Column({ header: 'Start Date', binding: 'salesPeriod.startDate', format: 'd' }),
      new Column({ header: 'End Date', binding: 'salesPeriod.endDate', format: 'd0' }),
      new Column({ header: 'Total Weeks', binding: 'weeks' }),
      new Column({ header: 'Total Stores', binding: 'stores' })
    ]

    // eslint-disable-next-line array-callback-return
    columns = columns.sort((a, b) => {
      if (grid) {
        const aIndex = grid != null ? grid.columns.indexOf(a.binding) : columns.indexOf(a)
        const bIndex = grid != null ? grid.columns.indexOf(b.binding) : columns.indexOf(b)
        return aIndex - bIndex
      }
    })

    return columns
  }

  @computed get itemsSource() {
    const r = this.props.buyingSessionProducts.map(({ node }, key) =>
      getProductDetails(node)
    )

    console.log(r)
    return r
  }

  rowById = {}

  @action private renderReactIntoGridCell = (c: ReactElement<any>, cell: HTMLElement) => {
    let reactCellDiv: HTMLDivElement

    // Remove inline editor
    if (cell.childNodes.length === 1) {
      const item = cell.childNodes[0] as HTMLElement // Can also be text after editing
      if (!item.classList || item.classList.contains('wj-grid-editor')) {
        item.remove()
      }
    }

    if (cell.childNodes.length === 0) {
      reactCellDiv = document.createElement('div') as HTMLDivElement
      cell.appendChild(reactCellDiv)
      reactCellDiv.classList.add(css.cellContainer)
    } else {
      reactCellDiv = cell.childNodes[0] as HTMLDivElement
    }

    reactCellDiv.innerHTML = ReactDOMServer.renderToStaticMarkup(c)
  }

  private getDataForRowAndCol = (
    row?: number,
    column?: number
  ): { rowItem: any; cellItem: any } => {
    const { grid } = this
    const { binding } = grid.columns[column]

    const rowItem = grid.itemsSource[row]
    if (binding) {
      return { rowItem, cellItem: grid.cells.getCellData(row, column, false) }
    }
  }

  @action onFormatItem = (grid: FlexGrid, e: FormatItemEventArgs) => {
    const { panel, row, col } = e
    const { cells, rows, columns } = grid
    const [
      {
        renderReactIntoGridCell,
        props: { classes, getProductStatus, markFavourite, removeFavourite }
      },
      {
        collection: { selectedAttributes }
      }
    ] = [this, stores]

    if (!columns[col]) {
      return
    }

    if (panel === grid.columnHeaders) {
      // const { binding, format } = columns[col]
    } else if (panel === grid.rowHeaders) {
      this.rowById[rows[row].dataItem.id] = row
    } else if (panel === cells) {
      const { binding, format } = columns[col]
      let cellDataType = binding

      const { rowItem: product } = this.getDataForRowAndCol(e.row, e.col)

      const {
        active,
        mmcName,
        materialName,
        colorName,
        description,
        image,
        id,
        productRetailStatus,
        productStatus,
        favourites,
        avgRatingCount,
        favouriteCount
      } = getProductDetails(product)
      // const tags =
      //   product && product.assortmentProducts
      //     ? getProductTags(product.assortmentProducts)
      //     : []
      const isActive = product && product.product ? product.product.active : null

      switch (binding) {
        case bindings.productImage: {
          if (
            // eslint-disable-next-line eqeqeq
            product == grid.editableCollectionView.currentEditItem &&
            e.cell.childNodes.length === 1
          ) {
            //(e.cell.firstChild as HTMLInputElement).value = (rowItem as ProductDefinition).name
          } else {
            renderReactIntoGridCell(
              <ApolloProvider client={apolloClient}>
                <MuiThemeProvider theme={MainTheme}>
                  <div style={{ display: 'flex' }}>
                    <div style={{ position: 'relative', marginTop: 16 }}>
                      <div>
                        {selectedAttributes['tags'] &&
                          getProductStatus(productStatus, isActive) && (
                            <ProductStatus
                              status={getProductStatus(productStatus, isActive)}
                              style={{ left: 6, top: 6 }}
                            />
                          )}
                      </div>
                      <ProductMedia
                        className={classes.cursorPointer}
                        onClick={() => nav.showDetail(id)}
                        image={getImageUrl(image)}
                        imgWidth={IMAGE_WIDTH}
                        width={IMAGE_CONTAINER_WIDTH}
                        height={IMAGE_CONTAINER_WIDTH}
                      />
                    </div>
                    <ProductDetail>
                      <ProductInfo
                        ellipsis={false}
                        status={productRetailStatus || 'Carry over'}
                        name={description}
                        sku={mmcName}
                      />

                      {selectedAttributes['composition'] && (
                        <ProductInfoItem
                          title={'Composition'}
                          content={
                            <Typography variant='caption'>{materialName}</Typography>
                          }
                        />
                      )}

                      {selectedAttributes['color'] && (
                        <ProductInfoItem
                          title={'Color'}
                          content={
                            <div className={classes.productWithColorbox}>
                              <Typography variant='caption'>
                                {colorName ? colorName : 'NA'}
                              </Typography>
                            </div>
                          }
                        />
                      )}
                      {selectedAttributes['actions'] && (
                        <ProductActions>
                          <ProductActionItem>
                            <Icon
                              className={classes.cursorPointer}
                              color={favourites.length ? 'error' : 'disabled'}
                              onClick={() =>
                                !favourites.length
                                  ? markFavourite(id, active)
                                  : removeFavourite(id, active)
                              }
                            >
                              favorite
                            </Icon>
                            <Typography variant='caption'>{favouriteCount}</Typography>
                          </ProductActionItem>
                          <ProductActionItem>
                            <Icon
                              className={classes.cursorPointer}
                              style={{
                                color: avgRatingCount ? '#FF9800' : 'lightgrey'
                              }}
                              onClick={() => nav.showDetail(id)}
                            >
                              star
                            </Icon>
                            {avgRatingCount > 0 && (
                              <Typography variant='caption'>{avgRatingCount}</Typography>
                            )}
                          </ProductActionItem>
                        </ProductActions>
                      )}
                    </ProductDetail>
                  </div>
                </MuiThemeProvider>
              </ApolloProvider>,
              e.cell
            )
          }
          break
        }
        default: {
          if (format === 'd') {
            e.cell.innerHTML = ReactDOMServer.renderToStaticMarkup(
              <TimeAgo date={product[binding]} />
            )
          }
        }
      }

      e.cell.classList.add(css.cell)
      e.cell.setAttribute('data-type', cellDataType)
    }
  }
}
