import { CentricTheme, StyledProps } from '@components/3rd-party/mui'
import {
  ProductActionItem,
  ProductActions
} from '@components/UI-Components/ProductCardOld/ProductActions'
import { ProductDetail } from '@components/UI-Components/ProductCardOld/ProductDetails'
import { ProductInfo } from '@components/UI-Components/ProductCardOld/ProductInfo'
import { ProductInfoItem } from '@components/UI-Components/ProductCardOld/ProductInfoItem'
import { ProductMedia } from '@components/UI-Components/ProductCardOld/ProductMedia'
import { ProductStatus } from '@components/UI-Components/ProductCardOld/ProductStatus'
import Icon from '@material-ui/core/Icon'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { IPermissions } from '@routes'
import { getProductDetails } from '@services/collectionServices'
import { getImageUrl } from '@services/imageService'
import { nav, stores } from '@stores'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import React from 'react'

const IMAGE_WIDTH = 114
const IMAGE_CONTAINER_WIDTH = 145

interface IProductCard extends StyledProps {
  product?: any
  removeFavourite: (channelId: any, productId: any) => void
  markFavourite: (channelId: any, productId: any) => void
  getProductStatus: (status: any, isActive: any) => any
  handleTagDrop: (product: any, type: any, id: any) => any
  permissions?: IPermissions
  isReginallMerchandiser?: Boolean
}

const styles = (theme: CentricTheme) => ({
  productCard: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  productWithColorbox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  cursorPointer: {
    cursor: 'pointer'
  },
  flex: {
    display: 'flex'
  },
  productStatus: {
    position: 'relative',
    marginTop: 16
  },
  rating: {
    color: '#FF9800'
  },
  greyColor: {
    color: 'lightgrey'
  },
  productDetailsContainerWitoutOverflow: {
    overflow: 'hidden !important',
    left: '0 !important',
    width: '200px'
  }
})

@((withStyles as any)(styles))
@observer
export class ActivityProductCard extends React.Component<IProductCard> {
  render() {
    const [
      {
        classes,
        product,
        isReginallMerchandiser,
        getProductStatus,
        markFavourite,
        removeFavourite
      },
      {
        collection: { selectedAttributes },
        assortment: { getRetailPrice }
      }
    ] = [this.props, stores]

    const {
      active,
      mmcName,
      retailPrice,
      materialName,
      colorName,
      description,
      image,
      id,
      productRetailStatus,
      productStatus,
      favourites,
      avgRatingCount,
      favouriteCount,
      attributes,
      comments,
      zoneRetailPrice
    } = getProductDetails(product)

    const isActive = product && product.product ? product.product.active : null
    const filteredAttributes = attributes.filter(
      attr => selectedAttributes[attr.definition.name]
    )
    const assortmentRetailPrice = !isReginallMerchandiser ? retailPrice : zoneRetailPrice

    return (
      <div className={classes.flex}>
        <div className={classes.productStatus}>
          <div>
            {getProductStatus(productStatus, isActive) && (
              <ProductStatus status={getProductStatus(productStatus, isActive)} />
            )}
          </div>
          <ProductMedia
            className={classes.cursorPointer}
            onClick={() => nav.showDetail(id)}
            image={getImageUrl(image)}
            imgWidth={IMAGE_WIDTH}
            width={IMAGE_CONTAINER_WIDTH}
            height={IMAGE_CONTAINER_WIDTH}
          />
        </div>
        <ProductDetail className={classes.productDetailsContainerWitoutOverflow}>
          <ProductInfo
            ellipsis={false}
            status={productRetailStatus || ''}
            name={description}
            sku={mmcName}
          />

          {selectedAttributes['composition'] && (
            <ProductInfoItem
              title={'Composition'}
              content={<Typography variant='caption'>{materialName}</Typography>}
            />
          )}

          {selectedAttributes['color'] && (
            <ProductInfoItem
              title={'Color'}
              content={
                <div className={classes.productWithColorbox}>
                  <Typography variant='caption'>
                    {colorName ? colorName : 'NA'}
                  </Typography>
                </div>
              }
            />
          )}
          {selectedAttributes['price'] && (
            <ProductInfoItem
              title={'Retail Price'}
              content={
                <Typography variant='caption'>
                  {getRetailPrice(assortmentRetailPrice)}
                </Typography>
              }
            />
          )}
          {filteredAttributes.map(
            ({
              id,
              strVal,
              intVal,
              definition: {
                displayName: { singular }
              }
            }) => {
              return (
                <ProductInfoItem
                  key={id}
                  title={singular}
                  content={
                    <Typography variant='caption'>{strVal || intVal || 'NA'}</Typography>
                  }
                />
              )
            }
          )}
          {selectedAttributes['actions'] && (
            <ProductActions>
              <ProductActionItem>
                <Icon
                  className={classes.cursorPointer}
                  color={favourites.length ? 'error' : 'disabled'}
                  onClick={() =>
                    !favourites.length
                      ? markFavourite(id, active)
                      : removeFavourite(id, active)
                  }
                >
                  favorite
                </Icon>
                <Typography variant='caption'>{favouriteCount}</Typography>
              </ProductActionItem>
              <ProductActionItem>
                <Icon
                  className={classNames(
                    classes.cursorPointer,
                    avgRatingCount ? classes.rating : classes.greyColor
                  )}
                  onClick={() => nav.showDetail(id)}
                >
                  star
                </Icon>
                {avgRatingCount > 0 && (
                  <Typography variant='caption'>{avgRatingCount}</Typography>
                )}
              </ProductActionItem>
              <ProductActionItem>
                <Icon
                  className={classes.cursorPointer}
                  style={{
                    color: comments.length ? '#FF9800' : 'lightgrey'
                  }}
                  onClick={() => nav.showDetail(id)}
                >
                  comment
                </Icon>
              </ProductActionItem>
            </ProductActions>
          )}
        </ProductDetail>
      </div>
    )
  }
}
