/*
 * @Author: Raeesaa Metkari
 * @Date: 2020-04-14 00:00:35
 * @Last Modified by: Raeesaa Metkari
 * @Last Modified time: 2020-04-15 18:25:37
 */
export enum DoorTableViewFieldMapping {
  Active = 'active',
  Code = 'code',
  Name = 'name',
  Address = 'address',
  City = 'city',
  Country = 'country',
  Currency = 'currency',
  Currency2 = 'currency2',
  PriceGroup = 'priceGroup',
  NumberOfDoors = 'numberOfDoors',
  Clusters = 'isExpanded',
  SalesDistrict = 'salesDistrict',
  Zone = 'zone'
}
