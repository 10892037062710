/*
 * @Author: Raeesaa Metkari
 * @Date: 2020-07-20 21:32:20
 * @Last Modified by:   Raeesaa Metkari
 * @Last Modified time: 2020-07-20 21:32:20
 */

export enum BaseRoute {
  showroom = '/showroom',
  wholesale = '/wholesale'
}
