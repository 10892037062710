import GET_CLIENT_ASSORTMENT_PRODUCT_DETAIL from './getClientAssortmentProductForDetail.graphql'
import ORDER_PRODUCT_DETAIL from './getOrderProduct.graphql'
import GET_ASSOCIATED_LOOKS from './getAssociatedLooks.graphql'
import GET_ORDER_PRODUCT_NOTIFICATIONS from './getOrderProductNotifications.graphql'

export {
  GET_CLIENT_ASSORTMENT_PRODUCT_DETAIL,
  ORDER_PRODUCT_DETAIL,
  GET_ASSOCIATED_LOOKS,
  GET_ORDER_PRODUCT_NOTIFICATIONS
}
export * from './hooks'
