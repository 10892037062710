import React from 'react'
import { IPermissions } from '@routes'
import { I18NextProvider } from '@i18n/i18next-context'

interface IContent {
  // isGlobalMerchandiser: boolean
  permissions: IPermissions
}

export class Content extends React.Component<IContent> {
  render() {
    const { component: Wrapped }: any = this.props

    return (
      <I18NextProvider>
        <React.Fragment>
          <Wrapped {...this.props} />
        </React.Fragment>
      </I18NextProvider>
    )
  }
}
