import '@styles/ProductMedia/style.css'
import cn from 'classnames'
import React from 'react'
import ReactPlayer, { ReactPlayerProps } from 'react-player'

export interface IProductVideo extends ReactPlayerProps {
  /**
   * custom props here
   */
  className?: string
}

export const ProductVideo: React.FC<IProductVideo> = props => {
  const { url, controls, children, style, height, width, className, ...rest } = props

  return (
    <div className={cn('product-media-video', className)}>
      {/*ReactPlayer doesn't have className as props, override either style or
        pass height and width*/}
      <ReactPlayer
        url={url}
        controls={controls}
        height={height}
        width={width}
        style={style}
        {...rest}
      />
      {children}
    </div>
  )
}
