import { useQuery } from '@apollo/client/react/hooks/useQuery'
import { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions'
import { GET_CLIENTS_DISCOUNTS, GET_SPEC_ITEMS_BY_SUBTYPE } from '.'
import { VIPChannel } from '@modules/common/models/enums/VIPChannel'
import { CriteriaOperationType } from '@modules/models'
/**
 * Hook for querying client discounts
 * @param {WatchQueryFetchPolicy} fetchPolicy
 * @returns useQuery hook
 */
export const useClientDiscounts = (
  fetchPolicy: WatchQueryFetchPolicy = 'cache-first'
) => {
  return useQuery(GET_CLIENTS_DISCOUNTS, {
    variables: {
      where: {
        Channel: { operation: CriteriaOperationType.NE, value: VIPChannel.Retail }
      }
    },
    fetchPolicy
  })
}

/**
 * Hook for querying spec items for given subtype
 * @param {subType} variables
 * @param {WatchQueryFetchPolicy} fetchPolicy
 * @returns useQuery hook
 */
export const useSpecItemsBySubType = (
  subType: string,
  fetchPolicy: WatchQueryFetchPolicy = 'cache-first'
) => {
  return useQuery(GET_SPEC_ITEMS_BY_SUBTYPE, {
    variables: {
      subType
    },
    skip: !subType,
    fetchPolicy
  })
}
