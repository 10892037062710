import { config } from '@config'
import {
  GET_BSG_AND_CAMPAIGNS,
  GET_DRAFT_FOR_CLIENT_ORDER
} from '@modules/showroom/buyingSessions/graphql'
import { stores } from '@stores'
import { apolloClient } from '@stores/apollo'
import findIndex from 'lodash/findIndex'
import map from 'lodash/map'
import { action, observable } from 'mobx'
import { computedFn } from 'mobx-utils'
import qs from 'query-string'
import { BuyingSessionGroupService } from '../service/BuyingSessionSelectionService'

export class BuyingSessionSelectionStore {
  private buyingSessionAPIService = new BuyingSessionGroupService()
  @observable activeIndex = 0
  @observable getDraftClientOrderDataProgress = false
  @observable slideChanged = true
  @observable buyingSessionGroupFetching = false
  @observable.ref buyingSessionGroupFetchError = null
  @observable buyingSessionGroups = []
  @observable searchTerm = ''
  @observable showSearchInput = false

  @action onSelectionArrowRightClick = () => {
    this.activeIndex = this.activeIndex + 1
    this.slideChanged = true
  }
  @action onSelectionArrowLeftClick = () => {
    this.activeIndex = this.activeIndex - 1
    this.slideChanged = true
  }
  @action onChangeIndex = index => {
    this.activeIndex = index
  }
  @action clearActiveIndex = () => {
    this.activeIndex = 0
    this.slideChanged = false
  }

  @action setActiveIndexOnRedirect = buyingSessionGroups => {
    const {
      nav: {
        queryParams: { buyingSessionGroupId }
      }
    } = stores
    let dataIndex = findIndex(buyingSessionGroups, { Id: buyingSessionGroupId })
    this.onChangeIndex(dataIndex)
    this.slideChanged = true
  }

  @action
  toggleShowSearchInput = () => {
    this.showSearchInput = !this.showSearchInput
    this.applySearch('')
  }

  @action
  applySearch = key => {
    if (key !== this.searchTerm) {
      this.searchTerm = key
      this.fetchBuyingSessionGroups()
    }
  }

  fetchBuyingSessionGroups = async () => {
    const {
      nav: {
        queryParams: { clientId }
      }
    } = stores
    if (!clientId) return
    this.buyingSessionGroupFetching = true
    try {
      const [response, bsgCampaignData] = await Promise.all([
        this.buyingSessionAPIService.getBuyingSessionGroups(clientId, this.searchTerm),
        apolloClient
          .query({
            query: GET_BSG_AND_CAMPAIGNS,
            variables: {
              sessionStatus:
                config?.appConfig?.enumerations?.buyingSessionStatus
                  ?.BUYING_SESSION_STATUS_VALUES.BUYING,
              retailSessionStatus:
                config?.appConfig?.enumerations?.retailBuyingSessionStatus
                  ?.BUYING_SESSION_STATUS_VALUES.BUYING,
              clientId
            }
          })
          .then(({ data }) => data?.findSpecificationItemDefinition?.Items ?? [])
      ])
      if (response) {
        const bsgCampaignMap = bsgCampaignData.reduce(
          (acc, campaign) => ({
            ...acc,
            ...(campaign?.Document?.CurrentRevision?.Id || campaign?.LinkageUrl
              ? {
                  [campaign.BuyingSessionGroup.Id]: campaign?.Id
                }
              : {})
          }),
          {}
        )
        this.buyingSessionGroups = map(response, bsg => ({
          ...bsg,
          campaignId: bsgCampaignMap[bsg.id]
        }))
      }
      this.buyingSessionGroupFetching = false
    } catch (e) {
      this.buyingSessionGroupFetching = false
      this.buyingSessionGroupFetchError = e
    }
  }

  @action redirectUser = async (buyingSessionGroupId, clientId) => {
    this.getDraftClientOrderDataProgress = true
    const {
      nav: {
        history,
        location: { pathname }
      }
    } = stores
    try {
      let clientOrderIdData = await apolloClient.query({
        query: GET_DRAFT_FOR_CLIENT_ORDER,
        variables: {
          buyingSessionGroupId: buyingSessionGroupId,
          clientId: clientId
        },
        fetchPolicy: 'network-only'
      })
      if (clientOrderIdData?.data?.findVIPClientOrder?.Items?.length) {
        history.push({
          pathname: `${pathname}/activities/collection`,
          search: qs.stringify({
            clientId,
            buyingSessionGroupId,
            orderId: clientOrderIdData.data.findVIPClientOrder.Items[0].Id
          })
        })
      } else {
        history.push({
          pathname: `${pathname}/activities`,
          search: qs.stringify({
            clientId,
            buyingSessionGroupId
          })
        })
      }
    } finally {
      this.getDraftClientOrderDataProgress = false
    }
  }

  getCampaignByBSGWhere = computedFn(
    (sessionStatus: string, clientId: string, bsgId: string) => {
      if (sessionStatus && clientId && bsgId) {
        return {
          Subtype: { Name: { operation: 'EQ', value: 'Campaign' } },
          BuyingSessionGroup: {
            Id: { operation: 'EQ', value: bsgId },
            GIV_VIPBuyingSessionGroup_Status_enum: {
              operation: 'EQ',
              value: sessionStatus
            }
          },
          Clients: { Id: { operation: 'EQ', value: clientId } }
        }
      }
      return null
    }
  )

  buyingSessionGroupByColAndRowIndex = computedFn(
    (colIndex: number, rowIndex: number) => {
      return this.buyingSessionGroups[rowIndex * 2 + colIndex]
    }
  )

  @action
  reset = () => {
    this.buyingSessionGroups = []
    this.searchTerm = ''
  }
}

export const showroomBuyingSessionSelection = new BuyingSessionSelectionStore()
