import { withIconHOC } from '@components/std-components/Icons'
import { CardIcon, KPIIcon, ListIcon } from '../resources'

export enum View {
  Product = 'Product',
  KPI = 'KPI',
  List = 'List',
  AddProduct = 'AddProduct'
}

// TODO: Add translation keys
export const ViewMapping = {
  Product: 'Card View',
  List: 'List View',
  KPI: 'KPI View'
}

export const ViewIconMapping = {
  Product: withIconHOC({ imageSrc: CardIcon }),
  List: withIconHOC({ imageSrc: ListIcon }),
  KPI: withIconHOC({ imageSrc: KPIIcon })
}

export const getViews = () => {
  return [
    {
      id: View.Product,
      name: ViewMapping[View.Product],
      icon: ViewIconMapping[View.Product]
    },
    {
      id: View.List,
      name: ViewMapping[View.List],
      icon: ViewIconMapping[View.List]
    },
    {
      id: View.KPI,
      name: ViewMapping[View.KPI],
      icon: ViewIconMapping[View.KPI]
    }
  ]
}
