export enum ClientDiscountFieldMapping {
  ClientName = 'name',
  Category = 'category',
  Activity = 'activity',
  Discount = 'discount'
}

export enum DiscountNewRow {
  id = 'newRow'
}
