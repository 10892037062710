/*
 * @Author: Raeesaa Metkari
 * @Date: 2020-03-23 13:45:20
 * @Last Modified by: Raeesaa Metkari
 * @Last Modified time: 2020-03-23 14:30:14
 */

/*
 * @Author: Raeesaa Metkari
 * @Date: 2020-03-20 17:44:58
 * @Last Modified by: Raeesaa Metkari
 * @Last Modified time: 2020-03-23 12:18:00
 */

export enum BuyingSessionGroupNavSection {
  Collection = 'collection',
  Tags = 'tags'
}

export enum BuyingSessionGroupNavSectionDisplayName {
  GroupSummary = 'Collection',
  Tags = 'Tags'
}

export enum BuyingSessionGroupNavView {
  Collection = 'collection',
  BuyingDocuments = 'documents',
  Lookbook = 'lookbook',
  Campaign = 'campaign',
  ClientExclusions = 'clientExclusions',
  WholesaleClusters = 'wholesaleClusters',
  FreeTags = 'freeTags',
  Drops = 'drops',
  Overview = 'overview'
}

export enum BuyingSessionGroupNavDisplayName {
  Collection = 'Collection',
  Campaign = 'Campaign',
  BuyingDocuments = 'Documents',
  Lookbook = 'Lookbook',
  WholesaleClusters = 'Wholesale Clusters',
  Clusters = 'Clusters',
  Channles = 'Availability',
  FreeTags = 'Free Tags',
  Drops = 'Drops',
  RetailClusters = 'Retail Clusters',
  wholesaleTags = 'Wholesale Tags',
  retailTags = 'Retail Tags',
  wholesaleFreeTags = 'Wholesale Free Tags',
  wholesaleDrops = 'Wholesale Drops',
  retailFreeTags = 'Retail Free Tags',
  RetailSalesPeriod = 'Retail Sales Period'
}

export enum BuyingSessionNavView {
  RecommendedAssortment = 'recommendedAssortment',
  ClientRecommendedAssortment = 'clientRecommendedAssortment'
}

export enum BuyingSessionNavDisplayName {
  RecommendedAssortment = 'RECOMMENDED ASSORT.',
  ClientRecommendedAssortment = 'MARKET.RECOMD.ASSORT.',
  ZoneRecommendedAssortment = 'ZONE RECOMD. ASSORT.'
}

/**
 * NOTE: In case view toolbar title is not found, display
 * section toolbar title instead
 */
export enum BuyingSessionGroupNavToolbarTitle {
  collection = 'Collection',
  documents = 'Buying Documents',
  lookbook = 'Lookbook',
  campaign = 'CAMPAIGNS'
}

export enum BuyingSessionAssortmentSection {
  clientAssortment = 'clientAssortment',
  clusterAssortment = 'clusterAssortment',
  doorAssortment = 'doorAssortment'
}

export enum BuyingSessionAssortmentType {
  client = 'client',
  cluster = 'cluster',
  door = 'door'
}

export enum ChannelSubSection {
  Wholesale = 'wholesale',
  Retail = 'retail'
}
