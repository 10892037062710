export enum RowType {
  targetQty = 'targetQty',
  totalSizedQtyAnalytics = 'totalSizedQtyAnalytics',
  totalSizedQty = 'totalSizedQty',
  sizeQty = 'sizeQty'
}

export enum RowTypeLabel {
  targetQty = 'Draft Quantity',
  totalSizedQty = 'Total Sized Quantity',
  totalSizedQtyAnalytics = 'Total Size Quantity Analytics'
}
