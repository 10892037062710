import { QueryResult } from '@apollo/client/react'
import { Query } from '@apollo/client/react/components/Query'
import { CircularLoadingProgress } from '@components/UI-Components/CircularLoading'
import { PrimaryButton } from '@components/UI-Components/PrimaryButton'
import { SecondaryButton } from '@components/UI-Components/SecondaryButton'
import Drawer from '@material-ui/core/Drawer'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { IPermissions } from '@routes'
import { getProductFilters } from '@services/collectionServices'
import { stores } from '@stores'
import { observer } from 'mobx-react'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { GET_COLLECTION_FILTERS } from '../graphql/collectionFilters'
import { RenderList } from './RenderList'

interface ICollectionFilter {
  classes?: any
  match?: any
  onFilterCheck?: (filterType, index) => any
  permissions?: IPermissions
  filterQuery?: any
  variables?: any
}

const styles = {
  list: {
    width: 500,
    padding: '25px 8px 25px 25px'
  },
  headerContent: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: 20,
    marginBottom: 20
  },
  rightHeaderDiv: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center'
  },
  cancelButton: {
    marginRight: 16
  }
}

@((withStyles as any)(styles))
@(withRouter as any)
@observer
export class CollectionFilterComponent extends React.Component<ICollectionFilter> {
  render() {
    const [
      {
        props: { variables, filterQuery, classes, permissions }
      },
      {
        filter: { openCollectionFilter, applyFilters, cancelFilltering, clearAllFilters }
      }
    ] = [this, stores]

    return (
      <Drawer open={openCollectionFilter} onClose={cancelFilltering}>
        <div className={classes.list}>
          <div className={classes.headerContent}>
            <Typography variant='h5'>Filters</Typography>
            <div className={classes.rightHeaderDiv}>
              <SecondaryButton style={{ marginRight: 16 }} onClick={cancelFilltering}>
                Cancel
              </SecondaryButton>
              <SecondaryButton style={{ marginRight: 16 }} onClick={clearAllFilters}>
                Clear All
              </SecondaryButton>
              <PrimaryButton onClick={applyFilters}>Apply</PrimaryButton>
            </div>
          </div>
          <Query
            query={filterQuery ? filterQuery : GET_COLLECTION_FILTERS}
            fetchPolicy='cache-and-network'
            variables={variables}
          >
            {({ data, error, loading }: QueryResult) => {
              if (loading && !data)
                return (
                  <div>
                    <CircularLoadingProgress />
                  </div>
                )
              if (error) return <div>Error</div>
              if (!error) {
                return (
                  <RenderList
                    filters={getProductFilters(data)}
                    permissions={permissions}
                  />
                )
              }
            }}
          </Query>
        </div>
      </Drawer>
    )
  }
}
