/*
 * @Author: Raeesaa Metkari
 * @Date: 2019-12-13 17:43:14
 * @Last Modified by: Raeesaa Metkari
 * @Last Modified time: 2019-12-20 00:55:31
 */
import { AttributeList } from '@components/UI-Components/AttributeList'
import { AvgDepthTextFieldList } from '@components/UI-Components/AverageDepth/AvgDepthTextFieldList'
import { AWSTextFieldList } from '@components/UI-Components/AverageWeeklySale/AWSTextFieldList'
import { ChipList } from '@components/UI-Components/ChipList'
import { LabelList } from '@components/UI-Components/LabelList'
import { ListProductCard } from '@components/UI-Components/ListProductCard'
import { OrderQuantityList } from '@components/UI-Components/OrderQuantity/OrderQuantityList'
import { StandardListViewLabel } from '@components/UI-Components/StandardListViewLabel/StandardListViewLabel'
import { ListViewColumnDefinition } from '@components/UI-Components/StandardTableView/models/ListviewColumnDefinition'
import { ChipListEnum } from '@modules/common/models/enums/ChipListEnum'
import { ListViewProduct } from '@modules/common/models/interfaces/ListViewProduct'
import { ZoneWiseCommentsComponent } from '@modules/retail/collection/component/ZoneWiseComments'
import { stores } from '@stores'
import upperFirst from 'lodash/upperFirst'
import React from 'react'

/**
 * Class with methods for returning components required to render product cell
 */
class ListViewComponentService {
  getProductInfoComponent(
    product: ListViewProduct,
    columnDefinition: ListViewColumnDefinition
  ) {
    return (
      <ListProductCard
        data={product[columnDefinition.product.key]}
        canDelete={product.canDelete}
        product={product}
        showFlag={product.hasCounterOffer}
      />
    )
  }

  getProductAttributeComponent = (
    product: ListViewProduct,
    columnDefinition: ListViewColumnDefinition
  ) => {
    return <AttributeList productAttributes={product[columnDefinition.product.key]} />
  }

  getChipListComponent = (
    product: ListViewProduct,
    columnDefinition: ListViewColumnDefinition
  ) => {
    const {
      wsBuyingSessionProduct: { handleTagDelete, handleTagClick }
    } = stores
    return (
      <ChipList
        tags={product[columnDefinition.product.key]}
        type={columnDefinition.product.key}
        product={product}
        chipsize={ChipListEnum.small}
        handleClick={
          product[`canEdit${upperFirst(columnDefinition.product.key)}`]
            ? handleTagClick
            : null
        }
        handleChipDelete={
          product[`canDelete${upperFirst(columnDefinition.product.key)}`]
            ? handleTagDelete
            : null
        }
      />
    )
  }

  getLabelComponent = (
    product: ListViewProduct,
    columnDefinition: ListViewColumnDefinition
  ) => {
    return (
      <StandardListViewLabel
        value={product[columnDefinition.product.key]}
        {...columnDefinition.props}
      />
    )
  }

  getLabelListComponent = (
    product: ListViewProduct,
    columnDefinition: ListViewColumnDefinition
  ) => {
    return (
      <LabelList
        data={product[columnDefinition.product.key]}
        dataKey={columnDefinition.product.path}
        hasGlobalRow={product.hasGlobalRow}
        isFloatingPointValue={columnDefinition.isFloatingPointValue}
        {...columnDefinition.props}
      />
    )
  }

  getAWSComponent = (
    product: ListViewProduct,
    columnDefinition: ListViewColumnDefinition
  ) => {
    return (
      <AWSTextFieldList
        data={product[columnDefinition.product.key]}
        dataKey={columnDefinition.product.path}
        product={product}
        hasGlobalRow={product.hasGlobalRow}
        canEdit={product.canEditKPIs}
        isFloatingPointValue={columnDefinition.isFloatingPointValue}
        {...columnDefinition.props}
      />
    )
  }

  getAvgDepthComponent = (
    product: ListViewProduct,
    columnDefinition: ListViewColumnDefinition
  ) => {
    return (
      <AvgDepthTextFieldList
        data={product[columnDefinition.product.key]}
        dataKey={columnDefinition.product.path}
        product={product}
        hasGlobalRow={product.hasGlobalRow}
        canEdit={product.canEditKPIs}
        isFloatingPointValue={columnDefinition.isFloatingPointValue}
        {...columnDefinition.props}
      />
    )
  }

  getOrderQuantityComponent = (
    product: ListViewProduct,
    columnDefinition: ListViewColumnDefinition
  ) => {
    return (
      <OrderQuantityList
        data={product[columnDefinition.product.key]}
        highlightGlobalQty={product.highlightGlobalQty}
        dataKey={columnDefinition.product.path}
        product={product}
        hasGlobalRow={product.hasGlobalRow}
        canEdit={product.canEditOrderQuantity}
        {...columnDefinition.props}
      />
    )
  }

  getCommentsComponent = (
    product: ListViewProduct,
    columnDefinition: ListViewColumnDefinition
  ) => {
    return (
      <ZoneWiseCommentsComponent
        betData={product[columnDefinition.product.key]}
        {...columnDefinition.props}
      />
    )
  }

  getChipListAndFlagComponent = (
    product: ListViewProduct,
    columnDefinition: ListViewColumnDefinition
  ) => {
    return <></>
  }
}

export const listViewComponentService = new ListViewComponentService()
