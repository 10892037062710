import { strings, stores } from '@stores'
import {
  getProductDetails,
  getZoneLevelProductValue,
  getZoneSellthrough
} from '@services/collectionServices'
import { User } from '@models'
import { storage } from '@services/storageService'
import { UserRoles } from '@modules/common/models/enums/UserRoles'
import {
  convertNumberToPercentage,
  getChildrenMetaData,
  getFilterProductsByHierarchy
} from './commonServices'
import { CLUSTER_ASSORTMENT_PRODUCTS } from '@modules/retail/assortment/graphql/clusterAssortmentProducts'
import { STORE_ASSORTMENT_PRODUCTS } from '@modules/retail/assortment/graphql/storeAssortmentProducts'
import { ZONE_ASSORTMENT_PRODUCTS } from '@modules/retail/assortment/graphql/zoneAssortmentProducts'
import { getDateDiff } from '@modules/retail/salesPeriod/services/SalesPeriodServices'

// type KPIRollUps = {
//   quantity: number
//   value: number
//   aws: string | number
//   depth: string | number
//   sku: string | number
//   salesQuantity: number
//   storeCount: string | number
// }

type QueryMeta = {
  query: object
  variables: any
}

const getAssortmentBetData = (
  betBuyData: { betBuyValues: any },
  assortmentProduct: {
    product: any
    bet: any
    buy: any
    retailPrice: any
    storeCount: any
    id: any
    comments: Array<{}>
  },
  markUpList: any[],
  zone: { id: any },
  rangePlanData: any
) => {
  // Extract assortment product properties
  const {
    product,
    bet: assortmentBet,
    buy: assortmentBuy,
    retailPrice: assortmentProductRetailPrice,
    storeCount: assortmentCount,
    id: assortmentProductId,
    comments
  } = assortmentProduct

  const {
    site: { globalCurrencyCode },
    assortment: { getRetailPrice },
    nav: {
      params: { assortmentType }
    }
  } = stores
  const user = storage.getItem<User>('user')
  const role = user.roles[0].name

  let betData = []
  const { betBuyValues } = betBuyData

  const {
    salesPeriod,
    buyingSession,
    family,
    line,
    activity,
    retailPrice: productRetailPrice
  } = getProductDetails(product)

  const weeks = salesPeriod
    ? getDateDiff(salesPeriod.endDate, salesPeriod.startDate)
    : null
  const zoneMarkUp = markUpList.find(({ description }) => description === activity.value)
  const zoneSellThroughList = getZoneSellthrough(
    rangePlanData,
    buyingSession,
    family,
    line
  )

  // assortment level calculations
  let assortmentProductQuantities = 0
  let totalAssortmentBetValue = 0
  const markUp =
    zoneMarkUp && zoneMarkUp.zones
      ? zoneMarkUp.zones.find(zoneInMarkup => zoneInMarkup && zoneInMarkup.id === zone.id)
      : null

  let metadata = betBuyValues && getChildrenMetaData(activity, family, line)
  // Filter products by family / line
  const filterProducts =
    betBuyValues && getFilterProductsByHierarchy(betBuyValues, metadata)
  if (assortmentBet && assortmentBet.quantity) {
    filterProducts &&
      filterProducts.forEach(product => {
        // eslint-disable-next-line array-callback-return
        product.assortmentProducts.filter(({ bet, retailPrice }) => {
          if (bet && bet.quantity > 0) {
            assortmentProductQuantities = assortmentProductQuantities + bet.quantity
            totalAssortmentBetValue =
              totalAssortmentBetValue +
              getZoneLevelProductValue(
                retailPrice,
                bet.quantity,
                markUp,
                role,
                product.retailPrice
              )
          }
        })
      })
  }

  const zoneLevelProductRetailPrice =
    assortmentType === strings.store ? 512 : assortmentProductRetailPrice
  const assortmentLevelStoreCount = assortmentType === strings.store ? 1 : assortmentCount
  const value = assortmentBet
    ? parseFloat(
        getZoneLevelProductValue(
          assortmentProductRetailPrice,
          assortmentBet.quantity,
          markUp,
          role,
          productRetailPrice
        ).toFixed(2)
      )
    : null

  const buyValue = assortmentBuy
    ? parseFloat(
        getZoneLevelProductValue(
          assortmentProductRetailPrice,
          assortmentBuy.quantity,
          markUp,
          role,
          productRetailPrice
        ).toFixed(2)
      )
    : null

  const mixValue =
    value && totalAssortmentBetValue > 0 ? value / totalAssortmentBetValue : 0
  const mixQuantity =
    assortmentBet && assortmentProductQuantities > 0
      ? assortmentBet.quantity / assortmentProductQuantities
      : 0
  const depth =
    assortmentBet && assortmentProductQuantities && assortmentLevelStoreCount
      ? parseFloat((assortmentBet.quantity / assortmentLevelStoreCount).toFixed(2))
      : 0

  const buyQty = assortmentBuy && assortmentBuy.quantity ? assortmentBuy.quantity : 0
  const betQty = assortmentBet && assortmentBet.quantity ? assortmentBet.quantity : 0
  //At ZOne - Sales QTY = QTYx(Sell-Through) At Global- Sales QTY = QTY x Average Sell-Through
  const sellThroughRecord = zoneSellThroughList.find(
    ({ zone: zoneInSellThrough }) => zoneInSellThrough && zoneInSellThrough.id === zone.id
  )
  const sellThrough = sellThroughRecord ? sellThroughRecord.sellThrough : null
  const salesQty =
    betQty && sellThrough ? parseFloat((betQty * sellThrough).toFixed(2)) : betQty
  //const aws = assortmentBet && weeks ? parseFloat((assortmentBet.quantity/weeks).toFixed(2)) : null
  // new rule AWS = Sales QTY / Nb Weeks. For store assortment use aws = quantity/weeks
  const aws =
    assortmentType === strings.store
      ? assortmentBet && weeks
        ? parseFloat((assortmentBet.quantity / weeks).toFixed(2))
        : 0
      : salesQty && weeks
      ? parseFloat((salesQty / weeks).toFixed(2))
      : 0
  const sku =
    aws && assortmentLevelStoreCount
      ? parseFloat((aws / assortmentLevelStoreCount).toFixed(2))
      : 0

  const assortmentProductValue =
    zoneLevelProductRetailPrice && zoneLevelProductRetailPrice.priceList && value
      ? convertToCurrencyLocaleString(
          role === UserRoles.REGIONAL_MERCHANDISER
            ? zoneLevelProductRetailPrice.priceList.currency.code
            : globalCurrencyCode,
          value
        )
      : value

  betData.push({
    id: assortmentProductId,
    type: assortmentType,
    betQty,
    buyQty,
    buyValueToSort: buyValue || 0,
    aws,
    mixQuantity: convertNumberToPercentage(mixQuantity),
    mixValue: convertNumberToPercentage(mixValue),
    depth: depth,
    value: assortmentProductValue || 0,
    sku,
    storeCount: assortmentLevelStoreCount,
    retailPrice: getRetailPrice(zoneLevelProductRetailPrice),
    salesQuantity: salesQty,
    weeks,
    sellThrough,
    zoneWiseComments: comments
  })

  return betData
}

const resetFilters = () => {
  const {
    product: { resetSort, clearRefetchQueries },
    search: { clearSearchKey },
    filter: { clearAllFilters }
  } = stores
  clearSearchKey()
  clearAllFilters()
  resetSort()
  clearRefetchQueries()
}

const getAssortmentBuyData = (
  betBuyData,
  assortmentProduct,
  markUpList,
  zone,
  rangePlanData
) => {
  // Extract assortment product properties
  const {
    product,
    bet: assortmentBet,
    buy: assortmentBuy,
    retailPrice: assortmentProductRetailPrice,
    storeCount: assortmentCount,
    id: assortmentProductId,
    comments
  } = assortmentProduct

  let buyData = []
  const { betBuyValues } = betBuyData
  const {
    site: { globalCurrencyCode },
    assortment: { getRetailPrice },
    nav: {
      params: { assortmentType }
    }
  } = stores
  const user = storage.getItem<User>('user')
  const role = user.roles[0].name

  const {
    salesPeriod,
    buyingSession,
    activity,
    family,
    line,
    retailPrice: productRetailPrice
  } = getProductDetails(product)

  const weeks = salesPeriod
    ? getDateDiff(salesPeriod.endDate, salesPeriod.startDate)
    : null
  const zoneMarkUp = markUpList.find(({ description }) => description === activity.value)
  const zoneSellThroughList = getZoneSellthrough(
    rangePlanData,
    buyingSession,
    family,
    line
  )

  //assortment level calculations
  let assortmentProductQuantities = 0
  let totalAssortmentBuyValue = 0
  const markUp =
    zoneMarkUp && zoneMarkUp.zones
      ? zoneMarkUp.zones.find(zoneInMarkup => zoneInMarkup && zoneInMarkup.id === zone.id)
      : null
  if (assortmentBuy && assortmentBuy.quantity) {
    ;(betBuyValues || []).forEach(product => {
      // eslint-disable-next-line array-callback-return
      product.assortmentProducts.filter(({ buy, retailPrice }) => {
        if (buy && buy.quantity > 0) {
          assortmentProductQuantities = assortmentProductQuantities + buy.quantity
          totalAssortmentBuyValue =
            totalAssortmentBuyValue +
            getZoneLevelProductValue(
              retailPrice,
              buy.quantity,
              markUp,
              role,
              product.retailPrice
            )
        }
      })
    })
  }

  const zoneLevelProductRetailPrice =
    assortmentType === strings.store ? 512 : assortmentProductRetailPrice
  const assortmentLevelStoreCount = assortmentType === strings.store ? 1 : assortmentCount

  const value = assortmentBuy
    ? parseFloat(
        getZoneLevelProductValue(
          assortmentProductRetailPrice,
          assortmentBuy.quantity,
          markUp,
          role,
          productRetailPrice
        ).toFixed(2)
      )
    : null
  const mixValue =
    value && totalAssortmentBuyValue > 0 ? value / totalAssortmentBuyValue : 0
  const mixQuantity =
    assortmentBuy && assortmentProductQuantities > 0
      ? assortmentBuy.quantity / assortmentProductQuantities
      : 0
  const depth =
    assortmentBuy && assortmentProductQuantities && assortmentLevelStoreCount
      ? parseFloat((assortmentBuy.quantity / assortmentLevelStoreCount).toFixed(2))
      : 0
  const buyQty = assortmentBuy && assortmentBuy.quantity ? assortmentBuy.quantity : 0
  const betQty = assortmentBet && assortmentBet.quantity ? assortmentBet.quantity : 0

  //At ZOne - Sales QTY = QTYx(Sell-Through) At Global- Sales QTY = QTY x Average Sell-Through
  const sellThroughRecord = zoneSellThroughList.find(
    ({ zone: zoneInSellThrough }) => zoneInSellThrough && zoneInSellThrough.id === zone.id
  )
  const sellThrough = sellThroughRecord ? sellThroughRecord.sellThrough : null
  const salesQty =
    buyQty && sellThrough ? parseFloat((buyQty * sellThrough).toFixed(2)) : buyQty
  //const aws = assortmentBuy && weeks ? parseFloat((assortmentBuy.quantity/weeks).toFixed(2)) : null
  // new rule AWS = Sales QTY / Nb Weeks. For store assortment use aws = quantity/weeks
  const aws =
    assortmentType === strings.store
      ? buyQty && weeks
        ? parseFloat((buyQty / weeks).toFixed(2))
        : 0
      : salesQty && weeks
      ? parseFloat((salesQty / weeks).toFixed(2))
      : 0
  const sku =
    aws && assortmentLevelStoreCount
      ? parseFloat((aws / assortmentLevelStoreCount).toFixed(2))
      : 0

  const assortmentProductValue =
    zoneLevelProductRetailPrice && zoneLevelProductRetailPrice.priceList && value
      ? convertToCurrencyLocaleString(
          role === UserRoles.REGIONAL_MERCHANDISER
            ? zoneLevelProductRetailPrice.priceList.currency.code
            : globalCurrencyCode,
          value
        )
      : value

  buyData.push({
    id: assortmentProductId,
    type: assortmentType,
    buyQty,
    betQty,
    buyValueToSort: value || 0,
    salesQty,
    aws,
    mixQuantity: convertNumberToPercentage(mixQuantity),
    mixValue: convertNumberToPercentage(mixValue),
    depth,
    value: assortmentProductValue || 0,
    sku,
    storeCount: assortmentLevelStoreCount,
    buyingSessionProductId: product?.id ?? '',
    retailPrice: getRetailPrice(zoneLevelProductRetailPrice),
    salesQuantity: salesQty,
    sellThrough,
    weeks,
    zoneWiseComments: comments
  })
  return buyData
}

/**
 * Method for returning query for assortment products along with variables
 * @param node - Object containing details of category (activity, family, line, etc) whose data is to be fetched
 * @returns {QueryMeta} - And object containing query and variables
 */
const buildAssortmentQueryBasedOnType = (node: {
  category: string
  childrenMeta?: Array<string>
  childrenMetaValues?: Array<string>
}): QueryMeta => {
  const { category: activity, childrenMeta, childrenMetaValues } = node
  const {
    nav: {
      params: { assortmentType }
    },
    product: { whereQueryVariablesForAssortmentProducts }
  } = stores

  const variables = whereQueryVariablesForAssortmentProducts(
    { name: activity },
    childrenMeta,
    childrenMetaValues,
    true
  )

  let query = CLUSTER_ASSORTMENT_PRODUCTS

  switch (assortmentType) {
    case strings.cluster:
      query = CLUSTER_ASSORTMENT_PRODUCTS
      break
    case strings.store:
      query = STORE_ASSORTMENT_PRODUCTS
      break
    case strings.zone:
      query = ZONE_ASSORTMENT_PRODUCTS
      break
  }

  return { query, variables }
}

/**
 * Method for converting number with currency code into string with currency symbol
 * @param {string} currencyCode - Code of currency
 * @param {number} value - Value
 */
const convertToCurrencyLocaleString = (
  currencyCode: string,
  value: number,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2
) => {
  try {
    return value.toLocaleString('en', {
      minimumFractionDigits,
      maximumFractionDigits,
      style: 'currency',
      currency: currencyCode
    })
  } catch (err) {
    /**
     * NOTE: toLocaleString will throw error in case currencyCode passed is in  valid or something it does not understand,
     * we will return value with currency code string in such cases
     */
    return currencyCode ? `${value} ${currencyCode}` : `${value}`
  }
}

export {
  getAssortmentBetData,
  getAssortmentBuyData,
  convertToCurrencyLocaleString,
  resetFilters,
  buildAssortmentQueryBasedOnType
}
