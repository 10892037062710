import { observable, action } from 'mobx'
import { stores } from '@stores'
import { ExecutionResult } from 'graphql/execution'
import { MutationFunctionOptions } from '@apollo/client/react/types/types'

export class ClusterStatusDropDownStore {
  @observable selectedPopOverIndex = ''
  @observable popOveranchorEl = null
  @observable clusterId = null
  @observable productId = null

  mutations: {
    updateClusterStatus?: (
      statuses?: MutationFunctionOptions<
        {},
        { clusterId: string; statusId: string; productId: string }
      >
    ) => Promise<void | ExecutionResult<{ statuses }>>
  } = {}

  @action
  handlePopOverClose = e => {
    e.stopPropagation()
    e.preventDefault()
    this.popOveranchorEl = null
  }

  @action
  handelPopOverClick = (productId, clusterId) => event => {
    const {
      product: { getClusterStatus }
    } = stores
    getClusterStatus()
    event.preventDefault()
    event.stopPropagation()
    this.popOveranchorEl = event.currentTarget
    this.selectedPopOverIndex = clusterId
    this.clusterId = clusterId
    this.productId = productId
  }

  @action
  handleClusterStatusChange = async statusId => {
    return await this.mutations.updateClusterStatus({
      variables: {
        clusterId: this.clusterId,
        statusId: statusId,
        productId: this.productId
      }
    })
  }
}
