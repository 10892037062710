import '@styles/Icon/style.css'
import cn from 'classnames'
import React from 'react'

interface IIcon {
  imageSrc?: string
  alt?: string
  svgPath?: any
  style?: React.CSSProperties
  className?: string
  onIconClick?: (e?: any) => void
}

const Icon = (props: IIcon): JSX.Element => {
  const { imageSrc, alt, svgPath, className, style, onIconClick } = props

  return (
    <div className={cn(['icon', className])} style={style} onClick={onIconClick}>
      {imageSrc && <img alt={alt} src={imageSrc} />}
      {svgPath && <svg>{svgPath}</svg>}
    </div>
  )
}

const withIconHOC = (overriddenProps: IIcon) => (props: IIcon) => {
  return <Icon {...props} {...overriddenProps} />
}
export default Icon
export { withIconHOC }
