import { config } from '@config'
import { storage } from '@services/storageService'
import axios from 'axios'

import { PRODUCT_DETAILS_SERVICE_ENDPOINTS } from '../new/constants/apiConstants'

export const SAProductDetailsService = {
  getInitialProductInformation: async (
    buyingSessionProductId: string,
    clientId: string,
    orderType: string | undefined,
    orderId: string
  ) => {
    const url = `${config.buyingBoardServiceRestEndpoint}${PRODUCT_DETAILS_SERVICE_ENDPOINTS.GET_INITIAL_PRODUCT_INFORMATION}/buyingSessionProduct/${buyingSessionProductId}/client/${clientId}`
    const accessToken: string = storage.getItem('access_token')
    const response: any = await axios.post(
      url,
      {
        ...(orderType && {
          ...(orderType === 'client'
            ? { clientOrderId: orderId }
            : { doorOrderId: orderId })
        })
      },
      {
        params: {
          ...(orderType && {
            orderType
          })
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
    )
    return response.data?.data?.productDetails
  },
  getExtraProductInformation: async (buyingSessionProductId: string) => {
    const url = `${config.buyingBoardServiceRestEndpoint}${PRODUCT_DETAILS_SERVICE_ENDPOINTS.GET_EXTRA_PRODUCT_INFORMATION}/buyingSessionProduct/${buyingSessionProductId}`
    const accessToken: string = storage.getItem('access_token')
    const response: any = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
    return response.data?.data?.secondaryProductDetails
  },

  toggleProductFavoriteStatus: async (productId: string) => {
    try {
      const url = `${config.buyingBoardServiceRestEndpoint}${PRODUCT_DETAILS_SERVICE_ENDPOINTS.COLLECTION}/buyingSessionProduct/${productId}`
      const accessToken: string = storage.getItem('access_token')
      const response: any = await axios.patch(
        url,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        }
      )
      return response.data?.data?.filterAttributes
    } catch (e) {
      console.log(e)
      throw e
    }
  },
  updateProductRating: async (
    productId: string,
    ratingId: string,
    value: string,
    ratingTypeId: string
  ) => {
    try {
      const url = `${config.buyingBoardServiceRestEndpoint}${PRODUCT_DETAILS_SERVICE_ENDPOINTS.PATCH_RATING}/buyingSessionProduct/${productId}`
      const accessToken: string = storage.getItem('access_token')
      const response = await axios.patch<any, { data: any }>(
        url,
        {
          ratingToUpdate: {
            ...(ratingId && { id: ratingId }),
            ratingTypeId,
            value
          }
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        }
      )
      return response.data?.data
    } catch (e) {
      console.log(e)
      throw e
    }
  }
}
