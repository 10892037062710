import { CategoryLabel } from '@modules/common/component/CategoryTreeRow'
import { ListViewColumnDefinition } from '@components/UI-Components/StandardTableView/models/ListviewColumnDefinition'
import Tooltip from '@material-ui/core/Tooltip'
import { ApplyToFixedIfNumber } from '@services/commonServices'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import memoize from 'memoize-one'
import React from 'react'
import { memo } from 'react'
import { areEqual } from 'react-window'
import styled from 'styled-components'

import { Cell, ListViewRowProps, Row } from './Common'

const CategoryKPISpan = styled.span<any>(props => {
  const { fontWeight } = props
  return {
    '&&': {
      fontWeight: fontWeight ? fontWeight : 'normal'
    }
  }
})

/**
 * Common Category row component which will be used for all 3 categories
 * and in both assortment and collection view
 */
export const CategoryRow: React.FunctionComponent<ListViewRowProps> = memo(
  ({
    kpiNode,
    style,
    stickyStyle,
    headers,
    canTableFitInView,
    rowSelectionConf,
    emptyColWidth,
    onClick
  }) => {
    /**
     * Returns mapped value from KPI data
     * @param col - column definition object
     */
    const getKpiData = memoize(
      (
        col: ListViewColumnDefinition
      ): {
        value: any
        displayDash?: boolean
      } => {
        if (col.valueFormatter) {
          return { value: col.valueFormatter(kpiNode[col.category.key]) }
        }
        if (col.category && col.category.showKPI) {
          const kpiData = col.category.key && kpiNode[col.category.key]
          return kpiData || kpiData === 0
            ? {
                value: col.isFloatingPointValue
                  ? ApplyToFixedIfNumber(kpiNode[col.category.key])
                  : kpiNode[col.category.key]
              }
            : { value: <span>&#8212;&#8212;</span>, displayDash: true }
        } else {
          return { value: <span>&nbsp;</span> }
        }
      }
    )

    return (
      <Row style={style}>
        {map(headers, (col: ListViewColumnDefinition, index) => {
          const kpiData = getKpiData(col)
          return (
            <Cell
              key={`${kpiNode.type}-${col.id}`}
              cellWidth={col.width}
              alignment={!col.isRootColumn && col.props && col.props.alignment}
              stickyLeft={stickyStyle[index] && stickyStyle[index].left}
              isCellShadow={
                stickyStyle[index] &&
                stickyStyle[index].shouldApplyCellShadow &&
                canTableFitInView
              }
              cellPad={col.isRootColumn ? '5px 10px 0 0' : '5px 10px 0 10px'}
            >
              {col.isRootColumn ? (
                <CategoryLabel kpiNode={kpiNode} onClick={onClick} />
              ) : (
                <Tooltip
                  title={kpiData.value}
                  placement='bottom'
                  disableHoverListener={kpiData.displayDash}
                >
                  <CategoryKPISpan fontWeight={col?.props?.fontWeight}>
                    {kpiData.value}
                  </CategoryKPISpan>
                </Tooltip>
              )}
            </Cell>
          )
        })}
        {emptyColWidth ? (
          <Cell
            key={`${kpiNode.type}_empty_cell`}
            cellWidth={emptyColWidth}
            stickyRight={0}
          />
        ) : null}
        {!isEmpty(rowSelectionConf) ? (
          <Cell
            cellPad={rowSelectionConf.padding ? rowSelectionConf.padding : undefined}
            key={`${kpiNode.type}_category_row_select`}
            cellWidth={rowSelectionConf.width}
            stickyRight={0}
          />
        ) : null}
      </Row>
    )
  },
  areEqual
)
