import { StyledProps } from '@components/3rd-party/mui'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import { copyToClipBoard } from '@services/commonServices'
import { strings } from '@stores'
import React from 'react'
import * as css from './BuyingSessionErrorDialog.css'
import { PrimaryButton } from './PrimaryButton'
import { SecondaryButton } from './SecondaryButton'

interface IBuyingSessionErrorDialog extends StyledProps {
  open?: any
  errors?: any
  onOKClick?: any
}

const BuyingSessionErrorDialogComponent = ({
  errors,
  onOKClick,
  open
}: IBuyingSessionErrorDialog) => {
  return (
    <Dialog
      open={open}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      className={`bsErrorMessagesParent`}
    >
      <DialogTitle id='alert-dialog-title'>
        {strings.buyingSessionErrorMessage}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          {errors && errors.length
            ? errors.map((errorObj, index) => (
                <div
                  key={'bsErrorContainer_' + index}
                  className={css.zoneErrorsContainer}
                >
                  <Typography variant='subtitle2' className={css.zoneErrorsHeader}>
                    Issue{errorObj.errors.length > 1 ? 's' : ''} with{' '}
                    <span className={css.zoneName}>{errorObj.zoneName}</span> zone
                  </Typography>
                  {
                    <ul>
                      {errorObj.errors.map((errorMsg, index) => (
                        <li key={`errorListItem_${index}`}>
                          <Typography key={'bsErrorMsg_' + index} gutterBottom>
                            {errorMsg}
                          </Typography>
                        </li>
                      ))}
                    </ul>
                  }
                </div>
              ))
            : null}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <PrimaryButton onClick={onOKClick}>OK</PrimaryButton>
        {document.queryCommandSupported('copy') && (
          <SecondaryButton onClick={() => copyToClipBoard('bsErrorMessagesParent')}>
            {strings.copyToClipBoard}
          </SecondaryButton>
        )}
      </DialogActions>
    </Dialog>
  )
}

export const BuyingSessionErrorDialog = React.memo(BuyingSessionErrorDialogComponent)
