import React from 'react'
import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'

const StyledListLabel = styled(({ index, hasGlobalRow, ...rest }) => {
  return <Typography {...rest} />
})(props => {
  return {
    '&&': {
      font:
        props.index === 0 && props.hasGlobalRow
          ? 'var(--typography-font-bold1)'
          : 'var(--typography-body3)',
      letterSpacing: 'var(--label-typography-letter-spacing)',
      textTransform: 'capitalize',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  }
})
export default StyledListLabel
