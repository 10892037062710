import { KeyCode } from '@constants'
import { User } from '@models'
import {
  ListViewColumnLabels,
  ListViewFieldMapping,
  ListViewProduct,
  QuantityType,
  UserRoles,
  Views,
  ViewTabs
} from '@modules/common/models'
import { CLUSTER_ASSORTMENT_PRODUCTS } from '@modules/retail/assortment/graphql/clusterAssortmentProducts'
import { STORE_ASSORTMENT_PRODUCTS } from '@modules/retail/assortment/graphql/storeAssortmentProducts'
import { ZONE_ASSORTMENT_PRODUCTS } from '@modules/retail/assortment/graphql/zoneAssortmentProducts'
import { constants } from '@modules/retail/buyingSession/constants'
import { GET_MARKUP_VALUES } from '@modules/retail/buyingSession/graphql/getMarkupValues'
import { GET_CLUSTER_STATUSES } from '@modules/retail/cluster/graphql/getClusterStatuses'
import { ADD_FREE_TAG_TO_PRODUCTS } from '@modules/retail/collection/graphql/addFreeTagToProducts'
import { ADD_PRODUCTS_TO_CLUSTER } from '@modules/retail/collection/graphql/addProductsToCluster'
import { ADD_PRODUCT_TO_ASSORTMENT } from '@modules/retail/collection/graphql/addProductToAssortment'
import { ADD_SALES_PERIOD_TO_PRODUCTS } from '@modules/retail/collection/graphql/addSalesPeriodToProducts'
import { BULK_OPERATIONS_ON_BS_PRODUCTS } from '@modules/retail/collection/graphql/bulkOperationsOnBuyingSessionProducts'
import { BUYING_SESSION_PRODUCT } from '@modules/retail/collection/graphql/buyingSessionProduct'
import { GET_ASSORTMENT_ID } from '@modules/retail/collection/graphql/getAssortment'
import { GET_PRODUCT_ATTRIBUTES } from '@modules/retail/collection/graphql/getProductAttributes'
import { MARK_PRODUCTS_CHANNEL_EXCLUSIVE } from '@modules/retail/collection/graphql/makeProductChannelExclusive'
import { ADD_FAVOURITE_TO_PRODUCT } from '@modules/retail/collection/graphql/markFavourite'
import { REMOVE_CHANNEL_FROM_PRODUCT } from '@modules/retail/collection/graphql/removeChannelExclusivityFromProduct'
import { REMOVE_FAVOURITE_FROM_PRODUCT } from '@modules/retail/collection/graphql/removeFavourite'
import { REMOVE_FREE_TAG_FROM_PRODUCT } from '@modules/retail/collection/graphql/removeFreeTagFromProduct'
import { REMOVE_SALES_PERIOD_TAG_FROM_PRODUCT } from '@modules/retail/collection/graphql/removeSalesPeriodFromProduct'
import { REMOVE_ZONE_RESTRICTION } from '@modules/retail/collection/graphql/removeZoneRestriction'
import { ZONE_COUNTER_OFFER } from '@modules/retail/collection/graphql/zoneCounterOffer'
import { UPDATE_ACTIVE_FLAG } from '@modules/retail/productDetail/graphql/updateProductStatusFlag'
import { GET_STORE_ASSORTMENTS } from '@modules/retail/navigations/graphql'
import { UPSERT_BUYINGSESSION_PRODUCT_ATTRIBUTE } from '@modules/retail/productDetail/graphql/upsertBuyingSessionProductAttribute'
import { UPSERT_PRODUCT_ATTRIBUTE } from '@modules/retail/productDetail/graphql/upsertProductAttribute'
import { ApplyToFixedIfDecimal, validateInteger } from '@services/commonServices'
import { getOptionsForBuyingSessionProductSizeAttributes } from '@services/productDetailService'
import { storage } from '@services/storageService'
import { nav } from '@stores/nav'
import { strings, stores } from '@stores'
import { apolloClient } from '@stores/apollo'
import groupBy from 'lodash/groupBy'
import cloneDeep from 'lodash/cloneDeep'
import filter from 'lodash/filter'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import map from 'lodash/map'
import some from 'lodash/some'
import memoize from 'memoize-one'
import { action, computed, observable, toJS } from 'mobx'
import { ForecastChangeTypeEnum } from '@modules/wholesale/buyingSessionGroupProductWrapper/constants/productForecastInterface'
import { productForecastUIStore } from '@modules/wholesale/buyingSessionGroupProductWrapper/stores/productForecastUIStore'

export interface SortOption {
  group: string
  field: string
  definition: string
  label: string
  value: string // Combination of field and definition
  disabled?: boolean
}

export interface IMultiProductModel {
  productId?: string
  buyingSession: {
    id: string
  }
}

export class ProductStore {
  @observable buyingSessionProductSizeAttribute = []
  @observable attributeDefinitions = []
  @observable sizeFrom = { definition: '', value: '' }
  @observable sizeTo = { definition: '', value: '' }
  @observable buyingSessionProductAttributeDefinitions = []
  @observable sortBy: string = ''
  @observable sortQuery: string = ''
  @observable markUpList = []
  @observable clusters = []
  @observable clusterStatuses = []
  @observable currentSelectedId = null
  @observable openConfirmationDialog = false
  @observable openErrorDialogForChannelDragOnInactiveProduct = false
  @observable isSplitButtonClicked = false
  @observable selectedProducts = new Map()
  @observable buyingSessionProductId = null
  @observable channelId = null
  @observable openDeleteConfirmationDialog = false
  @observable removeChannelFromProductInProgress = false
  @observable confirmationMessage = null

  // This should ideally be part of assortment but since it is not singleton keeping this
  // property in product assortment for now (This was done on last day of sprint)
  assortmentRefetchQueries = []

  @action resetSelectedProducts = () => {
    this.selectedProducts = new Map()
  }

  @action setCurrentSelectedId = value => {
    Object.assign(this, { currentSelectedId: value })
  }

  @action resetCurrentSelectedId = () => {
    this.currentSelectedId = null
  }

  @action loadProductAttributesForRetail = async () => {
    try {
      let {
        data: { productAttributesDefinitions }
      } = await apolloClient.query({
        query: GET_PRODUCT_ATTRIBUTES,
        fetchPolicy: 'network-only'
      })
      this.attributeDefinitions = productAttributesDefinitions
    } catch (err) {
      console.error('Error in querying product attribute definitions')
    }
    this.getClusterStatus()
  }

  @action storeProductClusters = clustersData => {
    const { clusters } = clustersData.data
    const clustersFromQuery = cloneDeep(clusters)
    Object.assign(this, { clusters: clustersFromQuery })
  }

  @action getClusterStatus = async () => {
    try {
      let {
        data: { statuses }
      } = await apolloClient.query({
        query: GET_CLUSTER_STATUSES,
        fetchPolicy: 'network-only'
      })
      this.clusterStatuses = statuses
    } catch (err) {
      console.error('Error in querying cluster statuses')
    }
  }

  @action
  getMarkupData = async gender => {
    const [
      { setMarkupValues },
      {
        nav: {
          params: { season }
        }
      }
    ] = [this, stores]

    const {
      data: { markupValues }
    } = await apolloClient.query({
      query: GET_MARKUP_VALUES,
      variables: {
        genderId: gender,
        seasonId: season
      },
      fetchPolicy: 'network-only'
    })
    setMarkupValues(markupValues)
  }

  setMarkupValues = markUpValues => {
    const markUpData = map(groupBy(markUpValues, 'activity.id'), g => ({
      ...g[0].activity,
      zones: map(g, ({ markup, priceList, zone: { id } }) => ({
        id,
        markup,
        priceList
      }))
    }))
    Object.assign(this, { markUpList: markUpData })
  }

  @action onChangeSortBy = event => {
    this.sortBy = event.target.value
  }

  @action resetSort = () => {
    this.sortBy = ''
  }

  @computed get isBuyMetricSort() {
    const { sortBy } = this
    return sortBy === strings.sortByBuyValue || sortBy === strings.sortByBuyQty
  }

  @computed.struct get sortByQuery() {
    const { isBuyMetricSort, sortBy, sortOptions } = this
    const {
      nav: {
        params: { view, assortmentType }
      },
      assortment: { selectedAssortmentTab }
    } = stores

    const isStoreSplitView =
      assortmentType === strings.zone &&
      view === Views.List &&
      selectedAssortmentTab === 3

    if (isBuyMetricSort && !isStoreSplitView) {
      return undefined
    }

    if (sortBy) {
      const sortOption = find(sortOptions, option => option.value === sortBy)
      return sortOption
        ? { field: sortOption.field, definition: sortOption.definition }
        : undefined
    }

    return undefined
  }

  @computed get attributeDefWithTag() {
    const { attributeDefinitions } = this

    let attributeOptions = map(attributeDefinitions, definition => {
      let object = {
        definition: definition,
        id: definition.id,
        name: definition.name,
        isEditable: definition.isEditable,
        displayName: definition.displayName,
        singular:
          definition.displayName && definition.displayName.singular
            ? definition.displayName.singular
            : definition.name,
        type: definition.type,
        tagType: definition.tagType,
        tags: []
      }
      let tags = []
      if (definition.type === 'Tag' && definition.tagType) {
        tags = map(definition.tagType.tags, tag => ({
          id: tag.id,
          name: tag.name
        }))

        tags.unshift({
          id: null,
          name: 'None'
        })
      }

      object.tags = tags
      return object
    })
    return attributeOptions
  }

  @action handleProductAttributeChange = async (productId, definitionId, value) => {
    await apolloClient.mutate({
      mutation: UPSERT_PRODUCT_ATTRIBUTE,
      variables: { productID: productId, definitionID: definitionId, tagID: value }
    })
  }

  @computed get sortOptions() {
    const { attributeDefinitions } = this
    const {
      nav: {
        params: { view }
      },
      assortment: { isClusterView }
    } = stores
    const isKPIView = view === Views.Kpi
    const isCardView = view === Views.Grid

    let globalOptions = [
      {
        group: strings.sortByGlobalGroup,
        field: strings.sortBySalesPeriod,
        definition: undefined,
        label: 'Sales Period',
        value: strings.sortBySalesPeriod,
        disabled: isKPIView
      },
      {
        group: strings.sortByGlobalGroup,
        field: strings.sortByFavourites,
        definition: undefined,
        label: 'Likes',
        value: strings.sortByFavourites,
        disabled: isKPIView
      },
      {
        group: strings.sortByGlobalGroup,
        field: strings.sortByBuyQty,
        definition: undefined,
        label: ListViewColumnLabels.OrderQty,
        value: strings.sortByBuyQty,
        disabled: isCardView || isClusterView()
      },
      {
        group: strings.sortByGlobalGroup,
        field: strings.sortByBuyValue,
        definition: undefined,
        label: ListViewColumnLabels.OrderValue,
        value: strings.sortByBuyValue,
        disabled: isCardView || isClusterView()
      }
    ]

    let attributeOptions = map(attributeDefinitions, definition => ({
      group: strings.sortByProductAttributesGroup,
      field: strings.sortByAttributes,
      definition: definition.name,
      disabled: isKPIView,
      label:
        definition.displayName && definition.displayName.singular
          ? definition.displayName.singular
          : definition.name,
      value: `${strings.sortByAttributes}_${definition.name}`
    }))

    return globalOptions.concat(attributeOptions)
  }

  @computed get globalSortOptions() {
    const { sortOptions } = this
    return filter(sortOptions, option => option.group === strings.sortByGlobalGroup)
  }

  @computed get attributeSortOptions() {
    const { sortOptions } = this
    return filter(
      sortOptions,
      option => option.group === strings.sortByProductAttributesGroup
    )
  }

  getProductWhereHierarchyQuery = memoize(attributeMap => {
    return map(Object.keys(attributeMap), attribute => ({
      attributes_some: {
        definition: {
          name: attribute
        },
        strVal: attributeMap[attribute]
      }
    }))
  })

  whereHierarchicalKpiQueryVariables = (quantityType: string = 'BET') => {
    const {
      filter: { productWhereInput, finalFilterToApply, getFlagFilter },
      search: { prepareProductSearchWhereInput, searchKey },
      nav: {
        params: { buyingSession: buyingSessionId, podium, gender }
      }
    } = stores

    const user = storage.getItem<User>('user')
    const customFilter = getFlagFilter(finalFilterToApply)

    const buyingSession = buyingSessionId
      ? {
          id: buyingSessionId
        }
      : {
          buyingSessionCollection: {
            id: podium
          }
        }

    const whereInput = prepareProductSearchWhereInput(
      productWhereInput,
      finalFilterToApply,
      user,
      searchKey
    )

    const variables = {
      buyingSessionWhereInput: buyingSession,
      genderId: gender,
      quantityType,
      productWhereInput: { ...whereInput, buyingSession },
      customFilter
    }

    return variables
  }

  whereHierarchicalKpiQueryVariablesForAssortment = (quantityType: string = 'BET') => {
    const user = storage.getItem<User>('user')
    const {
      filter: { productWhereInput, finalFilterToApply },
      assortment: { drawerOpen, selectedClusterId },
      search: { prepareProductSearchWhereInput, searchKey },
      nav: {
        params: { assortment, assortmentType, gender }
      }
    } = stores

    const whereInput = prepareProductSearchWhereInput(
      productWhereInput,
      finalFilterToApply,
      user,
      searchKey
    )
    const isRegionalMerchandiser = some(user.roles, {
      name: UserRoles.REGIONAL_MERCHANDISER
    })
    const variablesForWhere = {
      assortmentId: assortment,
      product: !drawerOpen ? whereInput : {},
      ...(selectedClusterId &&
        isRegionalMerchandiser && { buyerClusters_some: { id: selectedClusterId } })
    }

    const variables = {
      assortmentId: assortment,
      ...(assortmentType !== strings.zone && {
        productWhereInput: !drawerOpen ? whereInput : {}
      }),
      ...(assortmentType === strings.zone && { variablesForWhere }),
      genderId: gender,
      quantityType
    }
    return variables
  }

  whereZoneAssortmentProductQueryForSplit = (
    quantityType: string = 'BUY',
    includeKPIs: boolean = false
  ) => {
    const user = storage.getItem<User>('user')
    const {
      nav: {
        params: { assortment, gender }
      },
      filter: { productWhereInput, finalFilterToApply, getFlagFilter },
      assortment: { drawerOpen, selectedClusterId },
      search: { prepareAssortmentProductSearchWhereInput, searchKey },
      product: { sortByQuery }
    } = stores
    const flagFilter = getFlagFilter(finalFilterToApply)
    const isRegionalMerchandiser = some(user.roles, {
      name: UserRoles.REGIONAL_MERCHANDISER
    })
    const whereInput = prepareAssortmentProductSearchWhereInput(
      productWhereInput,
      finalFilterToApply,
      user,
      searchKey
    )
    const variableWhere = {
      assortmentId: assortment,
      product: !drawerOpen ? { ...whereInput } : {},
      ...(selectedClusterId &&
        isRegionalMerchandiser && { buyerClusters_some: { id: selectedClusterId } })
    }
    return {
      assortmentId: assortment,
      variableWhere,
      ...(includeKPIs && { genderId: gender, quantityType }),
      sortBy: sortByQuery,
      flagFilter
    }
  }

  /**
   * Build where query for collection / activity collection query
   */
  whereQueryVariablesForCollection = (
    activityObject: { name: string },
    childrenMeta,
    childrenMetaValues
  ) => {
    const {
      nav: {
        params: { buyingSession: buyingSessionId, podium, gender }
      },
      search: { prepareCollectionProductSearchWhere, searchKey },
      filter: { productWhereInput, finalFilterToApply, getFlagFilter },
      product: { sortByQuery },
      site: { isGlobalMerchandiserOrAdmin }
    } = stores

    const user = storage.getItem<User>('user')
    const buyingSession = buyingSessionId
      ? {
          id: buyingSessionId
        }
      : {
          buyingSessionCollection: {
            id: podium
          }
        }
    const whereProductQuery: any = toJS(
      prepareCollectionProductSearchWhere(
        buyingSession,
        searchKey,
        productWhereInput,
        finalFilterToApply,
        gender,
        activityObject,
        { childrenMeta, childrenMetaValues },
        user
      )
    )

    let zone_ids = []
    if (user.membership && user.membership.zones) {
      user.membership.zones.map(zone => zone_ids.push(zone.id))
    }
    const zoneIdWhere = zone_ids.length
      ? {
          zoneAssortment: {
            zone: {
              id_in: zone_ids
            }
          }
        }
      : isGlobalMerchandiserOrAdmin()
      ? null
      : { id: null }
    const customFilter = getFlagFilter(finalFilterToApply)

    const variables = {
      where: whereProductQuery,
      userId: user.id,
      zoneIdWhere: zoneIdWhere,
      sortBy: sortByQuery,
      customFilter
    }

    return variables
  }

  whereQueryVariablesForAssortmentProducts = (
    activityObject: { name: string },
    childrenMeta,
    childrenMetaValues,
    ignoreDrawerState: boolean = false
  ) => {
    const {
      nav: {
        params: { assortment, assortmentType },
        queryParams: { zoneId }
      },
      search: { prepareAssortmentProductSearchWhereInput, searchKey },
      filter: { productWhereInput, finalFilterToApply, getFlagFilter },
      product: { sortByQuery },
      assortment: { drawerOpen, selectedClusterId }
    } = stores

    const user = storage.getItem<User>('user')
    const isRegionalMerchandiser = some(user.roles, {
      name: UserRoles.REGIONAL_MERCHANDISER
    })
    const flagFilter = getFlagFilter(finalFilterToApply)
    const whereInput = prepareAssortmentProductSearchWhereInput(
      productWhereInput,
      finalFilterToApply,
      user,
      searchKey,
      activityObject,
      { childrenMeta, childrenMetaValues }
    )

    const variableWhere = {
      assortmentId: assortment,
      product: drawerOpen && !ignoreDrawerState ? {} : { ...whereInput },
      ...(selectedClusterId &&
        assortmentType === strings.zone &&
        isRegionalMerchandiser && { buyerClusters_some: { id: selectedClusterId } })
    }
    const variables = {
      assortmentId: assortment,
      variableWhere,
      sortBy: sortByQuery,
      flagFilter,
      ...(assortmentType === strings.store && { zoneId }),
      includeZoneAssortmentProduct: assortmentType === strings.store
    }

    return variables
  }

  @action addToAssortmentRefetchQueries = (refetch, query, variables) => {
    // Add unique refetch queries for assortment in collection
    if (
      !this.assortmentRefetchQueries.find(
        data => isEqual(data.query, query) && isEqual(data.variables, variables)
      )
    ) {
      this.assortmentRefetchQueries.push({
        query,
        variables,
        refetch
      })
    }
  }

  @action clearRefetchQueries = () => {
    this.assortmentRefetchQueries = []
  }

  @action executeAssortmentRefetchQueries = () => {
    this.assortmentRefetchQueries.forEach(query => {
      query.refetch().catch(err => console.error('Error in refetching hierarchy'))
    })
  }

  buildRefetchQueriesForAttributeHierarchy = (
    activityHierarchy,
    assortmentType: string
  ) => {
    let query =
      assortmentType === 'cluster'
        ? CLUSTER_ASSORTMENT_PRODUCTS
        : assortmentType === 'store'
        ? STORE_ASSORTMENT_PRODUCTS
        : ZONE_ASSORTMENT_PRODUCTS

    return activityHierarchy.map(hierarchy => {
      return {
        query,
        variables: this.whereQueryVariablesForAssortmentProducts(
          hierarchy.activity,
          hierarchy.childrenMeta,
          hierarchy.childrenMetaValues,
          true
        )
      }
    })
  }

  getHierarchyMetaFromBuyingSessionProduct = buyingSessionProduct => {
    if (
      buyingSessionProduct.product &&
      buyingSessionProduct.product.attributes &&
      buyingSessionProduct.product.attributes.length
    ) {
      let activity = buyingSessionProduct.product.attributes.find(
        attribute => attribute.definition.name === 'activity'
      )
      const activityDescription = activity ? activity.strVal : ''
      let family = buyingSessionProduct.product.attributes.find(
        attribute => attribute.definition.name === 'family'
      )
      let line = buyingSessionProduct.product.attributes.find(
        attribute => attribute.definition.name === 'line'
      )
      // FIXME: RM - Pick attributes from .env variables
      let childrenMeta = ['family']
      let childrenMetaValues = [family ? family.strVal : '']

      if (activityDescription === 'SHOES') {
        childrenMeta = ['line']
        childrenMetaValues = [line ? line.strVal : '']
      } else if (activityDescription === 'LEATHER GOODS') {
        childrenMeta = ['family', 'line']
        childrenMetaValues = [family ? family.strVal : '', line ? line.strVal : '']
      }

      let object = {
        activity: { name: activity ? activity.strVal : '' },
        childrenMeta,
        childrenMetaValues
      }

      return object
    }
    return null
  }

  @action showProductDetail = id => {
    const {
      history,
      location: { pathname }
    } = nav
    const detailsUrlRoot =
      pathname.indexOf('/details') === -1
        ? pathname
        : pathname.substr(0, pathname.indexOf('/details'))

    if (isEmpty(id)) {
      history.push({
        pathname: detailsUrlRoot,
        search: history.location.search
      })
    } else {
      history.push({
        pathname: `${detailsUrlRoot}/details/${id}`,
        search: history.location.search
      })
    }
  }

  /**
   * Mark Product Favourite
   */
  @action markFavourite = async (selectedProductId, active) => {
    const userId = storage.getItem<User>('user').id

    if (active) {
      await apolloClient.mutate({
        mutation: ADD_FAVOURITE_TO_PRODUCT,
        variables: { productID: selectedProductId, userId }
      })
    }
  }

  /**
   * Handle Product Status change
   */
  @action handleStatusChange = async (selectedProductId, active) => {
    await apolloClient.mutate({
      mutation: UPDATE_ACTIVE_FLAG,
      variables: { productID: selectedProductId, productStatus: !active }
    })
  }
  /**
   * Remove product favourite
   */
  @action removeFavourite = async (selectedProductId, active) => {
    if (active) {
      const userId = storage.getItem<User>('user').id
      await apolloClient.mutate({
        mutation: REMOVE_FAVOURITE_FROM_PRODUCT,
        variables: { productID: selectedProductId },
        refetchQueries: [
          {
            query: BUYING_SESSION_PRODUCT,
            variables: {
              id: selectedProductId,
              whereFavouriteInput: {
                user: {
                  id: userId
                }
              },
              includeUserFavourite: true
            }
          }
        ]
      })
    }
  }

  /**
   * Get product status
   */
  @action getProductStatus = (status, isActive) => {
    let productStatus = null
    if (isActive) {
      if (status) {
        if (status !== 'NON_CORE') {
          productStatus = status
        }
      } else {
        productStatus = 'CORE'
      }
    } else {
      productStatus = 'inactive'
    }
    return productStatus
  }

  /**
   * Handler for tag drop on product
   *
   * @param {object} product
   * @param {string} type
   * @param {string} tagId
   */
  @action handleTagDrop = (product, type, tagId) => {
    if (type === 'cluster') {
      if (
        product.channels &&
        product.channels.find(channel => channel.name === strings.retailChannelName)
      ) {
        this.addProductToAssortment(product.buyingSession.id, tagId, product.id)
      } else {
        this.openConfirmationDialog = true
      }
    } else if (type === 'channel') {
      if (product.product && product.product.active) {
        this.markProductChannelExclusive(tagId, product.id)
      } else {
        this.openErrorDialogForChannelDragOnInactiveProduct = true
      }
    } else if (type === 'drop') {
      this.addSalesPeriodToProducts(
        tagId,
        [product.id],
        this.pushKPIRefetchQueryForBetBuyTabsOfCollection()
      )
    } else if (type === 'freetag') {
      this.addFreeTagToProducts(tagId, [product.id])
    } else if (type === 'zoneRestriction') {
      this.addZoneRestrictionToProducts(tagId, product.id)
    }
    this.setCurrentSelectedId(null)
  }

  /**
   * Removes products exclusive channel
   */
  @action removeChannelFromProduct = async () => {
    this.removeChannelFromProductInProgress = true
    await apolloClient.mutate({
      mutation: REMOVE_CHANNEL_FROM_PRODUCT,
      variables: {
        channelId: this.channelId,
        buyingSessionProductId: this.buyingSessionProductId
      }
    })
    this.removeChannelFromProductInProgress = false
    this.toggleChannelDeleteConfirmationDialog()
  }

  /**
   * Remove Free Tag from Product
   */
  @action removeFreeTag = async (buyingSessionProductId, freeTagId) => {
    await apolloClient.mutate({
      mutation: REMOVE_FREE_TAG_FROM_PRODUCT,
      variables: { buyingSessionProductId, freeTagId }
    })
  }

  /**
   * Removes products exclusive channel
   */
  @action removeSalesPeriodFromProduct = async buyingSessionProductId => {
    const userId = storage.getItem<User>('user').id

    await apolloClient.mutate({
      mutation: REMOVE_SALES_PERIOD_TAG_FROM_PRODUCT,
      variables: {
        buyingSessionProductId,
        userId
      }
    })
  }

  @action removeZoneRestriction = async (zoneId, buyingSessionProductId) => {
    await apolloClient.mutate({
      mutation: REMOVE_ZONE_RESTRICTION,
      variables: {
        buyingSessionProductId,
        zoneId
      },
      refetchQueries: [
        {
          query: BUYING_SESSION_PRODUCT,
          variables: {
            id: buyingSessionProductId,
            includeUserFavourite: false
          }
        }
      ]
    })
  }

  @action addProductToAssortment = async (
    buyingSessionId,
    clusterId,
    buyingSessionProductId
  ) => {
    const {
      nav: {
        params: { buyingSession: buyingSessionIdFromURL, podium: podiumId }
      }
    } = stores

    const assortment = await apolloClient.query({
      query: GET_ASSORTMENT_ID,
      variables: {
        buyingSessionId,
        clusterId
      }
    })

    const refetchQueries = [
      {
        query: GET_STORE_ASSORTMENTS,
        variables: { buyingSessionId }
      },
      {
        query: BUYING_SESSION_PRODUCT,
        variables: {
          id: buyingSessionProductId,
          includeUserFavourite: false,
          zoneIdWhere: null
        }
      }
    ]

    if (buyingSessionIdFromURL && podiumId) {
      // Refetch ZONE_COUNTER_OFFER only if on Activity Collection page
      refetchQueries.push({
        query: ZONE_COUNTER_OFFER,
        variables: { buyingSessionId }
      })
    }

    await apolloClient.mutate({
      mutation: ADD_PRODUCT_TO_ASSORTMENT,
      variables: {
        assortmentId: assortment.data.assortments[0].id,
        buyingSessionProductIds: [buyingSessionProductId]
      },
      refetchQueries
    })
  }

  /**
   * Marks product exclusive to particular channel
   */
  @action markProductChannelExclusive = async (channelId, productId) => {
    // const {
    //   props: { client }
    // } = this

    await apolloClient.mutate({
      mutation: MARK_PRODUCTS_CHANNEL_EXCLUSIVE,
      variables: {
        channelId,
        productId
      }
    })
  }

  @action handleMultiProductAction = async (type: string, tagId: string) => {
    const {
      collection: { selectedProducts, resetSelectedProducts }
    } = stores
    // TODO - this is almost certainly a bug with regards to buyingSession vs buyingSessionId
    const products: IMultiProductModel[] = selectedProducts.filter(
      product => !!product
    ) as any
    if (type === 'clusters') {
      try {
        await apolloClient.mutate({
          mutation: ADD_PRODUCTS_TO_CLUSTER,
          variables: {
            products,
            clusterId: tagId
          }
        })
      } catch (e) {
        console.error('Error while adding products to cluster.', e)
      }
    } else if (type === 'channels') {
      try {
        await apolloClient.mutate({
          mutation: MARK_PRODUCTS_CHANNEL_EXCLUSIVE,
          variables: {
            channelId: tagId,
            productIds: products.map((product: IMultiProductModel) => product.productId)
          }
        })
      } catch (e) {
        console.error('Error while adding products to channel.', e)
      }
    } else if (type === 'salesPeriods') {
      try {
        this.addSalesPeriodToProducts(
          tagId,
          products.map((product: IMultiProductModel) => product.productId)
        )
      } catch (e) {
        console.error('Error while adding products to sales period.', e)
      }
    } else if (type === 'freeTags') {
      try {
        this.addFreeTagToProducts(
          tagId,
          products.map((product: IMultiProductModel) => product.productId)
        )
      } catch (e) {
        console.error('Error while adding products to free tags.', e)
      }
    }
    resetSelectedProducts()
  }

  /**
   * Marks product exclusive to particular channel
   */
  @action addSalesPeriodToProducts = async (
    salesPeriodId: string,
    buyingSessionProductIds: string[],
    refetchQueries: any[] = []
  ) => {
    await apolloClient.mutate({
      mutation: ADD_SALES_PERIOD_TO_PRODUCTS,
      variables: {
        buyingSessionProductIds,
        salesPeriodId
      },
      refetchQueries
    })
  }

  @action addFreeTagToProducts = async (freeTagId, buyingSessionProductIds) => {
    await apolloClient.mutate({
      mutation: ADD_FREE_TAG_TO_PRODUCTS,
      variables: {
        freeTagId,
        buyingSessionProductIds
      }
    })
  }

  @action addZoneRestrictionToProducts = async (zoneId, productId) => {
    let refetchQueries: any = [
      {
        query: BUYING_SESSION_PRODUCT,
        variables: {
          id: productId,
          includeUserFavourite: false,
          zoneIdWhere: null
        }
      }
    ]

    refetchQueries = this.pushKPIRefetchQueryForBetBuyTabsOfCollection(refetchQueries)
    await apolloClient.mutate({
      mutation: BULK_OPERATIONS_ON_BS_PRODUCTS,
      variables: {
        restrictedZones: [zoneId],
        buyingSessionProductIds: [productId]
      },
      refetchQueries
    })
  }

  pushKPIRefetchQueryForBetBuyTabsOfCollection = (refetchQueries = []) => {
    let refetchQueriesToBeUpdated = [...refetchQueries]
    const {
      nav: {
        params: { view, assortment }
      },
      assortment: { selectedAssortmentTab, assortmentTypeMapping }
    } = stores
    /**
     * In case user is in BET or BUY tab of collection / activity collection list view,
     * refetch KPIs
     */
    if (
      view === strings.list &&
      (selectedAssortmentTab === 1 || selectedAssortmentTab === 2) &&
      !assortment
    ) {
      const tab = selectedAssortmentTab === 1 ? QuantityType.BET : QuantityType.BUY
      refetchQueriesToBeUpdated.push({
        query: assortmentTypeMapping[strings.global]['hierarchicalKPIsQuery'],
        variables: this.whereHierarchicalKpiQueryVariables(tab)
      })
    }

    return refetchQueriesToBeUpdated
  }

  @action handleMessageDialogClose = () => {
    this.openConfirmationDialog = false
    this.openErrorDialogForChannelDragOnInactiveProduct = false
  }

  @action onFocusAvgDepth = (event, record) => {
    const selectedProduct = this.selectedProducts.get(record.id)
    let updatedProduct = {
      selectedProductAvgDepthValue:
        !isNaN(event.target.value) && parseInt(event.target.value) === 0
          ? ''
          : event.target.value,
      selectedProductQuantity: selectedProduct
        ? selectedProduct.selectedProductQuantity
        : (this.selectedTab === 1 ? record.betQty : record.buyQty) || 0,
      selectedProductAWS: selectedProduct
        ? selectedProduct.selectedProductAWS
        : ApplyToFixedIfDecimal(record.aws) || 0
    }
    this.selectedProducts.set(record.id, updatedProduct)
  }

  updateProductKPIsOnAvgDepthChange = (record, updatedAvgDepth) => {
    const calculatedQty = record.storeCount
      ? Math.round(updatedAvgDepth * record.storeCount)
      : 0
    const salesQty = record.sellThrough
      ? parseFloat((calculatedQty * record.sellThrough).toFixed(2))
      : calculatedQty
    const calculatedAWS = record.weeks ? salesQty / record.weeks : 0

    return {
      selectedProductAvgDepthValue: updatedAvgDepth,
      selectedProductQuantity: calculatedQty,
      selectedProductAWS: calculatedAWS
    }
  }

  @action handleAvgDepthChange = (event, record, product) => {
    const {
      nav: {
        queryParams: { tab }
      }
    } = stores
    if (!isNaN(event.target.value) && event.target.value >= 0) {
      let selectedProduct = this.selectedProducts.get(record.id)
      if (!selectedProduct) {
        this.onFocusAvgDepth(event, record)
        selectedProduct = this.selectedProducts.get(record.id)
      }
      const avgDepthValue = event.target.value
      let updatedProduct
      if (ViewTabs.FORECAST.toLowerCase() === tab) {
        updatedProduct = {
          selectedProductAWS: record?.aws,
          selectedProductQuantity: record?.betQty,
          selectedProductAvgDepthValue: avgDepthValue
        }
      } else {
        updatedProduct = this.updateProductKPIsOnAvgDepthChange(record, avgDepthValue)
      }
      this.selectedProducts.set(record.id, updatedProduct)
    }
  }

  @action updateAvgDepth = (event, record, product) => {
    const {
      nav: {
        queryParams: { tab }
      }
    } = stores
    const value = Number(event.target.value)
    const avgDepthValue = record.storeCount ? event.target.value : 0
    const isForecastTab = ViewTabs.FORECAST.toLowerCase() === tab
    if (!isNaN(value)) {
      const updatedProduct = isForecastTab
        ? {
            selectedProductAWS: record?.aws,
            selectedProductQuantity: record?.betQty,
            selectedProductAvgDepthValue: avgDepthValue
          }
        : this.updateProductKPIsOnAvgDepthChange(record, value)
      this.selectedProducts.set(record.id, updatedProduct)
      if (isForecastTab) {
        const selectedProduct = this.selectedProducts.get(record.id)
        product?.upsertForecast({
          recordToBeUpdated: record,
          updatedValues: selectedProduct,
          changeType: ForecastChangeTypeEnum.AVG_DEPTH
        })
      } else {
        this.updateQuantity(record)
      }
    }
  }

  updateProductKPIsOnAWSChange = (record, updatedValue) => {
    const salesQty = record.weeks ? updatedValue * record.weeks : 0
    const calculatedQty =
      salesQty && record.sellThrough
        ? Math.round(salesQty / record.sellThrough)
        : Math.round(salesQty)
    const calculatedDepth = record.storeCount
      ? ApplyToFixedIfDecimal(calculatedQty / record.storeCount)
      : 0

    return {
      selectedProductAWS: updatedValue,
      selectedProductQuantity: calculatedQty,
      selectedProductAvgDepthValue: calculatedDepth
    }
  }

  @action updateAWS = (event, record, product) => {
    const {
      nav: {
        queryParams: { tab }
      }
    } = stores
    const value = Number(event.target.value)
    const isForecastTab = ViewTabs.FORECAST.toLowerCase() === tab
    if (!isNaN(value)) {
      const updatedProduct = isForecastTab
        ? {
            selectedProductAWS: value,
            selectedProductQuantity: record?.betQty,
            selectedProductAvgDepthValue: record?.depth
          }
        : this.updateProductKPIsOnAWSChange(record, value)
      this.selectedProducts.set(record.id, updatedProduct)
      if (isForecastTab) {
        const selectedProduct = this.selectedProducts.get(record.id)
        product?.upsertForecast({
          recordToBeUpdated: record,
          updatedValues: selectedProduct,
          changeType: ForecastChangeTypeEnum.AVG_WEEKLY_SALES
        })
      } else {
        this.updateQuantity(record)
      }
    }
  }

  updateProductKPIsOnQuantityChange = (record, updatedValue) => {
    const salesQty =
      updatedValue && record.sellThrough
        ? updatedValue * record.sellThrough
        : updatedValue
    const calculatedDepth = record.storeCount
      ? ApplyToFixedIfDecimal(updatedValue / record.storeCount)
      : 0

    const calculatedAWS = record.weeks
      ? ApplyToFixedIfDecimal(salesQty / record.weeks)
      : 0

    return {
      selectedProductAWS: calculatedAWS,
      selectedProductQuantity: updatedValue,
      selectedProductAvgDepthValue: calculatedDepth
    }
  }

  @action updateProductQuantity = (event, record, product) => {
    const {
      nav: {
        queryParams: { tab }
      }
    } = stores
    const value = Number(event.target.value)
    const isForecastTab = ViewTabs.FORECAST.toLowerCase() === tab
    if (!isNaN(value)) {
      const updatedProduct = isForecastTab
        ? {
            selectedProductAWS: record?.aws,
            selectedProductQuantity: value,
            selectedProductAvgDepthValue: record?.depth
          }
        : this.updateProductKPIsOnQuantityChange(record, value)
      this.selectedProducts.set(record.id, updatedProduct)
      if (isForecastTab) {
        const selectedProduct = this.selectedProducts.get(record.id)
        product?.upsertForecast({
          recordToBeUpdated: record,
          updatedValues: selectedProduct,
          changeType: ForecastChangeTypeEnum.FORECAST_QUANTITY
        })
      } else {
        this.updateQuantity(record)
      }
    }
  }

  @action handleKeyDown = (event, record, product) => {
    if (
      event.keyCode === KeyCode.Enter &&
      event.target.tagName &&
      event.target.tagName.toLowerCase() === 'input'
    ) {
      this.updateQuantity(record, false)
    }
  }

  @action handleSplitButtonClick = async (event, record) => {
    const {
      nav: {
        queryParams: { tab }
      }
    } = stores

    const isForecastTab = ViewTabs.FORECAST.toLowerCase() === tab
    const { multiUpsertForecastsData } = productForecastUIStore

    if (!isNaN(record.betQty) && isForecastTab) {
      this.isSplitButtonClicked = true
      multiUpsertForecastsData({
        recordToBeUpdated: record,
        changeType: ForecastChangeTypeEnum.FORECAST_QUANTITY
      })
      this.isSplitButtonClicked = false
    }
    this.resetObservableDataForSelectedProduct(record)
  }

  whereBetBuyValuesQueryVariables = () => {
    const {
      nav: {
        params: { assortmentType, buyingSession: buyingSessionId, podium, gender }
      },
      assortment: { selectedAssortmentId }
    } = stores

    let variables = null
    if (assortmentType) {
      variables = { assortmentId: selectedAssortmentId }
    } else if (buyingSessionId) {
      variables = { buyingSessionId }
    } else {
      variables = {
        podium: podium,
        genderId: gender
      }
    }
    return variables
  }

  @action updateQuantity = async (record, resetObservable: boolean = true) => {
    const {
      assortment: { assortmentTypeMapping },
      product: {
        whereHierarchicalKpiQueryVariables,
        whereHierarchicalKpiQueryVariablesForAssortment
      },
      nav: {
        params: { assortment, activity }
      }
    } = stores

    //Check if the tab is bet or buy
    //Fix me - this parameter need to be change when we will change tab value
    const selectedProduct = this.selectedProducts.get(record.id)
    const selectedProductQuantity =
      selectedProduct && selectedProduct.selectedProductQuantity !== ''
        ? selectedProduct.selectedProductQuantity
        : 0
    const tab = this.selectedTab === 1 ? QuantityType.BET : QuantityType.BUY
    const existingQty = tab === QuantityType.BET ? record.betQty : record.buyQty
    if (this.isSplitButtonClicked || Number(selectedProductQuantity) !== existingQty) {
      const mutationVariables =
        record.zone === strings.global
          ? {
              buyingSessionProductId: record.id,
              quantity: selectedProductQuantity,
              splitToZones: this.isSplitButtonClicked
            }
          : { productId: record.id, quantity: selectedProductQuantity }

      const refetchQueries = []
      let entityType = record.type
      refetchQueries.push({
        query: assortment
          ? assortmentTypeMapping[entityType]['hierarchicalKPIsQuery']
          : assortmentTypeMapping[strings.global]['hierarchicalKPIsQuery'],
        variables: assortment
          ? whereHierarchicalKpiQueryVariablesForAssortment(tab)
          : whereHierarchicalKpiQueryVariables(tab)
      })
      await apolloClient.mutate({
        mutation:
          tab === QuantityType.BET
            ? assortmentTypeMapping[entityType].updateBetQuantityMutation
            : assortmentTypeMapping[entityType].updateBuyQuantityMutation,
        variables: mutationVariables,
        refetchQueries,
        update: async proxy => {
          const data = await apolloClient.readQuery({
            query: assortment
              ? assortmentTypeMapping[entityType]['betBuyValuesQuery']
              : activity
              ? assortmentTypeMapping[strings.activity]['betBuyValuesQuery']
              : assortmentTypeMapping[strings.global]['betBuyValuesQuery'],
            variables: this.whereBetBuyValuesQueryVariables()
          })

          if (data) {
            proxy.writeQuery({
              query: assortment
                ? assortmentTypeMapping[entityType]['betBuyValuesQuery']
                : activity
                ? assortmentTypeMapping[strings.activity]['betBuyValuesQuery']
                : assortmentTypeMapping[strings.global]['betBuyValuesQuery'],
              variables: this.whereBetBuyValuesQueryVariables(),
              data: data
            })
            setTimeout(() => {
              if (resetObservable) {
                this.resetObservableDataForSelectedProduct(record)
              }
            }, 1000)
          }
        }
      })
    }
  }

  @action resetObservableDataForSelectedProduct = record => {
    if (this.isSplitButtonClicked) {
      this.selectedProducts.clear()
    } else {
      this.selectedProducts.delete(record.id)
    }
    this.isSplitButtonClicked = false
  }

  @action onFocusAWS = (event, record) => {
    const selectedProduct = this.selectedProducts.get(record.id)
    let updatedProduct = {
      selectedProductAWS:
        !isNaN(event.target.value) && parseInt(event.target.value) === 0
          ? ''
          : event.target.value,
      selectedProductQuantity: selectedProduct
        ? selectedProduct.selectedProductQuantity
        : (this.selectedTab === 1 ? record.betQty : record.buyQty) || 0,
      selectedProductAvgDepthValue: selectedProduct
        ? selectedProduct.selectedProductAvgDepthValue
        : ApplyToFixedIfDecimal(record.depth) || 0
    }
    this.selectedProducts.set(record.id, updatedProduct)
  }

  @action handleAWSChange = (event, record, product) => {
    const {
      nav: {
        queryParams: { tab }
      }
    } = stores
    if (!isNaN(event.target.value) && event.target.value >= 0) {
      let selectedProduct = this.selectedProducts.get(record.id)
      if (!selectedProduct) {
        this.onFocusAWS(event, record)
        selectedProduct = this.selectedProducts.get(record.id)
      }

      const aws = event.target.value
      let updatedProduct
      if (ViewTabs.FORECAST.toLowerCase() === tab) {
        updatedProduct = {
          selectedProductAWS: aws,
          selectedProductQuantity: record?.betQty,
          selectedProductAvgDepthValue: record?.depth
        }
      } else {
        updatedProduct = this.updateProductKPIsOnAWSChange(record, aws)
      }
      this.selectedProducts.set(record.id, updatedProduct)
    }
  }

  @action onFocusQuantity = (event, record) => {
    const selectedProduct = this.selectedProducts.get(record.id)
    let updatedProduct = {
      selectedProductQuantity:
        !isNaN(event.target.value) && parseInt(event.target.value) === 0
          ? ''
          : event.target.value,
      selectedProductAWS: selectedProduct
        ? selectedProduct.selectedProductAWS
        : ApplyToFixedIfDecimal(record.aws) || 0,
      selectedProductAvgDepthValue: selectedProduct
        ? selectedProduct.selectedProductAvgDepthValue
        : ApplyToFixedIfDecimal(record.depth) || 0
    }
    this.selectedProducts.set(record.id, updatedProduct)
  }

  @action handleQuantityChange = (event, record, product) => {
    const {
      nav: {
        queryParams: { tab }
      }
    } = stores
    if (validateInteger(event.target.value) && event.target.value >= 0) {
      let selectedProduct = this.selectedProducts.get(record.id)

      if (!selectedProduct) {
        this.onFocusQuantity(event, record)
        selectedProduct = this.selectedProducts.get(record.id)
      }
      let updatedProduct
      if (ViewTabs.FORECAST.toLowerCase() === tab) {
        updatedProduct = {
          selectedProductAWS: record?.aws,
          selectedProductQuantity: event.target.value,
          selectedProductAvgDepthValue: record?.depth
        }
      } else {
        updatedProduct = this.updateProductKPIsOnQuantityChange(
          record,
          event.target.value
        )
      }
      this.selectedProducts.set(record.id, updatedProduct)
    }
  }

  @computed get selectedTab() {
    return nav.queryParams.tab ? parseInt(nav.queryParams.tab) : 0
  }

  @action
  handleChipDelete = (tag, type, product: ListViewProduct) => event => {
    const { buyingSessionProductId } = product
    switch (type) {
      case ListViewFieldMapping['CMClusters']: {
        const { clusterAssortmentId, clusterAssortmentProductId } = tag
        const {
          assortment: { removeAssortmentProduct }
        } = stores
        removeAssortmentProduct(
          clusterAssortmentId,
          clusterAssortmentProductId,
          buyingSessionProductId
        )
        break
      }
      case ListViewFieldMapping['BuyerClusters']: {
        const { id: clusterId } = tag
        const { assortmentProductId } = product
        const {
          assortment: { removeBuyerClusterFromZoneAssortmentProduct }
        } = stores
        removeBuyerClusterFromZoneAssortmentProduct(
          clusterId,
          assortmentProductId,
          product.buyingSessionProduct,
          product.buyerClusters
        )
        break
      }
      case ListViewFieldMapping['FreeTags']: {
        const { id: freeTagId } = tag
        this.removeFreeTag(buyingSessionProductId, freeTagId)
        break
      }
      case ListViewFieldMapping['SalesPeriod']: {
        this.removeSalesPeriodFromProduct(buyingSessionProductId)
        break
      }
      case ListViewFieldMapping['ExcludedZones']: {
        const { id: zoneId } = tag
        this.removeZoneRestriction(zoneId, buyingSessionProductId)
        break
      }
      case ListViewFieldMapping['Channels']: {
        const { id: channelId } = tag
        this.toggleChannelDeleteConfirmationDialog(buyingSessionProductId, channelId)
      }
    }
  }

  /**
   * Common drop event handler for list view - (Distribution, BET and BUY tabs for collection and assortment views)
   */
  @action handleListViewTagDrop =
    product =>
    ({ id, type }: { id: string; type: string }) => {
      const {
        assortment: { dropCluster }
      } = stores
      dropCluster(id, type, product.buyingSessionProduct, product.assortmentProductId)
    }

  @action getBuyingSessionProductSizeAttributes = memoize(
    (attributeDefinitions, productAttributes, buyingSessionProductAttributes) => {
      const { buyingSessionProductAttributesDefinitions } = attributeDefinitions
      const buyingSessionProductSizeAttribute =
        buyingSessionProductAttributesDefinitions.map(bsAttributeDefinition => {
          const obj = {
            ...bsAttributeDefinition,
            options: this.getOptions(
              bsAttributeDefinition.id,
              bsAttributeDefinition.name,
              productAttributes,
              buyingSessionProductAttributes
            ).map((option, index) => ({ id: option, name: option, value: index }))
          }
          return obj
        })

      this.buyingSessionProductSizeAttribute = buyingSessionProductSizeAttribute
      return this.buyingSessionProductSizeAttribute
    },
    isEqual
  )

  @action getOptions = (
    bsAttributeDefinitionId,
    attributeType,
    productAttributes,
    buyingSessionProductAttributes
  ) => {
    const { buyingSessionProductSizeAttribute } = strings
    const optionsFromProductAttributes =
      getOptionsForBuyingSessionProductSizeAttributes(productAttributes)
    const selectedAttribute = buyingSessionProductAttributes.find(
      attributeDef => attributeDef.definition.id === bsAttributeDefinitionId
    )
    if (
      selectedAttribute &&
      selectedAttribute.definition.name ===
        strings.buyingSessionProductSizeAttribute.sizeFrom
    ) {
      const sizeFrom = {
        value: selectedAttribute.strVal,
        definition: selectedAttribute.definition.id
      }
      Object.assign(this, { sizeFrom })
    }
    if (
      selectedAttribute &&
      selectedAttribute.definition.name ===
        strings.buyingSessionProductSizeAttribute.sizeTo
    ) {
      const sizeTo = {
        value: selectedAttribute.strVal,
        definition: selectedAttribute.definition.id
      }
      Object.assign(this, { sizeTo })
    }
    if (
      this.sizeFrom.definition &&
      bsAttributeDefinitionId !== this.sizeFrom.definition
    ) {
      if (optionsFromProductAttributes.indexOf(this.sizeFrom.value) === -1) {
        return optionsFromProductAttributes
      } else {
        return optionsFromProductAttributes.filter((option, index) => {
          if (
            this.sizeFrom.value &&
            optionsFromProductAttributes.indexOf(this.sizeFrom.value) <= index
          ) {
            return option
          }
          return false
        })
      }
    } else if (
      this.sizeTo.definition &&
      bsAttributeDefinitionId !== this.sizeTo.definition
    ) {
      if (optionsFromProductAttributes.indexOf(this.sizeTo.value) === -1) {
        return optionsFromProductAttributes
      } else {
        return optionsFromProductAttributes.filter((option, index) => {
          if (
            this.sizeTo.value &&
            optionsFromProductAttributes.indexOf(this.sizeTo.value) >= index
          ) {
            return option
          }
          return false
        })
      }
    } else if (
      !this.sizeFrom.definition &&
      bsAttributeDefinitionId === this.sizeFrom.definition &&
      attributeType === buyingSessionProductSizeAttribute.sizeFrom
    ) {
      return optionsFromProductAttributes.pop()
    } else {
      return optionsFromProductAttributes
    }
  }

  @action handleBuyingSessionProductAttributeChange = async (
    productId,
    definitionId,
    value
  ) => {
    await apolloClient.mutate({
      mutation: UPSERT_BUYINGSESSION_PRODUCT_ATTRIBUTE,
      variables: {
        buyingSessionProductID: productId,
        definitionID: definitionId,
        strVal: value
      }
    })
  }

  @action onSizeChange = (value, sizeType, definition, buyingSessionProductId) => {
    const { buyingSessionProductSizeAttribute } = strings
    if (sizeType === buyingSessionProductSizeAttribute.sizeFrom) {
      const sizeFrom = { value, definition }
      this.handleBuyingSessionProductAttributeChange(
        buyingSessionProductId,
        definition,
        value
      )
      Object.assign(this, { sizeFrom })
    } else {
      const sizeTo = { value, definition }
      this.handleBuyingSessionProductAttributeChange(
        buyingSessionProductId,
        definition,
        value
      )
      Object.assign(this, { sizeTo })
    }
  }

  @action resetSizeAttributeSelections = () => {
    this.sizeFrom = { definition: '', value: '' }
    this.sizeTo = { definition: '', value: '' }
  }

  @action
  toggleChannelDeleteConfirmationDialog = (
    buyingSessionProductId = null,
    channelId = null,
    buyingSessionStatus = null
  ) => {
    if (buyingSessionProductId && channelId) {
      Object.assign(this, {
        openDeleteConfirmationDialog: true,
        buyingSessionProductId: buyingSessionProductId,
        channelId: channelId,
        confirmationMessage:
          buyingSessionStatus === constants.FROZEN ||
          buyingSessionStatus === constants.BUYING
            ? strings.removeChannelMessageForBuyingOrFrozenSession
            : strings.removeChannelMessageForPreBuyingSession
      })
    } else {
      Object.assign(this, {
        openDeleteConfirmationDialog: false,
        buyingSessionProductId: null,
        confirmationMessage: null
      })
    }
  }
}

export const product = new ProductStore()
