
    
    var doc = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getBuyingSessionProductComments"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"buyingSessionProductId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}},"directives":[]}],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getVIPBuyingSessionProduct"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"where"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"buyingSessionProductId"}}}]}}],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"Id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"DocumentsAndComments"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"Id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"Comments"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"Id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"Comment"},"arguments":[],"directives":[]},{"kind":"Field","alias":{"kind":"Name","value":"CreatedAt"},"name":{"kind":"Name","value":"PROP_CreatedAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"Author"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"Id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"Name"},"arguments":[],"directives":[]}]}}]}}]}}]}}]}}]};
  

    
  var names = {};
  function unique(defs) {
    return defs.filter(function (def) {
      if (def.kind !== 'FragmentDefinition') return true;
      var name = def.name.value;
      if (names[name]) {
        return false;
      } else {
        names[name] = true;
        return true;
      }
    });
  };

  

    module.exports = doc
  
