import some from 'lodash/some'
import identity from 'lodash/identity'
import { storage } from '@services/storageService'
import { StyledProps } from '@components/3rd-party/mui'
import { MainTheme } from '@components/3rd-party/themes'
import React from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { stores } from '@stores'
import { observer } from 'mobx-react'
import { User } from '@models'
import * as css from './AssortmentTabs.css'
import styled from 'styled-components'
import { Views, UserRoles } from '@modules/common/models'

interface TabsProps extends StyledProps {
  assortmentType?: String
  match?: any
}

const StyledTabs = styled(Tabs)(props => ({
  '&&': {
    marginTop: '2.4em',
    flex: '0 0 auto',
    minHeight: 44
  }
}))

const StyledTab = styled(Tab)(props => ({
  '&&': {
    textTransform: 'uppercase',
    minWidth: 40,
    minHeight: 30,
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.14px',
    color: '#000000',
    margin: '0 4px 0 4px',
    '& span': {
      padding: '0',
      '> span': {
        padding: '0 0 10px 0'
      }
    },
    '&:hover': {
      color: MainTheme.colors.darkgrey,
      opacity: 1
    }
  }
}))

@observer
export class AssortmentTabs extends React.Component<TabsProps> {
  render() {
    const {
      assortment: { selectedAssortmentTab, setSelectedTab },
      nav: {
        params: { activity, assortmentType, view }
      },
      assortment: { shouldSplitBeAvailable }
    } = stores
    const user = storage.getItem<User>('user')
    const isGlobalMerchandiserOrAdmin = some(
      user.roles,
      role =>
        role.name === UserRoles.CENTRAL_MERCHANDISER_RETAIL ||
        role.name === UserRoles.ADMINISTRATOR
    )
    const isKPIView = view === Views.Kpi
    const tabs = [
      !isKPIView && 'Distribution',
      'Bet',
      'Buy',
      !isKPIView &&
        isGlobalMerchandiserOrAdmin &&
        !assortmentType &&
        activity &&
        'Counter Offer',
      !isKPIView && shouldSplitBeAvailable && 'Split'
    ].filter(identity)

    return (
      <StyledTabs
        value={selectedAssortmentTab}
        onChange={(e, v) => setSelectedTab(v)}
        classes={{ indicator: css.tabsIndicator }}
        TabIndicatorProps={{ children: <div /> }}
      >
        {tabs.map((l, i) => (
          <StyledTab
            key={i}
            disableRipple
            label={l}
            classes={{ selected: css.tabSelected }}
          />
        ))}
      </StyledTabs>
    )
  }
}
