/*
 * @Author: Raeesaa Metkari
 * @Date: 2019-12-17 20:10:56
 * @Last Modified by:   Raeesaa Metkari
 * @Last Modified time: 2019-12-17 20:10:56
 */

export enum QuantityType {
  BET = 'BET',
  BUY = 'BUY'
}

export enum WSQuantityType {
  draft = 'Draft',
  sized = 'Sized'
}
