import { config } from '@config'
import { VIPChannel } from '@modules/common/models/enums/VIPChannel'
import { strings } from '@stores'

const RTL_RESTRICTED_STATUSES = [
  config?.appConfig?.enumerations?.retailBuyingSessionStatus?.BUYING_SESSION_STATUS_VALUES
    .CLOSED
]
const WHL_RESTRICTED_STATUSES = [
  config?.appConfig?.enumerations?.buyingSessionStatus?.BUYING_SESSION_STATUS_VALUES
    .CLOSED
]

export const TAGS_BUYING_SESSION_STATUS_VALIDATIONS = {
  [VIPChannel.Retail]: {
    restrictedStatuses: RTL_RESTRICTED_STATUSES,
    dataKey: 'STD_RTLBuyingSession_Status'
  },
  [VIPChannel.Wholesale]: {
    restrictedStatuses: WHL_RESTRICTED_STATUSES,
    dataKey: 'Status'
  },
  [strings.tagTypes.clientExclusions]: {
    restrictedStatuses: WHL_RESTRICTED_STATUSES,
    dataKey: 'Status'
  },
  [strings.tagTypes.drop]: {
    restrictedStatuses: WHL_RESTRICTED_STATUSES,
    dataKey: 'Status'
  },
  [strings.tagTypes.freeTag]: {
    restrictedStatuses: WHL_RESTRICTED_STATUSES,
    dataKey: 'Status'
  },
  [strings.tagTypes.retailCluster]: {
    restrictedStatuses: RTL_RESTRICTED_STATUSES,
    dataKey: 'STD_RTLBuyingSession_Status'
  },
  [strings.tagTypes.retailFreeTags]: {
    restrictedStatuses: RTL_RESTRICTED_STATUSES,
    dataKey: 'STD_RTLBuyingSession_Status'
  },
  [strings.tagTypes.salesPeriod]: {
    restrictedStatuses: RTL_RESTRICTED_STATUSES,
    dataKey: 'STD_RTLBuyingSession_Status'
  },
  [strings.tagTypes.wholesaleCluster]: {
    restrictedStatuses: WHL_RESTRICTED_STATUSES,
    dataKey: 'Status'
  },
  [strings.tagTypes.wholesaleFreeTags]: {
    restrictedStatuses: WHL_RESTRICTED_STATUSES,
    dataKey: 'Status'
  },
  [strings.tagTypes.zoneExclusions]: {
    restrictedStatuses: RTL_RESTRICTED_STATUSES,
    dataKey: 'STD_RTLBuyingSession_Status'
  }
}

/**
 * fn to derive BS status from tag and to
 * check whether tag operation is
 * restricted for its product's BS
 * for channel exclusive value is compared
 * for rest of the tags, type is sufficient
 * as they are channel specific
 * for e.g. retailClusters/wholesaleClusters
 * @param buyingSession BuyingSession BO
 * @param tagType string
 * @param tagId string- value of the tag
 * @returns boolean
 */
export const isBuyingSessionRestrictedForTag = (
  buyingSession,
  tagType: string,
  tagId?: string
): boolean => {
  let buyingSessionStatus
  if (strings.tagTypes.channel === tagType) {
    buyingSessionStatus =
      buyingSession[TAGS_BUYING_SESSION_STATUS_VALIDATIONS[tagId].dataKey]
    return (
      TAGS_BUYING_SESSION_STATUS_VALIDATIONS[tagId].restrictedStatuses || []
    ).includes(buyingSessionStatus)
  }
  buyingSessionStatus =
    buyingSession[TAGS_BUYING_SESSION_STATUS_VALIDATIONS[tagType].dataKey]
  return (
    TAGS_BUYING_SESSION_STATUS_VALIDATIONS[tagType].restrictedStatuses || []
  ).includes(buyingSessionStatus)
}
