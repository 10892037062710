import { config } from '@config'
import { CriteriaOperationType } from '@models'
import {
  OrderManagementSection,
  OrderType
} from '@modules/common/models/enums/OrderManagement'
import { UPDATE_ORDER_STATUS } from '@modules/showroom/basketAndOrders/graphql/updateOrderStatus'
import { ordersDataStore } from '@modules/showroom/basketAndOrders/store/ordersDataStore'
import { IZone } from '@modules/wholesale/wsNavigations/stores/WholesaleSettingsStore'
import { getUpdateZoneList } from '@services/commonServices'
import { exportExcel } from '@services/exportExcel'
import { storage } from '@services/storageService'
import {
  canUpdateOrderStatus,
  createValidationErrorMessage,
  getListForModal,
  IOrderManagementTableData,
  SAPStatusDescription
} from '@services/wholesale/OrderManagementService'
import { apolloClient, nav, stores, strings } from '@stores'
import axios from 'axios'
import cloneDeep from 'lodash/cloneDeep'
import concat from 'lodash/concat'
import filter from 'lodash/filter'
import findIndex from 'lodash/findIndex'
import forEach from 'lodash/forEach'
import groupBy from 'lodash/groupBy'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import union from 'lodash/union'
import uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'
import { action, computed, observable } from 'mobx'
import { toast } from 'react-toastify'

import { VIPChannel } from '@modules/common/models/enums/VIPChannel'
import { ORDER_SERVICE_ENDPOINTS } from '@modules/order/constants/apiConstants'
import { View } from '@modules/order/constants/views'
import { WS_GET_ALL_CLIENT_ORDERS_FOR_BSG } from '../graphql/getAllClientOrders'
import { WS_GET_CLIENT_ORDERS_WITHOUT_PRODUCT } from '../graphql/getClientOrdersWithoutProduct'
import { WS_GET_DOOR_ORDERS_WITHOUT_PRODUCT } from '../graphql/getDoorOrdersWithoutProduct'
import { OrderManagementService } from '../service'

interface OrderSummary {
  id: string
  orderType: string
  orderNumber?: string
}

export class WSOrderManagementStore {
  private orderManagementService = new OrderManagementService()
  // Delete confirmation
  @observable openConfirmationDialog: boolean = false
  @observable selectedOrderIdForDeletion: string = null
  @observable selectedOrderTypeForDeletion: string = null
  @observable isOrderDeleteInProgress = false
  @observable isOrdersAvaliable = false
  // Order status validation errors
  @observable orderStatusValidationError: boolean = false
  @observable orderStatusValidationErrorMessage: string = ''

  // Status change warning
  @observable showWarningDialog: boolean = false
  @observable statusChangeWarningMessage: string = strings.statusChangeWarning

  @observable selectedOrderIdForStatusUpdate: string = null
  @observable selectedOrderTypeForStatusUpdate: string = null
  @observable statusValueToBeUpdated: string = null
  @observable isStatusChangeInProgress = false
  @observable isOrderValidationIsInProgress = false
  @observable invalidOrderIds = null
  @observable isEmptyOrderDeletePopupVisible = false
  @observable emptyOrderValidationErrorMessage = ''
  @observable changeStatusTo: string = ''
  @observable openMessageDialog = false
  @observable statusChangeConfirmationMessage = strings.canNotChageOrderStatus

  // Bulk status change warning for inactive door or client
  @observable showBulkStatusUpdateWarningDialog: boolean = false
  @observable bulkStatusChangeWarningMessage: string = null

  // Send to SAP warning for inactive door / client and inactive product with qty
  @observable showBulkSendToSAPWarningDialog: boolean = false
  @observable sendToSAPWarningMessage: string = ''

  clearTableFilter = null

  // Send to SAP
  @observable sendToSAPInProgressMap = {}
  @observable sendToSAPBulkInProgress = false
  @observable invalidOrderNumbers = []
  @observable isAddProductDrawerOpen = false
  // Export to excel
  selectedRows = []
  @observable isExportInProgress = false
  @observable isExportAllInProgress = false
  @observable productsAddedToOrder: Array<string> = []
  @observable exportAnchor = null
  @observable canExportOrder = false
  @observable bulkStatusChangeData = null
  @observable isBulkStatusChange = false

  // Send to sap data
  @observable sendToSAPData = null

  @observable activities = {}
  @observable orders = null
  @observable retailZoneList: Array<IZone> = []

  canExportOrders = canExport => {
    if (this.canExportOrder !== canExport) {
      this.canExportOrder = canExport
    }
  }

  getWhereForBuyingSessionProductWhere = buyingSessionList => {
    if (isEmpty(buyingSessionList)) return null
    return {
      OR: buyingSessionList.map(item => ({
        Id: { operation: CriteriaOperationType.EQ, value: item.Id }
      }))
    }
  }

  @action updateRetailZoneList = (zones: Array<IZone>) => {
    this.retailZoneList = getUpdateZoneList(zones)
  }

  @computed get assortmentProductsWhere() {
    const {
      showroomCollection: { buyingSessionList },
      nav: {
        queryParams: { clientId }
      }
    } = stores
    return {
      GIV_VIPAssortmentProduct_Assortment_ref: {
        VIPClientAssortment: {
          Client: { Id: { operation: CriteriaOperationType.EQ, value: clientId } },
          __Parent__: this.getWhereForBuyingSessionProductWhere(buyingSessionList)
        }
      }
    }
  }

  @computed get buyingSessionsProductWhere() {
    const {
      showroomCollection: { buyingSessionList }
    } = stores
    const wholesaleChannelValue =
      config.appConfig?.enumerations?.channel?.VALUES?.WHOLESALE
    return {
      __Parent__: this.getWhereForBuyingSessionProductWhere(buyingSessionList),
      Original: {
        Active: { operation: CriteriaOperationType.EQ, value: true }
      },
      Channels: { operation: CriteriaOperationType.EQ, value: wholesaleChannelValue }
    }
  }

  @action showExportMenu = event => {
    this.exportAnchor = event.currentTarget
  }

  @action hideExportMenu = event => {
    this.exportAnchor = null
  }

  @action onAddOrderProductClick = () => {
    this.isAddProductDrawerOpen = true
  }

  @action closeDrawer = () => {
    this.isAddProductDrawerOpen = false
    this.productsAddedToOrder = []
  }

  @action addProductsAddedToOrder = productId => {
    this.productsAddedToOrder.push(productId)
  }

  @action
  setOrderPresent = avaliable => {
    this.isOrdersAvaliable = avaliable
  }

  @action addProductToOrder = product => e => {
    const {
      showroomCollection: { addProductToBasket },
      nav: {
        queryParams: { orderId }
      }
    } = stores
    addProductToBasket(product?.node, product?.node?.Original?.Id, orderId)
  }

  @action onClickSettingsMenu = view => {
    const {
      nav: {
        queryParams: { section }
      }
    } = stores
    nav.updateQueryParams({
      view,
      section: section ?? OrderManagementSection.clientOrder
    })
  }

  @action onExportOrderClick = e => {
    this.selectedRows.length ? this.showExportMenu(e) : this.exportOrdersToExcel(e)
  }

  setSelectedData = selectedRows => {
    this.selectedRows = selectedRows
  }

  @action handleMessageDialogClose = () => {
    this.openMessageDialog = false
    this.bulkStatusChangeWarningMessage = null
    this.sendToSAPWarningMessage = ''
    this.statusChangeConfirmationMessage = strings.canNotChageOrderStatus
  }

  getWhereBLockForProductCount = (ordersObj: Array<OrderSummary>, orderType) => {
    const orForProductCount = []
    if (orderType === OrderType.Client) {
      forEach(ordersObj, order => {
        const clientOrder = {
          __Parent__: {
            VIPClientOrder: {
              Id: {
                operation: CriteriaOperationType.EQ,
                value: order
              }
            }
          }
        }

        orForProductCount.push(clientOrder)
      })
    } else {
      forEach(ordersObj, order => {
        const doorOrder = {
          __Parent__: {
            VIPDoorOrder: {
              Id: {
                operation: CriteriaOperationType.EQ,
                value: order
              }
            }
          }
        }
        orForProductCount.push(doorOrder)
      })
    }

    return orForProductCount
  }

  hasOrderProducts = async (orders: Array<OrderSummary>): Promise<any> => {
    let whereDoorOrderOrInput = []
    let whereClientOrderOrInput = []
    forEach(orders, (orderSummary: OrderSummary) => {
      const whereId = {
        Id: {
          operation: CriteriaOperationType.EQ,
          value: orderSummary.id
        }
      }

      if (orderSummary.orderType === OrderType.Client) {
        whereClientOrderOrInput.push(whereId)
      } else {
        whereDoorOrderOrInput.push(whereId)
      }
    })

    const queries = []
    if (whereClientOrderOrInput.length) {
      queries.push(
        apolloClient.query({
          query: WS_GET_CLIENT_ORDERS_WITHOUT_PRODUCT,
          variables: {
            whereClientOrderOrInput
          },
          fetchPolicy: 'network-only'
        })
      )
    }

    if (whereDoorOrderOrInput.length) {
      queries.push(
        apolloClient.query({
          query: WS_GET_DOOR_ORDERS_WITHOUT_PRODUCT,
          variables: {
            whereDoorOrderOrInput
          },
          fetchPolicy: 'network-only'
        })
      )
    }

    const emptyOrderData = await Promise.all(queries)

    let orderNumberForEmptyOrders = []
    forEach(emptyOrderData, orderResponse => {
      let orderNumbers = this.extractOrderNumberFromOrders(
        orderResponse?.data?.emptyOrders?.Items || []
      )
      orderNumberForEmptyOrders = concat(orderNumberForEmptyOrders, orderNumbers)
    })

    return orderNumberForEmptyOrders
  }

  hasOrderProductsNew = async (orders: Array<OrderSummary>): Promise<any> => {
    let doorOrderIds = []
    let clientOrderIds = []
    forEach(orders, (orderSummary: OrderSummary) => {
      if (orderSummary.orderType === OrderType.Client) {
        clientOrderIds.push(orderSummary.id)
      } else {
        doorOrderIds.push(orderSummary.id)
      }
    })

    const queries = []
    if (clientOrderIds.length) {
      queries.push(ordersDataStore.getEmptyClientOrders(clientOrderIds))
    }

    if (doorOrderIds.length) {
      queries.push(ordersDataStore.getEmptyDoorOrders(doorOrderIds))
    }
    const [clientOrders, doorOrders] = await Promise.all(queries)
    const emptyOrders = []
    if (clientOrders?.length) {
      concat(emptyOrders, clientOrders)
    }
    if (doorOrders?.length) {
      concat(emptyOrders, doorOrders)
    }
    return emptyOrders
  }

  @action showEmptyOrderDeleteConfirmationPopup = () =>
    (this.isEmptyOrderDeletePopupVisible = true)

  @action hideDeleteConfirmationPopup = () => () => {
    this.isEmptyOrderDeletePopupVisible = false
    this.emptyOrderValidationErrorMessage = ''
  }

  @action setSelectedOrderIdForDeletion = orderId =>
    (this.selectedOrderIdForDeletion = orderId)

  getWhereBLockForClientOrder = (ordersObj: Array<OrderSummary>) => {
    const orForClientOrder = []
    forEach(ordersObj, order => {
      const clientOrder = {
        Id: {
          operation: CriteriaOperationType.EQ,
          value: order.id
        }
      }
      orForClientOrder.push(clientOrder)
    })
    return orForClientOrder
  }

  getOrderNumbers = productMap => {
    const orderNumbers = []
    forEach(productMap, item => {
      if (
        item?.__Parent__?.GIV_VIPDoorOrder_ClientOrder_ref
          ?.GIV_VIPClientOrder_OrderNumber_string
      ) {
        orderNumbers.push({
          id: item?.__Parent__?.GIV_VIPDoorOrder_ClientOrder_ref?.Id,
          orderNumber:
            item?.__Parent__?.GIV_VIPDoorOrder_ClientOrder_ref
              ?.GIV_VIPClientOrder_OrderNumber_string
        })
      } else {
        orderNumbers.push({
          id: item?.__Parent__?.GIV_VIPDoorOrder_OrderNumber_string?.Id,
          orderNumber: item?.__Parent__?.GIV_VIPDoorOrder_OrderNumber_string
        })
      }
    })
    return orderNumbers
  }

  @action onOrderStatusChange = (order: IOrderManagementTableData) => async event => {
    const value = event?.target?.getAttribute('Value')
    let inProcessStatusValue =
      config?.appConfig?.enumerations?.orderStatus?.ORDER_STATUS_VALUES?.['In Process']

    if (value !== inProcessStatusValue) {
      let canUpdateOrderStatusVal = canUpdateOrderStatus(order)
      if (!canUpdateOrderStatusVal || order.hasInactiveProductOrSizeWithQty) {
        this.openMessageDialog = true

        if (!canUpdateOrderStatusVal) {
          this.statusChangeConfirmationMessage = strings.canNotChageOrderStatus
        } else {
          this.statusChangeConfirmationMessage =
            strings.cannotChangeStatusAsProductsAreInactive
        }
        return
      }
    }

    const orderId = order.id
    const orderType = order.orderType
    this.selectedOrderIdForStatusUpdate = orderId
    this.selectedOrderTypeForStatusUpdate = orderType
    this.statusValueToBeUpdated = value
    const orderSummary = [{ id: orderId, orderType }]
    // Validate data in case order status to be updated to confirmed
    let confirmedStatusValue =
      config?.appConfig?.enumerations?.orderStatus?.ORDER_STATUS_VALUES?.Confirmed
    if (value === confirmedStatusValue) {
      const hasProducts = await this.hasOrderProductsNew(orderSummary)
      if (hasProducts?.length) {
        this.showEmptyOrderDeleteConfirmationPopup()
        return
      }

      // Display warning dialog
      this.changeStatusTo = value
      this.invalidOrderIds = [orderId]
      this.showWarningDialog = true
    } else {
      // this.handleStatusChange(
      //   [this.selectedOrderIdForStatusUpdate],
      //   this.statusValueToBeUpdated
      // )
      this.handleStatusChangeNew(
        [this.selectedOrderIdForStatusUpdate],
        this.statusValueToBeUpdated
      )
    }
  }

  getOrderMap = (ordersObj, path) => {
    return map(ordersObj, order => order[path])
  }

  extractOrderNumberFromOrders = (orderItems: Array<any>) => {
    return uniq(map(orderItems, order => order.OrderNumber))
  }

  buildStatusChangeFailedMessageTemplate = (
    {
      ordersWithInactiveDoorClient,
      ordersWithInactiveProductsSizes
    }: {
      ordersWithInactiveDoorClient: Array<string>
      ordersWithInactiveProductsSizes: Array<string>
    },
    totalUniqueOrdersFailedValidation: number,
    totalOrdersSelected: number,
    forSendToSAP: boolean = false
  ) => {
    let template = ''

    if (
      ordersWithInactiveDoorClient.length === totalOrdersSelected ||
      ordersWithInactiveProductsSizes.length === totalOrdersSelected ||
      totalUniqueOrdersFailedValidation === totalOrdersSelected
    ) {
      // Status update won't be allowed
      template += `<div>
      <p>${
        forSendToSAP
          ? 'Order / Orders cannot be sent to SAP'
          : 'Status of orders cannot be updated'
      } as:</p>
        ${
          ordersWithInactiveDoorClient.length
            ? `<li>Either client or door is inactive for orders ${ordersWithInactiveDoorClient.join(
                ','
              )}.</li>`
            : ``
        }
        ${
          ordersWithInactiveProductsSizes.length
            ? `<li>Orders ${ordersWithInactiveProductsSizes.join(
                ','
              )} have at least one inactive product or deactivated size with quantity.</li>`
            : ''
        }
      <br/>
      </div>
    `
    } else {
      // Partial status change
      template += `<div>
      <p>${
        forSendToSAP
          ? 'Following orders cannot be sent to SAP'
          : 'Status of following orders will not be updated'
      } as :</p>
        ${
          ordersWithInactiveDoorClient.length
            ? `<li>Either client or door is inactive for orders ${ordersWithInactiveDoorClient.join(
                ','
              )}.</li>`
            : ''
        }
        ${
          ordersWithInactiveProductsSizes.length
            ? `<li>Orders ${ordersWithInactiveProductsSizes.join(
                ','
              )} have at least one inactive product or deactivated size with quantity.</li>`
            : ''
        }
      <br/>
      <p>Are you sure you want to continue?</p>
      </div>
    `
    }

    return template
  }

  @action bulkStatusChange =
    (ordersObj: Array<IOrderManagementTableData>, value: string, clearTableFilter) =>
    event => {
      this.bulkStatusChangeData = {
        ordersObj,
        value,
        clearTableFilter
      }

      let inProcessStatusValue =
        config?.appConfig?.enumerations?.orderStatus?.ORDER_STATUS_VALUES?.['In Process']
      if (value === inProcessStatusValue) {
        this.handleBulkStatusChange()
        return
      }

      this.isOrderValidationIsInProgress = true
      try {
        const orderForInactvieClientsOrDoors = []
        const ordersWithInactiveProductOrDoorWithQty = []
        forEach(ordersObj, order => {
          if (!canUpdateOrderStatus(order)) {
            orderForInactvieClientsOrDoors.push(order.orderNumber)
          }

          if (order.hasInactiveProductOrSizeWithQty) {
            ordersWithInactiveProductOrDoorWithQty.push(order.orderNumber)
          }
        })
        if (
          orderForInactvieClientsOrDoors.length ||
          ordersWithInactiveProductOrDoorWithQty.length
        ) {
          const uniqueOrders = union(
            orderForInactvieClientsOrDoors,
            ordersWithInactiveProductOrDoorWithQty
          )

          let warningMessage = this.buildStatusChangeFailedMessageTemplate(
            {
              ordersWithInactiveDoorClient: orderForInactvieClientsOrDoors,
              ordersWithInactiveProductsSizes: ordersWithInactiveProductOrDoorWithQty
            },
            uniqueOrders.length,
            ordersObj?.length
          )

          this.bulkStatusChangeWarningMessage = warningMessage
          if (
            orderForInactvieClientsOrDoors.length === ordersObj?.length ||
            ordersWithInactiveProductOrDoorWithQty.length === ordersObj?.length ||
            uniqueOrders.length === ordersObj?.length
          ) {
            this.openMessageDialog = true
          } else {
            this.showBulkStatusUpdateWarningDialog = true
          }
        } else {
          this.handleBulkStatusChange()
        }
      } finally {
        this.isOrderValidationIsInProgress = false
      }
    }

  handleBulkStatusChange = async () => {
    if (this.showBulkStatusUpdateWarningDialog) {
      this.handleBulkStatusUpdateConfirmationDialogClose()
    }

    const { ordersObj, value, clearTableFilter } = this.bulkStatusChangeData
    // Validate data in case order status to be updated to confirmed
    let confirmedStatusValue =
      config?.appConfig?.enumerations?.orderStatus?.ORDER_STATUS_VALUES?.Confirmed
    if (value === confirmedStatusValue) {
      // Check if there are empty order, if yes show confirmation pop-over and return
      const emptyOrders = await this.hasOrderProductsNew(ordersObj)
      if (emptyOrders?.length) {
        this.emptyOrderValidationErrorMessage = getListForModal(
          emptyOrders,
          strings.emptyOrderValidationErrorMessageForBulkUpdate
        )
        this.showEmptyOrderDeleteConfirmationPopup()
        return
      }
      this.changeStatusTo = value
      this.invalidOrderIds = this.getOrderMap(ordersObj, 'id')
      this.clearTableFilter = clearTableFilter
      this.statusChangeWarningMessage = strings.bulkStatusChange.ordersWithoutQty
      this.showWarningDialog = true
      this.isBulkStatusChange = true
    } else {
      this.isBulkStatusChange = true
      const orderIdsToUpdateStatus = this.getOrderMap(ordersObj, 'id')
      // await this.handleStatusChange(orderIdsToUpdateStatus, value, clearTableFilter)
      await this.handleStatusChangeNew(orderIdsToUpdateStatus, value, clearTableFilter)
    }
  }

  @action onValidationWarningConfirm = () => {
    // this.handleStatusChange(
    //   this.invalidOrderIds,
    //   this.changeStatusTo,
    //   this.clearTableFilter
    // )
    this.handleStatusChangeNew(
      this.invalidOrderIds,
      this.changeStatusTo,
      this.clearTableFilter
    )
  }

  @action handleStatusChange = async (orderIds, value, clearTableFilter?: Function) => {
    if (this.isStatusChangeInProgress) {
      return
    }
    if (value) {
      this.isStatusChangeInProgress = true
      try {
        await apolloClient.mutate({
          mutation: UPDATE_ORDER_STATUS,
          variables: {
            orderIds,
            status: value
          },
          update: (proxy, { data: { VIPBoard_UpdateOrderStatus } }) => {
            if (VIPBoard_UpdateOrderStatus.status === strings.mutationResultSuccess) {
              let confirmedStatusValue =
                config?.appConfig?.enumerations?.orderStatus?.ORDER_STATUS_VALUES
                  ?.Confirmed
              if (value === confirmedStatusValue) {
                // invalidate order cache
                stores.showroomCollection.invalidateCacheForAllOrders()
              }

              // update order management page cache
              const orderStatusValues: any =
                config?.appConfig?.enumerations?.orderStatus?.ORDER_STATUS_VALUES ?? {}
              const updatedOrderIds = map(
                VIPBoard_UpdateOrderStatus.modifiedCNLs || [],
                cnl => cnl.Id
              )
              // Mutation was successful, update cache
              const {
                nav: {
                  queryParams: { zone: zoneId, buyingSessionGroupId }
                }
              } = stores
              let data: any = proxy.readQuery({
                query: WS_GET_ALL_CLIENT_ORDERS_FOR_BSG,
                variables: {
                  zoneId,
                  buyingSessionGroupId
                }
              })

              let orders = cloneDeep(data?.findVIPClientOrder?.Items ?? [])
              orders.forEach(order => {
                // If client is master, search for Order ID in client order, else in door orders
                if (order.OrderNumber) {
                  if (includes(updatedOrderIds, order.Id)) {
                    if (
                      order?.Status === orderStatusValues?.['Sent to SAP'] &&
                      order?.SapStatus.toLowerCase() ===
                        SAPStatusDescription.FAILED.toLowerCase()
                    ) {
                      order.SapStatus = ''
                      order.SapOrderId = ''
                    }
                    order.Status = value
                  }
                } else {
                  forEach(order.DoorOrders, doorOrder => {
                    if (includes(updatedOrderIds, doorOrder.Id)) {
                      if (
                        doorOrder?.Status === orderStatusValues?.['Sent to SAP'] &&
                        doorOrder?.SapStatus.toLowerCase() ===
                          SAPStatusDescription.FAILED.toLowerCase()
                      ) {
                        doorOrder.SapStatus = ''
                        doorOrder.SapOrderId = ''
                      }
                      doorOrder.Status = value
                    }
                  })
                }
              })

              proxy.writeQuery({
                query: WS_GET_ALL_CLIENT_ORDERS_FOR_BSG,
                variables: {
                  zoneId,
                  buyingSessionGroupId
                },
                data: {
                  findVIPClientOrder: {
                    ...data.findVIPClientOrder,
                    Items: orders
                  }
                }
              })
            }
          }
        })
      } finally {
        clearTableFilter && clearTableFilter()
        this.handleStatusChangeWarningDialogClose()
      }
    } else {
      this.handleStatusChangeWarningDialogClose()
    }
  }

  @action handleStatusChangeNew = async (
    orderIds,
    value,
    clearTableFilter?: Function
  ) => {
    let {
      axios: { setErrToastVisibility }
    } = stores
    if (this.isStatusChangeInProgress) {
      return
    }
    if (value) {
      this.isStatusChangeInProgress = true
      try {
        setErrToastVisibility(false)
        const response = await ordersDataStore.updateOrderStatus(orderIds, value)
        if (response?.status === 200) {
          // bulk status change
          if (this.isBulkStatusChange) {
            this.removeSelectedOrders(orderIds)
          }
          let confirmedStatusValue =
            config?.appConfig?.enumerations?.orderStatus?.ORDER_STATUS_VALUES?.Confirmed
          if (value === confirmedStatusValue) {
            // invalidate order cache
            stores.showroomCollection.invalidateCacheForAllOrders()
          }
          this.getOrders()
        }
      } catch (e) {
        const message = e.response?.data?.message
        if (message) {
          try {
            const error = JSON.parse(message)
            if (error) {
              this.orderStatusValidationError = true
              this.orderStatusValidationErrorMessage = createValidationErrorMessage(
                error,
                strings.bulkOrderValidationPopupDescription
              )
            }
          } catch (parsingException) {
            this.orderStatusValidationError = true
            this.orderStatusValidationErrorMessage = message
          }
        } else if (e?.response?.data?.statusCode === 400) {
          this.orderStatusValidationError = true
          this.orderStatusValidationErrorMessage = message
        }
      } finally {
        setErrToastVisibility(true)
        clearTableFilter && clearTableFilter()
        this.handleStatusChangeWarningDialogClose()
        this.isBulkStatusChange = false
      }
    } else {
      this.handleStatusChangeWarningDialogClose()
    }
  }

  /**
   * Action for deleting order
   * @param {string} orderId - Order ID
   */
  @action handleOrderDelete = async () => {
    if (this.isOrderDeleteInProgress) {
      return
    }

    if (this.selectedOrderIdForDeletion && this.selectedOrderTypeForDeletion) {
      this.isOrderDeleteInProgress = true
      try {
        await this.orderManagementService.deleteOrder(
          this.selectedOrderIdForDeletion,
          this.selectedOrderTypeForDeletion
        )

        //If this order is already selected remove it
        // let selectedRows = clone(this.selectedRows)
        let index = findIndex(
          this.selectedRows,
          row => row.id === this.selectedOrderIdForDeletion
        )
        if (index > -1) {
          this.selectedRows.splice(index, 1)
        }
        // this.selectedRows = [...selectedRows]
      } finally {
        this.handleConfirmationDialogClose()
        this.getOrders()
      }
    } else {
      this.handleConfirmationDialogClose()
    }
  }

  @action onDeleteIconClick = (orderId: string, orderType: string) => event => {
    this.openConfirmationDialog = true
    this.selectedOrderIdForDeletion = orderId
    this.selectedOrderTypeForDeletion = orderType
  }

  @action handleConfirmationDialogClose = () => {
    this.openConfirmationDialog = false
    this.selectedOrderIdForDeletion = null
    this.selectedOrderTypeForDeletion = null
    this.isOrderDeleteInProgress = false
    this.isEmptyOrderDeletePopupVisible = false
  }

  @action handleBulkStatusUpdateConfirmationDialogClose = () => {
    this.showBulkStatusUpdateWarningDialog = false
    this.showBulkSendToSAPWarningDialog = false
    this.bulkStatusChangeWarningMessage = null
    this.sendToSAPWarningMessage = ''
    this.statusChangeConfirmationMessage = strings.canNotChageOrderStatus
    this.isBulkStatusChange = false
  }

  @action handleValidationDialogClose = () => {
    this.orderStatusValidationError = false
    this.orderStatusValidationErrorMessage = null
    this.statusChangeConfirmationMessage = strings.canNotChageOrderStatus
  }

  @action handleStatusChangeWarningDialogClose = () => {
    this.showWarningDialog = false
    this.changeStatusTo = ''
    this.invalidOrderIds = null
    this.selectedOrderIdForStatusUpdate = null
    this.selectedOrderTypeForStatusUpdate = null
    this.statusValueToBeUpdated = null
    this.isStatusChangeInProgress = false
    this.clearTableFilter = null
    this.bulkStatusChangeWarningMessage = null
    this.sendToSAPWarningMessage = ''
    this.statusChangeConfirmationMessage = strings.canNotChageOrderStatus
  }

  @action showFailedOrders = (
    ordersObj: Array<OrderSummary>,
    ordersSentToSAP: Array<string>,
    isBulkOperation?: boolean,
    errorMessage?: Array<string>
  ) => {
    const failedResult = ordersObj.filter(item => !includes(ordersSentToSAP, item.id))
    if (failedResult.length) {
      if (isBulkOperation) {
        this.orderStatusValidationError = true
        if (errorMessage?.length) {
          this.orderStatusValidationErrorMessage = getListForModal(
            errorMessage,
            strings.failedToSendSapOrders
          )
        } else {
          const invalidOrderNumbers = this.getOrderMap(
            uniqBy(failedResult, (item: any) => item.orderNumber),
            'orderNumber'
          )
          this.orderStatusValidationErrorMessage = getListForModal(
            invalidOrderNumbers,
            strings.bulkExportFailedForInvalidOrders
          )
        }
      } else {
        if (errorMessage?.length) {
          this.orderStatusValidationError = true
          this.orderStatusValidationErrorMessage = getListForModal(
            errorMessage,
            strings.failedToSendSapOrders
          )
        } else {
          toast.error(strings.exportFailedForInvalidOrders, {
            position: toast.POSITION.BOTTOM_RIGHT,
            bodyClassName: 'error-toastr',
            progressClassName: 'error-progress-bar-toaster'
          })
        }
      }
    }
  }

  processSendToSAPResponseAndClearFilter = async ({
    response,
    ordersObj = [],
    isBulkOperation,
    clearTableFilter
  }: {
    response: any
    ordersObj: Array<OrderSummary>
    isBulkOperation: boolean
    clearTableFilter: any
  }) => {
    const clientResponse = response?.data?.['validClientOrders'] || []
    const doorResponse = response?.data?.['validDoorOrders'] || []
    const errorMessage = response?.data?.['messages'] || []
    const ordersSentToSAP = [...clientResponse, ...doorResponse]
    this.showFailedOrders(ordersObj, ordersSentToSAP, isBulkOperation, errorMessage)
    this.getOrders()
    ordersObj.length && delete this.sendToSAPInProgressMap[ordersObj[0]?.id]
    if (
      isBulkOperation &&
      ordersSentToSAP.length === ordersObj.length &&
      clearTableFilter
    ) {
      clearTableFilter()
    }
  }

  @action sendOrderToSAP =
    (
      ordersObj: Array<IOrderManagementTableData>,
      orderType: string,
      clearTableFilter?: any,
      isBulkOperation?: boolean
    ) =>
    e => {
      if (isBulkOperation) {
        if (this.isOrderValidationIsInProgress) {
          return
        }
      } else if (this.sendToSAPInProgressMap[ordersObj[0]?.id]) {
        return
      }

      if (ordersObj.length > 1) {
        this.isOrderValidationIsInProgress = true
      } else {
        this.sendToSAPInProgressMap[ordersObj[0]?.id] = true
      }

      try {
        this.sendToSAPData = {
          ordersObj,
          orderType,
          clearTableFilter,
          isBulkOperation
        }
        const orderForInactvieClientsOrDoors = []
        const ordersWithInactiveProductOrDoorWithQty = []
        forEach(ordersObj, order => {
          if (!canUpdateOrderStatus(order)) {
            orderForInactvieClientsOrDoors.push(order.orderNumber)
          }

          if (order.hasInactiveProductOrSizeWithQty) {
            ordersWithInactiveProductOrDoorWithQty.push(order.orderNumber)
          }
        })
        if (
          orderForInactvieClientsOrDoors.length ||
          ordersWithInactiveProductOrDoorWithQty.length
        ) {
          const uniqueOrders = union(
            orderForInactvieClientsOrDoors,
            ordersWithInactiveProductOrDoorWithQty
          )

          this.sendToSAPData.validationFailedForOrders = uniqueOrders
          let warningMessage = ''

          if (isBulkOperation) {
            warningMessage = this.buildStatusChangeFailedMessageTemplate(
              {
                ordersWithInactiveDoorClient: orderForInactvieClientsOrDoors,
                ordersWithInactiveProductsSizes: ordersWithInactiveProductOrDoorWithQty
              },
              uniqueOrders.length,
              ordersObj?.length,
              true
            )
          } else {
            warningMessage = orderForInactvieClientsOrDoors.length
              ? strings.cannotSendOrderToSAPAsParentIsInactive
              : strings.cannotChangeSendOrderToSAPAsProductsAreInactive
          }

          this.sendToSAPWarningMessage = warningMessage
          if (
            orderForInactvieClientsOrDoors.length === ordersObj?.length ||
            ordersWithInactiveProductOrDoorWithQty.length === ordersObj?.length ||
            uniqueOrders.length === ordersObj?.length
          ) {
            this.openMessageDialog = true
          } else {
            this.showBulkSendToSAPWarningDialog = true
          }
          ordersObj.length && delete this.sendToSAPInProgressMap[ordersObj[0]?.id]
        } else {
          this.handleSendOrderToSAP()
        }
      } finally {
        this.isOrderValidationIsInProgress = false
      }
    }

  /**
   * Method for exporting order to SAP
   * @param {string} orderId - Order ID
   * @param {string} orderType - Order Type
   */
  @action handleSendOrderToSAP = async () => {
    if (this.showBulkSendToSAPWarningDialog) {
      this.handleBulkStatusUpdateConfirmationDialogClose()
    }

    const { ordersObj, clearTableFilter, isBulkOperation, validationFailedForOrders } =
      this.sendToSAPData
    const sessionURL: string = storage.getItem('session_url')
    const accessToken: string = storage.getItem('access_token')

    const finalOrderObjectsToBeUpdated = (validationFailedForOrders || []).length
      ? filter(
          ordersObj,
          orderObj => !includes(validationFailedForOrders, orderObj.orderNumber)
        )
      : ordersObj
    const groupByOrderType = groupBy(finalOrderObjectsToBeUpdated, 'orderType')
    const orderIdsForClient =
      this.getOrderMap(groupByOrderType[OrderType.Client], 'id') || []
    const orderIdsForDoor = this.getOrderMap(groupByOrderType[OrderType.Door], 'id') || []
    try {
      const result = await axios.post(
        `${config.bridgeServiceEndpoint}endpoints/export-sap-orders`,
        {
          ...(orderIdsForClient.length && { clientOrders: orderIdsForClient }),
          ...(orderIdsForDoor.length && { doorOrders: orderIdsForDoor })
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            SessionURL: sessionURL
          }
        }
      )
      this.processSendToSAPResponseAndClearFilter({
        response: result,
        ordersObj: finalOrderObjectsToBeUpdated,
        isBulkOperation,
        clearTableFilter
      })
      if (result.status === 200) {
        if (isBulkOperation) {
          this.removeSelectedOrders([...orderIdsForClient, ...orderIdsForDoor])
        }
      }
      if (!isEmpty((result.data as any)?.invalidOrders)) {
        throw result
      }
    } catch (err) {
      if (
        err.status !== 200 ||
        err?.data?.error ||
        err?.data?.status !== strings.centricCallSuccess
      ) {
        toast.error(err?.data?.error || strings.genericError, {
          position: toast.POSITION.BOTTOM_RIGHT,
          bodyClassName: 'error-toastr',
          progressClassName: 'error-progress-bar-toaster'
        })
        ordersObj.length && delete this.sendToSAPInProgressMap[ordersObj[0]?.id]
      } else if (
        err?.status === 200 &&
        err?.data?.status === strings.centricCallSuccess
      ) {
        if (!isEmpty(err.data?.invalidOrders)) {
          this.orderStatusValidationError = true
          this.orderStatusValidationErrorMessage = createValidationErrorMessage(
            err.data.invalidOrders,
            strings.failedToSendSapOrders
          )
        } else {
          this.processSendToSAPResponseAndClearFilter({
            response: err,
            ordersObj: finalOrderObjectsToBeUpdated,
            isBulkOperation,
            clearTableFilter
          })
        }
      }
    } finally {
      if (ordersObj.length) {
        this.isOrderValidationIsInProgress = false
      }
    }
  }

  @action onClickOrder = (order: IOrderManagementTableData) => event => {
    const {
      nav: {
        queryParams: { view, section },
        updateQueryParams
      }
    } = stores

    updateQueryParams(
      {
        orderId: order?.id,
        clientId: order?.clientId,
        orderType: order?.orderType,
        clientOrderId: order?.clientOrderId ?? undefined,
        buyingSession: order?.buyingSessionId ?? undefined,
        clientType: section === OrderManagementSection.retailOrder ? 'Internal' : '',
        view: View.Product,
        toolbar: 'hide',
        zone: view
      },
      'push'
    )
  }

  getFiltereOrders = (orders, orderType) => {
    let filteredOrders = filter(orders, order => order.orderType === orderType)
    return map(filteredOrders, 'id')
  }

  /**
   * Method for exporting orders to excel
   */
  @action exportOrdersToExcel = async e => {
    const {
      nav: {
        queryParams: { buyingSessionGroupId, section }
      }
    } = stores
    let clientOrders = []
    let doorOrders = []

    if (e.target.id === strings.exportSelectedOrders) {
      clientOrders = this.getFiltereOrders(this.selectedRows, OrderType.Client)
      doorOrders = this.getFiltereOrders(this.selectedRows, OrderType.Door)
    } else {
      const orders = this.orders?.clientOrders || []
      if (section === OrderManagementSection.retailOrder) {
        clientOrders = orders.map(order => order.id)
      } else {
        doorOrders = orders.map(order => order.doorOrders?.[0].id)
      }
    }
    if (buyingSessionGroupId || clientOrders.length || doorOrders.length) {
      if (this.isExportInProgress || this.isExportAllInProgress) {
        return
      } else {
        try {
          const exportOrderEndpoint =
            section === OrderManagementSection.retailOrder
              ? ORDER_SERVICE_ENDPOINTS.DOWNLOAD_CLIENT_ORDERS
              : ORDER_SERVICE_ENDPOINTS.DOWNLOAD_BB_WHOLESALE_ORDERS
          e.target.id === strings.exportSelectedOrders
            ? (this.isExportInProgress = true)
            : (this.isExportAllInProgress = true)
          const response = await exportExcel(
            `${config.buyingBoardServiceRestEndpoint}${exportOrderEndpoint}buyingSessionGroup/${buyingSessionGroupId}`,
            {
              clientOrders,
              doorOrders
            }
          )
          if (response.error) {
            toast.error(response.error || strings.genericError, {
              position: toast.POSITION.BOTTOM_RIGHT,
              bodyClassName: 'error-toastr',
              progressClassName: 'error-progress-bar-toaster'
            })
          }
        } catch (err) {
          if (
            err.status !== 200 ||
            err?.data?.error ||
            err?.data?.status !== strings.centricCallSuccess
          ) {
            toast.error(err?.data?.error || strings.genericError, {
              position: toast.POSITION.BOTTOM_RIGHT,
              bodyClassName: 'error-toastr',
              progressClassName: 'error-progress-bar-toaster'
            })
          }
        } finally {
          this.isExportInProgress = false
          this.isExportAllInProgress = false
          this.exportAnchor = null
        }
      }
    }
  }

  @action removeSelectedOrders = orders => {
    orders.forEach(order => {
      const index = findIndex(this.selectedRows, row => row.id === order)
      if (index > -1) {
        this.selectedRows.splice(index, 1)
      }
    })
  }

  @action getOrders = async () => {
    const {
      nav: {
        queryParams: { buyingSessionGroupId, view, section }
      }
    } = stores
    const clientId = view === strings.worldWide ? '' : view
    const channel =
      section === OrderManagementSection.retailOrder
        ? VIPChannel.Retail
        : VIPChannel.Wholesale
    const orders = await this.orderManagementService.getAllOrders(
      buyingSessionGroupId,
      clientId,
      channel
    )
    if (orders) {
      this.orders = orders
    }
  }

  @action getActivities = async () => {
    const {
      nav: {
        queryParams: { buyingSessionGroupId }
      }
    } = stores
    const activities = await this.orderManagementService.getActivities(
      buyingSessionGroupId
    )
    if (activities?.data) {
      this.activities = activities.data
    }
  }

  @action
  resetActivities = () => {
    this.activities = {}
  }
}
