import { withApollo } from '@apollo/client/react/hoc/withApollo'
import { StyledProps } from '@components/3rd-party/mui'
import { CircularLoading } from '@components/UI-Components/CircularLoading'
import { ConfirmationDialog } from '@components/UI-Components/ConfirmationDialog'
import { MessageDialog } from '@components/UI-Components/MessageDialog'
import {
  AFFECTS_STORE_ASSORTMENT_PRODUCTS,
  UPDATE_ALLOTMENT_FOR_STORE
} from '@modules/retail/clusterSetup/graphql'
import Backdrop from '@material-ui/core/Backdrop'
import FormGroup from '@material-ui/core/FormGroup'
import Portal from '@material-ui/core/Portal'
import Radio from '@material-ui/core/Radio'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { getUserFriendlyErrors } from '@services/errorService'
import { strings } from '@stores'
import cloneDeep from 'lodash/cloneDeep'
import omit from 'lodash/omit'
import { action, observable } from 'mobx'
import { observer } from 'mobx-react'
import React from 'react'
import { ClusterSetupTableHeader } from './ClusterSetupTableHeaders'

const styles = theme => ({
  borderRight: {
    borderRight: '1px solid #e1e1e1'
  },
  table: {
    minWidth: 900,
    marginBottom: 20
  },
  tableHead: {
    backgroundColor: theme.colors.navBackground
  },
  productRadioButton: {
    marginLeft: 5
  },
  clusters: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  clusterNames: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 'bold'
  },
  backdrop: {
    zIndex: 15000
  }
})
interface MyProps extends StyledProps {
  clusters?: any
  clusterSettingData?: any
  storeId?: any
  client?: any
  allotments?: any
}
@((withStyles as any)(styles))
@(withApollo as any)
@observer
export class ClusteringTable extends React.Component<MyProps> {
  state = {
    selectedRadio:
      this.props.allotments && this.props.allotments.length ? this.props.allotments : [],
    selectedCluster: ''
  }
  areClusterChangesSaved = true

  @observable openConfirmationDialog = false
  @observable showCircularLoader = false
  @observable oldSelectedRadio = []
  @observable errorMessage = null
  @observable errorPopupFlag = false
  @observable isClusterChangesInProgress = false

  @action
  setErrorPopupFlag = errorPopupFlag => {
    this.errorPopupFlag = errorPopupFlag
  }

  @action
  setErrorMessage = errorMessage => {
    this.errorMessage = errorMessage
  }

  componentWillUnmount() {
    if (!this.areClusterChangesSaved) {
      this.checkClusteringChangeImpact()
    }
  }

  handleConfirmationDialogClose = () => {
    this.openConfirmationDialog = false
    this.setState({
      selectedRadio: cloneDeep(this.oldSelectedRadio),
      selectedCluster: ''
    })
  }

  onClusterCheck = clusterID => {
    const {
      state: { selectedRadio }
    } = this
    let oldSelectedRadio

    if (this.areClusterChangesSaved) {
      oldSelectedRadio = cloneDeep(selectedRadio)
    }

    this.areClusterChangesSaved = false
    const {
      props: { clusterSettingData }
    } = this
    const emptySelectedRadio = []
    clusterSettingData.forEach(clusterSetting => {
      emptySelectedRadio.push({
        clusterID,
        activityID: clusterSetting.activityId,
        genderID: clusterSetting.genderId
      })
    })

    const newStateObj: any = {
      selectedRadio: emptySelectedRadio,
      selectedCluster: clusterID
    }
    if (oldSelectedRadio) {
      this.oldSelectedRadio = oldSelectedRadio
    }

    this.setState(() => newStateObj, this.checkClusteringChangeImpact)
  }
  onActivityAndGenderCheck = (clusterID, activityID, genderID) => {
    const {
      state: { selectedRadio }
    } = this
    let oldSelectedRadio

    if (this.areClusterChangesSaved) {
      oldSelectedRadio = cloneDeep(selectedRadio)
    }

    this.areClusterChangesSaved = false
    const clusteringItem = { clusterID, activityID, genderID }
    const getExistingIndex = this.isSelected('', activityID, genderID)
    if (getExistingIndex > -1) {
      selectedRadio[getExistingIndex] = clusteringItem
    } else {
      selectedRadio.push(clusteringItem)
    }
    if (oldSelectedRadio) {
      this.oldSelectedRadio = oldSelectedRadio
    }
  }
  isSelected = (clusterID = '', activityID, genderID) => {
    const { selectedRadio } = this.state
    return selectedRadio.findIndex(element => {
      if (clusterID || clusterID === null) {
        return (
          element.clusterID === clusterID &&
          element.activityID === activityID &&
          element.genderID === genderID
        )
      } else {
        return element.activityID === activityID && element.genderID === genderID
      }
    })
  }
  onClusteringSave = async () => {
    const {
      props: { client, storeId },
      state: { selectedRadio },
      formatRequestInput
    } = this

    if (this.openConfirmationDialog) {
      this.isClusterChangesInProgress = true
    }

    try {
      await client.mutate({
        mutation: UPDATE_ALLOTMENT_FOR_STORE,
        variables: {
          clusteringArray: formatRequestInput(selectedRadio),
          storeId
        }
      })
    } catch (e) {
      this.handleClusteringError(e)
    }

    this.areClusterChangesSaved = true
    if (this.openConfirmationDialog) {
      this.openConfirmationDialog = false
      this.isClusterChangesInProgress = false
    }
  }

  handleClusteringError = e => {
    const userFriendlyErrorsObj = getUserFriendlyErrors(e)
    if (
      userFriendlyErrorsObj &&
      userFriendlyErrorsObj.code === strings.associationChangeErrors &&
      userFriendlyErrorsObj.errors &&
      userFriendlyErrorsObj.errors.length
    ) {
      this.setErrorMessage(userFriendlyErrorsObj.errors[0])
      this.setErrorPopupFlag(true)
    }
  }

  handleMessageDialogClose = () => {
    const { setErrorMessage, setErrorPopupFlag } = this
    setErrorMessage(null)
    setErrorPopupFlag(false)
    this.setState({
      selectedRadio: cloneDeep(this.oldSelectedRadio),
      selectedCluster: ''
    })
  }

  formatRequestInput = selectedRadio => {
    const getInvalidElementId = selectedRadio.findIndex(radio => radio.activityID === '')
    if (getInvalidElementId > -1) {
      selectedRadio.splice(getInvalidElementId, 1)
    }
    return selectedRadio.map(radio =>
      radio['__typename'] ? omit(radio, '__typename') : radio
    )
  }

  checkClusteringChangeImpact = async () => {
    this.showCircularLoader = true
    const {
      props: { client, storeId },
      state: { selectedRadio },
      formatRequestInput,
      onClusteringSave
    } = this
    let response
    try {
      response = await client.query({
        query: AFFECTS_STORE_ASSORTMENT_PRODUCTS,
        variables: { clusteringArray: formatRequestInput(selectedRadio), storeId },
        fetchPolicy: 'network-only'
      })
    } catch (e) {
      this.handleClusteringError(e)
    }
    if (response && response.data && response.data.affectsStoreAssortmentProducts) {
      const {
        data: {
          affectsStoreAssortmentProducts: { result }
        }
      } = response
      if (result) {
        // Affects assortment products, so ask for confirmation
        this.openConfirmationDialog = true
      } else {
        // Doesn't affect assortment products, so trigger the mutation
        await onClusteringSave()
      }
    }
    this.showCircularLoader = false
  }

  render() {
    const {
      state: { selectedCluster },
      props: { classes, clusters, clusterSettingData },
      onClusterCheck,
      onActivityAndGenderCheck,
      isSelected,
      handleConfirmationDialogClose,
      onClusteringSave,
      handleMessageDialogClose,
      errorMessage,
      errorPopupFlag,
      isClusterChangesInProgress
    } = this

    return (
      <React.Fragment>
        {this.showCircularLoader ? (
          <Portal>
            <>
              <Backdrop open classes={{ root: classes.backdrop }} />
              <CircularLoading />
            </>
          </Portal>
        ) : null}
        {errorPopupFlag ? (
          <MessageDialog
            open={errorPopupFlag}
            title={strings.errorMsgForFailedToUpdate}
            message={errorMessage}
            handleClose={handleMessageDialogClose}
          />
        ) : null}
        <Table className={classes.table}>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <ClusterSetupTableHeader titles={clusterSettingData} />
            </TableRow>
          </TableHead>
          <TableBody>
            {clusters.map((cluster, outerIndex) => (
              <TableRow key={`clusteringTable-${outerIndex}`}>
                <TableCell className={classes.tableHead}>
                  <div className={classes.clusters}>
                    <Typography variant='caption' className={classes.clusterNames}>
                      {cluster.name}
                    </Typography>
                    <FormGroup className={classes.productRadioButton}>
                      <Radio
                        checked={selectedCluster === cluster.id}
                        onChange={e => onClusterCheck(cluster.id)}
                        value={cluster.id}
                        name={`clusterRadio${outerIndex}`}
                        aria-label={cluster.name}
                        color='primary'
                      />
                    </FormGroup>
                  </div>
                </TableCell>
                {clusterSettingData.map((clusterSetting, index) => {
                  return (
                    clusterSetting.name !== 'Clusters' && (
                      <TableCell
                        key={`genderAndActivities-${clusterSetting.activityId + index}`}
                      >
                        <FormGroup className={classes.productText}>
                          <Radio
                            checked={Boolean(
                              isSelected(
                                cluster.id,
                                clusterSetting.activityId,
                                clusterSetting.genderId
                              ) > -1
                                ? 1
                                : 0
                            )}
                            onChange={() =>
                              onActivityAndGenderCheck(
                                cluster.id,
                                clusterSetting.activityId,
                                clusterSetting.genderId
                              )
                            }
                            value={clusterSetting.activityId + cluster.id}
                            name={`clusterSettingRadio${index}`}
                            aria-label={clusterSetting.name}
                            color='primary'
                          />
                        </FormGroup>
                      </TableCell>
                    )
                  )
                })}
              </TableRow>
            ))}
            {/* No Cluster Row */}
            {
              <TableRow key={`clusteringTable-9`}>
                <TableCell className={classes.tableHead}>
                  <div className={classes.clusters}>
                    <Typography
                      variant='caption'
                      className={classes.clusterNames}
                    >{`No Cluster`}</Typography>
                    <FormGroup className={classes.productRadioButton}>
                      <Radio
                        checked={selectedCluster === null}
                        onChange={e => onClusterCheck(null)}
                        // value={cluster.id}
                        name={`clusterRadioNoCluster`}
                        aria-label={`No Cluster`}
                        color='primary'
                      />
                    </FormGroup>
                  </div>
                </TableCell>
                {clusterSettingData.map((clusterSetting, index) => {
                  return (
                    clusterSetting.name !== 'Clusters' && (
                      <TableCell
                        key={`genderAndActivities-${clusterSetting.activityId + index}`}
                      >
                        <FormGroup className={classes.productText}>
                          <Radio
                            checked={Boolean(
                              isSelected(
                                null,
                                clusterSetting.activityId,
                                clusterSetting.genderId
                              ) > -1
                                ? 1
                                : 0
                            )}
                            onChange={() =>
                              onActivityAndGenderCheck(
                                null,
                                clusterSetting.activityId,
                                clusterSetting.genderId
                              )
                            }
                            name={`clusterSettingRadio${index}`}
                            aria-label={clusterSetting.name}
                            color='primary'
                          />
                        </FormGroup>
                      </TableCell>
                    )
                  )
                })}
              </TableRow>
            }
          </TableBody>
        </Table>
        {this.openConfirmationDialog ? (
          <ConfirmationDialog
            open={this.openConfirmationDialog}
            title={'Confirm'}
            message={
              'This action will delete store assortment and RM cluster assortment for last selected cluster. Do you want to continue?'
            }
            handleClose={handleConfirmationDialogClose}
            handleDelete={onClusteringSave}
            loading={isClusterChangesInProgress}
          />
        ) : null}
      </React.Fragment>
    )
  }
}
