import { ApolloProvider } from '@apollo/client/react'
import { MainTheme } from '@components/3rd-party/themes'
import { Environment } from '@components/Utils/Environment'
import { ErrorHandler } from '@components/Utils/ErrorHandler'
import { NetworkStatusAndErrorNotifier } from '@components/Utils/NetworkStatusAndErrorNotifier'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { stores } from '@stores'
import { apolloClient } from '@stores/apollo'
import { createBrowserHistory } from 'history'
import { Observer } from 'mobx-react'
import { syncHistoryWithStore } from 'mobx-react-router'
import React from 'react'
import ReactDOM from 'react-dom'
import { AppContainer } from 'react-hot-loader'
import { Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { App } from './App'
import registerServiceWorker from './registerServiceWorker'
import './styles'
import './i18n'

const browserHistory = createBrowserHistory()
export const siteHistory = syncHistoryWithStore(browserHistory, stores.routing)

class AppIndex extends React.Component<{}> {
  render() {
    const { children } = this.props
    return (
      <ErrorHandler>
        <ApolloProvider client={apolloClient}>
          {/* Needed for styled-components to win material-ui cascade, but only available in v4 of mui*/}
          {/*<StylesProvider injectFirst> */}
          <MuiThemeProvider theme={MainTheme}>
            <NetworkStatusAndErrorNotifier />
            <Environment />
            <Router history={siteHistory}>{children}</Router>
            <Observer>
              {() => {
                // const { routing } = stores
                return null
              }}
            </Observer>
          </MuiThemeProvider>
          {/*</StylesProvider>*/}
        </ApolloProvider>
      </ErrorHandler>
    )
  }
}

const rootEl = document.getElementById('root')

ReactDOM.render(
  //<React.StrictMode>
  <AppContainer>
    <AppIndex>
      <>
        <ToastContainer position='bottom-right' autoClose={6000} />
        <App />
      </>
    </AppIndex>
  </AppContainer>,
  //</React.StrictMode>
  rootEl
)

registerServiceWorker()

declare const module: any
if (module.hot) {
  module.hot.accept()
  //console.clear()
}
