import { QueryResult } from '@apollo/client/react'
import { Query } from '@apollo/client/react/components/Query'
import { CircularLoading } from '@components/UI-Components/CircularLoading'
import { AuthorizationError } from '@components/Utils/AuthorizationError'
import { User } from '@models'
import { CollectionFilterContainer } from '@modules/retail/collection/container/CollectionFilter'
import { IPermissions } from '@routes'
import { storage } from '@services/storageService'
import { stores } from '@stores'
import { reaction } from 'mobx'
import { disposeOnUnmount, observer } from 'mobx-react'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { ViewTabsMap } from 'src/constant/ViewTabs'
import { AssortmentComponent } from '../component/Assortment'
import { GET_BET_BUY_VALUES_FOR_STORE } from '@modules/retail/assortment/graphql/getBetBuyValuesForStore'
import { GET_STOREASSORTMENT_PRODUCT_FILTERS } from '@modules/retail/assortment/graphql/getStoreAssortmentProductsFilter'
import { STORE_ASSORTMENT } from '@modules/retail/assortment/graphql/storeAssortment'
import { STORE_ASSORTMENT_PRODUCTS_CONNECTION } from '@modules/retail/assortment/graphql/storeAssortmentProductsConnection'
import { STORE_ASSORTMENT_PRODUCTS_HIERARCHY } from '@modules/retail/assortment/graphql/storeAssortmentProductsHierarchyKPIs'
import isEmpty from 'lodash/isEmpty'

interface IAssortment {
  match?: any
  assortment?: any
  permissions?: IPermissions
  history?: any
  clusterId?: any
}

@(withRouter as any)
@observer
export class StoreAssortmentContainer extends React.Component<IAssortment> {
  refetch = null

  @disposeOnUnmount _watchSearchInputEnterKeyPress = reaction(
    () => stores.search.lastUpdatedOn,
    lastUpdatedOn => {
      if (this.refetch) {
        this.refetch()
      }
    }
  )

  setRefetch = refetch => {
    this.refetch = refetch
  }

  onListViewClick = () => {
    const {
      history,
      match: {
        params: {
          assortmentId,
          seasonId,
          genderId,
          podiumId,
          activityId,
          buyingSessionId
        }
      }
    } = this.props
    history.push({
      pathname: `/collections/season/${seasonId}/gender/${genderId}/buyingSession/${buyingSessionId}/store/assortments/${assortmentId}/podium/${podiumId}/activity/${activityId}/views/list`,
      search: history.location.search
    })
  }

  componentDidUpdate = prevProps => {
    const {
      match: {
        params: { assortmentId: oldId }
      }
    } = prevProps

    const [
      {
        match: {
          params: { assortmentId }
        }
      },
      {
        product: { clearRefetchQueries }
      }
    ] = [this.props, stores]

    if (oldId !== assortmentId) {
      clearRefetchQueries()
    }
  }

  componentWillUnmount = () => {
    const {
      product: { clearRefetchQueries }
    } = stores
    clearRefetchQueries()
  }

  render() {
    const user = storage.getItem<User>('user')
    const [
      {
        props: {
          match: {
            params: { assortmentId, buyingSessionId, genderId }
          },
          permissions
        },
        setRefetch
      },
      {
        filter: {
          toggleCollectionFilter,
          productWhereInput,
          finalFilterToApply,
          getFlagFilter
        },
        product: { addToAssortmentRefetchQueries },
        search: { searchKey, prepareProductSearchWhereInput },
        assortment: { selectedAssortmentTab, drawerOpen },
        nav: {
          params: { view }
        }
      }
    ] = [this, stores]

    const flagFilter = getFlagFilter(finalFilterToApply)
    const whereInput = !drawerOpen
      ? prepareProductSearchWhereInput(
          productWhereInput,
          finalFilterToApply,
          user,
          searchKey
        )
      : {}
    return (
      <>
        {permissions && permissions.canRead ? (
          assortmentId ? (
            <>
              <div>
                {toggleCollectionFilter && !drawerOpen ? (
                  <CollectionFilterContainer
                    permissions={permissions}
                    filterQuery={GET_STOREASSORTMENT_PRODUCT_FILTERS}
                    variables={{ assortmentId }}
                  />
                ) : null}
              </div>
              <Query
                query={STORE_ASSORTMENT}
                variables={{
                  assortmentId
                }}
                fetchPolicy={'cache-and-network'}
              >
                {({
                  data: assortmentData = { assortment: undefined },
                  error,
                  loading: assortmentDataLoading
                }: QueryResult) => {
                  return (
                    <Query
                      query={STORE_ASSORTMENT_PRODUCTS_CONNECTION}
                      variables={{
                        assortmentId
                      }}
                      fetchPolicy={'cache-and-network'}
                    >
                      {({
                        data: connectionData,
                        error,
                        loading: assortmentDataLoading
                      }: QueryResult) => {
                        const buyTabIndex = ViewTabsMap[view]
                          ? ViewTabsMap[view].findIndex(
                              tab => tab.name === 'WHOLESALE BUY'
                            )
                          : -1
                        return (
                          <Query
                            query={STORE_ASSORTMENT_PRODUCTS_HIERARCHY}
                            context={{
                              noBatch: true
                            }}
                            variables={{
                              assortmentId,
                              productWhereInput: whereInput,
                              genderId,
                              flagFilter,
                              quantityType:
                                selectedAssortmentTab === buyTabIndex ? 'BUY' : 'BET'
                            }}
                            fetchPolicy={'cache-and-network'}
                          >
                            {({
                              data: activityHierarchy,
                              loading,
                              refetch,
                              variables
                            }: QueryResult) => {
                              addToAssortmentRefetchQueries(
                                refetch,
                                STORE_ASSORTMENT_PRODUCTS_HIERARCHY,
                                variables
                              )
                              setRefetch(refetch)
                              return (
                                <Query
                                  query={GET_BET_BUY_VALUES_FOR_STORE}
                                  context={{
                                    noBatch: true
                                  }}
                                  variables={{
                                    assortmentId: assortmentId
                                  }}
                                  fetchPolicy='cache-and-network'
                                >
                                  {({
                                    data: betBuyData,
                                    loading: betBuyLoading
                                  }: QueryResult) => {
                                    if (
                                      (assortmentDataLoading &&
                                        isEmpty(assortmentData)) ||
                                      (betBuyLoading && isEmpty(betBuyData))
                                    )
                                      return <CircularLoading />
                                    if (error) return null

                                    let assortment = null
                                    if (assortmentData && assortmentData.assortment) {
                                      assortment = assortmentData.assortment
                                    }

                                    return (
                                      <AssortmentComponent
                                        // need to change this
                                        assortment={assortment}
                                        title={
                                          assortment && assortment.store
                                            ? assortment.store
                                            : { name: '' }
                                        }
                                        isCategoryDataLoading={loading}
                                        totalProducts={
                                          connectionData &&
                                          connectionData.storeAssortmentProductsConnection
                                            ? connectionData
                                                .storeAssortmentProductsConnection
                                                .aggregate.count
                                            : 0
                                        }
                                        assortmentId={assortmentId}
                                        buyingSessionId={buyingSessionId}
                                        permissions={permissions}
                                        betBuyData={betBuyData || []}
                                        store={assortment ? assortment.store : null}
                                        zone={
                                          assortment && assortment.store
                                            ? assortment.store.zone
                                            : null
                                        }
                                        buyingSession={
                                          assortment && assortment.buyingSession
                                        }
                                        activityHierarchy={
                                          activityHierarchy
                                            ? activityHierarchy.storeAssortmentProductsKPIs
                                            : null
                                        }
                                      />
                                    )
                                  }}
                                </Query>
                              )
                            }}
                          </Query>
                        )
                      }}
                    </Query>
                  )
                }}
              </Query>
            </>
          ) : (
            'Loading...'
          )
        ) : (
          <AuthorizationError />
        )}
      </>
    )
  }
}
