import { CentricTheme, StyledProps } from '@components/3rd-party/mui'
import { GridCard } from '@components/UI-Components/GridCard'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { IPermissions } from '@routes'
import {
  getAssociatedLooks,
  getProductClustersWithStatus,
  getProductDetails,
  getSubstitutesInLooks
} from '@services/collectionServices'
import { stores } from '@stores'
import map from 'lodash/map'
import memoize from 'memoize-one'
import { observer } from 'mobx-react'
import React from 'react'
import { withRouter } from 'react-router-dom'

const styles = (theme: CentricTheme) => ({
  root: {
    overflow: 'hidden',
    paddingBottom: '1em'
  },
  sectionRoot: {
    overflow: 'hidden',
    overflowY: 'auto'
  },
  productContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: theme.spacing(1) / 2,
    background: '#FFFFFF',
    border: '1px solid lightgrey'
  },
  label: {
    paddingLeft: 12
  }
})

interface MyProps extends StyledProps {
  product?: any
  genderId?: any
  podiumId?: any
  refetch?: any
  buyingSessionProducts: any
  permissions?: IPermissions
  history?: any
  match?: any
}

@((withStyles as any)(styles))
@(withRouter as any)
@observer
export class ProductsCardGrid extends React.Component<MyProps> {
  state = {
    selectedProducts: []
  }

  rightClickPosition = [0, 0]
  scrollParentRef = null

  clickHandler = (
    e: React.MouseEvent<HTMLElement>,
    index: number,
    product: { id: string; buyingSession: { id: string }; productStatus: string }
  ): void => {
    const [
      {
        props: {
          match: { url }
        }
      },
      {
        collection: { onSelectProduct }
      }
    ] = [this, stores]
    if (e.shiftKey) {
      onSelectProduct(product)
      e.preventDefault()
    } else {
      const { history } = this.props

      history.push({
        pathname: `${url}/details/${product.id}`,
        search: history.location.search
      })
    }
  }

  handleMenuClose = () => {
    const {
      collection: { resetSelectedProducts }
    } = stores
    resetSelectedProducts()
  }

  rightClickHandler = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    const {
      collection: { selectedProducts },
      restrictProductByZoneModal: { handleRestrictProductByZoneModalOpen },
      site: { isGlobalMerchandiserOrAdmin }
    } = stores

    if (isGlobalMerchandiserOrAdmin()) {
      selectedProducts.length && handleRestrictProductByZoneModalOpen()
    }
  }

  // TODO: RM - Move this function to service
  getProcessedBuyingSessionProducts = memoize(buyingSessionProducts => {
    const {
      collection: { isProductRestrictedInZone },
      product: { getProductStatus },
      sidebar: { isRegionalMerchandiser }
    } = stores
    return map(buyingSessionProducts, productNode => {
      const { node: product } = productNode
      let processedProduct: any = getProductDetails(product, false, null)
      processedProduct.productStatusToBeDisplayed = getProductStatus(
        processedProduct.productStatus,
        processedProduct.active
      )
      processedProduct.actualProductObj = product
      // TODO: Rename this field to something more meaningful
      processedProduct.tags =
        product && product.assortmentProducts
          ? getProductClustersWithStatus(
              product.assortmentProducts,
              processedProduct.clusterStatuses
            )
          : []
      processedProduct.associatedLooks = getAssociatedLooks(product)
      processedProduct.substitutesInLooks = getSubstitutesInLooks(product)
      processedProduct.retailPriceToBeDisplayed = isRegionalMerchandiser()
        ? processedProduct.zoneRetailPrice
        : processedProduct.retailPrice
      processedProduct.isRestricted =
        isRegionalMerchandiser() && isProductRestrictedInZone(product)
      return processedProduct
    })
  })

  render() {
    const {
      props: { refetch, buyingSessionProducts, permissions }
    } = this

    const {
      product: {
        markFavourite,
        removeFavourite,
        handleTagDrop,
        removeFreeTag,
        removeSalesPeriodFromProduct,
        removeZoneRestriction
      },
      collection: { selectedProducts },
      assortment: { removeAssortmentProduct }
    } = stores

    const processedProducts =
      this.getProcessedBuyingSessionProducts(buyingSessionProducts)
    return (
      <>
        {processedProducts.length ? (
          processedProducts.map((product, key) => {
            return (
              <GridCard
                key={product.id}
                index={`${key}`}
                product={product}
                handleTagDrop={handleTagDrop}
                clickHandler={this.clickHandler}
                rightClickHandler={this.rightClickHandler}
                removeAssortmentProduct={removeAssortmentProduct}
                removeZoneRestriction={removeZoneRestriction}
                removeSalesPeriodFromProduct={removeSalesPeriodFromProduct}
                removeFreeTag={removeFreeTag}
                markFavourite={markFavourite}
                removeFavourite={removeFavourite}
                refetch={refetch}
                permissions={permissions}
                isSelected={
                  !!selectedProducts.find(
                    selectedProduct => selectedProduct.productId === product.id
                  )
                }
              />
            )
          })
        ) : (
          <div>
            <Typography variant='h5'>No Record Found.</Typography>
          </div>
        )}
      </>
    )
  }
}
