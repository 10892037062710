export const RETAILRIGHTSIDENAVTOP = 48
export const STANDARDRIGHTSIDENAVTOP = 60

/**
 * List view row heights
 */
export const PRODUCTROWHEIGHT = 230
export const STICKYROWHEIGHT = 26
export const WHOLESALEPRODUCTROWHEIGHT = 230
export const SAWHOLESALEPRODUCTROWHEIGHT = 230
