/**
 * Utility method required in salesPeriod component
 */

export const DATE_FORMAT = 'YYYY-MM-DD'

/**
 * Returns diff between two dates in specific duration
 *
 * @param {string} fromDate
 * @param {string} toDate
 *
 * @returns {number} diff
 */
export const getDateDiff = (toDate, fromDate) => {
  return Math.round(
    (new Date(toDate).getTime() - new Date(fromDate).getTime()) /
      (7 * 24 * 60 * 60 * 1000)
  )
}
