import { StyledProps } from '@components/3rd-party/mui'
import React, { memo } from 'react'
import * as css from './AttributeList.css'
import { ProductInfoItem } from './ProductCardOld/ProductInfoItem'

interface IProps extends StyledProps {
  productAttributes?: any
  productId?: string
}

export const AttributeList = memo(
  (props: IProps) => {
    const { productAttributes } = props
    return (
      <div className={css.productDetailContainer}>
        {productAttributes &&
          productAttributes.map(({ id, label, value }) => {
            return (
              <ProductInfoItem
                key={id}
                title={label}
                productInfoContainerStyle={css.productInfo}
                contentTextStyle={css.contentText}
                content={value}
                titleTextStyle={css.titleText}
                showTooltip={true}
              />
            )
          })}
      </div>
    )
  },
  (previousProps, nextProps) => previousProps.productId === nextProps.productId
)
