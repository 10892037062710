import { CircularLoading } from '@components/UI-Components/CircularLoading'
import { CrossIcon } from '@components/UI-Components/svg'
import { Tag } from '@components/UI-Components/Tag'
import { DraggableSource } from '@components/Utils/DraggableSource'
import { DropTarget } from '@components/Utils/DropTarget'
import { ZoneDisplayNames } from '@modules/common/models/enums/ZoneDisplayNames'
import Chip from '@material-ui/core/Chip'
import Typography from '@material-ui/core/Typography'
import {
  checkIfRetailChannelAdded,
  getDraggbleSourceTittle,
  tagsForRestrictProductByZone
} from '@services/collectionServices'
import { stores, strings } from '@stores'
import map from 'lodash/map'
import { observer } from 'mobx-react-lite'
import React from 'react'

import * as css from './RestrictProductByZone.css'

interface RestrictProductByZoneDragDropAreaProps {
  loading: boolean
  salesPeriodClusterChannelsResponse: any
  zoneResponse: any
  freeTagsResponse: any
}
const handleTagDrop = (type, id) => {
  const {
    restrictProductByZoneModal: { preparePayloadToRestricProduct }
  } = stores
  preparePayloadToRestricProduct(type, id)
}
export const RestrictProductByZoneDragDropArea: React.FunctionComponent<RestrictProductByZoneDragDropAreaProps> =
  observer(props => {
    const {
      loading,
      salesPeriodClusterChannelsResponse,
      zoneResponse,
      freeTagsResponse
    } = props
    const {
      restrictProductByZoneModal: {
        getchangeSelectedTabForRestricProduct,
        removeTags,
        coreProducts,
        retailChannelProducts,
        bulkAddChannel
      },
      clusterStatusDropDown: { handelPopOverClick }
    } = stores
    const selectedTabForRestricProduct = getchangeSelectedTabForRestricProduct
    const tags =
      !loading &&
      tagsForRestrictProductByZone(
        false,
        zoneResponse ? zoneResponse.zones : [],
        salesPeriodClusterChannelsResponse,
        freeTagsResponse ? freeTagsResponse.freeTags : []
      )
    const addedTags =
      !loading &&
      tagsForRestrictProductByZone(
        true,
        zoneResponse ? zoneResponse.zones : [],
        salesPeriodClusterChannelsResponse,
        freeTagsResponse ? freeTagsResponse.freeTags : []
      )
    const isRetailChannelAdded =
      salesPeriodClusterChannelsResponse && !loading
        ? checkIfRetailChannelAdded(
            salesPeriodClusterChannelsResponse[strings.channels],
            bulkAddChannel
          )
        : false
    const draggbleSourceTittle = !loading && getDraggbleSourceTittle()
    return loading ? (
      <CircularLoading />
    ) : (
      <React.Fragment>
        <div>
          {coreProducts.length &&
          (!getchangeSelectedTabForRestricProduct ||
            getchangeSelectedTabForRestricProduct === 'Zone exclusion') ? (
            <Typography className={css.coreProductSelectionMsg} variant='caption'>
              {`* You have selected ${coreProducts.length} core ${
                coreProducts.length > 1 ? 'products' : 'product'
              }. Zone exclusion will not be applied to those.`}
            </Typography>
          ) : null}

          {retailChannelProducts.length &&
          getchangeSelectedTabForRestricProduct === 'Cluster' ? (
            isRetailChannelAdded ? null : (
              <Typography className={css.coreProductSelectionMsg} variant='caption'>
                {`* You have selected ${retailChannelProducts.length} ${
                  retailChannelProducts.length > 1 ? 'products' : 'product'
                } which ${
                  retailChannelProducts.length > 1 ? 'are' : 'is'
                } not present in Retail channel. Clusters will not be applied to those.`}
              </Typography>
            )
          ) : null}

          {coreProducts.length &&
          (!getchangeSelectedTabForRestricProduct ||
            getchangeSelectedTabForRestricProduct === 'Cluster') ? (
            <Typography className={css.coreProductSelectionMsg} variant='caption'>
              {`* You have selected ${coreProducts.length} core ${
                coreProducts.length > 1 ? 'products' : 'product'
              }. Cluster status will not be applied to those.`}
            </Typography>
          ) : null}
        </div>
        <div className={css.mainContainer}>
          <div className={css.allTagsContainer}>
            <div className={css.containerTitle}>
              <Typography variant='caption'>{`All ${draggbleSourceTittle}`}</Typography>
            </div>
            <div className={css.draggbleSourceArea}>
              {map(tags, tag => (
                <DraggableSource
                  key={tag.id}
                  libraryItem={{
                    id: tag.id,
                    type: selectedTabForRestricProduct
                      ? selectedTabForRestricProduct
                      : 'Zone exclusion'
                  }}
                  className={css.draggbleSource}
                >
                  <Chip
                    classes={{
                      label: css.label
                    }}
                    className={css.chip}
                    label={
                      <Tag
                        name={ZoneDisplayNames[tag.name] || tag.name}
                        color={tag.colorHex}
                      />
                    }
                  />
                </DraggableSource>
              ))}
            </div>
          </div>
          <DropTarget
            className={css.dropTarget}
            canDrop
            handleDrop={({ id, type }) => handleTagDrop(type, id)}
          >
            <div className={css.excludeTagsContainer}>
              <div className={css.containerTitle}>
                {!getchangeSelectedTabForRestricProduct ||
                getchangeSelectedTabForRestricProduct === 'Zone exclusion' ? (
                  <Typography variant='caption'>
                    Exclude selected products from
                  </Typography>
                ) : (
                  <Typography variant='caption'>Apply to selected products</Typography>
                )}
              </div>
              <div className={css.draggbleSourceArea}>
                {map(addedTags, tag => (
                  <Chip
                    classes={{
                      label: css.label
                    }}
                    className={css.chip}
                    deleteIcon={<img src={CrossIcon} alt='cross' />}
                    onDelete={() => {
                      removeTags(tag.id)
                    }}
                    key={tag.id}
                    onClick={
                      tag.__typename === strings.Cluster
                        ? handelPopOverClick(null, tag.id)
                        : null
                    }
                    label={
                      <Tag
                        name={ZoneDisplayNames[tag.name] || tag.name}
                        color={tag.colorHex}
                        clusterId={tag.id}
                        statusId={tag.statusId}
                        clusterStatus={tag.statusName}
                      />
                    }
                  />
                ))}
              </div>
            </div>
          </DropTarget>
        </div>
      </React.Fragment>
    )
  })
