import { StyledProps } from '@components/3rd-party/mui'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import React from 'react'
import { SecondaryButton } from './SecondaryButton'

interface IConfirmationDialog extends StyledProps {
  open?: boolean
  handleClose?: any
  title?: any
  message?: any
  hideCloseButton?: boolean
  closeButtonTitle?: string
}

export class MessageDialog extends React.Component<IConfirmationDialog> {
  render() {
    const {
      props: { open, handleClose, title, message, hideCloseButton, closeButtonTitle }
    } = this

    return (
      <Dialog
        open={open as boolean}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText
            id='alert-dialog-description'
            dangerouslySetInnerHTML={{ __html: message }}
          />
        </DialogContent>
        <DialogActions>
          {!hideCloseButton ? (
            <SecondaryButton onClick={handleClose}>
              {closeButtonTitle || 'Ok'}
            </SecondaryButton>
          ) : null}
        </DialogActions>
      </Dialog>
    )
  }
}
