import { config } from '@config'
import { TableViewColumnDefinition } from '@components/UI-Components/StandardTableView/models/TableViewColumnDefinition'
import { OrderType } from '@modules/common/models/enums/OrderManagement'
import { IStandardBuyingSession } from '@modules/common/models/interfaces/BuyingSession'
import { CentricEnumValue } from '@modules/common/models/interfaces/CentricEnumValue'
import { CentricNode } from '@modules/common/models/interfaces/CentricNode'
import { IBuyingGroupDeliveryWindow } from '@modules/showroom/basketAndOrders'
import { convertToCurrencyLocaleString } from '@services/assortmentService'
import {
  getEnumDescriptionFromValue,
  getEnumOptionMap,
  isWholesaleCMOrAdmin,
  isZAM,
  searchData
} from '@services/commonServices'
import { strings } from '@stores'
import memoize from 'memoize-one'
import { getUTCFromTimeStamp } from '../commonServices'
import { IDoor } from './doorManagementService'
import compact from 'lodash/compact'
import filter from 'lodash/filter'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import forEach from 'lodash/forEach'
import groupBy from 'lodash/groupBy'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import keys from 'lodash/keys'
import map from 'lodash/map'

type OrderDataWithStatusEnums = {
  clients: Array<IClient>
  customerGroupEnumValues: Array<CentricEnumValue>
  buyingSessionGroup: IBuyingSessionGroup
  exchangeRatesForGlobalCurrency: any
}

export enum SAPStatusDescription {
  NOT_RECEIVED = 'NOT RECEIVED',
  RECEIVED = 'RECEIVED',
  FAILED = 'FAILED',
  SEND = 'SEND',
  SENT = 'SENT'
}

export enum SAPStatusDescriptionColorMapping {
  'NOT RECEIVED' = '#EF8A93',
  FAILED = '#A70000',
  RECEIVED = '#A5E170',
  SEND = '#4ba4f9',
  SENT = '#c2c2c2',
  INACTIVE = 'grey'
}

export interface IOption {
  label: string
  value: string
}

export interface IOrderManagementTableData {
  id: string
  name: string
  clientId: string
  code: string
  buyingSessionId?: string
  clientOrderId?: string
  date: string
  orderNumber: string
  salesAssistant: string
  doors: IOption[]
  value: string
  globalValue: string
  deliveryDate: string
  qty: string
  country: string
  zam: string
  activityGender: IOption[]
  orderType: OrderType
  shipTo: string
  deliveryWindow?: IBuyingGroupDeliveryWindow
  isDeliveryDateDisabled?: boolean
  disablePDFExport: boolean

  /**
   * Client status / availability
   */
  isClientDisabled: boolean // Will be true in case client is not available

  /**
   * Order status
   */
  status: string
  statusEnumValue: string
  possibleUpdateOptionsForStatus: Array<IOption>
  isEditStatusDisabled: boolean

  /**
   * SAP fields
   */
  sapStatus: string // Will be either "Sent" / "Received" / "Not received"
  sapStatusColor: string
  isSapStatusBold: boolean
  canSendToSap: boolean // Will be true in case order status is validated
  disableSendToSap: boolean
  sapOrderNumber: string

  /**
   * Operations on order
   */
  isDeleteStatusDisabled: boolean
  hasInactiveProductOrSizeWithQty: boolean

  isClientActive: boolean
  isDoorActive: boolean
}

export interface IClient {
  id: string
  name: string
  code: string
  countryCode: string
  currency: string
  active: boolean
  channel?: string
}

export interface IClientOrder {
  id: string
  orderName: string
  orderType: string
  orderId: string
  shipTo: string
  orderValue: number
  CreatedBy: ISalesAssistant
  creationDate: number
  globalValue: number
  deliveryDate: number
  doorOrders: Array<IDoorOrder>
  Client: IClient
  Sessions: Array<IActivity>
  internalClient: boolean
  status: string
  sapStatus: string
  sapOrderNumber: string
  LastUpdatedAt: number
  hasInactiveDoor?: boolean
  hasInactiveProductOrSizeWithQty?: boolean
  salesOfficeDescription: string
  salesAssistant: string
  orderQuantity: string
  activityIds: Array<any>
  isClientMaster: boolean
  buyingSession: any
}

export interface IDoorOrder {
  Id: string
  CreatedBy: ISalesAssistant
  CreationDate: string
  OrderNumber: string
  TotalQty: number
  TotalSizedQty: number
  SizedValue: number
  TotalValue: number
  DeliveryDate: number
  Status: string
  Door: IDoor
  SapStatus: string
  SapOrderId: string
  LastUpdatedAt: number
  HasInactiveProductOrSizeWithQty?: boolean
  id?: string
  hasInactiveProductOrSizeWithQty?: boolean
  doorActive?: boolean
}

export interface ISalesAssistant {
  Name: string
}

export interface IActivity {
  Activity: CentricNode
}

export interface IBuyingSessionGroup {
  Id: string
  BuyingSessionGroupStatus: string
  Gender: CentricNode
  ExcludedClients: Array<Partial<CentricNode>>
  DeliveryWindow?: IBuyingGroupDeliveryWindow
  Sessions?: Array<IStandardBuyingSession>
  Season?: CentricNode
}

export interface OrderBaseDetails {
  sessions: Array<any>
  clientName: string
  isClientUnavailable: boolean
}

export const getDoorsList = data => {
  const doorsList = []
  forEach(data, ({ Doors }) =>
    forEach(Doors, ({ Id, Name, IsActive }) => {
      if (IsActive) {
        doorsList.push({ Id, Name })
      }
    })
  )
  return doorsList
}

export const isStepDisabled = (activeStep, activityIds, selectedDoors) => {
  switch (activeStep) {
    case 0:
      if (activityIds?.length) {
        return false
      }
      return true
    case 1:
      if (selectedDoors?.length) {
        return false
      }
      return true
    case 2:
      if (activityIds?.length && selectedDoors?.length) {
        return false
      }
      return true
    default:
      return false
  }
}

export const getInstructionText = (maxLimit: number, data: Array<any> = []) => {
  return `Status change can be applied only to maximum of ${maxLimit} ${
    maxLimit > 1 ? 'records' : 'record'
  }. Currently ${data.length} ${
    data.length > 1 ? 'records' : 'record'
  } are present in table.`
}

export const getStatusesList = memoize(statuses => {
  let valueColorMap = config?.appConfig?.enumerations?.orderStatus?.VALUE_COLOR_MAP ?? {}
  const options = []
  forEach(statuses, status => {
    const option = {
      ...status,
      color: valueColorMap[status?.value]
    }
    options.push(option)
  })
  return options
}, isEqual)

export const isDisabledBulkStatusIcon = (
  data,
  key,
  status,
  filteredIds,
  possibleUpdateOptionsForStatus
) => {
  const groupByStatus = keys(groupBy(data, (item: any) => item[key])).length
  const filteredRows = filter(data, data => data[key] === status)
  if (
    groupByStatus > 1 ||
    !filteredIds?.length ||
    !filteredRows?.length ||
    !data?.length ||
    !possibleUpdateOptionsForStatus.length ||
    key !== 'status'
  ) {
    return true
  }
}

export const isDisabledBulkSendToSAPIcon = (filteredRows, filteredIds) => {
  let validatedStatusValue =
    config?.appConfig?.enumerations?.orderStatus?.POSSIBLE_VALUES_FOR_SEND_TO_SAP
  const rows = filter(filteredRows, data =>
    includes(validatedStatusValue, data?.statusEnumValue)
  )
  if (!filteredIds?.length || !rows.length) {
    return true
  }
}

export const canUpdateOrderStatus = order => {
  if (order?.orderType === OrderType.Client) {
    return order?.isClientActive && !order?.clientHasInactiveDoor
  }
  if (order?.orderType === OrderType.Door) {
    return order?.isDoorActive && order?.isClientActive
  }
}

export const getFilteredData = (data, selectedRows) => {
  let filteredData = []
  const selectedData = []
  forEach(data, record => {
    filteredData.push(record)
    const index = findIndex(selectedRows, { id: record.id })
    if (index > -1) {
      selectedData.push(record)
    }
  })
  return { filteredData, selectedData }
}

export const getListForModal = (list, titleMessage) => {
  return `<div>
      <Typography>${titleMessage}</Typography>
      <ul>
      ${map(list, item => `<li>${item}</li>`).join('')}
      </ul>
    </div>`
}

export const createValidationErrorMessage = (error, descText): string => {
  return getListForModal(
    Object.keys(error).map(orderId =>
      getListForModal(createOrderErrorMessage(error[orderId]), `Order No: ${orderId}`)
    ),
    descText
  )
}

const createOrderErrorMessage = (errors: any): string[] => {
  return Object.keys(errors).map(errorId =>
    getListForModal(errors[errorId], strings.orderValidationErrorKeys[errorId])
  )
}

export const getStatusData = memoize((rowData, key) => {
  const possibleUpdateOptionsForStatus = rowData?.possibleUpdateOptionsForStatus
  const status = rowData?.[key]
  const statusEnumValue = rowData?.statusEnumValue
  return {
    status,
    possibleUpdateOptionsForStatus,
    statusEnumValue
  }
}, isEqual)

export const getDoorOrdersList = memoize(orderData => {
  return orderData ? [orderData] : []
}, isEqual)

export const getOrderBaseDetails = memoize((orderData, isDoorOrder): OrderBaseDetails => {
  let order
  if (isDoorOrder) {
    order = orderData?.clientOrder ?? {}
  } else {
    order = orderData
  }

  const clientId = order?.client?.Id
  let sessions = order?.buyingSession ?? null
  let clientName = order?.client?.Name ?? undefined
  let isClientUnavailable = !!find(
    order?.buyingSessionGroup?.excludedClients ?? [],
    client => client.Id === clientId
  )
  return {
    sessions,
    clientName,
    isClientUnavailable
  }
}, isEqual)

const getDateDetails = date => {
  return new Date(date).toLocaleDateString('en-GB')
}

const hasSAPResponseTimeElapsed = (orderUpdatedAt: number) => {
  return (new Date().getTime() - new Date(orderUpdatedAt).getTime()) / 60000 > 90
}

export const getExchangeRateForClientCurrency = memoize(
  (clientCurrency: string, exchangeRateTable) => {
    // If client currency is same as currency to, return 1 as exchange rate
    if (clientCurrency === exchangeRateTable?.To?.[0]?.Name) {
      return 1
    }

    let exchangeRateRecord = find(
      exchangeRateTable?.To?.[0]?.Value?.Rates ?? [],
      exchangeRecord => exchangeRecord?.Name === clientCurrency
    )
    return exchangeRateRecord?.Value ?? null
  }
)

const CanZamUpdateOrderStatus = (orderStatus, isZAMUserLoggedIn) => {
  return (
    orderStatus ===
      config?.appConfig?.enumerations?.orderStatus?.ORDER_STATUS_VALUES.Validated &&
    isZAMUserLoggedIn
  )
}

const getClientOrderDetails = (
  order: IClientOrder,
  statusEnumValues: Array<any>,
  possibleUpdateValuesForStatus: any,
  possibleUpdateValuesForSendToSAP: Array<string>,
  sentToSAPEnumValue: string,
  isClientExcluded: boolean
): Partial<IOrderManagementTableData> => {
  const statusEnumOptionMap = getEnumOptionMap(statusEnumValues)
  const isZAMUserLoggedIn = isZAM()
  const canSendToSap =
    includes(possibleUpdateValuesForSendToSAP, order?.status) &&
    !order?.sapStatus &&
    !isZAMUserLoggedIn
  const sapStatus = order?.sapStatus
    ? order?.sapStatus === strings.sapSuccess
      ? SAPStatusDescription.RECEIVED
      : SAPStatusDescription.FAILED
    : canSendToSap
    ? isWholesaleCMOrAdmin()
      ? SAPStatusDescription.SEND
      : ''
    : order?.status === sentToSAPEnumValue
    ? hasSAPResponseTimeElapsed(order.LastUpdatedAt)
      ? SAPStatusDescription.NOT_RECEIVED
      : SAPStatusDescription.SENT
    : ''
  const orderStatusValues: any =
    config?.appConfig?.enumerations?.orderStatus?.ORDER_STATUS_VALUES ?? {}

  return {
    id: order?.id,
    buyingSessionId: order?.buyingSession?.id ?? '',
    possibleUpdateOptionsForStatus: getPossibleUpdateOptionsForStatus(
      statusEnumOptionMap,
      order?.status,
      orderStatusValues,
      possibleUpdateValuesForStatus,
      sapStatus
    ),
    /**
     * Disable edit status field
     */
    isEditStatusDisabled:
      CanZamUpdateOrderStatus(order?.status, isZAMUserLoggedIn) || isClientExcluded,
    /**
     * SAP fields
     */
    canSendToSap,
    disableSendToSap: !isWholesaleCMOrAdmin(),
    sapStatus,
    sapStatusColor: SAPStatusDescriptionColorMapping[sapStatus],
    isSapStatusBold: sapStatus === SAPStatusDescription.FAILED,
    hasInactiveProductOrSizeWithQty: order?.hasInactiveProductOrSizeWithQty || false
  }
}

const getPossibleUpdateOptionsForStatus = (
  statusEnumOptionMap,
  orderStatus,
  orderStatusValues,
  possibleUpdateValuesForStatus,
  sapStatus
): Array<IOption> => {
  if (
    sapStatus !== SAPStatusDescription.FAILED &&
    orderStatus === orderStatusValues?.['Sent to SAP']
  ) {
    return []
  } else if (!isEmpty(statusEnumOptionMap)) {
    return compact(
      (possibleUpdateValuesForStatus[orderStatus] || []).map(
        enumValue => statusEnumOptionMap[enumValue]
      )
    )
  } else {
    return []
  }
}

const getDoorOrderDetails = (
  order: IClientOrder,
  statusEnumValues: Array<any>,
  possibleUpdateValuesForStatus: any,
  possibleUpdateValuesForSendToSAP: Array<string>,
  sentToSAPEnumValue: string
): Partial<IOrderManagementTableData> => {
  const isZAMUserLoggedIn = isZAM()
  const statusEnumOptionMap = getEnumOptionMap(statusEnumValues)
  const canSendToSap =
    includes(possibleUpdateValuesForSendToSAP, order?.status) && !order?.sapStatus

  const sapStatus = order?.sapStatus
    ? order?.sapStatus === strings.sapSuccess
      ? SAPStatusDescription.RECEIVED
      : SAPStatusDescription.FAILED
    : canSendToSap
    ? isZAMUserLoggedIn
      ? ''
      : SAPStatusDescription.SEND
    : order?.status === sentToSAPEnumValue
    ? hasSAPResponseTimeElapsed(order.LastUpdatedAt)
      ? SAPStatusDescription.NOT_RECEIVED
      : SAPStatusDescription.SENT
    : ''

  const orderStatusValues: any =
    config?.appConfig?.enumerations?.orderStatus?.ORDER_STATUS_VALUES ?? {}

  return {
    id: order?.doorOrders?.[0].id,
    clientOrderId: order?.id,
    possibleUpdateOptionsForStatus: getPossibleUpdateOptionsForStatus(
      statusEnumOptionMap,
      order?.status,
      orderStatusValues,
      possibleUpdateValuesForStatus,
      sapStatus
    ),
    /**
     * SAP fields
     */
    canSendToSap,
    disableSendToSap: !isWholesaleCMOrAdmin(),
    sapStatus,
    sapStatusColor: SAPStatusDescriptionColorMapping[sapStatus],
    isSapStatusBold: sapStatus === SAPStatusDescription.FAILED,
    isDoorActive: order?.doorOrders?.[0].doorActive ?? false,
    hasInactiveProductOrSizeWithQty:
      order?.doorOrders?.[0].hasInactiveProductOrSizeWithQty || false
  }
}

const getActivitiesAndGender = (buyingSessionGroup, orderActivities) => {
  let activityGender = []
  if (isEmpty(buyingSessionGroup)) return []
  if (!orderActivities?.length) return []
  const gender = buyingSessionGroup?.gender ?? ''
  let activities = buyingSessionGroup?.activities
  activities = activities.filter(activity => {
    return orderActivities.some(id => activity.id === id)
  })
  for (let activity of activities) {
    const label = `${gender} ${activity.name}`
    activityGender.push({
      label,
      value: label
    })
  }
  return activityGender
}

const getDoorData = doorOrders => {
  let doorOrderName = []
  for (let door of doorOrders) {
    doorOrderName.push({
      label: door?.doorName,
      value: door?.doorId
    })
  }
  return doorOrderName
}

const disablePDFExport = orderStatusEnum => {
  const orderConfirmedStatus =
    config?.appConfig?.enumerations?.orderStatus?.ALLOWED_PDF_EXPORT_FOR_VALUES
  return !includes(orderConfirmedStatus, orderStatusEnum)
}

class OrderManagementService {
  processOrderData = memoize(
    ({
      orders,
      statusEnumValues,
      buyingSessionGroup
    }: {
      orders: Array<IClientOrder>
      statusEnumValues: Array<any>
      buyingSessionGroup: any
    }): Array<IOrderManagementTableData> => {
      if (isEmpty(orders)) return []
      const processedOrders = []
      let orderObj = {}
      const restrictDeleteForOrderStatuses =
        config?.appConfig?.enumerations?.orderStatus?.RESTRICTED_DELETE_FOR_VALUES ?? []
      let possibleUpdateValuesForStatus =
        config?.appConfig?.enumerations?.orderStatus.POSSIBLE_UPDATE_OPTIONS_MAP ?? {}
      let possibleUpdateValuesForStatusForClosedBS =
        config?.appConfig?.enumerations?.orderStatus
          ?.POSSIBLE_UPDATE_OPTIONS_MAP_WITH_NEXT_STATUS ?? {}
      let possibleUpdateValuesForSendToSAP =
        config?.appConfig?.enumerations?.orderStatus?.POSSIBLE_VALUES_FOR_SEND_TO_SAP ??
        []
      let sentToSAPEnumValue =
        config?.appConfig?.enumerations?.orderStatus?.ORDER_STATUS_VALUES?.[
          strings.sentToSAP
        ] ?? []

      const buyingSessionStatusValueMap =
        config?.appConfig?.enumerations?.buyingSessionStatus
          ?.BUYING_SESSION_STATUS_VALUES || {}
      const retailBuyingSessionStatusValueMap =
        config?.appConfig?.enumerations?.retailBuyingSessionStatus
          .BUYING_SESSION_STATUS_VALUES || {}

      let hasDeliveryWindowPassed = false
      if (buyingSessionGroup?.defaultDeliveryWindow?.end) {
        const deliveryWindowCancelDate = new Date(
          buyingSessionGroup?.defaultDeliveryWindow?.end
        ).setHours(0, 0, 0, 0)
        const currentDate = new Date().setHours(0, 0, 0, 0)
        hasDeliveryWindowPassed =
          new Date(currentDate).getTime() > new Date(deliveryWindowCancelDate).getTime()
      }
      const globalCurrency = config.globalCurrency
      const isZAMUserLoggedIn = isZAM()
      const orderStatusValues: any =
        config?.appConfig?.enumerations?.orderStatus?.ORDER_STATUS_VALUES ?? {}
      for (let order of orders) {
        const isClientExcluded =
          order?.Client?.id &&
          includes(
            map(
              buyingSessionGroup?.excludedClients || [],
              clientObject => clientObject.Id
            ),
            order?.Client?.id
          )
        const isBuyingSessionGroupClosed =
          buyingSessionGroup?.buyingSessionGroupStatus ===
            buyingSessionStatusValueMap[strings.closed.toUpperCase()] ||
          buyingSessionGroup?.retailBuyingSessionGroupStatus ===
            retailBuyingSessionStatusValueMap[strings.closed.toUpperCase()]
        const clientCurrency = order?.Client?.currency
        const isClientMaster = order?.isClientMaster
        const orderType = order?.orderType?.toLowerCase()

        orderObj = {
          name: order?.Client?.name || 'NA',
          clientId: order?.Client?.id,
          code: order?.Client?.code || 'NA',
          isClientDisabled: isClientExcluded,
          isClientActive: order?.Client?.active ?? false,
          clientHasInactiveDoor: order?.hasInactiveDoor ?? false,
          zam: order?.salesOfficeDescription || 'NA',
          country: order?.Client?.countryCode || 'NA',
          channel: order?.Client?.channel || 'NA',
          deliveryWindow: buyingSessionGroup?.defaultDeliveryWindow,
          activityGender: getActivitiesAndGender(buyingSessionGroup, order?.activityIds),
          orderType: orderType,
          internalClient: order?.internalClient || false,
          isClientMaster,
          doors: getDoorData(order?.doorOrders),
          salesAssistant: order?.salesAssistant,
          shipTo: order?.shipTo || '',
          disablePDFExport: disablePDFExport(order?.status ?? ''),
          date: order?.creationDate ? getDateDetails(order?.creationDate) : 'NA',
          orderNumber: order?.orderId,
          qty: order?.orderQuantity,
          value: convertToCurrencyLocaleString(clientCurrency, order?.orderValue),
          globalValue: globalCurrency
            ? convertToCurrencyLocaleString(globalCurrency, order?.globalValue)
            : 'NA',
          deliveryDate: order.deliveryDate
            ? (getUTCFromTimeStamp(order.deliveryDate, 'DD/MM/YYYY') as string)
            : null,
          isDeliveryDateDisabled:
            (order?.status !== orderStatusValues.Booking &&
              order?.status !== orderStatusValues['In Process']) ||
            isClientExcluded ||
            isBuyingSessionGroupClosed ||
            hasDeliveryWindowPassed,
          status: getEnumDescriptionFromValue(order?.status ?? '', statusEnumValues),
          statusEnumValue: order?.status ?? '',
          isDeleteStatusDisabled: includes(restrictDeleteForOrderStatuses, order?.status),
          isEditStatusDisabled:
            CanZamUpdateOrderStatus(order?.status, isZAMUserLoggedIn) || isClientExcluded,
          sapOrderNumber: order?.sapOrderNumber
        }
        if (isClientMaster) {
          orderObj = {
            ...orderObj,
            ...getClientOrderDetails(
              order,
              statusEnumValues,
              isBuyingSessionGroupClosed
                ? possibleUpdateValuesForStatusForClosedBS
                : possibleUpdateValuesForStatus,
              possibleUpdateValuesForSendToSAP,
              sentToSAPEnumValue,
              isClientExcluded
            )
          }
          processedOrders.push(orderObj)
        } else {
          /**
           * Since following flag will be same for all door orders, evaluate it once
           */
          orderObj = {
            ...orderObj,
            ...getDoorOrderDetails(
              order,
              statusEnumValues,
              isBuyingSessionGroupClosed
                ? possibleUpdateValuesForStatusForClosedBS
                : possibleUpdateValuesForStatus,
              possibleUpdateValuesForSendToSAP,
              sentToSAPEnumValue
            )
          }
          processedOrders.push(orderObj)
        }
      }
      return processedOrders
    },
    isEqual
  )

  processAndSearchOrderData = memoize(
    (
      orderDataWithStatusEnums: OrderDataWithStatusEnums,
      searchKey: string = '',
      columnDefList: Array<TableViewColumnDefinition>
    ) => {
      let processedOrderData = this.processOrderData(orderDataWithStatusEnums as any)

      /**
       * If search key is present filter clients
       */
      if (searchKey) {
        return searchData(
          processedOrderData,
          searchKey,
          columnDefList.map(col => col.node.key)
        )
      }

      return processedOrderData
    }
  )
}

export const orderManagementService = new OrderManagementService()
