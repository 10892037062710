import { PrimaryButton } from '@components/UI-Components/PrimaryButton'
import { TextInput } from '@components/UI-Components/TextInput'
import React from 'react'
import * as css from './ProductCommentItem.css'

const HEIGHT = 80

interface IComment {
  canEdit: boolean
  onChange: any
  commentText: string
  handleSubmit: () => void
  hideButton?: boolean
  isSaveCommentInProgress?: boolean
  commentId?: string
}

export const ProductCommentItem: React.FunctionComponent<IComment> = ({
  canEdit,
  commentText,
  onChange,
  handleSubmit,
  hideButton,
  isSaveCommentInProgress,
  commentId
}) => {
  return (
    <div className={css.container}>
      <TextInput
        id='outlined-textarea'
        label='Comment'
        multiline
        fullWidth
        rows={3}
        rowsMax={3}
        margin='normal'
        value={commentText}
        onChange={onChange(commentId)}
        style={{ height: HEIGHT }}
        disabled={!canEdit}
        onBlur={hideButton && commentText.length ? handleSubmit : null}
      />
      {!hideButton ? (
        <div className={css.button}>
          <PrimaryButton
            onClick={handleSubmit}
            disabled={isSaveCommentInProgress || !commentText.length}
          >
            Save
          </PrimaryButton>
        </div>
      ) : null}
    </div>
  )
}
