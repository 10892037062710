import '@styles/Button/style.css'
import cn from 'classnames'
import React from 'react'

interface IButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: any
  variant: 'primary' | 'secondary' | 'link' | 'icon'
  theme?: 'dark' | 'light' | 'custom' | 'customLight'
  size?: 'normal' | 'none'
  type?: 'button' | 'submit' | 'reset'
}

const Button: React.FC<IButton> = (props): JSX.Element => {
  const {
    children,
    variant = 'primary',
    theme = 'dark',
    style,
    disabled,
    onClick = () => {},
    className,
    type,
    size = 'normal',
    ...rest
  } = props

  return (
    <>
      <button
        className={cn(`button`, {
          [`button-${variant}`]: variant,
          [`button-${variant}-${theme}`]: !!theme,
          [`button-size-${size}`]: !!size && size !== 'none',
          [`button-disabled`]: !!disabled,
          [`${className}`]: !!className
        })}
        disabled={!!disabled}
        style={style}
        onClick={onClick}
        type={type || 'button'}
        {...rest}
      >
        {children}
      </button>
    </>
  )
}

export default Button
