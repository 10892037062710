import { AuthorizationError } from '@components/Utils/AuthorizationError'
import { ClusterSetupContainer } from '@modules/retail/clusterSetup'
import { IPermissions } from '@routes'
import { stores, strings } from '@stores'
import { observer } from 'mobx-react'
import React from 'react'
import { StoreHeaderComponent } from '../component/StoreHeader'
import { ImportStoreData } from './ImportStoreData'
import { StoreOTBWeightSettings } from './StoreOTBWeightSettings'
import { StoreContainer } from './Stores'

@observer
export class StoreClusterSetup extends React.Component<{
  permissions?: IPermissions
  match?: any
}> {
  render() {
    const {
      props: { permissions }
    } = this
    const {
      storeSettings: { selectedTabValue }
    } = stores
    return permissions && permissions.canRead ? (
      <React.Fragment>
        <ImportStoreData />
        <div>
          <StoreHeaderComponent />
        </div>
        {selectedTabValue === strings.storesSettings ? (
          <StoreContainer />
        ) : selectedTabValue === strings.storeClusterSettings ? (
          <ClusterSetupContainer />
        ) : (
          <StoreOTBWeightSettings />
        )}
      </React.Fragment>
    ) : (
      <AuthorizationError />
    )
  }
}
