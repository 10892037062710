import { StyledProps } from '@components/3rd-party/mui'
import { LoadingIndicator } from '@components/UI-Components/LoadingIndicator'
import {
  AllowDragging,
  AllowMerging,
  AllowResizing,
  AllowSorting,
  CellRange,
  Column,
  FlexGrid,
  FormatItemEventArgs,
  HeadersVisibility,
  MergeManager,
  Row,
  SelectionMode
} from '@grapecity/wijmo.grid'
import { FlexGrid as FlexGridComponent } from '@grapecity/wijmo.react.grid'
import { renderReactIntoGridCell } from '@modules/wijmo_helpers'
import classNames from 'classnames'
import { action, autorun, computed, observable } from 'mobx'
import { useLocalStore, useObserver } from 'mobx-react-lite'
import { transparentize } from 'polished'
import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import {
  AssortmentSplitTableStore,
  ClusterHeaderCell,
  SplitTableStoreContext,
  StoreFilterDropdownCell,
  useAssortmentSplitStore
} from './'
import * as classes from './AssortmentSplit_StoreClustersTable.css'

//region Styled Components
const Root = styled.div`
  &[data-loading='true'] {
    // To make loading panel center in scrollable width panel
    [wj-part='cells'] {
      &,
      .wj-row {
        max-width: 100%;
        width: 100%;
      }

      [role='gridcell']:not(.wj-frozen) {
        max-width: calc(100% - 160px);
      }
    }
  }
`

const MyFlexGrid = styled(FlexGridComponent).attrs(() => ({ collapsed: false }))`
  &,
  * {
    border-color: var(--split-border-color-light) !important;
  }

  border-radius: var(--split-border-radius) var(--split-border-radius) 0 0;
  overflow: hidden;
  border-width: 1px 1px 0 1px;
  border-color: black;

  [wj-part='root'] {
    overflow: hidden !important;
  }

  [wj-part='chcells'] {
    .wj-cell {
      background: white;
      font-size: 12px;
      line-height: 21px;
      color: var(--split-row-label-color);
    }

    ${p =>
      p.collapsed &&
      css`
        border-bottom: none;
      `}
  }

  [wj-part='cells'] {
    .wj-cell {
      //border-bottom: none;

      &.wj-frozen {
        color: var(--split-row-label-color);
      }
    }

    [role='row']:last-child {
      [role='gridcell'] {
        border-bottom: none;
      }
    }
  }

  .react-cell-container {
    display: flex;
    height: 100%;
    align-content: center;
    justify-content: center;
  }

  .bp3-icon {
    margin: auto;
    border: none;
    background: transparent;
    padding: 4px;
  }

  .bp3-popover-wrapper,
  .bp3-popover-target {
    display: flex;
    margin: auto;
  }

  [wj-part='ch'] {
    .react-cell-container {
      cursor: pointer;
    }
  }
`
//endregion

class MyMergeManager extends MergeManager {
  constructor(flexGrid, private store: StoreClustersStore) {
    super(flexGrid)
  }

  getMergedRange(panel, r, c, clip = true) {
    const { grid } = panel
    // eslint-disable-next-line eqeqeq
    if (panel == grid.cells) {
      if (c <= 1) {
        return new CellRange(r, 0, r, 1)
      }

      if (this.store.loading) {
        return new CellRange(0, 2, grid.rows.length - 1, grid.columns.length - 1)
      }
    } else {
      return super.getMergedRange(panel, r, c, clip)
    }
  }
}

//region Store
export class StoreClustersStore {
  constructor(private store: AssortmentSplitTableStore) {
    store.clusterStore = this
  }

  @computed get columns(): Array<Column> {
    return this.store.clusterGridColumns
  }

  @computed get grid() {
    return this.store.clusterGrid
  }

  autosizingRow = false

  @action onInitialized = (grid: FlexGrid) => {
    this.store.clusterGrid = grid
    grid.mergeManager = new MyMergeManager(grid, this)
    grid.allowResizing = AllowResizing.None
    grid.allowDragging = AllowDragging.None
    grid.allowSorting = AllowSorting.None

    /**
     * Autosize the store name column after update
     * This will trigger another updatedView callback which is why we guard against reentrency here
     */
    grid.updatedView.addHandler(() => {
      if (!this.autosizingRow) {
        this.autosizingRow = true
        grid.autoSizeRow(1, true)
      } else {
        this.autosizingRow = false
      }
    })
  }

  @observable expanded = true
  @observable showFilter = false
  @action toggleExpandCollapsed = () => {
    const { expanded } = this

    this.expanded = !expanded
  }

  get loading() {
    return this.store.queries.clusters.loading
  }

  onFormatItem = (grid: FlexGrid, e: FormatItemEventArgs) => {
    let { panel, cell, row, col } = e
    const { columns } = grid
    const {
      loading,
      store,
      store: { columnDict, reactNodes }
    } = this

    if (!columns[col]) {
      return
    }

    const renderProps = {
      cell,
      mode: 'dynamic',
      nodeDicts: reactNodes,
      key: `storeClusters_${panel.cellType}_${col},${row}`
    }

    if (panel === grid.columnHeaders) {
      if (row === 0) {
        if (col === 0) {
          //cell.addEventListener('click', toggleExpandCollapsed)
          renderReactIntoGridCell({
            c: <ClusterHeaderCell store={this} />,
            ...renderProps
          })
        } else if (col === 1) {
          renderReactIntoGridCell({
            c: (
              <SplitTableStoreContext.Provider value={store}>
                <StoreFilterDropdownCell />
              </SplitTableStoreContext.Provider>
            ),
            ...renderProps
          })
        } else {
          // Cluster column

          col -= 2
          if (!loading && columnDict[col] && columnDict[col].cluster) {
            const {
              cluster: { id, name, colorHex }
            } = columnDict[col]
            cell.setAttribute('data-id', id)
            cell.style.background = transparentize(0.8, colorHex)
            cell.innerText = name
          }
        }
      } else if (row === 1) {
        if (col > 1) {
          col -= 2
          if (!loading && columnDict[col] && columnDict[col].cluster) {
            const {
              store: { name, id }
            } = columnDict[col]
            cell.setAttribute('data-id', id)
            cell.innerText = name
          }
        }
      }
    } else if (panel === grid.cells) {
      if (col > 1) {
        if (loading) {
          cell.style.borderBottom = 'none'
          renderReactIntoGridCell({ c: <LoadingIndicator />, cell })
        } else {
          col -= 2
          if (columnDict[col] && columnDict[col].store) {
            cell.style.fontSize = '14px'
            const {
              store: { value, quantity, totalProducts, otb }
            } = columnDict[col]

            switch (row) {
              case 0: {
                cell.innerHTML = otb.toLocaleString()
                break
              }

              case 1: {
                cell.innerHTML = value.toLocaleString()
                break
              }

              case 2: {
                cell.innerHTML = quantity.toLocaleString()
                break
              }

              case 3: {
                cell.innerHTML = totalProducts.toLocaleString()
                break
              }
            }
          }
        }
      }

      if (col === 0) {
        cell.style.fontSize = '14px'
      }
    }
  }

  @computed get itemsSource() {
    const { expanded } = this

    return expanded
      ? ['Store OTB', 'Total store value', 'Total store quantity', 'Number of ACs'].map(
          (t, i) => ({ row: i, title: t })
        )
      : []
  }
}
//endregion

//region <AssortmentSplit_StoreClustersTable />
interface MyProps extends StyledProps {}

export const AssortmentSplit_StoreClustersTable: React.FunctionComponent<MyProps> = ({
  className
}) => {
  const splitStore = useAssortmentSplitStore(),
    { clusterStoreTableLeftMargin } = splitStore
  const store = useLocalStore(() => new StoreClustersStore(splitStore))

  useEffect(
    () =>
      autorun(
        () => {
          const { columns } = store,
            { clusterGrid: grid, filteredStores, clusters } = splitStore
          if (grid && columns) {
            grid.columns.beginUpdate()
            grid.columns.clear()
            columns.forEach(c => grid.columns.push(c))

            const { columnHeaders } = grid
            if (columnHeaders.rows.length === 1) {
              columnHeaders.rows[0].wordWrap = true
              columnHeaders.rows[0].height = 50

              const extraRow = new Row()
              extraRow.allowMerging = true
              columnHeaders.rows.splice(0, 0, extraRow)
            }

            let c = 2
            clusters.forEach(({ id, stores, name, colorHex }, i) => {
              let filtered = stores.filter(s => !filteredStores.has(s.id))

              filtered.forEach((s, j) => {
                columnHeaders.setCellData(0, c + j, id + `_${i}`)
                columnHeaders.setCellData(1, c + j, s.id + `_${j}`)
              })

              c += filtered.length
            })

            columnHeaders.setCellData(0, 0, 'caret')
            columnHeaders.setCellData(1, 0, 'caret')

            columnHeaders.setCellData(0, 1, 'gear')
            columnHeaders.setCellData(1, 1, 'gear')

            grid.columns.endUpdate()
            grid.autoSizeColumns(2, null, true)
          }
        },
        { name: `Update wijmo column list on initialization` }
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return useObserver(function useHook() {
    const { onInitialized, onFormatItem, loading, itemsSource } = store
    const { hasVerticalOverflow } = splitStore

    return (
      <Root
        className={classNames(
          className,
          hasVerticalOverflow ? classes['root-scroll'] : classes['root']
        )}
        data-loading={loading}
        style={{ marginLeft: clusterStoreTableLeftMargin }}
      >
        <MyFlexGrid
          collapsed={!store.expanded}
          alternatingRowStep={0}
          autoGenerateColumns={false}
          allowDelete={false}
          frozenColumns={2}
          allowSorting={false}
          selectionMode={SelectionMode.None}
          showSelectedHeaders={HeadersVisibility.Column}
          itemsSource={itemsSource}
          headersVisibility={HeadersVisibility.Column}
          allowMerging={AllowMerging.ColumnHeaders}
          initialized={onInitialized}
          formatItem={onFormatItem}
          // scrollPositionChanged={this.onScrollPositionChanged}
        />
      </Root>
    )
  })
}
//endregion
