export enum OrderManagementDisplayName {
  clientOrder = 'WHOLESALE ORDER',
  clientBasket = 'MARKET BASKET',
  retailOrder = 'RETAIL ORDER'
}

export enum OrderManagementSection {
  clientBasket = 'clientBasket',
  clientOrder = 'clientOrder',
  retailOrder = 'retailOrder'
}

export enum OrderDataKeys {
  Id = 'id',
  OrderNumber = 'orderNumber',
  Status = 'statusWithName',
  // Activities = 'Activities',
  SalesDocType = 'salesDocType',
  DeliveryDate = 'deliveryDate',
  PORef = 'poRef',
  Notes = 'notes',
  IsClientMaster = 'isClientMaster',
  DoorOrders = 'doorOrders',
  Type = 'type',
  Action = 'Action',
  IsActivityEditable = 'IsActivityEditable',
  CanUpdateOrderStatus = 'canUpdateOrderStatus',
  HasInactiveProductOrSizeWithQty = 'hasInactiveProductOrSizeWithQty',
  CreatedDateOfOrder = 'creationDate',
  CreatedBy = 'createdBy',
  Name = 'name',
  buyingSessionId = 'buyingSessionId'
}

export enum OrderDataHeadersText {
  OrderNumber = 'Order #',
  Status = 'Order Status',
  Activities = 'Departments',
  SalesDocType = 'Sales Doc Type',
  DeliveryDate = 'Delivery Date',
  PORef = 'Market PO Ref',
  Notes = 'Notes',
  Action = 'Action',
  Export = 'Export',
  CreatedDateOfOrder = 'Created Dt.',
  CreatedBy = 'Created By',
  Name = 'Order Name'
}

export enum ExportTye {
  ImportExcel = 'IMPORT EXCEL',
  ExportSelectedOrdersToExcel = 'EXPORT SELECTED ORDERS',
  ExportWithImagesToExcel = 'WITH IMAGES',
  ExportWithOutImagesToExcel = 'WITHOUT IMAGES'
}

export enum OrderType {
  Client = 'client',
  Door = 'door'
}

export interface IOrderData {
  id: string
  isClientMaster: boolean
  isClientOrderDraft?: boolean
  clientOrderStatus?: string
  doorOrders?: Array<{
    id: string
    status: string
  }>
}
