import { CentricTheme } from '@components/3rd-party/mui'
import { ToolBar } from '@components/UI-Components/ToolBar'
import { User } from '@models'
import { UserRoles } from '@modules/common/models/enums/UserRoles'
import Icon from '@material-ui/core/Icon'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { storage } from '@services/storageService'
import { strings } from '@stores'
import React from 'react'
import { Link } from 'react-router-dom'
import { BUYING_SESSION_SETTING_OPTIONS } from '../constants'

const styles = (theme: CentricTheme) => ({
  icon: {
    margin: theme.spacing(1),
    color: theme.colors.grey,
    cursor: 'pointer',
    '&:hover': {
      color: theme.colors.lightgrey
    }
  },
  menuItem: {
    whiteSpace: 'normal',
    wordWrap: 'break-word'
  },
  report: {
    padding: '5px 10px',
    marginRight: 16
  },
  collection: {
    padding: '5px 10px'
  },
  toolBar: {
    marginRight: 16,
    display: 'flex'
  },
  typography: {
    margin: theme.spacing(1)
  },
  popOver: {
    width: 250,
    fontSize: 14
  },
  popOverMenu: {
    fontSize: '14px'
  }
})

// const ITEM_HEIGHT = 48

const BuyingSessionToolBarComponent = ({
  podium,
  link,
  classes,
  onClick,
  reportLink,
  settingsLink,
  isClosed,
  handlePopOverClose,
  handelPopOverClick,
  popOveranchorEl,
  selectedPopOverIndex,
  podiumId,
  location
}) => {
  const statusStyle = isClosed ? 'gray' : 'green'
  const user = storage.getItem<User>('user')
  const role = user.roles[0].name

  return (
    <>
      <ToolBar
        leftmenu={
          <div className={classes.toolBar}>
            <Icon style={{ color: statusStyle }}>fiber_manual_record</Icon>
            <Typography variant='subtitle1' color='inherit'>
              {podium}
            </Typography>
          </div>
        }
        rightmenu={
          <>
            <div className={classes.report}>
              <Link
                to={{
                  pathname: reportLink,
                  search: location.search,
                  state: {
                    from: location.pathname
                  }
                }}
              >
                <Typography variant='button' onClick={onClick}>
                  {strings.report}
                </Typography>
              </Link>
            </div>
            <div className={classes.collection}>
              <Link to={{ pathname: link, search: location.search }}>
                <Typography variant='button' onClick={onClick}>
                  {strings.buyingOverview}
                </Typography>
              </Link>
            </div>
            {role !== UserRoles.REGIONAL_MERCHANDISER ? (
              <div className={classes.collection}>
                <Icon onClick={handelPopOverClick(podiumId)} className={classes.icon}>
                  more_horiz
                </Icon>
              </div>
            ) : null}
            <Menu
              id='status-menu'
              anchorEl={popOveranchorEl}
              open={Boolean(popOveranchorEl && selectedPopOverIndex === podiumId)}
              onClose={handlePopOverClose}
              classes={{
                paper: classes.popOver
              }}
            >
              {BUYING_SESSION_SETTING_OPTIONS.map(({ name }) => (
                <MenuItem
                  key={name}
                  classes={{
                    root: classes.popOverMenu
                  }}
                  onClick={e => {
                    e.stopPropagation()
                    onClick()
                    handlePopOverClose()
                  }}
                >
                  <Link to={{ pathname: settingsLink, search: location.search }}>
                    <Typography className={classes.menuItem} color='textSecondary'>
                      {name}
                    </Typography>
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </>
        }
      />
    </>
  )
}

export const BuyingSessionToolBar = withStyles(styles as any)(
  React.memo(BuyingSessionToolBarComponent)
)
