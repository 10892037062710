import React from 'react'
import styled from 'styled-components'

interface IListViewRowSelectorProps {
  text: string
  onClick?: Function
  isVisible?: boolean
  isDisable?: boolean
}

const SelectorContainer = styled.div<{ isDisable?: boolean }>((props: any) => ({
  '&&': {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: props.isDisable && 0.5,
    pointerEvents: props.isDisable ? 'none' : 'auto'
  }
}))

const SelectorText = styled.div<any>(props => ({
  '&&': {
    backgroundColor: 'var(--Whitesmoke)',
    writingMode: 'vertical-rl',
    textOrientation: 'mixed',
    width: '100%',
    height: '90%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  }
}))

export const ListViewRowSelector: React.FunctionComponent<IListViewRowSelectorProps> = ({
  text,
  onClick,
  isVisible,
  isDisable
}) => {
  return isVisible ? (
    <SelectorContainer isDisable={isDisable}>
      <SelectorText onClick={onClick}> {text}</SelectorText>
    </SelectorContainer>
  ) : null
}
