import { withIconHOC } from '@components/std-components/Icons'
import { StandardAttributeList } from '@components/UI-Components/StandardAttributeList'
import { StandardChipList } from '@components/UI-Components/StandardChipList'
import { StandardListViewLabel } from '@components/UI-Components/StandardListViewLabel'
import { StandardListViewProductInfo } from '@components/UI-Components/StandardListViewProductInfo'
import { ListViewColumnDefinition } from '@components/UI-Components/StandardTableView/models/ListviewColumnDefinition'
import { NewFlagIcon, TrashIcon } from '@components/UI-Components/svg'
import {
  ChipListEnum,
  ListViewFieldMapping,
  ListViewProduct,
  ViewTabs
} from '@modules/common/models'
import { WSZoneWiseComments } from '@modules/wholesale/buyingSessionGroupProductWrapper/component/WSZoneWiseComments'
import { stores } from '@stores'
import get from 'lodash/get'
import upperFirst from 'lodash/upperFirst'
import React from 'react'
import * as css from '@modules/wholesale/buyingSessionGroupProductWrapper/containers/CollectionProductRowWrapper.css'

/**
 * Class with methods for returning components required to render product cell
 */
class StandardListViewComponentService {
  getProductInfoComponent = (
    product: ListViewProduct,
    columnDefinition: ListViewColumnDefinition
  ) => {
    const {
      showroomCollection: { onProductDetailClick },
      nav: {
        queryParams: { tab }
      }
    } = stores
    const canViewCounterOfferFlag =
      tab === ViewTabs.RTL_BUY.toLowerCase() ||
      tab === ViewTabs.RTL_DISTRIBUTION.toLowerCase() ||
      tab === ViewTabs.FORECAST.toLowerCase() ||
      tab === ViewTabs.COUNTER_OFFER.toLowerCase()
    return (
      <StandardListViewProductInfo
        image={product[columnDefinition.product.key].image}
        fitStyle={product[columnDefinition.product.key].image ? null : 'cover'}
        status={product[columnDefinition.product.key].status}
        isActive={product?.isActive}
        isCore={product?.isCore}
        topRightIcon={product.onDelete ? TrashIcon : null}
        onTopRightIconClick={product.onDelete}
        onClick={onProductDetailClick(product)}
        hasCounterOffer={canViewCounterOfferFlag && product?.hasCounterOffer}
      />
    )
  }

  getProductAttributeComponent = (
    product: ListViewProduct,
    columnDefinition: ListViewColumnDefinition
  ) => {
    return (
      <StandardAttributeList productAttributes={product[columnDefinition.product.key]} />
    )
  }

  getChipListComponent = (
    product: ListViewProduct,
    columnDefinition: ListViewColumnDefinition
  ) => {
    const {
      wsBuyingSessionProduct: { handleTagDelete, handleTagClick }
    } = stores
    return (
      <StandardChipList
        tags={get(product, columnDefinition.product.key)}
        type={columnDefinition.product.key}
        product={product}
        chipsize={ChipListEnum.small}
        handleChipDelete={
          product[`canDelete${upperFirst(columnDefinition.product.key)}`]
            ? handleTagDelete
            : null
        }
        handleClick={
          product[`canEdit${upperFirst(columnDefinition.product.key)}`]
            ? (productId, tagId, e) =>
                handleTagClick(productId, tagId, columnDefinition.product.key, e)
            : null
        }
      />
    )
  }

  getLabelComponent = (
    product: ListViewProduct,
    columnDefinition: ListViewColumnDefinition
  ) => {
    return (
      <StandardListViewLabel
        value={product[columnDefinition.product.key]}
        {...columnDefinition.props}
      />
    )
  }

  getCommentsComponent = (
    product: ListViewProduct,
    columnDefinition: ListViewColumnDefinition
  ) => {
    return (
      <WSZoneWiseComments
        zoneWiseData={product[columnDefinition.product.key]}
        commentType={columnDefinition.product.path}
      />
    )
  }

  getFlagComponent = (
    product: ListViewProduct,
    columnDefinition: ListViewColumnDefinition
  ) => {
    const FlaggedIcon = withIconHOC({
      imageSrc: NewFlagIcon,
      className: css['flagIcon']
    })
    return get(product, columnDefinition.product.key) ? <FlaggedIcon /> : null
  }

  getChipListAndFlagComponent = (
    product: ListViewProduct,
    columnDefinition: ListViewColumnDefinition
  ) => {
    return (
      <div
        style={{
          display: 'flex'
        }}
      >
        {this.getFlagComponent(product, {
          ...columnDefinition,
          product: {
            ...columnDefinition.product,
            key: `${columnDefinition.product.key}.flagProductAtClient` as ListViewFieldMapping
          }
        })}
        {this.getChipListComponent(product, {
          ...columnDefinition,
          product: {
            ...columnDefinition.product,
            key: `${columnDefinition.product.key}.clusters` as ListViewFieldMapping
          }
        })}
      </div>
    )
  }
}

export const standardListViewComponentService = new StandardListViewComponentService()
