import { Tree } from 'primereact/tree'
import { StyledProps } from '@components/3rd-party/mui'
import { FlayOutDirection, NavBar } from '@components/UI-Components/NavBar'
import { NavigationIcon } from '@components/UI-Components/svg'
import Checkbox from '@material-ui/core/Checkbox'
import Tooltip from '@material-ui/core/Tooltip'
import 'primereact/resources/themes/nova-light/theme.css'
import 'primereact/resources/primereact.min.css'
import { mouseEventPropagationStopper } from '@services/commonServices'
import classNames from 'classnames'
import { useObserver } from 'mobx-react-lite'
import React from 'react'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'
import { StringConstants } from '../Store/string'
import { StandardTreeViewStore } from '../Store/TreeViewStore'

export interface TreeNavItem {
  key: string
  label: string
  depth: number
  children: Array<TreeNavItem>
}

export interface RootNodeStatus {
  id: string
  visible: boolean
}

export interface TreeNavProps extends StyledProps {
  type?: string
  data: Array<TreeNavItem>
  viewName?: string
  rootNodeStatus?: Array<RootNodeStatus>
  toggleRootNode?: Function
  top?: number
  withoutHeader?: boolean
  anchorForNav?: any
  onBackDrop?: any
}

const TreeNode = styled.div`
  width: 100%;
  height: 14px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.11px;
  color: #000000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`

const TreeNodeComp: React.FunctionComponent<{
  node: TreeNavItem
  rootNodeStatus: Array<RootNodeStatus>
  toggleRootNode: Function
  onBackDrop?: any
}> = ({ node, rootNodeStatus, toggleRootNode, onBackDrop }) => {
  return useObserver(function useHook() {
    const store = StandardTreeViewStore

    const onNavSelectionChange = key => {
      store.onNavSelectionChange(key)
      onBackDrop &&
        setTimeout(() => {
          onBackDrop()
        }, 1)
    }

    const toggleRootParentItem = () => {
      toggleRootNode(node.key)
    }

    return (
      <>
        {!store.disableRootToggle && rootNodeStatus && node.depth === 1 ? (
          <Checkbox
            color='default'
            className={'custom-node-checkbox'}
            onChange={toggleRootParentItem}
            checked={!!rootNodeStatus.find(item => item.visible && item.id === node.key)}
          ></Checkbox>
        ) : (
          ''
        )}
        <Tooltip
          title={node.label}
          placement='bottom'
          className={'custom-node-tooltip'}
          disableHoverListener={isMobile ? true : false}
          disableTouchListener={isMobile ? false : true}
        >
          <TreeNode
            key={node.key}
            onClick={() => onNavSelectionChange(node.key)}
            onMouseDown={mouseEventPropagationStopper}
            className={classNames({
              'custom-node': true,
              'node-highlight':
                store.selectedNavItems && store.selectedNavItems.indexOf(node.key) !== -1
            })}
          >
            {node.label}
          </TreeNode>
        </Tooltip>
      </>
    )
  })
}

const ViewNamePlaceHolder = styled.div`
  height: 12px;
  width: 80%;
  animation: pulse 1s infinite ease-in-out;

  @keyframes pulse {
    0% {
      background-color: rgba(165, 165, 165, 0.1);
    }
    50% {
      background-color: rgba(165, 165, 165, 0.3);
    }
    100% {
      background-color: rgba(165, 165, 165, 0.1);
    }
  }
`

const PrimeTree: React.FunctionComponent<TreeNavProps> = ({
  data,
  className,
  viewName,
  rootNodeStatus,
  toggleRootNode,
  withoutHeader,
  onBackDrop
}) => {
  const store = StandardTreeViewStore
  const nodeTemplate = (node: TreeNavItem) => {
    return (
      <TreeNodeComp
        onBackDrop={onBackDrop}
        node={{ ...node }}
        rootNodeStatus={rootNodeStatus}
        toggleRootNode={toggleRootNode}
      ></TreeNodeComp>
    )
  }

  /**
   * onToggle needs to specify explicitly
   * in order to reflect correct expandedKeys.
   */
  const onToggle = () => null

  return (
    <div className={className}>
      {withoutHeader ? null : (
        <div className={'header'}>
          {viewName ? viewName : <ViewNamePlaceHolder></ViewNamePlaceHolder>}
        </div>
      )}
      <Tree
        value={data}
        nodeTemplate={nodeTemplate}
        expandedKeys={store.expandedKeys}
        onToggle={onToggle}
      ></Tree>
    </div>
  )
}

export const StyledPrimeTree = styled(PrimeTree)`
  .header {
    margin: 10px 0 10px 20px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
  }

  && .p-tree {
    padding: 0;
    width: 100%;
    border: none;
    .p-treenode-children {
      padding-left: 0;
      .p-treenode-leaf {
        padding-left: 20px;
      }
    }
  }

  && ul.p-tree-container {
    padding: 0;
    & > .p-treenode {
      padding-left: 20px;
      border-top: 1px solid #e8e8e8;
    }
    li.p-treenode {
      span.p-tree-toggler {
        display: none;
      }
      .p-treenode-content {
        div.custom-node {
          display: inline-block;
          width: 100px;
          &.node-highlight {
            font-weight: bold;
          }
        }

        .custom-node-tooltip {
          -webkit-touch-callout: none;
          -webkit-user-select: none;
        }

        .custom-node-checkbox {
          position: relative;
          left: -3px;
          top: -2px;
          svg {
            font-size: 18px;
          }
          padding: 0px 0px 0px 0px;
        }

        .p-treenode-label {
          padding: 0px;
        }

        &:focus {
          box-shadow: none;
        }
      }
    }
  }
`

const StyledToggleIcon = styled(({ className }) => {
  return (
    <Tooltip title={StringConstants.rightNavigation} placement='left'>
      <div className={className}>
        <img src={NavigationIcon} alt='' />
      </div>
    </Tooltip>
  )
})`
  cursor: pointer;
  width: 24px;
  height: 24px;
`

export const TreeNav: React.FunctionComponent<TreeNavProps> = props => {
  return useObserver(function useHook() {
    const store = StandardTreeViewStore

    const getToggleIcons = () => {
      return <StyledToggleIcon></StyledToggleIcon>
    }

    // First time after load, open the nav
    // and close after 3 sec.
    React.useEffect(() => {
      store.setRightHandNavStatus(true)
      setTimeout(() => {
        store.setRightHandNavStatus(false)
      }, 3000)
    }, [store])

    const rightHandNavToggle = () => {
      store.setRightHandNavStatus(!store.isRightHandNavOpen)
    }

    return (
      <NavBar
        open={store.isRightHandNavOpen}
        toggleDrawer={rightHandNavToggle}
        width={170}
        side={FlayOutDirection.right}
        zIndex={100}
        clearanceWidth={15}
        top={props.top}
        navToggleIcons={getToggleIcons}
        backgroundColor='#ffff'
        onMouseDownHandler={mouseEventPropagationStopper}
      >
        <StyledPrimeTree {...props} />
      </NavBar>
    )
  })
}
