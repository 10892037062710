import { ApolloClient } from '@apollo/client/core'
import { QueryResult } from '@apollo/client/react'
import { Query } from '@apollo/client/react/components/Query'
import { withApollo } from '@apollo/client/react/hoc'
import { CentricTheme, StyledProps } from '@components/3rd-party/mui'
import { FilterIcon } from '@components/std-components/resources'
import { User } from '@models'
import { SearchComponent } from '@modules/collection/components/SearchComponent'
import { UserRoles } from '@modules/common/models'
import { ErrorContainer } from '@modules/errors/containers/Error'
import { constants } from '@modules/retail/buyingSession/constants'
import { SortBySelector } from '@modules/retail/collection/component/SortBySelector'
import { ViewAttributeSelector } from '@modules/retail/collection/component/ViewAttributeSelector'
import { GET_PRODUCT_ATTRIBUTES } from '@modules/retail/collection/graphql/getProductAttributes'
import { GET_RM_CLUSTERS } from '@modules/retail/navigations/graphql'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'
import { IPermissions } from '@routes'
import { storage } from '@services/storageService'
import { stores, strings } from '@stores'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import {
  AddIcon,
  ApprovalGreenIcon,
  ApprovalIcon,
  DownloadIcon,
  ReportIcon,
  ShareIcon
} from '../svg'
import { ToolBarComponent as ToolBar } from '../ToolBar'
import { ViewSelector } from '../ViewSelector'
import { OPEN_ASSORTMENT_REVIEW_REQUEST } from './graphql'
import some from 'lodash/some'

const styles = (theme: CentricTheme) => ({
  toolBarMenu: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    color: theme.colors.grey,
    cursor: 'pointer',
    marginRight: 27,
    width: '24px',
    height: '24px'
  },
  iconWithBorder: {
    margin: '0px 17px 0px 17px'
  },
  divider: {
    width: '1px',
    height: '24px',
    border: 'solid 1px rgba(0, 0, 0, 0.1);'
  }
})

interface AssormentToolbar extends StyledProps {
  hideRightMenu?: boolean
  toogleDrawer?: any
  onViewClick?: any
  onExcelExportClick?: any
  permissions?: IPermissions
  buyingSessionStatus?: any
  location?: any
  showApprovalPopup?: any
  match?: any
  enableReviewAssortmentLink?: boolean
  assortmentProductsPresent?: boolean
  reviewOrRequestSent?: boolean
  client?: ApolloClient<{
    season: { description: string }
    zoneAssortment: { zone: { name: string } }
  }>
}

@((withStyles as any)(styles))
@(withRouter as any)
@(withApollo as any)
@observer
export class AssortmentToolbar extends React.Component<AssormentToolbar> {
  render() {
    const [
      {
        props: {
          classes,
          onViewClick,
          onExcelExportClick,
          toogleDrawer,
          showApprovalPopup,
          permissions,
          buyingSessionStatus,
          hideRightMenu,
          location: { pathname },
          match,
          match: {
            params: { assortmentId, clusterId }
          },
          assortmentProductsPresent,
          reviewOrRequestSent
        }
      },
      {
        assortment: { getAssortmentType },
        filter: { toggleCollectionFilter },
        lookbook: { openFilter },
        product: { clusters },
        nav: {
          params: { season, podium, gender, assortment, buyingSession, view }
        },
        site: { isGlobalMerchandiserOrAdmin } = {} as any
      }
    ] = [this, stores]
    const assortmentType = getAssortmentType(match)
    const user = storage.getItem<User>('user')
    const isRegionalMerchandiser = some(user.roles, {
      name: UserRoles.REGIONAL_MERCHANDISER
    })
    const isGMOrAdmin = isGlobalMerchandiserOrAdmin()
    const canAddProducts =
      permissions &&
      (permissions.canEdit || isRegionalMerchandiser) &&
      buyingSessionStatus &&
      buyingSessionStatus !== constants.CLOSED
    const isBuyingSessionFrozen = buyingSessionStatus === constants.FROZEN

    return (
      <div>
        <ToolBar
          leftmenu={
            <>
              <div className={classes.toolBarMenu}>
                <SearchComponent />
                <div className={classes.divider}></div>
                <Tooltip title={strings.toolTipManageFilters}>
                  <div className={classNames([classes.icon, classes.iconWithBorder])}>
                    <img
                      src={FilterIcon}
                      alt={strings.toolTipManageFilters}
                      onClick={() =>
                        pathname.includes('lookbooks')
                          ? openFilter()
                          : toggleCollectionFilter(true)
                      }
                    />
                  </div>
                </Tooltip>
              </div>
            </>
          }
          rightmenu={
            !hideRightMenu ? (
              <>
                <div className={classes.toolBarMenu}>
                  <div className={classes.divider}></div>
                  <SortBySelector />
                  {view === strings.grid ? (
                    <Query query={GET_PRODUCT_ATTRIBUTES}>
                      {({ error, loading, data }: QueryResult) => {
                        if (loading) return null
                        if (error) return <ErrorContainer error={error} />
                        return (
                          <>
                            <div className={classes.divider}></div>
                            <ViewAttributeSelector
                              attributes={data.productAttributesDefinitions}
                            />
                          </>
                        )
                      }}
                    </Query>
                  ) : null}
                  {buyingSession &&
                  (assortmentType === strings.zone ||
                    assortmentType === strings.store) ? (
                    <Query
                      query={OPEN_ASSORTMENT_REVIEW_REQUEST}
                      variables={{ assortmentId }}
                      fetchPolicy={'cache-and-network'}
                      skip={!isGMOrAdmin}
                    >
                      {({
                        data: openAssortmentReview,
                        error,
                        loading: loadingOpenAssortment
                      }: QueryResult) => {
                        if (error) return null
                        return isGMOrAdmin ? (
                          <>
                            <div className={classes.divider}></div>
                            <Tooltip title={strings.toolTipRequestApproval}>
                              <div
                                className={classNames([
                                  classes.icon,
                                  classes.iconWithBorder
                                ])}
                              >
                                <img
                                  src={
                                    assortmentProductsPresent &&
                                    buyingSessionStatus !== constants.CLOSED &&
                                    !reviewOrRequestSent &&
                                    openAssortmentReview &&
                                    openAssortmentReview.openAssortmentReviewRequest
                                      ? ApprovalGreenIcon
                                      : ApprovalIcon
                                  }
                                  alt={strings.toolTipRequestApproval}
                                  onClick={
                                    assortmentProductsPresent &&
                                    !reviewOrRequestSent &&
                                    openAssortmentReview &&
                                    openAssortmentReview.openAssortmentReviewRequest &&
                                    buyingSessionStatus !== constants.CLOSED
                                      ? event => showApprovalPopup(event)
                                      : () => {}
                                  }
                                />
                              </div>
                            </Tooltip>
                          </>
                        ) : (
                          <>
                            <div className={classes.divider}></div>
                            <Tooltip title={strings.toolTipRequestApproval}>
                              <div
                                className={classNames([
                                  classes.icon,
                                  classes.iconWithBorder
                                ])}
                              >
                                <img
                                  src={ShareIcon}
                                  alt={strings.toolTipRequestApproval}
                                  onClick={
                                    assortmentProductsPresent &&
                                    buyingSessionStatus !== constants.CLOSED &&
                                    buyingSessionStatus !== constants.FROZEN
                                      ? event => showApprovalPopup(event)
                                      : () => {}
                                  }
                                />
                              </div>
                            </Tooltip>
                          </>
                        )
                      }}
                    </Query>
                  ) : null}
                  <div className={classes.divider}></div>
                  <ViewSelector
                    pathname={pathname}
                    onViewClick={onViewClick}
                  ></ViewSelector>
                  {isRegionalMerchandiser ? (
                    canAddProducts &&
                    !isBuyingSessionFrozen &&
                    (assortmentType === strings.zone ||
                      assortmentType === strings.store) &&
                    !clusterId ? (
                      assortmentType === strings.zone ? (
                        <Query
                          query={GET_RM_CLUSTERS}
                          variables={{ zoneAssortmentId: assortmentId }}
                          fetchPolicy={'cache-and-network'}
                        >
                          {({ data, error, loading }: QueryResult) => {
                            return !loading &&
                              data &&
                              data.clusters &&
                              data.clusters.length ? (
                              <>
                                <div className={classes.divider}></div>
                                <Tooltip title={strings.toolTipAddProduct}>
                                  <div
                                    className={classNames([
                                      classes.icon,
                                      classes.iconWithBorder
                                    ])}
                                  >
                                    <img
                                      src={AddIcon}
                                      alt={strings.toolTipAddProduct}
                                      onClick={toogleDrawer}
                                    />
                                  </div>
                                </Tooltip>
                              </>
                            ) : null
                          }}
                        </Query>
                      ) : (
                        <>
                          <div className={classes.divider}></div>
                          <Tooltip title={strings.toolTipAddProduct}>
                            <div
                              className={classNames([
                                classes.icon,
                                classes.iconWithBorder
                              ])}
                            >
                              <img
                                src={AddIcon}
                                alt={strings.toolTipAddProduct}
                                onClick={toogleDrawer}
                              />
                            </div>
                          </Tooltip>
                        </>
                      )
                    ) : assortmentType === strings.zone &&
                      some(clusters, { id: clusterId }) ? (
                      <>
                        <div className={classes.divider}></div>
                        <Tooltip title={strings.toolTipAddProduct}>
                          <div
                            className={classNames([classes.icon, classes.iconWithBorder])}
                          >
                            <img
                              src={AddIcon}
                              alt={strings.toolTipAddProduct}
                              onClick={toogleDrawer}
                            />
                          </div>
                        </Tooltip>
                      </>
                    ) : null
                  ) : canAddProducts ? (
                    <>
                      <div className={classes.divider}></div>
                      <Tooltip title={strings.toolTipAddProduct}>
                        <div
                          className={classNames([classes.icon, classes.iconWithBorder])}
                        >
                          <img
                            src={AddIcon}
                            alt={strings.toolTipAddProduct}
                            onClick={() => toogleDrawer(true)}
                          />
                        </div>
                      </Tooltip>
                    </>
                  ) : null}
                  {(assortmentType === strings.zone && !clusterId) ||
                  (!assortment && buyingSession && isGlobalMerchandiserOrAdmin()) ? (
                    <>
                      <div className={classes.divider}></div>
                      <Tooltip title={strings.toolTipExportExcel}>
                        <div
                          className={classNames([classes.icon, classes.iconWithBorder])}
                        >
                          <img
                            src={DownloadIcon}
                            alt={strings.toolTipAddProduct}
                            onClick={onExcelExportClick}
                          />
                        </div>
                      </Tooltip>
                    </>
                  ) : null}
                  <div className={classes.divider}></div>
                  <Tooltip title={strings.viewReprt}>
                    <div className={classNames([classes.icon, classes.iconWithBorder])}>
                      <Link
                        to={{
                          pathname: `/collections/season/${season}/gender/${gender}/podium/${podium}/reports`,
                          state: { from: pathname }
                        }}
                      >
                        <img src={ReportIcon} alt={strings.rightNavigation} />
                      </Link>
                    </div>
                  </Tooltip>
                </div>
              </>
            ) : null
          }
        />
      </div>
    )
  }
}
