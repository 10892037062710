import { StyledProps } from '@components/3rd-party/mui'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'

const styles = theme => ({
  container: {
    padding: `0 0 10px 0`
  }
})

interface IProductDescriptionItem extends StyledProps {
  children?: any
}

@((withStyles as any)(styles))
export class ProductDescriptionItem extends React.Component<IProductDescriptionItem> {
  render() {
    const {
      props: { children, classes }
    } = this
    return <div className={classes.container}>{children}</div>
  }
}
