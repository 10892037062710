import { QueryResult } from '@apollo/client/react'
import { Query } from '@apollo/client/react/components/Query'
import { CircularLoading } from '@components/UI-Components/CircularLoading'
import { AuthorizationError } from '@components/Utils/AuthorizationError'
import { User } from '@models'
import { CollectionFilterContainer } from '@modules/retail/collection/container/CollectionFilter'
import { IPermissions } from '@routes'
import { storage } from '@services/storageService'
import { stores } from '@stores'
import isEmpty from 'lodash/isEmpty'
import { reaction } from 'mobx'
import { disposeOnUnmount, observer } from 'mobx-react'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { AssortmentComponent } from '../component/Assortment'
import { CLUSTER_ASSORTMENT } from '../graphql/clusterAssortment'
import { CLUSTER_ASSORTMENT_PRODUCTS_HIERARCHY } from '../graphql/clusterAssortmentProductsHierarchy'
import { GET_ASSORTMENT_PRODUCT_FILTERS } from '../graphql/getAssortmentFilter'

interface IAssortment {
  match?: any
  assortment?: any
  permissions?: IPermissions
  history?: any
}

@(withRouter as any)
@observer
export class ClusterAssortmentContainer extends React.Component<IAssortment> {
  refetch = null

  @disposeOnUnmount _watchSearchInputEnterKeyPress = reaction(
    () => stores.search.lastUpdatedOn,
    lastUpdatedOn => {
      if (this.refetch) {
        this.refetch()
      }
    }
  )

  setRefetch = refetch => {
    this.refetch = refetch
  }

  onListViewClick = () => {
    const {
      history,
      match: {
        params: {
          seasonId,
          assortmentId,
          genderId,
          podiumId,
          activityId,
          buyingSessionId
        }
      }
    } = this.props
    history.push({
      pathname: `/collections/season/${seasonId}/gender/${genderId}/buyingSession/${buyingSessionId}/cluster/assortments/${assortmentId}/podium/${podiumId}/activity/${activityId}/views/list`,
      search: history.location.search
    })
  }

  componentWillUnmount = () => {
    const {
      product: { resetSort, clearRefetchQueries }
    } = stores
    clearRefetchQueries()
    resetSort()
  }

  componentDidUpdate = prevProps => {
    const {
      match: {
        params: { assortmentId: oldId }
      }
    } = prevProps

    const [
      {
        match: {
          params: { assortmentId }
        }
      },
      {
        product: { clearRefetchQueries }
      }
    ] = [this.props, stores]

    if (oldId !== assortmentId) {
      clearRefetchQueries()
    }
  }

  render() {
    const user = storage.getItem<User>('user')
    const [
      {
        props: {
          match: {
            params: { assortmentId, buyingSessionId }
          },
          permissions
        },
        setRefetch
      },
      {
        assortment: { drawerOpen },
        product: { addToAssortmentRefetchQueries },
        filter: { productWhereInput, toggleCollectionFilter, finalFilterToApply },
        search: { prepareProductSearchWhereInput, searchKey }
      }
    ] = [this, stores]

    const whereInput = prepareProductSearchWhereInput(
      productWhereInput,
      finalFilterToApply,
      user,
      searchKey
    )
    return (
      <React.Fragment>
        {permissions && permissions.canRead ? (
          assortmentId ? (
            <React.Fragment>
              <div>
                {toggleCollectionFilter && !drawerOpen ? (
                  <CollectionFilterContainer
                    permissions={permissions}
                    filterQuery={GET_ASSORTMENT_PRODUCT_FILTERS}
                    variables={{ assortmentId }}
                  />
                ) : null}
              </div>
              <Query
                query={CLUSTER_ASSORTMENT}
                fetchPolicy={'cache-and-network'}
                variables={{
                  assortmentId
                }}
              >
                {({
                  data: assortmentData = { assortment: undefined },
                  error,
                  loading
                }: QueryResult) => {
                  return (
                    <Query
                      query={CLUSTER_ASSORTMENT_PRODUCTS_HIERARCHY}
                      variables={{
                        assortmentId,
                        productWhereInput: !drawerOpen ? whereInput : {}
                      }}
                      fetchPolicy={'cache-and-network'}
                      context={{
                        noBatch: true
                      }}
                    >
                      {({ data: activityHierarchy, refetch, variables }) => {
                        addToAssortmentRefetchQueries(
                          refetch,
                          CLUSTER_ASSORTMENT_PRODUCTS_HIERARCHY,
                          variables
                        )
                        setRefetch(refetch)
                        if (loading && isEmpty(assortmentData)) return <CircularLoading />
                        if (error) return null

                        let assortment = null
                        if (assortmentData && assortmentData.assortment) {
                          assortment = assortmentData.assortment
                        }

                        return (
                          <AssortmentComponent
                            // need to change this
                            assortment={assortment}
                            title={assortment ? assortment.cluster : null}
                            assortmentId={assortmentId}
                            buyingSessionId={buyingSessionId}
                            permissions={permissions}
                            buyingSession={assortment && assortment.buyingSession}
                            activityHierarchy={
                              activityHierarchy
                                ? activityHierarchy.assortmentProductsHierarchy
                                : null
                            }
                          />
                        )
                      }}
                    </Query>
                  )
                }}
              </Query>
            </React.Fragment>
          ) : (
            'Loading...'
          )
        ) : (
          <AuthorizationError />
        )}
      </React.Fragment>
    )
  }
}
