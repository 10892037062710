import {
  Root,
  StyledAWSTextFieldComponent
} from '@components/UI-Components/AverageWeeklySale/AWSTextFieldList'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { stores, strings } from '@stores'
import classNames from 'classnames'
import { action, observable } from 'mobx'
import { observer } from 'mobx-react'
import React from 'react'
import * as css from './WSZoneWiseComments.css'
import get from 'lodash/get'
import { UserRoles } from '@modules/common/models/enums/UserRoles'
import { hasUserRoles } from '@services/userRoleService'

interface WSZoneWiseCommentsProps {
  zoneWiseData: any
  commentType: string
}

const allowedUserRoles = {
  RM: [],
  CM: [UserRoles.CM_ADMIN, UserRoles.CENTRAL_MERCHANDISER_RETAIL, UserRoles.ADMINISTRATOR]
}

@observer
export class WSZoneWiseComments extends React.Component<WSZoneWiseCommentsProps> {
  @observable editedCommentMap = {}
  @observable showTextInputMap = {}

  @action
  commentTyped = (text, zoneProductId) => {
    this.editedCommentMap[`${zoneProductId}_${this.props.commentType}`] = text.substring(
      0,
      150
    )
  }

  @action
  showCommentTextInput = (zoneProductId, comment) => {
    const {
      props: { commentType },
      showTextInputMap,
      editedCommentMap
    } = this
    showTextInputMap[`${zoneProductId}_${commentType}`] = true
    editedCommentMap[`${zoneProductId}_${commentType}`] = comment
  }

  @action
  updateComment = (zoneProductId, buyingSessionProductId, zoneId, comment) => {
    const {
      wsBuyingSessionProduct: { updateRetailBuyComment }
    } = stores
    const index = `${zoneProductId}_${this.props.commentType}`
    this.editedCommentMap[index] = this.editedCommentMap[index].trim()
    const commentText: string = this.editedCommentMap[index]
    //update API call
    updateRetailBuyComment(
      buyingSessionProductId,
      zoneId,
      commentText,
      this.props.commentType
    )
    comment = comment ? commentText : ''
    this.hideCommentTextInput(zoneProductId)
  }

  isAllowedToAddUpdateComment = (zoneType, commentType): boolean => {
    return zoneType !== 'global' && hasUserRoles(allowedUserRoles[commentType])
  }

  @action
  hideCommentTextInput = zoneProductId => {
    this.showTextInputMap[`${zoneProductId}_${this.props.commentType}`] = false
  }

  handleKeyDownForComment = async (
    evt,
    isCommentChanged,
    zoneProductId,
    buyingSessionProductId,
    zoneId,
    comment
  ) => {
    if (evt.key === 'Enter') {
      if (isCommentChanged) {
        this.updateComment(zoneProductId, buyingSessionProductId, zoneId, comment)
      } else {
        this.hideCommentTextInput(zoneProductId)
      }
    }
  }

  render() {
    const {
      props: { zoneWiseData, commentType },
      editedCommentMap,
      commentTyped,
      updateComment,
      showTextInputMap,
      showCommentTextInput,
      hideCommentTextInput,
      handleKeyDownForComment,
      isAllowedToAddUpdateComment
    } = this

    return (
      <div>
        {zoneWiseData.map(zone => {
          const commentLocalId = `${zone.id}_${commentType}`
          const comment = get(zone, commentType)
          const currentText =
            editedCommentMap && editedCommentMap[commentLocalId] !== undefined
              ? editedCommentMap[commentLocalId]
              : comment ?? null

          // If non-empty DB comment exists, compare with changed comment to decide if comment is changed,
          // else (DB comment does not exist or empty) check if currentText is not empty
          const isCommentChanged =
            comment && comment ? currentText !== comment : !!currentText
          return (
            <div
              key={`comment_${commentLocalId}`}
              onClick={
                isAllowedToAddUpdateComment(zone.type, commentType)
                  ? () => showCommentTextInput(zone.id, currentText)
                  : null
              }
            >
              <Root>
                {showTextInputMap && showTextInputMap[commentLocalId] ? (
                  <StyledAWSTextFieldComponent
                    key={`comment_text_input_${commentLocalId}`}
                    value={currentText || ''}
                    onChange={e => commentTyped(e.target.value, zone.id)}
                    onBlur={() =>
                      isCommentChanged
                        ? updateComment(
                            zone.id,
                            zone.buyingSessionProductId,
                            zone.zoneId,
                            comment ? comment : null
                          )
                        : hideCommentTextInput(zone.id)
                    }
                    autoFocus={true}
                    fullWidth={true}
                    onKeyDown={e =>
                      handleKeyDownForComment(
                        e,
                        isCommentChanged,
                        zone.id,
                        zone.buyingSessionProductId,
                        zone.zoneId,
                        comment ? comment : null
                      )
                    }
                  />
                ) : (
                  <Tooltip
                    key={`comment_tooltip_${commentLocalId}`}
                    title={currentText || ''}
                    disableHoverListener={!currentText}
                  >
                    <Typography
                      key={`comment_text_${commentLocalId}`}
                      variant={'caption'}
                      className={classNames(
                        css.ellipsis,
                        !currentText ? css.helpText : null
                      )}
                    >
                      {currentText
                        ? currentText
                        : isAllowedToAddUpdateComment(zone.type, commentType)
                        ? strings.commentsHelpText
                        : null}
                    </Typography>
                  </Tooltip>
                )}
              </Root>
            </div>
          )
        })}
      </div>
    )
  }
}
