import { config } from '@config'
import {
  EXTERNALIMAGEPREFIX,
  EXTERNALIMAGETHUMBNAIL,
  tags,
  WholesaleAttributes,
  WholesaleLookAttributes
} from '@constants'
import {
  BuyingSessionAssortmentType,
  ChannelSubSection
} from '@modules/common/models/enums/BSGroupAndBSNav'
import { ListViewFieldMapping } from '@modules/common/models/enums/ListViewFieldMapping'
import {
  ImageAttributes,
  Modules,
  Operations
} from '@modules/common/models/enums/NodeAttributes'
import { AttributeValueType } from '@modules/common/models/enums/ProductAttributeValueType'
import {
  ProductAttributeSelectorEnum,
  ProductCardAttributeEnum
} from '@modules/common/models/enums/ProductCardAttributeEnum'
import { ViewTabs } from '@modules/common/models/enums/Views'
import { VIPChannel } from '@modules/common/models/enums/VIPChannel'
import { VIPClientType } from '@modules/common/models/enums/VIPClientType'
import { WholesaleGirdProductFieldMapping } from '@modules/common/models/enums/WholesaleGirdProductFieldMapping'
import { CentricEnumValue } from '@modules/common/models/interfaces/CentricEnumValue'
import { CentricNode } from '@modules/common/models/interfaces/CentricNode'
import { CentricUserDetails } from '@modules/common/models/interfaces/CentricUser'
import {
  CoreProductAttribute,
  ListViewProduct,
  ProductKPIsByZone
} from '@modules/common/models/interfaces/ListViewProduct'
import { VIPProduct } from '@modules/common/models/interfaces/VIPProduct'
import {
  IAttribute,
  IChip,
  IFavorite,
  IGridViewProduct,
  INodeInfo
} from '@modules/common/models/interfaces/WholesaleGridProduct'
import {
  ProductAttributeConf,
  WholeSaleProductItemConf
} from '@modules/common/models/interfaces/WholesaleProductConf'
import { PRODUCT_STATUS } from '@modules/retail/buyingSession/constants'
import { isBuyingSessionRestrictedForTag } from '@modules/wholesale/wsNavigations/constants/Tags'
import { convertToCurrencyLocaleString } from '@services/assortmentService'
import {
  getInterfaceImageURLFromKey,
  getMediaCentricURI,
  resolveEnum
} from '@services/commonServices'
import { storage } from '@services/storageService'
import { hasUserRoles, PERMISSION_MAP } from '@services/userRoleService'
import {
  isRetailChannel,
  isWholesaleChannel
} from '@services/wholesale/wholesaleBuyingSessionGroupsServices'
import { stores, strings } from '@stores'
import formatCurrencyValue from '@utils/format-currency-value'
import { getEnumValue } from '@utils/get-enum-value'
import { getPermissionsByEntityAndRoleForWholesale } from '@ws-routes/wholesaleRoutes'
import cloneDeep from 'lodash/cloneDeep'
import every from 'lodash/every'
import filter from 'lodash/filter'
import find from 'lodash/find'
import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import lowerCase from 'lodash/lowerCase'
import map from 'lodash/map'
import mapValues from 'lodash/mapValues'
import orderBy from 'lodash/orderBy'
import reduce from 'lodash/reduce'
import rejectLodash from 'lodash/reject'
import some from 'lodash/some'
import sortBy from 'lodash/sortBy'
import upperFirst from 'lodash/upperFirst'
import memoize from 'memoize-one'
import moment from 'moment'
import {
  isBuyingSessionClosed,
  isRtlBuyingSessionClosed
} from './wholesaleBuyingSessionService'
import { VIPAssortmentProduct, VIPOrderProduct } from './wholesaleCollectionService'
import { wholesalePriceService } from './wholesalePriceService'

interface Drop {
  Id: string
  Name: string
}

interface OrderProductLocalPrice {
  wholesale?: {
    price: number
    currency: string
  }
  retail?: {
    price: number
    currency: string
  }
  retailRefPrice?: {
    price: number
    currency: string
  }
}

export interface Clusters {
  Id: string
  Name: string
  RGBHex?: string
  channel?: VIPChannel
}

interface ExcludedClients {
  Id: string
  Name: string
}

interface Favorite {
  Id: string
  UserID: string
}

interface DocumentsAndComments {
  Id: string
  Comments: Array<{
    Id: string
  }>
}

export interface VIPBuyingSessionProduct {
  isCore?: boolean
  Id: string
  totalDoors: number
  totalClients: number
  Clusters: Clusters
  DocumentsAndComments: DocumentsAndComments
  Drop: Drop
  ExcludedClients: ExcludedClients
  Favorites: Array<Favorite>
  Channels: Array<string>
  FreeTags: Array<string>
  Original: any
  BuyingSession: any
  ClusterAssortmentProducts: any
  averageRating: number
  hasComments: boolean
  CreatedAt?: number
  draftQty?: number
  sizedQty?: number
  salesQty?: number
  value?: number
  draftValue?: number
  globalDraftValue?: number
  sizedValue?: number
  globalSizedValue?: number
  clusterExclusion?: Clusters[]
}

interface VIPProductEnum {
  Id: string
  Name: string
  Description: string
  Values: Array<CentricEnumValue>
}

interface IPricesMapWithList {
  retailPriceList: any
  retailPriceValues: any
  srpPriceList: any
  srpPriceValues: any
  wholesalePriceList: any
  wholesalePriceValues: any
  retailRefPriceList: any
  retailRefPriceValues: any
}

export interface IVIPAllotment {
  Id: string
  Gender: CentricNode
  Activity: CentricNode
  BuyingSessionGroup: CentricNode
  Cluster: CentricNode
  __Parent__: CentricNode
}

/**
 * Returns attribute config data specific to client
 */
const getProductAttributeConfig = (key: string, reject = false) => {
  if (key) {
    // exclude filtered attributes
    if (reject) {
      return rejectLodash(
        config?.appConfig?.attributes?.VIPProduct,
        config => config[key] === true
      ) as Array<ProductAttributeConf>
    }
    return config?.appConfig?.attributes?.VIPProduct?.filter(
      config => config[key] === true
    ) as Array<ProductAttributeConf>
  }
  return (config?.appConfig?.attributes?.VIPProduct as Array<ProductAttributeConf>) ?? []
}

/**
 * Returns enum name from config data
 */
const getEnumName = (name: string) => {
  return config?.appConfig?.enumerations[name]?.NAME ?? ''
}

/**
 * Returns product tags specific configuration
 * @param Tag
 */
const getConfItem = (
  attributeNameToMatch: ProductCardAttributeEnum,
  confList: Array<WholeSaleProductItemConf>
) => {
  return confList.find(item => item.attributeName === attributeNameToMatch)
}

/**
 * Returns comma separated name string from list. Comma separated values
 * can be sorted using keyToSort
 * @param list - List of values/string
 * @pathToValue - Path to data prop
 * @param pathToSortProp - Path to props which will decide sort order
 */
const resolvedRefListToValue = (
  list: Array<string>,
  pathToValue: string,
  pathToSortProp?: string
): string => {
  if (isEmpty(list)) return ''

  const listData = pathToSortProp ? sortBy(list, item => get(item, pathToSortProp)) : list
  return listData.map(item => item[pathToValue]).join(',')
}

/**
 * Returns product specific enum value for data
 * @param enumName - Name of enum
 * @param enumKey - Enum Key
 * @param enumList - Possible list of product enums
 */
const getEnum = (enumName: string, enumKey: string, enumList: Array<VIPProductEnum>) => {
  const enumData = enumList.find(element => {
    return element.Name === enumName
  })
  //TODO: revert- temp fix since enum data isn't available
  return (
    find(enumData?.Values ?? [], enumValue => enumValue?.Value === enumKey) || {
      Name: enumKey?.split(':')?.[1],
      Id: enumKey?.split(':')?.[1],
      Description: enumKey?.split(':')?.[1],
      Value: enumKey?.split(':')?.[1]
    }
  )
}

/**
 * Returns product specific enum value for data
 * @param enumName - Name of enum
 * @param enumKeyList - List of Enum Keys
 * @param enumList - Possible list of product enums
 */
const getEnumList = (
  enumName: string,
  enumKeyList: Array<string>,
  enumList: Array<VIPProductEnum>
) => {
  return enumKeyList && enumKeyList.length
    ? enumKeyList.map(enumVal => {
        return getEnum(enumName, enumVal, enumList)
      })
    : []
}

export type ValueMetadata = {
  targetCurrency: string
  convertToTargetCurrency: boolean
  exchangeRate: number
}

class WholeSaleProductService {
  /**
   * Returns NodeInfo object for each product
   * TODO: Need a way to get rid of hardcode
   * @param product - product data
   * @param enumData - product enum list
   * @returns <INodeInfo>
   *
   */
  getNodeInfo = (product: any, enumList: Array<VIPProductEnum>): INodeInfo => {
    const productStatus = product?.Original
      ? product?.Original.ProductStatus
      : product?.ProductStatus
    const title = product?.Original
      ? product?.Original?.Style?.ModelDescription ?? ''
      : product?.Style?.ModelDescription ?? ''
    const subtitle = product?.Original
      ? product?.Original?.StyleColorCode ?? ''
      : product?.StyleColorCode ?? ''

    return {
      legend: productStatus || '',
      title,
      subtitle
    }
  }

  /**
   * Returns Favorite object for each product
   * TODO: Need a way to get rid of hardcode
   * @param product - product data
   * @returns <IFavorite>
   */
  getFavorite = (product: VIPBuyingSessionProduct): IFavorite => {
    const currentUser: CentricUserDetails = storage.getItem('user')
    const favorite = product?.Favorites ?? []
    let count = isEmpty(favorite) ? 0 : favorite.length
    let likedByUser = count ? !!favorite.find(item => item.Id === currentUser.id) : false

    return {
      likedByUser,
      count
    }
  }

  getDropsPermissions = memoize((buyingSessionProduct, view) => {
    const permissions = getPermissionsByEntityAndRoleForWholesale('buyingSessions')
    return {
      canDeleteDrop:
        (!view || BuyingSessionAssortmentType.cluster === view) &&
        hasUserRoles(
          PERMISSION_MAP.find(ele => ele.id === VIPChannel.Wholesale)?.allowedRoles
        ) &&
        !isBuyingSessionRestrictedForTag(
          buyingSessionProduct.BuyingSession,
          strings.tagTypes.drop
        ) &&
        buyingSessionProduct?.Original.Active &&
        permissions.canEdit
    }
  })

  /**
   * Returns drop present on product
   * TODO: Need a way to get rid of hardcode
   * @param product
   * @returns <IAttribute>
   */
  getDrops = (product: VIPBuyingSessionProduct, canDeleteTag?: boolean): IAttribute => {
    const tag = getConfItem(ProductCardAttributeEnum.drop, tags)
    const name = product[tag.attributeName]?.Name ?? ''
    const id = product[tag.attributeName]?.Id ?? ''
    const startDate = product[tag.attributeName]?.Start
      ? moment(new Date(product[tag.attributeName]?.Start)).format('DD-MMM-YYYY')
      : ''
    const endDate = product[tag.attributeName]?.End
      ? moment(new Date(product[tag.attributeName]?.End)).format('DD-MMM-YYYY')
      : ''
    return {
      name: tag.attributeName,
      label: tag.name,
      value:
        name && id
          ? [{ id, title: name, subtitle: '', tooltip: `${startDate} To ${endDate}` }]
          : [],
      isChip: true
    }
  }

  getSalePeriodTagsPermissions = memoize((buyingSessionProduct, view) => {
    const originalProduct = buyingSessionProduct?.Original
    const permissions = getPermissionsByEntityAndRoleForWholesale('buyingSessions')
    const hasRetailRoles = hasUserRoles(
      PERMISSION_MAP.find(ele => ele.id === VIPChannel.Retail)?.allowedRoles
    )
    return {
      [`canDelete${upperFirst(ProductCardAttributeEnum.retailSalesPeriod)}`]:
        (!view || BuyingSessionAssortmentType.cluster === view) &&
        hasRetailRoles &&
        !isBuyingSessionRestrictedForTag(
          buyingSessionProduct.BuyingSession,
          strings.tagTypes.salesPeriod
        ) &&
        originalProduct.Active &&
        permissions.canEdit
    }
  })

  getRetailSalesPeriodTags = (product: VIPBuyingSessionProduct): IAttribute => {
    const tag = getConfItem(ProductCardAttributeEnum.retailSalesPeriod, tags)
    const salesPeriod = product[tag.attributeName] ?? []
    const startDate = salesPeriod?.Start
      ? moment(new Date(salesPeriod.Start)).format('DD-MMM-YYYY')
      : ''
    const endDate = salesPeriod?.End
      ? moment(new Date(salesPeriod?.End)).format('DD-MMM-YYYY')
      : ''
    return {
      name: tag.attributeName,
      label: tag.name,
      value: salesPeriod?.Id
        ? [
            {
              id: salesPeriod.Id,
              title: salesPeriod.Name,
              subtitle: '',
              tooltip: `${startDate} To ${endDate}`
            }
          ]
        : [],
      isChip: true
    }
  }

  getFreeTagsPermissions = memoize((buyingSessionProduct, view) => {
    const canEditWholesaleRoles = hasUserRoles(
      PERMISSION_MAP.find(ele => ele.id === VIPChannel.Wholesale)?.allowedRoles
    )
    const canEditRetailRoles = hasUserRoles(
      PERMISSION_MAP.find(ele => ele.id === VIPChannel.Retail)?.allowedRoles
    )
    const permissions = getPermissionsByEntityAndRoleForWholesale('buyingSessions')

    const canDeleteWholesaleFreeTags =
      !isBuyingSessionRestrictedForTag(
        buyingSessionProduct.BuyingSession,
        strings.tagTypes.freeTag
      ) &&
      buyingSessionProduct.Original.Active &&
      permissions.canEdit &&
      (!view || BuyingSessionAssortmentType.cluster === view)

    const canDeleteRetailFreeTags =
      !isBuyingSessionRestrictedForTag(
        buyingSessionProduct.BuyingSession,
        strings.tagTypes.retailFreeTags
      ) &&
      buyingSessionProduct.Original.Active &&
      permissions.canEdit &&
      (!view || BuyingSessionAssortmentType.cluster === view)
    return {
      [`canDelete${upperFirst(ListViewFieldMapping.RetailFreeTags)}`]:
        canDeleteRetailFreeTags && canEditRetailRoles,
      [`canDelete${upperFirst(ListViewFieldMapping.WholesaleFreeTags)}`]:
        canDeleteWholesaleFreeTags && canEditWholesaleRoles
    }
  })

  private getFreeTags = (
    tag: any,
    product: VIPBuyingSessionProduct,
    canDeleteTag?: boolean
  ): IAttribute => {
    return {
      name: tag.attributeName,
      label: tag.name,
      value: map(product[tag.attributeName] ?? [], tag => {
        return {
          id: tag.Id,
          title: tag.Name,
          subtitle: '',
          tooltip: `${tag.Name}`
        }
      }),
      isChip: true
    }
  }

  /**
   * Returns RTL free tags present on product
   * TODO: Need a way to get rid of hardcode
   * @param product
   * @returns <IAttribute>
   */
  getRetailFreeTags = (
    product: VIPBuyingSessionProduct,
    canDeleteTag?: boolean
  ): IAttribute => {
    const tag = getConfItem(ProductCardAttributeEnum.retailFreeTags, tags)
    const canDelete =
      canDeleteTag &&
      hasUserRoles(PERMISSION_MAP.find(ele => ele.id === VIPChannel.Retail)?.allowedRoles)
    return this.getFreeTags(tag, product, canDelete)
  }

  /**
   * Returns WHL free tags present on product
   * TODO: Need a way to get rid of hardcode
   * @param product
   * @returns <IAttribute>
   */
  getWholesaleFreeTags = (
    product: VIPBuyingSessionProduct,
    canDeleteTag?: boolean
  ): IAttribute => {
    const tag = getConfItem(ProductCardAttributeEnum.wholesaleFreeTags, tags)
    const canDelete =
      canDeleteTag &&
      hasUserRoles(
        PERMISSION_MAP.find(ele => ele.id === VIPChannel.Wholesale)?.allowedRoles
      )
    return this.getFreeTags(tag, product, canDelete)
  }

  getChannelsPermissions = memoize((buyingSessionProduct, view) => {
    const permissions = getPermissionsByEntityAndRoleForWholesale('buyingSessions')
    const canDeleteChannels =
      !view && buyingSessionProduct.Original.Active && permissions.canEdit
    return {
      canDeleteChannels,
      canDeleteRetailChannel:
        canDeleteChannels &&
        !isBuyingSessionRestrictedForTag(
          buyingSessionProduct.BuyingSession,
          strings.tagTypes.channel,
          VIPChannel.Retail
        ) &&
        !buyingSessionProduct?.isCore &&
        hasUserRoles(
          PERMISSION_MAP.find(ele => ele.id === VIPChannel.Retail)?.allowedRoles
        ),
      canDeleteWholesaleChannel:
        canDeleteChannels &&
        !isBuyingSessionRestrictedForTag(
          buyingSessionProduct.BuyingSession,
          strings.tagTypes.channel,
          VIPChannel.Wholesale
        ) &&
        hasUserRoles(
          PERMISSION_MAP.find(ele => ele.id === VIPChannel.Wholesale)?.allowedRoles
        )
    }
  })

  /**
   * Returns channels present on product
   * TODO: Need a way to get rid of hardcode
   * @param product
   * @returns <IAttribute>
   */
  getChannels = (
    product: VIPBuyingSessionProduct,
    enumList: Array<VIPProductEnum>,
    permissions
  ): IAttribute => {
    const tag = getConfItem(ProductCardAttributeEnum.channels, tags)
    const valList = getEnumList(
      getEnumName(strings.tagTypes.channel),
      product[tag.attributeName],
      enumList
    )
    const values = isEmpty(valList)
      ? []
      : valList.map(item => {
          const enumTitle = resolveEnum(item)
          return {
            id: item?.Value,
            title: enumTitle,
            subtitle: '',
            tooltip: enumTitle,
            canDelete: permissions[`canDelete${enumTitle}Channel`]
          }
        })
    return {
      name: tag.attributeName,
      label: tag.name,
      value: values,
      isChip: true
    }
  }

  getClustersPermissions = memoize(buyingSessionProduct => {
    const canEditWholesaleRoles = hasUserRoles(
      PERMISSION_MAP.find(ele => ele.id === VIPChannel.Wholesale)?.allowedRoles
    )
    const canEditRetailRoles = hasUserRoles(
      PERMISSION_MAP.find(ele => ele.id === VIPChannel.Retail)?.allowedRoles
    )
    const isBuyingSessionRestrictedForWhlCluster = isBuyingSessionRestrictedForTag(
      buyingSessionProduct.BuyingSession,
      strings.tagTypes.wholesaleCluster
    )
    const isBuyingSessionRestrictedForRtlCluster = isBuyingSessionRestrictedForTag(
      buyingSessionProduct.BuyingSession,
      strings.tagTypes.retailCluster
    )
    const permissions = getPermissionsByEntityAndRoleForWholesale('buyingSessions')
    const canDeleteWholesaleClusters =
      !isBuyingSessionRestrictedForWhlCluster && permissions.canEdit
    const canDeleteRetailClusters =
      !isBuyingSessionRestrictedForRtlCluster && permissions.canEdit
    return {
      [`canDelete${upperFirst(ListViewFieldMapping.WholesaleClusters)}`]:
        canDeleteWholesaleClusters && canEditWholesaleRoles,
      [`canDelete${upperFirst(ListViewFieldMapping.RetailClusters)}`]:
        canDeleteRetailClusters && canEditRetailRoles && !buyingSessionProduct?.isCore,
      [`canEdit${upperFirst(ListViewFieldMapping.RetailClusters)}`]:
        canDeleteRetailClusters && canEditRetailRoles
    }
  })

  /**
   * Returns clusters present on product
   * TODO: Need a way to get rid of hardcode
   * @param product
   * @returns <IAttribute>
   */
  getClusters = (
    product,
    {
      canDeleteWholesaleClusters,
      canDeleteRetailClusters
    }: { canDeleteWholesaleClusters: boolean; canDeleteRetailClusters: boolean },
    selectedChannels?: Array<String>
  ): IAttribute[] => {
    const clusters: any = filter(
      map(
        product[ProductCardAttributeEnum.clusterAssortmentsProduct],
        assortmentProduct => ({
          cluster:
            assortmentProduct[ProductCardAttributeEnum.assortment]?.[
              ProductCardAttributeEnum.clusters
            ],
          clusterStatus: assortmentProduct['clusterStatus']
        })
      ),
      val => val
    )
    const clusterAssortments = {
      ...(selectedChannels ?? Object.keys(VIPChannel)).reduce(
        (acc, key) => ({ ...acc, [`${lowerCase(getEnumValue(key))}Clusters`]: [] }),
        {}
      ),
      ...mapValues(
        groupBy(
          clusters,
          ({ cluster: { channel } }) => `${lowerCase(getEnumValue(channel))}Clusters`
        ),
        clusters =>
          map(clusters as any[], ({ cluster, clusterStatus }) => ({
            id: cluster.Id,
            title: cluster.Name,
            subtitle: clusterStatus?.Name,
            tooltip: cluster.Name,
            color: cluster.RGBHex
          }))
      )
    }
    return Object.keys(clusterAssortments).map((channeledClusterKey: string) => {
      const tag = getConfItem(channeledClusterKey as ProductCardAttributeEnum, tags)
      return {
        name: tag.attributeName,
        label: tag.name,
        value: clusterAssortments[channeledClusterKey],
        isChip: true
      }
    })
  }

  /**
   * Returns status for product
   * @param product - Product
   * @returns Array<ClusterAssortmentProducts>
   */
  getClusterAssortmentProduct = product => {
    return product[ProductCardAttributeEnum.clusterAssortmentsProduct]
      ? product[ProductCardAttributeEnum.clusterAssortmentsProduct]
      : []
  }

  getExcludedClientOrZonePermissions = (buyingSessionProduct, view) => {
    const originalProduct = buyingSessionProduct.Original
    const isRtlBuyingSessionClosedStatus = isRtlBuyingSessionClosed(
      buyingSessionProduct.BuyingSession
    )
    const permissions = getPermissionsByEntityAndRoleForWholesale('buyingSessions')
    const hasRetailRoles = hasUserRoles(
      PERMISSION_MAP.find(ele => ele.id === VIPChannel.Retail)?.allowedRoles
    )
    return {
      [`canDelete${upperFirst(ListViewFieldMapping.ExcludedClients)}`]: false,
      [`canDelete${upperFirst(ListViewFieldMapping.ExcludedZones)}`]:
        !view &&
        hasRetailRoles &&
        !isRtlBuyingSessionClosedStatus &&
        originalProduct.Active &&
        permissions.canEdit
    }
  }

  /**
   * Returns clusters present on product
   * TODO: Need a way to get rid of hardcode
   * @param product
   * @returns <IAttribute>
   */
  getExcludedClients = (product: VIPBuyingSessionProduct): IAttribute => {
    const tag = getConfItem(ProductCardAttributeEnum.clientExclusion, tags)
    const clients = (product[tag.attributeName] || []).filter(
      client => client.ClientType === VIPClientType.External
    )
    let value = []
    if (!isEmpty(clients)) {
      let title =
        clients.length > 1
          ? `${clients.length} ${strings.clients}`
          : `1 ${strings.client}`
      value = [{ id: '', title, subtitle: '', tooltip: title }]
    }

    return {
      name: tag.attributeName,
      label: tag.name,
      value,
      isChip: true
    }
  }

  getExcludedZones = (product: VIPBuyingSessionProduct): IAttribute => {
    const clientExclusionTag = getConfItem(ProductCardAttributeEnum.clientExclusion, tags)
    const tag = getConfItem(ProductCardAttributeEnum.zoneExclusion, tags)
    const clients = (product[clientExclusionTag.attributeName] || []).filter(
      client => client.ClientType === VIPClientType.Internal
    )
    return {
      name: tag.attributeName,
      label: tag.name,
      value: map(clients, ele => ({
        id: ele.Id,
        title: ele.Name,
        subtitle: '',
        tooltip: ele.Name
      })),
      isChip: true
    }
  }

  getExcludedClusterPermissions = buyingSessionProduct => {
    const originalProduct = buyingSessionProduct.Original
    const isBuyingSessionClosedStatus = isBuyingSessionClosed(
      buyingSessionProduct.BuyingSession
    )
    const isRtlBuyingSessionClosedStatus = isRtlBuyingSessionClosed(
      buyingSessionProduct.BuyingSession
    )
    const permissions = getPermissionsByEntityAndRoleForWholesale('buyingSessions')
    return {
      [`canDelete${upperFirst(ProductCardAttributeEnum.wholesaleClusterExclusion)}`]:
        hasUserRoles(
          PERMISSION_MAP.find(ele => ele.id === VIPChannel.Wholesale)?.allowedRoles
        ) &&
        !isBuyingSessionClosedStatus &&
        originalProduct.Active &&
        permissions.canEdit,
      [`canDelete${upperFirst(ProductCardAttributeEnum.retailClusterExclusion)}`]:
        hasUserRoles(
          PERMISSION_MAP.find(ele => ele.id === VIPChannel.Retail)?.allowedRoles
        ) &&
        !isRtlBuyingSessionClosedStatus &&
        originalProduct.Active &&
        permissions.canEdit
    }
  }

  /**
   * Returns clusters present on product
   * TODO: Need a way to get rid of hardcode
   * @param product
   * @returns <IAttribute>
   */
  getExcludedCluster = (
    product,
    {
      canDeleteWholesaleClusterExclusion,
      canDeleteRetailClusterExclusion
    }: {
      canDeleteWholesaleClusterExclusion: boolean
      canDeleteRetailClusterExclusion: boolean
    },
    selectedChannels?: Array<String>
  ): IAttribute[] => {
    const clusterAssortments = {
      ...(selectedChannels ?? Object.keys(VIPChannel)).reduce(
        (acc, key) => ({
          ...acc,
          [`${lowerCase(getEnumValue(key))}ClusterExclusion`]: []
        }),
        {}
      ),
      ...mapValues(
        groupBy(
          [
            ...(product.clusterExclusion || []),
            ...(product.retailClusterExclusion || [])
          ],
          ({ channel }) => `${lowerCase(getEnumValue(channel))}ClusterExclusion`
        ),
        clusters =>
          map(clusters as any[], cluster => ({
            id: cluster.Id,
            title: cluster.Name,
            subtitle: '',
            tooltip: cluster.Name,
            color: cluster.RGBHex
          }))
      )
    }
    return Object.keys(clusterAssortments).map((channeledClusterKey: string) => {
      const tag = getConfItem(channeledClusterKey as ProductCardAttributeEnum, tags)
      return {
        name: tag.attributeName,
        label: tag.name,
        value: clusterAssortments[channeledClusterKey],
        isChip: true
      }
    })
  }

  /**
   * Returns associated looks for product
   * @param product
   * @returns <IAttribute>
   */
  getAssociatedLooks = (product: any): IAttribute => {
    const look = getConfItem(
      ProductCardAttributeEnum.associatedLooks,
      WholesaleLookAttributes
    )
    const value = resolvedRefListToValue(product[look.attributeName], 'Name')
    return {
      name: look.attributeName,
      label: look.name,
      color: undefined,
      isChip: false,
      value
    }
  }

  /**
   * Returns retail price for product
   * @param product
   * @returns <IAttribute>
   */

  getCentralSRPPrice = (
    product: VIPBuyingSessionProduct | VIPOrderProduct,
    priceListData: IPricesMapWithList,
    orderProductPrice?: OrderProductLocalPrice
  ) => {
    const seasonalProduct: VIPProduct = product.Original
    const price = getConfItem(
      ProductCardAttributeEnum.suggestedRetailPrice,
      WholesaleAttributes
    )

    let priceValue,
      currency = ''
    if (!priceListData?.srpPriceValues && orderProductPrice) {
      priceValue = orderProductPrice?.retail?.price
    } else {
      priceValue = find(
        wholesalePriceService.sortPriceListValuesByExtId(
          priceListData?.srpPriceValues?.Items
        ),
        priceValue => priceValue.Code === seasonalProduct?.Style?.Article
      )
    }

    const value = priceValue?.Price
    if (value && !isEmpty(orderProductPrice)) {
      currency = orderProductPrice.retail.currency
    } else if (value && !isEmpty(priceListData)) {
      currency = priceListData?.srpPriceList?.Items?.[0]?.Currency?.Name
    }

    return {
      name: price.attributeName,
      isPriceAttribute: true,
      label: price.name,
      color: undefined,
      isChip: false,
      value:
        value !== null && value !== undefined
          ? formatCurrencyValue(currency, value)
          : 'NA'
    }
  }

  /**
   * Returns EURO retail price for product
   * @param product
   * @returns <IAttribute>
   */
  getCentralRetailPrice = (
    product: VIPBuyingSessionProduct | VIPOrderProduct,
    priceListData: IPricesMapWithList
  ) => {
    const seasonalProduct: VIPProduct = product.Original
    const price = getConfItem(ProductCardAttributeEnum.retailPrice, WholesaleAttributes)

    const priceValue = find(
      wholesalePriceService.sortPriceListValuesByExtId(
        priceListData?.retailPriceValues?.Items
      ),
      priceValue => priceValue.Code === seasonalProduct?.Style?.Article
    )
    const value = priceValue?.Price
    const currency = value
      ? priceListData?.retailPriceList?.Items?.[0]?.Currency?.Name
      : ''

    return {
      name: price.attributeName,
      isPriceAttribute: true,
      label: price.name,
      color: undefined,
      isChip: false,
      value:
        value !== null && value !== undefined
          ? formatCurrencyValue(currency, value)
          : 'NA'
    }
  }

  /**
   * Returns wholesale price for product
   * @param product
   * @returns <IAttribute>
   */
  getWholesalePrice = (
    product: VIPBuyingSessionProduct | VIPOrderProduct,
    priceListData: IPricesMapWithList,
    orderProductPrice?: OrderProductLocalPrice
  ) => {
    const seasonalProduct: VIPProduct = product.Original
    const price = getConfItem(
      ProductCardAttributeEnum.wholesalePrice,
      WholesaleAttributes
    )

    let priceValue,
      currency = ''
    if (!priceListData?.wholesalePriceValues && orderProductPrice) {
      priceValue = orderProductPrice?.wholesale?.price
    } else {
      priceValue = find(
        wholesalePriceService.sortPriceListValuesByExtId(
          priceListData?.wholesalePriceValues?.Items
        ),
        priceValue => priceValue.Code === seasonalProduct?.Style?.Article
      )
    }

    const value = priceValue?.Price
    if (value && !isEmpty(orderProductPrice)) {
      currency = orderProductPrice?.wholesale?.currency
    } else if (value && !isEmpty(priceListData)) {
      currency = priceListData?.wholesalePriceList?.Items?.[0]?.Currency?.Name
    }

    return {
      name: price.attributeName,
      isPriceAttribute: true,
      label: price.name,
      color: undefined,
      isChip: false,
      value:
        value !== null && value !== undefined
          ? formatCurrencyValue(currency, value)
          : 'NA'
    }
  }

  /**
   * Returns status for product
   * @param isActive - Product active status
   * @returns string
   */
  getProductStatus = (isActive: boolean) => {
    let productStatus = null
    if (isActive) {
      productStatus = ''
    } else {
      productStatus = 'inactive'
    }
    return productStatus
  }

  /**
   * Returns average rating for product after rounding to 1 deciaml place
   * @param product - BS product
   * @returns Number
   */
  getAverageRating = (product: VIPBuyingSessionProduct) =>
    Number(product.averageRating ? product.averageRating.toFixed(1) : 0)

  /**
   * Resolved product attribute list to
   * UI friendly data structure
   * @param - product
   * @params - selectedAttributes
   * @params - enumList
   */
  getProductAttributeList = (
    buyingSessionProduct: any,
    enumList: Array<VIPProductEnum>,
    onlyDefaultAttribute: boolean = false,
    selectedAttributes?: Array<ProductAttributeConf>
  ): Array<IAttribute> => {
    const processedAttributes: Array<IAttribute> = []
    const productAttributeList = getProductAttributeConfig(
      onlyDefaultAttribute ? ProductAttributeSelectorEnum.isDefaultSelected : null
    )
    const attributeList = selectedAttributes ? selectedAttributes : productAttributeList
    const originalProduct = buyingSessionProduct.Original

    for (let attribute of attributeList) {
      let value
      switch (attribute.valueType) {
        case AttributeValueType.STRING:
          value = originalProduct[attribute.attributeName]
          break
        case AttributeValueType.ENUM:
          const enumName = getEnumName(attribute.id)
          value = resolveEnum(
            getEnum(enumName, originalProduct[attribute.attributeName], enumList)
          )
          break
        case AttributeValueType.REF:
          const ref = originalProduct[attribute.attributeName]
          value = get(ref, attribute.pathToValue, '')
          break
        case AttributeValueType.INTEGER:
          value = String(originalProduct[attribute.attributeName])
          break
        case AttributeValueType.REFLIST:
          const refList = originalProduct[attribute.attributeName]
          value = resolvedRefListToValue(refList, attribute.pathToValue)
          break
        default:
          value = ''
      }
      let colorValue = attribute.pathToColorValue
        ? originalProduct[attribute.attributeName]?.[attribute.pathToColorValue] ?? ''
        : ''
      const isPrimaryAttribute = !isEmpty(attribute.saNewDetailsView)
      processedAttributes.push({
        name: attribute.id,
        label: attribute.displayName,
        color: colorValue,
        isChip: false,
        value,
        // primary attributes should on sa prod detail
        // regardless of show more attributes collapsible panel state
        isPrimaryAttribute,
        sortIndex: isPrimaryAttribute ? attribute.saNewDetailsView.sortIndex : null
      })
    }

    return processedAttributes
  }

  /**
   * Returns delete decisions for product attributes/properties
   * based on given criteria
   * @param decisionParams - criteria
   * @returns DeleteDecisionResponse - response
   */
  clusterAssortmentProductPermission = view => {
    if (!view) {
      return {
        canDeleteClusterAssortmentProduct: false
      }
    }
    return {
      canDeleteClusterAssortmentProduct:
        view &&
        getPermissionsByEntityAndRoleForWholesale('buyingSessions').canEdit &&
        BuyingSessionAssortmentType.cluster === view
    }
  }

  /**
   * Function to get the associated looks in sorted way
   */

  getAssociatedLooksSorted = originalProduct => {
    const associatedLooks = cloneDeep(originalProduct?.associatedLooks) || []
    return associatedLooks.sort((a, b) => parseInt(a.Name, 10) - parseInt(b.Name, 10))
  }

  /**
   * Function to check weather product has wholesale channel
   */

  isWholeSaleChannelPresent = currentProductChannel => {
    const wholesaleChannelValue =
      config.appConfig?.enumerations?.channel?.VALUES?.WHOLESALE
    return currentProductChannel.value.some(({ id }) => id === wholesaleChannelValue)
  }

  /**
   * Function to check weather product has retail channel
   */

  isRetailChannelPresent = currentProductChannel => {
    const retailChannelValue = config.appConfig?.enumerations?.channel?.VALUES?.RETAIL
    return currentProductChannel.value.some(({ id }) => id === retailChannelValue)
  }

  /**
   * Method for converting product data received in response into the format that can be passed to SA ProductDetail view
   * @params {products} - Object with list products
   * @params {enumList} - Object with list enum values specific to products
   * @params {priceListData} - Object with price values specific to products
   * @params {productAttributes} - List with product attributes to fetch
   * @params {isShowMoreAttributesOpen} - Boolean to decide product attribute list dynamically
   */
  processProductForSAProductDetailView = memoize(
    (
      buyingSessionProduct: VIPBuyingSessionProduct,
      enumList: Array<VIPProductEnum>,
      priceListData: IPricesMapWithList,
      productAttributes?: [],
      isShowMoreAttributesOpen?
    ) => {
      const productAttributeList = isShowMoreAttributesOpen
        ? getProductAttributeConfig(
            ProductAttributeSelectorEnum.excludeFromNewSADetailsViewAttr,
            true
          )
        : sortBy(
            getProductAttributeConfig(
              ProductAttributeSelectorEnum.renderInNewSADetailsView
            ).filter(item => !isEmpty(item.saNewDetailsView)),
            item => item.saNewDetailsView.sortIndex
          )

      const originalProduct = buyingSessionProduct.Original
      const processedAttributes: Array<IAttribute> = this.getProductAttributeList(
        buyingSessionProduct,
        enumList,
        false,
        productAttributeList
      )

      processedAttributes.push(
        this.getWholesalePrice(buyingSessionProduct, priceListData)
      )
      processedAttributes.push(
        this.getCentralSRPPrice(buyingSessionProduct, priceListData)
      )

      processedAttributes.push(this.getWholesaleFreeTags(buyingSessionProduct))
      processedAttributes.push(this.getRetailFreeTags(buyingSessionProduct))

      processedAttributes.push(this.getDrops(buyingSessionProduct))

      map(processedAttributes, processedAttribute => {
        const existingProductAttribute = find(
          productAttributes,
          (productAttribute: any) => productAttribute.label === processedAttribute.label
        )

        if (existingProductAttribute) {
          processedAttribute.value = existingProductAttribute.value
        }
      })

      return {
        productId: buyingSessionProduct.Id,
        buyingSession: buyingSessionProduct.BuyingSession,
        isActive: originalProduct.Active,
        status: this.getProductStatus(originalProduct.Active),
        externalImages: originalProduct?.ExternalImages,
        imageURL: this.getProductDefaultImageURL(originalProduct),
        canDelete: false,
        isRestricted: false,
        isWholeSaleChannelPresent: this.isWholeSaleChannelPresent(
          this.getChannels(buyingSessionProduct, enumList, {})
        ),
        [WholesaleGirdProductFieldMapping.NodeInfo]: this.getNodeInfo(
          originalProduct,
          enumList
        ),
        [WholesaleGirdProductFieldMapping.Attributes]: processedAttributes,
        node: buyingSessionProduct,
        associatedLooks: this.getAssociatedLooksSorted(originalProduct)?.filter(
          look => look?.Active
        ),
        [WholesaleGirdProductFieldMapping.AverageRating]:
          this.getAverageRating(buyingSessionProduct),
        isFavourite: this.getFavorite(buyingSessionProduct)?.likedByUser
      }
    },
    isEqual
  )

  /**
   * getProductDefaultImageURL
   * It returns required product image. It prefers interface (like orb) image
   * first, if its not present it fallbacks to PLM image
   * @param originalProduct
   */
  getProductDefaultImageURL = (originalProduct: VIPProduct) => {
    if (!isEmpty(originalProduct.ExternalImages)) {
      const defaultExternalImageURL = getInterfaceImageURLFromKey(
        originalProduct?.ExternalImages,
        `${EXTERNALIMAGEPREFIX}_${EXTERNALIMAGETHUMBNAIL}`
      )
      return defaultExternalImageURL ? defaultExternalImageURL : ''
    } else {
      return originalProduct?.DefaultImage
        ? getMediaCentricURI({
            URL: originalProduct.DefaultImage.Id,
            Attribute: ImageAttributes.SmallImage,
            Module: Modules.Publisher,
            Operation: Operations.GetFromNode
          })
        : ''
    }
  }

  getRetailRefPrice = (
    product: VIPBuyingSessionProduct | VIPOrderProduct,
    priceListData: IPricesMapWithList,
    orderProductPrice?: OrderProductLocalPrice
  ) => {
    const seasonalProduct: VIPProduct = product.Original
    const price = getConfItem(
      ProductCardAttributeEnum.suggestedRetailPrice,
      WholesaleAttributes
    )

    let priceValue,
      currency = ''
    if (!priceListData?.retailRefPriceValues && orderProductPrice) {
      priceValue = orderProductPrice?.retailRefPrice?.price
    } else {
      priceValue = find(
        orderBy(
          priceListData?.retailRefPriceValues?.Items || [],
          priceValue => priceValue.VIPExternalId,
          'desc'
        ),
        priceValue => priceValue.Code === seasonalProduct?.Style?.Article
      )
    }

    const value = priceValue?.Price
    if (value && !_.isEmpty(orderProductPrice)) {
      currency = orderProductPrice?.retailRefPrice?.currency
    } else if (value && !_.isEmpty(priceListData)) {
      currency = priceListData?.retailRefPriceList?.Items?.[0]?.Currency?.Name
    }

    return {
      name: price.attributeName,
      isPriceAttribute: true,
      label: price.name,
      color: undefined,
      isChip: false,
      value:
        value !== null && value !== undefined
          ? convertToCurrencyLocaleString(currency, value)
          : 'NA'
    }
  }

  /**
   * Method for converting product data received in response into the format that can be passed to product card
   * @params {products} - Object with list products
   * @params {enumList} - Object with list enum values specific to products
   * @returns {Array<IGridViewProduct>}
   */
  processProductsForGridView = memoize(
    (
      products: Array<VIPBuyingSessionProduct>,
      enumList: Array<VIPProductEnum>,
      priceListData: IPricesMapWithList,
      view: string,
      selectedChannels?: Array<String>,
      isDetailsView: boolean = false
    ): Array<IGridViewProduct> => {
      const productAttributeList = isDetailsView
        ? getProductAttributeConfig(
            ProductAttributeSelectorEnum.excludeFromCMDetailsViewAttr,
            true
          )
        : getProductAttributeConfig(null)

      const {
        wsBuyingSessionProduct: { openDeleteAssortmentProductConfirmation }
      } = stores

      if (isEmpty(products) || isEmpty(enumList) || isEmpty(productAttributeList))
        return []

      /**
       * Map product attributes
       */
      const processedProducts: Array<IGridViewProduct> = []
      for (let product of products) {
        const buyingSessionProduct = view ? product.Original : product
        let originalProduct = (buyingSessionProduct as VIPBuyingSessionProduct).Original

        // filter out inactive looks GBB-4786
        // associated looks serves as a source for attribute info as well as looks grid
        // hence modifying the og product itself
        originalProduct = {
          ...originalProduct,
          associatedLooks: originalProduct.associatedLooks?.filter(look => look?.Active)
        }

        const processedAttributes: Array<IAttribute> = this.getProductAttributeList(
          buyingSessionProduct,
          enumList,
          false,
          productAttributeList
        )

        const deletePermissions = this.clusterAssortmentProductPermission(view)
        const { canDeleteClusterAssortmentProduct } = deletePermissions
        const excludedClientZonePermission = this.getExcludedClientOrZonePermissions(
          buyingSessionProduct,
          view
        )
        const channelPermissions = this.getChannelsPermissions(buyingSessionProduct, view)
        const clusterPermissions = this.getClustersPermissions(buyingSessionProduct)
        const dropsPermissions = this.getDropsPermissions(buyingSessionProduct, view)
        const salesPeriodPermissions = this.getSalePeriodTagsPermissions(
          buyingSessionProduct,
          view
        )
        const freeTagsPermissions = this.getFreeTagsPermissions(
          buyingSessionProduct,
          view
        )
        const excludedClustersPermissions =
          this.getExcludedClusterPermissions(buyingSessionProduct)
        const channels = this.getChannels(
          buyingSessionProduct,
          enumList,
          channelPermissions
        )
        let isWholeSaleChannelPresent = this.isWholeSaleChannelPresent(channels)

        /**
         * Map product tags
         */
        processedAttributes.push(
          this.getWholesalePrice(buyingSessionProduct, priceListData)
        )
        processedAttributes.push(
          this.getCentralSRPPrice(buyingSessionProduct, priceListData)
        )

        processedAttributes.push(
          this.getCentralRetailPrice(buyingSessionProduct, priceListData)
        )

        processedAttributes.push(this.getAssociatedLooks(originalProduct))
        processedAttributes.push(this.getExcludedClients(buyingSessionProduct))
        processedAttributes.push(this.getExcludedZones(buyingSessionProduct))
        processedAttributes.push(channels)
        processedAttributes.push(
          ...this.getClusters(
            buyingSessionProduct,
            clusterPermissions as any,
            selectedChannels
          )
        )
        isWholesaleChannel(selectedChannels) &&
          processedAttributes.push(
            this.getDrops(buyingSessionProduct, dropsPermissions.canDeleteDrop)
          )
        processedAttributes.push(
          this.getWholesaleFreeTags(
            buyingSessionProduct,
            freeTagsPermissions.canDeleteWholesaleFreeTags
          )
        )
        processedAttributes.push(
          this.getRetailFreeTags(
            buyingSessionProduct,
            freeTagsPermissions.canDeleteRetailFreeTags
          )
        )
        processedAttributes.push(
          ...this.getExcludedCluster(
            buyingSessionProduct,
            excludedClustersPermissions as any
          )
        )
        if (isRetailChannel(selectedChannels)) {
          processedAttributes.push(this.getRetailSalesPeriodTags(buyingSessionProduct))
        }

        const gridViewProduct = {
          productId: product.Id,
          isCore: buyingSessionProduct?.isCore as boolean,
          buyingSession: buyingSessionProduct.BuyingSession,
          isActive: originalProduct.Active,
          status: this.getProductStatus(originalProduct.Active),
          externalImages: originalProduct?.ExternalImages,
          imageURL: this.getProductDefaultImageURL(originalProduct),
          canDelete: false,
          isRestricted: false,
          [WholesaleGirdProductFieldMapping.NodeInfo]: this.getNodeInfo(
            originalProduct,
            enumList
          ),
          [WholesaleGirdProductFieldMapping.HasComments]:
            buyingSessionProduct.hasComments,
          [WholesaleGirdProductFieldMapping.Favorite]:
            this.getFavorite(buyingSessionProduct),
          [WholesaleGirdProductFieldMapping.AverageRating]:
            this.getAverageRating(buyingSessionProduct),
          [WholesaleGirdProductFieldMapping.Attributes]: processedAttributes,
          [WholesaleGirdProductFieldMapping.ClusterAssortmentProducts]:
            this.getClusterAssortmentProduct(buyingSessionProduct),
          onDelete: null,
          node: buyingSessionProduct,
          associatedLooks: this.getAssociatedLooksSorted(originalProduct),
          isWholeSaleChannelPresent,
          ...this.getCommonProductColumnData(
            buyingSessionProduct,
            originalProduct,
            enumList,
            priceListData
          ),
          ...dropsPermissions,
          ...channelPermissions,
          ...clusterPermissions,
          ...freeTagsPermissions,
          ...excludedClustersPermissions,
          ...salesPeriodPermissions,
          ...excludedClientZonePermission
        }
        const openDeleteAssortmentProductConfirmationOnClusterDelete =
          canDeleteClusterAssortmentProduct
            ? openDeleteAssortmentProductConfirmation(gridViewProduct)
            : null
        gridViewProduct.onDelete = openDeleteAssortmentProductConfirmationOnClusterDelete
        processedProducts.push(gridViewProduct)
      }
      return processedProducts
    },
    isEqual
  )

  /**
   * Method for converting product data received in response into the format that can be passed to product card
   * @params {products} - Object with list products
   * @params {enumList} - Object with list enum values specific to products
   * @returns {Array<IGridViewProduct>}
   */

  processProductsForLibrary = memoize(
    (
      products: Array<VIPBuyingSessionProduct>,
      enumList: Array<VIPProductEnum>,
      priceListData: IPricesMapWithList,
      clusterAssortmentProducts: Array<VIPAssortmentProduct>,
      clusterId: string
    ): Array<IGridViewProduct> => {
      const productAttributeList = getProductAttributeConfig(
        ProductAttributeSelectorEnum.isDefaultSelected
      )

      if (isEmpty(products) || isEmpty(enumList) || isEmpty(productAttributeList))
        return []

      /**
       * filter out the products which don't have given cluster, and don't have this cluster excluded.
       */
      products = filter(products, product => {
        return (
          every(
            product.clusterExclusion ?? [],
            excludedCluster => excludedCluster.Id !== clusterId
          ) &&
          !some(clusterAssortmentProducts, clusterAssortmentProduct => {
            return product?.Id === clusterAssortmentProduct?.Original?.Id
          })
        )
      })

      /**
       * Map product attributes
       */

      const processedProducts: Array<IGridViewProduct> = []
      for (let product of products) {
        const buyingSessionProduct = product
        const originalProduct = buyingSessionProduct.Original

        const processedAttributes: Array<IAttribute> = this.getProductAttributeList(
          buyingSessionProduct,
          enumList,
          true
        )

        /**
         * Map product tags
         */
        processedAttributes.push(
          this.getWholesalePrice(buyingSessionProduct, priceListData)
        )
        processedAttributes.push(
          this.getCentralSRPPrice(buyingSessionProduct, priceListData)
        )
        processedAttributes.push(
          this.getCentralRetailPrice(buyingSessionProduct, priceListData)
        )

        processedAttributes.push(
          ...this.getClusters(buyingSessionProduct, {
            canDeleteWholesaleClusters: false,
            canDeleteRetailClusters: false
          })
        )
        processedProducts.push({
          productId: product.Id,
          buyingSession: buyingSessionProduct.BuyingSession,
          isActive: originalProduct.Active,
          status: this.getProductStatus(originalProduct.Active),
          imageURL: this.getProductDefaultImageURL(originalProduct),
          canDelete: false,
          isRestricted: false,
          isCore: product?.isCore,
          [WholesaleGirdProductFieldMapping.NodeInfo]: this.getNodeInfo(
            originalProduct,
            enumList
          ),
          [WholesaleGirdProductFieldMapping.HasComments]:
            buyingSessionProduct.hasComments,
          [WholesaleGirdProductFieldMapping.Favorite]:
            this.getFavorite(buyingSessionProduct),
          [WholesaleGirdProductFieldMapping.AverageRating]:
            this.getAverageRating(buyingSessionProduct),
          [WholesaleGirdProductFieldMapping.Attributes]: processedAttributes,
          [WholesaleGirdProductFieldMapping.ClusterAssortmentProducts]:
            this.getClusterAssortmentProduct(buyingSessionProduct),
          onDelete: null,
          node: buyingSessionProduct
        })
      }

      return processedProducts
    },
    isEqual
  )

  isProductExcludedForDoorId = (
    allotments: Array<IVIPAllotment>,
    buyingSessionProduct: VIPBuyingSessionProduct,
    doorId?: string
  ) =>
    some(
      allotments,
      allotment =>
        allotment?.__Parent__?.Id === doorId &&
        some(
          buyingSessionProduct?.clusterExclusion,
          cluster => cluster?.Id === allotment?.Cluster?.Id
        ) &&
        allotment?.Gender?.Id === buyingSessionProduct?.Original?.Gender?.Id &&
        allotment?.Activity?.Id === buyingSessionProduct?.Original?.Activity?.Id
    )

  /**
   * Returns Buy tab column values
   */
  getBuyTabColumns = (product: VIPBuyingSessionProduct, valueMeta?: ValueMetadata) => {
    return {
      [ListViewFieldMapping.salesQty]: product?.salesQty || 0,
      [ListViewFieldMapping.value]: convertToCurrencyLocaleString(
        valueMeta?.targetCurrency,
        product?.value || 0
      ),
      [ListViewFieldMapping.sizedQty]: product?.sizedQty || 0,
      [ListViewFieldMapping.draftQty]: product?.draftQty || 0
    }
  }

  /**
   * Returns Retail Buy tab column values
   */
  getRetailBuyTabColumns = (
    productId: string,
    zoneId = '',
    retailBuyProducts,
    retailBuyComments
  ) => {
    let zones = retailBuyProducts && retailBuyProducts[productId]?.clients?.slice()
    zones = zoneId ? zones?.filter(zone => zoneId === zone?.clientId) : zones
    return (zones || [])
      .map(zone => ({
        buyingSessionProductId: productId,
        id: zone?.clientName === 'GLOBAL' ? productId : `${productId}_${zone?.clientId}`,
        type: zone?.clientName === 'GLOBAL' ? 'global' : 'zone',
        zone: zone?.clientName,
        zoneId: zone?.clientId,
        retailPrice: convertToCurrencyLocaleString(zone?.currency, +zone?.price),
        storeCount: zone?.numberOfStores || 0,
        betQty: zone?.forecastQuantity || 0,
        buyQty: zone?.orderQuantity || 0,
        aws: zone?.avgWeeklySales || 0,
        depth: zone?.avgDepth || 0,
        sku: zone?.skuEFF || 0,
        value: convertToCurrencyLocaleString(
          zone?.targetCurrency,
          +(zone?.orderValue || 0)
        ),
        salesQuantity: zone?.salesQuantity || 0,
        CM:
          (retailBuyComments && retailBuyComments[zone.clientId]?.[productId]?.CM) ?? '',
        RM: (retailBuyComments && retailBuyComments[zone.clientId]?.[productId]?.RM) ?? ''
      }))
      .sort((a, b) => a?.id?.localeCompare(b?.id, 'en', { numeric: true }))
  }

  /**
   * Returns list view friendly
   * set of chip list data
   */
  getTagChipsForListView = (tags: IAttribute) => {
    const chips = (tags?.value as Array<IChip>) ?? []
    return chips.map(chip => ({
      id: chip.id,
      title: chip.title,
      color: chip.color,
      tooltip: chip.tooltip,
      subtitle: chip.subtitle
    }))
  }

  /**
   * Returns product details column which are
   * being commonly used across all list views
   * @params {products} - Object with list products
   * @params {enumList} - Object with list enum values specific to products
   */
  getCommonProductColumnData = (
    product: VIPBuyingSessionProduct | VIPOrderProduct,
    originalProduct: any,
    enumList: Array<VIPProductEnum>,
    priceListData: IPricesMapWithList,
    orderProductPrice?: OrderProductLocalPrice
  ) => {
    const productInfo = this.getNodeInfo(product, enumList)
    // const excludedAttributes = isCardView ? ['StyleColorCode', 'Style'] : []
    const productAttributesForListView = sortBy(
      getProductAttributeConfig(null).filter(item => !isEmpty(item.listView)),
      item => item.listView.sortIndex
    )

    let attributes: Array<CoreProductAttribute> = this.getProductAttributeList(
      product,
      enumList,
      false,
      productAttributesForListView
    ).map(
      item =>
        ({
          label: '',
          value: item?.value,
          color: item?.color
        } as CoreProductAttribute)
    )

    // Product Price
    const wholesalePrice = this.getWholesalePrice(
      product,
      priceListData,
      orderProductPrice
    )
    const retailPrice = this.getCentralSRPPrice(product, priceListData, orderProductPrice)
    const sgSrp = this.getRetailRefPrice(product, priceListData, orderProductPrice)

    attributes = [
      ...attributes,
      {
        label: wholesalePrice.label,
        value: wholesalePrice.value
      },
      {
        label: retailPrice.label,
        value: retailPrice.value
      },
      {
        label: sgSrp.label,
        value: sgSrp.value
      }
    ]

    return {
      [ListViewFieldMapping.ProductInfo]: {
        status: originalProduct.Active ? '' : PRODUCT_STATUS.inactive,
        legend: productInfo.legend,
        description: productInfo.title,
        image: this.getProductDefaultImageURL(originalProduct)
      },
      [ListViewFieldMapping.ProductAttributes]: attributes
    }
  }

  getRMClusters = (zoneId, retailRMCluster, buyingSessionProduct) => {
    if (zoneId !== undefined) {
      return map(
        retailRMCluster?.[buyingSessionProduct.Id]?.clientMap?.[zoneId]?.clusters as any,
        cluster => ({
          id: cluster?.clusterId,
          title: cluster?.clusterName,
          subtitle: '',
          tooltip: cluster?.clusterName,
          color: cluster?.clusterColor
        })
      )
    }
  }

  /**
   * Method for converting product data received in response into the format that can be passed to product list
   * @params {products} - Object with list products
   * @params {enumList} - Object with list enum values specific to products
   * @returns {Array<ListViewProduct>}
   */
  processProductsForListViewData = memoize(
    (
      products: Array<VIPBuyingSessionProduct>,
      retailBuyProducts,
      retailBuyComments,
      retailRMCluster,
      enumList: Array<VIPProductEnum>,
      tab: ViewTabs,
      priceListData: IPricesMapWithList,
      view,
      valueMeta?: ValueMetadata,
      productForecastData?, // TODO: define an interface,
      rmClustersData?
    ): Array<ListViewProduct> => {
      if (isEmpty(products) || isEmpty(enumList)) return []
      const {
        wsBuyingSessionProduct: { openDeleteAssortmentProductConfirmation },
        nav: {
          queryParams: { subSection, zoneId }
        }
      } = stores
      const isRetailBuyTabSelected = tab === ViewTabs.RTL_BUY.toLocaleLowerCase()
      const isForecastTabSelected = tab === ViewTabs.FORECAST.toLocaleLowerCase()
      return products.map(product => {
        const buyingSessionProduct =
          view && !isRetailBuyTabSelected ? product.Original : product

        const originalProduct = buyingSessionProduct.Original
        const totalDoors = product?.totalDoors ?? 0
        const totalClients = product?.totalClients ?? 0
        const channelsPermissions = this.getChannelsPermissions(
          buyingSessionProduct,
          view
        )
        const channels: IAttribute = this.getChannels(
          buyingSessionProduct,
          enumList,
          channelsPermissions
        )
        const clusters = this.getClusters(
          buyingSessionProduct,
          {
            canDeleteWholesaleClusters: false,
            canDeleteRetailClusters: false
          },
          []
        )
        const retailFreeTags: IAttribute = this.getRetailFreeTags(buyingSessionProduct)
        const wholesaleFreeTags: IAttribute =
          this.getWholesaleFreeTags(buyingSessionProduct)
        const drops: IAttribute = this.getDrops(buyingSessionProduct)
        const excludedClients: IAttribute = this.getExcludedClients(buyingSessionProduct)
        const excludedZones: IAttribute = this.getExcludedZones(buyingSessionProduct)
        const salesPeriod: IAttribute =
          this.getRetailSalesPeriodTags(buyingSessionProduct)
        const permissions = this.clusterAssortmentProductPermission(view)
        const { canDeleteClusterAssortmentProduct } = permissions
        const canEditRetailRoles = hasUserRoles(
          PERMISSION_MAP.find(ele => ele.id === VIPChannel.Retail)?.allowedRoles
        )
        const zoneWiseData = isRetailBuyTabSelected
          ? this.getRetailBuyTabColumns(
              buyingSessionProduct?.Id,
              zoneId,
              retailBuyProducts,
              retailBuyComments
            )
          : this.getZoneWiseData(
              productForecastData?.productForecastData,
              buyingSessionProduct?.Id,
              productForecastData?.targetCurrency
            )

        const highlightGlobalQty = isForecastTabSelected
          ? this.shouldHighlightGlobalQty(zoneWiseData)
          : !view
          ? false
          : this.shouldHighlightGlobalQty(zoneWiseData)

        const listViewProduct = {
          id: buyingSessionProduct.Id,
          productId: product.Id,
          buyingSession: buyingSessionProduct.BuyingSession,
          canDelete: false,
          onDelete: null,
          upsertForecast: null,
          isRestricted: false,
          isActive: originalProduct.Active,
          isCore: buyingSessionProduct?.isCore,
          ...this.getCommonProductColumnData(
            buyingSessionProduct,
            originalProduct,
            enumList,
            priceListData
          ),
          ...permissions,
          [ListViewFieldMapping.RMClusters]: this.getRMClusters(
            zoneId,
            retailRMCluster,
            buyingSessionProduct
          ),
          [ListViewFieldMapping.TotalDoors]: totalDoors,
          [ListViewFieldMapping.TotalWeeks]:
            buyingSessionProduct[ProductCardAttributeEnum.retailSalesPeriod]
              ?.NumberOfWeeks ?? 'NA',
          [ListViewFieldMapping.TotalClients]: totalClients,
          [ListViewFieldMapping.Price]:
            this.getWholesalePrice(buyingSessionProduct, priceListData)?.value ?? '',
          ...(clusters?.length
            ? clusters.reduce(
                (acc, cluster) => ({
                  ...acc,
                  [cluster.name]: this.getTagChipsForListView(cluster)
                }),
                {}
              )
            : {}),
          [ListViewFieldMapping.ClusterAssortmentProducts]:
            this.getClusterAssortmentProduct(buyingSessionProduct),
          [ListViewFieldMapping.Channels]: this.getTagChipsForListView(channels),
          [ListViewFieldMapping.RetailFreeTags]:
            this.getTagChipsForListView(retailFreeTags),
          [ListViewFieldMapping.WholesaleFreeTags]:
            this.getTagChipsForListView(wholesaleFreeTags),
          [ListViewFieldMapping.ExcludedClients]:
            this.getTagChipsForListView(excludedClients),
          [ListViewFieldMapping.ExcludedZones]:
            this.getTagChipsForListView(excludedZones),
          [ListViewFieldMapping.Drops]: this.getTagChipsForListView(drops),
          [ListViewFieldMapping.SalesPeriod]: this.getTagChipsForListView(salesPeriod),
          ...this.getClustersPermissions(buyingSessionProduct),
          ...this.getFreeTagsPermissions(buyingSessionProduct, view),
          ...this.getSalePeriodTagsPermissions(buyingSessionProduct, view),
          ...this.getDropsPermissions(buyingSessionProduct, view),
          ...this.getExcludedClientOrZonePermissions(buyingSessionProduct, view),
          /*
           * Maintaining these inside attributes separately
           * to handle validation cases during drag-drop.
           */
          [ListViewFieldMapping.Attributes]: [
            channels,
            ...clusters,
            retailFreeTags,
            wholesaleFreeTags,
            drops
          ],
          hasGlobalRow: canEditRetailRoles,
          [ListViewFieldMapping.zoneWiseData]: zoneWiseData,
          canEditKPIs: !isRetailBuyTabSelected ?? canEditRetailRoles,
          canEditOrderQuantity: !isRetailBuyTabSelected ?? canEditRetailRoles,
          highlightGlobalQty,
          ...(tab === ViewTabs.BUY.toLocaleLowerCase() &&
            this.getBuyTabColumns(product, valueMeta)),
          ...(isRetailBuyTabSelected &&
            this.getRetailBuyTabColumns(
              buyingSessionProduct.Id,
              zoneId,
              retailBuyProducts,
              retailBuyComments
            )),
          hasCounterOffer: rmClustersData?.[buyingSessionProduct.Id]?.flagProduct,
          ...reduce(
            rmClustersData?.[buyingSessionProduct.Id]?.clientMap,
            (rmClusters, zoneInfo, zoneId) => {
              return (rmClusters = {
                ...rmClusters,
                [zoneId]: {
                  clusters: (zoneInfo.clusters || []).map(rmCluster => ({
                    id: rmCluster.clusterId,
                    title: rmCluster.clusterName,
                    color: rmCluster.clusterColor
                  })),
                  flagProductAtClient: zoneInfo?.flagProductAtClient
                }
              })
            },
            {}
          ),
          node: buyingSessionProduct,
          isWholeSaleChannelPresent: this.isWholeSaleChannelPresent(channels)
        }
        listViewProduct.upsertForecast = productForecastData?.upsertForecastData || null
        const openDeleteAssortmentProductConfirmationOnClusterDelete =
          canDeleteClusterAssortmentProduct
            ? openDeleteAssortmentProductConfirmation(listViewProduct)
            : null
        listViewProduct.onDelete =
          buyingSessionProduct?.isCore && subSection === ChannelSubSection.Retail
            ? null
            : openDeleteAssortmentProductConfirmationOnClusterDelete
        return listViewProduct
      })
    },
    isEqual
  )

  private getZoneWiseData = (
    productForecastData,
    buyingSessionProductId,
    targetCurrency
  ): Array<ProductKPIsByZone> => {
    const {
      nav: {
        queryParams: { zoneId }
      }
    } = stores

    let zones = (productForecastData || [])
      ?.find(product => product?.id === buyingSessionProductId)
      ?.clients?.slice() // return new arr

    // if assortment view, get only sepcific zone data
    zones = zoneId ? zones?.filter(zone => zoneId === zone?.id) : zones

    // GLOBAL should always be at the top GBB-7377
    // zones have to be sorted GBB-7757, response should be processed based on ids
    return (zones || [])
      .map(zone => ({
        buyingSessionProductId,
        // these are used in store map, they need to be unique across products and clients
        id:
          zone?.name === 'GLOBAL'
            ? buyingSessionProductId
            : `${buyingSessionProductId}_${zone?.id}`,
        type: zone?.name === 'GLOBAL' ? 'global' : 'zone',
        zone: zone?.name,
        aws: zone?.avgWeeklySales || 0,
        // response values are already in percentages, toLocaleString won't work
        mixQuantity: `${zone?.mixQuantity || 0}%`,
        mixValue: `${zone?.mixValue || 0}%`,
        depth: zone?.avgDepth || 0,
        sku: zone?.skuEFF || 0,
        storeCount: zone?.numberOfStores || 0,
        betQty: zone?.forecastQuantity || 0,
        value: convertToCurrencyLocaleString(
          targetCurrency || zone?.currency,
          +(zone?.forecastValue || 0)
        ),
        salesQuantity: zone?.salesQuantity || 0,
        retailPrice: convertToCurrencyLocaleString(zone?.currency, +zone?.price)
      }))
      .sort((a, b) => a?.id?.localeCompare(b?.id, 'en', { numeric: true }))
  }

  private shouldHighlightGlobalQty = (
    zoneWiseData: Array<ProductKPIsByZone>
  ): boolean => {
    const zoneRecords = filter(zoneWiseData, record => record.type === strings.zone)
    const globalRecord = find(zoneWiseData, data => data.type === strings.global)
    const globalQty = globalRecord ? globalRecord.betQty || 0 : 0

    let totalZoneBetQuantity = zoneRecords.reduce((acc, curr) => {
      acc += curr.betQty || 0
      return acc
    }, 0)
    return zoneRecords.length ? totalZoneBetQuantity !== globalQty : false
  }

  /**
   * Returns client assortment product
   * for given buying session product
   * @param bsProduct
   * @param clientAssortmentProducts
   */
  getClientAssortmentProductForBsProduct = (
    bsProduct: VIPBuyingSessionProduct,
    clientAssortmentProducts: Array<VIPAssortmentProduct>
  ) => {
    return clientAssortmentProducts.find(product => {
      return product.Original.Id === bsProduct.Id
    })
  }
}

export const wholeSaleProductService = new WholeSaleProductService()
