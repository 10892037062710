import { storage } from '@services/storageService'

const extractFileNameFromResponse = response => {
  const attachFile = response?.headers?.get('Content-Disposition') ?? null
  return attachFile ? attachFile?.split('filename=')[1]?.split(';')[0] : ''
}

/**
 * Get file URL and downlaod the files
 * @param {string} fileURL
 * @param {string} fileName
 */
export const downloadFile = (fileURL, fileName?, sessionUrl?) => {
  let downloadFileName = ''
  return fetchFileAPI(fileURL, sessionUrl)
    .then(response => {
      downloadFileName = fileName ? fileName : extractFileNameFromResponse(response)
      if (response.status !== 200) {
        return response.json()
      }
      return response.blob()
    })
    .then(response => {
      if (response && response.error) {
        return response
      }
      var url = window.URL.createObjectURL(response)
      var a = document.createElement('a')
      a.href = url
      a.download = downloadFileName
      a.click()
      a.remove()
      setTimeout(() => window.URL.revokeObjectURL(url), 100)
      return {
        error: false,
        statusCode: 200
      }
    })
}

export const fetchFileAPI = (fileURL, sessionUrl?) =>
  fetch(fileURL, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: sessionUrl
        ? sessionUrl
        : `Bearer ${storage.getItem<string>('access_token')}`,
      mode: 'no-cors'
    }
  })
