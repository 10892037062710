import cloneDeep from 'lodash/cloneDeep'
import find from 'lodash/find'
import { action, observable } from 'mobx'

export class StoreImportStore {
  @observable isDialogOpen = false
  @observable isCreateStoreClicked = false
  @observable name = ''
  @observable isCreateStoreDialogOpen = false
  @observable isEditClicked = false
  @observable selectedStoreId = null
  @observable openDeleteDialog = false
  @observable openMessageDialog = false

  @observable storeFields = {
    storeCode: '',
    name: '',
    zone: '',
    country: '',
    isActive: '',
    storeType: '',
    address: '',
    postalCode: '',
    city: ''
  }

  @action
  handleDeleteDialogOpen = selectedStoreId => {
    Object.assign(this, { selectedStoreId: selectedStoreId, openDeleteDialog: true })
  }

  @action
  handleDeleteDialogClose = () => {
    Object.assign(this, { openDeleteDialog: !this.openDeleteDialog })
  }
  @action
  handleMessageDialogOpen = () => {
    Object.assign(this, { openMessageDialog: true })
  }

  @action
  handleMessageDialogClose = () => {
    Object.assign(this, { openMessageDialog: !this.openMessageDialog })
  }

  @action
  toggleDialogOpen = () => {
    Object.assign(this, { isDialogOpen: !this.isDialogOpen })
  }

  @action
  toggleCreateStoreDialogOpen = () => {
    const { isCreateStoreDialogOpen } = this

    if (isCreateStoreDialogOpen) {
      this.clearStoreFields()
      Object.assign(this, { isEditClicked: false })
    }

    Object.assign(this, { isCreateStoreDialogOpen: !isCreateStoreDialogOpen })
  }

  @action
  handleCreateStore = () => {
    Object.assign(this, { isCreateStoreClicked: !this.isCreateStoreClicked })
  }

  @action
  handleChange = event => {
    const storeFields = cloneDeep(this.storeFields)
    storeFields[event.target.name] = event.target.value
    Object.assign(this, {
      storeFields: storeFields
    })
  }

  @action
  setCreatedCountry = createdCountry => {
    const storeFields = cloneDeep(this.storeFields)
    storeFields['country'] = createdCountry.id
    Object.assign(this, {
      storeFields
    })
  }

  @action
  editStore = (id, stores) => {
    const store = find(stores, store => store.id === id)
    let storeFields = {
      storeCode: store.storeCode,
      name: store.name,
      country: store.country.id,
      zone: store.zone.id,
      isActive: store.isActive,
      storeType: store.storeType.id,
      address: store.address,
      postalCode: store.postalCode,
      city: store.city
    }
    Object.assign(this, {
      isCreateStoreDialogOpen: !this.isCreateStoreDialogOpen,
      storeFields: storeFields,
      isEditClicked: !this.isEditClicked,
      selectedStoreId: id
    })
  }

  @action
  clearStoreFields = () => {
    let storeFields = {
      storeCode: '',
      name: '',
      country: '',
      zone: '',
      isActive: '',
      storeType: '',
      address: '',
      postalCode: '',
      city: ''
    }
    Object.assign(this, { storeFields: storeFields })
  }
}
