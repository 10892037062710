import { withApollo } from '@apollo/client/react/hoc/withApollo'
import { StyledProps } from '@components/3rd-party/mui'
import { ConfirmationDialog } from '@components/UI-Components/ConfirmationDialog'
import { MessageDialog } from '@components/UI-Components/MessageDialog'
import { PrimaryButton } from '@components/UI-Components/PrimaryButton'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { Paper } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { withStyles } from '@material-ui/core/styles'
import { apolloClient, stores as globalStores } from '@stores'
import { observer } from 'mobx-react'
import React from 'react'
import { GET_STORES, REMOVE_STORE } from '../graphql'

const styles = theme => ({
  root: {
    margin: theme.spacing(3),
    overflowX: 'auto'
  },
  table: {
    minWidth: 900
  },
  textField: {
    width: 80
  },
  icon: {
    margin: theme.spacing(1),
    fontSize: 32,
    cursor: 'pointer'
  },
  action: {
    display: 'flex'
  },
  actionCloumn: {
    paddingLeft: 0
  },
  button: {
    margin: theme.spacing(1, 0, 2, 3)
  },
  countryHeaderCell: {
    paddingRight: '27px'
  }
})

interface IStore extends StyledProps {
  stores: any
  zones?: any
  countries?: any
  categories?: any
  client?: any
}

@((withStyles as any)(styles))
@(withApollo as any)
@observer
export class Store extends React.Component<IStore> {
  removeStore = async () => {
    const [
      { stores },
      {
        storeImport: { handleDeleteDialogClose, selectedStoreId, handleMessageDialogOpen }
      }
    ] = [this.props, globalStores]

    handleDeleteDialogClose()

    if (stores.find(s => s.id === selectedStoreId).clusters.length) {
      handleMessageDialogOpen()
    } else {
      try {
        const {
          data: { store }
        } = await apolloClient.mutate({
          mutation: REMOVE_STORE,
          variables: {
            storeId: selectedStoreId
          }
        })

        const { stores } = apolloClient.readQuery({ query: GET_STORES })

        const remainingStores = stores.filter(({ id }) => id !== store.id)

        apolloClient.writeQuery({
          query: GET_STORES,
          data: {
            stores: remainingStores
          }
        })
      } catch (err) {
        console.log(err)
      }
    }
  }

  render() {
    const [
      {
        props: { stores, classes },
        removeStore
      },
      {
        storeImport: {
          toggleCreateStoreDialogOpen,
          editStore,
          handleDeleteDialogOpen,
          handleDeleteDialogClose,
          handleMessageDialogClose,
          openDeleteDialog,
          openMessageDialog
        }
      }
    ] = [this, globalStores]

    return (
      <React.Fragment>
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Code</TableCell>
                <TableCell>Name</TableCell>
                <TableCell className={classes.countryHeaderCell}>
                  Country / Region
                </TableCell>
                <TableCell>Zone</TableCell>
                <TableCell>Active</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>Zip Code</TableCell>
                <TableCell>City</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {stores.map(
                ({
                  id,
                  name,
                  storeCode,
                  isActive,
                  address,
                  postalCode,
                  city,
                  country: { name: countryName },
                  zone: { name: zoneName },
                  storeType: { name: type }
                }) => (
                  <TableRow key={id}>
                    <TableCell>{storeCode}</TableCell>
                    <TableCell>{name}</TableCell>
                    <TableCell>{countryName}</TableCell>
                    <TableCell>{zoneName}</TableCell>
                    <TableCell>{isActive ? 'Yes' : 'No'}</TableCell>
                    <TableCell>{type}</TableCell>
                    <TableCell>{address}</TableCell>
                    <TableCell>{postalCode}</TableCell>
                    <TableCell>{city}</TableCell>
                    <TableCell className={classes.actionCloumn}>
                      <div className={classes.action}>
                        <EditIcon
                          className={classes.icon}
                          onClick={() => editStore(id, stores)}
                        />
                        <DeleteIcon
                          className={classes.icon}
                          onClick={() => handleDeleteDialogOpen(id)}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </Paper>
        <PrimaryButton className={classes.button} onClick={toggleCreateStoreDialogOpen}>
          Create New Store
        </PrimaryButton>
        {openDeleteDialog && (
          <ConfirmationDialog
            open={openDeleteDialog}
            title={'Delete Store'}
            message={'Are you sure you want to delete this store ?'}
            handleClose={handleDeleteDialogClose}
            handleDelete={removeStore}
          />
        )}

        <MessageDialog
          open={openMessageDialog}
          title={'Delete Store'}
          message={
            'This store cannot be deleted because it is referenced by other items.'
          }
          handleClose={handleMessageDialogClose}
        />
      </React.Fragment>
    )
  }
}
