import { StyledProps } from '@components/3rd-party/mui'
import { PrimaryButton } from '@components/UI-Components/PrimaryButton'
import { SelectFileButton } from '@components/UI-Components/SelectFileButton'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'

const styles = theme => ({
  fileUploadWrapper: {
    minWidth: '30vw',
    maxWidth: 480,
    marginTop: theme.spacing(2)
  },
  uploadItem: {
    margin: theme.spacing(1, 0)
  },
  error: {
    color: theme.colors.error
  }
})

interface IProductUpload extends StyledProps {
  uploadInProgress: boolean
  productDataSheet: any
  productDataSheetUploaded: boolean
  productDataSheetUploadError: string
  productImages: any
  productImagesUploaded: boolean
  productImagesUploadError: string
  productDataSheetUploadProgress: boolean
  productImagesUploadProgress: boolean
  uploadDone: boolean
  handleSubmit: () => void
  handleChanges: (any, string) => void
}

@((withStyles as any)(styles))
export class ImportBuyingSessionData extends React.Component<IProductUpload> {
  render() {
    const {
      props: {
        classes,
        handleSubmit,
        handleChanges,
        uploadInProgress,
        productDataSheetUploadError,
        productImagesUploadError,
        productDataSheetUploadProgress,
        productImagesUploadProgress,
        uploadDone
      }
    } = this

    return (
      <div className={classes.fileUploadWrapper}>
        <Typography variant='subtitle2'>Upload collection files</Typography>
        <Typography variant='caption'>
          Product data and product images from file browser
        </Typography>

        <div>
          <div className={classes.uploadItem}>
            <SelectFileButton
              accept='application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              label='Products (*.xlsx)'
              disabled={productDataSheetUploadProgress}
              loading={productDataSheetUploadProgress}
              handleChange={file => handleChanges(file, 'productDataSheet')}
            />
            {productDataSheetUploadError ? (
              <Typography className={classes.error}>
                {' '}
                {productDataSheetUploadError}{' '}
              </Typography>
            ) : null}
          </div>

          <div className={classes.uploadItem}>
            <SelectFileButton
              accept='.zip'
              label='Images (*.zip)'
              loading={productImagesUploadProgress}
              disabled={productImagesUploadProgress}
              handleChange={file => handleChanges(file, 'productImages')}
            />
            {productImagesUploadError ? (
              <Typography className={classes.error}>
                {' '}
                {productImagesUploadError}{' '}
              </Typography>
            ) : null}
          </div>
        </div>

        {uploadDone ? (
          <Typography>Files Uploaded Successfully!</Typography>
        ) : (
          <PrimaryButton onClick={handleSubmit} loading={uploadInProgress}>
            {uploadInProgress ? 'Uploading Files...' : 'Upload Files'}
          </PrimaryButton>
        )}
      </div>
    )
  }
}
