import React from 'react'
import { toast } from 'react-toastify'

interface MyProps {
  error: any
}

export class ErrorNotifier extends React.Component<MyProps> {
  componentDidUpdate(nextProps) {
    if (nextProps.error && nextProps.error !== '') {
      toast.error(nextProps.error, {
        position: toast.POSITION.BOTTOM_RIGHT,
        bodyClassName: 'error-toastr',
        progressClassName: 'error-progress-bar-toaster'
      })
    }
  }

  render() {
    return null
  }
}
