import { useObserver } from 'mobx-react-lite'
import { Collapsible } from '@components/std-components/Collapsible'
import Typography from '@components/std-components/Typography'
import { stores, strings } from '@stores'

import * as css from './SAProductDetailPanel.css'
import { useSAProductDetailsContext } from '../../hook/SAProductDetails-Hook'
import React, { useEffect } from 'react'

interface ISAProductDetailPanel {
  panelAttributes: any
}

export const SAProductDetailPanel: React.FC<ISAProductDetailPanel> = props => {
  return useObserver(function useHook() {
    // const { panelAttributes } = props
    const {
      saProductDetailsStore: { detailedPanelData }
    } = useSAProductDetailsContext()

    const {
      showroomProductDetailStore: { isShowMoreAttributesOpen, setShowMoreAttributesOpen },
      nav: {
        queryParams: { bspId }
      }
    } = stores
    useEffect(() => {
      setShowMoreAttributesOpen(false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bspId])

    return (
      <div className={css.collapsibleContainer}>
        <Collapsible
          isOpen={isShowMoreAttributesOpen}
          title={strings.seeAllAttr}
          onClick={() => setShowMoreAttributesOpen(!isShowMoreAttributesOpen)}
        >
          <div className={css.panel}>
            {detailedPanelData?.map(({ label, value }, index) => {
              return (
                <div key={label} className={css.panelItem}>
                  <Typography variant={'h3'}>{label}</Typography>
                  <Typography variant={'h1'}>{value || strings.notAvailable}</Typography>
                </div>
              )
            })}
          </div>
        </Collapsible>
      </div>
    )
  })
}
