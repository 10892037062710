import { config } from '@config'
import { Image } from '@modules/common/models'
import { DefaultImage } from '@resources/images'
import { storage } from '@services/storageService'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import max from 'lodash/max'
import memoize from 'memoize-one'

/**
 * Returns image url for imageId
 *
 * @param {string} imageId
 * @param {string} type
 * @returns {string}
 */
export const getImageUrl = (imageId, type = 'small') => {
  return imageId
    ? `${config.imagesEndpoint}images/${imageId}/?type=${type}&token=${storage.getItem(
        'access_token'
      )}`
    : DefaultImage
}

/**
 * getDefaultImage
 * Get default image from centric standard list
 * of images
 * @param images
 */
export const getDefaultImage = memoize((images: Array<Image>) => {
  if (isEmpty(images)) return undefined

  const defaultImage = images.find(image => {
    return isEmpty(image.Name)
  })

  return defaultImage
}, isEqual)

/**
 * getDefaultImageNameWithId
 * Get default image Name and Id value from default image and centric standard list
 * of images
 * @param defaultImage
 * @param images
 */
export const getDefaultImageNameWithId = memoize(
  (defaultImage: Image, images: Array<Image>) => {
    if (!defaultImage?.Value?.Id) return undefined

    const defaultImageWithName = images.find(
      image => !isEmpty(image.Name) && image.Value.Id === defaultImage.Value.Id
    )

    return defaultImageWithName
  },
  isEqual
)

/**
 * getImageUploadIndex
 * Returns the passible new index for image upload
 * @param images
 */
export const getImageUploadIndex = memoize(images => {
  if (isEmpty(images)) return 1
  const keyList = images.map(image => {
    return !!parseInt(image.Name, 10) ? parseInt(image.Name, 10) : null
  })

  const newIndex: number = keyList && keyList.length ? max(keyList) : 0
  return newIndex + 1
}, isEqual)

export const IMAGE_MIME_TYPES = [
  'image/png',
  'image/gif',
  'image/bmp',
  'image/jpg',
  'image/jpeg'
]
