import StartRating from '@components/std-components/Rating'
import Typography from '@components/std-components/Typography'
import IosStar from '@meronex/icons/ios/IosStar'
import { useUpdateBuyingSessionProductRating } from '@modules/wholesale/productDetail/graphql/hooks'
import { stores, strings } from '@stores'
import cn from 'classnames'
import { useObserver } from 'mobx-react-lite'
import React from 'react'
import { useSAProductDetailsContext } from '../../hook/SAProductDetails-Hook'
import * as css from './SAProductDetailRating.css'

interface ISAProductDetailRating {
  canEditRating?: boolean
}

export const SAProductDetailRating: React.FC<ISAProductDetailRating> = props => {
  return useObserver(function useHook() {
    const { canEditRating } = props
    const {
      productDetail: { mutations }
    } = stores
    const {
      saProductDetailsStore: {
        isBuyingSessionProductExtraInformationLoading,
        product,
        updateUIRating
      }
    } = useSAProductDetailsContext()

    mutations.updateBuyingSessionProductRating =
      useUpdateBuyingSessionProductRating() as any

    return (
      <>
        {isBuyingSessionProductExtraInformationLoading ? (
          <div>Loading...</div>
        ) : (
          <>
            <Typography variant={'h2'}>{strings.ratingTitle}</Typography>
            {product.ratings?.ratingWithTypes?.length ? (
              <>
                {product?.ratings?.ratingWithTypes?.map(
                  ({ id, ratingType, value }, index) => {
                    return (
                      <div className={css.productDetailRating} key={id || index}>
                        <div className={css.productDetailRatingName}>
                          <Typography variant={'h3'}>{ratingType?.name}</Typography>
                        </div>
                        <div className={css.productDetailRatingValue}>
                          <StartRating
                            rating={value}
                            stop={9}
                            readonly={!canEditRating || !product?.original?.active}
                            onChangeRating={newRating =>
                              updateUIRating(id, newRating.toString(), ratingType?.id)
                            }
                            onUnselectRating={() =>
                              updateUIRating(id, '0', ratingType?.id)
                            }
                          />
                        </div>
                      </div>
                    )
                  }
                )}
                <div className={css.productDetailRating}>
                  <div className={css.productDetailRatingName}>
                    <Typography variant={'h3'}>{strings.overallRatingTitle}</Typography>
                  </div>
                  <div
                    className={cn(
                      css.productDetailRatingAvg,
                      css.productDetailRatingValue
                    )}
                  >
                    {' '}
                    <IosStar size={28} style={{ margin: '8px' }} />
                    <Typography variant={'h3'}>
                      {product.ratings?.averageRating.toFixed(2)}
                    </Typography>
                  </div>
                </div>
              </>
            ) : (
              <Typography variant={'h3'}>{strings.notAvailable}</Typography>
            )}
          </>
        )}
      </>
    )
  })
}

SAProductDetailRating.defaultProps = {
  canEditRating: false
}
