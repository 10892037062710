import { StyledProps } from '@components/3rd-party/mui'
import { SAListViewProduct } from '@modules/common/models/interfaces/SAListViewProduct'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import map from 'lodash/map'
import React from 'react'
import classNames from 'classnames'
import * as css from './SASelectBookUnbookAll.css'

interface ISAQuantity extends StyledProps {
  style?: any
  items?: any
  title?: string
  onChange?: any
  isDisabled?: boolean
  product?: SAListViewProduct
}

export const SASelectBookUnbookAll: React.FunctionComponent<ISAQuantity> = ({
  items = [],
  title,
  isDisabled,
  onChange,
  product
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = value => event => {
    setAnchorEl(null)
    if (value) {
      onChange(product, value)
    }
  }

  return (
    <div className={css.container}>
      <Typography
        className={classNames({
          [css.label]: true,
          [css.disabled]: isDisabled
        })}
        onClick={handleClick}
      >
        {title}
      </Typography>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose(null)}
      >
        {map(items, (item, index) => {
          return (
            <MenuItem
              disabled={item.isDisabled || isDisabled}
              onClick={handleClose(item.value)}
              key={index}
            >
              <div>
                <div>
                  <Typography>{item.label}</Typography>
                </div>
                <div>
                  <span className={css.warning}>{item.warningMessage}</span>
                </div>
              </div>
            </MenuItem>
          )
        })}
      </Menu>
    </div>
  )
}
