/*
 * @Author: Raeesaa Metkari
 * @Date: 2020-04-10 17:06:22
 * @Last Modified by: Raeesaa Metkari
 * @Last Modified time: 2020-08-04 10:16:37
 */
import { FetchPolicy, gql } from '@apollo/client'
import { config } from '@config'
import { ACTIVITY_HIERARCHY_MAP, DEFAULT_ACTIVITY_HIERARCHY } from '@constants'
import {
  BuyingSessionAssortmentSection,
  BuyingSessionAssortmentType,
  BuyingSessionGroupNavView,
  ChannelSubSection
} from '@modules/common/models/enums/BSGroupAndBSNav'
import { ListViewFieldMapping } from '@modules/common/models/enums/ListViewFieldMapping'
import {
  ImageAttributes,
  Modules,
  Operations
} from '@modules/common/models/enums/NodeAttributes'
import { AttributeValueType } from '@modules/common/models/enums/ProductAttributeValueType'
import { ProductCardAttributeEnum } from '@modules/common/models/enums/ProductCardAttributeEnum'
import { ProductTypeForSearchFilterEnum } from '@modules/common/models/enums/SearchFilterEnums'
import { UserRoles } from '@modules/common/models/enums/UserRoles'
import { ViewTabs } from '@modules/common/models/enums/Views'
import { VIPChannel } from '@modules/common/models/enums/VIPChannel'
import { WholesaleGirdProductFieldMapping } from '@modules/common/models/enums/WholesaleGirdProductFieldMapping'
import { IGridViewProduct } from '@modules/common/models/interfaces/WholesaleGridProduct'
import { CriteriaOperationType } from '@modules/models'
import { WS_CLIENT_ASSORTMENT_PRODUCTS } from '@modules/wholesale/assortmentProductWrapper/graphql/getClientAssortmentProducts'
import { GET_CLIENT_ASSORTMENT_SUMMARY } from '@modules/wholesale/assortmentProductWrapper/graphql/getClientAssortmentProductsSummary'
import { WS_CLUSTER_ASSORTMENT_PRODUCTS } from '@modules/wholesale/assortmentProductWrapper/graphql/getClusterAssortmentProducts'
import { GET_CLUSTER_ASSORTMENT_SUMMARY } from '@modules/wholesale/assortmentProductWrapper/graphql/getClusterAssortmentProductsSummary'
import { WS_DOOR_ASSORTMENT_PRODUCTS } from '@modules/wholesale/assortmentProductWrapper/graphql/getDoorAssortmentProducts'
import { GET_DOOR_ASSORTMENT_SUMMARY } from '@modules/wholesale/assortmentProductWrapper/graphql/getDoorAssortmentProductsSummary'
import { BuyTargetSync } from '@modules/wholesale/buyingSessionGroupProductWrapper/services/buyTabService'
import { productForecastUIStore } from '@modules/wholesale/buyingSessionGroupProductWrapper/stores/productForecastUIStore'
import { FilterKeys } from '@modules/wholesale/FilterModal/enums/FilterEnums'
import { IFilter, ISelectedFilter } from '@modules/wholesale/FilterModal/interface/Filter'
import { GET_BUYING_SESSION_PRODUCT_DETAILS } from '@modules/wholesale/productDetail/graphql/findBuyingSessionProductDetails'
import { REMOVE_CLIENT_FROM_BUYING_SESSION_PRODUCT_EXCLUSION } from '@modules/wholesale/productDetail/graphql/removeClientFromBuyingSessionProductExclusion'
import { isBuyingSessionRestrictedForTag } from '@modules/wholesale/wsNavigations/constants/Tags'
import { getHighestUser } from '@routes'
import CommentService from '@services/commentService'
import { getMediaCentricURI, getViewTabsToRender, isZAM } from '@services/commonServices'
import { getDefaultImage } from '@services/imageService'
import { storage } from '@services/storageService'
import { hasUserRoles, PERMISSION_MAP } from '@services/userRoleService'
import { IDoorOrder } from '@services/wholesale/OrderManagementService'
import { VIPBuyingSessionProduct } from '@services/wholesale/productService'
import {
  VIPOrderProduct,
  wholesaleCollectionService
} from '@services/wholesale/wholesaleCollectionService'
import { collectionKpiViewDefinitionService } from '@services/wholesale/wsCollectionKpiViewDefinitionService'
import { collectionListViewDefinitionService } from '@services/wholesale/wsCollectionListViewDefinitionService'
import { apolloClient, nav, stores, strings } from '@stores'
import axios from 'axios'
import capitalize from 'lodash/capitalize'
import cloneDeep from 'lodash/cloneDeep'
import concat from 'lodash/concat'
import endsWith from 'lodash/endsWith'
import every from 'lodash/every'
import filter from 'lodash/filter'
import find from 'lodash/find'
import forEach from 'lodash/forEach'
import groupBy from 'lodash/groupBy'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import keys from 'lodash/keys'
import map from 'lodash/map'
import remove from 'lodash/remove'
import some from 'lodash/some'
import toNumber from 'lodash/toNumber'
import memoize from 'memoize-one'
import { action, computed, observable } from 'mobx'
import { computedFn } from 'mobx-utils'
import * as qs from 'query-string'
import { ADD_CHANNEL_TO_BUYING_SESSION_PRODUCT } from '../graphql/addChannelToBuyingSessionProduct'
import { ADD_DROP_TO_BUYING_SESSION_PRODUCT } from '../graphql/addDropToBuyingSessionProduct'
import { WS_ADD_FREE_TAG_TO_BUYING_SESSION_PRODUCT } from '../graphql/addFreeTagToBuyingSessionProduct'
import { ADD_SALES_PERIOD_TO_BUYING_SESSION_PRODUCT } from '../graphql/addSalesPeriodToBuyingSessionProduct'
import { WS_BUYING_SESSION_PRODUCTS } from '../graphql/buyingSessionProducts'
import { GET_BUYING_SESSION_PRODUCT } from '../graphql/getBuyingSessionPrduct'
import { REMOVE_CHANNEL_FROM_BUYING_SESSION_PRODUCT } from '../graphql/removeChannelFromBuyingSessionProduct'
import { REMOVE_CLUSTER_FROM_BUYING_SESSION_PRODUCT_EXCLUSION } from '../graphql/removeClusterFromBSPExclusion'
import { REMOVE_DROP_FROM_BUYING_SESSION_PRODUCT } from '../graphql/removeDropFromBuyingSessionProduct'
import { WS_REMOVE_FREE_TAG_TO_BUYING_SESSION_PRODUCT } from '../graphql/removeFreeTagFromBuyingSessionProduct'
import { REMOVE_SALES_PERIOD_FROM_BUYING_SESSION_PRODUCT } from '../graphql/removeSalesPeriodFromBuyingSessionProduct'
import { WS_TOGGLE_FAVORITE_FLAG_FOR_BS_PRODUCT } from '../graphql/toggleFavoriteFlagForBuyingSessionProduct'
import UPDATE_CLUSTER_ASSORTMENT_PRODUCT_STATUS from '../graphql/upsertClusterStatus'
import { CommonService } from '../services/commonService'
import { ProductForecastKPIsService } from '../services/productKPIsService'
import { counterOfferUIStore } from './CounterOffer/CounterOfferUIStore'

export class WSBuyingSessionProductStore {
  private syncBuyTargetService = new BuyTargetSync()
  private commonService = new CommonService()
  private commentService = new CommentService()
  @observable openMessageDialog = false
  @observable displayMessage = ''
  @observable openConfirmationDialog = false
  @observable isDeleteInProgress = false
  @observable displayTitle = ''
  @observable buyingSessionProductId = ''
  @observable tagId = ''
  @observable showConfirmationDeleteClusterAssortmentProduct = false
  @observable isMutationInProgress = false
  @observable selectedClusterAssortmentProduct = {}
  @observable selectedClusterAssortmentId = null
  @observable onDeleteConfirmationClick = null

  @observable bulkOpSelectedProducts = []
  @observable isBulkOpPopupDisplayed = false
  @observable bulkOpSelectedTab = strings.tagTypes.channel
  @observable bulkOpSelectedEntities = {}
  @observable bulkOpProductsCountWithoutWholesaleChannel = 0
  @observable bulkOpProductsCountFromClosedBS = 0
  @observable bulkOpCoreProductsCount = 0
  @observable freeTagsEnums = []
  @observable isSyncInProcess = false
  @observable products = null
  @observable showConfirmationPopUpOnSync = false
  @observable areProductsAvaliable = false
  @observable bulkOpProductsCountWithoutRetailChannel = 0
  @observable bsgData = null

  @observable productForecastKPIData = []
  @observable productBuyKPIData = []
  @observable wholesaleBuyProducts = null
  @observable retailBuyProducts = null
  @observable retailRMClusters = null
  @observable retailBuyComments = null
  @observable isKPIDataLoading = false

  @observable.ref clusterAndEle = null
  query: any = {}

  @observable.shallow pendingJobs = []

  @computed get isAssortmentCreationInProgress() {
    return !!this.pendingJobs?.length
  }

  @action updateBSGData = data => {
    this.bsgData = data
  }

  updateProductsRefetchQuery = query => {
    this.query.productsRefetchQuery = query
  }

  @action
  setPendingJobs = jobs => {
    this.pendingJobs = jobs
  }
  /**
   * Handles all tag delete operations from collection list view.
   * @param product buyingSession Product
   * @param tagId chip id
   * @param tagType type of chip
   */
  @action
  handleTagDelete = (product, tagId, tagType) => {
    switch (tagType) {
      case ListViewFieldMapping.WholesaleClusters:
      case ListViewFieldMapping.RetailClusters: {
        this.deleteClusterFromBuyingSessionProduct(product, tagId)
        break
      }
      case ListViewFieldMapping.RetailFreeTags:
      case ListViewFieldMapping.WholesaleFreeTags: {
        this.removeFreeTagFromBuyingSessionProduct(product, tagId)
        break
      }
      case ListViewFieldMapping.Drops: {
        this.showDropDeleteConfirmationDialog(product)
        break
      }
      case ListViewFieldMapping.Channels: {
        this.showDeleteConfirmationDialog(product, tagId)
        break
      }
      case ListViewFieldMapping.ExcludedZones: {
        this.removeZoneExclusionFromBuyingSessionProduct(product, tagId)
        break
      }
      case ListViewFieldMapping.SalesPeriod: {
        this.removeSalesPeriodFromBuyingSessionProduct(product)
        break
      }
      case ProductCardAttributeEnum.wholesaleClusterExclusion: {
        this.removeClusterFromExcludedClusters(product, tagId)
      }
    }
  }

  @action
  handleTagClick = (product, tagId, tagType, event) => {
    switch (tagType) {
      case ListViewFieldMapping.RetailClusters: {
        this.showClusterStatuses(product, tagId, event.target)
        break
      }
      default:
    }
  }

  @action
  showClusterStatuses = (product, tagId, element) => {
    this.buyingSessionProductId = product?.id
    const assortmentProduct = find(product?.clusterAssortmentProducts, [
      'assortment.clusters.Id',
      tagId
    ])
    this.clusterAndEle = {
      tagId,
      element,
      currentStatus: assortmentProduct?.clusterStatus,
      assortmentProductId: assortmentProduct?.Id
    }
  }

  @action
  upsertBSPClusterStatus = async (
    assortmentProduct: string,
    ClusterStatus: { Id: string; Name: string },
    clusterId: string
  ) => {
    const bulkTabEntity = this.bulkOpSelectedEntities?.[this.bulkOpSelectedTab]?.find(
      entity => entity?.Id === clusterId
    )
    if (bulkTabEntity) {
      bulkTabEntity.clusterStatus = ClusterStatus.Name
      bulkTabEntity.clusterStatusId = ClusterStatus.Id
    }
    if (assortmentProduct) {
      try {
        await apolloClient.mutate({
          mutation: UPDATE_CLUSTER_ASSORTMENT_PRODUCT_STATUS,
          variables: {
            assortmentProduct,
            clusterStatus: ClusterStatus.Id
          },
          update: (cache, result) => {
            apolloClient.writeFragment({
              id: `VIPClusterAssortmentProduct:${assortmentProduct}`,
              fragment: gql`
                fragment VIPClusterAssortmentProductClusterStatus on VIPClusterAssortmentProduct {
                  ClusterStatus {
                    Id
                    Name
                  }
                }
              `,
              data: {
                ClusterStatus
              }
            })
          }
        })
      } catch (e) {}
    }
  }

  @action
  setRefetch = refetch => {
    this.products = refetch
  }

  @action
  setShowConfirmationPopUpOnSync = () => () => {
    this.showConfirmationPopUpOnSync = true
  }

  @action
  handleCloseConfirmPopupSync = () => () => {
    this.showConfirmationPopUpOnSync = false
  }

  @action
  setBuyingSessionPresent = productsAvaliable => {
    this.areProductsAvaliable = productsAvaliable
  }

  @computed get whereInputForBSPSummaryForGroup() {
    const [
      {
        queryParams: { buyingSessionGroupId }
      }
    ] = [nav]

    const isZAMUserLoggedIn = isZAM()

    const where = {
      __Parent__: {
        __Parent__: {
          Id: { operation: CriteriaOperationType.EQ, value: buyingSessionGroupId }
        }
      },
      ...(isZAMUserLoggedIn && {
        Channels: this.whereInputForChannels()
      })
    }
    return where
  }

  @action setFreeTagsEnums = freeTagsEnums =>
    Object.assign(this, {
      freeTagsEnums: filter(
        freeTagsEnums,
        freeTagsEnum => freeTagsEnum?.Description?.length
      )
    })

  filterProductsBasedOnFilterAndSearch = memoize(
    (
      products,
      searchKey: string,
      wsAppliedFilters: ISelectedFilter,
      productType: ProductTypeForSearchFilterEnum = ProductTypeForSearchFilterEnum.buyingSessionProduct,
      doorOrders: IDoorOrder[] = []
    ): VIPBuyingSessionProduct[] => {
      const {
        queryParams: { section, subview, tab }
      } = nav
      const { rmClustersData } = counterOfferUIStore
      const productsClone = cloneDeep(products)
      productsClone.forEach(product => {
        // Cluster Exclusion
        let excludedClusters = product?.clusterExclusion ?? []
        const excludedClustersByChannel = groupBy(excludedClusters, 'channel')
        product.clusterExclusion = excludedClustersByChannel?.[VIPChannel.Wholesale] ?? []
        product.retailClusterExclusion =
          excludedClustersByChannel?.[VIPChannel.Retail] ?? []
        const productId =
          (section === BuyingSessionAssortmentSection.clientAssortment ||
            section === BuyingSessionAssortmentSection.doorAssortment) &&
          tab !== ViewTabs.RTL_BUY.toLowerCase()
            ? product?.Original?.Id
            : product?.Id
        if (
          // BuyingSessionGroupNavView.Collection === view &&
          BuyingSessionGroupNavView.Overview !== subview &&
          hasUserRoles(
            PERMISSION_MAP.find(ele => VIPChannel.Retail === ele.id).allowedRoles
          )
        ) {
          product.hasCounterOffer = rmClustersData?.[productId]?.flagProduct
          // temp fix for assortmnet views
          product.Original.hasCounterOffer = rmClustersData?.[productId]?.flagProduct
        }
        if (this.wholesaleBuyProducts) {
          const buyData = this.wholesaleBuyProducts[productId]
          product.draftQty = buyData?.inProcessQuantity ?? 0
          product.sizedQty = buyData?.confirmedQuantity ?? 0
          product.value = buyData?.globalTotalValue ?? 0
          product.salesQty = buyData?.totalQuantity ?? 0
        }
        if (this.retailBuyProducts) {
          const zones = this.retailBuyProducts[productId]?.clients?.slice()
          let hasOrders = 0
          forEach(zones, zone => (hasOrders += zone.numberOfOrders))
          product.hasOrders = !!hasOrders
        }
      })
      return this.filterProductsBasedOnFilters(
        this.filterProductsBasedOnSearch(
          this.filterProductsBasedOnExcludedClients(productsClone, productType),
          searchKey,
          productType
        ),
        wsAppliedFilters,
        doorOrders
      )
    },
    isEqual
  )

  filterProductsBasedOnExcludedClients = (
    products,
    productType: ProductTypeForSearchFilterEnum = ProductTypeForSearchFilterEnum.buyingSessionProduct
  ): VIPBuyingSessionProduct[] => {
    const [
      {
        queryParams: { clientId, orderType }
      }
    ] = [nav]
    return orderType
      ? filter(products, product => {
          let buyingSessionProduct
          if (productType === ProductTypeForSearchFilterEnum.assortmentProduct) {
            buyingSessionProduct = product.Original
          } else if (productType === ProductTypeForSearchFilterEnum.orderProduct) {
            buyingSessionProduct = {
              Original: product.Original,
              ...(product.BuyingSessionProduct ?? {})
            }
          } else {
            buyingSessionProduct = product
          }
          return !find(
            buyingSessionProduct?.clientExclusion,
            client => client.Id === clientId
          )
        })
      : products
  }

  filterProductsBasedOnSearch = (
    products,
    searchKeyFromStore: string,
    productType: ProductTypeForSearchFilterEnum = ProductTypeForSearchFilterEnum.buyingSessionProduct
  ): VIPBuyingSessionProduct[] => {
    const searchKeyLowerCase = searchKeyFromStore?.toLowerCase()?.trim()
    if (searchKeyLowerCase) {
      const attributesToSearch = filter(
        config?.appConfig?.attributes?.VIPProduct ?? [],
        attr => attr.includeForSearch
      )

      return filter(products, product => {
        let buyingSessionProduct
        if (productType === ProductTypeForSearchFilterEnum.assortmentProduct) {
          buyingSessionProduct = product.Original
        } else if (productType === ProductTypeForSearchFilterEnum.orderProduct) {
          buyingSessionProduct = {
            Original: product.Original,
            ...(product.BuyingSessionProduct ?? {})
          }
        } else {
          buyingSessionProduct = product
        }
        return (
          some(attributesToSearch, attribute => {
            switch (attribute.valueType) {
              case AttributeValueType.STRING:
              case AttributeValueType.ENUM:
                if (
                  includes(
                    (
                      buyingSessionProduct.Original?.[attribute.attributeName] ?? ''
                    ).toLowerCase(),
                    searchKeyLowerCase
                  )
                ) {
                  return true
                }
                break

              case AttributeValueType.REF:
                if (
                  includes(
                    (
                      buyingSessionProduct.Original?.[attribute.attributeName]?.[
                        attribute.pathToValue
                      ] ?? ''
                    ).toLowerCase(),
                    searchKeyLowerCase
                  )
                ) {
                  return true
                }
                break

              case AttributeValueType.REFLIST:
                if (
                  some(
                    buyingSessionProduct.Original?.[attribute.attributeName] ?? [],
                    reflistItem =>
                      includes(
                        (reflistItem?.pathToValue ?? '').toLowerCase(),
                        searchKeyLowerCase
                      )
                  )
                ) {
                  return true
                }
                break
            }
            return false
          }) ||
          // Clusters
          some(
            buyingSessionProduct.clusterAssortmentsProduct ?? [],
            clusterAssortmentProduct =>
              includes(
                (
                  clusterAssortmentProduct?.assortment?.clusters?.Name ?? ''
                ).toLowerCase(),
                searchKeyLowerCase
              )
          ) ||
          // Channels
          some(buyingSessionProduct.channels ?? [], channel =>
            includes((channel ?? '').toLowerCase(), searchKeyLowerCase)
          ) ||
          // Drop
          includes(
            (buyingSessionProduct.drop?.Name ?? '').toLowerCase(),
            searchKeyLowerCase
          ) ||
          // Free Tags
          // wholesale Free Tags
          some(buyingSessionProduct.retailFreeTags ?? [], tag =>
            includes((tag?.name ?? '').toLowerCase(), searchKeyLowerCase)
          ) ||
          // retail Free Tags
          some(buyingSessionProduct.wholesaleFreeTags ?? [], tag =>
            includes((tag?.name ?? '').toLowerCase(), searchKeyLowerCase)
          )
        )
      })
    }
    return products
  }

  /**
   *
   * @param product : {VIPOrderProduct}
   * @param withQty : Boolean
   * @param isBooked : Boolean
   */
  sizedQtyAndBookedUnbookedFilter = (
    product: VIPOrderProduct,
    withQty: boolean,
    isBooked: boolean
  ) => {
    if (product?.sizes?.length) {
      return withQty
        ? some(product?.qtyPerSize, qty => qty > 0) && product?.isBooked === isBooked
        : every(product?.qtyPerSize, qty => qty === 0) && product?.isBooked === isBooked
    } else {
      return (
        (withQty ? product?.totalQty > 0 : product?.totalQty === 0) &&
        product?.isBooked === isBooked
      )
    }
  }

  @computed get productsWithComment() {
    const cmComments = []
    const rmComments = []
    Object.keys(this.retailBuyComments || {}).forEach(zoneId => {
      Object.keys(this.retailBuyComments[zoneId]).forEach(productId => {
        if (this.retailBuyComments[zoneId]?.[productId]?.CM) {
          cmComments.push(productId)
        }
        if (this.retailBuyComments[zoneId]?.[productId]?.RM) {
          rmComments.push(productId)
        }
      })
    })
    return { cmComments, rmComments }
  }

  filterProductsBasedOnFilters = (
    products,
    wsAppliedFilters,
    doorOrders?: any
  ): VIPBuyingSessionProduct[] | any => {
    const {
      nav: {
        queryParams: { assortmentType, tab }
      },
      wholesaleAddProductToAssortment: { isAddProductDrawerOpen }
    } = stores
    if (!isEmpty(wsAppliedFilters)) {
      return filter(products, currentProduct => {
        let product
        if (
          assortmentType &&
          tab !== ViewTabs.RTL_BUY.toLowerCase() &&
          !isAddProductDrawerOpen
        ) {
          product = currentProduct.Original
        } else {
          product = currentProduct
        }
        return every(keys(wsAppliedFilters), appliedFilterKey => {
          const filterValueObject: IFilter = wsAppliedFilters[appliedFilterKey]
          const filterValues = filterValueObject.values ?? []
          if (!filterValues.length) {
            return true
          }
          if (filterValueObject.isVIPProductAttribute) {
            switch (filterValueObject.type) {
              case AttributeValueType.STRING:
              case AttributeValueType.ENUM:
                return some(
                  filterValues,
                  filterValue =>
                    filterValue.value === product.Original?.[appliedFilterKey]
                )
              case AttributeValueType.INTEGER:
                return some(
                  filterValues,
                  filterValue =>
                    filterValue.value === product.Original?.[appliedFilterKey]
                )
              case AttributeValueType.REF:
                return some(
                  filterValues,
                  filterValue =>
                    filterValue.value ===
                    product.Original?.[appliedFilterKey]?.[filterValueObject.pathToValue]
                )
            }
          } else {
            // Check filter key is ending with Cluster to filter both wholesale and retail cluster at once since property
            // is same
            if (endsWith(appliedFilterKey, 'Cluster')) {
              return some(
                filterValues,
                filterValue =>
                  (filterValue.value &&
                    some(
                      product.clusterAssortmentsProduct ?? [],
                      clusterAssortmentProduct =>
                        clusterAssortmentProduct.assortment?.clusters?.Id ===
                        filterValue.value
                    )) ||
                  (!filterValue.value &&
                    (!product.clusterAssortmentsProduct ||
                      !product.clusterAssortmentsProduct.length))
              )
            }
            // Check filter key is ending with Free Tag to filter both wholesale and retail tag at once since property
            // is same
            if (endsWith(appliedFilterKey, 'Free Tag')) {
              const tags = concat(
                product?.wholesaleFreeTags ?? [],
                product?.retailFreeTags ?? []
              )
              return some(
                filterValues,
                filterValue =>
                  (filterValue.value &&
                    some(tags, tag => tag?.Id === filterValue.value)) ||
                  (!filterValue.value && !tags?.length)
              )
            }
            switch (appliedFilterKey) {
              case FilterKeys.Drop:
                return some(
                  filterValues,
                  filterValue =>
                    filterValue &&
                    filterValue.value ===
                      (product?.[appliedFilterKey]?.Name ??
                        product?.BuyingSessionProduct?.[appliedFilterKey]?.Name)
                )

              case FilterKeys.Channel:
                return some(
                  filterValues,
                  filterValue =>
                    (filterValue.value &&
                      includes(product?.[appliedFilterKey] ?? [], filterValue.value)) ||
                    (!filterValue.value && !(product?.[appliedFilterKey] ?? []).length)
                )

              case FilterKeys.Looks:
                return (
                  product?.Original?.[appliedFilterKey]?.length &&
                  some(
                    filterValues,
                    filterValue =>
                      filterValue &&
                      some(
                        product.Original[appliedFilterKey],
                        look => look.Id === filterValue.value
                      )
                  )
                )
              case FilterKeys.BookedUnbooked: {
                return some(filterValues, filterValue =>
                  some(doorOrders, door => {
                    /**
                     * FIXME: RM - This logic is really bad and very easy to break. Refactor it on priority after first
                     * today's release
                     */
                    return some(door?.OrderLineItems, orderItem => {
                      if (
                        filterValue.value === FilterKeys.Booked &&
                        orderItem?.Original?.Id === product?.Original?.Id
                      ) {
                        if (
                          wsAppliedFilters?.[FilterKeys.WithQtyWithoutQty]?.values
                            ?.length !== 2 &&
                          some(wsAppliedFilters?.[FilterKeys.WithQtyWithoutQty]?.values, [
                            'value',
                            FilterKeys.WithoutQty
                          ])
                        ) {
                          return this.sizedQtyAndBookedUnbookedFilter(
                            orderItem,
                            false,
                            true
                          )
                        }
                        if (
                          wsAppliedFilters?.[FilterKeys.WithQtyWithoutQty]?.values
                            ?.length !== 2 &&
                          some(wsAppliedFilters?.[FilterKeys.WithQtyWithoutQty]?.values, [
                            'value',
                            FilterKeys.WithQty
                          ])
                        ) {
                          return this.sizedQtyAndBookedUnbookedFilter(
                            orderItem,
                            true,
                            true
                          )
                        }
                        return orderItem?.isBooked
                      } else if (
                        filterValue.value === FilterKeys.Unbooked &&
                        orderItem?.Original?.Id === product?.Original?.Id
                      ) {
                        if (
                          wsAppliedFilters?.[FilterKeys.WithQtyWithoutQty]?.values
                            ?.length !== 2 &&
                          some(wsAppliedFilters?.[FilterKeys.WithQtyWithoutQty]?.values, [
                            'value',
                            FilterKeys.WithoutQty
                          ])
                        ) {
                          return this.sizedQtyAndBookedUnbookedFilter(
                            orderItem,
                            false,
                            false
                          )
                        }
                        if (
                          wsAppliedFilters?.[FilterKeys.WithQtyWithoutQty]?.values
                            ?.length !== 2 &&
                          some(wsAppliedFilters?.[FilterKeys.WithQtyWithoutQty]?.values, [
                            'value',
                            FilterKeys.WithQty
                          ])
                        ) {
                          return this.sizedQtyAndBookedUnbookedFilter(
                            orderItem,
                            true,
                            false
                          )
                        }
                        return orderItem?.isBooked === false
                      }
                    })
                  })
                )
              }
              case FilterKeys.WithQtyWithoutQty: {
                return some(filterValues, filterValue =>
                  some(doorOrders, door => {
                    return some(door?.OrderLineItems, orderItem => {
                      if (orderItem?.Original?.Id === product?.Original?.Id) {
                        if (filterValue.value === FilterKeys.WithQty) {
                          if (
                            wsAppliedFilters?.[FilterKeys.BookedUnbooked]?.values
                              ?.length !== 2 &&
                            some(wsAppliedFilters?.[FilterKeys.BookedUnbooked]?.values, [
                              'value',
                              FilterKeys.Booked
                            ])
                          ) {
                            return this.sizedQtyAndBookedUnbookedFilter(
                              orderItem,
                              true,
                              true
                            )
                          } else if (
                            wsAppliedFilters?.[FilterKeys.BookedUnbooked]?.values
                              ?.length !== 2 &&
                            some(wsAppliedFilters?.[FilterKeys.BookedUnbooked]?.values, [
                              'value',
                              FilterKeys.Unbooked
                            ])
                          ) {
                            return this.sizedQtyAndBookedUnbookedFilter(
                              orderItem,
                              true,
                              false
                            )
                          }
                          if (orderItem?.sizes?.length) {
                            return some(orderItem?.qtyPerSize, qty => qty > 0)
                          } else {
                            return orderItem?.totalQty > 0
                          }
                        } else if (filterValue.value === FilterKeys.WithoutQty) {
                          if (
                            wsAppliedFilters?.[FilterKeys.BookedUnbooked]?.values
                              ?.length !== 2 &&
                            some(wsAppliedFilters?.[FilterKeys.BookedUnbooked]?.values, [
                              'value',
                              FilterKeys.Booked
                            ])
                          ) {
                            return this.sizedQtyAndBookedUnbookedFilter(
                              orderItem,
                              false,
                              true
                            )
                          } else if (
                            wsAppliedFilters?.[FilterKeys.BookedUnbooked]?.values
                              ?.length !== 2 &&
                            some(wsAppliedFilters?.[FilterKeys.BookedUnbooked]?.values, [
                              'value',
                              FilterKeys.Unbooked
                            ])
                          ) {
                            return this.sizedQtyAndBookedUnbookedFilter(
                              orderItem,
                              false,
                              false
                            )
                          }
                          if (orderItem?.sizes?.length) {
                            return every(orderItem?.qtyPerSize, qty => qty === 0)
                          } else {
                            return orderItem?.totalQty === 0
                          }
                        }
                      }
                    })
                  })
                )
              }
              case FilterKeys.Status:
                return (
                  product?.Original &&
                  some(
                    filterValues,
                    filterValue => product.Original.Active === filterValue.value
                  )
                )

              case FilterKeys.SalesQty:
                const productToConsider =
                  assortmentType === BuyingSessionAssortmentType.client
                    ? currentProduct
                    : product
                const sizedQty = !isNaN(productToConsider.sizedQty)
                  ? toNumber(productToConsider.sizedQty)
                  : 0
                const draftQty = !isNaN(productToConsider.draftQty)
                  ? toNumber(productToConsider.draftQty)
                  : 0
                const salesQty = sizedQty + draftQty
                return some(
                  filterValues,
                  filterValue =>
                    (filterValue.value && salesQty > 0) ||
                    (!filterValue.value && salesQty === 0)
                )

              case FilterKeys.Rating:
                return some(
                  filterValues,
                  filterValue =>
                    (filterValue.value && product.averageRating > 0) ||
                    (!filterValue.value && product.averageRating === 0)
                )

              case FilterKeys.ProductStatus:
                let returnValue = some(
                  filterValues,
                  filterValue => product.isCore === filterValue.value
                )
                return returnValue

              case FilterKeys.Flagged:
                return some(
                  filterValues,
                  filterValue => product.hasCounterOffer === filterValue.value
                )

              case FilterKeys.ProductOrderStatus:
                return some(
                  filterValues,
                  filterValue => product.hasOrders === filterValue.value
                )

              case FilterKeys.ProductComment:
                return some(filterValues, filterValue =>
                  filterValue.value
                    ? includes(this.productsWithComment?.cmComments, product.Id)
                    : includes(this.productsWithComment?.rmComments, product.Id)
                )
              case FilterKeys.Favourite:
                return some(
                  filterValues,
                  filterValue =>
                    (filterValue.value && (product.Favorites ?? []).length > 0) ||
                    (!filterValue.value &&
                      (!product.Favorites || !product.Favorites.length))
                )
            }
          }
          return false
        })
      })
    }
    return products
  }

  @computed get whereInputForBSPSummaryForBS() {
    const {
      queryParams: { buyingSession }
    } = nav

    const isZAMUserLoggedIn = isZAM()

    const where = {
      __Parent__: {
        Id: { operation: CriteriaOperationType.EQ, value: buyingSession }
      },
      ...(isZAMUserLoggedIn && {
        Channels: this.whereInputForChannels()
      })
    }

    return where
  }

  /**
   * Computed map for list view column headers for different tabs
   */
  getListViewColumnDefinitionMap = computedFn((zoneColumnMap?) => {
    return {
      [ViewTabs.DISTRIBUTION]:
        collectionListViewDefinitionService.buildListViewDefinitionForDistribution(
          ChannelSubSection.Wholesale
        ),
      [ViewTabs.RTL_DISTRIBUTION]:
        collectionListViewDefinitionService.buildListViewDefinitionForDistribution(
          ChannelSubSection.Retail
        ),
      [ViewTabs.FORECAST]:
        collectionListViewDefinitionService.buildListViewDefinitionForForecast(),
      [ViewTabs.BUY]: collectionListViewDefinitionService.buildListViewDefinitionForBuy(
        ChannelSubSection.Wholesale
      ),
      [ViewTabs.RTL_BUY]:
        collectionListViewDefinitionService.buildListViewDefinitionForBuy(
          ChannelSubSection.Retail
        ),
      [ViewTabs.COUNTER_OFFER]:
        collectionListViewDefinitionService.buildListViewDefinitionForCounterOffer(
          zoneColumnMap
        )
    }
  })

  /**
   * Computed map for kpi view column headers for different tabs
   */
  @computed get kpiViewColumnDefinitionMap() {
    return {
      [ViewTabs.FORECAST]:
        collectionKpiViewDefinitionService.buildKpiViewDefinitionForForecast(),
      [ViewTabs.RTL_BUY]:
        collectionKpiViewDefinitionService.buildKpiViewDefinitionForBuy()
    }
  }

  /**
   * Sync list view tab changes to nav store
   * @param tab - list view tab name
   */
  onListViewTabChangeHandler = (tab: string) => {
    const {
      nav: { updateQueryParams }
    } = stores

    updateQueryParams({
      tab: tab.toLocaleLowerCase()
    })
  }

  /**
   * where input for wholesale channel check
   */
  whereInputForChannels = () => {
    const wholesaleChannelValue =
      config.appConfig?.enumerations?.channel?.VALUES?.WHOLESALE
    return {
      operation: CriteriaOperationType.EQ,
      value: wholesaleChannelValue
    }
  }

  /**
   * Method for building where input for buying session products query for buying session group
   * @param {string} activity - Name of activity
   * @param {Array<string>} childrenMeta - ChildrenMeta for given activity
   * @param {Array<string>} childrenMetaValues - Values for hierarchy chilren
   * @returns Where input object for buying session products query for given group
   */
  whereInputForBSPForGroup = (
    activity: string,
    childrenMeta: Array<string>,
    childrenMetaValues: Array<string>
  ) => {
    let baseInputForQuery: any = cloneDeep(this.whereInputForBSPSummaryForGroup)

    baseInputForQuery.Original = wholesaleCollectionService.buildAttributeHierarchyQuery(
      activity,
      childrenMeta,
      childrenMetaValues
    )
    return baseInputForQuery
  }

  /**
   * Method for building where input for buying session products query for buying session
   * @param {string} activity - Name of activity
   * @param {Array<string>} childrenMeta - ChildrenMeta for given activity
   * @param {Array<string>} childrenMetaValues - Values for hierarchy chilren
   * @returns Where input object for buying session products query for given buying session
   */
  whereInputForBSPForBS = (
    activity: string,
    childrenMeta: Array<string>,
    childrenMetaValues: Array<string>
  ) => {
    let baseInputForQuery: any = cloneDeep(this.whereInputForBSPSummaryForBS)

    baseInputForQuery.Original = wholesaleCollectionService.buildAttributeHierarchyQuery(
      activity,
      childrenMeta,
      childrenMetaValues
    )
    return baseInputForQuery
  }
  /**
   * To get product attribute
   * @param {object} product
   * @returns {boolean}
   */
  getAttribute = (attributes, attributeNameEnum) => {
    return find(attributes, attribute => attribute.name === attributeNameEnum) || []
  }
  /**
   * To check duplicate tag on product
   *
   * @param {object} product
   * @param {string} tagId
   * @param {string} attrubuteEnum
   * @returns {boolean}
   */
  checkDuplicateTag = (buyingSessionProduct, tagId, attribute) => {
    const attributes = this.getAttribute(buyingSessionProduct.attributes, attribute)
    return attributes?.value?.length
      ? attributes.value.some(attr => attr.id === tagId)
      : false
  }

  checkIsClusterExcluded = ({ attributes }, tagId: string) => {
    attributes = this.getAttribute(
      attributes,
      ProductCardAttributeEnum.wholesaleClusterExclusion
    )
    return attributes?.value?.length && attributes.value.some(elem => elem.id === tagId)
  }

  /**
   * To check channel tag present on product
   * @param {object} product
   * @returns {boolean}
   */
  hasChannelTagOnProduct = (buyingSessionProduct, channel) => {
    const channels = this.getAttribute(
      buyingSessionProduct.attributes,
      ProductCardAttributeEnum.channels
    )
    const allChannels = map(channels.value, 'id')
    return allChannels.length && allChannels?.includes(channel)
  }

  /**
   * Listview Wrapper around handleTagDrop
   */
  handleListViewTagDrop =
    buyingSessionProduct =>
    ({ id, type }) => {
      this.handleTagDrop(buyingSessionProduct, type, id)
    }
  /**
   * Handler for tag drop on product
   *
   * @param {object} processedProduct - Massaged product, can be either buying session product or assortment product
   * @param {string} type
   * @param {string} tagId
   */
  @action handleTagDrop = (processedProduct: IGridViewProduct, type, tagId) => {
    let {
      nav: { queryParams }
    } = stores

    const isAssortmentView = !!queryParams.assortmentType
    const isBSRestricted = isBuyingSessionRestrictedForTag(
      processedProduct.buyingSession,
      type,
      tagId
    )
    const productId = processedProduct.node.Id
    if (type === strings.tagTypes.channel) {
      const allowedRoles = PERMISSION_MAP.find(ele => ele.id === tagId)?.allowedRoles
      const canAdd = hasUserRoles(allowedRoles)
      if (!canAdd) return
      if (processedProduct.isActive && !isBSRestricted) {
        const isDuplicate = this.checkDuplicateTag(
          processedProduct,
          tagId,
          ProductCardAttributeEnum.channels
        )
        if (!isDuplicate) {
          this.addChannelToBuyingSessionProduct(productId, tagId)
        }
      } else {
        this.openMessageDialog = true
        this.displayMessage = !processedProduct.isActive
          ? strings.cannotDragTagsInactiveProduct
          : strings.cannotDragTagsClosedBuyingSession
      }
    } else if (type === strings.tagTypes.wholesaleCluster && !isAssortmentView) {
      const allowedRoles = PERMISSION_MAP.find(
        ele => ele.id === VIPChannel.Wholesale
      )?.allowedRoles
      const canAdd = hasUserRoles(allowedRoles)
      if (!canAdd) return
      // Prevent Excluded Cluster From Getting Added as Wholesale Cluster
      if (this.checkIsClusterExcluded(processedProduct, tagId)) return
      const isChannelPresentOnProduct = this.hasChannelTagOnProduct(
        processedProduct,
        VIPChannel.Wholesale
      )
      if (!isBSRestricted && isChannelPresentOnProduct && processedProduct.isActive) {
        const isDuplicate = this.checkDuplicateTag(
          processedProduct,
          tagId,
          ProductCardAttributeEnum.wholesaleClusters
        )
        if (!isDuplicate) {
          this.addClusterToBuyingSessionProduct(productId, tagId)
        }
      } else {
        this.openMessageDialog = true
        if (isBSRestricted) {
          this.displayMessage = strings.cannotDragTagsClosedBuyingSession
        } else if (!processedProduct.isActive) {
          this.displayMessage = strings.cannotDragTagsInactiveProduct
        } else {
          this.displayMessage = strings.cannotDragTagsNoChannelOnProduct
        }
      }
    } else if (type === strings.tagTypes.retailCluster && !isAssortmentView) {
      const allowedRoles = PERMISSION_MAP.find(
        ele => ele.id === VIPChannel.Retail
      )?.allowedRoles
      const canAdd = hasUserRoles(allowedRoles)
      if (!canAdd) return
      // Prevent Excluded Cluster From Getting Added as Retail Cluster
      if (this.checkIsClusterExcluded(processedProduct, tagId)) return
      const isChannelPresentOnProduct = this.hasChannelTagOnProduct(
        processedProduct,
        VIPChannel.Retail
      )
      if (!isBSRestricted && isChannelPresentOnProduct && processedProduct.isActive) {
        const isDuplicate = this.checkDuplicateTag(
          processedProduct,
          tagId,
          ProductCardAttributeEnum.retailClusters
        )
        if (!isDuplicate) {
          this.addClusterToBuyingSessionProduct(productId, tagId)
        }
      } else {
        this.openMessageDialog = true
        if (isBSRestricted) {
          this.displayMessage = strings.cannotDragTagsClosedBuyingSession
        } else if (!processedProduct.isActive) {
          this.displayMessage = strings.cannotDragTagsInactiveProduct
        } else {
          this.displayMessage = strings.cannotDragRetailTagsNoChannelOnProduct
        }
      }
    } else if (type === strings.tagTypes.drop && !isAssortmentView) {
      const allowedRoles = PERMISSION_MAP.find(
        ele => ele.id === VIPChannel.Wholesale
      )?.allowedRoles
      const canAdd = hasUserRoles(allowedRoles)
      if (!canAdd) return
      if (processedProduct.isActive && !isBSRestricted) {
        const isDuplicate = this.checkDuplicateTag(
          processedProduct,
          tagId,
          ProductCardAttributeEnum.drop
        )
        if (!isDuplicate) {
          this.addDropToBuyingSessionProduct(productId, tagId)
        }
      } else {
        this.openMessageDialog = true
        if (!processedProduct.isActive) {
          this.displayMessage = strings.cannotDragTagsInactiveProduct
        } else {
          this.displayMessage = strings.cannotDragTagsClosedBuyingSession
        }
      }
    } else if (
      type === strings.tagTypes.retailFreeTags &&
      // !isAssortmentView &&
      !isBSRestricted &&
      processedProduct.isActive
    ) {
      const allowedRoles = PERMISSION_MAP.find(
        ele => ele.id === VIPChannel.Retail
      )?.allowedRoles
      const canAdd = hasUserRoles(allowedRoles)
      if (!canAdd) return
      const isDuplicate = this.checkDuplicateTag(
        processedProduct,
        tagId,
        ProductCardAttributeEnum.retailFreeTags
      )
      if (!isDuplicate) {
        this.addFreeTagToBuyingSessionProduct(productId, tagId)
      }
    } else if (
      type === strings.tagTypes.wholesaleFreeTags &&
      // !isAssortmentView &&
      !isBSRestricted &&
      processedProduct.isActive
    ) {
      const allowedRoles = PERMISSION_MAP.find(
        ele => ele.id === VIPChannel.Wholesale
      )?.allowedRoles
      const canAdd = hasUserRoles(allowedRoles)
      if (!canAdd) return
      const isDuplicate = this.checkDuplicateTag(
        processedProduct,
        tagId,
        ProductCardAttributeEnum.wholesaleFreeTags
      )
      if (!isDuplicate) {
        this.addFreeTagToBuyingSessionProduct(productId, tagId)
      }
    } else if (
      type === strings.tagTypes.salesPeriod &&
      // !isAssortmentView &&
      processedProduct.isActive
    ) {
      const allowedRoles = PERMISSION_MAP.find(
        ele => ele.id === VIPChannel.Retail
      )?.allowedRoles
      const canAdd = hasUserRoles(allowedRoles)
      if (!canAdd) return
      const isChannelPresentOnProduct = this.hasChannelTagOnProduct(
        processedProduct,
        VIPChannel.Retail
      )
      if (!isBSRestricted && isChannelPresentOnProduct && processedProduct.isActive) {
        const isDuplicate = this.checkDuplicateTag(
          processedProduct,
          tagId,
          ProductCardAttributeEnum.retailSalesPeriod
        )
        if (!isDuplicate) {
          this.addSalesPeriodToBSP(productId, tagId)
        }
      } else {
        this.openMessageDialog = true
        if (isBSRestricted) {
          this.displayMessage = strings.cannotDragTagsClosedBuyingSession
        } else if (!processedProduct.isActive) {
          this.displayMessage = strings.cannotDragTagsInactiveProduct
        } else {
          this.displayMessage = strings.cannotDragRetailSalesPeriodNoChannelOnProduct
        }
      }
    }
  }

  @action deleteClusterAssortmentProduct = async (
    buyingSessionProduct,
    assortmentProductId
  ) => {
    this.isMutationInProgress = true
    const {
      queryParams: { assortment, assortmentType }
    } = nav
    const refetchQueries = this.getRefetchQueriesForRemoveCluster(
      assortment,
      assortmentType,
      buyingSessionProduct
    )
    try {
      const url = `${config.centricRestEndpoint}removeClusterAssortmentProduct`
      const body = {
        clusterAssortmentProductId: assortmentProductId
      }
      const sessionURL: string = storage.getItem('session_url')
      const { data } = await axios.post(url, body, {
        headers: {
          Authorization: `Bearer ${sessionURL}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      if (data?.status === strings.mutationResultSuccess) {
        const {
          nav: {
            queryParams: { productLayout }
          }
        } = stores
        const { setForecastData } = productForecastUIStore
        const promiseAll = []
        refetchQueries.forEach(query => promiseAll.push(apolloClient.query(query)))
        await Promise.all(promiseAll)
        // delaying for job service response
        'list' === productLayout && setTimeout(async () => await setForecastData(), 300)
      }
    } finally {
      this.isMutationInProgress = false
      this.closeDeleteAssortmentProductConfirmation()
    }
  }

  @action
  removeClusterFromExcludedClusters = async (buyingSessionProduct, cluster) => {
    const bspID = buyingSessionProduct.productId
    this.isMutationInProgress = true
    const refetchQuery = {
      query: GET_BUYING_SESSION_PRODUCT,
      variables: {
        buyingSessionProductID: bspID
      }
    }
    try {
      await apolloClient.mutate({
        mutation: REMOVE_CLUSTER_FROM_BUYING_SESSION_PRODUCT_EXCLUSION,
        variables: {
          buyingSessionProduct: bspID,
          cluster
        },
        awaitRefetchQueries: true,
        refetchQueries: [refetchQuery]
      })
    } finally {
      this.isMutationInProgress = false
    }
  }

  @action deleteClusterFromBuyingSessionProduct = async (
    buyingSessionProduct,
    tagId,
    assortmentProductId?
  ) => {
    const assortmentProducts =
      buyingSessionProduct[WholesaleGirdProductFieldMapping.ClusterAssortmentProducts]
    // eslint-disable-next-line array-callback-return
    const clusterAssortmentProductToDelete = assortmentProducts.find(product => {
      const cluster =
        product[ProductCardAttributeEnum.assortment][ProductCardAttributeEnum.clusters]
      if (cluster?.Id === tagId) {
        return product
      }
    })

    this.deleteClusterAssortmentProduct(
      buyingSessionProduct,
      clusterAssortmentProductToDelete?.Id
    )
  }

  @action
  openDeleteAssortmentProductConfirmation = selectedClusterAssortmentProduct => e => {
    if (e.stopPropagation) e.stopPropagation()
    this.showConfirmationDeleteClusterAssortmentProduct = true
    this.selectedClusterAssortmentProduct = selectedClusterAssortmentProduct
    this.selectedClusterAssortmentId = selectedClusterAssortmentProduct.productId
  }

  @action closeDeleteAssortmentProductConfirmation = () => {
    this.showConfirmationDeleteClusterAssortmentProduct = false
  }

  getRefetchQueriesForRemoveCluster = (
    assortment,
    assortmentType,
    processedBuyingSessionProduct
  ) => {
    if (assortment) {
      // In case of assortment, refecth both summary as well as products on cluster remove
      const {
        wholesaleAssortmentProductStore: { whereInputForwhereInputForAssortmentProduct }
      } = stores

      // Extract hierarchy for building where object for assortment product query
      let activity =
        processedBuyingSessionProduct?.node?.Original?.Activity?.Name ?? 'Undefined'
      let childrenMeta = ACTIVITY_HIERARCHY_MAP[activity] || DEFAULT_ACTIVITY_HIERARCHY
      let childrenMetaValues = []

      childrenMeta.forEach(currentCategory => {
        childrenMetaValues.push(
          processedBuyingSessionProduct?.node?.Original?.[
            `${capitalize(currentCategory)}`
          ]?.Name
        )
      })

      const assortmentProductWhereInput = whereInputForwhereInputForAssortmentProduct(
        activity,
        childrenMeta,
        childrenMetaValues
      )

      const assortmentWhereKey =
        assortmentType === BuyingSessionAssortmentType.cluster
          ? 'VIPClusterAssortment'
          : assortmentType === BuyingSessionAssortmentType.client
          ? 'VIPClientAssortment'
          : 'VIPDoorAssortment'
      return [
        {
          query:
            assortmentType === BuyingSessionAssortmentType.cluster
              ? GET_CLUSTER_ASSORTMENT_SUMMARY
              : assortmentType === BuyingSessionAssortmentType.client
              ? GET_CLIENT_ASSORTMENT_SUMMARY
              : GET_DOOR_ASSORTMENT_SUMMARY,
          variables: {
            buyingSessionAssortmentProductSummaryWhere: {
              GIV_VIPAssortmentProduct_Assortment_ref: {
                [assortmentWhereKey]: {
                  Id: { operation: CriteriaOperationType.EQ, value: assortment }
                }
              }
            }
          },
          fetchPolicy: 'network-only'
        },
        {
          query:
            assortmentType === BuyingSessionAssortmentType.cluster
              ? WS_CLUSTER_ASSORTMENT_PRODUCTS
              : assortmentType === BuyingSessionAssortmentType.client
              ? WS_CLIENT_ASSORTMENT_PRODUCTS
              : WS_DOOR_ASSORTMENT_PRODUCTS,
          variables: {
            where: assortmentProductWhereInput
          },
          fetchPolicy: 'network-only'
        }
      ]
    } else {
      return [
        {
          query: GET_BUYING_SESSION_PRODUCT,
          variables: {
            buyingSessionProductID: processedBuyingSessionProduct?.productId
          },
          fetchPolicy: 'network-only'
        }
      ]
    }
  }
  /**
   * Marks product exclusive to particular channel
   */
  @action addChannelToBuyingSessionProduct = async (
    buyingSessionProductId,
    channelId
  ) => {
    const refetchQuery = {
      query: GET_BUYING_SESSION_PRODUCT,
      variables: {
        buyingSessionProductID: buyingSessionProductId
      }
    }
    await apolloClient.mutate({
      mutation: ADD_CHANNEL_TO_BUYING_SESSION_PRODUCT,
      variables: {
        buyingSessionProductId,
        channelId
      },
      awaitRefetchQueries: true,
      refetchQueries: [refetchQuery]
    })
  }

  @action addClusterToBuyingSessionProduct = async (
    buyingSessionProductId: string,
    clusterId: string
  ) => {
    const url = `${config.centricRestEndpoint}addClusterToProduct`
    const body = {
      buyingSessionProductId,
      clusterId
    }
    const sessionURL: string = storage.getItem('session_url')
    const { data } = await axios.post(url, body, {
      headers: {
        Authorization: `Bearer ${sessionURL}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })

    if (data?.status === strings.mutationResultSuccess) {
      const {
        nav: {
          queryParams: { productLayout }
        }
      } = stores
      const { setForecastData } = productForecastUIStore
      await apolloClient.query({
        query: GET_BUYING_SESSION_PRODUCT,
        variables: {
          buyingSessionProductID: buyingSessionProductId
        },
        fetchPolicy: 'network-only'
      })
      'list' === productLayout && (await setForecastData())
    }
  }

  @action addDropToBuyingSessionProduct = async (buyingSessionProductId, dropId) => {
    const refetchQuery = {
      query: GET_BUYING_SESSION_PRODUCT,
      variables: {
        buyingSessionProductID: buyingSessionProductId
      }
    }
    await apolloClient.mutate({
      mutation: ADD_DROP_TO_BUYING_SESSION_PRODUCT,
      variables: {
        buyingSessionProductId,
        dropId
      },
      refetchQueries: [refetchQuery]
    })
  }

  /**
   * addFreeTagToBuyingSessionProduct
   * @param buyingSessionProductId - Product id
   * @param tag - Free tag id
   */
  @action addFreeTagToBuyingSessionProduct = async (buyingSessionProductId, tagId) => {
    const refetchQuery = {
      query: GET_BUYING_SESSION_PRODUCT,
      variables: {
        buyingSessionProductID: buyingSessionProductId
      }
    }

    await apolloClient.mutate({
      mutation: WS_ADD_FREE_TAG_TO_BUYING_SESSION_PRODUCT,
      variables: {
        buyingSessionProductId,
        tagId
      },
      awaitRefetchQueries: true,
      refetchQueries: [refetchQuery]
    })
  }

  @action handleMessageDialogClose = () => {
    this.openMessageDialog = false
    this.displayMessage = ''
  }

  @action handleConfirmationDialogClose = () => {
    this.openConfirmationDialog = false
    this.displayMessage = ''
  }

  @action
  showDeleteConfirmationDialog = (buyingSessionProduct = null, channel = null) => {
    const isBSRestricted = isBuyingSessionRestrictedForTag(
      buyingSessionProduct.buyingSession,
      strings.tagTypes.channel,
      channel
    )
    if (buyingSessionProduct.isActive && !isBSRestricted) {
      this.openConfirmationDialog = true
      this.displayMessage = strings.channelRemovalConfirmationMessage
      this.displayTitle = strings.removeChannel
      this.buyingSessionProductId = buyingSessionProduct.productId
      this.tagId = channel
      this.onDeleteConfirmationClick = this.removeChannelFromBuyingSessionProduct
    } else {
      this.openMessageDialog = true
      this.displayMessage = !buyingSessionProduct.isActive
        ? strings.cannotRemoveTagsInactiveProduct
        : strings.cannotRemoveTagsClosedBuyingSession
    }
  }

  @action showDropDeleteConfirmationDialog = buyingSessionProduct => {
    this.buyingSessionProductId = buyingSessionProduct?.node?.Id
    this.openConfirmationDialog = true
    this.displayMessage = strings.deleteDropConfirmationMessage
    this.displayTitle = strings.removeDrop
    this.onDeleteConfirmationClick = this.removeDropFromBuyingSessionProduct
  }

  @action removeDropFromBuyingSessionProduct = async () => {
    const { buyingSessionProductId } = this
    this.openConfirmationDialog = false
    const refetchQuery = {
      query: GET_BUYING_SESSION_PRODUCT,
      variables: {
        buyingSessionProductID: buyingSessionProductId
      }
    }
    await apolloClient.mutate({
      mutation: REMOVE_DROP_FROM_BUYING_SESSION_PRODUCT,
      variables: {
        buyingSessionProductId
      },
      refetchQueries: [refetchQuery]
    })
  }

  /**
   * Remove channel from buying session product
   */
  @action removeChannelFromBuyingSessionProduct = async () => {
    const { buyingSessionProductId, tagId } = this

    this.isDeleteInProgress = true

    const refetchQuery = {
      query: GET_BUYING_SESSION_PRODUCT,
      variables: {
        buyingSessionProductID: buyingSessionProductId
      }
    }
    try {
      await apolloClient.mutate({
        mutation: REMOVE_CHANNEL_FROM_BUYING_SESSION_PRODUCT,
        variables: {
          buyingSessionProductId: buyingSessionProductId,
          channel: tagId
        },
        awaitRefetchQueries: true,
        refetchQueries: [refetchQuery]
      })

      // Product is not present in an Order → Admin/CM removes channel from product card in BB → on Add Product page →
      // this product should not be visible (Same as Inactive product use case) -
    } finally {
      this.isDeleteInProgress = false
      this.handleConfirmationDialogClose()
      this.onDeleteConfirmationClick = null
    }
  }

  @action removeZoneExclusionFromBuyingSessionProduct = async (
    buyingSessionProduct,
    tagId
  ) => {
    const {
      node: { Id }
    } = buyingSessionProduct
    const refetchQuery = {
      query: GET_BUYING_SESSION_PRODUCT,
      variables: {
        buyingSessionProductID: Id
      }
    }
    await apolloClient.mutate({
      mutation: REMOVE_CLIENT_FROM_BUYING_SESSION_PRODUCT_EXCLUSION,
      variables: {
        buyingSessionProduct: Id,
        client: tagId
      },
      awaitRefetchQueries: true,
      refetchQueries: [refetchQuery]
    })
  }

  /**
   * removeFreeTagFromBuyingSessionProduct
   * @param buyingSessionProductId - Product id
   * @param tag - Free tag id
   */
  @action removeFreeTagFromBuyingSessionProduct = async (buyingSessionProduct, tagId) => {
    const {
      node: { Id }
    } = buyingSessionProduct
    if (!isEmpty(Id)) {
      const refetchQuery = {
        query: GET_BUYING_SESSION_PRODUCT,
        variables: {
          buyingSessionProductID: Id
        }
      }
      await apolloClient.mutate({
        mutation: WS_REMOVE_FREE_TAG_TO_BUYING_SESSION_PRODUCT,
        variables: {
          buyingSessionProductId: Id,
          tagId
        },
        awaitRefetchQueries: true,
        refetchQueries: [refetchQuery]
      })
    }
  }

  /**
   * removeSalesPeriodFromBuyingSessionProduct
   * @param buyingSessionProduct - Product id
   */
  @action removeSalesPeriodFromBuyingSessionProduct = async buyingSessionProduct => {
    const {
      node: { Id }
    } = buyingSessionProduct
    if (!isEmpty(Id)) {
      const refetchQuery = {
        query: GET_BUYING_SESSION_PRODUCT,
        variables: {
          buyingSessionProductID: Id
        }
      }
      await apolloClient.mutate({
        mutation: REMOVE_SALES_PERIOD_FROM_BUYING_SESSION_PRODUCT,
        variables: {
          buyingSessionProduct: Id
        },
        awaitRefetchQueries: true,
        refetchQueries: [refetchQuery]
      })
    }
  }

  @action
  closeProductDetail = () => {
    const {
      setQueryParams,
      location: { search }
    } = nav
    let qsJSON: any = cloneDeep(qs.parse(search))
    if (qsJSON.bspId) {
      delete qsJSON.bspId
    } else if (qsJSON.productId) {
      delete qsJSON.productId
    }
    setQueryParams(qsJSON)
  }

  /**
   * Sync list view tab changes to nav store
   * @param tab - list view tab name
   */
  getImage = Images => {
    const image = Images ? getDefaultImage(Images) : null
    const imageId = image?.Value?.Id ?? null
    const imageURL = imageId
      ? getMediaCentricURI({
          URL: imageId,
          Attribute: ImageAttributes.SmallImage,
          Module: Modules.Publisher,
          Operation: Operations.GetFromNode
        })
      : null
    return imageURL
  }

  @action favoriteClickHandler = buyingSessionProductId => async event => {
    // const {
    //   showroomProductDetailStore: { toggleIsFavourite }
    // } = stores
    if (!event.shiftKey) {
      event.stopPropagation()
      const refetchQuery = {
        query: GET_BUYING_SESSION_PRODUCT_DETAILS,
        variables: {
          where: {
            Id: { operation: CriteriaOperationType.EQ, value: buyingSessionProductId }
          }
        }
      }
      await apolloClient.mutate({
        mutation: WS_TOGGLE_FAVORITE_FLAG_FOR_BS_PRODUCT,
        variables: {
          buyingSessionProductId
        },
        refetchQueries: [refetchQuery]
      })
    }
  }

  getTabsForView = computedFn((channels, zoneColumnMap?) => {
    const {
      nav: {
        queryParams: { tab, productLayout, subview = '' }
      }
    } = stores
    let tabList = []
    let selectedTab
    let tabColDefinition
    if (productLayout === strings.list) {
      tabList = getViewTabsToRender(strings.list, 'collection', channels, subview)
      selectedTab = tab ? decodeURIComponent(tab).toUpperCase() : ViewTabs.DISTRIBUTION
      tabColDefinition = this.getListViewColumnDefinitionMap(zoneColumnMap)[selectedTab]
    }
    if (productLayout === strings.kpi) {
      tabList = getViewTabsToRender(strings.kpi, 'collection', channels, subview)
      selectedTab = tab ? decodeURIComponent(tab).toUpperCase() : ViewTabs.FORECAST
      tabColDefinition = this.kpiViewColumnDefinitionMap[selectedTab]
    }
    return {
      tabList,
      selectedTab,
      tabColDefinition
    }
  })

  @action
  fetchBuyKPIs = async (buyingSessionGroupId, buyingSessionId, clientId?, doorId?) => {
    this.isKPIDataLoading = true
    try {
      const [kpiData, buyKpiData] = await Promise.all([
        ProductForecastKPIsService.getProductForecastKPIs(
          buyingSessionGroupId,
          buyingSessionId,
          clientId
        ),
        ProductForecastKPIsService.getBuyKPIsForRetail(
          buyingSessionGroupId,
          buyingSessionId,
          clientId,
          doorId
        )
      ])

      this.productForecastKPIData = kpiData
      this.productBuyKPIData = buyKpiData
    } catch (e) {
      console.log(e)
    } finally {
      this.isKPIDataLoading = false
    }
  }

  @action
  fetchRetailBuyProducts = (
    buyingSessionGroupId,
    buyingSessionId,
    clientId?,
    doorId?
  ) => {
    Promise.all([
      this.syncBuyTargetService.getRetailBuyProducts(
        buyingSessionGroupId,
        buyingSessionId,
        clientId,
        doorId
      ),
      buyingSessionGroupId && buyingSessionId && clientId
        ? this.commonService.getRetailRMClusters(
            buyingSessionGroupId,
            buyingSessionId,
            clientId,
            doorId
          )
        : {},
      this.commentService.getComments(buyingSessionGroupId, clientId)
    ])
      .then(([retailBuyData, retailRMClusters, retailBuyComments]) => {
        this.retailBuyProducts = retailBuyData
        this.retailRMClusters = retailRMClusters
        this.retailBuyComments = retailBuyComments
      })
      .catch(e => {
        console.log(e)
      })
  }

  @action
  updateRetailBuyComment = (
    buyingSessionProductId: string,
    clientId: string,
    comments: string,
    commentType: string
  ) => {
    const {
      queryParams: { buyingSessionGroupId }
    } = nav
    this.commentService
      .updateComment(buyingSessionGroupId, buyingSessionProductId, clientId, {
        comments,
        commentType
      })
      .catch(e => {
        console.log(e)
      })
  }

  @action
  fetchWHLBuyProducts = async (
    buyingSessionGroupId,
    buyingSessionId,
    clientId?,
    doorId?
  ) => {
    try {
      this.syncBuyTargetService
        .getWholesaleBuyProducts(buyingSessionGroupId, buyingSessionId, clientId, doorId)
        .then(response => {
          this.wholesaleBuyProducts = response
        })
        .catch(e => {
          console.log(e)
        })
    } catch (e) {
      console.log(e)
    }
  }

  @action
  resetBulkOpSelectedProducts = (evt?) => {
    if (evt?.preventDefault) {
      evt.preventDefault()
    }
    if (this.bulkOpSelectedProducts.length) {
      Object.assign(this, {
        bulkOpSelectedProducts: [],
        bulkOpProductsCountWithoutWholesaleChannel: 0,
        bulkOpProductsCountFromClosedBS: 0,
        bulkOpProductsCountWithoutRetailChannel: 0,
        bulkOpCoreProductsCount: 0
      })
    }
  }

  @action rightClickHandlerForBulkOp = evt => {
    const {
      nav: {
        queryParams: { assortmentType }
      }
    } = stores
    evt.preventDefault()
    if (this.bulkOpSelectedProducts.length && !assortmentType) {
      this.isBulkOpPopupDisplayed = true
    }
  }

  @action hideBulkOpPopup = () =>
    Object.assign(this, {
      isBulkOpPopupDisplayed: false,
      bulkOpSelectedEntities: {},
      bulkOpSelectedTab: strings.tagTypes.channel
    })

  @action mouseDownHandlerForBSProducts = product => event => {
    if (event.shiftKey) {
      this.handleBulkOpProductSelection(event, product)
    }
  }

  @action
  handleBulkOpProductSelection = (e, product) => {
    const {
      nav: {
        queryParams: { assortmentType }
      }
    } = stores
    e.preventDefault()
    if (assortmentType) {
      return
    }
    const highestUser = getHighestUser()
    const highestUserRoleName = highestUser?.[0]?.name ?? ''
    const wholesaleChannelValue =
      config.appConfig?.enumerations?.channel?.VALUES?.WHOLESALE
    const retailChannelValue = VIPChannel.Retail
    const hasWholesaleChannel = product.attributes.some(
      attribute =>
        attribute.name === ProductCardAttributeEnum.channels &&
        attribute.value.find(({ id }) => id === wholesaleChannelValue)
    )
    const hasRetailChannel = product.attributes.some(
      attribute =>
        attribute.name === ProductCardAttributeEnum.channels &&
        attribute.value.find(({ id }) => id === retailChannelValue)
    )
    const isProductFromClosedBS =
      product?.buyingSession?.Status ===
        config?.appConfig?.enumerations?.buyingSessionStatus?.BUYING_SESSION_STATUS_VALUES
          .CLOSED &&
      product?.buyingSession?.STD_RTLBuyingSession_Status ===
        config?.appConfig?.enumerations?.retailBuyingSessionStatus
          ?.BUYING_SESSION_STATUS_VALUES.CLOSED
    if (
      !some(
        this.bulkOpSelectedProducts,
        selectedProductId => selectedProductId === product.productId
      )
    ) {
      if (
        product.isActive &&
        this.bulkOpSelectedProducts.length < 15 &&
        highestUserRoleName !== UserRoles.ZONE_AREA_MANAGER
      ) {
        this.bulkOpSelectedProducts.push(product.productId)
        if (!hasWholesaleChannel) {
          this.bulkOpProductsCountWithoutWholesaleChannel++
        }
        if (isProductFromClosedBS) {
          this.bulkOpProductsCountFromClosedBS++
        }
        if (!hasRetailChannel) {
          this.bulkOpProductsCountWithoutRetailChannel++
        }
        if (product.isCore) {
          this.bulkOpCoreProductsCount++
        }
      }
    } else {
      remove(
        this.bulkOpSelectedProducts,
        selectedProductId => selectedProductId === product.productId
      )
      if (!hasWholesaleChannel) {
        this.bulkOpProductsCountWithoutWholesaleChannel--
      }
      if (isProductFromClosedBS) {
        this.bulkOpProductsCountFromClosedBS--
      }
      if (!hasRetailChannel) {
        this.bulkOpProductsCountWithoutRetailChannel--
      }
      if (product.isCore) {
        this.bulkOpCoreProductsCount--
      }
    }
  }

  @action setBulkOpSelectedTab = tab => Object.assign(this, { bulkOpSelectedTab: tab })

  @action handleBulkOpTabChange = newTabValue => {
    this.setBulkOpSelectedTab(newTabValue.id)
  }

  @computed get isAnyEntityDraggedForBulkOp(): boolean {
    return Object.keys(this.bulkOpSelectedEntities).some(
      key => !!this.bulkOpSelectedEntities[key].length
    )
  }

  @action mouseDownEventHandlerForCollection = refNode => event => {
    if (
      !event.shiftKey &&
      event.button !== 2 &&
      this.bulkOpSelectedProducts.length &&
      refNode?.current?.contains(event.target as Node)
    ) {
      this.resetBulkOpSelectedProducts(event)
    }
  }

  @action
  triggerBulkOpMutation = async () => {
    const allClusters = concat(
      this.bulkOpSelectedEntities[strings.tagTypes.retailCluster] || [],
      this.bulkOpSelectedEntities[strings.tagTypes.wholesaleCluster] || []
    )
    const allFreeTags = concat(
      this.bulkOpSelectedEntities[strings.tagTypes.retailFreeTags] || [],
      this.bulkOpSelectedEntities[strings.tagTypes.wholesaleFreeTags] || []
    )
    const allExclusions = concat(
      this.bulkOpSelectedEntities[strings.tagTypes.clientExclusions] || [],
      this.bulkOpSelectedEntities[strings.tagTypes.zoneExclusions] || []
    )
    const salesPeriod = (
      this.bulkOpSelectedEntities[strings.tagTypes.salesPeriod] || []
    ).map(({ Id }) => Id)
    const body = {
      buyingSessionProductIds: this.bulkOpSelectedProducts,
      clusterIds: (allClusters || []).map(({ Id }) => Id),
      clusterStatuses: (allClusters || []).map(
        cluster => cluster?.clusterStatusId || 'centric:'
      ),
      channelIds: (this.bulkOpSelectedEntities[strings.tagTypes.channel] || []).map(
        ({ Value }) => Value
      ),
      dropIds: (this.bulkOpSelectedEntities[strings.tagTypes.drop] || []).map(
        ({ Id }) => Id
      ),
      freeTagIds: (allFreeTags || []).map(({ Id }) => Id),
      retailSalesPeriod: salesPeriod?.length ? salesPeriod[0] : '',
      clientExclusions: (allExclusions || []).map(({ Id }) => Id)
    }
    const url = `${config.centricRestEndpoint}bulkUpdateBuyingSessionProducts`
    const sessionURL: string = storage.getItem('session_url')
    const { data } = await axios.post(url, body, {
      headers: {
        Authorization: `Bearer ${sessionURL}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })

    this.hideBulkOpPopup()
    const fetchPolicy: FetchPolicy = 'network-only'
    const refetchQuery = {
      query: WS_BUYING_SESSION_PRODUCTS,
      fetchPolicy,
      variables: {
        where: {
          OR: this.bulkOpSelectedProducts.map(Id => ({
            Id: {
              operation: CriteriaOperationType.EQ,
              value: Id
            }
          }))
        }
      }
    }
    if (data?.status === strings.mutationResultSuccess) {
      await apolloClient.query(refetchQuery)
    }
    this.resetBulkOpSelectedProducts()
  }

  getBulkOperationWarnings = tab => {
    let warnings: string[] = []
    const wholesaleChannelValue =
      config.appConfig?.enumerations?.channel?.VALUES?.WHOLESALE
    const isWholesaleChannelSelected = this.bulkOpSelectedEntities[
      strings.tagTypes.channel
    ]?.find(({ Value }) => Value === wholesaleChannelValue)
    const retailChannelValue = VIPChannel.Retail
    const isRetailChannelSelected = this.bulkOpSelectedEntities[
      strings.tagTypes.channel
    ]?.find(({ Value }) => Value === retailChannelValue)
    if (this.bulkOpProductsCountFromClosedBS) {
      warnings.push(
        `You have selected ${this.bulkOpProductsCountFromClosedBS} products whose buying session is CLOSED. Clusters/Drops/Channels/Free Tags will not be applied to those.`
      )
    }

    if (
      tab === strings.tagTypes.wholesaleCluster &&
      this.bulkOpProductsCountWithoutWholesaleChannel &&
      !isWholesaleChannelSelected
    ) {
      warnings.push(
        `You have selected ${this.bulkOpProductsCountWithoutWholesaleChannel} products which are not present in Wholesale channel. Wholesale clusters will not be applied to those.`
      )
    }
    if (
      tab === strings.tagTypes.retailCluster &&
      this.bulkOpProductsCountWithoutRetailChannel &&
      !isRetailChannelSelected
    ) {
      warnings.push(
        `You have selected ${this.bulkOpProductsCountWithoutRetailChannel} products which are not present in Retail channel. Retail clusters will not be applied to those.`
      )
    }
    if (
      tab === strings.tagTypes.salesPeriod &&
      this.bulkOpProductsCountWithoutRetailChannel &&
      !isRetailChannelSelected
    ) {
      warnings.push(
        `You have selected ${this.bulkOpProductsCountWithoutRetailChannel} products which are not present in Retail channel. Retail sales period will not be applied to those.`
      )
    }
    if (tab === strings.tagTypes.zoneExclusions && this.bulkOpCoreProductsCount) {
      warnings.push(
        `You have selected ${this.bulkOpCoreProductsCount} core products. Zone exclusion will not be applied to those.`
      )
    }
    return warnings
  }

  @action removeBulkOpEntity = (entityType, entityId) => {
    if (
      this.bulkOpSelectedEntities[entityType] &&
      this.bulkOpSelectedEntities[entityType].find(
        ({ Id, Value }) => (Value && Value === entityId) || (Id && Id === entityId)
      )
    ) {
      this.bulkOpSelectedEntities[entityType] = this.bulkOpSelectedEntities[
        entityType
      ].filter(
        ({ Id, Value }) => !((Value && Value === entityId) || (Id && Id === entityId))
      )
    }
  }

  @action addBulkOpSelectedEntity = (entityType, entity) => {
    if (!this.bulkOpSelectedEntities[entityType]) {
      this.bulkOpSelectedEntities[entityType] = []
    }
    if (
      entityType === strings.tagTypes.drop ||
      entityType === strings.tagTypes.salesPeriod
    ) {
      this.bulkOpSelectedEntities[entityType] = [entity]
    } else if (
      !this.bulkOpSelectedEntities[entityType].find(
        ({ Id, Value }) => (Value && Value === entity.Value) || (Id && Id === entity.Id)
      )
    ) {
      this.bulkOpSelectedEntities[entityType].push(entity)
    }
  }

  @computed get whereInputForClientOrderProducts() {
    const {
      queryParams: { buyingSessionGroupId, client }
    } = nav
    return {
      __Parent__: {
        VIPClientOrder: {
          GIV_VIPClientOrder_OrderNumber_string: {
            operation: CriteriaOperationType.NE,
            value: ''
          },
          BuyingSessionGroup: {
            Id: { operation: CriteriaOperationType.EQ, value: buyingSessionGroupId }
          },
          ...(client && {
            Client: {
              Id: { operation: CriteriaOperationType.EQ, value: client }
            }
          })
        }
      },
      OR: this.getORConditionForOrderProducts()
    }
  }

  @computed get whereInputForDoorOrderProducts() {
    const {
      queryParams: { buyingSessionGroupId, client }
    } = nav
    return {
      __Parent__: {
        VIPDoorOrder: {
          GIV_VIPDoorOrder_OrderNumber_string: {
            operation: CriteriaOperationType.NE,
            value: ''
          },
          BuyingSessionGroup: {
            Id: { operation: CriteriaOperationType.EQ, value: buyingSessionGroupId }
          },
          ...(client && {
            Door: {
              __Parent__: {
                Id: {
                  operation: CriteriaOperationType.EQ,
                  value: client
                }
              }
            }
          })
        }
      },
      OR: this.getORConditionForOrderProducts()
    }
  }

  getBuyingSessionCollection = collectionId => {
    let collection = {
      Id: { operation: CriteriaOperationType.EQ, value: collectionId }
    }
    return collection
  }

  getORConditionForOrderProducts = () => {
    return [
      {
        GIV_VIPOrderProduct_TotalQty_int: {
          operation: CriteriaOperationType.GT,
          value: 0
        }
      },
      {
        GIV_VIPOrderProduct_TotalSizedQty_int: {
          operation: CriteriaOperationType.GT,
          value: 0
        }
      }
    ]
  }

  /**
   * This function will refetch all the buying session products
   */
  @action
  handleBuyTargetSync = () => () => {
    const {
      nav: {
        queryParams: { buyingSessionGroupId }
      }
    } = stores
    try {
      this.syncBuyTargetService
        .syncBuyTabData(buyingSessionGroupId)
        .then(async response => {
          if (response.status === 200) {
            await this.query.productsRefetchQuery()
          }
        })
        .catch(error => {
          this.showConfirmationPopUpOnSync = false
        })
    } catch (error) {
      this.showConfirmationPopUpOnSync = false
    } finally {
      this.showConfirmationPopUpOnSync = false
    }
  }

  /**
   * addSalesPeriodToBSP
   * @param buyingSessionProductId - Product id
   * @param salesPeriod - Sales period id
   */
  @action addSalesPeriodToBSP = async (buyingSessionProduct, salesPeriod) => {
    const refetchQuery = {
      query: GET_BUYING_SESSION_PRODUCT,
      variables: {
        buyingSessionProductID: buyingSessionProduct
      }
    }

    await apolloClient.mutate({
      mutation: ADD_SALES_PERIOD_TO_BUYING_SESSION_PRODUCT,
      variables: {
        buyingSessionProduct,
        salesPeriod
      },
      awaitRefetchQueries: true,
      refetchQueries: [refetchQuery]
    })
  }
}
