export const ASSOERTMENT = 'ASSOERTMENT'
export const GLOBAL_PRODUCT_ATTRIBUTES = 'GLOBAL_PRODUCT_ATTRIBUTES'
export const LOOK_ATTRIBUTES = 'LOOK_ATTRIBUTES'
export const BUYING_SESSION_PRODUCT_ATTRIBUTES = 'BUYING_SESSION_PRODUCT_ATTRIBUTES'
export const LOOK_NAME = 'lookName'
export const BUYING_SESSION_PRODUCT_SALES_PERIOD = 'SALES_PERIOD'
export const BUYING_SESSION_PRODUCT_CHANNEL = 'CHANNEL'
export const NO_OF_FILTER_TO_SHOW = 5
export const CLUSTER_STATUS = 'CLUSTER_STATUS'
export const CLUSTER_STATUS_NAME = 'Cluster Status'
export const CLUSTER = 'CLUSTER'
export const LIST_VIEW_PRODUCT_ATTRIBUTES = [
  'modelDescription',
  'color',
  'colorSpecification',
  'serieDescription',
  'manufacturingType'
]

export const ACTIVITY_HIERARCHY_MAP = {
  SHOES: ['line'],
  'LEATHER GOODS': ['family', 'line']
}

export const DEFAULT_ACTIVITY_HIERARCHY = ['family']

export const ACTIVITY_HIERARCHY_MAP_FOR_REPORTING = {
  "Ladies' Bag": ['size'],
  "Ladies' Footwear": ['family'],
  "Men's Bag": ['size'],
  "Men's Footwear": ['family']
}

export const DEFAULT_ACTIVITY_HIERARCHY_FOR_REPORTING = ['activity']
export * from './KeyCodes'
export * from './PlaceHolder'
export * from './ImageUploadFormData'
export * from './WholesaleTags'
export * from './ProductLayoutViews'
export * from './ExternalImages'
export * from './VIPJobStatuses'
export * from './VIPJobNames'
export * from './RestEndPoints'
