import React from 'react'
import Typography from '@components/std-components/Typography'
import { useSAProductDetailsContext } from '../../hook/SAProductDetails-Hook'
import styled from 'styled-components'
import { useObserver } from 'mobx-react-lite'
import * as css from './SAProductDetailClustersPopup.css'

const ClusterColor = styled.div(props => {
  const { color } = props
  return {
    display: 'inline-block',
    width: '15%',
    height: 12,
    flexGrow: 0,
    margin: '1px 4px 1px 0',
    backgroundColor: color,
    borderRadius: '50%'
  }
})

export interface ISAProductDetailClustersPopup {
  label: string
}
export const SAProductDetailClustersForPopup: React.FC<ISAProductDetailClustersPopup> = ({
  label
}) => {
  return useObserver(function useHook() {
    const {
      saProductDetailsStore: { product }
    } = useSAProductDetailsContext()

    return (
      <>
        {product?.retailCMClusters?.length ? (
          <div className={css.clusterContainer}>
            <Typography variant={'label'} color={'label'}>
              {label}
            </Typography>
            <div className={css.clusterRowWrapper}>
              {product.retailCMClusters.map(({ id, name, color }) => (
                <div className={css.clusterRow} key={id}>
                  <ClusterColor color={color} />
                  <Typography
                    variant={'caption2'}
                    classes={css.clusterName}
                    title={name || ''}
                  >
                    {name || ''}
                  </Typography>
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </>
    )
  })
}
