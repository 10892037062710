import { UserRoles } from '@modules/common/models/enums/UserRoles'
import { User } from '@modules/models'
import { storage } from '@services/storageService'
import { strings } from '@stores'
import intersectionBy from 'lodash/intersectionBy'
import { lazy } from 'react'
import { calculateUserRoles } from './calculate-user-roles'

const ReportCollectionContainer = lazy(() =>
  import('@modules/retail/reports/container/ReportCollection').then(m => ({
    default: m.ReportCollectionContainer
  }))
)
const GMReportCollectionContainer = lazy(() =>
  import('@modules/retail/reports/container/GMReportCollectionContainer').then(m => ({
    default: m.GMReportCollectionContainer
  }))
)
const BuyingSessionContainer = lazy(() =>
  import('@modules/retail/buyingSession/container/BuyingSession').then(m => ({
    default: m.BuyingSessionContainer
  }))
)
const CollectionContainer = lazy(() =>
  import('@modules/retail/collection/container/Collection').then(m => ({
    default: m.CollectionContainer
  }))
)
const CollectionBriefContainer = lazy(() =>
  import('@modules/retail/collectionBrief/container/CollectionBrief').then(m => ({
    default: m.CollectionBriefContainer
  }))
)
const LookBookContainer = lazy(() =>
  import('@modules/retail/lookbook/container/LookBook').then(m => ({
    default: m.LookBookContainer
  }))
)
const ActivityCollectionContainer = lazy(() =>
  import('@modules/retail/assortment/container/ActivityCollection').then(m => ({
    default: m.ActivityCollectionContainer
  }))
)
const AssortmentContainer = lazy(() =>
  import('@modules/retail/assortment/container/Assortment').then(m => ({
    default: m.AssortmentContainer
  }))
)
const AssortmentTypeContainer = lazy(() =>
  import('@modules/retail/assortment/container/AssortmentType').then(m => ({
    default: m.AssortmentTypeContainer
  }))
)
const AssortmentSelectionContainer = lazy(() =>
  import('@modules/retail/navigations/container/AssortmentSelection').then(m => ({
    default: m.AssortmentSelectionContainer
  }))
)
const BuyingSessionYearSelectionContainer = lazy(() =>
  import('@modules/retail/navigations/container/BuyingSessionSeasonSelection').then(
    m => ({ default: m.BuyingSessionYearSelectionContainer })
  )
)
const CollectionBriefSelectionContainer = lazy(() =>
  import('@modules/retail/navigations/container/CollectionBriefSelection').then(m => ({
    default: m.CollectionBriefSelectionContainer
  }))
)
const CollectionSegmentSelectionContainer = lazy(() =>
  import('@modules/retail/navigations/container/CollectionSegments').then(m => ({
    default: m.CollectionSegmentSelectionContainer
  }))
)
const CollectionSettingSelectionContainer = lazy(() =>
  import('@modules/retail/navigations/container/CollectionSettingsSelection').then(m => ({
    default: m.CollectionSettingSelectionContainer
  }))
)
const LookBookSelectionContainer = lazy(() =>
  import('@modules/retail/navigations/container/LookBooksSelection').then(m => ({
    default: m.LookBookSelectionContainer
  }))
)
const ReportSelection = lazy(() =>
  import('@modules/retail/navigations/container/ReportSelection').then(m => ({
    default: m.ReportSelectionContainer
  }))
)
const SettingSelectionContainer = lazy(() =>
  import('@modules/retail/navigations/container/SettingsSelection').then(m => ({
    default: m.SettingSelectionContainer
  }))
)
const SettingsContainer = lazy(() =>
  import('@modules/retail/settings').then(m => ({ default: m.SettingsContainer }))
)
const CollectionSettingsContainer = lazy(() =>
  import('@modules/retail/collectionSetting').then(m => ({
    default: m.CollectionSettingsContainer
  }))
)

interface IRoutes {
  path: string | string[]
  isExact: boolean
  component: any
  allowedRoles: { [role: string]: IPermissions }
}

export interface IPermissionsDict {
  cluster?: IPermissions
  store?: IPermissions
  zone?: IPermissions
}
export interface IPermissions {
  canEdit?: boolean
  canRead?: boolean
}

const userRolesWeight = [
  { name: UserRoles.ADMINISTRATOR, weight: 1 },
  { name: UserRoles.CENTRAL_MERCHANDISER_WHOLESALE, weight: 2 },
  { name: UserRoles.ZONE_AREA_MANAGER, weight: 3 },
  { name: UserRoles.WHOLESALE_SHOWROOM_ASSISTANT, weight: 4 },
  { name: UserRoles.EXTERNAL_CLIENT, weight: 5 },
  { name: UserRoles.CM_ADMIN, weight: 6 },
  { name: UserRoles.CENTRAL_MERCHANDISER_RETAIL, weight: 7 },
  { name: UserRoles.REGIONAL_MERCHANDISER, weight: 8 }
]

export const getHighestUser = (): Array<{ name: string; weight: number }> => {
  const user = storage.getItem<User>('user')
  return user ? intersectionBy(userRolesWeight, user.roles, 'name').sort() : []
}

export const getPermissionsByEntityAndRole = (entity: string): IPermissions => {
  const highestUser = getHighestUser()
  const defaultPermissions = { canEdit: false, canRead: false }

  if (highestUser.length) {
    let highestWeight = highestUser[0].weight
    let allRolesWithHighestWeight = highestUser.filter(
      role => role.weight === highestWeight
    )
    let permissions = null
    const highestRoleWithPermission = allRolesWithHighestWeight.find(role => {
      permissions = entityAndRoleWisePermisions[entity][role.name]
      return permissions
    })
    return highestRoleWithPermission ? permissions : defaultPermissions
  }

  return defaultPermissions
}

export const getAssortmentTypePermissions = () => {
  let permissions = {}
  for (let key in entityAndRoleWisePermisions[`${strings.cluster}Assortments`]) {
    permissions[key] = {
      [strings.cluster]:
        entityAndRoleWisePermisions[`${strings.cluster}Assortments`][key],
      [strings.store]: entityAndRoleWisePermisions[`${strings.store}Assortments`][key],
      [strings.zone]: entityAndRoleWisePermisions[`${strings.zone}Assortments`][key]
    }
  }
  return permissions
}

const entityAndRoleWisePermisions = {
  buyingSessions: {
    ...calculateUserRoles(UserRoles.ADMINISTRATOR, 1, 1),
    ...calculateUserRoles(UserRoles.CENTRAL_MERCHANDISER_RETAIL, 1, 1),
    ...calculateUserRoles(UserRoles.REGIONAL_MERCHANDISER, 0, 1)
  },
  collections: {
    ...calculateUserRoles(UserRoles.ADMINISTRATOR, 1, 1),
    ...calculateUserRoles(UserRoles.CENTRAL_MERCHANDISER_RETAIL, 1, 1),
    ...calculateUserRoles(UserRoles.REGIONAL_MERCHANDISER, 0, 1)
  },
  lookbooks: {
    ...calculateUserRoles(UserRoles.ADMINISTRATOR, 1, 1),
    ...calculateUserRoles(UserRoles.CENTRAL_MERCHANDISER_RETAIL, 1, 1),
    ...calculateUserRoles(UserRoles.REGIONAL_MERCHANDISER, 0, 1)
  },
  assortments: {
    ...calculateUserRoles(UserRoles.ADMINISTRATOR, 1, 1),
    ...calculateUserRoles(UserRoles.CENTRAL_MERCHANDISER_RETAIL, 1, 1),
    ...calculateUserRoles(UserRoles.REGIONAL_MERCHANDISER, 0, 1)
  },
  [`${strings.cluster}Assortments`]: {
    ...calculateUserRoles(UserRoles.ADMINISTRATOR, 1, 1),
    ...calculateUserRoles(UserRoles.CENTRAL_MERCHANDISER_RETAIL, 1, 1),
    ...calculateUserRoles(UserRoles.REGIONAL_MERCHANDISER, 0, 1)
  },
  [`${strings.zone}Assortments`]: {
    ...calculateUserRoles(UserRoles.ADMINISTRATOR, 0, 1),
    ...calculateUserRoles(UserRoles.CENTRAL_MERCHANDISER_RETAIL, 0, 1),
    ...calculateUserRoles(UserRoles.REGIONAL_MERCHANDISER, 1, 1)
  },
  [`${strings.store}Assortments`]: {
    ...calculateUserRoles(UserRoles.ADMINISTRATOR, 0, 1),
    ...calculateUserRoles(UserRoles.CENTRAL_MERCHANDISER_RETAIL, 0, 1),
    ...calculateUserRoles(UserRoles.REGIONAL_MERCHANDISER, 1, 1)
  },
  settings: {
    ...calculateUserRoles(UserRoles.ADMINISTRATOR, 1, 1),
    ...calculateUserRoles(UserRoles.CENTRAL_MERCHANDISER_RETAIL, 1, 1),
    ...calculateUserRoles(UserRoles.REGIONAL_MERCHANDISER, 0, 0)
  },
  collectionSettings: {
    ...calculateUserRoles(UserRoles.ADMINISTRATOR, 1, 1),
    ...calculateUserRoles(UserRoles.CENTRAL_MERCHANDISER_RETAIL, 1, 1),
    ...calculateUserRoles(UserRoles.REGIONAL_MERCHANDISER, 0, 0)
  },
  sync: {
    ...calculateUserRoles(UserRoles.ADMINISTRATOR, 1, 1),
    ...calculateUserRoles(UserRoles.CENTRAL_MERCHANDISER_RETAIL, 1, 1),
    ...calculateUserRoles(UserRoles.REGIONAL_MERCHANDISER, 0, 0)
  },
  activityCollection: {
    ...calculateUserRoles(UserRoles.ADMINISTRATOR, 1, 1),
    ...calculateUserRoles(UserRoles.CENTRAL_MERCHANDISER_RETAIL, 1, 1),
    ...calculateUserRoles(UserRoles.REGIONAL_MERCHANDISER, 0, 1)
  },
  bet: {
    ...calculateUserRoles(UserRoles.ADMINISTRATOR, 1, 1),
    ...calculateUserRoles(UserRoles.CENTRAL_MERCHANDISER_RETAIL, 1, 1),
    ...calculateUserRoles(UserRoles.REGIONAL_MERCHANDISER, 0, 1)
  },
  reports: {
    ...calculateUserRoles(UserRoles.ADMINISTRATOR, 1, 1),
    ...calculateUserRoles(UserRoles.CENTRAL_MERCHANDISER_RETAIL, 1, 1),
    ...calculateUserRoles(UserRoles.REGIONAL_MERCHANDISER, 1, 1)
  },
  buy: {
    ...calculateUserRoles(UserRoles.ADMINISTRATOR, 0, 1),
    ...calculateUserRoles(UserRoles.CENTRAL_MERCHANDISER_RETAIL, 0, 1),
    ...calculateUserRoles(UserRoles.REGIONAL_MERCHANDISER, 1, 1)
  },
  seasonSync: {
    ...calculateUserRoles(UserRoles.ADMINISTRATOR, 1, 1),
    ...calculateUserRoles(UserRoles.CENTRAL_MERCHANDISER_RETAIL, 0, 0),
    ...calculateUserRoles(UserRoles.REGIONAL_MERCHANDISER, 0, 0)
  }
}

export const getRoutes = (): IRoutes[] => [
  {
    path: '/buyingSession/:seasonId/gender/:genderId',
    isExact: true,
    component: BuyingSessionContainer,
    allowedRoles: entityAndRoleWisePermisions.buyingSessions
  },
  {
    path: '/buyingSession/:seasonId/gender',
    isExact: true,
    component: BuyingSessionContainer,
    allowedRoles: entityAndRoleWisePermisions.buyingSessions
  },
  {
    path: [
      '/collections/season/:seasonId/gender/:genderId/podium/:podiumId/collectionSettings'
    ],
    isExact: false,
    component: CollectionSettingsContainer,
    allowedRoles: entityAndRoleWisePermisions.collectionSettings
  },
  {
    path: '/collections/season/:seasonId/gender/:genderId/podium/:podiumId/views',
    isExact: false,
    component: CollectionContainer,
    allowedRoles: entityAndRoleWisePermisions.collections
  },
  {
    path: '/collections/season/:seasonId/gender/:genderId/podium/:podiumId/collectionbriefs/channel/:channelId',
    isExact: true,
    component: CollectionBriefContainer,
    allowedRoles: entityAndRoleWisePermisions.collections
  },
  {
    path: '/collections/season/:seasonId/gender/:genderId/podium/:podiumId/collectionbriefs',
    isExact: true,
    component: CollectionBriefContainer,
    allowedRoles: entityAndRoleWisePermisions.collections
  },
  {
    path: '/collections/season/:seasonId/gender/:genderId/container/:podiumId/lookbooks',
    isExact: false,
    component: LookBookContainer,
    allowedRoles: entityAndRoleWisePermisions.lookbooks
  },
  {
    path: '/lookbooks/buyingSessions/:sessionId',
    isExact: true,
    component: LookBookContainer,
    allowedRoles: entityAndRoleWisePermisions.lookbooks
  },
  {
    path: '/collections/season/:seasonId/buyingSession/:buyingSessionId/assortments',
    isExact: true,
    component: AssortmentContainer,
    allowedRoles: entityAndRoleWisePermisions.assortments
  },
  {
    path: '/collections/season/:seasonId/buyingSession/:buyingSessionId/assortments/:assortmentId',
    isExact: true,
    component: AssortmentContainer,
    allowedRoles: entityAndRoleWisePermisions.assortments
  },
  {
    path: '/collections/season/:seasonId/gender/:genderId/buyingSession//buyingSession/:buyingSessionId/assortments',
    isExact: true,
    component: AssortmentContainer,
    allowedRoles: entityAndRoleWisePermisions.assortments
  },
  {
    path: '/collections/season/:seasonId/gender/:genderId/buyingSession/:buyingSessionId/assortments/:assortmentId/podium/:podiumId/activity/:activityId',
    isExact: true,
    component: AssortmentContainer,
    allowedRoles: entityAndRoleWisePermisions.assortments
  },
  {
    path: '/collections/season/:seasonId/gender/:genderId/buyingSession/:buyingSessionId/:assortmentType/assortments/:assortmentId/cluster/:clusterId/podium/:podiumId/activity/:activityId/views/grid',
    isExact: false,
    component: AssortmentTypeContainer,
    allowedRoles: getAssortmentTypePermissions()
  },
  {
    path: '/collections/season/:seasonId/gender/:genderId/buyingSession/:buyingSessionId/:assortmentType/assortments/:assortmentId/podium/:podiumId/activity/:activityId/views/grid',
    isExact: false,
    component: AssortmentTypeContainer,
    allowedRoles: getAssortmentTypePermissions()
  },
  {
    path: '/collections/season/:seasonId/gender/:genderId/buyingSession/:buyingSessionId/:assortmentType/assortments/:assortmentId/cluster/:clusterId/podium/:podiumId/activity/:activityId/views/list',
    isExact: false,
    component: AssortmentTypeContainer,
    allowedRoles: getAssortmentTypePermissions()
  },
  {
    path: '/collections/season/:seasonId/gender/:genderId/buyingSession/:buyingSessionId/:assortmentType/assortments/:assortmentId/podium/:podiumId/activity/:activityId/views/list',
    isExact: false,
    component: AssortmentTypeContainer,
    allowedRoles: getAssortmentTypePermissions()
  },
  {
    path: '/collections/season/:seasonId/gender/:genderId/buyingSession/:buyingSessionId/:assortmentType/assortments/:assortmentId/podium/:podiumId/activity/:activityId/views/kpi',
    isExact: false,
    component: AssortmentTypeContainer,
    allowedRoles: getAssortmentTypePermissions()
  },
  {
    path: '/collections/season/:seasonId/gender/:genderId/buyingSession/:buyingSessionId/podium/:podiumId/activity/:activityId/views',
    isExact: false,
    component: ActivityCollectionContainer,
    allowedRoles: entityAndRoleWisePermisions.activityCollection
  },
  {
    path: ['/settings', '/collections/season/:seasonId/gender/:genderId/settings'],
    isExact: false,
    component: SettingsContainer,
    allowedRoles: entityAndRoleWisePermisions.settings
  },
  {
    path: '/collections/season/:seasonId/gender/:genderId/podium/:podiumId/reports',
    isExact: true,
    component: ReportCollectionContainer,
    allowedRoles: entityAndRoleWisePermisions.reports
  },
  {
    path: '/collections/season/:seasonId/gender/:genderId/podium/:podiumId/gmReports',
    isExact: true,
    component: GMReportCollectionContainer,
    allowedRoles: entityAndRoleWisePermisions.reports
  }
]

export const getNavigationRoutes = (): IRoutes[] => [
  {
    path: '/buyingSession/:seasonId/gender',
    isExact: true,
    component: BuyingSessionYearSelectionContainer,
    allowedRoles: entityAndRoleWisePermisions.buyingSessions
  },
  {
    path: '/buyingSession/:seasonId/gender/:genderId',
    isExact: true,
    component: BuyingSessionYearSelectionContainer,
    allowedRoles: entityAndRoleWisePermisions.buyingSessions
  },
  {
    path: [
      '/collections/season/:seasonId/gender/:genderId/podium/:podiumId/collectionSettings'
    ],
    isExact: false,
    component: CollectionSettingSelectionContainer,
    allowedRoles: entityAndRoleWisePermisions.collectionSettings
  },
  {
    path: '/collections/season/:seasonId/gender/:genderId/podium/:podiumId/reports',
    isExact: true,
    component: ReportSelection,
    allowedRoles: entityAndRoleWisePermisions.reports
  },
  {
    path: '/collections/season/:seasonId/gender/:genderId/podium/:podiumId',
    isExact: false,
    component: CollectionSegmentSelectionContainer,
    allowedRoles: entityAndRoleWisePermisions.collections
  },
  {
    path: '/collections/season/:seasonId/gender/:genderId/podium/:podiumId/list',
    isExact: true,
    component: CollectionSegmentSelectionContainer,
    allowedRoles: entityAndRoleWisePermisions.collections
  },
  {
    path: '/collections',
    isExact: true,
    component: CollectionSegmentSelectionContainer,
    allowedRoles: entityAndRoleWisePermisions.collections
  },
  {
    path: '/collections/season/:seasonId/gender/:genderId/podium/:podiumId/collectionbriefs/channel/:channelId',
    isExact: true,
    component: CollectionBriefSelectionContainer,
    allowedRoles: entityAndRoleWisePermisions.collections
  },
  {
    path: '/collections/season/:seasonId/gender/:genderId/podium/:podiumId/collectionbriefs',
    isExact: true,
    component: CollectionBriefSelectionContainer,
    allowedRoles: entityAndRoleWisePermisions.collections
  },
  {
    path: '/collections/season/:seasonId/gender/:genderId/lookbooks/buyingSessions/:sessionId',
    isExact: true,
    component: LookBookSelectionContainer,
    allowedRoles: entityAndRoleWisePermisions.lookbooks
  },
  {
    path: '/collections/season/:seasonId/gender/:genderId/container/:podiumId/lookbooks',
    isExact: false,
    component: CollectionSegmentSelectionContainer,
    allowedRoles: entityAndRoleWisePermisions.lookbooks
  },
  {
    path: ['/settings', '/collections/season/:seasonId/gender/:genderId/settings'],
    isExact: false,
    component: SettingSelectionContainer,
    allowedRoles: entityAndRoleWisePermisions.settings
  },
  {
    path: '/collections/season/:seasonId/gender/:genderId/buyingSession/:buyingSessionId/assortments',
    isExact: true,
    component: AssortmentSelectionContainer,
    allowedRoles: entityAndRoleWisePermisions.assortments
  },
  {
    path: '/collections/season/:seasonId/gender/:genderId/buyingSession/:buyingSessionId/assortments/:assortmentId',
    isExact: true,
    component: AssortmentSelectionContainer,
    allowedRoles: entityAndRoleWisePermisions.assortments
  },
  {
    path: '/collections/season/:seasonId/gender/:genderId/buyingSession/:buyingSessionId/assortments',
    isExact: true,
    component: AssortmentSelectionContainer,
    allowedRoles: entityAndRoleWisePermisions.assortments
  },
  {
    path: '/collections/season/:seasonId/gender/:genderId/buyingSession/:buyingSessionId/assortments/:assortmentId/podium/:podiumId/activity/:activityId',
    isExact: true,
    component: AssortmentSelectionContainer,
    allowedRoles: entityAndRoleWisePermisions.assortments
  },
  // Todo - combine using path arrays
  {
    path: '/collections/season/:seasonId/gender/:genderId/buyingSession/:buyingSessionId/podium/:podiumId/activity/:activityId/views/grid',
    isExact: false,
    component: AssortmentSelectionContainer,
    allowedRoles: entityAndRoleWisePermisions.assortments
  },
  {
    path: '/collections/season/:seasonId/gender/:genderId/buyingSession/:buyingSessionId/podium/:podiumId/activity/:activityId/views/list',
    isExact: false,
    component: AssortmentSelectionContainer,
    allowedRoles: entityAndRoleWisePermisions.assortments
  },
  {
    path: '/collections/season/:seasonId/gender/:genderId/buyingSession/:buyingSessionId/podium/:podiumId/activity/:activityId/views/kpi',
    isExact: false,
    component: AssortmentSelectionContainer,
    allowedRoles: entityAndRoleWisePermisions.assortments
  },
  {
    path: '/collections/season/:seasonId/gender/:genderId/buyingSession/:buyingSessionId/:assortmentType/assortments/:assortmentId/podium/:podiumId/activity/:activityId/views/grid',
    isExact: false,
    component: AssortmentSelectionContainer,
    allowedRoles: entityAndRoleWisePermisions.assortments
  },
  {
    path: '/collections/season/:seasonId/gender/:genderId/buyingSession/:buyingSessionId/:assortmentType/assortments/:assortmentId/cluster/:clusterId/podium/:podiumId/activity/:activityId/views/grid',
    isExact: false,
    component: AssortmentSelectionContainer,
    allowedRoles: entityAndRoleWisePermisions.assortments
  },
  {
    path: '/collections/season/:seasonId/gender/:genderId/buyingSession/:buyingSessionId/:assortmentType/assortments/:assortmentId/podium/:podiumId/activity/:activityId/views/list',
    isExact: false,
    component: AssortmentSelectionContainer,
    allowedRoles: entityAndRoleWisePermisions.assortments
  },
  {
    path: '/collections/season/:seasonId/gender/:genderId/buyingSession/:buyingSessionId/:assortmentType/assortments/:assortmentId/podium/:podiumId/activity/:activityId/views/kpi',
    isExact: false,
    component: AssortmentSelectionContainer,
    allowedRoles: entityAndRoleWisePermisions.assortments
  },
  {
    path: '/collections/season/:seasonId/gender/:genderId/buyingSession/:buyingSessionId/:assortmentType/assortments/:assortmentId/cluster/:clusterId/podium/:podiumId/activity/:activityId/views/list',
    isExact: false,
    component: AssortmentSelectionContainer,
    allowedRoles: entityAndRoleWisePermisions.assortments
  },
  {
    path: '/buyingSession/:buyingSessionId/gender/:genderId/podium/:podiumId/activity/:activityId/views/table',
    isExact: true,
    component: AssortmentSelectionContainer,
    allowedRoles: entityAndRoleWisePermisions.assortments
  },
  {
    path: '/',
    isExact: true,
    component: BuyingSessionYearSelectionContainer,
    allowedRoles: entityAndRoleWisePermisions.buyingSessions
  }
]
