import { StyledProps } from '@components/3rd-party/mui'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import styled, { css } from 'styled-components'

// const color = 'rgba(0, 0, 0, 0.38)'
const skuStyle = {
  width: 184,
  height: 14,
  fontFamily: 'Roboto',
  fontSize: 12,
  fontWeight: 'normal' as 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  color: '#000000',
  marginTop: 5
}
const nameStyle = {
  width: 184,
  fontFamily: 'Roboto',
  fontSize: 12,
  fontWeight: 'bold' as 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 0.21,
  color: '#000000'
}

interface MyProps extends StyledProps {
  status?: string
  name?
  sku?
}
const ProductInfoComponent: React.FunctionComponent<MyProps> = ({
  status = '',
  className,
  style,
  name,
  sku
}) => {
  return (
    <div className={className} style={style}>
      <Typography style={nameStyle} title={name}>
        {name}
      </Typography>
      <Typography style={skuStyle}>{sku}</Typography>
    </div>
  )
}

export const ProductInfo = styled(ProductInfoComponent).attrs(() => ({
  ellipsis: true,
  'data-type': 'ProductInfo'
}))`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  background-color: white;
  padding: 10px;

  ${p =>
    p.ellipsis &&
    css`
      text-overflow: ellipsis;
      white-space: pre-wrap;
      overflow: hidden;
    `}
`
