import { useQuery } from '@apollo/client'
import GET_CLUSTER_STATUS from './getClusterStatus.graphql'

export const useQueryGetClusterStatus = (channel: string) => {
  const { data, loading, error } = useQuery(GET_CLUSTER_STATUS, {
    skip: !channel,
    variables: {
      channel
    }
  })

  return {
    items: data?.findVIPClusterStatus?.Items,
    loading,
    error
  }
}

export default GET_CLUSTER_STATUS
