import { DropTarget } from '@components/Utils/DropTarget'
import { Cell, ListViewRowProps, Row } from '@modules/collection/components/Common'
import { ListRowSkeleton } from '@modules/common/component/ListRowSkeleton'
import {
  ListViewColumnDefinition,
  ListViewRowSelectionConf
} from '@components/UI-Components/StandardTableView/models/ListviewColumnDefinition'
import { ListViewProduct } from '@modules/common/models/interfaces/ListViewProduct'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import map from 'lodash/map'
import React, { memo } from 'react'
import VisibilitySensor from 'react-visibility-sensor'
import { WHOLESALEPRODUCTROWHEIGHT } from 'src/constant/ProductLayoutViews'

const getProductCells = (
  product,
  stickyStyle,
  headers,
  canTableFitInView,
  rowSelectionConf: ListViewRowSelectionConf,
  emptyColWidth: number
) => {
  return (
    <>
      {map(headers, (col: ListViewColumnDefinition, index) => {
        return (
          <Cell
            key={`${product.id}_${index}`}
            cellWidth={col.width}
            alignment={col.props && col.props.alignment}
            stickyLeft={stickyStyle[index] && stickyStyle[index].left}
            isCellShadow={
              stickyStyle[index] &&
              stickyStyle[index].shouldApplyCellShadow &&
              canTableFitInView
            }
            cellPad={col.isRootColumn ? '5px 0px 0px 0px' : '5px 0px 0px 0px'}
            showLeftBorder={col.product && col.product.showLeftBorder}
            showRightBorder={col.product && col.product.showRightBorder}
            cellZIndex={1}
          >
            {col.product.component ? col.product.component(product, col) : null}
          </Cell>
        )
      })}
      {emptyColWidth ? (
        <Cell
          key={`${product.id}_empty_cell`}
          cellWidth={emptyColWidth}
          stickyRight={0}
        ></Cell>
      ) : null}
      {!isEmpty(rowSelectionConf) ? (
        <Cell
          cellPad={rowSelectionConf.padding ? rowSelectionConf.padding : undefined}
          key={`${product.id}_row_select`}
          cellWidth={rowSelectionConf.width}
          stickyRight={0}
        >
          {rowSelectionConf?.component ? rowSelectionConf.component(product) : null}
        </Cell>
      ) : null}
    </>
  )
}

const ProductRow = props => {
  const {
    product,
    forwardedRef,
    stickyStyle,
    headers,
    canTableFitInView,
    skeletonImageWidth,
    productRowHeight,
    emptyColWidth,
    rowSelectionConf
  } = props
  return (
    <VisibilitySensor partialVisibility>
      {({ isVisible }) => (
        <Row
          height={productRowHeight}
          ref={forwardedRef}
          rowInactive={!product.isActive || product.isRestricted}
        >
          {isVisible ? (
            getProductCells(
              product,
              stickyStyle,
              headers,
              canTableFitInView,
              rowSelectionConf,
              emptyColWidth
            )
          ) : (
            <ListRowSkeleton noMargin imageWidth={skeletonImageWidth} />
          )}
        </Row>
      )}
    </VisibilitySensor>
  )
}
const ProductRowWrapper: React.FunctionComponent<any> = memo(
  ({
    product,
    handleDrop,
    stickyStyle,
    headers,
    canTableFitInView,
    productRowHeight,
    skeletonImageWidth,
    emptyColWidth,
    rowSelectionConf
  }) => {
    return (
      <DropTarget
        showDropBackground
        canDrop={handleDrop ? true : false}
        handleDrop={handleDrop ? handleDrop(product) : null}
      >
        <ProductRow
          emptyColWidth={emptyColWidth}
          rowSelectionConf={rowSelectionConf}
          skeletonImageWidth={skeletonImageWidth}
          productRowHeight={productRowHeight}
          product={product}
          stickyStyle={stickyStyle}
          headers={headers}
          canTableFitInView={canTableFitInView}
          handleDrop={handleDrop}
        />
      </DropTarget>
    )
  },
  (oldProps, newProps) =>
    isEqual(oldProps.product, newProps.product) &&
    isEqual(oldProps.headers, newProps.headers) &&
    isEqual(oldProps.emptyColWidth, newProps.emptyColWidth) &&
    isEqual(oldProps.canTableFitInView, newProps.canTableFitInView)
)

/**
 * Common Category row component which will be used for all 3 categories
 * and in both assortment and collection view
 */
export const ProductContainerRow: React.FunctionComponent<ListViewRowProps> = ({
  products,
  kpiNode,
  stickyStyle,
  headers,
  canTableFitInView,
  handleDrop,
  missingProducts,
  isWholesale,
  isShowroomView,
  skeletonImageWidth,
  emptyColWidth,
  rowSelectionConf,
  productRowHeight
}) => {
  return (
    <Row>
      {map(products, (product: ListViewProduct, index) => {
        return (
          <ProductRowWrapper
            emptyColWidth={emptyColWidth}
            rowSelectionConf={rowSelectionConf}
            skeletonImageWidth={skeletonImageWidth}
            key={product.id}
            product={product}
            stickyStyle={stickyStyle}
            headers={headers}
            canTableFitInView={canTableFitInView}
            handleDrop={handleDrop}
            productRowHeight={productRowHeight || WHOLESALEPRODUCTROWHEIGHT}
          />
        )
      })}
      {map(missingProducts, (product, index) => (
        <ListRowSkeleton key={index} noMargin imageWidth={skeletonImageWidth} />
      ))}
    </Row>
  )
}
