/*
 * @Author: Raeesaa Metkari
 * @Date: 2019-12-12 13:08:20
 * @Last Modified by: Raeesaa Metkari
 * @Last Modified time: 2020-07-10 20:45:35
 */

export enum ColumnWidth {
  ProductInfo = 200,
  StandardProductInfo = 191,
  OrderProductInfo = 156,
  ProductAttributes = 150,
  Date = 120,
  Status = 115,
  DeliveryDate = 135,
  Comments = 300,
  Large = 160,
  XL = 200,
  XXL = 250,
  Medium = 110,
  Small = 80,
  XS = 70,
  XXS = 60,
  ICON = 50,
  Campaign = 550,
  OrderTotal = 120
}
