/*
 * @Author: Raeesaa Metkari
 * @Date: 2019-09-06 01:25:40
 * @Last Modified by: Raeesaa Metkari
 * @Last Modified time: 2019-09-07 19:38:32
 */
import { MutationFunctionOptions } from '@apollo/client/react/types/types'
import { nav } from '@stores/nav'
import { ExecutionResult } from 'graphql/execution'
import { action, observable } from 'mobx'
import { IUserNotification, NOTIFICATIONS_FOR_USER } from '../graphql'

export class NotificationStore {
  @observable isMarkAllAsReadInProgress: boolean = false
  mutations: {
    markNotificationAsRead?: (
      options?: MutationFunctionOptions<
        { userNotification: IUserNotification },
        { id: string; clientId?: string; buyingSessionGroupId?: string }
      >
    ) => Promise<void | ExecutionResult<{
      userNotification: IUserNotification
    }>>
    markAllUserNotificationsAsRead?: (
      options?: MutationFunctionOptions
    ) => Promise<void | ExecutionResult<{
      count: number
    }>>
  } = {}

  buildActorDescription = actor => {
    let nameOfUser = ''

    if (actor) {
      nameOfUser += actor.firstName + (actor.firstName ? ' ' : '') + actor.lastName
      if (actor.membership && actor.membership.zones && actor.membership.zones.length) {
        let zoneNames = actor.membership.zones.map(zone => zone.name || '--')
        nameOfUser += ` (${zoneNames.join(', ')})`
      }
    }

    return nameOfUser ? `by ${nameOfUser}` : ''
  }

  @action markNotificationAsRead = (id: string, redirectUrl?: string) => {
    this.mutations.markNotificationAsRead({
      variables: {
        id
      }
    })

    if (redirectUrl) {
      const { history } = nav
      history.push(redirectUrl)
    }
  }

  @action markAllNotificationsAsRead = () => {
    if (!this.isMarkAllAsReadInProgress) {
      this.isMarkAllAsReadInProgress = true
      this.mutations
        .markAllUserNotificationsAsRead({
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: NOTIFICATIONS_FOR_USER
            }
          ]
        })
        .finally(() => {
          this.isMarkAllAsReadInProgress = false
        })
    }
  }
}

export const notification = new NotificationStore()
