import { QueryResult } from '@apollo/client/react'
import { Query } from '@apollo/client/react/components/Query'
import { CircularLoading } from '@components/UI-Components/CircularLoading'
import { GET_CHANNEL } from '@modules/retail/assortment/graphql/getChannels'
import { ClusterSetup } from '@modules/retail/clusterSetup/component'
import { GET_CLUSTER_SETUP } from '@modules/retail/clusterSetup/graphql'
import Typography from '@material-ui/core/Typography'
import isEmpty from 'lodash/isEmpty'
import React from 'react'

export class ClusterSetupContainer extends React.Component {
  render() {
    return (
      <Query query={GET_CHANNEL}>
        {({
          loading: channelLoading,
          error,
          data: { channels } = { channels: [] }
        }: QueryResult) => {
          if (channelLoading) return <CircularLoading />
          return (
            <Query
              query={GET_CLUSTER_SETUP}
              skip={channels.length > 0 ? false : true}
              variables={{ channel: channels.length > 0 && channels[0].id }}
              fetchPolicy={'cache-and-network'}
            >
              {({
                loading,
                data: { storeData = [], genders, clusters, activities } = {
                  storeData: [],
                  genders: [],
                  clusters: [],
                  activities: []
                }
              }) => {
                if (loading) return <CircularLoading />
                if (error) return null
                if (storeData && !isEmpty(storeData)) {
                  return (
                    <ClusterSetup
                      genders={genders}
                      clusters={clusters}
                      activities={activities}
                      stores={storeData}
                    />
                  )
                } else {
                  return (
                    <div
                      style={{
                        margin: 24
                      }}
                    >
                      <Typography variant='body1'>NO STORE DATA AVAILABLE.</Typography>
                    </div>
                  )
                }
              }}
            </Query>
          )
        }}
      </Query>
    )
  }
}
