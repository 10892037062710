import { AddIcon } from '@components/UI-Components/svg'
import CircleChecked from '@material-ui/icons/CheckCircleOutline'
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { SANumberInput } from './SANumberInput'
import * as css from './SASizeInput.css'

interface IInputData {
  isChecked?: boolean
  inProgress?: boolean
  checkboxLabel: string
  inputValue?: string
  targetQuantityLabel?: string
  sizedQuantityLabel?: string
  totalSizedQuantity?: string
  isRecommended?: boolean
}

interface SASizeInputInterface {
  data?: IInputData
  onClickAdd?: any
  isAddDisabled?: boolean
  isCheckedDisabled?: boolean
  onCheckedChange?: any
  handleInputChange?: any
  handleKeyDown?: any
  isInputDisabled?: boolean
  showQuantityLabel?: boolean
}

// interface SARecommended {
//   isRecommended: boolean
// }

// const INPUT_WIDTH = 45
// const INPUT_HEIGHT = 30

const StyledSpan = styled.span<Partial<{ isRecommended }>>(props => {
  return {
    color: props.isRecommended ? 'green' : 'black',
    font: 'var(--typography-body3)',
    textTransform: 'uppercase',
    letterSpacing: 0.21
  }
})

const StyledDiv = styled.div<Partial<SASizeInputInterface>>`
  display: flex;
  flex-direction: column;

  .checkboxWrapper {
    align-self: flex-end;
    padding-bottom: 20px;
  }

  .checkboxSASizeInput {
    padding: 0px;
  }

  .iconButton {
    padding: 0px;
    cursor: pointer;
  }

  .spinning {
    animation-name: spinning;
    animation-duration: 4000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @keyframes spinning {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .textInboxWrapper {
    padding-bottom: 20px;
  }

  .checkboxLabel,
  .textInputLabel,
  .sizedQuantityLabel {
    font: var(--typography-body3);
    text-transform: uppercase;
    letter-spacing: 0.21px;
  }

  .sizedQuantityValue {
    font: var(--typography-body4);
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`

export const SASizeInput: React.FunctionComponent<SASizeInputInterface> = ({
  onClickAdd,
  isAddDisabled,
  isCheckedDisabled,
  onCheckedChange,
  handleInputChange,
  handleKeyDown,
  isInputDisabled,
  showQuantityLabel = true,
  data
}) => {
  // Local state for maintaining checkbox checked state
  const [isChecked, setChecked] = useState(false)
  useEffect(() => {
    setChecked(data?.isChecked || false)
  }, [data?.isChecked])

  const onInputChange = event => {
    if (event.target.checked === true) {
      setChecked(event.target.checked)
    }
    onCheckedChange(event)
  }

  return (
    <StyledDiv>
      {data ? (
        <>
          <div className='checkboxWrapper'>
            <Checkbox
              classes={{ root: 'checkboxSASizeInput' }}
              icon={
                isChecked ? (
                  <CircleChecked
                    classes={{
                      root: 'iconButton'
                    }}
                  />
                ) : (
                  <CircleUnchecked
                    classes={{
                      root: 'iconButton'
                    }}
                  />
                )
              }
              checkedIcon={<CircleChecked />}
              checked={isChecked}
              onChange={onInputChange}
              color='default'
              disabled={isCheckedDisabled}
            />
            <div className={'checkboxLabel'}>
              <StyledSpan isRecommended={data?.isRecommended}>
                {data?.checkboxLabel}
              </StyledSpan>
            </div>
          </div>
          <div className='textInboxWrapper'>
            <div className={'textInputLabel'}>
              <span>{data?.targetQuantityLabel}</span>
            </div>
            <div className={css.sizeInput}>
              <SANumberInput
                handleInputChange={handleInputChange}
                handleKeyDown={handleKeyDown}
                isInputDisabled={isInputDisabled}
                inputValue={data?.inputValue}
              />
            </div>
          </div>
          {showQuantityLabel ? (
            <div className='sizedQuantityWrapper'>
              <div className={'sizedQuantityLabel'}>
                <span>{data?.sizedQuantityLabel}</span>
              </div>
              <div className={'sizedQuantityValue'}>
                <span>{data?.totalSizedQuantity}</span>
              </div>
            </div>
          ) : null}
        </>
      ) : (
        <div>
          <IconButton
            aria-label='delete'
            size='small'
            onClick={isAddDisabled ? null : onClickAdd}
            disabled={isAddDisabled}
          >
            <img src={AddIcon} className={isAddDisabled ? css.disable : null} alt='' />
          </IconButton>
        </div>
      )}
    </StyledDiv>
  )
}
