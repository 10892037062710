/**
 * properties have same name as that of `SidebarNavTypes` enum values
 */

export const SIDEBAR_CONFIG = {
  clientSelection: {
    url: '/showroom/clients',
    label: 'Client Selection',
    parentLabel: null
  },
  buyingSessionGroup: {
    url: '/showroom/clients/buyingSessionGroup',
    label: 'Buying Session Group',
    parentLabel: null
  },
  presentation: {
    url: '/showroom/clients/buyingSessionGroup/presentation',
    label: 'Presentation',
    parentLabel: 'Showroom'
  },
  lookbook: {
    url: '/showroom/clients/buyingSessionGroup/lookbook',
    label: 'Lookbook',
    parentLabel: 'Showroom'
  },
  clientCollection: {
    url: '/showroom/clients/buyingSessionGroup/activities/client-collection',
    label: 'Collection',
    parentLabel: 'Collection'
  },
  clientWishlist: {
    url: '/showroom/clients/buyingSessionGroup/activities/client-wishlist',
    label: 'Wishlist',
    parentLabel: 'Wishlist'
  },
  basketAndOrders: {
    url: '/showroom/clients/buyingSessionGroup/basketAndOrders',
    label: 'My Orders',
    parentLabel: 'My Orders'
  },
  buyingSessionActivities: {
    url: '/showroom/clients/buyingSessionGroup/activities',
    label: 'My Orders',
    parentLabel: 'My Orders'
  },
  collection: {
    url: '/showroom/clients/buyingSessionGroup/activities/collection',
    label: 'My Orders',
    parentLabel: 'My Orders'
  },
  order: {
    url: '/showroom/clients/buyingSessionGroup/activities/order',
    label: 'My Orders',
    parentLabel: 'My Orders'
  },
  buyingBoard: {
    url: '/wholesale',
    label: 'Buying Board',
    parentLabel: null
  },
  saShowroom: {
    url: '/showroom',
    label: 'Showroom',
    parentLabel: null
  }
}
