import { withApollo } from '@apollo/client/react/hoc/withApollo'
import { SecondaryButton } from '@components/UI-Components/SecondaryButton'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { BUYING_SESSION_PRODUCT } from '@modules/retail/collection/graphql/buyingSessionProduct'
import { stores, strings } from '@stores'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { STORE_ASSORTMENT_PRODUCTS_CONNECTION } from '@modules/retail/assortment/graphql/storeAssortmentProductsConnection'
import { ZONE_ASSORTMENT_PRODUCTS_CONNECTION } from '@modules/retail/assortment/graphql/zoneAssortmentProductsConnection'

interface IDeleteAssortmentProductDialog {
  assortmentProductId?: any
  product: any
  assortmentId: string
  client?: any
  match?: any
}

@(withRouter as any)
@(withApollo as any)
@observer
export class DeleteAssortmentProductDialog extends React.Component<IDeleteAssortmentProductDialog> {
  @observable deleteInProgress = false

  removeProductFromAssortment = async () => {
    const [
      {
        props: {
          match: {
            params: { assortmentId, clusterId }
          },
          product,
          assortmentProductId,
          client,
          match
        }
      },
      {
        product: {
          buildRefetchQueriesForAttributeHierarchy,
          getHierarchyMetaFromBuyingSessionProduct,
          executeAssortmentRefetchQueries
        },
        assortment: {
          getAssortmentType,
          assortmentTypeMapping,
          closeDeleteModal,
          removeBuyerClusterFromZoneAssortmentProduct
        },
        sidebar: { isRegionalMerchandiser }
      }
    ] = [this, stores]

    Object.assign(this, { deleteInProgress: true })
    const assortmentType = getAssortmentType(match)

    let refetchQueries = []
    if (product) {
      let object = getHierarchyMetaFromBuyingSessionProduct(product)
      if (object) {
        refetchQueries = buildRefetchQueriesForAttributeHierarchy(
          [object],
          assortmentType
        )
      }
    }

    try {
      if (isRegionalMerchandiser() && clusterId) {
        const busyingSessionProduct = product
        removeBuyerClusterFromZoneAssortmentProduct(
          clusterId,
          assortmentProductId,
          busyingSessionProduct
        )
        await client.query({
          query: BUYING_SESSION_PRODUCT,
          variables: {
            id: busyingSessionProduct.id,
            includeUserFavourite: false
          }
        })
      } else {
        // In case of zone / store assortment, refetch assortment product count on deletion
        if (assortmentType === strings.zone || assortmentType === strings.store) {
          refetchQueries.push({
            query:
              assortmentType === strings.store
                ? STORE_ASSORTMENT_PRODUCTS_CONNECTION
                : ZONE_ASSORTMENT_PRODUCTS_CONNECTION,
            variables: {
              assortmentId
            }
          })
        }

        await client
          .mutate({
            mutation: assortmentTypeMapping[assortmentType].removeProductMutation,
            variables: {
              assortmentId,
              productIds: [assortmentProductId]
            },
            refetchQueries
          })
          .then(() => {
            // Refresh counts
            executeAssortmentRefetchQueries()
          })
      }
    } finally {
      Object.assign(this, { deleteInProgress: false })
      closeDeleteModal()
    }
  }

  render() {
    const [
      { removeProductFromAssortment, deleteInProgress },
      {
        assortment: { openDeleteConfirmationPopUp, closeDeleteModal }
      }
    ] = [this, stores]

    return (
      <Dialog
        open={openDeleteConfirmationPopUp}
        onClose={closeDeleteModal}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Remove Product</DialogTitle>
        <DialogContent>
          <DialogContentText
            id='alert-dialog-description'
            style={{ color: `rgba(0, 0, 0, 0.87)` }}
          >
            Are you sure you want to remove this product ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <SecondaryButton onClick={closeDeleteModal}>Cancel</SecondaryButton>
          <SecondaryButton
            autoFocus
            onClick={removeProductFromAssortment}
            disabled={deleteInProgress}
          >
            Delete
          </SecondaryButton>
        </DialogActions>
      </Dialog>
    )
  }
}
