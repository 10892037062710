import React from 'react'
import Typography from '../Typography'
import cn from 'classnames'
import {
  ISwitchMedia,
  ProductMediaType,
  SwitchMedia
} from '@components/std-components/ProductMedia/SwitchMedia'
import '@styles/ProductMedia/style.css'

export interface IProductMedia extends ISwitchMedia {
  watermark?: string
  watermarkImage?: string
  onClick?: (...rest: any[]) => void
  className?: string
  /**
   * Sets active controls product media
   * use when prod media is used independently
   * and not with prod card
   */
  active?: boolean
  mediaVariant?: 'standalone' | 'card'
}

const ProductMedia: React.FC<IProductMedia> = (props): JSX.Element => {
  const { onClick, watermark, className, type, media, active, mediaVariant } = props
  // const mediaObj = useMemo(
  //   () =>
  //     type === ProductMediaType.staticImage ? { ...media, mediaVariant } : { ...media },
  //   [media, mediaVariant]
  // )
  return (
    <div
      className={cn([
        'product-media',
        { 'product-media-inactive': !active },
        { 'product-media-card': mediaVariant === 'card' },
        className
      ])}
      onClick={onClick}
    >
      <SwitchMedia media={media} type={type} />
      {watermark && (
        <Typography variant={'h2'} classes={'product-media-watermark'}>
          {watermark}
        </Typography>
      )}
    </div>
  )
}

ProductMedia.defaultProps = {
  type: ProductMediaType.staticImage,
  active: true,
  mediaVariant: 'standalone'
}

export default ProductMedia
