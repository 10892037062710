import { CentricTheme, StyledProps } from '@components/3rd-party/mui'
import TabletMediaQuery from '@components/Utils/TabletMediaQuery'
import Button, { ButtonProps } from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'

const styles = (theme: CentricTheme) => ({
  root: {
    //...theme.styles.components.button,
    background: 'transparent',
    color: theme.palette.primary.main
  },
  rootTablet: {
    background: 'transparent',
    borderRadius: 0,
    marginHorizontal: 0
  },
  disabled: {
    color: 'rgba(0, 0, 0, 0.38)',
    pointerEvents: 'none'
  },
  buttonLabel: {
    marginTop: -1
  },
  buttonProgress: {
    color: theme.colors.black,
    margin: `0 ${theme.spacing(1)}`
  },
  loading: {
    cursor: 'progress',
    pointerEvents: 'none'
  }
})

interface MyProps extends StyledProps, React.ButtonHTMLAttributes<HTMLElement> {
  /** Boolean indicating whether the button should render as disabled */
  disabled?: boolean
  component?: any
  color?: any
  type?: 'button' | 'submit' | 'reset'
  fullWidth?: boolean
  loading?: boolean
}
export class SecondaryButtonComponent extends React.Component<MyProps & ButtonProps> {
  static defaultProps = {
    disabled: false,
    onClick: () => {}
  }

  render() {
    const {
      props: {
        classes,
        className,
        onClick,
        disabled,
        children,
        href,
        loading,
        ...otherProps
      }
    } = this
    const LinkBehavior = React.forwardRef((props: any, ref: any) => {
      return <Link ref={ref} {...props} to={href} />
    })
    return (
      <TabletMediaQuery>
        {isTablet => (
          <Button
            classes={{
              root: isTablet ? classes.rootTablet : classes.root,
              disabled: classes.disabled
            }}
            className={classNames(loading ? classes.loading : '', className)}
            variant='text'
            color={'primary' as any}
            disabled={disabled}
            onClick={href || loading ? undefined : onClick}
            component={!href ? undefined : LinkBehavior}
            {...otherProps}
          >
            {children}
            {loading && (
              <CircularProgress
                color='secondary'
                size={24}
                className={`${classes.buttonProgress}`}
              />
            )}
          </Button>
        )}
      </TabletMediaQuery>
    )
  }
}

@((withStyles as any)(styles))
export class SecondaryButton extends SecondaryButtonComponent {}
