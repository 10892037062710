import { withApollo } from '@apollo/client/react/hoc/withApollo'
import { CentricTheme, StyledProps } from '@components/3rd-party/mui'
import { ProductActionItem } from '@components/UI-Components/ProductCardOld/ProductActions'
import { ProductInfoItem } from '@components/UI-Components/ProductCardOld/ProductInfoItem'
import { ProductMedia } from '@components/UI-Components/ProductCardOld/ProductMedia'
import { ProductStatus } from '@components/UI-Components/ProductCardOld/ProductStatus'
import { ProductDescription } from '@components/UI-Components/ProductDetail/ProductDescription'
import { ProductDescriptionItem } from '@components/UI-Components/ProductDetail/ProductDescriptionItem'
import { ProductDetailActions } from '@components/UI-Components/ProductDetail/ProductDetailActions'
import { ProductDetailHeader } from '@components/UI-Components/ProductDetail/ProductDetailHeader'
import { ProductDetailInfo } from '@components/UI-Components/ProductDetail/ProductDetailInfo'
import { ProductLook } from '@components/UI-Components/ProductDetail/ProductLook'
import { SelectField } from '@components/UI-Components/SelectField'
import { CrossIcon } from '@components/UI-Components/svg'
import { TextInput } from '@components/UI-Components/TextInput'
import { User } from '@models'
import {
  NON_RETAIL_CORE_PRODUCT_MSG,
  RESTRICTED_PRODUCT_MSG
} from '@modules/collection/constants'
import { UserRoles } from '@modules/common/models/enums/UserRoles'
import { constants } from '@modules/retail/buyingSession'
import { MARK_PRODUCTS_CHANNEL_EXCLUSIVE } from '@modules/retail/collection/graphql/makeProductChannelExclusive'
import { ADD_FAVOURITE_TO_PRODUCT } from '@modules/retail/collection/graphql/markFavourite'
import { REMOVE_FAVOURITE_FROM_PRODUCT } from '@modules/retail/collection/graphql/removeFavourite'
import { GET_PRODUCT_DETAILS } from '@modules/retail/productDetail/graphql/productDetails'
import { ADD_RATING } from '@modules/retail/productDetail/graphql/addProductRating'
import Chip from '@material-ui/core/Chip'
import Divider from '@material-ui/core/Divider'
import FormControl from '@material-ui/core/FormControl'
import Icon from '@material-ui/core/Icon'
import InputLabel from '@material-ui/core/InputLabel'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { IPermissions } from '@routes'
import { getRatings } from '@services/getRatings'
import { getImageUrl } from '@services/imageService'
import {
  getAttributeTagIdForProduct,
  getAttributeValuesForProduct,
  isRestrictedToComment
} from '@services/productDetailService'
import { storage } from '@services/storageService'
import { stores, strings } from '@stores'
import classNames from 'classnames'
import differenceBy from 'lodash/differenceBy'
import Rating from 'material-ui-rating'
import React from 'react'
import { ProductLookContainer } from '../../lookbook/component/ProductLookContainer'
import { PRODUCT_STATUSES } from '../constants'
import { BuyingSessionProductSizeAttributesContainer } from '../container/BuyingSessionProductSizeAttributesContainer'
import { CommentContainer } from '../container/Comment'
import {
  getProductDetail,
  isRetailsChannelRemovable
} from '../services/ProductDetailServices'

const LARGE_IMAGE_STYLE = 422

const styles = (theme: CentricTheme) => ({
  producDetailWithColorbox: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    flex: 2
  },
  selectField: {
    paddingRight: 5,
    height: 40
  },
  editableSelectField: {
    padding: 5,
    marginRight: 20,
    height: 40
  },
  divider: {
    margin: '20px 0px 10px 0px'
  },
  flexContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  overFlow: {
    overflowY: 'auto',
    maxHeight: '250px'
  },
  lookConatiner: {
    width: LARGE_IMAGE_STYLE
  },
  attributeItem: {
    width: 150,
    padding: `0 calc(${theme.spacing(1)} / 2)`
  },
  flexLeftContent: {
    display: 'flex',
    justifyContent: 'left'
  },
  flexRowContent: {
    display: 'flex'
  },
  productAction: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-evenly',
    cursor: 'pointer'
  },
  productActionWithOutPointer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  greyColor: {
    color: theme.colors.grey
  },
  image: {
    marginRight: 10
  },
  chip: {
    margin: theme.spacing(1)
  },

  lookNameMargin: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  topMargin: {
    marginTop: theme.spacing(2)
  },
  label: {
    paddingLeft: 4,
    paddingRight: 4,
    marginLeft: 4,
    marginRight: 4
  },
  inactive: {
    opacity: '0.5'
  },
  attributes: {
    whiteSpace: 'normal',
    wordWrap: 'break-word'
  },
  formControl: {
    paddingRight: 10
  },
  selectFieldLabel: {
    color: 'rgba(0, 0, 0, 0.38)'
  },
  restrictedZoneMsg: {
    marginTop: 16,
    color: '#ffab07'
  },
  hasNoRetailChannelMsg: {
    marginTop: 16,
    color: '#ffab07'
  },
  addChannelAlignItems: {
    display: 'flex',
    alignItems: 'center'
  },
  marginRight: {
    marginRight: theme.spacing(1)
  }
})

interface MyProps extends StyledProps {
  loading: boolean
  data: any
  handleProductStatusChange?: (e, buyingSessionProduct: any) => void
  handleProductAttributeChange?: (productId, definitionId, e) => void
  handleStatusChange?: (isActive) => void
  updateProductMOQ?: (value) => void
  client?: any
  allChannels?: any[]
  ratingTypes?: any
  permissions?: IPermissions
}

@((withStyles as any)(styles))
@(withApollo as any)
export class ProductDetail extends React.Component<MyProps> {
  state = {
    moq: 0,
    anchorEl: null,
    favouriteUpdateInProgress: false
  }

  static getDerivedStateFromProps(props, state) {
    const { moq = 0 } = props.data.buyingSessionProduct
      ? getProductDetail(props.data.buyingSessionProduct)
      : {}

    return {
      moq: state.moq === 0 ? moq : state.moq
    }
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  markFavourite = async (selectedProductId, isActive) => {
    if (isActive) {
      this.setState({
        favouriteUpdateInProgress: true
      })

      const { client } = this.props
      const user = storage.getItem<User>('user')

      try {
        await client.mutate({
          mutation: ADD_FAVOURITE_TO_PRODUCT,
          variables: { productID: selectedProductId, userId: user.id }
        })

        // Read the data from our cache for this query.
        const { buyingSessionProduct } = client.readQuery({
          query: GET_PRODUCT_DETAILS,
          variables: {
            productId: selectedProductId,
            userID: user.id
          }
        })

        // Write our data back to the cache.
        client.writeQuery({
          query: GET_PRODUCT_DETAILS,
          variables: {
            productId: selectedProductId,
            userID: user.id
          },
          data: { buyingSessionProduct: buyingSessionProduct }
        })
      } finally {
        this.setState({
          favouriteUpdateInProgress: false
        })
      }
    }
  }

  removeFavourite = async (selectedProductId, isActive) => {
    if (isActive) {
      this.setState({
        favouriteUpdateInProgress: true
      })
      const { client } = this.props
      const user = storage.getItem<User>('user')

      try {
        await client.mutate({
          mutation: REMOVE_FAVOURITE_FROM_PRODUCT,
          variables: { productID: selectedProductId },
          optimisticResponse: {
            __typename: 'Mutation',
            removeUserFavourite: {
              __typename: 'Favourite',
              id: selectedProductId
            }
          },
          update: (proxy, data) => {
            const {
              data: { removeUserFavourite }
            } = data
            // Read the data from our cache for this query.
            const { buyingSessionProduct } = proxy.readQuery({
              query: GET_PRODUCT_DETAILS,
              variables: {
                productId: selectedProductId,
                userID: user.id
              }
            })
            let totalFavourites = buyingSessionProduct.totalFavourites.filter(
              ({ id }) => id !== removeUserFavourite.id
            )
            buyingSessionProduct.totalFavourites = totalFavourites
            buyingSessionProduct.favourites = []

            // Write our data back to the cache.
            proxy.writeQuery({
              query: GET_PRODUCT_DETAILS,
              variables: {
                productId: selectedProductId,
                userID: user.id
              },
              data: { buyingSessionProduct: buyingSessionProduct }
            })
          }
        })
      } finally {
        this.setState({
          favouriteUpdateInProgress: false
        })
      }
    }
  }

  markProductChannelExclusive = async (channel, productId, isActive) => {
    if (isActive) {
      const {
        props: { client }
      } = this
      const user = storage.getItem<User>('user')

      await client.mutate({
        mutation: MARK_PRODUCTS_CHANNEL_EXCLUSIVE,
        variables: {
          channelId: channel.id,
          productId
        }
      })
      const { buyingSessionProduct } = client.readQuery({
        query: GET_PRODUCT_DETAILS,
        variables: {
          productId: productId,
          userID: user.id
        }
      })
      buyingSessionProduct.channel = channel
      client.writeQuery({
        query: GET_PRODUCT_DETAILS,
        variables: {
          productId: productId,
          userID: user.id
        },
        data: { buyingSessionProduct: buyingSessionProduct }
      })
    }
  }

  addProductRating = async (productId, rating, value, isActive) => {
    if (isActive) {
      const {
        props: { client }
      } = this
      const typeId = rating.id
      const user = storage.getItem<User>('user')

      // check to remove rating
      const rating_value = rating.value === value ? 0 : value
      await client.mutate({
        mutation: ADD_RATING,
        variables: {
          productId,
          typeId,
          value: rating_value
        }
      })

      await client.query({
        query: GET_PRODUCT_DETAILS,
        variables: {
          productId: productId,
          userID: user.id
        },
        fetchPolicy: 'network-only'
      })
    }
  }

  render() {
    const [
      {
        props: {
          classes,
          data,
          loading,
          handleProductStatusChange,
          allChannels,
          ratingTypes,
          permissions
        },
        state: { anchorEl, favouriteUpdateInProgress },
        handleClose,
        handleClick,
        markProductChannelExclusive,
        addProductRating
      },
      {
        nav: {
          params: { assortmentType }
        },
        sidebar: { isRegionalMerchandiser },
        product: {
          attributeDefWithTag,
          handleProductAttributeChange,
          toggleChannelDeleteConfirmationDialog
        }
      }
    ] = [this, stores]

    const {
      color = { label: strings.lookProductColor, value: '' },
      image = {},
      productStatus = '',
      buyingSessionProductAttributes = [],
      retailStatus = { label: strings.lookProductRetailStatus, value: '' },
      looks = [],
      substitutedInLooks = [],
      favourites = [],
      id = '',
      channels = [],
      ratings = [],
      avgRatingCount = '',
      attributes = [],
      active = true,
      description = '',
      favouriteCount = [],
      restrictedZones = []
    } = loading ? {} : getProductDetail(data.buyingSessionProduct)
    const {
      buyingSessionProduct: {
        buyingSession,
        product: { id: productId }
      }
    } = data
    const productRatings = getRatings(ratings, ratingTypes)
    const user = storage.getItem<User>('user')
    const role = user.roles[0].name
    const zones = user.membership && user.membership.zones
    const isRestricted = isRestrictedToComment(zones, restrictedZones)

    const isExcludedFromZone =
      isRegionalMerchandiser() && restrictedZones.length ? true : false
    const hasRetailChannel = channels.find(
      channel => channel.name === strings.retailChannelName
    )
    const isClosedOrFrozen = buyingSession
      ? buyingSession.status === constants.CLOSED ||
        (isRegionalMerchandiser() && buyingSession.status === constants.FROZEN)
      : false
    return (
      <div>
        <ProductDetailHeader title={'Product Detail'}></ProductDetailHeader>
        <ProductDetailInfo>
          <div className={classNames({ [classes.inactive]: !active })}>
            <ProductMedia
              image={getImageUrl(image && image.id, 'large')}
              height={422}
              width={422}
              imgWidth={334}
              className={classes.image}
            />
            {looks.length ? (
              <div className={classNames(classes.lookConatiner)}>
                <Typography
                  variant='body1'
                  style={{
                    color: 'rgba(0, 0, 0, 0.54',
                    marginBottom: '10px',
                    marginTop: '10px'
                  }}
                >
                  Associated in Looks
                </Typography>
                <ProductLook shouldScroll={true}>
                  <ProductLookContainer looks={looks} />
                </ProductLook>
              </div>
            ) : null}
            {substitutedInLooks.length ? (
              <div className={classNames(classes.lookConatiner)}>
                <Typography
                  variant='body1'
                  style={{
                    color: 'rgba(0, 0, 0, 0.54',
                    marginBottom: '10px',
                    marginTop: '10px'
                  }}
                >
                  Substituted in Looks
                </Typography>
                <ProductLook shouldScroll={true}>
                  <ProductLookContainer looks={substitutedInLooks} />
                </ProductLook>
              </div>
            ) : null}
          </div>

          <ProductDescription>
            <ProductDescriptionItem>
              <ProductDetailActions>
                <div className={classes.status}>
                  <ProductActionItem>
                    <ProductStatus
                      status={active ? 'active' : 'inactive'}
                      style={{ top: 6 }}
                    />
                  </ProductActionItem>
                </div>
                <div
                  className={
                    isExcludedFromZone
                      ? classes.productActionWithOutPointer
                      : classes.productAction
                  }
                >
                  <ProductActionItem>
                    <Icon
                      color={
                        favourites.length && !isExcludedFromZone && !isClosedOrFrozen
                          ? 'error'
                          : 'disabled'
                      }
                      onClick={() =>
                        !favourites.length &&
                        !isExcludedFromZone &&
                        !isClosedOrFrozen &&
                        !favouriteUpdateInProgress
                          ? this.markFavourite(id, active)
                          : !isExcludedFromZone &&
                            !isClosedOrFrozen &&
                            !favouriteUpdateInProgress &&
                            this.removeFavourite(id, active)
                      }
                    >
                      favorite
                    </Icon>
                    <Typography variant='caption'>{favouriteCount}</Typography>
                  </ProductActionItem>
                  <ProductActionItem>
                    <Icon style={{ color: avgRatingCount ? '#FF9800' : 'lightgrey' }}>
                      star
                    </Icon>
                    {avgRatingCount > 0 && (
                      <Typography variant='caption'>{avgRatingCount}</Typography>
                    )}
                  </ProductActionItem>
                </div>
              </ProductDetailActions>
            </ProductDescriptionItem>
            <ProductDescriptionItem>
              <Typography variant='h4'>{description}</Typography>
            </ProductDescriptionItem>
            <ProductDescriptionItem>
              <Typography variant='subtitle1' className={classes.greyColor}>
                {color ? color.value : 'NA'}
              </Typography>
            </ProductDescriptionItem>

            <ProductDescriptionItem>
              <div className={classes.addChannelAlignItems}>
                {channels &&
                  channels.length &&
                  channels.map(channel => (
                    <Chip
                      key={channel.id}
                      classes={{
                        label: classes.label
                      }}
                      label={channel.name}
                      className={classes.chip}
                      deleteIcon={<img src={CrossIcon} alt='cross' />}
                      onDelete={
                        channel.name === strings.retailChannelName &&
                        productStatus !== strings.productStatus.CORE &&
                        role !== UserRoles.REGIONAL_MERCHANDISER &&
                        isRetailsChannelRemovable(assortmentType) &&
                        !isClosedOrFrozen
                          ? () =>
                              toggleChannelDeleteConfirmationDialog(
                                id,
                                channel.id,
                                buyingSession ? buyingSession.status : null
                              )
                          : null
                      }
                    />
                  ))}
                {permissions.canEdit &&
                  allChannels.length > channels.length &&
                  role !== UserRoles.REGIONAL_MERCHANDISER &&
                  !isClosedOrFrozen &&
                  active && (
                    <Icon
                      className={classes.icon}
                      color='disabled'
                      onClick={handleClick}
                      aria-owns={anchorEl ? 'simple-menu' : undefined}
                    >
                      add_circle
                    </Icon>
                  )}
                {permissions.canEdit ? (
                  <Menu
                    id='simple-menu'
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    {allChannels &&
                      allChannels.length &&
                      differenceBy(allChannels, channels, 'id').map(channel => (
                        <MenuItem
                          key={channel.id}
                          onClick={() => {
                            markProductChannelExclusive(channel, id, active)
                            handleClose()
                          }}
                        >
                          {channel.name}
                        </MenuItem>
                      ))}
                  </Menu>
                ) : null}
              </div>
            </ProductDescriptionItem>

            <div className={classes.flexLeftContent}>
              <FormControl
                fullWidth
                variant='outlined'
                className={classes.selectField}
                margin='dense'
              >
                <InputLabel>Product Status</InputLabel>
                <SelectField
                  className={classes.selectfield}
                  input={
                    <OutlinedInput
                      name='ProductStatus'
                      labelWidth={105}
                      id='outlined-simple'
                    />
                  }
                  options={PRODUCT_STATUSES}
                  // TODO: Add buying session status related condition to disabled
                  value={productStatus ? productStatus : 'CORE'}
                  disabled={
                    !active ||
                    !permissions.canEdit ||
                    role === UserRoles.REGIONAL_MERCHANDISER ||
                    (restrictedZones && restrictedZones.length) ||
                    (buyingSession && buyingSession.status === constants.CLOSED) ||
                    !hasRetailChannel
                  }
                  onChange={e => {
                    handleProductStatusChange(e.target.value, data.buyingSessionProduct)
                  }}
                />
              </FormControl>
              <TextInput
                id='retailStatus'
                name='retailStatus'
                label={retailStatus.label}
                type='text'
                margin='dense'
                variant='outlined'
                value={retailStatus ? retailStatus.value : ''}
                className={classes.textfield}
                fullWidth
                style={{ paddingRight: 10 }}
                disabled={true}
              />
            </div>
            {restrictedZones.length ? (
              <div className={classes.restrictedZoneMsg}>{RESTRICTED_PRODUCT_MSG}</div>
            ) : null}
            {!hasRetailChannel ? (
              <div className={classes.hasNoRetailChannelMsg}>
                {NON_RETAIL_CORE_PRODUCT_MSG}
              </div>
            ) : null}
            <Divider className={classes.divider} />
            {/* <Typography variant='subtitle1' className={classes.greyColor}>
                    Buyingsession Product Attributes
                  </Typography> */}
            <BuyingSessionProductSizeAttributesContainer
              active={active}
              role={role}
              buyingSession={buyingSession}
              buyingSessionProductId={id}
              productAttributes={attributes}
              buyingSessionProductAttributes={buyingSessionProductAttributes}
            />
            <Divider className={classes.divider} />
            <div className={classes.productInfo}>
              <Typography variant='subtitle1' className={classes.greyColor}>
                Ratings
              </Typography>
              {productRatings.map((rating, index) => (
                <div className={classes.flexRowContent} key={index}>
                  <Typography
                    variant='caption'
                    className={classes.topMargin}
                    style={{ flex: 0.3 }}
                  >
                    {rating.typeName}
                  </Typography>
                  <Rating
                    id={rating.id}
                    value={rating.value ? rating.value : 0}
                    max={5}
                    disabled={isExcludedFromZone || isClosedOrFrozen}
                    onChange={value => addProductRating(id, rating, value, active)}
                  />
                </div>
              ))}
            </div>
            <Divider className={classes.divider} />
            <div className={classes.productInfo}>
              <Typography variant='subtitle1' className={classes.greyColor}>
                Details
              </Typography>
              <div className={classes.flexContainer}>
                {attributeDefWithTag.map(
                  ({ id: defId, isEditable, singular, type, tags }) => (
                    <div key={defId} className={classes.attributeItem}>
                      {isEditable &&
                      role !== UserRoles.REGIONAL_MERCHANDISER &&
                      type === 'Tag' ? (
                        <div>
                          <FormControl
                            fullWidth
                            variant='outlined'
                            className={classes.formControl}
                            margin='dense'
                          >
                            <Typography
                              variant='overline'
                              className={classes.selectFieldLabel}
                            >
                              {singular ? singular : ''}
                            </Typography>
                            <SelectField
                              className={classes.editableSelectField}
                              input={
                                <OutlinedInput
                                  name='Offset'
                                  labelWidth={0}
                                  id='outlined-simple'
                                />
                              }
                              options={tags}
                              displayEmpty
                              disabled={
                                !active ||
                                isExcludedFromZone ||
                                (buyingSession &&
                                  buyingSession.status === constants.CLOSED)
                              }
                              value={getAttributeTagIdForProduct(attributes, defId)}
                              onChange={e => {
                                if (
                                  getAttributeTagIdForProduct(attributes, defId) !==
                                  e.target.value
                                ) {
                                  handleProductAttributeChange(
                                    productId,
                                    defId,
                                    e.target.value
                                  )
                                }
                              }}
                            />
                          </FormControl>
                        </div>
                      ) : (
                        <ProductInfoItem
                          title={singular ? singular : ''}
                          content={
                            <Typography className={classes.attributes} variant='caption'>
                              {getAttributeValuesForProduct(attributes, defId)}
                            </Typography>
                          }
                        />
                      )}
                    </div>
                  )
                )}
              </div>
            </div>
            <Divider className={classes.divider} />
            <CommentContainer
              selectedProductId={id}
              isActive={active}
              isRestricted={isRestricted}
              isClosed={isClosedOrFrozen}
            />
          </ProductDescription>
        </ProductDetailInfo>
      </div>
    )
  }
}
