import { BuyingSessionGroupNavSection } from '@modules/common/models/enums'
import { BuyingSessionGroupNavView } from '@modules/common/models/enums/BSGroupAndBSNav'
import { IZone } from '@modules/wholesale/wsNavigations/stores/WholesaleSettingsStore'
import { getUpdateZoneList } from '@services/commonServices'
import { stores, strings } from '@stores'
import isString from 'lodash/isString'
import { action, observable } from 'mobx'
import * as qs from 'query-string'

export class BuyingSessionGroupSidebarStore {
  @observable selectedBuyingSessionSelectionTab = BuyingSessionGroupNavSection.Collection
  @observable zoneList: Array<IZone> = []

  @action updateZoneList = (zones: Array<IZone>) => {
    this.zoneList = getUpdateZoneList(zones)
  }

  @action
  onBackButtonClick = (parentBuyingSessionGroupId?) => {
    const {
      nav: { history, buyingSessionGroupId, refererUrl, clearRefererUrl }
    } = stores

    const bsgId = buyingSessionGroupId
      ? buyingSessionGroupId
      : isString(parentBuyingSessionGroupId)
      ? parentBuyingSessionGroupId
      : undefined

    const search = refererUrl.search
      ? refererUrl.search
      : bsgId
      ? qs.stringify({
          buyingSessionGroupId: bsgId
        })
      : ''

    history.push({
      pathname: refererUrl.pathname || `/wholesale`,
      search
    })

    clearRefererUrl()
  }

  @action onClickSettingsMenu = view => {
    const { nav } = stores
    nav.updateQueryParams({ view })
  }

  @action
  onBuyingSelectionTabChange = (event, value) => {
    const {
      nav: {
        updateQueryParams,
        queryParams: { assortment, productLayout }
      }
    } = stores
    this.selectedBuyingSessionSelectionTab = value

    updateQueryParams(
      {
        view: assortment ? assortment : BuyingSessionGroupNavView.Collection,
        productLayout: productLayout || strings.grid
      },
      'replace'
    )
  }

  @action resetBuyingSessionLeftNavTabSelection = () => {
    Object.assign(this, {
      selectedBuyingSessionSelectionTab: BuyingSessionGroupNavSection.Collection
    })
  }
}

export const buyingSessionGroupSidebar = new BuyingSessionGroupSidebarStore()
