import { StyledProps } from '@components/3rd-party/mui'
import { PrimaryButton } from '@components/UI-Components/PrimaryButton'
import { KeyboardBackspace } from '@icons'
import { Paper } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'

const styles = theme => ({
  container: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  root: {
    width: 528,
    padding: theme.spacing(4)
  },
  button: {
    margin: theme.spacing(1)
  },
  buttonRoot: {
    paddingLeft: 0
  },
  logoutButton: {
    color: theme.palette.primary.main
  }
})

interface MyProps extends StyledProps {}

@((withStyles as any)(styles))
export class ErrorHandlerComponent extends React.Component<MyProps> {
  render() {
    const { classes } = this.props

    const moveToSite = () => {
      window.location.replace('/')
    }

    return (
      <div className={classes.container}>
        <Paper className={classes.root} elevation={1} tabIndex={1}>
          <Typography variant='h4' component='h3'>
            Page Not Found
          </Typography>
          <br />
          <Typography>
            Looks like you've followed a broken link or entered a URL that doesn't exist
            on this site.
            <br />
            <br />
          </Typography>
          <PrimaryButton
            color='primary'
            className={classes.button}
            classes={{ root: classes.buttonRoot }}
            onClick={moveToSite}
          >
            <KeyboardBackspace /> Back to our site
          </PrimaryButton>
        </Paper>
      </div>
    )
  }
}
