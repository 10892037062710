import { SIDEBAR_CONFIG } from '@modules/showroom/Sidebar/common'
import { stores } from '@stores'
import find from 'lodash/find'
import { action, computed, observable } from 'mobx'
import qs from 'query-string'

export class ShowroomSidebarStore {
  @observable campaignId = null
  @observable anchorEl = null

  @action setCampaignId = campaignId => {
    this.campaignId = campaignId
  }

  @action
  handleClick = event => {
    this.anchorEl = event.currentTarget
  }

  @action
  handleClose = () => {
    this.anchorEl = null
  }

  @action redirectToPage = (url: string, queryParams?) => {
    const {
      nav: { history }
    } = stores
    if (history?.location?.pathname !== url) {
      if (this.anchorEl) this.handleClose()
      history.push({
        pathname: url,
        search: qs.stringify(queryParams)
      })
    }
  }

  @action onShowroomTabClick = (queryParams?) => {
    if (queryParams?.campaignId) {
      this.redirectToPage(SIDEBAR_CONFIG.presentation.url, queryParams)
    } else {
      this.redirectToPage(SIDEBAR_CONFIG.lookbook.url, queryParams)
    }
  }

  @computed get activePath() {
    const {
      nav: {
        location: { pathname }
      }
    } = stores
    // need one match only, hence find
    return find(SIDEBAR_CONFIG, key => pathname.endsWith(key.url))
  }

  /** Handle BSG selection, if presentation is present, 
      route to it else check if looks are present, 
      then route to lookbook page else route to collection
  **/
  handleBSGSelection =
    (id, retailStatus = '', campaignId) =>
    async e => {
      const {
        nav: {
          queryParams: { clientId, clientType },
          history,
          location: { pathname }
        }
      } = stores
      this.setCampaignId(campaignId)
      if (campaignId) {
        history.push({
          pathname: `${pathname}/presentation`,
          search: qs.stringify({
            clientId,
            buyingSessionGroupId: id,
            campaignId: campaignId,
            clientType
          })
        })
      } else {
        try {
          const {
            showroomDashboardStore: { setActiveLookMap, isActiveLookPresent }
          } = stores
          await setActiveLookMap(id)
          if (isActiveLookPresent(id)) {
            history.push({
              pathname: `${pathname}/lookbook`,
              search: qs.stringify({
                clientId,
                buyingSessionGroupId: id,
                clientType
              })
            })
          } else {
            const {
              clientCollection: { url }
            } = SIDEBAR_CONFIG
            history.push({
              pathname: url,
              search: qs.stringify({
                clientId,
                buyingSessionGroupId: id,
                clientType
              })
            })
          }
        } catch (e) {
          // incase of error in activeLookMap API, still navigate to collection page
          const {
            clientCollection: { url }
          } = SIDEBAR_CONFIG
          history.push({
            pathname: url,
            search: qs.stringify({
              clientId,
              buyingSessionGroupId: id,
              clientType
            })
          })
        }
      }
    }
}

export const showroomSidebarStore = new ShowroomSidebarStore()
