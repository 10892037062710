import { StyledProps } from '@components/3rd-party/mui'
import { LinearProgress } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'

const styles = {
  root: {
    position: 'absolute',
    top: 0,
    zIndex: 2000,
    width: '100%'
  }
}

@((withStyles as any)(styles))
export class ProgressBar extends React.Component<StyledProps> {
  render() {
    const { classes } = this.props
    return (
      <div className={classes.root}>
        <LinearProgress />
      </div>
    )
  }
}
