/*
 * @Author: Raeesaa Metkari
 * @Date: 2019-12-12 15:09:50
 * @Last Modified by: Raeesaa Metkari
 * @Last Modified time: 2019-12-19 13:46:22
 */
import {
  ListViewColumnIdentifiers,
  ListViewColumnLabels,
  ListViewFieldMapping,
  TextAlignment,
  ColumnWidth,
  QuantityType,
  ComponentServiceTypes,
  ListViewFontWeights,
  ZoneDisplayNames
} from '@modules/common/models'
import { listViewComponentService } from '@modules/retail/collection/component/ListView/Components'
import { standardListViewComponentService } from './standardListViewComponentService'
import { strings } from '@stores'
import { ListViewColumnDefinition } from '@components/UI-Components/StandardTableView/models/ListviewColumnDefinition'

const isStandardComponentService = serviceType => {
  return serviceType === ComponentServiceTypes.Standard
}

const getComponentServiceInstance = serviceType => {
  return isStandardComponentService(serviceType)
    ? standardListViewComponentService
    : listViewComponentService
}

class ListViewDefinitionService {
  buildProductInfoColumnDefinitionInstance = (serviceType?: string) => {
    const service = getComponentServiceInstance(serviceType)
    return new ListViewColumnDefinition({
      id: ListViewColumnIdentifiers.ProductInfo,
      header: {
        label: ListViewColumnLabels.ProductInfo
      },
      width: isStandardComponentService(serviceType)
        ? ColumnWidth.StandardProductInfo
        : ColumnWidth.ProductInfo,
      isFixed: true,
      isRootColumn: true,
      category: null,
      product: {
        component: service.getProductInfoComponent,
        key: ListViewFieldMapping.ProductInfo
      }
    })
  }

  buildProductAttributeColumnDefinitionInstance = (serviceType?: string) => {
    const service = getComponentServiceInstance(serviceType)
    return new ListViewColumnDefinition({
      id: ListViewColumnIdentifiers.ProductAttributes,
      header: {
        label: ListViewColumnLabels.ProductAttributes
      },
      width: ColumnWidth.ProductAttributes,
      isFixed: true,
      category: {
        showKPI: false
      },
      product: {
        component: service.getProductAttributeComponent,
        key: ListViewFieldMapping.ProductAttributes,
        showLeftBorder: isStandardComponentService(serviceType),
        showRightBorder: isStandardComponentService(serviceType)
      }
    })
  }

  buildCMClusterColumnDefinitionInstance = (
    isFixed: boolean = false,
    showKPI: boolean = false,
    label: string = ListViewColumnLabels.CMCluster,
    serviceType?: string,
    key?: ListViewFieldMapping
  ) => {
    const service = getComponentServiceInstance(serviceType)
    return new ListViewColumnDefinition({
      id: key ?? ListViewColumnIdentifiers.CMCluster,
      header: {
        label
      },
      width: ColumnWidth.Large,
      isFixed,
      category: {
        showKPI
      },
      product: {
        component: service.getChipListComponent,
        key: key ?? ListViewFieldMapping.CMClusters
      }
    })
  }

  buildRMClusterColumnDefinitionInstance = (
    isFixed: boolean = false,
    showKPI: boolean = false,
    label: string = ListViewColumnLabels.RMCluster,
    serviceType?: string,
    key?: ListViewFieldMapping
  ) => {
    const service = getComponentServiceInstance(serviceType)
    return new ListViewColumnDefinition({
      id: key ?? ListViewColumnIdentifiers.RMCluster,
      header: {
        label
      },
      width: ColumnWidth.Large,
      isFixed,
      category: {
        showKPI
      },
      product: {
        component: service.getChipListComponent,
        key: key ?? ListViewFieldMapping.RMClusters
      }
    })
  }

  buildChannelColumnDefinitionInstance = (serviceType?: string) => {
    const service = getComponentServiceInstance(serviceType)
    return new ListViewColumnDefinition({
      id: ListViewColumnIdentifiers.Channels,
      header: {
        label: ListViewColumnLabels.Channels
      },
      width: ColumnWidth.Large,
      category: null,
      product: {
        component: service.getChipListComponent,
        key: ListViewFieldMapping.Channels
      }
    })
  }

  buildDropColumnDefinitionInstance = (serviceType?: string) => {
    const service = getComponentServiceInstance(serviceType)
    return new ListViewColumnDefinition({
      id: ListViewColumnIdentifiers.Drops,
      header: {
        label: ListViewColumnLabels.Drops
      },
      width: ColumnWidth.Large,
      category: null,
      product: {
        component: service.getChipListComponent,
        key: ListViewFieldMapping.Drops
      }
    })
  }

  buildExcludedClientColumnDefinitionInstance = (serviceType?: string) => {
    const service = getComponentServiceInstance(serviceType)
    return new ListViewColumnDefinition({
      id: ListViewColumnIdentifiers.ExcludedClients,
      header: {
        label: ListViewColumnLabels.ExcludedClients
      },
      width: ColumnWidth.Large,
      category: null,
      product: {
        component: service.getChipListComponent,
        key: ListViewFieldMapping.ExcludedClients
      }
    })
  }

  buildExcludedZonesColumnDefinitionInstance = (serviceType?: string) => {
    const service = getComponentServiceInstance(serviceType)
    return new ListViewColumnDefinition({
      id: ListViewColumnIdentifiers.ExcludedZones,
      header: {
        label: ListViewColumnLabels.ExcludedZones
      },
      width: ColumnWidth.Large,
      category: null,
      product: {
        component: service.getChipListComponent,
        key: ListViewFieldMapping.ExcludedZones
      }
    })
  }

  buildSalesPeriodColumnDefinitionInstance = (serviceType?: string) => {
    const service = getComponentServiceInstance(serviceType)
    return new ListViewColumnDefinition({
      id: ListViewColumnIdentifiers.SalesPeriod,
      header: {
        label: ListViewColumnLabels.SalesPeriod
      },
      width: ColumnWidth.Large,
      category: {
        showKPI: false
      },
      product: {
        component: service.getChipListComponent,
        key: ListViewFieldMapping.SalesPeriod
      }
    })
  }

  buildTotalWeeksColumnDefinitionInstance = () => {
    return new ListViewColumnDefinition({
      id: ListViewColumnIdentifiers.TotalWeeks,
      header: {
        label: ListViewColumnLabels.TotalWeeks
      },
      width: ColumnWidth.XS,
      props: {
        alignment: TextAlignment.right
      },
      category: {
        showKPI: false
      },
      product: {
        component: listViewComponentService.getLabelComponent,
        key: ListViewFieldMapping.TotalWeeks
      }
    })
  }

  buildBuyerClusterColumnDefinitionInstance = (
    isFixed: boolean = false,
    showKPI: boolean = false,
    serviceType?: string
  ) => {
    const service = getComponentServiceInstance(serviceType)
    return new ListViewColumnDefinition({
      id: ListViewColumnIdentifiers.BuyerCluster,
      header: {
        label: ListViewColumnLabels.BuyerCluster
      },
      width: ColumnWidth.Large,
      isFixed,
      category: {
        showKPI
      },
      product: {
        component: service.getChipListComponent,
        key: ListViewFieldMapping.BuyerClusters
      }
    })
  }

  buildRetailFreeTagsColumnDefinitionInstance = (serviceType?: string) => {
    const service = getComponentServiceInstance(serviceType)
    return new ListViewColumnDefinition({
      id: ListViewColumnIdentifiers.RetailFreeTags,
      header: {
        label: ListViewColumnLabels.RetailFreeTags
      },
      width: ColumnWidth.Large,
      category: null,
      product: {
        component: service.getChipListComponent,
        key: ListViewFieldMapping.RetailFreeTags
      }
    })
  }

  buildWholesaleFreeTagsColumnDefinitionInstance = (serviceType?: string) => {
    const service = getComponentServiceInstance(serviceType)
    return new ListViewColumnDefinition({
      id: ListViewColumnIdentifiers.WholesaleFreeTags,
      header: {
        label: ListViewColumnLabels.WholesaleFreeTags
      },
      width: ColumnWidth.Large,
      category: null,
      product: {
        component: service.getChipListComponent,
        key: ListViewFieldMapping.WholesaleFreeTags
      }
    })
  }

  buildTotalStoresColumnDefinitionInstanceForDistribution = (
    showKPI: boolean = false
  ) => {
    return new ListViewColumnDefinition({
      id: ListViewColumnIdentifiers.TotalStores,
      header: {
        label: ListViewColumnLabels.TotalStores
      },
      width: ColumnWidth.XS,
      props: {
        alignment: TextAlignment.right
      },
      category: showKPI
        ? {
            key: ListViewFieldMapping.TotalDoors,
            showKPI: true
          }
        : { showKPI },
      product: {
        component: listViewComponentService.getLabelComponent,
        key: ListViewFieldMapping.TotalDoors
      }
    })
  }

  buildTotalDoorsColumnDefinitionInstanceForDistribution = (serviceType: string) => {
    const service = getComponentServiceInstance(serviceType)
    return new ListViewColumnDefinition({
      id: ListViewColumnIdentifiers.TotalDoors,
      header: {
        label: ListViewColumnLabels.TotalStores
      },
      width: ColumnWidth.XS,
      props: {
        alignment: TextAlignment.right
      },
      category: null,
      product: {
        component: service.getLabelComponent,
        key: ListViewFieldMapping.TotalDoors
      }
    })
  }

  buildTotalStoresColumnDefinitionInstance = (showKPI: boolean = false) => {
    return new ListViewColumnDefinition({
      id: ListViewColumnIdentifiers.TotalStores,
      header: {
        label: ListViewColumnLabels.TotalStores
      },
      width: ColumnWidth.XS,
      props: {
        alignment: TextAlignment.right
      },
      category: showKPI
        ? {
            key: ListViewFieldMapping.totalStores,
            showKPI: true
          }
        : { showKPI },
      product: {
        component: listViewComponentService.getLabelListComponent,
        key: ListViewFieldMapping.zoneWiseData,
        path: ListViewFieldMapping.storeCount
      }
    })
  }

  buildZonesColumnDefinitionInstance = (showKPI: boolean = false) => {
    return new ListViewColumnDefinition({
      id: ListViewColumnIdentifiers.Zones,
      header: {
        label: ListViewColumnLabels.Zones
      },
      width: ColumnWidth.Small,
      isFixed: true,
      category: {
        showKPI
      },
      props: {
        alignment: TextAlignment.left
      },
      product: {
        component: listViewComponentService.getLabelListComponent,
        key: ListViewFieldMapping.zoneWiseData,
        path: ListViewFieldMapping.zone,
        showLeftBorder: true
      }
    })
  }

  buildPriceColumnDefinitionInstance = (showKPI: boolean = false) => {
    return new ListViewColumnDefinition({
      id: ListViewColumnIdentifiers.Price,
      header: {
        label: ListViewColumnLabels.Price
      },
      width: ColumnWidth.Medium,
      props: {
        alignment: TextAlignment.right
      },
      category: {
        showKPI
      },
      product: {
        component: listViewComponentService.getLabelListComponent,
        key: ListViewFieldMapping.zoneWiseData,
        path: ListViewFieldMapping.retailPrice
      }
    })
  }

  buildPriceColumnDefinitionInstanceForDistribution = (
    showKPI: boolean = false,
    serviceType?: string
  ) => {
    const service = getComponentServiceInstance(serviceType)
    return new ListViewColumnDefinition({
      id: ListViewColumnIdentifiers.Price,
      header: {
        label: ListViewColumnLabels.Price
      },
      width: ColumnWidth.Medium,
      props: {
        alignment: TextAlignment.right,
        fontWeight: isStandardComponentService(serviceType)
          ? ListViewFontWeights.Bold
          : ListViewFontWeights.Normal
      },
      category: {
        showKPI
      },
      product: {
        component: service.getLabelComponent,
        key: ListViewFieldMapping.Price
      }
    })
  }

  buildAWSColumnDefinitionInstance = () => {
    return new ListViewColumnDefinition({
      id: ListViewColumnIdentifiers.AvgWeeklySales,
      header: {
        label: ListViewColumnLabels.AvgWeeklySales
      },
      width: ColumnWidth.XS,
      isFloatingPointValue: true,
      props: {
        alignment: TextAlignment.right
      },
      category: {
        key: ListViewFieldMapping.aws,
        showKPI: true
      },
      product: {
        component: listViewComponentService.getAWSComponent,
        key: ListViewFieldMapping.zoneWiseData,
        path: ListViewFieldMapping.aws
      }
    })
  }

  buildSkuEffColumnDefinitionInstance = () => {
    return new ListViewColumnDefinition({
      id: ListViewColumnIdentifiers.SkuEff,
      header: {
        label: ListViewColumnLabels.SkuEff
      },
      width: ColumnWidth.XS,
      props: {
        alignment: TextAlignment.right
      },
      isFloatingPointValue: true,
      category: {
        key: ListViewFieldMapping.skuEfficiency,
        showKPI: true
      },
      product: {
        component: listViewComponentService.getLabelListComponent,
        key: ListViewFieldMapping.zoneWiseData,
        path: ListViewFieldMapping.sku
      }
    })
  }

  buildAvgDepthColumnDefinitionInstance = () => {
    return new ListViewColumnDefinition({
      id: ListViewColumnIdentifiers.AvgDepth,
      header: {
        label: ListViewColumnLabels.AvgDepth
      },
      width: ColumnWidth.XS,
      props: {
        alignment: TextAlignment.right
      },
      isFloatingPointValue: true,
      category: {
        key: ListViewFieldMapping.averageDepth,
        showKPI: true
      },
      product: {
        component: listViewComponentService.getAvgDepthComponent,
        key: ListViewFieldMapping.zoneWiseData,
        path: ListViewFieldMapping.depth
      }
    })
  }

  buildValueColumnDefinitionInstance = (labelPrefix: string) => {
    return new ListViewColumnDefinition({
      id: ListViewColumnIdentifiers.Value,
      header: {
        label: `${labelPrefix} ${ListViewColumnIdentifiers.Value}`
      },
      width: ColumnWidth.Large,
      props: {
        alignment: TextAlignment.right
      },
      category: {
        key: ListViewFieldMapping.totalValue,
        showKPI: true
      },
      product: {
        component: listViewComponentService.getLabelListComponent,
        key: ListViewFieldMapping.zoneWiseData,
        path: ListViewFieldMapping.value
      }
    })
  }

  buildSalesQtyColumnDefinitionInstance = () => {
    return new ListViewColumnDefinition({
      id: ListViewColumnIdentifiers.SalesQty,
      header: {
        label: ListViewColumnLabels.SalesQty
      },
      width: ColumnWidth.Medium,
      props: {
        alignment: TextAlignment.right
      },
      isFloatingPointValue: true,
      category: {
        key: ListViewFieldMapping.salesQty,
        showKPI: true
      },
      product: {
        component: listViewComponentService.getLabelListComponent,
        key: ListViewFieldMapping.zoneWiseData,
        path: ListViewFieldMapping.salesQuantity
      }
    })
  }

  buildOrderQtyColumnDefinitionInstance = (
    quantityType: QuantityType = QuantityType.BET,
    labelPrefix?: string
  ) => {
    const label = labelPrefix ? labelPrefix : quantityType
    return new ListViewColumnDefinition({
      id: ListViewColumnIdentifiers.OrderQty,
      header: {
        label: `${label} Qty`
      },
      width: ColumnWidth.Medium,
      props: {
        alignment: TextAlignment.right
      },
      category: {
        key: ListViewFieldMapping.totalQuantity,
        showKPI: true
      },
      product: {
        component: listViewComponentService.getOrderQuantityComponent,
        key: ListViewFieldMapping.zoneWiseData,
        path: ListViewFieldMapping[`${quantityType.toLowerCase()}Qty`]
      }
    })
  }

  buildMixQtyColumnDefinitionInstance = () => {
    return new ListViewColumnDefinition({
      id: ListViewColumnIdentifiers.MixQty,
      header: {
        label: ListViewColumnLabels.MixQty
      },
      width: ColumnWidth.XS,
      props: {
        alignment: TextAlignment.right
      },
      isFloatingPointValue: true,
      category: {
        key: ListViewFieldMapping.mixQuantity,
        showKPI: true
      },
      product: {
        component: listViewComponentService.getLabelListComponent,
        key: ListViewFieldMapping.zoneWiseData,
        path: ListViewFieldMapping.mixQuantity
      }
    })
  }

  buildMixValueColumnDefinitionInstance = () => {
    return new ListViewColumnDefinition({
      id: ListViewColumnIdentifiers.MixValue,
      header: {
        label: ListViewColumnLabels.MixValue
      },
      width: ColumnWidth.XS,
      isFloatingPointValue: true,
      props: {
        alignment: TextAlignment.right
      },
      category: {
        key: ListViewFieldMapping.mixValue,
        showKPI: true
      },
      product: {
        component: listViewComponentService.getLabelListComponent,
        key: ListViewFieldMapping.zoneWiseData,
        path: ListViewFieldMapping.mixValue
      }
    })
  }

  buildBuyerBETQuantityColumnDefinitionInstance = (labelPrefix?: string) => {
    const label = labelPrefix || 'BET'
    return new ListViewColumnDefinition({
      id: ListViewColumnIdentifiers.BetQty,
      header: {
        label: `${label} Qty`
      },
      width: ColumnWidth.Medium,
      props: {
        alignment: TextAlignment.right
      },
      category: {
        key: ListViewFieldMapping.totalBETQuantity,
        showKPI: true
      },
      product: {
        component: listViewComponentService.getLabelListComponent,
        key: ListViewFieldMapping.zoneWiseData,
        path: ListViewFieldMapping.betQty
      }
    })
  }

  buildCMCommentsColumnDefinitionInstance = () => {
    return new ListViewColumnDefinition({
      id: ListViewColumnIdentifiers.CMComments,
      header: {
        label: ListViewColumnLabels.CMComments
      },
      width: ColumnWidth.Comments,
      props: {
        userRole: strings.GlobalMerchaniser
      },
      category: {
        showKPI: true
      },
      product: {
        component: listViewComponentService.getCommentsComponent,
        key: ListViewFieldMapping.zoneWiseData
      }
    })
  }

  buildRMCommentsColumnDefinitionInstance = () => {
    return new ListViewColumnDefinition({
      id: ListViewColumnIdentifiers.RMComments,
      header: {
        label: ListViewColumnLabels.RMComments
      },
      width: ColumnWidth.Comments,
      props: {
        userRole: strings.RegionalMerchandiser
      },
      category: {
        showKPI: true
      },
      product: {
        component: listViewComponentService.getCommentsComponent,
        key: ListViewFieldMapping.zoneWiseData
      }
    })
  }

  buildOrderValueColumnDefinitionInstanceForWS = () => {
    return new ListViewColumnDefinition({
      id: ListViewColumnIdentifiers.OrderValue,
      header: {
        label: ListViewColumnLabels.OrderValue
      },
      width: ColumnWidth.Large,
      props: {
        alignment: TextAlignment.right
      },
      category: {
        showKPI: true,
        key: ListViewFieldMapping.value
      },
      product: {
        component: listViewComponentService.getLabelComponent,
        key: ListViewFieldMapping.value
      }
    })
  }

  buildSalesQtyColumnDefinitionInstanceForWS = () => {
    return new ListViewColumnDefinition({
      id: ListViewColumnIdentifiers.SalesQty,
      header: {
        label: ListViewColumnLabels.OrderQty
      },
      width: ColumnWidth.Large,
      props: {
        alignment: TextAlignment.right
      },
      category: {
        showKPI: true,
        key: ListViewFieldMapping.salesQty
      },
      product: {
        component: listViewComponentService.getLabelComponent,
        key: ListViewFieldMapping.salesQty
      }
    })
  }
  buildSizedQtyColumnDefinitionInstanceForWS = () => {
    return new ListViewColumnDefinition({
      id: ListViewColumnIdentifiers.SizedQty,
      header: {
        label: ListViewColumnLabels.SizedQty
      },
      width: ColumnWidth.Large,
      props: {
        alignment: TextAlignment.right
      },
      category: {
        showKPI: true,
        key: ListViewFieldMapping.sizedQty
      },
      product: {
        component: listViewComponentService.getLabelComponent,
        key: ListViewFieldMapping.sizedQty
      }
    })
  }
  buildDraftQtyColumnDefinitionInstanceForWS = () => {
    return new ListViewColumnDefinition({
      id: ListViewColumnIdentifiers.DraftQty,
      header: {
        label: ListViewColumnLabels.DoorQty
      },
      width: ColumnWidth.Large,
      props: {
        alignment: TextAlignment.right
      },
      category: {
        showKPI: true,
        key: ListViewFieldMapping.draftQty
      },
      product: {
        component: listViewComponentService.getLabelComponent,
        key: ListViewFieldMapping.draftQty
      }
    })
  }
  buildCMCommentsColumnInstance = (serviceType?: string) => {
    const service = getComponentServiceInstance(serviceType)
    return new ListViewColumnDefinition({
      id: ListViewColumnIdentifiers.CMComments,
      header: {
        label: ListViewColumnLabels.CMComments
      },
      width: ColumnWidth.Comments,
      props: {
        userRole: strings.GlobalMerchaniser
      },
      category: {
        showKPI: true
      },
      product: {
        component: service.getCommentsComponent,
        key: ListViewFieldMapping.zoneWiseData,
        path: ListViewFieldMapping.CM
      }
    })
  }

  buildRMCommentsColumnInstance = (serviceType?: string) => {
    const service = getComponentServiceInstance(serviceType)
    return new ListViewColumnDefinition({
      id: ListViewColumnIdentifiers.RMComments,
      header: {
        label: ListViewColumnLabels.RMComments
      },
      width: ColumnWidth.Comments,
      props: {
        userRole: strings.RegionalMerchandiser
      },
      category: {
        showKPI: true
      },
      product: {
        component: service.getCommentsComponent,
        key: ListViewFieldMapping.zoneWiseData,
        path: ListViewFieldMapping.RM
      }
    })
  }

  buildZoneCounterOfferColumnInstance = (
    serviceType?: string,
    zone?: {
      id: string
      name: string
      hasCounterOffer: boolean
      hasClusterAssociation: boolean
    }
  ) => {
    const service = getComponentServiceInstance(serviceType)
    return new ListViewColumnDefinition({
      id: zone.id,
      header: {
        label: `RM CLUSTER | ${ZoneDisplayNames[zone.name] || zone.name}`,
        showFlagIcon: zone.hasCounterOffer,
        isDisabled: !zone.hasClusterAssociation
      },
      width: ColumnWidth.Large,
      category: {
        showKPI: false
      },
      product: {
        component: service.getChipListAndFlagComponent,
        key: zone.id as ListViewFieldMapping
      }
    })
  }
}

export const listViewColumnDefinitionService = new ListViewDefinitionService()
