import Axios, { AxiosRequestConfig } from 'axios'

export class CancellableRequest<T> {
  constructor(private originalPromise: Promise<T>, private source) {}

  then<TResult1 = T, TResult2 = never>(
    onfulfilled?: ((value: T) => TResult1 | PromiseLike<TResult1>) | undefined | null,
    onrejected?: ((reason: any) => TResult2 | PromiseLike<TResult2>) | undefined | null
  ) {
    return new CancellableRequest(
      this.originalPromise.then(onfulfilled, onrejected),
      this.source
    )
  }

  catch<TResult = never>(
    onrejected?: ((reason: any) => TResult | PromiseLike<TResult>) | undefined | null
  ) {
    return new CancellableRequest(this.originalPromise.catch(onrejected), this.source)
  }

  finally(onfinally?: (() => void) | undefined | null) {
    return new CancellableRequest(this.originalPromise.finally(onfinally), this.source)
  }

  cancel() {
    this.source.cancel()
  }

  // isCancelled() {
  //   this.source.
  // }
}

export class HttpService {
  request(options: AxiosRequestConfig) {
    const source = Axios.CancelToken.source()
    return new CancellableRequest(
      Axios.request({ ...options, cancelToken: source.token }),
      source
    )
  }

  get(url: string, options: AxiosRequestConfig) {
    return this.request({ ...options, url, method: 'get' })
  }

  put(url: string, data: any, options: AxiosRequestConfig) {
    return this.request({ ...options, url, data, method: 'put' })
  }

  post(url: string, data: any, options: AxiosRequestConfig) {
    return this.request({ ...options, url, data, method: 'post' })
  }

  patch(url: string, data: any, options: AxiosRequestConfig) {
    return this.request({ ...options, url, data, method: 'patch' })
  }

  delete(url: string, options: AxiosRequestConfig) {
    return this.request({ ...options, url, method: 'delete' })
  }
}
