import React from 'react'
import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'

const StyledTitle = styled(Typography)({
  '&&': {
    maxWidth: 44,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    font: `var(--typography-font-bold1)`,
    letterSpacing: 'var(--chip-typography-letterSpacing)',
    color: `var(--chip-typography-color)`,
    marginRight: 2
  }
})
const StyledSubTitle = styled(Typography)({
  '&&': {
    maxWidth: 44,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    font: `var(--typography-body3)`,
    letterSpacing: 'var(--chip-typography-letterSpacing)',
    color: `var(--chip-typography-color)`,
    borderLeft: '1px solid grey',
    paddingLeft: 2
  }
})
const StyledLabelWrapper = styled.div({
  '&&': {
    maxWidth: 'var(--chip-typography-max-width)',
    justifyContent: 'space-between',
    display: 'inline-flex',
    alignItems: 'center'
  }
})

export const ChipLabel = ({ title, subtitle }) => {
  return (
    <StyledLabelWrapper>
      <StyledTitle>{title}</StyledTitle>
      <StyledSubTitle>{subtitle}</StyledSubTitle>
    </StyledLabelWrapper>
  )
}
