import { ProductForecastDataStore } from '@modules/wholesale/buyingSessionGroupProductWrapper/stores/productForecastDataStore'
import { observable, ObservableMap } from 'mobx'
import { IGetProductForecast } from '@modules/wholesale/buyingSessionGroupProductWrapper/constants/productForecastInterface'
import unionBy from 'lodash/unionBy'
import { ForecastService } from '@modules/wholesale/buyingSessionGroupProductWrapper/services/forecastService'
import isObject from 'lodash/isObject'
import { stores } from '@stores'
import { forecastGlobalTopBarStore } from './ForecastGlobalTopBar/ForecastGlobalTopBarUIStore'
import { find } from 'lodash'
import { ClientAndBSGService } from '@stores/productCurvesStore'
import { computedFn } from 'mobx-utils'

//todo: rectify file name

class ProductForecastUIStore {
  private productForecastDataStore = new ProductForecastDataStore()
  private productForecastService = new ForecastService()
  private apiService = new ClientAndBSGService()

  @observable.struct productForecastData = []
  @observable.struct totalForecastData = []
  @observable targetCurrency = ''
  @observable forecastCategory: IGetProductForecast = {
    categoryValues: []
  }
  @observable curves = new ObservableMap()
  @observable isCurveLoading = new ObservableMap()

  init = async (
    forecastCategory: IGetProductForecast,
    initCategoryValues = false,
    fetchZoneWeight = false
  ) => {
    if (initCategoryValues) this.forecastCategory = forecastCategory
    await this.setForecastData(forecastCategory)
    if (fetchZoneWeight) {
      await this.fetchZoneWeight(forecastCategory.buyingSessionGroupId)
    }
  }

  setForecastData = async (forecastCategory?: IGetProductForecast) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { categoryValues = [], ...restCategory } = isObject(forecastCategory)
      ? forecastCategory
      : this.forecastCategory
    const response = await this.productForecastDataStore.getAllForecasts(restCategory)
    this.productForecastData = unionBy(response?.products, this.productForecastData, 'id')
    // this.totalForecastData = unionBy(response?.totals, this.totalForecastData, 'comboId')
    this.totalForecastData =
      this.productForecastService.processUpsertForecastResponseTotal({
        forecastResponseData: response?.totals,
        updatedTotalIds: Object.values(restCategory),
        storeTotal: this.totalForecastData || []
      })
    this.targetCurrency = response?.targetCurrency || ''
  }

  fetchZoneWeight = async (buyingSessionGroupId: string) => {
    if (
      !this.curves.has(buyingSessionGroupId) &&
      !this.getCurveLoading(buyingSessionGroupId)
    ) {
      this.isCurveLoading.set(buyingSessionGroupId, true)
      this.apiService
        .getZoneWeightCurve(buyingSessionGroupId, 'client')
        .then(curvesData => {
          this.curves.set(buyingSessionGroupId, curvesData)
          this.isCurveLoading.set(buyingSessionGroupId, false)
        })
        .catch(error => {
          this.curves.set(buyingSessionGroupId, null)
          this.isCurveLoading.set(buyingSessionGroupId, false)
        })
    }
  }

  getCurveLoading = computedFn(buyingSessionGroupId => {
    const isCurveLoading =
      this.isCurveLoading.has(buyingSessionGroupId) &&
      this.isCurveLoading.get(buyingSessionGroupId)
    return isCurveLoading
  })

  /**
   * Function which will return the zone level otb values of products .
   */
  getZoneWiseOtbValuesForProduct = computedFn((productId, buyingSessionGroupId) => {
    const bsgOtbData = this.curves.get(buyingSessionGroupId) || {}
    return {
      zoneWiseOtbValue: bsgOtbData?.NC?.[productId]?.curves ?? {}
    }
  })

  upsertForecastData = async ({ recordToBeUpdated, updatedValues, changeType }) => {
    const {
      product: { resetSelectedProducts }
    } = stores
    // prevent api requests with unchanged values
    if (
      this.productForecastService.isRecordUpdated({
        recordToBeUpdated,
        updatedValues,
        changeType
      })
    ) {
      const productToBeUpdated = (this.productForecastData || [])?.find(
        product => recordToBeUpdated?.buyingSessionProductId === product?.id
      )
      const clientToBeUpdated = (productToBeUpdated?.clients || [])?.find(
        client => client?.name === recordToBeUpdated?.zone
      )
      const payload = this.productForecastService.getPayloadForUpsertForecast({
        productToBeUpdated,
        clientToBeUpdated,
        recordToBeUpdated,
        updatedValues,
        changeType
      })
      const data = await this.productForecastDataStore.upsertForecast(payload)
      this.totalForecastData =
        this.productForecastService.processUpsertForecastResponseTotal({
          forecastResponseData: data?.totals,
          updatedTotalIds: [
            payload?.buyingSessionGroupId,
            payload?.activityId,
            payload?.productCat1Id,
            payload?.productCat2Id
          ],
          storeTotal: this.totalForecastData || []
        })
      // update all products reset selected products map
      this.productForecastData = unionBy(data?.products, this.productForecastData, 'id')
      this.targetCurrency = data?.targetCurrency || ''
      // update forecast kpis for quick reporting.
      forecastGlobalTopBarStore.updateForecastTopBarKpis(
        find(
          this.productForecastData,
          record => record.id === payload.buyingSessionProductId
        ),
        clientToBeUpdated,
        productToBeUpdated
      )
      resetSelectedProducts()
    }
  }

  multiUpsertForecastsData = async ({ recordToBeUpdated, changeType }) => {
    const {
      product: { resetSelectedProducts }
    } = stores
    const productToBeUpdated = (this.productForecastData || [])?.find(
      product => recordToBeUpdated?.buyingSessionProductId === product?.id
    )
    const payload = this.productForecastService.getPayloadForMultiUpsertForecast({
      productToBeUpdated,
      recordToBeUpdated,
      otbValues: this.getZoneWiseOtbValuesForProduct(
        productToBeUpdated?.id,
        productToBeUpdated?.buyingSessionGroupId
      ),
      changeType
    })
    const data = await this.productForecastDataStore.multiUpsertForecast(payload)
    this.totalForecastData =
      this.productForecastService.processUpsertForecastResponseTotal({
        forecastResponseData: data?.totals,
        updatedTotalIds: [
          payload[0]?.buyingSessionGroupId,
          payload[0]?.activityId,
          payload[0]?.productCat1Id,
          payload[0]?.productCat2Id
        ],
        storeTotal: this.totalForecastData || []
      })
    // update all products reset selected products map
    this.productForecastData = unionBy(data?.products, this.productForecastData, 'id')
    this.targetCurrency = data?.targetCurrency || ''
    // update forecast kpis for quick reporting.

    for (const zone of productToBeUpdated?.clients) {
      forecastGlobalTopBarStore.updateForecastTopBarKpis(
        find(
          this.productForecastData,
          record => record.id === payload[0].buyingSessionProductId
        ),
        zone,
        productToBeUpdated
      )
    }
    resetSelectedProducts()
  }
}

export const productForecastUIStore = new ProductForecastUIStore()
