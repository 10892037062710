import cloneDeep from 'lodash/cloneDeep'
import findIndex from 'lodash/findIndex'
import { action, observable } from 'mobx'

export class BuyingSessionStore {
  @observable buyingSessionCreated = false
  @observable anchorEl = null
  @observable sort = 1
  @observable createDialogOpen = false
  @observable deleteDialogOpen = false
  @observable selectedBuyingSession = null
  @observable selectedBuyingSessionName = null
  @observable isEditing = false
  @observable newlyCreateBuyingSessionId = null
  @observable selectedIndex = 0
  @observable selectedPopOverIndex = 0
  @observable selectedCollections = []
  @observable isNextClicked = 0
  @observable selectedActivities = []
  @observable popOveranchorEl = null
  @observable buyingSessionErrors = []
  @observable isOpenBuyingSessionErrorPopup = false
  @observable isBuyingSessionCreationInProgress = false

  @action
  toggleBuyingSessionCreationProgress = value => {
    Object.assign(this, { isBuyingSessionCreationInProgress: value })
  }

  @action
  handleClick = i => event => {
    event.preventDefault()
    event.stopPropagation()
    Object.assign(this, { selectedIndex: i, anchorEl: event.currentTarget })
  }

  @action
  handelPopOverClick = i => event => {
    event.preventDefault()
    event.stopPropagation()
    this.popOveranchorEl = event.currentTarget
    this.selectedPopOverIndex = i
  }

  @action
  handlePopOverClose = () => {
    this.popOveranchorEl = null
  }

  @action
  handleClose = (event?: any) => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    Object.assign(this, { anchorEl: null })
  }

  @action
  onSortClick = () => {
    Object.assign(this, { sort: !this.sort })
  }

  @action
  handleDialogClose = () => {
    Object.assign(this, {
      createDialogOpen: false,
      selectedBuyingSession: null,
      selectedBuyingSessionName: null,
      isEditing: false,
      isNextClicked: 0,
      selectedCollections: [],
      selectedActivities: []
    })
    this.buyingSessionCreated && this.toggleBuyingSessionCreated()
  }

  @action
  handleDialogOpen = () => {
    Object.assign(this, { createDialogOpen: true, isEditing: false })
  }

  @action
  openDeleteDialog = selectedBuyingSession => {
    Object.assign(this, { deleteDialogOpen: true, selectedBuyingSession })
    this.handleClose()
  }

  @action
  closeDeleteDialog = () => {
    Object.assign(this, { deleteDialogOpen: false })
  }

  @action
  toggleBuyingSessionCreated = () => {
    Object.assign(this, { buyingSessionCreated: !this.buyingSessionCreated })
  }

  @action
  updateCreatedBuyingSessionId = newlyCreateBuyingSessionId => {
    Object.assign(this, { newlyCreateBuyingSessionId })
  }

  @action
  toggleEditingBuyingSession = () => {
    Object.assign(this, { isEditing: !this.isEditing })
  }

  @action
  openEditDialog = (selectedBuyingSession, selectedBuyingSessionName) => {
    Object.assign(this, {
      createDialogOpen: true,
      isEditing: true,
      selectedBuyingSession,
      selectedBuyingSessionName
    })
    this.handleClose()
  }
  @action
  handleNextClick = () => {
    // Redirect user to collection for newly created buyingSession
    Object.assign(this, { isNextClicked: !this.isNextClicked })
  }

  @action
  handleBackClick = () => {
    // Redirect user to collection for newly created buyingSession
    Object.assign(this, { isNextClicked: !this.isNextClicked })
  }

  @action
  onCollectionClick = (collection, event) => {
    const selectedCollections = cloneDeep(this.selectedCollections)
    if (event.target.checked) {
      selectedCollections.push(collection)
    } else {
      const index = findIndex(selectedCollections, { id: collection.id })
      selectedCollections.splice(index, 1)
    }
    Object.assign(this, { selectedCollections })
  }
  @action
  onActivityClick = (collection, activity, event) => {
    const selectedActivities = cloneDeep(this.selectedActivities)
    const activityClone = { ...activity, collectionId: collection.id }
    if (event.target.checked) {
      selectedActivities.push(activityClone)
    } else {
      const index = findIndex(selectedActivities, {
        id: activity.id,
        collectionId: collection.id
      })
      selectedActivities.splice(index, 1)
    }
    Object.assign(this, { selectedActivities })
  }

  @action
  setBuyingSessionErrors = buyingSessionErrors => {
    Object.assign(this, { buyingSessionErrors })
  }

  @action
  setBuyingSessionErrorPopupFlag = isOpenBuyingSessionErrorPopup => {
    Object.assign(this, { isOpenBuyingSessionErrorPopup })
  }
}
