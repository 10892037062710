import { StyledProps } from '@components/3rd-party/mui'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { withStyles } from '@material-ui/core/styles'
import { KPIRollUps } from '@services/collectionServices'
import { strings } from '@stores'
import React from 'react'

interface IKPIRollUpsRow extends StyledProps {
  rollUps: KPIRollUps
  totalSKUs: number
  assortmentType?: any
}

const styles = theme => ({
  tableCell: {
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    display: 'flex'
  }
})

@((withStyles as any)(styles))
export class KPIRollUpsRow extends React.Component<IKPIRollUpsRow> {
  render() {
    const {
      props: { rollUps, totalSKUs, classes, assortmentType }
    } = this

    const {
      aws,
      depth,
      //salesQuantity,
      quantity,
      value,
      sku,
      storeCount
    } = rollUps

    return (
      <TableRow key={`product-collection-rollUps`}>
        <TableCell>
          <div className={classes.tableCell}>{`TOTAL SKUS: ${totalSKUs}`}</div>
        </TableCell>
        {assortmentType !== strings.store ? (
          <TableCell>
            <div className={classes.tableCell}>{'--'}</div>
          </TableCell>
        ) : null}
        <TableCell>
          <div className={classes.tableCell}>{'--'}</div>
        </TableCell>
        <TableCell>
          <div className={classes.tableCell}>{aws}</div>
        </TableCell>
        <TableCell>
          <div className={classes.tableCell}>{'--'}</div>
        </TableCell>
        <TableCell>
          <div className={classes.tableCell}>{'--'}</div>
        </TableCell>
        <TableCell>
          <div className={classes.tableCell}>{depth}</div>
        </TableCell>
        <TableCell>
          <div className={classes.tableCell}>{sku}</div>
        </TableCell>
        {assortmentType !== strings.store ? (
          <TableCell>
            <div className={classes.tableCell}>{storeCount}</div>
          </TableCell>
        ) : null}
        {/* <TableCell>
          {salesQuantity}
        </TableCell> */}
        <TableCell>
          <div className={classes.tableCell}>{value}</div>
        </TableCell>
        <TableCell>
          <div className={classes.tableCell}>{quantity}</div>
        </TableCell>
      </TableRow>
    )
  }
}
