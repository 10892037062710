import { WholesaleSettingsView } from '@modules/common/models/enums/WholesaleSettingsRoute'
import { stores } from '@stores'
import forEach from 'lodash/forEach'
import map from 'lodash/map'
import remove from 'lodash/remove'
import { action, observable } from 'mobx'

import { SyncData } from '../services'

export class SyncSettingsStore {
  private syncAPIService = new SyncData()

  @observable selectedDataToSync = []
  @observable openConfirmation = false
  @observable resultMessage = ''
  @observable isSyncInProgress = false
  @action
  handleToggle = settingsOptions => e => {
    if (e.target.value === 'selectAll') {
      if (e.target.checked) {
        this.selectedDataToSync = map(settingsOptions, value => value.Id)
      } else {
        this.selectedDataToSync = []
      }
    } else {
      e.target.checked
        ? this.selectedDataToSync.push(e.target.value)
        : remove(this.selectedDataToSync, Id => Id === e.target.value)
    }
  }
  @action
  handleSubmit = view => async e => {
    const {
      nav: {
        queryParams: { buyingSessionGroupId }
      }
    } = stores
    let syncFlags = {}
    forEach(this.selectedDataToSync, values => {
      syncFlags[values] = true
    })
    try {
      this.isSyncInProgress = true
      if (view === WholesaleSettingsView.GlobalDataSync) {
        const result = await this.syncAPIService.syncGlobalData(syncFlags)
        this.openConfirmation = true
        this.resultMessage = this.getMessageForGlobal(result)
      } else {
        const result = await this.syncAPIService.syncBSGData(
          syncFlags,
          buyingSessionGroupId
        )
        this.openConfirmation = true
        this.resultMessage = this.getMessageForBSG(result)
      }
    } catch {
      this.isSyncInProgress = false
    } finally {
      this.isSyncInProgress = false
    }
  }
  @action
  closeConfirmation = () => {
    this.openConfirmation = false
  }
  @action
  resetSelection = () => {
    this.selectedDataToSync = []
  }
  getMessageForGlobal = result => {
    let finalResult = ''
    Object.keys(result).forEach(key => {
      if (result[key]?.message) {
        finalResult += `<li>${result[key]?.message}</li>`
      }
    })
    return finalResult
  }
  getMessageForBSG = result => {
    let finalResult = ''
    Object.keys(result).forEach(key => {
      if (result[key]?.message) {
        finalResult += `<li>Price Validity Date: ${result[key]?.message}</li>`
      } else if (result[key]?.status) {
        finalResult += `<li>Buying Session Product: ${result[key]?.status}</li>`
      }
    })
    return finalResult
  }
}

export const syncSettingsStore = new SyncSettingsStore()
