import { StyledProps } from '@components/3rd-party/mui'
import { TitleBar } from '@components/UI-Components/TitleBar'
import { REGION } from '@modules/collection/constants'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { stores } from '@stores'
import { observer } from 'mobx-react'
import React from 'react'

const styles = theme => ({
  sortOptions: {
    padding: '0px 2px 0px 2px',
    outlineStyle: 'unset'
  }
})
interface CollectionProps extends StyledProps {}

@((withStyles as any)(styles))
@observer
export class CollectionTitleBar extends React.Component<CollectionProps> {
  render() {
    const [
      { classes },
      {
        collection: { handleRegionChange, selectedRegion }
      }
    ] = [this.props, stores]
    return (
      <TitleBar
        title='COLLECTION'
        actions={
          <FormGroup className={classes.sortOptions}>
            <FormControl>
              <Select
                disableUnderline
                value={selectedRegion}
                onChange={e => {
                  handleRegionChange(e)
                }}
                inputProps={{
                  name: 'region',
                  id: 'region-change'
                }}
                classes={{
                  root: classes.sortBySelect
                }}
              >
                {REGION.map((region, index) => (
                  <MenuItem key={`Collection${index}`} value={region.name}>
                    <Typography style={{ paddingTop: 3 }} variant='caption'>
                      {region.name}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </FormGroup>
        }
      />
    )
  }
}
