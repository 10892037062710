import { StyledProps } from '@components/3rd-party/mui'
import AttributeSelectorIcon from '@components/UI-Components/svg/Attributes.svg'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Menu from '@material-ui/core/Menu'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { stores, strings } from '@stores'
import classNames from 'classnames'
import some from 'lodash/some'
import { observer } from 'mobx-react'
import React from 'react'

import { ViewAttributeSelectorTabs } from './ViewAttributeSelectorTabs'

const styles = theme => ({
  popOver: {
    width: 330,
    fontSize: 14,
    outline: 'none',
    overflow: 'hidden',
    maxHeight: 600
  },
  icon: {
    color: theme.colors.grey,
    cursor: 'pointer',
    marginRight: 27,
    width: '24px',
    height: '24px'
  },
  iconWithBorder: {
    margin: '0px 17px 0px 17px'
  },
  sortOptions: {
    display: 'inherit',
    outline: 'none',
    overflowY: 'auto',
    maxHeight: 489
  },
  heading: {
    padding: theme.spacing(1),
    outline: 0
  },
  formControlLable: {
    margin: 0,
    width: 150
  },
  tabSelector: {
    margin: 8
  }
})

interface IViewAttributeSelector extends StyledProps {
  attributes?: any
}

@((withStyles as any)(styles))
@observer
export class ViewAttributeSelector extends React.Component<IViewAttributeSelector> {
  render() {
    const [
      {
        props: { classes, attributes }
      },
      {
        collection: {
          handleClose,
          open,
          handleClick,
          anchorEl,
          coreAttributes,
          visibleAttributeSelection,
          updateAttributeSelection,
          setVisibleAttributes,
          isAttributeSet,
          selectedViewAttributeSelectorTab
        }
      }
    ] = [this, stores]

    !isAttributeSet && attributes && setVisibleAttributes(attributes)
    const isCustomeAttributes = selectedViewAttributeSelectorTab === 'tags'
    const allAttributes = isCustomeAttributes
      ? visibleAttributeSelection.filter(item =>
          some(coreAttributes, { value: item.value })
        )
      : visibleAttributeSelection.filter(
          item => !some(coreAttributes, { value: item.value })
        )
    return (
      <React.Fragment>
        <Tooltip title={strings.manageAttributes}>
          <div className={classNames([classes.icon, classes.iconWithBorder])}>
            {/* eslint-disable-next-line jsx-a11y/role-supports-aria-props */}
            <img
              id='viewByPopper'
              aria-haspopup='true'
              aria-owns={open ? 'viewByPopper' : undefined}
              src={AttributeSelectorIcon}
              alt={strings.kpiView}
              onClick={event => handleClick(event)}
            />
          </div>
        </Tooltip>
        <Menu
          id='viewByPopper'
          anchorEl={anchorEl}
          open={Boolean(anchorEl && anchorEl.id === 'viewByPopper')}
          onClose={handleClose}
          classes={{
            paper: classes.popOver
          }}
        >
          <div className={classes.tabSelector}>
            <ViewAttributeSelectorTabs />
          </div>
          <div className={classes.heading}>
            <Typography variant='caption'>{strings.maxAttributeMessage}</Typography>
          </div>
          <FormGroup className={classes.sortOptions}>
            {allAttributes.map(({ name, value, isSelected, enabled }) => (
              <FormControlLabel
                key={value}
                id={value}
                className={classes.formControlLable}
                control={
                  <Checkbox
                    onChange={(e, checked) => {
                      updateAttributeSelection(name, checked)
                    }}
                    disabled={!enabled}
                    checked={isSelected}
                    value={value}
                    color='default'
                  />
                }
                label={name}
              />
            ))}
          </FormGroup>
        </Menu>
      </React.Fragment>
    )
  }
}
