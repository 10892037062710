import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import { strings } from '@stores'
import classNames from 'classnames'
import React from 'react'
import * as css from './StandardPopup.css'

export enum PopupSize {
  extraSmall = 'xs',
  small = 'sm',
  medium = 'md',
  large = 'lg',
  extraLarge = 'xl'
}

interface IClasses {
  dialogHeader?: any
  dialogBody?: any
  dialogFooter?: any
  dialogRoot?: any
}

interface StandardPopupProps {
  popupTitle?: string
  children?: any
  showBack?: any
  classes?: IClasses
  fullWidth?: boolean
  isSaveInProgress?: boolean
  nextButtonLabel?: string
  disableNext?: boolean
  maxWidth?: PopupSize
  isBulkOperation?: boolean
  cancelHandler?: () => void
  saveHandler?: () => void
  backHandler?: () => void
  hideCancelButton?: boolean
  cancelButtonLabel?: string
  showNextButton?: boolean
}

export const StandardPopup: React.FunctionComponent<StandardPopupProps> = props => {
  const {
    popupTitle,
    children,
    cancelHandler,
    saveHandler,
    showBack,
    backHandler,
    fullWidth,
    maxWidth,
    nextButtonLabel,
    disableNext,
    isSaveInProgress,
    isBulkOperation,
    classes,
    hideCancelButton,
    cancelButtonLabel,
    showNextButton = true
  } = props
  return (
    <Dialog
      open={true}
      aria-labelledby='form-create-buying-session'
      fullWidth={fullWidth}
      maxWidth={maxWidth || PopupSize.small}
      classes={{ root: classNames(css.standardPopupDialogRoot, classes?.dialogRoot) }}
    >
      <div className={css.popupRoot}>
        <div className={css.container}>
          <form className={css.standardPopupForm}>
            <React.Fragment>
              {popupTitle && (
                <DialogTitle
                  id='form-dialog-title'
                  className={classNames(css.formHeader, classes?.dialogHeader)}
                >
                  <Typography variant='body2' className={css.formHeaderText}>
                    {popupTitle}
                  </Typography>
                </DialogTitle>
              )}
              <DialogContent
                className={classNames(css.dialogContent, classes?.dialogBody)}
              >
                {children}
              </DialogContent>

              <DialogActions
                className={classNames(
                  css.footer,
                  !isBulkOperation ? css.borderTop : null,
                  classes?.dialogFooter
                )}
              >
                {showBack && (
                  <Button
                    onClick={backHandler}
                    className={css.backButton}
                    variant={'text'}
                    disabled={isSaveInProgress}
                  >
                    {strings.back}
                  </Button>
                )}
                {hideCancelButton ? null : (
                  <Button
                    onClick={cancelHandler}
                    className={css.cancelButton}
                    variant={'text'}
                    disabled={isSaveInProgress}
                  >
                    {cancelButtonLabel || strings.cancel}
                  </Button>
                )}
                {showNextButton && (
                  <Button
                    onClick={!isSaveInProgress ? saveHandler : () => {}}
                    variant={'text'}
                    disabled={!!disableNext || isSaveInProgress}
                  >
                    {isSaveInProgress ? (
                      <CircularProgress
                        color='secondary'
                        size={24}
                        className={`${css.buttonProgress} ${css.rightIcon}`}
                      />
                    ) : (
                      nextButtonLabel || strings.next
                    )}
                  </Button>
                )}
              </DialogActions>
            </React.Fragment>
          </form>
        </div>
      </div>
    </Dialog>
  )
}
