/*
 * @Author: Raeesaa Metkari
 * @Date: 2020-03-27 03:31:46
 * @Last Modified by: Raeesaa Metkari
 * @Last Modified time: 2020-04-13 12:50:57
 */
import { MutationFunctionOptions } from '@apollo/client/react/types/types'
import {
  CentricGraphQLMutationResponseStatus,
  CentricGraphQLStandardTypeNames,
  GraphQLRootTypes
} from '@modules/common/models/enums/CentricGraphQLSchema'
import { CentricNodeType } from '@modules/common/models/enums/CentricNodeType'
import { WholesaleSettingsView } from '@modules/common/models/enums/WholesaleSettingsRoute'
import { CriteriaOperationType } from '@modules/models'
import { IClientTableData } from '@services/wholesale/clientManagementService'
import { nav, stores } from '@stores'
import { ExecutionResult } from 'graphql/execution'
import cloneDeep from 'lodash/cloneDeep'
import find from 'lodash/find'
import { action, computed, observable } from 'mobx'

import { GET_CLIENTS } from '../graphql'
import GET_CLIENT from '../graphql/client'

export class ClientManagementStore {
  @computed get whereInputForClients() {
    const {
      nav: {
        queryParams: { view }
      }
    } = stores
    return view === WholesaleSettingsView.Worldwide
      ? undefined
      : {
          __Parent__: {
            Id: {
              operation: CriteriaOperationType.EQ,
              value: view
            }
          }
        }
  }

  @observable mutations: {
    toggleReorderFlagForClient?: (
      options?: MutationFunctionOptions<any, { id: string }>
    ) => Promise<void | ExecutionResult<{}>>
  } = { toggleReorderFlagForClient: null }

  @action setMutations = mutations => {
    this.mutations = {
      ...this.mutations,
      ...mutations
    }
  }

  @action toggleReorderFlagForClient =
    (client: IClientTableData) => async (event, checked: boolean) => {
      try {
        await this.mutations.toggleReorderFlagForClient({
          variables: {
            id: client.id
          },
          optimisticResponse: {
            __typename: GraphQLRootTypes.Mutation,
            VIPBoard_ToggleReorderFlagForClient: {
              __typename: CentricGraphQLStandardTypeNames.CustomActionResult,
              status: CentricGraphQLMutationResponseStatus.Success,
              modifiedCNLs: [
                {
                  Id: client.id,
                  Type: CentricNodeType.Client,
                  Name: client.name,
                  __typename: CentricGraphQLStandardTypeNames.NodeInfo
                }
              ]
            }
          },
          update: (proxy, { data: { VIPBoard_ToggleReorderFlagForClient } }) => {
            let modifiedClient = find(
              VIPBoard_ToggleReorderFlagForClient?.modifiedCNLs ?? [],
              CNL => {
                return CNL.Id === client.id && CNL.Type === CentricNodeType.Client
              }
            )

            let data: any = proxy.readQuery({
              query: GET_CLIENTS,
              variables: this.whereInputForClients
            })

            let clients = cloneDeep(data?.findVIPClient?.Items ?? [])
            let updatedClient = find(clients, client => client.Id === modifiedClient.Id)
            updatedClient.GIV_VIPClient_Reorder_bool =
              !updatedClient.GIV_VIPClient_Reorder_bool

            proxy.writeQuery({
              query: GET_CLIENTS,
              variables: this.whereInputForClients,
              data: {
                findVIPClient: {
                  ...data.findVIPClient,
                  Items: clients
                }
              }
            })
          },
          /**
           * NOTE: Adding refetch query here despite of having update since we don't get reorder flag
           * in response of mutation and there are chances that assumption made for updated value for re-order
           * might be incorrect in case multiple users are updating same record (update is added as it is needed for
           * optimistic response)
           */
          refetchQueries: [
            {
              query: GET_CLIENT,
              variables: {
                id: client.id
              }
            }
          ]
        })
      } finally {
      }
    }

  @action onClickClient = (client: IClientTableData) => event => {
    const { updateQueryParams } = nav
    updateQueryParams(
      {
        client: client.id
      },
      'push'
    )
  }
}

export const clientManagementStore = new ClientManagementStore()
