import { config } from '@config'
import { storage } from '@services/storageService'
import axios from 'axios'

export class SyncData {
  syncBSGData = async (syncFlags, buyingSessionGroupId?: string) => {
    try {
      const url = `${config.buyingBoardServiceRestEndpoint}sync/bsgSync`
      const accessToken: string = storage.getItem('access_token')
      const body = {
        ...(buyingSessionGroupId && { buyingSessionGroupId }),
        syncFlags
      }
      const response = await axios.post(url, body, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      return response.data
    } catch (e) {
      console.log(e)
      // throw e
    }
  }

  syncGlobalData = async syncFlags => {
    try {
      const url = `${config.buyingBoardServiceRestEndpoint}sync/globalSync`
      const accessToken: string = storage.getItem('access_token')
      const body = {
        syncFlags
      }
      const response = await axios.post(url, body, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      return response.data
    } catch (e) {
      console.log(e)
      // throw e
    }
  }
}
