import React from 'react'
import { config } from '@config'

const style = {
  height: 20,
  background: 'black',
  position: 'fixed' as any,
  right: 0,
  bottom: 0,
  textAlign: 'center' as any,
  color: 'white',
  zIndex: 10000,
  paddingLeft: 15,
  paddingRight: 15
}

export const Environment = React.memo(() => {
  const env = config.environment

  return env ? <div style={style}>{env}</div> : null
})
