import { StyledProps } from '@components/3rd-party/mui'
import { DropTarget } from '@components/Utils/DropTarget'
import { ZoneDisplayNames } from '@modules/common/models/enums'
import { constants } from '@modules/retail/buyingSession'
import Chip from '@material-ui/core/Chip'
import Icon from '@material-ui/core/Icon'
import Typography from '@material-ui/core/Typography'
import { IPermissions } from '@routes'
import {
  getFilteredAttributes,
  getSalesPeriodStartAndEndDate
} from '@services/collectionServices'
import { getZoneOrder, sortListUsingOrderList } from '@services/commonServices'
import { getImageUrl } from '@services/imageService'
import { stores, strings } from '@stores'
import classNames from 'classnames'
import map from 'lodash/map'
import { useObserver } from 'mobx-react-lite'
import React, { memo } from 'react'
import * as css from './GridCard.css'
import { ProductActionItem, ProductActions } from './ProductCardOld/ProductActions'
import { ProductCard } from './ProductCardOld/ProductCard'
import { ProductDetail } from './ProductCardOld/ProductDetails'
import { ProductInfo } from './ProductCardOld/ProductInfo'
import { ProductInfoItem } from './ProductCardOld/ProductInfoItem'
import { ProductMedia } from './ProductCardOld/ProductMedia'
import { ProductStatus } from './ProductCardOld/ProductStatus'
import { CrossIcon } from './svg'
import { Tag } from './Tag'

interface MyProps extends StyledProps {
  product?: any
  client?: any
  genderId?: any
  podiumId?: any
  refetch?: any
  addProductToAssortment?: any
  markProductChannelExclusive?: any
  buyingSessionProducts?: any
  removeFavourite?: (channelId: any, productId: any) => void
  markFavourite?: (channelId: any, productId: any) => void
  handleTagDrop?: (product: any, type: any, id: any) => any
  removeFreeTag?: (productId: any, freeTagId: any) => any
  removeBuyerClusterFromZoneAssortmentProduct?: (
    clusterId: any,
    assortmentProductId: string,
    buyingSessionProduct: any,
    existinBuyerClusters: Array<any>
  ) => any
  removeAssortmentProduct?: (
    assortmentId: string,
    assortmentProductId: string,
    productId: string
  ) => void
  handleMultiProductAction?: (type: string, tagId: string) => void
  removeSalesPeriodFromProduct?: (buyingSessionProductId: string) => void
  removeZoneRestriction?: (buyingSessionProductId: string, zoneId: string) => void
  deleteProduct?: (id: any, product?: any) => any
  clickHandler?: (e: any, index: any, product: any) => any
  rightClickHandler?: (e: any) => any
  permissions?: IPermissions
  history?: any
  match?: any
  fetchMore?: any
  hasMore?: any
  loadFunc?: any
  sectionHeight?: any
  canDelete?: boolean
  assortmentProductId?: string
  index?: string
  assortment?: any
  restrictedZones?: any
  assortmentType?: string
  assortmentRetailPrice?: any
  isSelected?: boolean
}

export const GridCard: React.FunctionComponent<MyProps> = memo((props: MyProps) => {
  return useObserver(function useHook() {
    const [
      {
        product,
        handleTagDrop,
        permissions,
        deleteProduct,
        canDelete,
        assortmentProductId,
        clickHandler,
        rightClickHandler,
        removeBuyerClusterFromZoneAssortmentProduct,
        removeAssortmentProduct,
        removeZoneRestriction,
        removeSalesPeriodFromProduct,
        removeFreeTag,
        markFavourite,
        removeFavourite,
        refetch,
        index,
        isSelected
      },
      {
        collection: { selectedAttributes },
        clusterStatusDropDown: { handelPopOverClick },
        assortment: { getRetailPrice },
        nav: {
          params: { clusterId, assortmentType }
        },
        sidebar: { isRegionalMerchandiser },
        site: { isGlobalMerchandiserOrAdmin } = {} as any,
        product: { toggleChannelDeleteConfirmationDialog }
      }
    ] = [props, stores]

    const filteredAttributes = getFilteredAttributes(product.attributes)

    const {
      active,
      mmcName,
      description,
      image,
      id,
      productStatus,
      retailPriceToBeDisplayed,
      productStatusToBeDisplayed,
      comments,
      favourites,
      channels,
      salesPeriod,
      avgRatingCount,
      freeTags,
      tags,
      favouriteCount,
      restrictedZones,
      buyingSession,
      isRestricted,
      rmClusters: buyerClusters,
      actualProductObj,
      associatedLooks,
      substitutesInLooks
    } = product
    const canEditClusterStatus =
      productStatus !== strings.coreStatus && !isRegionalMerchandiser()
    // FIXME: RM: This can be further optimized since buying session status will be same for all products
    const isClosedOrFrozen = buyingSession
      ? buyingSession.status === constants.CLOSED ||
        (isRegionalMerchandiser() && buyingSession.status === constants.FROZEN)
      : false

    const productDetails = assortmentProductId
      ? css.productDetailsContainerWithoutOverflowAndAction
      : css.productDetailsContainerWithoutOverflow

    const orderedRestrictedZones = sortListUsingOrderList(
      restrictedZones,
      ['name'],
      getZoneOrder()
    )
    return (
      <div
        className={classNames(
          css.productCard,
          isSelected ? css.selectedProduct : css.productCardWithoutBorder
        )}
        onClick={e => {
          if (clickHandler) {
            clickHandler(e, index, actualProductObj)
          }
        }}
        onContextMenu={rightClickHandler}
      >
        <DropTarget
          className={css.dropTargetClass}
          canDrop
          showDropBackground
          handleDrop={({ id, type }) =>
            handleTagDrop!(
              assortmentProductId ? assortmentProductId : actualProductObj,
              type,
              id
            )
          }
        >
          <ProductCard
            active={active}
            isRestricted={isRestricted}
            tagAction={e => {
              e.stopPropagation()
              if (canDelete) {
                deleteProduct!(assortmentProductId, actualProductObj)
              }
            }}
            removeTag={canDelete}
          >
            {productStatusToBeDisplayed && (
              <ProductStatus status={productStatusToBeDisplayed} />
            )}
            <ProductMedia image={getImageUrl(image)} />

            <ProductDetail className={productDetails}>
              <ProductInfo name={description} sku={mmcName} />
              {selectedAttributes['price'] && (
                <ProductInfoItem
                  className={css.productInfoItem}
                  title={strings.retailPrice}
                  content={
                    assortmentType === strings.store
                      ? 'NA'
                      : getRetailPrice(retailPriceToBeDisplayed)
                  }
                />
              )}
              {map(
                filteredAttributes as any[],
                ({
                  value,
                  definition: {
                    name,
                    displayName: { singular }
                  }
                }) => {
                  return (
                    <ProductInfoItem
                      className={css.productInfoItem}
                      key={name}
                      title={singular}
                      content={value}
                    />
                  )
                }
              )}
              {selectedAttributes['associatedLooks'] && (
                <ProductInfoItem
                  className={css.productInfoItem}
                  title={strings.associatedLooks}
                  content={associatedLooks}
                />
              )}
              {selectedAttributes['substitutesInLooks'] && (
                <ProductInfoItem
                  className={css.productInfoItem}
                  title={strings.substitutesInLooks}
                  content={substitutesInLooks}
                />
              )}
              {/** Exclusivity Tag */}
              {selectedAttributes['channels'] ? (
                <ProductInfoItem
                  className={css.productInfoItem}
                  title={strings.channels}
                  content={
                    channels && channels.length ? (
                      channels.map(channel =>
                        permissions?.canEdit &&
                        !assortmentProductId &&
                        channel.name !== strings.wholeSaleChannelName &&
                        productStatus !== strings.productStatus.CORE &&
                        !isClosedOrFrozen ? (
                          <Chip
                            key={channel.name}
                            classes={{
                              label: css.label,
                              root: css.chipRoot
                            }}
                            deleteIcon={
                              <img src={CrossIcon} className={css.cursor} alt='' />
                            }
                            onDelete={() => {
                              toggleChannelDeleteConfirmationDialog(
                                actualProductObj.id,
                                channel.id,
                                buyingSession ? buyingSession.status : null
                              )
                            }}
                            className={css.chip}
                            label={
                              <Tag key={id} name={channel.name} tagStyle={css.tag} />
                            }
                          />
                        ) : (
                          <Chip
                            key={channel.name}
                            classes={{
                              label: css.label,
                              root: css.chipRoot
                            }}
                            className={css.chip}
                            label={
                              <Tag key={id} name={channel.name} tagStyle={css.tag} />
                            }
                          />
                        )
                      )
                    ) : (
                      <Typography className={css.contentText}>NA</Typography>
                    )
                  }
                />
              ) : null}
              {selectedAttributes['retails_cluster'] && isGlobalMerchandiserOrAdmin() ? (
                <ProductInfoItem
                  className={css.productInfoItem}
                  title={strings.retailClusters}
                  content={
                    tags && tags.length ? (
                      tags.map(
                        ({
                          assortmentProductId,
                          assortmentId,
                          clusterId,
                          clusterName,
                          colorHex,
                          statusId,
                          statusName
                        }) =>
                          !isClosedOrFrozen ? (
                            <Chip
                              classes={{
                                label: css.label,
                                root: css.chipRoot
                              }}
                              key={`tags-${clusterId}`}
                              deleteIcon={
                                <img src={CrossIcon} className={css.cursor} alt='' />
                              }
                              {...({
                                onDelete:
                                  productStatus === strings.coreStatus
                                    ? null
                                    : () => {
                                        removeAssortmentProduct!(
                                          assortmentId,
                                          assortmentProductId,
                                          actualProductObj.id
                                        )
                                      }
                              } as any)}
                              onClick={
                                canEditClusterStatus
                                  ? handelPopOverClick(actualProductObj.id, clusterId)
                                  : null
                              }
                              className={css.chip}
                              label={
                                <Tag
                                  key={assortmentProductId}
                                  clusterId={clusterId}
                                  name={clusterName}
                                  color={colorHex}
                                  statusId={statusId}
                                  clusterStatus={statusName}
                                  tagStyle={css.tag}
                                />
                              }
                            />
                          ) : (
                            <Chip
                              classes={{
                                label: css.label,
                                root: css.chipRoot
                              }}
                              key={`tags-${clusterId}`}
                              className={css.chip}
                              label={
                                <Tag
                                  key={clusterId}
                                  name={clusterName}
                                  color={colorHex}
                                  clusterStatus={statusName}
                                  tagStyle={css.tag}
                                />
                              }
                            />
                          )
                      )
                    ) : (
                      <Typography className={css.contentText}>NA</Typography>
                    )
                  }
                />
              ) : selectedAttributes['retails_cluster'] &&
                isRegionalMerchandiser() &&
                !(assortmentType === strings.zone || assortmentType === strings.store) ? (
                <ProductInfoItem
                  className={css.productInfoItem}
                  title={strings.retailClusters}
                  content={
                    tags && tags.length ? (
                      tags.map(
                        ({
                          assortmentProductId,
                          assortmentId,
                          clusterId,
                          clusterName,
                          colorHex,
                          statusId,
                          statusName
                        }) =>
                          permissions?.canEdit && !isClosedOrFrozen ? (
                            <Chip
                              classes={{
                                label: css.label,
                                root: css.chipRoot
                              }}
                              key={`tags-${clusterId}`}
                              deleteIcon={
                                <img src={CrossIcon} className={css.cursor} alt='' />
                              }
                              {...({
                                onDelete:
                                  productStatus === strings.coreStatus
                                    ? null
                                    : () => {
                                        removeAssortmentProduct!(
                                          assortmentId,
                                          assortmentProductId,
                                          actualProductObj.id
                                        )
                                      }
                              } as any)}
                              onClick={
                                canEditClusterStatus
                                  ? handelPopOverClick(actualProductObj.id, clusterId)
                                  : null
                              }
                              className={css.chip}
                              label={
                                <Tag
                                  key={assortmentProductId}
                                  clusterId={clusterId}
                                  name={clusterName}
                                  color={colorHex}
                                  statusId={statusId}
                                  clusterStatus={statusName}
                                  tagStyle={css.tag}
                                />
                              }
                            />
                          ) : (
                            <Chip
                              classes={{
                                label: css.label,
                                root: css.chipRoot
                              }}
                              key={`tags-${clusterId}`}
                              className={css.chip}
                              label={
                                <Tag
                                  key={clusterId}
                                  name={clusterName}
                                  color={colorHex}
                                  clusterStatus={statusName}
                                  tagStyle={css.tag}
                                />
                              }
                            />
                          )
                      )
                    ) : (
                      <Typography className={css.contentText}>NA</Typography>
                    )
                  }
                />
              ) : null}

              {selectedAttributes['retails_cluster'] &&
              isRegionalMerchandiser() &&
              buyerClusters ? (
                <ProductInfoItem
                  className={css.productInfoItem}
                  title={strings.rmRetailClusters}
                  content={
                    buyerClusters && buyerClusters.length > 0
                      ? buyerClusters.map(({ id, name, colorHex }) => (
                          <Chip
                            classes={{
                              label: css.label,
                              root: css.chipRoot
                            }}
                            key={id}
                            className={css.chip}
                            deleteIcon={
                              <img src={CrossIcon} className={css.cursor} alt='' />
                            }
                            {...({
                              onDelete:
                                productStatus === strings.coreStatus ||
                                !isRegionalMerchandiser() ||
                                !canDelete ||
                                clusterId ||
                                assortmentType === strings.store
                                  ? null
                                  : () => {
                                      removeBuyerClusterFromZoneAssortmentProduct!(
                                        id,
                                        assortmentProductId as string,
                                        actualProductObj,
                                        buyerClusters
                                      )
                                    }
                            } as any)}
                            label={
                              <Tag
                                key={id}
                                name={name}
                                color={colorHex}
                                tagStyle={css.tag}
                              />
                            }
                          />
                        ))
                      : 'NA'
                  }
                />
              ) : null}
              {!isRegionalMerchandiser() &&
              selectedAttributes['excludedZones'] &&
              orderedRestrictedZones &&
              orderedRestrictedZones.length ? (
                <React.Fragment>
                  <ProductInfoItem
                    className={css.productInfoItem}
                    title={strings.restrictedIn}
                    content={
                      orderedRestrictedZones && orderedRestrictedZones.length ? (
                        orderedRestrictedZones.map(({ id, name }) =>
                          permissions?.canEdit && !isClosedOrFrozen ? (
                            <Chip
                              classes={{
                                label: css.label,
                                root: css.chipRoot
                              }}
                              key={id}
                              deleteIcon={
                                <img src={CrossIcon} className={css.cursor} alt='' />
                              }
                              {...({
                                onDelete:
                                  productStatus === strings.coreStatus
                                    ? null
                                    : async () => {
                                        await removeZoneRestriction!(
                                          id,
                                          actualProductObj.id
                                        )
                                        refetch()
                                      }
                              } as any)}
                              className={css.chip}
                              label={
                                <Tag
                                  key={id}
                                  name={ZoneDisplayNames[name] || name}
                                  tagStyle={css.tag}
                                />
                              }
                            />
                          ) : (
                            <Chip
                              classes={{
                                label: css.label,
                                root: css.chipRoot
                              }}
                              key={id}
                              className={css.chip}
                              label={
                                <Tag
                                  key={id}
                                  name={ZoneDisplayNames[name] || name}
                                  tagStyle={css.tag}
                                />
                              }
                            />
                          )
                        )
                      ) : (
                        <Typography className={css.contentText}>NA</Typography>
                      )
                    }
                  />
                </React.Fragment>
              ) : null}
              {/* Sales Period */}
              {selectedAttributes['sales_period'] &&
                salesPeriod &&
                (permissions?.canEdit && !isClosedOrFrozen ? (
                  <ProductInfoItem
                    className={css.productInfoItem}
                    title={strings.salesPeriod}
                    content={
                      <Chip
                        classes={{
                          label: css.label,
                          root: css.chipRoot
                        }}
                        className={css.chip}
                        label={
                          <Tag
                            key={id}
                            name={salesPeriod.name}
                            customeToolTipText={getSalesPeriodStartAndEndDate(
                              salesPeriod
                            )}
                            tagStyle={css.tag}
                          />
                        }
                        deleteIcon={<img src={CrossIcon} className={css.cursor} alt='' />}
                        {...({
                          onDelete:
                            !isRegionalMerchandiser() && !isClosedOrFrozen
                              ? () => removeSalesPeriodFromProduct!(id)
                              : null
                        } as any)}
                      />
                    }
                  />
                ) : (
                  <ProductInfoItem
                    className={css.productInfoItem}
                    title={strings.salesPeriod}
                    content={
                      <Chip
                        classes={{
                          label: css.label,
                          root: css.chipRoot
                        }}
                        className={css.chip}
                        label={
                          <Tag key={id} name={salesPeriod.name} tagStyle={css.tag} />
                        }
                      />
                    }
                  />
                ))}
              {/* Free Tags */}
              {selectedAttributes['free_tags'] ? (
                <ProductInfoItem
                  className={css.productInfoItem}
                  title={strings.freeTags}
                  content={
                    freeTags && freeTags.length ? (
                      freeTags.map(({ id: freeTagId, name }) =>
                        permissions?.canEdit && !isClosedOrFrozen ? (
                          <Chip
                            classes={{
                              label: css.label,
                              root: css.chipRoot
                            }}
                            key={freeTagId}
                            deleteIcon={
                              !isRegionalMerchandiser() &&
                              !isClosedOrFrozen && (
                                <img src={CrossIcon} className={css.cursor} alt='' />
                              )
                            }
                            {...({
                              onDelete:
                                !isRegionalMerchandiser() && !isClosedOrFrozen
                                  ? () => {
                                      removeFreeTag!(id, freeTagId)
                                    }
                                  : null
                            } as any)}
                            className={css.chip}
                            label={<Tag key={id} name={name} tagStyle={css.tag} />}
                          />
                        ) : (
                          <Chip
                            classes={{
                              label: css.label,
                              root: css.chipRoot
                            }}
                            key={freeTagId}
                            className={css.chip}
                            label={<Tag key={id} name={name} tagStyle={css.tag} />}
                          />
                        )
                      )
                    ) : (
                      <Typography className={css.contentText}>NA</Typography>
                    )
                  }
                />
              ) : null}

              {!assortmentProductId && selectedAttributes['actions'] ? (
                <div className={css.productActions}>
                  <ProductActions>
                    <ProductActionItem>
                      <Icon
                        color={favourites.length ? 'error' : 'disabled'}
                        onClick={e => {
                          e.stopPropagation()
                          !favourites.length && !isRestricted && !isClosedOrFrozen
                            ? markFavourite!(id, active)
                            : !isRestricted &&
                              !isClosedOrFrozen &&
                              removeFavourite!(id, active)
                        }}
                      >
                        favorite
                      </Icon>
                      <Typography variant='caption'>{favouriteCount}</Typography>
                    </ProductActionItem>
                    <ProductActionItem>
                      <Icon
                        style={{
                          color: comments.length ? '#FF9800' : 'lightgrey'
                        }}
                      >
                        comment
                      </Icon>
                    </ProductActionItem>
                    <ProductActionItem>
                      <Icon
                        style={{
                          color: avgRatingCount ? '#FF9800' : 'lightgrey'
                        }}
                      >
                        star
                      </Icon>
                      {avgRatingCount > 0 && (
                        <Typography variant='caption'>{avgRatingCount}</Typography>
                      )}
                    </ProductActionItem>
                  </ProductActions>
                </div>
              ) : null}
            </ProductDetail>
          </ProductCard>
        </DropTarget>
      </div>
    )
  })
})
