import { QueryResult } from '@apollo/client/react'
import { Query } from '@apollo/client/react/components/Query'
import { LoadingIndicator } from '@components/UI-Components/LoadingIndicator'
import { AuthorizationError } from '@components/Utils/AuthorizationError'
import { ErrorContainer } from '@modules/errors/containers/Error'
import { IPermissions } from '@routes'
import isEmpty from 'lodash/isEmpty'
import React from 'react'
import { withRouter } from 'react-router-dom'

import { BuyingSession } from '../component/BuyingSession'
import { constants } from '../constants'
import { GET_GENDERS } from '../graphql/getGenders'

interface IBuyingSession {
  match?: any
  permissions?: IPermissions
}

@(withRouter as any)
export class BuyingSessionContainer extends React.Component<IBuyingSession> {
  state = {
    order: constants.asc
  }

  // TODO: This can be done locally by mutating cache
  onOrderChange = order => {
    if (order === constants.asc) {
      this.setState({
        order: constants.desc
      })
    } else {
      this.setState({
        order: constants.asc
      })
    }
  }

  render() {
    const {
      state: { order },
      props: { match, permissions }
    } = this
    return permissions && permissions.canRead ? (
      <Query
        query={GET_GENDERS}
        variables={{ orderBy: order, SalesYearId: match.params.yearId }}
      >
        {({ loading, error, data: { genders } = { genders: [] } }: QueryResult) => {
          if (loading && isEmpty(genders)) return <LoadingIndicator />
          if (error) return <ErrorContainer error={error} />

          return (
            <BuyingSession
              genders={genders}
              salesYear={{}}
              onOrderChange={this.onOrderChange}
              order={this.state.order}
              permissions={permissions}
            />
          )
        }}
      </Query>
    ) : (
      <AuthorizationError />
    )
  }
}
