import { NoRecordFound } from '@components/UI-Components/NoRecordFound'
import {
  StyledPrimeTree,
  TreeNavProps
} from '@components/UI-Components/StandardTreeView/Components/TreeNav'
import { Paper } from '@material-ui/core'
import isEmpty from 'lodash/isEmpty'
import React from 'react'

import { ShowroomMenu } from './menu/ShowroomMenu'
import * as css from './TreeNavDropdown.css'

export interface ITreeNavDropdownProps {
  viewName?: string
  navData?: TreeNavProps
  anchorEl?: any
}

export const TreeNavDropdown: React.FunctionComponent<ITreeNavDropdownProps> = props => {
  const { navData } = props
  return (
    <ShowroomMenu
      id='customized-menu-for-category-jump'
      papermenucss={css.viewSelectorMenu}
      keepMounted
      anchorEl={navData?.anchorForNav}
      open={Boolean(navData?.anchorForNav)}
      onClose={navData?.onBackDrop}
    >
      <Paper>
        <div className={css.container}>
          {!isEmpty(navData) ? (
            <StyledPrimeTree {...navData} withoutHeader={true} />
          ) : (
            <NoRecordFound />
          )}
        </div>
      </Paper>
    </ShowroomMenu>
  )
}
