import { config } from '@config'
import { storage } from '@services/storageService'
import axios from 'axios'
import { Channel } from '@modules/common/models/enums/Channel'

export class BuyTargetSync {
  syncBuyTabData = async (buyingSessionGroupId?: string) => {
    try {
      const url = `${config.buyingBoardServiceRestEndpoint}sync/backfill/bspKPIs`
      const accessToken: string = storage.getItem('access_token')
      const body = {
        ...(buyingSessionGroupId && { buyingSessionGroupId })
      }
      const response = await axios.post(url, body, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      return response
    } catch (e) {
      console.log(e)
      // throw e
    }
  }

  getWholesaleBuyProducts = async (
    buyingSessionGroupId,
    buyingSessionId,
    clientId,
    doorId
  ) => {
    try {
      const url = `${config.buyingBoardServiceRestEndpoint}buy/buyingSessionGroupId/${buyingSessionGroupId}/wholesaleBuyProducts`
      const accessToken: string = storage.getItem('access_token')
      const response: any = await axios.get(url, {
        params: {
          buyingSessionId,
          clientId,
          doorId
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      return response?.data
    } catch (e) {
      console.log(e)
    }
  }

  getRetailBuyProducts = async (
    buyingSessionGroupId,
    buyingSessionId,
    clientId,
    doorIds
  ) => {
    try {
      const quantityType = config.defaultQuantityType[Channel.Retail]
      const url = `${config.buyingBoardServiceRestEndpoint}buy/buyingSessionGroupId/${buyingSessionGroupId}/retailBuyProducts`
      const accessToken: string = storage.getItem('access_token')
      const response: any = await axios.get(url, {
        params: {
          buyingSessionId,
          clientId,
          doorIds,
          quantityType
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      return response?.data
    } catch (e) {
      console.log(e)
    }
  }
}
