import { useQuery } from '@apollo/client/react/hooks/useQuery'
import { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions'
import {
  SA_CLIENT_ORDER_PRODUCT_SUMMARY,
  GET_VIP_CLIENT_ORDER,
  GET_VIP_DOOR_ORDER
} from '.'

export interface IOrder {
  orderId?: string
  orderMeta?: string
}

/**
 * Hook for fetching order summary
 * @param {string} orderId - ID of order
 * @param {boolean} isDoorOrder - Boolean specifying if type of order is door
 * @param {WatchQueryFetchPolicy} fetchPolicy - Apollo client fetch policy for query
 */
export const useOrderSummary = (
  orderId: string,
  isDoorOrder: boolean = false,
  fetchPolicy: WatchQueryFetchPolicy = 'cache-first'
) => {
  const doorOrderRes = useQuery(GET_VIP_DOOR_ORDER, {
    skip: !isDoorOrder && !orderId,
    fetchPolicy,
    variables: {
      orderId
    }
  })
  const clientOrderRes = useQuery(GET_VIP_CLIENT_ORDER, {
    skip: isDoorOrder && !orderId,
    fetchPolicy,
    variables: {
      clientOrderId: orderId
    }
  })
  if (isDoorOrder) {
    return doorOrderRes
  } else {
    return clientOrderRes
  }
}

/**
 * Returns the client order product summary for passed client order id
 * @param clientOrderId - Id for client order
 * @param {WatchQueryFetchPolicy} fetchPolicy - Fetch policy for query, defaults to cache-first
 */
export const useClientOrderProductsSummary = (
  clientOrderProductSummaryWhere,
  fetchPolicy: WatchQueryFetchPolicy = 'cache-first'
) => {
  return useQuery(SA_CLIENT_ORDER_PRODUCT_SUMMARY, {
    skip: !clientOrderProductSummaryWhere,
    fetchPolicy,
    variables: {
      where: clientOrderProductSummaryWhere
    }
  })
}
