import { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions'
import { useQuery } from '@apollo/client/react/hooks/useQuery'
import {
  GET_BSG_AND_CAMPAIGNS,
  GET_CAMPAIGN_BY_BUYING_SESSION_GROUP
} from '@modules/showroom/buyingSessions/graphql'

export const useBSGAndCampaigns = (
  sessionStatus: string,
  retailSessionStatus: string,
  clientId: string
) => {
  return useQuery(GET_BSG_AND_CAMPAIGNS, {
    variables: { sessionStatus, retailSessionStatus, clientId },
    fetchPolicy: 'cache-and-network',
    skip: !(sessionStatus && clientId)
  })
}

/**
 * Hook to fetch buying session group specific campaign
 * @param campaignByBSGWhere
 * @param fetchPolicy
 * @param skip
 */
export const useCampaignByBSG = (
  campaignByBSGWhere,
  fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network',
  skip?
) => {
  return useQuery(GET_CAMPAIGN_BY_BUYING_SESSION_GROUP, {
    variables: {
      where: campaignByBSGWhere
    },
    fetchPolicy,
    skip: skip || !campaignByBSGWhere
  })
}
