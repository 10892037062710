import { ClusterSetupTableHeader } from '@modules/retail/clusterSetup/component'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'

const HEIGHT = 137

const style = theme => ({
  root: {
    overflow: 'auto',
    height: `calc(100vh - ${HEIGHT}px)`
  },
  borderRight: {
    borderRight: '1px solid #e1e1e1'
  },
  table: {
    minWidth: 900
  },
  tableHead: {
    backgroundColor: theme.colors.navBackground
  },
  tableCellWidth: {
    maxWidth: 200,
    paddingTop: 0
  },
  chip: {
    margin: theme.spacing(1)
  }
})

const StoreDetailsTableComponent = props => {
  const { classes, isActive, name, storeCode, countryName, zoneName } = props
  return (
    <Table className={classes.table}>
      <TableHead className={classes.tableHead}>
        <TableRow>
          <ClusterSetupTableHeader
            titles={[
              { name: 'code' },
              { name: 'name' },
              { name: 'country / region' },
              { name: 'zone' },
              { name: 'active' }
            ]}
          />
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>{storeCode}</TableCell>
          <TableCell style={{ maxWidth: 55 }}>{name}</TableCell>
          <TableCell>{countryName}</TableCell>
          <TableCell>{zoneName}</TableCell>
          <TableCell>{isActive ? 'Yes' : 'No'}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

export const StoreDetailsTable = withStyles(style)(React.memo(StoreDetailsTableComponent))
