import './wijmo.css'
import { config } from 'src/config'
import { setLicenseKey } from '@grapecity/wijmo'
import '@grapecity/wijmo.styles/wijmo.css'
import '@grapecity/wijmo.styles/themes/wijmo.theme.material.css'
import * as wijmo from '@grapecity/wijmo'
import * as wjcGrid from '@grapecity/wijmo.grid'
import * as wjGrid from '@grapecity/wijmo.react.grid'
import * as wjGridFilter from '@grapecity/wijmo.react.grid.filter'
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx'

setLicenseKey(config.wijmoLicenseKey)

export { wjGrid, wjGridFilter, wjcGrid, wijmo, wjcGridXlsx }

if (DEBUG) {
  const hideNagPopup = setInterval(() => {
    const evalDiv = document.querySelector('body > div:last-of-type') as HTMLDivElement
    if (evalDiv && evalDiv.style.opacity === '0.8') {
      evalDiv.style.display = 'none'
      clearInterval(hideNagPopup)
    }
  }, 500)
}
