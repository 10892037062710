import { withApollo } from '@apollo/client/react/hoc/withApollo'
import { CentricTheme, StyledProps } from '@components/3rd-party/mui'
import { PrimaryButton } from '@components/UI-Components/PrimaryButton'
import { SecondaryButton } from '@components/UI-Components/SecondaryButton'
import { TextInput } from '@components/UI-Components/TextInput'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { GET_SEASONS } from '@modules/retail/navigations/graphql'
import { apolloClient, stores } from '@stores'
import { withFormik } from 'formik'
import cloneDeep from 'lodash/cloneDeep'
import findIndex from 'lodash/findIndex'
import some from 'lodash/some'
import { observer } from 'mobx-react'
import React from 'react'
import { withRouter } from 'react-router-dom'
import * as Yup from 'yup'

import { GET_BUYINGSESSIONS } from '../graphql/buyingsessions'
import { CREATE_BUYINGSESSION_CONTAINER } from '../graphql/createBuyingSessionContainer'

const styles = (theme: CentricTheme) => ({
  root: {
    overflow: 'auto',
    height: '100vh'
  },
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'left',
    flexDirection: 'column'
  },
  rowContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2)
  },
  topMargin: {
    marginTop: 30
  },
  buttonMargin: {
    marginTop: theme.spacing(2)
  },
  input: {
    display: 'none'
  },
  columnContent: {
    flexDirection: 'column',
    alignItems: 'left'
  },
  leftContent: {
    paddingRight: 45
  },
  formControl: {
    width: '100%'
  },
  leftalign: {
    marginLeft: theme.spacing(1)
  },
  footer: {
    display: 'flex',
    padding: '14px 8px',
    justifyContent: 'flex-end',
    borderTop: `1px solid ${theme.colors.lightgrey}`
  },
  divider: {
    width: 2,
    height: 36,
    backgroundColor: '#eeeeee',
    margin: '5px 10px !important;'
  }
})

interface MyProps extends StyledProps {
  values?: any
  touched?: any
  errors?: any
  dirty?: boolean
  isSubmitting?: boolean
  themes?: any
  open?: boolean
  salesYears?: Array<any>
  error?: object
  headerLabel?: string
  seasonId?: any
  categoryId?: any
  handleChange?: (e) => void
  handleBlur?: (e) => void
  handleSubmit?: (e) => void
  onSubmit?: (e) => void
  onUpdateBuyingSession?: (e) => void
  collections?: any
  selectedCollections?: any
  activities?: any
  refetch?: any
  buyingSessions?: any
  selectedBuyingSession?: any
  client?: any
  match?: any
  setValues?: any
}

@((withStyles as any)(styles))
@(withRouter as any)
@(withApollo as any)
@((withFormik as any)({
  enableReinitialize: true,
  mapPropsToValues: () => {
    const { buyingSession } = stores
    return {
      sessionName: buyingSession.selectedBuyingSessionName || '',
      selectedBuyingSession: buyingSession.selectedBuyingSession
    }
  },
  validationSchema: () => {
    const {
      buyingSession: { isEditing }
    } = stores

    const validation = isEditing
      ? Yup.object().shape({
          sessionName: Yup.string().trim().required('Session name is required!')
        })
      : Yup.object().shape({
          sessionName: Yup.string().trim().required('Session name is required!')
        })

    return validation
  },

  handleSubmit: async (values, { setSubmitting, props }) => {
    const {
      buyingSession: { toggleBuyingSessionCreationProgress }
    } = stores
    const { sessionName } = values
    const [
      {
        onSubmit,
        match: {
          params: { seasonId, genderId }
        }
      },
      {
        buyingSession: {
          updateCreatedBuyingSessionId,
          selectedBuyingSession,
          handleDialogClose,
          selectedActivities,
          selectedCollections
        }
      }
    ] = [props, stores]
    let createdBuyingSessionContainerId = ''
    const collections = []
    selectedCollections.forEach(collection => {
      const activities = []
      selectedActivities.forEach(activity => {
        if (activity.collectionId === collection.id) {
          activities.push(activity.id)
        }
      })
      collections.push({ id: collection.id, activities })
    })
    try {
      toggleBuyingSessionCreationProgress(true)
      if (!selectedBuyingSession) {
        const result = await apolloClient.mutate({
          mutation: CREATE_BUYINGSESSION_CONTAINER,
          variables: {
            genderId: genderId,
            seasonId: seasonId,
            name: sessionName,
            collections
          }
        })
        const {
          data: { createBuyingSessionCollection }
        } = result
        const { buyingSessionCollections } = apolloClient.readQuery({
          query: GET_BUYINGSESSIONS,
          variables: {
            // TODO: Move it to store
            seasonId: seasonId,
            genderId: genderId
          }
        })
        buyingSessionCollections.push(createBuyingSessionCollection)
        apolloClient.writeQuery({
          query: GET_BUYINGSESSIONS,
          variables: {
            // TODO: Move it to store
            seasonId: seasonId,
            genderId: genderId
          },
          data: { buyingSessionCollections }
        })

        const { seasons } = apolloClient.readQuery({
          query: GET_SEASONS
        })
        const seasonData = cloneDeep(seasons)
        const index = findIndex(seasonData, { id: seasonId })
        if (index > -1) {
          seasonData[index]['status'] = 'ACTIVE'
        }
        apolloClient.writeQuery({
          query: GET_SEASONS,
          data: { seasons: seasonData }
        })
        createdBuyingSessionContainerId = createBuyingSessionCollection.id
        updateCreatedBuyingSessionId(createdBuyingSessionContainerId)
        handleDialogClose()
      } else {
        onSubmit({
          variables: {
            label: sessionName,
            season: seasonId,
            gender: genderId,
            collection: collections,
            buyingSessionCollectionId: createdBuyingSessionContainerId
              ? createdBuyingSessionContainerId
              : selectedBuyingSession
          },
          update: (proxy, data) => {
            const {
              data: { createBuyingSessions }
            } = data

            // Read the data from our cache for this query.
            const { buyingSessionCollections } = apolloClient.readQuery({
              query: GET_BUYINGSESSIONS,
              variables: {
                // TODO: Move it to store
                seasonId: seasonId,
                genderId: genderId
              }
            })

            // Update buyingSessions
            const buyingSessionContainerId = createdBuyingSessionContainerId
              ? createdBuyingSessionContainerId
              : selectedBuyingSession
            buyingSessionCollections.forEach(buyingSessionCollection => {
              if (buyingSessionCollection.id === buyingSessionContainerId) {
                buyingSessionCollection.buyingSessions.push(...createBuyingSessions)
              }
            })
            // currentBuyingSessionContainer.buyingSessions.push(...createBuyingSessions);

            // Write our data back to the cache.
            apolloClient.writeQuery({
              query: GET_BUYINGSESSIONS,
              variables: {
                // TODO: Move it to store
                seasonId: seasonId,
                genderId: genderId
              },
              data: { buyingSessionCollections: cloneDeep(buyingSessionCollections) }
            })
            handleDialogClose()
          }
        })
      }
    } finally {
      toggleBuyingSessionCreationProgress(false)
    }
  },
  displayName: 'SessionForm'
}))
@observer
export class CreateBuyingSession extends React.Component<MyProps> {
  state = {
    createdBuyingSessionContainer: ''
  }

  handleNextClick = async () => {
    const {
      buyingSession: { handleNextClick }
    } = stores
    handleNextClick()
  }
  render() {
    const [
      {
        handleNextClick,
        props: {
          classes,
          values: { sessionName },
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          open,
          collections,
          activities,
          buyingSessions
        }
      },
      {
        buyingSession: {
          selectedCollections,
          onCollectionClick,
          handleDialogClose,
          isEditing,
          handleBackClick,
          isNextClicked,
          onActivityClick,
          selectedActivities,
          selectedBuyingSession: selectedBuyingSessionId,
          isBuyingSessionCreationInProgress
        }
      }
    ] = [this, stores]

    const preSelectedActivities = []
    if (isEditing) {
      const currentBuyingSessionCollection = buyingSessions.filter(
        buyingSession => buyingSession.id === selectedBuyingSessionId
      )[0]
      const { buyingSessions: currentBuyingSessions } = currentBuyingSessionCollection
      currentBuyingSessions.forEach(buyingSession => {
        preSelectedActivities.push({
          id: buyingSession.activity.id,
          collectionId: buyingSession.collection.id
        })
      })
    }
    const dialogContent = isNextClicked ? (
      <React.Fragment>
        <DialogTitle id='form-dialog-title'>
          <Typography variant='h5'>SELECT ACTIVITIES</Typography>
        </DialogTitle>
        <DialogContent>
          <div style={{ display: 'flex' }}>
            {!activities.length ? (
              <Typography>
                There are no activities configured. Please contact administrator.
              </Typography>
            ) : (
              collections.map(
                collection =>
                  some(selectedCollections, { id: collection.id }) && (
                    <div key={collection.id}>
                      <Typography variant='h5'>{collection.description}</Typography>
                      {activities.length
                        ? activities.map(activity => (
                            <FormControl
                              variant='outlined'
                              className={classes.formControl}
                              margin='dense'
                              key={activity.id}
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    //For Demo perpose remove this disabled proprerty
                                    onClick={e => {
                                      onActivityClick(collection, activity, e)
                                    }}
                                    checked={some(selectedActivities, {
                                      id: activity.id,
                                      collectionId: collection.id
                                    })}
                                    value={activity.id}
                                    color='default'
                                    disabled={some(preSelectedActivities, {
                                      id: activity.id,
                                      collectionId: collection.id
                                    })}
                                  />
                                }
                                label={activity.description}
                              />
                            </FormControl>
                          ))
                        : null}
                    </div>
                  )
              )
            )}
          </div>
        </DialogContent>

        <DialogActions className={classes.footer}>
          <SecondaryButton onClick={handleDialogClose}>Cancel</SecondaryButton>
          <SecondaryButton onClick={handleBackClick}>Back</SecondaryButton>
          <PrimaryButton
            loading={isBuyingSessionCreationInProgress}
            onClick={handleSubmit}
            disabled={!Boolean(selectedActivities.length)}
          >
            Create New Session
          </PrimaryButton>
        </DialogActions>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <DialogTitle id='form-dialog-title'>
          <Typography variant='h5'>
            {isEditing ? 'ADD BUYING CATEGORY' : 'CREATE NEW BUYING SESSION'}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TextInput
            id='sessionName'
            name='sessionName'
            label={'Enter Buying Session Name'}
            type='text'
            value={sessionName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.sessionName && touched.sessionName}
            fullWidth
            margin='dense'
            variant='outlined'
            disabled={isEditing}
          />
          {collections.map(collection => (
            <FormControl
              variant='outlined'
              className={classes.formControl}
              margin='dense'
              key={collection.id}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    //For Demo perpose remove this disabled proprerty
                    onClick={e => {
                      onCollectionClick(collection, e)
                    }}
                    checked={some(selectedCollections, { id: collection.id })}
                    value={collection.id}
                    color='default'
                  />
                }
                label={collection.description}
              />
            </FormControl>
          ))}
        </DialogContent>

        <DialogActions className={classes.footer}>
          <SecondaryButton onClick={handleDialogClose}>Cancel</SecondaryButton>
          <PrimaryButton
            disabled={!sessionName.trim() || !selectedCollections.length}
            onClick={handleNextClick}
          >
            Next
          </PrimaryButton>
        </DialogActions>
      </React.Fragment>
    )

    return (
      <div className={classes.root}>
        <div className={classes.container}>
          <Dialog open={open} aria-labelledby='form-create-buying-session'>
            <form>{dialogContent}</form>
          </Dialog>
        </div>
      </div>
    )
  }
}
