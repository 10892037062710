import { action, observable, ObservableMap } from 'mobx'
import { showroomDashboardService } from './ShowroomDashboardService'
import { computedFn } from 'mobx-utils'

export class ShowroomDashboardStore {
  @observable isActionFromCollectionSideBar: boolean = false
  @observable activeLookMap = new ObservableMap()

  @action
  setActiveLookMap = async buyingSessionGroupId => {
    if (this.activeLookMap.has(buyingSessionGroupId)) {
      return
    }
    const response = await showroomDashboardService.isActiveLookPresent(
      buyingSessionGroupId
    )
    if (response) {
      this.activeLookMap.set(buyingSessionGroupId, response?.data?.isActiveLookPresent)
    }
  }

  @action
  resetActiveLook = (buyingSessionGroupId: string) => {
    this.activeLookMap.delete(buyingSessionGroupId)
  }

  isActiveLookPresent = computedFn(buyingSessionGroupId => {
    return this.activeLookMap.has(buyingSessionGroupId)
      ? this.activeLookMap.get(buyingSessionGroupId)
      : false
  })
}

export const showroomDashboardStore = new ShowroomDashboardStore()
