import filter from 'lodash/filter'
import find from 'lodash/find'
import memoize from 'memoize-one'
import { action, observable } from 'mobx'

export class ZoneWiseCommentsStore {
  @observable commentsTextInputDisplayStatus = {}

  @action
  setCommentsTextInputDisplayStatus = (commentsId, show) => {
    const commentsTextInputDisplayStatus = {
      ...this.commentsTextInputDisplayStatus,
      [commentsId]: !!show
    }
    Object.assign(this, { commentsTextInputDisplayStatus })
  }

  getZoneWiseCommentsByRole = memoize((zoneWiseComments, userRole) => {
    const commentsByRole = filter(
      zoneWiseComments,
      comment => !!find(comment.user.roles, role => role.name === userRole)
    )
    return commentsByRole[0]
  })
}
