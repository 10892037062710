import { TreeNavItem, TreeTable } from '@components/UI-Components/StandardTreeView'
import { User } from '@models'
import { Views } from '@modules/common/models/enums/Views'
import { ProductKPIsByZone } from '@modules/common/models/interfaces'
import { storage } from '@services/storageService'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import orderBy from 'lodash/orderBy'
import sortBy from 'lodash/sortBy'
import { observable, reaction } from 'mobx'

import { stores, strings } from '.'

export const CategoryBuySortMetrics = {
  [strings.sortByBuyQty]: 'totalBUYQuantity',
  [strings.sortByBuyValue]: 'totalBUYValue'
}

export class ListViewStore {
  @observable rootSelections = null
  watchSelectedView: Function
  removeSortOrderOnEventListener: Function
  cachedCategoryOrder: Map<string, Array<string>> = new Map()
  sortedProductData: Map<string, Array<string>> = new Map()

  /**
   * Setter for sync up the root node
   * checked statuses
   */
  setRootSelectionStatus = status => {
    this.rootSelections = status
  }

  /**
   * Reaction to cleanup the root node
   * status if view is other than KPI
   */
  listenToViewChange = () => {
    this.watchSelectedView = reaction(
      () => stores.nav.queryParams.productLayout,
      view => {
        const isKPIView = view === Views.Kpi || view === strings.kpiView
        if (!isKPIView) {
          this.rootSelections = null
        }
      }
    )
  }

  /**
   * Destroy, if reaction in listenToViewChange
   * exists
   */
  cleanupViewListener = () => {
    if (this.watchSelectedView) {
      this.watchSelectedView()
    }
  }

  /**
   * getCollectionBuyMetricsForSort
   * Returns buy metrics for sorting in collection view.
   * Helpful to normalized the buy param which
   * can be then found at the product level
   * @arg zoneWiseKPIData - Zone wise KPI data for product
   * @returns - Buy metrics nested within products
   */
  getCollectionBuyMetricsForSort = (
    zoneWiseKPIData: Array<ProductKPIsByZone>,
    isGMOrAdminUser
  ) => {
    let sortMetrics
    if (!isGMOrAdminUser) {
      const user = storage.getItem<User>('user')
      if (user && user.membership && !isEmpty(user.membership.zones)) {
        const zoneName = user.membership.zones[0].name
        sortMetrics = zoneWiseKPIData.find(zoneData => zoneData.zone === zoneName)
      }
    } else {
      sortMetrics = zoneWiseKPIData.find(zoneData => zoneData.type === strings.global)
    }

    return {
      [strings.sortByBuyQty]: (sortMetrics && sortMetrics.buyQty) || 0,
      [strings.sortByBuyValue]: (sortMetrics && sortMetrics.buyValueToSort) || 0
    }
  }

  /**
   * getAssortmentBuyMetricsForSort
   * Returns buy metrics to use for sorting in assortment view.
   * Helpful to normalized the buy param which
   * can be then found at the product level
   * @arg zoneWiseKPIData - Zone wise KPI data for product
   * @returns - Buy metrics nested within products
   */
  getAssortmentBuyMetricsForSort = (
    zoneWiseKPIData: Array<ProductKPIsByZone>,
    assortmentType: string
  ) => {
    let sortMetrics
    if (zoneWiseKPIData && zoneWiseKPIData.length && assortmentType) {
      sortMetrics = zoneWiseKPIData.find(zoneData => zoneData.type === assortmentType)
    }

    return {
      [strings.sortByBuyQty]: (sortMetrics && sortMetrics.buyQty) || 0,
      [strings.sortByBuyValue]: (sortMetrics && sortMetrics.buyValueToSort) || 0
    }
  }

  /**
   * listenToSortOrderCleanupEvents
   * Wrapper to create reaction for tracking
   * change in sortBy. This resets cachedCategoryOrder,
   * cachedNavData and sortedProductData if there
   * is change in sortBy from toolbar
   */
  listenToSortOrderCleanupEvents = () => {
    this.removeSortOrderOnEventListener = reaction(
      () => {
        const {
          product: { sortBy, isBuyMetricSort },
          filter: { finalFilterToApply },
          search: { searchKey, searchText }
        } = stores
        return {
          sortBy,
          isBuyMetricSort,
          finalFilterToApply,
          searchKey,
          searchText
        }
      },
      e => {
        this.resetCachedSortOrder()
      }
    )
  }

  /**
   * sortCategoryData
   * Sorts category data based on sort metric
   * and cache the order of children (activity/family/line).
   * We are using this cached order when we don't want to sort
   * on re-renders.
   * Example - User applied sortBy with buyQty and then editing
   * the buyQty inline. In such cases we should be maintaining
   * the previous order using cached data set here.
   *
   * @param inputTableData - category data
   * @param sortMetric
   */
  sortCategoryData = (data: TreeTable, sortMetric: string, loading) => {
    let resultSet = {}
    const cachedDataSize = this.cachedCategoryOrder.size
    const sortBy = CategoryBuySortMetrics[sortMetric]
    if (!sortBy) return data

    // Sort and cache the order data
    if (cachedDataSize === 0 && !loading) {
      for (const category in data) {
        let children
        let sortedChildren
        if (data[category]) {
          children = data[category].children
          if (children && children.length > 1) {
            const unsortedChildren = children.map(key => data[key])
            sortedChildren = orderBy(unsortedChildren, [sortBy], 'desc').map(
              item => item.key
            )
            resultSet[category] = {
              ...data[category],
              children: sortedChildren
            }
          } else {
            resultSet[category] = {
              ...data[category],
              children
            }
          }
        }

        /**
         * Caching the category structure as category name as
         * key and sorted list of children as value
         */
        this.cachedCategoryOrder.set(category, sortedChildren ? sortedChildren : children)
      }
    } else {
      resultSet = cachedDataSize ? this.reorderCategoryDataAsPerCache(data) : data
    }

    return resultSet
  }

  /**
   * reorderCategoryDataAsPerCache
   * It restores category order based on
   * already stored sorted category data
   * @param inputTableData - category data
   */
  reorderCategoryDataAsPerCache = inputTableData => {
    let tableData = cloneDeep(inputTableData)
    for (const category in tableData) {
      const updatedNode = tableData[category]
      if (updatedNode && updatedNode.children) {
        const correctOrder = this.cachedCategoryOrder.get(category)
        if (correctOrder && correctOrder.length) {
          updatedNode.children = correctOrder
        }
      }
    }

    return tableData
  }

  /**
   * sortCategoryNavData
   * Similar to sortCategoryData but for Nav data
   * @param nodes - nav data
   */
  sortCategoryNavData = (data: any): Array<TreeNavItem> => {
    if (data && !this.cachedCategoryOrder.size) return data.children

    const nodes = cloneDeep(data)
    const _sort = (list, parent) => {
      // Sort as per cached order
      const sorted = sortBy(list, category => {
        // IMP: Ordering of current node has been maintained in the
        // parent node present in the cachedCategoryOrder
        const order = this.cachedCategoryOrder.get(parent.key)
        return order ? order.indexOf(category.key) : -1
      })

      // Attach sorted children
      parent.children = sorted
      parent.children.forEach(node => {
        if (node && node.children) {
          return _sort(node.children, node)
        }
      })
    }

    // Start with root node  - ROOTHEADER
    _sort(nodes.children, nodes)

    // Remove root node - ROOTHEADER, while returning
    return nodes && nodes.children
  }

  /**
   * cleanupSortOrderWiperOnEventListener
   * To cleanup the reaction for tracking
   * change in sort, search and filter criteria
   */
  cleanupSortOrderWiperOnEventListener = () => {
    this.resetCachedSortOrder()
    if (this.removeSortOrderOnEventListener) {
      this.removeSortOrderOnEventListener()
    }
  }

  /**
   * setSortedProductData
   * sets the products list in order against the respective
   * category. This will be useful to maintain the order
   * across renders.
   * @param products - List of BSP
   * @param categoryKey - Category key. Combination of activity / family / line
   */
  setSortedProductData = (products, categoryKey) => {
    if (categoryKey && products && products.length) {
      this.sortedProductData.set(categoryKey, products)
    }
  }

  /**
   * sortProductList
   * Sorts product in descending order by either buyValue or buyQty
   * @param listViewProducts - list of products
   * @param sortMetric - Metric to use in sort qty or value
   * @param categoryKey - Key (Activity/Family/Line) to group similar products
   * @returns sorted list of products
   */
  sortProductList = (
    listViewProducts: Array<any>,
    sortMetric: string,
    categoryKey: string
  ) => {
    let sortedProducts

    if (this.sortedProductData && !this.sortedProductData.has(categoryKey)) {
      sortedProducts = orderBy(listViewProducts, [sortMetric], 'desc')
      this.setSortedProductData(
        sortedProducts.map(({ buyingSessionProductId }) => buyingSessionProductId),
        categoryKey
      )
    } else {
      sortedProducts = this.reorderProductList(listViewProducts, categoryKey)
    }

    return sortedProducts
  }

  /**
   * reorderProductList
   * Backend returns products in natural order. This
   * returns the correct order if user has buy qty/val product sorting
   * enable and user tries inline editing or jump to
   * @param listViewProduct - list of products
   * @param categoryKey - Key (Activity/Family/Line) to group similar products
   * @returns Product in previous sort order when buy qty/val was applied
   */
  reorderProductList = (listViewProduct: Array<any>, categoryKey: string) => {
    const order = this.sortedProductData.get(categoryKey)
    if (order && order.length) {
      return sortBy(listViewProduct, function (product) {
        return order.indexOf(product.buyingSessionProductId)
      })
    }
  }

  /**x`
   * resetCachedSortOrder
   * Clears the cached order for categories
   * and products
   */
  resetCachedSortOrder = () => {
    this.cachedCategoryOrder.clear()
    this.sortedProductData.clear()
  }
}
