export enum FilterDisplayNames {
  status = 'Status',
  drop = 'Drop',
  channels = 'Channel',
  cluster = 'Cluster',
  ProductAttributes = 'Product Attributes',
  associatedLooks = 'Looks',
  salesQty = 'Sales Quantity',
  favourite = 'Favourite Filter',
  rating = 'Rating Filter',
  freeTags = 'Free Tags',
  productStatus = 'Product Status',
  hasCounterOffer = 'Flag Filter',
  productOrderStatus = 'Product Order Status',
  productComment = 'Comment Filter',
  //SA Filters
  BookedUnbooked = 'Booked/Unbooked',
  Booked = 'Booked',
  Unbooked = 'Unbooked',
  WithQtyWithoutQty = 'Sized Qty',
  WithQty = 'With Qty',
  WithoutQty = 'Without Qty'
}

export enum FilterKeys {
  Status = 'status',
  Drop = 'drop',
  Channel = 'channels',
  Cluster = 'cluster',
  ProductStatus = 'productStatus',
  ProductAttributes = 'productAttributes',
  Looks = 'associatedLooks',
  Style = 'Style',
  SalesQty = 'salesQty',
  Favourite = 'favourite',
  Rating = 'rating',
  FreeTags = 'freeTags',
  WholesaleFreeTags = 'wholesaleFreeTags',
  RetailFreeTags = 'retailFreeTags',
  Flagged = 'hasCounterOffer',
  ProductOrderStatus = 'productOrderStatus',
  ProductComment = 'productComment',
  //SA Filters
  BookedUnbooked = 'bookedUnbooked',
  Booked = 'booked',
  Unbooked = 'unbooked',
  WithQtyWithoutQty = 'sizedQty',
  WithQty = 'withQty',
  WithoutQty = 'withoutQty'
}
