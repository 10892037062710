import { StyledProps } from '@components/3rd-party/mui'
import {
  ClusteringTable,
  StoreDetailsTable
} from '@modules/retail/clusterSetup/component'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Collapse from '@material-ui/core/Collapse'
import { Paper } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { getClusterSettingHeader } from '@services/clusterSetup'
import React from 'react'

const BLANK_HEADER = {
  name: 'Clusters',
  genderId: '',
  activityId: ''
}

const styles = () => ({
  storeDetailsTable: {
    margin: 10
  },
  ClusteringTable: {
    display: 'flex',
    justifyContent: 'center',
    margin: '10px 10px 20px 20px',
    overflowY: 'hidden' as any
  },
  iconStyles: {
    margin: 10,
    cursor: 'pointer'
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: 20,
    paddingTop: 10,
    marginRight: 20,
    marginLeft: 20
  },
  heading: {
    cursor: 'pointer',
    borderBottom: '1px solid blue',
    color: 'blue'
  }
})

interface IClusteringRowProps extends StyledProps {
  stores?: any
  genders?: any
  activities?: any
  clusters?: any
  index?: any
}
class ClusteringRowComponent extends React.Component<IClusteringRowProps> {
  state = {
    expanded: ''
  }
  handleChange = panel => {
    const { expanded } = this.state
    if (expanded === panel) {
      this.setState({
        expanded: null
      })
    } else {
      this.setState({
        expanded: panel
      })
    }
  }
  render() {
    const {
      props: { classes, style, stores, genders, activities, clusters },
      state: { expanded },
      handleChange
    } = this
    const clusterSettingData = getClusterSettingHeader(genders, activities)
    clusterSettingData.unshift(BLANK_HEADER)

    // const {
    // 	id,
    // 	name,
    // 	storeCode,
    // 	isActive,
    // 	country: { name: countryName },
    // 	zone: { name: zoneName },
    // 	allotments
    // } = stores[index];
    return stores
      ? stores.map(
          ({
            id,
            name,
            storeCode,
            isActive,
            country: { name: countryName },
            zone: { name: zoneName },
            allotments
          }) => (
            <div style={style} key={id}>
              <React.Fragment>
                <div className={classes.storeDetailsTable}>
                  <StoreDetailsTable
                    name={name}
                    storeCode={storeCode}
                    countryName={countryName}
                    zoneName={zoneName}
                    isActive={isActive}
                  />
                </div>
                <Collapse
                  in={expanded === name}
                  collapsedHeight={'45px'}
                  style={{ margin: 8 }}
                >
                  <Paper elevation={4} className={classes.paper}>
                    <div className={classes.headerContainer}>
                      <div
                        onClick={() => {
                          handleChange(name)
                        }}
                      >
                        <Typography variant='subtitle1' className={classes.heading}>
                          {name}
                        </Typography>
                      </div>
                      <div>
                        {expanded === name ? (
                          <ExpandLess
                            className={classes.iconStyles}
                            onClick={() => {
                              handleChange(name)
                            }}
                          />
                        ) : (
                          <ExpandMore
                            className={classes.iconStyles}
                            onClick={() => {
                              handleChange(name)
                            }}
                          />
                        )}
                      </div>
                    </div>
                    {expanded === name && (
                      <div className={classes.ClusteringTable}>
                        {clusters.length ? (
                          <ClusteringTable
                            clusters={clusters}
                            clusterSettingData={clusterSettingData}
                            allotments={allotments}
                            storeId={id}
                          />
                        ) : null}
                      </div>
                    )}
                  </Paper>
                </Collapse>
              </React.Fragment>
            </div>
          )
        )
      : null
  }
}

export const ClusteringRow = withStyles(styles)(React.memo(ClusteringRowComponent))
