/*
 * @Author: Raeesaa Metkari
 * @Date: 2019-08-20 00:20:27
 * @Last Modified by: Raeesaa Metkari
 * @Last Modified time: 2019-12-24 00:27:50
 */
import { nav } from '@stores/nav'
import { strings } from '@stores'
import cloneDeep from 'lodash/cloneDeep'
import find from 'lodash/find'
import forEach from 'lodash/forEach'
import isEqual from 'lodash/isEqual'
import memoize from 'memoize-one'
import { action, computed, observable } from 'mobx'

interface Tag {
  id: string
  name: string
  description: string
}

type Allotment = {
  clusterID: string
  activityID: string
  genderID: string
}

type Store = {
  id: string
  name: string
  storeCode: string
  allotments: Array<Allotment>
}

type StoreWeight = {
  id: string
  activity: { id: string }
  gender: { id: string }
  store: { id: string }
  weight: number
}

enum HasClustering {
  Yes = 'Yes',
  No = 'No'
}

export class StoreSettingsStore {
  tabs: Array<string> = [
    strings.storesSettings,
    strings.storeClusterSettings,
    strings.storeOTBSettings
  ]
  @observable selectedZone: string = ''
  @observable validationErrorMessage: string = ''

  // For persisting filter on itemsource change
  @observable filterDefinition = ''
  @observable lastFilterDefinition = ''

  // For persisting selection on itemsource change
  @observable selection = null
  @observable lastSelection = null

  @computed get selectedTab() {
    return nav.queryParams.tab ? parseInt(nav.queryParams.tab) : 0
  }

  @action onChangeSelectedTab = tab => {
    nav.updateQueryParams({ tab })
  }

  @action resetSelectedTab = () => {
    nav.updateQueryParams({ tab: undefined })
  }

  @computed get selectedTabValue() {
    const selectedTab = this.selectedTab
    return selectedTab < this.tabs.length ? this.tabs[selectedTab] : this.tabs[0]
  }

  @action onSelectZone = event => {
    this.setSelectedZone(event.target.value)
  }

  @action setSelectedZone = (zoneId: string) => {
    this.selectedZone = zoneId
  }

  @action onValidationError = (message: string) => {
    this.validationErrorMessage = message
  }

  @action setFilterDefinition = (definition: string) => {
    this.lastFilterDefinition = cloneDeep(this.filterDefinition)
    this.filterDefinition = definition
  }

  @action setSelectionSet = selection => {
    this.lastSelection = cloneDeep(this.selection)
    this.selection = selection
  }

  getStoresByZone = memoize(
    (
      zoneId: string,
      zones: Array<{
        id: string
        name: string
        stores: Array<{ id: string; name: string }>
      }>
    ) => {
      if (zoneId) {
        let zone = find(zones, zone => zone.id === zoneId)
        return zone ? zone.stores : []
      }
      return []
    },
    isEqual
  )

  generateStoreWeightData = memoize(
    (
      stores: Array<Store>,
      activities: Array<Tag>,
      genders: Array<Tag>,
      storeWeights: Array<StoreWeight>
    ) => {
      let result = []
      const percentageMapForActivityGender: { [key: string]: number } = {}
      forEach(stores, store => {
        forEach(activities, activity => {
          forEach(genders, gender => {
            const weightObj = find(storeWeights, storeWeightRecord => {
              return (
                storeWeightRecord.activity.id === activity.id &&
                storeWeightRecord.gender.id === gender.id &&
                storeWeightRecord.store.id === store.id
              )
            })
            const weight = weightObj ? weightObj.weight : null
            result.push({
              storeId: store.id,
              storeLabel: store.storeCode,
              storeName: store.name,
              activityId: activity.id,
              activityLabel: activity.description,
              genderId: gender.id,
              genderLabel: gender.description,
              weightId: weightObj ? weightObj.id : null,
              weightLabel: weight,
              validationError: false,
              hasClustering: find(
                store.allotments,
                allotment =>
                  allotment.activityID === activity.id && allotment.genderID === gender.id
              )
                ? HasClustering.Yes
                : HasClustering.No
            })

            const mapKey = `${activity.id}_${gender.id}`
            if (percentageMapForActivityGender[mapKey]) {
              percentageMapForActivityGender[mapKey] += weight || 0
            } else {
              percentageMapForActivityGender[mapKey] = weight || 0
            }
          })
        })
      })

      for (const mapKey in percentageMapForActivityGender) {
        if (percentageMapForActivityGender[mapKey] > 100) {
          const [activityId, genderId] = mapKey.split('_')
          forEach(result, storeWeightRecord => {
            if (
              storeWeightRecord.activityId === activityId &&
              storeWeightRecord.genderId === genderId
            ) {
              storeWeightRecord.validationError = true
            }
          })
        }
      }

      return result
    },
    isEqual
  )
}

export const storeSettings = new StoreSettingsStore()
