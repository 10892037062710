import React from 'react'
import get from 'lodash/get'
import { ILabelsList } from './Interfaces'
import StyledListLabel from './StyledListLabel'
import StyledLabelWrapper from './StyledLabelListWrapper'
import { TextAlignment } from '@modules/common/models/enums'
import { ApplyToFixedIfNumber } from '@services/commonServices'
import Tooltip from '@material-ui/core/Tooltip'

export const LabelList: React.FunctionComponent<ILabelsList> = React.memo(
  ({
    data,
    dataKey,
    hasGlobalRow,
    isFloatingPointValue,
    alignment = TextAlignment.right
  }) => {
    return (
      <React.Fragment>
        {data && data.length
          ? data.map((labelObject, index) => {
              const value = isFloatingPointValue
                ? ApplyToFixedIfNumber(get(labelObject, dataKey))
                : get(labelObject, dataKey)
              return (
                <StyledLabelWrapper key={index} alignment={alignment}>
                  <Tooltip title={value || ''}>
                    <div>
                      <StyledListLabel index={index} hasGlobalRow={hasGlobalRow}>
                        {value}
                      </StyledListLabel>
                    </div>
                  </Tooltip>
                </StyledLabelWrapper>
              )
            })
          : null}
      </React.Fragment>
    )
  }
)
