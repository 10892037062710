import { StyledProps } from '@components/3rd-party/mui'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'
import React from 'react'
import * as css from './StandardProductInfoItem.css'

interface IStandardProductInfoItem extends StyledProps {
  style?: any
  title?: string
  sku?: string
  content?: any
  className?: any
  showTooltip?: boolean
  isHeader?: boolean
}

export class StandardProductInfoItem extends React.Component<IStandardProductInfoItem> {
  render() {
    const {
      props: { style, title, content, className, showTooltip, sku, isHeader }
    } = this
    return (
      <div className={classNames(css.productInfo, className, css.root)} style={style}>
        {title ? (
          <>
            <Typography display='block' component={'span'} className={css.title}>
              {title}
            </Typography>
            <Tooltip disableHoverListener={!showTooltip} title={content || ''}>
              <Typography
                display='block'
                component={'span'}
                className={css.valueWithTitle}
              >
                {content}
              </Typography>
            </Tooltip>
          </>
        ) : (
          <Tooltip disableHoverListener={!showTooltip} title={content || ''}>
            <Typography
              display='block'
              component={'span'}
              className={isHeader ? css.description : css.descriptionValue}
            >
              {content}
            </Typography>
          </Tooltip>
        )}
        {sku ? (
          <Typography display='block' component={'span'} className={css.skuStyle}>
            {sku}
          </Typography>
        ) : null}
      </div>
    )
  }
}
