import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  split
} from '@apollo/client/core'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { config } from '@config'
import { centricAuthLink } from '@services/authService'
import * as lockr from 'lockr'
import includes from 'lodash/includes'
import { createNetworkStatusNotifier } from 'react-apollo-network-status'
import { ABORTED_NETWORK_ERROR } from 'src/constant/Errors'

// Set up apollo-link-state
const cache = new InMemoryCache({
  dataIdFromObject: (object: any) =>
    object.Id && object.__typename ? `${object.__typename}:${object.Id}` : null
})

const batchHttpLink = new BatchHttpLink({
  uri: config.centricGraphQLEndpoint
})
const httpLink = new HttpLink({ uri: config.centricGraphQLEndpoint })
const httpLinkWithAbortCleanup = new HttpLink({
  uri: config.centricGraphQLEndpoint,
  fetch: (input, init?) => {
    return fetch(input, init).catch(e => {
      if (e.name === DOMException.ABORT_ERR) {
        throw new DOMException(ABORTED_NETWORK_ERROR, 'AbortErrorForced')
      }
      throw e
    }) as any
  }
})
let graphQLSubscriptionUrl = config.wsCentricGraphQLEndpoint

if (graphQLSubscriptionUrl && !includes(graphQLSubscriptionUrl, 'ws://')) {
  graphQLSubscriptionUrl =
    (window.location.protocol === 'https:' ? 'wss://' : 'ws://') +
    window.location.host +
    graphQLSubscriptionUrl
}

const wsLink = new WebSocketLink({
  uri: graphQLSubscriptionUrl,
  options: {
    lazy: true,
    reconnect: true,
    reconnectionAttempts: 5,
    connectionParams: () => {
      const sessionUrl = lockr.get('session_url')
      return {
        Authorization: sessionUrl ? sessionUrl : null
      }
    }
  }
})

const notifier = createNetworkStatusNotifier()

const {
  useApolloNetworkStatus,
  link: networkStatusNotifierLink,
  useApolloNetworkStatusReducer
} = notifier

const linkToUse = split(
  operation => {
    const context = operation.getContext()
    // Return true in case request is NOT to be batched
    return context?.fetchOptions?.signal
  },
  httpLinkWithAbortCleanup,
  split(
    operation => {
      const context = operation.getContext()
      // Return true in case request is NOT to be batched
      return context.noBatch
    },
    httpLink,
    batchHttpLink
  )
)

const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query) as any
    return kind === 'OperationDefinition' && operation === 'subscription'
  },
  wsLink,
  ApolloLink.from([networkStatusNotifierLink.concat(centricAuthLink.concat(linkToUse))])
)

export const apolloClient = new ApolloClient({
  link,
  cache,
  defaultOptions: {
    watchQuery: {
      nextFetchPolicy(lastFetchPolicy) {
        if (
          lastFetchPolicy === 'cache-and-network' ||
          lastFetchPolicy === 'network-only'
        ) {
          return 'cache-first'
        }
        return lastFetchPolicy
      }
    }
  }
})

export { useApolloNetworkStatus, useApolloNetworkStatusReducer }
