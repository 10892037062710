
    
    var doc = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getBuyingSessionGroupWithClient"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}},"directives":[]}],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getVIPBuyingSessionGroup"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"where"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}]}}],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"Name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"Id"},"arguments":[],"directives":[]},{"kind":"Field","alias":{"kind":"Name","value":"ExcludedClients"},"name":{"kind":"Name","value":"GIV_VIPBuyingSessionGroup_ExcludedClients_reflist"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"Id"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"Sessions"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"Id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"Name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"Status"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"STD_RTLBuyingSession_Status"},"arguments":[],"directives":[]}]}}]}}]}}]};
  

    
  var names = {};
  function unique(defs) {
    return defs.filter(function (def) {
      if (def.kind !== 'FragmentDefinition') return true;
      var name = def.name.value;
      if (names[name]) {
        return false;
      } else {
        names[name] = true;
        return true;
      }
    });
  };

  

    module.exports = doc
  
