import { Button, Icon, Keys, Menu, MenuItem, Popover, Tooltip } from '@blueprintjs/core'
import { ConfirmationDialog } from '@components/UI-Components/ConfirmationDialog'
import { CrossIcon } from '@components/UI-Components/svg'
import { Tag } from '@components/UI-Components/Tag'
import { constants, HELP_TEXT } from '@modules/retail/buyingSession/constants'
import AddRounded from '@material-ui/icons/AddRounded'
import Delete from '@material-ui/icons/Delete'
import VerticalSplitRoundedIcon from '@material-ui/icons/VerticalSplitRounded'
import Chip from '@material-ui/core/Chip'
import CircularProgress from '@material-ui/core/CircularProgress'
import Input, { InputProps } from '@material-ui/core/Input'
import Typography from '@material-ui/core/Typography'
import { validateNumber } from '@services/commonServices'
import classNames from 'classnames'
import cuid from 'cuid'
import flatMap from 'lodash/flatMap'
import merge from 'lodash/merge'
import omit from 'lodash/omit'
import findKey from 'lodash/findKey'
import { useObserver } from 'mobx-react-lite'
import { transparentize } from 'polished'
import React, { useState } from 'react'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'

import * as css from './AssortmentSplitTable.css'
import { AssortmentSplitTableStore, useAssortmentSplitStore } from './store'

//region Styles
const Root = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
`

const StatusColumnHeader = styled(({ status, ...p }) => <div {...p}>{status}</div>).attrs(
  () => ({ status: '' })
)`
  display: flex;
  margin: auto;
  text-transform: uppercase;
`

//region Styles
const StyledLabel = styled(Typography)`
  flex: 1;
  margin: auto;
  align-self: center;
  font-size: 14px;
  padding-top: 36px;
`

//endregion

const SelectAllOnFocusInput: React.FunctionComponent<InputProps> = p => {
  const [focused, setFocused] = useState(false)
  const props = merge({}, p, {
    inputProps: {
      // Otherwise clicking in mouse down will unselect instantly
      onFocus: e => {
        if (!focused) {
          const { currentTarget } = e
          setTimeout(() => {
            currentTarget.select()
            setFocused(true)
          }, 50)
          p.onFocus && p.onFocus(e as any)
        }
      },
      onBlur: e => {
        setFocused(false)
        p.onBlur && p.onBlur(e as any)
      },
      // Eat the mouse up on our initial focus
      onMouseUp: e => {
        if (!focused) {
          e.preventDefault()
        }
        p.onMouseUp && p.onMouseUp(e as any)
      }
    }
  } as Partial<InputProps>)
  const toolTip = props.inputProps ? props.inputProps.tooltip : undefined
  const toolTipId = cuid()
  return (
    <div className={props.className}>
      <span data-class={css.standardTooltip} data-tip={toolTip} data-for={toolTipId}>
        <Input {...omit(props, ['className'])} />
      </span>
      <ReactTooltip id={toolTipId} type={'none' as any} place='bottom' effect='solid' />
    </div>
  )
}

const QuantityInput = styled(SelectAllOnFocusInput).attrs(
  ({ defaultValue, inputProps }) =>
    ({
      key: defaultValue || 0, // Force reload of input on defaultValue change
      defaultValue: 0,
      disableUnderline: true,
      type: 'text', // If set to number then selectionStart and End are unavailable
      // Select all text on focus
      onKeyPress: validateNumber,
      inputProps: inputProps
    } as InputProps)
)`
  margin: auto;
  padding-top: 36px;
  flex: 1;

  input {
    text-align: center;
    font-size: 14px;
  }

  &.qtymismatch input {
    color: #fa6400;
  }
`
const BaseButton = styled(Button)`
  &&.bp3-button {
    &,
    &:hover {
      color: ${p => (p['aria-disabled'] ? 'inherit' : 'white')};

      &[aria-disabled='false'] {
        color: white;
        background: #24aaff;
      }
    }

    &[aria-disabled='true'] {
      cursor: not-allowed;
    }

    font-family: Roboto, serif;
    font-weight: 500;
    padding: 5px 8px;
    border: none;
    box-shadow: none;
    cursor: pointer;
    border-radius: 5px;

    margin-bottom: 0.5em;
  }
`

const SplitToStoresButton = styled(BaseButton).attrs(() => ({
  children: 'Split to stores'
}))``
const RollUpToGlobalButton = styled(BaseButton).attrs(() => ({
  children: 'Roll up to global'
}))``
const RemoveProductFromStoreButton = styled(BaseButton).attrs(() => ({
  children: <Delete />
}))``

const AddAssociationButton = styled(p => (
  <Tooltip position='bottom' disabled={p.disabled} content='Associate Product with Store'>
    <button {...p}>
      <AddRounded />
    </button>
  </Tooltip>
))`
  && {
    background: ${transparentize(0.2, `#e0e0e0`)};
    width: 40px;
    height: 40px;
    padding: 0px;
    opacity: 1 !important;
    color: var(--background-white);
    border-radius: 50%;
    border-style: solid !important;
    cursor: ${p => (p['aria-disabled'] ? 'not-allowed' : 'pointer')};

    svg {
      width: 100%;
      height: 100%;
    }
  }
`

const CircularLoading = styled(CircularProgress)`
  margin: 3px;
  color: #fff;
`
export const GlobalZoneSplitButton = styled(p => {
  return useObserver(function useHook() {
    const store = useAssortmentSplitStore(),
      {
        canEdit,
        masterSplitToStore,
        buyingSession,
        isGlobalSplitInProgress,
        productStatusMap,
        handleSplitQuantityDialogOpen,
        openSplitConfirmationDialog
      } = store
    const isAnyProductSplitInProgress = findKey(productStatusMap, function (product) {
      return product.isStoreSplitInProgress
    })
    const allowEdit =
      canEdit &&
      !isGlobalSplitInProgress &&
      !isAnyProductSplitInProgress &&
      buyingSession.status === constants.BUYING
    const toolTipId = cuid()

    return (
      <>
        <span
          className={p.className}
          data-class={css.standardTooltip}
          data-tip={HELP_TEXT.mastersplit}
          data-for={toolTipId}
        >
          <button
            className={allowEdit ? '' : 'disabled'}
            disabled={allowEdit ? false : true}
            onClick={handleSplitQuantityDialogOpen}
          >
            {isGlobalSplitInProgress || isAnyProductSplitInProgress ? (
              <CircularLoading size={15} />
            ) : (
              <div className='icon' style={{ fontSize: '15px' }}>
                <VerticalSplitRoundedIcon />
                <ReactTooltip
                  id={toolTipId}
                  type={'none' as any}
                  place='bottom'
                  effect='solid'
                />
              </div>
            )}
          </button>
        </span>
        {openSplitConfirmationDialog && (
          <ConfirmationDialog
            open={openSplitConfirmationDialog}
            title={'Split Zone Quantity'}
            message={'Are you sure to split zone quantity for all products to stores?'}
            handleClose={handleSplitQuantityDialogOpen}
            handleDelete={masterSplitToStore}
            proceedButtonTitle={'YES'}
          />
        )}
      </>
    )
  })
})`
  && {
    button {
      background-color: #8592a6;
      width: 18px;
      height: 18px;
      padding: 0px;
      margin: 0px 10px 0px 10px;
      color: var(--background-white);
      border-radius: 50%;
      border-style: solid !important;
      cursor: pointer;
      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
      & .icon svg {
        width: 95%;
        height: 100%;
        font-size: 15px;
      }
    }
  }
`

export const SplitTableHeaderCell = styled(props => {
  const { header, actionComponent, className } = props
  return (
    <div className={className}>
      {actionComponent && actionComponent}
      <span className='header-text'>{header}</span>
    </div>
  )
})`
  & .header-text {
    font-size: 10px;
    vertical-align: top;
  }
`

const handleTabKey =
  ({ row, col, store }: { row: number; col: number; store: AssortmentSplitTableStore }) =>
  (e: React.KeyboardEvent) => {
    const { productGrid, focusAndEditCell } = store
    if (e.keyCode === Keys.TAB) {
      let r = row,
        c = col

      // Going back/left/up
      if (e.shiftKey) {
        // Go to row above us or bottom right of grid
        if (col <= 1) {
          // Can't select product card
          c = productGrid.columns.length - 1
          r = row > 0 ? r - 1 : productGrid.rows.length - 1
        }
        // 1 row per cell
        else if (col < 3) {
          c--
        }
        // Shifting from 2 row to single row
        else if (col === 3) {
          c--
          r--
        } else {
          c--
        }
      } else {
        // Going right/down/forward
        if (c === 2) {
          r++
          c++
        }
        // End of column?
        else if (col + 1 === productGrid.columns.length) {
          c = 1
          r++
        } else {
          c++
        }
      }

      focusAndEditCell({ grid: productGrid, row: r, col: c })
    }
  }

export const ClusterProductCell: React.FunctionComponent<{
  style?: React.CSSProperties
  row: number
  col: number
}> = ({ style, row, col }) => {
  return useObserver(function useHook() {
    const store = useAssortmentSplitStore(),
      { canEdit, removeBuyerClusterFromAssortmentProduct, productAtRow, buyingSession } =
        store,
      product = productAtRow(row)

    if (!product) return null
    const { buyerClusters, isCore } = product

    return (
      <Root style={style} className={classNames(css.clusterColumn, css.hoverScrollBar)}>
        <div className={classNames(css.clusterChips)}>
          {buyerClusters
            ? buyerClusters.map(({ id, name, colorHex }) => (
                <Chip
                  classes={{ label: css.label }}
                  key={id}
                  className={css.chip}
                  deleteIcon={<img src={CrossIcon} alt='cross' />}
                  onDelete={
                    isCore ||
                    !canEdit ||
                    buyingSession.status === constants.CLOSED ||
                    buyingSession.status === constants.FROZEN
                      ? null
                      : () => {
                          removeBuyerClusterFromAssortmentProduct(product, id)
                        }
                  }
                  label={<Tag key={id} name={name} color={colorHex} />}
                />
              ))
            : null}
        </div>
      </Root>
    )
  })
}

export const NumberCell: React.FunctionComponent<{
  style?: React.CSSProperties
  row: number
  col: number
  valueKey: string
}> = ({ style, row, col, valueKey }) => {
  return useObserver(function useHook() {
    const store = useAssortmentSplitStore(),
      { productAtRow } = store,
      product = productAtRow(row)

    if (!product) return null
    const value = product[valueKey]

    return (
      <Root style={style}>
        <StyledLabel>{value.toFixed(2).replace(/\.?0*$/, '')}</StyledLabel>
      </Root>
    )
  })
}

export const ZoneQuantityProductCell: React.FunctionComponent<{
  style?: React.CSSProperties
  row: number
  col: number
}> = ({ style, row, col }) => {
  return useObserver(function useHook() {
    const store = useAssortmentSplitStore(),
      {
        canEdit,
        splitToStores,
        updateProductZoneQuantity,
        isGlobalSplitInProgress,
        focusZoneQuantity,
        productStatusMap,
        productAtRow,
        buyingSession,
        getTotalQuantity
      } = store,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      handleTab = React.useCallback(handleTabKey({ row, col: col, store }), []),
      product = productAtRow(row)

    const totalQuantity = getTotalQuantity(row)

    if (!product) return null
    const { buy } = product

    const onFocusingZoneQty = () => {
      focusZoneQuantity(product.id)
    }

    return (
      <Root style={style}>
        <QuantityInput
          disabled={
            !canEdit ||
            (!buy && !product.active) ||
            (buy && product && !product.active && buy.quantity === 0) ||
            buyingSession.status === constants.CLOSED ||
            buyingSession.status === constants.FROZEN
          }
          defaultValue={buy && buy.quantity ? buy.quantity : 0}
          onKeyDown={handleTab}
          onFocus={onFocusingZoneQty}
          onBlur={async e => {
            await updateProductZoneQuantity(product, parseInt(e.target['value']))
          }}
        />

        <SplitToStoresButton
          aria-disabled={
            !canEdit ||
            productStatusMap[product.id].isZoneQtyUpdateInProgress ||
            (!buy && !product.active) ||
            (buy &&
              product &&
              !product.active &&
              ((totalQuantity === 0 && buy.quantity === 0) || buy.quantity > 0)) ||
            buyingSession.status === constants.CLOSED ||
            buyingSession.status === constants.FROZEN
          }
          disabled={
            !canEdit ||
            productStatusMap[product.id].isZoneQtyUpdateInProgress ||
            (!buy && !product.active) ||
            (buy &&
              product &&
              !product.active &&
              ((totalQuantity === 0 && buy.quantity === 0) || buy.quantity > 0)) ||
            buyingSession.status === constants.CLOSED ||
            buyingSession.status === constants.FROZEN
          }
          onClick={() => splitToStores(product)}
          loading={
            isGlobalSplitInProgress || productStatusMap[product.id].isStoreSplitInProgress
          }
        />
      </Root>
    )
  })
}

export const TotalStoreQuantityProductCell: React.FunctionComponent<{
  style?: React.CSSProperties
  row: number
  col: number
}> = ({ row, col, style }) => {
  return useObserver(function useHook() {
    const store = useAssortmentSplitStore(),
      { canEdit, rollUpToGlobal, productAtRow, buyingSession, getTotalQuantity } = store,
      product = productAtRow(row),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      handleTab = React.useCallback(handleTabKey({ row, col: col, store }), [])

    const totalQuantity = getTotalQuantity(row)
    const buyQty =
      product && product.buy && product.buy.quantity ? product.buy.quantity : 0
    const isQuantityqtymismatch = totalQuantity !== buyQty ? true : false

    return (
      <Root style={style}>
        <QuantityInput
          className={isQuantityqtymismatch ? 'qtymismatch' : ''}
          inputProps={isQuantityqtymismatch ? { tooltip: HELP_TEXT.qtymismatch } : null}
          disabled
          onKeyDown={handleTab}
          defaultValue={totalQuantity || 0}
        />
        <RollUpToGlobalButton
          aria-disabled={
            !canEdit ||
            (product && !product.active) ||
            buyingSession.status === constants.CLOSED ||
            buyingSession.status === constants.FROZEN
          }
          disabled={
            !canEdit ||
            (product && !product.active) ||
            buyingSession.status === constants.CLOSED ||
            buyingSession.status === constants.FROZEN
          }
          onClick={() => rollUpToGlobal(product, totalQuantity)}
        />
      </Root>
    )
  })
}

/**
 * col here doesn't include the first 3 columns (product, zone quantity, total store quantity)
 * @param row
 * @param col
 * @constructor
 */
export const StoreProductCell: React.FunctionComponent<{ row: number; col: number }> = ({
  row,
  col
}) => {
  const store = useAssortmentSplitStore()
  return useObserver(function useHook() {
    const {
        canEdit,
        columnDict,
        addProductToStore,
        removeProductFromStoreAssortment,
        updateAssortmentProductQuantity,
        findStoreAssortmentProduct,
        productAtRow,
        buyingSession
      } = store,
      product = productAtRow(row)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleTab = React.useCallback(handleTabKey({ row, col: col + 6, store }), [])
    if (!columnDict[col]) return null

    const { cluster, store: assortmentStore } = columnDict[col]
    if (!product || !cluster || !assortmentStore) return null

    const { productCluster: pc, storeAssortmentProduct } = findStoreAssortmentProduct(
      product,
      cluster,
      assortmentStore
    )
    const value =
      !storeAssortmentProduct || !storeAssortmentProduct.buy
        ? 0
        : storeAssortmentProduct.buy.quantity

    //console.log({pc, value, storeAssortmentProduct})
    return (
      <Root>
        {row % 2 === 0 ? (
          <StatusColumnHeader status={pc && pc.status ? pc.status : '--'} />
        ) : !storeAssortmentProduct ? (
          <AddAssociationButton
            aria-disabled={
              !canEdit ||
              buyingSession.status === constants.CLOSED ||
              buyingSession.status === constants.FROZEN ||
              (product && !product.active)
            }
            disabled={
              !canEdit ||
              buyingSession.status === constants.CLOSED ||
              buyingSession.status === constants.FROZEN ||
              (product && !product.active)
            }
            onKeyDown={handleTab}
            onClick={async () => {
              await addProductToStore(product, cluster, pc, assortmentStore)

              // Repaint the cell
              //productGrid.onFormatItem(new FormatItemEventArgs(productGrid.cells, new CellRange(row, col), productGrid.cells.getCellElement(row, col)))
            }}
          />
        ) : (
          <>
            <QuantityInput
              disabled={
                !canEdit ||
                buyingSession.status === constants.CLOSED ||
                buyingSession.status === constants.FROZEN ||
                (product && !product.active)
              }
              key={value}
              defaultValue={value || 0}
              onKeyDown={handleTab}
              onBlur={async e => {
                await updateAssortmentProductQuantity(
                  storeAssortmentProduct,
                  parseInt(e.target['value']),
                  product
                )
              }}
            />

            <RemoveProductFromStoreButton
              aria-disabled={
                !canEdit ||
                product.isCore ||
                (product && !product.active) ||
                buyingSession.status === constants.CLOSED ||
                buyingSession.status === constants.FROZEN
              }
              disabled={
                !canEdit ||
                product.isCore ||
                !product.active ||
                buyingSession.status === constants.CLOSED ||
                buyingSession.status === constants.FROZEN
              }
              onClick={() =>
                removeProductFromStoreAssortment(
                  product,
                  storeAssortmentProduct,
                  assortmentStore
                )
              }
            />
          </>
        )}
      </Root>
    )
  })
}

export const StoreFilterDropdownCell: React.FunctionComponent<{}> = () => {
  return useObserver(function useHook() {
    const {
      filteredStores,
      queries: {
        clusters: { loading }
      },
      clusters
    } = useAssortmentSplitStore()

    return (
      <Popover
        minimal
        interactionKind='click'
        disabled={loading}
        position='bottom-left'
        content={
          <Menu>
            {!loading &&
              flatMap(
                clusters.map(c =>
                  c.stores.map(s => (
                    <MenuItem
                      key={s.id}
                      text={s.name}
                      shouldDismissPopover={false}
                      icon={filteredStores.has(s.id) ? 'blank' : 'tick'}
                      onClick={() =>
                        filteredStores.has(s.id)
                          ? filteredStores.delete(s.id)
                          : filteredStores.add(s.id)
                      }
                    />
                  ))
                )
              )}
          </Menu>
        }
      >
        <Tooltip content='Filter Stores' position='bottom'>
          <Icon iconSize={18} icon='cog' />
        </Tooltip>
      </Popover>
    )
  })
}

export const ClusterHeaderCell: any = ({ store }) => {
  return useObserver(function useHook() {
    const { expanded, toggleExpandCollapsed } = store
    return (
      <Tooltip
        content={`${expanded ? 'Collapse' : 'Expand'} Store Clusters`}
        position='bottom'
      >
        <Icon
          iconSize={18}
          onClick={toggleExpandCollapsed}
          icon={expanded ? 'caret-up' : 'caret-down'}
        />
      </Tooltip>
    )
  })
}
