/*
 * @Author: Raeesaa Metkari
 * @Date: 2020-04-13 23:56:04
 * @Last Modified by: Raeesaa Metkari
 * @Last Modified time: 2020-04-15 18:25:25
 */

export enum DoorTableViewColumnIdentifiers {
  Active = 'Active',
  Code = 'Code',
  Name = 'Name',
  Address = 'Address',
  City = 'City',
  Country = 'Country',
  Currency = 'Currency',
  Currency2 = 'Currency2',
  PriceGroup = 'PriceGroup',
  NumberOfDoors = 'NumberOfDoors',
  Clusters = 'Clusters',
  SalesDistrict = 'SalesDistrict'
}
