import { config } from '@config'
import { storage } from '@services/storageService'
import axios from 'axios'

const GET_COMMENT = 'comments'
const UPDATE_COMMENT = 'comments/product-comments-upsert'

export default class CommentService {
  getComments = async (buyingSessionGroupId, clientId) => {
    try {
      const url = `${config.buyingBoardServiceRestEndpoint}${GET_COMMENT}/buyingSessionGroupId/${buyingSessionGroupId}`
      const accessToken: string = storage.getItem('access_token')
      const response: any = await axios.get(url, {
        params: { clientId },
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      return response?.data
    } catch (e) {
      console.log(e)
    }
  }

  updateComment = async (
    buyingSessionGroupId: string,
    buyingSessionProductId: string,
    clientId: string,
    payload: any
  ) => {
    try {
      const url = `${config.buyingBoardServiceRestEndpoint}${UPDATE_COMMENT}`
      const accessToken: string = storage.getItem('access_token')
      const response = await axios.post(
        url,
        { ...payload, clientId, buyingSessionGroupId, buyingSessionProductId },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        }
      )
      return response?.data?.data
    } catch (e) {}
  }
}
