import React from 'react'
import { Route, RouteProps, Redirect } from 'react-router-dom'
import { storage } from '@services/storageService'
import { Authorization, AuthorizationProps } from './Authorization'

interface MyProps extends Partial<RouteProps>, AuthorizationProps {
  component: any
  classes?: any
  allowedRoles?: any
  isNavigation?: boolean
}

export class PrivateRoute extends React.Component<MyProps> {
  render() {
    const {
      props: { component, location, ...rest }
    } = this
    const token = storage.getItem('access_token')
    return (
      <Route
        {...rest}
        render={renderProps =>
          token ? (
            <Authorization {...rest} {...renderProps} component={component} />
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location }
              }}
            />
          )
        }
      />
    )
  }
}
