import { NO_OF_FILTER_TO_SHOW } from '@constants'
import { stores } from '@stores'
import cloneDeep from 'lodash/cloneDeep'
import find from 'lodash/find'
import forEach from 'lodash/forEach'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import map from 'lodash/map'
import keys from 'lodash/keys'
import remove from 'lodash/remove'
import memoize from 'memoize-one'
import { action, computed, observable } from 'mobx'

export class LookBookStore {
  @observable isDetailOpen = false
  @observable selectedLookId = null
  @observable isAddProductModalOpen = false
  @observable selectedProducts = []
  @observable selectedProductId = null
  @observable openDeleteDialog = false
  @observable addProductToLookFlag = false
  // Filter specific observables
  @observable showFilter = false
  @observable checkedFilterValues = {}
  @observable filters = {}

  // Filter specific actions
  @action closeFilter = () => {
    this.showFilter = false
  }

  @action
  setAddProductToLookFlag = (flag: boolean) => {
    Object.assign(this, { addProductToLookFlag: flag })
  }

  @action openFilter = () => {
    this.showFilter = true
  }

  @action clearFilter = () => {
    this.filters = {}
    this.checkedFilterValues = {}
  }

  @action cancelFilter = () => {
    this.clearFilter()
    this.closeFilter()
  }

  @action applyFilter = () => {
    this.filters = cloneDeep(this.checkedFilterValues)
    this.closeFilter()
  }

  @action onCheckFilter = (attribute, value) => {
    if (this.checkedFilterValues[attribute]) {
      const isValuePresentInFilter = find(
        this.checkedFilterValues[attribute],
        filter => filter.value === value.value
      )
      if (isValuePresentInFilter) {
        remove(
          this.checkedFilterValues[attribute],
          (filter: { label: string; value: string }) => filter.value === value.value
        )
      } else {
        this.checkedFilterValues[attribute].push(value)
      }
    } else {
      this.checkedFilterValues[attribute] = [value]
    }
  }

  @action onRemoveFilter = value => {
    forEach(keys(this.checkedFilterValues), key => {
      remove(
        this.checkedFilterValues[key],
        (filter: { label: string; value: string }) => filter.value === value.value
      )
    })

    this.filters = cloneDeep(this.checkedFilterValues)
  }

  @computed get filterChipsData() {
    const { filters } = this
    const chipsArrayToDisplay = []
    const chipsArrayInDropDown = []

    Object.keys(filters).forEach(filter => {
      filters[filter] &&
        filters[filter].forEach(item => {
          if (chipsArrayToDisplay.length < NO_OF_FILTER_TO_SHOW) {
            chipsArrayToDisplay.push(item)
          } else {
            chipsArrayInDropDown.push(item)
          }
        })
    })
    return {
      chipsArrayToDisplay: chipsArrayToDisplay || [],
      chipsArrayInDropDown: chipsArrayInDropDown || []
    }
  }

  // Lookbook page actions
  @action
  openLookbookDetails = selectedLookId => {
    Object.assign(this, { isDetailOpen: true })
    this.setSelectedLookId(selectedLookId)
  }

  @action
  closeLookbookDetails = () => {
    Object.assign(this, { isDetailOpen: false })
    this.resetSelectedLookId()
  }

  @action
  setSelectedLookId = selectedLookId => {
    Object.assign(this, { selectedLookId })
  }

  @action
  resetSelectedLookId = () => {
    Object.assign(this, { selectedLookId: null })
  }

  @action
  openAddProductModal = () => {
    Object.assign(this, { isAddProductModalOpen: true })
  }

  @action
  closeAddProductModal = () => {
    const {
      search: { clearSearchKey },
      filter: { clearAllFilters }
    } = stores
    Object.assign(this, { isAddProductModalOpen: false, selectedProducts: [] })
    clearAllFilters()
    clearSearchKey()
  }

  @action
  addProductToSelection = id => {
    const { selectedProducts } = this
    selectedProducts.push(id)
    Object.assign(this, { selectedProducts })
  }

  @action
  removeProductFromSelection = id => {
    const selectedProducts = this.selectedProducts.filter(productId => productId !== id)
    Object.assign(this, { selectedProducts })
  }

  @action
  resetProductSelection = () => {
    Object.assign(this, { selectedProducts: [] })
  }

  @action
  handleDeleteDialogOpen = selectedProductId => {
    Object.assign(this, { selectedProductId: selectedProductId, openDeleteDialog: true })
  }

  @action
  handleDeleteDialogClose = () => {
    Object.assign(this, { openDeleteDialog: !this.openDeleteDialog })
  }

  buildFilterInputForLooks = () => {
    let filterInput = null
    const { filters } = this

    // Filter input
    if (!isEmpty(filters)) {
      filterInput = {
        AND: []
      }

      forEach(keys(filters), key => {
        if (key === 'name' && filters[key].length) {
          filterInput.AND.push({
            name_in: map(filters[key], value => value.value)
          })
        } else if (key === 'clusters' && filters[key].length) {
          filterInput.AND.push({
            clusters_some: {
              id_in: map(filters[key], value => value.value)
            }
          })
        }
      })
    }
    return filterInput
  }

  buildSearchInputForLooks = () => {
    let lookSearchInput = null
    const {
      search: { searchKey }
    } = stores

    // Search input
    if (searchKey) {
      lookSearchInput = {
        OR: [
          { name_contains: searchKey },
          {
            clusters_some: {
              name_contains: searchKey
            }
          }
        ]
      }
    }

    return lookSearchInput
  }

  buildWhereInputForLooks = collectionNames => {
    let lookSearchInput = this.buildSearchInputForLooks()
    let filterInput = this.buildFilterInputForLooks()

    return {
      ...(lookSearchInput && lookSearchInput),
      ...(filterInput && filterInput),
      attributes_some: {
        strVal_in: collectionNames,
        definition: { name: 'collection' }
      }
    }
  }

  buildProductAttributeORInputForLooks = (categories, lookCollection?: string) => {
    const categoryList = cloneDeep(categories)

    // Filter categoryList to have only conditions related to collection of look
    if (lookCollection) {
      remove(categoryList, categoryMap => categoryMap['collection'] !== lookCollection)
    }

    let productAttributeORInput = map(categoryList, categoryMap => {
      let attributeANDInput = []

      Object.keys(categoryMap).forEach(key => {
        attributeANDInput.push({
          attributes_some: {
            strVal: categoryMap[key],
            definition: {
              name: key
            }
          }
        })
      })
      return attributeANDInput.length ? { AND: attributeANDInput } : undefined
    })

    return productAttributeORInput.length ? { OR: productAttributeORInput } : null
  }

  buildProductWhereInputForLookProducts = memoize(buyingSessionCollection => {
    // Build attribute OR input by parsing buying session collection
    let categoryList = this.getCategoryListFromBuyingSessionCollection(
      buyingSessionCollection
    )
    const attributeORInput = this.buildProductAttributeORInputForLooks(categoryList)

    return {
      ...attributeORInput
    }
  }, isEqual)

  buildProductWhereInputForAddProductToLooks = (look, buyingSessionCollection) => {
    const {
      filter: { productWhereInput: filterWhereInput }
    } = stores
    // Get collection for look
    const collectionAttribute = look.attributes
      ? look.attributes.find(attribute => attribute.definition.name === 'collection')
      : null
    let collectionName = ''
    if (collectionAttribute) {
      collectionName = collectionAttribute.strVal
    }

    // Build attribute OR input by parsing buying session collection
    let categoryList = this.getCategoryListFromBuyingSessionCollection(
      buyingSessionCollection
    )
    const attributeORInput = this.buildProductAttributeORInputForLooks(
      categoryList,
      collectionName
    )

    // Attribute condition
    let attributeWhereInput: any = {
      AND: [
        {
          ...attributeORInput
        }
      ]
    }

    if (collectionName) {
      attributeWhereInput.AND.push({
        attributes_some: {
          strVal: collectionName,
          definition: {
            name: 'collection'
          }
        }
      })
    }

    if (filterWhereInput) {
      attributeWhereInput.AND.push(filterWhereInput)
    }

    return {
      ...attributeWhereInput,
      active: true,
      looks_every: { NOT: { id: look.id } },
      substitutedInLooks_every: { NOT: { id: look.id } }
    }
  }

  getCategoryListFromBuyingSessionCollection = memoize(buyingSessionCollection => {
    if (
      buyingSessionCollection &&
      buyingSessionCollection.buyingSessions &&
      buyingSessionCollection.buyingSessions.length
    ) {
      return buyingSessionCollection.buyingSessions.reduce((acc, buyingSession) => {
        acc.push({
          collection: buyingSession.collection
            ? buyingSession.collection.description
            : '',
          gender: buyingSessionCollection.gender
            ? buyingSessionCollection.gender.description
            : '',
          activity: buyingSession.activity ? buyingSession.activity.description : ''
        })
        return acc
      }, [])
    }
    return []
  }, isEqual)

  getCollectionListFromBuyingSessionCollection = memoize(buyingSessionCollection => {
    if (
      buyingSessionCollection &&
      buyingSessionCollection.buyingSessions &&
      buyingSessionCollection.buyingSessions.length
    ) {
      return buyingSessionCollection.buyingSessions.reduce((acc, buyingSession) => {
        if (
          buyingSession &&
          buyingSession.collection &&
          !acc.includes(buyingSession.collection.description)
        ) {
          acc.push(buyingSession.collection.description)
        }
        return acc
      }, [])
    }
    return []
  }, isEqual)
}

export const lookbook = new LookBookStore()
