import React from 'react'
import * as css from './CardSkeleton.css'
interface MyProps {}

// FIXME: Have CSS based skeleton instead of adding div's for every row
export const CardSkeleton: React.FunctionComponent<MyProps> = () => {
  return (
    <div className={css['text-input__loading']}>
      <div className={css['image__loading--line']} />
      <div className={css['text-input__loading--line']} />
      <div className={css['text-input__loading--line']} />
      <div className={css['text-input__loading--line']} />
      <div className={css['text-input__loading--line']} />
      <div className={css['text-input__loading--line']} />
      <div className={css['text-input__loading--line']} />
      <div className={css['text-input__loading--line']} />
      <div className={css['text-input__loading--line']} />
      {window.innerWidth <= 1366 ? null : (
        <>
          <div className={css['text-input__loading--line']} />
          <div className={css['text-input__loading--line']} />
          <div className={css['text-input__loading--line']} />
          <div className={css['text-input__loading--line']} />
          <div className={css['text-input__loading--line']} />
          <div className={css['text-input__loading--line']} />
        </>
      )}
      <div className={css['actions__loading--line']} />
    </div>
  )
}
