/*
 * @Author: Raeesaa Metkari
 * @Date: 2019-11-20 16:56:23
 * @Last Modified by: Raeesaa Metkari
 * @Last Modified time: 2019-12-24 13:13:41
 */
import { QueryResult } from '@apollo/client/react'
import { Query } from '@apollo/client/react/components/Query'
import { ListViewColumnDefinition } from '@components/UI-Components/StandardTableView/models/ListviewColumnDefinition'
import { AuthorizationContext } from '@components/Utils/Authorization'
import { ProductContainerRow } from '@modules/collection/components/ProductContainerRow'
import { CardSkeleton } from '@modules/common/component/CardSkeleton'
import { ListRowSkeleton } from '@modules/common/component/ListRowSkeleton'
import { StickyStyle } from '@modules/common/models'
import * as css from '@modules/retail/collection/component/CollectionProductGrid.css'
import { IPermissions } from '@routes'
import { buildAssortmentQueryBasedOnType } from '@services/assortmentService'
import { getEmptyArrayOfLength } from '@services/commonServices'
import { stores, strings } from '@stores'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import map from 'lodash/map'
import memoize from 'memoize-one'
import { observer } from 'mobx-react'
import React from 'react'
import { AssortmentProductCardGrid } from './AssortmentProductCardGrid'

interface MyProps {
  style: any
  stickyStyle?: StickyStyle
  node: any
  categoryKey?: string
  assortment: {}
  buyingSession: { status: string }

  /**
   * NOTE: Following props will be set only in case of list view
   */
  headers?: Array<ListViewColumnDefinition>
  canTableFitInView?: boolean
  betBuyData?: Array<{}>
  rangePlans?: Array<{}>
  zone?: {}
}

const fetchAssortmentPermissions = memoize(
  (permissions: Array<IPermissions>, assortmentType: string) => {
    return permissions ? permissions[assortmentType] : null
  }
)

@observer
export class AssortmentProductRowWrapper extends React.Component<MyProps> {
  /**
   * Method for processing list view data
   */
  processAssortmentProductsForListView = memoize(
    ({
      assortmentProducts,
      buyingSession,
      permissions,
      betBuyData,
      rangePlans,
      zone,
      selectedAssortmentTab
    }: {
      assortmentProducts: Array<any>
      buyingSession: any
      permissions: IPermissions
      betBuyData: Array<any>
      rangePlans: Array<any>
      zone: any
      selectedAssortmentTab: number
      sortBy: string
    }) => {
      const {
        assortment: { getProcessedAssortmentProductsForListView }
      } = stores
      return getProcessedAssortmentProductsForListView({
        assortmentProducts,
        buyingSession,
        permissions,
        betBuyData,
        rangePlanData: rangePlans,
        zone,
        selectedAssortmentTab
      })
    },
    (oldParams, newParams) => {
      return (
        isEqual(oldParams[0].assortmentProducts, newParams[0].assortmentProducts) &&
        isEqual(oldParams[0].betBuyData, newParams[0].betBuyData) &&
        isEqual(oldParams[0].rangePlans, newParams[0].rangePlans) &&
        isEqual(oldParams[0].selectedAssortmentTab, newParams[0].selectedAssortmentTab)
      )
    }
  )

  /**
   * processAndSortAssortmentProducts
   * Wrapper around processAssortmentProductsForListView
   * which maintains sort order while sorting data
   * with buy metrics
   */
  processAndSortAssortmentProducts = (
    assortmentProducts,
    buyingSession,
    permissions,
    betBuyData,
    rangePlans,
    zone,
    selectedAssortmentTab,
    sortBy
  ) => {
    const [
      {
        props: { categoryKey }
      },
      {
        listView: { sortProductList },
        product: { isBuyMetricSort }
      }
    ] = [this, stores]

    let products = this.processAssortmentProductsForListView({
      assortmentProducts,
      buyingSession,
      permissions,
      betBuyData,
      rangePlans,
      zone,
      selectedAssortmentTab,
      sortBy
    })

    if (isBuyMetricSort) {
      products = sortProductList(products, sortBy, categoryKey)
    }

    return products
  }

  render() {
    const [
      {
        processAndSortAssortmentProducts,
        props: {
          node,
          style,
          assortment,
          buyingSession,
          headers,
          canTableFitInView,
          stickyStyle,
          betBuyData,
          rangePlans,
          zone
        }
      },
      {
        nav: {
          params: { assortmentType, view }
        },
        assortment: { selectedAssortmentTab },
        product: { handleListViewTagDrop, sortBy }
      }
    ] = [this, stores]
    const mockProducts = getEmptyArrayOfLength(node.products)
    const { query, variables } = buildAssortmentQueryBasedOnType(node)

    return (
      <AuthorizationContext.Consumer>
        {value => (
          <Query
            query={query as any}
            variables={variables}
            fetchPolicy={'cache-and-network'}
          >
            {({ error, loading, data, refetch }: QueryResult) => {
              // Permissions
              const assortmentPermissions = value.permissions as IPermissions[]
              const permissions = fetchAssortmentPermissions(
                assortmentPermissions,
                assortmentType as string
              )

              // Products
              const isLoading = loading && isEmpty(data)
              const assortmentProducts =
                data && data.assortmentProducts ? data.assortmentProducts : [] || []
              const missingProducts =
                !isLoading && node.products > assortmentProducts.length
                  ? getEmptyArrayOfLength(node.products - assortmentProducts.length)
                  : []
              return (
                <div style={style} className={view === strings.grid ? css.root : null}>
                  {view === strings.grid ? (
                    isLoading ? (
                      map(mockProducts, (product, index) => <CardSkeleton key={index} />)
                    ) : (
                      <AssortmentProductCardGrid
                        assortmentProducts={assortmentProducts}
                        refetch={refetch}
                        permissions={permissions}
                        assortment={assortment}
                        buyingSession={buyingSession}
                      />
                    )
                  ) : isLoading ? (
                    map(mockProducts, (product, index) => <ListRowSkeleton key={index} />)
                  ) : (
                    <ProductContainerRow
                      loading={isLoading}
                      products={processAndSortAssortmentProducts(
                        assortmentProducts,
                        buyingSession,
                        permissions,
                        betBuyData,
                        rangePlans,
                        zone,
                        selectedAssortmentTab,
                        sortBy
                      )}
                      /**
                       * In case there is mismatch in number of products and total products in KPI response,
                       * this prop will contain empty array with number of elements equal to the difference between
                       * the two so that skeleton is rendered till products is fetched
                       * NOTE: This use-case will happen only in case of add product to assortment mutation, where KPIs are updated
                       * before product for hierarchy are fetched
                       */
                      missingProducts={missingProducts}
                      kpiNode={node}
                      stickyStyle={stickyStyle}
                      key={node.key}
                      headers={headers}
                      canTableFitInView={canTableFitInView}
                      handleDrop={
                        selectedAssortmentTab !== 3 ? handleListViewTagDrop : null
                      }
                    />
                  )}
                </div>
              )
            }}
          </Query>
        )}
      </AuthorizationContext.Consumer>
    )
  }
}
