import Typography from '@components/std-components/Typography'
import { strings } from '@stores'
import '@styles/ReadMore/style.css'
import React, { useState } from 'react'

interface IReadMore {
  charLimit?: number
  text?: string
}

export const ReadMore: React.FC<IReadMore> = props => {
  const { text, charLimit } = props

  const [isReadMore, setIsReadMore] = useState(true)

  return (
    <Typography variant={'section2'} classes={'text'}>
      {isReadMore ? text?.slice(0, charLimit) : text}
      {text?.length! > charLimit! ? (
        <>
          {isReadMore && <Typography variant={'section3'}>{strings.ellipsis}</Typography>}
          <Typography
            variant={'section2'}
            onClick={() => setIsReadMore(isReadMore => !isReadMore)}
            classes={'read-or-hide'}
          >
            {isReadMore ? strings.readMore : strings.showLess}
          </Typography>
        </>
      ) : null}
    </Typography>
  )
}

ReadMore.defaultProps = {
  text: '',
  charLimit: 150
}
