import { StyledProps } from '@components/3rd-party/mui'
import TabletMediaQuery from '@components/Utils/TabletMediaQuery'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import React from 'react'

const styles = theme => ({
  root: {
    backgroundColor: '#424242',
    '&:hover': {
      backgroundColor: '#000000'
    }
  },
  rootTablet: {
    backgroundColor: '#000000',
    borderRadius: 0,
    marginHorizontal: 0
  },
  disabled: {
    color: '#bdbdbd !important',
    backgroundColor: 'rgba(27,27,27, 0.1) !important',
    pointerEvents: 'none'
  },
  buttonLabel: {
    marginTop: -1
  },
  buttonProgress: {
    color: '#fff',
    margin: `0 ${theme.spacing(1)}`
  },
  loading: {
    cursor: 'progress',
    pointerEvents: 'none'
  }
})

interface MyProps extends StyledProps, React.ButtonHTMLAttributes<HTMLElement> {
  /** Boolean indicating whether the button should render as disabled */
  disabled?: boolean
  component?: any
  /** Boolean indicating action progress */
  loading?: boolean
  color?: string
  type?: 'submit' | 'reset' | 'button'
  fullWidth?: boolean
}

export class PrimaryButtonComponent extends React.Component<MyProps> {
  static defaultProps = {
    disabled: false,
    loading: false,
    onClick: () => {}
  }

  render() {
    const {
      props,
      props: { classes, className, children, loading, ...otherProps }
    } = this

    return (
      <TabletMediaQuery>
        {isTablet => (
          <Button
            classes={{
              root: isTablet ? classes.rootTablet : classes.root,
              disabled: classes.disabled
            }}
            className={classNames(loading ? classes.loading : '', className)}
            variant='contained'
            color={'primary' as any}
            {...otherProps}
            disabled={props.disabled}
            onClick={!loading ? props.onClick : () => {}}
          >
            {children}
            {loading && (
              <CircularProgress
                color='secondary'
                size={24}
                className={`${classes.buttonProgress} ${classes.rightIcon}`}
              />
            )}
          </Button>
        )}
      </TabletMediaQuery>
    )
  }
}

@((withStyles as any)(styles))
export class PrimaryButton extends PrimaryButtonComponent {}
