import GET_ZONES from './zones.graphql'
import GET_STORE_WEIGHTS from './storeWeights.graphql'
import GET_STORES from './GetStores.graphql'
import GET_ZONES_COUNTRIES_CATEGORIES from './GetZoneCountryCategory.graphql'
import CREATE_STORE from './CreateStore.graphql'
import REMOVE_STORE from './RemoveStore.graphql'
import UPDATE_STORE from './UpdateStore.graphql'
import UPSERT_STORE_WEIGHT from './upsertStoreWeight.graphql'
import CREATE_COUNTRY from './createCountry.graphql'

export * from './hooks'

export {
  GET_ZONES,
  GET_STORE_WEIGHTS,
  GET_STORES,
  GET_ZONES_COUNTRIES_CATEGORIES,
  CREATE_STORE,
  REMOVE_STORE,
  UPDATE_STORE,
  UPSERT_STORE_WEIGHT,
  CREATE_COUNTRY
}
