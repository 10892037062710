/*
 * @Author: Raeesaa Metkari
 * @Date: 2020-04-10 16:51:06
 * @Last Modified by: Raeesaa Metkari
 * @Last Modified time: 2020-08-03 20:58:15
 */
import { useQuery } from '@apollo/client/react/hooks/useQuery'
import { WHL_PRICES_WITH_PRICE_LIST } from './getWHLPriceWithPricelist'
import { RETAIL_PRICES_WITH_PRICE_LIST } from './getRetailPriceWithPricelist'
import { SRP_PRICES_WITH_PRICE_LIST } from './getSRPWithPricelist'
import { ENUM_LIST_WITH_PATTERN } from './enumListWithPattern'
import { ASSORTMENT_STATUS_BY_BSG } from './assortmentStatusByBSG'
import { BUYING_SESSION_PRODUCT } from './buyingSessionProducts'
import { BUYING_SESSION_GROUP_PRODUCT_SUMMARY } from './buyingSessionProductSummary'
import { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions'
import { CriteriaOperationType } from '@models'
import { CriteriaStringInput } from '@modules/common/models'
import { VIPJobStatus } from 'src/constant/VIPJobStatuses'

export interface IArticleOrInput {
  Code: CriteriaStringInput
}

export interface IPriceListVariables {
  srpPriceGroup: string
  whlPriceGroup: string
  retailRefPriceGroup: string
  retailRefPriceType: string
  srpPriceType: string
  whlPriceType: string
  retailPriceGroup: string
  retailPriceType: string
  buyingSessionGroupDate: number
  articlesOrInput: Array<IArticleOrInput>
}

/**
 * Hook for returning buying session product summary
 * @param buyingSessionProductSummaryWhereQuery - Where argument to be passed with query
 * @param {WatchQueryFetchPolicy} fetchPolicy - Fetch policy for query, defaults to cache-first
 */
export const useBuyingSessionProductSummary = (
  buyingSessionProductSummaryWhereQuery,
  fetchPolicy: WatchQueryFetchPolicy = 'cache-first',
  skip: boolean = false
) => {
  return useQuery(BUYING_SESSION_GROUP_PRODUCT_SUMMARY, {
    skip: skip ? skip : !buyingSessionProductSummaryWhereQuery,
    variables: {
      where: buyingSessionProductSummaryWhereQuery
    },
    fetchPolicy
  })
}

/**
 * Hook for returning buying session products
 * @param buyingSessionProductWhereQuery  - Where argument to be passed with query
 * @param {WatchQueryFetchPolicy} fetchPolicy - Fetch policy for query, defaults to cache-first
 */

export const useBuyingSessionProduct = (
  buyingSessionProductWhereQuery,
  fetchPolicy: WatchQueryFetchPolicy = 'cache-first'
) => {
  return useQuery(BUYING_SESSION_PRODUCT, {
    skip: !buyingSessionProductWhereQuery,
    variables: {
      where: buyingSessionProductWhereQuery
    },
    fetchPolicy
  })
}

/**
 * Hook for returning all relevant enums
 * @param buyingSessionProductWhereQuery  - Where argument to be passed with query
 * @param {WatchQueryFetchPolicy} fetchPolicy - Fetch policy for query, defaults to cache-first
 */

export const useProductEnumList = (
  fetchPolicy: WatchQueryFetchPolicy = 'cache-first'
) => {
  return useQuery(ENUM_LIST_WITH_PATTERN, {
    variables: {
      where: {
        OR: [
          {
            Name: {
              operation: CriteriaOperationType.RE,
              value: '%GIV%'
            }
          },
          {
            Name: {
              operation: CriteriaOperationType.RE,
              value: '%VIP%'
            }
          }
        ]
      }
    },
    fetchPolicy
  })
}

/**
 * Hook for fetching all 3 types of prices along with price list for set of articles (styles)
 * @param {IPriceListVariables} variables - Variables to be passed in where argument of batched query
 * @param {boolean} skip - Boolean flag specifying if query should be skipped
 * @param {WatchQueryFetchPolicy} fetchPolicy - Fetch policy for query, defaults to cache-first
 */
export const usePricesWithPriceList = (
  variables: IPriceListVariables,
  skip: boolean = false
) => {
  let [
    { data: srpEuroPriceData, loading: srpEuroPriceRequestLoading },
    { data: srpPriceData, loading: srpPriceRequestLoading },
    { data: whlPriceData, loading: whlPriceRequestLoading }
  ] = [
    // Query Retail Prices with price list
    useQuery(RETAIL_PRICES_WITH_PRICE_LIST, {
      variables,
      skip: skip || !variables.retailPriceType
    }),
    // Query SRP Prices with price list
    useQuery(SRP_PRICES_WITH_PRICE_LIST, {
      variables,
      skip: skip || !variables.srpPriceGroup
    }),
    // Query WHL Prices with price list
    useQuery(WHL_PRICES_WITH_PRICE_LIST, {
      variables,
      skip: skip || !variables.whlPriceGroup
    })
  ]

  const loading =
    srpEuroPriceRequestLoading || srpPriceRequestLoading || whlPriceRequestLoading

  return {
    data:
      srpEuroPriceData || srpPriceData || whlPriceData
        ? { ...srpEuroPriceData, ...srpPriceData, ...whlPriceData }
        : undefined,
    loading
  }
}

export const useAssortmentStatusByBSG = (
  buyingSessionGroupId: string,
  statuses: VIPJobStatus[],
  limit = 10,
  pollInterval,
  fetchPolicy: WatchQueryFetchPolicy = 'cache-first'
) => {
  const { data, loading, startPolling, stopPolling, refetch } = useQuery(
    ASSORTMENT_STATUS_BY_BSG,
    {
      variables: {
        buyingSessionGroupId,
        statusFilter: statuses.map(value => ({
          VIPJobStatus: {
            operation: CriteriaOperationType.EQ,
            value
          }
        })),
        limit
      },
      fetchPolicy,
      pollInterval,
      skip: !buyingSessionGroupId
    }
  )
  return {
    jobQueueItems: data?.findSpecificationItemDefinition?.Items,
    loading,
    startPolling,
    stopPolling,
    refetch
  }
}
