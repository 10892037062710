export enum clientOrderManagementTableFieldViewMapping {
  ClientName = 'name',
  ClientCode = 'code',
  CreationDate = 'date',
  OrderNumber = 'orderNumber',
  SalesAssistant = 'salesAssistant',
  Doors = 'doors',
  ShipTo = 'shipTo',
  Value = 'value',
  globalValue = 'globalValue',
  DeliveryDate = 'deliveryDate',
  Qty = 'qty',
  Country = 'country',
  ZAM = 'zam',
  ActivityGender = 'activityGender',
  Status = 'status',
  SAPStatus = 'sapStatus',
  IsSendToSAPDisabled = 'disableSendToSap',
  SAPOrderNumber = 'sapOrderNumber',
  ClientDisabled = 'isClientDisabled',
  IsDeleteStatusDisabled = 'isDeleteStatusDisabled',
  IsEditStatusDisabled = 'isEditStatusDisabled',
  IsDeliveryDateDisabled = 'isDeliveryDateDisabled',
  Channel = 'channel'
}
