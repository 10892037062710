import { useQueryDynamic } from '@apollo'
import { useMutation } from '@apollo/client/react/hooks/useMutation'
import { useQuery } from '@apollo/client/react/hooks/useQuery'
import { ADD_PRODUCT_CLUSTER_STATUS } from '@modules/retail/assortment/graphql/addProductClusterStatus'
import { BULK_ADD_PRODUCTS_TO_STORE_ASSORTMENT } from '@modules/retail/assortment/graphql/bulkAddProductsToStoreAssortment'
import { CLUSTER_ASSOCIATIONS_BY_ZONE_ASSORTMENT } from '@modules/retail/assortment/graphql/clustersByZoneAssortment'
import { REMOVE_RM_CLUSTER_FROM_PRODUCT } from '@modules/retail/assortment/graphql/removeClusterFromRMAssortment'
import { REMOVE_PRODUCT_FROM_STORE_ASSORTMENT } from '@modules/retail/assortment/graphql/removeProductFromStoreAssortment'
import { ZONE_ASSORTMENT_PRODUCT_WITH_STORES } from '@modules/retail/assortment/graphql/zoneAssortmentProductsWithStores'
import {
  GET_CHANNELS_CLUSTERS_DROPS,
  GET_FREETAGS
} from '@modules/retail/navigations/graphql'
import { GET_ZONES } from '@modules/stores'
import { useBuyingSessionGroupName } from '@modules/wholesale/buyingGroupSummary/graphql/hooks'
import { useBuyingSession } from '@modules/wholesale/buyingSessionCollection/graphql/hooks'
import { stores } from '@stores'
import { GET_COLLECTION } from '../graphql/collection'
import { GET_BUYING_SESSION_COLLECTION } from '../graphql/getBuyingSessionCollection'
import { GET_BUYINGSESSION_PRODUCT_ATTRIBUTES } from '../graphql/getBuyingSessionProductAttributes'
import { SPLIT_ZONE_QUANTITY_TO_ALL_STORES } from '../graphql/splitZoneQuantityToAllStores'
import { SPLIT_ZONE_QUANTITY_TO_STORES } from '../graphql/splitZoneQuantityToStores'
import { UPDATE_STORE_PRODUCT_BUY_QUANTITY } from '@modules/retail/collection/graphql/updateStoreAssortmentProductBuyQauntity'
import { UPDATE_ZONE_PRODUCT_BUY_QUANTITY } from '@modules/retail/collection/graphql/updateZoneAssortmentProductBuyQauntity'
import { ZONE_ASSORTMENT_PRODUCT_BY_ID } from '../graphql/zoneAssortmentProductById'
import { ZONE_COUNTER_OFFER } from '../graphql/zoneCounterOffer'

export interface IStoreAssortment {
  assortmentProducts?: Array<IStoreAssortmentProduct>
  id: string
  store?: IStoreAssortment
}

export interface IBetBuy {
  bet?: { id; quantity: number }
  buy?: { id; quantity: number }
}
export interface IStoreAssortmentProduct extends IBetBuy {
  createdAt?: string
  id: string
  product?: IBuyingSessionProduct
}

interface IBuyingSessionProduct extends IBetBuy {
  id: string
  assortmentProducts?: Array<IClusterAssortmentProducts>
}

interface IClusterAssortmentProducts {
  id: string
  assortment: IClusterAssortmentProduct
}

interface IClusterAssortmentProduct {}

export interface IStoreProduct extends IBetBuy {
  id: string
  storeId: string
}

export interface IClusterWiseStoreAssortmentProducts {
  clusterId: string
  id: string
  name: string
  colorHex: string
  status: string
  storeAssortmentProducts: Array<IStoreProduct>
}

export interface IZoneAssortmentProductWithStore extends IBetBuy {
  id: string
  active?: boolean
  buyingSessionProductId?: string
  isCore?: boolean
  clusters?: Array<IClusterWiseStoreAssortmentProducts>
  buyerClusters?: Array<IClusterWiseStoreAssortmentProducts>
}

export interface IZoneAssortmentProducts {
  zoneAssortmentProductsWithStores: {
    zoneAssortmentProducts: Array<IZoneAssortmentProductWithStore>
  }
}

export const useZoneAssortmentProductsWithStores = () => {
  const {
    product: { whereZoneAssortmentProductQueryForSplit }
  } = stores

  return useQueryDynamic<IZoneAssortmentProducts>(ZONE_ASSORTMENT_PRODUCT_WITH_STORES, {
    variables: whereZoneAssortmentProductQueryForSplit(),
    fetchPolicy: 'network-only'
  })
}

export interface IClusterByZoneAssortmentStore {
  averageDepth: number
  id: string
  storeAssortmentId: string
  name: string
  quantity: number
  totalProducts: number
  value: number
  otb: number
}

export interface IClusterByZoneAssortment {
  id: string
  colorHex?: string
  name?: string
  stores: Array<IClusterByZoneAssortmentStore>
}

export interface IClusterStores {
  clustersByZoneAssortment: {
    clusters: Array<IClusterByZoneAssortment>
  }
}

export const useClusterStoreAssociations = () => {
  const {
    product: { whereZoneAssortmentProductQueryForSplit }
  } = stores

  return useQueryDynamic<IClusterStores>(CLUSTER_ASSOCIATIONS_BY_ZONE_ASSORTMENT, {
    variables: whereZoneAssortmentProductQueryForSplit('BUY', true)
  })
}

/**
 * Hook for querying zone assortment product by id
 * @param {string} id - Id of zone assortment product whose
 */
export const useZoneAssortmentProductById = (id: string) => {
  return useQueryDynamic(ZONE_ASSORTMENT_PRODUCT_BY_ID, {
    variables: {
      id
    }
  })
}

/**
 * Hook for updating buy quantity for store assortment product
 * @param {string} assortmentProductId - Id of store assortment product whose quantity is to be updated
 * @param {number} quantity - BUY quantity to be updated
 */
export const useUpdateAssortmentProductQuantity = () => {
  const [updateAssortmentProductQuantity] = useMutation<
    {},
    { productId: string; quantity: number }
  >(UPDATE_STORE_PRODUCT_BUY_QUANTITY)
  return updateAssortmentProductQuantity
}

/**
 * Hook for updating buy quantity for zone assortment product
 * @param {string} assortmentProductId - Id of store assortment product whose quantity is to be updated
 * @param {number} quantity - BUY quantity to be updated
 */
export const useUpdateZoneAssortmentProductQuantity = () => {
  const [updateAssortmentProductQuantity] = useMutation<
    {},
    { productId: string; quantity: number }
  >(UPDATE_ZONE_PRODUCT_BUY_QUANTITY)
  return updateAssortmentProductQuantity
}

/**
 * Hook for removing buyer cluster form zone assortment product
 * @param {string} assortmentProductId - Id of store assortment product
 * @param {string} clusterId - BUY quantity to be updated
 */
export const useRemoveBuyerClusterFromAssortmentProduct = () => {
  const [removeBuyerClusterFromAssortmentProduct] = useMutation<
    {},
    { productId: string; clusterId: string }
  >(REMOVE_RM_CLUSTER_FROM_PRODUCT)
  return removeBuyerClusterFromAssortmentProduct
}

/**
 * Hook for adding products to store assortment
 * @param {string} assortmentId - Id of store assortment to which product is to be added
 * @param {Array<string>} buyingSessionProductIds - List of buying session product ids to be added to store assortment
 */
export const useAddProductsToStoreAssortment = () => {
  // (assortmentId: string, buyingSessionProductIds: Array<string>) => {
  const [addProductsToStoreAssortment] = useMutation<
    { assortment: IStoreAssortment },
    { productIds: string[]; assortmentId: string }
  >(BULK_ADD_PRODUCTS_TO_STORE_ASSORTMENT)
  return addProductsToStoreAssortment
}

/**
 * Hook for removing product from store assortment
 * @param {string} assortmentId - Id of store assortment to which product is to be added
 * @param {string} assortmentProductId - Id of store assortment product that is to be deleted
 */
export const useRemoveProductFromStoreAssortment = () => {
  // (assortmentId: string, assortmentProductId: string) => {
  const [removeProductFromStoreAssortment] = useMutation<
    {},
    { assortmentId: string; productIds: string[] }
  >(REMOVE_PRODUCT_FROM_STORE_ASSORTMENT)
  return removeProductFromStoreAssortment
}

/**
 * Hook for spliting zone quantity to stores
 */
export const useSplitZoneQuantityToStores = () => {
  const [splitToStore] = useMutation<
    {},
    { zoneAssortmentProductId: string; quantityType: string }
  >(SPLIT_ZONE_QUANTITY_TO_STORES)
  return splitToStore
}

/**
 * Hook for spliting zone quantity of every product
 * across their available stores
 */
export const useSplitZoneQuantityToAllStores = () => {
  const [splitToAllStore] = useMutation<
    {},
    { zoneAssortmentId: string; quantityType: string }
  >(SPLIT_ZONE_QUANTITY_TO_ALL_STORES)
  return splitToAllStore
}

/**
 * Hook for getting zones, cluster, sales period and channels
 */

export const useGetSalesPeriodClusterChannels = () => {
  const {
    nav: {
      params: { podium }
    }
  } = stores
  return useQueryDynamic(GET_CHANNELS_CLUSTERS_DROPS, {
    variables: { collectionId: podium },
    fetchPolicy: 'cache-and-network'
  })
}
/**
 * Hook for getting zones
 */

export const useGetZones = () => {
  return useQuery(GET_ZONES)
}
/**
 * Hook for getting Free tags
 */

export const useGetFreeTags = () => {
  return useQuery(GET_FREETAGS)
}

export const useUpdateClusterStatus = () => {
  const [addSeasonsToSync] = useMutation<
    { statuses },
    { clusterId: string; statusId: string; productId: string }
  >(ADD_PRODUCT_CLUSTER_STATUS)
  return addSeasonsToSync
}

/**
 * Hook for fetching buying session products (collection and activity collection)
 */

export const useCollection = variables => {
  return useQuery(GET_COLLECTION, {
    variables,
    fetchPolicy: 'cache-and-network'
  })
}

/**
 * Hook for fetching zone wise counter offer for buying session products
 * Picks buyingsessionId from URL in case not passed in parameters
 */
export const useCounterOffer = (buyingSessionId?: string) => {
  const {
    nav: {
      params: { buyingSession }
    },
    site: { isGlobalMerchandiserOrAdmin },
    assortment: { selectedAssortmentTab }
  } = stores
  const buyingSessionIdToBeUsed = buyingSessionId || buyingSession

  return useQuery(ZONE_COUNTER_OFFER, {
    variables: {
      buyingSessionId: buyingSessionIdToBeUsed
    },
    skip:
      !buyingSessionIdToBeUsed ||
      !isGlobalMerchandiserOrAdmin() ||
      selectedAssortmentTab !== 3
  })
}

/**
 * Hook for buying session collection
 */
export const useBuyingSessionCollection = variables => {
  return useQuery(GET_BUYING_SESSION_COLLECTION, {
    variables,
    skip: Object.keys(variables || {}).length === 0
  })
}

/**
 * Hook to get buyingsessionProductAttributeDefinitions
 */

export const useBuyingSeeionProductAttributeDefinition = () => {
  return useQuery(GET_BUYINGSESSION_PRODUCT_ATTRIBUTES)
}

/**
 *
 * @param isWholesale
 * Hook to get the buyingsessionGroup Name on the basis of wholsale or retail request.
 */
export const useBuyingSessionGroupNameForChannel = (
  isWholesale: boolean,
  skip = false
): string => {
  const {
    nav: {
      buyingSession,
      buyingSessionGroupId,
      params: { podium: podiumId }
    }
  } = stores
  const { data: groupNameRes } = useBuyingSessionGroupName(
    skip || !isWholesale ? undefined : buyingSessionGroupId
  )
  const { data: buyingSessionRes } = useBuyingSession(
    skip || !isWholesale ? undefined : buyingSession
  )
  const { data: buyingSessionCollectionRes } = useBuyingSessionCollection(
    skip || isWholesale ? undefined : { collectionId: podiumId }
  )
  return isWholesale
    ? buyingSessionGroupId
      ? groupNameRes?.getVIPBuyingSessionGroup.Name
      : buyingSessionRes?.getVIPBuyingSession.Name
    : buyingSessionCollectionRes?.buyingSessionCollection.name
}
