import React from 'react'
import CoreIcon from '@components/std-components/CoreIcon'
import { InactiveIcon } from '@components/std-components/InactiveIcon'
import {
  IProductMediaCarousel,
  ProductMediaCarousel
} from '@components/std-components/ProductMediaCarousel'
import { SmallRecommendedIcon } from '@components/std-components/RecommendedIcon'
import {
  getBasketActionsByRoute,
  getFavouriteActions,
  getOrderActions
} from '@services/showroom/SAProductDetailService'
import { stores } from '@stores'
import { useObserver } from 'mobx-react-lite'
import { SAActions, SAProductDetailsBasicCarousel } from '..'
import { useSAProductDetailsContext } from '../../hook/SAProductDetails-Hook'
import { VIPClientType } from '@modules/common/models/enums/VIPClientType'
import { getEnumValue } from '@utils/get-enum-value'
import Button from '@components/std-components/Button'

interface ISAProductDetailCarousel extends IProductMediaCarousel {
  isBottomRightIconClickable?: boolean
  productDetailBottomRightCallBack?: (bspId) => void
  showIcon?: boolean
}

export const SAProductDetailCarousel: React.FC<ISAProductDetailCarousel> = ({
  productDetailBottomRightCallBack,
  isBottomRightIconClickable,
  showIcon,
  variant
}) => {
  return useObserver(function useHook() {
    const {
      saProductDetailsStore: {
        isBuyingSessionProductBasicInformationLoading,
        product,
        buyingSessionProduct,
        productImages,
        toggleFavoriteStatus,
        addProductToOrder,
        removeProductToOrder,
        isOrderProduct,
        isOrderPage
      }
    } = useSAProductDetailsContext()
    const {
      // showroomProductDetailStore: { processedProduct, toggleIsFavourite },
      nav: {
        queryParams: { bspId, clientType },
        location: { pathname }
      }
    } = stores
    const originalActionFlag = isOrderPage ? isOrderProduct : product?.isFavorite

    const [isLocalOrderProduct, setLocalOrderProduct] =
      React.useState<boolean>(originalActionFlag)

    const callBackRef = React.useRef(null)
    React.useEffect(() => {
      return () => callBackRef?.current
    }, [bspId])
    React.useEffect(() => {
      if (isLocalOrderProduct === originalActionFlag) {
        callBackRef.current = null
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLocalOrderProduct])
    React.useEffect(() => {
      setLocalOrderProduct(originalActionFlag)
    }, [originalActionFlag])

    // const { images, iframeURL } = getProductImagesForDetailsView(
    //   processedProduct?.externalImages,
    //   processedProduct?.imageURL,
    //   true
    // )

    const { images, iframeURL, videoURL } = productImages

    // get either basket or favorite actions based on pathname
    const { actionIcon, onActionIconClick } = getBasketActionsByRoute(pathname)
      ? getOrderActions(
          isLocalOrderProduct,
          buyingSessionProduct?.original?.active,
          product?.isCore,
          isBottomRightIconClickable,
          bspId,
          productDetailBottomRightCallBack,
          addProductToOrder,
          removeProductToOrder // TMP fix need to improve condition
        )
      : getFavouriteActions(
          isLocalOrderProduct,
          buyingSessionProduct?.original?.active,
          isBottomRightIconClickable,
          bspId,
          productDetailBottomRightCallBack,
          toggleFavoriteStatus,
          pathname.includes('lookbook')
        )

    const onBottomRightActionClick = () => {
      setLocalOrderProduct(preVal => !preVal)
      if (onActionIconClick) callBackRef.current = onActionIconClick()
    }

    // product?.isCore only for retail
    const topLeftIcon =
      product?.isCore && clientType === getEnumValue(VIPClientType.Internal) ? (
        <CoreIcon isInactiveFlagPresent={!buyingSessionProduct?.original?.active} />
      ) : product?.isRecommended &&
        clientType === getEnumValue(VIPClientType.External) ? (
        <SmallRecommendedIcon />
      ) : null

    return (
      <>
        {isBuyingSessionProductBasicInformationLoading ? (
          <SAProductDetailsBasicCarousel />
        ) : (
          <ProductMediaCarousel
            active={buyingSessionProduct?.original?.active}
            carouselMedia={images}
            iFrameSrc={iframeURL}
            videoSrc={videoURL}
            // iconConfig={iconConfig}
            topLeftIcon={
              !product?.original?.active ? (
                <div>
                  <InactiveIcon /> {topLeftIcon}
                </div>
              ) : (
                topLeftIcon
              )
            }
            bottomRightIcon={
              showIcon && (
                <Button
                  variant='icon'
                  style={{ height: '60px', width: '60px' }}
                  theme='light'
                  disabled={!isBottomRightIconClickable}
                  onClick={onBottomRightActionClick}
                >
                  <SAActions actionType={actionIcon} />
                </Button>
              )
            }
            variant={variant}
          />
        )}
      </>
    )
  })
}

SAProductDetailCarousel.defaultProps = {
  isBottomRightIconClickable: false
}
