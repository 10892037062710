import { QueryResult } from '@apollo/client/react'
import { Query } from '@apollo/client/react/components/Query'
import { withApollo } from '@apollo/client/react/hoc/withApollo'
import { StyledProps } from '@components/3rd-party/mui'
import {
  OPEN_ASSORTMENT_REVIEW_REQUEST,
  SUBMIT_ASSORTMENT_REVIEW
} from '@components/UI-Components/Assortment/graphql'
import { ErrorContainer } from '@modules/errors/containers/Error'
import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { nav } from '@stores/nav'
import { strings, stores } from '@stores'
import classNames from 'classnames'
import React from 'react'
import { withRouter } from 'react-router-dom'

const styles = theme => ({
  container: {
    flex: 1,
    padding: 15
  },
  paddingBottom: {
    paddingBottom: 10
  },
  bottomBorder: {
    borderBottom: '1px solid #000000'
  },
  group: {
    margin: theme.spacing(2),
    flexDirection: 'row'
  },
  textField: {
    flex: 1
  },
  commentBox: {
    flex: 1,
    display: 'flex',
    paddingLeft: 15,
    paddingRight: 15,
    flexDirection: 'column'
  },
  justifyRight: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  openRequestInfoContainer: {
    borderTop: '1px solid black',
    marginTop: '10px',
    paddingTop: '10px'
  },
  openRequestInfoRow: {
    width: '100%',
    clear: 'both',
    float: 'left',
    marginBottom: '5px'
  },
  comments: {
    width: '100%'
  },
  usersName: {
    fontWeight: 'bolder'
  },
  smallFonts: {
    fontSize: '12px'
  },
  requestType: {
    fontSize: '18px',
    fontWeight: 'bold'
  },
  reviewType: {
    float: 'left'
  },
  reviewStatus: {
    float: 'right'
  },
  commentsContainer: {
    paddingBottom: '15px'
  }
})

interface ICMApprovalDialog extends StyledProps {
  zone?: any
  store?: any
  history?: any
  handleApprovalPopupClose: any
  handleApprovalPopupSubmit: any
  client?: any
  match?: any
}

@((withStyles as any)(styles))
@(withRouter as any)
@(withApollo as any)
export class CMApprovalDialog extends React.Component<ICMApprovalDialog> {
  state = {
    status: 'SUGGEST_CHANGES',
    comment: ''
  }

  onCommentChanged = event => {
    this.setState({ comment: event.target.value })
  }

  onStatusChanged = event => {
    this.setState({ status: event.target.value })
  }

  onSend = async openRequestData => {
    const {
      props: { handleApprovalPopupSubmit, client },
      state: { comment, status }
    } = this
    await client.mutate({
      mutation: SUBMIT_ASSORTMENT_REVIEW,
      variables: {
        action: status,
        comment,
        id: openRequestData.id
      }
    })
    handleApprovalPopupSubmit()
  }

  render() {
    const {
      props: { classes },
      state: { status, comment },
      onCommentChanged,
      onStatusChanged,
      onSend
    } = this

    const {
      params: { assortment: assortmentId }
    } = nav

    const isGMOrAdmin = stores.site.isGlobalMerchandiserOrAdmin()
    return (
      <Query
        query={OPEN_ASSORTMENT_REVIEW_REQUEST}
        variables={{ assortmentId }}
        fetchPolicy={'cache-and-network'}
        skip={!isGMOrAdmin}
      >
        {({
          data,
          error: errorInLOpenAssortmentQuery,
          loading: loadingOpenAssortment
        }: QueryResult) => {
          let openRequestData = data ? data.openAssortmentReviewRequest : null

          const dateObj = openRequestData ? new Date(openRequestData.createdAt) : ''
          const commentDateTime = dateObj
            ? `${dateObj.getMonth()}/${dateObj.getDate()}/${dateObj.getFullYear()} ${dateObj.getHours()}:${dateObj.getMinutes()}:${dateObj.getSeconds()}`
            : ''
          if (errorInLOpenAssortmentQuery)
            return <ErrorContainer error={errorInLOpenAssortmentQuery} />

          return (
            <div className={classes.container}>
              <div className={classNames(classes.bottomBorder, classes.paddingBottom)}>
                <Typography variant='subtitle1'>Review</Typography>
              </div>
              <div>
                <RadioGroup
                  name='status'
                  className={classes.group}
                  value={status}
                  onChange={onStatusChanged}
                >
                  <FormControlLabel
                    value='SUGGEST_CHANGES'
                    control={<Radio color='primary' />}
                    label='Suggest adjustments'
                  />
                  <FormControlLabel
                    value='APPROVE'
                    control={<Radio color='primary' />}
                    label='Approved'
                  />
                </RadioGroup>
              </div>
              <div className={classes.commentBox}>
                <Typography variant='caption'>Comment</Typography>
                <TextField
                  label=''
                  multiline
                  rows='2'
                  placeholder=''
                  className={classes.textField}
                  margin='normal'
                  variant='outlined'
                  onChange={onCommentChanged}
                />
              </div>
              <div className={classes.justifyRight}>
                <Button
                  onClick={() => onSend(openRequestData)}
                  disabled={Boolean(comment && comment.length > 180)}
                >
                  Send
                </Button>
              </div>

              {/* HISTORY SECTION */}
              {openRequestData ? (
                <div className={classes.openRequestInfoContainer}>
                  <span
                    className={classNames(
                      classes.openRequestInfoRow,
                      classes.requestType
                    )}
                  >
                    <span className={classes.reviewType}>
                      {strings.assortmentReviewTypes[openRequestData.type]}
                    </span>
                    <span className={classes.reviewStatus}>
                      {strings.assortmentReviewStatus[openRequestData.status]}
                    </span>
                  </span>
                  <span
                    className={classNames(classes.openRequestInfoRow, classes.smallFonts)}
                  >
                    Request raised on {commentDateTime} by:{' '}
                    <span
                      className={classes.usersName}
                    >{`${openRequestData.raisedBy.firstName} ${openRequestData.raisedBy.lastName}`}</span>
                  </span>
                  <span
                    className={classNames(classes.openRequestInfoRow, classes.smallFonts)}
                  >
                    Comments:
                  </span>
                  <span
                    className={classNames(
                      classes.openRequestInfoRow,
                      classes.commentsContainer
                    )}
                  >
                    {openRequestData.comment ? openRequestData.comment : '--'}
                  </span>
                </div>
              ) : null}
            </div>
          )
        }}
      </Query>
    )
  }
}
