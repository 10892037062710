import { RowType, RowTypeLabel } from '@modules/common/models/enums/SASizeProductModal'
import { VIPSize } from '@services/wholesale/wholesaleCollectionService'
import memoize from 'memoize-one'
import { DOORPREFIX } from './SACollectionListViewDefinitionService'
import findIndex from 'lodash/findIndex'
import includes from 'lodash/includes'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'
import unionBy from 'lodash/unionBy'

const getSizeProductModalTableData = memoize(processedProduct => {
  const originalProductSizes = map(
    processedProduct.node?.Original?.Sizes,
    size => size?.Id
  )
  let headers = Object.keys(processedProduct).filter(
    key => key.startsWith(`${DOORPREFIX}-`) && processedProduct[key].isBooked
  )
  headers.unshift('')
  const rows = []
  for (let rowType in RowType) {
    switch (rowType) {
      case RowType.totalSizedQtyAnalytics: {
        const row = []
        headers.forEach((header, index) => {
          if (index === 1) {
            row.push({ value: RowTypeLabel.totalSizedQtyAnalytics })
          } else if (index === 0) {
            row.push({ value: '' })
          }
        })
        rows.push({ rowType, data: row })
        break
      }
      case RowType.targetQty: {
        const row = []
        headers.forEach((header, index) => {
          if (header) {
            row.push({
              value: processedProduct[header].targetQuantity,
              doorOrderProductId: processedProduct[headers[index]]?.id
            })
          } else {
            row.push({ value: RowTypeLabel[rowType].toUpperCase() })
          }
        })

        rows.push({ rowType, data: row })
        break
      }
      case RowType.totalSizedQty:
        let row = []
        headers.forEach(header => {
          if (header) {
            row.push({
              value: processedProduct[header].totalSizedQuantity
            })
          } else {
            row.push({ value: RowTypeLabel[rowType].toUpperCase() })
          }
        })

        rows.push({ rowType, data: row })
        break
      default:
        let sizesArray = map(headers, header => processedProduct?.[header]?.sizes || [])

        let sizes = sortBy(
          // @ts-ignore
          unionBy(...(sizesArray as Array<Array<any>>), 'Id'),
          'SizeCode'
        )
        ;(sizes || []).forEach(size => {
          let row = []
          const isSizeDeactivated = !includes(originalProductSizes, size.Id)
          headers.forEach((header, index) => {
            if (header) {
              let doorProductSizes = processedProduct?.[header]?.sizes ?? []
              const sizeIndex = findIndex(
                doorProductSizes,
                (orderProductSize: VIPSize) => orderProductSize.Id === size.Id
              )

              row.push({
                value:
                  sizeIndex > -1
                    ? processedProduct?.[header]?.qtyPerSize?.[sizeIndex]
                    : null,
                doorOrderProductId: processedProduct[headers[index]]?.id,
                sizeId: size?.Id,
                isDisabled: isSizeDeactivated,
                isSizeMissing: sizeIndex === -1
              })
            } else {
              row.push({ value: size?.Name ?? 'NA', isDisabled: isSizeDeactivated })
            }
          })

          rows.push({ rowType, data: row })
        })
        break
    }
  }
  return { rows, headers }
})

export { getSizeProductModalTableData }
