import Button from '@components/std-components/Button'
import Carousel, { ICarouselMedia } from '@components/std-components/Carousel'
import { ProductMediaType } from '@components/std-components/ProductMedia/SwitchMedia'
import { MP4 } from '@components/UI-Components/svg'
import Md3DRotation from '@meronex/icons/md/Md3DRotation'
import { BigPlaceholderImage } from '@resources/images'
import { strings } from '@stores'
import '@styles/ProductMediaCarousel/style.css'
import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import { ProductOperation, ProductOperationProps } from '../ProductOperation'

export interface IProductMediaCarousel extends ProductOperationProps {
  carouselMedia?: ICarouselMedia[]
  /**
   * Sets the iframe url to be used for 360 view
   */
  iFrameSrc?: string
  /**
   * Sets the video url to be used for video view
   */
  videoSrc?: string
  /**
   * Sets styles for the main media container
   */
  variant?: 'mid' | 'large' | 'small'
}

export const ProductMediaCarousel: React.FC<IProductMediaCarousel> = props => {
  const { carouselMedia, iFrameSrc, videoSrc, variant, active, ...productMediaProps } =
    props

  const [selectedMedia, setSelectedMedia] = useState(carouselMedia[0])
  const [view, setView] = useState(ProductMediaType.staticImage)

  useEffect(() => {
    setView(
      iFrameSrc && active ? ProductMediaType.threeSixty : ProductMediaType.staticImage
    )
  }, [iFrameSrc, active])

  useEffect(() => {
    setSelectedMedia(carouselMedia[0])
  }, [carouselMedia])

  const onCarouselCellClick = _selectedMedia => {
    setSelectedMedia(_selectedMedia)
    const _view = _selectedMedia?.isVideo
      ? ProductMediaType.video
      : ProductMediaType.staticImage
    setView(_view)
  }

  /**
   * if 3D url is present and 3D icon is clicked show 360 view with iframe
   * else show slider selected media
   * show video player if selected media is video, else show `ProductMedia`(img)
   * Carousel can be navigated, but cell selection
   * makes no difference when 3D view is on
   * Note: 3D icon is assumed to be the bottom left icon on `ProductMedia`
   * For inactive products, vertical slider can be navigated
   * however main media can't be interacted
   */
  return (
    <div className={'product-media-carousel'}>
      {carouselMedia?.length > 1 ? (
        <Carousel
          images={carouselMedia}
          className={'carousel-nav'}
          cellClassName={cn({
            'carousel-cell-3D-view': view === ProductMediaType.threeSixty
          })}
          onCellClick={onCarouselCellClick}
        />
      ) : null}
      <div
        className={cn('media-container', {
          [`media-container-${variant}`]: variant,
          [`singleMedia-${variant}`]: carouselMedia?.length <= 1
        })}
      >
        {(() => {
          switch (view) {
            case ProductMediaType.staticImage:
              return (
                <>
                  <ProductOperation
                    active={active}
                    media={{
                      src: selectedMedia?.src || BigPlaceholderImage,
                      alt: selectedMedia?.src,
                      className: cn('media-container-main', {
                        [`media-container-main-${variant}`]: variant
                      })
                    }}
                    {...productMediaProps}
                    bottomLeftIcon={
                      iFrameSrc ? (
                        <Button
                          variant='icon'
                          theme='light'
                          onClick={() => setView(ProductMediaType.threeSixty)}
                        >
                          <Md3DRotation size={24} />
                        </Button>
                      ) : (
                        productMediaProps?.bottomLeftIcon
                      )
                    }
                    bottomVideoIcon={
                      videoSrc ? (
                        <Button
                          variant='icon'
                          theme='light'
                          onClick={() => setView(ProductMediaType.video)}
                        >
                          <img src={MP4} alt='' />
                        </Button>
                      ) : (
                        productMediaProps?.bottomVideoIcon
                      )
                    }
                  />
                </>
              )
            case ProductMediaType.threeSixty:
              return (
                <>
                  <ProductOperation
                    active={active}
                    type={ProductMediaType.threeSixty}
                    media={{
                      src: iFrameSrc,
                      className: cn('media-container-main', {
                        [`media-container-main-${variant}`]: variant
                      })
                    }}
                    {...productMediaProps}
                  />
                  <div className={'media-container-close-IFrame'}>
                    <Button
                      variant={'primary'}
                      theme={'light'}
                      onClick={() => setView(ProductMediaType.staticImage)}
                    >
                      {strings.closeIFrame}
                    </Button>
                  </div>
                </>
              )
            case ProductMediaType.video:
              return (
                <>
                  <ProductOperation
                    active={active}
                    type={ProductMediaType.video}
                    media={{
                      controls: true,
                      url: selectedMedia?.videoUrl || videoSrc,
                      width: '100%',
                      height: '100%',
                      className: cn('media-container-main', {
                        [`media-container-main-${variant}`]: variant
                      })
                    }}
                  />
                  <div className={'media-container-close-IFrame'}>
                    <Button
                      variant={'primary'}
                      theme={'light'}
                      onClick={() => setView(ProductMediaType.staticImage)}
                    >
                      {strings.closeIFrame}
                    </Button>
                  </div>
                </>
              )
            default:
              return null
          }
        })()}
      </div>
    </div>
  )
}

ProductMediaCarousel.defaultProps = {
  variant: 'mid',
  active: true
}
