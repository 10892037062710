import { MuiThemeProvider } from '@material-ui/core/styles'
import { apolloClient } from '@stores'
import React, { ReactElement } from 'react'
import { Router } from 'react-router-dom'
import { siteHistory } from '../index'
import { stores } from '@stores'
import ReactDOM from 'react-dom'
import { MainTheme } from '@components/3rd-party/themes'
import { Provider } from 'mobx-react'
import { ApolloProvider } from '@apollo/client/react'
import ReactDOMServer from 'react-dom/server'

export const renderReactIntoGridCell = function <T>({
  c,
  cell,
  mode = 'static',
  nodeDicts,
  key
}: {
  key?: string
  c: ReactElement<T>
  cell: HTMLElement
  mode?: 'static' | 'dynamic' | string
  nodeDicts?: Map<string, HTMLDivElement>
}) {
  let reactCellDiv: HTMLDivElement

  // Remove inline editor
  if (cell.childNodes.length === 1) {
    const item = cell.childNodes[0] as HTMLElement // Can also be text after editing
    if (!item.classList || item.classList.contains('wj-grid-editor')) {
      item.remove()
    }
  }

  if (key && nodeDicts && nodeDicts.has(key)) {
    const existingNode = nodeDicts.get(key)
    if (cell.hasChildNodes()) {
      ReactDOM.unmountComponentAtNode(existingNode)
      nodeDicts.delete(key)
    } else {
      cell.appendChild(existingNode)
      return
    }
  }

  if (cell.childNodes.length === 0) {
    reactCellDiv = document.createElement('div') as HTMLDivElement
    cell.appendChild(reactCellDiv)
    reactCellDiv.classList.add('react-cell-container')
  } else {
    reactCellDiv = cell.childNodes[0] as HTMLDivElement
  }

  //mode == 'dynamic' && console.log(key)

  const finalTree = (
    <ApolloProvider key={key} client={apolloClient}>
      <MuiThemeProvider theme={MainTheme}>
        <Provider {...stores}>
          <Router key={key} history={siteHistory}>
            {c}
          </Router>
        </Provider>
      </MuiThemeProvider>
    </ApolloProvider>
  )

  if (mode === 'static') {
    reactCellDiv.innerHTML = ReactDOMServer.renderToStaticMarkup(finalTree)
  } else {
    ReactDOM.render(finalTree, reactCellDiv)
    if (key) {
      if (nodeDicts) {
        nodeDicts.set(key, reactCellDiv)
      } else {
        console.warn(
          `You are rendering a dynamic react tree into a wijmo cell but not providing a dictionary to track the cell for later disposal.  You must provide a dictionary in addition to a key (${key}).  Failure to do this will lead to memory leaks!`
        )
      }
    } else {
      console.warn(
        `You are rendering a dynamic react tree into a wijmo cell but not providing a key to track the tree later for cleanup.  This will lead to memory leaks!`
      )
    }
    //reactCellRemovedObserver.observe(cell, {childList: true})
  }
}

// Can't do this as the wijmo grid unpredictably removes cells and reuses them during scroll
// const reactCellRemovedObserver = new MutationObserver(e => {
//   e.forEach(n => {
//     n.removedNodes.forEach(node => {
//       try {
//         if (node && node.parentNode) {
//          // ReactDOM.unmountComponentAtNode(node as HTMLElement)
//         }
//       } catch (err) {
//         console.error(err)
//       }
//     })
//   })
// })
