import React from 'react'
import styled from 'styled-components'
import { StickyTree } from 'react-virtualized-sticky-tree'
import { AutoSizer } from 'react-virtualized'
import { StandardTreeViewStore } from '../Store/TreeViewStore'
import scrollbarSize from 'dom-helpers/scrollbarSize'
export const BASEZINDEX = 30

interface TreeNode {
  key?: string
  name: string
  type?: string
  children?: Array<string>
  depth: number
  height?: number
}

export interface TreeTable {
  [key: string]: TreeNode
}

export interface RowStyle {
  id: string
  height: number
  isSticky?: boolean
  stickTop?: number
  backGroundColor?: string
  zIndex?: number
}

export interface RootInfo {
  renderRoot: boolean
  root: RowStyle
}

export interface VirtualizedTreeTableProps {
  tableWidth?: number
  tableData: TreeTable
  rootInfo: RootInfo
  renderRoot?: boolean
  fillToAvailableWidth?: boolean
  stickyRowHeight: number
  leafRowHeight: Function
  treeRowRender: Function
  overScanCount: number
  containerStyle?: Object
  scrollPluginHandler?: Function
}

/**
 * Sticky tree lib don't have any provision to configure row
 * width. Its always been set as 100%. Below style component
 * overrides the inline style from lib
 */
interface TreeContainerProps {
  containerWidth: number
  containerHeight: number
  rowWidth: number
}
const TreeContainer = styled.div<TreeContainerProps>(props => ({
  width: props.containerWidth,
  height: props.containerHeight,
  '& div.rv-sticky-parent-node': {
    width: props.rowWidth ? `${props.rowWidth}px !important` : undefined
  }
}))

TreeContainer.displayName = 'TreeContainer'

export const VirtualizedTreeTable: React.FunctionComponent<VirtualizedTreeTableProps> =
  props => {
    const store = StandardTreeViewStore
    const {
      treeRowRender,
      fillToAvailableWidth,
      rootInfo,
      overScanCount,
      stickyRowHeight,
      leafRowHeight,
      tableWidth,
      scrollPluginHandler
    } = props

    const listRef: any = stickyTree => {
      if (stickyTree !== null) {
        store.setStickyTableRef(stickyTree, scrollPluginHandler)
      }
    }

    /**
     * getStickTopHeight
     * Calculates sticky top for individual
     * row. Calculation changes slightly when
     * root row is absent.
     * @param nodeDepth
     */
    const getStickTopHeight = (nodeDepth: number) => {
      let topHeight = nodeDepth * stickyRowHeight
      if (rootInfo.renderRoot && rootInfo.root.height !== stickyRowHeight) {
        topHeight = rootInfo.root.height + (nodeDepth - 1) * stickyRowHeight
      } else if (!rootInfo.renderRoot) {
        topHeight = (nodeDepth - 1) * stickyRowHeight
      }
      return topHeight
    }

    /**
     * getChildren
     * Returns table item as required by StickyTree lib
     * @param id
     * @param availableWidth
     */
    const getChildren = (id: string, availableWidth: number) => {
      const node = store.inputTableData[id]
      if (node && node.children) {
        return node.children.map((childId: string) => {
          const childNode = store.inputTableData[childId]
          if (!childNode) return { height: 0 }
          let nodeHeight = 0
          if (childNode.children) {
            nodeHeight = stickyRowHeight
          } else {
            nodeHeight = leafRowHeight(childNode, availableWidth)
          }
          return {
            key: childId,
            id: childId,
            height: nodeHeight,
            isSticky: childNode.children ? true : false,
            stickyTop: getStickTopHeight(childNode.depth),
            zIndex: childNode.children ? BASEZINDEX - childNode.depth : 0
          }
        })
      }
    }

    return (
      store.inputTableData && (
        <AutoSizer>
          {({ height, width }) => {
            if (width <= 0 || height <= 0) return <></>

            /**
             * Evaluate width of container ignoring scroll bar. This width will be passed to functions calculating
             * height of virtualized list item with dynamic number of products
             */
            const widthWithoutScrollbar = width - scrollbarSize()
            const rowRender = params => {
              return treeRowRender({ ...params, availableWidth: widthWithoutScrollbar })
            }

            const getTreeChildren = id => {
              return getChildren(id, widthWithoutScrollbar)
            }

            const treeTableWidth = fillToAvailableWidth
              ? widthWithoutScrollbar > tableWidth
                ? widthWithoutScrollbar
                : tableWidth
              : tableWidth
            return (
              <TreeContainer
                rowWidth={treeTableWidth as number}
                containerWidth={width}
                containerHeight={height}
              >
                <StickyTree
                  ref={listRef}
                  root={rootInfo.root as any}
                  width={width}
                  height={height}
                  onScroll={store.onScroll}
                  getChildren={getTreeChildren as any}
                  rowRenderer={rowRender}
                  renderRoot={rootInfo.renderRoot}
                  overscanRowCount={overScanCount}
                />
              </TreeContainer>
            )
          }}
        </AutoSizer>
      )
    )
  }
