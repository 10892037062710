/*
 * @Author: Raeesaa Metkari
 * @Date: 2020-08-21 17:58:10
 * @Last Modified by: Raeesaa Metkari
 * @Last Modified time: 2020-08-22 01:45:36
 */
import { SelectFileButton } from '@components/UI-Components/SelectFileButton'
import {
  PopupSize,
  StandardPopup
} from '@components/UI-Components/StandardPopup/StandardPopup'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import * as css from './FileUploadDialog.css'

interface MyProps {
  uploadInProgress: boolean
  title?: string
  uploadButtonLabel?: string
  fileMimeType: string
  uploadError?: string
  handleChange: (file: any) => void
  onClickSave: (e?: any) => void
  onClickClose: (e?: any) => void
  fileUploadSummary: any
  nextButtonLabel?: string
  cancelButtonLabel?: string
}

export const FileUploadDialog: React.FunctionComponent<MyProps> = (props: MyProps) => {
  const {
    uploadInProgress,
    onClickSave,
    handleChange,
    onClickClose,
    title,
    uploadButtonLabel,
    fileMimeType,
    uploadError,
    fileUploadSummary,
    nextButtonLabel,
    cancelButtonLabel
  } = props
  return (
    <div className={css.root}>
      <StandardPopup
        cancelHandler={onClickClose}
        saveHandler={onClickSave}
        maxWidth={PopupSize.large}
        nextButtonLabel={nextButtonLabel || 'Upload'}
        popupTitle={title || 'Upload File'}
        isSaveInProgress={uploadInProgress}
        cancelButtonLabel={cancelButtonLabel}
      >
        <div className={css.fileUploadWrapper}>
          {fileUploadSummary ? (
            <div dangerouslySetInnerHTML={{ __html: fileUploadSummary }}></div>
          ) : (
            <>
              <SelectFileButton
                accept={`${fileMimeType}`}
                label={`${uploadButtonLabel || 'Select File (*.pdf)'}`}
                handleChange={file => handleChange(file)}
                disabled={uploadInProgress}
                loading={uploadInProgress}
              />

              {uploadError ? (
                <Typography className={css.error}>{uploadError}</Typography>
              ) : null}
            </>
          )}
        </div>
      </StandardPopup>
    </div>
  )
}
