import { ApolloClient } from '@apollo/client/core/ApolloClient'
import { withApollo } from '@apollo/client/react/hoc/withApollo'
import { config } from '@config'
import { uploadFile } from '@services/uploadFileService'
import { apolloClient, stores } from '@stores'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import React from 'react'
import { StoreImportDialog } from '../component/StoreImportDialog'
import { GET_STORES } from '../graphql'

interface MyProps {
  client?: ApolloClient<any>
}

@(withApollo as any)
@observer
export class ImportStoreData extends React.Component<MyProps> {
  @observable storeDataSheet = ''
  @observable storeDataSheetUploadProgress
  @observable storeDataSheetUploaded = false
  @observable storeDataSheetUploadError = ''
  @observable uploadInProgress = false
  @observable uploadDone = false

  handleChanges = (file, field) => {
    this[field] = file
  }

  updateProgress = (field, progress) => {
    this[`${field}UploadProgress`] = progress
  }

  handleSubmit = async () => {
    const [
      { storeDataSheetUploaded },
      {
        storeImport: { toggleDialogOpen }
      }
    ] = [this, stores]

    this.uploadInProgress = true
    this.uploadDone = false
    this.storeDataSheetUploadError = ''

    try {
      if (!storeDataSheetUploaded && this['storeDataSheet']) {
        this.storeDataSheetUploadProgress = true
        await this.importData(
          'storeDataSheet',
          `${config.dataImporter}stores/import`,
          'stores'
        )
        this.storeDataSheetUploadProgress = false
        // TODO: Need to move this out of from here
        await apolloClient.query({
          query: GET_STORES,
          fetchPolicy: 'network-only'
        })
      }

      this.uploadInProgress = false
      this.uploadDone = true

      toggleDialogOpen()
    } catch (err) {
      this.uploadInProgress = false
    }
  }

  importData = async (field, url, key) => {
    try {
      await uploadFile(url, field, key, this[field], this.updateProgress)
      this.updateProgress(field, null)
      this[`${field}Uploaded`] = true
    } catch (err) {
      console.error(err)
      this[`${field}UploadError`] = err.message
      this.updateProgress(field, null)
      throw err
    }
  }

  render() {
    return <StoreImportDialog {...this} {...this.props} />
  }
}
