import { FilterCollapse } from '@components/UI-Components/Collection/FilterCollapse'
import {
  BUY_QUANTITY_FILTER,
  CORE_BASIC_FILTERS,
  FLAG_FILTER,
  PRODUCT_FILTERS
} from '@modules/collection/constants'
import Checkbox from '@material-ui/core/Checkbox'
import Divider from '@material-ui/core/Divider'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { IPermissions } from '@routes'
import { stores, strings } from '@stores'
import cloneDeep from 'lodash/cloneDeep'
import some from 'lodash/some'
import memoize from 'memoize-one'
import { observer } from 'mobx-react'
import React from 'react'

interface IRenderList {
  filters: any
  classes?: any
  onFilterCheck?: (string, e) => any
  permissions?: IPermissions
}

const styles = theme => ({
  productList: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  divider: {
    marginTop: 20,
    marginBottom: 24
  },
  checkboxContainer: {
    width: 230,
    paddingRight: 8
  },
  label: {
    marginRight: 8
  },
  individualFilterSectionContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  }
})

const evaluateFiltersToBeDisplayed = memoize(
  ({ assortment, assortmentType, clusterId, drawerOpen, buyingSession }) => {
    const {
      sidebar: { isRegionalMerchandiser }
    } = stores
    let basicFilters = cloneDeep(CORE_BASIC_FILTERS)
    /**
     * Show counter offer flag filter only in store / zone and assortment and activity collection view
     */
    if (
      (assortment &&
        assortmentType !== strings.zone &&
        assortmentType !== strings.store) || // Check if assortment is other than zone and store
      (assortment && drawerOpen) || // In case we are in assortment view, check if drawer is open
      (!assortment && (!buyingSession || isRegionalMerchandiser())) // In case of collection view, check if buying session is not set or user is RM
    ) {
      const index = basicFilters.findIndex(
        ({ definition: { name } }) => name === FLAG_FILTER
      )
      if (index > -1) {
        basicFilters.splice(index, 1)
      }
    }

    /**
     * Do not show qty filter in case of CM / RM cluster assortment and add product library sidebar
     */
    if (
      (assortment &&
        (assortmentType === strings.cluster ||
          (assortmentType === strings.zone && clusterId))) ||
      (assortment && drawerOpen) // In case we are in assortment view, check if drawer is open
    ) {
      const index = basicFilters.findIndex(
        ({ definition: { name } }) => name === BUY_QUANTITY_FILTER
      )
      if (index > -1) {
        basicFilters.splice(index, 1)
      }
    }

    return basicFilters
  }
)

@((withStyles as any)(styles))
@observer
export class RenderList extends React.Component<IRenderList> {
  render() {
    const [
      {
        filters: [
          productFilters,
          lookFilter,
          buyingSessionProductFilter,
          clusterStatusFilter
        ],
        classes,
        permissions
      },
      {
        filter: { onFilterCheck, checkedFilters },
        nav: {
          location: { pathname },
          params: { assortmentType, assortment, buyingSession, clusterId }
        },
        assortment: { drawerOpen }
      }
    ] = [this.props, stores]

    let basicFilters = evaluateFiltersToBeDisplayed({
      assortment,
      assortmentType,
      clusterId,
      drawerOpen,
      buyingSession
    })

    return (
      <>
        {!pathname.includes('lookbooks') ? (
          <div className={classes.individualFilterSectionContainer}>
            {basicFilters.map((filterType, index) => {
              const {
                definition: {
                  name,
                  displayName: { singular }
                },
                values
              } = filterType
              return (
                <div key={`filter-${index}`} className={classes.checkboxContainer}>
                  <Typography variant='subtitle2'>{singular}</Typography>
                  <div className={classes.productList}>
                    {values.map((filter, i) => (
                      <FormGroup className={classes.productText} key={`checkbox-${i}`}>
                        <FormControlLabel
                          classes={{ root: classes.label }}
                          control={
                            <Checkbox
                              disabled={
                                singular === 'Activity Filter' && !permissions.canEdit
                              }
                              onClick={e => onFilterCheck(name, filter, e)}
                              checked={some(checkedFilters[name], {
                                value: filter.value
                              })}
                              color='default'
                            />
                          }
                          label={filter.label}
                        />
                      </FormGroup>
                    ))}
                  </div>
                  <Divider className={classes.divider} />
                </div>
              )
            })}
          </div>
        ) : null}
        {/* Buying Session Product Filters */}
        <div className={classes.individualFilterSectionContainer}>
          {buyingSessionProductFilter &&
            buyingSessionProductFilter.map((filterType, index) => {
              const {
                definition: {
                  name,
                  displayName: { singular }
                },
                values
              } = filterType
              return values && values.length ? (
                <div key={`filter-${index}`} className={classes.checkboxContainer}>
                  <Typography variant='subtitle2'>{singular}</Typography>
                  {values.length ? (
                    <div key={`filter-${index}`} className={classes.checkboxContainer}>
                      <FilterCollapse panelName={singular}>
                        <div className={classes.productList}>
                          {values.map((filter, i) => (
                            <FormGroup
                              className={classes.productText}
                              key={`checkbox-${i}`}
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onClick={e => onFilterCheck(name, filter, e)}
                                    checked={some(checkedFilters[name], {
                                      value: filter.value
                                    })}
                                    color='default'
                                  />
                                }
                                label={filter.label}
                              />
                            </FormGroup>
                          ))}
                        </div>
                        <Divider className={classes.divider} />
                      </FilterCollapse>
                    </div>
                  ) : null}
                  <Divider className={classes.divider} />
                </div>
              ) : null
            })}
          {/* Product cluster status Filters */}
          {clusterStatusFilter &&
            clusterStatusFilter.map((filterType, index) => {
              const {
                definition: {
                  name,
                  displayName: { singular }
                },
                values
              } = filterType
              return values && values.length ? (
                <div key={`filter-${index}`} className={classes.checkboxContainer}>
                  <Typography variant='subtitle2'>{singular}</Typography>
                  {values.length ? (
                    <div key={`filter-${index}`} className={classes.checkboxContainer}>
                      <FilterCollapse panelName={singular}>
                        <div className={classes.productList}>
                          {values.map((filter, i) => (
                            <FormGroup
                              className={classes.productText}
                              key={`checkbox-${i}`}
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onClick={e => onFilterCheck(name, filter, e)}
                                    checked={some(checkedFilters[name], {
                                      value: filter.value
                                    })}
                                    color='default'
                                  />
                                }
                                label={filter.label}
                              />
                            </FormGroup>
                          ))}
                        </div>
                        <Divider className={classes.divider} />
                      </FilterCollapse>
                    </div>
                  ) : null}
                  <Divider className={classes.divider} />
                </div>
              ) : null
            })}
        </div>
        {/* Looks Filter */}
        <div className={classes.individualFilterSectionContainer}>
          {lookFilter &&
            lookFilter.map((filterType, index) => {
              const {
                definition: {
                  name,
                  displayName: { singular }
                },
                values
              } = filterType
              return values && values.length ? (
                <div key={`filter-${index}`} className={classes.checkboxContainer}>
                  <Typography variant='subtitle2'>
                    {singular === strings.lookName ? strings.lookFilter : singular}
                  </Typography>
                  {values.length ? (
                    <div key={`filter-${index}`} className={classes.checkboxContainer}>
                      <FilterCollapse panelName={singular}>
                        <div className={classes.productList}>
                          {values.map((filter, i) => (
                            <FormGroup
                              className={classes.productText}
                              key={`checkbox-${i}`}
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onClick={e => onFilterCheck(name, filter, e)}
                                    checked={some(checkedFilters[name], {
                                      value: filter.value
                                    })}
                                    color='default'
                                  />
                                }
                                label={filter.label}
                              />
                            </FormGroup>
                          ))}
                        </div>
                        <Divider className={classes.divider} />
                      </FilterCollapse>
                    </div>
                  ) : null}
                  <Divider className={classes.divider} />
                </div>
              ) : null
            })}
        </div>
        <div style={{ marginBottom: 8 }}>
          <Typography variant='subtitle2' className={classes.heading}>
            {PRODUCT_FILTERS}
          </Typography>
        </div>
        <div className={classes.individualFilterSectionContainer}>
          {productFilters &&
            productFilters.map((filterType, index) => {
              const {
                definition: {
                  name,
                  displayName: { singular }
                },
                values
              } = filterType
              return values.length ? (
                <div key={`filter-${index}`} className={classes.checkboxContainer}>
                  <FilterCollapse panelName={singular}>
                    <div className={classes.productList}>
                      {values.map((filter, i) => (
                        <FormGroup className={classes.productText} key={`checkbox-${i}`}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled={
                                  singular === 'Activity Filter' && !permissions.canEdit
                                }
                                onClick={e => onFilterCheck(name, filter, e)}
                                checked={some(checkedFilters[name], {
                                  value: filter.value
                                })}
                                color='default'
                              />
                            }
                            label={filter.label}
                          />
                        </FormGroup>
                      ))}
                    </div>
                    <Divider className={classes.divider} />
                  </FilterCollapse>
                </div>
              ) : null
            })}
        </div>
      </>
    )
  }
}
