import { ProductInfoItem } from '@components/UI-Components/ProductCardOld'
import classNames from 'classnames'
import React from 'react'
import styled from 'styled-components'
import { ProductCardChip } from './ProductCardChip'
import css from './ProductInfoItemWithColorBox.css'

interface IProductInfoItemWithColorBoxProps {
  color?: string
  content?: any
  title?: string
  className?: any
  otherProps?: any
  isChip?: boolean
  onDelete?: any
  onClick?: any
  node?: any
}

const ColorBox = styled.div(props => {
  const { color } = props
  return {
    width: 20,
    height: 20,
    borderRadius: 2,
    marginRight: 7,
    marginBottom: 6,
    backgroundColor: color
  }
})

export const ProductInfoItemWithColorBox: React.FunctionComponent<IProductInfoItemWithColorBoxProps> =
  ({
    color,
    content,
    title,
    className,
    isChip,
    onDelete,
    node,
    onClick,
    ...otherProps
  }) => {
    return (
      <div className={css.container}>
        <ProductInfoItem
          className={css.productInfoItem}
          title={title}
          content={
            isChip ? (
              <ProductCardChip
                content={content}
                onDelete={onDelete}
                node={node}
                onClick={onClick}
              />
            ) : (
              content || 'NA'
            )
          }
        />
        {color ? (
          <ColorBox className={classNames(className)} color={color} {...otherProps} />
        ) : null}
      </div>
    )
  }
