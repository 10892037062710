import { ListViewColumnDefinition } from '@components/UI-Components/StandardTableView/models/ListviewColumnDefinition'
import {
  ColumnWidth,
  ListViewFieldMapping,
  ListViewFontWeights,
  SAKPIViewColumnIdntifiers,
  SAKPIViewColumnLabels
} from '@modules/common'
import formattedNumber from '@utils/formatted-number'

class SAKPIViewDefinitionService {
  buildColumnDefinition = (useSizeQty = false, isSAView = true) => {
    let mixQtyKey
    let mixValKey
    let totalQtyKey
    let valueKey

    if (useSizeQty) {
      mixQtyKey = ListViewFieldMapping.mixRatioBySizedQty
      mixValKey = ListViewFieldMapping.mixRatioBySizedValue
      totalQtyKey = ListViewFieldMapping.totalSizedQuantity
      valueKey = ListViewFieldMapping.totalSizedValueWithCurrency
    } else {
      mixQtyKey = ListViewFieldMapping.mixRatioByTargetQty
      mixValKey = ListViewFieldMapping.mixRatioByTargetValue
      totalQtyKey = ListViewFieldMapping.totalDraftQuantity
      valueKey = ListViewFieldMapping.totalDraftValueWithCurrency
    }

    let listViewDefinition = [
      new ListViewColumnDefinition({
        id: SAKPIViewColumnIdntifiers.ProductInfo,
        header: {
          label: SAKPIViewColumnIdntifiers.ProductInfo
        },
        width: isSAView ? ColumnWidth.OrderProductInfo : ColumnWidth.StandardProductInfo,
        isFixed: true,
        isRootColumn: true,
        category: null,
        product: null
      }),
      new ListViewColumnDefinition({
        id: SAKPIViewColumnIdntifiers.ActualMixTargetMixQty,
        header: {
          label: SAKPIViewColumnLabels.ActualMixTargetMixQty
        },
        props: {
          fontWeight: ListViewFontWeights.Bold
        },
        isFixed: true,
        width: ColumnWidth.Large,
        category: {
          key: mixQtyKey,
          showKPI: true
        },
        product: null
      }),
      new ListViewColumnDefinition({
        id: SAKPIViewColumnIdntifiers.ActualMixTargetMixValue,
        header: {
          label: SAKPIViewColumnLabels.ActualMixTargetMixVal
        },
        props: {
          fontWeight: ListViewFontWeights.Bold
        },
        isFixed: true,
        width: ColumnWidth.Large,
        category: {
          key: mixValKey,
          showKPI: true
        },
        product: null
      }),
      new ListViewColumnDefinition({
        id: SAKPIViewColumnIdntifiers.Quantity,
        header: {
          label: SAKPIViewColumnLabels.Quantity
        },
        props: {
          fontWeight: ListViewFontWeights.Bold
        },
        isFixed: true,
        width: ColumnWidth.Medium,
        category: {
          key: totalQtyKey,
          showKPI: true
        },
        product: null,
        valueFormatter: val => formattedNumber(val)
      }),
      new ListViewColumnDefinition({
        id: SAKPIViewColumnIdntifiers.Value,
        header: {
          label: SAKPIViewColumnLabels.Value
        },
        props: {
          fontWeight: ListViewFontWeights.Bold
        },
        isFixed: true,
        width: ColumnWidth.Medium,
        category: {
          key: valueKey,
          showKPI: true
        },
        product: null,
        valueFormatter: val => {
          const [curr, intVal] = val.split(' ')
          return `${curr} ${formattedNumber(intVal)}`
        }
      })
    ]
    return listViewDefinition
  }
}

export const saKPIViewDefinitionService = new SAKPIViewDefinitionService()
