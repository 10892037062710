import { withApollo } from '@apollo/client/react/hoc/withApollo'
import { StyledProps } from '@components/3rd-party/mui'
import { AssortmentToolbar } from '@components/UI-Components/Assortment/AssortmentToolbar'
import { AssortmentReportingModal } from '@components/UI-Components/AssortmentReportingModal'
import { FilterChips } from '@components/UI-Components/FilterChips'
import { SecondaryButton } from '@components/UI-Components/SecondaryButton'
import { KeyboardArrowDown } from '@icons'
import { User } from '@models'
import { ProductGridContainer } from '@modules/collection/components/ProductGridContainer'
import { ProductListContainer } from '@modules/collection/components/ProductListContainer'
import { ListViewAssortmentTypes } from '@modules/common/models/enums/ListViewColumnIdentifiers'
import { UserRoles } from '@modules/common/models/enums/UserRoles'
import { ViewTabs } from '@modules/common/models/enums/Views'
import { ZoneDisplayNames } from '@modules/common/models/enums/ZoneDisplayNames'
import { ADD_PRODUCT_CLUSTER_STATUS } from '@modules/retail/assortment/graphql/addProductClusterStatus'
import { CLUSTER_ASSOCIATIONS_BY_ZONE_ASSORTMENT } from '@modules/retail/assortment/graphql/clustersByZoneAssortment'
import { STORE_ASSORTMENT_PRODUCTS_CONNECTION } from '@modules/retail/assortment/graphql/storeAssortmentProductsConnection'
import { ZONE_ASSORTMENT_PRODUCTS_CONNECTION } from '@modules/retail/assortment/graphql/zoneAssortmentProductsConnection'
import { ZONE_ASSORTMENT_PRODUCT_WITH_STORES } from '@modules/retail/assortment/graphql/zoneAssortmentProductsWithStores'
import { AssortmentSplitTable } from '@modules/retail/collection/component'
import { ProductDetailContainer } from '@modules/retail/productDetail/container'
import Fab from '@material-ui/core/Fab'
import Popover from '@material-ui/core/Popover'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { getHighestUser, IPermissions } from '@routes'
import { resetFilters } from '@services/assortmentService'
import { getFilterChipsData } from '@services/collectionServices'
import { getViewTabIndexMap } from '@services/commonServices'
import { storage } from '@services/storageService'
import { stores, strings } from '@stores'
import cloneDeep from 'lodash/cloneDeep'
import some from 'lodash/some'
import { observer } from 'mobx-react'
import React from 'react'
import { Route, withRouter } from 'react-router-dom'
import { ViewTabsMap } from 'src/constant/ViewTabs'
import styled from 'styled-components'

import { AddAssortmentProductsDrawer } from './AddAssortmentProduct'
import { AssortmentTitleBar } from './AssortmentTitleBar'
import { CMApprovalDialog } from './CMApprovalDialog'
import { DeleteAssortmentProductDialog } from './DeleteAssortmentProduct'
import { DeleteProductConformationOnLastRMClusterDelete } from './DeleteProductConformationOnLastRMClusterDelete'
import { RMApprovalDialog } from './RMApprovalDialog'

const HEIGHT = 170

const Root = styled.div`
  background-color: var(--background-paper);
  flex: 1;
  overflow-x: auto;
  position: relative;
  display: flex;
  flex-direction: column;
`

const AssortmentContainer = styled.div({
  margin: '0px 20px 10px 20px',
  overflow: 'hidden',
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column'
})

const styles = theme => ({
  productCard: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  productContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'auto',
    height: `calc(100vh - ${HEIGHT}px)`,
    margin: '18px 24px',
    marginRight: 0
  },
  selectfield: {
    padding: 5,
    height: 40
  },
  tableHead: {
    backgroundColor: theme.colors.navBackground
  },
  productCell: {
    width: 435,
    padding: `16px 22px`,
    borderRight: '1px solid #e1e1e1'
  },
  table: {
    margin: `0 ${theme.spacing(2)} 0 ${theme.spacing(1)}`
  },
  wrapper: {
    display: 'flex'
  },
  filterChips: {
    display: 'flex',
    marginLeft: 32,
    alignItems: 'baseline'
    //borderTop: '1px solid lightgrey'
  },
  toolBar: {
    marginLeft: 25,
    marginBottom: 8
  },
  fab: {
    zIndex: 4000,
    position: 'fixed',
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    margin: theme.spacing(3)
  }
})

interface IAssortment extends StyledProps {
  assortment: any
  assortmentProducts?: Array<any>
  client?: any
  assortmentId: any
  buyingSessionId?: any
  permissions?: IPermissions
  match?: any
  title?: any
  betBuyData?: any
  zone?: any
  store?: any
  history?: any
  buyingSession?: any
  activityHierarchy?: any
  rangePlanData?: any
  isCategoryDataLoading?: boolean
  totalProducts?: number
}

@((withStyles as any)(styles))
@(withApollo as any)
@(withRouter as any)
@observer
export class AssortmentComponent extends React.Component<IAssortment> {
  state = {
    productList: [],
    drawerOpen: false,
    showApprovalPopupAnchorEl: null,
    reviewOrRequestSent: false,
    attributeHierarchy: []
  }

  showApprovalPopup = event => {
    this.setState({ showApprovalPopupAnchorEl: event.currentTarget })
  }

  handleApprovalPopupClose = () => {
    this.setState({ showApprovalPopupAnchorEl: null })
  }

  handleApprovalPopupSubmit = () => {
    this.setState({ showApprovalPopupAnchorEl: null, reviewOrRequestSent: true })
  }

  addProductStatus = async (id, statusId) => {
    const {
      props: {
        client,
        assortment: { cluster }
      }
    } = this
    await client.mutate({
      mutation: ADD_PRODUCT_CLUSTER_STATUS,
      variables: { clusterId: cluster.id, statusId: statusId, productId: id }
    })
  }

  bulkAddProductsToAssortment = async () => {
    const [
      {
        props: {
          match,
          match: {
            params: { clusterId }
          },
          client,
          assortmentId
        }
      },
      {
        product: {
          buildRefetchQueriesForAttributeHierarchy,
          executeAssortmentRefetchQueries,
          whereZoneAssortmentProductQueryForSplit
        },
        assortment: {
          getAssortmentType,
          assortmentTypeMapping,
          toogleDrawer,
          setBulkAdditionAPIFlag
        }
      }
    ] = [this, stores]
    const user = storage.getItem<User>('user')
    const isRegionalMerchandiser = some(user.roles, {
      name: UserRoles.REGIONAL_MERCHANDISER
    })
    const assortmentType = getAssortmentType(match)

    const refetchQueries = buildRefetchQueriesForAttributeHierarchy(
      this.state.attributeHierarchy,
      assortmentType
    )
    // Refetch store split data and assortment product connections in case of zone assortment
    if (assortmentType === strings.zone) {
      refetchQueries.push(
        {
          query: ZONE_ASSORTMENT_PRODUCT_WITH_STORES,
          variables: whereZoneAssortmentProductQueryForSplit()
        },
        {
          query: CLUSTER_ASSOCIATIONS_BY_ZONE_ASSORTMENT,
          variables: whereZoneAssortmentProductQueryForSplit('BUY', true)
        },
        {
          query: ZONE_ASSORTMENT_PRODUCTS_CONNECTION,
          variables: {
            assortmentId
          }
        }
      )
    }

    // Refetch assortment product connections in case of zone assortment
    if (assortmentType === strings.store) {
      refetchQueries.push({
        query: STORE_ASSORTMENT_PRODUCTS_CONNECTION,
        variables: {
          assortmentId
        }
      })
    }

    setBulkAdditionAPIFlag(true)

    await client.mutate({
      mutation:
        isRegionalMerchandiser && assortmentType === strings.zone && clusterId
          ? assortmentTypeMapping[assortmentType].bulkAddProductRMMutation
          : assortmentTypeMapping[assortmentType].bulkAddProductMutation,
      variables: {
        productIds: this.state.productList,
        assortmentId: isRegionalMerchandiser && clusterId ? clusterId : assortmentId
      },
      refetchQueries
    })

    setBulkAdditionAPIFlag(false)
    toogleDrawer()
    executeAssortmentRefetchQueries()

    this.setState({
      productList: []
    })
  }

  handleChange = (event, id, product) => {
    // const user = storage.getItem<User>('user')
    // const isRegionalMerchandiser = some(user.roles, {
    //   name: UserRoles.REGIONAL_MERCHANDISER
    // })
    // const {
    //   assortment: { selectedClusterId }
    // } = stores
    const productList = cloneDeep(this.state.productList)
    let attributeHierarchy = cloneDeep(this.state.attributeHierarchy)
    // const zoneAssortmentProductId = product.product.id;
    const productIdsToAddInAssortment =
      product.zoneAssortmentProducts && product.zoneAssortmentProducts.length
        ? product.zoneAssortmentProducts[0].id
        : id
    event.target.checked
      ? productList.push(productIdsToAddInAssortment)
      : productList.splice(
          productList.findIndex(({ id }) => id === productIdsToAddInAssortment),
          1
        )

    if (
      product.product &&
      product.product.attributes &&
      product.product.attributes.length
    ) {
      let activity = product.product.attributes.find(
        attribute => attribute.definition.name === 'activity'
      )
      const activityDescription = activity ? activity.strVal : ''
      let family = product.product.attributes.find(
        attribute => attribute.definition.name === 'family'
      )
      let line = product.product.attributes.find(
        attribute => attribute.definition.name === 'line'
      )
      // FIXME: RM - Pick attributes from .env variables
      let childrenMeta = ['family']
      let childrenMetaValues = [family ? family.strVal : '']

      if (activityDescription === 'SHOES') {
        childrenMeta = ['line']
        childrenMetaValues = [line ? line.strVal : '']
      } else if (activityDescription === 'LEATHER GOODS') {
        childrenMeta = ['family', 'line']
        childrenMetaValues = [family ? family.strVal : '', line ? line.strVal : '']
      }

      let object = {
        activity: { name: activity ? activity.strVal : '' },
        childrenMeta,
        childrenMetaValues
      }
      if (
        !attributeHierarchy.find(
          data => data.activity === activity && data.family === family
        )
      ) {
        attributeHierarchy.push(object)
      }
    }

    this.setState({
      productList: productList,
      attributeHierarchy
    })
  }

  componentDidUpdate = prevProps => {
    const {
      match: {
        params: { assortmentId: oldId }
      }
    } = prevProps

    const {
      match: {
        params: { assortmentId }
      }
    } = this.props

    if (oldId !== assortmentId) {
      resetFilters()
    }
  }

  componentDidMount() {
    const [
      {
        props: { assortmentId }
      },
      {
        assortment: {
          setSelectedAssortment,
          selectedAssortmentId,
          updateListViewColumnDefinitionForAssortmentType
        },
        nav: {
          params: { assortmentType }
        }
      }
    ] = [this, stores]

    if (assortmentType) {
      updateListViewColumnDefinitionForAssortmentType(
        assortmentType as ListViewAssortmentTypes
      )
    }

    if (assortmentId !== selectedAssortmentId) {
      setSelectedAssortment(assortmentId)
      resetFilters()
    }
  }

  render() {
    const [
      {
        props: {
          match,
          match: { path },
          activityHierarchy,
          assortmentId,
          classes,
          buyingSessionId,
          title,
          buyingSession,
          permissions,
          betBuyData,
          rangePlanData,
          zone,
          isCategoryDataLoading,
          totalProducts
        },
        state: { showApprovalPopupAnchorEl, productList, reviewOrRequestSent },
        handleChange,
        bulkAddProductsToAssortment,
        showApprovalPopup,
        handleApprovalPopupClose,
        handleApprovalPopupSubmit
      },
      {
        assortment: {
          toogleDrawer,
          drawerOpen,
          selectedClusterAssortment,
          getAssortmentType,
          selectedProductForDeletion,
          selectedProductIdForDeletion,
          onExcelExportClick,
          onViewClick,
          listViewColumnDefinitionMap,
          selectedAssortmentTab,
          reportingDrawerOpen,
          toggleReportingDrawer,
          confirmDeleteProductConfirmationOnLastRMClusterDeleteDialog,
          isOpenDeleteProductConfirmationOnLastRMClusterDeleteDialog,
          closeDeleteProductConfirmationOnLastRMClusterDeleteDialog,
          rmZoneAssortmentProductDeleteInProgress
        },
        filter: { finalFilterToApply, clearAllFilters },
        nav: {
          params: { view }
        }
      }
    ] = [this, stores]

    const loggedInUserRoles = getHighestUser()
    const isGMOrAdmin =
      loggedInUserRoles &&
      loggedInUserRoles.length &&
      loggedInUserRoles.find(
        role =>
          role.name === UserRoles.CENTRAL_MERCHANDISER_RETAIL ||
          role.name === UserRoles.ADMINISTRATOR
      )
    const approvalPopupOpen = Boolean(showApprovalPopupAnchorEl)
    const { chipsArrayToDisplay, chipsArrayInDropDown } =
      getFilterChipsData(finalFilterToApply)
    const assortmentType = getAssortmentType(match)
    const viewMap = ViewTabsMap[view] && ViewTabsMap[view]
    const tabIndexMap = getViewTabIndexMap(view, viewMap)

    const totalAssortmentProducts = totalProducts || 0

    return (
      <Root>
        <div>
          <AssortmentTitleBar
            title={
              title
                ? `${
                    selectedClusterAssortment &&
                    assortmentType !== strings.store &&
                    assortmentType !== strings.cluster
                      ? selectedClusterAssortment
                      : assortmentType === strings.zone
                      ? ZoneDisplayNames[title.name] || title.name
                      : title.name
                  } Assortment`
                : null
            }
            actions={
              <AssortmentToolbar
                onViewClick={onViewClick}
                onExcelExportClick={onExcelExportClick}
                toogleDrawer={toogleDrawer}
                permissions={permissions}
                buyingSessionStatus={buyingSession && buyingSession.status}
                showApprovalPopup={showApprovalPopup}
                enableReviewAssortmentLink={
                  totalAssortmentProducts && !reviewOrRequestSent
                }
                assortmentProductsPresent={totalAssortmentProducts > 0}
                reviewOrRequestSent={reviewOrRequestSent}
              />
            }
          />
        </div>

        {!drawerOpen && (
          <div className={classes.filterChips}>
            <FilterChips />
            {chipsArrayToDisplay.length || chipsArrayInDropDown.length ? (
              <SecondaryButton onClick={() => clearAllFilters()}>
                <Typography variant='body1'>CLEAR ALL</Typography>
              </SecondaryButton>
            ) : null}
          </div>
        )}
        <AssortmentContainer>
          {view === strings.list && selectedAssortmentTab === 3 ? (
            <AssortmentSplitTable buyingSession={buyingSession} />
          ) : view === strings.list || view === strings.kpi ? (
            <ProductListContainer
              hierarchyKPIs={activityHierarchy}
              isCategoryDataLoading={isCategoryDataLoading}
              assortment={this.props.assortment}
              buyingSession={this.props.buyingSession}
              colDef={listViewColumnDefinitionMap[selectedAssortmentTab]}
              betBuyData={betBuyData}
              rangePlans={rangePlanData}
              zone={zone}
              buyingSessionGroupName={''}
            />
          ) : (
            <ProductGridContainer
              hierarchyKPIs={activityHierarchy}
              assortment={this.props.assortment}
              buyingSession={this.props.buyingSession}
            />
          )}
        </AssortmentContainer>
        {/* Quick reporting */}
        {(selectedAssortmentTab === tabIndexMap[ViewTabs.BET] ||
          selectedAssortmentTab === tabIndexMap[ViewTabs.BUY] ||
          selectedAssortmentTab === tabIndexMap[ViewTabs.SPLIT]) &&
        !reportingDrawerOpen ? (
          <Fab size='small' className={classes.fab} onClick={toggleReportingDrawer}>
            <KeyboardArrowDown />
          </Fab>
        ) : null}

        {(selectedAssortmentTab === tabIndexMap[ViewTabs.BET] ||
          selectedAssortmentTab === tabIndexMap[ViewTabs.BUY] ||
          selectedAssortmentTab === tabIndexMap[ViewTabs.SPLIT]) &&
        reportingDrawerOpen ? (
          <AssortmentReportingModal
            buyingSession={buyingSession}
            zone={this.props.zone}
            store={this.props.store}
            betBuyData={this.props.betBuyData}
            assortmentType={assortmentType}
            open={reportingDrawerOpen}
            onClose={toggleReportingDrawer}
            selectedTab={selectedAssortmentTab}
          />
        ) : null}
        {/* Delete Product Confirmation */}
        <DeleteAssortmentProductDialog
          assortmentId={assortmentId}
          assortmentProductId={selectedProductIdForDeletion}
          product={selectedProductForDeletion}
        />
        {/* Delete Product Confirmation on Last RM Cluster Delete */}
        <DeleteProductConformationOnLastRMClusterDelete
          onDelete={confirmDeleteProductConfirmationOnLastRMClusterDeleteDialog}
          openDialog={isOpenDeleteProductConfirmationOnLastRMClusterDeleteDialog}
          onClose={closeDeleteProductConfirmationOnLastRMClusterDeleteDialog}
          loading={rmZoneAssortmentProductDeleteInProgress}
        />
        {/* Add product to assortment modal */}
        {drawerOpen && (
          <AddAssortmentProductsDrawer
            buyingSessionId={buyingSessionId}
            handleChange={handleChange}
            bulkAddProductsToAssortment={bulkAddProductsToAssortment}
            productList={productList}
            drawerOpen={drawerOpen}
            toogleDrawer={toogleDrawer}
            assortmentId={assortmentId}
            permissions={permissions}
          />
        )}
        {/* Product detail sidebar route */}
        <Route
          path={`${path}/details/:productId`}
          render={routerProps => (
            <ProductDetailContainer {...routerProps} permissions={permissions} />
          )}
        />
        {/* Assortment approval popover */}
        <Popover
          open={approvalPopupOpen}
          anchorEl={showApprovalPopupAnchorEl}
          onClose={handleApprovalPopupClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <div>
            {isGMOrAdmin ? (
              <CMApprovalDialog
                handleApprovalPopupClose={handleApprovalPopupClose}
                handleApprovalPopupSubmit={handleApprovalPopupSubmit}
              />
            ) : (
              <RMApprovalDialog
                handleApprovalPopupClose={handleApprovalPopupClose}
                handleApprovalPopupSubmit={handleApprovalPopupSubmit}
              />
            )}
          </div>
        </Popover>
      </Root>
    )
  }
}
