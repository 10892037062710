import { QueryResult } from '@apollo/client/react'
import { User } from '@models'
import { UserRoles } from '@modules/common/models'
import { storage } from '@services/storageService'
import { stores } from '@stores'
import { nav } from '@stores/nav'
import find from 'lodash/find'
import some from 'lodash/some'
import { action, computed, observable } from 'mobx'

export class SidebarStore {
  @observable anchorEl = null
  @observable buyingSessionGroupId = ''
  @observable inactiveSeasonsCollapseValue = false
  @observable isWholesaleClusterMenuOpen = true
  @observable isSeasonCollapsed = false
  @observable genderId = ''
  @observable selectedSettingMenu = { section: '' }
  @observable selectedWholesaleSeason = { Id: '', Gender: { Id: '' }, SessionGroups: [] }

  queries: {
    seasons: QueryResult<any>
  } = { seasons: null }

  @computed get open() {
    const {
      queryParams: { sidebar }
    } = nav
    return !sidebar || sidebar !== 'hide'
  }

  set open(open: boolean) {
    nav.updateQueryParams({ sidebar: open ? undefined : 'hide' })
  }

  @action toggle = () => {
    this.open = !this.open
  }

  @action isRegionalMerchandiser = () => {
    const user = storage.getItem<User>('user')
    const isRegionalMerchandiser = some(user.roles, {
      name: UserRoles.REGIONAL_MERCHANDISER
    })
    return isRegionalMerchandiser
  }

  @action
  handleClick = event => {
    this.anchorEl = event.currentTarget
  }

  @action
  handleClose = () => {
    this.anchorEl = null
  }

  @action
  handleLogout = () => {
    const { history } = nav
    this.handleClose()
    history.push('/logout')
  }

  @action
  handleSessionChange = buyingSessionGroupId => {
    const {
      nav: { updateQueryParams }
    } = stores
    this.buyingSessionGroupId = buyingSessionGroupId
    updateQueryParams({ buyingSessionGroupId })
  }

  @action toggleCollapse = () => {
    this.inactiveSeasonsCollapseValue = !this.inactiveSeasonsCollapseValue
  }

  handleGenderChange = () => event => {
    this.genderId = event.target.value
  }

  @action
  setWholesaleSeason = season => {
    const {
      nav: {
        queryParams: { buyingSessionGroupId }
      }
    } = stores
    if (season.Id === this.selectedWholesaleSeason.Id) {
      this.isSeasonCollapsed = !this.isSeasonCollapsed
    } else if (season.Id !== this.selectedWholesaleSeason.Id && !this.isSeasonCollapsed) {
      this.isSeasonCollapsed = true
    }
    this.selectedWholesaleSeason = season
    if (!buyingSessionGroupId) {
      const sessionGroup = find(season.SessionGroups, [
        'GIV_VIPBuyingSessionGroup_HasActiveBuyingSessions_bool',
        true
      ])
      if (sessionGroup) {
        this.handleSessionChange(sessionGroup.Id)
      }
    }
    this.genderId = ''
  }

  @action
  setSelectedGender = genderId => {
    this.genderId = genderId
  }
  @action
  resetSeasonCollapseToggle = () => {
    this.isSeasonCollapsed = false
  }
}

export const sidebar = new SidebarStore()
