import { QueryResult } from '@apollo/client/react'
import { Query } from '@apollo/client/react/components/Query'
import { CentricTheme, StyledProps } from '@components/3rd-party/mui'
import { CircularLoading } from '@components/UI-Components/CircularLoading'
import { ReportingModal } from '@components/UI-Components/ReportingModal'
import { KeyboardArrowDown } from '@icons'
import { User } from '@models'
import { ProductGridContainer } from '@modules/collection/components/ProductGridContainer'
import { ProductListContainer } from '@modules/collection/components/ProductListContainer'
import { UserRoles, ViewTabs } from '@modules/common/models/enums'
import { RestrictProductByZoneModal } from '@modules/retail/collection/component/RestrictProductByZone/RestrictProductByZoneModal'
import Fab from '@material-ui/core/Fab'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { ZONE_COUNTER_OFFER } from '@modules/retail/collection/graphql/zoneCounterOffer'
import { BUYING_SESSION_PRODUCTS_HIERARCHY_KPIS } from '@modules/retail/collection/graphql/buyingSessionProductsHierarchyKPIs'
import { getPermissionsByEntityAndRole, IPermissions } from '@routes'
import { getViewTabIndexMap } from '@services/commonServices'
import { storage } from '@services/storageService'
import { stores } from '@stores'
import isEmpty from 'lodash/isEmpty'
import some from 'lodash/some'
import { observe, reaction } from 'mobx'
import { disposeOnUnmount, observer } from 'mobx-react'
import React from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'

//const LazyAssortmentSplitTable = lazy(() => import('./AssortmentSplitTable'))

const styles = (theme: CentricTheme) => ({
  noRecordFoundMsg: {
    marginLeft: 32,
    marginTop: 8
  },
  fab: {
    zIndex: 4000,
    position: 'fixed',
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    margin: theme.spacing(3)
  }
})
interface MyProps extends StyledProps {
  product?: any
  client?: any
  genderId?: any
  podiumId?: any
  permissions?: IPermissions
  history?: any
  match?: any
  betBuyData?: any
  activities?: any
  rangePlanData?: any
  listViewContainerHeight?: number
}

@((withStyles as any)(styles))
@(withRouter as any)
@observer
export class ProductCardAssortmentTree extends React.Component<MyProps> {
  scrollHeaderRef = null
  refetch = null
  state = {
    salesValue: null,
    selectedId: null,
    qtyValue: null,
    selectedProductId: null
  }

  @disposeOnUnmount _watchSearchInputEnterKeyPress = reaction(
    () => stores.search.lastUpdatedOn,
    lastUpdatedOn => {
      if (this.refetch) {
        this.refetch()
      }
    }
  )

  setRefetch = refetch => {
    this.refetch = refetch
  }

  componentDidMount = () => {
    const { assortment } = stores
    observe(assortment, 'selectedAssortmentTab', ({ oldValue }: any) => {
      const {
        assortment: { selectedAssortmentTab }
      } = stores
      if (
        (oldValue === 1 || oldValue === 2) &&
        selectedAssortmentTab !== 1 &&
        selectedAssortmentTab !== 2
      ) {
        if (this.scrollHeaderRef) {
          this.scrollHeaderRef.scrollLeft = 0
        }
      }
    })
  }

  handleChange = (event, selectedId, id) => {
    const qtyValue = event.target.value * 7
    this.setState({
      salesValue: event.target.value,
      selectedId: selectedId,
      qtyValue: qtyValue,
      selectedProductId: id
    })
  }

  render() {
    const [
      {
        props: {
          classes,
          match: {
            path,
            params: { buyingSessionId, genderId, podiumId, activityId }
          },
          activities,
          betBuyData,
          rangePlanData
        },
        setRefetch
      },
      {
        filter: { productWhereInput, finalFilterToApply, getFlagFilter },
        search: { searchKey, prepareProductSearchWhereInput },
        assortment: { selectedAssortmentTab, reportingDrawerOpen, toggleReportingDrawer },
        collection: { listViewColumnDefinitionMap },
        nav: {
          params: { view }
        }
      }
    ] = [this, stores]

    const user = storage.getItem<User>('user')
    const role = user.roles[0].name
    const isGlobalMerchandiserOrAdmin = some(
      user.roles,
      role =>
        role.name === UserRoles.CENTRAL_MERCHANDISER_RETAIL ||
        role.name === UserRoles.ADMINISTRATOR
    )

    // For quick reporting
    const showReport = getPermissionsByEntityAndRole('bet').canEdit

    const customFilter = getFlagFilter(finalFilterToApply)
    const whereInput = prepareProductSearchWhereInput(
      productWhereInput,
      finalFilterToApply,
      user,
      searchKey
    )
    const buyingSession = buyingSessionId
      ? {
          id: buyingSessionId
        }
      : {
          buyingSessionCollection: {
            id: podiumId
          }
        }

    return (
      <Query
        query={ZONE_COUNTER_OFFER}
        fetchPolicy='cache-and-network'
        variables={{
          buyingSessionId: buyingSessionId
        }}
        skip={selectedAssortmentTab !== 3 || !isGlobalMerchandiserOrAdmin || !activityId}
        notifyOnNetworkStatusChange
      >
        {({ data: counterOfferData }) => {
          const tabIndexMap = getViewTabIndexMap(view)

          return (
            <>
              <Query
                query={BUYING_SESSION_PRODUCTS_HIERARCHY_KPIS}
                variables={{
                  buyingSessionWhereInput: buyingSession,
                  genderId,
                  quantityType:
                    selectedAssortmentTab === tabIndexMap[ViewTabs.BUY] ? 'BUY' : 'BET',
                  productWhereInput: { ...whereInput, buyingSession },
                  customFilter
                }}
                context={{
                  noBatch: true
                }}
                fetchPolicy={'cache-and-network'}
              >
                {({ error, loading, data, refetch }: QueryResult) => {
                  setRefetch(refetch)

                  if (loading && isEmpty(data)) return <CircularLoading />

                  if (
                    data &&
                    data.buyingSessionProductsKPIs &&
                    data.buyingSessionProductsKPIs.categories.length
                  ) {
                    return (
                      <>
                        <Switch>
                          <Route
                            path={`${path}/grid`}
                            render={() => {
                              return (
                                <>
                                  <ProductGridContainer
                                    hierarchyKPIs={data.buyingSessionProductsKPIs}
                                  />
                                  <RestrictProductByZoneModal
                                    message={'Restrict Product by zone'}
                                  />
                                </>
                              )
                            }}
                          />
                          <Route
                            path={[`${path}/list`, `${path}/kpi`]}
                            render={() => {
                              return (
                                <>
                                  <ProductListContainer
                                    key={view}
                                    isCategoryDataLoading={loading}
                                    hierarchyKPIs={data.buyingSessionProductsKPIs}
                                    colDef={
                                      listViewColumnDefinitionMap[selectedAssortmentTab]
                                    }
                                    betBuyData={betBuyData}
                                    rangePlans={rangePlanData}
                                    zoneCounterOffer={
                                      counterOfferData &&
                                      counterOfferData.zoneCounterOffer
                                        ? counterOfferData.zoneCounterOffer
                                        : []
                                    }
                                    buyingSessionGroupName=''
                                  />
                                  {selectedAssortmentTab === tabIndexMap[ViewTabs.BET] &&
                                  showReport &&
                                  !reportingDrawerOpen ? (
                                    <Fab
                                      size='small'
                                      className={classes.fab}
                                      onClick={toggleReportingDrawer}
                                    >
                                      <KeyboardArrowDown />
                                    </Fab>
                                  ) : null}

                                  {selectedAssortmentTab === tabIndexMap[ViewTabs.BET] &&
                                  reportingDrawerOpen &&
                                  showReport ? (
                                    <ReportingModal
                                      betBuyData={betBuyData}
                                      activities={activities}
                                      open={reportingDrawerOpen}
                                      onClose={toggleReportingDrawer}
                                      role={role}
                                    />
                                  ) : null}
                                </>
                              )
                            }}
                          />
                        </Switch>
                      </>
                    )
                  } else {
                    return (
                      <div className={classes.noRecordFoundMsg}>
                        <Typography variant='h5'>No Record Found.</Typography>
                      </div>
                    )
                  }
                }}
              </Query>
            </>
          )
        }}
      </Query>
    )
  }
}
