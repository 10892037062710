import { ApolloClient } from '@apollo/client/core/ApolloClient'
import { withApollo } from '@apollo/client/react/hoc/withApollo'
import { CentricTheme, StyledProps } from '@components/3rd-party/mui'
import { PrimaryButton } from '@components/UI-Components/PrimaryButton'
import { TitleBar } from '@components/UI-Components/TitleBar'
import { User } from '@models'
import { withStyles } from '@material-ui/core/styles'
import { IPermissions } from '@routes'
import { storage } from '@services/storageService'
import { apolloClient, stores } from '@stores'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import React from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import { UPDATE_BUYING_SESSION_STATUS } from '../graphql/UpdateBuyingSessionStatus'
import { UPDATE_LAST_ACCESSED_BUYING_SESSION } from '../graphql/UpdateLastAccessedBuyingSession'
import * as css from './BuyingSession.css'
import { BuyingSessionGenderTabs } from './BuyingSessionGenderTabs'
import { BuyingSessionList } from './BuyingSessionList'

const styles = (theme: CentricTheme) => ({
  typography: {
    padding: theme.spacing(3)
  },
  tableCellWithIcon: {
    display: 'flex',
    alignItems: 'center'
  },
  tableCellText: {
    marginRight: theme.spacing(1)
  },
  createButtonIcon: {
    marginRight: theme.spacing(1)
  },
  popOver: {
    width: 153,
    fontSize: 14
  },
  popOverMenu: {
    fontSize: '14px'
  },
  cursor: {
    cursor: 'pointer'
  },
  statusButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.colors.yellow,
    color: theme.colors.white,
    height: 24,
    width: 120,
    paddingLeft: theme.spacing(1) / 2,
    fontSize: 10,
    '&:hover': {
      color: theme.colors.darkgrey,
      cursor: 'pointer'
    }
  },
  smallColorBox: {
    marginRight: 12
  },
  icon: {
    margin: theme.spacing(1),
    color: theme.colors.grey,
    cursor: 'pointer',
    '&:hover': {
      color: theme.colors.lightgrey
    }
  }
})

// const getStatusValues = status => {
//   const [{ color, value }] = BUYING_SESSION_STATUSES.filter(({ name }) => name === status)

//   return { color, value }
// }
interface MyProps extends StyledProps {
  anchorEl?: any
  onOrderChange: (order) => any
  order: string
  salesYear: any
  client?: ApolloClient<any>
  history?: any
  genders?: any
  permissions?: IPermissions
}
@((withStyles as any)(styles))
@(withApollo as any)
@(withRouter as any)
@observer
export class BuyingSession extends React.Component<MyProps> {
  static defaultProps = {
    headerLabel: 'Givenchy',
    keepMeLoginCheckbox: false
  }
  state = {
    tabValue: 0,
    selectedStatus: 'PRE_BUYING'
  }

  updateBuyingSessionStatus = async (status, buyingSessionId) => {
    const {
      buyingSession: { handleClose }
    } = stores
    this.setState({ selectedStatus: status })
    await apolloClient.mutate({
      mutation: UPDATE_BUYING_SESSION_STATUS,
      variables: {
        status,
        buyingSessionId
      }
    })

    handleClose()
  }

  updateLastAccessedBuyingSession = buyingSessionId => {
    const {
      props: { client, history }
    } = this

    history.push({
      pathname: `/collections/buyingSessions/${buyingSessionId}`,
      search: history.location.search
    })

    const user = storage.getItem<User>('user')
    client.mutate({
      mutation: UPDATE_LAST_ACCESSED_BUYING_SESSION,
      variables: {
        buyingSessionId,
        userId: user.id
      }
    })
  }

  render() {
    const [
      {
        props: { genders, permissions }
      },
      {
        buyingSession: { handleDialogOpen }
      }
    ] = [this, stores]

    return (
      <React.Fragment>
        <TitleBar
          title='Buying Sessions'
          actions={
            permissions && permissions.canEdit ? (
              <PrimaryButton onClick={handleDialogOpen}>Create New Session</PrimaryButton>
            ) : null
          }
        />
        <div className={classNames(css.wrapper)}>
          <div className={css.root}>
            <BuyingSessionGenderTabs genders={genders} />
            <Switch>
              <Route
                exact
                path='/buyingSession/:seasonId/gender/:genderId'
                render={props => (
                  <BuyingSessionList permissions={permissions} {...props} />
                )}
              />
            </Switch>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
