/*
 * @Author: Raeesaa Metkari
 * @Date: 2019-12-12 13:07:09
 * @Last Modified by: Raeesaa Metkari
 * @Last Modified time: 2020-01-23 14:37:30
 */

export enum TextAlignment {
  left = 'left',
  right = 'right',
  center = 'center'
}

export enum TextAlignmentFlexMap {
  left = 'flex-start',
  right = 'flex-end',
  center = 'center'
}
