import { getModelDescription } from '@services/collectionServices'
import { strings } from '@stores'
import isEmpty from 'lodash/isEmpty'

/**
 * Retrievs values from color object
 *
 * @param {object} colorObj
 * @param {array}
 */

const getColorValues = colorObj => {
  let {
    color: { color }
  } = colorObj

  return [
    {
      name: 'color',
      value: color ? color.name : 'NA'
    }
  ]
}

const getModelMaterialDescription = attributes => {
  let modelDescription = getValuesFromAttributes(attributes, 'modelDescription')
  let model = getValuesFromAttributes(attributes, 'model')
  return modelDescription && model ? getModelDescription(modelDescription, model) : 'NA'
}

/**
 * Retrievs values from attributes object
 *
 * @param {array} attributes
 * @param {array}
 */
const getValuesFromAttributes = (attributes, valueFor) => {
  const { strVal, intVal } = attributes.filter(attribute =>
    attribute.definition
      ? attribute.definition.name === valueFor
      : {
          strVal: 'NA'
        }
  )[0]
  return strVal || intVal
}

/**
 * Retrievs values from attributes object
 *
 * @param {array} attributes
 * @param {array}
 */
const getLabelValueFromAttributes = (attributes, valueFor) => {
  const { strVal, intVal, definition } = attributes.filter(attribute =>
    attribute.definition
      ? attribute.definition.name === valueFor
      : {
          strVal: 'NA',
          intVal: 'NA',
          definition: {
            singular: valueFor
          }
        }
  )[0]
  return { value: strVal || intVal || 'NA', label: definition.displayName.singular }
}

/**
 * Returns offer detail object
 *
 * @param {object} product
 * @param {object}
 */
const getProductDetail = (buyingSessionProduct, isCoreProduct = false) => {
  if (!buyingSessionProduct) return {}

  let product
  if (isCoreProduct) {
    product = buyingSessionProduct
  } else {
    product = buyingSessionProduct.product
  }

  const {
    attributes,
    image,
    images,
    name,
    active,
    looks,
    substitutedInLooks,
    retailPrice,
    zoneRetailPrice
  } = product

  const color = getLabelValueFromAttributes(attributes, 'color')
  const modelDescription = getLabelValueFromAttributes(attributes, 'modelDescription')
  const model = attributes.length
    ? getLabelValueFromAttributes(attributes, 'model')
    : null
  const retailStatus = getLabelValueFromAttributes(attributes, 'retailStatus')
  const category = getLabelValueFromAttributes(attributes, 'category')
  const family = getLabelValueFromAttributes(attributes, 'family')
  const lining = getLabelValueFromAttributes(attributes, 'lining')

  return {
    id: buyingSessionProduct.id,
    color,
    // modelDescription,
    zoneRetailPrice: zoneRetailPrice,
    image,
    images,
    retailPrice: retailPrice,
    substitutedInLooks: [...substitutedInLooks],
    looks: [...looks],
    attributes,
    isActive: isCoreProduct ? active : buyingSessionProduct.isActive,
    productStatus: isCoreProduct ? '' : buyingSessionProduct.productStatus,
    buyingSessionProductAttributes: buyingSessionProduct.buyingSessionProductAttributes,
    retailStatus,
    restrictedZones: buyingSessionProduct.restrictedZones
      ? buyingSessionProduct.restrictedZones
      : [],
    category,
    family,
    lining,
    description:
      !isEmpty(modelDescription) && !isEmpty(model)
        ? getModelDescription(modelDescription.value, model.value)
        : 'NA',
    name,
    moq: isCoreProduct ? '' : buyingSessionProduct.moq,
    favourites: buyingSessionProduct.favourites,
    active,
    channels: buyingSessionProduct.channels ? buyingSessionProduct.channels : [],
    ratings: buyingSessionProduct.ratings,
    avgRatingCount:
      buyingSessionProduct.avgRatingCount &&
      !Number.isInteger(buyingSessionProduct.avgRatingCount)
        ? buyingSessionProduct.avgRatingCount.toFixed(1)
        : buyingSessionProduct.avgRatingCount,
    favouriteCount:
      buyingSessionProduct.totalFavourites &&
      buyingSessionProduct.totalFavourites.length > 0
        ? buyingSessionProduct.totalFavourites.length
        : null,
    product
  }
}

const isRetailsChannelRemovable = assortmentType => {
  if (
    assortmentType === strings.zone ||
    assortmentType === strings.store ||
    assortmentType === strings.cluster
  ) {
    return false
  }
  return true
}
export {
  getProductDetail,
  getColorValues,
  getValuesFromAttributes,
  getModelMaterialDescription,
  isRetailsChannelRemovable
}
