import { UserRoles } from '@modules/common/models/enums/UserRoles'
import { User } from '@modules/models'
import intersection from 'lodash/intersection'
import map from 'lodash/map'
import { storage } from './storageService'
import { VIPChannel } from '@modules/common/models/enums/VIPChannel'

export const hasUserRoles = (roles: UserRoles[]) => {
  const user = storage.getItem<User>('user')
  return !!intersection(map(user.roles, 'name'), roles).length
}

export const PERMISSION_MAP = [
  {
    id: VIPChannel.Wholesale,
    allowedRoles: [
      UserRoles.ADMINISTRATOR,
      UserRoles.CM_ADMIN,
      UserRoles.CENTRAL_MERCHANDISER_WHOLESALE
    ]
  },
  {
    id: VIPChannel.Retail,
    allowedRoles: [
      UserRoles.ADMINISTRATOR,
      UserRoles.CM_ADMIN,
      UserRoles.CENTRAL_MERCHANDISER_RETAIL
    ]
  }
]

export const SAClientsAllowedRoles = [
  UserRoles.ADMINISTRATOR,
  UserRoles.CM_ADMIN,
  UserRoles.CENTRAL_MERCHANDISER_WHOLESALE,
  UserRoles.WHOLESALE_SHOWROOM_ASSISTANT,
  UserRoles.ZONE_AREA_MANAGER
]

export const SAZonesAllowedRoles = [
  UserRoles.ADMINISTRATOR,
  UserRoles.CM_ADMIN,
  UserRoles.CENTRAL_MERCHANDISER_RETAIL,
  UserRoles.REGIONAL_MERCHANDISER
]
