import debounce from 'lodash/debounce'
import React from 'react'

export const useDebouncedCallback = (callback, timeDuration = 300) => {
  const debouncedSearch = React.useMemo(
    () => debounce((...params: any[]) => callback(...params), timeDuration),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [callback]
  )
  return debouncedSearch
}
