import { withApollo } from '@apollo/client/react/hoc'
import { CentricTheme, StyledProps } from '@components/3rd-party/mui'
import { PrimaryButton } from '@components/UI-Components/PrimaryButton'
import { SecondaryButton } from '@components/UI-Components/SecondaryButton'
import { SelectField } from '@components/UI-Components/SelectField'
import { TextInput } from '@components/UI-Components/TextInput'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import { withStyles } from '@material-ui/core/styles'
import { getZoneOrder, sortListUsingOrderList } from '@services/commonServices'
import { apolloClient, stores as globalStores } from '@stores'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import React from 'react'
import {
  CREATE_COUNTRY,
  CREATE_STORE,
  GET_STORES,
  GET_ZONES_COUNTRIES_CATEGORIES,
  UPDATE_STORE
} from '../graphql'

const styles = (theme: CentricTheme) => ({
  input: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(2)
  },
  field: {
    marginRight: theme.spacing(2)
  }
})

interface IProps extends StyledProps {
  categories?: any
  zones?: any
  countries?: any
  client?: any
}

const ACTIVE_OPTIONS = [
  { id: true, name: 'Yes' },
  { id: false, name: 'No' }
]

@((withStyles as any)(styles))
@(withApollo as any)
@observer
export class CreateStoreDialog extends React.Component<IProps> {
  @observable
  quickCreateInProgress = false
  @observable isCreateStoreInProgress = false

  createStore = async () => {
    const {
      storeImport: { storeFields, toggleCreateStoreDialogOpen }
    } = globalStores
    this.isCreateStoreInProgress = true
    try {
      const {
        data: { createStore }
      } = await apolloClient.mutate({
        mutation: CREATE_STORE,
        variables: {
          name: storeFields.name.trim(),
          storeCode: storeFields.storeCode.trim(),
          city: storeFields.city.trim(),
          postalCode: storeFields.postalCode.trim(),
          isActive: storeFields.isActive,
          address: storeFields.address.trim(),
          country: storeFields.country,
          zone: storeFields.zone,
          storeType: storeFields.storeType
        }
      })
      const { stores } = apolloClient.readQuery({
        query: GET_STORES
      })
      let newStores = sortListUsingOrderList(
        stores.concat(createStore),
        ['zone', 'name'],
        getZoneOrder()
      )
      apolloClient.writeQuery({
        query: GET_STORES,
        data: { stores: newStores }
      })
    } finally {
      this.isCreateStoreInProgress = false
      toggleCreateStoreDialogOpen()
    }
  }

  editStore = async () => {
    const {
      storeImport: { storeFields, toggleCreateStoreDialogOpen, selectedStoreId }
    } = globalStores
    await apolloClient.mutate({
      mutation: UPDATE_STORE,
      variables: {
        name: storeFields.name,
        storeCode: storeFields.storeCode.trim(),
        city: storeFields.city.trim(),
        postalCode: storeFields.postalCode.trim(),
        isActive: storeFields.isActive,
        address: storeFields.address.trim(),
        storeId: selectedStoreId
      }
    })
    toggleCreateStoreDialogOpen()
  }

  createAction = async name => {
    this.quickCreateInProgress = true
    const {
      storeImport: { storeFields, setCreatedCountry }
    } = globalStores

    await apolloClient
      .mutate({
        mutation: CREATE_COUNTRY,
        variables: {
          name,
          zoneId: storeFields['zone']
        },
        update: (cache, { data: { createCountry } }) => {
          const { zones, countries, categories } = cache.readQuery({
            query: GET_ZONES_COUNTRIES_CATEGORIES
          })
          const currentZone = zones.find(zone => zone.id === storeFields['zone'])
          const newCountriesForCurrentZone = currentZone.countries.concat(createCountry)
          setCreatedCountry(createCountry)
          currentZone.countries = newCountriesForCurrentZone
          const newCountries = countries.concat(createCountry)
          cache.writeQuery({
            query: GET_ZONES_COUNTRIES_CATEGORIES,
            data: { zones, countries: newCountries, categories }
          })
        }
      })
      .finally(() => {
        this.quickCreateInProgress = false
      })
  }

  render() {
    const [
      {
        props: { classes, categories, zones },
        createStore,
        editStore,
        createAction,
        quickCreateInProgress,
        isCreateStoreInProgress
      },
      {
        storeImport: {
          toggleCreateStoreDialogOpen,
          storeFields,
          handleChange,
          isEditClicked
        }
      }
    ] = [this, globalStores]
    return (
      <Dialog open disableBackdropClick>
        <DialogTitle>{isEditClicked ? 'Edit' : 'Create'} Store</DialogTitle>
        <DialogContent>
          <div className={classes.input}>
            <TextInput
              name={'storeCode'}
              value={storeFields['storeCode']}
              label={'Code'}
              type='text'
              fullWidth
              margin='dense'
              variant='outlined'
              className={classes.field}
              onChange={handleChange}
            />
            <TextInput
              name={'name'}
              value={storeFields['name']}
              label={'Name'}
              type='text'
              fullWidth
              margin='dense'
              variant='outlined'
              className={classes.field}
              onChange={handleChange}
            />
          </div>
          <div className={classes.input}>
            <FormControl
              fullWidth
              variant='outlined'
              margin='dense'
              className={classes.field}
            >
              <InputLabel htmlFor='zone'>Zone</InputLabel>
              <SelectField
                className={classes.selectfield}
                input={<OutlinedInput name='zone' labelWidth={50} id='outlined-simple' />}
                options={zones}
                disabled={isEditClicked}
                value={storeFields['zone']}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl
              fullWidth
              variant='outlined'
              margin='dense'
              className={classes.field}
            >
              <InputLabel htmlFor='zone'>Country / Region</InputLabel>
              <SelectField
                className={classes.selectfield}
                input={
                  <OutlinedInput name='country' labelWidth={120} id='outlined-simple' />
                }
                options={
                  zones && storeFields['zone']
                    ? zones.find(zone => storeFields['zone'] === zone.id).countries || []
                    : []
                }
                disabled={isEditClicked ? true : storeFields['zone'] ? false : true}
                value={storeFields['country']}
                onChange={handleChange}
                quickCreate={true}
                quickCreateInProgress={quickCreateInProgress}
                createAction={createAction}
              />
            </FormControl>
          </div>
          <div className={classes.input}>
            <FormControl
              fullWidth
              variant='outlined'
              margin='dense'
              className={classes.field}
            >
              <InputLabel>Active</InputLabel>
              <SelectField
                className={classes.selectfield}
                input={
                  <OutlinedInput name='isActive' labelWidth={60} id='outlined-simple' />
                }
                options={ACTIVE_OPTIONS}
                value={storeFields['isActive']}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl
              fullWidth
              variant='outlined'
              margin='dense'
              className={classes.field}
            >
              <InputLabel>Type</InputLabel>
              <SelectField
                className={classes.selectfield}
                input={
                  <OutlinedInput name='storeType' labelWidth={50} id='outlined-simple' />
                }
                options={categories}
                disabled={isEditClicked}
                value={storeFields['storeType']}
                onChange={handleChange}
              />
            </FormControl>
          </div>
          <div className={classes.input}>
            <TextInput
              name={'city'}
              value={storeFields['city']}
              label={'City'}
              type='text'
              fullWidth
              margin='dense'
              variant='outlined'
              className={classes.field}
              onChange={handleChange}
            />
            <TextInput
              name={'postalCode'}
              value={storeFields['postalCode']}
              label={'Zip Code'}
              type='text'
              fullWidth
              margin='dense'
              variant='outlined'
              className={classes.field}
              onChange={handleChange}
            />
          </div>
          <div className={classes.input}>
            <TextInput
              name={'address'}
              value={storeFields['address']}
              label={'Address'}
              type='text'
              fullWidth
              margin='dense'
              variant='outlined'
              className={classes.field}
              onChange={handleChange}
              multiline
            />
          </div>
        </DialogContent>
        <DialogActions>
          <PrimaryButton
            onClick={isEditClicked ? editStore : createStore}
            disabled={
              !storeFields['storeCode'] ||
              storeFields['storeCode'].trim().length === 0 ||
              !storeFields['name'] ||
              storeFields['name'].trim().length === 0 ||
              !storeFields['country'] ||
              !storeFields['zone'] ||
              !storeFields['storeType'] ||
              // As per discussion commented fields are not mandatory
              //|| !storeFields['address']
              //|| !storeFields['postalCode'] || storeFields['postalCode'].trim().length === 0
              (storeFields['postalCode'].trim().length > 0 &&
                isNaN(Number(storeFields['postalCode']))) ||
              //|| !storeFields['city'] || storeFields['city'].trim().length === 0
              storeFields['isActive'] === ''
            }
            loading={isCreateStoreInProgress}
          >
            Save
          </PrimaryButton>
          <SecondaryButton onClick={toggleCreateStoreDialogOpen}>Cancel</SecondaryButton>
        </DialogActions>
      </Dialog>
    )
  }
}
