import { config } from '@config'
import { User } from '@modules/models'
import { GET_EXCHANGE_RATES } from '@modules/retail/buyingSession/graphql/getExchangeRate'
import { GET_GLOBAL_PRICE_CODE } from '@modules/retail/buyingSession/graphql/getGlobalPriceCode'
import { storage } from '@services/storageService'
import { nav } from '@stores/nav'
import { strings } from '@stores'
import { apolloClient } from '@stores/apollo'
import * as lockr from 'lockr'
import { action, autorun, computed, observable, runInAction, toJS } from 'mobx'
import { observer } from 'mobx-react'
import {
  configureDevtool,
  setGraphEnabled,
  setLogEnabled,
  setUpdatesEnabled
} from 'mobx-react-devtools'
import React from 'react'
import { withRouter } from 'react-router-dom'

interface MyProps {
  onInit?: (store: SiteStore) => void
}

export let site: SiteStore

@(withRouter as any)
@observer
export class SiteStore extends React.Component<MyProps> {
  // Main app will set this if the user touches the screen ever
  @observable touchDevice = 'TouchEvent' in window && 'ontouchstart' in window

  // Set by react-idle-timer - https://www.npmjs.com/package/react-idle-timer
  @observable userIsIdle = false
  @observable features = { assortment: {} }
  @observable exchangRates = []
  @observable globalCurrencyCode = null
  constructor(props) {
    super(props)
    site = this // singleton
    window.centric.site = site
    props.onInit && props.onInit(this)

    this.manageMobxDevTool()

    const { body } = document
    body.setAttribute('data-touch', this.touchDevice.toString())
  }

  @action(`User detected as idle`) private onUserIdle = () => (this.userIsIdle = true)
  @action(`User detected as active`) private onUserActive = () =>
    (this.userIsIdle = false)

  @action async loadPriceDataForRetail() {
    try {
      let {
        data: { priceLists }
      } = await apolloClient.query({
        query: GET_GLOBAL_PRICE_CODE,
        variables: {
          globalPriceListCode: config.globalPriceListCode
        },
        fetchPolicy: 'network-only'
      })
      if (priceLists.length) {
        this.globalCurrencyCode = priceLists[0].currency.code
      }

      const { globalCurrencyCode } = this
      if (globalCurrencyCode) {
        let {
          data: { exchangeRates }
        } = await apolloClient.query({
          query: GET_EXCHANGE_RATES,
          variables: {
            currencyCode: this.globalCurrencyCode
          },
          fetchPolicy: 'network-only'
        })
        this.exchangRates = exchangeRates
      }
    } catch (err) {
      console.error('Error in querying exchage rates')
    }
  }

  render() {
    return null
  }

  // Mediocre solution vs fixing underlying tooltip issues with newer material ui
  /*@disposeOnUnmount hideMaterialUiTooltipsOnIdle = autorun(() => {
    if (this.userIsIdle) {
      const tooltip = document.querySelector("body > [role='tooltip']") as HTMLDivElement;
      if (tooltip) {
        tooltip.style.display = 'none'
      }
    }
  }, {name: 'Hide material-ui tooltips when user is inactive'})*/

  @action reset = () => {}

  @observable mobxDevToolConfig = {
    graph: false,
    updates: false,
    log: {
      enabled: false,
      components: false,
      scheduledReactions: false,
      actions: false,
      computed: false,
      updates: false,
      reaction: false
    }
  }

  hasRole = roleName => {
    const user = storage.getItem<User>('user')
    return user.roles.find(role => role.name === roleName)
  }

  isGlobalMerchandiserOrAdmin() {
    const user = storage.getItem<User>('user')
    return user.roles.find(
      role => role.name === strings.GlobalMerchaniser || role.name === strings.Admin
    )
  }

  @action
  isAdmin = () => {
    return !!this.hasRole(strings.Admin)
  }

  @action
  isGlobalMerchandiser = () => {
    return !!this.hasRole(strings.GlobalMerchaniser)
  }

  /**
   * Return true if user is viewing SA application
   */
  @computed get isSAView() {
    return window.location.pathname.includes('/showroom')
  }

  /**
   * Return true if user is viewing Order view
   */
  @computed get isOrderManagementView() {
    return window.location.pathname.includes('/order')
  }

  @computed get showNotifications() {
    const {
      queryParams: { notification }
    } = nav
    return !!notification
  }

  @action openNotifications = () => {
    nav.updateQueryParams({ notification: true })
  }
  @action closeNotifications = () => {
    nav.updateQueryParams({ notification: undefined })
  }

  @action
  private manageMobxDevTool() {
    this.mobxDevToolConfig = lockr.get('mobxDevToolConfig') || this.mobxDevToolConfig
    DEBUG &&
      autorun(
        () => {
          const {
            mobxDevToolConfig,
            mobxDevToolConfig: { graph, updates, log }
          } = this
          setGraphEnabled(graph)
          setLogEnabled(log.enabled)
          setUpdatesEnabled(updates)

          runInAction(`Update mobx dev config in local storage`, () => {
            if (!log.enabled) {
              Object.assign(log, {
                components: false,
                scheduledReactions: false,
                actions: false,
                computed: false,
                updates: false,
                reaction: false
              })
            }

            lockr.set('mobxDevToolConfig', toJS(mobxDevToolConfig))
          })
        },
        { name: `Update mobx dev tools config` }
      )

    configureDevtool({
      logFilter: ({ type, name, object, ...info }) => {
        const {
          mobxDevToolConfig: {
            log: { components, scheduledReactions, actions, computed, reaction, updates }
          }
        } = this
        if (
          (!scheduledReactions && type === 'scheduled-reaction') ||
          (!components && type === 'reaction' && name.endsWith('.render()')) ||
          (!actions && type === 'action') ||
          (!computed && type === 'compute') ||
          (!reaction && type === 'reaction') ||
          (!updates && type === 'update') ||
          type === 'add' // These aren't useful - they're @observables on a class being initialized
        ) {
          return false
        }

        //console.log({type, name, object, ...info})

        return true
      }
    })
  }
}
