import { withStyles } from '@material-ui/core/styles'
import React from 'react'

const style = theme => ({
  container: {
    padding: theme.spacing(2)
  }
})

const BuyingSessionDetailComponent = ({ classes, children }) => {
  return <div className={classes.container}>{children}</div>
}

export const BuyingSessionDetails = withStyles(style)(
  React.memo(BuyingSessionDetailComponent)
)
