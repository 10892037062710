import {
  ActiveStatusIcon,
  InActiveStatusIcon
} from '@components/UI-Components/svg/wholesale'
import { config } from '@config'
import { UserRoles } from '@modules/common/models/enums/UserRoles'
import { getHighestUser } from '@routes'
import isEqual from 'lodash/isEqual'
import memoize from 'memoize-one'

const isBuyingSessionClosed = memoize(buyingSession => {
  let bsIsClosed = buyingSession
    ? buyingSession.Status ===
      config?.appConfig?.enumerations?.buyingSessionStatus?.BUYING_SESSION_STATUS_VALUES
        .CLOSED
    : false
  return bsIsClosed
}, isEqual)

const isRtlBuyingSessionClosed = memoize(buyingSession => {
  let bsIsClosed = buyingSession
    ? buyingSession.STD_RTLBuyingSession_Status ===
      config?.appConfig?.enumerations?.retailBuyingSessionStatus
        ?.BUYING_SESSION_STATUS_VALUES.CLOSED
    : false
  return bsIsClosed
}, isEqual)

const isBuyingSessionPreBuying = memoize(buyingSession => {
  let bsIsPreBuying = buyingSession
    ? buyingSession.Status ===
      config?.appConfig?.enumerations?.buyingSessionStatus?.BUYING_SESSION_STATUS_VALUES
        .PRE_BUYING
    : false
  return bsIsPreBuying
}, isEqual)

const getBuyingSessionGroupStatusIcon = buyingSessionGroupStatus => {
  let buyingGroupAllstatus =
    config?.appConfig?.enumerations?.buyingSessionStatus?.BUYING_SESSION_STATUS_VALUES
  switch (buyingSessionGroupStatus) {
    case buyingGroupAllstatus.BUYING:
      return ActiveStatusIcon
    case buyingGroupAllstatus.PRE_BUYING:
      return ActiveStatusIcon
    case buyingGroupAllstatus.CLOSED:
      return InActiveStatusIcon
  }
}

const isZAMUserInPrebuyingSession = buyingSession => {
  const highestUser = getHighestUser()
  const highestUserRoleName = highestUser?.[0]?.name ?? ''
  return (
    highestUserRoleName === UserRoles.ZONE_AREA_MANAGER &&
    isBuyingSessionPreBuying(buyingSession)
  )
}

export {
  isBuyingSessionClosed,
  isRtlBuyingSessionClosed,
  getBuyingSessionGroupStatusIcon,
  isBuyingSessionPreBuying,
  isZAMUserInPrebuyingSession
}
