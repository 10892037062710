import { StyledProps } from '@components/3rd-party/mui'
import { PrimaryButton } from '@components/UI-Components/PrimaryButton'
import { Paper } from '@material-ui/core'
import Portal from '@material-ui/core/Portal'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { strings } from '@stores'
import React from 'react'

const styles = theme => ({
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  root: {
    width: 528,
    padding: theme.spacing(4)
  },
  button: {
    margin: theme.spacing(1)
  },
  buttonRoot: {
    textAlign: 'center'
  },
  logout: {
    textAlign: 'center'
  }
})

interface MyProps extends StyledProps {
  errorMessage?: string
}

@((withStyles as any)(styles))
export class AuthorizationError extends React.Component<MyProps> {
  moveToSite = () => {
    window.location.replace('/')
  }

  logout = () => {
    window.location.replace('/logout')
  }

  render() {
    const { classes, errorMessage } = this.props

    return (
      <Portal>
        <div className={classes.container}>
          <Paper className={classes.root} elevation={1} tabIndex={1}>
            <Typography variant='h4' component='h3'>
              Unauthorized Access
            </Typography>
            <br />
            <Typography>
              {errorMessage ? errorMessage : strings.defaultErrorMessage}
              <br />
              <br />
            </Typography>
            <PrimaryButton
              color='primary'
              className={classes.button}
              classes={{ root: classes.buttonRoot }}
              onClick={this.moveToSite}
            >
              Back to our site
            </PrimaryButton>
            <PrimaryButton
              color='primary'
              className={classes.button}
              classes={{ root: classes.logout }}
              onClick={this.logout}
            >
              Logout
            </PrimaryButton>
          </Paper>
        </div>
      </Portal>
    )
  }
}
