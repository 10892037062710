import { StyledProps } from '@components/3rd-party/mui'
import { Views } from '@modules/common/models/enums/Views'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Radio from '@material-ui/core/Radio'
import Select from '@material-ui/core/Select'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { SortOption, stores, strings } from '@stores'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import React from 'react'
import styled from 'styled-components'
import { CollectionStore } from '../store'

const styles = theme => ({
  popOver: {
    width: 480,
    fontSize: 14,
    outline: 'none'
  },
  heading: {
    padding: theme.spacing(1),
    outline: 0
  },

  controlLabel: {
    margin: 0,
    width: 150
  },
  controlLabelHidden: {
    display: 'none'
  },
  menuContainer: {
    width: 480
  },
  hrContainer: {
    paddingLeft: '20px',
    paddingRight: '20px'
  },
  formControl: {
    margin: 0,
    minWidth: 120
  },
  sortOptions: {
    padding: '0px 2px 0px 2px',
    outlineStyle: 'unset'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '20px'
  },
  sortGroup: {
    fontWeight: 600
  },
  disableSortOption: {
    pointerEvents: 'none'
  },
  downArrow: {
    color: 'var(--black)'
  }
})

interface IViewAttributeSelector extends StyledProps {
  collectionStore?: CollectionStore
  attributes?: any
  marginLeft?: string
  marginRight?: string
  fontSize?: string
  disableSort?: boolean
}

interface ISortBySelector {
  marginLeft?: string
  marginRight?: string
  fontSize?: string
}

const StyledDiv = styled.div<ISortBySelector>(({ marginLeft, marginRight }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '24px',
  marginLeft: marginLeft ? marginLeft : '17px',
  marginRight: marginRight ? marginRight : '17px',
  marginTop: '0px',
  marginBottom: '0px'
}))

const StyledDefaultText = styled(Typography)<ISortBySelector>`
  && {
    font-size: ${({ fontSize }) => (fontSize ? fontSize : '12px')};
    font-weight: bold;
    letter-spacing: ${({ fontSize }) => (fontSize ? '0.14px' : '0.09px')};
    color: var(--black);
  }
`

const StyledTypography = styled(Typography)<ISortBySelector>`
  && {
    font-family: 'Roboto';
    font-size: ${({ fontSize }) => (fontSize ? fontSize : '10px')};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: ${({ fontSize }) => (fontSize ? '0.14px' : '0.07px')};
    width: ${({ fontSize }) => (fontSize ? '50px' : '35px')};
    height: ${({ fontSize }) => (fontSize ? '19px' : '14px')};
    color: var(--black);
    cursor: pointer;
  }
`

@((withStyles as any)(styles))
@observer
export class SortBySelector extends React.Component<IViewAttributeSelector> {
  render() {
    const [
      {
        props: { classes, marginRight, marginLeft, fontSize, disableSort }
      },
      {
        product: {
          globalSortOptions,
          attributeSortOptions,
          onChangeSortBy,
          sortBy,
          resetSort,
          isBuyMetricSort
        },
        nav: {
          params: { view }
        }
      }
    ] = [this, stores]

    // 1. Card view should not have buy sort option.
    // 2. kPI view should not have any other sort option than buy.
    // Clear sorts if above criteria is not matching
    if (
      (view === Views.Grid && isBuyMetricSort) ||
      (view === Views.Kpi && !isBuyMetricSort)
    ) {
      resetSort()
    }

    return (
      <StyledDiv marginRight={marginRight} marginLeft={marginLeft}>
        <StyledTypography fontSize={fontSize}>Sort By</StyledTypography>
        <FormGroup className={classes.sortOptions}>
          <FormControl>
            <Select
              disableUnderline
              value={sortBy || ''}
              displayEmpty
              onChange={onChangeSortBy}
              disabled={disableSort}
              inputProps={{
                name: 'sort-by',
                id: 'sort-by-selection'
              }}
              classes={{
                icon: classes.downArrow
              }}
              MenuProps={{
                classes: {
                  paper: classes.menuContainer
                }
              }}
            >
              <div className={classes.buttonContainer}>
                <Button onClick={resetSort}>Reset</Button>
              </div>
              <FormControlLabel
                label={
                  <Tooltip title={strings.toolTipSortProduct}>
                    <StyledDefaultText fontSize={fontSize}>{'Default'}</StyledDefaultText>
                  </Tooltip>
                }
                value={''}
                className={classes.controlLabelHidden}
                control={
                  <Radio
                    checked={!sortBy}
                    value={''}
                    name='radio-button-demo'
                    color='default'
                  />
                }
              />
              {globalSortOptions.map((sortFilter: SortOption, index) => {
                return (
                  <FormControlLabel
                    key={sortFilter.value}
                    label={
                      <Typography className={classes.defaultText}>
                        {sortFilter.label}
                      </Typography>
                    }
                    value={sortFilter.value}
                    className={classNames({
                      [classes.controlLabel]: true,
                      [classes.disableSortOption]: sortFilter.disabled
                    })}
                    disabled={sortFilter.disabled}
                    control={
                      <Radio
                        checked={sortFilter.value === sortBy}
                        value={sortBy}
                        name='radio-button-demo'
                        color='default'
                      />
                    }
                  />
                )
              })}

              <div className={classes.hrContainer}>
                <hr />
                <Typography variant='caption' className={classes.sortGroup}>
                  Product Attributes
                </Typography>
              </div>

              {attributeSortOptions.map((sortFilter: SortOption, index) => {
                return (
                  <FormControlLabel
                    key={sortFilter.value}
                    label={
                      <Typography className={classes.defaultText}>
                        {sortFilter.label}
                      </Typography>
                    }
                    value={sortFilter.value}
                    className={classNames({
                      [classes.controlLabel]: true,
                      [classes.disableSortOption]: sortFilter.disabled
                    })}
                    disabled={sortFilter.disabled}
                    control={
                      <Radio
                        checked={sortFilter.value === sortBy}
                        value={sortBy}
                        name='radio-button-demo'
                        color='default'
                      />
                    }
                  />
                )
              })}
            </Select>
          </FormControl>
        </FormGroup>
      </StyledDiv>
    )
  }
}
