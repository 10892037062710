import { Query } from '@apollo/client/react/components/Query'
import { AssortmentToolbar } from '@components/UI-Components/Assortment/AssortmentToolbar'
import { FilterChips } from '@components/UI-Components/FilterChips'
import { SecondaryButton } from '@components/UI-Components/SecondaryButton'
import { AuthorizationError } from '@components/Utils/AuthorizationError'
import Typography from '@material-ui/core/Typography'
import { User } from '@models'
import { GET_ACTIVITY } from '@modules/retail/assortment/graphql/getActivity'
import { GET_BET_BUY_VALUES_FOR_ACTIVITY } from '@modules/retail/assortment/graphql/getActivityBetBuyValues'
import { GET_SELL_THROUGH_VALUES } from '@modules/retail/buyingSession/graphql/getSellThroughValues'
import { CollectionComponent } from '@modules/retail/collection/component'
import { CollectionFilterContainer } from '@modules/retail/collection/container/CollectionFilter'
import { IPermissions } from '@routes'
import { getFilterChipsData } from '@services/collectionServices'
import { storage } from '@services/storageService'
import { stores } from '@stores'
import { observer } from 'mobx-react'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { AssortmentTitleBar } from '../component/AssortmentTitleBar'

const styles = {
  toolBar: {
    margin: '19px 16px 8px 25px'
  },
  filterChips: {
    display: 'flex',
    marginLeft: 32,
    alignItems: 'baseline'
  }
}

interface IActivityCollection {
  match?: any
  CollectionStore?: any
  buyingSessionProducts?: any
  permissions: IPermissions
  history?: any
}

@(withRouter as any)
@observer
export class ActivityCollectionContainer extends React.Component<IActivityCollection> {
  componentDidMount() {
    const {
      search: { clearSearchKey },
      filter: { clearAllFilters }
    } = stores
    clearSearchKey()
    clearAllFilters()
  }

  componentWillUnmount = () => {
    const {
      filter: { clearAllFilters },
      product: { resetSort },
      search: { clearSearchKey },
      collection: { resetSelectedProducts }
    } = stores
    clearAllFilters()
    clearSearchKey()
    resetSort()
    resetSelectedProducts()
  }

  render() {
    const user = storage.getItem<User>('user')
    const [
      {
        props: {
          match: {
            params: { activityId, buyingSessionId }
          },
          permissions
        }
      },
      {
        filter: { toggleCollectionFilter, finalFilterToApply, clearAllFilters },
        collection: { onExcelExportClick, onViewClick }
      }
    ] = [this, stores]

    let zone_ids = []
    if (user.membership && user.membership.zones) {
      user.membership.zones.map(zone => zone_ids.push(zone.id))
    }

    // const filterValue =
    //   permissions && permissions.canEdit ? getActivityValue(finalFilterToApply) : true
    // const { favouriteFilterEvery, favouriteFilterSome } = getFavouriteFilter(
    //   getFavouriteValue(finalFilterToApply),
    //   user
    // )
    // const { ratingFilterNone, ratingFilterSome } = getRatingFilter(finalFilterToApply)
    // const productStatusFilter = getProductStatusFilter(finalFilterToApply)
    // const salesPeriodFilter = getFilterValues(
    //   finalFilterToApply,
    //   BUYING_SESSION_PRODUCT_SALES_PERIOD
    // )
    // const clusterStatusFilter = getFilterValues(finalFilterToApply, CLUSTER_STATUS_NAME)
    // const clusterFilter = getFilterValues(finalFilterToApply, CLUSTER)
    const { chipsArrayToDisplay, chipsArrayInDropDown } =
      getFilterChipsData(finalFilterToApply)
    return permissions && permissions.canRead ? (
      <Query
        query={GET_ACTIVITY}
        variables={{
          buyingSessionId: buyingSessionId
        }}
        fetchPolicy='cache-and-network'
      >
        {({ data: activityData }) => {
          return (
            <Query
              query={GET_SELL_THROUGH_VALUES}
              variables={{
                buyingSessionId
              }}
              fetchPolicy='cache-and-network'
            >
              {({ data: rangePlansForBuyingSessionProducts }) => {
                return (
                  <Query
                    query={GET_BET_BUY_VALUES_FOR_ACTIVITY}
                    context={{
                      noBatch: true
                    }}
                    variables={{
                      buyingSessionId: buyingSessionId
                    }}
                    fetchPolicy='cache-and-network'
                  >
                    {({ data: betBuyData }) => {
                      return (
                        <React.Fragment>
                          <div>
                            {/* We need to move logic of permissions in React Context instead
                                    of passing it all the way down */}
                            {toggleCollectionFilter ? (
                              <CollectionFilterContainer
                                permissions={permissions}
                                variables={{
                                  buyingsessionWhereInput: {
                                    id: buyingSessionId
                                  }
                                }}
                              />
                            ) : null}
                          </div>
                          <AssortmentTitleBar
                            title={`${activityId}`}
                            actions={
                              <AssortmentToolbar
                                onViewClick={onViewClick}
                                onExcelExportClick={onExcelExportClick}
                              />
                            }
                          />

                          <div style={styles.filterChips}>
                            <FilterChips />
                            {chipsArrayToDisplay.length || chipsArrayInDropDown.length ? (
                              <SecondaryButton onClick={() => clearAllFilters()}>
                                <Typography variant='body1'>CLEAR ALL</Typography>
                              </SecondaryButton>
                            ) : null}
                          </div>

                          <CollectionComponent
                            permissions={permissions}
                            activities={(activityData && activityData.activities) || []}
                            betBuyData={betBuyData || []}
                            rangePlanData={rangePlansForBuyingSessionProducts || []}
                          />
                        </React.Fragment>
                      )
                    }}
                  </Query>
                )
              }}
            </Query>
          )
        }}
      </Query>
    ) : (
      <AuthorizationError />
    )
  }
}
