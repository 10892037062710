import { QueryResult } from '@apollo/client/react'
import { Query } from '@apollo/client/react/components'
import { withApollo } from '@apollo/client/react/hoc'
import { CentricTheme, StyledProps } from '@components/3rd-party/mui'
import { CircularLoading } from '@components/UI-Components/CircularLoading'
import { User } from '@models'
import { ViewTabs, ZoneDisplayNames } from '@modules/common/models'
import { GET_ZONE_ASSORTMENT_PRODUCT_COUNT } from '@modules/retail/assortment/graphql/getZoneAssortmentProductCount'
import { STORE_OTB } from '@modules/retail/reports/graphql/storeOTB'
import { RANGE_PLANS } from '@modules/retail/reports/graphql/rangePlans'
import CloseIcon from '@material-ui/icons/Close'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import { Paper } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { getViewTabIndexMap } from '@services/commonServices'
import { storage } from '@services/storageService'
import { stores, strings } from '@stores'
import { observer } from 'mobx-react'
import React from 'react'
import { withRouter } from 'react-router-dom'

const styles = (theme: CentricTheme) => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'left',
    paddingLeft: theme.spacing(3),
    flex: 1
  },
  root: {
    width: '100%',
    overflowX: 'auto'
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: theme.spacing(3)
  },
  detail: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.spacing(1)
  },
  paper: {
    position: 'absolute',
    marginBottom: theme.spacing(1),
    border: '3px solid lightgrey'
  },
  content: {
    flex: '1 0 auto',
    margin: theme.spacing(1)
  },
  main: {
    display: 'flex'
  },
  border: {
    borderRight: '2px solid lightgrey'
  },
  flexEnd: {
    alignSelf: 'flex-end'
  },
  rowContent: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(1)
  },
  hr: {
    border: 'none',
    borderLeft: '1px solid hsla(200, 10%, 50%,100);',
    height: '80vh;'
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    zIndex: 99
  }
})

interface IReportingModal extends StyledProps {
  open: boolean
  onClose: () => void
  zoneReportingData?: any
  storeReportingData?: any
  zone?: any
  store?: any
  buyingSession?: any
  match?: any
  assortmentType?: any
  betBuyData?: any
  selectedTab?: number
}

@(withRouter as any)
@(withApollo as any)
@observer
export class AssortmentReportingModalComponent extends React.Component<IReportingModal> {
  render() {
    const [
      {
        props: {
          classes,
          open,
          onClose,
          zone,
          store,
          buyingSession,
          assortmentType,
          betBuyData,
          selectedTab,
          match: {
            params: { assortmentId, genderId, buyingSessionId, activityId }
          }
        }
      },
      {
        assortment: {
          getZoneReportingBetData,
          getZoneReportingBuyData,
          getStoreReportingBetData,
          getStoreReportingBuyData
        },
        product: { markUpList },
        nav: {
          params: { view }
        }
      }
    ] = [this, stores]

    const user = storage.getItem<User>('user')
    const role = user.roles[0].name
    const tabIndexMap = getViewTabIndexMap(view as string)
    return (
      <Query
        query={STORE_OTB}
        variables={{
          storeId: store ? store.id : null,
          buyingSessionId: buyingSession.id
        }}
        skip={assortmentType !== strings.store || !(buyingSession && zone)}
      >
        {({ data: storeOTBData }: QueryResult) => {
          return (
            <Query
              query={RANGE_PLANS}
              variables={{
                genderId: genderId,
                activityId: buyingSession.activity ? buyingSession.activity.id : '',
                collectionId: buyingSession.collection ? buyingSession.collection.id : '',
                zoneId: zone.id
              }}
              skip={!(buyingSession && zone)}
            >
              {({ loading, error, data: rangePlanData }: QueryResult) => {
                return (
                  <Query
                    query={GET_ZONE_ASSORTMENT_PRODUCT_COUNT}
                    fetchPolicy='network-only'
                    variables={{ assortmentId, buyingSessionId }}
                  >
                    {({ loading, error, data }: QueryResult) => {
                      if (loading)
                        return (
                          <div className={classes.loader}>
                            <CircularLoading />
                          </div>
                        )
                      if (error) return <div>Error</div>
                      const { zoneAssortmentProductsConnection } = data
                      const {
                        zoneOtbValueWithCurrency,
                        zoneValueConsumption,
                        zoneActualValueWithCurrency,
                        zoneOtbQty,
                        zoneQtyConsumption,
                        zoneActualQty,
                        zoneAc,
                        zoneAsp,
                        zoneAverageDepth
                      } =
                        selectedTab === tabIndexMap[ViewTabs.BET]
                          ? zoneAssortmentProductsConnection &&
                            getZoneReportingBetData(
                              betBuyData,
                              assortmentType,
                              zoneAssortmentProductsConnection,
                              rangePlanData && rangePlanData.rangePlans,
                              role,
                              markUpList,
                              zone,
                              activityId
                            )
                          : zoneAssortmentProductsConnection &&
                            getZoneReportingBuyData(
                              betBuyData,
                              assortmentType,
                              zoneAssortmentProductsConnection,
                              rangePlanData && rangePlanData.rangePlans,
                              role,
                              markUpList,
                              zone,
                              activityId
                            )
                      const {
                        storeOtbValue,
                        storeValueConsumption,
                        storeActualValueWithCurrency,
                        storeActualQty,
                        storeAc,
                        storeAsp,
                        storeAverageDepth
                      } =
                        selectedTab === tabIndexMap[ViewTabs.BET]
                          ? getStoreReportingBetData(
                              betBuyData,
                              storeOTBData,
                              role,
                              markUpList,
                              zone,
                              activityId
                            )
                          : getStoreReportingBuyData(
                              betBuyData,
                              storeOTBData,
                              role,
                              markUpList,
                              zone,
                              activityId
                            )
                      return (
                        <Drawer
                          anchor='bottom'
                          open={open}
                          onClose={onClose}
                          variant='persistent'
                          classes={{ paper: classes.paper }}
                        >
                          <div tabIndex={0} role='button'>
                            <Paper className={classes.root}>
                              <div className={classes.rowContent}>
                                <div className={classes.title}>
                                  <Typography variant='subtitle2'>
                                    {`${ZoneDisplayNames[zone.name] || zone.name} ${
                                      buyingSession.gender.description
                                    } ${buyingSession.activity.description} ${
                                      selectedTab === tabIndexMap[ViewTabs.BET]
                                        ? 'BET'
                                        : 'BUY'
                                    } QUICK REPORT`}
                                  </Typography>
                                  <IconButton
                                    aria-label='Close'
                                    className={classes.closeButton}
                                    onClick={onClose}
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                </div>
                                <div className={classes.main}>
                                  <div className={`${classes.container}`}>
                                    <div className={classes.details}>
                                      <div className={classes.content}>
                                        <Typography variant='subtitle2'>
                                          OTB VALUE
                                        </Typography>
                                        <Typography variant='subtitle2'>
                                          {zoneOtbValueWithCurrency}
                                        </Typography>
                                      </div>
                                      <div className={classes.content}>
                                        <Typography
                                          variant='subtitle2'
                                          color='textSecondary'
                                        >
                                          Actual Value
                                        </Typography>
                                        <Typography
                                          variant='subtitle2'
                                          color='textSecondary'
                                        >
                                          {zoneActualValueWithCurrency}
                                        </Typography>
                                      </div>
                                    </div>
                                    <div className={classes.details}>
                                      <div className={classes.content}>
                                        <Typography
                                          variant='subtitle2'
                                          color='textSecondary'
                                        >
                                          Consumption %
                                        </Typography>
                                        <Typography
                                          variant='subtitle2'
                                          color='textSecondary'
                                        >
                                          {`${zoneValueConsumption} %`}
                                        </Typography>
                                      </div>
                                    </div>
                                  </div>
                                  <hr />
                                  <div className={`${classes.container}`}>
                                    <div className={classes.details}>
                                      <div className={classes.content}>
                                        <Typography variant='subtitle2'>
                                          OTB QTY
                                        </Typography>
                                        <Typography variant='subtitle2'>
                                          {zoneOtbQty}
                                        </Typography>
                                      </div>
                                      <div className={classes.content}>
                                        <Typography
                                          variant='subtitle2'
                                          color='textSecondary'
                                        >
                                          Actual Quantity
                                        </Typography>
                                        <Typography
                                          variant='subtitle2'
                                          color='textSecondary'
                                        >
                                          {zoneActualQty}
                                        </Typography>
                                      </div>
                                    </div>
                                    <div className={classes.details}>
                                      <div className={classes.content}>
                                        <Typography
                                          variant='subtitle2'
                                          color='textSecondary'
                                        >
                                          Consumption %
                                        </Typography>
                                        <Typography
                                          variant='subtitle2'
                                          color='textSecondary'
                                        >
                                          {`${zoneQtyConsumption} %`}
                                        </Typography>
                                      </div>
                                    </div>
                                  </div>
                                  <hr />
                                  <div
                                    className={`${classes.container} ${classes.flexEnd}`}
                                  >
                                    <div className={classes.details}>
                                      <div className={classes.content}>
                                        <Typography
                                          variant='subtitle2'
                                          color='textSecondary'
                                        >
                                          {`NB ACs ${
                                            selectedTab === tabIndexMap[ViewTabs.BET]
                                              ? 'BET'
                                              : 'BUY'
                                          }`}
                                        </Typography>
                                        <Typography
                                          variant='subtitle2'
                                          color='textSecondary'
                                        >
                                          {zoneAc}
                                        </Typography>
                                      </div>
                                    </div>
                                    <div className={classes.details}>
                                      <div className={classes.content}>
                                        <Typography
                                          variant='subtitle2'
                                          color='textSecondary'
                                        >
                                          ASP
                                        </Typography>
                                        <Typography
                                          variant='subtitle2'
                                          color='textSecondary'
                                        >
                                          {zoneAsp}
                                        </Typography>
                                      </div>
                                    </div>
                                    <div className={classes.details}>
                                      <div className={classes.content}>
                                        <Typography
                                          variant='subtitle2'
                                          color='textSecondary'
                                        >
                                          Avg Depth by AC
                                        </Typography>
                                        <Typography
                                          variant='subtitle2'
                                          color='textSecondary'
                                        >
                                          {zoneAverageDepth}
                                        </Typography>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {assortmentType === strings.store ? (
                                  <>
                                    <Divider variant='middle' />
                                    <div className={classes.title}>
                                      <Typography variant='subtitle2'>
                                        {store.name}
                                      </Typography>
                                    </div>
                                    <div className={classes.main}>
                                      <div className={`${classes.container}`}>
                                        <div className={classes.details}>
                                          <div className={classes.content}>
                                            <Typography variant='subtitle2'>
                                              OTB VALUE
                                            </Typography>
                                            <Typography variant='subtitle2'>
                                              {storeOtbValue}
                                            </Typography>
                                          </div>
                                          <div className={classes.content}>
                                            <Typography
                                              variant='subtitle2'
                                              color='textSecondary'
                                            >
                                              Actual Value
                                            </Typography>
                                            <Typography
                                              variant='subtitle2'
                                              color='textSecondary'
                                            >
                                              {storeActualValueWithCurrency}
                                            </Typography>
                                          </div>
                                        </div>
                                        <div className={classes.details}>
                                          <div className={classes.content}>
                                            <Typography
                                              variant='subtitle2'
                                              color='textSecondary'
                                            >
                                              Consumption %
                                            </Typography>
                                            <Typography
                                              variant='subtitle2'
                                              color='textSecondary'
                                            >
                                              {`${storeValueConsumption} %`}
                                            </Typography>
                                          </div>
                                        </div>
                                      </div>
                                      <hr />
                                      <div
                                        className={`${classes.container} ${classes.flexEnd}`}
                                      >
                                        <div className={`${classes.details}`}>
                                          <div className={classes.content}>
                                            <Typography
                                              variant='subtitle2'
                                              color='textSecondary'
                                            >
                                              Actual Quantity
                                            </Typography>
                                            <Typography
                                              variant='subtitle2'
                                              color='textSecondary'
                                            >
                                              {storeActualQty}
                                            </Typography>
                                          </div>
                                        </div>
                                      </div>
                                      <hr />
                                      <div
                                        className={`${classes.container} ${classes.flexEnd}`}
                                      >
                                        <div className={classes.details}>
                                          <div className={classes.content}>
                                            <Typography
                                              variant='subtitle2'
                                              color='textSecondary'
                                            >
                                              {`NB ACs ${
                                                selectedTab === 1 ? 'BET' : 'BUY'
                                              }`}
                                            </Typography>
                                            <Typography
                                              variant='subtitle2'
                                              color='textSecondary'
                                            >
                                              {storeAc}
                                            </Typography>
                                          </div>
                                        </div>
                                        <div className={classes.details}>
                                          <div className={classes.content}>
                                            <Typography
                                              variant='subtitle2'
                                              color='textSecondary'
                                            >
                                              ASP
                                            </Typography>
                                            <Typography
                                              variant='subtitle2'
                                              color='textSecondary'
                                            >
                                              {storeAsp}
                                            </Typography>
                                          </div>
                                        </div>
                                        <div className={classes.details}>
                                          <div className={classes.content}>
                                            <Typography
                                              variant='subtitle2'
                                              color='textSecondary'
                                            >
                                              Avg Depth by AC
                                            </Typography>
                                            <Typography
                                              variant='subtitle2'
                                              color='textSecondary'
                                            >
                                              {storeAverageDepth}
                                            </Typography>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : null}
                              </div>
                            </Paper>
                          </div>
                        </Drawer>
                      )
                    }}
                  </Query>
                )
              }}
            </Query>
          )
        }}
      </Query>
    )
  }
}

@((withStyles as any)(styles))
export class AssortmentReportingModal extends AssortmentReportingModalComponent {}
