import { config } from '@config'
import { EXTERNALIMAGEFRAMES, EXTERNALIMAGEPREFIX, EXTERNALVIDEOPREFIX } from '@constants'
import { ImageAttributes, Modules, Operations } from '@modules/common'
import { VIPChannel } from '@modules/common/models/enums/VIPChannel'
import { VIPClientType } from '@modules/common/models/enums/VIPClientType'
import { getMediaCentricURI } from '@services/commonServices'
import { stores, strings } from '@stores'
import buyingSessionGroupStore from '@stores/BuyingSessionGroup'
import { nav } from '@stores/nav'
import { getEnumValue } from '@utils/get-enum-value'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import { action, computed, observable } from 'mobx'
import { computedFn } from 'mobx-utils'
import qs from 'query-string'
import detailsMappings, { RETAIL_DETAILS_MAPPING } from './detailsMappings'
import { SAProductDetailsDataStore } from './SAProductDetailsDataStore'

export class SAProductDetailsUIStore extends SAProductDetailsDataStore {
  @observable isProductDetailsPopupOpen: boolean = false

  @computed get isProductAddedOrRemoved() {
    return !this.isProductDetailsPopupOpen && this.productAddedOrRemoved
  }

  @action
  toggleProductDetailPopup = () => {
    this.isProductDetailsPopupOpen = !this.isProductDetailsPopupOpen
    // on close evict bspId query param
    if (!this.isProductDetailsPopupOpen) {
      const {
        nav: {
          location: { pathname },
          history,
          queryParams
        }
      } = stores
      history.push({
        pathname: pathname,
        search: qs.stringify(omit(queryParams, 'bspId'))
      })
    }
  }

  @computed get product() {
    return this.buyingSessionProduct
  }
  @computed get productImages() {
    const images = []
    let iframeURL = ''
    let videoURL = ''
    const externalImages = this.buyingSessionProduct?.original?.externalImages
    const defaultImage = this.buyingSessionProduct?.original?.defaultImage
    if (!isEmpty(externalImages)) {
      Object.keys(externalImages).forEach((img, i) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [prefix, range] = img.split('_')
        if (+range <= EXTERNALIMAGEFRAMES) {
          images.push({
            id: i,
            src: externalImages[img]
          })
        }
      })
      images.sort((firstImage, secondImage) =>
        firstImage.src.toLowerCase().localeCompare(secondImage.src.toLowerCase())
      )
      iframeURL = externalImages[`${EXTERNALIMAGEPREFIX}_360`]
      videoURL = externalImages[EXTERNALVIDEOPREFIX]
    } else if (!isEmpty(defaultImage)) {
      images.push({
        id: 'defaultImage',
        src: getMediaCentricURI({
          URL: defaultImage,
          Attribute: ImageAttributes.LargeImage,
          Module: Modules.Publisher,
          Operation: Operations.GetFromNode
        })
      })
    }
    return { images, iframeURL, videoURL }
  }

  @computed get isBuyingSessionProductBasicInformationLoading() {
    return this.productBasicInformationLoading
  }
  @computed get isBuyingSessionProductExtraInformationLoading() {
    return this.productExtraInformationLoading
  }

  getTagName = computedFn(
    (rawTagName: string) => (rawTagName && rawTagName.split(':')[1]) || ''
  )

  @action
  toggleFavoriteStatus = () => {
    this.toggleProductFavoriteStatus()
  }

  @action onBackClick = () => {
    const {
      nav: {
        location: { pathname },
        history,
        queryParams
      }
    } = stores
    history.push({
      pathname: pathname,
      search: qs.stringify(omit(queryParams, 'bspId'))
    })
  }

  @action redirectUser = menu => {
    const {
      showroomHeader: { openRoute, menuConfig }
    } = stores
    const config = this.getUrl(menuConfig, menu)
    openRoute(config.url)()
  }

  @action getUrl = (menuConfig, title) => {
    return menuConfig.find(menu => menu.title === title)
  }

  @computed get detailedPanelData() {
    const {
      nav: {
        queryParams: { clientType }
      }
    } = stores
    const isRetailClientType = clientType === getEnumValue(VIPClientType.Internal)
    const mappings = isRetailClientType
      ? [...detailsMappings, ...RETAIL_DETAILS_MAPPING]
      : detailsMappings

    return mappings.map(({ label, value, withC8Label }) => {
      const tagValue = get(this.buyingSessionProduct, value, strings.notAvailable)
      return {
        label,
        value: withC8Label ? this.getTagName(tagValue) : tagValue
      }
    })
  }

  @action
  updateUIRating = (ratingId: string, value: string, ratingTypeId: string) => {
    this.updateRating(ratingId, value, ratingTypeId)
  }

  @action
  reset = () => {
    const {
      nav: {
        location: { pathname },
        history,
        queryParams
      }
    } = stores
    // update query params, if parent cmp unmounts
    this.isProductDetailsPopupOpen = false
    if (queryParams?.bspId) {
      history.push({
        pathname: pathname,
        search: qs.stringify(omit(queryParams, 'bspId'))
      })
    }
    this.productAddedOrRemoved = false
  }

  // ** Order Product **
  @computed get isOrderProduct() {
    return this.buyingSessionProduct?.isOrderProductExist
  }

  @computed get isOrderPage() {
    const {
      nav: {
        location: { pathname }
      }
    } = stores
    return pathname.endsWith('/order')
  }

  @computed get validBSGStatuses() {
    const { buyingSessionGroupId, clientType } = nav.queryParams
    return buyingSessionGroupStore.hasStatusesByClientType(
      buyingSessionGroupId,
      VIPClientType[clientType] || VIPClientType.External,
      {
        [VIPChannel.Wholesale]: [
          config.appConfig.enumerations.buyingSessionStatus.BUYING_SESSION_STATUS_VALUES
            .BUYING
        ],
        [VIPChannel.Retail]: [
          config.appConfig.enumerations.retailBuyingSessionStatus
            .BUYING_SESSION_STATUS_VALUES.BUYING
        ]
      }
    )
  }
}
