import jwt from 'jsonwebtoken'
import { setContext } from '@apollo/client/link/context'
import { storage } from './storageService'
import cuid from 'cuid'

/**
 * Returns HTTP headers required for authenticated calls.
 * @returns {object}
 */
const addAuthenticationHeadersAndRefreshTokenIfNeeded = (
  { headers, skipAuth },
  isC8Client: boolean = false
) => {
  // Ignore access token check in case of login operation
  if (!skipAuth) {
    const currentTime = Math.floor(new Date().getTime() / 1000)
    const accessTokenExp = storage.getItem('access_token_expiry_date')
      ? parseInt(storage.getItem('access_token_expiry_date'), 10)
      : undefined

    if (accessTokenExp) {
      if (accessTokenExp <= currentTime) {
        navigateToLogout()
      } else {
        // get the authentication token from local storage if it exists
        const token = storage.getItem('access_token')

        let authorizationHeader = ''
        if (isC8Client) {
          authorizationHeader = storage.getItem('session_url') || ''
        } else {
          authorizationHeader = token ? `Bearer ${token}` : ''
        }
        // return the headers to the context so httpLink can read them
        return {
          headers: {
            ...headers,
            // 'c8-context-id': `${storage.getItem('contextId')}/${Math.floor(
            //   Math.random() * 1000000
            // )}`, // For Performance analyze purposes only
            authorization: authorizationHeader
          }
        }
      }
    } else {
      navigateToLogout()
    }
  }
}

const navigateToLogout = () => {
  const {
    location: { pathname }
  } = window
  if (pathname !== '/logout' && pathname !== '/login') {
    window.location.pathname = '/logout'
  }
}

/**
 * Auth link for Prisma application server Apollo Client instance
 * Returns HTTP headers required for authenticated calls. Checks for required
 * authentications tokens, generates new refresh token if required.
 *
 * @returns {object}
 */
const authLink = setContext((operation, { headers, skipAuth }) => {
  return addAuthenticationHeadersAndRefreshTokenIfNeeded({
    headers,
    skipAuth
  })
})

/**
 * Auth link for Centric8 Apollo Client instance
 * Returns HTTP headers required for authenticated calls. Checks for required
 * authentications tokens, generates new refresh token if required.
 *
 * @returns {object}
 */
const centricAuthLink = setContext((operation, { headers, skipAuth }) => {
  return addAuthenticationHeadersAndRefreshTokenIfNeeded(
    {
      headers,
      skipAuth
    },
    true
  )
})

/**
 * Stores access token
 *
 * @param {string} token
 */
const saveAccessToken = token => {
  const decodedAccessTokenValue: any = jwt.decode(token)
  storage.setItem('access_token_expiry_date', decodedAccessTokenValue.exp)
  storage.setItem('access_token', token)
  if (decodedAccessTokenValue && decodedAccessTokenValue.sessionUrl) {
    storage.setItem('session_url', decodedAccessTokenValue.sessionUrl)
  }
}

/**
 * Stores refresh token and it's expiry in local storage
 */
const saveRefreshToken = token => {
  const decodedRefeshTokenValue: any = jwt.decode(token)
  storage.setItem('refresh_token_expiry_date', decodedRefeshTokenValue.exp)
  storage.setItem('refresh_token', token)
}

/**
 * Stores User object
 *
 * @param {object} user
 */
const saveUser = user => {
  storage.setItem('user', user)
}

/**
 * Removes all information related to user (access tokens, refresh token)
 */
const invalidateUser = () => {
  storage.deleteItem('access_token')
  storage.deleteItem('session_url')
  storage.deleteItem('user')
  storage.deleteItem('channel')
  storage.clearStorage()
}

/**
 * Checks if user is authenticated
 */
const isAuthenticated = () => {
  return storage.getItem('access_token')
}

const generateContextId = () => {
  storage.setItem('contextId', cuid())
}

export {
  authLink,
  centricAuthLink,
  saveAccessToken,
  saveRefreshToken,
  invalidateUser,
  isAuthenticated,
  saveUser,
  generateContextId
}
