import React from 'react'
import Skeleton from 'react-loading-skeleton'
import * as css from './SAProductDetailsTextLoading.css'

export const SAProductDetailsTextLoading = () => {
  return (
    <div className={css.text}>
      <Skeleton width={300} height={30} />
    </div>
  )
}
