import { RootNodeStatus } from './../Components/TreeNav'
import { RootInfo, TreeTable } from '@components/UI-Components/StandardTreeView'
import { observable, action, runInAction } from 'mobx'
import mapValues from 'lodash/mapValues'
import cloneDeep from 'lodash/cloneDeep'

class TreeViewStore {
  tableRef: any = []
  tableHeader: string
  rootInfo: RootInfo
  disableRootToggle = false
  scrollPluginHandler = null
  expandedKeys: {
    [key: string]: boolean
  } = {}

  @observable selectedNavItems: Array<string>
  @observable inputTableData: TreeTable = {}
  @observable selectedKey: string
  @observable isRightHandNavOpen: boolean = false
  @observable triggerRerender: boolean = false

  /**
   * InitStore
   * 1. Compute flat list of table nodes
   * 2. Initialize selected Nav element
   * 3. Initialized expandedKeys so that
   * everything will be expanded by default
   */
  @action InitStore = (
    inputTableData: TreeTable,
    rootInfo: RootInfo,
    rootNodeStatus?: Array<RootNodeStatus>
  ) => {
    this.tableHeader = rootInfo.root && rootInfo.root.id ? rootInfo.root.id : undefined
    this.rootInfo = rootInfo
    let tableData = cloneDeep(inputTableData)
    if (rootNodeStatus && rootNodeStatus.length && rootInfo.root) {
      this.disableRootToggle = rootNodeStatus && rootNodeStatus.length === 1
      tableData[this.rootInfo.root.id].children = this.getFilteredChildren(rootNodeStatus)
    }
    this.inputTableData = tableData
    this.expandedKeys = mapValues(inputTableData, () => true)
  }

  /**
   * setStickyTableRef
   * Sets table ref to store
   */
  @action setStickyTableRef = (tableRef: any, scrollHandler: Function) => {
    this.tableRef = tableRef
    this.scrollPluginHandler = scrollHandler
    this.onScroll({ scrollTop: this.tableRef.state.scrollTop })
  }

  /**
   * Sets open/close status of
   * right side nav
   */
  @action setRightHandNavStatus = status => {
    this.isRightHandNavOpen = status
  }

  /**
   * Returns to level children depending
   * on their visibility statuses
   */
  getFilteredChildren = rootNodeStatus => {
    if (rootNodeStatus && rootNodeStatus.length) {
      return rootNodeStatus
        .filter(node => {
          return node.visible
        })
        .map(item => item.id)
    } else {
      return []
    }
  }

  /**
   * onNavSelectionChange
   * Once user click on NAV element this triggers
   * below actions.
   * 1. Scroll that element to top of the table
   * 2. Sets clicked element as selected nav element
   * 3. Handle toggling of node by controlling expandedKeys
   */
  @action onNavSelectionChange = (key: string) => {
    const nodes = this.tableRef.nodes
    const targetElement = nodes.find(item => item.id === key)
    this.setNavSelection(
      [key]
        .concat(this.getAllParents(targetElement))
        .concat(this.getAllFirstChildren(targetElement))
    )
    this.tableRef.scrollNodeIntoView(key)
  }

  /**
   * setNavSelection
   * Sect currently selected NAV nodes
   * @param id
   */
  setNavSelection = (list: Array<string>) => {
    this.selectedNavItems = list
  }

  /**
   * getAllParents
   * Returns all parents to given node
   */
  getAllParents = (targetNode: any) => {
    const visibleParent = []
    if (targetNode && targetNode.parentInfo) {
      let parent = targetNode.parentInfo
      while (parent) {
        visibleParent.push(parent.id)
        parent = parent.parentInfo ? parent.parentInfo : null
      }
    }
    return visibleParent
  }

  /**
   * getAllFirstChildren
   * Returns all first children of given
   * node
   */
  getAllFirstChildren = (targetNode: any) => {
    const visibleChildren = []
    const nodes = this.tableRef.nodes
    if (targetNode && targetNode.children) {
      let child = nodes[targetNode.children[0]]
      while (child) {
        visibleChildren.push(child)
        child = child.children ? nodes[child.children[0]] : null
      }
    }
    return visibleChildren
  }

  /**
   * onScroll
   * Sets closest row element in table as selected NAV element
   * during free scroll.
   * @param scrollReason
   */
  onScroll = (data: any) => {
    const { scrollTop, scrollReason = 'observed' } = data

    // Execute below logic only when user explicitly scrolls
    // the view. (And not when he scrolls indirectly through side nav)
    if (scrollReason === 'observed') {
      if (this.scrollPluginHandler) {
        this.scrollPluginHandler()
      }
      const {
        tableRef: {
          elem: { clientHeight, scrollHeight }
        }
      } = this

      const nodes = this.tableRef.nodes

      // If scroll top sits within the 'top' and 'end'
      // of element. Nav bar should highlight
      // that particular element.
      // eslint-disable-next-line array-callback-return
      const selectedNode = nodes.find((item: any) => {
        if (item && !item.children) {
          const itemStart = item.top - item.stickyTop
          let itemEnd = itemStart + item.totalHeight
          return itemStart <= scrollTop && itemEnd >= scrollTop
        }
      })

      if (selectedNode) {
        this.setNavSelection([selectedNode.id].concat(this.getAllParents(selectedNode)))
      }

      /**
       * Hack for rendering last nodes when number of products in last two categories are
       * one. Need to replace this with correct solution
       */
      const currentScrollHeight = Math.ceil(scrollTop) + clientHeight
      if (currentScrollHeight >= scrollHeight && !this.triggerRerender) {
        this.triggerRerender = true
        runInAction(() => {
          this.tableRef.elem.scrollTop--
          this.tableRef.elem.scrollTop++
        })
      } else if (currentScrollHeight < scrollHeight && this.triggerRerender) {
        this.triggerRerender = false
      }
    }
  }
}

export const StandardTreeViewStore = new TreeViewStore()
