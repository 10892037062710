import CircularProgress from '@material-ui/core/CircularProgress'
import React from 'react'
import styled from 'styled-components'

export const CircularLoading = styled(({ ...rest }) => <CircularProgress {...rest} />)(
  props => ({
    '&&': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      color: 'var(--secondary)',
      zIndex: props?.zIndex ? props?.zIndex : 'auto'
    }
  })
)
export const CircularLoadingProgress = styled(CircularProgress).attrs(() => ({
  color: 'secondary'
}))`
  display: flex;
  justify-content: center;
`
