import { ListViewColumnDefinition } from '@components/UI-Components/StandardTableView/models/ListviewColumnDefinition'
import {
  ColumnWidth,
  FontTShirtSize,
  ListViewColumnIdentifiers,
  ListViewColumnLabels,
  ListViewFieldMapping,
  ListViewFontWeights,
  TextAlignment
} from '@modules/common/models'
import { VIPDoorOrders } from '@services/wholesale/wholesaleCollectionService'
import forEach from 'lodash/forEach'
import isEmpty from 'lodash/isEmpty'
import { saCollectionListViewComponentService } from './SACollectionListViewComponentService'

export const DOORPREFIX = 'door'

class OrderListViewDefinitionService {
  buildColumnDefinition = (
    doorOrders: Array<VIPDoorOrders>,
    useValueKPI = false,
    useSizeQty = false,
    isSAView = true
  ) => {
    let mixKey
    let qtyKey
    if (!useValueKPI) {
      if (useSizeQty) {
        mixKey = ListViewFieldMapping.mixRatioBySizedQty
        qtyKey = ListViewFieldMapping.totalSizedQuantity
      } else {
        mixKey = ListViewFieldMapping.mixRatioByTargetQty
        qtyKey = ListViewFieldMapping.totalTargetQuantity
      }
    } else {
      if (useSizeQty) {
        mixKey = ListViewFieldMapping.mixRatioBySizedValue
        qtyKey = ListViewFieldMapping.totalSizedValueWithCurrency
      } else {
        mixKey = ListViewFieldMapping.mixRatioByTargetValue
        qtyKey = ListViewFieldMapping.totalTargetValueWithCurrency
      }
    }

    let listViewDefinition = [
      new ListViewColumnDefinition({
        id: ListViewColumnIdentifiers.ProductInfo,
        header: {
          label: ListViewColumnLabels.ProductInfo
        },
        width: isSAView ? ColumnWidth.OrderProductInfo : ColumnWidth.StandardProductInfo,
        isFixed: true,
        isRootColumn: true,
        category: null,
        product: {
          component: saCollectionListViewComponentService.getProductInfoComponent,
          key: ListViewFieldMapping.ProductInfo
        }
      }),
      new ListViewColumnDefinition({
        id: ListViewColumnIdentifiers.ActualMixTargetMix,
        header: {
          label: ListViewColumnLabels.ActualMixTargetMix
        },
        props: {
          fontWeight: ListViewFontWeights.Bold
        },
        isFixed: true,
        width: ColumnWidth.Large,
        category: {
          key: mixKey,
          showKPI: true
        },
        product: {
          component: saCollectionListViewComponentService.getProductAttributeComponent,
          key: ListViewFieldMapping.ProductAttributes,
          showRightBorder: true
        }
      }),
      new ListViewColumnDefinition({
        id: ListViewColumnIdentifiers.Quantity,
        header: {
          label: useValueKPI
            ? ListViewColumnLabels.ActualValue
            : ListViewColumnLabels.Quantity
        },
        props: {
          alignment: TextAlignment.right,
          fontWeight: ListViewFontWeights.Bold,
          font: FontTShirtSize.lg
        },
        width: ColumnWidth.Medium,
        category: {
          key: qtyKey,
          showKPI: true
        },
        product: {
          component: saCollectionListViewComponentService.getProductQuantityComponent,
          key: ListViewFieldMapping.actualQuantityList
        }
      })
    ]

    if (!isEmpty(doorOrders)) {
      forEach(doorOrders, doorOrder => {
        let id = doorOrder?.Door?.Id
        let name = doorOrder?.Door?.Name ?? ''
        const doorCounts = doorOrders?.length ?? 0
        const baseKey = `${DOORPREFIX}-${id}` as ListViewFieldMapping
        listViewDefinition.push(
          new ListViewColumnDefinition({
            id,
            header: {
              label: name,
              showTooltip: true
            },
            props: {
              alignment: TextAlignment.right,
              fontWeight: ListViewFontWeights.Bold
            },
            /**
             * Tree view is not responsive
             * but when few doors, having empty
             * space at right looks odd.
             * Using variable TShirt sizes for now
             */
            width:
              doorCounts === 1
                ? ColumnWidth.XL
                : doorCounts === 2
                ? ColumnWidth.Large
                : ColumnWidth.Medium,
            category: {
              key: `${baseKey}-${qtyKey}` as ListViewFieldMapping,
              showKPI: true
            },
            product: {
              component: saCollectionListViewComponentService.getDoorLevelComponent,
              key: baseKey
            }
          })
        )
      })
    }
    return listViewDefinition
  }
}

export const orderListViewDefinitionService = new OrderListViewDefinitionService()
