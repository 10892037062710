import { config } from '@config'
import { VIPChannel } from '@modules/common/models/enums/VIPChannel'
import { CriteriaOperationType } from '@modules/models'
import { BUYING_SESSION_GROUP_PRODUCT_SUMMARY } from '@modules/wholesale/buyingSessionGroupProductWrapper/graphql/buyingSessionProductSummary'
import { isZAM } from '@services/commonServices'
import { apolloClient } from '@stores'
import groupBy from 'lodash/groupBy'
import { action, observable } from 'mobx'
import CREATE_FREE_TAG_SPEC_ITEM from '../graphql/createFreeTagSpecItem'
import DELETE_FREE_TAG_SPEC_ITEM from '../graphql/deleteFreeTagSpecItem'
import GET_FREE_TAGS from '../graphql/getFreeTags'
import UPDATE_FREE_TAG_SPEC_ITEM from '../graphql/updateFreeTagSpecItem'

export class FreeTagListStore {
  @observable operationInProgress = false
  @observable tagsLoading = false
  @observable showCreateNewFreeTag = false
  @observable showCreateNewFreeTagRTL = false
  @observable wholesaleFreeTags = []
  @observable retailFreeTags = []

  resetTags = () => {
    this.wholesaleFreeTags = []
    this.retailFreeTags = []
    this.showCreateNewFreeTag = false
    this.showCreateNewFreeTagRTL = false
  }

  // For both the Channels
  getAllFreeTagData = async (channels, buyingSessionGroupId) => {
    const queries = []
    channels.forEach(channel => {
      queries.push(
        apolloClient.query({
          query: GET_FREE_TAGS,
          variables: {
            channel,
            buyingSessionGroupId
          },
          fetchPolicy: 'network-only'
        })
      )
    })
    try {
      this.tagsLoading = true
      const data = await Promise.all(queries)
      data.forEach(freeTagData => {
        const freeTags = freeTagData?.data?.findSpecificationItemDefinition?.Items ?? []
        if (freeTags.length && freeTags[0]?.Channel === VIPChannel.Wholesale) {
          this.wholesaleFreeTags = freeTags
        } else if (freeTags.length && freeTags[0]?.Channel === VIPChannel.Retail) {
          this.retailFreeTags = freeTags
        }
      })
    } finally {
      this.tagsLoading = false
    }
  }

  getFreeTagsByBSGId = async buyingSessionGroupId => {
    try {
      this.tagsLoading = true
      const freeTagData = await apolloClient.query({
        query: GET_FREE_TAGS,
        variables: {
          buyingSessionGroupId
        },
        fetchPolicy: 'network-only'
      })
      const freeTags = freeTagData?.data?.findSpecificationItemDefinition?.Items ?? []
      const freeTagsByChannel = groupBy(freeTags, 'Channel')
      this.wholesaleFreeTags = freeTagsByChannel?.[VIPChannel.Wholesale] ?? []
      this.retailFreeTags = freeTagsByChannel?.[VIPChannel.Retail] ?? []
    } finally {
      this.tagsLoading = false
    }
  }

  @action
  toggleCreateFreeTag = (channel: string) => {
    if (channel === VIPChannel.Retail) {
      this.showCreateNewFreeTagRTL = !this.showCreateNewFreeTagRTL
    } else {
      this.showCreateNewFreeTag = !this.showCreateNewFreeTag
    }
  }

  @action
  createNewTag = async (description, bsgId, channel) => {
    this.operationInProgress = true
    try {
      await apolloClient.mutate({
        mutation: CREATE_FREE_TAG_SPEC_ITEM,
        variables: { description, bsgId, channel }
      })
      await this.getFreeTagsByBSGId(bsgId)
    } finally {
      this.toggleCreateFreeTag(channel)
      this.operationInProgress = false
    }
  }

  /**
   * where input for wholesale channel check
   */
  whereInputForChannels = () => {
    const wholesaleChannelValue =
      config.appConfig?.enumerations?.channel?.VALUES?.WHOLESALE
    return {
      operation: CriteriaOperationType.EQ,
      value: wholesaleChannelValue
    }
  }

  whereInputForBSPs = bsgId => {
    const isZAMUserLoggedIn = isZAM()
    return {
      __Parent__: {
        __Parent__: {
          Id: { operation: CriteriaOperationType.EQ, value: bsgId }
        }
      },
      ...(isZAMUserLoggedIn && {
        Channels: this.whereInputForChannels()
      })
    }
  }

  @action
  onEdit = async (description, tag, bsgId) => {
    const where = this.whereInputForBSPs(bsgId)
    const refetchQuery = {
      query: BUYING_SESSION_GROUP_PRODUCT_SUMMARY,
      variables: {
        where
      }
    }

    this.operationInProgress = true
    try {
      await apolloClient.mutate({
        mutation: UPDATE_FREE_TAG_SPEC_ITEM,
        variables: { tagId: tag.id, description, channel: tag.Channel },
        awaitRefetchQueries: true,
        refetchQueries: [refetchQuery]
      })
      await this.getFreeTagsByBSGId(bsgId)
    } finally {
      this.operationInProgress = false
    }
  }

  @action
  onDelete = (tag, bsgId) => async () => {
    const where = this.whereInputForBSPs(bsgId)
    const refetchQuery = {
      query: BUYING_SESSION_GROUP_PRODUCT_SUMMARY,
      variables: {
        where
      }
    }

    this.operationInProgress = true
    try {
      await apolloClient.mutate({
        mutation: DELETE_FREE_TAG_SPEC_ITEM,
        variables: { value: tag.id },
        awaitRefetchQueries: true,
        refetchQueries: [refetchQuery]
      })
      await this.getFreeTagsByBSGId(bsgId)
    } finally {
      this.operationInProgress = false
    }
  }
}

export const freeTagListStore = new FreeTagListStore()
