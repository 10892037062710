import { MutationFunctionOptions } from '@apollo/client/react/types/types'
import { GET_ACTIVITIES } from '@modules/retail/clusterSetup/graphql'
import { ExecutionResult } from 'graphql/execution'
import remove from 'lodash/remove'
import { action, observable } from 'mobx'
import { IActivity } from '../graphql/hooks'

export class ActivitySettingStore {
  @observable selectedActivities = []
  @observable activeTagIds = []
  @observable inactiveTagIds = []
  @observable isActivityFiltered = false

  mutations: {
    updateTags?: (
      options?: MutationFunctionOptions<
        {},
        { activeTagIds: string[]; inactiveTagIds: string[] }
      >
    ) => Promise<void | ExecutionResult<{ activities: IActivity }>>
  } = {}

  @action
  handleToggle = e => {
    const tagId = e.target.value
    if (e.target.checked) {
      this.activeTagIds.push(tagId)
      remove(this.inactiveTagIds, id => id === tagId)
    } else {
      this.inactiveTagIds.push(tagId)
      remove(this.activeTagIds, id => id === tagId)
    }
  }

  setActivities = activities => {
    if (activities.length > 0) {
      this.activeTagIds = activities
        .filter(({ active }) => active === true)
        .map(activity => activity.id)
      this.inactiveTagIds = activities
        .filter(({ active }) => active === false)
        .map(activity => activity.id)
      this.isActivityFiltered = true
    }
  }

  @action
  handleSubmit = async () => {
    return await this.mutations.updateTags({
      variables: { activeTagIds: this.activeTagIds, inactiveTagIds: this.inactiveTagIds },
      refetchQueries: [
        {
          query: GET_ACTIVITIES
        }
      ]
    })
  }

  @action resetIsActivityFiltered = () => {
    this.isActivityFiltered = false
  }
}
