import { StyledProps } from '@components/3rd-party/mui'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    // color: theme.colors.white,
    left: 0,
    top: 0,
    borderRadius: 0,
    justifyContent: 'space-between',
    maxHeight: '60px'
  },
  menu: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  icon: {
    margin: theme.spacing(2),
    color: theme.colors.grey,
    cursor: 'pointer',
    '&:hover': {
      color: theme.colors.lightgrey
    }
  }
})
interface IToolBarClasses {
  root?: object
  menu?: object
}
interface IToolBar extends StyledProps, IToolBarClasses {
  leftmenu?: React.ReactNode
  rightmenu?: React.ReactNode
}

export class ToolBarComponent extends React.Component<IToolBar> {
  render() {
    const {
      props: { leftmenu, rightmenu, classes }
    } = this
    return (
      <div className={classes.root}>
        <div className={classes.menu}>{leftmenu}</div>
        <div className={classes.menu}>{rightmenu}</div>
      </div>
    )
  }
}

@((withStyles as any)(styles))
export class ToolBar extends ToolBarComponent {}
