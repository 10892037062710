import '@styles/Typography/style.css'
import cn from 'classnames'
import React, { CSSProperties } from 'react'

export type Variants =
  | 'body1'
  | 'body2'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'subHeading1'
  | 'subHeading2'
  | 'section'
  | 'caption'
  | 'subHeading3'
  | 'list'
  | 'subCategory'
  | 'subCategoryCustom'
  | 'title'
  | 'lookTitle'
  | 'detailTitle'
  | 'caption2'
  | 'section2'
  | 'section3'
  | 'checkboxlabel'
  | 'custom'
  | 'subHeading4'
  | 'buttonlabel'
  | 'toolbarHeader'
  | 'hierarchy'
  | 'h1Custom'
  | 'h1SidebarMenu'
  | 'toolBarTitle'
  | 'toggle'
  | 'label'
  | 'value'
  | 'body2Custom'
  | 'subHeading2Custom'
  | 'subHeading5'

export type Color =
  | 'primary'
  | 'secondary'
  | 'error'
  | 'hierarchy'
  | 'qty-labels'
  | 'blue'
  | 'label'

type Decorators = 'plain' | 'under' | 'over' | 'line'

interface Props {
  variant: Variants
  color?: Color
  decorator?: Decorators
  classes?: string
  style?: CSSProperties
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify'
  title?: string
  onClick?: (...rest: any[]) => void
  disabled?: boolean
}

const variantsMapping: { [key in Variants]: string } = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  subHeading1: 'h2',
  subHeading2: 'h2',
  body1: 'span',
  body2: 'span',
  section: 'p',
  caption: 'span',
  label: 'span',
  toggle: 'span',
  subHeading3: 'h2',
  list: 'span',
  subCategory: 'h2',
  subCategoryCustom: 'h2',
  title: 'h1',
  lookTitle: 'h2',
  detailTitle: 'h1',
  caption2: 'span',
  section2: 'p',
  section3: 'span',
  checkboxlabel: 'h2',
  custom: 'h2',
  subHeading4: 'h2',
  buttonlabel: 'h2',
  toolbarHeader: 'h1',
  hierarchy: 'h2',
  h1Custom: 'h1',
  h1SidebarMenu: 'h1',
  toolBarTitle: 'h1',
  value: 'div',
  body2Custom: 'span',
  subHeading2Custom: 'h2',
  subHeading5: 'h3'
}

const Typography: React.FC<Props> = ({
  variant,
  decorator = 'plain',
  color,
  children,
  align,
  classes,
  title,
  onClick,
  disabled,
  ...props
}): JSX.Element => {
  const Component: any = variant ? variantsMapping[variant] : 'p'
  return (
    <Component
      title={title}
      className={cn({
        [`typography--variant-${variant}`]: variant,
        [`typography--color-${color}`]: color,
        [`typography--align-${align}`]: align,
        [`typography--decorator-${decorator}`]: decorator,
        // eslint-disable-next-line no-useless-computed-key
        ['typography-disabled']: disabled,
        [`${classes}`]: classes
      })}
      onClick={onClick}
      {...props}
    >
      {children}
    </Component>
  )
}

export default Typography
