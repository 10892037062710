import { storage } from '@services/storageService'

/**
 * Get file URL and downlaod the files
 * @param {string} fileURL
 * @param {string} fileName
 * @param {JSON} data
 */

export const exportExcel = (url, data) => {
  const accessToken: string = storage.getItem('access_token')

  let filename = ''
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  })
    .then(function (response: any) {
      const attachFile = response?.headers?.get('Content-Disposition') ?? null
      if (attachFile) {
        filename = attachFile.split('filename=')[1].split(';')[0]
      }
      if (response.status !== 200) {
        return response.json()
      }
      return response.blob()
    })
    .then(response => {
      if (response && response.message) {
        return response
      }
      var url = window.URL.createObjectURL(response)
      var a = document.createElement('a')
      a.href = url
      a.download = filename
      a.click()
      a.remove()
      setTimeout(() => window.URL.revokeObjectURL(url), 100)
      return {
        error: false,
        statusCode: 200
      }
    })
}
