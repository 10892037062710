import { IPermissions } from '@routes'
import { observer } from 'mobx-react'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { CollectionFilterComponent } from '../component/CollectionFilter'

interface IBuyingSession {
  classes?: any
  match?: any
  collectionStore?: any
  permissions?: IPermissions
  filterQuery?: any
  variables?: any
}

@(withRouter as any)
@observer
export class CollectionFilterContainer extends React.Component<IBuyingSession> {
  render() {
    const { filterQuery, variables } = this.props
    return (
      <CollectionFilterComponent
        filterQuery={filterQuery}
        variables={variables}
        permissions={this.props.permissions}
      />
    )
  }
}
