import { EXTERNALIMAGEFRAMES, EXTERNALIMAGEPREFIX, EXTERNALVIDEOPREFIX } from '@constants'
import { ProductDetailsImages } from '@modules/common/models'
import {
  IFreeTag,
  IInfoData,
  ISAProductDetailInfoComponent
} from '@modules/showroom/productDetail/component/SAProductDetailInfoComponent'
import { defaultProductCardImage, BigPlaceholderImage } from '@resources/images'
import { IPermissions } from '@routes'
import { stores } from '@stores'
import { getPermissionsByEntityAndRoleForWholesale } from '@ws-routes/wholesaleRoutes'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import memoize from 'memoize-one'
import { config } from '@config'
import { storage } from '@services/storageService'
import axios from 'axios'

import {
  getEnumDescriptionFromValue,
  getInterfaceImageURLFromKey
} from './commonServices'

const getAttributeValuesForProduct = (attributes, id) => {
  const productAtt = attributes.find(att => att.definition && att.definition.id === id)
  let value = 'NA'
  if (productAtt) {
    switch (productAtt && productAtt.definition.type) {
      case 'String':
        value = productAtt ? productAtt.strVal : null
        break
      case 'Int':
        value = productAtt ? productAtt.intVal : null
        break
      case 'Tag':
        value = productAtt && productAtt.tag ? productAtt.tag.name : null
        break
      default:
        value = productAtt ? productAtt.strVal : null
    }
  }
  return value || 'NA'
}

/**
 * Method for returning attribute value on the basis of definition name
 * @param attributes - List of custom attributes
 * @param {string} definitionName - Attribute definition name
 */
const getAttributeNameValuePair = (attributes, definitionName: string) => {
  const attribute = attributes.find(
    attribute =>
      attribute && attribute.definition && attribute.definition.name === definitionName
  )
  const attributeType = attribute && attribute.definition ? attribute.definition.type : ''

  const label = attribute
    ? attribute.definition.displayName.singular || attribute.name
    : definitionName
  let value = 'NA'

  // TODO: Add remaining types once we have attributes of types other than string, int and tag
  switch (attributeType) {
    case 'String':
      value = attribute ? attribute.strVal : null
      break
    case 'Int':
      value = attribute ? attribute.intVal : null
      break
    case 'Tag':
      value = attribute && attribute.tag ? attribute.tag.name : null
      break
    default:
      value = 'NA'
      break
  }

  return {
    id: attribute ? attribute.id : '',
    label,
    value: value || 'NA'
  }
}

const getAttributeTagIdForProduct = (attributes, id) => {
  const productAtt = attributes.find(att => att.definition && att.definition.id === id)
  const tagId = productAtt && productAtt.tag ? productAtt.tag.id : null
  return tagId
}

const isRestrictedToComment = (zones, restrictedZones) => {
  const {
    site: { isAdmin },
    sidebar: { isRegionalMerchandiser }
  } = stores
  if (isRegionalMerchandiser()) {
    const isZoneExcluded =
      zones &&
      !!zones.find(
        zone => !!restrictedZones.find(restrictedZone => restrictedZone.id === zone.id)
      )
    return !!isZoneExcluded
  } else if (isAdmin()) {
    return true
  } else {
    return false
  }
}

const getOptionsForBuyingSessionProductSizeAttributes = productAttributes => {
  const sizesObject = find(productAttributes, att => att.definition.name === 'sizes')
  return sizesObject.strVal.split(',')
}

/**
 * Function to construct a product detail information object
 * @param attributes
 * @param title
 * @param freeTagEnums
 * @param freeTags
 * @param entity
 */
const getProductDetailInfo = (
  attributes,
  title,
  freeTagEnums,
  freeTags,
  entity = 'buyingSessions'
): ISAProductDetailInfoComponent => {
  const infoData = {}
  attributes.forEach(attr => {
    infoData[attr.name] = attr.value
  })
  infoData['productName'] = title

  const _freeTags = map(freeTags ?? [], freeTag => ({
    title: getEnumDescriptionFromValue(freeTag, freeTagEnums?.Values ?? [])
  })).filter(freeTag => freeTag?.title?.length)

  const commentPermission: IPermissions =
    getPermissionsByEntityAndRoleForWholesale(entity)

  return {
    infoData: infoData as IInfoData,
    freeTags: _freeTags as IFreeTag[],
    commentPermission
  }
}

/**
 * It extracts interface required images
 * to mount on product details page
 * @param interfaceImages
 */
const extractInterfaceImages = (
  interfaceImages: Array<{ Name: string; Value: string }>
): ProductDetailsImages => {
  const images = []
  for (let i = 0; i < EXTERNALIMAGEFRAMES; i++) {
    const src = getInterfaceImageURLFromKey(
      interfaceImages,
      `${EXTERNALIMAGEPREFIX}_${i}`
    )
    if (src) {
      images.push({
        id: i,
        src
      })
    }
  }
  const iframeURL = getInterfaceImageURLFromKey(
    interfaceImages,
    `${EXTERNALIMAGEPREFIX}_360`
  )

  const videoURL = getInterfaceImageURLFromKey(interfaceImages, `${EXTERNALVIDEOPREFIX}`)
  return { images, iframeURL, videoURL }
}

/**
 * It prefers interfaces (like orb) images first, if no interface image found
 * it returns single image if present
 * @param interfaceImages
 * @param singleImageURL
 */
const getProductImagesForDetailsView = memoize(
  (interfaceImages, singleImageURL, isSAView = false): ProductDetailsImages => {
    if (!isEmpty(interfaceImages)) {
      return extractInterfaceImages(interfaceImages)
    } else {
      return {
        images: [
          {
            id: 0,
            src: singleImageURL
              ? singleImageURL
              : isSAView
              ? BigPlaceholderImage
              : defaultProductCardImage
          }
        ]
      }
    }
  }
)

interface DeleteProductForecast {
  buyingSessionProductId: string
  clientId: string
}

const deleteProductForecast = async (
  buyingSessionGroupId: string,
  toBeDeletedForecasts: DeleteProductForecast[]
) => {
  const url = `${config.buyingBoardServiceRestEndpoint}forecast/buyingSessionGroupId/${buyingSessionGroupId}/deleteRecords`
  const accessToken: string = storage.getItem('access_token')
  const response: any = await axios.post(url, toBeDeletedForecasts, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })

  return response
}
/* delete forecast data for client exclusions added */
const deleteForecastFromOrderMgmtSvc = async (
  buyingSessionGroupId: string,
  buyingSessionProductId: string,
  clientExclusions: string[]
) => {
  let toBeDeletedForecasts = [] as DeleteProductForecast[]
  if (clientExclusions?.length > 0) {
    clientExclusions.forEach(clientId => {
      let forecast = {} as DeleteProductForecast
      forecast.buyingSessionProductId = buyingSessionProductId
      forecast.clientId = clientId
      toBeDeletedForecasts.push(forecast)
    })

    if (toBeDeletedForecasts.length > 0) {
      deleteProductForecast(buyingSessionGroupId, toBeDeletedForecasts)
    }
  }
}

export {
  getAttributeValuesForProduct,
  getAttributeTagIdForProduct,
  getAttributeNameValuePair,
  isRestrictedToComment,
  getOptionsForBuyingSessionProductSizeAttributes,
  getProductDetailInfo,
  getProductImagesForDetailsView,
  deleteForecastFromOrderMgmtSvc
}
