export const PRODUCT_STATUSES = [
  { id: 'CORE', name: 'Core' },
  { id: 'NON_CORE', name: 'Non Core' }
]

export const RETAIL_STATUSES = [
  { id: 'SEASONAL', name: 'Seasonal' },
  { id: 'CARRYOVER', name: 'Carryover' },
  { id: 'START_CARRYOVER', name: 'Start Carryover' },
  { id: 'CROSS_CARRYOVER', name: 'Cross Carryover' },
  { id: 'END_CARRYOVER', name: 'End Carryover' },
  { id: 'KEEP_IN_MERCH', name: 'Keep In Merch' }
]
