import { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions'
import { useQuery } from '@apollo/client/react/hooks/useQuery'
import GET_RETAIL_ZONES from './getRetailZones'
import GET_WHOLESALE_GENDERS from './getWholesaleGenders'
import GET_WHOLESALE_RETAIL_SEASONS from './getWholesaleSeasons'
import WHOLESALE_ZONES from './zones'

export const useWholesaleSeasons = () => {
  return useQuery(GET_WHOLESALE_RETAIL_SEASONS)
}
export const useGetWholesaleGenders = (query: string) => {
  return useQuery(query as any)
}

/**
 * Hook for querying wholesale genders
 */
export const useWholesaleGenders = () => {
  return useQuery(GET_WHOLESALE_GENDERS)
}

/**
 * Hooks for fetching wholesale zones
 * @param {WatchQueryFetchPolicy} fetchPolicy - Fetch policy for query. Defaults to "cache-first".
 */
export const useWholesaleZones = (
  fetchPolicy: WatchQueryFetchPolicy = 'cache-first',
  skip = false
) => {
  const { data, loading, error } = useQuery(WHOLESALE_ZONES, {
    fetchPolicy,
    skip
  })

  return {
    zones: data?.findVIPZone?.Items,
    loading,
    error
  }
}

/**
 * Hooks for fetching retail zones
 * @param {WatchQueryFetchPolicy} fetchPolicy - Fetch policy for query. Defaults to "cache-first".
 */
export const useRetailZones = (
  fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network',
  skip = false
) => {
  const { loading, data } = useQuery(GET_RETAIL_ZONES, {
    skip,
    fetchPolicy
  })
  return {
    loading,
    items: data?.findVIPClient?.Items
  }
}
