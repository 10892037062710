import { StyledProps } from '@components/3rd-party/mui'
import { ProductMedia, ProductStatus } from '@components/UI-Components/ProductCardOld'
import { CoreProductInfo } from '@modules/common/models/interfaces'
import Error from '@material-ui/icons/Error'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { stores, strings } from '@stores'
import React from 'react'
import * as css from './ListProductCard.css'
import { FlagIcon, TrashIcon } from './svg'

interface IProps extends StyledProps {
  data?: CoreProductInfo
  canDelete?: boolean
  product?: any
  showFlag?: boolean
}

const IMAGE_WIDTH = 129
const IMAGE_CONTAINER_WIDTH = 176
const HEIGHT = 161

export const ListProductCard = React.memo((props: IProps) => {
  const [
    { data, canDelete, product, showFlag },
    {
      assortment: { deleteAssortmentProductFromListView, showProductDetail }
    }
  ] = [props, stores]

  return (
    <div key={product.id} className={css.productCard}>
      {product.isRestricted && (
        <p className={css.restrictionText}>
          <Error fontSize='inherit' className={css.warningIcon} />
          {strings.restrictedProductWarning}
        </p>
      )}
      <div className={css.productInfo}>
        <Tooltip title={data?.description}>
          <Typography className={css.descriptionText}>{data?.description}</Typography>
        </Tooltip>
      </div>
      <ProductMedia
        classes={css.cursorPointer}
        image={data?.image as string}
        imgWidth={IMAGE_WIDTH}
        width={IMAGE_CONTAINER_WIDTH}
        height={HEIGHT}
        onClick={e => showProductDetail(e, product)}
      />
      {data?.status ? (
        <ProductStatus status={data?.status} statusTagStyle={css.statusTag} />
      ) : null}
      {showFlag ? (
        <img
          src={FlagIcon}
          className={canDelete ? css.flagIcon : css.deleteIcon}
          alt=''
        />
      ) : null}
      {canDelete ? (
        <img
          src={TrashIcon}
          className={css.deleteIcon}
          onClick={e => deleteAssortmentProductFromListView(e, product)}
          alt=''
        />
      ) : null}
    </div>
  )
})
