/*
 * @Author: Raeesaa Metkari
 * @Date: 2020-03-27 13:59:50
 * @Last Modified by: Raeesaa Metkari
 * @Last Modified time: 2020-10-18 21:00:38
 */

export enum CentricNodeType {
  Client = 'VIPClient',
  Drop = 'VIPSalesPeriod',
  DeliveryWindow = 'VIPDeliveryWindow',
  VIPCluster = 'VIPCluster',
  VIPClusterStatus = 'VIPClusterStatus',
  SpecItem = 'SpecificationItemDefinition',
  VIPActivity = 'VIPActivity',
  VIPClientDiscount = 'VIPClientDiscount',
  VIPGender = 'VIPGender',
  VIPClientOrder = 'VIPClientOrder',
  VIPDoorOrder = 'VIPDoorOrder',
  VIPUserNotification = 'VIPUserNotification',
  VIPOrderProduct = 'VIPOrderProduct'
}
