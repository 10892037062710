import Platform from 'platform'
import { StyledProps } from '@components/3rd-party/mui'
import React from 'react'
import { DragSource, DragSourceSpec } from 'react-dnd'
import { DragPreview } from './DragPreview'
import isBoolean from 'lodash/isBoolean'

const ItemTypes = {
  LIBRARYITEM: 'libraryItem'
}

const spec: DragSourceSpec<any, any> = {
  beginDrag(props) {
    return {
      ...props
    }
  },
  canDrag(props) {
    return isBoolean(props.canDrag) ? props.canDrag : true
  }
}

const collectDrag = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  }
}

interface MyProps extends StyledProps {
  connectDragSource?: (any) => React.ReactNode
  canDrag?: boolean
  isDragging?: boolean
}

@((DragSource as any)(ItemTypes.LIBRARYITEM, spec, collectDrag))
export class DraggableSource extends React.Component<MyProps & any> {
  render() {
    const {
      props,
      props: { connectDragSource, children, className, isDragging }
    } = this

    return connectDragSource(
      <div style={{ WebkitTouchCallout: 'none' }}>
        <div className={className} style={{ opacity: isDragging ? 0.25 : 1 }}>
          {children}
        </div>
        {(Platform.os.family === 'iOS' || Platform.os.family === 'Android') && (
          <DragPreview {...props} />
        )}
      </div>
    ) as React.ReactNode
  }
}
