import { QueryResult } from '@apollo/client/react'
import { MutationFunctionOptions } from '@apollo/client/react/types/types'
import { Point } from '@grapecity/wijmo'
import { Column, FlexGrid } from '@grapecity/wijmo.grid'
import { CLUSTER_ASSOCIATIONS_BY_ZONE_ASSORTMENT } from '@modules/retail/assortment/graphql/clustersByZoneAssortment'
import { ZONE_ASSORTMENT_PRODUCT_WITH_STORES } from '@modules/retail/assortment/graphql/zoneAssortmentProductsWithStores'
import { IPermissionsDict } from '@routes'
import { stores } from '@stores'
import { ExecutionResult } from 'graphql/execution'
import cloneDeep from 'lodash/cloneDeep'
import filter from 'lodash/filter'
import find from 'lodash/find'
import flatMap from 'lodash/flatMap'
import forEach from 'lodash/forEach'
import identity from 'lodash/identity'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import mapValues from 'lodash/mapValues'
import remove from 'lodash/remove'
import sum from 'lodash/sum'
import keyBy from 'lodash/keyBy'
import { action, autorun, computed, observable, runInAction } from 'mobx'
import React, { useContext } from 'react'
import { ZONE_ASSORTMENT_PRODUCT_BY_ID } from '../../graphql/zoneAssortmentProductById'
import {
  IClusterByZoneAssortment,
  IClusterByZoneAssortmentStore,
  IClusterStores,
  IClusterWiseStoreAssortmentProducts,
  IStoreAssortment,
  IStoreProduct,
  IZoneAssortmentProducts,
  IZoneAssortmentProductWithStore
} from '../../graphql/hooks'
import { StoreClustersStore } from './AssortmentSplit_StoreClustersTable'

const SCROLL_MULTIPLIER = 0.5

enum SplitStatus {
  isStoreSplitInProgress = 'isStoreSplitInProgress',
  isZoneQtyUpdateInProgress = 'isZoneQtyUpdateInProgress'
}

interface ProductStatusMap {
  [key: string]: {
    isStoreSplitInProgress: boolean
    isZoneQtyUpdateInProgress: boolean
  }
}

type StoreAssortmentProductForDeletion = {
  product: IZoneAssortmentProductWithStore
  storeAssortmentProduct: Partial<IStoreProduct>
  storeAssortment: Partial<IClusterByZoneAssortmentStore>
}

//region Context
export const SplitTableStoreContext =
  React.createContext<AssortmentSplitTableStore>(undefined)
export const useAssortmentSplitStore = () => useContext(SplitTableStoreContext)

//endregion

export class AssortmentSplitTableStore {
  constructor() {
    // So we can modify the local store in console
    DEBUG && Object.assign(window.centric, { assortmentSplit: this })
  }

  @observable.ref clusterStore: StoreClustersStore

  @observable.ref permissions: IPermissionsDict

  @observable.ref clusterGrid: FlexGrid = null
  @observable.ref productGrid: FlexGrid = null
  @observable productGridInitialized: boolean = false
  @observable gridClientSize = null
  @observable openSplitConfirmationDialog = false
  /**
   * For delete product from store
   */
  @observable deleteInProgress = {}
  @observable
  isOpenDeleteProductConfirmationOnLastStoreAssortmentProductRemoval = false
  @observable isLastStoreAssortmentProductDeletionInProgress = false
  selectedStoreAssortmentProductForDeletion: StoreAssortmentProductForDeletion = null

  queries: {
    clusters: QueryResult<IClusterStores>
    products: QueryResult<IZoneAssortmentProducts>
  } = { clusters: null, products: null }
  mutations: {
    addProductsToStoreAssortment?: (
      options?: MutationFunctionOptions<
        any,
        { productIds: string[]; assortmentId: string }
      >
    ) => Promise<void | ExecutionResult<{ assortment: IStoreAssortment }>>
    removeProductFromStoreAssortment?: (
      options?: MutationFunctionOptions<
        any,
        { assortmentId: string; productIds: string[] }
      >
    ) => Promise<void | ExecutionResult<{}>>
    updateAssortmentProductQuantity?: (
      options?: MutationFunctionOptions<any, { productId: string; quantity: number }>
    ) => Promise<void | ExecutionResult<{}>>
    updateZoneAssortmentProductQuantity?: (
      options?: MutationFunctionOptions<any, { productId: string; quantity: number }>
    ) => Promise<void | ExecutionResult<{}>>
    removeBuyerClusterFromAssortmentProduct?: (
      options?: MutationFunctionOptions<any, { productId: string; clusterId: string }>
    ) => Promise<void | ExecutionResult<{}>>
    splitToStores?: (
      options?: MutationFunctionOptions<
        any,
        { zoneAssortmentProductId: string; quantityType: string }
      >
    ) => Promise<void | ExecutionResult<{}>>
    splitProductsToAllStores?: (
      options?: MutationFunctionOptions<
        any,
        { zoneAssortmentId: string; quantityType: string }
      >
    ) => Promise<void | ExecutionResult<{}>>
  } = {}

  // Wrap responses from apollo with local observables so we can update optimistically
  @observable clusters: Array<IClusterByZoneAssortment> = []
  @observable products: Array<IZoneAssortmentProductWithStore> = []
  @observable buyingSession: any

  // Keep track of the previous results from apollo itself so we can overwrite our mobx cache if the query is refetched due to filter/sort/whatever change
  lastResults: {
    products: Array<IZoneAssortmentProductWithStore>
    clusters: Array<IZoneAssortmentProductWithStore>
  } = { products: undefined, clusters: undefined }

  @observable clustersById = observable.map<string, IClusterByZoneAssortment>()
  @observable productStatusMap: ProductStatusMap = {}
  @observable isGlobalSplitInProgress: boolean = false

  @observable
  isOpenDeleteProductConfirmationOnLastRMClusterDeleteDialogSplit = false
  @observable rmZoneAssortmentProductDeleteInProgress = false

  @observable columnWidths = {
    product: 280,
    cluster: 150,
    storeCount: 80,
    averageDepth: 75,
    zoneQuantity: 130,
    totalStoreQuantity: 140
  }

  @observable productTableSizing = {
    tableHeaderHeight: 24,
    productRowHeight: 160,
    statusColumnHeaderRowHeight: 50
  }
  filteredStores = observable.set<string>()

  updateProductStatusMap = (
    productId: string,
    keyToUpdate: SplitStatus,
    valueToUpdate: boolean
  ) => {
    const productStatusObject = this.productStatusMap[productId]
    return {
      ...this.productStatusMap,
      [productId]: {
        ...productStatusObject,
        [keyToUpdate]: valueToUpdate
      }
    }
  }

  // This is the sum of the three columns (Products + cluster + Zone Quantity) of the product tabe
  @computed get clusterStoreTableLeftMargin() {
    const {
      columnWidths: { product, cluster, storeCount, averageDepth, zoneQuantity }
    } = this
    return product + cluster + storeCount + averageDepth + zoneQuantity
  }

  @computed get canEdit() {
    return this.permissions.zone.canEdit
  }

  reactNodes = new Map<string, HTMLDivElement>()
  //columnDict: { [c: number]: { column: Column, cluster?: IClusterByZoneAssortment, store?: IClusterByZoneAssortmentStore } } = {}

  @observable.struct private scrollPosition: { x: number; y: number } = {
    x: 0,
    y: 0
  }
  @observable productGridWidth = 0

  getTotalQuantity = row => {
    const product = this.productAtRow(row)
    if (!product) return 0
    const { clusters } = product
    const { clusters: zoneClusters } = this
    return sum(
      flatMap(
        clusters
          .map(c => ({
            zoneCluster: zoneClusters.find(({ id }) => id === c.clusterId),
            cluster: c
          }))
          .filter(({ zoneCluster }) => zoneCluster)
          .map(({ zoneCluster, cluster }) =>
            cluster.storeAssortmentProducts
              .filter(({ storeId }) => zoneCluster.stores.find(s => s.id === storeId))
              .map(({ buy }) => (buy ? buy.quantity : 0))
          )
      )
    )
  }

  @computed get clusterGridStoreColumns() {
    const {
      filteredStores,
      clusters,
      queries: {
        clusters: { loading }
      }
    } = this

    const storeColumns: Array<{ column }> = loading
      ? [
          {
            column: new Column({
              header: ' ',
              binding: 'loading',
              minWidth: 500
            })
          }
        ]
      : flatMap(
          clusters.map(c =>
            c.stores.map(s =>
              filteredStores.has(s.id)
                ? null
                : {
                    column: new Column({
                      header: s.name,
                      binding: s.id,
                      align: 'center',
                      width: 150
                    }),
                    cluster: c,
                    store: s
                  }
            )
          )
        ).filter(identity)

    return storeColumns
  }

  @computed get clusterGridColumns(): Array<Column> {
    const {
      columnWidths: { totalStoreQuantity: rowColumnWidth },
      queries: {
        clusters: { loading }
      },
      clusterGridStoreColumns
    } = this

    return [
      new Column({
        header: ` `,
        binding: 'title',
        allowResizing: false,
        width: rowColumnWidth / 2,
        align: 'left',
        allowMerging: true
      }),
      new Column({
        header: ` `,
        allowResizing: false,
        width: rowColumnWidth / 2,
        allowMerging: true
      }),
      ...clusterGridStoreColumns.map(({ column }) => column),
      isEmpty(clusterGridStoreColumns) &&
        !loading &&
        new Column({ header: 'No Stores Found' })
    ].filter(identity)
  }

  @computed get columnDict() {
    const { clusterGridStoreColumns } = this
    const result = clusterGridStoreColumns.reduce((p, v, c) => ({ ...p, [c]: v }), {})
    return result
    // productGridColumns.forEach((v, c) => {
    //   store.columnDict[c] = v
    // })
  }

  @computed get productGridColumns(): Array<Column> {
    const { filteredStores, clusters, columnWidths } = this
    return [
      new Column({
        header: 'Products',
        allowResizing: false,
        width: columnWidths.product,
        align: 'center'
      }),
      new Column({
        header: 'RM Clusters',
        allowResizing: false,
        width: columnWidths.cluster
      }),
      new Column({
        header: 'Total Stores',
        allowResizing: false,
        width: columnWidths.storeCount
      }),
      new Column({
        header: 'AVG Depth',
        width: columnWidths.averageDepth
      }),
      new Column({
        header: 'Zone quantity',
        allowResizing: false,
        width: columnWidths.zoneQuantity
      }),
      new Column({
        header: 'Total store quantity',
        width: columnWidths.totalStoreQuantity
      }),
      ...flatMap(
        clusters.map(cluster =>
          cluster.stores
            .filter(s => !filteredStores.has(s.id))
            .map(store => new Column({ binding: store.id, header: ' ', width: 150 }))
        )
      )
    ]
  }

  trackProductScroller = autorun(
    () => {
      const { productGrid } = this
      productGrid &&
        productGrid.scrollPositionChanged.addHandler(() => {
          const {
            scrollPosition: { x, y }
          } = productGrid
          this.scrollPosition = { x, y }
        })
      productGrid &&
        productGrid.updatedLayout.addHandler(() => {
          const {
            scrollPosition: { x, y }
          } = productGrid
          this.scrollPosition = { x, y }
        })
    },
    { name: `Track product details grid scroll position` }
  )

  @computed get canScrollLeft() {
    const { scrollPosition, productGrid } = this
    return productGrid && scrollPosition.x < 0
  }

  @computed get canScrollRight() {
    const { scrollPosition, productGrid, productGridInitialized, gridClientSize } = this

    if (productGridInitialized && productGrid && gridClientSize) {
      const { scrollSize, clientSize } = productGrid
      return clientSize.width === 0 || clientSize.height <= 1
        ? false
        : scrollPosition.x > clientSize.width - scrollSize.width
    }
    return false
  }

  @computed get hasVerticalOverflow() {
    const { productGrid, productGridInitialized, gridClientSize } = this

    if (productGridInitialized && productGrid && gridClientSize) {
      const {
        scrollSize: { height: scrollHeight },
        clientSize: { height: clientHeight }
      } = productGrid
      if (clientHeight <= 1) {
        return false
      }

      return scrollHeight - clientHeight > 0
    }
    return false
  }

  @action scrollLeft = () => {
    const {
      productGrid: { scrollPosition, controlRect }
    } = this
    this.productGrid.scrollPosition = new Point(
      scrollPosition.x + controlRect.width * SCROLL_MULTIPLIER,
      scrollPosition.y
    )
  }

  @action scrollRight = () => {
    const {
      productGrid: { controlRect, scrollPosition }
    } = this
    this.productGrid.scrollPosition = new Point(
      scrollPosition.x - controlRect.width * SCROLL_MULTIPLIER,
      scrollPosition.y
    )
  }

  @observable headerExpanded = false

  @action splitToStores = async (product: IZoneAssortmentProductWithStore) => {
    runInAction(`splitToStores - ${product.id}`, async () => {
      const {
        mutations: { splitToStores }
      } = this

      this.productStatusMap = this.updateProductStatusMap(
        product.id,
        SplitStatus.isStoreSplitInProgress,
        true
      )
      try {
        await splitToStores({
          variables: {
            zoneAssortmentProductId: product.id,
            quantityType: 'BUY'
          },
          refetchQueries: [
            {
              query: ZONE_ASSORTMENT_PRODUCT_BY_ID,
              variables: {
                id: product.id
              }
            }
          ]
        })
        // Manually refetching instead of refetch queries so that loading state is correctly rendered on UI
        await Promise.all([
          this.queries.products.refetch(),
          this.queries.clusters.refetch()
        ])
      } finally {
        this.productStatusMap = this.updateProductStatusMap(
          product.id,
          SplitStatus.isStoreSplitInProgress,
          false
        )
      }
    })
  }

  @action
  handleSplitQuantityDialogOpen = () => {
    Object.assign(this, {
      openSplitConfirmationDialog: !this.openSplitConfirmationDialog
    })
  }

  @action masterSplitToStore = () => {
    const {
      nav: {
        params: { assortment }
      }
    } = stores

    runInAction(`splitProductsToAllStores - ${assortment}`, async () => {
      const {
        mutations: { splitProductsToAllStores }
      } = this
      this.isGlobalSplitInProgress = true

      try {
        await splitProductsToAllStores({
          variables: { zoneAssortmentId: assortment, quantityType: 'BUY' }
        })
        // Manually refetching instead of refetch queries so that loading state is correctly rendered on UI
        await Promise.all([
          this.queries.products.refetch(),
          this.queries.clusters.refetch()
        ])
      } finally {
        this.isGlobalSplitInProgress = false
      }
    })
    this.handleSplitQuantityDialogOpen()
  }

  @action
  confirmDeleteProductConfirmationOnLastRMClusterDeleteDialog = () => {
    const {
      assortment: { selectedClusterIdForDeletion, selectedProductForDeletion }
    } = stores
    this.removeBuyerClusterFromAssortmentProduct(
      selectedProductForDeletion,
      selectedClusterIdForDeletion
    )
  }

  @action
  openDeleteProductConfirmationOnLastRMClusterDeleteDialogSplit = () => {
    Object.assign(this, {
      isOpenDeleteProductConfirmationOnLastRMClusterDeleteDialogSplit: true
    })
  }

  @action
  closeDeleteProductConfirmationOnLastRMClusterDeleteDialogSplit = () => {
    Object.assign(this, {
      isOpenDeleteProductConfirmationOnLastRMClusterDeleteDialogSplit: false
    })
  }

  @action removeBuyerClusterFromAssortmentProduct = async (
    product: IZoneAssortmentProductWithStore,
    clusterId
  ) => {
    const {
      mutations: { removeBuyerClusterFromAssortmentProduct }
    } = this
    const {
      assortment: {
        getAssociatedBuyerClustersOnCurrentZAP,
        setProductDetailsForRMLastClusterDelete
      }
    } = stores
    const existingBuyerClusters = product.buyerClusters
    const associatedBuyerClustersOnCurrentZAP =
      await getAssociatedBuyerClustersOnCurrentZAP(existingBuyerClusters)
    const existingBuyerClustersAfterRemoval = filter(
      associatedBuyerClustersOnCurrentZAP,
      cluster => cluster.id !== clusterId
    )
    if (
      existingBuyerClustersAfterRemoval.length === 0 &&
      product &&
      !this.isOpenDeleteProductConfirmationOnLastRMClusterDeleteDialogSplit
    ) {
      setProductDetailsForRMLastClusterDelete(clusterId, product.id, product)
      this.openDeleteProductConfirmationOnLastRMClusterDeleteDialogSplit()
      return null
    }
    try {
      this.rmZoneAssortmentProductDeleteInProgress = true
      await removeBuyerClusterFromAssortmentProduct({
        variables: { productId: product.id, clusterId: clusterId },
        refetchQueries:
          existingBuyerClustersAfterRemoval.length === 0
            ? []
            : [
                {
                  query: ZONE_ASSORTMENT_PRODUCT_BY_ID,
                  variables: {
                    id: product.id
                  }
                }
              ]
      })
      // Manually refetching instead of refetch queries so that loading state is correctly rendered on UI
      await Promise.all([
        this.queries.products.refetch(),
        this.queries.clusters.refetch()
      ])
    } finally {
      Object.assign(this, {
        rmZoneAssortmentProductDeleteInProgress: false
      })
      this.closeDeleteProductConfirmationOnLastRMClusterDeleteDialogSplit()
    }
  }

  @action rollUpToGlobal = (
    product: IZoneAssortmentProductWithStore,
    quantity: number
  ) => {
    this.updateProductZoneQuantity(product, quantity)
  }

  // API wrappers

  @action addProductToStore = async (
    product: IZoneAssortmentProductWithStore,
    cluster: IClusterByZoneAssortment,
    pc: IClusterWiseStoreAssortmentProducts,
    {
      storeAssortmentId: assortmentId,
      id: assortmentStoreId
    }: IClusterByZoneAssortmentStore
  ) => {
    const { buyingSessionProductId: productId } = product
    const {
      mutations: { addProductsToStoreAssortment },
      canEdit
    } = this
    const {
      product: { whereZoneAssortmentProductQueryForSplit }
    } = stores
    if (!canEdit) return
    ;(await addProductsToStoreAssortment({
      variables: { assortmentId, productIds: [productId] },
      update: (
        proxy,
        {
          data: {
            assortment: { assortmentProducts }
          }
        }
      ) => {
        const { zoneAssortmentProductsWithStores } = proxy.readQuery({
          query: ZONE_ASSORTMENT_PRODUCT_WITH_STORES,
          variables: whereZoneAssortmentProductQueryForSplit()
        })

        const addedStoreAssortmentProduct = find(
          assortmentProducts,
          assortmentProduct => assortmentProduct.product.id === productId
        )

        let zoneAssortmentProductsWithStoresClone = cloneDeep(
          zoneAssortmentProductsWithStores
        )
        let zoneAssortmentProductToBeUpdated = find(
          zoneAssortmentProductsWithStoresClone.zoneAssortmentProducts,
          zap => zap.buyingSessionProductId === productId
        )

        if (zoneAssortmentProductToBeUpdated) {
          // Increase store count and update average depth
          zoneAssortmentProductToBeUpdated.storeCount++
          const zoneQty =
            zoneAssortmentProductToBeUpdated && zoneAssortmentProductToBeUpdated.buy
              ? zoneAssortmentProductToBeUpdated.buy.quantity
              : 0
          zoneAssortmentProductToBeUpdated.averageDepth =
            zoneAssortmentProductToBeUpdated.storeCount
              ? zoneQty / zoneAssortmentProductToBeUpdated.storeCount
              : 0

          // Add product to store
          let clusterInProduct = find(
            zoneAssortmentProductToBeUpdated.clusters,
            c => c.clusterId === cluster.id
          )
          const storeAssortmentProduct = {
            storeId: assortmentStoreId,
            id: addedStoreAssortmentProduct.id,
            bet: addedStoreAssortmentProduct.bet,
            buy: addedStoreAssortmentProduct.buy,
            __typename: 'StoreProduct'
          }

          if (clusterInProduct) {
            clusterInProduct.storeAssortmentProducts.push(storeAssortmentProduct)
          } else {
            zoneAssortmentProductToBeUpdated.clusters.push({
              id: `${zoneAssortmentProductToBeUpdated.id}_${cluster.id}`,
              clusterId: cluster.id,
              status: '',
              storeAssortmentProducts: [storeAssortmentProduct],
              __typename: 'ClusterWiseStoreAssortmentProducts'
            })
          }

          // Check if buyer cluster corresponding to store assortment product added is present in zone assortment. If not, add it
          let storeAssortmentProductCluster = addedStoreAssortmentProduct.storeAssortment
            ? addedStoreAssortmentProduct.storeAssortment.cluster
            : null
          let buyerCluster = storeAssortmentProductCluster
            ? zoneAssortmentProductToBeUpdated.buyerClusters.find(
                cluster => cluster.id === storeAssortmentProductCluster.id
              )
            : null
          if (!buyerCluster) {
            zoneAssortmentProductToBeUpdated.buyerClusters.push(
              storeAssortmentProductCluster
            )
          }
        }

        proxy.writeQuery({
          query: ZONE_ASSORTMENT_PRODUCT_WITH_STORES,
          variables: whereZoneAssortmentProductQueryForSplit(),
          data: {
            zoneAssortmentProductsWithStores: zoneAssortmentProductsWithStoresClone
          }
        })
      },
      refetchQueries: [
        {
          query: CLUSTER_ASSOCIATIONS_BY_ZONE_ASSORTMENT,
          variables: whereZoneAssortmentProductQueryForSplit('BUY', true)
        }
      ]
    })) as ExecutionResult<{ assortment: IStoreAssortment }>
  }

  @action focusZoneQuantity = productId => {
    this.productStatusMap = this.updateProductStatusMap(
      productId,
      SplitStatus.isZoneQtyUpdateInProgress,
      true
    )
  }

  @action updateProductZoneQuantity = async (
    product: IZoneAssortmentProductWithStore,
    quantity: number
  ) => {
    const {
      mutations: { updateZoneAssortmentProductQuantity },
      canEdit
    } = this
    const {
      product: { whereZoneAssortmentProductQueryForSplit }
    } = stores

    if (!canEdit) return

    if (!product.buy) {
      this.productStatusMap = this.updateProductStatusMap(
        product.id,
        SplitStatus.isZoneQtyUpdateInProgress,
        false
      )
      if (quantity === 0) return

      product.buy = { id: '', quantity }
    } else if (product.buy.quantity === quantity) {
      this.productStatusMap = this.updateProductStatusMap(
        product.id,
        SplitStatus.isZoneQtyUpdateInProgress,
        false
      )
      return
    }

    this.productStatusMap = this.updateProductStatusMap(
      product.id,
      SplitStatus.isZoneQtyUpdateInProgress,
      true
    )
    try {
      ;(await updateZoneAssortmentProductQuantity({
        variables: { productId: product.id, quantity },
        update: (
          proxy,
          {
            data: {
              updateZoneAssortmentProductBuyQauntity: { buy }
            }
          }
        ) => {
          const { zoneAssortmentProductsWithStores } = proxy.readQuery({
            query: ZONE_ASSORTMENT_PRODUCT_WITH_STORES,
            variables: whereZoneAssortmentProductQueryForSplit()
          })

          let zoneAssortmentProductsWithStoresClone = cloneDeep(
            zoneAssortmentProductsWithStores
          )
          let zoneAssortmentProductToBeUpdated = find(
            zoneAssortmentProductsWithStoresClone.zoneAssortmentProducts,
            zap => zap.id === product.id
          )

          if (zoneAssortmentProductToBeUpdated) {
            zoneAssortmentProductToBeUpdated.buy = buy

            // update average depth
            zoneAssortmentProductToBeUpdated.averageDepth =
              zoneAssortmentProductToBeUpdated.storeCount
                ? quantity / zoneAssortmentProductToBeUpdated.storeCount
                : 0
          }

          proxy.writeQuery({
            query: ZONE_ASSORTMENT_PRODUCT_WITH_STORES,
            variables: whereZoneAssortmentProductQueryForSplit(),
            data: {
              zoneAssortmentProductsWithStores: zoneAssortmentProductsWithStoresClone
            }
          })
        }
      })) as ExecutionResult<{
        updateZoneAssortmentProductBuyQauntity: {
          buy: { id: string; quantity: number }
        }
      }>
    } finally {
      this.productStatusMap = this.updateProductStatusMap(
        product.id,
        SplitStatus.isZoneQtyUpdateInProgress,
        false
      )
    }
  }

  @action updateAssortmentProductQuantity = async (
    product: IZoneAssortmentProductWithStore,
    quantity: number,
    zoneAssortmentProduct
  ) => {
    const {
      mutations: { updateAssortmentProductQuantity },
      canEdit
    } = this

    const {
      product: { whereZoneAssortmentProductQueryForSplit }
    } = stores
    if (!canEdit) return

    if (!product.buy) {
      if (quantity === 0) return

      product.buy = { id: '', quantity }
    }
    // Is the value different?
    else if (product.buy.quantity === quantity) return

    await updateAssortmentProductQuantity({
      variables: { productId: product.id, quantity },
      update: (
        proxy,
        {
          data: {
            updateStoreAssortmentProductBuyQauntity: { buy }
          }
        }
      ) => {
        const { zoneAssortmentProductsWithStores } = proxy.readQuery({
          query: ZONE_ASSORTMENT_PRODUCT_WITH_STORES,
          variables: whereZoneAssortmentProductQueryForSplit()
        })

        let zoneAssortmentProductsWithStoresClone = cloneDeep(
          zoneAssortmentProductsWithStores
        )
        let zoneAssortmentProductToBeUpdated = find(
          zoneAssortmentProductsWithStoresClone.zoneAssortmentProducts,
          zap => zap.id === zoneAssortmentProduct.id
        )
        let storeAssortmentProductToBeUpdated = null

        if (zoneAssortmentProductToBeUpdated) {
          for (let i = 0; i < zoneAssortmentProductToBeUpdated.clusters.length; i++) {
            storeAssortmentProductToBeUpdated = find(
              zoneAssortmentProductToBeUpdated.clusters[i].storeAssortmentProducts,
              sap => sap.id === product.id
            )
            if (storeAssortmentProductToBeUpdated) {
              break
            }
          }

          storeAssortmentProductToBeUpdated.buy = buy
        }

        proxy.writeQuery({
          query: ZONE_ASSORTMENT_PRODUCT_WITH_STORES,
          variables: whereZoneAssortmentProductQueryForSplit(),
          data: {
            zoneAssortmentProductsWithStores: zoneAssortmentProductsWithStoresClone
          }
        })
      },
      refetchQueries: [
        {
          query: CLUSTER_ASSOCIATIONS_BY_ZONE_ASSORTMENT,
          variables: whereZoneAssortmentProductQueryForSplit('BUY', true)
        }
      ]
    })
  }

  @action
  closeDeleteProductConfirmationOnLastStoreAssortmentProductRemoval = () => {
    this.isOpenDeleteProductConfirmationOnLastStoreAssortmentProductRemoval = false
    this.selectedStoreAssortmentProductForDeletion = null
  }

  @action
  confirmDeleteProductConfirmationOnLastStoreAssortmentProductRemoval = async () => {
    if (this.selectedStoreAssortmentProductForDeletion) {
      const { product, storeAssortmentProduct, storeAssortment } =
        this.selectedStoreAssortmentProductForDeletion

      this.isLastStoreAssortmentProductDeletionInProgress = true
      try {
        await this.removeProductFromStoreAssortment(
          product,
          storeAssortmentProduct,
          storeAssortment
        )
      } finally {
        this.isLastStoreAssortmentProductDeletionInProgress = false
      }
    }
    this.selectedStoreAssortmentProductForDeletion = null
    this.isOpenDeleteProductConfirmationOnLastStoreAssortmentProductRemoval = false
  }

  @action removeProductFromStoreAssortment = async (
    product: IZoneAssortmentProductWithStore,
    { id: productId }: Partial<IStoreProduct>,
    { storeAssortmentId: assortmentId }: Partial<IClusterByZoneAssortmentStore>
  ) => {
    /**
     * Check if last store assortment product is being deleted. If yes, show confirmation dialog
     */
    if (!this.isOpenDeleteProductConfirmationOnLastStoreAssortmentProductRemoval) {
      const existingStoreAssortmentProducts = product.clusters.reduce((acc, curr) => {
        acc += curr.storeAssortmentProducts.length
        return acc
      }, 0)

      if (
        existingStoreAssortmentProducts - (this.deleteInProgress[product.id] || 0) <=
        1
      ) {
        this.selectedStoreAssortmentProductForDeletion = {
          product,
          storeAssortmentProduct: { id: productId },
          storeAssortment: { storeAssortmentId: assortmentId }
        }
        this.isOpenDeleteProductConfirmationOnLastStoreAssortmentProductRemoval = true
        return
      }
    }

    // Call delete mutation
    const {
      canEdit,
      mutations: { removeProductFromStoreAssortment }
    } = this
    const {
      product: { whereZoneAssortmentProductQueryForSplit }
    } = stores

    if (!canEdit) return

    if (this.deleteInProgress[product.id]) {
      this.deleteInProgress[product.id]++
    } else {
      this.deleteInProgress[product.id] = 1
    }

    try {
      await removeProductFromStoreAssortment({
        variables: { assortmentId, productIds: [productId] },
        refetchQueries: [
          {
            query: CLUSTER_ASSOCIATIONS_BY_ZONE_ASSORTMENT,
            variables: whereZoneAssortmentProductQueryForSplit('BUY', true)
          }
        ],
        update: (proxy, { data: { removeProductFromStoreAssortment } }) => {
          const { zoneAssortmentProductsWithStores } = proxy.readQuery({
            query: ZONE_ASSORTMENT_PRODUCT_WITH_STORES,
            variables: whereZoneAssortmentProductQueryForSplit()
          })
          let zoneAssortmentProductsWithStoresClone = cloneDeep(
            zoneAssortmentProductsWithStores
          )
          let assortmentProduct =
            zoneAssortmentProductsWithStoresClone.zoneAssortmentProducts.find(product => {
              return product.clusters.some(cluster => {
                return cluster.storeAssortmentProducts.some(
                  (storeProduct: IStoreProduct) => {
                    return (
                      storeProduct.storeId ===
                        removeProductFromStoreAssortment.store.id &&
                      storeProduct.id === productId
                    )
                  }
                )
              })
            })
          // Remove product from cluster / store
          if (assortmentProduct) {
            // Decrease store count and update average depth
            assortmentProduct.storeCount--
            const zoneQty =
              assortmentProduct && assortmentProduct.buy
                ? assortmentProduct.buy.quantity
                : 0
            assortmentProduct.averageDepth = assortmentProduct.storeCount
              ? zoneQty / assortmentProduct.storeCount
              : 0

            // Remove product from cluster / store
            forEach(assortmentProduct.clusters, cluster => {
              let removedProduct = remove(
                cluster.storeAssortmentProducts,
                (storeProduct: IStoreProduct) =>
                  storeProduct.storeId === removeProductFromStoreAssortment.store.id
              )

              // Remove buyer cluster if there are no products in store assortment corresponding to cluster
              if (
                removedProduct &&
                removedProduct.length &&
                !cluster.storeAssortmentProducts.length
              ) {
                remove(
                  assortmentProduct.buyerClusters,
                  (clusterObj: any) => clusterObj.id === cluster.clusterId
                )
              }
            })
          }

          let storeAssortmentProductsPresentInZone = assortmentProduct.clusters.some(
            cluster => cluster.storeAssortmentProducts.length
          )
          if (!storeAssortmentProductsPresentInZone) {
            remove(
              zoneAssortmentProductsWithStoresClone.zoneAssortmentProducts,
              (zoneProduct: IZoneAssortmentProductWithStore) =>
                zoneProduct.id === assortmentProduct.id
            )
          }
          proxy.writeQuery({
            query: ZONE_ASSORTMENT_PRODUCT_WITH_STORES,
            variables: whereZoneAssortmentProductQueryForSplit(),
            data: {
              zoneAssortmentProductsWithStores: zoneAssortmentProductsWithStoresClone
            }
          })
        }
      })
    } finally {
      if (this.deleteInProgress[product.id]) {
        this.deleteInProgress[product.id]--
        if (!this.deleteInProgress[product.id]) delete this.deleteInProgress[product.id]
      }
    }
  }

  get selectedProduct() {
    const {
      productGrid: {
        selection: { row }
      },
      products
    } = this
    return row === -1 ? null : products[row]
  }

  @action toggleShowSelectedProduct = () => {
    const { selectedProduct } = this
    stores.product.showProductDetail(
      selectedProduct ? selectedProduct.buyingSessionProductId : null
    )
  }

  @computed get productToCluster(): {
    [zoneAssortmentProductWithStoreId: string]: {
      [clusterByZoneAssortmentClusterId: string]: IClusterWiseStoreAssortmentProducts & {
        productByStoreId: { [storeId: string]: IStoreProduct }
      }
    }
  } {
    const { products } = this
    return products.reduce(
      (acc, p) => ({
        ...acc,
        [p.id]: keyBy(
          p.clusters.map(c => ({
            ...c,
            productByStoreId: keyBy(c.storeAssortmentProducts, sap => sap.storeId)
          })),
          c => c.clusterId
        )
      }),
      {}
    )
  }

  /**
   * We double the rows in the grid to allow for column headers in detail cells
   * @param row
   */
  productAtRow = (row: number) => {
    const {
      queries: {
        products: { loading }
      },
      products
    } = this
    if (loading || isEmpty(products)) {
      return null
    }
    const index = row / 2
    return index < products.length ? products[index] : null
  }
  findStoreAssortmentProduct = (
    product: IZoneAssortmentProductWithStore,
    cluster: IClusterByZoneAssortment,
    assortmentStore: IClusterByZoneAssortmentStore
  ) => {
    const { productToCluster } = this

    const productCluster = product ? productToCluster[product.id][cluster.id] : null
    const storeAssortmentProduct =
      productCluster && productCluster.productByStoreId[assortmentStore.id]
    return { productCluster, storeAssortmentProduct }
  }

  @action observeBuyingSession = buyingSession => {
    this.buyingSession = buyingSession
  }

  @action observeClustersQuery = () => {
    const {
      queries: { clusters },
      lastResults
    } = this
    if (
      !clusters.loading &&
      clusters.data &&
      clusters.data.clustersByZoneAssortment &&
      !isEqual(
        clusters.data.clustersByZoneAssortment.clusters.slice(),
        lastResults.clusters
      )
    ) {
      lastResults.clusters = this.clusters =
        clusters.data.clustersByZoneAssortment.clusters.slice()
      this.clustersById.replace(this.clusters.map(c => [c.id, c]))
    }
  }

  @action observeProductsQuery = (wrapIfExists = false) => {
    const {
      queries: { products },
      lastResults
    } = this
    if (
      !products.loading &&
      products.data &&
      products.data.zoneAssortmentProductsWithStores &&
      !isEqual(
        products.data.zoneAssortmentProductsWithStores.zoneAssortmentProducts.slice(),
        lastResults.products
      )
    ) {
      lastResults.products = this.products =
        products.data.zoneAssortmentProductsWithStores.zoneAssortmentProducts.slice()
      this.productStatusMap = mapValues(keyBy(this.products, 'id'), () => {
        return {
          isStoreSplitInProgress: false,
          isZoneQtyUpdateInProgress: false
        }
      })
    }
  }

  focusAndEditCell = ({
    grid,
    row: r,
    col: c
  }: {
    grid: FlexGrid
    row: number
    col: number
  }) => {
    // Make sure we're scrolled to this item so that it has a rendered cell
    //grid.select(new CellRange(r, c), true)
    grid.scrollIntoView(r, c)
    setTimeout(() => {
      const cellElem = grid.cells.getCellElement(r, c)
      if (cellElem) {
        const input = cellElem.querySelector('input')
        if (input) {
          input.focus()
        } else {
          const button = cellElem.querySelector('button')
          if (button) {
            button.focus()
          } else {
          }
        }
      } else {
        console.warn(`Unable to find cell element for ${c}, ${r}`)
      }
    }, 100)
  }
}
