import difference from 'lodash/difference'
import intersection from 'lodash/intersection'
import isEqual from 'lodash/isEqual'
import sortBy from 'lodash/sortBy'
import { action, computed, observable } from 'mobx'
import { computedFn } from 'mobx-utils'

export class GeneralSelectionStore {
  @observable selected = observable.set<any>([])
  @observable deselected = observable.set<any>([])
  @observable disabled = observable.set<any>([])
  @observable data = []

  constructor(private field?: string, private min?: number) {}

  @computed get disabledExcludedData() {
    return difference(this.data, Array.from(this.disabled))
  }

  @computed get disabledExcludedSelectedRows() {
    return difference(Array.from(this.selected), Array.from(this.disabled))
  }

  @computed get isAllSelected() {
    return isEqual(
      sortBy(this.disabledExcludedData),
      sortBy(this.disabledExcludedSelectedRows)
    )
  }

  @computed get allowSelectAll() {
    return this.disabledExcludedData.length > 0
  }

  isSelected = computedFn(value => {
    return this.selected.has(value)
  })

  isDisabled = computedFn(value => {
    return this.disabled.has(value)
  })

  @action
  setSelectedRows = (selectedRows: any[]) => {
    this.selected.replace(selectedRows)
  }

  @action
  setDeselectedRows = (deselectedRows: any[]) => {
    this.deselected.replace(deselectedRows)
  }

  @action
  setDisabledRows = (disabledRows: any[]) => {
    this.disabled.replace(disabledRows)
  }

  @action
  setData = (data: any[]) => {
    this.data = data
  }

  @action
  select = (value: any) => {
    if (!this.isDisabled(value)) {
      this.selected.add(value)
      this.deselected.delete(value)
    }
  }

  @action
  toggle = value => {
    if (!this.isDisabled(value)) {
      this.selected.has(value) ? this.deselect(value) : this.select(value)
    }
  }

  @action
  deselect = (value: any) => {
    if (!this.isDisabled(value)) {
      if (this.min) {
        if (this.selected.size > this.min) {
          this.selected.delete(value)
          this.deselected.add(value)
        } else {
          // Replace the set so that checkbox will reset again if event not prevented default
          this.selected.replace(new Set(Array.from(this.selected)))
        }
      } else {
        this.selected.delete(value)
        this.deselected.add(value)
      }
    }
  }

  @action
  selectAll = () => {
    if (this.allowSelectAll) {
      const selectedRecords = [
        ...this.disabledExcludedData,
        ...intersection(Array.from(this.selected), Array.from(this.disabled))
      ]
      this.selected.replace(new Set(selectedRecords))
      this.deselected.replace(difference(this.data, selectedRecords))
    }
  }

  @action
  deselectAll = () => {
    if (this.allowSelectAll) {
      // this.deselectedRows.replace(
      //   difference(Array.from(this.selectedRows), Array.from(this.disabledRows))
      // )
      const selectedRecords = intersection(
        Array.from(this.selected),
        Array.from(this.disabled)
      )
      this.selected.replace(selectedRecords)
      this.deselected.replace(
        difference(Array.from(this.data), Array.from(this.disabled))
      )
    }
  }

  @action
  toggleSelectAll = () => {
    if (this.allowSelectAll) {
      this.isAllSelected ? this.deselectAll() : this.selectAll()
    }
  }
}
