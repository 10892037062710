import { buildSearchQuery } from '@services/commonServices'
import { stores } from '@stores'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { action, observable, toJS } from 'mobx'

export class SearchStore {
  @observable hasMore = true
  @observable searchKey = ''
  @observable searchText = ''
  @observable lastUpdatedOn = null
  @observable showField = false

  @action handleKeyPress = event => {
    if (event.key === 'Enter') {
      Object.assign(this, {
        lastUpdatedOn: new Date().getTime(),
        searchKey: event.target.value,
        hasMore: true
      })
    }
  }

  @action showInputField = () => {
    this.showField = !this.showField
    if (!this.showField) {
      this.searchText = ''
      this.onSearchKeyChange(this.searchText)
    }
  }

  @action removeSearch = () => {
    Object.assign(this, { showField: false, searchText: '' })
    this.onSearchKeyChange('')
  }

  @action
  onSearchComponentTextChange = searchText => {
    Object.assign(this, { searchText, hasMore: true })
  }

  @action
  onSearchKeyChange = searchKey => {
    const {
      assortment: { changeLoadingStatus }
    } = stores
    changeLoadingStatus(true)
    Object.assign(this, { searchKey, hasMore: true })
  }
  @action
  changeLoadingStatus = status => {
    Object.assign(this, { hasMore: status })
  }

  buildAttributeMap = (activity, { childrenMeta, childrenMetaValues }) => {
    let attributes = {
      activity: get(activity, 'name')
    }
    childrenMeta.forEach((category, index) => {
      attributes[category] = childrenMetaValues[index]
    })

    return attributes
  }

  @action
  prepareCollectionProductSearchWhere = (
    buyingSession,
    searchKey,
    productWhereInput,
    finalFilterToApply,
    genderId,
    activity,
    { childrenMeta, childrenMetaValues },
    user
  ) => {
    /////////////////////////////
    const {
      filter: { buyingSessionLevelFilter }
    } = stores
    const coreFilters = buyingSessionLevelFilter
    /////////////////////////////
    const {
      product: { attributeDefinitions, getProductWhereHierarchyQuery }
    } = stores
    const whereProductQuery: any = toJS(productWhereInput)

    let attributes = this.buildAttributeMap(activity, {
      childrenMeta,
      childrenMetaValues
    })

    // Base query with buying session and product attribute hierarchy
    const where: any = {
      buyingSession: { ...buyingSession },
      AND: [
        {
          product: { AND: getProductWhereHierarchyQuery(attributes) }
        }
      ]
    }

    // Push filter in case it is applied
    if (
      !isEmpty(coreFilters) ||
      (whereProductQuery.AND && whereProductQuery.AND.length)
    ) {
      where.AND.push({
        ...(!isEmpty(coreFilters) && { ...coreFilters }),
        ...(whereProductQuery.AND && { product: whereProductQuery })
      })
    }

    // Push search query in case it is applied
    if (searchKey) {
      where.AND.push({
        ...buildSearchQuery(searchKey, attributeDefinitions)
      })
    }

    return where
  }

  @action
  clearSearchKey = () => {
    const {
      assortment: { changeLoadingStatus }
    } = stores
    changeLoadingStatus(true)
    let searchKey = cloneDeep(this.searchKey)
    searchKey = ''
    this.onSearchKeyChange('')
    Object.assign(this, { searchKey, hasMore: true, searchText: '', showField: false })
  }

  @action
  prepareAssortmentProductSearchWhereInput = (
    productWhereInput,
    finalFilterToApply,
    user,
    searchKey,
    activity = null,
    hierarchyChildren = null,
    isQueryForAddProduct = false
  ) => {
    const {
      product: { attributeDefinitions, getProductWhereHierarchyQuery },
      filter: { buyingSessionLevelFilter },
      assortment: { drawerOpen }
    } = stores
    const coreFilters = buyingSessionLevelFilter

    const whereProductQuery: any = toJS(productWhereInput)
    let attributes: any = []
    if (activity && hierarchyChildren) {
      attributes = this.buildAttributeMap(activity, hierarchyChildren)
    }

    // Base query with buying session and product attribute hierarchy
    let where: any = {
      AND: [
        {
          product: { AND: getProductWhereHierarchyQuery(attributes) }
        }
      ]
    }

    const applyFilterOrSearch =
      (!isQueryForAddProduct && !drawerOpen) || (isQueryForAddProduct && drawerOpen)
    // Filter query
    if (applyFilterOrSearch && (!isEmpty(whereProductQuery) || !isEmpty(coreFilters))) {
      where.AND.push({
        product: whereProductQuery,
        ...((!isQueryForAddProduct && !drawerOpen) || (isQueryForAddProduct && drawerOpen)
          ? coreFilters
          : null)
      })
    }

    // Search Query
    if (applyFilterOrSearch && searchKey) {
      where.AND.push({
        ...buildSearchQuery(searchKey, attributeDefinitions)
      })
    }

    return where
  }

  @action
  prepareProductSearchWhereInput = (
    productWhereInput,
    finalFilterToApply,
    user,
    searchKey
  ) => {
    const {
      product: { attributeDefinitions },
      filter: { buyingSessionLevelFilter }
    } = stores
    const coreFilters = buyingSessionLevelFilter
    const where = {
      product: {
        ...productWhereInput
      }
    }
    const finalQuery = []
    Object.keys(where).forEach(element => {
      const object = {
        [element]: where[element]
      }
      finalQuery.push(object)
    })

    let query = { AND: [] }
    query.AND.push({ OR: [...finalQuery], ...coreFilters })
    if (searchKey) {
      query.AND.push({
        ...buildSearchQuery(searchKey, attributeDefinitions)
      })
    }

    return query
  }
}
export const search = new SearchStore()
