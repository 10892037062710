import { QueryResult } from '@apollo/client/react'
import { Query } from '@apollo/client/react/components/Query'
import { CircularLoading } from '@components/UI-Components/CircularLoading'
import { LoadingIndicator } from '@components/UI-Components/LoadingIndicator'
import { AuthorizationError } from '@components/Utils/AuthorizationError'
import { IPermissions } from '@routes'
import { observer } from 'mobx-react'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { AssortmentComponent } from '../component/Assortment'
import { GET_ASSORTMENT } from '../graphql/getAssortment'
import { GET_BET_BUY_VALUES_FOR_ZONE } from '../graphql/getBetBuyValuesForZone'

interface IAssortment {
  match?: any
  assortment?: any
  permissions?: IPermissions
}

@(withRouter as any)
@observer
export class AssortmentContainer extends React.Component<IAssortment> {
  render() {
    const {
      props: {
        match: {
          params: { assortmentId, podiumId, genderId, activityId, buyingSessionId }
        },
        permissions
      }
    } = this
    return (
      <>
        {permissions && permissions.canRead ? (
          assortmentId ? (
            <Query query={GET_ASSORTMENT} variables={{ assortmentId }}>
              {({
                data: assortmentData = { assortment: undefined, zone: undefined },
                error,
                loading
              }: QueryResult) => {
                return (
                  <Query
                    query={GET_BET_BUY_VALUES_FOR_ZONE}
                    variables={{
                      podium: podiumId,
                      genderId: genderId,
                      activity: activityId,
                      buyingSessionId: buyingSessionId
                    }}
                    fetchPolicy='cache-and-network'
                  >
                    {({ data: betBuyData }) => {
                      if (loading) return <CircularLoading />
                      if (error) return null
                      return (
                        <AssortmentComponent
                          assortment={assortmentData.assortment}
                          assortmentId={assortmentId}
                          buyingSessionId={buyingSessionId}
                          permissions={permissions}
                          betBuyData={betBuyData}
                          zone={assortmentData.zone}
                          buyingSession={
                            assortmentData.assortment &&
                            assortmentData.assortment.buyingSession
                          }
                        />
                      )
                    }}
                  </Query>
                )
              }}
            </Query>
          ) : (
            <LoadingIndicator />
          )
        ) : (
          <AuthorizationError />
        )}
      </>
    )
  }
}
