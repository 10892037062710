export enum NodeTypes {
  Image = 'Image',
  Document = 'Document'
}

export enum NodeAttributeId {
  UserImages = 'UserImages',
  Documents = 'Documents'
}

export enum ImageAttributes {
  LargeImage = 'LargeImage',
  Thumbnail = 'Thumbnail',
  SmallImage = 'SmallImage',
  Viewable = 'Viewable'
}

export enum Modules {
  Publisher = 'Publisher',
  SiteAdmin = 'SiteAdmin'
}

export enum Operations {
  GetFromNode = 'GetFromNode',
  MultiFilePublish = 'MultiFilePublish',
  GetDirect = 'GetDirect'
}
