import { Mutation } from '@apollo/client/react/components/Mutation'
import React from 'react'
import { DeleteBuyingSessionDialog } from '../component/DeleteBuyingSessionDialog'
import { DELETE_BUYING_SESSION } from '../graphql/deleteBuyingSession'

interface IDeleteBuyingSessionDialog {
  open: boolean
  handleClose: () => void
  selectedBuyingSession: string
}

export class DeleteBuyingSession extends React.PureComponent<IDeleteBuyingSessionDialog> {
  render() {
    return (
      <Mutation mutation={DELETE_BUYING_SESSION}>
        {deleteBuyingSession => {
          return (
            <DeleteBuyingSessionDialog
              {...this.props}
              handleDelete={deleteBuyingSession}
            />
          )
        }}
      </Mutation>
    )
  }
}
