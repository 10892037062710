import { SecondaryButton } from '@components/UI-Components/SecondaryButton'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { strings } from '@stores'
import React, { memo } from 'react'

interface IDeleteProductConformationOnLastRMClusterDelete {
  onDelete?: () => void
  openDialog?: boolean
  loading?: boolean
  onClose?: () => void
}

export const DeleteProductConformationOnLastRMClusterDelete: React.FunctionComponent<IDeleteProductConformationOnLastRMClusterDelete> =
  memo(({ onDelete, openDialog, onClose, loading }) => {
    return (
      <Dialog
        open={openDialog}
        onClose={onClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Remove Product</DialogTitle>
        <DialogContent>
          <DialogContentText
            id='alert-dialog-description'
            style={{ color: `rgba(0, 0, 0, 0.87)` }}
          >
            {strings.removeLastRmClusterConfirmationMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <SecondaryButton onClick={onClose}>No</SecondaryButton>
          <SecondaryButton
            autoFocus
            onClick={onDelete}
            disabled={loading}
            loading={loading}
          >
            Yes
          </SecondaryButton>
        </DialogActions>
      </Dialog>
    )
  })
