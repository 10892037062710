import { StyledProps } from '@components/3rd-party/mui'
import { LoadingIndicator } from '@components/UI-Components/LoadingIndicator'
import { StyledProductMedia } from '@components/UI-Components/ProductCardOld'
import { Point } from '@grapecity/wijmo'
import {
  AllowDragging,
  AllowResizing,
  AllowSorting,
  CellRange,
  FlexGrid,
  FormatItemEventArgs,
  HeadersVisibility,
  MergeManager,
  SelectionMode
} from '@grapecity/wijmo.grid'
import { FlexGrid as FlexGridComponent } from '@grapecity/wijmo.react.grid'
import {
  AssortmentProductDetail,
  StyledAssortmentProductDetailMediaContainer
} from '@modules/retail/assortment/component/AssortmentProductDetail'
import { useZoneAssortmentProductById } from '@modules/retail/collection/graphql/hooks'
import { renderReactIntoGridCell } from '@modules/wijmo_helpers'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import { action, autorun, computed } from 'mobx'
import { useComputed, useLocalStore, useObserver } from 'mobx-react-lite'
import React, { CSSProperties, useEffect } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import useDimensions from 'react-use-dimensions'
import styled from 'styled-components'
import identity from 'lodash/identity'

import {
  ClusterProductCell,
  GlobalZoneSplitButton,
  NumberCell,
  SplitTableHeaderCell,
  StoreProductCell,
  TotalStoreQuantityProductCell,
  ZoneQuantityProductCell
} from './cells'
import {
  AssortmentSplitTableStore,
  SplitTableStoreContext,
  useAssortmentSplitStore
} from './store'

//region <ProductDataProvider />
/**
 * Given a product id, loads the associated data needed to render a product card
 * @param id
 * @param children
 * @param className
 * @param style
 * @constructor
 */
export const ProductDataProvider: React.FunctionComponent<{
  style?: CSSProperties
  className?
  id
  children: ({ style, product, className }) => React.ReactElement
}> = ({ id, children, className, style }) => {
  const { loading, data } = useZoneAssortmentProductById(id)
  return loading
    ? null
    : children({
        style,
        className,
        product: data.zoneAssortmentProduct.product
      })
}
//endregion

//region Styled Components

const NoProductsInfo = styled.div.attrs(() => ({ children: <>No Results Found</> }))`
  padding: 1em 0.5em;
  vertical-align: middle;
  display: inline-table; // Not sure why I need to do this, but otherwise I get extra padding below
  font: var(--typography-headline);
`

const StyledLoadingIndicator = styled(LoadingIndicator)`
  width: 100%;
  min-height: 40vh;
`

const MyFlexGrid = styled(FlexGridComponent)`
  --split-product-table-column-background: #eeeeee;

  [wj-part='ch'] {
    background: var(--split-product-table-column-background);
  }

  [wj-part='chcells'] {
    .wj-cell.wj-header {
      background: var(--split-product-table-column-background);
      color: #999999;
      text-transform: uppercase;
      font-size: 10px;
      vertical-align: middle;
      text-align: center;
      padding: 4px 4px;

      border-color: var(--split-border-color-dark) !important;

      &:nth-child(n + 6) {
        &:not(:last-child) {
          border-right: none;
        }
      }
    }
  }

  [wj-part='cells'] {
    .wj-row {
      &[aria-selected='true'] {
        button.bp3-button {
          opacity: 1;
        }

        .wj-cell {
          //background: var(--selected-background-no-transparent);

          //&[aria-selected='true'] {
          //  border: 1px solid green;
          //}
        }
      }
    }

    .wj-cell:not(:nth-child(n + 3)) {
      //border-right:none;
    }

    .wj-cell {
      border-color: var(--split-border-color-light);
      /* padding: 2px 2px; */

      &.wj-state-active {
      }

      &.wj-state-selected {
        background: initial;
        color: initial;
      }

      &[aria-select='true'] {
      }

      &[data-hide-border='true'] {
        border-right: none;
      }
    }

    button {
      opacity: 0;
      transition: opacity 1.5s ease;
    }

    .wj-row:hover button {
      opacity: 1;
    }
  }
`

const Root = styled.div`
  display: flex;
  overflow: hidden;

  ${MyFlexGrid}, ${StyledLoadingIndicator}, ${NoProductsInfo} {
    border-color: var(--split-border-color-dark);
    border-radius: var(--split-border-radius);
    border-width: thin;
    border-style: solid;
  }

  ${MyFlexGrid} {
    border-top-right-radius: 0;
  }

  ${StyledLoadingIndicator}, ${NoProductsInfo} {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: 0;
  }

  &[data-loading='true'],
  &[data-product-count='0'] {
    ${MyFlexGrid} {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      height: 40px !important;
      flex: 1 0 auto;

      [wj-part='root'] {
        // Turn off the scrollbars
        overflow: hidden !important;
      }
    }
  }
`

const StyledProductDetailCell = styled(ProductDataProvider)`
  max-height: 100%;

  > :last-child {
    padding-right: var(--spacing-unit);
    align-items: flex-start;
  }

  ${StyledAssortmentProductDetailMediaContainer} {
    flex: 0 0 40%;
    align-self: stretch;
    margin: 0;
    max-width: 100%;

    ${StyledProductMedia} {
      &,
      img {
        width: auto !important;
        height: auto !important;
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }
  }

  ${AssortmentProductDetail.styles.ProductActions} {
    display: none;
  }
`

//endregion

//region Store

class MyStore {
  constructor(public store: AssortmentSplitTableStore) {}

  get loading() {
    return this.store.queries.products.loading
  }
  @computed get columns() {
    return this.store.productGridColumns
  }

  @action onInitialized = (grid: FlexGrid) => {
    const { store } = this
    store.productGrid = grid
    grid.allowResizing = AllowResizing.None
    grid.allowDragging = AllowDragging.None
    grid.allowSorting = AllowSorting.None

    grid.mergeManager = new MyMergeManager(grid, this)
    let beforeScrollPosition
    grid.itemsSourceChanging.addHandler(() => {
      beforeScrollPosition = grid.scrollPosition
    })
    grid.itemsSourceChanged.addHandler(() => {
      grid.scrollPosition = beforeScrollPosition

      // NOTE: Hack for getting scroll tabs to working - Need to find better way of getting @computed to observe clientSize and scrollSize properties of productGrid
      if (!store.canScrollRight && store.productGridInitialized) {
        store.productGridInitialized = false
        store.productGridInitialized = true
      } else if (!store.productGridInitialized) {
        store.productGridInitialized = true
      }

      const {
        loading,
        store: {
          productTableSizing: {
            productRowHeight,
            statusColumnHeaderRowHeight,
            tableHeaderHeight
          }
        }
      } = this

      const headerRow = grid.columnHeaders.rows[0]
      headerRow.wordWrap = true
      headerRow.height = tableHeaderHeight

      if (loading) {
        grid.rows.defaultSize = 200
      } else {
        grid.rows
          .filter(identity)
          .forEach(
            (row, i) =>
              (row.height =
                i % 2 === 0
                  ? statusColumnHeaderRowHeight
                  : productRowHeight - statusColumnHeaderRowHeight)
          )
      }
    })
  }

  @action onUpdatedView = (grid: FlexGrid) => {
    const { store } = this
    if (!isEqual(store.gridClientSize, grid.clientSize)) {
      store.gridClientSize = grid.clientSize
    }
  }

  onFormatItem = (grid: FlexGrid, e: FormatItemEventArgs) => {
    const { panel, cell, row, col } = e
    const { columns } = grid
    const {
      store,
      store: {
        reactNodes,
        columnDict,
        productTableSizing: { statusColumnHeaderRowHeight },
        products,
        queries: {
          products: { loading }
        }
      }
    } = this

    const renderIntoCellDynamicProps = {
      cell,
      mode: 'dynamic',
      nodeDicts: reactNodes,
      key: `splitProducts_${panel.cellType}_${col},${row}`
    }

    if (!columns[col]) {
      return
    }

    // We have to render two rows per Product item so we can show the cluster type in a column header in each of the store cells
    // Due to idiosyncracies of the wijmo cell recycling, if only the 2nd sub row of a given product row is visible onscreen, the first
    // row (which contains the render we normally see!) will not be shown, and we'd render white space instead of the onscreen portion of the product card
    const isOddCell = row % 2 === 1

    if (panel === grid.columnHeaders) {
      if (row === 0) {
        if (col === 4) {
          const cellActionComponent = (
            <SplitTableStoreContext.Provider value={store}>
              <GlobalZoneSplitButton></GlobalZoneSplitButton>
            </SplitTableStoreContext.Provider>
          )

          renderReactIntoGridCell({
            c: (
              <SplitTableHeaderCell
                header={columns[col].header}
                actionComponent={cellActionComponent}
              />
            ),
            ...renderIntoCellDynamicProps
          })
        }
      }
    } else if (panel === grid.cells) {
      if (loading) {
        renderReactIntoGridCell({ c: <LoadingIndicator />, cell })
      } else {
        if (isOddCell) {
          if (col <= 1) {
            //cell.style.display = 'none'
            cell.style.zIndex = '0'
            //return
          }
        }

        const product = products[row / 2]

        let style: React.CSSProperties =
          col < 6 && isOddCell
            ? {
                // position: 'absolute',
                height: `calc(100% + ${statusColumnHeaderRowHeight}px)`,
                maxHeight: `calc(100% + ${statusColumnHeaderRowHeight}px)`,
                marginTop: `-${statusColumnHeaderRowHeight}px`
              }
            : {}

        if (!product.active) {
          style.opacity = 0.5
        }

        renderIntoCellDynamicProps.key += `_${product.id}`

        if (col === 0) {
          renderReactIntoGridCell({
            c: (
              <StyledProductDetailCell
                id={product.id}
                style={style}
                children={p => <AssortmentProductDetail {...p} />}
              />
            ),
            ...renderIntoCellDynamicProps
          })
        } else if (col === 1) {
          renderIntoCellDynamicProps.key += `_cluster`
          renderReactIntoGridCell({
            c: (
              <SplitTableStoreContext.Provider value={store}>
                <ClusterProductCell row={row} col={col} style={style} />
              </SplitTableStoreContext.Provider>
            ),
            ...renderIntoCellDynamicProps
          })
        } else if (col === 2) {
          renderIntoCellDynamicProps.key += `_storeCount`
          renderReactIntoGridCell({
            c: (
              <SplitTableStoreContext.Provider value={store}>
                <NumberCell row={row} col={col} style={style} valueKey={'storeCount'} />
              </SplitTableStoreContext.Provider>
            ),
            ...renderIntoCellDynamicProps
          })
        } else if (col === 3) {
          // Avg Depth
          renderIntoCellDynamicProps.key += `_avgDepth`
          renderReactIntoGridCell({
            c: (
              <SplitTableStoreContext.Provider value={store}>
                <NumberCell row={row} col={col} style={style} valueKey={'averageDepth'} />
              </SplitTableStoreContext.Provider>
            ),
            ...renderIntoCellDynamicProps
          })
        } else if (col === 4) {
          renderIntoCellDynamicProps.key += `_zoneQty`
          renderReactIntoGridCell({
            c: (
              <SplitTableStoreContext.Provider value={store}>
                <ZoneQuantityProductCell row={row} col={col} style={style} />
              </SplitTableStoreContext.Provider>
            ),
            ...renderIntoCellDynamicProps
          })
        } else if (col === 5) {
          renderIntoCellDynamicProps.key += `_totalStoreQty`
          renderReactIntoGridCell({
            c: (
              <SplitTableStoreContext.Provider value={store}>
                <TotalStoreQuantityProductCell row={row} col={col} style={style} />
              </SplitTableStoreContext.Provider>
            ),
            ...renderIntoCellDynamicProps
          })
        } else {
          const c = col - 6
          if (columnDict[c]) {
            const { cluster, store } = columnDict[c]
            if (!cluster || !store) return null

            //cell.setAttribute('data-hide-border', (!isEmpty(columnDict[c + 1]) && columnDict[c + 1].cluster == cluster).toString())

            // Is the cell to our right in the same cluster as us?

            // if (row % 2 == 1) {
            //   const {productCluster: pc, storeAssortmentProduct} = findStoreAssortmentProduct(product, cluster, store)
            //   const value                                        = !storeAssortmentProduct || !storeAssortmentProduct.buy ? 0 : storeAssortmentProduct.buy.quantity
            //
            //   if (!storeAssortmentProduct) {
            //     return null;
            //   }
            // }
            renderReactIntoGridCell({
              c: (
                <SplitTableStoreContext.Provider value={this.store}>
                  <StoreProductCell row={row} col={c} />
                </SplitTableStoreContext.Provider>
              ),
              ...renderIntoCellDynamicProps
            })
          }
        }
      }
    }
  }

  onScrollPositionChanged = (grid: FlexGrid) => {
    const {
      store: { clusterGrid }
    } = this
    clusterGrid.scrollPosition = new Point(
      grid.scrollPosition.x,
      clusterGrid.scrollPosition.y
    )
    clusterGrid.scrollIntoView(
      Math.floor(grid.scrollPosition.x),
      Math.floor(clusterGrid.scrollPosition.y)
    )
  }

  lastSelectedRow = -1
  onSelectionChanged = (grid: FlexGrid) => {
    const { lastSelectedRow } = this

    if (lastSelectedRow !== -1) {
      const cell = grid.cells.getCellElement(lastSelectedRow, 0)
      if (cell) {
        const row = cell.parentElement
        row.setAttribute('aria-selected', 'false')
      }
    }

    // Add an attribute to tell if the selected cell is in this row so that we can style all cells within the row
    this.lastSelectedRow = grid.selection.row
    if (this.lastSelectedRow !== -1) {
      const cell = grid.cells.getCellElement(this.lastSelectedRow, 0)
      if (cell) {
        cell.parentElement.setAttribute('aria-selected', 'true')
      }
    }
  }

  onBeginningEdit = async (grid: FlexGrid) => {
    if (!grid || !grid.selection) return

    const {
      selection: { row, col }
    } = grid
    const {
      store: { products, columnDict, findStoreAssortmentProduct, addProductToStore }
    } = this
    const product = products[row]

    if (col > 5) {
      const { store, cluster } = columnDict[col - 6]
      const { productCluster: pc, storeAssortmentProduct } = findStoreAssortmentProduct(
        product,
        cluster,
        store
      )
      if (!storeAssortmentProduct) {
        await addProductToStore(product, cluster, pc, store)
      } else {
      }
    } else {
    }
  }
}

//endregion

class MyMergeManager extends MergeManager {
  constructor(flexGrid, private store: MyStore) {
    super(flexGrid)
  }

  getMergedRange(panel, r, c, clip = true) {
    const { grid } = panel,
      {
        store: { loading }
      } = this
    // eslint-disable-next-line eqeqeq
    if (panel == grid.cells) {
      if (loading) {
        return new CellRange(0, 0, 1, grid.columns.length - 1)
      }

      if (c <= 5) {
        if (r % 2 === 0) {
          return new CellRange(r, c, r + 1, c)
        } else {
          //return new CellRange(r, c, r - 1, c);
        }
      } else {
        if (r % 2 === 0) {
          const {
            store: {
              store: { columnDict }
            }
          } = this

          let c1 = c - 6,
            c2 = c1

          while (
            columnDict[c1 - 1] &&
            // eslint-disable-next-line eqeqeq
            columnDict[c1 - 1].cluster == columnDict[c2].cluster
          ) {
            c1--
          }
          while (
            columnDict[c2 + 1] &&
            // eslint-disable-next-line eqeqeq
            columnDict[c2 + 1].cluster == columnDict[c1].cluster
          ) {
            c2++
          }
          return new CellRange(r, c1 + 6, r, c2 + 6)
        }
      }
      return super.getMergedRange(panel, r, c, clip)
    } else {
      return super.getMergedRange(panel, r, c, clip)
    }
  }
}

//region <AssortmentSplit_ProductTable />
interface MyProps extends StyledProps {}

export const AssortmentSplit_ProductTable: React.FunctionComponent<MyProps> = ({
  className
}) => {
  const splitStore = useAssortmentSplitStore(),
    { toggleShowSelectedProduct } = splitStore,
    store = useLocalStore(() => new MyStore(splitStore))

  // We need to react to size changes but the grid doesn't have an event to tell us
  const [ref, { width }] = useDimensions()
  splitStore.productGridWidth = width

  useHotkeys('d', toggleShowSelectedProduct)

  useEffect(
    () =>
      autorun(
        () => {
          const { columns } = store,
            { productGrid: grid, clusterGrid } = splitStore
          if (grid && columns) {
            grid.columns.beginUpdate()
            grid.columns.clear()
            columns.forEach(c => grid.columns.push(c))

            // Sync the column widths with the cluster table

            if (clusterGrid) {
              for (var c = 6; c < grid.columns.length; c++) {
                if (clusterGrid.columns.length > c) {
                  const { width } = clusterGrid.columnHeaders.getCellBoundingRect(
                    1,
                    c - 1
                  )
                  grid.columns[c].width = width
                }
              }
            }

            grid.columns.endUpdate()
          }
        },
        { name: `Update wijmo column list on initialization` }
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  /**
   * We double the products because we have to render 2 rows per product to show the product cluster status
   *   Each product card, zone quantity, and total store quantity (the first 3 columns in the GUI that are frozen) which span two rows
   *   @see MyMergeManager for more details
   **/
  const doubledProducts = useComputed(() =>
    splitStore.products.reduce((acc, p) => [...acc, p, p], [])
  )

  return useObserver(function useHook() {
    const {
      onInitialized,
      onFormatItem,
      onScrollPositionChanged,
      onUpdatedView,
      onSelectionChanged,
      onBeginningEdit,
      loading,
      store: { products }
    } = store

    return (
      <Root
        className={className}
        ref={ref}
        data-loading={loading}
        data-product-count={products.length}
      >
        <MyFlexGrid
          alternatingRowStep={0}
          autoGenerateColumns={false}
          allowDelete={false}
          frozenColumns={6}
          headersVisibility={HeadersVisibility.Column}
          selectionMode={SelectionMode.None}
          showSelectedHeaders={HeadersVisibility.Column}
          //columns={columns.slice()}
          itemsSource={loading ? [] : doubledProducts.slice()}
          initialized={onInitialized}
          formatItem={onFormatItem}
          scrollPositionChanged={onScrollPositionChanged}
          updatedView={onUpdatedView}
          selectionChanged={onSelectionChanged}
          onBeginningEdit={onBeginningEdit}
        />

        {loading && <StyledLoadingIndicator />}
        {!loading && isEmpty(doubledProducts) && <NoProductsInfo />}
      </Root>
    )
  })
}
//endregion
