import { TableRow } from '@components/UI-Components/StandardTableView/common/modals'
import { CentricNodeType } from '@modules/common/models/enums/CentricNodeType'
import { ClientDiscountColumnIdentifier } from '@modules/common/models/enums/ClientDiscountColumnIdentifiers'
import { DiscountNewRow } from '@modules/common/models/enums/ClientDiscountFieldMapping'
import { DiscountTableRow } from '@modules/common/models/interfaces/ClientsDiscount'
import { CentricModifiedCNLs } from '@modules/common/models/interfaces/ResponseCNLs'
import { SyncData } from '@modules/wholesale/syncSetting/services'
import { IZone } from '@modules/wholesale/wsNavigations/stores/WholesaleSettingsStore'
import { getModifiedCNL, getUpdateZoneList } from '@services/commonServices'
import { apolloClient, stores, strings } from '@stores'
import cloneDeep from 'lodash/cloneDeep'
import findIndex from 'lodash/findIndex'
import isEmpty from 'lodash/isEmpty'
import { action, computed, observable } from 'mobx'
import { toast } from 'react-toastify'
import { VIPChannel } from '@modules/common/models/enums/VIPChannel'
import { CriteriaOperationType } from '@modules/models'
import { DELETE_DISCOUNT, GET_CLIENTS_DISCOUNTS, UPSERT_DISCOUNT } from '../graphql'
import { VIPProductService } from '@modules/wholesale/createBuyingSession/constants/apiConstants'
import { storage } from '@services/storageService'
import { HttpService } from '@services/HttpService'
import { config } from '@config'

export interface INode {
  id: string
  name: string
}
export interface IActivityCategories extends INode {
  categories: INode[]
}
export class ClientDiscountStore {
  httpService = new HttpService()

  watchCategorySelect: Function = null

  activityCategories: Array<IActivityCategories> = []
  activityCategoriesErr: any = ''

  @observable selectedClient: TableRow = null
  @observable selectedActivity = ''
  @observable selectedCategory = ''

  @observable discountTableRows: Array<DiscountTableRow> = []
  @observable selectedDiscount: number = 0
  private syncAPIService = new SyncData()

  //Default worldwide will be selected
  @observable zoneList: Array<IZone> = []

  /**
   * initDiscountData
   * Syncs discount data to store
   */
  @action initDiscountData = (
    clientDiscount: Array<DiscountTableRow>,
    zones: Array<IZone>
  ) => {
    if (!isEmpty(zones)) {
      this.discountTableRows = cloneDeep(clientDiscount)
      this.zoneList = getUpdateZoneList(zones)
    }
  }

  /**
   * cleanupNewRowData
   * Cleans up the discount data
   */
  @action cleanupNewRowData = () => {
    this.selectedClient = null
    this.selectedActivity = null
    this.selectedCategory = null
    this.selectedDiscount = 0
  }

  /**
   * addNewDiscountRow
   * Add new client discount row
   */
  @action addNewDiscountRow = async () => {
    try {
      if (!this.activityCategories?.length) {
        const activityCategories = await this.getActivitiesAndCategories()
        if (activityCategories?.data?.data?.activities?.length)
          this.activityCategories = cloneDeep(activityCategories.data.data.activities)
      }
    } catch (e) {
      this.activityCategoriesErr = e
    }
    const discountData = cloneDeep(this.discountTableRows)
    const firstRow = discountData[0]
    if (!firstRow || (firstRow && !firstRow.isAddMode)) {
      this.cleanupNewRowData()
      discountData.unshift({
        id: DiscountNewRow.id,
        isAddMode: true
      })
      this.discountTableRows = discountData
    }
  }

  @action clearNewRow = () => {
    const discountRowsData = cloneDeep(this.discountTableRows)
    const firstRow = discountRowsData[0]
    if (firstRow && firstRow.id === DiscountNewRow.id) {
      discountRowsData.shift()
    }
    this.discountTableRows = discountRowsData
  }

  /**
   * onClientSelect
   * Syncs selected client to add discount
   */
  @action onClientSelect = (selectedClientData: any) => e => {
    const {
      clientSearch: { handlePopOverClose }
    } = stores

    this.selectedClient = selectedClientData
    handlePopOverClose()
  }

  /**
   * onActivitySelect
   * Syncs selected activity to add discount
   */
  @action onActivitySelect = event => {
    this.selectedActivity = event?.target?.value
  }

  /**
   * onCategorySelect
   * Syncs selected category to add discount
   */
  @action onCategorySelect = event => {
    this.selectedCategory = event?.target?.value
    if (this.selectedClient && this.selectedCategory) {
      this.upsertDiscount({
        clientId: this.selectedClient.id,
        name: this.selectedClient.name.toString(),
        activityId: this.selectedActivity,
        categoryId: this.selectedCategory,
        discount: this.selectedDiscount || 0
      })
    }
  }

  /**
   * categoriesForClientActivity
   * Returns available category options for given client
   * activity combination.
   */
  @computed get categoriesForClientActivity() {
    if (!this.selectedClient || !this.selectedActivity) return []
    return (
      this.activityCategories.find(activity => activity.id === this.selectedActivity)
        ?.categories || []
    )
  }

  /**
   * onDiscountChange
   * Syncs discount to store
   */
  @action onDiscountSelect = (rowData: DiscountTableRow) => async discount => {
    try {
      if (!this.activityCategories?.length) {
        const activityCategories = await this.getActivitiesAndCategories()
        if (activityCategories?.data?.data?.activities?.length)
          this.activityCategories = cloneDeep(activityCategories.data.data.activities)
      }
    } catch (e) {
      this.activityCategoriesErr = e
    }
    if (rowData.id && rowData.id !== DiscountNewRow.id) {
      this.upsertDiscount({
        ...rowData,
        discount: discount || 0
      })
    } else {
      this.selectedDiscount = discount || 0
    }
  }

  /**
   * Handles discount mutation
   * @param client - client id
   * @param category - category id
   * @param discount - client discount
   */
  @action upsertDiscount = async (data: DiscountTableRow) => {
    if (data.clientId && data.categoryId) {
      try {
        await apolloClient.mutate({
          mutation: UPSERT_DISCOUNT,
          variables: {
            client: data.clientId,
            category: data.categoryId,
            discount: data.discount
          },
          update: async (cache, result) => {
            const { findVIPClientDiscount } = cloneDeep(
              cache.readQuery({
                query: GET_CLIENTS_DISCOUNTS,
                variables: {
                  where: {
                    Channel: {
                      operation: CriteriaOperationType.NE,
                      value: VIPChannel.Retail
                    }
                  }
                }
              })
            )

            if (
              result?.data?.VIPBoard_UpsertClientDiscount?.status ===
              strings.mutationResultSuccess
            ) {
              const resultData: any = await this.syncAPIService.syncGlobalData({
                markupOrDiscounts: true
              })
              if (resultData?.message !== strings.sapSuccess) {
                toast.dismiss()
                toast.error(strings.discountSyncFailed, {
                  autoClose: 6000,
                  position: toast.POSITION.BOTTOM_RIGHT,
                  bodyClassName: 'error-toastr',
                  progressClassName: 'error-progress-bar-toaster'
                })
              }
            }
            const discountCNL: CentricModifiedCNLs = getModifiedCNL(
              CentricNodeType.VIPClientDiscount,
              result?.data?.VIPBoard_UpsertClientDiscount?.modifiedCNLs ?? []
            )
            if (!isEmpty(discountCNL)) {
              const indexToUpdate = findIndex(findVIPClientDiscount?.Items ?? [], [
                'Id',
                discountCNL.Id
              ])
              if (data.id && indexToUpdate !== -1) {
                findVIPClientDiscount.Items[indexToUpdate] = {
                  ...this.getDiscountElementTOUpsert(data, discountCNL)
                }
              } else {
                findVIPClientDiscount.Items.unshift(
                  this.getDiscountElementTOUpsert(data, discountCNL)
                )
              }

              cache.writeQuery({
                query: GET_CLIENTS_DISCOUNTS,
                variables: {
                  where: {
                    Channel: {
                      operation: CriteriaOperationType.NE,
                      value: VIPChannel.Retail
                    }
                  }
                },
                data: { findVIPClientDiscount }
              })
            }
          }
        })
      } finally {
        this.cleanupNewRowData()
      }
    }
  }

  /**
   * Returns discount element to upsert the graphql cache
   * @params data - Row data to upsert
   * @params discountCNL- Successful response CNL
   */
  getDiscountElementTOUpsert = (
    data: DiscountTableRow,
    discountCNL: CentricModifiedCNLs
  ) => {
    const activity = this.activityCategories.find(
      activity => activity.id === data.activityId
    )
    const category = activity?.categories.find(
      category => category.id === data.categoryId
    )

    return {
      Id: discountCNL.Id,
      Discount: data.discount,
      Category: {
        Id: category.id,
        Name: category.name,
        __typename: CentricNodeType.SpecItem,
        Activity: {
          Id: activity.id,
          Name: activity.name,
          __typename: CentricNodeType.VIPActivity
        }
      },
      Client: {
        Id: data.clientId,
        Name: data.name,
        __typename: CentricNodeType.Client
      }
    }
  }

  /**
   * Handles deleting of the client discount
   * @param clientDiscount - Id of client discount record
   */
  @action deleteDiscount = (clientDiscount: string) => async e => {
    if (clientDiscount) {
      try {
        let updatedDiscount = await apolloClient.mutate({
          mutation: DELETE_DISCOUNT,
          variables: {
            clientDiscount
          },
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: GET_CLIENTS_DISCOUNTS,
              variables: {
                where: {
                  Channel: {
                    operation: CriteriaOperationType.NE,
                    value: VIPChannel.Retail
                  }
                }
              }
            }
          ]
        })
        if (
          updatedDiscount?.data?.VIPBoard_DeleteClientDiscount?.status ===
          strings.mutationResultSuccess
        ) {
          const resultData: any = await this.syncAPIService.syncGlobalData({
            markupOrDiscounts: true
          })
          if (resultData?.message !== strings.sapSuccess) {
            toast.dismiss()
            toast.error(strings.discountSyncFailed, {
              autoClose: 6000,
              position: toast.POSITION.BOTTOM_RIGHT,
              bodyClassName: 'error-toastr',
              progressClassName: 'error-progress-bar-toaster'
            })
          }
        }
      } finally {
      }
    }
  }

  /**
   * Returns component enable status for new discount
   * row based on cell type and situations
   * @param fieldType - ClientDiscountColumnIdentifier
   */
  getNewRowDisableStatus = (fieldType: string) => {
    switch (fieldType) {
      case ClientDiscountColumnIdentifier.Activity:
        return this.selectedClient ? false : true
      case ClientDiscountColumnIdentifier.Category:
        return this.selectedClient && this.selectedActivity ? false : true
    }
  }

  /**
   * Returns error messages for new discount
   * row based on cell type and situations
   * @param fieldType - ClientDiscountColumnIdentifier
   */
  getCategoryErrorMessage = (fieldType: string) => {
    if (
      fieldType === ClientDiscountColumnIdentifier.Category &&
      this.selectedActivity &&
      !this.categoriesForClientActivity.length
    ) {
      return strings.categoryNotFound
    } else {
      return ''
    }
  }

  // Add andEdit discounts
  getActivitiesAndCategories = async () => {
    try {
      const url = `${config.buyingBoardServiceRestEndpoint}${VIPProductService.GET_ACTIVITIES_AND_CATEGORIES}`
      const accessToken: string = storage.getItem('access_token')

      const response = await this.httpService.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      return response
    } catch (e) {
      console.log(e)
    }
  }
}

export const clientDiscount = new ClientDiscountStore()
