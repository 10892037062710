/*
 * @Author: Raeesaa Metkari
 * @Date: 2020-03-25 13:24:42
 * @Last Modified by: Raeesaa Metkari
 * @Last Modified time: 2020-04-14 13:42:53
 */

import GET_CLIENT from './client.graphql'

export default GET_CLIENT
