export interface IGetProductForecast {
  buyingSessionGroupId?: string
  buyingSessionId?: string
  activityId?: string
  familyId?: string
  lineId?: string
  clientId?: string
  categoryValues?: Array<string>
}

export enum ForecastChangeTypeEnum {
  AVG_WEEKLY_SALES = 'AVG_WEEKLY_SALES',
  AVG_DEPTH = 'AVG_DEPTH',
  FORECAST_QUANTITY = 'FORECAST_QUANTITY'
}
