import { CriteriaOperationType } from '@models'
import { ListViewColumnDefinition } from '@components/UI-Components/StandardTableView/models/ListviewColumnDefinition'
import {
  BuyingSessionAssortmentType,
  ChannelSubSection
} from '@modules/common/models/enums/BSGroupAndBSNav'
import { ViewTabs } from '@modules/common/models/enums/Views'
import { VIPChannel } from '@modules/common/models/enums/VIPChannel'
import { BuyTargetSync } from '@modules/wholesale/buyingSessionGroupProductWrapper/services/buyTabService'
import { getViewTabsToRender } from '@services/commonServices'
import { wholesaleCollectionService } from '@services/wholesale/wholesaleCollectionService'
import { collectionListViewDefinitionService } from '@services/wholesale/wsCollectionListViewDefinitionService'
import { nav } from '@stores/nav'
import { strings, stores } from '@stores'
import find from 'lodash/find'
import startCase from 'lodash/startCase'
import uniqBy from 'lodash/uniqBy'
import { action, computed, observable } from 'mobx'
import { computedFn } from 'mobx-utils'
import { VIPClientType } from '@modules/common/models/enums/VIPClientType'
import { collectionKpiViewDefinitionService } from '@services/wholesale/wsCollectionKpiViewDefinitionService'

const ASSORTMENT_FIELD_MAP = {
  [BuyingSessionAssortmentType.client]: 'VIPClientAssortment',
  [BuyingSessionAssortmentType.cluster]: 'VIPClusterAssortment',
  [BuyingSessionAssortmentType.door]: 'VIPDoorAssortment'
}

export class WholesaleAssortmentProductStore {
  query: any = {}

  @observable isLoading = false
  @observable showConfirmationPopUpOnSyncAssortment = false
  @observable areAssortmentsAvaliable = false

  /**
   * Computed map for list view column headers for different tabs
   */
  listViewColumnDefinitionMap = computedFn(
    (subSection: ChannelSubSection, assortmentType?) => {
      const wsListViewColumnDefinitionForDistribution: Array<ListViewColumnDefinition> =
        collectionListViewDefinitionService.buildListViewDefinitionForDistribution(
          subSection,
          assortmentType
        )

      const wsListViewColumnDefinitionForBuy: Array<ListViewColumnDefinition> =
        collectionListViewDefinitionService.buildListViewDefinitionForBuy(
          subSection,
          assortmentType
        )

      const wsListViewColumnDefinitionForForecast: Array<ListViewColumnDefinition> =
        collectionListViewDefinitionService.buildListViewDefinitionForForecast(
          assortmentType
        )

      return {
        [BuyingSessionAssortmentType.client]: {
          [ViewTabs.DISTRIBUTION]: wsListViewColumnDefinitionForDistribution,
          [ViewTabs.BUY]: wsListViewColumnDefinitionForBuy,
          [ViewTabs.FORECAST]: wsListViewColumnDefinitionForForecast
        },
        [BuyingSessionAssortmentType.cluster]: {
          [ViewTabs.DISTRIBUTION]: wsListViewColumnDefinitionForDistribution
        },
        [BuyingSessionAssortmentType.door]: {
          [ViewTabs.DISTRIBUTION]: wsListViewColumnDefinitionForDistribution,
          [ViewTabs.BUY]: wsListViewColumnDefinitionForBuy,
          [ViewTabs.FORECAST]: wsListViewColumnDefinitionForForecast
        }
      }
    }
  )

  /**
   * Computed map for kpi view column headers for different tabs
   */
  @computed get kpiViewColumnDefinitionMap() {
    const {
      queryParams: { assortmentType }
    } = stores.nav
    return {
      [ViewTabs.FORECAST]:
        collectionKpiViewDefinitionService.buildKpiViewDefinitionForForecast(),
      [ViewTabs.RTL_BUY]:
        collectionKpiViewDefinitionService.buildKpiViewDefinitionForBuy(assortmentType)
    }
  }

  getTabsForView = computedFn(channels => {
    const {
      nav: {
        queryParams: { tab, productLayout, assortmentType, subSection }
      }
    } = stores
    let tabList = []
    let selectedTab
    let tabColDefinition
    if (productLayout === strings.list) {
      tabList = getViewTabsToRender(strings.list, assortmentType, [
        VIPChannel[startCase(subSection)]
      ])
      selectedTab = find(tabList, { name: tab?.toUpperCase() }) || tabList[0]
      tabColDefinition = this.listViewColumnDefinitionMap(
        subSection as ChannelSubSection,
        assortmentType
      )[assortmentType][selectedTab?.key]
    }
    if (productLayout === strings.kpi) {
      tabList = getViewTabsToRender(strings.kpi, assortmentType, channels)
      selectedTab = find(tabList, { name: tab?.toUpperCase() }) || tabList[0]
      tabColDefinition = this.kpiViewColumnDefinitionMap[selectedTab?.key]
    }
    return {
      tabList,
      selectedTab: selectedTab?.name,
      tabColDefinition
    }
  })

  updateProductsRefetchQuery = query => {
    this.query.productsRefetchQuery = query
  }

  @action
  setShowConfirmationPopUpOnSyncAssortment = () => () => {
    this.showConfirmationPopUpOnSyncAssortment = true
  }

  @action
  handleCloseConfirmPopupSyncAssortment = () => () => {
    this.showConfirmationPopUpOnSyncAssortment = false
  }

  @computed get whereInputForBSPToAdd() {
    const {
      queryParams: { buyingSession, subSection }
    } = nav
    const channel =
      subSection === ChannelSubSection.Wholesale
        ? VIPChannel.Wholesale
        : VIPChannel.Retail
    return {
      __Parent__: {
        Id: { operation: CriteriaOperationType.EQ, value: buyingSession }
      },
      Original: {
        Active: { operation: CriteriaOperationType.EQ, value: true }
        // },
        // GIV_VIPBuyingSessionProduct_ClusterAssortmentProducts_reflist: {
        //   GIV_VIPAssortmentProduct_Assortment_ref: {
        //     VIPClusterAssortment: {
        //       Id_not: assortment
        //     }
        //   }
      },
      Channels: { operation: CriteriaOperationType.EQ, value: channel }
    }
  }

  /**
   * Method for building where input for Assortment products query
   * @param {string} activity - Name of activity
   * @param {Array<string>} childrenMeta - ChildrenMeta for given activity
   * @param {Array<string>} childrenMetaValues - Values for hierarchy chilren
   * @returns Where input object for assortment products for a given assortment ID
   */

  whereInputForwhereInputForAssortmentProduct = (
    activity: string,
    childrenMeta: Array<string>,
    childrenMetaValues: Array<string>
  ) => {
    const {
      queryParams: { assortmentType, assortment, buyingSession }
    } = nav

    if (assortmentType === BuyingSessionAssortmentType.door) {
      return {
        __Parent__: {
          VIPDoorAssortment: {
            Door: {
              __Parent__: {
                ClientType: {
                  operation: CriteriaOperationType.EQ,
                  value: VIPClientType.Internal
                }
              }
            }
          }
        },
        Original: {
          Original: wholesaleCollectionService.buildAttributeHierarchyQuery(
            activity,
            childrenMeta,
            childrenMetaValues
          ),
          __Parent__: {
            Id: {
              operation: CriteriaOperationType.EQ,
              value: buyingSession
            }
          }
        }
      }
    }

    return {
      GIV_VIPAssortmentProduct_Assortment_ref: {
        [ASSORTMENT_FIELD_MAP[assortmentType]]: {
          Id: { operation: CriteriaOperationType.EQ, value: assortment }
        }
      },
      Original: {
        Original: wholesaleCollectionService.buildAttributeHierarchyQuery(
          activity,
          childrenMeta,
          childrenMetaValues
        )
      }
    }
  }

  @computed get buyingSessionAssortmentProductSummaryWhere() {
    const {
      nav: {
        queryParams: { assortment, assortmentType }
      }
    } = stores

    const assortmentWhere = assortment
      ? {
          GIV_VIPAssortmentProduct_Assortment_ref: {
            [ASSORTMENT_FIELD_MAP[assortmentType]]: {
              Id: { operation: CriteriaOperationType.EQ, value: assortment }
            }
          }
        }
      : null

    return assortmentWhere
  }

  // FUnction for getting the data

  loadFunc = async (fetchMore, offset) => {
    //need to rename this
    if (this.isLoading) {
      return false
    }
    this.isLoading = true
    fetchMore({
      variables: {
        offset
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev

        const newBuyingSessionProducts = fetchMoreResult.findVIPBuyingSessionProduct.Items
        const items = uniqBy(
          [...prev.findVIPBuyingSessionProduct.Items, ...newBuyingSessionProducts],
          node => node.Id
        )

        return newBuyingSessionProducts.length
          ? {
              findVIPBuyingSessionProduct: {
                __typename: prev.findVIPBuyingSessionProduct.__typename,
                Items: items,
                Page: fetchMoreResult.findVIPBuyingSessionProduct.Page
              }
            }
          : prev
      }
    }).then(() => {
      this.isLoading = false
    })
  }

  @action
  handleBuyTargetSyncAssortment = () => () => {
    const {
      nav: {
        queryParams: { buyingSessionGroupId }
      }
    } = stores
    try {
      new BuyTargetSync()
        .syncBuyTabData(buyingSessionGroupId)
        .then(async response => {
          if (response.status === 200) {
            await this.query.productsRefetchQuery()
          }
        })
        .catch(error => {})
    } catch (error) {
    } finally {
      this.showConfirmationPopUpOnSyncAssortment = false
    }
  }

  @action
  setAssortmentAvaliable = avaliable => {
    this.areAssortmentsAvaliable = avaliable
  }
}
