import findIndex from 'lodash/findIndex'
import { action, observable } from 'mobx'

export class ClusterExclusionMultiSelectStore {
  @observable newSelectedClusters: Array<any> = []
  @observable clusterListAnchorEl = null
  @observable rtlClusterListAnchorEl = null
  @observable selectedClusters: Array<any> = []

  /**
   * handleClusterPopOverClick
   * Sets anchor element for client search pop over
   */
  @action
  handleClusterPopOverClick = event => {
    this.clusterListAnchorEl = event.currentTarget
  }

  /**
   * handleRtlClusterPopOverClick
   * Sets anchor element for cluster search pop over
   */
  @action
  handleRtlClusterPopOverClick = event => {
    this.rtlClusterListAnchorEl = event.currentTarget
  }

  /**
   * handleClusterPopOverClose
   * Closes the popover
   * If Closed Empty
   */
  @action
  handleClusterPopOverClose = () => {
    this.clusterListAnchorEl = null
    this.rtlClusterListAnchorEl = null
    this.newSelectedClusters = []
  }

  @action
  setSelectedClusters = (excludedClusters?: any[]) => {
    this.selectedClusters = [...excludedClusters]
    this.newSelectedClusters = [...excludedClusters]
  }

  /**
   * To check single row checkbox
   */
  @action
  onCheckboxClick = row => e => {
    const cpy = [...this.newSelectedClusters]
    if (e.target.checked) {
      cpy.push(row)
    } else {
      const indexToRemoveId = findIndex(cpy, (e: any) => e.Id === row.Id)
      if (indexToRemoveId > -1) {
        cpy.splice(indexToRemoveId, 1)
      }
    }
    this.newSelectedClusters = cpy
  }
}

export const clusterExclusionMultiSelect = new ClusterExclusionMultiSelectStore()
