import { CriteriaOperationType } from '@modules/models'

/**
 * Returns id selection fragment of
 * graphql query
 * @param id
 */
export const getIdSelector = (id: string) => {
  return {
    Id: { operation: CriteriaOperationType.EQ, value: id }
  }
}
