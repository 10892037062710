import GET_BUYING_GROUP_WITH_CLIENTS from './getBuyingGroupWithClients.graphql'
import UPDATE_AVAILABLE_FLAG_FOR_CLIENT from './updateExcludedClientForBSG.graphql'
import BULK_UPDATE_AVAILABLE_FLAG_FOR_CLIENTS from './bulkUpdateExcludedClientsForBSG.graphql'

export * from './hooks'

export {
  GET_BUYING_GROUP_WITH_CLIENTS,
  UPDATE_AVAILABLE_FLAG_FOR_CLIENT,
  BULK_UPDATE_AVAILABLE_FLAG_FOR_CLIENTS
}
