import Typography from '@components/std-components/Typography'
import { strings } from '@stores'
import React from 'react'

export const InactiveIcon: React.FC = (): JSX.Element => {
  return (
    <Typography variant='body1' color='error'>
      {strings.inactive.toLocaleUpperCase()}
    </Typography>
  )
}
