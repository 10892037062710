/*
 * @Author: Raeesaa Metkari
 * @Date: 2020-02-18 22:36:19
 * @Last Modified by: Raeesaa Metkari
 * @Last Modified time: 2020-02-18 22:36:40
 */

export enum Channel {
  Retail = 'Retail',
  Wholesale = 'Wholesale'
}
