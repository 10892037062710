import { ApolloClient } from '@apollo/client/core/ApolloClient'
import { Query } from '@apollo/client/react/components/Query'
import { withApollo } from '@apollo/client/react/hoc/withApollo'
import { StyledProps } from '@components/3rd-party/mui'
import { User } from '@models'
import { assortmentStringFragments } from '@modules/retail/assortment/graphql'
import { GET_CHANNELS } from '@modules/retail/productDetail/graphql/getChannels'
import { GET_RATING_TYPES } from '@modules/retail/productDetail/graphql/getRatingTypes'
import { GET_PRODUCT_DETAILS } from '@modules/retail/productDetail/graphql/productDetails'
import { UPDATE_PRODUCT_MOQ } from '@modules/retail/productDetail/graphql/updateProductMOQ'
import { UPDATE_PRODUCT_STATUS } from '@modules/retail/productDetail/graphql/updateProductStatus'
import { UPDATE_ACTIVE_FLAG } from '@modules/retail/productDetail/graphql/updateProductStatusFlag'
import { IPermissions } from '@routes'
import { storage } from '@services/storageService'
import { apolloClient, nav } from '@stores'
import isEmpty from 'lodash/isEmpty'
import { action, reaction } from 'mobx'
import { disposeOnUnmount, observer } from 'mobx-react'
import React from 'react'
import { withRouter } from 'react-router-dom'

import { ProductDetail } from '../component/ProductDetail'

interface IProps extends StyledProps {
  client?: ApolloClient<any>
  match?: any
  history?: any
  refetch?: any
  permissions?: IPermissions
}

@(withRouter as any)
@(withApollo as any)
@observer
export class ProductDetailContainer extends React.Component<IProps> {
  @action handleProductStatusChange = async (value, buyingSessionProduct) => {
    const {
      client,
      match: {
        params: { productId }
      }
    } = this.props

    let bsProduct = buyingSessionProduct
    try {
      bsProduct = apolloClient.readFragment({
        id: productId,
        fragment:
          assortmentStringFragments.buyingSessionProductWithAttrAndAssortmentProducts
      })
    } catch (err) {}

    const {
      isActive,
      moq,
      product,
      assortmentProducts = [],
      zoneAssortmentProducts = []
    } = bsProduct

    await client.mutate({
      mutation: UPDATE_PRODUCT_STATUS,
      variables: { productID: productId, status: value, zoneIdWhere: null },
      optimisticResponse: {
        __typename: 'Mutation',
        updateBuyingSessionProductStatus: {
          __typename: 'BuyingSessionProduct',
          id: productId,
          productStatus: value,
          isActive,
          moq,
          assortmentProducts,
          zoneAssortmentProducts,
          product: {
            __typeName: 'Product',
            ...product
          }
        }
      }
    })
  }

  @action updateProductMOQ = async value => {
    const {
      client,
      match: {
        params: { productId }
      }
    } = this.props
    await client.mutate({
      mutation: UPDATE_PRODUCT_MOQ,
      variables: { productID: productId, value: value || 0 }
    })
  }

  @action handleStatusChange = async isActive => {
    const {
      client,
      match: {
        params: { productId }
      }
    } = this.props
    await client.mutate({
      mutation: UPDATE_ACTIVE_FLAG,
      variables: { productID: productId, productStatus: !isActive }
    })
  }

  @disposeOnUnmount refetchWhenProductDetailsClose = reaction(
    () => ({ closed: isEmpty(nav.params.detail) }),
    ({ closed }) => closed && this.props.refetch && this.props.refetch(),
    { name: `Refetch product details query data after detail sidebar closes` }
  )

  render() {
    const {
      props: {
        permissions,
        match: {
          params: { productId }
        }
      }
    } = this
    const user = storage.getItem<User>('user')

    return (
      <Query
        query={GET_PRODUCT_DETAILS}
        variables={{ productId, userID: user.id }}
        fetchPolicy={'cache-and-network'}
      >
        {({ loading, error = null, data = {} }) => {
          return (
            <Query query={GET_CHANNELS}>
              {({ data: channels = { channels: [] } }) => {
                return (
                  <Query query={GET_RATING_TYPES}>
                    {({ data: ratingTypes = { ratingTypes: [] } }) => {
                      if (error) return <div>Error</div>
                      return (
                        <ProductDetail
                          handleProductStatusChange={this.handleProductStatusChange}
                          data={data}
                          loading={loading && isEmpty(data)}
                          updateProductMOQ={this.updateProductMOQ}
                          handleStatusChange={this.handleStatusChange}
                          channels={channels.channels || []}
                          ratingTypes={ratingTypes.ratingTypes || []}
                          permissions={permissions}
                        />
                      )
                    }}
                  </Query>
                )
              }}
            </Query>
          )
        }}
      </Query>
    )
  }
}
