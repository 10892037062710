import { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions'
import { useQuery } from '@apollo/client/react/hooks/useQuery'

import {
  GET_ASSOCIATED_LOOKS,
  GET_CLIENT_ASSORTMENT_PRODUCT_DETAIL,
  GET_ORDER_PRODUCT_NOTIFICATIONS,
  ORDER_PRODUCT_DETAIL
} from '..'

export const useClientAssortmentProductDetail = (
  assortmentProductWhere,
  skip,
  fetchPolicy: WatchQueryFetchPolicy = 'cache-first'
) => {
  return useQuery(GET_CLIENT_ASSORTMENT_PRODUCT_DETAIL, {
    skip: skip || !assortmentProductWhere,
    fetchPolicy,
    variables: {
      where: assortmentProductWhere
    }
  })
}

export const useOrderProduct = (
  orderProductWhereQuery,
  fetchPolicy: WatchQueryFetchPolicy = 'cache-first'
) => {
  return useQuery(ORDER_PRODUCT_DETAIL, {
    skip: !orderProductWhereQuery,
    fetchPolicy,
    variables: {
      where: orderProductWhereQuery
    }
  })
}

export const useAssociatedLooks = (
  vipProductWhereQuery,
  fetchPolicy: WatchQueryFetchPolicy = 'cache-first'
) => {
  return useQuery(GET_ASSOCIATED_LOOKS, {
    skip: !vipProductWhereQuery,
    fetchPolicy,
    variables: {
      where: vipProductWhereQuery
    }
  })
}

export const useOrderProductNotifications = (
  orderProductNotificationsQuery,
  fetchPolicy: WatchQueryFetchPolicy = 'cache-first'
) => {
  return useQuery(GET_ORDER_PRODUCT_NOTIFICATIONS, {
    skip: !orderProductNotificationsQuery,
    fetchPolicy,
    variables: {
      where: orderProductNotificationsQuery
    }
  })
}
