export enum clientOrderManagementTableViewColumnLabel {
  ClientName = 'Market Name',
  clientCode = 'Market Code',
  country = 'Country',
  doors = 'Doors',
  stores = 'Stores',
  shipTo = 'Ship To',
  creationDate = 'Date',
  order = 'Order #',
  zam = 'ZAM',
  salesAsst = 'Sales Asst.',
  qty = 'Qty',
  value = 'Value',
  globalValue = 'Value SGD',
  activityGender = 'Activity Gender',
  departmentGender = 'Department Label',
  status = 'Status',
  deliveryDate = 'Delivery Date',
  actions = 'Actions',
  sapStatus = 'ERP Status',
  sapOrderNumber = 'ERP Order #',
  channel = 'Channel',
  zoneName = 'Zone Name',
  zoneCode = 'Zone Code'
}
