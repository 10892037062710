import React from 'react'
import Skeleton from 'react-loading-skeleton'
import * as css from '@modules/wholesale/salesDocType/component/SalesDocType.css'

export const BuyingSessionGroupDateSkeleton = () => {
  return (
    <div className={css.skeletonContainer}>
      <div className={css.labelSkeleton}>
        <Skeleton width={300} />
      </div>
      <div className={css.valueSkeleton}>
        <Skeleton height={20} />
        <Skeleton height={20} />
      </div>
    </div>
  )
}
