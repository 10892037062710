import {
  RESTRICT_PRODUCT_BY_ZONE_DEFAULT_TAB_VALUE as defaultTabValue,
  RESTRICT_PRODUCT_BY_ZONE_TABS as tabs
} from '@modules/collection/constants'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import { stores } from '@stores'
import React from 'react'
import * as css from './RestrictProductByZone.css'

interface RestrictProductByZoneTabsProps {}

export const RestrictProductByZoneTabs: React.FunctionComponent<RestrictProductByZoneTabsProps> =
  () => {
    const {
      restrictProductByZoneModal: { changeSelectedTabForRestricProduct }
    } = stores
    const [value, setValue] = React.useState(defaultTabValue)
    const handleChange = (event, newValue) => {
      changeSelectedTabForRestricProduct(newValue)
      setValue(newValue)
    }
    return (
      <div>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor='primary'
          variant='fullWidth'
          classes={{ root: css.tabsRoot, indicator: css.tabsIndicator }}
        >
          {tabs.map((tab, index) => (
            <Tab
              key={`${tab}_${index}`}
              disableRipple
              classes={{ root: css.tabRoot, selected: css.tabSelected }}
              label={tab}
              value={tab}
            />
          ))}
        </Tabs>
      </div>
    )
  }
