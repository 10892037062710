import { StyledProps } from '@components/3rd-party/mui'
import { useAuthorizationContext } from '@components/Utils/Authorization'
import { DeleteProductConformationOnLastRMClusterDelete } from '@modules/retail/assortment/component/DeleteProductConformationOnLastRMClusterDelete'
import {
  useAddProductsToStoreAssortment,
  useClusterStoreAssociations,
  useRemoveBuyerClusterFromAssortmentProduct,
  useRemoveProductFromStoreAssortment,
  useSplitZoneQuantityToAllStores,
  useSplitZoneQuantityToStores,
  useUpdateAssortmentProductQuantity,
  useUpdateZoneAssortmentProductQuantity,
  useZoneAssortmentProductsWithStores
} from '@modules/retail/collection/graphql/hooks'
import { apolloClient, nav } from '@stores'
import classNames from 'classnames'
import { autorun, reaction } from 'mobx'
import { useLocalStore, useObserver } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import * as css from './AssortmentSplitTable.css'
import { AssortmentSplit_ProductTable } from './AssortmentSplit_ProductTable'
import { AssortmentSplit_StoreClustersTable } from './AssortmentSplit_StoreClustersTable'
import { AssortmentSplit_Toolbar } from './AssortmentSplit_Toolbar'
import { AssortmentSplitTableStore, SplitTableStoreContext } from './store'

//region Styled Components
const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0 auto auto 0;
  max-width: 100%;
  overflow: hidden;

  .wj-cell {
    font-family: Roboto;
  }
`

const Toolbar = styled(AssortmentSplit_Toolbar)`
  flex: 0 0 auto;
  margin-bottom: 10px;
`

const ClustersTable = styled(AssortmentSplit_StoreClustersTable)`
  flex: 0 0 auto;
`

const ProductTableContainer = styled.div`
  flex: 1;
  max-height: fit-content;
  border-bottom: none;
  margin-top: -4px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`

const ProductTable = styled(AssortmentSplit_ProductTable)`
  flex: 1;
  display: flex;
  flex-direction: column;

  > * {
    flex: 1;
  }
`

//endregion

//region <AssortmentSplitTable />
interface MyProps extends StyledProps {
  buyingSession?
}

export const AssortmentSplitTable: React.FunctionComponent<MyProps> = ({
  className,
  buyingSession
}) => {
  return useObserver(function useHook() {
    const store = useLocalStore(() => new AssortmentSplitTableStore())
    // Cleanup other react render trees at unmount
    useEffect(() => {
      return () => {
        store.reactNodes.forEach(node => ReactDOM.unmountComponentAtNode(node))
        store.trackProductScroller()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    store.permissions = useAuthorizationContext().permissions
    store.queries = {
      clusters: useClusterStoreAssociations(),
      products: useZoneAssortmentProductsWithStores()
    }
    store.mutations = {
      addProductsToStoreAssortment: useAddProductsToStoreAssortment(),
      updateAssortmentProductQuantity: useUpdateAssortmentProductQuantity(),
      updateZoneAssortmentProductQuantity: useUpdateZoneAssortmentProductQuantity(),
      removeProductFromStoreAssortment: useRemoveProductFromStoreAssortment(),
      splitToStores: useSplitZoneQuantityToStores(),
      splitProductsToAllStores: useSplitZoneQuantityToAllStores(),
      removeBuyerClusterFromAssortmentProduct:
        useRemoveBuyerClusterFromAssortmentProduct()
    }

    store.observeClustersQuery()
    store.observeProductsQuery()
    store.observeBuyingSession(buyingSession)

    useEffect(
      () =>
        reaction(
          () => store.filteredStores.size,
          size => {
            const { filteredStores } = store
            nav.updateQueryParams({
              ast_stores: size
                ? Array.from(filteredStores.keys()).sort().join(',')
                : undefined
            })
          }
        ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    )

    // Clear cache on unmount
    useEffect(() => {
      return () => {
        const cache: any = apolloClient.cache
        // Hack for emptying cache for queries made by this component on unmount
        Object.keys(cache.data.data).forEach(key => {
          if (
            key.match(/^StoreByZoneAssortment/) ||
            key.match(/^ClusterWiseStoreAssortmentProducts/)
          ) {
            cache.data.delete(key)
          }
        })
      }
    }, [])

    useEffect(
      () =>
        autorun(() => {
          const {
              queryParams: { ast_stores }
            } = nav,
            { filteredStores } = store
          if (ast_stores) {
            const keys = ast_stores.split(',')
            if (keys.length !== filteredStores.size) {
              filteredStores.replace(new Set(keys))
            }
          }
        }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    )

    return (
      <Root className={classNames(css.root, className)}>
        <SplitTableStoreContext.Provider value={store}>
          <Toolbar />
          <ClustersTable />
          <ProductTableContainer>
            <ProductTable />
          </ProductTableContainer>
          <DeleteProductConformationOnLastRMClusterDelete
            onDelete={store.confirmDeleteProductConfirmationOnLastRMClusterDeleteDialog}
            openDialog={
              store.isOpenDeleteProductConfirmationOnLastRMClusterDeleteDialogSplit
            }
            onClose={store.closeDeleteProductConfirmationOnLastRMClusterDeleteDialogSplit}
            loading={store.rmZoneAssortmentProductDeleteInProgress}
          />

          <DeleteProductConformationOnLastRMClusterDelete
            onDelete={
              store.confirmDeleteProductConfirmationOnLastStoreAssortmentProductRemoval
            }
            openDialog={
              store.isOpenDeleteProductConfirmationOnLastStoreAssortmentProductRemoval
            }
            onClose={
              store.closeDeleteProductConfirmationOnLastStoreAssortmentProductRemoval
            }
            loading={store.isLastStoreAssortmentProductDeletionInProgress}
          />
        </SplitTableStoreContext.Provider>
      </Root>
    )
  })
}
//endregion
