import { storage } from '@services/storageService'
import reduce from 'lodash/reduce'

export enum FmtVersion {
  Version1 = 1,
  Version2 = 2
}

export enum FmtNodeInfo {
  Min = 'Min',
  Mid = 'Mid',
  Max = 'Max'
}

export enum FmtAttrInc {
  No = 'No',
  Rel = 'Rel',
  BuildIn = 'BuildIn',
  All = 'All'
}

export enum FmtAttrInfo {
  ValueOnly = 'ValueOnly',
  Min = 'Min',
  Mid = 'Mid',
  Max = 'Max'
}

export enum FmtAttrFormat {
  Yes = 'Yes',
  No = 'No'
}

export enum FmtAttrLocal {
  bg = 'bg',
  bn = 'bn',
  cs = 'cs',
  da = 'da',
  de = 'de',
  el = 'el',
  en = 'en',
  en_GB = 'en_GB',
  es = 'es',
  es_419 = 'es_419',
  et = 'et',
  fi = 'ft',
  fr = 'fr',
  ga = 'ga',
  hi = 'hi',
  hr = 'hr',
  hu = 'hu',
  it = 'it',
  ja = 'ja',
  ko = 'ko',
  lb = 'lb',
  lt = 'lt',
  lv = 'lv',
  ms = 'ms',
  mt = 'mt',
  nl = 'nl',
  pl = 'pl',
  pt = 'pt',
  pt_BR = 'pt_BR',
  ro = 'ro',
  ru = 'ru',
  sk = 'sk',
  sl = 'sl',
  sv = 'sv',
  th = 'th',
  tr = 'tr',
  zh = 'zh',
  zh_TW = 'zh_TW'
}

export enum AttributeName {
  FmtAttrFormat = 'Fmt.Attr.Format',
  FmtAttrInc = 'Fmt.Attr.Inc',
  FmtAttrInfo = 'Fmt.Attr.Info',
  FmtComplete = 'Fmt.Complete',
  FmtNodeInfo = 'Fmt.Node.Info',
  FmtVersion = 'Fmt.Version',
  FmtAttrList = 'Fmt.Attr.List',
  DepPath = 'Dep.Path',
  QryURL = 'Qry.URL',
  QryXML = 'Qry.XML',
  QryValueAttr = 'Qry.Value.Attr',
  QryValuePath = 'Qry.Value.Path',
  QryLimitBegin = 'Qry.Limit.Begin',
  QryLimitEnd = 'Qry.Limit.End',
  QryLimitPath = 'Qry.Limit.Path',
  FmtAttrLocal = 'Fmt.Attr.Locale',
  uploadedfiles = 'uploadedfiles[]'
}

export const isValid = input => {
  if (!input) {
    return false
  }
  return input.Status === 'Successful'
}

export const sessionUrl = () => storage.getItem('session_url')

export const merge = array => {
  return reduce(
    array,
    (mergedObj, arrElement) => {
      return { ...mergedObj, ...arrElement }
    },
    {}
  )
}
