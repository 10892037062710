import { Icons } from '@icons'
import { VIEW_ATTRIBUTE_SELECTORS_TABS as viewAttributeSelectorTabs } from '@modules/collection/constants'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import { stores } from '@stores'
import { useObserver } from 'mobx-react-lite'
import React from 'react'
import * as css from './ViewAttributeSelectorTabs.css'

interface ViewAttributeSelectorTabsProps {}

export const ViewAttributeSelectorTabs: React.FunctionComponent<ViewAttributeSelectorTabsProps> =
  () => {
    return useObserver(function useHook() {
      const {
        collection: { onViewAttributeSelectorTabChange, selectedViewAttributeSelectorTab }
      } = stores

      return (
        <div>
          <Tabs
            action={ref => ref?.updateIndicator()}
            value={selectedViewAttributeSelectorTab}
            onChange={onViewAttributeSelectorTabChange}
            indicatorColor='primary'
            classes={{ root: css.tabsRoot, indicator: css.tabsIndicator }}
            centered
          >
            {viewAttributeSelectorTabs.map((tab, index) => (
              <Tab
                key={`${tab.label}_${index}`}
                disableRipple
                classes={{
                  root: css.tabRoot,
                  selected: css.tabSelected,
                  // wrapper: css.tabIconWrapper,
                  labelIcon: css.tabLabelIcon
                }}
                label={tab.label}
                value={tab.value}
                icon={
                  tab.icon ? <Icons className={css.tabIcons} iconname={tab.icon} /> : null
                }
              />
            ))}
          </Tabs>
        </div>
      )
    })
  }
