import { MutationFunctionOptions } from '@apollo/client/react/types/types'
import GET_WS_ACTIVITIES from '@modules/wholesale/createBuyingSession/graphql/getWSActivities'
import { ExecutionResult } from 'graphql/execution'
import remove from 'lodash/remove'
import { action, observable } from 'mobx'

export class ActivitySelectionSettingsStore {
  @observable selectedActivities = []
  @observable isActivityFiltered = false
  mutations: {
    setAvailableFlagForDeliveryWindow?: (
      options?: MutationFunctionOptions<any, { availableActivities: string[] }>
    ) => Promise<void | ExecutionResult>
  } = {}

  @action
  handleToggle = e => {
    e.target.checked
      ? this.selectedActivities.push(e.target.value)
      : remove(this.selectedActivities, Id => Id === e.target.value)
  }

  getSelectedActivities = activities => {
    this.selectedActivities = activities
      .filter(
        ({ GIV_VIPActivity_AvailableForBuying_bool }) =>
          GIV_VIPActivity_AvailableForBuying_bool === true
      )
      .map(activity => activity.Id)
    this.isActivityFiltered = true
  }

  @action
  handleSubmit = async () => {
    return await this.mutations.setAvailableFlagForDeliveryWindow({
      variables: { availableActivities: this.selectedActivities },
      refetchQueries: [
        {
          query: GET_WS_ACTIVITIES
        }
      ]
    })
  }
}

export const activitySelectionSettings = new ActivitySelectionSettingsStore()
