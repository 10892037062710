export enum ShowroomMenuEnum {
  clientSelection = 'MARKET SELECTION',
  buyingSessions = 'BUYING SESSIONS',
  basketAndOrders = 'BASKET & ORDERS',
  buyingSessionActivities = 'BUYING SESSION DEPARTMENTS',
  collection = 'COLLECTION',
  basket = 'MY BASKET',
  order = 'MY ORDER',
  createOrder = 'CREATE ORDER',
  createNewOrder = 'CREATE NEW',
  macroOrder = 'MACRO ORDER',
  microOrder = 'MICRO ORDER',
  buyingHome = 'Buying Home',
  buyingBoard = 'Buying Board',
  presentation = 'PRESENTATION',
  startBuy = 'START BUY',
  clientNotFound = 'MARKET NOT FOUND',
  lookBook = 'Look Book',
  clientCollection = 'Market Collection',
  clientWishlist = 'Market Wishlist'
}

export enum TogglePopoverSwitch {
  filterPopover = 'filterPopover',
  KpiPopover = 'KpiPopover'
}

export enum KPIMetric {
  qty = 'Quantity',
  value = 'Value'
}

export enum SAKPISettingsToggleType {
  kpiMetric = 'kpiMetric',
  qtyType = 'qtyType'
}
