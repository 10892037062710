/*
 * @Author: Raeesaa Metkari
 * @Date: 2020-09-09 16:55:33
 * @Last Modified by: Raeesaa Metkari
 * @Last Modified time: 2020-09-11 21:23:15
 */

export enum ReportingTab {
  worldwide = 'worldwide',
  client = 'market',
  worldwideBuy = 'worldwideBuy',
  zoneBuy = 'zoneBuy',
  forecast = 'forecast',

  //TODO: C&K Global Reporting New Tabs
  wwTotalQty = 'wwTotalQty',
  whl = 'whl',
  rtl = 'rtl',
  market = 'market',
  //Second Level Tabs
  globalOrderQty = 'globalOrderQty',
  orderQtyWhl = 'orderQtyWhl',
  whlRevenue = 'whlRevenue',
  orderQtyRtl = 'orderQtyRtl',
  retailRevenue = 'retailRevenue',
  orderQty = 'orderQty'
}

export enum ReportingTabLabel {
  worldwide = 'Worldwide',
  client = 'Market',
  worldwideBuy = 'Worldwide Buy',
  zoneBuy = 'Zone Buy',
  forecast = 'Forecast',

  //TODO: C&K Global Reporting New Tabs
  wwTotalQty = 'WW TOTAL QTY',
  whl = 'WHOLESALE',
  rtl = 'RETAIL',
  market = 'Market',
  //Second Level Tabs
  globalOrderQty = 'Global Order Qty',
  orderQtyWhl = 'Order Qty Whl',
  whlRevenue = 'WHL Revenue',
  orderQtyRtl = 'Order Qty Rtl',
  retailRevenue = 'Retail Revenue',
  orderQty = 'Order Qty'
}
