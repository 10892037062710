import { CentricTheme } from '@components/3rd-party/mui'
import { withStyles } from '@material-ui/core/styles'
import TextField, { StandardTextFieldProps } from '@material-ui/core/TextField'
import classNames from 'classnames'
import React from 'react'

const styles = (theme: CentricTheme) => ({
  textfield: {
    height: 40
  }
})

export interface TextInputProps extends StandardTextFieldProps {
  classes?: any
  variant?: any
  inputProps?: any
  InputProps?: any
}

export class TextInputComponent extends React.Component<TextInputProps> {
  static defaultProps = {
    disabled: false,
    variant: 'outlined',
    margin: 'dense',
    type: 'text'
  }

  render() {
    const { classes, className, ...otherProps } = this.props

    return (
      <TextField
        ref={this.props.innerRef}
        className={classNames(classes.textfield, className)}
        {...otherProps}
      />
    )
  }
}

@((withStyles as any)(styles))
export class TextInput extends TextInputComponent {}
