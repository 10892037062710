import { RefetchQueriesFunction } from '@apollo/client'
import { config } from '@config'
import { KeyCode } from '@constants'
import { CriteriaOperationType } from '@models'
import {
  CentricNode,
  CentricPricelistValue,
  IStandardBuyingSession,
  ListViewFieldMapping,
  OrderListView,
  SAKPISettingsToggleType,
  SAListViewProduct,
  SAProductDetailAddRemoveProduct,
  ShowroomMenuEnum,
  SummaryBarData
} from '@modules/common'
import { CentricNodeType, OrderType } from '@modules/common/models/enums'
import {
  GET_VIP_CLIENT_ORDER,
  SA_CLIENT_ORDER_PRODUCT_SUMMARY
} from '@modules/showroom/basket/graphql'
import { DOOR_ORDERS_BOOKED_PRODUCTS_COUNT } from '@modules/showroom/collection/graphql/doorOrdersBookedProductsCount'
import { SA_CLIENT_ORDER_PRODUCTS } from '@modules/showroom/collection/graphql/getClientOrderProducts'
import { SA_CLIENT_ORDER_PRODUCT_SUMMARY_DETAILS } from '@modules/showroom/collection/graphql/getClientOrderProductSummaryDetails'
import { SA_CLIENT_ORDER_PRODUCT_WITH_DOOR_PRODUCTS } from '@modules/showroom/collection/graphql/getClientOrderProductWithDoorProducts'
import { SA_DOOR_ORDER_PRODUCT_BY_ORIGINAL } from '@modules/showroom/collection/graphql/getDoorOrderProductByOriginal'
import { SA_DOOR_ORDER_PRODUCTS } from '@modules/showroom/collection/graphql/getDoorOrderProducts'
import { SA_DOOR_ORDER_PRODUCT_SUMMARY_DETAILS } from '@modules/showroom/collection/graphql/getDoorOrderProductSummaryDetails'
import { REMOVE_PRODUCT_FROM_CLIENT_ORDER } from '@modules/showroom/collection/graphql/removeProductFromClientOrder'
import { REMOVE_PRODUCT_FROM_DOOR_ORDER } from '@modules/showroom/collection/graphql/removeProductFromDoorOrder'
import { SA_TOGGLE_BOOKED_FLAG_FOR_DOOR_ORDER_PRODUCT } from '@modules/showroom/collection/graphql/toggleBookedFlagForProduct'
import { UPDATE_BOOK_UNBOOK_ALL_FLAG_FOR_CLIENT_ORDER_PRODUCT } from '@modules/showroom/collection/graphql/updateBookUnBookAllFlag'
import { SA_UPDATE_DOOR_ORDER_PRODUCT_QTY } from '@modules/showroom/collection/graphql/updateGlobalDoorOrderProductQty'
import { ORDER_PRODUCT_DETAIL } from '@modules/showroom/productDetail/graphql'
import { BUYING_SESSION_GROUP_PRODUCT_SUMMARY } from '@modules/wholesale/buyingSessionGroupProductWrapper/graphql/buyingSessionProductSummary'
import { convertToCurrencyLocaleString } from '@services/assortmentService'
import {
  getProductsByCategories,
  isOrderAvailableForUpdates
} from '@services/commonServices'
import { orderListViewDefinitionService } from '@services/showroom/SACollectionListViewDefinitionService'
import { saKPIViewDefinitionService } from '@services/showroom/SAKPIViewDefinitionService'
import { storage } from '@services/storageService'
import {
  VIPDoorOrders,
  VIPOrderProduct,
  wholesaleCollectionService
} from '@services/wholesale/wholesaleCollectionService'
import { apolloClient, stores, strings } from '@stores'
import axios from 'axios'
import cloneDeep from 'lodash/cloneDeep'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import flatMap from 'lodash/flatMap'
import forEach from 'lodash/forEach'
import has from 'lodash/has'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import map from 'lodash/map'
import omit from 'lodash/omit'
import remove from 'lodash/remove'
import some from 'lodash/some'
import uniqBy from 'lodash/uniqBy'
import concat from 'lodash/concat'
import pick from 'lodash/pick'
import compact from 'lodash/compact'
import fill from 'lodash/fill'
import { action, computed, observable } from 'mobx'
import qs from 'query-string'

// import { storage } from '@services/storageService'

enum OrderActions {
  basketTogglingInProgress = 'basketTogglingInProgress',
  doorOrderProductBookingInProgress = 'doorOrderProductBookingInProgress',
  globalProductQtyUpdateInProgress = 'globalProductQtyUpdateInProgress',
  addDoorOrderProductInProgress = 'addDoorOrderProductInProgress',
  bookUnbookTogglingInProgress = 'bookUnbookTogglingInProgress'
}
type CategoryHierarchy = {
  activity: string
  childrenMeta: Array<string>
  childrenMetaValues: Array<string>
}

export class ShowRoomCollectionStore {
  productViewType: string
  isClientUnavailable = false
  canEditOrder = false

  @observable summaryBarData: SummaryBarData = {
    useValueRollup: true,
    useSizedQtyRollup: true,
    filterPopover: null
  }

  @observable doorOrders: Array<VIPDoorOrders> = []
  @observable totalBasketProducts: number
  @observable totalProductsAsPerSearchAndFilter: number
  @observable isOrderCreationEnabled: boolean = false
  @observable isDraftOrder = false
  @observable orderStatus = undefined
  @observable isSAView = true
  @observable autoRollUps = false
  @observable productActionMap: any = {}
  @observable buyingSessionList: Array<{ Id: string; Status: string }> = []
  @observable isBackdropOnLoading: boolean = false
  @observable openConfirmationDialog = false
  @observable isInProgress = false
  @observable isDetailsLoading = false
  @observable selectedProduct = null
  @observable doorOrderProductId = null
  @observable ordersWithBookedProducts = []

  // Row selector
  @observable selectedProductForSizedQty = null
  @observable openSizeQtyModal = false
  @observable SAConfirmationMessage = strings.unbookedConfirmationMessage
  hierarchyKPIs = null

  @observable openConfirmationToRemoveProduct = false
  @observable productIdToRemoveProduct = null
  @observable clientOrderIdToRemoveProduct = null
  @observable clientOrderProductIdToRemoveProduct = null
  @observable refetchQueriesToRemoveProduct = []
  @observable isAddProductDrawerOpen = false
  @observable selectedHeaderFilter =
    strings.showroomHeaders.showroomSubHeaderFilterValues.allProducts
  @observable isHeaderFilterOpen = null
  @observable productDetails = null

  // For sync order
  @observable isSyncInProgress: boolean = false
  @observable refetchQueries: Array<RefetchQueriesFunction> = []

  @action onProductDetailClick = productDetails => e => {
    const {
      nav: { updateQueryParams }
    } = stores
    this.setDetailsLoading(true)
    if (!e.shiftKey) {
      const buyingSessionProduct =
        productDetails.node && productDetails.node.BuyingSessionProduct
          ? {
              ...productDetails.node.BuyingSessionProduct,
              Original: productDetails.node?.Original ?? {}
            }
          : productDetails.node
      // don't init half data
      // Object.assign(this, {
      //   productDetails: {
      //     buyingSessionProduct,
      //     isRecommendedProduct: productDetails.isRecommendedProduct,
      //     orderProductId: productDetails.orderProductId,
      //     productAttributes: productDetails.productAttributes
      //   }
      // })
      updateQueryParams({ bspId: buyingSessionProduct.Id }, 'push')
    }
  }

  @action syncOrderData = async () => {
    this.isSyncInProgress = true

    try {
      await Promise.all(map(this.refetchQueries, refetch => refetch()))
      // Delete row level queries from cache
      apolloClient.cache.evict({
        fieldName: 'findVIPOrderProduct'
      })
      // Call garbage collector so that unreachable cache entries are deleted
      apolloClient.cache.gc()
    } finally {
      this.isSyncInProgress = false
    }
  }

  invalidateCacheForAllOrders = () => {
    // Delete row level queries from cache
    apolloClient.cache.evict({
      id: 'ROOT_QUERY',
      fieldName: 'getVIPDoorOrder',
      broadcast: false
    })
    apolloClient.cache.evict({
      id: 'ROOT_QUERY',
      fieldName: 'getVIPClientOrder',
      broadcast: false
    })
    apolloClient.cache.evict({
      id: 'ROOT_QUERY',
      fieldName: 'findVIPOrderProduct',
      broadcast: false
    })

    apolloClient.cache.gc()
  }

  @action setRefetchQueries = refetchQueries => {
    this.refetchQueries = refetchQueries
  }

  /**
   * Initializes required data to store
   */
  @action initStore = (
    buyingSessions,
    doorOrders,
    isClientUnavailable,
    productViewType,
    canEditOrder: boolean,
    orderData?,
    clientName?: string,
    autoRollUps?: boolean,
    view?: OrderListView
  ) => {
    this.productViewType = productViewType
    this.doorOrders = doorOrders || this.doorOrders
    this.totalBasketProducts = this.getBasketProductCount(doorOrders)
    this.buyingSessionList = buyingSessions
    this.isClientUnavailable = isClientUnavailable
    this.isDraftOrder = orderData?.isDraft ?? false
    this.orderStatus = orderData?.status ?? null
    this.autoRollUps = autoRollUps
    this.isSAView =
      OrderListView.SAOrder === view || productViewType === ShowroomMenuEnum.collection
    this.canEditOrder = canEditOrder

    this.summaryBarData.isLoading = false
    this.summaryBarData.orderNumber = orderData?.OrderNumber
    this.summaryBarData.clientName = clientName
    this.summaryBarData.useSizedQtyRollup = this.isSizedQtyRollup
  }

  @computed get columnDefinitionForKPIView() {
    const {
      summaryBarData: { useSizedQtyRollup }
    } = this
    return saKPIViewDefinitionService.buildColumnDefinition(useSizedQtyRollup)
  }

  @computed get columnDefinitionForListView() {
    const {
      doorOrders,
      summaryBarData: { useValueRollup, useSizedQtyRollup }
    } = this
    return orderListViewDefinitionService.buildColumnDefinition(
      doorOrders || [],
      useValueRollup,
      useSizedQtyRollup
    )
  }

  /**
   * Returns basket products count
   * from the list of door orders
   * @param {Array<VIPDoorOrders>} doorOrders
   */
  getBasketProductCount = (doorOrders: Array<VIPDoorOrders>) => {
    const doorOrderProducts = compact(flatMap(doorOrders, order => order?.OrderLineItems))
    return (
      uniqBy(doorOrderProducts, (product: VIPOrderProduct) => product?.Original?.Id)
        ?.length ?? 0
    )
  }

  @action setDetailsLoading = isDetailsLoading =>
    (this.isDetailsLoading = isDetailsLoading)

  @action setBackDropOnLoading = isLoading => (this.isBackdropOnLoading = isLoading)

  @action setOrderSummaryLoading = loading => {
    this.summaryBarData.isLoading = loading
    this.summaryBarData = Object.assign({}, this.summaryBarData)
  }

  /**
   * Set total bar data:
   * 1. Total count based on search and filter
   * 2. Flag to enable / disable "Create Order" option
   */
  @action setTopBarData = (
    total: number = 0,
    areProductsBooked: boolean = false,
    ordersWithBookedProducts
  ) => {
    if (this.totalProductsAsPerSearchAndFilter !== total) {
      this.totalProductsAsPerSearchAndFilter = total
    }
    this.isOrderCreationEnabled = areProductsBooked
    this.ordersWithBookedProducts = ordersWithBookedProducts
  }

  @action onAddOrderProductClick = () => {
    this.isAddProductDrawerOpen = true
  }

  @action closeDrawer = () => {
    this.isAddProductDrawerOpen = false
  }

  @action cleanup = () => {
    if (!this.productActionMap) {
      this.productActionMap = {}
    }
    this.isDraftOrder = false
    this.orderStatus = undefined
    this.autoRollUps = false
    this.isSAView = false
    this.summaryBarData.orderNumber = null
    this.doorOrders = []
  }

  @computed get isDoorOrder() {
    const {
      nav: {
        queryParams: { orderType }
      }
    } = stores
    return orderType === OrderType.Door
  }

  /**
   * Returns whether kpi rollup depends
   * on sized qty or not. For CM/ZAM the decision
   * is based on order status
   */
  @computed get isSizedQtyRollup() {
    if (this.autoRollUps) {
      const orderStatusesForSizedQty =
        config?.appConfig?.enumerations?.orderStatus?.VALUES_FOR_CONFIRMED_ORDER ?? []
      return includes(orderStatusesForSizedQty, this.orderStatus)
    } else {
      return this.summaryBarData.useSizedQtyRollup
    }
  }

  @computed get whereForClientAssortmentProducts() {
    const {
      nav: {
        queryParams: { clientId, buyingSessionGroupId }
      }
    } = stores
    if (isEmpty(this.buyingSessionList)) return null

    const bsWhere =
      this.buyingSessionList.length === 1
        ? {
            __Parent__: {
              Id: {
                operation: CriteriaOperationType.EQ,
                value: this.buyingSessionList[0].Id
              }
            }
          }
        : {
            __Parent__: {
              __Parent__: {
                Id: {
                  operation: CriteriaOperationType.EQ,
                  value: buyingSessionGroupId
                }
              }
            }
          }
    return {
      GIV_VIPAssortmentProduct_Assortment_ref: {
        VIPClientAssortment: {
          Client: {
            Id: {
              operation: CriteriaOperationType.EQ,
              value: clientId
            }
          },
          ...bsWhere
        }
      }
    }
  }

  setOrderNumber = (orderNumber = null) => {
    this.summaryBarData.orderNumber = orderNumber
  }

  /**
   * Used to set anchor ref to anchor map
   * @param event
   */
  @action onSummaryMenuClick = (event, popoverKey) => {
    this.summaryBarData[popoverKey] = event.currentTarget
    this.summaryBarData = Object.assign({}, this.summaryBarData)
  }

  @action resetSelectedHeaderFilter = () => {
    if (
      this.selectedHeaderFilter !==
      strings.showroomHeaders.showroomSubHeaderFilterValues.allProducts
    ) {
      this.selectedHeaderFilter =
        strings.showroomHeaders.showroomSubHeaderFilterValues.allProducts
    }
  }

  invalidateOrderPageCache = () => {
    const {
      nav: {
        queryParams: { orderId }
      }
    } = stores
    /**
     * Removing only main wrapper query whose data is used when client is master / basket page
     * Cache for all other queries remain up to date
     */
    apolloClient.cache.evict({
      fieldName: 'findVIPOrderProduct',
      args: {
        where: {
          __Parent__: {
            [CentricNodeType.VIPClientOrder]: {
              Id: { operation: CriteriaOperationType.EQ, value: orderId }
            }
          }
        }
      }
    })

    // Call garbage collector so that unreachable cache entries are deleted
    apolloClient.cache.gc()
  }

  /**
   * Used to remove anchor ref from anchor map
   */
  @action onSummaryMenuClose = (event, popoverKey) => {
    this.summaryBarData[popoverKey] = null
    this.summaryBarData = Object.assign({}, this.summaryBarData)
  }

  @action onSummaryFilterMenuChange = (popoverKey, selectedHeaderFilter) => () => {
    this.summaryBarData[popoverKey] = null
    this.selectedHeaderFilter = selectedHeaderFilter
  }

  @action onSummaryToggleMenuChange =
    popoverKey => (event, toggleType: SAKPISettingsToggleType) => {
      this.summaryBarData[popoverKey] = null
      if (toggleType === SAKPISettingsToggleType.kpiMetric) {
        this.summaryBarData.useValueRollup = event.target.checked
      } else if (toggleType === SAKPISettingsToggleType.qtyType) {
        this.summaryBarData.useSizedQtyRollup = event.target.checked
      }

      this.summaryBarData = Object.assign({}, this.summaryBarData)
    }

  @action onCMOrderKPIToggleChange = event => {
    this.summaryBarData.useValueRollup = event.target.checked
    this.summaryBarData = Object.assign({}, this.summaryBarData)
  }

  /**
   * Open size qty modal
   */
  @action
  onSizeQtyModalClick = (productRowData: SAListViewProduct) => e => {
    this.selectedProductForSizedQty = productRowData
    this.openSizeQtyModal = true
  }

  @action updatedSelectedProduct = updatedProduct => {
    this.selectedProductForSizedQty = updatedProduct
  }

  @action
  onSizeQtyModalClose = () => {
    this.selectedProductForSizedQty = null
    this.openSizeQtyModal = false
  }
  @action setProductActionTracker = (
    productId: string,
    action: OrderActions,
    status: boolean,
    orderId: string = null
  ) => {
    if (orderId) {
      this.productActionMap[`${productId}-${orderId}`] =
        this.productActionMap[`${productId}-${orderId}`] || {}
      this.productActionMap[`${productId}-${orderId}`][action] = status
    } else {
      this.productActionMap[productId] = this.productActionMap[productId] || {}
      this.productActionMap[productId][action] = status
    }
    this.productActionMap = Object.assign({}, this.productActionMap)
  }

  @action setHierarchyKPIs = hierarchyKPIs => {
    if (!isEqual(this.hierarchyKPIs, hierarchyKPIs)) {
      this.hierarchyKPIs = hierarchyKPIs
    }
  }

  /**
   * Returns where for validating client's availability within buying
   * session group
   */
  @computed get buyingSessionsGroupForClientWhere() {
    const {
      nav: {
        queryParams: { clientId, buyingSessionGroupId }
      }
    } = stores

    if (isEmpty(buyingSessionGroupId) || isEmpty(clientId)) return null

    return {
      Id: { operation: CriteriaOperationType.EQ, value: buyingSessionGroupId },
      GIV_VIPBuyingSessionGroup_ExcludedClients_reflist: {
        Id: { operation: CriteriaOperationType.EQ, value: clientId }
      }
    }
  }

  /**
   * Returns where for buying session products
   */
  @computed get buyingSessionsProductWhere() {
    if (isEmpty(this.buyingSessionList)) return null

    return {
      OR: this.buyingSessionList.map(item => ({
        __Parent__: {
          Id: { operation: CriteriaOperationType.EQ, value: item.Id }
        }
      }))
    }
  }

  /**
   * Returns where fragment for door order
   * product
   */
  @computed get doorOrderProductWhereFragment() {
    const {
      nav: {
        queryParams: { orderId }
      }
    } = stores
    if (isEmpty(orderId)) return null
    if (this.isDoorOrder) {
      return {
        __Parent__: {
          VIPDoorOrder: {
            Id: { operation: CriteriaOperationType.EQ, value: orderId }
          }
        }
      }
    } else {
      return {
        __Parent__: {
          VIPDoorOrder: {
            GIV_VIPDoorOrder_ClientOrder_ref: {
              Id: { operation: CriteriaOperationType.EQ, value: orderId }
            }
          }
        }
      }
    }
  }

  /**
   * Returns where fragment for client order
   * product
   */
  @computed get clientOrderProductWhereFragment() {
    const {
      nav: {
        queryParams: { orderId }
      }
    } = stores
    if (isEmpty(orderId)) return null
    return {
      __Parent__: {
        [CentricNodeType.VIPClientOrder]: {
          Id: { operation: CriteriaOperationType.EQ, value: orderId }
        }
      }
    }
  }

  /**
   * Method for building where input for buying session products query for buying session group
   * @param {string} activity - Name of activity
   * @param {Array<string>} childrenMeta - ChildrenMeta for given activity
   * @param {Array<string>} childrenMetaValues - Values for hierarchy chilren
   * @returns Where input object for buying session products query for given group
   */
  BSPWhere = (
    activity: string,
    childrenMeta: Array<string>,
    childrenMetaValues: Array<string>
  ) => {
    if (isEmpty(this.buyingSessionsProductWhere)) return null
    let baseInputForQuery: any = cloneDeep(this.buyingSessionsProductWhere)
    baseInputForQuery.Original = wholesaleCollectionService.buildAttributeHierarchyQuery(
      activity,
      childrenMeta,
      childrenMetaValues
    )

    return baseInputForQuery
  }

  /**
   * Method for building where input for order products query for current client order
   * @param {string} activity - Name of activity
   * @param {Array<string>} childrenMeta - ChildrenMeta for given activity
   * @param {Array<string>} childrenMetaValues - Values for hierarchy children
   * @param {boolean} isClientOrderProducts - To decide whether to return client or door products
   * @returns Where input object for buying session products query for given group
   */
  orderProductsWhere = (
    activity: string,
    childrenMeta: Array<string>,
    childrenMetaValues: Array<string>,
    isClientOrderProducts: boolean = false
  ) => {
    const doorOrderProductWhere = this.doorOrderProductWhereFragment
    const clientOrderProductWhere = this.clientOrderProductWhereFragment
    if (isEmpty(doorOrderProductWhere) || isEmpty(clientOrderProductWhere)) return null

    let baseInputForQuery: any = {
      AND: [
        cloneDeep(isClientOrderProducts ? clientOrderProductWhere : doorOrderProductWhere)
      ]
    }
    baseInputForQuery.AND.push({
      Original: wholesaleCollectionService.buildAttributeHierarchyQuery(
        activity,
        childrenMeta,
        childrenMetaValues
      )
    })

    return baseInputForQuery
  }

  /**
   * Method for building where input for assortment products query for current
   * client
   * @param {string} activity - Name of activity
   * @param {Array<string>} childrenMeta - ChildrenMeta for given activity
   * @param {Array<string>} childrenMetaValues - Values for hierarchy chilren
   * @returns Where input object for buying session products query for given group
   */
  clientAssortmentProductsWhere = (
    activity: string,
    childrenMeta: Array<string>,
    childrenMetaValues: Array<string>
  ) => {
    const {
      nav: {
        queryParams: { clientId }
      }
    } = stores
    if (isEmpty(this.buyingSessionList) || isEmpty(clientId)) return null

    return {
      GIV_VIPAssortmentProduct_Assortment_ref: {
        VIPClientAssortment: {
          Client: { Id: { operation: CriteriaOperationType.EQ, value: clientId } }
        }
      },
      Original: {
        Original: wholesaleCollectionService.buildAttributeHierarchyQuery(
          activity,
          childrenMeta,
          childrenMetaValues
        )
      },
      OR: this.buyingSessionList.map(item => ({
        Original: {
          __Parent__: {
            Id: { operation: CriteriaOperationType.EQ, value: item.Id }
          }
        }
      }))
    }
  }

  /**
   * Builds refetch query for base type of products
   * used for SA list view depending upon view i.e
   * collection, basket or order
   */
  buildProductSummaryReFetchQuery = () => {
    return this.productViewType === ShowroomMenuEnum.collection
      ? {
          query: BUYING_SESSION_GROUP_PRODUCT_SUMMARY,
          variables: {
            where: this.buyingSessionsProductWhere
          },
          context: {
            queryDeduplication: false
          }
        }
      : {
          query: SA_CLIENT_ORDER_PRODUCT_SUMMARY,
          variables: {
            where: this.clientOrderProductWhereFragment
          },
          context: {
            queryDeduplication: false
          }
        }
  }

  /**
   * To build refetch query which mostly used while
   * toggling the product for basket
   * @param activity
   * @param childrenMeta
   * @param childrenMetaValues
   */
  getProductsRefetchByCategories = (activity, childrenMeta, childrenMetaValues) => {
    return [
      {
        query: SA_CLIENT_ORDER_PRODUCTS,
        variables: {
          where: this.orderProductsWhere(
            activity,
            childrenMeta,
            childrenMetaValues,
            true
          ),
          withVIPProductDetails: ShowroomMenuEnum.basket === this.productViewType
        },
        context: {
          queryDeduplication: false
        }
      }
    ]
  }

  /**
   * To build refetch query which mostly used when
   * adding, booking door products
   * @param activity
   * @param childrenMeta
   * @param childrenMetaValues
   */
  buildRefetchQueryForDoorOrderProductByCategory = (
    activity,
    childrenMeta,
    childrenMetaValues
  ) => {
    const {
      showroomCollection: { orderProductsWhere }
    } = stores

    const doorOrderProductsWhere = orderProductsWhere(
      activity,
      childrenMeta,
      childrenMetaValues
    )
    return {
      query: SA_DOOR_ORDER_PRODUCTS,
      variables: {
        where: doorOrderProductsWhere
      },
      context: {
        queryDeduplication: false
      }
    }
  }

  /**
   * To build refetch query which mostly used while
   * updating the product qty
   * @param activity
   * @param childrenMeta
   * @param childrenMetaValues
   */
  buildRefetchQueriesForOrderQtyUpdates = (
    activity,
    childrenMeta,
    childrenMetaValues
  ) => {
    const {
      nav: {
        queryParams: { orderId }
      }
    } = stores
    return [
      {
        query: this.isDoorOrder
          ? SA_DOOR_ORDER_PRODUCT_SUMMARY_DETAILS
          : SA_CLIENT_ORDER_PRODUCT_SUMMARY_DETAILS,
        variables: {
          orderId
        },
        context: {
          queryDeduplication: false
        }
      },
      {
        query: SA_CLIENT_ORDER_PRODUCTS,
        variables: {
          where: this.orderProductsWhere(
            activity,
            childrenMeta,
            childrenMetaValues,
            true
          ),
          withVIPProductDetails: ShowroomMenuEnum.basket === this.productViewType
        },
        context: {
          queryDeduplication: false
        }
      },
      this.buildProductSummaryReFetchQuery()
    ]
  }

  updateDoorOrderProductsQueryOnRemovingProductFromOrder = (
    orderProductId: string,
    hierarchy: CategoryHierarchy,
    isDoorOrderProduct: boolean = false
  ) => {
    const {
      showroomCollection: { orderProductsWhere }
    } = stores

    try {
      let doorOrderProductsData = apolloClient.readQuery({
        query: SA_DOOR_ORDER_PRODUCTS,
        variables: {
          where: orderProductsWhere(
            hierarchy.activity,
            hierarchy.childrenMeta,
            hierarchy.childrenMetaValues
          )
        }
      })

      let doorOrderProductDataClone = cloneDeep(doorOrderProductsData)

      // Delete door order products corresponding to client order product
      if (
        doorOrderProductDataClone.doorOrderProducts &&
        doorOrderProductDataClone.doorOrderProducts.Items
      ) {
        remove(
          doorOrderProductDataClone.doorOrderProducts.Items,
          (item: { Id: string; clientOrderProduct: CentricNode }) =>
            isDoorOrderProduct
              ? item.Id === orderProductId
              : item?.clientOrderProduct?.Id === orderProductId
        )

        apolloClient.writeQuery({
          query: SA_DOOR_ORDER_PRODUCTS,
          variables: {
            where: orderProductsWhere(
              hierarchy.activity,
              hierarchy.childrenMeta,
              hierarchy.childrenMetaValues
            )
          },
          data: doorOrderProductDataClone
        })
      }
    } catch (err) {}
  }

  updateClientOrderSummaryQueryCacheOnRemovingProductFromClientOrder = (
    orderProductId: string,
    orderId: string
  ) => {
    const isBasketPage = window.location.pathname.includes('/order')
    if (isBasketPage) {
      try {
        // update client order products
        let clientOrderProductSummaryData = apolloClient.readQuery({
          query: SA_CLIENT_ORDER_PRODUCT_SUMMARY,
          variables: {
            where: {
              __Parent__: {
                [CentricNodeType.VIPClientOrder]: {
                  Id: { operation: CriteriaOperationType.EQ, value: orderId }
                }
              }
            }
          }
        })

        const clientOrderProductsSummary = cloneDeep(clientOrderProductSummaryData)

        if (
          clientOrderProductsSummary?.clientOrderProducts?.Items &&
          clientOrderProductsSummary.clientOrderProducts.Items.length
        ) {
          remove(
            clientOrderProductsSummary.clientOrderProducts.Items,
            (item: { Id: string }) => item.Id === orderProductId
          )
        }

        apolloClient.writeQuery({
          query: SA_CLIENT_ORDER_PRODUCT_SUMMARY,
          variables: {
            where: {
              __Parent__: {
                [CentricNodeType.VIPClientOrder]: {
                  Id: { operation: CriteriaOperationType.EQ, value: orderId }
                }
              }
            }
          },
          data: clientOrderProductsSummary
        })
      } catch (err) {}
    }
    try {
      // Update order summary
      let clientOrderSummaryData = apolloClient.readQuery({
        query: SA_CLIENT_ORDER_PRODUCT_SUMMARY_DETAILS,
        variables: {
          orderId
        }
      })

      let clientOrderSummary = cloneDeep(clientOrderSummaryData)
      if (
        clientOrderSummary?.orderProductSummary &&
        clientOrderSummary.orderProductSummary.DoorOrders
      ) {
        forEach(clientOrderSummary.orderProductSummary.DoorOrders, doorOrder => {
          remove(
            doorOrder?.OrderLineItems || [],
            (doorProduct: { clientOrderProduct: CentricNode }) =>
              doorProduct?.clientOrderProduct?.Id === orderProductId
          )
        })
      }

      apolloClient.writeQuery({
        query: SA_CLIENT_ORDER_PRODUCT_SUMMARY_DETAILS,
        variables: {
          orderId
        },
        data: clientOrderSummary
      })
    } catch (err) {}
  }

  updateDoorOrderSummaryQueryCacheOnRemovingProductFromDoorOrder = (
    orderProductId: string,
    orderId: string
  ) => {
    try {
      let doorOrderSummaryData = apolloClient.readQuery({
        query: SA_DOOR_ORDER_PRODUCT_SUMMARY_DETAILS,
        variables: {
          orderId
        }
      })
      let doorOrderSummary = cloneDeep(doorOrderSummaryData)

      if (doorOrderSummary?.orderProductSummary?.OrderLineItems?.length) {
        remove(
          doorOrderSummary?.orderProductSummary.OrderLineItems,
          (item: { Id: string }) => item.Id === orderProductId
        )

        apolloClient.writeQuery({
          query: SA_DOOR_ORDER_PRODUCT_SUMMARY_DETAILS,
          variables: {
            orderId
          },
          data: doorOrderSummary
        })
      }
    } catch (error) {
      /**
       * Apollo client throws exception if query is not present in cache.
       * We might have query missing for products section in case product for new category is added. Silently ignoring it in that
       * case
       */
    }
  }

  updateCacheOnRemovingProductFromDoorOrder = (
    node,
    orderProductId: string,
    orderId: string
  ) => {
    const { activity, childrenMeta, childrenMetaValues } = getProductsByCategories(node)
    this.updateDoorOrderProductsQueryOnRemovingProductFromOrder(
      orderProductId,
      {
        activity,
        childrenMeta,
        childrenMetaValues
      },
      true
    )

    this.updateDoorOrderSummaryQueryCacheOnRemovingProductFromDoorOrder(
      orderProductId,
      orderId
    )
  }

  updateCacheOnRemovingProductFromClientOrder = (
    node,
    orderProductId: string,
    orderId: string
  ) => {
    const { activity, childrenMeta, childrenMetaValues } = getProductsByCategories(node)
    const withVIPProductDetails = ShowroomMenuEnum.basket === this.productViewType

    try {
      // Read data to be updated from cache
      let clientOrderProductsData = apolloClient.readQuery({
        query: SA_CLIENT_ORDER_PRODUCTS,
        variables: {
          where: this.orderProductsWhere(
            activity,
            childrenMeta,
            childrenMetaValues,
            true
          ),
          withVIPProductDetails
        }
      })

      const clientOrderItems = cloneDeep(clientOrderProductsData)

      if (clientOrderItems?.clientOrderProducts?.Items?.length) {
        remove(
          clientOrderItems?.clientOrderProducts?.Items,
          (item: { Id: string }) => item.Id === orderProductId
        )
      }

      apolloClient.writeQuery({
        query: SA_CLIENT_ORDER_PRODUCTS,
        variables: {
          where: this.orderProductsWhere(
            activity,
            childrenMeta,
            childrenMetaValues,
            true
          ),
          withVIPProductDetails
        },
        data: clientOrderItems
      })
    } catch (err) {}

    this.updateDoorOrderProductsQueryOnRemovingProductFromOrder(orderProductId, {
      activity,
      childrenMeta,
      childrenMetaValues
    })
    this.updateClientOrderSummaryQueryCacheOnRemovingProductFromClientOrder(
      orderProductId,
      orderId
    )
  }

  @action removeProductFromBasket = async () => {
    const {
      nav: {
        queryParams: { bspId, orderId }
      },
      showroomProductDetailStore: { processedProduct, setProcessedProduct }
    } = stores
    this.isInProgress = true
    const { id: productId, orderProductId, node } = this.selectedProduct
    try {
      this.setProductActionTracker(productId, OrderActions.basketTogglingInProgress, true)

      let refetchQueries = []
      if (bspId) {
        refetchQueries.push(this.getOrderProductForDetail())
      }
      const { data } = await apolloClient.mutate({
        mutation: this.isDoorOrder
          ? REMOVE_PRODUCT_FROM_DOOR_ORDER
          : REMOVE_PRODUCT_FROM_CLIENT_ORDER,
        variables: {
          orderProductId: orderProductId
        },
        awaitRefetchQueries: true,
        refetchQueries
      })

      if (
        data?.VIPBoard_RemoveProductFromDoorOrder?.status ===
          strings.mutationResultSuccess ||
        data?.VIPBoard_RemoveProductFromClientOrder?.status ===
          strings.mutationResultSuccess
      ) {
        // Remove done
        this.isDoorOrder
          ? await this.updateCacheOnRemovingProductFromDoorOrder(
              node,
              orderProductId,
              orderId
            )
          : await this.updateCacheOnRemovingProductFromClientOrder(
              node,
              orderProductId,
              orderId
            )
      }
    } finally {
      this.openConfirmationToRemoveProduct = false
      this.setProductActionTracker(
        productId,
        OrderActions.basketTogglingInProgress,
        false
      )
      this.isInProgress = false
      if (bspId && this.productDetails) {
        // Remove orderProductId from productDetails
        this.productDetails.orderProductId = null
      }
      if (bspId && processedProduct) {
        const _processedProduct = cloneDeep(processedProduct)
        _processedProduct.orderProduct = null
        setProcessedProduct(_processedProduct)
      }
    }
  }

  getRefetchQueries = node => {
    const { activity, childrenMeta, childrenMetaValues } = getProductsByCategories(node)
    const {
      nav: {
        queryParams: { orderId }
      }
    } = stores
    return concat(
      !this.isDoorOrder
        ? this.getProductsRefetchByCategories(activity, childrenMeta, childrenMetaValues)
        : [],
      [
        this.buildRefetchQueryForDoorOrderProductByCategory(
          activity,
          childrenMeta,
          childrenMetaValues
        ) as any
      ],
      !this.isDoorOrder ? this.buildProductSummaryReFetchQuery() : [],
      {
        query: DOOR_ORDERS_BOOKED_PRODUCTS_COUNT,
        variables: {
          clientOrderId: orderId
        },
        context: {
          queryDeduplication: false
        }
      },
      {
        query: GET_VIP_CLIENT_ORDER,
        variables: {
          clientOrderId: orderId
        },
        context: {
          queryDeduplication: false
        }
      }
    )
  }

  getOrderProductForDetail = () => {
    const {
      showroomProductDetailStore: { whereInputForOrderProduct }
    } = stores
    const whereQueryOrderProduct = whereInputForOrderProduct()
    return {
      query: ORDER_PRODUCT_DETAIL,
      fetchPolicy: 'network-only',
      variables: {
        where: whereQueryOrderProduct
      }
    }
  }

  @action toggleBasketProduct =
    (
      product: SAListViewProduct | SAProductDetailAddRemoveProduct,
      isBasketProduct: boolean,
      isDisabled = false
    ) =>
    async e => {
      if (e.stopPropagation) e.stopPropagation()
      if (!isDisabled) {
        const {
          nav: {
            queryParams: { orderId, bspId }
          }
        } = stores
        const {
          id: productId,
          orderProductId,
          isSessionClosed,
          isActive,
          node,
          isWholeSaleChannelPresent
        } = product
        if (
          productId &&
          !this.isClientUnavailable &&
          !this.productActionMap[productId]?.basketTogglingInProgress &&
          !isSessionClosed
        ) {
          if (isBasketProduct && orderProductId) {
            this.openConfirmationToRemoveProduct = true
            this.SAConfirmationMessage = strings.removeProductFromBasket
            this.selectedProduct = product
          } else if (isActive && isWholeSaleChannelPresent) {
            await this.addProductToBasket(node, productId, orderId, bspId)
          }
        }
      }
    }

  @action setOrderProductIdInProductDetails = async () => {
    const {
      nav: {
        queryParams: { bspId }
      },
      showroomProductDetailStore: {
        whereInputForOrderProduct,
        setProcessedProduct,
        processedProduct
      }
    } = stores
    let { data: orderProductData }: any = await apolloClient.query({
      query: ORDER_PRODUCT_DETAIL,
      fetchPolicy: 'network-only',
      variables: {
        where: whereInputForOrderProduct()
      }
    })
    const orderProduct = orderProductData?.findVIPOrderProduct?.Items?.[0]
    if (
      orderProduct &&
      this.productDetails &&
      this.productDetails?.buyingSessionProduct?.Id === bspId
    ) {
      this.productDetails.orderProductId = orderProduct.Id
    }
    // update new detail store
    if (orderProduct && processedProduct) {
      const _processedProduct = cloneDeep(processedProduct)
      _processedProduct.orderProduct = orderProduct
      setProcessedProduct(_processedProduct)
    }
    //
  }

  updateClientOrderSummaryQueryCacheOnAddingProductToClientOrder = (
    clientOrderProduct,
    orderId: string
  ) => {
    const isShowroomFlow = window.location.pathname.includes('/showroom')
    if (!isShowroomFlow) {
      try {
        // update client order products
        let clientOrderProductSummaryData = apolloClient.readQuery({
          query: SA_CLIENT_ORDER_PRODUCT_SUMMARY,
          variables: {
            where: {
              __Parent__: {
                [CentricNodeType.VIPClientOrder]: {
                  Id: { operation: CriteriaOperationType.EQ, value: orderId }
                }
              }
            }
          }
        })

        const clientOrderProductsSummary = cloneDeep(clientOrderProductSummaryData)
        if (clientOrderProductsSummary?.clientOrderProducts) {
          const productToBeAdded = omit(clientOrderProduct, [
            'doorProducts',
            'CreatedAt',
            'isBooked',
            'rtlPrice',
            'whlPrice'
          ])

          if (clientOrderProductsSummary.clientOrderProducts.Items) {
            clientOrderProductsSummary.clientOrderProducts.Items.push(productToBeAdded)
          } else {
            clientOrderProductsSummary.clientOrderProducts.Items = [productToBeAdded]
          }

          apolloClient.writeQuery({
            query: SA_CLIENT_ORDER_PRODUCT_SUMMARY,
            variables: {
              where: {
                __Parent__: {
                  [CentricNodeType.VIPClientOrder]: {
                    Id: { operation: CriteriaOperationType.EQ, value: orderId }
                  }
                }
              }
            },
            data: clientOrderProductsSummary
          })
        }
      } catch (err) {}
    }

    try {
      // Update order summary
      let clientOrderSummaryData = apolloClient.readQuery({
        query: SA_CLIENT_ORDER_PRODUCT_SUMMARY_DETAILS,
        variables: {
          orderId
        }
      })

      let clientOrderSummary = cloneDeep(clientOrderSummaryData)
      if (
        clientOrderSummary?.orderProductSummary &&
        clientOrderSummary.orderProductSummary.DoorOrders
      ) {
        forEach(clientOrderSummary.orderProductSummary.DoorOrders, doorOrder => {
          let doorOrderProduct = find(
            clientOrderProduct.doorProducts,
            doorProduct => doorProduct?.DoorOrder.Id === doorOrder.Id
          )

          const doorProduct = {
            ...omit(doorOrderProduct, ['rtlPrice', 'BuyingSessionProduct', 'DoorOrder']),
            Original: {
              Id: doorOrderProduct?.Original?.Id,
              Active: doorOrderProduct?.Original?.Active,
              Activity: doorOrderProduct?.Original?.Activity,
              Family: doorOrderProduct?.Original?.Family,
              Line: doorOrderProduct?.Original?.Line,
              __typename: doorOrderProduct?.Original?.__typename
            },
            clientOrderProduct: pick(clientOrderProduct, [
              'Id',
              'isRecommended',
              'whlPrice',
              '__typename'
            ]),
            __typename: CentricNodeType.VIPOrderProduct
          }

          if (doorProduct) {
            if (doorOrder.OrderLineItems) {
              doorOrder.OrderLineItems.push(doorProduct)
            } else {
              doorOrder.OrderLineItems = [doorProduct]
            }
          }
        })
      }

      apolloClient.writeQuery({
        query: SA_CLIENT_ORDER_PRODUCT_SUMMARY_DETAILS,
        variables: {
          orderId
        },
        data: clientOrderSummary
      })
    } catch (err) {}
  }

  updateDoorOrderProductsQueryOnAddingProductToOrder = (
    newDoorOrderProducts,
    hierarchy: CategoryHierarchy,
    clientOrderProduct?: {
      Id: string
      isRecommended: boolean
      rtlPrice: CentricPricelistValue
      whlPrice: CentricPricelistValue
      __typename: string
    }
  ) => {
    const {
      showroomCollection: { orderProductsWhere }
    } = stores

    try {
      let doorOrderProductsData = apolloClient.readQuery({
        query: SA_DOOR_ORDER_PRODUCTS,
        variables: {
          where: orderProductsWhere(
            hierarchy.activity,
            hierarchy.childrenMeta,
            hierarchy.childrenMetaValues
          )
        }
      })

      let doorOrderProductDataClone = cloneDeep(doorOrderProductsData)
      let doorOrderProductsToBeConcatenated = map(newDoorOrderProducts, orderProduct => {
        let doorOrderProduct = { ...orderProduct }
        doorOrderProduct.__typename = CentricNodeType.VIPOrderProduct
        if (clientOrderProduct) {
          doorOrderProduct.clientOrderProduct = clientOrderProduct
        }

        return doorOrderProduct
      })

      // Concatenate door order products
      if (doorOrderProductDataClone.doorOrderProducts) {
        doorOrderProductDataClone.doorOrderProducts.Items = uniqBy(
          concat(
            doorOrderProductDataClone.doorOrderProducts.Items || [],
            doorOrderProductsToBeConcatenated
          ),
          'Id'
        )

        apolloClient.writeQuery({
          query: SA_DOOR_ORDER_PRODUCTS,
          variables: {
            where: orderProductsWhere(
              hierarchy.activity,
              hierarchy.childrenMeta,
              hierarchy.childrenMetaValues
            )
          },
          data: doorOrderProductDataClone
        })
      }
    } catch (err) {}
  }

  updateCacheOnAddingProductToClientOrder = async (
    node: any,
    productId: string,
    orderId: string
  ) => {
    const { activity, childrenMeta, childrenMetaValues } = getProductsByCategories(node)
    let {
      data: { findVIPOrderProduct }
    } = await apolloClient.query({
      query: SA_CLIENT_ORDER_PRODUCT_WITH_DOOR_PRODUCTS,
      variables: {
        seasonalProduct: productId,
        clientOrderId: orderId
      },
      fetchPolicy: 'network-only'
    })

    if (findVIPOrderProduct.Items.length) {
      const clientOrderProduct = cloneDeep(findVIPOrderProduct.Items[0])
      clientOrderProduct.__typename = CentricNodeType.VIPOrderProduct
      const withVIPProductDetails = ShowroomMenuEnum.basket === this.productViewType

      try {
        // Read data to be updated from cache
        let clientOrderProductsData = apolloClient.readQuery({
          query: SA_CLIENT_ORDER_PRODUCTS,
          variables: {
            where: this.orderProductsWhere(
              activity,
              childrenMeta,
              childrenMetaValues,
              true
            ),
            withVIPProductDetails
          }
        })

        const clientOrderItems = cloneDeep(clientOrderProductsData)
        let clientOrderProductNode = {
          ...clientOrderProduct,
          ...(withVIPProductDetails
            ? { Original: clientOrderProduct?.Original }
            : {
                Original: {
                  Id: clientOrderProduct?.Original?.Id,
                  Name: clientOrderProduct?.Original?.Name,
                  __typename: clientOrderProduct?.Original?.__typename
                }
              }),
          rtlPrice: clientOrderProduct.rtlPrice
            ? omit(clientOrderProduct.rtlPrice, 'Code')
            : null,
          whlPrice: clientOrderProduct.whlPrice
            ? omit(clientOrderProduct.whlPrice, 'Code')
            : null
        }

        if (clientOrderItems?.clientOrderProducts?.Items?.length) {
          clientOrderItems?.clientOrderProducts?.Items.push(clientOrderProductNode)
        } else if (clientOrderItems.clientOrderProducts) {
          clientOrderItems.clientOrderProducts.Items = [clientOrderProductNode]
        }

        apolloClient.writeQuery({
          query: SA_CLIENT_ORDER_PRODUCTS,
          variables: {
            where: this.orderProductsWhere(
              activity,
              childrenMeta,
              childrenMetaValues,
              true
            ),
            withVIPProductDetails
          },
          data: clientOrderItems
        })
      } catch (err) {}

      this.updateClientOrderSummaryQueryCacheOnAddingProductToClientOrder(
        clientOrderProduct,
        orderId
      )

      this.updateDoorOrderProductsQueryOnAddingProductToOrder(
        clientOrderProduct.doorProducts,
        { activity, childrenMeta, childrenMetaValues },
        pick(clientOrderProduct, [
          'Id',
          'isRecommended',
          'rtlPrice',
          'whlPrice',
          '__typename'
        ])
      )
    }
  }

  updateDoorOrderSummaryQueryCacheOnAddingProductToDoorOrder = (
    doorOrderProduct,
    doorOrderId: string
  ) => {
    try {
      let doorOrderSummaryData = apolloClient.readQuery({
        query: this.isDoorOrder
          ? SA_DOOR_ORDER_PRODUCT_SUMMARY_DETAILS
          : SA_CLIENT_ORDER_PRODUCT_SUMMARY_DETAILS,
        variables: {
          orderId: stores?.nav?.queryParams?.orderId
        }
      })
      let orderSummary = cloneDeep(doorOrderSummaryData)

      if (orderSummary?.orderProductSummary) {
        const updatedDoorOrder = has(orderSummary.orderProductSummary, 'DoorOrders')
          ? find(
              orderSummary.orderProductSummary?.DoorOrders,
              doorOrder => doorOrder?.Id === doorOrderId
            )
          : orderSummary.orderProductSummary

        const doorProduct: any = this.isDoorOrder
          ? {
              ...omit(doorOrderProduct, ['rtlPrice', 'DoorOrder']),
              Original: omit(doorOrderProduct?.Original, [
                'DefaultImage',
                'Name',
                'Sizes'
              ]),
              clientOrderProduct: omit(doorOrderProduct?.clientOrderProduct, ['rtlPrice'])
            }
          : {
              ...omit(doorOrderProduct, [
                'rtlPrice',
                'BuyingSessionProduct',
                'DoorOrder'
              ]),
              Original: {
                Id: doorOrderProduct?.Original?.Id,
                Active: doorOrderProduct?.Original?.Active,
                Activity: doorOrderProduct?.Original?.Activity,
                Family: doorOrderProduct?.Original?.Family,
                Line: doorOrderProduct?.Original?.Line,
                __typename: doorOrderProduct?.Original?.__typename
              },
              clientOrderProduct: omit(doorOrderProduct?.clientOrderProduct, ['rtlPrice'])
            }
        doorProduct.__typename = CentricNodeType.VIPOrderProduct
        if (updatedDoorOrder.OrderLineItems) {
          if (
            !find(updatedDoorOrder.OrderLineItems, item => item.Id === doorProduct.Id)
          ) {
            updatedDoorOrder.OrderLineItems.push(doorProduct)
          }
        } else {
          updatedDoorOrder.OrderLineItems = [doorProduct]
        }
      }

      apolloClient.writeQuery({
        query: this.isDoorOrder
          ? SA_DOOR_ORDER_PRODUCT_SUMMARY_DETAILS
          : SA_CLIENT_ORDER_PRODUCT_SUMMARY_DETAILS,
        variables: {
          orderId: stores?.nav?.queryParams?.orderId
        },
        data: orderSummary
      })
    } catch (error) {
      /**
       * Apollo client throws exception if query is not present in cache.
       * We might have query missing for products section in case product for new category is added. Silently ignoring it in that
       * case
       */
    }
  }

  updateCacheOnAddingProductToDoorOrder = async (node, productId, orderId) => {
    const { activity, childrenMeta, childrenMetaValues } = getProductsByCategories(node)
    let {
      data: { findVIPOrderProduct }
    } = await apolloClient.query({
      query: SA_DOOR_ORDER_PRODUCT_BY_ORIGINAL,
      variables: {
        seasonalProduct: productId,
        doorOrderId: orderId
      },
      fetchPolicy: 'network-only'
    })

    const doorOrderProduct = findVIPOrderProduct?.Items?.[0]

    if (doorOrderProduct) {
      this.updateDoorOrderProductsQueryOnAddingProductToOrder([doorOrderProduct], {
        activity,
        childrenMeta,
        childrenMetaValues
      })

      this.updateDoorOrderSummaryQueryCacheOnAddingProductToDoorOrder(
        doorOrderProduct,
        orderId
      )
    }
  }

  @action
  addProductToBasket = async (node, productId, orderId, bspId?) => {
    const SA_ADD_PRODUCT_TO_DOOR_ORDER_URL = `${config.centricRestEndpoint}addProductToDoor`
    const ADD_PRODUCT_TO_CLIENT_ORDER_URL = `${config.centricRestEndpoint}addProductToClient`

    try {
      this.setProductActionTracker(productId, OrderActions.basketTogglingInProgress, true)

      const URL = this.isDoorOrder
        ? SA_ADD_PRODUCT_TO_DOOR_ORDER_URL
        : ADD_PRODUCT_TO_CLIENT_ORDER_URL
      const sessionURL: string = storage.getItem('session_url')
      const body = {
        ...(this.isDoorOrder
          ? {
              doorOrder: orderId
            }
          : {
              clientOrder: orderId
            }),
        product: productId
      }

      // api call
      const { data } = await axios.post(URL, body, {
        headers: {
          Authorization: `Bearer ${sessionURL}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })

      if (data?.status === strings.mutationResultSuccess) {
        const {
          orderManagementStore: { addProductsAddedToOrder }
        } = stores

        addProductsAddedToOrder(productId)

        // In case request is made from product details view, update product details saved in store
        if (bspId) {
          this.setOrderProductIdInProductDetails()
        }

        // Update cache
        this.isDoorOrder
          ? await this.updateCacheOnAddingProductToDoorOrder(node, productId, orderId)
          : await this.updateCacheOnAddingProductToClientOrder(node, productId, orderId)
      }
    } finally {
      this.setProductActionTracker(
        productId,
        OrderActions.basketTogglingInProgress,
        false
      )
    }
  }

  @action onBackClick = () => {
    const {
      nav: {
        history,
        queryParams: { clientId = '', buyingSessionGroupId = '', clientType }
      }
    } = stores
    history.push({
      pathname: `/showroom/clients/buyingSessionGroup`,
      search: qs.stringify({
        clientId,
        buyingSessionGroupId,
        clientType
      })
    })
  }

  @action
  addProductToDoorOrder = (product: SAListViewProduct, doorOrderId) => async e => {
    const SA_ADD_PRODUCT_TO_DOOR_ORDER_URL = `${config.centricRestEndpoint}addProductToDoor`
    const sessionURL: string = storage.getItem('session_url')
    const { node, id: productId } = product
    const body = {
      doorOrder: doorOrderId,
      product: productId
    }

    if (
      productId &&
      !this.productActionMap[`${productId}-${doorOrderId}`]?.addDoorOrderProductInProgress
    ) {
      this.setProductActionTracker(
        productId,
        OrderActions.addDoorOrderProductInProgress,
        true,
        doorOrderId
      )

      try {
        // api call
        const { data } = await axios.post(SA_ADD_PRODUCT_TO_DOOR_ORDER_URL, body, {
          headers: {
            Authorization: `Bearer ${sessionURL}`,
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        })

        if (data?.status === strings.mutationResultSuccess) {
          // const createdNode = find(
          //   data?.modifiedCNLs || [],
          //   updatedNode =>
          //     product.orderProductId &&
          //     updatedNode.Type === CentricNodeType.VIPOrderProduct &&
          //     updatedNode.Id !== product.orderProductId
          // )

          this.updateCacheOnAddingProductToDoorOrder(node, productId, doorOrderId)
        }
      } finally {
        this.setProductActionTracker(
          productId,
          OrderActions.addDoorOrderProductInProgress,
          false,
          doorOrderId
        )
      }
    }
  }

  @action
  handleDeleteConfirmationClick = async () => {
    if (this.openConfirmationToRemoveProduct) {
      this.removeProductFromBasket()
    } else {
      await this.toggleBookedFlagForProduct(
        this.selectedProduct,
        this.doorOrderProductId,
        false
      )
    }
  }

  @action
  handleConfirmationDialogClose = () => {
    if (this.openConfirmationToRemoveProduct) {
      this.openConfirmationToRemoveProduct = false
    } else {
      this.openConfirmationDialog = false
    }
  }

  toggleBookUnbookAllProduct = async (product, selectedMenu) => {
    const { node } = product
    const isBooked = selectedMenu === strings.bookAll
    this.setProductActionTracker(
      product.orderProductId,
      OrderActions.bookUnbookTogglingInProgress,
      true
    )
    this.setBackDropOnLoading(true)
    try {
      await apolloClient.mutate({
        mutation: UPDATE_BOOK_UNBOOK_ALL_FLAG_FOR_CLIENT_ORDER_PRODUCT,
        variables: {
          clientOrderProductId: product.orderProductId,
          isBooked
        },
        update: (cache, response) => {
          const mutationResult =
            response?.data?.VIPBoard_UpdateBookedFlagForClientOrderProduct
          if (mutationResult?.status === strings.mutationResultSuccess) {
            const modifiedIds = map(
              response.data.VIPBoard_UpdateBookedFlagForClientOrderProduct.modifiedCNLs,
              modifiedCNL => modifiedCNL.Id
            )
            const {
              showroomCollection: { orderProductsWhere }
            } = stores
            const { activity, childrenMeta, childrenMetaValues } =
              getProductsByCategories(node)
            const doorOrderProductsWhere = orderProductsWhere(
              activity,
              childrenMeta,
              childrenMetaValues
            )
            const [doorOrderProductsData, orderSummary]: [any, any] = [
              cache.readQuery({
                query: SA_DOOR_ORDER_PRODUCTS,
                variables: {
                  where: doorOrderProductsWhere
                }
              }),
              cache.readQuery({
                query: this.isDoorOrder
                  ? SA_DOOR_ORDER_PRODUCT_SUMMARY_DETAILS
                  : SA_CLIENT_ORDER_PRODUCT_SUMMARY_DETAILS,
                variables: {
                  orderId: stores?.nav?.queryParams?.orderId
                }
              })
            ]
            const doorOrderProductsDataClone = cloneDeep(doorOrderProductsData)
            let updatedDoorOrderProducts = []
            if (doorOrderProductsDataClone) {
              const {
                doorOrderProducts: { Items }
              } = doorOrderProductsDataClone
              updatedDoorOrderProducts = map(Items, item => {
                if (includes(modifiedIds, item?.Id)) {
                  if (!isBooked) {
                    item.isBooked = isBooked
                    if (item.sizes) {
                      fill(item.qtyPerSize, 0)
                      item.totalQty = 0
                    } else {
                      item.totalQty = 0
                    }
                  }
                  if (isBooked) {
                    item.isBooked = isBooked
                  }
                }
                return item
              })
              doorOrderProductsDataClone.doorOrderProducts.Items =
                updatedDoorOrderProducts
              cache.writeQuery({
                query: SA_DOOR_ORDER_PRODUCTS,
                variables: {
                  where: doorOrderProductsWhere
                },
                data: doorOrderProductsDataClone
              })
            }
            // Update category level data
            const orderSummaryClone: any = cloneDeep(orderSummary)
            let doorOrders = has(orderSummaryClone?.orderProductSummary, 'DoorOrders')
              ? orderSummaryClone?.orderProductSummary?.DoorOrders
              : orderSummaryClone?.orderProductSummary
              ? [orderSummaryClone?.orderProductSummary]
              : []
            map(doorOrders, doorOrder => {
              let orderProductToBeUpdated = find(doorOrder?.OrderLineItems || [], item =>
                includes(modifiedIds, item?.Id)
              )

              if (orderProductToBeUpdated) {
                orderProductToBeUpdated.isBooked = selectedMenu === strings.bookAll

                if (selectedMenu !== strings.bookAll) {
                  // Set draft and sized qty 0
                  orderProductToBeUpdated.totalQty = 0
                  fill(orderProductToBeUpdated.qtyPerSize, 0)
                }
                return true
              }
            })
            cache.writeQuery({
              query: this.isDoorOrder
                ? SA_DOOR_ORDER_PRODUCT_SUMMARY_DETAILS
                : SA_CLIENT_ORDER_PRODUCT_SUMMARY_DETAILS,
              variables: {
                orderId: stores?.nav?.queryParams?.orderId
              },
              data: orderSummaryClone
            })
          }
        }
      })
    } finally {
      this.setProductActionTracker(
        product.orderProductId,
        OrderActions.bookUnbookTogglingInProgress,
        false
      )
      this.setBackDropOnLoading(false)
    }
  }

  @action onAllBookChange = (product: SAListViewProduct, selectedMenu: string) => {
    this.toggleBookUnbookAllProduct(product, selectedMenu)
  }

  @action
  onBookedChange =
    (product: SAListViewProduct, doorOrderProductId: string, booked: boolean) =>
    async e => {
      if (!booked) {
        this.openConfirmationDialog = true
        this.selectedProduct = product
        this.doorOrderProductId = doorOrderProductId
        this.SAConfirmationMessage = strings.unbookedConfirmationMessage
      } else {
        await this.toggleBookedFlagForProduct(product, doorOrderProductId, booked)
      }
    }

  @action toggleBookedFlagForProduct = async (
    product: SAListViewProduct,
    doorOrderProductId: string,
    booked: boolean
  ) => {
    const { node } = product
    this.isInProgress = true
    if (
      doorOrderProductId &&
      !this.productActionMap[doorOrderProductId]?.doorOrderProductBookingInProgress
    ) {
      this.setProductActionTracker(
        doorOrderProductId,
        OrderActions.doorOrderProductBookingInProgress,
        true
      )

      try {
        await apolloClient.mutate({
          mutation: SA_TOGGLE_BOOKED_FLAG_FOR_DOOR_ORDER_PRODUCT,
          variables: {
            doorOrderProductId,
            isBooked: booked
          },
          update: (cache, response) => {
            if (
              response?.data?.VIPBoard_UpdateBookedFlagForOrderProduct?.status ===
              strings.mutationResultSuccess
            ) {
              this.updateCacheOnBookedFlagChange(cache, node, {
                doorOrderProductId,
                booked
              })
            }
          }
        })
      } finally {
        this.setProductActionTracker(
          doorOrderProductId,
          OrderActions.doorOrderProductBookingInProgress,
          false
        )
        doorOrderProductId === this.doorOrderProductId &&
          this.handleConfirmationDialogClose()

        this.isInProgress = false
      }
    }
  }

  updateHierarchyLevelCacheOnBookedFlagChange = (
    cache,
    { doorOrderProductId, booked }
  ) => {
    const orderSummary = cache.readQuery({
      query: this.isDoorOrder
        ? SA_DOOR_ORDER_PRODUCT_SUMMARY_DETAILS
        : SA_CLIENT_ORDER_PRODUCT_SUMMARY_DETAILS,
      variables: {
        orderId: stores?.nav?.queryParams?.orderId
      }
    })

    const orderSummaryClone: any = cloneDeep(orderSummary)
    let doorOrders = has(orderSummaryClone?.orderProductSummary, 'DoorOrders')
      ? orderSummaryClone?.orderProductSummary?.DoorOrders
      : orderSummaryClone?.orderProductSummary
      ? [orderSummaryClone?.orderProductSummary]
      : []

    some(doorOrders, doorOrder => {
      let orderProductToBeUpdated = find(
        doorOrder?.OrderLineItems || [],
        item => item.Id === doorOrderProductId
      )

      if (orderProductToBeUpdated) {
        orderProductToBeUpdated.isBooked = booked

        if (!booked) {
          // Set draft and sized qty 0
          orderProductToBeUpdated.totalQty = 0
          fill(orderProductToBeUpdated.qtyPerSize, 0)
        }
        return true
      }
    })
    cache.writeQuery({
      query: this.isDoorOrder
        ? SA_DOOR_ORDER_PRODUCT_SUMMARY_DETAILS
        : SA_CLIENT_ORDER_PRODUCT_SUMMARY_DETAILS,
      variables: {
        orderId: stores?.nav?.queryParams?.orderId
      },
      data: orderSummaryClone
    })
  }

  updateCategoryLevelCacheOnBookedFlagChange = (
    cache,
    hierarchyMeta,
    { doorOrderProductId, booked }
  ) => {
    const {
      showroomCollection: { orderProductsWhere }
    } = stores
    const doorOrderProductsWhere = orderProductsWhere(
      hierarchyMeta.activity,
      hierarchyMeta.childrenMeta,
      hierarchyMeta.childrenMetaValues
    )
    let doorOrderData = cache.readQuery({
      query: SA_DOOR_ORDER_PRODUCTS, // Category level products
      variables: {
        where: doorOrderProductsWhere
      }
    })

    const dataClone: any = cloneDeep(doorOrderData)
    let updatedProduct = find(
      dataClone?.doorOrderProducts?.Items || [],
      item => item.Id === doorOrderProductId
    )
    updatedProduct.isBooked = booked

    if (!booked) {
      // Set draft and sized qty 0
      updatedProduct.totalQty = 0
      fill(updatedProduct.qtyPerSize, 0)
    }

    cache.writeQuery({
      query: SA_DOOR_ORDER_PRODUCTS,
      variables: {
        where: doorOrderProductsWhere
      },
      data: dataClone
    })
  }

  updateCacheOnBookedFlagChange = (cache, node, { doorOrderProductId, booked }) => {
    const hierarchyMeta = getProductsByCategories(node)
    this.updateCategoryLevelCacheOnBookedFlagChange(cache, hierarchyMeta, {
      doorOrderProductId,
      booked
    })

    this.updateHierarchyLevelCacheOnBookedFlagChange(cache, {
      doorOrderProductId,
      booked
    })
  }

  @action handleKeyDownForOrderProductQtyInput =
    (
      product: SAListViewProduct,
      doorOrderProductId: string,
      existingQuantity: number,
      sizeId?: string
    ) =>
    (e, updatedQuantity) => {
      if (e.keyCode === KeyCode.Enter) {
        const quantity = updatedQuantity ? parseInt(updatedQuantity) : 0
        this.updateQtyForDoorOrderProduct(
          product,
          doorOrderProductId,
          existingQuantity,
          quantity,
          sizeId
        )
      }
    }

  @action handleQtyChange =
    (
      product: SAListViewProduct,
      doorOrderProductId: string,
      existingQuantity: number,
      sizeId?: string
    ) =>
    async e => {
      const quantity = e?.target?.value ? parseInt(e?.target?.value) : 0
      this.updateQtyForDoorOrderProduct(
        product,
        doorOrderProductId,
        existingQuantity,
        quantity,
        sizeId
      )
    }

  updateQtyOfCachedOrderProduct = (orderProduct, sizeId: string, quantity: number) => {
    if (sizeId) {
      let indexOfSizeToBeUpdated = findIndex(
        orderProduct?.sizes || [],
        (size: CentricNode) => size.Id === sizeId
      )
      if (
        indexOfSizeToBeUpdated > -1 &&
        indexOfSizeToBeUpdated < (orderProduct?.qtyPerSize || []).length
      ) {
        orderProduct.qtyPerSize[indexOfSizeToBeUpdated] = quantity
      }
    } else {
      orderProduct.totalQty = quantity
    }
  }

  @action updateQtyForDoorOrderProduct = async (
    product: SAListViewProduct,
    doorOrderProductId: string,
    existingQuantity: number,
    quantity: number,
    sizeId?: string
  ) => {
    const { node } = product
    const keyForProductActionMap = sizeId
      ? doorOrderProductId + sizeId
      : doorOrderProductId
    if (
      doorOrderProductId &&
      existingQuantity !== quantity &&
      !this.productActionMap[keyForProductActionMap]?.globalProductQtyUpdateInProgress
    ) {
      this.setProductActionTracker(
        keyForProductActionMap,
        OrderActions.globalProductQtyUpdateInProgress,
        true
      )
      try {
        await apolloClient.mutate({
          mutation: SA_UPDATE_DOOR_ORDER_PRODUCT_QTY,
          variables: {
            doorOrderProductId,
            quantity,
            size: sizeId
          },
          update: (cache, response) => {
            if (
              response?.data?.VIPBoard_UpdateOrderProductQuantity?.status ===
              strings.mutationResultSuccess
            ) {
              const {
                showroomCollection: { orderProductsWhere }
              } = stores
              const { activity, childrenMeta, childrenMetaValues } =
                getProductsByCategories(node)
              const doorOrderProductsWhere = orderProductsWhere(
                activity,
                childrenMeta,
                childrenMetaValues
              )

              try {
                let [doorOrderData, orderSummary] = [
                  cache.readQuery({
                    query: SA_DOOR_ORDER_PRODUCTS,
                    variables: {
                      where: doorOrderProductsWhere
                    }
                  }),
                  cache.readQuery({
                    query: this.isDoorOrder
                      ? SA_DOOR_ORDER_PRODUCT_SUMMARY_DETAILS
                      : SA_CLIENT_ORDER_PRODUCT_SUMMARY_DETAILS,
                    variables: {
                      orderId: stores?.nav?.queryParams?.orderId
                    }
                  })
                ]

                const doorOrderDataClone: any = cloneDeep(doorOrderData)
                let updatedProduct = find(
                  doorOrderDataClone?.doorOrderProducts?.Items || [],
                  item => item.Id === doorOrderProductId
                )

                if (updatedProduct) {
                  // Update category level data
                  this.updateQtyOfCachedOrderProduct(updatedProduct, sizeId, quantity)
                  cache.writeQuery({
                    query: SA_DOOR_ORDER_PRODUCTS,
                    variables: {
                      where: doorOrderProductsWhere
                    },
                    data: doorOrderDataClone
                  })

                  // Update hierarchy level data
                  const orderSummaryClone: any = cloneDeep(orderSummary)
                  let doorOrderDataToBeUdpated = has(
                    orderSummaryClone?.orderProductSummary,
                    'DoorOrders'
                  )
                    ? find(
                        orderSummaryClone?.orderProductSummary?.DoorOrders,
                        doorOrder => doorOrder?.Id === updatedProduct?.DoorOrder?.Id
                      )
                    : orderSummaryClone?.orderProductSummary
                  let updatedDoorOrderProduct = find(
                    doorOrderDataToBeUdpated?.OrderLineItems || [],
                    item => item.Id === doorOrderProductId
                  )
                  this.updateQtyOfCachedOrderProduct(
                    updatedDoorOrderProduct,
                    sizeId,
                    quantity
                  )

                  cache.writeQuery({
                    query: this.isDoorOrder
                      ? SA_DOOR_ORDER_PRODUCT_SUMMARY_DETAILS
                      : SA_CLIENT_ORDER_PRODUCT_SUMMARY_DETAILS,
                    variables: {
                      orderId: stores?.nav?.queryParams?.orderId
                    },
                    data: orderSummaryClone
                  })
                }
              } catch (err) {
                /**
                 * NOTE: Logging error here as there won't be any use-case where this query will not be present in cache
                 * and therefore, should be updated correctly
                 */
                console.error(err)
              }
            }
          }
        })
      } finally {
        this.setProductActionTracker(
          keyForProductActionMap,
          OrderActions.globalProductQtyUpdateInProgress,
          false
        )
      }
    }
  }

  @action updateTotalQtyForClientOrder = (
    clientOrderHierarchyData,
    useSizedQty,
    currency?: string
  ) => {
    const qtyKey = useSizedQty
      ? ListViewFieldMapping.totalSizedQuantity
      : ListViewFieldMapping.totalTargetQuantity

    const valueKey = useSizedQty
      ? ListViewFieldMapping.totalSizedValue
      : ListViewFieldMapping.totalTargetValue
    let { totalQty, totalValue } = (clientOrderHierarchyData?.categories ?? []).reduce(
      (acc, curr) => {
        acc.totalQty += curr[qtyKey] ?? 0
        acc.totalValue += curr[valueKey] ?? 0
        return acc
      },
      { totalQty: 0, totalValue: 0 }
    )

    const valueWithCurrency = convertToCurrencyLocaleString(currency, totalValue)

    if (
      this.summaryBarData.totalQuantity !== totalQty ||
      this.summaryBarData.totalValue !== valueWithCurrency
    ) {
      this.summaryBarData.totalQuantity = totalQty
      this.summaryBarData.totalValue = valueWithCurrency
    }
  }

  isOrderEditable = (orderData): boolean => {
    let isClientMaster, isClientOrderDraft, clientOrderStatus
    let doorOrders = []
    let id = orderData?.Id
    if (this.isDoorOrder) {
      isClientMaster = !orderData?.OrderNumber
      doorOrders = [orderData]
    } else {
      isClientMaster = !!orderData?.OrderNumber
      isClientOrderDraft = orderData?.isDraft
      clientOrderStatus = orderData?.status
      doorOrders = orderData?.DoorOrders ?? []
    }

    return isOrderAvailableForUpdates({
      id,
      isClientMaster,
      isClientOrderDraft,
      clientOrderStatus,
      doorOrders
    })
  }

  getLeafRowHeight = (node: any, availableWidth: number) => {
    let heightOfRow = 0
    if (node && availableWidth) {
      let widthOfProductCard = 175 // Showroom Grod Card Width (150) + Margin (25)
      let heightOfProductCard = 516 // Card Height (496) + Margin (20)

      const totalProducts = node.products

      const productsPerRow = Math.floor(availableWidth / widthOfProductCard)
      if (productsPerRow > totalProducts) {
        heightOfRow = heightOfProductCard
      } else {
        heightOfRow = Math.ceil(node.products / productsPerRow) * heightOfProductCard
      }
    }
    return heightOfRow + 8 // Top Padding
  }

  /**
   * Where query to get the buy target from rangeplan.
   */

  whereInputForBuyTarget = (
    buyingSessions: Array<IStandardBuyingSession>,
    gender: string,
    specType?: string,
    season?: string
  ) => {
    if (!buyingSessions || !buyingSessions.length) return null

    const activityCollectionMap = map(buyingSessions, session => ({
      Activity: {
        Id: {
          operation: CriteriaOperationType.EQ,
          value: session.Activity.Id
        }
      },
      ...(season && {
        Season: {
          Id: {
            operation: CriteriaOperationType.EQ,
            value: season
          }
        }
      })
    }))
    return {
      Subtype: {
        Name: {
          operation: CriteriaOperationType.EQ,
          value: specType || config?.appConfig?.specLibraryItems?.buyTarget?.TYPE || ''
        }
      },
      GIV_SpecItemDef_Gender_ref: {
        Id: {
          operation: CriteriaOperationType.EQ,
          value: gender
        }
      },
      OR: activityCollectionMap
    }
  }
}

export const showroomCollection = new ShowRoomCollectionStore()
