import { StyledProps } from '@components/3rd-party/mui'
import { TextInput } from '@components/UI-Components/TextInput'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment'
import React from 'react'

const styles = theme => ({
  root: {
    padding: `8px 20px 8px 8px`
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '10px 0'
  },
  commentContainer: {
    paddingRight: 20,
    textOverflow: 'wrap'
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center'
  },
  userDate: {
    display: 'flex',
    justifyContent: 'space-between'
  }
})

interface ICommentItem extends StyledProps {
  comment: any
  productId: any
  user: any
  onClick?: () => void
  onBlur?: () => void
  toggleEdit?: (text) => void
  deleteComment: (string) => void
  addComment: (String, string) => void
  updateComment: (String, string) => void
}

@((withStyles as any)(styles))
export class CommentItem extends React.Component<ICommentItem> {
  state = {
    isEdit: false,
    commentText: ''
  }

  toggleEdit = text => {
    const {
      state: { isEdit }
    } = this

    this.setState({
      isEdit: !isEdit,
      commentText: text
    })
  }

  handleComment = e => {
    this.setState({ commentText: e.target.value })
  }

  handleEdit = () => {
    const {
      props: { comment, updateComment },
      state: { isEdit, commentText }
    } = this

    this.setState({
      isEdit: !isEdit
    })
    commentText && updateComment(comment.id, commentText)
  }

  render() {
    const {
      props: { comment, deleteComment, classes, user },
      state: { isEdit, commentText },
      toggleEdit
    } = this

    const isSameUser = comment.user.id === user.id

    return (
      <div className={classes.root}>
        <div className={classes.userDate}>
          <Typography variant='overline' style={{ color: 'rgba(0, 0, 0, 0.38)' }}>
            {comment.user.firstName}
          </Typography>
          <Typography variant='overline' style={{ color: 'rgba(0, 0, 0, 0.38)' }}>
            {moment(comment.createdAt).format('MM-DD-YYYY HH:mm')}
          </Typography>
        </div>
        <div className={classes.flexContainer}>
          {isEdit ? (
            <TextInput
              multiline
              value={commentText}
              onChange={this.handleComment}
              onBlur={this.handleEdit}
              className={classes.commentContainer}
              style={{ paddingBottom: 16 }}
              rows={2}
              rowsMax={2}
              fullWidth
            />
          ) : (
            <Typography className={classes.commentContainer}>{comment.text}</Typography>
          )}
          {!isEdit && isSameUser ? (
            <div className={classes.actions}>
              <EditIcon className={'icon'} onClick={() => toggleEdit(comment.text)} />
              <DeleteIcon className={'icon'} onClick={() => deleteComment(comment.id)} />
            </div>
          ) : null}
        </div>
      </div>
    )
  }
}
