import { StyledProps } from '@components/3rd-party/mui'
import { CrossIcon } from '@components/UI-Components/svg'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import { withStyles } from '@material-ui/core/styles'
import { SearchStore, stores } from '@stores'
import classNames from 'classnames'
import debounce from 'lodash/debounce'
import { observer } from 'mobx-react'
import React from 'react'
import { withRouter } from 'react-router-dom'

const styles = theme => ({
  textField: {
    marginBottom: '8px',
    width: 150,
    paddingRight: '10px',
    transitionTimingFunction: 'ease-in',
    transition: '2s;'
  },
  fullWidthTextField: {
    width: '100%',
    marginLeft: '25px'
  },
  cursor: {
    cursor: 'pointer'
  },
  root: {
    zIndex: 1,
    display: 'flex',
    alignItems: 'center'
  }
})

export interface SearchInputComponentProps extends StyledProps {
  collectionStore?: any
  filterStore?: any
  history?: any
  match?: any
  location?: any
  searchText?: string
  onSearchKeyChange?: (string) => void
  localSearchStore?: SearchStore
  inputPlaceholder?: string
  isFullWidthTextField?: boolean
}

@((withStyles as any)(styles))
@(withRouter as any)
@observer
export class SearchInputComponent extends React.Component<SearchInputComponentProps> {
  handleSearch = event => {}
  componentWillMount() {
    this.handleSearch = debounce(this.queryToSearchProduct, 1000)
  }
  queryToSearchProduct = () => {
    const { localSearchStore } = this.props
    const { onSearchKeyChange, searchText } = localSearchStore ?? stores.search
    onSearchKeyChange(searchText.trim())
  }
  handleSearchTextChange = event => {
    const { localSearchStore } = this.props
    const { onSearchComponentTextChange } = localSearchStore ?? stores.search
    onSearchComponentTextChange(event.target.value)
    this.handleSearch(event)
  }

  render() {
    const { localSearchStore } = this.props
    const [
      {
        props: { classes, inputPlaceholder, isFullWidthTextField }
      },
      { searchText, handleKeyPress, showInputField, showField }
    ] = [this, localSearchStore ?? stores.search]
    return (
      <div className={classes.root}>
        {showField ? (
          <FormControl
            className={classNames(
              classes.textField,
              isFullWidthTextField ? classes.fullWidthTextField : null
            )}
          >
            <Input
              id='standard-adornment-password'
              autoFocus
              type={'text'}
              value={searchText}
              className={classes.searchInput}
              onChange={this.handleSearchTextChange}
              onKeyPress={handleKeyPress}
              placeholder={inputPlaceholder}
              endAdornment={
                <InputAdornment position='end'>
                  <img
                    src={CrossIcon}
                    className={classes.cursor}
                    onClick={showInputField}
                    alt=''
                  />
                </InputAdornment>
              }
            />
          </FormControl>
        ) : null}
      </div>
    )
  }
}
