import { ReadMore } from './ReadMore'
import { EditIcon, TrashIcon } from '@components/UI-Components/svg'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { strings } from '@stores'
import classNames from 'classnames'
import moment from 'moment'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import * as css from './ProductComment.css'
import { ProductCommentItem } from './ProductCommentItem'

interface IComment {
  comments: any
  currentUser: any
  handleEditComment: (Id: String, Comment: String) => void
  selectedCommentId: string
  onChange: any
  handleSubmit: () => void
  onDelete: (String) => void
  canEdit: boolean
  commentText: string
  showUserAddComment?: boolean
  loading?: boolean
}

export const ProductComment: React.FunctionComponent<IComment> = ({
  comments,
  currentUser,
  handleEditComment,
  selectedCommentId,
  handleSubmit,
  onDelete,
  canEdit,
  commentText,
  onChange,
  showUserAddComment,
  loading
}) => {
  return (
    <div
      className={classNames({
        [css.container]: true,
        [css.isSACommentContainer]: !showUserAddComment
      })}
    >
      <Typography className={css.text}>Comments:</Typography>
      {comments.length ? (
        <div
          className={classNames({
            [css.commentContainer]: true,
            [css.saCommentContainer]: !showUserAddComment
          })}
        >
          {comments.map(comment => (
            <div key={comment.Id}>
              <div className={css.commentInfo}>
                <Typography variant='overline'>{comment?.Author?.Name ?? ''}</Typography>
                <Typography variant='overline'>
                  {moment(comment.CreatedAt).format('MM-DD-YYYY HH:mm')}
                </Typography>
              </div>
              <div className={css.commentItem}>
                {selectedCommentId === comment.Id ? (
                  <ProductCommentItem
                    canEdit={canEdit}
                    onChange={onChange}
                    handleSubmit={handleSubmit}
                    commentText={commentText}
                    commentId={comment.Id}
                    hideButton={true}
                  />
                ) : (
                  <>
                    <div>
                      {!showUserAddComment ? (
                        <ReadMore comment={comment?.Comment ?? ''}></ReadMore>
                      ) : (
                        <Tooltip title={comment?.Comment} placement='bottom-start'>
                          <Typography
                            className={classNames({
                              [css.text]: true
                            })}
                          >
                            {comment?.Comment ?? ''}
                          </Typography>
                        </Tooltip>
                      )}
                    </div>
                    {comment?.Author?.Id === currentUser?.id ? (
                      <div>
                        <img
                          src={EditIcon}
                          onClick={
                            canEdit
                              ? () => handleEditComment(comment?.Id, comment?.Comment)
                              : null
                          }
                          className={canEdit ? css.icon : css.disabled}
                          alt='edit'
                        />
                        <img
                          src={TrashIcon}
                          className={canEdit ? css.icon : css.disabled}
                          onClick={canEdit ? () => onDelete(comment.Id) : null}
                          alt='delete'
                        />
                      </div>
                    ) : null}
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      ) : loading ? (
        <Skeleton />
      ) : !showUserAddComment ? (
        <Typography variant='body2' className={css.text}>
          {strings.noComments}
        </Typography>
      ) : null}
    </div>
  )
}
