import { withApollo } from '@apollo/client/react/hoc/withApollo'
import { StyledProps } from '@components/3rd-party/mui'
import { GridCard } from '@components/UI-Components/GridCard'
import { IPermissions } from '@routes'
import { observer } from 'mobx-react'
import React from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'

interface MyProps extends StyledProps {
  product?: any
  statuses?: any
  buyingSession?: any
  key?: any
  assortmentProductId?: any
  getProductStatus: (status: string, isActive: boolean) => any
  updateProductMOQ?: (id, value) => void
  assortment?: any
  deleteProduct?: (id: any, product?: any) => any
  match?: any
  refetch?: any
  history?: any
  permissions?: IPermissions
  handleTagDrop?: (assortmentId, type, id) => void
  buyerClusters?: Array<{ id: string; name: string; colorHex: string }>
  removeBuyerClusterFromZoneAssortmentProduct?: (
    clusterId: string,
    zoneAssortmentProductId: string,
    buyingSessionProduct: any,
    existinBuyerClusters: Array<any>
  ) => void
  removeAssortmentProduct?: (
    assortmentId: string,
    assortmentProductId: string,
    productId: string
  ) => void
  removeZoneRestriction?: (buyingSessionProductId: string, zoneId: string) => void
  removeSalesPeriodFromProduct: (buyingSessionProductId: string) => void
  removeFreeTag?: (productId: any, freeTagId: any) => any
  assortmentRetailPrice?: any
  canDelete?: boolean
}

@(withRouter as any)
@(withApollo as any)
@observer
export class AssortmentGrid extends React.Component<MyProps> {
  state = {
    drawerOpen: false,
    selectedProductId: '',
    moq: 0
  }

  toggleDrawer = () => {
    this.setState({
      drawerOpen: !this.state.drawerOpen
    })
  }

  render() {
    const {
      props: {
        permissions,
        assortmentProductId,
        product,
        canDelete,
        assortment,
        deleteProduct,
        handleTagDrop,
        removeBuyerClusterFromZoneAssortmentProduct,
        removeAssortmentProduct,
        removeZoneRestriction,
        removeSalesPeriodFromProduct,
        removeFreeTag,
        refetch,
        assortmentRetailPrice
      }
    } = this

    return (
      <StyledProductCard
        // to={{
        // pathname: `${match.url}/details/${id}`,
        // search: nav.history.location.search
        // }}
        onClick={e => this.showProductDetail(e, product.id)}
      >
        <GridCard
          assortmentProductId={assortmentProductId}
          product={product}
          handleTagDrop={handleTagDrop}
          removeBuyerClusterFromZoneAssortmentProduct={
            removeBuyerClusterFromZoneAssortmentProduct
          }
          removeAssortmentProduct={removeAssortmentProduct}
          removeZoneRestriction={removeZoneRestriction}
          permissions={permissions}
          assortment={assortment}
          refetch={refetch}
          removeSalesPeriodFromProduct={removeSalesPeriodFromProduct}
          removeFreeTag={removeFreeTag}
          canDelete={canDelete}
          deleteProduct={deleteProduct}
          assortmentRetailPrice={assortmentRetailPrice}
        />
      </StyledProductCard>
    )
  }
  showProductDetail = (e: React.MouseEvent, id) => {
    e.preventDefault()
    e.stopPropagation()
    const {
      history,
      match: { url }
    } = this.props
    history.push({
      pathname: `${url}/details/${id}`,
      search: history.location.search
    })
  }
}

const StyledProductCard = styled.div`
  display: flex;
  // Expand the content card to be as tall as the container
  &,
  > * {
    display: flex;
    flex-direction: column;
  }
  // Drop Target Wrapper
  > * {
    &,
    > * {
      flex: 1;
    }
  }
`
