/**
 * Returns combined ratings
 *
 * @param {array} ratings
 * @param {array} ratingTypes
 *
 * @returns {array}
 */
export const getWsProductRatings = (ratings = [], ratingTypes = []) => {
  const mergedRatings = []

  ratingTypes.forEach(({ Id, Name: TypeName }) => {
    ratings.forEach(rating => {
      if (TypeName === rating?.Name) {
        mergedRatings.push({ Id, TypeName, Value: rating?.Value })
      }
    })

    !isRatingExist(mergedRatings, Id) && mergedRatings.push({ Id, TypeName, Value: 0 })
  })

  return mergedRatings
}

// Check if rating exists
const isRatingExist = (ratings, ratingId) => ratings.some(({ Id }) => ratingId === Id)
