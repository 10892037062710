import { CentricTheme, StyledProps } from '@components/3rd-party/mui'
import Drawer from '@material-ui/core/Drawer'
import { withStyles } from '@material-ui/core/styles'
import { nav, ProductStore } from '@stores'
import { observer } from 'mobx-react'
import React from 'react'

const styles = (theme: CentricTheme) => ({
  container: {
    // display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'left',
    flexDirection: 'column',
    width: '70vw',
    maxWidth: 1024,
    paddingLeft: theme.spacing(3)
  }
})

interface MyProps extends StyledProps {
  productStore?: ProductStore
  open?: boolean
  onClose?: () => void
}

@((withStyles as any)(styles))
@observer
export class ProductDetailComponent extends React.Component<MyProps> {
  render() {
    const {
      props: { classes, children, open, onClose }
    } = this
    const { closeDetails, isDetailsOpen } = nav
    return (
      <Drawer
        anchor='right'
        open={open !== null && open !== undefined ? !!open : isDetailsOpen}
        onClose={onClose ? onClose : closeDetails}
      >
        <div className={classes.container} tabIndex={0} role='button'>
          {children}
        </div>
      </Drawer>
    )
  }
}
