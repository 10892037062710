import { OrderType } from '@modules/common'
import { stores } from '@stores'
import { action, observable } from 'mobx'

class OrderKPIGlobalTopBarStore {
  @observable orderKPIsForGlobalTopBar: { summary: any; otbData: any } = {
    summary: {}, // consumed OTB values "will change"
    otbData: {} // this OTB values "will never change" we would need this value only on-mount
  }

  @action
  setOrderKPIsForGlobalTopBar(input) {
    this.orderKPIsForGlobalTopBar = {
      ...this.orderKPIsForGlobalTopBar,
      ...input
    }
  }

  @action
  updateOrderKPIByOrderTypes = (kpi: any) => {
    const {
      nav: {
        queryParams: { orderType }
      }
    } = stores
    const { summary } = this.orderKPIsForGlobalTopBar
    const previousClientOrderKPI = summary?.clientOrder
    const previousDoorOrderKPI = summary?.doorOrder
    const nextClientOrderKPI = kpi?.clientOrder
    const nextDoorOrderKPI = kpi?.doorOrder

    const newSummary = {
      summary: {
        ...this.orderKPIsForGlobalTopBar.summary,
        ...(orderType === OrderType.Client
          ? {
              clientOrder: {
                ...previousClientOrderKPI,
                ...nextClientOrderKPI
              }
            }
          : {
              doorOrder: {
                ...previousDoorOrderKPI,
                ...nextDoorOrderKPI
              }
            })
      }
    }

    this.setOrderKPIsForGlobalTopBar(newSummary)
  }
}

export const orderKPIGlobalTopBarStore = new OrderKPIGlobalTopBarStore()
