import { TextAlignmentFlexMap } from '@modules/common/models'
import Tooltip from '@material-ui/core/Tooltip'
import React, { memo } from 'react'
import { areEqual } from 'react-window'
import styled from 'styled-components'
import { Cell, ListViewRowProps, Row } from './Common'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import { ListViewColumnDefinition } from '@components/UI-Components/StandardTableView/models/ListviewColumnDefinition'
import { withIconHOC } from '@components/std-components/Icons'
import { NewFlagIcon } from '@components/UI-Components/svg'
import * as css from './style.css'

const FlaggedIcon = withIconHOC({
  imageSrc: NewFlagIcon,
  className: css['flagIcon']
})

interface HeaderContentProps {
  alignment?: string
}

const LabelContainer = styled.span(props => ({
  '&&': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    userSelect: 'none',
    '-webkitUserSelect': 'none',
    '-webkitTouchCallout': 'none'
  }
}))

const StyledDiv = styled.div<HeaderContentProps>(props => {
  const { alignment } = props
  return {
    '&&': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: alignment
        ? TextAlignmentFlexMap[alignment] || TextAlignmentFlexMap.left
        : TextAlignmentFlexMap.left
    }
  }
})

const HeaderRow: React.FunctionComponent<ListViewRowProps> = memo(
  ({
    style,
    headers,
    stickyStyle,
    canTableFitInView,
    rowSelectionConf,
    emptyColWidth
  }) => {
    return (
      <Row style={style}>
        {map(headers, (col: ListViewColumnDefinition, index) => {
          return (
            <Cell
              key={col.id}
              cellWidth={col.width}
              isUpperCase={true}
              isDisabled={col.header.isDisabled}
              cellPad={col.isRootColumn ? '5px 10px 0 0' : '5px 10px 0 10px'}
              stickyLeft={stickyStyle[index] && stickyStyle[index].left}
              alignment={
                !col.isRootColumn && col.props && (col.props.alignment as string)
              }
              isCellShadow={
                stickyStyle[index] &&
                stickyStyle[index].shouldApplyCellShadow &&
                canTableFitInView
              }
            >
              <StyledDiv
                alignment={
                  !col.isRootColumn && col.props && (col.props.alignment as string)
                }
              >
                {col.header.showTooltip ? (
                  <Tooltip title={col.header.label} placement='bottom'>
                    <LabelContainer>{col.header.label}</LabelContainer>
                  </Tooltip>
                ) : (
                  <>
                    {col.header.showFlagIcon && <FlaggedIcon />}
                    <span>{col.header.label}</span>
                  </>
                )}
              </StyledDiv>
            </Cell>
          )
        })}
        {emptyColWidth ? (
          <Cell
            key={`header_empty_cell`}
            cellWidth={emptyColWidth}
            stickyRight={0}
          ></Cell>
        ) : null}
        {!isEmpty(rowSelectionConf) ? (
          <Cell
            cellPad={rowSelectionConf.padding ? rowSelectionConf.padding : undefined}
            key={`header_row_select`}
            cellWidth={rowSelectionConf.width}
            stickyRight={0}
          ></Cell>
        ) : null}
      </Row>
    )
  },
  areEqual
)

HeaderRow.displayName = 'HeaderRow'

export default HeaderRow
