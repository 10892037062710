import { config } from '@config'
import {
  BuyingSessionAssortmentType,
  BuyingSessionGroupNavView
} from '@modules/common/models/enums/BSGroupAndBSNav'
import { AttributeValueType } from '@modules/common/models/enums/ProductAttributeValueType'
import { VIPChannel } from '@modules/common/models/enums/VIPChannel'
import { ProductAttributeConf } from '@modules/common/models/interfaces/WholesaleProductConf'
import { WS_BUYING_SESSION_PRODUCT_TO_ADD } from '@modules/wholesale/assortmentProductWrapper/graphql/getBuyingSessionProductsToadd'
import { ASSORTMENT_PRODUCT_SUMMARY_QUERIES_BY_ASSORTMENT_TYPE } from '@modules/wholesale/assortmentProductWrapper/graphql/hooks'
import { BUYING_SESSION_GROUP_PRODUCT_SUMMARY } from '@modules/wholesale/buyingSessionGroupProductWrapper/graphql/buyingSessionProductSummary'
import {
  FilterDisplayNames,
  FilterKeys
} from '@modules/wholesale/FilterModal/enums/FilterEnums'
import {
  IFilter,
  IFilterChipData,
  IFilterRoot,
  IFilterValue,
  ISelectedFilter
} from '@modules/wholesale/FilterModal/interface/Filter'
import { ViewTabs } from '@modules/common/models/enums/Views'
import { hasUserRoles, PERMISSION_MAP } from '@services/userRoleService'
import { stores, strings } from '@stores'
import { getEnumValue } from '@utils/get-enum-value'
import cloneDeep from 'lodash/cloneDeep'
import concat from 'lodash/concat'
import differenceWith from 'lodash/differenceWith'
import filter from 'lodash/filter'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import forEach from 'lodash/forEach'
import groupBy from 'lodash/groupBy'
import isEmpty from 'lodash/isEmpty'
import keys from 'lodash/keys'
import lowerCase from 'lodash/lowerCase'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import reduce from 'lodash/reduce'
import remove from 'lodash/remove'
import some from 'lodash/some'
import memoize from 'memoize-one'
import { action, computed, observable } from 'mobx'

export class FilterModalStore {
  @observable anchorElForSAFilter = null
  @observable isWSFilterOpen = false
  @observable wsSelectedFilters: ISelectedFilter = {}
  @observable wsAppliedFilters: ISelectedFilter = {}
  @observable selectedFilterTab = null

  @action onFilterIconClick = e => {
    this.anchorElForSAFilter = e.currentTarget
  }
  @action onFilterClose = e => {
    this.anchorElForSAFilter = null
    this.isWSFilterOpen = false
    this.selectedFilterTab = null

    this.wsSelectedFilters = cloneDeep(this.wsAppliedFilters)
  }

  @action onFilterClear = (event, resetSelectedFilterTab: boolean = false) => {
    Object.assign(this, {
      wsSelectedFilters: {},
      wsAppliedFilters: {},
      selectAllFilters: {}
    })

    if (resetSelectedFilterTab) {
      this.selectedFilterTab = null
    }
  }

  @action onSingleFilterRemove = (filterChipData: IFilterChipData) => {
    const wsAppliedFilters = cloneDeep(this.wsAppliedFilters)

    remove(
      wsAppliedFilters[filterChipData.filterKey].values,
      filterValue => filterValue.value === filterChipData.value
    )

    if (isEmpty(wsAppliedFilters[filterChipData.filterKey].values)) {
      delete wsAppliedFilters[filterChipData.filterKey]
    }

    Object.assign(this, { wsSelectedFilters: wsAppliedFilters, wsAppliedFilters })
  }

  @computed get wsAppliedFiltersProcessedForChips() {
    return reduce(
      keys(this.wsAppliedFilters),
      (obj, wsAppliedFilterKey) => {
        obj[wsAppliedFilterKey] = this.wsAppliedFilters[wsAppliedFilterKey]?.values ?? []
        return obj
      },
      {}
    )
  }
  @computed get totalFiltersApplied() {
    return keys(this.wsAppliedFilters).reduce((total, key) => {
      total = total + this.wsAppliedFilters[key].values.length
      return total
    }, 0)
  }

  @action onFilterApply = e => {
    Object.assign(this, {
      wsAppliedFilters: cloneDeep(this.wsSelectedFilters),
      isWSFilterOpen: false,
      anchorElForSAFilter: null
    })
  }

  @action toggleWSFilterDrawer = e => {
    this.isWSFilterOpen = !this.isWSFilterOpen
  }

  @computed get queryVariablesForFilterProductsQuery() {
    const {
      nav: {
        buyingSession,
        queryParams: { assortmentType }
      },
      wsBuyingSessionProduct: {
        whereInputForBSPSummaryForGroup,
        whereInputForBSPSummaryForBS
      },
      wholesaleAssortmentProductStore: {
        buyingSessionAssortmentProductSummaryWhere,
        whereInputForBSPToAdd
      },
      wholesaleAddProductToAssortment: { isAddProductDrawerOpen }
    } = stores
    return isAddProductDrawerOpen
      ? {
          where: whereInputForBSPToAdd
        }
      : assortmentType
      ? { buyingSessionAssortmentProductSummaryWhere }
      : buyingSession
      ? { where: whereInputForBSPSummaryForBS }
      : { where: whereInputForBSPSummaryForGroup }
  }

  @computed get queryForFilterProducts() {
    const {
      nav: {
        queryParams: { assortmentType }
      },
      wholesaleAddProductToAssortment: { isAddProductDrawerOpen }
    } = stores

    return isAddProductDrawerOpen
      ? WS_BUYING_SESSION_PRODUCT_TO_ADD
      : assortmentType
      ? ASSORTMENT_PRODUCT_SUMMARY_QUERIES_BY_ASSORTMENT_TYPE[assortmentType].query
      : BUYING_SESSION_GROUP_PRODUCT_SUMMARY
  }

  @computed get responseFieldForProducts() {
    const {
      nav: {
        queryParams: { assortmentType }
      },
      wholesaleAddProductToAssortment: { isAddProductDrawerOpen }
    } = stores

    return isAddProductDrawerOpen
      ? 'findVIPBuyingSessionProduct'
      : assortmentType
      ? ASSORTMENT_PRODUCT_SUMMARY_QUERIES_BY_ASSORTMENT_TYPE[assortmentType].field
      : 'findVIPBuyingSessionProduct'
  }

  getWSFilterFields = memoize(
    (
      products,
      channelValues,
      freeTagValues,
      clusters,
      showRetailSpecificFilter,
      clientAssortmentProducts = []
    ): { [key: string]: IFilterRoot }[] => {
      const {
        site: { isSAView, isOrderManagementView },
        orderManagementStore: { isAddProductDrawerOpen },
        showroomCollection: { selectedHeaderFilter },
        nav: {
          queryParams: { subview, view }
        }
      } = stores
      const isFiltersForSA =
        (isSAView || isOrderManagementView) && !isAddProductDrawerOpen
      let productsToUse = products

      if (
        isSAView &&
        selectedHeaderFilter ===
          strings.showroomHeaders.showroomSubHeaderFilterValues.recommendedProducts
      ) {
        if (isOrderManagementView) {
          productsToUse = filter(products, product => product?.isRecommended)
        } else {
          productsToUse = filter(products, product =>
            some(
              clientAssortmentProducts,
              clientAssortmentProduct =>
                clientAssortmentProduct.Original.Id === product.Id
            )
          )
        }
      }
      const vipProductAttributesForFilter = filter(
        config?.appConfig?.attributes?.VIPProduct ?? [],
        attr => {
          if (isFiltersForSA) {
            return attr.includeForSAFilter
          } else {
            return attr.includeForSearch
          }
        }
      )

      let wsFilterFields: {
        [key: string]: IFilterRoot
      }[] = []
      let vipAttributes = []

      const drops: IFilterValue[] = []
      const looks: IFilterValue[] = []
      const clustersByChannel = groupBy(clusters, 'Channel')
      const freeTagsByChannel: any = groupBy(
        map(freeTagValues, ({ Id, Name, Channel }) => ({
          Channel,
          label: Name,
          value: Id
        })),
        'Channel'
      )
      const freeTags = Object.keys(freeTagsByChannel)
        .filter((channel: string) => freeTagsByChannel[channel])
        .map((channel: string) => {
          return {
            key: `${lowerCase(getEnumValue(channel))}Free Tag`,
            displayName: `${getEnumValue(channel)} Free Tags`,
            hasFixedValues: true,
            isCollapsible: true,
            values: concat(freeTagsByChannel[channel], {
              label: strings.noFreeTags,
              value: null
            })
          } as IFilter
        })

      forEach(productsToUse, product => {
        const productForDrop = product.BuyingSessionProduct ?? product
        // Drop
        if (
          productForDrop?.drop?.Name &&
          !find(drops, drop => drop.value === productForDrop.drop.Name)
        ) {
          drops.push({
            label: productForDrop.drop.Name,
            value: productForDrop.drop.Name
          })
        }

        if (!isFiltersForSA) {
          // Looks
          const productLooks = product.Original?.associatedLooks ?? []
          const newLooks = differenceWith(
            productLooks,
            looks,
            (productLook: any, look) => productLook.Name === look.label
          )

          if (newLooks.length) {
            forEach(newLooks, newLook => {
              looks.push({
                label: newLook.Name,
                value: newLook.Id
              })
            })
          }
        }

        if (product) {
          vipAttributes = this.buildVIPAttributesFilter(
            vipProductAttributesForFilter,
            product,
            vipAttributes
          )
        }
      })

      //SAFilters
      if (isFiltersForSA) {
        if (vipAttributes.length) {
          wsFilterFields.push({
            [FilterKeys.ProductAttributes]: {
              label: FilterDisplayNames.ProductAttributes,
              name: FilterKeys.ProductAttributes,
              data: vipAttributes,
              orderIndex: isSAView ? 0 : 2
            }
          })
        }
        if (drops.length) {
          wsFilterFields.push({
            [FilterKeys.Drop]: {
              label: FilterDisplayNames.drop,
              name: FilterKeys.Drop,
              data: [
                {
                  displayName: FilterDisplayNames.drop,
                  key: FilterKeys.Drop,
                  values: drops,
                  type: AttributeValueType.REF,
                  isCollapsible: true
                }
              ],
              orderIndex: isOrderManagementView && !isSAView ? 1 : 0
            }
          })
        }
        if (freeTags.length > 1) {
          wsFilterFields.push({
            [FilterKeys.FreeTags]: {
              label: FilterDisplayNames.freeTags,
              name: FilterKeys.ProductAttributes,
              data: freeTags
            }
          })
        }

        wsFilterFields.push(
          {
            [FilterKeys.BookedUnbooked]: {
              label: FilterDisplayNames.BookedUnbooked,
              name: FilterKeys.BookedUnbooked,
              data: [
                {
                  displayName: FilterDisplayNames.BookedUnbooked,
                  key: FilterKeys.BookedUnbooked,
                  values: [
                    {
                      label: FilterDisplayNames.Booked,
                      value: FilterKeys.Booked
                    },
                    {
                      label: FilterDisplayNames.Unbooked,
                      value: FilterKeys.Unbooked
                    }
                  ],
                  type: AttributeValueType.REFLIST
                }
              ],
              orderIndex: 0
            }
          },
          {
            [FilterKeys.WithQtyWithoutQty]: {
              label: FilterDisplayNames.WithQtyWithoutQty,
              name: FilterKeys.WithQtyWithoutQty,
              data: [
                {
                  displayName: FilterDisplayNames.WithQtyWithoutQty,
                  key: FilterKeys.WithQtyWithoutQty,
                  values: [
                    {
                      label: FilterDisplayNames.WithQty,
                      value: FilterKeys.WithQty
                    },
                    {
                      label: FilterDisplayNames.WithoutQty,
                      value: FilterKeys.WithoutQty
                    }
                  ],
                  type: AttributeValueType.REFLIST
                }
              ],
              orderIndex: 0
            }
          }
        )
      } else {
        if (showRetailSpecificFilter) {
          // Product Status Filter - Core - Non Core
          let filterKey = FilterKeys.ProductStatus
          wsFilterFields.push({
            [filterKey]: {
              name: filterKey,
              label: FilterDisplayNames[filterKey],
              data: [
                {
                  displayName: FilterDisplayNames[filterKey],
                  type: AttributeValueType.BOOLEAN,
                  hasFixedValues: true,
                  key: filterKey,
                  values: [
                    {
                      label: strings.filterOptionsLabels[filterKey].with,
                      value: true
                    },
                    {
                      label: strings.filterOptionsLabels[filterKey].without,
                      value: false
                    }
                  ]
                }
              ]
            }
          })

          // counter offer
          if (
            BuyingSessionGroupNavView.Collection === view &&
            BuyingSessionGroupNavView.Overview !== subview &&
            hasUserRoles(
              PERMISSION_MAP.find(ele => VIPChannel.Retail === ele.id).allowedRoles
            )
          ) {
            filterKey = FilterKeys.Flagged
            wsFilterFields.push({
              [filterKey]: {
                name: filterKey,
                label: FilterDisplayNames[filterKey],
                data: [
                  {
                    displayName: FilterDisplayNames[filterKey],
                    type: AttributeValueType.BOOLEAN,
                    hasFixedValues: true,
                    key: filterKey,
                    values: [
                      {
                        label: strings.filterOptionsLabels[filterKey].with,
                        value: true
                      },
                      {
                        label: strings.filterOptionsLabels[filterKey].without,
                        value: false
                      }
                    ]
                  }
                ]
              }
            })
          }
        }

        wsFilterFields = this.buildGlobalAttributesFilter(wsFilterFields, {
          [FilterKeys.Channel]: [
            ...channelValues.map(channel => ({
              label: getEnumValue(channel),
              value: channel
            })),
            {
              label: strings.noChannels,
              value: null
            }
          ],
          [FilterKeys.Drop]: drops,
          [FilterKeys.Looks]: looks
        })

        wsFilterFields.push({
          [FilterKeys.Cluster]: {
            label: FilterDisplayNames.cluster,
            name: FilterKeys.ProductAttributes,
            data: channelValues.map((channel: string) => {
              return {
                key: `${lowerCase(getEnumValue(channel))}Cluster`,
                displayName: `${getEnumValue(channel)} Clusters`,
                hasFixedValues: true,
                isCollapsible: true,
                values: map(clustersByChannel[channel], ({ Id, Name }) => ({
                  label: Name,
                  value: Id
                })).concat({
                  label: strings.unclustered,
                  value: null
                })
              } as IFilter
            })
          },
          [FilterKeys.FreeTags]: {
            label: FilterDisplayNames.freeTags,
            name: FilterKeys.ProductAttributes,
            data: freeTags
          }
        })

        if (vipAttributes.length) {
          wsFilterFields.push({
            [FilterKeys.ProductAttributes]: {
              label: FilterDisplayNames.ProductAttributes,
              name: FilterKeys.ProductAttributes,
              data: filter(vipAttributes, vipAttribute => !isEmpty(vipAttribute.values))
            }
          })
        }
      }

      const filterFields = isOrderManagementView
        ? orderBy(wsFilterFields, filter => {
            return keys(filter).map(key => filter[key]?.orderIndex)
          })
        : wsFilterFields
      return filterFields
    }
  )

  buildGlobalAttributesFilter = (
    wsFilterFields: { [key: string]: IFilterRoot }[],
    filterObject
  ) => {
    const {
      nav: {
        queryParams: { assortmentType, tab }
      }
    } = stores

    // Boolean filters
    forEach(
      [
        FilterKeys.Status,
        FilterKeys.SalesQty,
        FilterKeys.Rating,
        FilterKeys.ProductOrderStatus,
        FilterKeys.ProductComment
      ],
      filterKey => {
        if (
          (assortmentType === BuyingSessionAssortmentType.cluster &&
            filterKey === FilterKeys.SalesQty) ||
          (tab !== ViewTabs.RTL_BUY.toLowerCase() &&
            (filterKey === FilterKeys.ProductOrderStatus ||
              filterKey === FilterKeys.ProductComment)) ||
          (tab === ViewTabs.RTL_BUY.toLowerCase() &&
            filterKey === FilterKeys.ProductComment &&
            assortmentType === BuyingSessionAssortmentType.door)
        ) {
          return
        }
        wsFilterFields.push({
          [filterKey]: {
            name: filterKey,
            label: FilterDisplayNames[filterKey],
            data: [
              {
                displayName: FilterDisplayNames[filterKey],
                type: AttributeValueType.BOOLEAN,
                hasFixedValues: true,
                key: filterKey,
                values: [
                  {
                    label: strings.filterOptionsLabels[filterKey].with,
                    value: true
                  },
                  {
                    label: strings.filterOptionsLabels[filterKey].without,
                    value: false
                  }
                ]
              }
            ]
          }
        })
      }
    )
    // Non boolean filters
    forEach(
      [
        FilterKeys.Channel,
        FilterKeys.Drop,
        FilterKeys.Looks,
        FilterKeys.Cluster,
        FilterKeys.FreeTags
      ],
      filterKey => {
        const filterOptionsLength = (filterObject[filterKey] ?? []).length
        const isClusterOrFreeTag =
          filterKey === FilterKeys.Channel || filterKey === FilterKeys.FreeTags
        if ((!isClusterOrFreeTag && filterOptionsLength > 0) || filterOptionsLength > 1) {
          wsFilterFields.push({
            [filterKey]: {
              name: filterKey,
              label: FilterDisplayNames[filterKey],
              data: [
                {
                  displayName: FilterDisplayNames[filterKey],
                  type:
                    filterKey === FilterKeys.Channel
                      ? AttributeValueType.STRING
                      : filterKey === FilterKeys.Drop
                      ? AttributeValueType.REF
                      : AttributeValueType.REFLIST,
                  hasFixedValues: filterKey === FilterKeys.Channel,
                  isCollapsible: true,
                  key: filterKey,
                  values: filterObject[filterKey] ?? []
                }
              ]
            }
          })
        }
      }
    )
    return wsFilterFields
  }

  buildVIPAttributesFilter = (
    vipProductAttributesForFilter,
    vipProduct,
    vipAttributes: IFilter[]
  ) => {
    forEach(vipProductAttributesForFilter, (attribute: ProductAttributeConf) => {
      let vipAttribute = find(
        vipAttributes,
        vipAttribute => vipAttribute.key === attribute.attributeName
      )
      if (!vipAttribute) {
        vipAttribute = {
          key: attribute.attributeName,
          displayName: attribute.displayName,
          type: attribute.valueType,
          isCollapsible: true,
          values: []
        }
        vipAttributes.push(vipAttribute)
      }
      switch (attribute.valueType) {
        case AttributeValueType.STRING:
        case AttributeValueType.ENUM:
          if (
            vipProduct[attribute.attributeName] &&
            !find(
              vipAttribute.values,
              value =>
                value.value === vipProduct[attribute.attributeName] ||
                (vipProduct[attribute.attributeName]?.split(':')?.length === 2 &&
                  value.value === vipProduct[attribute.attributeName]?.split(':')[1])
            )
          ) {
            vipAttribute.values.push({
              label:
                vipProduct[attribute.attributeName]?.split(':')?.length === 2
                  ? vipProduct[attribute.attributeName]?.split(':')[1] || 'NA'
                  : vipProduct[attribute.attributeName],
              value: vipProduct[attribute.attributeName]
            })
          }
          break

        case AttributeValueType.INTEGER:
          if (
            String(vipProduct[attribute.attributeName]) &&
            !find(
              vipAttribute.values,
              value => value.value === vipProduct[attribute.attributeName]
            )
          ) {
            vipAttribute.values.push({
              label: String(vipProduct[attribute.attributeName]).toUpperCase(),
              value: vipProduct[attribute.attributeName]
            })
          }
          break

        case AttributeValueType.REF:
          vipAttribute.pathToValue = attribute.pathToValue
          if (
            vipProduct[attribute.attributeName]?.[attribute.pathToValue] &&
            !find(
              vipAttribute.values,
              value =>
                value.value === vipProduct[attribute.attributeName][attribute.pathToValue]
            )
          ) {
            vipAttribute.values.push({
              label: vipProduct[attribute.attributeName][attribute.pathToValue],
              value: vipProduct[attribute.attributeName][attribute.pathToValue]
            })
          }
          break
      }
    })
    return vipAttributes
  }

  @action onFilterCheck =
    (
      filterField: IFilter,
      filter: IFilterValue,
      isVIPProductAttribute: boolean = false
    ) =>
    e => {
      const filterFieldKey = filterField.key
      const wsSelectedFilters = cloneDeep(this.wsSelectedFilters)
      if (!wsSelectedFilters[filterFieldKey]) {
        wsSelectedFilters[filterFieldKey] = {
          values: [],
          isVIPProductAttribute,
          type: filterField.type,
          pathToValue: filterField.pathToValue
        }
      }

      const alreadyCheckedFilterIndex = findIndex(
        wsSelectedFilters[filterFieldKey].values,
        selectedFilter => selectedFilter.value === filter.value
      )

      if (alreadyCheckedFilterIndex !== -1) {
        wsSelectedFilters[filterFieldKey].values.splice(alreadyCheckedFilterIndex, 1)
        if (isEmpty(wsSelectedFilters[filterFieldKey].values)) {
          delete wsSelectedFilters[filterFieldKey]
        }
      } else {
        wsSelectedFilters[filterFieldKey].values.push(filter)
      }

      Object.assign(this, { wsSelectedFilters })
    }

  isFilterChecked = (filterKey, filter) => {
    return some(
      this.wsSelectedFilters?.[filterKey]?.values ?? [],
      filterValue => filterValue.value === filter.value
    )
  }
  @action handleTabChange = (e, value) => {
    this.selectedFilterTab = value
  }
}

export const filterModalStore = new FilterModalStore()
