import React from 'react'
import { IconContext } from '@meronex/icons'
import FaHeart from '@meronex/icons/fa/FaHeart'

interface IHeartContrastOutlineProps {
  size?: number
  height?: number
  width?: number
  strokeColor?: string
  fillColor?: string
}

const HeartContrastOutline: React.FC<IHeartContrastOutlineProps> = ({
  size = 24,
  height = 24,
  width = 26,
  strokeColor = '#ffffff',
  fillColor = '#000000'
}): JSX.Element => {
  return (
    <IconContext.Provider value={{ style: { strokeWidth: 50 } }}>
      <div>
        <FaHeart
          size={size}
          style={{ width: width, height: height, fill: fillColor, stroke: strokeColor }}
        />
      </div>
    </IconContext.Provider>
  )
}

export default HeartContrastOutline
