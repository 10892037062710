import { ListViewColumnDefinition } from '@components/UI-Components/StandardTableView/models/ListviewColumnDefinition'
import { WholesaleGridCard } from '@components/UI-Components/WholesaleGridCard'
import { config } from '@config'
import Divider from '@material-ui/core/Divider'
import { ProductContainerRow } from '@modules/collection/components/ProductContainerRow'
import { CardSkeleton } from '@modules/common/component/CardSkeleton'
import { ListRowSkeleton } from '@modules/common/component/ListRowSkeleton'
import { useBuyingSessionGroup } from '@modules/wholesale/buyingGroupSummary/graphql/hooks'
import {
  useBuyingSessionProduct,
  useProductEnumList
} from '@modules/wholesale/buyingSessionGroupProductWrapper/graphql/hooks'
import { productForecastUIStore } from '@modules/wholesale/buyingSessionGroupProductWrapper/stores/productForecastUIStore'
import { getDateWithoutTime, getEmptyArrayOfLength } from '@services/commonServices'
import { wholeSaleProductService } from '@services/wholesale/productService'
import { isZAMUserInPrebuyingSession } from '@services/wholesale/wholesaleBuyingSessionService'
import { usePricesForBuyingSessionProducts } from '@services/wholesale/wholesalePriceService'
import { hasUserRoles, PERMISSION_MAP } from '@services/userRoleService'
import { stores, strings } from '@stores'
import classNames from 'classnames'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import { useObserver } from 'mobx-react-lite'
import React, { useMemo } from 'react'
import { StandardTreeViewStore } from '@components/UI-Components/StandardTreeView/Store/TreeViewStore'
import { ACTIVITY_HIERARCHY_MAP, WHOLESALEPRODUCTROWHEIGHT } from '@constants'
import { BuyingSessionGroupNavView } from '@modules/common/models/enums/BSGroupAndBSNav'
import { VIPChannel } from '@modules/common/models/enums/VIPChannel'
import ClusterStatusDropdown from '@modules/wholesale/assortmentProductWrapper/components/ClusterStatusDropDown'
import { useQueryGetClusterStatus } from '@modules/wholesale/clusterSetting/graphql/getClusterStatus'
import { ViewTabs } from '@modules/common/models/enums'
import { isRetailChannel } from '@services/wholesale/wholesaleBuyingSessionGroupsServices'
import isEqual from 'lodash/isEqual'
import isString from 'lodash/isString'
import { counterOfferUIStore } from '../stores/CounterOffer/CounterOfferUIStore'
import * as css from './CollectionProductRowWrapper.css'
import { useRetailZones } from '@modules/wholesale/wsNavigations/graphql/hooks'

export const zoneRowHeight = 25.6

interface ICollectionProductRowWrapper {
  style?: any
  node?: any
  stickyStyle?: any
  listHeaders?: Array<ListViewColumnDefinition>
  canTableFitInView?: boolean
}

export const CollectionProductRowWrapper: React.FunctionComponent<ICollectionProductRowWrapper> =
  ({ node, style, stickyStyle, listHeaders, canTableFitInView }) => {
    return useObserver(function useHook() {
      /**
       * Read activity, childrenMeta (hierarhcy levels: family -> line / family / line) and chilrenMetaValues (name of family / line) from node
       */
      const { category: activity, childrenMeta, childrenMetaValues, categoryIds } = node
      const {
        wsBuyingSessionProduct: {
          whereInputForBSPForGroup,
          whereInputForBSPForBS,
          handleTagDrop,
          handleListViewTagDrop,
          favoriteClickHandler,
          bulkOpSelectedProducts,
          mouseDownHandlerForBSProducts,
          rightClickHandlerForBulkOp,
          filterProductsBasedOnFilterAndSearch,
          fetchWHLBuyProducts,
          retailBuyProducts,
          retailRMClusters,
          fetchRetailBuyProducts,
          retailBuyComments
        },
        nav: {
          buyingSession,
          queryParams: { productLayout, tab, buyingSessionGroupId, subview },
          onProductDetailClick
        },
        search: { searchKey },
        filterModalStore: { wsAppliedFilters }
      } = stores
      const { init, productForecastData, upsertForecastData, targetCurrency } =
        productForecastUIStore
      const { selectedNavItems } = StandardTreeViewStore
      const { setCounterOfferData, rmClustersData } = counterOfferUIStore

      // create mock array
      const mockProducts = getEmptyArrayOfLength(node.products)

      // Query buying session group in order to get collection id
      const { data: buyingSessionGroupData, loading: buyingSessionGroupRequestLoading } =
        useBuyingSessionGroup(buyingSessionGroupId)
      // const currentBuyingSession = find(
      //   buyingSessionGroupData?.buyingSessionGroup?.Sessions,
      //   session => session?.Id === buyingSession
      // )

      // Build where input for buying session product and order product queries
      const whereQueryObject = buyingSession
        ? whereInputForBSPForBS
        : whereInputForBSPForGroup

      const queryVariableObject = whereQueryObject(
        activity,
        childrenMeta,
        childrenMetaValues
      )

      /**
       * Query:
       * 1. Buying session products -> Actual products to be rendered in view
       * 2. EnumList -> For resolving enum attributes
       */
      const [
        { data, loading },
        { data: productEnumList, loading: enumLoading },
        { items: retailZones, loading: retailZonesLoading }
      ] = [
        useBuyingSessionProduct(queryVariableObject, 'cache-and-network'),
        useProductEnumList(),
        useRetailZones('cache-and-network')
      ]

      React.useEffect(() => {
        if (productLayout === 'list') {
          let categoryObj: any = {
            familyId: categoryIds?.[1],
            lineId: categoryIds?.[2]
          }
          if (isEqual(ACTIVITY_HIERARCHY_MAP.SHOES, childrenMeta)) {
            categoryObj = {
              lineId: categoryIds?.[1]
            }
          }
          const initCategoryValues = selectedNavItems?.some(navItem => {
            if (!isString(navItem)) return false
            const items = navItem?.split(':')
            return (
              items?.[0] === activity &&
              childrenMetaValues?.some(
                child => items?.[1] === child || items?.[2] === child
              )
            )
          })

          init(
            {
              buyingSessionGroupId,
              buyingSessionId: buyingSession,
              activityId: categoryIds?.[0],
              ...categoryObj,
              categoryValues: [activity, ...(childrenMetaValues || [])]
            },
            initCategoryValues,
            true
          )
          fetchWHLBuyProducts(buyingSessionGroupId, buyingSession)
          fetchRetailBuyProducts(buyingSessionGroupId, buyingSession)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [
        buyingSessionGroupId,
        buyingSession,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        JSON.stringify(categoryIds),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        JSON.stringify(selectedNavItems),
        productLayout
      ])

      React.useEffect(() => {
        if (
          BuyingSessionGroupNavView.Overview !== subview &&
          hasUserRoles(
            PERMISSION_MAP.find(ele => VIPChannel.Retail === ele.id).allowedRoles
          )
        )
          setCounterOfferData(buyingSessionGroupId, buyingSession)
      }, [buyingSessionGroupId, buyingSession, setCounterOfferData, subview])

      const isGridView = productLayout === strings.grid
      const products = filterProductsBasedOnFilterAndSearch(
        data?.findVIPBuyingSessionProduct?.Items ?? [],
        searchKey,
        wsAppliedFilters
      )
      const productEnums = productEnumList?.findEnumList?.Items ?? []

      /**
       * Call hook for fetching price of products
       */
      const bsgDate = buyingSessionGroupData?.buyingSessionGroup?.BSGDate
      const buyingSessionGroupDate = getDateWithoutTime(bsgDate)
      let { data: priceData } = usePricesForBuyingSessionProducts(
        products,
        buyingSessionGroupDate,
        null,
        buyingSessionGroupRequestLoading || (loading && isEmpty(data)) // Skip until BSG and product data is fetched
      )

      const { items: retailClusterStatuses = [] } = useQueryGetClusterStatus(
        isRetailChannel(buyingSessionGroupData?.buyingSessionGroup?.channels)
          ? VIPChannel.Retail
          : null
      )

      //Calculated custom product row height for Retail buy zones
      const customRowHeight = useMemo(
        () => {
          if (tab === ViewTabs.RTL_BUY.toLowerCase() && retailZones?.length) {
            const rowHeight = retailZones?.length * zoneRowHeight
            return rowHeight > WHOLESALEPRODUCTROWHEIGHT ? rowHeight : null
          }
          return null
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [retailZones, tab]
      )

      return (
        <div style={style} className={classNames({ [css.gridRoot]: isGridView })}>
          {isGridView ? (
            (loading || enumLoading || retailZonesLoading) && isEmpty(data) ? (
              map(mockProducts, (product, index) => (
                <CardSkeleton key={'cardSkeleton' + index} />
              ))
            ) : (
              map(
                wholeSaleProductService.processProductsForGridView(
                  products,
                  productEnums,
                  priceData,
                  null,
                  buyingSessionGroupData?.buyingSessionGroup?.channels
                ),
                product => (
                  <WholesaleGridCard
                    product={product}
                    key={product.productId}
                    handleTagDrop={handleTagDrop}
                    isSelectedForBulkOp={
                      !!find(
                        bulkOpSelectedProducts,
                        selectedProductId => selectedProductId === product.productId
                      )
                    }
                    mouseDownHandler={mouseDownHandlerForBSProducts(product)}
                    rightClickHandler={rightClickHandlerForBulkOp}
                    favoriteClickHandler={favoriteClickHandler(product.productId)}
                    clickHandler={onProductDetailClick(product.node.Id)}
                    isZAMUserInPrebuyingSession={isZAMUserInPrebuyingSession(
                      product.buyingSession
                    )}
                  />
                )
              )
            )
          ) : (loading || enumLoading) && isEmpty(data) ? (
            map(mockProducts, (product, index) => (
              <React.Fragment key={'ListRowSkeleton' + index}>
                <ListRowSkeleton />
                <Divider />
              </React.Fragment>
            ))
          ) : (
            <ProductContainerRow
              isWholesale={true}
              headers={listHeaders}
              handleDrop={handleListViewTagDrop}
              stickyStyle={stickyStyle}
              canTableFitInView={canTableFitInView}
              products={wholeSaleProductService.processProductsForListViewData(
                products,
                retailBuyProducts,
                retailBuyComments,
                retailRMClusters,
                productEnums,
                tab as any,
                priceData,
                '',
                {
                  targetCurrency: config.globalCurrency
                } as any,
                { productForecastData, upsertForecastData, targetCurrency },
                rmClustersData
              )}
              productRowHeight={customRowHeight}
            />
          )}
          <ClusterStatusDropdown clusterStatuses={retailClusterStatuses} />
        </div>
      )
    })
  }
