import { QueryResult } from '@apollo/client/react'
import { Query } from '@apollo/client/react/components/Query'
import { CentricTheme, StyledProps } from '@components/3rd-party/mui'
import { AssortmentToolbar } from '@components/UI-Components/Assortment/AssortmentToolbar'
import { FilterChips } from '@components/UI-Components/FilterChips'
import { PrimaryButton } from '@components/UI-Components/PrimaryButton'
import { ProductCard } from '@components/UI-Components/ProductCardOld/ProductCard'
import { ProductDetail } from '@components/UI-Components/ProductCardOld/ProductDetails'
import { ProductInfo } from '@components/UI-Components/ProductCardOld/ProductInfo'
import { ProductInfoItem } from '@components/UI-Components/ProductCardOld/ProductInfoItem'
import { ProductMedia } from '@components/UI-Components/ProductCardOld/ProductMedia'
import { SecondaryButton } from '@components/UI-Components/SecondaryButton'
import { User } from '@models'
import { CollectionFilterContainer } from '@modules/retail/collection/container/CollectionFilter'
import Checkbox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress'
import Drawer from '@material-ui/core/Drawer'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { IPermissions } from '@routes'
import { getFilterChipsData, getProductDetails } from '@services/collectionServices'
import { getImageUrl } from '@services/imageService'
import { storage } from '@services/storageService'
import { stores, strings } from '@stores'
import some from 'lodash/some'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import React from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { withRouter } from 'react-router-dom'
import { GET_PRODUCTS_TO_ADD_IN_ASSORTMENT } from '../graphql/getBuyingSessionProducts'
import { GET_ZONEASSORTMENT_PRODUCT_FILTERS } from '../graphql/getZoneAssortmentProductsFilter'
import { AssortmentTitleBar } from './AssortmentTitleBar'
import uniqWith from 'lodash/uniqWith'
import { UserRoles } from '@modules/common/models/enums/UserRoles'

const HEIGHT = 70

const styles = (theme: CentricTheme) => ({
  container: {
    justifyContent: 'flex-start',
    alignItems: 'left',
    flexDirection: 'column',
    width: '64vw',
    maxWidth: 1024,
    paddingLeft: theme.spacing(3)
  },
  drawerHeader: {
    margin: '0px'
  },
  productGrid: {
    flexDirection: 'row',
    display: 'flex',
    flexWrap: 'wrap'
  },
  noProductsWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  productCard: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  button: {
    margin: theme.spacing(1)
  },
  productContainer: {
    height: `calc(100vh - ${HEIGHT}px)`,
    overflow: 'auto',
    paddingTop: '10px'
  },
  filterChips: {
    display: 'flex',
    alignItems: 'baseline'
  },
  buttonProgress: {
    color: theme.colors.black,
    top: '35px',
    right: '55px',
    position: 'absolute'
  },
  doneButton: {
    marginLeft: theme.spacing(2)
  },
  loader: { display: 'flex', justifyContent: 'center' }
})

interface IAddAssortmentProductsDrawer extends StyledProps {
  handleChange?: (event, id, product?: any) => void
  toogleDrawer?: () => void
  drawerOpen?: boolean
  bulkAddProductsToAssortment?: () => void
  productList?: any
  buyingSessionId?: any
  assortmentId?: any
  match?: any
  permissions?: IPermissions
}
@(withRouter as any)
@((withStyles as any)(styles))
@observer
export class AddAssortmentProductsDrawer extends React.Component<IAddAssortmentProductsDrawer> {
  @observable isLoading = false

  private scrollParentRef = React.createRef<HTMLDivElement>()

  componentDidMount = () => {
    const {
      filter: { clearAllFilters },
      search: { clearSearchKey }
    } = stores
    clearAllFilters()
    clearSearchKey()
  }

  componentWillUnmount = () => {
    const {
      search: { clearSearchKey },
      filter: { clearAllFilters }
    } = stores
    clearAllFilters()
    clearSearchKey()
  }

  loadFunc = async (fetchMore, skipCount) => {
    if (this.isLoading) {
      return false
    }
    this.isLoading = true
    const {
      assortment: { changeLoadingStatus }
    } = stores

    fetchMore({
      variables: {
        skipCount
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        if (fetchMoreResult.buyingSessionProductsConnection.edges.length < 20) {
          changeLoadingStatus(false)
        }

        const newEdges = fetchMoreResult.buyingSessionProductsConnection.edges
        const edges = uniqWith(
          [...prev.buyingSessionProductsConnection.edges, ...newEdges],
          node => node.id
        )

        return newEdges.length
          ? {
              buyingSessionProductsConnection: {
                __typename: prev.buyingSessionProductsConnection.__typename,
                edges,
                pageInfo: fetchMoreResult.buyingSessionProductsConnection.pageInfo
              }
            }
          : prev
      }
    }).then(() => {
      this.isLoading = false
    })
  }

  static defaultProps: any = {
    open: false,
    onClose: () => {}
  }

  render() {
    const [
      {
        props: {
          classes,
          handleChange,
          toogleDrawer,
          drawerOpen,
          bulkAddProductsToAssortment,
          productList,
          assortmentId,
          buyingSessionId,
          match,
          match: {
            params: { clusterId }
          },
          permissions
        },
        loadFunc
      },
      {
        assortment: {
          getAssortmentType,
          prepareAddProductSearchWhere,
          hasMore,
          getRetailPrice,
          bulkAdditionAPIFlag
        },
        search: { searchKey },
        filter: {
          toggleCollectionFilter,
          productWhereInput,
          finalFilterToApply,
          clearAllFilters,
          buyingSessionLevelFilter
        },
        site: { isGlobalMerchandiserOrAdmin }
      }
    ] = [this, stores]
    const user = storage.getItem<User>('user')
    const isRegionalMerchandiser = some(user.roles, {
      name: UserRoles.REGIONAL_MERCHANDISER
    })
    const assortmentType = getAssortmentType(match)
    const coreFilters: any = buyingSessionLevelFilter

    const variables = {
      where: prepareAddProductSearchWhere(
        buyingSessionId,
        assortmentId,
        assortmentType,
        searchKey,
        productWhereInput,
        coreFilters
      ),
      includeZoneAssortmentProduct: isRegionalMerchandiser && clusterId ? true : false,
      skipCount: 0,
      ...(isRegionalMerchandiser &&
        clusterId && {
          zoneAssortmentId: assortmentId
        })
    }

    const { chipsArrayToDisplay, chipsArrayInDropDown } =
      getFilterChipsData(finalFilterToApply)
    const isGMOrAdmin = !!isGlobalMerchandiserOrAdmin()

    return (
      <Drawer
        anchor='right'
        open={drawerOpen}
        onClose={toogleDrawer}
        classes={{ paper: classes.paper }}
      >
        {toggleCollectionFilter && drawerOpen ? (
          <CollectionFilterContainer
            permissions={permissions}
            filterQuery={
              assortmentType === strings.zone && clusterId
                ? GET_ZONEASSORTMENT_PRODUCT_FILTERS
                : undefined
            }
            variables={
              assortmentType === strings.zone && clusterId
                ? {
                    assortmentId,
                    buyerClusters_none: {
                      id: clusterId
                    }
                  }
                : {
                    buyingsessionWhereInput: {
                      id: buyingSessionId
                    },
                    assortmentId
                  }
            }
          />
        ) : null}
        <div className={classes.container} tabIndex={0} role='button'>
          <div className={classes.drawerHeader}>
            <AssortmentTitleBar
              title={'Library'}
              hideAssortmentTabs={true}
              actions={
                <>
                  <AssortmentToolbar hideRightMenu={true} />
                  <SecondaryButton onClick={toogleDrawer}>Cancel</SecondaryButton>
                  <PrimaryButton
                    className={classes.doneButton}
                    onClick={bulkAddProductsToAssortment}
                    loading={bulkAdditionAPIFlag}
                    disabled={!productList.length || bulkAdditionAPIFlag}
                  >
                    Done
                  </PrimaryButton>
                </>
              }
            />
            <div className={classes.filterChips}>
              <FilterChips />
              {chipsArrayToDisplay.length || chipsArrayInDropDown.length ? (
                <SecondaryButton onClick={() => clearAllFilters()}>
                  <Typography variant='body1'>CLEAR ALL</Typography>
                </SecondaryButton>
              ) : null}
            </div>
          </div>
          {/* {bulkAdditionAPIFlag ? <CircularProgress /> : null} */}
          <Query
            query={GET_PRODUCTS_TO_ADD_IN_ASSORTMENT}
            fetchPolicy='network-only'
            variables={{ ...variables }}
            notifyOnNetworkStatusChange
          >
            {({ loading, error, data, fetchMore }: QueryResult) => {
              if (error) return <div>Error</div>
              return (
                <React.Fragment>
                  <div className={classes.productContainer} ref={this.scrollParentRef}>
                    <InfiniteScroll
                      pageStart={0}
                      initialLoad={false}
                      loadMore={() =>
                        loadFunc(
                          fetchMore,
                          data && data.buyingSessionProductsConnection
                            ? data.buyingSessionProductsConnection.edges.length
                            : 0
                        )
                      }
                      hasMore={hasMore}
                      loader={
                        loading ? (
                          <div key='add-product-loader' className={classes.loader}>
                            <CircularProgress />
                          </div>
                        ) : null
                      }
                      useWindow={false}
                    >
                      <div className={classes.productGrid}>
                        {data &&
                        data.buyingSessionProductsConnection &&
                        data.buyingSessionProductsConnection.edges &&
                        data.buyingSessionProductsConnection.edges.length > 0
                          ? data.buyingSessionProductsConnection.edges.map(
                              ({ node: product }, index) => {
                                const {
                                  active,
                                  mmcName,
                                  colorName,
                                  description,
                                  image,
                                  id,
                                  productRetailStatus,
                                  retailPrice,
                                  zoneRetailPrice
                                } = getProductDetails(product)
                                return (
                                  <div className={classes.productCard} key={product.id}>
                                    {
                                      <ProductCard style={{ margin: 5 }} active={active}>
                                        <Checkbox
                                          key={`addProductCheckBox_${product.id}`}
                                          color='default'
                                          onChange={e => handleChange(e, id, product)}
                                        />
                                        <ProductMedia image={getImageUrl(image)} />
                                        <ProductDetail>
                                          <ProductInfo
                                            status={productRetailStatus || ''}
                                            name={description}
                                            sku={mmcName}
                                          />
                                          <ProductInfoItem
                                            title={'Color'}
                                            content={
                                              <div
                                                className={classes.productWithColorbox}
                                              >
                                                <Typography variant='caption'>
                                                  {colorName ? colorName : 'NA'}
                                                </Typography>
                                              </div>
                                            }
                                          />
                                          <ProductInfoItem
                                            title={'Retail Price'}
                                            content={
                                              <Typography variant='caption'>
                                                {assortmentType === strings.store
                                                  ? 'NA'
                                                  : getRetailPrice(
                                                      isGMOrAdmin
                                                        ? retailPrice
                                                        : zoneRetailPrice
                                                    )}
                                              </Typography>
                                            }
                                          />
                                        </ProductDetail>
                                      </ProductCard>
                                    }
                                  </div>
                                )
                              }
                            )
                          : !loading && (
                              <div className={classes.noProductsWrapper}>
                                <Typography variant='h5'>
                                  No Products Available.
                                </Typography>
                              </div>
                            )}
                      </div>
                    </InfiniteScroll>
                  </div>
                </React.Fragment>
              )
            }}
          </Query>
        </div>
      </Drawer>
    )
  }
}
