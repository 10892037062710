export enum UserNotificationEntityType {
  BuyingSessionProduct = 'VIPEntityType:VIPBuyingSessionProduct',
  OrderProduct = 'VIPEntityType:VIPOrderProduct',
  BuyingSessionGroup = 'VIPEntityType:VIPBuyingSessionGroup'
}

export enum UserNotificationProducts {
  BuyingSessionProduct = 'buyingSessionProduct',
  BuyingSessionGroup = 'buyingSessionGroup',
  OrderProduct = 'orderProduct'
}
