import Typography from '@material-ui/core/Typography'
import { strings } from '@stores'
import React from 'react'
import * as css from './Error.css'

export const ErrorContainer = props => {
  return (
    <div className={css.message}>
      <Typography variant='subtitle1'>{strings.commonErrorMessage}</Typography>
    </div>
  )
}
