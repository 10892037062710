import { StyledProps } from '@components/3rd-party/mui'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import React from 'react'

const styles = theme => ({
  container: {
    padding: `5px 10px 5px 10px`
  },
  titleText: {
    fontFamily: 'Roboto',
    fontSize: 10,
    fontWeight: 'normal' as 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 1.5,
    color: 'rgba(0, 0, 0, 0.5)',
    wordWrap: 'break-word',
    margin: '5px 0px 5px 0px',
    textTransform: 'uppercase' as 'uppercase'
  },
  contentText: {
    fontFamily: 'Roboto',
    fontSize: 12,
    fontWeight: 'bold' as 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.4,
    color: '#000000'
  },
  skuStyle: {
    width: 184,
    height: 14,
    fontFamily: 'Roboto',
    fontSize: 12,
    fontWeight: 'normal' as 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    color: '#000000',
    marginTop: 5
  }
})

interface IProductInfoItem extends StyledProps {
  style?: any
  title?: string
  sku?: string
  content?: any
  className?: any
  contentTextStyle?: any
  titleTextStyle?: any
  showTooltip?: boolean
  productInfoContainerStyle?: object
}

@((withStyles as any)(styles))
export class ProductInfoItem extends React.Component<IProductInfoItem> {
  render() {
    const {
      props: {
        style,
        title,
        content,
        classes,
        className,
        showTooltip,
        contentTextStyle,
        titleTextStyle,
        productInfoContainerStyle,
        sku
      }
    } = this
    return (
      <div
        className={classNames(
          productInfoContainerStyle ? productInfoContainerStyle : classes.container,
          className
        )}
        style={style}
      >
        {title ? (
          <Typography
            display='block'
            component={'span'}
            className={titleTextStyle ? titleTextStyle : classes.titleText}
          >
            {title}
          </Typography>
        ) : null}
        <Tooltip disableHoverListener={!showTooltip} title={content || ''}>
          <Typography
            display='block'
            component={'span'}
            className={contentTextStyle ? contentTextStyle : classes.contentText}
          >
            {content}
          </Typography>
        </Tooltip>
        {sku ? (
          <Typography display='block' component={'span'} className={classes.skuStyle}>
            {sku}
          </Typography>
        ) : null}
      </div>
    )
  }
}
