import { ApolloClient } from '@apollo/client/core/ApolloClient'
import { QueryResult } from '@apollo/client/react'
import { Query } from '@apollo/client/react/components/Query'
import { withApollo } from '@apollo/client/react/hoc/withApollo'
import { CentricTheme, StyledProps } from '@components/3rd-party/mui'
import {
  BuyingSessionActions,
  BuyingSessionCard,
  BuyingSessionInfo
} from '@components/UI-Components/BuyingSessionCard'
import { BuyingSessionErrorDialog } from '@components/UI-Components/BuyingSessionErrorDialog'
import { ConfirmationDialog } from '@components/UI-Components/ConfirmationDialog'
import { LoadingIndicator } from '@components/UI-Components/LoadingIndicator'
import { PrimaryButton } from '@components/UI-Components/PrimaryButton'
import { SmallColorBox } from '@components/UI-Components/SmallColorBox'
import { Icons } from '@icons'
import { User } from '@models'
import { UserRoles } from '@modules/common/models/enums/UserRoles'
import { GET_SEASONS } from '@modules/retail/navigations/graphql'
import Icon from '@material-ui/core/Icon'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { Paper } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { getHighestUser, IPermissions } from '@routes'
import { getBuyingSessionName } from '@services/buyingSessionServices'
import { getUserFriendlyErrors } from '@services/errorService'
import { storage } from '@services/storageService'
import { apolloClient, stores, strings } from '@stores'
import { nav } from '@stores/nav'
import classNames from 'classnames'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import React from 'react'
import { withRouter } from 'react-router-dom'

import { BUYING_SESSION_STATUSES, constants } from '../constants'
import { CreateSessionComponent } from '../container/CreateBuyingSession'
import { DeleteBuyingSession } from '../container/DeleteBuyingSession'
import { GET_BUYINGSESSIONS } from '../graphql/buyingsessions'
import { UPDATE_BUYING_SESSION_STATUS } from '../graphql/UpdateBuyingSessionStatus'
import { UPDATE_LAST_ACCESSED_BUYING_SESSION } from '../graphql/UpdateLastAccessedBuyingSession'
import { VALIDATE_BUYING_SESSION_BEFORE_CHANGING_STATUS } from '../graphql/validateBuyingSessionBeforeChangingStatus'
import * as css from './BuyingSessionList.css'
import { BuyingSessionToolBar } from './BuyingSessionToolBar'

const styles = (theme: CentricTheme) => {
  return {
    wrapper: {
      display: 'flex'
    },
    tabContainer: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper
    },
    tabsRoot: {
      borderBottom: '1px solid #e8e8e8'
    },
    tabsIndicator: {
      backgroundColor: theme.colors.black
    },
    typography: {
      padding: theme.spacing(3)
    },
    tableCellWithIcon: {
      display: 'flex',
      alignItems: 'center'
    },
    tableCellText: {
      marginRight: theme.spacing(1)
    },
    createButtonIcon: {
      marginRight: theme.spacing(1)
    },
    popOver: {
      width: 153,
      fontSize: 14
    },
    popOverMenu: {
      fontSize: '14px'
    },
    cursor: {
      cursor: 'pointer'
    },
    statusButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: theme.colors.yellow,
      color: theme.colors.white,
      height: 24,
      width: 120,
      paddingLeft: theme.spacing(1) / 2,
      fontSize: 10
    },
    smallColorBox: {
      marginRight: 12
    },
    noBuyingSessionPaper: {
      padding: 44,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    noBuyingSessionBox: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 400
    },
    message: {
      marginBottom: 15
    },
    addBuyingSessionBox: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 48,
      height: 48,
      borderRadius: 2,
      backgroundColor: theme.colors.midgrey
    }
  }
}

// const getStatusValues = status => {
//   const [{ color, value }] = BUYING_SESSION_STATUSES.filter(({ name }) => name === status)

//   return { color, value }
// }

const getStatusColor = status => {
  const [{ color }] = BUYING_SESSION_STATUSES.filter(({ name }) => name === status)
  return color
}

interface MyProps extends StyledProps {
  buyingSessions?: any
  client?: ApolloClient<any>
  history?: any
  match?: any
  location?: any
  permissions?: IPermissions
}

@((withStyles as any)(styles))
@(withApollo as any)
@(withRouter as any)
@observer
export class BuyingSessionList extends React.Component<MyProps> {
  static defaultProps = {
    headerLabel: 'Givenchy',
    keepMeLoginCheckbox: false
  }
  state = {
    tabValue: 0
  }
  @observable openBSStatusChangeConfirmationDialog = false
  buyingSessionNewStatus = ''
  buyingSessionOldStatus = ''
  selectedBuyingSessionId = ''

  handleChange = (event, value) => {
    const {
      props: { history, match }
    } = this

    history.push({
      pathname: `/buyingSession/${match.params.seasonId}/gender/${value}`,
      search: history.location.search
    })
    this.setState({ tabValue: value })
  }
  getBSStatusChangeConfirmationMsg = () =>
    `Are you sure you want to switch to ${this.buyingSessionNewStatus}? It won't be possible to go back to ${this.buyingSessionOldStatus}`
  validateAndConfirmBuyingSessionStatusChange = async (
    buyingSessionNewStatus,
    buyingSessionOldStatus,
    selectedBuyingSessionId
  ) => {
    this.buyingSessionNewStatus = buyingSessionNewStatus
    this.selectedBuyingSessionId = selectedBuyingSessionId
    this.buyingSessionOldStatus = buyingSessionOldStatus
    const {
      buyingSession: { setBuyingSessionErrors, setBuyingSessionErrorPopupFlag }
    } = stores
    let response
    try {
      response = await apolloClient.query({
        query: VALIDATE_BUYING_SESSION_BEFORE_CHANGING_STATUS,
        variables: {
          buyingSessionStatus: buyingSessionNewStatus,
          buyingSessionId: selectedBuyingSessionId
        }
      })
    } catch (e) {
      const userFriendlyErrorsObj = getUserFriendlyErrors(e)
      if (
        userFriendlyErrorsObj &&
        userFriendlyErrorsObj.code === strings.buyingSessionErrors
      ) {
        setBuyingSessionErrors(userFriendlyErrorsObj.errors)
        setBuyingSessionErrorPopupFlag(true)
      }
    }
    if (
      response &&
      response.data &&
      response.data.validateBuyingSessionBeforeChangingStatus
    ) {
      if (
        this.isIrreversibleStatusChange(buyingSessionNewStatus, buyingSessionOldStatus)
      ) {
        this.openBSStatusChangeConfirmationDialog = true
      } else {
        this.updateBuyingSessionStatus()
      }
    }
  }

  isIrreversibleStatusChange = (buyingSessionNewStatus, buyingSessionOldStatus) =>
    (buyingSessionNewStatus === constants.CLOSED &&
      (buyingSessionOldStatus === constants.BUYING ||
        buyingSessionOldStatus === constants.PRE_BUYING ||
        buyingSessionOldStatus === constants.FROZEN)) ||
    (buyingSessionNewStatus === constants.BUYING &&
      buyingSessionOldStatus === constants.PRE_BUYING)

  handleConfirmationDialogClose = () => {
    this.openBSStatusChangeConfirmationDialog = false
  }
  updateBuyingSessionStatus = async () => {
    const {
      buyingSession: {
        handleClose,
        setBuyingSessionErrors,
        setBuyingSessionErrorPopupFlag
      }
    } = stores
    this.openBSStatusChangeConfirmationDialog = false
    try {
      await apolloClient.mutate({
        mutation: UPDATE_BUYING_SESSION_STATUS,
        variables: {
          status: this.buyingSessionNewStatus,
          buyingSessionId: this.selectedBuyingSessionId
        },
        refetchQueries: [
          {
            query: GET_SEASONS
          }
        ]
      })
    } catch (e) {
      const userFriendlyErrorsObj = getUserFriendlyErrors(e)
      if (
        userFriendlyErrorsObj &&
        userFriendlyErrorsObj.code === strings.buyingSessionErrors
      ) {
        setBuyingSessionErrors(userFriendlyErrorsObj.errors)
        setBuyingSessionErrorPopupFlag(true)
      }
    }
    handleClose()
  }

  closeErrorPopup = () => {
    const {
      buyingSession: { setBuyingSessionErrors, setBuyingSessionErrorPopupFlag }
    } = stores
    setBuyingSessionErrors([])
    setBuyingSessionErrorPopupFlag(false)
  }

  updateLastAccessedBuyingSession = buyingSessionId => {
    const {
      props: { client, history }
    } = this

    history.push({
      pathname: `/collections/buyingSessions/${buyingSessionId}`,
      search: history.location.search
    })

    const user = storage.getItem<User>('user')
    client.mutate({
      mutation: UPDATE_LAST_ACCESSED_BUYING_SESSION,
      variables: {
        buyingSessionId,
        userId: user.id
      }
    })
  }

  goToAssortment = (
    name: string,
    buyingSessionId: string,
    genderId: string,
    podiumId: string,
    activityId: string
  ): void => {
    const [
      {
        props: {
          history,
          match: {
            params: { seasonId }
          }
        }
      }
    ] = [this, stores]
    storage.setItem('selectedCollection', { name, podiumId })
    history.push({
      pathname: `/collections/season/${seasonId}/gender/${genderId}/buyingSession/${buyingSessionId}/podium/${podiumId}/activity/${activityId}/views/grid`
    })
  }

  onCollectionClick = (name, podiumId) => {
    const {
      buyingSession: { handlePopOverClose, popOveranchorEl }
    } = stores
    if (popOveranchorEl) {
      handlePopOverClose()
    }
    storage.setItem('selectedCollection', { name, podiumId })
  }

  render() {
    const [
      {
        props: {
          match: {
            params: { seasonId, genderId }
          },
          location,
          classes,
          permissions
        },
        updateBuyingSessionStatus,
        validateAndConfirmBuyingSessionStatusChange,
        handleConfirmationDialogClose,
        onCollectionClick,
        closeErrorPopup,
        openBSStatusChangeConfirmationDialog,
        getBSStatusChangeConfirmationMsg
      },
      {
        buyingSession: {
          anchorEl,
          createDialogOpen,
          deleteDialogOpen,
          closeDeleteDialog,
          selectedBuyingSession,
          selectedBuyingSessionName,
          handleDialogOpen,
          handleClick,
          handleClose,
          openEditDialog,
          selectedIndex,
          popOveranchorEl,
          handlePopOverClose,
          handelPopOverClick,
          selectedPopOverIndex,
          buyingSessionErrors,
          isOpenBuyingSessionErrorPopup
        }
      }
    ] = [this, stores]
    const loggedInUserRoles = getHighestUser()
    const isGM =
      loggedInUserRoles &&
      loggedInUserRoles.length &&
      loggedInUserRoles.find(role => role.name === UserRoles.CENTRAL_MERCHANDISER_RETAIL)
    const canEdit = permissions && permissions.canEdit
    const statues = cloneDeep(BUYING_SESSION_STATUSES).filter(
      status => status.name !== constants.FROZEN
    )

    return (
      <Query
        query={GET_BUYINGSESSIONS}
        fetchPolicy='network-only'
        variables={{ seasonId: seasonId, genderId: genderId }}
      >
        {({ loading, error, data, refetch }: QueryResult) => {
          //if (loading && !buyingSessionCollections) return (<div style={{ display: 'flex', justifyContent: 'center' }}><CircularLoading /></div>)
          if (loading && isEmpty(data)) return <LoadingIndicator />
          // if (error) return <ErrorContainer error={error}/>

          if (error) return <div>Error</div>
          const { buyingSessionCollections } = data
          const buyingSessionCollectionsWithBS = isGM
            ? buyingSessionCollections
            : buyingSessionCollections && buyingSessionCollections.length
            ? buyingSessionCollections.filter(
                buyingSessionCollection =>
                  buyingSessionCollection &&
                  buyingSessionCollection.buyingSessions &&
                  buyingSessionCollection.buyingSessions.length
              )
            : []
          return (
            <div className={css.root}>
              {buyingSessionCollectionsWithBS.length ? (
                buyingSessionCollectionsWithBS.map(
                  ({ id, name, buyingSessions, gender: { id: genderId } }, index) => {
                    const isCollectionClosed = buyingSessions.every(
                      bs => bs.status === constants.CLOSED
                    )
                    return (
                      <div className={css.buyingSessionBox} key={index}>
                        <BuyingSessionToolBar
                          onClick={() => {
                            onCollectionClick(name, id)
                          }}
                          reportLink={`/collections/season/${seasonId}/gender/${genderId}/podium/${id}/reports`}
                          podium={name}
                          link={`/collections/season/${seasonId}/gender/${genderId}/podium/${id}/views/grid`}
                          settingsLink={`/collections/season/${seasonId}/gender/${genderId}/podium/${id}/collectionSettings`}
                          isClosed={isCollectionClosed}
                          handlePopOverClose={handlePopOverClose}
                          handelPopOverClick={handelPopOverClick}
                          popOveranchorEl={popOveranchorEl}
                          selectedPopOverIndex={selectedPopOverIndex}
                          podiumId={id}
                          location={location}
                        />
                        <div className={css.sessionContainer}>
                          <div className={css.sessions}>
                            {buyingSessions.map((session, i) => (
                              <BuyingSessionCard
                                className={css.card}
                                href={nav.urlFor('assortment', {
                                  buyingSession: session.id,
                                  gender: genderId,
                                  activity: session.activity.description,
                                  podium: id,
                                  season: seasonId
                                })}
                                key={i}
                              >
                                <BuyingSessionInfo
                                  seasonName={session.collection.description}
                                  sessionName={getBuyingSessionName(
                                    session.gender,
                                    session.activity,
                                    session.collection
                                  )}
                                  quntityOfProducts={`${session.products.length} products`}
                                  onClick={() => {
                                    this.goToAssortment(
                                      name,
                                      session.id,
                                      genderId,
                                      id,
                                      session.activity.description
                                    )
                                  }}
                                />
                                <BuyingSessionActions>
                                  <Typography
                                    id='status'
                                    aria-owns={
                                      anchorEl && anchorEl.id === 'status'
                                        ? 'status-menu'
                                        : undefined
                                    }
                                    aria-haspopup='true'
                                    onClick={
                                      canEdit && session.status !== constants.CLOSED
                                        ? handleClick(session.id)
                                        : () => {}
                                    }
                                    className={classNames(classes.statusButton)}
                                    style={{ background: getStatusColor(session.status) }}
                                    variant='overline'
                                  >
                                    {session.status}
                                  </Typography>
                                  <Menu
                                    id='status-menu'
                                    anchorEl={anchorEl}
                                    open={Boolean(
                                      anchorEl &&
                                        anchorEl.id === 'status' &&
                                        selectedIndex === session.id
                                    )}
                                    onClose={handleClose}
                                    classes={{
                                      paper: classes.popOver
                                    }}
                                  >
                                    {statues.map(({ name, color }) => (
                                      <MenuItem
                                        key={name}
                                        classes={{
                                          root: classes.popOverMenu
                                        }}
                                        onClick={e => {
                                          e.stopPropagation()
                                          validateAndConfirmBuyingSessionStatusChange(
                                            name,
                                            session.status,
                                            session.id
                                          )
                                          handleClose()
                                        }}
                                      >
                                        <SmallColorBox
                                          color={color}
                                          className={classes.smallColorBox}
                                        />
                                        <Typography variant='caption'>{name}</Typography>
                                      </MenuItem>
                                    ))}
                                  </Menu>
                                  {permissions && permissions.canEdit ? (
                                    session.status === constants.FROZEN ? (
                                      <Tooltip title='Unfreeze Session'>
                                        <Icons
                                          iconname='unfreeze'
                                          onClick={e => {
                                            e.preventDefault()
                                            validateAndConfirmBuyingSessionStatusChange(
                                              constants.BUYING,
                                              session.status,
                                              session.id
                                            )
                                          }}
                                        ></Icons>
                                      </Tooltip>
                                    ) : (
                                      session.status === constants.BUYING && (
                                        <Tooltip title='Freeze Session'>
                                          <Icons
                                            iconname='freeze'
                                            onClick={e => {
                                              e.preventDefault()
                                              validateAndConfirmBuyingSessionStatusChange(
                                                constants.FROZEN,
                                                session.status,
                                                session.id
                                              )
                                            }}
                                          ></Icons>
                                        </Tooltip>
                                      )
                                    )
                                  ) : null}
                                </BuyingSessionActions>
                              </BuyingSessionCard>
                            ))}
                            {permissions && permissions.canEdit ? (
                              <div
                                className={css.addBuyingSession}
                                onClick={() => openEditDialog(id, name)}
                              >
                                <Icon className={css.icon}>add</Icon>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    )
                  }
                )
              ) : (
                <div className={classes.noBuyingSessionBox}>
                  <Paper className={classes.noBuyingSessionPaper}>
                    <div className={classes.message}>
                      <Typography variant='subtitle1'>
                        No buying sessions have created for this season.
                      </Typography>
                    </div>
                    <div>
                      {permissions && permissions.canEdit ? (
                        <PrimaryButton onClick={handleDialogOpen}>
                          Create New Session
                        </PrimaryButton>
                      ) : null}
                    </div>
                  </Paper>
                </div>
              )}
              {createDialogOpen && (
                <CreateSessionComponent
                  open={createDialogOpen}
                  refetch={refetch}
                  selectedBuyingSession={selectedBuyingSessionName}
                  buyingSessions={buyingSessionCollectionsWithBS}
                />
              )}
              {deleteDialogOpen && (
                <DeleteBuyingSession
                  open={deleteDialogOpen}
                  handleClose={closeDeleteDialog}
                  selectedBuyingSession={selectedBuyingSession}
                />
              )}
              {buyingSessionErrors && buyingSessionErrors.length ? (
                <BuyingSessionErrorDialog
                  open={isOpenBuyingSessionErrorPopup}
                  errors={buyingSessionErrors}
                  onOKClick={closeErrorPopup}
                ></BuyingSessionErrorDialog>
              ) : null}
              {openBSStatusChangeConfirmationDialog ? (
                <ConfirmationDialog
                  open={openBSStatusChangeConfirmationDialog}
                  title={strings.confirm}
                  message={getBSStatusChangeConfirmationMsg()}
                  handleClose={handleConfirmationDialogClose}
                  handleDelete={updateBuyingSessionStatus}
                  proceedButtonTitle={strings.yes}
                  cancelButtonTitle={strings.no}
                />
              ) : null}
            </div>
          )
        }}
      </Query>
    )
  }
}
