import { QueryResult } from '@apollo/client/react'
import { Query } from '@apollo/client/react/components/Query'
import { CircularLoading } from '@components/UI-Components/CircularLoading'
import { AuthorizationError } from '@components/Utils/AuthorizationError'
import { User } from '@models'
import { UserRoles } from '@modules/common/models/enums/UserRoles'
import { GET_SELL_THROUGH_VALUES } from '@modules/retail/buyingSession/graphql/getSellThroughValues'
import { CollectionFilterContainer } from '@modules/retail/collection/container/CollectionFilter'
import { IPermissions } from '@routes'
import { storage } from '@services/storageService'
import { stores } from '@stores'
import { reaction } from 'mobx'
import { disposeOnUnmount, observer } from 'mobx-react'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { ViewTabsMap } from 'src/constant/ViewTabs'
import { AssortmentComponent } from '../component/Assortment'
import { GET_BET_BUY_VALUES_FOR_ZONE } from '../graphql/getBetBuyValuesForZone'
import { GET_ZONEASSORTMENT_PRODUCT_FILTERS } from '../graphql/getZoneAssortmentProductsFilter'
import { ZONE_ASSORTMENT } from '../graphql/zoneAssortment'
import { ZONE_ASSORTMENT_PRODUCTS_CONNECTION } from '@modules/retail/assortment/graphql/zoneAssortmentProductsConnection'
import { ZONE_ASSORTMENT_PRODUCTS_HIERARCHY } from '@modules/retail/assortment/graphql/zoneAssortmentProductsHierarchyKPIs'
import isEmpty from 'lodash/isEmpty'
import some from 'lodash/some'

interface IAssortment {
  match?: any
  assortment?: any
  permissions?: IPermissions
  history?: any
}

@(withRouter as any)
@observer
export class ZoneAssortmentContainer extends React.Component<IAssortment> {
  refetch = null

  @disposeOnUnmount _watchSearchInputEnterKeyPress = reaction(
    () => stores.search.lastUpdatedOn,
    lastUpdatedOn => {
      if (this.refetch) {
        this.refetch()
      }
    }
  )

  setRefetch = refetch => {
    this.refetch = refetch
  }

  componentDidUpdate = prevProps => {
    const {
      match: {
        params: { assortmentId: oldId }
      }
    } = prevProps

    const [
      {
        match: {
          params: { assortmentId }
        }
      },
      {
        product: { clearRefetchQueries }
      }
    ] = [this.props, stores]

    if (oldId !== assortmentId) {
      clearRefetchQueries()
    }
  }

  componentWillUnmount = () => {
    const {
      product: { clearRefetchQueries }
    } = stores
    clearRefetchQueries()
  }

  render() {
    const user = storage.getItem<User>('user')
    const isRegionalMerchandiser = some(user.roles, {
      name: UserRoles.REGIONAL_MERCHANDISER
    })
    const [
      {
        props: {
          match: {
            params: { assortmentId, buyingSessionId, genderId }
          },
          permissions
        },
        setRefetch
      },
      {
        assortment: { drawerOpen, selectedAssortmentTab, selectedClusterId },
        search: { prepareProductSearchWhereInput, searchKey },
        filter: {
          finalFilterToApply,
          productWhereInput,
          toggleCollectionFilter,
          getFlagFilter
        },
        product: { addToAssortmentRefetchQueries },
        nav: {
          params: { view }
        }
      }
    ] = [this, stores]

    const flagFilter = getFlagFilter(finalFilterToApply)
    const whereInput = prepareProductSearchWhereInput(
      productWhereInput,
      finalFilterToApply,
      user,
      searchKey
    )
    const variablesForWhere = {
      assortmentId: assortmentId,
      product: !drawerOpen ? whereInput : {},
      ...(selectedClusterId &&
        isRegionalMerchandiser && { buyerClusters_some: { id: selectedClusterId } })
    }
    return (
      <>
        {permissions && permissions.canRead ? (
          assortmentId ? (
            <>
              <div>
                {toggleCollectionFilter && !drawerOpen ? (
                  <CollectionFilterContainer
                    permissions={permissions}
                    filterQuery={GET_ZONEASSORTMENT_PRODUCT_FILTERS}
                    variables={{ assortmentId }}
                  />
                ) : null}
              </div>
              <Query
                query={ZONE_ASSORTMENT}
                variables={{
                  assortmentId
                }}
                fetchPolicy='cache-and-network'
              >
                {({
                  data: assortmentData,
                  loading: assortmentLoading,
                  error: assortmentError
                }: QueryResult) => {
                  return (
                    <Query
                      query={ZONE_ASSORTMENT_PRODUCTS_CONNECTION}
                      variables={{
                        assortmentId
                      }}
                      fetchPolicy='cache-and-network'
                    >
                      {({ data: connectionData }) => {
                        const buyTabIndex = ViewTabsMap[view]
                          ? ViewTabsMap[view].findIndex(
                              tab => tab.name === 'WHOLESALE BUY'
                            )
                          : -1
                        return (
                          <Query
                            query={ZONE_ASSORTMENT_PRODUCTS_HIERARCHY}
                            variables={{
                              assortmentId: assortmentId,
                              variablesForWhere,
                              genderId,
                              flagFilter,
                              quantityType:
                                selectedAssortmentTab === buyTabIndex ? 'BUY' : 'BET'
                            }}
                            fetchPolicy={'cache-and-network'}
                            context={{
                              noBatch: true
                            }}
                          >
                            {({
                              data: activityHierarchy,
                              loading: betBuyLoading,
                              refetch,
                              variables
                            }: QueryResult) => {
                              addToAssortmentRefetchQueries(
                                refetch,
                                ZONE_ASSORTMENT_PRODUCTS_HIERARCHY,
                                variables
                              )
                              setRefetch(refetch)
                              return (
                                <Query
                                  query={GET_SELL_THROUGH_VALUES}
                                  variables={{
                                    buyingSessionId: buyingSessionId
                                  }}
                                  fetchPolicy='cache-and-network'
                                >
                                  {({
                                    data: rangePlansForBuyingSessionProducts
                                  }: QueryResult) => {
                                    return (
                                      <Query
                                        query={GET_BET_BUY_VALUES_FOR_ZONE}
                                        variables={{
                                          assortmentId: assortmentId
                                        }}
                                        context={{
                                          noBatch: true
                                        }}
                                        fetchPolicy='cache-and-network'
                                      >
                                        {({ error, data: betBuyData }: QueryResult) => {
                                          if (
                                            (assortmentLoading &&
                                              isEmpty(assortmentData)) ||
                                            (betBuyLoading && isEmpty(betBuyData))
                                          )
                                            return <CircularLoading />
                                          if (error) return <div>Error</div>

                                          let assortment = null
                                          if (
                                            assortmentData &&
                                            assortmentData.assortment
                                          ) {
                                            assortment = assortmentData.assortment
                                          }
                                          return (
                                            <AssortmentComponent
                                              // need to change this
                                              assortment={assortment}
                                              title={assortment ? assortment.zone : ''}
                                              isCategoryDataLoading={betBuyLoading}
                                              totalProducts={
                                                connectionData &&
                                                connectionData.zoneAssortmentProductsConnection
                                                  ? connectionData
                                                      .zoneAssortmentProductsConnection
                                                      .aggregate.count
                                                  : 0
                                              }
                                              assortmentId={assortmentId}
                                              buyingSessionId={buyingSessionId}
                                              permissions={permissions}
                                              betBuyData={betBuyData || []}
                                              zone={assortment ? assortment.zone : null}
                                              activityHierarchy={
                                                activityHierarchy
                                                  ? activityHierarchy.zoneAssortmentProductsKPIs
                                                  : null
                                              }
                                              buyingSession={
                                                assortment && assortment.buyingSession
                                              }
                                              rangePlanData={
                                                rangePlansForBuyingSessionProducts || []
                                              }
                                            />
                                          )
                                        }}
                                      </Query>
                                    )
                                  }}
                                </Query>
                              )
                            }}
                          </Query>
                        )
                      }}
                    </Query>
                  )
                }}
              </Query>
            </>
          ) : (
            'Loading...'
          )
        ) : (
          <AuthorizationError />
        )}
      </>
    )
  }
}
