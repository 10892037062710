import { config } from '@config'
import { RestServiceEndpoints } from '@constants'
import { CentricUserDetails } from '@modules/common/models'
import { stores } from '@stores'
import axios from 'axios'
import forEach from 'lodash/forEach'
import isEmpty from 'lodash/isEmpty'

import { isValid } from './centric/SDKServiceUtils'
import { storage } from './storageService'

/**
 * Get upload URL, POST paramter and file object
 * @param {string} uploadURL
 * @param {string} keyParameter
 * @param {object} fileObject
 * @param {function} callBackProgressUpdater
 */

export enum MultipleUploadImageContainerType {
  CAMPAIGN = 'Campaign',
  VIPBuyingSessionGroup = 'VIPBuyingSessionGroup',
  VIPBuyingSession = 'VIPBuyingSession'
}

export const uploadFile = (
  uploadURL,
  field,
  keyParameter,
  fileObject,
  callBackProgressUpdater
) => {
  return new Promise((resolve, reject) => {
    const token = storage.getItem('access_token')
    const fd = new FormData()
    fd.append(keyParameter, fileObject)

    const xhr = new XMLHttpRequest()

    xhr.upload.onprogress = function (e) {
      if (e.lengthComputable) {
        var percentComplete = (e.loaded / e.total) * 100
        if (callBackProgressUpdater) {
          callBackProgressUpdater(field, Math.round(percentComplete))
        }
      }
    }

    xhr.onload = function () {
      if (xhr.status === 200) {
        const responce = JSON.parse(xhr.response)
        if (responce.message === 'success') {
          resolve(responce)
        } else {
          reject(new TypeError(responce))
        }
      } else {
        reject(new TypeError(JSON.parse(xhr.response).message))
      }
    }

    xhr.ontimeout = function () {
      reject(new TypeError('Network request failed'))
    }

    xhr.onerror = function () {
      reject(new TypeError('Network request failed'))
    }

    xhr.open('POST', uploadURL, true)
    xhr.setRequestHeader('Authorization', `Bearer ${token}`)
    xhr.send(fd)
  })
}

export const uploadPdfFile = (
  uploadURL,
  field,
  keyParameter,
  fileObject,
  callBackProgressUpdater
) => {
  return new Promise((resolve, reject) => {
    const token = storage.getItem('access_token')
    const fd = new FormData()
    fd.append(keyParameter, fileObject)

    const xhr = new XMLHttpRequest()

    xhr.upload.onprogress = function (e) {
      if (e.lengthComputable) {
        var percentComplete = (e.loaded / e.total) * 100
        if (callBackProgressUpdater) {
          callBackProgressUpdater(field, Math.round(percentComplete))
        }
      }
    }

    xhr.onload = function () {
      if (xhr.status === 200) {
        const responce = JSON.parse(xhr.response)
        if (responce.id) {
          resolve(responce)
        } else {
          reject(new TypeError(responce))
        }
      } else {
        reject(new TypeError('Error while uploading file.'))
      }
    }

    xhr.ontimeout = function () {
      reject(new TypeError('Network request failed'))
    }

    xhr.onerror = function () {
      reject(new TypeError('Network request failed'))
    }

    xhr.open('POST', uploadURL, true)
    xhr.setRequestHeader('Authorization', `Bearer ${token}`)
    xhr.send(fd)
  })
}

export const uploadImages = async image => {
  const token = storage.getItem('access_token')

  if (image) {
    // @ts-ignore
    const formData = new window.FormData()
    formData.append('file', image)
    try {
      const response = await window.fetch(`${config.imagesEndpoint}images`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: formData
      })

      if (response.status === 200) {
        let result = await response.json()
        return result
      } else {
        console.log(`Error in uploading file: ${image.name}`)
      }
    } catch (e) {
      console.log(`Error in uploading file: ${image.name}`)
      console.error(e)
    }
  }
}

export const callBridgeServiceAPI = async (
  endpoint,
  data = {},
  displayErrorToast = true
) => {
  const sessionURL: string = storage.getItem('session_url')
  let user: CentricUserDetails = storage.getItem('user')

  if (!isEmpty(data)) {
    user = {
      ...user,
      sessionUrl: sessionURL
    }
    data = {
      ...data,
      user
    }
  }

  const formData = new window.FormData()
  for (var key in data) {
    if (Array.isArray(data[key])) {
      // eslint-disable-next-line no-loop-func
      forEach(data[key], (value, i) => {
        formData.append(`${key}[${i}]`, value)
      })
    } else {
      formData.append(key, data[key])
    }
  }
  let responseData
  let {
    axios: { setErrToastVisibility }
  } = stores
  await axios
    .post(`${config.centricRestEndpoint}${endpoint}`, formData, {
      headers: {
        Authorization: sessionURL
      }
    })
    .then(response => {
      if (response.data && isValid(response.data)) {
        responseData = response.data
      } else {
        console.log(`Some error in API call:${endpoint}: `, data)
        return false
      }
    })
    .catch(error => {
      setErrToastVisibility(displayErrorToast)
      if (endpoint === 'create-buying-session-or-group' && error?.response?.data) {
        responseData = error.response?.data
      } else {
        console.log(`Error caught in API call: ${endpoint}: `, data)
        console.error(error)
        return false
      }
    })
  return responseData
}

export const centricUploadDocument = async (
  document,
  URL,
  revisionURL?: string,
  channel?: string
) => {
  if (document && URL) {
    const data = new FormData()
    data.append('file', document)
    data.append('documentName', document?.name)
    data.append('documentContainerURL', URL)
    if (revisionURL) {
      data.append('revisionURL', revisionURL)
    }
    channel && data.append('channel', channel)
    const sessionURL: string = storage.getItem('session_url')
    const endpoint = `${config.centricRestEndpoint}${RestServiceEndpoints.DOCUMENT_UPLOAD}`

    try {
      const response = await fetch(endpoint, {
        body: data,
        method: 'POST',
        headers: {
          Authorization: sessionURL
        }
      })

      const res = await response.json()
      return res
    } catch (e) {
      console.log(`Error in uploading file: ${document.name}`)
      console.error(e)
    }
  }
}

export const deleteDocument = async documentId => {
  if (documentId) {
    const sessionURL: string = storage.getItem('session_url')
    const endpoint = `${config.centricRestEndpoint}${RestServiceEndpoints.DOCUMENT_DELETE}`

    try {
      const response = await axios({
        url: endpoint,
        data: {
          documentId
        },
        method: 'POST',
        headers: {
          Authorization: sessionURL
        }
      })

      return response
    } catch (e) {
      console.log(`Error in deleting document node: ${documentId}`)
      console.error(e)
    }
  }
}

export const insertImages = async (
  containerId: string,
  containerType: MultipleUploadImageContainerType,
  images: File[] | Blob[]
) => {
  const res = await callBridgeServiceAPI(RestServiceEndpoints.INSERT_IMAGES, {
    containerId,
    containerType,
    files: images
  })
  return res
}

export const replaceImages = async (
  containerId: string,
  containerType: MultipleUploadImageContainerType,
  images: File[] | Blob[],
  imagesIds: string[]
) => {
  const res = await callBridgeServiceAPI(RestServiceEndpoints.REPLACE_IMAGES, {
    containerId,
    containerType,
    files: images,
    imagesToReplace: imagesIds
  })
  return res
}

export const deleteImages = async (
  containerId: string,
  containerType: MultipleUploadImageContainerType,
  imagesIds: string[]
) => {
  const res = await callBridgeServiceAPI(RestServiceEndpoints.DELETE_IMAGES, {
    containerId,
    containerType,
    imageURLList: imagesIds
  })
  return res
}
