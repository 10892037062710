import { StyledProps } from '@components/3rd-party/mui'
import classNames from 'classnames'
import isEqual from 'lodash/isEqual'
import React, { memo } from 'react'
import styled from 'styled-components'
import { StandardProductInfoItem } from '../StandardProductInfoItem'
import * as css from './StandardAttributeList.css'

interface IProps extends StyledProps {
  productAttributes?: any
  productId?: string
  className?: any
  other?: any
  style?: any
}

const ColorBox = styled.div(props => {
  const { color } = props
  return {
    width: 20,
    height: 20,
    borderRadius: 2,
    marginRight: 7,
    marginBottom: 6,
    backgroundColor: color
  }
})

export const StandardAttributeList = memo(
  (props: IProps) => {
    const { productAttributes, className, style, ...otherProps } = props
    return (
      <div className={css.productDetailContainer}>
        {productAttributes &&
          productAttributes.map(({ id, label, value, color }, index) => {
            return (
              <div className={css.container} key={index}>
                <StandardProductInfoItem
                  title={label}
                  content={value}
                  showTooltip={true}
                  isHeader={index === 0}
                  style={style}
                />
                {color ? (
                  <ColorBox
                    className={classNames(className)}
                    color={color}
                    {...otherProps}
                  />
                ) : null}
              </div>
            )
          })}
      </div>
    )
  },
  (previousProps, nextProps) =>
    previousProps.productId === nextProps.productId &&
    isEqual(previousProps.productAttributes, nextProps.productAttributes)
)
