import { useMutation } from '@apollo/client/react/hooks/useMutation'
import { useQuery } from '@apollo/client/react/hooks/useQuery'
import { GET_SEASON_SYNC_METADATA, UPSERT_SYNC_SEASON_METADATA } from '.'

export const useWSSeasonSyncMetaData = () => {
  return useQuery(GET_SEASON_SYNC_METADATA)
}

export const useUpsertSeasonSyncMetaData = () => {
  const [ref] = useMutation(UPSERT_SYNC_SEASON_METADATA)
  return ref
}
