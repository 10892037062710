import { CentricTheme, StyledProps } from '@components/3rd-party/mui'
import { PrimaryButton } from '@components/UI-Components/PrimaryButton'
import { SecondaryButton } from '@components/UI-Components/SecondaryButton'
import { SelectFileButton } from '@components/UI-Components/SelectFileButton'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { stores } from '@stores'
import { observer } from 'mobx-react'
import React from 'react'

const styles = (theme: CentricTheme) => ({})

interface IStoreImport extends StyledProps {
  handleSubmit
  handleChanges
  uploadInProgress
  storeDataSheetUploadError
}

@((withStyles as any)(styles))
@observer
export class StoreImportDialog extends React.Component<IStoreImport> {
  render() {
    const [
      {
        props: {
          classes,
          handleSubmit,
          handleChanges,
          uploadInProgress,
          storeDataSheetUploadError
        }
      },
      {
        storeImport: { isDialogOpen, toggleDialogOpen }
      }
    ] = [this, stores]

    return (
      <Dialog open={isDialogOpen}>
        <DialogTitle>Import Store Data</DialogTitle>

        <DialogContent>
          <div className={classes.uploadItem}>
            <SelectFileButton
              accept='application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              label='Stores (*.xlsx)'
              disabled={uploadInProgress}
              loading={uploadInProgress}
              handleChange={file => handleChanges(file, 'storeDataSheet')}
            />
            {storeDataSheetUploadError ? (
              <Typography className={classes.error}>
                {' '}
                {storeDataSheetUploadError}{' '}
              </Typography>
            ) : null}
          </div>
        </DialogContent>

        <DialogActions>
          <SecondaryButton onClick={toggleDialogOpen}>Cancel</SecondaryButton>
          <PrimaryButton onClick={handleSubmit}>Import</PrimaryButton>
        </DialogActions>
      </Dialog>
    )
  }
}
