/*
 * @Author: Raeesaa Metkari
 * @Date: 2020-04-12 15:55:34
 * @Last Modified by: Raeesaa Metkari
 * @Last Modified time: 2020-11-11 15:31:55
 */
import { config } from '@config'
import { ACTIVITY_HIERARCHY_MAP, DEFAULT_ACTIVITY_HIERARCHY } from '@constants'
import { CriteriaOperationType } from '@models'
import { ListViewFieldMapping } from '@modules/common/models/enums/ListViewFieldMapping'
import { CentricNode } from '@modules/common/models/interfaces/CentricNode'
import { CentricPricelistValue } from '@modules/common/models/interfaces/CentricPricelistValue'
import { FilterKeys } from '@modules/wholesale/FilterModal/enums/FilterEnums'
import { convertToCurrencyLocaleString } from '@services/assortmentService'
import { convertNumberToPercentage } from '@services/commonServices'
import { stores } from '@stores'
import capitalize from 'lodash/capitalize'
import clone from 'lodash/clone'
import filter from 'lodash/filter'
import find from 'lodash/find'
import forEach from 'lodash/forEach'
import groupBy from 'lodash/groupBy'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import map from 'lodash/map'
import some from 'lodash/some'
import sortBy from 'lodash/sortBy'
import flatten from 'lodash/flatten'
import memoize from 'memoize-one'
import { IDoor } from './doorManagementService'
import { getDoorKPISAtCategory } from './wholesaleDoorKPIService'
import { Clusters, ValueMetadata } from './productService'
import { toJS } from 'mobx'
import { ViewTabs } from '@modules/common/models/enums/Views'
import pick from 'lodash/pick'
import union from 'lodash/union'

interface VIPProduct {
  Id: string
  Activity: CentricNode
  Family: CentricNode
  Line: CentricNode
  Active: boolean
  Gender: CentricNode
}

interface VIPBuyingSessionProduct {
  Id: string
  Name: string
  Original: VIPProduct
  sizedQty?: any
  draftQty?: any
  draftValue?: number
  globalDraftValue?: number
  sizedValue?: number
  globalSizedValue?: number
  ClientAssortmentProducts: Array<{ Assortment: { Client: { Id } } }>
  channels: string[]
  excludedClients?: any
  clusterExclusion?: Array<Clusters>
}

export interface VIPAssortmentProduct {
  Id: string
  Name: string
  sizedQty?: any
  draftQty?: any
  draftValue?: number
  globalDraftValue?: number
  sizedValue?: number
  globalSizedValue?: number
  Original: VIPBuyingSessionProduct
}

export interface VIPDoorOrders {
  Id: string
  Door: IDoor
  OrderLineItems: Array<VIPOrderProduct>
}

export interface VIPSize {
  Id: string
  Name: string
}

export interface VIPOrderProduct {
  Id: string
  Name: string
  totalQty: number
  isBooked: boolean
  isRecommended: boolean
  clientOrderProduct?: VIPOrderProduct
  discount: number
  whlPrice: CentricPricelistValue
  rtlPrice: CentricPricelistValue
  Original: VIPProduct
  DoorOrder: VIPDoorOrders
  sizes: VIPSize[]
  qtyPerSize: number[]
  totalSizedQty: number
  CreatedAt: number
  sizedQty?: number
  draftQty?: number
  BuyingSessionProduct: VIPBuyingSessionProduct
}

export interface DoorProductMap {
  [key: string]: Array<VIPOrderProduct>
}

export interface DoorProductData {
  totalTargetQuantity?: number
  totalTargetValue?: number
  totalSizedQuantity?: number
  totalSizedValue?: number
  doorProductMap?: DoorProductMap
}

type Product = VIPBuyingSessionProduct | VIPAssortmentProduct | VIPOrderProduct

/**
 * Group products based on property identified
 * by group selector
 * @param products - list of products
 * @param groupKeySelector - Function to identify product properties
 */
const groupProducts = (
  products: Array<VIPBuyingSessionProduct | VIPOrderProduct | VIPAssortmentProduct>,
  groupKeySelector: Function
) => {
  return groupBy(products, bsp => groupKeySelector(bsp))
}

/**
 * Returns data to render tabs and filters on SA filter modal
 * @param filterFieldsData
 */
export const getFilterTabs = filterFieldsData => {
  const attributeFilterFields = []
  map(filterFieldsData, field => {
    forEach(
      [
        FilterKeys.ProductAttributes,
        FilterKeys.BookedUnbooked,
        FilterKeys.WithQtyWithoutQty,
        FilterKeys.Drop,
        FilterKeys.FreeTags
      ],
      filterKey => {
        if (field?.[filterKey]?.data?.length) {
          forEach(field[filterKey].data, data => {
            attributeFilterFields.push({
              label: field?.[filterKey]?.label,
              name: field?.[filterKey]?.name,
              ...data
            })
          })
        }
      }
    )
  })
  return attributeFilterFields
}

/**
 * Returns door products grouped by given category
 * across all doors
 * @param productMap
 * @param category
 */
const groupDoorProductsForCategory = (
  productMap: DoorProductMap,
  category: string
): DoorProductMap => {
  const groupedProductsByCategoryAcrossDoors = {}
  for (let door in productMap) {
    const doorProducts = isEmpty(productMap[door]) ? null : productMap[door]
    groupedProductsByCategoryAcrossDoors[door] = doorProducts
      ? groupProducts(
          doorProducts,
          (orderProduct: VIPOrderProduct) =>
            orderProduct?.Original?.[category]?.Name ?? 'Undefined'
        )
      : null
  }

  return groupedProductsByCategoryAcrossDoors
}

/**
 * Returns door products for given category
 * across all doors
 * @param productMap
 * @param category
 */
const getDoorProductsForCategory = (
  productMap: DoorProductMap,
  category: string
): DoorProductMap => {
  const categoryProductsByDoor = {}
  for (let doorId in productMap) {
    categoryProductsByDoor[doorId] =
      productMap[doorId] && productMap[doorId][category]
        ? productMap[doorId][category]
        : null
  }
  return categoryProductsByDoor
}

export const getQtyValueFromRangePlan = rangePlandata => {
  return rangePlandata.reduce(
    (acc, curr) => {
      acc.buyTargetValue += curr.buyTargetValue || 0
      acc.buyTargetQuantity += curr.buyTargetQuantity || 0
      return acc
    },
    { buyTargetValue: 0, buyTargetQuantity: 0 }
  )
}

const recursivelyBuildChildren = (
  buyingSessionProducts: Array<VIPBuyingSessionProduct | VIPAssortmentProduct>,
  hierarchy,
  isAssortmentType,
  doorActivityProductData: DoorProductData = {},
  valueMeta?: ValueMetadata,
  buyTargetInfo?: any,
  withData: boolean = false,
  forecastData?,
  selectedTab?,
  activityBuyProducts?
) => {
  let currentCategory = hierarchy[0]
  const isRtlBuyView = selectedTab === ViewTabs.RTL_BUY.toLowerCase()
  const groupedProducts = groupProducts(
    buyingSessionProducts,
    !isAssortmentType || isRtlBuyView
      ? (bsp: VIPBuyingSessionProduct) =>
          bsp?.Original?.[`${capitalize(currentCategory)}`]?.Name ?? 'Undefined'
      : (bsp: VIPAssortmentProduct) =>
          bsp?.Original?.Original?.[`${capitalize(currentCategory)}`]?.Name ?? 'Undefined'
  )

  const groupedBuyProducts = groupBy(
    activityBuyProducts,
    currentCategory === 'line' ? 'lineId' : 'familyId'
  )
  const groupedProductsByDoor = doorActivityProductData?.doorProductMap
    ? groupDoorProductsForCategory(
        doorActivityProductData?.doorProductMap,
        `${capitalize(currentCategory)}`
      )
    : null

  let response = map(Object.keys(groupedProducts), category => {
    const categoryProducts: any = groupedProducts[category]
    let seasonalProduct =
      !isAssortmentType || isRtlBuyView
        ? categoryProducts[0]?.Original
        : categoryProducts[0]?.Original?.Original
    const sortOrderByField =
      seasonalProduct?.[`${capitalize(currentCategory)}`]?.GIV_VIPSortOrder
    const rangePlansCategory = filter(
      buyTargetInfo.rangePlansForActivity,
      data => data[`${capitalize(currentCategory)}`]?.Name === category
    )
    // RetailBuyProductsByCategory
    const categoryBuyProducts: any =
      groupedBuyProducts[seasonalProduct?.[`${capitalize(currentCategory)}`]?.Id]
    let { buyTargetValue, buyTargetQuantity } =
      getQtyValueFromRangePlan(rangePlansCategory)

    let { salesQty, draftQty, sizedQty, value } = groupedProducts[category].reduce(
      (acc, curr: any) => {
        acc.salesQty += curr?.salesQty || 0
        acc.draftQty += curr?.draftQty || 0
        acc.sizedQty += curr?.sizedQty || 0
        acc.value += curr?.value || 0
        return acc
      },
      {
        salesQty: 0,
        draftQty: 0,
        sizedQty: 0,
        value: 0
      }
    ) as any

    const buytargetQtyMix = convertNumberToPercentage(
      buyTargetInfo.totalTargetQtyForActivities
        ? buyTargetQuantity / buyTargetInfo.totalTargetQtyForActivities
        : 0
    )

    const buytargetValueMix = convertNumberToPercentage(
      buyTargetInfo.totalTargetValueForActivities
        ? buyTargetValue / buyTargetInfo.totalTargetValueForActivities
        : 0
    )

    const buytargetMixKPI = {
      buytargetQtyMix,
      buytargetValueMix
    }

    let doorKpi = {}
    let doorProductDataForChild = {}

    const categoryProductsByDoor = getDoorProductsForCategory(
      groupedProductsByDoor,
      category
    )
    doorKpi = getDoorKPISAtCategory(
      categoryProductsByDoor,
      valueMeta?.targetCurrency,
      doorActivityProductData?.totalTargetQuantity,
      doorActivityProductData?.totalTargetValue,
      doorActivityProductData?.totalSizedQuantity,
      doorActivityProductData?.totalSizedValue,
      buytargetMixKPI,
      valueMeta
    )

    doorProductDataForChild = {
      totalTargetQuantity: doorKpi[ListViewFieldMapping.totalTargetQuantity],
      totalTargetValue: doorKpi[ListViewFieldMapping.totalTargetValue],
      totalTargetValueWithCurrency:
        doorKpi[ListViewFieldMapping.totalTargetValueWithCurrency],
      totalSizedQuantity: doorKpi[ListViewFieldMapping.totalSizedQuantity],
      totalSizedValue: doorKpi[ListViewFieldMapping.totalSizedValue],
      totalSizedValueWithCurrency:
        doorKpi[ListViewFieldMapping.totalSizedValueWithCurrency],
      doorProductMap: categoryProductsByDoor
    }

    let object: any = {
      id: seasonalProduct?.[`${capitalize(currentCategory)}`]?.Id,
      name: category,
      sortOrder: sortOrderByField,
      createdAt: seasonalProduct?.[`${capitalize(currentCategory)}`]?.CreatedAt,
      totalProducts: groupedProducts[category].length,
      salesQty,
      draftQty,
      sizedQty,
      buyTargetValue: buyTargetValue,
      buyTargetQuantity: buyTargetQuantity,
      value: convertToCurrencyLocaleString(valueMeta?.targetCurrency, value),
      ...doorKpi,
      ...(selectedTab === ViewTabs.FORECAST.toLowerCase()
        ? mapForecastTotal(
            (forecastData?.totalForecastData || [])?.find(total =>
              total?.comboId?.endsWith(
                `${forecastData?.hierarchyPrefix}.${
                  seasonalProduct?.[`${capitalize(currentCategory)}`]?.Id
                }`
              )
            )
          )
        : {}),
      ...(isRtlBuyView ? mapRetailBuyTotal(categoryBuyProducts, isAssortmentType) : {})
    }

    let hierarchyCopy = clone(hierarchy)
    hierarchyCopy.shift()

    const buyTarget = {
      rangePlansForActivity: rangePlansCategory,
      totalTargetQtyForActivities: buyTargetQuantity,
      totalTargetValueForActivities: buyTargetValue
    }

    if (hierarchyCopy.length) {
      object.children = recursivelyBuildChildren(
        categoryProducts,
        hierarchyCopy,
        isAssortmentType,
        doorProductDataForChild,
        valueMeta,
        buyTarget,
        withData,
        {
          totalForecastData: forecastData?.totalForecastData,
          hierarchyPrefix: `${forecastData?.hierarchyPrefix}.${
            seasonalProduct?.[`${capitalize(currentCategory)}`]?.Id
          }`
        },
        selectedTab,
        categoryBuyProducts
      )
    } else if (withData) {
      object.data = categoryProducts
    }

    return object
  })
  return sortBy(response, ['sortOrder', 'name'])
}

const mapForecastTotal = totalForecastData => {
  return {
    aws: totalForecastData?.avgWeeklySales || 0,
    // response values are already in percentages, toLocaleString won't work
    mixQuantity: `${totalForecastData?.mixQuantity || 0}%`,
    mixValue: `${totalForecastData?.mixValue || 0}%`,
    averageDepth: totalForecastData?.avgDepth || 0,
    skuEfficiency: totalForecastData?.skuEFF || 0,
    totalStores: totalForecastData?.numberOfStores || 0,
    totalQuantity: totalForecastData?.forecastQuantity || 0,
    totalValue: totalForecastData?.forecastValue || 0,
    salesQty: totalForecastData?.salesQuantity || 0
  }
}

const calculateRTLBuyTotals = (result, client) => {
  result.doorIds = union(result.doorIds || [], client.doorIds || [])
  result.forecastQuantity += client.forecastQuantity || 0
  result.orderQuantity += client.orderQuantity || 0
  result.avgWeeklySales += client.avgWeeklySales || 0
  result.avgDepth += client.avgDepth || 0
  result.skuEFF += client.skuEFF || 0
  result.orderValue += client.orderValue || 0
  result.currency = client.targetCurrency || result.currency
  result.salesQuantity += client.salesQuantity || 0
  return result
}

const defaultRTLBuyTotals = {
  forecastQuantity: 0,
  orderQuantity: 0,
  avgWeeklySales: 0,
  avgDepth: 0,
  skuEFF: 0,
  orderValue: 0,
  salesQuantity: 0
}

const mapRetailBuyTotal = (categoryWiseBuyData, isAssortmentType) => {
  const currency = config.globalCurrency
  const result = (categoryWiseBuyData || []).reduce(
    (total, product) => {
      const clientTotals = (product.clients || []).reduce(
        (acc, client) => {
          if (isAssortmentType) {
            acc = { ...calculateRTLBuyTotals(acc, client) }
          } else if (client.clientId === 'GLOBAL') {
            acc = { ...calculateRTLBuyTotals(acc, client) }
          }
          return acc
        },
        {
          ...defaultRTLBuyTotals
        }
      )
      total = { ...calculateRTLBuyTotals(total, clientTotals) }
      return total
    },
    {
      ...defaultRTLBuyTotals
    }
  )
  return {
    totalStores: result.doorIds?.length ?? 0,
    totalBETQuantity: result.forecastQuantity ?? 0,
    totalQuantity: result.orderQuantity ?? 0,
    aws: result.avgWeeklySales ?? 0,
    averageDepth: result.avgDepth ?? 0,
    skuEfficiency: result.skuEFF ?? 0,
    totalValue: convertToCurrencyLocaleString(
      result.currency || currency,
      +(result.orderValue.toFixed(2) || 0)
    ),
    salesQty: result.salesQuantity ?? 0
  }
}

class WholesaleCollectionService {
  buildKPIHierarhyForBuyingSessionProducts = memoize(
    (
      products: Array<Product>,
      isAssortmentType: boolean,
      doorProductMap: DoorProductMap = {},
      valueMeta?: ValueMetadata,
      isRecommendedFilterApplied?: boolean,
      rangePlans?: any,
      withData: boolean = false,
      totalForecastData?,
      retailBuyProductData?,
      selectedTab?
    ) => {
      //Retail Buy Tab
      const isRtlBuyView = selectedTab === ViewTabs.RTL_BUY.toLowerCase()
      let productIds = products.map(({ Id, Original }) =>
        isAssortmentType && !isRtlBuyView ? Original.Id : Id
      )
      const filteredRTLBuyProducts =
        retailBuyProductData && pick(retailBuyProductData, productIds)
      const retailBuyProductsList = Object.keys(filteredRTLBuyProducts || {}).reduce(
        (acc, key) => {
          acc.push(filteredRTLBuyProducts[key])
          return acc
        },
        []
      )
      const groupedRTLBuyProductsByActivity = groupBy(retailBuyProductsList, 'activityId')

      // Group data by activity name
      const groupedProductsByActivity = groupProducts(
        products,
        !isAssortmentType || isRtlBuyView
          ? (bsp: VIPBuyingSessionProduct) => bsp?.Original?.Activity?.Name ?? 'Undefined'
          : (bsp: VIPAssortmentProduct) =>
              bsp?.Original?.Original?.Activity?.Name ?? 'Undefined'
      )
      /**
       * Group door products within each door
       * by activity if doorProductData
       */
      const groupedDoorProductsByActivity = doorProductMap
        ? groupDoorProductsForCategory(doorProductMap, 'Activity')
        : null

      let activitiesInResponse = []
      let totalDraftQtyForActivities = 0
      let totalDraftValueForActivities = 0
      let totalSizedQtyForActivities = 0
      let totalSizedValueForActivities = 0
      let totalTargetQtyForActivities = 0
      let totalTargetValueForActivities = 0
      for (let activity in groupedProductsByActivity) {
        let seasonalProduct =
          !isAssortmentType || isRtlBuyView
            ? (groupedProductsByActivity[activity][0] as VIPBuyingSessionProduct)
                ?.Original
            : (groupedProductsByActivity[activity][0] as VIPAssortmentProduct)?.Original
                ?.Original
        const activityId = seasonalProduct?.Activity?.Id
        const sortOrderActivity = seasonalProduct?.Activity?.GIV_VIPSortOrder
        const activityProducts = groupedProductsByActivity[activity]
        let { salesQty, draftQty, sizedQty, value } = activityProducts.reduce(
          (acc, curr: any) => {
            acc.salesQty += curr?.salesQty || 0
            acc.draftQty += curr?.draftQty || 0
            acc.sizedQty += curr?.sizedQty || 0
            acc.value += curr?.value || 0
            return acc
          },
          {
            salesQty: 0,
            draftQty: 0,
            sizedQty: 0,
            value: 0
          }
        )
        // RetailBuyProductsByActivities
        const activityBuyProducts = groupedRTLBuyProductsByActivity[activityId]

        let hierarchy = ACTIVITY_HIERARCHY_MAP[activity] || DEFAULT_ACTIVITY_HIERARCHY

        let doorKpi = {}
        let doorProductDataForChild = {}

        const activityDoorProductsMap = getDoorProductsForCategory(
          groupedDoorProductsByActivity,
          activity
        )
        doorKpi = getDoorKPISAtCategory(
          activityDoorProductsMap,
          valueMeta?.targetCurrency,
          null,
          null,
          null,
          null,
          null,
          valueMeta
        )

        doorProductDataForChild = {
          totalTargetQuantity: doorKpi[ListViewFieldMapping.totalTargetQuantity],
          totalTargetValue: doorKpi[ListViewFieldMapping.totalTargetValue],
          totalTargetValueWithCurrency:
            doorKpi[ListViewFieldMapping.totalTargetValueWithCurrency],
          totalSizedQuantity: doorKpi[ListViewFieldMapping.totalSizedQuantity],
          totalSizedValue: doorKpi[ListViewFieldMapping.totalSizedValue],
          totalSizedValueWithCurrency:
            doorKpi[ListViewFieldMapping.totalSizedValueWithCurrency],
          doorProductMap: activityDoorProductsMap
        }

        totalDraftQtyForActivities += doorKpi[ListViewFieldMapping.totalTargetQuantity]
        totalDraftValueForActivities += doorKpi[ListViewFieldMapping.totalTargetValue]
        totalSizedQtyForActivities += doorKpi[ListViewFieldMapping.totalSizedQuantity]
        totalSizedValueForActivities += doorKpi[ListViewFieldMapping.totalSizedValue]

        const rangePlansForActivity = filter(
          rangePlans,
          data => data.Activity.Name === activity
        )

        let { buyTargetValue, buyTargetQuantity } =
          getQtyValueFromRangePlan(rangePlansForActivity)

        totalTargetQtyForActivities += buyTargetQuantity
        totalTargetValueForActivities += buyTargetValue

        const buyTargetInfo = {
          rangePlansForActivity,
          totalTargetQtyForActivities: buyTargetQuantity,
          totalTargetValueForActivities: buyTargetValue
        }

        let children = recursivelyBuildChildren(
          activityProducts as Array<VIPBuyingSessionProduct | VIPAssortmentProduct>,
          hierarchy,
          isAssortmentType,
          doorProductDataForChild,
          valueMeta,
          buyTargetInfo,
          withData,
          { totalForecastData, hierarchyPrefix: seasonalProduct?.Activity?.Id },
          selectedTab,
          activityBuyProducts
        )

        let object = {
          id: seasonalProduct?.Activity?.Id,
          name: activity,
          sortOrder: sortOrderActivity,
          createdAt: seasonalProduct?.Activity?.CreatedAt,
          totalProducts: activityProducts.length,
          // Following four attributes are for BUY tab
          salesQty,
          draftQty,
          sizedQty,
          value: convertToCurrencyLocaleString(valueMeta?.targetCurrency, value),
          buyTargetValue: buyTargetValue,
          buyTargetQuantity: buyTargetQuantity,
          children,
          childrenMeta: hierarchy,
          // Following attribute are for order
          ...doorKpi,
          ...(selectedTab === ViewTabs.FORECAST.toLowerCase()
            ? mapForecastTotal(
                (totalForecastData || [])?.find(total =>
                  total?.comboId?.endsWith(seasonalProduct?.Activity?.Id)
                )
              )
            : {}),
          ...(isRtlBuyView
            ? mapRetailBuyTotal(activityBuyProducts, isAssortmentType)
            : {})
        }

        activitiesInResponse.push(object)
      }

      // Calculate actual mix for activities
      /**
       * NOTE: Could not caculate mix for activity in loop as we need total qty across all activities,
       * which won't be possible to evaluate until we iterate through all activities
       */
      activitiesInResponse.forEach(kpi => {
        // Target qty / value mix
        const targetQtyMix = convertNumberToPercentage(
          totalTargetQtyForActivities
            ? kpi.buyTargetQuantity / totalTargetQtyForActivities
            : 0
        )
        const targetValueMix = convertNumberToPercentage(
          totalTargetValueForActivities
            ? kpi.buyTargetValue / totalTargetValueForActivities
            : 0
        )

        // For draft qty / value
        const draftActualQtyMix = convertNumberToPercentage(
          totalDraftQtyForActivities
            ? kpi.totalTargetQuantity / totalDraftQtyForActivities
            : 0
        )

        const draftActualValueMix = convertNumberToPercentage(
          totalDraftValueForActivities
            ? kpi.totalTargetValue / totalDraftValueForActivities
            : 0
        )

        kpi[
          ListViewFieldMapping.mixRatioByTargetQty
        ] = `${draftActualQtyMix} / ${targetQtyMix}`

        kpi[
          ListViewFieldMapping.mixRatioByTargetValue
        ] = `${draftActualValueMix} / ${targetValueMix}`

        // For sized qty / value
        const sizedActualQtyMix = convertNumberToPercentage(
          totalSizedQtyForActivities
            ? kpi.totalSizedQuantity / totalSizedQtyForActivities
            : 0
        )
        const sizedActualValueMix = convertNumberToPercentage(
          totalSizedValueForActivities
            ? kpi.totalSizedValue / totalSizedValueForActivities
            : 0
        )
        kpi[
          ListViewFieldMapping.mixRatioBySizedQty
        ] = `${sizedActualQtyMix} / ${targetQtyMix}`
        kpi[
          ListViewFieldMapping.mixRatioBySizedValue
        ] = `${sizedActualValueMix} / ${targetValueMix}`
      })
      return { categories: sortBy(activitiesInResponse, ['sortOrder', 'name']) }
    },
    isEqual
  )

  /**
   * Returns door to door order product map from
   * door orders
   * @param doorOrders - Array<VIPDoorOrders>)
   * @returns DoorProductMap
   */
  buildDoorWiseOrderProductMap = memoize(
    (
      doorOrders: Array<VIPDoorOrders>,
      isRecommendedFilterApplied: boolean,
      filteredParentProducts: Array<VIPBuyingSessionProduct | VIPOrderProduct>
    ): {
      doorProductMap: DoorProductMap
      isAtleastOneProductBooked: boolean
      ordersWithBookedProducts
    } => {
      const {
        search: { searchKey },
        filterModalStore: { wsAppliedFilters }
      } = stores
      const doorProductMap = {}
      let isAtleastOneProductBooked = false
      let ordersWithBookedProducts = []
      for (let order of doorOrders) {
        const doorOrderProducts = order?.OrderLineItems || []
        let isBookedProductsExist = some(
          doorOrderProducts,
          orderProduct => orderProduct.isBooked
        )
        if (isBookedProductsExist) {
          ordersWithBookedProducts.push(order.Id)
        }

        if (!isAtleastOneProductBooked && isBookedProductsExist) {
          isAtleastOneProductBooked = true
        }

        if (order?.Door?.Id) {
          let filteredProducts =
            !isEmpty(searchKey) ||
            !isEmpty(wsAppliedFilters) ||
            isRecommendedFilterApplied
              ? filter(
                  doorOrderProducts,
                  orderProduct =>
                    !!find(
                      filteredParentProducts,
                      parentProduct =>
                        parentProduct?.Original &&
                        parentProduct?.Original?.Id === orderProduct?.Original?.Id
                    ) &&
                    (isRecommendedFilterApplied
                      ? orderProduct?.clientOrderProduct?.isRecommended ||
                        orderProduct?.isRecommended
                      : true)
                )
              : doorOrderProducts
          doorProductMap[order.Door.Id] = filteredProducts
        }
      }
      return { doorProductMap, isAtleastOneProductBooked, ordersWithBookedProducts }
    },
    isEqual
  )

  /**
   * NOTE: This function will be re-used in case of both collection and assortment for building
   * hierarchy input for BSP and assortment product query
   *
   * Method for building input argument for hierarchy within VIPProduct
   * @param {string} activity - Name of activity
   * @param {Array<string>} childrenMeta - ChildrenMeta for given activity
   * @param {Array<string>} childrenMetaValues - Values for hierarchy chilren
   */
  buildAttributeHierarchyQuery = (
    activity: string,
    childrenMeta: Array<string>,
    childrenMetaValues: Array<string>
  ) => {
    let attributeHierarchyQuery = {
      Activity: {
        Name: { operation: CriteriaOperationType.EQ, value: activity }
      }
    }

    childrenMeta.forEach((category, index) => {
      attributeHierarchyQuery[capitalize(category)] = {
        Name: { operation: CriteriaOperationType.EQ, value: childrenMetaValues[index] }
      }
    })

    return attributeHierarchyQuery
  }

  /**
   * Method that accepts BSP and door order products and filters buying session product
   * to keep only those products that either have wholesale channel set or are present in
   * door order
   * @param {Array<VIPBuyingSessionProduct>} products - List of buying session products
   * @param {DoorProductData} doorOrderProductMap - Map of door order products where key is door order id and value is array of order products
   * @param {Array<VIPOrderProduct>} doorOrderProducts - Array of door order products. If second parameter is empty, this parameter will be considered
   */
  filterOrderCollectionListProducts = memoize(
    (
      products: Array<VIPBuyingSessionProduct>,
      doorOrderProductMap: DoorProductData = {},
      doorOrderProducts?: Array<VIPOrderProduct>,
      isRecommendedFilter?: Boolean,
      clientAssortmentProducts?: VIPAssortmentProduct[]
    ) => {
      const {
        nav: {
          queryParams: { clientId }
        }
      } = stores

      const wholesaleChannelValue =
        config.appConfig?.enumerations?.channel?.VALUES?.WHOLESALE

      let orderProducts = isEmpty(doorOrderProductMap)
        ? doorOrderProducts || []
        : flatten(Object.values(doorOrderProductMap))

      const bspToDoorProductMap = groupBy(
        orderProducts,
        orderProduct => orderProduct?.Original?.Id
      )
      let filteredProducts = products.filter(product => {
        const matchingDoorProducts = bspToDoorProductMap[product?.Original?.Id] || []
        const isBasketProduct =
          matchingDoorProducts && matchingDoorProducts?.length ? true : false

        let hasClientAssortmentProductForThisClient = false
        let hasRecommendedFlagOnOrderProduct = false
        if (isRecommendedFilter) {
          if (isBasketProduct) {
            hasRecommendedFlagOnOrderProduct = some(
              matchingDoorProducts,
              (orderProduct: any) =>
                orderProduct.isRecommended ||
                orderProduct.clientOrderProduct?.isRecommended
            )
          } else {
            hasClientAssortmentProductForThisClient = some(
              clientAssortmentProducts,
              clientAssortmentProduct =>
                clientAssortmentProduct.Original?.Id === product?.Id
            )
          }
        }

        return (
          (product?.Original?.Active || !!isBasketProduct) &&
          (includes(product.channels, wholesaleChannelValue) || isBasketProduct) &&
          (!find(product?.excludedClients, client => client.Id === clientId) ||
            isBasketProduct) &&
          (!isRecommendedFilter ||
            hasClientAssortmentProductForThisClient ||
            hasRecommendedFlagOnOrderProduct)
        )
      })
      return filteredProducts
    },
    isEqual
  )

  buildProductForecastKPIHierarchy = memoize(
    (productForecastKPIData: Array<any>, products: Array<Product>) => {
      let activitiesKPIResponse = []
      if (products.length) {
        const newForecastKPIData = (productForecastKPIData || []).map(kpiData => {
          return {
            comboId: kpiData?.comboId,
            name: kpiData?.name || '',
            aws: kpiData?.avgWeeklySales || 0,
            mixQuantity: convertNumberToPercentage(kpiData?.mixQuantity / 100),
            mixValue: convertNumberToPercentage(kpiData?.mixValue / 100),
            averageDepth: kpiData?.avgDepth || 0,
            skuEfficiency: kpiData?.skuEFF || 0,
            totalStores: kpiData?.numberOfStores || 0,
            totalQuantity: kpiData?.forecastQuantity || 0,
            totalValue: kpiData?.forecastValue || 0,
            salesQty: kpiData?.salesQuantity || 0,
            totalProducts: kpiData?.count || 0,
            children: null
          }
        })
        const helper = newForecastKPIData.reduce(
          (h, o) => (h[o.comboId] = Object.assign({}, o)) && h,
          Object.create(null)
        )
        activitiesKPIResponse = newForecastKPIData.reduce((t, node) => {
          const current = helper[node.comboId]
          if (current.comboId.split('.').length === 2) {
            current.childrenMeta =
              ACTIVITY_HIERARCHY_MAP[current?.name] || DEFAULT_ACTIVITY_HIERARCHY
            t.push(current)
          } else {
            const split = node.comboId.split('.')
            const x =
              split.length > 2 ? split.slice(0, split.length - 1).join('.') : node.comboId
            helper[x].children || (helper[x].children = [])
            helper[x].children.push(current)
            helper[x].children = sortBy(helper[x].children, 'name')
          }
          return t
        }, [])
      }
      return { categories: activitiesKPIResponse }
    }
  )

  recursiveBuildBuyKPIsForCategories = (categories: any[]) => {
    const massageKPIs = kpis => {
      const value = kpis?.totalBuyValue || 0
      const currency = kpis?.currency
      return {
        aws: kpis?.aws || 0,
        averageDepth: kpis?.averageDepth || 0,
        skuEfficiency: kpis?.skuEfficiency || 0,
        totalStores: kpis?.numberOfStores || 0,
        totalQuantity: kpis?.totalBuyQuantity || 0,
        totalValue: currency ? convertToCurrencyLocaleString(currency, value) : value,
        totalBETQuantity: kpis?.totalForecastQuantity || 0,
        salesQty: kpis?.salesQty || 0
      }
    }

    return map(categories, kpi => {
      const childrenMeta = toJS(kpi.childrenMeta)
      return {
        name: kpi.name || '',
        ...(childrenMeta ? { childrenMeta } : null),
        totalProducts: kpi.totalProducts || 0,
        ...massageKPIs(kpi?.kpis),
        ...(kpi.children
          ? { children: this.recursiveBuildBuyKPIsForCategories(kpi.children) }
          : null)
      }
    })
  }

  /**
   * Method to convert buy KPI response to structure matching with list view mappings
   * @param productBuyKPIData - BUY KPI response
   * @returns - Processed object to be passed to tree view
   */
  processBuyKPIResponse = memoize((productBuyKPIData: any) => {
    return {
      categories: this.recursiveBuildBuyKPIsForCategories(
        productBuyKPIData?.data?.productHierarchy?.categories || []
      )
    }
  })
}

export const wholesaleCollectionService = new WholesaleCollectionService()
