export enum BuyingGroupMode {
  imageUpload = 'imageUpload'
}

export enum BuyingGroupSettingViews {
  Images = 'images',
  Campaign = 'campaign',
  DeliveryWindow = 'deliveryWindow',
  Drops = 'drops',
  Clients = 'clients',
  SyncBSGData = 'syncBSGData',
  WorldWide = 'worldwide',
  SalesDocType = 'salesDocType',
  BuyingSessionDate = 'buyingSessionDate',
  SalesPeriods = 'salesPeriods'
}

export enum BuyingGroupSettingSectionName {
  Images = 'images',
  Campaign = 'campaign',
  DeliveryWindow = 'deliveryWindow',
  Drops = 'drops',
  Clients = 'clients',
  SyncBSGData = 'syncBSGData',
  WorldWide = 'worldWide',
  SalesDocType = 'salesDocType',
  BuyingSessionDate = 'buyingSessionDate'
}

export enum BuyingGroupSettingsDisplayName {
  WorldWide = 'WORLDWIDE',
  Images = 'Images',
  Campaign = 'Campaign',
  DeliveryWindow = 'Delivery Window',
  Drops = 'Drops',
  Clients = 'MARKET',
  SyncBSGData = 'Sync Data',
  SalesDocType = 'Sales Doc Type',
  BuyingSessionDate = 'Price Validity Date'
}
