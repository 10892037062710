import { SecondaryButton } from '@components/UI-Components/SecondaryButton'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { GET_BUYINGSESSIONS } from '../graphql/buyingsessions'

interface IDeleteBuyingSessionDialog {
  open: boolean
  handleClose: () => void
  handleDelete: (any) => any
  selectedBuyingSession: string
  match?: any
}

@observer
@(withRouter as any)
export class DeleteBuyingSessionDialog extends React.Component<IDeleteBuyingSessionDialog> {
  @observable deleteInProgress = false

  handleDeleteBuyingSession = async () => {
    const {
      props: {
        handleDelete,
        selectedBuyingSession,
        handleClose,
        match: {
          params: { yearId }
        }
      }
    } = this

    Object.assign(this, { deleteInProgress: true })

    await handleDelete({
      variables: {
        sessionId: selectedBuyingSession
      },
      update: proxy => {
        // Read the data from our cache for this query.
        const { buyingSessions, salesYear } = proxy.readQuery({
          query: GET_BUYINGSESSIONS,
          variables: {
            // TODO: Move it to store
            orderBy: 'createdAt_ASC',
            SalesYearId: yearId
          }
        })

        // Update buyingSessions
        const remainingnBuyingSessions = buyingSessions.filter(
          buyingSession => buyingSession.id !== selectedBuyingSession
        )

        // Write our data back to the cache.
        proxy.writeQuery({
          query: GET_BUYINGSESSIONS,
          variables: {
            // TODO: Move it to store
            orderBy: 'createdAt_ASC',
            SalesYearId: yearId
          },
          data: { buyingSessions: remainingnBuyingSessions, salesYear }
        })
      }
    })

    Object.assign(this, { deleteInProgress: false })
    handleClose()
  }

  render() {
    const {
      props: { open, handleClose },
      handleDeleteBuyingSession,
      deleteInProgress
    } = this

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Delete Buying Session</DialogTitle>
        <DialogContent>
          <DialogContentText
            id='alert-dialog-description'
            style={{ color: `rgba(0, 0, 0, 0.87)` }}
          >
            Regional Merchandisers and Central Merchandisers will no longer be able to
            access the Buying Session.
            <br />
            <br />
            Are you sure you would like to delete the session?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <SecondaryButton onClick={handleClose}>Cancel</SecondaryButton>
          <SecondaryButton
            autoFocus
            onClick={handleDeleteBuyingSession}
            disabled={deleteInProgress}
          >
            Delete
          </SecondaryButton>
        </DialogActions>
      </Dialog>
    )
  }
}
