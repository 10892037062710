import { StyledProps } from '@components/3rd-party/mui'
import CoreIcon from '@components/std-components/CoreIcon'
import { withIconHOC } from '@components/std-components/Icons'
import { defaultProductCardImage } from '@resources/images'
import classNames from 'classnames'
import React from 'react'
import styled from 'styled-components'
import { ProductStatus, ProductRecommended } from '../ProductCardOld'
import { ProductMedia } from '../ProductCardOld/ProductMedia'
import { NewFlagIcon } from '../svg'
import * as css from './StandardListViewProductInfo.css'

interface IProps extends StyledProps {
  image?: string
  status?: string
  onClick?: any
  topRightIcon?: any
  onTopRightIconClick?: any
  bottomRightIcon?: any
  onBottomRightIconClick?: any
  iconWidth?: number
  maxHeight?: number
  maxWidth?: number
  disabled?: boolean
  isActive?: boolean
  fitStyle?: string
  topRightIconTop?: number
  bottomLeftIcon?: any
  onbottomLeftIconClick?: any
  cursorPointer?: boolean
  isRecommended?: boolean
  isCore?: boolean
  hasCounterOffer?: boolean
}

const IMAGE_WIDTH = 191
export const STANDARDLISTVIEWPRODUCTHEIGHT = 209

const RAISED_EFFECT_WIDTH = 4
const RAISED_EFFECT_HEIGHT = 0

const CardContainer = styled.div<any>(
  (props: any) =>
    ({
      '&&': {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        width: props.maxWidth ? props.maxWidth : IMAGE_WIDTH,
        position: 'relative',
        backgroundColor: !props.isActive && 'white',
        opacity: !props.isActive && 0.6
      }
    } as any)
)

export const StandardListViewProductInfo = React.memo((props: IProps) => {
  const {
    image,
    isActive,
    status,
    onClick,
    topRightIcon,
    onTopRightIconClick,
    bottomRightIcon,
    onBottomRightIconClick,
    iconWidth,
    maxHeight,
    maxWidth,
    disabled,
    fitStyle,
    topRightIconTop,
    bottomLeftIcon,
    onbottomLeftIconClick,
    cursorPointer,
    isRecommended,
    isCore,
    hasCounterOffer
  } = props
  const computedWidth =
    isRecommended && maxWidth ? maxWidth - RAISED_EFFECT_WIDTH : maxWidth || IMAGE_WIDTH
  const computedHeight =
    isRecommended && maxHeight
      ? STANDARDLISTVIEWPRODUCTHEIGHT - RAISED_EFFECT_HEIGHT
      : maxHeight || STANDARDLISTVIEWPRODUCTHEIGHT
  const FlaggedIcon = withIconHOC({
    imageSrc: NewFlagIcon,
    className: classNames(css['flaggedIcon'], {
      [css['top-shifted-flagged-icon']]: !isActive
    })
  })
  return (
    <CardContainer maxWidth={computedWidth} isActive={isActive}>
      {status ? <ProductStatus status={status} statusTagStyle={css.statusTag} /> : null}
      {isRecommended ? <ProductRecommended /> : null}
      {isCore ? <CoreIcon isInactiveFlagPresent={!isActive} /> : null}
      {hasCounterOffer && <FlaggedIcon />}
      <ProductMedia
        classes={css.cursorPointer}
        image={image ? image : defaultProductCardImage}
        imgWidth={computedWidth}
        width={computedWidth}
        height={computedHeight}
        onClick={onClick ? onClick : null}
        disabled={disabled}
        iconWidth={iconWidth}
        topRightIcon={topRightIcon}
        onTopRightIconClick={onTopRightIconClick}
        bottomRightIcon={bottomRightIcon}
        onBottomRightIconClick={onBottomRightIconClick}
        fitStyle={fitStyle}
        margin={'0 0 0 1px'}
        topRightIconTop={topRightIconTop}
        onBottomLeftIconClick={onbottomLeftIconClick}
        bottomLeftIcon={bottomLeftIcon}
        cursorPointer={cursorPointer}
        isRecommended={isRecommended}
        status={status}
      />
    </CardContainer>
  )
})
