import GET_DELIVERY_WINDOW from './deliveryWindow.graphql'
import CREATE_DELIVERY_WINDOW from './createDeliveryWindow.graphql'
import EDIT_DELIVERY_WINDOW from './editDeliveryWindow.graphql'
import DELETE_DELIVERY_WINDOW from './deleteDeliveryWindow.graphql'
import SET_DELIVERY_WINDOW_FOR_BUYING_SESSION_GROUP from './setDeliveryWindowForBuyingSessionGroup.graphql'
import GET_DELIVERY_WINDOW_FOR_BUYING_SESSION_GROUP from './getDeliveryWindowForBuyingSessionGroup.graphql'

export * from './hooks'
export {
  GET_DELIVERY_WINDOW,
  CREATE_DELIVERY_WINDOW,
  EDIT_DELIVERY_WINDOW,
  DELETE_DELIVERY_WINDOW,
  SET_DELIVERY_WINDOW_FOR_BUYING_SESSION_GROUP,
  GET_DELIVERY_WINDOW_FOR_BUYING_SESSION_GROUP
}
