import { useDebouncedCallback } from '@hooks/debounced-callback'
import Chip from '@material-ui/core/Chip'
import React from 'react'
import { CrossIcon } from '../svg'
import { Tag } from '../Tag'
import css from './ProductCardChip.css'

interface IProductCardChip {
  onDelete?: any
  onClick?: any
  node?: any
  chip?: any
}

export const StandardChip: React.FunctionComponent<IProductCardChip> = ({
  onDelete,
  onClick,
  node,
  chip
}) => {
  const debouncedDeleteHandler = useDebouncedCallback(() => {
    onDelete(node, chip.id)
  }, 100)

  return (
    <Chip
      classes={{
        label: css.label,
        root: css.chipRoot
      }}
      deleteIcon={<img src={CrossIcon} className={css.cursor} alt='' />}
      {...(onDelete ? { onDelete: debouncedDeleteHandler } : {})}
      className={css.chip}
      label={
        <Tag
          name={chip.title}
          tagStyle={css.tag}
          color={chip.color}
          clusterStatus={chip.subtitle}
          customeToolTipText={chip.tooltip}
        />
      }
      onClick={onClick}
    />
  )
}
