// TODO: Delete file not needed

import { CrossIcon } from '@components/UI-Components/svg'
import Chip from '@material-ui/core/Chip'
import Tooltip from '@material-ui/core/Tooltip'
import { ChipListEnum } from '@modules/common/models'
import React, { memo } from 'react'
import styled from 'styled-components'
import { ChipLabel } from './ChipLabel'
import ColorCircle from './ColorCircle'
import { IChipList, IStyledChip } from './Interface'

const StyledChip = styled(({ ...props }) => (
  <Chip classes={{ label: 'label' }} {...props} />
))((props: IStyledChip) => {
  return {
    '&&': {
      margin: 'var(--chip-margin)',
      borderRadius: 'var(--chip-border-radius)',
      backgroundColor: 'var(--chip-background-color)',
      height:
        props.chipsize === ChipListEnum.small
          ? 'var(--chip-size-small)'
          : 'var(--chip-size-normal)',
      '&& .label': {
        paddingRight: 5,
        flex: '1 0 auto'
      }
    }
  }
})

const StyledContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'hidden'
})

const StyledChipListWrapper = styled.div({
  height: 'var(--cell-height)',
  padding: 'var(--cell-padding)'
})

export const ChipList: React.FunctionComponent<IChipList> = memo(
  ({
    tags,
    type,
    product,
    chipsize = ChipListEnum.normal,
    handleChipDelete,
    handleClick
  }) => {
    // NOTE: Not wrapping this function with useObserver since we are only using actions from store here
    const onScroll = event => {
      event.stopPropagation()
    }
    return (
      <StyledContainer className='standardScrollBar' onScroll={onScroll}>
        {tags &&
          tags.length > 0 &&
          tags.map(tag => {
            const { color, title, subtitle, id: tagId, tooltip } = tag
            const titleLabel = `${subtitle ? `${title} | ${subtitle}` : title}`
            return (
              <StyledChipListWrapper key={tagId}>
                <Tooltip title={tooltip || titleLabel}>
                  <div>
                    <StyledChip
                      clickable={true}
                      chipsize={chipsize}
                      icon={color && <ColorCircle color={color} />}
                      label={<ChipLabel title={title} subtitle={subtitle} />}
                      onDelete={
                        handleChipDelete
                          ? handleChipDelete(tag, type, product!)
                          : undefined
                      }
                      onClick={
                        handleClick
                          ? handleClick(product?.buyingSessionProductId, tagId)
                          : undefined
                      }
                      deleteIcon={<img src={CrossIcon} alt='' />}
                    />
                  </div>
                </Tooltip>
              </StyledChipListWrapper>
            )
          })}
      </StyledContainer>
    )
  }
)
