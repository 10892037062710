import { StyledProps } from '@components/3rd-party/mui'
import { ProductInfo } from '@components/UI-Components/ProductCardOld/ProductInfo'
import { ProductInfoItem } from '@components/UI-Components/ProductCardOld/ProductInfoItem'
import { ProductMedia } from '@components/UI-Components/ProductCardOld/ProductMedia'
import { ProductStatus } from '@components/UI-Components/ProductCardOld/ProductStatus'
import { TrashIcon } from '@components/UI-Components/svg'
import { Icons } from '@icons'
import { constants } from '@modules/retail/buyingSession/constants'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { IPermissions } from '@routes'
import { getFilteredAttributes, getProductDetails } from '@services/collectionServices'
import { getImageUrl } from '@services/imageService'
import { stores, strings } from '@stores'
import { observer } from 'mobx-react'
import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import styled from 'styled-components'

//region Styles
const IMAGE_WIDTH = 114
const IMAGE_CONTAINER_WIDTH = 145
const SELECT_FIELD_WIDTH = 140
const TEXT_FIELD_WIDTH = 120

const StyledProductActions = styled.div`
  display: flex;
  margin: var(--spacing-unit) 0;
`

const StyledProductDetail = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: calc(2 * var(--spacing-unit));
  div {
    padding: 0px 0px 6px 0px;
    text-align: left;
  }
  span {
    text-align: left;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    line-height: 1.1;
    margin-top: 4px;
  }
`

const Root = styled.div`
  display: flex;
`

export const StyledAssortmentProductDetailMediaContainer = styled.div`
  position: relative;
  margin-top: 16px;
`

const styles = theme => ({
  textfield: {
    width: TEXT_FIELD_WIDTH
  },
  borderRight: {
    borderRight: '1px solid #e1e1e1'
  },
  cursorPointer: {
    cursor: 'pointer'
  },
  hidden: {
    display: 'none'
  },
  marginTop: {
    marginTop: '10px'
  },
  tag: {
    position: 'absolute',
    top: 6,
    right: 1,
    borderRadius: 2,
    color: theme.colors.tagBackground,
    fontSize: 18,
    cursor: 'pointer'
  },
  selectFieldWidth: {
    width: SELECT_FIELD_WIDTH
  },
  productInfoItem: {
    borderTop: '1px solid #eeeeee'
  }
})
//endregion

interface MyProps extends StyledProps {
  statuses?: any
  addProductStatus?: (id, statusId) => void
  assortment?: any
  updateProductMOQ?: (id, value) => void
  product?: any
  match?: any
  history?: any
  permissions?: IPermissions
  buyingSession?: any
  assortmentProductId?: String
  deleteProduct?: (id: any, product?: any) => any
  handleTagDrop?: (product: any, type: any, id: string) => void
  isRed?: boolean
}

@((withStyles as any)(styles))
@(withRouter as any)
@observer
export class AssortmentProductDetail extends React.Component<MyProps> {
  static styles = { ProductActions: StyledProductActions }

  getProductStatus = (status, isActive) => {
    let productStatus = null
    if (isActive) {
      if (status) {
        if (status !== 'NON_CORE') {
          productStatus = status
        }
      } else {
        productStatus = 'CORE'
      }
    } else {
      productStatus = 'inactive'
    }
    return productStatus
  }

  canDeleteProduct = productStatus => {
    const [
      {
        props: { buyingSession }
      },
      {
        sidebar: { isRegionalMerchandiser },
        site: { isGlobalMerchandiserOrAdmin },
        nav: {
          params: { assortmentType }
        }
      }
    ] = [this, stores]
    const canDelete =
      buyingSession &&
      buyingSession.status !== constants.CLOSED &&
      buyingSession.status !== constants.FROZEN &&
      productStatus !== 'CORE'
    if (canDelete && isRegionalMerchandiser()) {
      return true
    } else if (
      canDelete &&
      isGlobalMerchandiserOrAdmin() &&
      assortmentType &&
      assortmentType === strings.cluster
    ) {
      return true
    } else {
      return false
    }
  }

  render() {
    const {
        props: {
          className,
          classes,
          assortment,
          buyingSession,
          product,
          assortmentProductId,
          match,
          isRed,
          style
        },
        canDeleteProduct
      } = this,
      {
        assortment: { getAssortmentType, deleteAssortmentProductFromGridView },
        nav: {
          history: {
            location: { pathname, search }
          }
        }
      } = stores

    const assortmentType = getAssortmentType(match)
    const {
      mmcName,
      colorName,
      description,
      image,
      id,
      productRetailStatus,
      productStatus,
      attributes,
      serieDescription
    } = getProductDetails(
      product,
      false,
      assortmentType === strings.cluster ? assortment.cluster : null
    )
    const isActive = product && product.product ? product.product.active : null
    const canDelete =
      buyingSession &&
      buyingSession.status !== constants.CLOSED &&
      buyingSession.status !== constants.FROZEN &&
      productStatus !== 'CORE'
    const filteredAttributes = getFilteredAttributes(attributes)
    return (
      <Root className={className} style={style}>
        <StyledAssortmentProductDetailMediaContainer>
          <Link
            to={{
              pathname: `${pathname}/details/${id}`,
              search: search
            }}
          >
            <div>
              {this.getProductStatus(productStatus, isActive) && (
                <ProductStatus status={this.getProductStatus(productStatus, isActive)} />
              )}
            </div>
            <ProductMedia
              className={classes.cursorPointer}
              image={getImageUrl(image)}
              imgWidth={IMAGE_WIDTH}
              width={IMAGE_CONTAINER_WIDTH}
              height={IMAGE_CONTAINER_WIDTH}
            />
            {canDeleteProduct(productStatus) && (
              <img
                src={TrashIcon}
                className={classes.tag}
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  if (canDelete) {
                    deleteAssortmentProductFromGridView(assortmentProductId, product)
                  }
                }}
                alt=''
              />
            )}
          </Link>
        </StyledAssortmentProductDetailMediaContainer>
        <StyledProductDetail>
          <ProductInfo
            status={productRetailStatus || ''}
            name={description}
            sku={mmcName}
            ellipsis={false}
          />
          <Typography variant='caption'>{serieDescription}</Typography>
          <Typography variant='caption'>{colorName ? colorName : 'NA'}</Typography>
          {assortmentType !== strings.cluster && isRed ? (
            <div className={classes.marginTop}>
              <Icons iconname='flag' />
            </div>
          ) : null}
          {filteredAttributes.map(
            ({
              value,
              definition: {
                name,
                displayName: { singular }
              }
            }) => {
              return (
                <ProductInfoItem
                  key={name}
                  title={singular}
                  content={<Typography variant='caption'>{value}</Typography>}
                />
              )
            }
          )}
        </StyledProductDetail>
      </Root>
    )
  }
}
