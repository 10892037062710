import { StyledProps } from '@components/3rd-party/mui'
import { PrimaryButton } from '@components/UI-Components/PrimaryButton'
import { config } from '@config'
import { ClusteringRow } from '@modules/retail/clusterSetup/component'
import { withStyles } from '@material-ui/core/styles'
import { downloadFile } from '@services/downloadFileService'
import { stores as storesObj } from '@stores'
import moment from 'moment'
import React from 'react'

const styles = () => ({
  borderRight: {
    borderRight: '1px solid #e1e1e1'
  },
  storeDetailsTable: {
    margin: 10
  },
  ClusteringTable: {
    margin: '10px 10px 20px 10px',
    overflowY: 'hidden'
  },
  clusteringContainer: {
    overflow: 'auto',
    height: 'calc(100vh - 120px)',
    paddingBottom: '20px'
  },
  exportBtn: {
    margin: '16px 0px 8px 24px'
  }
})

interface MyProps extends StyledProps {
  stores?: any
  genders?: any
  activities?: any
  clusters?: any
}
@((withStyles as any)(styles))
export class ClusterSetup extends React.Component<MyProps> {
  rowHeights = new Array(this.props.stores.length)
    .fill(true)
    .map(
      () => 140 + (this.props.clusters.length ? 100 : 0) + this.props.clusters.length * 57
    )

  getItemSize = index => this.rowHeights[index]

  onExcelExportClick = async () => {
    const [
      { stores },
      {
        assortment: { updateExportError }
      }
    ] = [this.props, storesObj]
    if (stores && stores.length) {
      const fileName = `StoreClusterMapping-${moment(new Date()).format(
        'DD/MM/YYYY'
      )}.xlsx`
      const downloadResponse = await downloadFile(
        `${config.dataImporter}stores/clustering/export`,
        fileName
      )
      if (downloadResponse.error && downloadResponse.message) {
        updateExportError(downloadResponse.message)
      }
    }
  }

  render() {
    const {
      props: { classes, stores, genders, activities, clusters }
    } = this
    return (
      <div className={classes.clusteringContainer}>
        {stores && stores.length ? (
          <PrimaryButton className={classes.exportBtn} onClick={this.onExcelExportClick}>
            Export Clustering
          </PrimaryButton>
        ) : null}
        <ClusteringRow
          className={classes.ClusteringTable}
          stores={stores}
          genders={genders}
          activities={activities}
          clusters={clusters}
        />
      </div>
    )
  }
}
