/*
 * @Author: Raeesaa Metkari
 * @Date: 2019-12-11 13:26:10
 * @Last Modified by: Raeesaa Metkari
 * @Last Modified time: 2019-12-12 12:22:21
 */

import { ListViewFieldMapping } from '../../../../modules/common/models/enums/ListViewFieldMapping'
import { CellProps } from './CellProps'
import { HeaderCellDefn } from './HeaderColDef'

interface HierarchyCategoryCellDefn {
  /**
   * Key for mapping data in category header cell
   */
  key?: ListViewFieldMapping

  /**
   * Flag speciying if data is to be rendered in category header cell
   */
  showKPI: boolean
}

interface ProductCellDefn {
  /**
   * Key for mapping data in category header cell
   */
  key: ListViewFieldMapping
  /**
   * Function that returns react component to be rendered in product cell
   */
  component: Function

  /**
   * Path of field in case of nested objects
   */
  path?: ListViewFieldMapping

  /**
   * Flag for specifying if left/right borders is to be added to cell
   */
  showLeftBorder?: boolean
  showRightBorder?: boolean
}

/**
 * Column definition mapping props
 */
interface ListviewColumnDefinitionProps {
  id: string
  width: number
  isVisible?: boolean
  isFixed?: boolean
  isRootColumn?: boolean
  showFlagIcon?: boolean
  isFloatingPointValue?: boolean
  props?: CellProps
  category: HierarchyCategoryCellDefn
  product: ProductCellDefn
  header: HeaderCellDefn
  font?: string
  fontWeight?: any
  valueFormatter?: any
}

export interface ListViewRowSelectionConf {
  padding?: string
  width?: number
  component: Function
}

export class ListViewColumnDefinition {
  /**
   * Unique identifier for column, will be required for updating defn on user action
   * (e.g. toggle isVisible flag)
   */
  id: string

  /**
   * Width of column in pixels
   */
  width: number

  /**
   * Flag specifying if column should be rendered.
   * Defaults to true
   */
  isVisible: boolean = true

  /**
   * Flag specifying id column should be fixed
   * Defaults to false
   */
  isFixed: boolean = false

  /**
   * Flag specifying if column is root
   * Defaults to false
   */
  isRootColumn: boolean = false

  /**
   * Flag specifying if column value is float
   * Defaults to false
   */
  isFloatingPointValue: boolean = false

  /**
   * Props to be passed to components rendered in category hierarchy
   * and product rows
   */
  props?: CellProps

  header: HeaderCellDefn

  /**
   * Properties required for rendering category hierarchy cell for given column
   */
  category: HierarchyCategoryCellDefn

  /**
   * Properties required for rendering product cell for given column
   */
  product: ProductCellDefn

  valueFormatter: any

  constructor(props: ListviewColumnDefinitionProps) {
    this.id = props.id
    this.width = props.width
    this.isVisible = props.isVisible || true
    this.isFixed = props.isFixed || false
    this.isRootColumn = props.isRootColumn || false
    this.isFloatingPointValue = props.isFloatingPointValue || false
    this.header = {
      label: props.header.label,
      showFlagIcon: props.header ? props.header.showFlagIcon || false : false,
      isDisabled: props.header ? props.header.isDisabled || false : false,
      showTooltip: props.header.showTooltip || false
    }
    this.props = props.props
    this.category = props.category
    this.product = props.product
    this.valueFormatter = props.valueFormatter
  }
}
