/*
 * @Author: Raeesaa Metkari
 * @Date: 2019-08-21 05:20:21
 * @Last Modified by: Raeesaa Metkari
 * @Last Modified time: 2019-12-24 00:18:46
 */

import { SelectionMode } from '@grapecity/wijmo.grid'
import * as css from './StoreOTBWeightSettingsTable.css'
import { apolloClient, stores } from '@stores'
import { GET_STORE_WEIGHTS, UPSERT_STORE_WEIGHT } from '../graphql'
import { useObserver } from 'mobx-react-lite'
import { useEffect } from 'react'
import { wjGrid, wjGridFilter } from '@components/3rd-party/wijmo'
import React from 'react'
import { MessageDialog } from '@components/UI-Components/MessageDialog'

interface StoreWeight {
  storeId: string
  storeLabel: string
  activityId: string
  activityLabel: string
  genderId: string
  genderLabel: string
  weightId: string
  weightLabel: number
}

interface MyProps {
  storeWeights: Array<StoreWeight>
  loading: boolean
}

const headers = [
  {
    id: 'storeName',
    label: 'Store Name',
    isReadOnly: true
  },
  {
    id: 'storeLabel',
    label: 'Store Code',
    isReadOnly: true
  },
  {
    id: 'genderLabel',
    label: 'Gender',
    isReadOnly: true
  },
  {
    id: 'activityLabel',
    label: 'Activity',
    isReadOnly: true
  },
  {
    id: 'hasClustering',
    label: 'Clustering',
    isReadOnly: true
  },
  {
    id: 'weightLabel',
    label: 'Store Weight (%)',
    isReadOnly: false
  }
]

const initializeGrid = flex => {
  const grid = flex.grid
  const {
    storeSettings: { onValidationError, setFilterDefinition, setSelectionSet }
  } = stores

  flex.filterApplied.addHandler(async s => {
    setFilterDefinition(s.filterDefinition)
  })

  grid.selectionChanged.addHandler((s, e) => {
    setSelectionSet(s ? s.selection : null)
  })

  grid.itemsSourceChanged.addHandler(async (s, e) => {
    const {
      storeSettings: { lastFilterDefinition, lastSelection }
    } = stores
    if (lastFilterDefinition) {
      flex.filterDefinition = lastFilterDefinition
    }

    if (lastSelection) {
      grid.selection = lastSelection
    }
  })

  grid.cellEditEnding.addHandler(async (s, e) => {
    const {
      storeSettings: { selectedZone }
    } = stores
    let col = s.columns[e.col]
    const dataItem = grid.rows[e.row] ? grid.rows[e.row].dataItem : null
    if (col.binding === 'weightLabel') {
      let value = s.activeEditor ? s.activeEditor.value : ''
      if (isNaN(value)) {
        onValidationError('Please enter only numbers.')
        e.cancel = true
        return
      }

      let floatNumber = value ? parseFloat(value) : null
      // Allow null and numbers between 0 to 100
      if (!floatNumber || (floatNumber >= 0 && floatNumber <= 100)) {
        if (dataItem) {
          await apolloClient.mutate({
            mutation: UPSERT_STORE_WEIGHT,
            variables: {
              storeId: dataItem.storeId,
              activityId: dataItem.activityId,
              genderId: dataItem.genderId,
              weight: floatNumber
            },
            refetchQueries: [
              {
                query: GET_STORE_WEIGHTS,
                variables: {
                  zoneId: selectedZone
                }
              }
            ]
          })
        }
        return
      } else {
        onValidationError('Store weight must be between 0 and 100.')
        e.cancel = true
      }
    }
  })

  grid.formatItem.addHandler((s, e) => {
    let item =
      e.row !== null && e.row !== undefined && grid.rows[e.row]
        ? grid.rows[e.row].dataItem
        : null
    if (item && item.validationError) {
      e.cell.classList.add('highlight-mismatch')
    }
  })
}

const handleMessageDialogClose = () => {
  const {
    storeSettings: { onValidationError }
  } = stores
  onValidationError('')
}

export const StoreOTBWeightSettingsTable: React.FunctionComponent<MyProps> = ({
  storeWeights,
  loading
}) => {
  return useObserver(function useHook() {
    const {
      storeSettings: { validationErrorMessage, setFilterDefinition }
    } = stores

    // Clear grid on unmount
    useEffect(() => {
      return () => {
        setFilterDefinition(null)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <>
        <wjGrid.FlexGrid
          itemsSource={storeWeights}
          allowSorting={true}
          selectionMode={SelectionMode.Cell}
          className={css.table}
        >
          {headers.map(header => (
            <wjGrid.FlexGridColumn
              key={header.id}
              // format={header.format}
              binding={header.id}
              header={header.label}
              isReadOnly={header.isReadOnly}
              isRequired={false}
              width='*'
              allowResizing
            />
          ))}
          <wjGridFilter.FlexGridFilter initialized={initializeGrid} />
        </wjGrid.FlexGrid>
        {validationErrorMessage ? (
          <MessageDialog
            open={validationErrorMessage !== ''}
            title={'Error in updating store weight'}
            message={validationErrorMessage}
            handleClose={handleMessageDialogClose}
          />
        ) : null}
      </>
    )
  })
}
