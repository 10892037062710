import { StyledProps } from '@components/3rd-party/mui'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import { DragLayer } from 'react-dnd'
import ReactDOM from 'react-dom'

// Global drag preview component, which will be visible on dragging
const dragPreviewRoot = document.getElementById('drag-preview-root')

const styles = theme => ({
  sourcePreview: {
    background: '#888888',
    opacity: 0.8,
    position: 'fixed',
    cursor: 'move',
    zIndex: 16000,
    left: 0,
    top: 0,
    pointerEvents: 'none',
    '-webkit-touch-callout': 'none'
  }
})

const collect = monitor => {
  return {
    sourceOffset: monitor.getSourceClientOffset()
  }
}

interface MyProps extends StyledProps {
  isDragging?: boolean
  sourceOffset?: { x: number; y: number }
}

@((DragLayer as any)(collect))
@((withStyles as any)(styles))
export class DragPreview extends React.Component<MyProps> {
  el: HTMLElement

  constructor(props) {
    super(props)
    this.el = document.createElement('div')
  }

  componentDidMount() {
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
    dragPreviewRoot.appendChild(this.el)
  }

  componentWillUnmount() {
    dragPreviewRoot.removeChild(this.el)
  }

  getLayerStyles() {
    const { sourceOffset } = this.props

    return {
      transform: sourceOffset ? `translate(${sourceOffset.x}px, ${sourceOffset.y}px)` : ''
    }
  }

  render() {
    const { isDragging, children, classes } = this.props

    if (!isDragging) {
      return null
    }

    return ReactDOM.createPortal(
      <span className={classes.sourcePreview} style={this.getLayerStyles()}>
        {children}
      </span>,
      this.el
    )
  }
}
