import OPEN_ASSORTMENT_REVIEW_REQUEST from './openAssortmentReviewRequest.graphql'
import CREATE_ASSORTMENT_REVIEW_REQUEST from './createAssortmentReviewRequest.graphql'
import SUBMIT_ASSORTMENT_REVIEW from './submitAssortmentReview.graphql'
import LAST_ASSORTMENT_REVIRW_REQUEST from './lastAssortmentReviewRequest.graphql'
import GET_ZONE_AND_SEASON_BY_ID from './getZoneAndSeasonById.graphql'

export {
  OPEN_ASSORTMENT_REVIEW_REQUEST,
  CREATE_ASSORTMENT_REVIEW_REQUEST,
  SUBMIT_ASSORTMENT_REVIEW,
  LAST_ASSORTMENT_REVIRW_REQUEST,
  GET_ZONE_AND_SEASON_BY_ID
}
