import Error from '@material-ui/icons/Error'
import { TrashIcon } from '@components/UI-Components/svg'
import { strings } from '@stores'
import { StyledProps } from '@components/3rd-party/mui'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import classNames from 'classnames'

const styles = theme => ({
  root: {
    width: 200,
    borderRadius: 2,
    position: 'relative',
    boxShadow: `0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 0px 1px -1px rgba(0, 0, 0, 0.12),
    0 1px 1px 0 rgba(0, 0, 0, 0.14)`,
    '&:hover': {
      boxShadow: `0 10px 15px -5px rgba(0, 0, 0, 0.2);`
    }
  },
  inactive: {
    backgroundColor: theme.colors.white,
    opacity: 0.6
  },
  tag: {
    position: 'absolute',
    left: 180,
    borderRadius: 2,
    color: '#a9a7a7',
    fontSize: 18,
    zIndex: 1
  },
  cardCursor: {
    cursor: 'pointer'
  },
  restricted: {
    opacity: 0.6
  },
  restrictionText: {
    position: 'absolute',
    top: '11%',
    left: '12%',
    zIndex: '1',
    display: 'flex',
    textAlign: 'center',
    opacity: 0.6
  },
  restrictedProductContainer: {
    display: 'flex',
    position: 'relative',
    width: '100%',
    fontSize: 14,
    fontWeight: 'bold'
  },
  warningIcon: {
    margin: 'auto'
  },
  raisedEffect: {
    boxShadow: `0px 2px 6px -2px var(--grey),
                1px 0px 13px -8px var(--grey), 
                0px 3px 8px -37px var(--grey), 
                10px 3px 6px -29px var(--grey)`,
    '&:hover': {
      boxShadow: `0px 2px 6px -2px var(--grey), 
                  1px 0px 13px -8px var(--grey),
                  0px 3px 8px -37px var(--grey), 
                  10px 3px 6px -29px var(--grey)`
    }
  }
})

interface IProductCard extends StyledProps {
  active?: boolean
  showTag?: boolean
  removeTag?: boolean
  onClick?: () => void
  tagAction?: (e: any) => void
  isRestricted?: boolean
  isRecommended?: boolean
}

@((withStyles as any)(styles))
export class ProductCard extends React.Component<IProductCard> {
  static defaultProps = {
    active: true,
    showTag: false
  }
  getIcon = () => {
    const { showTag, removeTag } = this.props
    if (showTag) {
      return 'local_offer'
    } else if (removeTag) {
      return 'delete_forever'
    } else {
      return null
    }
  }

  renderProductCard = (
    className,
    classes,
    onClick,
    tagAction,
    getIcon,
    removeTag,
    children
  ) => {
    return (
      <div className={classNames(className)} onClick={onClick}>
        {removeTag ? (
          <img src={TrashIcon} className={classes.tag} onClick={tagAction} alt='' />
        ) : null}
        {children}
      </div>
    )
  }

  render() {
    const {
      props: {
        classes,
        children,
        active,
        onClick,
        tagAction,
        isRestricted,
        removeTag,
        isRecommended
      },
      getIcon,
      renderProductCard
    } = this
    const className = [classes.root, classes.cardCursor]

    !active && className.push(classes.inactive)
    isRecommended && className.push(classes.raisedEffect)

    if (isRestricted) {
      className.push(classes.restricted)
    }

    return isRestricted ? (
      <React.Fragment>
        <div className={classes.restrictedProductContainer}>
          <p className={classes.restrictionText}>
            <Error className={classes.warningIcon} fontSize='inherit' />
            {strings.restrictedProductWarning}
          </p>
          {renderProductCard(
            className,
            classes,
            onClick,
            tagAction,
            getIcon,
            removeTag,
            children
          )}
        </div>
      </React.Fragment>
    ) : (
      <React.Fragment>
        {renderProductCard(
          className,
          classes,
          onClick,
          tagAction,
          getIcon,
          removeTag,
          children
        )}
      </React.Fragment>
    )
  }
}
