import Icon from '@components/std-components/Icons'
import { PulseProgress } from '@components/UI-Components/svg'
import MdAdd from '@meronex/icons/md/MdAdd'
import MdRemove from '@meronex/icons/md/MdRemove'
import React from 'react'
import HeartContrastOutline from '@components/std-components/HeartContrastOutlineIcon'

export enum SAActionsType {
  basketInProgress = 'Basket toggle in progress',
  removeFromBasket = 'Remove a product from the basket',
  addToBasket = 'Add a product to the basket',
  removeFavourite = 'Remove a product from favourites',
  addFavourite = 'Add a product to favourites'
}

export interface ISAActions {
  actionType: SAActionsType
}

export const SAActions: React.FC<ISAActions> = ({ actionType }) => {
  switch (actionType) {
    case SAActionsType.basketInProgress:
      return <Icon imageSrc={PulseProgress} />
    case SAActionsType.removeFromBasket:
      return <MdRemove size={22} />
    case SAActionsType.addToBasket:
      return <MdAdd size={22} />
    case SAActionsType.removeFavourite:
      return <HeartContrastOutline />
    case SAActionsType.addFavourite:
      return <HeartContrastOutline fillColor={'#ffffff'} strokeColor={'#000000'} />
    default:
      return null
  }
}
