export enum ClientTableViewColumnLabels {
  ClientCode = 'Market Code',
  ZoneCode = 'Zone Code',
  Active = 'Active',
  Name = 'Name',
  CustomerGroup = 'Customer Group',
  Master = 'Master',
  ReorderStatus = 'Reorder',
  Country = 'Country',
  Zone = 'Sales Region',
  Doors = 'Doors',
  Open = 'Open',
  PriceGroup = 'Price Group',
  SalesDistrict = 'Sales District',
  Actions = 'Actions',
  Channel = 'Channel'
}
