import { StyledProps } from '@components/3rd-party/mui'
import { grey } from '@material-ui/core/colors'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import has from 'lodash/has'
import { observer } from 'mobx-react'
import React from 'react'
import { DropTarget as dropTarget, DropTargetSpec } from 'react-dnd'
import * as css from './DropTarget.css'

const ItemTypes = {
  LIBRARYITEM: 'libraryItem',
  NATIVE_TYPES: '__NATIVE_FILE__'
}

const styles = theme => ({
  notAllowed: {
    background: grey[400],
    opacity: 0.2
  },
  allowed: {
    opacity: 0.2
  }
})

const itemTarget: DropTargetSpec<any> = {
  drop(props, monitor) {
    if (monitor.getItem().files) {
      props.handleDrop(monitor.getItem().files, props)
    } else {
      const sourceLibraryItem =
        monitor.getItem().libraryItem ||
        (monitor.getItem().children && monitor.getItem().children.props.libraryItem)
      props.handleDrop(sourceLibraryItem, props)
    }
  },
  // Get it from prop
  canDrop(props, monitor) {
    const { subSectionTypeName, canDrop } = props
    // You can disallow drop based on props or item
    const sourceLibraryItem =
      monitor.getItem().libraryItem ||
      (monitor.getItem().children && monitor.getItem().children.props.libraryItem)
    return subSectionTypeName
      ? sourceLibraryItem && sourceLibraryItem.type.name === subSectionTypeName
      : has(props, 'canDrop')
      ? canDrop
      : true
  }
}

function collectDrop(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  }
}

interface MyProps extends StyledProps {
  className?: any
  connectDropTarget?: (c: React.ReactNode) => React.ReactNode
  handleDrop?: Function
  showDropBackground?: boolean
  isOver?: boolean
  canDrop?: boolean
  zoneInfo?: any
  libraryItem?: any
  allowedDropEffect?: string
}

@((withStyles as any)(styles))
@((dropTarget as any)(Object.values(ItemTypes), itemTarget, collectDrop))
@(observer as any)
export class DropTarget extends React.Component<MyProps> {
  render() {
    const {
      connectDropTarget,
      classes,
      isOver,
      canDrop,
      className,
      showDropBackground,
      children
    } = this.props

    return connectDropTarget(
      <div
        className={classNames(
          css.root,
          classes.card,
          className,
          isOver && showDropBackground && (!canDrop ? null : classes.allowed)
        )}
      >
        {children}
      </div>
    )
  }
}
