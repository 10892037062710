import { MutationFunctionOptions } from '@apollo/client/react/types/types'
import { ExecutionResult } from 'graphql/execution'
import { action, observable } from 'mobx'
import { ISeasonMetedata } from '../graphql/hooks'

export class SeasonSyncStore {
  @observable selectedSeasons = []
  @observable isSeasonSelected = false

  mutations: {
    // TODO: Rename key to action
    addSeasonsToSync?: (
      options?: MutationFunctionOptions<any, { seasonIds: string[] }>
    ) => Promise<void | ExecutionResult<{ seasonSyncMetadata: ISeasonMetedata }>>
  } = {}

  @action
  handleToggle = e => {
    const currentIndex = this.selectedSeasons.indexOf(e.target.value)
    if (currentIndex === -1) {
      this.selectedSeasons.push(e.target.value)
    } else {
      this.selectedSeasons.splice(currentIndex, 1)
    }
  }

  getSeasonData = (seasons, seasonsMetaData) => {
    let seasonIds = []
    let sesaonList =
      seasonsMetaData && seasonsMetaData.value
        ? seasonsMetaData.value.split(',').map(item => item.trim())
        : []
    seasons.forEach(({ id, description }) => {
      if (sesaonList.includes(description)) {
        seasonIds.push(id)
      }
    })
    this.selectedSeasons = seasonIds
    this.isSeasonSelected = true
  }

  @action
  handleSubmit = async () => {
    return await this.mutations.addSeasonsToSync({
      variables: { seasonIds: this.selectedSeasons }
    })
  }
}
