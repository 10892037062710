import { CriteriaOperationType, User } from '@models'
import { OrderType, ShowroomMenuEnum } from '@modules/common/models/enums'
import { IPermissions } from '@routes'
import { getAverageRating } from '@services/showroom/SAProductDetailService'
import { storage } from '@services/storageService'
import { stores, strings } from '@stores'
import { getIdSelector } from '@utils/get-id-selector'
import { getPermissionsByEntityAndRoleForWholesale } from '@ws-routes/wholesaleRoutes'
import cloneDeep from 'lodash/cloneDeep'
import { action, observable } from 'mobx'
import { computedFn } from 'mobx-utils'

export class ShowroomProductDetailStore {
  @observable isShowMoreAttributesOpen = false
  @observable processedProduct = null
  @observable userPermission: IPermissions = null
  @observable currentUser = null

  @action clearShowroomProductDetailStore = () => {
    this.processedProduct = null
    this.isShowMoreAttributesOpen = false
  }

  @action toggleIsFavourite = () => {
    if (this.processedProduct) {
      this.processedProduct.isFavourite = !this.processedProduct?.isFavourite
    }
  }

  @action redirectUser = menu => {
    const {
      showroomHeader: { openRoute, menuConfig }
    } = stores
    const config = this.getUrl(menuConfig, menu)
    openRoute(config.url)()
  }

  @action getUrl = (menuConfig, title) => {
    return menuConfig.find(menu => menu.title === title)
  }

  /**
   * on back click redirect user to specific page
   */

  @action onBackClick = () => {
    const {
      nav: {
        location: { pathname }
      }
    } = stores
    if (pathname.includes('client-collection')) {
      this.redirectUser(ShowroomMenuEnum.clientCollection)
    } else if (pathname.includes('lookbook')) {
      this.redirectUser(ShowroomMenuEnum.lookBook)
    } else if (pathname.includes('client-wishlist')) {
      this.redirectUser(ShowroomMenuEnum.clientWishlist)
    } else if (pathname.includes('collection')) {
      this.redirectUser(ShowroomMenuEnum.collection)
    } else {
      this.redirectUser(ShowroomMenuEnum.basket)
    }
  }

  /**
   * where query input for client assortment product
   */

  whereInputForAssortmentProduct = () => {
    const {
      nav: {
        queryParams: { clientId, bspId }
      }
    } = stores

    return {
      GIV_VIPAssortmentProduct_Assortment_ref: {
        VIPClientAssortment: {
          Client: { Id: { operation: CriteriaOperationType.EQ, value: clientId } }
        }
      },
      Original: {
        Id: {
          operation: CriteriaOperationType.EQ,
          value: bspId
        }
      }
    }
  }

  getOrderTypeWhereInput = (orderId, orderType) => {
    const doorOrder = {
      __Parent__: {
        VIPDoorOrder: {
          Id: {
            operation: CriteriaOperationType.EQ,
            value: orderId
          }
        }
      }
    }

    const clientOrder = {
      __Parent__: {
        VIPClientOrder: {
          Id: {
            operation: CriteriaOperationType.EQ,
            value: orderId
          }
        }
      }
    }

    if (orderType === OrderType.Client) {
      return clientOrder
    } else if (orderType === OrderType.Door) {
      return doorOrder
    } else {
      return [doorOrder, clientOrder]
    }
  }

  /**
   * where input for the order product
   */

  whereInputForOrderProduct = () => {
    const {
      nav: {
        queryParams: { orderType, bspId, orderId }
      }
    } = stores

    if (orderId && bspId) {
      return {
        GIV_VIPOrderProduct_BuyingSessionProduct_ref: {
          Id: {
            operation: CriteriaOperationType.EQ,
            value: bspId
          }
        },
        OR: this.getOrderTypeWhereInput(orderId, orderType)
      }
    }
    return null
  }

  /**
   * where input for order product notification
   * Not Used
   */
  whereInputForOrderProductNotification = orderProductData => {
    // Todo: conditional product details
    //  product details can be null sometimes
    //  try to eliminate param
    const {
      showroomCollection: { productDetails }
      // userNotification: { userNotificationWhere }
    } = stores
    const userId = storage.getItem<User>('user')?.id ?? ''
    const productId =
      productDetails && productDetails.orderProductId
        ? productDetails.orderProductId
        : orderProductData?.findVIPOrderProduct?.Items[0]?.Id ?? null

    return {
      Notification: {
        GIV_VIPNotification_OrderProduct_ref: {
          Id: getIdSelector(productId)
        }
      },
      User: userId ? getIdSelector(userId) : undefined
    }
  }

  @action setShowMoreAttributesOpen = isShowMoreAttributesOpen => {
    this.isShowMoreAttributesOpen = isShowMoreAttributesOpen
  }

  @action setProcessedProduct = processedProduct => {
    this.processedProduct = { ...processedProduct }
  }

  setUserPermission = computedFn((entity = strings.buyingSessionsEntity) => {
    this.userPermission = cloneDeep(getPermissionsByEntityAndRoleForWholesale(entity))
  })

  setCurrentUser = computedFn((currentUser: { id: string } = storage.getItem('user')) => {
    this.currentUser = currentUser
  })

  @action updateProcessedProductRating = (ratingTypeId: string, ratingValue: number) => {
    if (this.processedProduct?.ratings?.length) {
      const processedProduct = cloneDeep(this.processedProduct)
      const ratingToUpdate = processedProduct.ratings.findIndex(
        rating => ratingTypeId === rating.Id
      )
      if (-1 !== ratingToUpdate) {
        processedProduct.ratings[ratingToUpdate].Value = ratingValue
        processedProduct.averageRating = getAverageRating(processedProduct.ratings)
        this.setProcessedProduct(processedProduct)
      }
    }
  }
}

export const showroomProductDetailStore = new ShowroomProductDetailStore()
