/**
 * Builds Activity and Gender data
 *
 * @param {array} activities
 * @param {array} genders
 *
 * @returns {array}
 */
const getClusterSettingHeader = (genders, activities) => {
  const clusteringHeaders = []

  genders.forEach(gender => {
    activities.forEach(activity => {
      clusteringHeaders.push({
        name: `${gender.description} ${activity.description}`,
        genderId: gender.id,
        activityId: activity.id
      })
    })
  })

  return clusteringHeaders
}

export { getClusterSettingHeader }
