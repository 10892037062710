import { StyledProps, CentricTheme } from '@components/3rd-party/mui'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'

const styles = (theme: CentricTheme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between'
  }
})

interface IProductDetailInfo extends StyledProps {}

@((withStyles as any)(styles))
export class ProductDetailInfo extends React.Component<IProductDetailInfo> {
  render() {
    const {
      props: { classes, children }
    } = this
    return <div className={classes.container}>{children}</div>
  }
}
