import { strings } from '@stores'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { ClusterAssortmentContainer } from './ClusterAssortment'
import { StoreAssortmentContainer } from './StoreAssortment'
import { ZoneAssortmentContainer } from './ZoneAssortment'

interface IAssortmentType {
  match?: any
  history?: any
  permissions?: any
}

@(withRouter as any)
export class AssortmentTypeContainer extends React.Component<IAssortmentType> {
  render() {
    const {
      props,
      props: {
        match: {
          params: { assortmentType }
        }
      }
    } = this
    switch (assortmentType) {
      case strings.store:
        return (
          <StoreAssortmentContainer
            {...props}
            permissions={props.permissions[strings.store]}
          />
        )
      case strings.zone:
        return (
          <ZoneAssortmentContainer
            {...props}
            permissions={props.permissions[strings.zone]}
          />
        )
      case strings.cluster:
        return (
          <ClusterAssortmentContainer
            {...props}
            permissions={props.permissions[strings.cluster]}
          />
        )
      default:
        return null
    }
  }
}
