import { useQuery } from '@apollo/client/react/hooks/useQuery'
import { GET_BUYING_GROUP_SALES_DOC_TYPE } from '.'

export const useSalesDocTypeForBuyingSessionGroup = (variables, skip, fetchPolicy?) => {
  return useQuery(GET_BUYING_GROUP_SALES_DOC_TYPE, {
    fetchPolicy: fetchPolicy || 'cache-first',
    skip,
    variables
  })
}
