import { StyledProps } from '@components/3rd-party/mui'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import React, { ElementType } from 'react'
import { PrimaryButton } from './PrimaryButton'
import { SecondaryButton } from './SecondaryButton'

export interface IConfirmationDialog extends StyledProps {
  open?: boolean
  loading?: boolean
  handleClose?: any
  handleCancel?: any
  title?: any
  message?: any
  messageEle?: ElementType
  handleDelete?: any
  proceedButtonTitle?: string
  cancelButtonTitle?: string
  hideCancelButton?: boolean
  showBackButton?: boolean
  handleBack?: any
}

export class ConfirmationDialog extends React.Component<IConfirmationDialog> {
  render() {
    const {
      props: {
        open,
        handleClose,
        handleCancel,
        title,
        message,
        handleDelete,
        proceedButtonTitle,
        cancelButtonTitle,
        loading,
        hideCancelButton,
        showBackButton = false,
        handleBack,
        messageEle: MessageElement
      }
    } = this
    return (
      <Dialog
        open={open as boolean}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        disableEscapeKeyDown
      >
        <DialogTitle id='alert-dialog-title'>{title || ''}</DialogTitle>
        <DialogContent>
          <DialogContentText
            id='alert-dialog-description'
            {...(message
              ? {
                  dangerouslySetInnerHTML: { __html: message }
                }
              : {})}
          >
            {MessageElement ? <MessageElement /> : null}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {showBackButton ? (
            <SecondaryButton onClick={handleBack} disabled={loading}>
              {'Back'}
            </SecondaryButton>
          ) : null}
          {!hideCancelButton ? (
            <SecondaryButton
              onClick={() => {
                handleClose?.()
                handleCancel?.()
              }}
              disabled={loading}
            >
              {cancelButtonTitle ? cancelButtonTitle : 'Cancel'}
            </SecondaryButton>
          ) : null}
          <PrimaryButton
            onClick={handleDelete}
            autoFocus
            loading={loading}
            disabled={loading}
          >
            {proceedButtonTitle ? proceedButtonTitle : 'Delete'}
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    )
  }
}
