import { nav } from '@stores/nav'
import { action, computed } from 'mobx'

export class ToolbarStore {
  @computed get open() {
    const {
      queryParams: { toolbar }
    } = nav
    return !toolbar || toolbar !== 'hide'
  }

  set open(open: boolean) {
    nav.updateQueryParams({ toolbar: open ? undefined : 'hide' })
  }

  @action toggle = () => {
    this.open = !this.open
  }
}

export const toolbar = new ToolbarStore()
