import { strings } from '@stores'

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  //
  //
  //  Serie Serie Description Shape LG/ACC  Shipping Group Story Sub family
  {
    label: 'Article',
    value: 'original.style.article',
    withC8Label: false
  },
  {
    label: 'Department',
    value: 'original.activity.name',
    withC8Label: false
  },
  {
    label: 'Brand',
    value: 'info.gender.name',
    withC8Label: false
  },
  {
    label: 'Category',
    value: 'info.category.name',
    withC8Label: false
  },
  {
    label: 'Collection',
    value: 'info.collection.name',
    withC8Label: false
  },
  {
    label: 'Theme',
    value: 'info.complementary',
    withC8Label: false
  },
  {
    label: 'Class',
    value: 'original.family.name',
    withC8Label: false
  },
  // {
  //   label: 'Floor Display Month ',
  //   value: 'info.floorDisplayMonth',
  //   withC8Label: false
  // },
  // {
  //   label: 'Fit',
  //   value: 'info.fit',
  //   withC8Label: false
  // },
  {
    label: 'Heel Height Range',
    value: 'info.heelHeight',
    withC8Label: false
  },
  {
    label: 'Category Name',
    value: 'info.category1',
    withC8Label: false
  },
  {
    label: 'Lining',
    value: 'info.lining',
    withC8Label: false
  },
  {
    label: 'Long Description',
    value: 'original.style.modelDescription',
    withC8Label: false
  },
  {
    label: 'Number of Colors',
    value: 'original.numberOfColors',
    withC8Label: false
  },
  {
    label: 'Product Changes',
    value: 'original.productChanges',
    withC8Label: false
  },
  {
    label: 'Previous Article Number',
    value: 'original.prevStyleCode',
    withC8Label: false
  },
  {
    label: ' Season',
    value: 'info.season.name',
    withC8Label: false
  },
  {
    label: ' Other Material 1',
    value: 'info.secondaryMaterial',
    withC8Label: false
  },
  // {
  //   label: ' Section',
  //   value: 'info.gender.name',
  //   withC8Label: false
  // },
  {
    label: 'SubClass',
    value: 'info.shapeLG',
    withC8Label: false
  },
  // {
  //   label: 'Story',
  //   value: 'info.story',
  //   withC8Label: false
  // },
  {
    label: 'Theme Code',
    value: 'info.themeCode',
    withC8Label: false
  },
  {
    label: 'Designer Name',
    value: 'info.designerName',
    withC8Label: false
  },
  {
    label: 'Strap Type',
    value: 'info.strapType',
    withC8Label: false
  },
  {
    label: 'Closure Type',
    value: 'info.closureType',
    withC8Label: false
  },
  {
    label: 'Compartment',
    value: 'info.compartment',
    withC8Label: false
  },
  {
    label: 'Type',
    value: 'info.type',
    withC8Label: false
  },
  {
    label: 'Depth',
    value: 'info.depth',
    withC8Label: false
  },
  {
    label: 'Width',
    value: 'info.width',
    withC8Label: false
  },
  {
    label: 'Height',
    value: 'info.height',
    withC8Label: false
  },
  {
    label: 'Heel Height',
    value: 'info.heelHeightValue',
    withC8Label: false
  },
  {
    label: 'Toebox',
    value: 'info.toebox',
    withC8Label: false
  },
  {
    label: 'Country Of Origin',
    value: 'info.countryOfOrigin',
    withC8Label: false
  },
  {
    label: 'Sku Product Name',
    value: 'info.skuProductName',
    withC8Label: false
  },
  {
    label: 'Drop Week',
    value: 'info.dropWeek',
    withC8Label: false
  },
  {
    label: 'Sku Product Branding',
    value: 'info.skuProductBranding',
    withC8Label: false
  },
  {
    label: 'Sku Hierarchy',
    value: 'info.skuHierarchy',
    withC8Label: false
  }
]

// retail
export const RETAIL_DETAILS_MAPPING = [
  {
    label: strings.retailStatus,
    value: 'info.retailStatus',
    withC8Label: false
  },
  {
    label: strings.retailFloorDisplayWeek,
    value: 'info.floorDisplayWeek',
    withC8Label: false
  }
]
