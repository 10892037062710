import { ListViewColumnDefinition } from '@components/UI-Components/StandardTableView/models/ListviewColumnDefinition'
import { WholesaleGridCard } from '@components/UI-Components/WholesaleGridCard'
import { config } from '@config'
import { Divider } from '@material-ui/core'
import { ProductContainerRow } from '@modules/collection/components/ProductContainerRow'
import { CardSkeleton } from '@modules/common/component/CardSkeleton'
import { ListRowSkeleton } from '@modules/common/component/ListRowSkeleton'
import { ProductTypeForSearchFilterEnum } from '@modules/common/models/enums/SearchFilterEnums'
import { useBuyingSessionGroup } from '@modules/wholesale/buyingGroupSummary/graphql/hooks'
import { BuyingSessionGroupNavView, ViewTabs } from '@modules/common/models/enums'
import {
  useProductEnumList,
  useBuyingSessionProduct
} from '@modules/wholesale/buyingSessionGroupProductWrapper/graphql/hooks'
import {
  getDateWithoutTime,
  getEmptyArrayOfLength,
  isWholesaleCMOrAdmin
} from '@services/commonServices'
import { getExchangeRateForClientCurrency } from '@services/wholesale/OrderManagementService'
import { wholeSaleProductService } from '@services/wholesale/productService'
import { usePricesForBuyingSessionProducts } from '@services/wholesale/wholesalePriceService'
import { stores, strings } from '@stores'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import { useObserver } from 'mobx-react-lite'
import React, { useEffect } from 'react'

import { StandardTreeViewStore } from '@components/UI-Components/StandardTreeView/Store/TreeViewStore'
import { ACTIVITY_HIERARCHY_MAP } from '@constants'
import { VIPChannel } from '@modules/common/models/enums/VIPChannel'
import { productForecastUIStore } from '@modules/wholesale/buyingSessionGroupProductWrapper/stores/productForecastUIStore'
import { useQueryGetClusterStatus } from '@modules/wholesale/clusterSetting/graphql/getClusterStatus'
import { isRetailChannel } from '@services/wholesale/wholesaleBuyingSessionGroupsServices'
import isEqual from 'lodash/isEqual'
import isString from 'lodash/isString'
import ClusterStatusDropdown from '../components/ClusterStatusDropDown'
import { useAssortmentProduct, useClientAssortmentById } from '../graphql/hooks'
import * as css from './WsAssortmentProductRowWrapper.css'
import { hasUserRoles, PERMISSION_MAP } from '@services/userRoleService'
import { counterOfferUIStore } from '@modules/wholesale/buyingSessionGroupProductWrapper/stores/CounterOffer/CounterOfferUIStore'

interface IwsAssortmentProductRowWrapper {
  style?: any
  node?: any
  stickyStyle?: any
  listHeaders?: Array<ListViewColumnDefinition>
  canTableFitInView?: boolean
}

export const WsAssortmentProductRowWrapper: React.FunctionComponent<IwsAssortmentProductRowWrapper> =
  ({ node, style, stickyStyle, listHeaders, canTableFitInView }) => {
    return useObserver(function useHook() {
      const { category: activity, childrenMeta, childrenMetaValues, categoryIds } = node
      const {
        wsBuyingSessionProduct: {
          handleTagDrop,
          filterProductsBasedOnFilterAndSearch,
          setRefetch,
          fetchWHLBuyProducts,
          retailBuyProducts,
          fetchRetailBuyProducts,
          retailRMClusters,
          retailBuyComments,
          whereInputForBSPForBS
        },
        nav: {
          queryParams: {
            assortmentType,
            productLayout,
            tab,
            buyingSessionGroupId,
            assortment,
            subSection,
            buyingSession,
            zoneId,
            client,
            subview
          },
          onProductDetailClick
        },
        search: { searchKey },
        filterModalStore: { wsAppliedFilters }
      } = stores
      const { init, productForecastData, upsertForecastData, targetCurrency } =
        productForecastUIStore
      const { selectedNavItems } = StandardTreeViewStore
      const { setCounterOfferData, rmClustersData } = counterOfferUIStore

      // create mock array
      const mockProducts = getEmptyArrayOfLength(node.products)

      // Query buying session group in order to get buying session closed date
      const { data: buyingSessionGroupData, loading: buyingSessionGroupRequestLoading } =
        useBuyingSessionGroup(buyingSessionGroupId)

      const queryVariableObject = whereInputForBSPForBS(
        activity,
        childrenMeta,
        childrenMetaValues
      )

      /**
       * Query:
       * 1. Assortment products -> Actual products to be rendered in view
       * 2. EnumList -> For resolving enum attributes
       * 3. Client with client pricelist -> Required for fetching price data
       * 4. Buying session products -> products to be rendered in Rtl Buy view
       */
      const [
        { items, loading, refetch },
        { data: productEnumList, loading: enumLoading },
        { data: clientAssortmentData, loading: clientAssortmentLoading },
        { items: retailClusterStatuses = [] },
        { data, loading: bspLoading }
      ] = [
        useAssortmentProduct(subSection, assortmentType, assortment, buyingSession, {
          activity,
          childrenMeta,
          childrenMetaValues
        }),
        useProductEnumList(),
        useClientAssortmentById(
          assortmentType === strings.client.toLowerCase() ? assortment : null
        ),
        useQueryGetClusterStatus(
          isRetailChannel(buyingSessionGroupData?.buyingSessionGroup?.channels)
            ? VIPChannel.Retail
            : null
        ),
        useBuyingSessionProduct(queryVariableObject, 'cache-and-network')
      ]
      useEffect(() => {
        refetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [node.products])
      useEffect(() => {
        setRefetch(refetch)
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [refetch])

      // TODO: define a custom hook, this is a duplicate code block
      React.useEffect(() => {
        if (productLayout === 'list') {
          let categoryObj: any = {
            familyId: categoryIds?.[1],
            lineId: categoryIds?.[2]
          }
          if (isEqual(ACTIVITY_HIERARCHY_MAP.SHOES, childrenMeta)) {
            categoryObj = {
              lineId: categoryIds?.[1]
            }
          }
          const initCategoryValues = selectedNavItems?.some(navItem => {
            if (!isString(navItem)) return false
            const items = navItem?.split(':')
            return (
              items?.[0] === activity &&
              childrenMetaValues?.some(
                child => items?.[1] === child || items?.[2] === child
              )
            )
          })

          init(
            {
              buyingSessionGroupId,
              buyingSessionId: buyingSession,
              activityId: categoryIds?.[0],
              ...categoryObj,
              clientId: zoneId,
              categoryValues: [activity, ...(childrenMetaValues || [])]
            },
            initCategoryValues,
            false
          )
          fetchWHLBuyProducts(buyingSessionGroupId, buyingSession, client)
          fetchRetailBuyProducts(buyingSessionGroupId, buyingSession, zoneId, client)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [
        buyingSessionGroupId,
        buyingSession,
        client,
        zoneId,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        JSON.stringify(categoryIds),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        JSON.stringify(selectedNavItems),
        productLayout
      ])

      React.useEffect(() => {
        if (
          BuyingSessionGroupNavView.Overview !== subview &&
          hasUserRoles(
            PERMISSION_MAP.find(ele => VIPChannel.Retail === ele.id).allowedRoles
          )
        )
          setCounterOfferData(buyingSessionGroupId, buyingSession)
      }, [buyingSessionGroupId, buyingSession, setCounterOfferData, subview])

      const isGridView = productLayout === strings.grid
      const isRtlBuyView = ViewTabs.RTL_BUY.toLowerCase() === tab

      const products = filterProductsBasedOnFilterAndSearch(
        isRtlBuyView ? data?.findVIPBuyingSessionProduct?.Items || [] : items ?? [],
        searchKey,
        wsAppliedFilters,
        isRtlBuyView
          ? ProductTypeForSearchFilterEnum.buyingSessionProduct
          : ProductTypeForSearchFilterEnum.assortmentProduct
      )
      const productEnums = productEnumList?.findEnumList?.Items ?? []
      const clientData = clientAssortmentData?.getVIPClientAssortment?.Client
      const clientCurrency = clientData?.WHLPriceList?.Currency?.Name

      /**
       * Call hook for fetching price of products
       */
      const bsgDate = buyingSessionGroupData?.buyingSessionGroup?.BSGDate
      const exchangeRatesForGlobalCurrency =
        buyingSessionGroupData?.buyingSessionGroup?.CurrencyExchangeTable
      const buyingSessionGroupDate = getDateWithoutTime(bsgDate)
      let { data: priceData } = usePricesForBuyingSessionProducts(
        products,
        buyingSessionGroupDate,
        assortmentType === strings.client.toLowerCase()
          ? {
              retailPriceGroup: clientData?.RTLPriceList?.PriceGroup,
              wholesalePriceGroup: clientData?.WHLPriceList?.PriceGroup
            }
          : null,
        buyingSessionGroupRequestLoading ||
          clientAssortmentLoading ||
          loading ||
          bspLoading // Skip until BSG and client data is fetched
      )
      return (
        <React.Fragment>
          <div style={style} className={classNames({ [css.root]: isGridView })}>
            {isGridView ? (
              (loading || bspLoading || enumLoading) && isEmpty(items) ? (
                map(mockProducts, (product, index) => <CardSkeleton key={index} />)
              ) : (
                map(
                  wholeSaleProductService.processProductsForGridView(
                    products,
                    productEnums,
                    priceData,
                    assortmentType
                  ),
                  (product, index) => (
                    <WholesaleGridCard
                      product={product}
                      key={product.productId}
                      handleTagDrop={handleTagDrop}
                      clickHandler={onProductDetailClick(product.node.Id)}
                    />
                  )
                )
              )
            ) : (loading || bspLoading || enumLoading) && isEmpty(items) ? (
              map(mockProducts, (product, index) => (
                <React.Fragment key={'ListRowSkeleton' + index}>
                  <ListRowSkeleton />
                  <Divider />
                </React.Fragment>
              ))
            ) : (
              <ProductContainerRow
                isWholesale={true}
                headers={listHeaders}
                stickyStyle={stickyStyle}
                canTableFitInView={canTableFitInView}
                products={wholeSaleProductService.processProductsForListViewData(
                  products,
                  retailBuyProducts,
                  retailBuyComments,
                  retailRMClusters,
                  productEnums,
                  tab as any,
                  priceData,
                  assortmentType,
                  assortmentType === strings.client.toLowerCase()
                    ? {
                        targetCurrency: isWholesaleCMOrAdmin()
                          ? config.globalCurrency
                          : clientCurrency,
                        convertToTargetCurrency: isWholesaleCMOrAdmin() ? true : false,
                        exchangeRate:
                          clientCurrency === config.globalCurrency
                            ? 1
                            : getExchangeRateForClientCurrency(
                                clientCurrency,
                                exchangeRatesForGlobalCurrency
                              )
                      }
                    : null,
                  { productForecastData, upsertForecastData, targetCurrency },
                  rmClustersData
                )}
              />
            )}
          </div>
          <ClusterStatusDropdown clusterStatuses={retailClusterStatuses} />
        </React.Fragment>
      )
    })
  }
