export enum Views {
  List = 'list',
  Grid = 'grid',
  Kpi = 'kpi'
}

export enum ViewTabs {
  DISTRIBUTION = 'WHOLESALE DISTRIBUTION',
  RTL_DISTRIBUTION = 'RETAIL DISTRIBUTION',
  RTL_BUY = 'RETAIL BUY',
  BET = 'BET',
  BUY = 'WHOLESALE BUY',
  COUNTER_OFFER = 'COUNTER OFFER',
  SPLIT = 'SPLIT',
  // tab should be named retail GBB-7376
  FORECAST = 'RETAIL FORECAST'
}

export const RETAIL_TABS = [
  ViewTabs.RTL_BUY.toLowerCase(),
  ViewTabs.RTL_DISTRIBUTION.toLowerCase(),
  ViewTabs.FORECAST.toLowerCase()
]
