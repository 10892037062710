import { CentricTheme, StyledProps } from '@components/3rd-party/mui'
import Attachment from '@material-ui/icons/Attachment'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import { SecondaryButton } from './SecondaryButton'

const styles = (theme: CentricTheme) => ({
  root: {
    ...theme.styles.components.button,
    background: 'transparent',
    color: theme.palette.primary.main
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minWidth: 240
  },
  buttonPrimary: {
    width: 200,
    justifyContent: 'flex-start'
  },
  buttonProgress: {
    // position: 'absolute',
    // left: '50%',
    width: 200
  },
  fileName: {
    display: 'inline-block',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    padding: '0 10px',
    width: 200
  },
  input: {
    display: 'none'
  }
})

interface MyProps extends StyledProps {
  /** Label for select button */
  label: string
  /** Handler function on file selection. It will get called with selected file object */
  handleChange?: (e) => void
  /** File type to accept */
  accept: string
  /** Flag specifying if button is to be disabled */
  disabled?: boolean
  /** Flag specifying if loader is to be displayed on button */
  loading?: boolean
  /** Color for button */
  color: string
}

@((withStyles as any)(styles))
export class SelectFileButton extends React.Component<MyProps, { filename?: string }> {
  static defaultProps = {
    color: 'primary'
  }

  constructor(props) {
    super(props)
    this.state = {
      filename: ''
    }
  }

  handleChange = event => {
    if (event.target.files && event.target.files.length) {
      let file = event.target.files[0]

      if (this.props.handleChange) {
        this.props.handleChange(file)
      }

      this.setState({
        filename: file.name
      })
    }
  }

  render() {
    const { classes, label, accept, disabled, loading, color } = this.props

    return (
      <div className={classes.content}>
        <label>
          <input
            accept={accept || '*.*'}
            className={classes.input}
            type='file'
            onChange={this.handleChange}
            disabled={disabled}
          />
          <SecondaryButton
            component='span'
            color={color as any}
            className={classes.buttonPrimary}
            disabled={disabled}
          >
            {label || 'Choose File'}
            <Attachment className={classes.rightIcon} />
          </SecondaryButton>
        </label>
        <Typography variant='subtitle2' className={classes.fileName}>
          {this.state.filename}
        </Typography>
        {loading && <LinearProgress className={classes.buttonProgress} />}
      </div>
    )
  }
}
