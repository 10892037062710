import { config } from '@config'
import { RestServiceEndpoints } from '@constants'
import { Channel } from '@modules/common/models/enums/Channel'
import { HttpService } from '@services/HttpService'
import { storage } from '@services/storageService'

export class CounterOfferDataStore {
  private httpService = new HttpService()

  fetchRMClusters = async (buyingSessionGroupId: string, buyingSessionId: string) => {
    const quantityType = config.defaultQuantityType[Channel.Retail]
    const accessToken = storage.getItem('access_token')
    try {
      const response = await this.httpService.get(
        `${config.buyingBoardServiceRestEndpoint}${RestServiceEndpoints.COUNTER_OFFER}/buyingSessionGroupId/${buyingSessionGroupId}/buyingSessionId/${buyingSessionId}`,
        {
          params: { quantityType },
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        }
      )
      return response?.data
    } catch (e) {
      console.log(e)
    }
  }
}
