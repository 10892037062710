/*
 * @Author: Raeesaa Metkari
 * @Date: 2020-04-13 23:59:05
 * @Last Modified by: Raeesaa Metkari
 * @Last Modified time: 2020-04-15 18:25:19
 */

export enum DoorTableViewColumnLabels {
  Active = 'Active',
  Code = 'Door Code',
  Name = 'Name',
  Address = 'Address',
  City = 'City',
  Country = 'Country',
  Currency = 'Currency',
  Currency2 = 'Currency2',
  PriceGroup = 'Price Group',
  NumberOfDoors = 'NB Doors',
  Clusters = 'Clusters',
  SalesDistrict = 'Sales District',
  StoreCode = 'Code',
  Zone = 'Zone'
}
