import { ApolloClient } from '@apollo/client/core/ApolloClient'
import { QueryResult } from '@apollo/client/react'
import { Query } from '@apollo/client/react/components/Query'
import { StyledProps } from '@components/3rd-party/mui'
import { CircularLoading } from '@components/UI-Components/CircularLoading'
import { User } from '@models'
import { GET_COMMENTS } from '@modules/retail/productDetail/graphql/getComments'
import { Comments } from '@modules/retail/productDetail'
import { GET_RM_COMMENTS } from '@modules/retail/productDetail/graphql/getRMComments'
import { storage } from '@services/storageService'
import React from 'react'

interface IProps extends StyledProps {
  client?: ApolloClient<any>
  selectedProductId: string
  isActive?: Boolean
  isRestricted?: boolean
  isClosed?: boolean
}

export class CommentContainer extends React.Component<IProps> {
  render() {
    const {
      props: { selectedProductId, isActive, isRestricted, isClosed }
    } = this
    const user = storage.getItem<User>('user')
    const zoneIds =
      user.membership && user.membership.zones
        ? user.membership.zones.map(zone => zone.id)
        : null

    return (
      <Query
        query={zoneIds ? GET_RM_COMMENTS : GET_COMMENTS}
        variables={{ productId: selectedProductId, ...(zoneIds && { zoneIds }) }}
      >
        {({ loading, error, data = {} }: QueryResult) => {
          if (loading) return <CircularLoading />
          if (error) return <div>Error</div>
          return (
            <Comments
              data={data}
              productId={selectedProductId}
              isActive={isActive}
              isRestricted={isRestricted}
              isClosed={isClosed}
            />
          )
        }}
      </Query>
    )
  }
}
