import { StyledProps } from '@components/3rd-party/mui'
import { observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import React from 'react'
import { ErrorHandlerComponent } from './ErrorHandlerComponent'

interface MyProps extends StyledProps {}

//@(withStyles as any)(styles)
@observer
export class ErrorHandler extends React.Component<MyProps> {
  @observable hasError = false
  @observable error = ''
  @observable errorInfo = ''

  componentDidCatch(error, info) {
    runInAction(`Update error`, () => {
      this.hasError = true
      this.error = error
      this.errorInfo = info
    })
  }

  render() {
    const {
      hasError,
      props,
      props: { children }
    } = this
    return hasError ? <ErrorHandlerComponent {...props} /> : children
  }
}
