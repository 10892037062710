import { action, observable } from 'mobx'
import { strings } from '@stores'

export class ClientSearchStore {
  //Default worldwide will be selected
  @observable selectedZone = strings.worldWide
  @observable anchorEl = null
  @observable anchorElClient = null

  /**
   * onZoneSelect
   * Controls client list from client search
   * based on zone selection
   */
  @action onZoneSelect = zone => {
    this.selectedZone = zone
  }

  /**
   * handlePopOverClick
   * Sets anchor element for client search pop over
   */
  @action handlePopOverClick = event => {
    this.anchorEl = event.currentTarget
  }

  /**
   * handlePopOverClose
   * Closes the popover
   */
  @action handlePopOverClose = () => {
    this.anchorEl = null
  }

  @action handlePopoverClientClick = e => {
    this.anchorElClient = e.currentTarget
  }
}

export const clientSearch = new ClientSearchStore()
