import { NoRecordFound } from '@components/UI-Components/NoRecordFound'
import { TreeCategoryType } from '@modules/common/models/enums/TreeCategoryType'
import isEmpty from 'lodash/isEmpty'
import memoize from 'memoize-one'
import React from 'react'
import { StandardTreeViewStore } from '../Store/TreeViewStore'
import { TreeNav, TreeNavProps } from './TreeNav'
import { TreeNavDropdown } from './TreeNavDropdown'
import { VirtualizedTreeTable, VirtualizedTreeTableProps } from './VirtualizedTreeTable'

export interface StandardTreeViewProps {
  viewName?: string
  treeTable: VirtualizedTreeTableProps
  navData?: TreeNavProps
  isLoading?: boolean
}

const init = memoize((tableData, rootInfo, rootNodeStatus) => {
  let store = StandardTreeViewStore
  store.InitStore(tableData, rootInfo, rootNodeStatus)
})

export const StandardTreeView: React.FunctionComponent<StandardTreeViewProps> = props => {
  let store = StandardTreeViewStore
  const {
    treeTable: { tableData, rootInfo },
    navData
  } = props
  init(tableData, rootInfo, navData?.rootNodeStatus ?? null)

  React.useEffect(() => {
    return () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, react-hooks/exhaustive-deps
      store = null
    }
  }, [])

  return tableData && Object.keys(tableData).length > 1 ? (
    <>
      <VirtualizedTreeTable {...props.treeTable}></VirtualizedTreeTable>
      {navData && navData.type === TreeCategoryType.DROPDOWN ? (
        <TreeNavDropdown navData={navData} />
      ) : (
        !isEmpty(props.navData) && <TreeNav {...props.navData}></TreeNav>
      )}
    </>
  ) : (
    <NoRecordFound></NoRecordFound>
  )
}
