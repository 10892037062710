import { CentricTheme } from '@components/3rd-party/mui'
import { Add as AddIcon } from '@icons'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Select, { SelectProps } from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import { validateAlphabetOnly } from '@services/commonServices'
import React from 'react'
import { CircularLoading } from './CircularLoading'

const styles = (theme: CentricTheme) => ({
  selectfield: {
    height: 40
  },
  icon: {},
  wrapper: {
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute',
    top: '55%',
    left: '55%',
    marginTop: -12,
    marginLeft: -12
  }
})

interface MyProps extends SelectProps {
  classes?: any
  options?: Array<any>
  onChange: (e) => void
  quickCreate?: boolean
  createAction?: (e) => void
  quickCreateInProgress?: boolean
}

export class SelectFieldComponent extends React.Component<MyProps> {
  static defaultProps = {
    disabled: false,
    onChange: () => {},
    options: []
  }

  state = {
    open: false,
    quickCreateItemName: ''
  }

  setOpen = stateBool => {
    this.setState({
      open: stateBool
    })
  }
  handleClose = event => {
    if (event.target.type !== 'text') {
      this.setOpen(false)
    }
    this.setState({
      quickCreateItemName: ''
    })
  }

  handleOpen = () => {
    this.setOpen(true)
  }

  quickCreateOnChange = event => {
    if (validateAlphabetOnly(event.target.value)) {
      this.setState({
        quickCreateItemName: event.target.value
      })
    }
  }

  onKeyDown = event => {
    event.stopPropagation()
  }

  render() {
    const {
      classes,
      options,
      onChange,
      defaultValue = '',
      quickCreate = false,
      createAction,
      quickCreateInProgress,
      ...otherProps
    } = this.props
    const { quickCreateItemName } = this.state

    return (
      <Select
        defaultValue={defaultValue}
        className={classes.selectfield}
        classes={{ icon: classes.icon }}
        onChange={onChange}
        onClose={this.handleClose}
        onOpen={this.handleOpen}
        open={this.state.open}
        {...otherProps}
      >
        {options?.map(option => (
          <MenuItem className={classes.selectOption} key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
        {quickCreate ? (
          <MenuItem
            onKeyDown={this.onKeyDown}
            className={classes.selectOption}
            key={'quickCreate'}
          >
            <TextField
              placeholder={'Add new'}
              onChange={this.quickCreateOnChange}
              value={quickCreateItemName}
            />
            <div className={classes.wrapper}>
              <IconButton
                aria-label='create'
                disabled={quickCreateInProgress || quickCreateItemName.length === 0}
                onClick={() => createAction!(this.state.quickCreateItemName)}
              >
                <AddIcon />
              </IconButton>
              {quickCreateInProgress && (
                <CircularLoading className={classes.buttonProgress} size={20} />
              )}
            </div>
          </MenuItem>
        ) : null}
      </Select>
    )
  }
}

@((withStyles as any)(styles))
export class SelectField extends SelectFieldComponent {}
