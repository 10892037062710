import { CentricTheme, StyledProps } from '@components/3rd-party/mui'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { stores, strings } from '@stores'
import { useObserver } from 'mobx-react-lite'
import React from 'react'
import styled from 'styled-components'

const styles = (theme: CentricTheme) => ({
  typography: {
    margin: theme.spacing(1)
  },
  popOver: {
    width: 250,
    fontSize: 14,
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  popOverMenu: {
    fontSize: '10px',
    paddingTop: 2,
    paddingBottom: 2
  },
  menuItem: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
})

const ITEM_HEIGHT = 48

interface IProps extends StyledProps {
  clusterStatuses: any[]
}

const StyledTypography = styled(Typography)(() => {
  return {
    '&&': {
      fontSize: 10
    }
  }
})
const ClusterStatusDropdownComponent: React.FunctionComponent<IProps> = ({
  classes,
  clusterStatuses
}) => {
  return useObserver(function useHook() {
    const {
      wsBuyingSessionProduct: {
        clusterAndEle,
        upsertBSPClusterStatus,
        showClusterStatuses
      }
    } = stores
    const { element, tagId: clusterId } = clusterAndEle || {}
    return (
      <Menu
        id='cluster-status-menu'
        anchorEl={element}
        open={Boolean(element && clusterId)}
        onClose={() => showClusterStatuses(null, null, null)}
        classes={{
          paper: classes.popOver
        }}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: 150
          }
        }}
      >
        {clusterStatuses.length ? (
          clusterStatuses.map(({ Id: id, Name: name }) => (
            <MenuItem
              key={name}
              classes={{
                root: classes.popOverMenu
              }}
              onClick={e => {
                e.stopPropagation()
                if (clusterAndEle?.currentStatus?.Name !== name) {
                  upsertBSPClusterStatus(
                    clusterAndEle.assortmentProductId,
                    {
                      Id: id,
                      Name: name
                    },
                    clusterId
                  )
                }
                showClusterStatuses(null, null, null)
              }}
            >
              <Tooltip title={name}>
                <StyledTypography className={classes.menuItem}>{name}</StyledTypography>
              </Tooltip>
            </MenuItem>
          ))
        ) : (
          <MenuItem
            key={'noCluster'}
            classes={{
              root: classes.popOverMenu
            }}
            onClick={() => showClusterStatuses(null, null, null)}
          >
            <StyledTypography>{strings.noClusterStatus}</StyledTypography>
          </MenuItem>
        )}
      </Menu>
    )
  })
}
const ClusterStatusDropdown = withStyles(styles as any)(ClusterStatusDropdownComponent)

export default ClusterStatusDropdown
