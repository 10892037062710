import { VIPChannel } from '@modules/common/models/enums/VIPChannel'
import { VIPClientType } from '@modules/common/models/enums/VIPClientType'
import GET_BUYING_SESSION_GROUP from '@modules/wholesale/buyingGroupSummary/graphql/getBuyingSessionGroup'
import { apolloClient } from '@stores'
import includes from 'lodash/includes'
import { action, observable, ObservableMap } from 'mobx'
import { computedFn } from 'mobx-utils'

type StatusByChannel<T extends string | string[]> = {
  [key in VIPChannel]: T
}

export class BuyingSessionGroup {
  id: string
  statusByChannel: StatusByChannel<string>
  channels: VIPChannel[]
}

export class BuyingSessionGroupStore {
  @observable data = new ObservableMap<string, BuyingSessionGroup>()
  @observable loading = new ObservableMap()
  @observable error = new ObservableMap()

  hasStatusesByChannel = computedFn(
    (bsgId: string, channel: VIPChannel, statuses: string[]) => {
      return this.data.get(bsgId)?.statusByChannel[channel]
        ? includes(statuses, this.data.get(bsgId)?.statusByChannel[channel])
        : false
    }
  )

  hasStatusesByClientType = computedFn(
    (bsgId: string, clientType: VIPClientType, statuses: StatusByChannel<string[]>) => {
      return clientType === VIPClientType.External
        ? this.hasStatusesByChannel(
            bsgId,
            VIPChannel.Wholesale,
            statuses[VIPChannel.Wholesale]
          )
        : this.hasStatusesByChannel(bsgId, VIPChannel.Retail, statuses[VIPChannel.Retail])
    }
  )

  @action
  fetchData = async (bsgId: string) => {
    this.loading.set(bsgId, true)
    try {
      const { data, error } = await apolloClient.query({
        query: GET_BUYING_SESSION_GROUP,
        variables: {
          id: bsgId
        }
      })
      const { Id, channels, STD_RTLBuyingSessionGroup_Status, BuyingSessionGroupStatus } =
        data?.buyingSessionGroup || {}
      this.data.set(bsgId, {
        id: Id,
        statusByChannel: {
          ...[
            {
              channel: VIPChannel.Retail,
              status: STD_RTLBuyingSessionGroup_Status
            },
            {
              channel: VIPChannel.Wholesale,
              status: BuyingSessionGroupStatus
            }
          ].reduce(
            (acc, { channel, status }) => ({
              ...acc,
              ...(includes(channels, channel) ? { [channel]: status } : {})
            }),
            {}
          )
        },
        channels
      } as BuyingSessionGroup)
      this.error.set(bsgId, error)
    } catch (e) {
      this.error.set(bsgId, e)
    } finally {
      this.loading.set(bsgId, false)
    }
    return this.data.get(bsgId)
  }

  fetchDataIfNotExist = async (bsgId: string) => {
    if (
      this.loading.get(bsgId) !== true &&
      !this.error.has(bsgId) &&
      !this.data.has(bsgId)
    ) {
      return await this.fetchData(bsgId)
    }
    return this.data.get(bsgId)
  }

  @action
  updateStatus = (bsgId: string, status: string) => {}

  @action
  clear = () => {
    this.data.clear()
    this.loading.clear()
    this.error.clear()
  }
}

const store = new BuyingSessionGroupStore()
export default store
