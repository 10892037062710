import { config } from '@config'
import { storage } from '@services/storageService'
import axios from 'axios'
import { Channel } from '@modules/common/models/enums/Channel'
export class CommonService {
  getRetailRMClusters = async (
    buyingSessionGroupId,
    buyingSessionId,
    clientId,
    doorIds
  ) => {
    try {
      const quantityType = config.defaultQuantityType[Channel.Retail]
      const url = `${config.buyingBoardServiceRestEndpoint}rmClusters/buyingSessionGroupId/${buyingSessionGroupId}/buyingSessionId/${buyingSessionId}`
      const accessToken: string = storage.getItem('access_token')
      const response: any = await axios.get(url, {
        params: {
          buyingSessionId,
          clientId,
          doorIds,
          quantityType
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      return response?.data
    } catch (e) {
      console.log(e)
    }
  }
}
