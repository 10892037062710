import { useLazyQuery, useQuery } from '@apollo/client'
import i18next, { changeLanguage } from 'i18next'
import moment from 'moment'
import React, { createContext, useEffect, useState } from 'react'
import { GET_LOCALE } from './graphql/get-locale'
import { GET_LOCALES } from './graphql/get-locales'

const fallbackLangResource = import('@resources/locales/en.json')

const contextDefault = {
  currentLocale: {
    key: 'I18N-en',
    name: 'English',
    code: 'en',
    firstLoad: true
  },
  locales: [],
  getHandleChangeLanguage: (a, b) => () => {}
}

export const I18NextContext = createContext(contextDefault)

const useI18NextProvider = () => {
  const language = i18next.resolvedLanguage?.substring(0, 2)
  const [currentLocale, setCurrentLocale] = useState(() => ({
    key: 'I18N-en',
    name: 'English',
    code: 'en',
    firstLoad: true
  }))
  const [locales, setLocales] = useState([])
  const [handleClose, setHandleClose] = useState(() => () => {})
  const { data: localesData, loading: localesLoading } = useQuery(GET_LOCALES)
  const [getLocale, { called, data: localeData, refetch }] = useLazyQuery(GET_LOCALE, {
    variables: { locale: `VIPBoardConfigurationName:${currentLocale.key}` }
  })

  useEffect(() => {
    if (!localesLoading && localesData && localesData.findVIPBoardConfiguration) {
      try {
        const payload = JSON.parse(
          localesData.findVIPBoardConfiguration.Items[0].ConfigurationValue
        ).map(locale => ({
          ...locale,
          code: locale.key.replace(/^I18N-/, '')
        }))
        const locale = payload.reduce((acc, val) => {
          return acc.skip
            ? acc
            : {
                locale: {
                  ...val,
                  firstLoad: true
                },
                skip: val.key.replace(/^I18N-/, '') === language
              }
        }, {}).locale
        setCurrentLocale(locale)
        setLocales(payload)
      } catch (e) {
        console.log(e)
      }
    }
  }, [localesData, language, localesLoading])

  useEffect(() => {
    if (localeData && localeData.findVIPBoardConfiguration.Items[0]) {
      try {
        const payload = JSON.parse(
          localeData.findVIPBoardConfiguration.Items[0].ConfigurationValue
        )
        i18next.addResourceBundle(currentLocale.code, 'translations', payload, true, true)
        moment.updateLocale(currentLocale.code, payload.translation.dateAndTime)
        localStorage.setItem(
          'i18nextLngCache',
          JSON.stringify({
            [currentLocale.code]: payload
          })
        )
        !currentLocale.firstLoad &&
          changeLanguage(currentLocale.code).then(() => {
            !!handleClose && handleClose()
          })
      } catch (e) {
        console.log(e)
      }
    }
  }, [localeData, currentLocale.code, currentLocale.firstLoad, handleClose])

  useEffect(() => {
    ;(!currentLocale.firstLoad ||
      i18next.languages?.indexOf(currentLocale.code) === -1) &&
    called
      ? refetch({ locale: `VIPBoardConfigurationName:${currentLocale.key}` })
      : getLocale()
  }, [currentLocale, called, getLocale, refetch])

  useEffect(() => {
    fallbackLangResource
      .then(resources =>
        i18next.addResourceBundle('en', 'translation', resources.translation, true, true)
      )
      .catch()
  }, [])

  const getHandleChangeLanguage = (locale, handleClose) => () => {
    locale.firstLoad = false
    !!handleClose && setHandleClose(handleClose)
    setCurrentLocale(locale)
  }
  return {
    getHandleChangeLanguage,
    currentLocale,
    locales
  }
}

export const I18NextProvider = ({ children }) => {
  const providerValue = useI18NextProvider() as any
  return (
    <I18NextContext.Provider value={providerValue}>{children}</I18NextContext.Provider>
  )
}
