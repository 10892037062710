import { stores } from '@stores/index'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import maxBy from 'lodash/maxBy'
import trim from 'lodash/trim'
import { action, computed, observable } from 'mobx'
import qs from 'query-string'
import { matchPath } from 'react-router-dom'

export type BuyingBoardRouteParams = {
  season?: string
  gender?: string
  buyingSession?: string
  podium?: string
  activity?: string
  assortment?: string
  view?: string
  detail?: string
  assortmentType?: string
  clusterId?: string
  podiumId?: string
  clientId?: string
}

export interface AvailableQueryParams {
  tab?: string
  subTab?: string
  sidebar?: 'hide' | undefined
  mobx?: string
  zoneId?: string
  notification?: boolean | string
  ast_stores?: undefined | string
  buyingSessionGroupId?: undefined | string
  buyingSession?: undefined | string
  referrerBuyingSessionGroup?: undefined | string
  genderId?: undefined | string
  seasonId?: undefined | string
  view?: undefined | string
  addBuyingSession?: boolean | string
  addBuyingSessionGroup?: boolean | string
  section?: string
  client?: string
  assortmentType?: string
  assortment?: string
  zone?: string
  productLayout?: string
  clientId?: string
  orderId?: string
  bspId?: string
  productId?: string
  orderType?: string
  modal?: string
  name?: string
  id?: string
  campaignId?: string
  scrollToCategory?: string
  isAddProductToOrderModalOpen?: boolean
  toolbar?: 'hide' | undefined
  subSection?: string
  lookId?: string
  channel?: string
  hasPresentationUrl?: boolean
  isAddProductToOrderView?: boolean
  clientType?: string
  clientOrderId?: string
  subview?: string
}

export interface NotificationMetadata {
  season?: string
  gender?: string
  activity?: string
  activityDescription?: string
  buyingSessionCollection?: string
  buyingSession?: string
  buyingSessionProduct?: string
  zoneAssortment?: string
  storeAssortment?: string
  clusterAssortment?: string
  assortmentType?: 'zone' | 'store' | 'cluster' // FIXME: Replace by strings
  zone?: string
}

type NotificationEntityType =
  | 'Product'
  | 'BuyingSession'
  | 'ZoneAssortment'
  | 'StoreAssortment'
  | 'BuyingSessionProduct'
  | 'ZoneAssortmentProduct'
  | 'StoreAssortmentProduct'
  | 'VIPBuyingSessionProduct'
  | 'VIPOrderProduct'
  | 'VIPBuyingSessionGroup'

export class NavStore {
  @computed.struct get queryParams(): AvailableQueryParams {
    return qs.parse(this.location.search)
  }

  @observable refererUrl = {
    pathname: '',
    search: ''
  }

  @action setRefererUrl = () => {
    const refererUrl = {
      pathname: window.location.pathname,
      search: window.location.search
    }
    Object.assign(this, { refererUrl })
  }

  @action clearRefererUrl = () => {
    Object.assign(this, { refererUrl: {} })
  }

  urlForParams = (params: BuyingBoardRouteParams = {}) => {
    const keys = Object.keys(params)
    const { season, gender } = params

    switch (keys.length) {
      case 0:
        return '/'
      case 1:
        return season ? `/buyingSession/${season}/gender` : '/unknown'
      case 2:
        return season && gender ? `/buyingSession/${season}/gender/${gender}` : '/'
      default:
        return '/unknown'
    }
  }

  urlFor = (
    type: 'assortment' | 'assortmentSelection',
    params: BuyingBoardRouteParams = {}
  ) => {
    const { activity, buyingSession, gender, podium, season } = {
      ...this.params,
      ...params
    }

    switch (type) {
      case 'assortment': {
        return `/collections/season/${season}/gender/${gender}/buyingSession/${buyingSession}/podium/${podium}/activity/${activity}/views/grid`
      }
      case 'assortmentSelection': {
        return `/collections/season/${season}/gender/${gender}/buyingSession/${buyingSession}/podium/${podium}/activity/${activity}/views/grid`
      }

      default:
        return 'unknown URL'
    }
  }

  urlForNotifications = (
    entityType: NotificationEntityType,
    metadata: NotificationMetadata = {}
  ) => {
    let { queryParams } = nav
    queryParams.notification = undefined
    switch (entityType) {
      case 'ZoneAssortment': {
        queryParams.zoneId = metadata.zone
        return `/collections/season/${metadata.season}/gender/${
          metadata.gender
        }/buyingSession/${metadata.buyingSession}/zone/assortments/${
          metadata.zoneAssortment
        }/podium/${metadata.buyingSessionCollection}/activity/${
          metadata.activityDescription
        }/views/grid?${qs.stringify(queryParams)}`
      }
      case 'StoreAssortment': {
        queryParams.zoneId = metadata.zone
        return `/collections/season/${metadata.season}/gender/${
          metadata.gender
        }/buyingSession/${metadata.buyingSession}/store/assortments/${
          metadata.storeAssortment
        }/podium/${metadata.buyingSessionCollection}/activity/${
          metadata.activityDescription
        }/views/grid?${qs.stringify(queryParams)}`
      }
      case 'ZoneAssortmentProduct': {
        queryParams.zoneId = metadata.zone
        return `/collections/season/${metadata.season}/gender/${
          metadata.gender
        }/buyingSession/${metadata.buyingSession}/zone/assortments/${
          metadata.zoneAssortment
        }/podium/${metadata.buyingSessionCollection}/activity/${
          metadata.activityDescription
        }/views/grid/details/${metadata.buyingSessionProduct}?${qs.stringify(
          queryParams
        )}`
      }
      case 'BuyingSessionProduct': {
        return `/collections/season/${metadata.season}/gender/${metadata.gender}/buyingSession/${metadata.buyingSession}/podium/${metadata.buyingSessionCollection}/activity/${metadata.activityDescription}/views/grid/details/${metadata.buyingSessionProduct}`
      }
      case 'BuyingSession': {
        return `/collections/season/${metadata.season}/gender/${metadata.gender}/buyingSession/${metadata.buyingSession}/podium/${metadata.buyingSessionCollection}/activity/${metadata.activityDescription}/views/grid`
      }

      default:
        return ''
    }
  }

  urlFragments = {
    root: '/',
    logout: '/logout',
    login: '/login',
    collections: '/collections',
    cluster: '/cluster',
    buyingSessionForSeason: `/buyingSession/:season`,
    buyingSession: `/buyingSession/:buyingSession`,
    season: `/season/:season`,
    gender: `/gender/:gender`,
    podium: `/podium/:podium`,
    assortment: '/assortments/:assortment',
    activity: '/activity/:activity',
    view: '/views/:view',
    detail: '/details/:detail',
    lookbooks: '/lookbooks',
    container: '/container/:podiumId',
    assortmentType: '/:assortmentType',
    clusterId: '/cluster/:clusterId',
    collectionSettings: '/collectionSettings',
    collectionSettingsSalesPeriods: '/salesPeriods',
    collectionSettingsImages: '/images',
    collectionbriefs: '/collectionbriefs',
    channel: '/channel/:channelId',
    settings: '/settings',
    memberships: '/memberships',
    reports: '/reports',
    clientId: 'client/:clientId'
  }

  get history() {
    return stores.routing.history
  }

  get location() {
    return stores.routing.location
  }

  @computed get params() {
    const {
      routing: { location }
    } = stores
    const {
      urlFragments: {
        root,
        logout,
        login,
        activity,
        assortment,
        buyingSession,
        buyingSessionForSeason,
        cluster,
        collections,
        gender,
        podium,
        season,
        view,
        detail,
        container,
        lookbooks,
        assortmentType,
        clusterId,
        collectionSettings,
        settings,
        memberships,
        collectionbriefs,
        channel,
        collectionSettingsSalesPeriods,
        collectionSettingsImages,
        reports
      }
    } = this

    if (!location) return {}

    // Using the array prop in matchPath did not work and would produce wonky results as I added new items to this array.  However, using a simple map call seems to work fine.
    const matches = [
      root,
      [root, logout].join(''),
      [root, login].join(''),

      buyingSessionForSeason,
      [buyingSessionForSeason, '/gender'].join(''),
      [buyingSessionForSeason, gender].join(''),

      // Container Collection
      collections,
      [collections, season].join(''),
      [collections, gender].join(''),
      [collections, season, gender].join(''),
      [collections, season, gender, podium].join(''),
      [collections, season, gender, podium, reports].join(''),
      [collections, season, gender, podium, view].join(''),
      [collections, season, gender, podium, view, detail].join(''),
      [collections, season, gender, podium, collectionSettings].join(''),
      [collections, season, gender, podium, collectionbriefs].join(''),
      [collections, season, gender, podium, collectionbriefs, channel].join(''),
      [
        collections,
        season,
        gender,
        podium,
        collectionSettings,
        collectionSettingsSalesPeriods
      ].join(''),
      [
        collections,
        season,
        gender,
        podium,
        collectionSettings,
        collectionSettingsImages
      ].join(''),

      // Activity collection
      [collections, season, gender, buyingSession].join(''),
      [collections, season, gender, buyingSession, podium].join(''),
      [collections, season, gender, buyingSession, podium, view].join(''),
      [collections, season, gender, buyingSession, podium, view, detail].join(''),

      // Lookbook
      [collections, season, gender, container, lookbooks].join(''),
      [collections, season, gender, container, lookbooks, detail].join(''),

      // Cluster
      [collections, season, gender, buyingSession, cluster, assortment].join(''),
      [collections, season, gender, buyingSession, cluster, assortment, podium].join(''),
      [
        collections,
        season,
        gender,
        buyingSession,
        cluster,
        assortment,
        podium,
        activity
      ].join(''),
      [
        collections,
        season,
        gender,
        buyingSession,
        cluster,
        assortment,
        podium,
        activity,
        view
      ].join(''),
      [
        collections,
        season,
        gender,
        buyingSession,
        assortmentType,
        assortment,
        podium,
        activity,
        view,
        detail
      ].join(''),

      // Podium
      [collections, season, gender, buyingSession, podium].join(''),
      [collections, season, gender, buyingSession, podium, activity].join(''),
      [collections, season, gender, buyingSession, podium, activity, view].join(''),
      [collections, season, gender, buyingSession, podium, activity, view, detail].join(
        ''
      ),

      // // Zone
      // [collections, season, gender, buyingSession, zone, assortment].join(''),
      // [collections, season, gender, buyingSession, zone, assortment, podium].join(''),
      // [collections, season, gender, buyingSession, zone, assortment, podium, activity].join(''),
      // [collections, season, gender, buyingSession, zone, assortment, podium, activity, view].join(''),
      // [collections, season, gender, buyingSession, zone, assortment, podium, activity, view, detail].join(''),

      //http://localhost:4000/collections/season/cjzb3z0yqk8uf07688g7ftaiq/gender/cjzb3z26akab00768odb4hq4i/buyingSession/cjzb4axdv1csr07682autqmrg/zone/assortments/cjzb4b22h1fh10768m6c6z4iy/podium/cjzb4ax3s1csb0768ksrrpk7g/activity/READY%20TO%20WEAR/views/list?sidebar&tab=3
      // Assortment Type included
      [
        collections,
        season,
        gender,
        buyingSession,
        assortmentType,
        assortment,
        podium
      ].join(''),
      [
        collections,
        season,
        gender,
        buyingSession,
        assortmentType,
        assortment,
        podium,
        activity
      ].join(''),
      [
        collections,
        season,
        gender,
        buyingSession,
        assortmentType,
        assortment,
        podium,
        activity,
        view
      ].join(''),
      [
        collections,
        season,
        gender,
        buyingSession,
        assortmentType,
        assortment,
        clusterId,
        podium,
        activity,
        view
      ].join(''),
      [
        collections,
        season,
        gender,
        buyingSession,
        assortmentType,
        assortment,
        podium,
        activity,
        view
      ].join(''),
      [
        collections,
        season,
        gender,
        buyingSession,
        assortmentType,
        assortment,
        podium,
        activity,
        view,
        detail
      ].join(''),
      [
        collections,
        season,
        gender,
        buyingSession,
        assortmentType,
        assortment,
        clusterId,
        podium,
        activity,
        view,
        detail
      ].join(''),

      //Settings
      [collections, season, gender, settings].join(''),
      [collections, season, gender, settings, memberships].join('')
      // path: "/collections/season/:seasonId/gender/:genderId/buyingSession/:buyingSessionId/:assortmentType/assortments/:assortmentId/podium/:podiumId/activity/:activityId/views/grid",
      //
    ]
      .map(path =>
        matchPath<BuyingBoardRouteParams>(location.pathname, { exact: true, path })
      )
      .filter(m => m != null)

    // Use the match with the most resulting query params
    const match = maxBy(matches, m => Object.keys(m.params).length)
    return match ? match.params : {}
  }

  //
  // goToAssortment = (name: string, buyingSessionId: string, genderId: string, podiumId: string, activityId: string): void => {
  //   const {props: {history, match: {params: {seasonId}}}} = this;
  //   storage.setItem('selectedCollection', {name, podiumId});
  //   this.props.breadcrumbStore.updateBreadcrumb({entity: BREADCRUMB_COLLECTION, id: podiumId, value: name});
  //   history.push(
  //     `/collections/season/${seasonId}/gender/${genderId}/buyingSession/${buyingSessionId}/podium/${podiumId}/activity/${activityId}/views/grid`
  //   );
  // };

  /* Wrapper around set/clear of /details/:detail section of URL */
  @action closeDetails = () => {
    const {
      history,
      location,
      params: { detail }
    } = nav

    const {
      product: { resetSizeAttributeSelections }
    } = stores
    //To Reset the selected objects of size attributes of buyingSessionProduct
    resetSizeAttributeSelections()

    if (detail) {
      history.push({
        pathname: location.pathname.replace(`/details/${detail}`, ''),
        search: history.location.search
      })
    }
  }

  /*
  AssortmenBetBuyList
  toggleDrawer = () => {
        const {props: {history, match: {params: {genderId, podiumId, seasonId, productId}}}} = this;
        if (productId) {
            history.push(`/collections/season/${seasonId}/gender/${genderId}/podium/${podiumId}/views/list`);
            history.goBack();
        }
    };

    Product Card List
    toggleDrawer = () => {
        const {props: {refetch, history, match: {params: {genderId, podiumId, seasonId, productId}}}} = this;
        if (productId) {
            history.push(`/collections/season/${seasonId}/gender/${genderId}/podium/${podiumId}/views/list`);
            history.goBack();
        }
        refetch();
    };

    showProductDetail = (id) => {
        const {history, match: {params: {genderId, podiumId, seasonId}, url}} = this.props;
        history.push(`${url}/details/${id}`);
    };

   */

  @computed get isDetailsOpen() {
    return !isEmpty(nav.params.detail)
  }

  @action showDetail = (id?: string) => {
    if (isEmpty(id)) {
      this.closeDetails()
    } else {
      const {
        history,
        location,
        params: { detail }
      } = nav
      let newUrl = location.pathname
      if (detail !== id) {
        // Todo - someday this can just be a query string update
        newUrl = location.pathname.replace(`/details/${detail}`, `/details/${id}`)
      }

      if (!detail) {
        newUrl = `${location.pathname}/details/${id}`
      }

      history.push({ pathname: newUrl, search: history.location.search })
    }
  }

  /** Todo - these should all be computeds coming from the query string **/
  @observable isTagOpen = false
  @observable isAssortmentOpen = false
  @observable isStoreAssortmentDrawerOpen = false
  @observable isZoneAssortmentDrawerOpen = false
  @observable isClusterAssortmentOpen = false
  @observable selectedZoneAssortmentId = null
  @observable selectedClusterAssortmentId = null
  @observable selectedStoreAssortmentId = null
  @observable lastVisitedUrl = null

  @action pushLastVisitedUrl = url => {
    if (url !== this.lastVisitedUrl) {
      if (url && !url.includes('settings')) {
        Object.assign(this, { lastVisitedUrl: url })
      } else {
        Object.assign(this, { lastVisitedUrl: this.lastVisitedUrl })
      }
    }
  }

  @action
  toggleTags = () => {
    Object.assign(this, { isTagOpen: !this.isTagOpen })
  }

  @action
  toggleAssortmentDrawer = () => {
    const {
      assortment: { clearSelectedClusterAssortment }
    } = stores
    clearSelectedClusterAssortment()
    Object.assign(this, { isAssortmentOpen: !this.isAssortmentOpen })
  }

  @action
  toggleZoneAssortmentDrawer = (zoneAssortmentId = null) => {
    const flag =
      zoneAssortmentId && zoneAssortmentId === this.selectedZoneAssortmentId
        ? false
        : true
    Object.assign(this, { isZoneAssortmentDrawerOpen: flag })
  }

  @action
  toggleStoreAssortmentDrawer = (storeAssortmentId = null) => {
    const flag =
      storeAssortmentId && storeAssortmentId === this.selectedStoreAssortmentId
        ? false
        : true
    Object.assign(this, { isStoreAssortmentDrawerOpen: flag })
  }

  @action
  toggleClusterAssortmentDrawer = (clusterAssortmentId = null) => {
    const flag =
      clusterAssortmentId && clusterAssortmentId === this.selectedClusterAssortmentId
        ? false
        : true
    Object.assign(this, { isClusterAssortmentOpen: flag })
  }

  @action
  handleZoneAssortmentSelection = zoneAssortmentId => {
    Object.assign(this, { selectedZoneAssortmentId: zoneAssortmentId })
  }

  @action
  handleClusterAssortmentSelection = clusterAssortmentId => {
    Object.assign(this, { selectedClusterAssortmentId: clusterAssortmentId })
  }

  @action
  handleStoreAssortmentSelection = selectedStoreAssortmentId => {
    Object.assign(this, { selectedStoreAssortmentId: selectedStoreAssortmentId })
  }

  @action
  clearAllAssortmentSelections = () => {
    const {
      sidebar: { isRegionalMerchandiser }
    } = stores
    const flag = isRegionalMerchandiser()
    if (!flag) {
      Object.assign(this, {
        isTagOpen: false,
        isAssortmentOpen: false,
        isStoreAssortmentDrawerOpen: false,
        isZoneAssortmentDrawerOpen: false,
        isClusterAssortmentOpen: false,
        selectedZoneAssortmentId: null,
        selectedClusterAssortmentId: null,
        selectedStoreAssortmentId: null
      })
    }
  }

  @action setQueryParams = (params: AvailableQueryParams) => {
    const { history } = this
    const search = qs.stringify({ ...params })
    history.replace({ search })
    this.location.search = search
  }

  @action updateQueryParams = (
    params: AvailableQueryParams,
    historyAction: 'push' | 'replace' = 'replace'
  ) => {
    const { history, queryParams, location } = this as any
    const state = { from: location.state && location.state.from }
    const search = qs.stringify({ ...queryParams, ...params })
    if (trim(search, '?') !== trim(history.location.search, '?')) {
      historyAction === 'replace'
        ? history.replace({ search, state })
        : history.push({ search, state })
    }

    this.location.search = search
  }

  @computed get buyingSessionGroupId() {
    const { queryParams } = this
    const { buyingSessionGroupId, referrerBuyingSessionGroup } = queryParams
    return referrerBuyingSessionGroup || buyingSessionGroupId
  }

  @computed get buyingSession() {
    const { queryParams } = this
    const { buyingSession, referrerBuyingSessionGroup } = queryParams
    return referrerBuyingSessionGroup || buyingSession
  }

  /**
   * Returns query string for desktop order management page.
   * It preserves navigated user selection
   */
  @computed get queryStringForClientOrderPage() {
    let qsJSON: any = cloneDeep(qs.parse(this.location.search))
    delete qsJSON.clientId
    delete qsJSON.orderId
    delete qsJSON.orderType
    delete qsJSON.productId

    return qs.stringify(qsJSON)
  }

  /**
   * product details modal
   * for All product
   */
  @action onProductDetailClick = (productId: string) => e => {
    const {
      showroomCollection: { setDetailsLoading }
    } = stores
    setDetailsLoading(true)
    if (!e.shiftKey) {
      this.updateQueryParams({ bspId: productId }, 'push')
    }
  }
}

export const nav = new NavStore()

/* const viewUrlHierarchy = {
      'views': {
        ':view': {}
      }
    }

    const podiumUrlHierarchy = {
      'podium': {
        ':podium': {
          'activity': {
            ':activity': viewUrlHierarchy
          }
        }
      }
    }

    const urlHierarchy = {
      'collections' : {
        'season': {
          ':season': {
            'buyingSession': {
              ':buyingSession': {
              }
            },
            'gender': {
              ':gender': {
                'buyingSession': {
                  ':buyingSession': {
                    'cluster': {
                      'assortments': { ':assortment': podiumUrlHierarchy}
                    },
                    'zone': {
                      ':zone': {
                        'activity': {':activity': viewUrlHierarchy}
                      }
                    },
                    ...podiumUrlHierarchy
                  }
                }
              }
            }
          }
        }
      }
    }*/
