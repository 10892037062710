import { strings } from '@stores'
import '@styles/ProductMedia/style.css'
import cn from 'classnames'
import React from 'react'

export interface IProduct360 extends React.IframeHTMLAttributes<HTMLIFrameElement> {
  /**
   * custom props here
   */
}

export const Product360: React.FC<IProduct360> = props => {
  const { src, className, style, title, children, ...rest } = props

  return (
    <>
      <iframe
        allowFullScreen={true}
        src={src}
        className={cn('product-media-360', className)}
        style={style}
        title={strings.mediaAlt}
        {...rest}
      />
      {children}
    </>
  )
}
