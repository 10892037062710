export const constants = {
  asc: 'createdAt_ASC',
  desc: 'createdAt_DESC',
  FROZEN: 'FROZEN',
  BUYING: 'BUYING',
  CLOSED: 'CLOSED',
  PRE_BUYING: 'PRE_BUYING',
  RETAIL: 'Retail'
}

export const BUYING_SESSION_STATUSES = [
  { name: 'PRE_BUYING', color: 'green', value: 'Pre Buying' },
  { name: 'BUYING', color: '#FFC107', value: 'Buying' },
  // { name: 'CONSOLIDATION', color: 'blue', value: 'Consolidation' },
  { name: 'CLOSED', color: '#000000', value: 'Closed' },
  { name: 'FROZEN', color: 'grey', value: 'Frozen' }
]

export const BUYING_SESSION_SETTING_OPTIONS = [
  { name: 'Buying Session Collection Settings', value: 'buyingSessionSettings' }
]

export const PRODUCT_STATUS = {
  core: 'CORE',
  inactive: 'INACTIVE'
}

export const HELP_TEXT = {
  qtymismatch: 'Should equal ZONE QTY',
  mastersplit: 'Split to all stores'
}

export const CHANNEL_OPERATION_OPTIONS = [
  { name: 'Add to all products', value: 'addToAllProducts' },
  { name: 'Remove from all products', value: 'removeFromAllProducts' }
]
