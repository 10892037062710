import { StyledProps } from '@components/3rd-party/mui'
import { colors } from '@components/3rd-party/themes'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Menu from '@material-ui/icons/Menu'
import { Paper } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'

const APP_BAR_WIDTH = 60
const NAV_BAR_WIDTH = 240
export enum FlayOutDirection {
  left = 'LEFT',
  right = 'RIGHT'
}

interface INavBar extends StyledProps {
  classes?: any
  open: boolean
  toggleDrawer: () => void
  onMouseDownHandler?: (event?) => void
  width?: number
  side: FlayOutDirection
  zIndex: number
  clearanceWidth?: number
  top?: number
  navToggleIcons?: Function
  backgroundColor?: string
}

const NavContext = React.createContext<INavBar>(null as any)
const RootNavBar = styled(Paper)(props => {
  const { top, open, width, clearanceWidth, side, zIndex, backgroundColor } =
    React.useContext(NavContext)
  return {
    width: open ? width : clearanceWidth,
    height: `calc(100vh - ${top}px)`,
    backgroundColor: backgroundColor ? backgroundColor : colors?.navBackground,
    position: 'fixed',
    left: side === FlayOutDirection.left ? APP_BAR_WIDTH : undefined,
    right: side === FlayOutDirection.right ? 0 : undefined,
    top: top,
    borderRadius: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    transition: 'all 300ms cubic-bezier(0.2, 0, 0, 1) 0s',
    zIndex: zIndex
  }
})

const ExpandButton = styled.div(props => {
  const { side } = React.useContext(NavContext)
  return {
    position: 'absolute',
    zIndex: 10,
    left: side === FlayOutDirection.right ? -13 : undefined,
    right: side === FlayOutDirection.left ? -13 : undefined,
    top: 18,
    width: 26,
    height: 26,
    borderRadius: 13,
    boxShadow:
      '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)',
    backgroundColor: colors.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
})

const Content = styled.div({
  height: '100%',
  overflow: 'hidden',
  width: '100%'
})

const NavToggler = styled(({ className }) => {
  const { side, open, navToggleIcons } = React.useContext(NavContext)
  if (navToggleIcons) {
    return navToggleIcons(open)
  }
  const chevron =
    side === FlayOutDirection.left ? (
      <ChevronLeft className={className} fontSize='small' />
    ) : (
      <ChevronRight className={className} fontSize='small' />
    )

  return open ? chevron : <Menu className={className} fontSize='small' />
})`
  color: #000000;
`

export class NavBarComponent extends React.Component<INavBar> {
  static defaultProps = {
    open: true,
    width: NAV_BAR_WIDTH,
    side: FlayOutDirection.left,
    zIndex: 1,
    clearanceWidth: 18,
    top: 0,
    navToggleIcons: null
  }

  render() {
    const {
      props: { children, open, toggleDrawer, navToggleIcons, onMouseDownHandler }
    } = this

    return (
      <NavContext.Provider value={{ ...this.props }}>
        <RootNavBar>
          <ExpandButton onClick={toggleDrawer} onMouseDown={onMouseDownHandler}>
            {navToggleIcons ? navToggleIcons(open) : <NavToggler></NavToggler>}
          </ExpandButton>
          {open && <Content className='standardScrollBar'>{children}</Content>}
        </RootNavBar>
      </NavContext.Provider>
    )
  }
}

export class NavBar extends NavBarComponent {}
