import { HttpService } from '@services/HttpService'
import { storage } from '@services/storageService'
import { config } from '@config'
import { RestServiceEndpoints } from '@constants'
import { IGetProductForecast } from '@modules/wholesale/buyingSessionGroupProductWrapper/constants/productForecastInterface'

//todo: rectify file name

export class ProductForecastDataStore {
  private httpService = new HttpService()

  getAllForecasts = async (productForecast: IGetProductForecast) => {
    const { buyingSessionGroupId, ...restParams } = productForecast
    const accessToken = storage.getItem('access_token')
    const response = await this.httpService.get(
      `${config.buyingBoardServiceRestEndpoint}${RestServiceEndpoints.FORECAST}/buyingSessionGroupId/${buyingSessionGroupId}`,
      {
        params: restParams,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
    )
    return response?.data
  }

  upsertForecast = async productForecast => {
    const accessToken = storage.getItem('access_token')
    const response = await this.httpService.post(
      `${config.buyingBoardServiceRestEndpoint}${RestServiceEndpoints.FORECAST}/product-forecast-upsert`,
      productForecast,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
    )
    return response?.data?.data?.response
  }

  multiUpsertForecast = async productForecasts => {
    const accessToken = storage.getItem('access_token')
    const response = await this.httpService.post(
      `${config.buyingBoardServiceRestEndpoint}${RestServiceEndpoints.FORECAST}/product-forecast-multi-upsert`,
      productForecasts,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
    )
    return response?.data?.data?.response
  }
}
