import React from 'react'
import { useObserver } from 'mobx-react-lite'
import ProductMedia from '@components/std-components/ProductMedia'
import Typography from '@components/std-components/Typography'
import { strings } from '@stores'
import { ProductCardPlaceholderImage } from '@resources/images'
import { useSAProductDetailsContext } from '../../hook/SAProductDetails-Hook'
import { getMediaCentricURI } from '@services/commonServices'
import { ImageAttributes, Modules, Operations } from '@modules/common'
import * as css from './SAProductDetailLooksPopup.css'

export const SAProductDetailLooksForPopup: React.FC = props => {
  return useObserver(function useHook() {
    const {
      saProductDetailsStore: { product }
    } = useSAProductDetailsContext()

    return (
      <>
        {!!product?.looks?.length && (
          <div className={css.looksWrapper}>
            <Typography variant={'h1Custom'}>{strings.associatedLooks}</Typography>
            <div className={css.looksContainer}>
              {product.looks.map(look => {
                return (
                  <div key={look?.id}>
                    <ProductMedia
                      media={{
                        src: look?.defaultImage?.id
                          ? getMediaCentricURI({
                              URL: look?.defaultImage?.id,
                              Attribute: ImageAttributes.Thumbnail,
                              Module: Modules.Publisher,
                              Operation: Operations.GetFromNode
                            })
                          : ProductCardPlaceholderImage
                      }}
                      className={css.looksMedia}
                    />
                    <Typography variant={'lookTitle'}>{look?.name ?? ''}</Typography>
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </>
    )
  })
}

SAProductDetailLooksForPopup.defaultProps = {
  looks: []
}
