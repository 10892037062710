import React from 'react'
import { strings } from '@stores'
import { MultiLineInput } from '@components/std-components/MultiLineInput'
import Button from '@components/std-components/Button'

import * as css from './SAComment.css'

interface ISAAddCommentProps {
  canEditComment?: boolean
  onChange: any
  commentText?: string
  handleSubmit: () => void
  hideButton?: boolean
  isSaveCommentInProgress?: boolean
  commentId?: string
  autofocus?: boolean
}

export const SAAddComment: React.FC<ISAAddCommentProps> = props => {
  const {
    canEditComment,
    onChange,
    commentText,
    handleSubmit,
    hideButton,
    isSaveCommentInProgress,
    commentId,
    autofocus
  } = props

  return (
    <div className={css.commentAddContainer}>
      <MultiLineInput
        autoFocus={autofocus}
        placeholder={strings.commentPlaceHolder}
        value={commentText}
        onChange={onChange(commentId)}
        disabled={!canEditComment}
        onBlur={hideButton && commentText.length ? handleSubmit : null}
      />
      {!hideButton && (
        <Button
          variant={'primary'}
          className={css.commentAddSave}
          onClick={handleSubmit}
          disabled={isSaveCommentInProgress || !commentText.length}
        >
          {strings.save}
        </Button>
      )}
    </div>
  )
}

SAAddComment.defaultProps = {
  canEditComment: false,
  autofocus: false
}
