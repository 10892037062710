import { StyledProps } from '@components/3rd-party/mui'
import { SearchIcon } from '@components/UI-Components/svg'
import Tooltip from '@material-ui/core/Tooltip'
import { SearchStore, stores, strings } from '@stores'
import { useObserver } from 'mobx-react-lite'
import React from 'react'
import * as css from './SearchComponent.css'
import { SearchInputComponent } from './SearchInputComponent'

interface SearchComponentProps extends StyledProps {
  searchText?: string
  onSearchKeyChange?: (string) => void
  localSearchStore?: SearchStore
  hideInlineInput?: boolean
}

export const SearchComponent: React.FunctionComponent<SearchComponentProps> = ({
  hideInlineInput,
  localSearchStore
}) => {
  return useObserver(function useHook() {
    const { showInputField } = localSearchStore ?? stores.search
    return (
      <div className={css.root}>
        <Tooltip title={strings.search}>
          <div className={css.icon}>
            <img src={SearchIcon} onClick={showInputField} alt='' />
          </div>
        </Tooltip>
        {!hideInlineInput && <SearchInputComponent localSearchStore={localSearchStore} />}
      </div>
    )
  })
}
