import { withStyles } from '@material-ui/core/styles'
import React from 'react'

const styles = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '6px'
  },
  item: {
    display: 'flex',
    alignItems: 'center'
  }
})

const ProductActionsContainer = ({ classes, children }) => (
  <div className={classes.container}>{children}</div>
)

const ProductActionItemContainer = ({ classes, children }) => (
  <div className={classes.item}>{children}</div>
)

export const ProductActions = withStyles(styles)(React.memo(ProductActionsContainer))
export const ProductActionItem = withStyles(styles)(
  React.memo(ProductActionItemContainer)
)
