import GET_VIP_CLIENT_ORDER from './getVIPClientOrder.graphql'
import GET_VIP_DOOR_ORDER from './getVIPDoorOrder.graphql'
import DELETE_BASKET from './deleteBasket.graphql'
import SA_CLIENT_ORDER_PRODUCT_SUMMARY from './getClientOrderProductsSummary.graphql'
export {
  SA_CLIENT_ORDER_PRODUCT_SUMMARY,
  GET_VIP_CLIENT_ORDER,
  GET_VIP_DOOR_ORDER,
  DELETE_BASKET
}
export * from './hooks'
