import {
  WholesaleSettingsSection,
  WholesaleSettingsSectionDisplayName,
  WholesaleSettingsView,
  WholesaleSettingsViewDisplayName
} from '@modules/common/models/enums/WholesaleSettingsRoute'
import { isAdmin, isZAM } from '@services/commonServices'
import { nav } from '@stores/nav'
import cloneDeep from 'lodash/cloneDeep'
import filter from 'lodash/filter'
import find from 'lodash/find'
import map from 'lodash/map'
import { action, computed, observable } from 'mobx'
import { UserRoles } from '@modules/common/models/enums/UserRoles'
import { getHighestUser } from '@routes'
import includes from 'lodash/includes'

export interface IZone {
  Id
  Name
}

export class WholesaleSettingsSidebarStore {
  @observable selectedSettingMenu = { section: '' }
  /**
   * FIXME: RM - Rename appSettings to something more relevant once cluster
   * story is done so that there are no conflicts
   */
  @observable appSettings = [
    {
      collapseHeader: WholesaleSettingsSectionDisplayName.Settings,
      section: WholesaleSettingsSection.Settings,
      isCollapsable: true,
      isSelected: true,
      allowedRoles: [],
      view: WholesaleSettingsSection.Settings,
      menus: [
        {
          displayName: WholesaleSettingsViewDisplayName.Clusters,
          view: WholesaleSettingsView.Clusters,
          isSelected: false
        },
        {
          displayName: WholesaleSettingsViewDisplayName.SeasonsSyncUp,
          view: WholesaleSettingsView.SeasonsSyncUp,
          isSelected: false
        },
        {
          displayName: WholesaleSettingsViewDisplayName.GlobalDataSync,
          view: WholesaleSettingsView.GlobalDataSync,
          isSelected: false
        },
        {
          displayName: WholesaleSettingsViewDisplayName.ActivitySelection,
          view: WholesaleSettingsView.ActivitySelection,
          isSelected: false
        }
      ]
    },
    {
      collapseHeader: WholesaleSettingsSectionDisplayName.WholesaleSettings,
      section: WholesaleSettingsSection.WholesaleSettings,
      isCollapsable: true,
      isSelected: false,
      view: WholesaleSettingsSection.WholesaleSettings,
      allowedRoles: [
        UserRoles.ADMINISTRATOR,
        UserRoles.CM_ADMIN,
        UserRoles.CENTRAL_MERCHANDISER_WHOLESALE
      ],
      menus: [
        {
          collapseHeader: WholesaleSettingsSectionDisplayName.Clients,
          section: WholesaleSettingsSection.Clients,
          isCollapsable: true,
          isSelected: false,
          view: WholesaleSettingsSection.Clients,
          displayName: WholesaleSettingsSectionDisplayName.Clients,
          menus: [
            {
              displayName: WholesaleSettingsViewDisplayName.Worldwide,
              view: WholesaleSettingsView.Worldwide,
              isSelected: false
            }
          ]
        },
        {
          collapseHeader: WholesaleSettingsSectionDisplayName.Discounts,
          section: WholesaleSettingsSection.Discounts,
          isCollapsable: false,
          isSelected: false,
          view: WholesaleSettingsView.Discounts,
          menus: null,
          displayName: WholesaleSettingsSectionDisplayName.Discounts
        }
      ]
    },
    {
      collapseHeader: WholesaleSettingsSectionDisplayName.RetailSettings,
      section: WholesaleSettingsSection.RetailSettings,
      isCollapsable: true,
      isSelected: false,
      view: WholesaleSettingsSection.RetailSettings,
      allowedRoles: [
        UserRoles.ADMINISTRATOR,
        UserRoles.CM_ADMIN,
        UserRoles.CENTRAL_MERCHANDISER_RETAIL
      ],
      menus: [
        {
          collapseHeader: WholesaleSettingsSectionDisplayName.Zones,
          section: WholesaleSettingsSection.Zones,
          isCollapsable: true,
          isSelected: false,
          view: WholesaleSettingsSection.Zones,
          displayName: WholesaleSettingsSectionDisplayName.Zones,
          menus: [
            {
              displayName: WholesaleSettingsViewDisplayName.Worldwide,
              view: WholesaleSettingsView.Worldwide,
              isSelected: false
            }
          ]
        },
        {
          collapseHeader: WholesaleSettingsSectionDisplayName.Memberships,
          section: WholesaleSettingsSection.Memberships,
          isCollapsable: false,
          isSelected: false,
          view: WholesaleSettingsView.Memberships,
          menus: null,
          displayName: WholesaleSettingsSectionDisplayName.Memberships
        },
        {
          collapseHeader: WholesaleSettingsSectionDisplayName.MarkupOrDiscount,
          section: WholesaleSettingsSection.MarkupOrDiscount,
          isCollapsable: false,
          isSelected: false,
          view: WholesaleSettingsView.MarkupOrDiscount,
          menus: null,
          displayName: WholesaleSettingsSectionDisplayName.MarkupOrDiscount
        }
      ]
    }
  ]
  /**
   * it will return the filtered menu items
   */
  getFileterMenus = (menus, filterMenu) => {
    let filteredMenus = filter(menus, menu => !filterMenu.includes(menu.section))
    return filteredMenus
  }

  @computed
  get settingMenus() {
    if (this.selectedSettingMenu.section) {
      const isZamLoggedIn = isZAM()
      const loggedInUser = getHighestUser()
      const loggedInUserRoleName = loggedInUser?.[0]?.name ?? ''
      let appSettings: any = this.appSettings
      if (isZamLoggedIn) {
        const settings: any = this.appSettings.find(
          menu => menu.section === WholesaleSettingsSection.WholesaleSettings
        )
        const newMenu = settings?.menus?.filter(menu => {
          return menu.section === WholesaleSettingsSection.Clients
        })
        const obj = {
          collapseHeader: settings.collapseHeader,
          section: settings.section,
          isCollapsable: settings.isCollapsable,
          isSelected: settings.isSelected,
          menus: newMenu
        }
        appSettings = [obj]
      }
      let menus = []
      appSettings.forEach(menu => {
        const appMenu: any = cloneDeep(menu)
        if (menu.section === this.selectedSettingMenu.section) {
          appMenu.isSelected = true
        } else {
          appMenu.isSelected = false
        }
        if (!isAdmin() && appMenu.section === WholesaleSettingsSection.Settings) {
          appMenu.menus = filter(
            appMenu.menus,
            menu =>
              ![
                WholesaleSettingsView.SeasonsSyncUp,
                WholesaleSettingsView.ActivitySelection
              ].includes(menu.view)
          )
        }
        menus.push(appMenu)
      })
      menus = menus.filter(menu => {
        return (
          !menu.allowedRoles?.length || includes(menu.allowedRoles, loggedInUserRoleName)
        )
      })
      return menus
    }
    return []
  }

  @action
  setSelectedMenu = section => {
    this.selectedSettingMenu = section
  }

  /**
   * This function will return the menu option which needs to be selected at first.
   */
  @action getMenuOptions = () => {
    let menus = cloneDeep(this.appSettings)
    if (isZAM()) {
      let menuIndexToBeUdated = menus.findIndex(menu => menu.isSelected === true)
      let menuIndexToBeSelected = menus.findIndex(
        menu => menu.section === WholesaleSettingsSection.WholesaleSettings
      )
      menus[menuIndexToBeUdated].isSelected = false
      menus[menuIndexToBeSelected].isSelected = true
      return menus
    }
    return menus
  }

  @action updateClientMenu = (zones: Array<IZone>) => {
    const {
      queryParams: { view }
    } = nav
    /**
     * Build menu options from zones
     */
    let clientMenuOptions = map(zones, (zone: IZone) => {
      const { Id, Name } = zone
      return {
        displayName: Name,
        view: Id,
        isSelected: view === Id
      }
    })

    /**
     * Add worldwide as first entry in menu options array
     */
    clientMenuOptions.unshift({
      displayName: WholesaleSettingsViewDisplayName.Worldwide,
      view: WholesaleSettingsView.Worldwide,
      isSelected: view === WholesaleSettingsView.Worldwide
    })

    /**
     * Replace menu options for clients in observable by array computed above
     */
    const wholesaleSettings: any = this.appSettings.find(
      setting => setting.section === WholesaleSettingsSection.WholesaleSettings
    )
    let clientSection = find(
      wholesaleSettings.menus,
      setting => setting.section === WholesaleSettingsSection.Clients
    )
    clientSection.menus = clientMenuOptions
  }

  // this function will update retail zone menu options when the zones data is available
  @action updateRetailZonesMenu = (zones: Array<IZone>) => {
    const {
      queryParams: { view }
    } = nav
    /**
     * Build menu options from zones
     */
    let storeMenuOptions = map(zones, (zone: IZone) => {
      const { Id, Name } = zone
      return {
        displayName: Name,
        view: Id,
        isSelected: view === Id
      }
    })

    /**
     * Add worldwide as first entry in menu options array
     */
    storeMenuOptions.unshift({
      displayName: WholesaleSettingsViewDisplayName.Worldwide,
      view: WholesaleSettingsView.Worldwide,
      isSelected: view === WholesaleSettingsView.Worldwide
    })
    /**
     * Replace menu options for stores in observable by array computed above
     */
    const RetailSettings: any = this.appSettings.find(
      setting => setting.section === WholesaleSettingsSection.RetailSettings
    )
    let storeSection = find(
      RetailSettings.menus,
      setting => setting.section === WholesaleSettingsSection.Zones
    )
    storeSection.menus = storeMenuOptions
  }
}

export const wholesaleSettingsSidebar = new WholesaleSettingsSidebarStore()
