import { withApollo } from '@apollo/client/react/hoc/withApollo'
import { StyledProps } from '@components/3rd-party/mui'
import { PrimaryButton } from '@components/UI-Components/PrimaryButton'
import { TextInput } from '@components/UI-Components/TextInput'
import { User } from '@models'
import { BUYING_SESSION_PRODUCT } from '@modules/retail/collection/graphql/buyingSessionProduct'
import { ADD_COMMENT } from '@modules/retail/productDetail/graphql/addComment'
import { DELETE_COMMENT } from '@modules/retail/productDetail/graphql/deleteComment'
import { GET_COMMENTS } from '@modules/retail/productDetail/graphql/getComments'
import { GET_RM_COMMENTS } from '@modules/retail/productDetail/graphql/getRMComments'
import { UPDATE_COMMENT } from '@modules/retail/productDetail/graphql/updateComment'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { storage } from '@services/storageService'
import React from 'react'
import { CommentItem } from './CommentItem'

const HEIGHT = 80
const styles = theme => ({
  action: {
    margin: theme.spacing(1, 0)
  },
  commentBox: {
    paddingLeft: 8,
    paddingRight: 40
  }
})

interface IComments extends StyledProps {
  data: any
  productId: any
  client?: any
  isActive?: Boolean
  isRestricted?: boolean
  isClosed?: boolean
}
@(withApollo as any)
@((withStyles as any)(styles))
export class Comments extends React.Component<IComments> {
  state = {
    commentText: ''
  }

  handleComment = e => {
    this.setState({ commentText: e.target.value })
  }

  addComment = async productId => {
    const {
      props: { client },
      state: { commentText }
    } = this

    const user = storage.getItem<User>('user')
    const zoneIds =
      user.membership && user.membership.zones
        ? user.membership.zones.map(zone => zone.id)
        : null

    if (commentText && commentText.trim().length > 0) {
      await client.mutate({
        mutation: ADD_COMMENT,
        variables: {
          productId,
          value: commentText.trim()
        }
      })

      await client.query({
        query: zoneIds ? GET_RM_COMMENTS : GET_COMMENTS,
        variables: {
          productId: productId,
          zoneIds
        },
        fetchPolicy: 'network-only'
      })

      this.setState({ commentText: '' })
    }
  }

  deleteComment = async commentId => {
    const {
      props: { client, productId }
    } = this
    const user = storage.getItem<User>('user')
    const zoneIds =
      user.membership && user.membership.zones
        ? user.membership.zones.map(zone => zone.id)
        : null

    await client.mutate({
      mutation: DELETE_COMMENT,
      variables: {
        commentId
      },
      refetchQueries: [
        {
          query: BUYING_SESSION_PRODUCT,
          variables: {
            id: productId,
            includeUserFavourite: false
          }
        }
      ]
    })

    await client.query({
      query: zoneIds ? GET_RM_COMMENTS : GET_COMMENTS,
      variables: {
        productId: productId,
        zoneIds
      },
      fetchPolicy: 'network-only'
    })
  }

  updateComment = async (commentId, value) => {
    const {
      props: { client }
    } = this
    await client.mutate({
      mutation: UPDATE_COMMENT,
      variables: {
        commentId,
        value
      }
    })
  }

  render() {
    const user = storage.getItem<User>('user')
    const {
      props: { data, productId, classes, isActive, isRestricted, isClosed },
      state: { commentText },
      addComment,
      deleteComment,
      updateComment
    } = this
    return (
      <div>
        <Typography variant='h5'>Comments:</Typography>
        {data &&
          data.comments &&
          data.comments.map(comment => (
            <CommentItem
              key={comment.id}
              comment={comment}
              productId={productId}
              addComment={addComment}
              deleteComment={deleteComment}
              updateComment={updateComment}
              user={user}
            />
          ))}
        <div className={classes.commentBox}>
          <TextInput
            disabled={!isActive || isRestricted || isClosed}
            id='outlined-textarea'
            label='Comment'
            value={commentText}
            multiline
            onChange={this.handleComment}
            fullWidth
            style={{ height: HEIGHT }}
            rows={2}
            rowsMax={2}
            margin='normal'
          />

          <div className={classes.action}>
            <PrimaryButton
              disabled={
                !commentText ||
                commentText.trim().length === 0 ||
                !isActive ||
                isClosed ||
                isRestricted
              }
              onClick={() => {
                addComment(productId)
              }}
            >
              Save
            </PrimaryButton>
          </div>
        </div>
      </div>
    )
  }
}
