import { config } from '@config'
import { RestServiceEndpoints } from '@constants'
import { OrderDataKeys, OrderType } from '@modules/common/models/enums'
import { ATTR_FOR_APPLY_TO_ALL } from '@modules/models'
import { GET_CLIENT_ORDER } from '@modules/showroom/basketAndOrders/graphql/getClientOrder'
import { GET_DOOR_ORDER } from '@modules/showroom/basketAndOrders/graphql/getDoorOrder'
import { UPDATE_DELIVERY_DATE_FOR_ORDER } from '@modules/showroom/basketAndOrders/graphql/updateDeliveryDateForOrder'
import { sessionUrl } from '@services/centric/SDKServiceUtils'
import { getEpochTimeStamp, getUTCRepresentation } from '@services/commonServices'
import { downloadFile } from '@services/downloadFileService'
import { exportExcel } from '@services/exportExcel'
import { apolloClient, stores, strings } from '@stores'
import filter from 'lodash/filter'
import forEach from 'lodash/forEach'
import isEmpty from 'lodash/isEmpty'
import { action, computed, observable } from 'mobx'
import qs from 'query-string'
import { toast } from 'react-toastify'
import { MAX_ALLOWED_ORDER_SELECTION } from '../common/SAOrdersTableConstants'
import { ordersDataStore } from './ordersDataStore'
import { ordersUIStore } from './ordersUIStore'
import { OrderService } from '@modules/order/services/orderService'
import { createValidationErrorMessage } from '@services/wholesale/OrderManagementService'

export class ShowroomOrderManagementStore {
  @observable selectedOrderId = null
  @observable selectedOrderType = null

  @observable actionsMenuAnchorEleMap = {}
  @observable isDeleteConfirmationPpopupVisible = false
  @observable excelExportWithImageLimitErrorMessage = ''
  @observable PORefInEditModeEditMap = {}
  @observable isNotesModalVisible = false
  @observable isNotesEditable = false
  @observable notesText = ''
  @observable oldNotesText = ''
  @observable exportAnchor = null
  @observable exportAnchorSubMenu = null
  @observable isExportInProgress = false
  @observable inProcessOrderPDF = {}
  @observable isOrderDeleteInProgress = false
  @observable selectedOrdersToExport = {}
  @observable noOfSelectedOrders = 0 // not used
  @observable isExportAllInProgress = false
  @observable selectedMacroOrders = []
  // Reset Delete Count
  @observable macroOrderId = null
  @observable isClientMasterForDelete = false
  @observable isClientMaster = false

  private orderService = new OrderService()

  @action setNotes = () => event => (this.notesText = event.target.value)

  @computed get excelExportWithImageHasLimitError() {
    return !isEmpty(this.excelExportWithImageLimitErrorMessage)
  }

  @computed get getSelectedOrdersCount() {
    let noOfSelectedOrders = 0
    for (const key in this.selectedOrdersToExport) {
      noOfSelectedOrders += this.selectedOrdersToExport[key].length
    }
    return noOfSelectedOrders
  }

  @action resetExportOrdersToExcelSelection = () => {
    this.selectedOrdersToExport = {}
    this.selectedMacroOrders = []
    this.exportAnchor = null
    this.exportAnchorSubMenu = null
  }

  @action resetOrderSelectionCountOnDelete = () => {
    // When One Micro Order Is Deleted, selectedOrderId = doorOrderId
    this.selectedOrdersToExport[this.macroOrderId] = this.getFilteredData(
      this.selectedOrdersToExport[this.macroOrderId],
      this.selectedOrderId
    )
    // When All Micro Orders Are Deleted
    if (!this.selectedOrdersToExport[this.macroOrderId]?.length) {
      this.selectedMacroOrders = this.getFilteredData(
        this.selectedMacroOrders,
        this.macroOrderId
      )
      delete this.selectedOrdersToExport[this.macroOrderId]
    }
  }

  getFilteredData = (array, filterByValue) => {
    return filter(array, element => element !== filterByValue)
  }

  @action toggleSelectedMacroOrder = order => event => {
    if (!order?.[OrderDataKeys.IsClientMaster]) {
      this.isClientMaster = false
      if (event.target.checked) {
        // if (this.selectedOrdersToExport?.[order.id]?.length) {
        //   this.selectedOrdersToExport[order.id] = [
        //     ...this.selectedOrdersToExport[order.id]
        //   ]
        // }
        this.selectedOrdersToExport[order.id] = []
        forEach(order.doorOrders, doorOrder => {
          if (this.getSelectedOrdersCount < MAX_ALLOWED_ORDER_SELECTION) {
            this.selectedOrdersToExport[order.id].push(doorOrder.id)
          }
        })
        if (this.selectedOrdersToExport?.[order.id]?.length === order.doorOrders.length) {
          this.selectedMacroOrders.push(order.id)
        }
        // else {
        //   delete this.selectedOrdersToExport[order.id]
        // }
      } else {
        delete this.selectedOrdersToExport[order.id]
        this.selectedMacroOrders = this.getFilteredData(
          this.selectedMacroOrders,
          order.id
        )
      }
    } else {
      this.isClientMaster = true
      if (event.target.checked) {
        this.selectedOrdersToExport[order.id] = [order.id]
        this.selectedMacroOrders.push(order.id)
      } else {
        this.selectedOrdersToExport[order.id] = this.getFilteredData(
          this.selectedOrdersToExport[order.id],
          order.id
        )
        this.selectedMacroOrders = this.getFilteredData(
          this.selectedMacroOrders,
          order.id
        )
      }
    }
  }

  @action toggleSelectedMicroOrder = (order, doorOrderId) => event => {
    if (event.target.checked) {
      const limit = this.getSelectedOrdersCount
      if (limit < MAX_ALLOWED_ORDER_SELECTION) {
        if (this.selectedOrdersToExport[order.id]) {
          this.selectedOrdersToExport[order.id].push(doorOrderId)
        } else {
          this.selectedOrdersToExport[order.id] = [doorOrderId]
        }
        if (this.selectedOrdersToExport[order.id].length === order.doorOrders.length) {
          this.selectedMacroOrders.push(order.id)
        }
      }
    } else {
      this.selectedMacroOrders = this.getFilteredData(this.selectedMacroOrders, order.id)
      this.selectedOrdersToExport[order.id] = this.getFilteredData(
        this.selectedOrdersToExport[order.id],
        doorOrderId
      )
    }
  }

  @action onNotesCellClick =
    (orderId, notesText, orderType, isNotesEditable = true) =>
    event => {
      if (!isNotesEditable && notesText === '') {
        return
      }
      this.selectedOrderId = orderId
      this.notesText = notesText
      this.oldNotesText = notesText
      this.isNotesModalVisible = true
      this.selectedOrderType = orderType
      this.isNotesEditable = isNotesEditable
    }

  @action onNotesModalCancelClick = () => () => {
    this.selectedOrderId = null
    this.notesText = ''
    this.isNotesModalVisible = false
    this.isNotesEditable = false
  }

  @action onNotesModalSaveClick = () => async () => {
    const isClientOrder = this.selectedOrderType === OrderType.Client
    try {
      const response = await this.orderService.updateNotes(
        this.selectedOrderId,
        this.notesText,
        this.selectedOrderType
      )
      if (response?.data?.updatedOrderDetails?.message === 'Success') {
        isClientOrder
          ? ordersUIStore.updateClientOrder(
              this.selectedOrderId,
              this.notesText,
              OrderDataKeys.Notes
            )
          : ordersUIStore.updateDoorOrder(
              this.selectedOrderId,
              this.notesText,
              OrderDataKeys.Notes
            )
      }
    } finally {
      this.onNotesModalCancelClick()()
    }
  }

  @action onOrderDeliveryDateChange =
    (orderId: string, orderType: string, isCMView = false) =>
    async (deliveryDate: Date) => {
      const {
        orderManagementStore: { getOrders }
      } = stores
      const isClientOrder = orderType === OrderType.Client
      const variables = isClientOrder
        ? {
            clientOrderId: orderId
          }
        : {
            doorOrderId: orderId
          }
      const refetchQueries = [
        {
          query: isClientOrder ? GET_CLIENT_ORDER : GET_DOOR_ORDER,
          variables
        }
      ]
      try {
        const response = isCMView
          ? await apolloClient.mutate({
              mutation: UPDATE_DELIVERY_DATE_FOR_ORDER,
              variables: {
                orderId,
                deliveryDate: getUTCRepresentation(deliveryDate)
              },
              refetchQueries
            })
          : await this.orderService.updateDeliveryDate(
              orderId,
              getEpochTimeStamp(deliveryDate),
              orderType
            )
        if (!isCMView && response?.data?.updatedOrderDetails?.message === 'Success') {
          isClientOrder
            ? ordersUIStore.updateClientOrder(
                orderId,
                deliveryDate,
                OrderDataKeys.DeliveryDate
              )
            : ordersUIStore.updateDoorOrder(
                orderId,
                deliveryDate,
                OrderDataKeys.DeliveryDate
              )
        } else if (isCMView) {
          getOrders()
        }
      } finally {
      }
    }

  @action savePORef = (orderId, orderType, oldValue) => async event => {
    if (event?.target?.value === oldValue?.value) {
      this.PORefInEditModeEditMap[orderId] = null
      return
    }
    const value = event.target.value
    const isClientOrder = orderType === OrderType.Client
    try {
      const response = await this.orderService.updatePORef(
        orderId,
        event.target.value,
        orderType
      )
      if (response?.data?.updatedOrderDetails?.message === 'Success') {
        isClientOrder
          ? ordersUIStore.updateClientOrder(orderId, value, OrderDataKeys.PORef)
          : ordersUIStore.updateDoorOrder(orderId, value, OrderDataKeys.PORef)
      }
    } finally {
      this.PORefInEditModeEditMap[orderId] = null
    }
  }

  @action showExportMenu = event => {
    this.exportAnchor = event.currentTarget
  }

  @action showExportSubMenu = event => {
    this.exportAnchorSubMenu = event.currentTarget
  }

  @action subMenuClose = () => {
    this.exportAnchorSubMenu = null
  }

  @action hideExportMenu = event => {
    this.exportAnchor = null
    this.exportAnchorSubMenu = null
  }

  @observable enablePORefEdit = orderId => event =>
    (this.PORefInEditModeEditMap[orderId] = true)

  @action showActionsMenu = (macroOrder, orderId) => event => {
    this.actionsMenuAnchorEleMap[orderId] = event.currentTarget
    this.selectedOrderId = orderId
    this.macroOrderId = macroOrder?.id ?? null
  }

  @action hideActionsMenu = orderId => () => {
    this.actionsMenuAnchorEleMap[orderId] = null
    this.selectedOrderId = null
    this.macroOrderId = null
  }

  @action onDeleteClick = isClientMaster => {
    this.isDeleteConfirmationPpopupVisible = true
    this.isClientMasterForDelete = isClientMaster
  }

  @action onDeleteConfirmationClose = () =>
    Object.assign(this, {
      isDeleteConfirmationPpopupVisible: false,
      isClientMasterForDelete: false
    })

  @action deleteOrder = async event => {
    if (this.isOrderDeleteInProgress) {
      return
    }
    this.isOrderDeleteInProgress = true
    try {
      const response: any = await ordersDataStore.deleteOrder(
        this.selectedOrderId,
        this.isClientMasterForDelete ? 'client' : 'door'
      )
      if (response?.message === 'Success') {
        this.isClientMasterForDelete
          ? ordersUIStore.deleteClientOrder(this.selectedOrderId)
          : ordersUIStore.deleteDoorOrder(this.selectedOrderId)
      }
      // Reset selected order count
      // Delete: Micro Order, pass doorOrderId
      this.resetOrderSelectionCountOnDelete()
    } finally {
      this.isOrderDeleteInProgress = false
      this.onDeleteConfirmationClose()
      this.hideActionsMenu(this.selectedOrderId)()
    }
  }

  @action copyAttributeToAllMicroOrders =
    (clientOrderId: string, attribute: string) => async event => {
      if (attribute !== OrderDataKeys.Notes && attribute !== OrderDataKeys.PORef) {
        return
      }
      const attributeToCopy =
        attribute === OrderDataKeys.PORef
          ? ATTR_FOR_APPLY_TO_ALL.POREF
          : attribute === OrderDataKeys.Notes
          ? ATTR_FOR_APPLY_TO_ALL.NOTES
          : attribute === OrderDataKeys.DeliveryDate
          ? ATTR_FOR_APPLY_TO_ALL.DELIVERY_DATE
          : null
      if (attributeToCopy) {
        try {
          const response = await this.orderService.applyToAllSummary(clientOrderId, {
            attributeToCopy
          })
          if (response?.message === 'Success') {
            ordersUIStore.applyToAll(clientOrderId, attribute)
          }
        } catch (e) {
          console.log(e)
        }
      }
    }

  @action updateOrderStatusForSA =
    (orderId, orderType, currentStatus, statusUpdateValidationMetadata) =>
    async event => {
      const newStatus = event.target.value
      if (
        (orderType !== OrderType.Client && orderType !== OrderType.Door) ||
        newStatus === currentStatus
      ) {
        return
      }

      let {
        orderManagementStore,
        orderManagementStore: { showEmptyOrderDeleteConfirmationPopup }
      } = stores

      let inProcessStatusValue =
        config?.appConfig?.enumerations?.orderStatus?.ORDER_STATUS_VALUES?.['In Process']

      if (
        newStatus !== inProcessStatusValue &&
        (!statusUpdateValidationMetadata.canUpdateOrderStatus ||
          statusUpdateValidationMetadata.hasInactiveProductSizeWithQty)
      ) {
        orderManagementStore.openMessageDialog = true

        if (!statusUpdateValidationMetadata.canUpdateOrderStatus) {
          orderManagementStore.statusChangeConfirmationMessage =
            strings.canNotChageOrderStatus
        } else {
          orderManagementStore.statusChangeConfirmationMessage =
            strings.cannotChangeStatusAsProductsAreInactive
        }
        return
      }

      orderManagementStore.selectedOrderIdForStatusUpdate = orderId
      orderManagementStore.selectedOrderTypeForStatusUpdate = orderType
      orderManagementStore.statusValueToBeUpdated = newStatus

      // Validate data in case order status to be updated to confirmed
      let confirmedStatusValue =
        config?.appConfig?.enumerations?.orderStatus?.ORDER_STATUS_VALUES?.Confirmed
      if (newStatus === confirmedStatusValue) {
        let emptyOrders
        if (orderType === OrderType.Client) {
          emptyOrders = await ordersDataStore.getEmptyClientOrders([orderId])
        } else {
          emptyOrders = await ordersDataStore.getEmptyDoorOrders([orderId])
        }
        if (emptyOrders?.length) {
          showEmptyOrderDeleteConfirmationPopup()
          return
        }
        // Display warning dialog
        orderManagementStore.showWarningDialog = true
      } else {
        this.handleStatusChange()
      }
    }

  @action handleStatusChange = async () => {
    let {
      orderManagementStore,
      orderManagementStore: {
        statusValueToBeUpdated,
        selectedOrderTypeForStatusUpdate,
        selectedOrderIdForStatusUpdate,
        handleStatusChangeWarningDialogClose
      },
      nav: {
        queryParams: { clientId }
      },
      axios: { setErrToastVisibility }
    } = stores

    if (orderManagementStore.isStatusChangeInProgress) {
      return
    }

    if (statusValueToBeUpdated) {
      orderManagementStore.isStatusChangeInProgress = true
      setErrToastVisibility(false)
      try {
        const response = await ordersDataStore.updateOrderStatus(
          [selectedOrderIdForStatusUpdate],
          statusValueToBeUpdated,
          clientId
        )

        if (response?.status === 200) {
          selectedOrderTypeForStatusUpdate === OrderType.Client
            ? ordersUIStore.updateClientOrderStatus(
                selectedOrderIdForStatusUpdate,
                statusValueToBeUpdated
              )
            : ordersUIStore.updateDoorOrderStatus(
                selectedOrderIdForStatusUpdate,
                statusValueToBeUpdated
              )
        }
      } catch (e) {
        const message = e.response?.data?.message
        if (message) {
          try {
            const error = JSON.parse(message)
            if (error) {
              orderManagementStore.orderStatusValidationError = true
              orderManagementStore.orderStatusValidationErrorMessage =
                createValidationErrorMessage(
                  error,
                  strings.bulkOrderValidationPopupDescription
                )
            }
          } catch (parsingException) {
            orderManagementStore.orderStatusValidationError = true
            orderManagementStore.orderStatusValidationErrorMessage = message
          }
        }
      } finally {
        handleStatusChangeWarningDialogClose()
        setErrToastVisibility(true)
      }
    } else {
      handleStatusChangeWarningDialogClose()
    }
  }

  @action onViewOrderClick = (orderId, orderType, clientOrderId, buyingSessionId?) => {
    let {
      nav: {
        history,
        queryParams: { buyingSessionGroupId, clientId, clientType }
      }
    } = stores
    history.push({
      pathname: `/showroom/clients/buyingSessionGroup/activities/order`,
      search: qs.stringify({
        clientId,
        buyingSessionGroupId,
        orderId: orderId,
        orderType: orderType,
        clientOrderId: clientOrderId ?? '',
        clientType: clientType,
        buyingSession: buyingSessionId ?? ''
      })
    })
    this.hideActionsMenu(this.selectedOrderId)()
  }

  @action onExportToPDF = (orderId: string, type: string) => async e => {
    if (orderId && type && !this.inProcessOrderPDF[orderId]) {
      this.inProcessOrderPDF[orderId] = true
      try {
        const response = await downloadFile(
          `${config.pdfServiceEndPoint}pdf/v1/orders/${orderId}/type/${type}`,
          null,
          sessionUrl()
        )
        if (response.error) {
          toast.error(response.message || strings.genericError, {
            position: toast.POSITION.BOTTOM_RIGHT,
            bodyClassName: 'error-toastr',
            progressClassName: 'error-progress-bar-toaster'
          })
        }
      } catch (err) {
        if (err.status !== 200 || err?.data?.error) {
          toast.error(err?.data?.error || strings.genericError, {
            position: toast.POSITION.BOTTOM_RIGHT,
            bodyClassName: 'error-toastr',
            progressClassName: 'error-progress-bar-toaster'
          })
        }
      } finally {
        this.inProcessOrderPDF[orderId] = false
      }
    }
  }

  /**
   * Method for exporting orders to excel
   */
  @action exportOrdersToExcel =
    (withImages = false, exportType?: string) =>
    async e => {
      const {
        nav: {
          queryParams: { clientId, buyingSessionGroupId }
        }
      } = stores
      const orderIds = []
      for (const key in this.selectedOrdersToExport) {
        orderIds.push(...this.selectedOrdersToExport[key])
      }
      if (clientId && buyingSessionGroupId) {
        if (this.isExportInProgress || this.isExportAllInProgress) {
          return
        } else {
          try {
            this.isExportInProgress = true
            this.subMenuClose()
            const response = await exportExcel(
              `${config.buyingBoardServiceRestEndpoint}${RestServiceEndpoints.DOWNLOAD_CLIENT_ORDERS}buyingSessionGroup/${buyingSessionGroupId}/client/${clientId}`,
              {
                ...(this.isClientMaster && { clientOrders: orderIds }),
                ...(!this.isClientMaster && { doorOrders: orderIds }),
                withImages
              }
            )
            if (response.message) {
              if (strings.exportExcelError[response?.message]) {
                showroomOrderManagementStore.excelExportWithImageLimitErrorMessage =
                  strings.exportExcelError[response?.message]
              } else {
                toast.error(response.message || strings.genericError, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  bodyClassName: 'error-toastr',
                  progressClassName: 'error-progress-bar-toaster'
                })
              }
            }
          } catch (err) {
            if (
              err.status !== 200 ||
              err?.data?.error ||
              err?.data?.status !== strings.centricCallSuccess
            ) {
              if (err.status === 503 && strings.exportExcelError[err?.data?.error]) {
                showroomOrderManagementStore.excelExportWithImageLimitErrorMessage =
                  strings.exportExcelError[err?.data?.error]
              } else {
                toast.error(err?.data?.error || strings.genericError, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  bodyClassName: 'error-toastr',
                  progressClassName: 'error-progress-bar-toaster'
                })
              }
            }
          } finally {
            this.isExportInProgress = false
            this.isExportAllInProgress = false
          }
        }
      }
    }
}

export const showroomOrderManagementStore = new ShowroomOrderManagementStore()
