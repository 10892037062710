import IosStar from '@meronex/icons/ios/IosStar'
import IosStarOutline from '@meronex/icons/ios/IosStarOutline'
import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import Rating, { RatingComponentProps } from 'react-rating'
import '@styles/Rating/style.css'

interface Props extends RatingComponentProps {
  rating: number
  size?: number
  starIconClassName?: string
  onChangeRating: (newRating: number) => void
  onUnselectRating?: (...rest: any[]) => void
}

const StartRating: React.FC<Props> = ({
  rating,
  onChangeRating,
  onUnselectRating,
  children,
  className,
  size,
  starIconClassName,
  ...rest
}): JSX.Element => {
  const [currentRating, setCurrentRating] = useState<number>()

  useEffect(() => {
    setCurrentRating(rating)
    return () => {
      setCurrentRating(0)
    }
  }, [rating])

  return (
    <>
      <Rating
        onClick={value => {
          if (value === +currentRating!) {
            onUnselectRating && onUnselectRating(value)
          }
        }}
        onChange={onChangeRating}
        initialRating={rating}
        emptySymbol={
          <IosStarOutline
            size={size || 28}
            className={cn('rating-icon', starIconClassName)}
          />
        }
        fullSymbol={
          <IosStar size={size || 28} className={cn('rating-icon', starIconClassName)} />
        }
        className={className}
        {...rest}
      />
      {children}
    </>
  )
}

export default StartRating
