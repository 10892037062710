import { config } from '@config'
import { HttpService } from '@services/HttpService'
import { storage } from '@services/storageService'

const GET_BUYING_SESSION_GROUPS = 'buyingSessionGroup/client'

export class BuyingSessionGroupService {
  httpService = new HttpService()

  getBuyingSessionGroups = (clientId: string, searchKey = '') => {
    const url = `${config.buyingBoardServiceRestEndpoint}${GET_BUYING_SESSION_GROUPS}/${clientId}`
    const accessToken: string = storage.getItem('access_token')
    return this.httpService
      .get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params: {
          ...(searchKey ? { searchKey } : {})
        }
      })
      .then(response => response.data)
  }
}
