import { config } from '@config'
import { storage } from '@services/storageService'
import { HttpService } from '@services/HttpService'
import { VIPProductService } from '../constants/apiConstants'

export class CreateBuyingSessionAPIService {
  httpService = new HttpService()
  getCollectionAndActivities = async (seasonId, genderid) => {
    try {
      const url = `${config.buyingBoardServiceRestEndpoint}${VIPProductService.GET_COLLECTION_AND_ACTIVITIES}/season/${seasonId}/gender/${genderid}`
      const accessToken: string = storage.getItem('access_token')

      const response = await this.httpService.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      return response
    } catch (e) {
      console.log(e)
    }
  }
}
