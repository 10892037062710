import memoize from 'memoize-one'
import { ProductStyle } from '@models'

import moment from 'moment'
import { strings, site, stores } from '@stores'
import { User } from '@models'
import {
  ACTIVITY_HIERARCHY_MAP,
  BUYING_SESSION_PRODUCT_ATTRIBUTES,
  CLUSTER_STATUS,
  DEFAULT_ACTIVITY_HIERARCHY,
  GLOBAL_PRODUCT_ATTRIBUTES,
  LOOK_ATTRIBUTES,
  LOOK_NAME,
  NO_OF_FILTER_TO_SHOW
} from '@constants'
import {
  COLOR,
  HIERARCHY,
  RESTRICT_PRODUCT_BY_ZONE_TABS
} from '@modules/collection/constants'
import { getDateDiff } from '@modules/retail/salesPeriod/services/SalesPeriodServices'
import { UserRoles } from '@modules/common/models/enums/UserRoles'
import { Chip, ClusterChip } from '@modules/common/models/interfaces/ListViewProduct'
import { ZoneDisplayNames } from '@modules/common/models/enums/ZoneDisplayNames'
import { storage } from './storageService'
import {
  convertNumberToPercentage,
  getChildrenMetaData,
  getFilterProductsByHierarchy,
  getZoneOrder,
  sortListUsingOrderList
} from './commonServices'
import { convertToCurrencyLocaleString } from './assortmentService'
import cloneDeep from 'lodash/cloneDeep'
import difference from 'lodash/difference'
import filter from 'lodash/filter'
import find from 'lodash/find'
import groupBy from 'lodash/groupBy'
import isEqual from 'lodash/isEqual'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'
import unionBy from 'lodash/unionBy'
import uniq from 'lodash/uniq'

type KPIRollUps = {
  quantity: number
  value: number
  aws: string | number
  depth: string | number
  sku: string | number
  salesQuantity?: number
  storeCount?: string | number
}

/**
 * Retrieves status value from offer product
 *
 * @param {Object} filtersObject
 * @returns {Object}
 */
const retrieveFilters = filterObj => {
  const finalFilter = {}
  finalFilter['Activity Filter'] = [
    {
      name: 'Active',
      isChecked: false
    },
    {
      name: 'Inactive',
      isChecked: false
    }
  ]
  Object.keys(filterObj).forEach(type => {
    if (type !== '__typename') {
      const arryFilters = []
      filterObj[type].forEach(filter => {
        arryFilters.push({ name: filter, isChecked: false })
      })
      finalFilter[type] = arryFilters
    }
  })
  return finalFilter
}

/**
 * Retrieves status value from offer product
 *
 * @param {array} attributes
 * @returns {string}
 */
const retrieveStatusValue = attributes => {
  const status = attributes[0]
  return status ? (status.tag ? status.tag.name : status.name) : ''
}

/**
 * Retrievs values from color object
 *
 * @param {object} colorObj
 * @param {array}
 */
const getColorValues = colorObj => {
  let {
    color: { color, material }
  } = colorObj

  return [
    {
      name: 'color',
      value: color ? color.name : 'NA',
      hex: color && color.hex ? color.hex : ''
    },
    {
      name: 'material',
      value: material ? material.name : 'NA'
    }
  ]
}

/**
 * Retrievs values from style object
 *
 * @param {object} style
 * @param {array}
 */
const getStyleValues = style => {
  const {
    type,
    theme: {
      collection: { name, category2 }
    }
  } = style

  return [
    {
      name: 'Segment',
      value: category2.name
    },
    {
      name: 'Line',
      value: name
    },
    {
      name: 'Family',
      value: type.name
    }
  ]
}
/**
 * Retrievs values from attributes object
 *
 * @param {array} attributes
 * @param {array}
 */
const getAttributeValues = attributes => {
  return attributes
    .map(attribute => {
      const {
        definition: { name },
        strVal,
        intVal
      } = attribute
      return {
        name,
        value: strVal || intVal
      }
    })
    .filter(attribute => {
      return attribute.name !== 'Seasonality'
    })
}
/**
 * Retrieves seasonality value from product
 *
 * @param {array} attributes
 * @returns {string}
 */
const retrieveSeasonalityValue = attributes => {
  return attributes.find(attribute => attribute.name === 'Seasonality').tag.name
}
const getSortByColor = rawData => {
  const allColorWays = []
  rawData.forEach(data =>
    data.collections.forEach(collection =>
      collection.styles.forEach(style =>
        style.colorways.forEach(colorway => allColorWays.push(colorway))
      )
    )
  )
  const sortedColorWays = sortBy(allColorWays, 'color.color.color.name')
  const collection = groupBy(sortedColorWays, 'color.color.color.name')

  const newCollections = Object.keys(collection).map(item => ({
    name: item,
    collections: collection[item]
  }))

  newCollections.forEach(coll => {
    const collectionGroupByStyles = groupBy(coll.collections, 'style.type.name')
    coll.collections = Object.keys(collectionGroupByStyles).map(item => {
      const collectionStyles = collectionGroupByStyles[item].map(subItem => ({
        colorways: [subItem],
        collection: subItem.style.theme.collection
      }))
      return { name: item, styles: collectionStyles }
    }) as any
  })
  return newCollections
}

const getSortByHierarchy = rawData => {
  let allProducts = groupBy(rawData, 'name')
  let productsGrpupedBySegment = []

  map(allProducts, (item, key) => {
    let segment = {
      name: key,
      collections: []
    }
    map(item, currentItem => {
      segment.collections = segment.collections.concat(currentItem.collections)
    })
    productsGrpupedBySegment.push(segment)
  })
  return productsGrpupedBySegment
}
const groupProductsBySegment = (data, sortFilter) => {
  switch (sortFilter) {
    case HIERARCHY:
      return getSortByHierarchy(data)

    case COLOR:
      return getSortByColor(data)
  }
}

const getExchangeRate = currency => {
  if (currency) {
    const { exchangRates, globalCurrencyCode } = site
    const exchangeRate = exchangRates.find(
      ({ currencyFrom, currencyTo }) =>
        (currencyFrom.id === currency.id && currencyTo.code === globalCurrencyCode) ||
        (currencyTo.id === currency.id && currencyFrom.code === globalCurrencyCode)
    )
    return exchangeRate
  }
  return null
}

const getCurrencyCode = (role, exchangeRate, currency) => {
  const { globalCurrencyCode } = site
  return role === UserRoles.REGIONAL_MERCHANDISER && exchangeRate
    ? currency.code
    : globalCurrencyCode
}

const getZoneLevelProductValue = (
  retailPrice,
  quantity,
  markUp,
  role,
  productRetailPrice
) => {
  const {
    nav: {
      params: { assortmentType }
    }
  } = stores
  const {
    site: { globalCurrencyCode }
  } = stores
  const currency =
    retailPrice && retailPrice.priceList ? retailPrice.priceList.currency : null
  const markupValue = markUp ? markUp.markup || 1 : 1

  if (role !== UserRoles.REGIONAL_MERCHANDISER && assortmentType !== strings.store) {
    const exchangeRate = getExchangeRate(currency)
    if (exchangeRate) {
      if (exchangeRate.currencyFrom.id === currency.id) {
        return (quantity * retailPrice.price * markupValue) / exchangeRate.value
      } else {
        return quantity * retailPrice.price * markupValue * exchangeRate.value
      }
    }
    if (currency && currency.code === globalCurrencyCode) {
      return quantity * retailPrice.price * markupValue
    } else {
      return productRetailPrice ? quantity * productRetailPrice.price : 0
    }
  }
  return quantity * (retailPrice ? retailPrice.price * markupValue : 0)
}

/**
 * Returns filtered library items by name
 *
 * @param {string} term - term to filter library items
 * @param {array} libraryItems
 * @returns {array} filtered results
 */
const filterLibraryItemsByAttributes = (term, libraryItems) => {
  return term && term !== ''
    ? libraryItems.map(({ name, colorways }) => {
        return {
          name,
          colorways: colorways.filter(colorway => {
            const { name, attributes, offerProducts = [], color, style } = colorway

            const colorValues = getColorValues(color)
            const styleValues = getStyleValues(style)
            const attributeValues = getAttributeValues(attributes)
            const seasonality = retrieveSeasonalityValue(attributes)
            const status = offerProducts.length
              ? retrieveStatusValue(offerProducts[0].attributes)
              : 'Flex'
            const mmcName = style.theme ? style.theme.name : ''

            const searchAttributes = [
              name,
              status,
              seasonality,
              mmcName,
              ...colorValues.map(color => color.value),
              ...styleValues.map(style => style.value),
              ...attributeValues.map(attribute =>
                attribute.value ? attribute.value.toString() : ''
              )
            ]

            return searchAttributes.some(
              attribute => attribute.toLowerCase().indexOf(term.toLowerCase()) > -1
            )
          })
        }
      })
    : libraryItems
}

const filterDataByLines = memoize(
  (
    styles: Array<ProductStyle> = []
  ): {
    uniqueLines: Array<{
      colorways
      name
    }>
    count: number
  } => {
    const unique = new Map<string, { name; colorways }>()
    let totalColorways = 0

    styles.forEach(({ collection, colorways }) => {
      if (!collection || !colorways) return

      totalColorways += colorways.length

      let line = unique.get(collection.name)
      if (!line) {
        line = {
          name: collection.name,
          colorways
        }

        unique.set(collection.name, line)
      } else {
        line.colorways = [...line.colorways, ...colorways]
      }
    })

    const result = {
      uniqueLines: Array.from(unique.values()),
      count: totalColorways
    }
    return result
  }
)
const getValuesFromAttributes = (attributes, valueFor) => {
  const attribute = attributes.find(
    attribute => attribute.definition && attribute.definition.name === valueFor
  )
  if (attribute) {
    const { definition, strVal, intVal } = attribute
    return {
      definition,
      value: strVal || intVal
    }
  } else {
    return {
      definition: 'NA',
      strVal: 'NA'
    }
  }
}

const getModelDescription = (modelDescription, model) => {
  if (modelDescription.includes(model)) {
    return modelDescription.replace(`${model} - `, '')
  }
  return modelDescription
}

const getProductDetails = memoize((productVal, isCoreProduct = false, cluster?: any) => {
  const {
    id,
    productStatus,
    comments,
    favourites,
    moq,
    channels,
    salesPeriod,
    freeTags,
    productClusterStatus,
    avgRatingCount,
    totalFavourites,
    buy,
    bet,
    assortmentProducts,
    zoneAssortmentProducts,
    buyingSession,
    storeCount,
    retailPrice,
    zoneRetailPrice,
    restrictedZones
  } = productVal
  let { product } = productVal
  if (isCoreProduct) {
    product = productVal
  }
  const attributes = product ? product.attributes || [] : []
  const productName = product ? product.name : ''
  const produtImage = product && product.image && product.image.id
  const color = attributes.length ? getValuesFromAttributes(attributes, 'color') : null
  const composition = attributes.length
    ? getValuesFromAttributes(attributes, 'composition')
    : null
  const retailStatus = attributes.length
    ? getValuesFromAttributes(attributes, 'retailStatus')
    : null
  const model = attributes.length ? getValuesFromAttributes(attributes, 'model') : null
  let modelDescription = attributes.length
    ? getValuesFromAttributes(attributes, 'modelDescription')
    : null
  const activity = attributes.length
    ? getValuesFromAttributes(attributes, 'activity')
    : null
  const family = attributes.length ? getValuesFromAttributes(attributes, 'family') : null
  const line = attributes.length ? getValuesFromAttributes(attributes, 'line') : null
  const serieDescription = attributes.length
    ? getValuesFromAttributes(attributes, 'serieDescription')
    : null

  let clusterStatusId = ''
  if (productClusterStatus && productClusterStatus.length) {
    const statusRecord =
      cluster && cluster.id
        ? productClusterStatus.find(
            status => status.cluster && status.cluster.id === cluster.id
          )
        : productClusterStatus[0]
    clusterStatusId = statusRecord ? statusRecord.status.id : ''
  }

  return {
    active: product ? product.active : true,
    attributes,
    mmcName: color ? color.value : 'NA',
    retailPrice: retailPrice,
    zoneRetailPrice: zoneRetailPrice,
    materialName: composition && composition.value ? composition.value : 'NA',
    colorName: productName,
    description:
      modelDescription && modelDescription.value && model && model.value
        ? getModelDescription(modelDescription.value, model.value)
        : 'NA',
    image: produtImage,
    id: isCoreProduct ? product.id : id,
    productRetailStatus: retailStatus ? retailStatus.value : '',
    productStatus: isCoreProduct ? null : productStatus,
    comments,
    favourites,
    moq,
    channels,
    salesPeriod,
    freeTags: freeTags || [],
    productClusterStatus: clusterStatusId,
    avgRatingCount:
      avgRatingCount && !Number.isInteger(avgRatingCount)
        ? parseFloat(avgRatingCount).toFixed(1)
        : avgRatingCount,
    favouriteCount:
      totalFavourites && totalFavourites.length > 0 ? totalFavourites.length : null,
    buy: buy,
    bet: bet,
    buyingSession: buyingSession,
    zoneAssortmentProducts: zoneAssortmentProducts ? zoneAssortmentProducts : [],
    storeCount: storeCount >= 0 ? storeCount : 'NA',
    assortmentProducts: assortmentProducts ? assortmentProducts : [],
    activity,
    family,
    line,
    clusterStatuses: productClusterStatus,
    restrictedZones,
    serieDescription: serieDescription ? serieDescription.value : 'NA'
  }
})

const getProducts = buyingSessionProducts => {
  const allProducts = []
  buyingSessionProducts.forEach(item => {
    item.product.attributes.forEach(attri => {
      allProducts.push(attri)
    })
  })
  const grpByName = groupBy(allProducts, 'definition.name')
  const activityGroup = unionBy(uniq(grpByName.activity), 'strVal')
  const acti = []
  activityGroup.forEach(activity => acti.push(activity.strVal))
  const products = []

  acti.forEach(itemActivity => {
    const groupedProducts = []
    buyingSessionProducts.forEach(item => {
      item.product.attributes.forEach(attribute => {
        if (attribute.strVal === itemActivity) {
          groupedProducts.push(item)
        }
      })
    })
    products.push({ [itemActivity]: [groupedProducts] })
  })
  return products
}

/**
 * Get tags on product
 */
const getProductTags = assortmentProducts => {
  const tags = []

  assortmentProducts.forEach(product => {
    if (
      product.assortment &&
      product.assortment.cluster &&
      product.assortment.cluster.name
    ) {
      tags.push({
        ...product.assortment.cluster,
        assortmentId: product.assortment.id,
        assortmentProductId: product.id
      })
    }
  })

  return tags
}

const mapClustersWithStatus = (clusters, clusterStatuses) => {
  let clustersWithStatus = cloneDeep(clusters)
  clusterStatuses.forEach(status => {
    let cluster = find(
      clustersWithStatus,
      cluster => status && status.cluster && status.cluster.id === cluster.id
    )
    cluster.status = status && status.status ? status.status.name : ''
  })

  return clustersWithStatus
}

/**
 * Get product cluster wuth status
 */
const getProductClustersWithStatus = (
  assortmentProducts,
  clusterStatuses,
  isListView: boolean = false
) => {
  const tags = []
  assortmentProducts.forEach(product => {
    if (
      product.assortment &&
      product.assortment.cluster &&
      product.assortment.cluster.name
    ) {
      const status =
        clusterStatuses && clusterStatuses.length
          ? clusterStatuses.find(
              ({ cluster: { id } }) => id === product.assortment.cluster.id
            )
          : null
      let tag = {}
      if (isListView) {
        tag = {
          id: product.assortment.cluster.id,
          title: product.assortment.cluster.name,
          subtitle: status ? status.status.name : null,
          color: product.assortment.cluster.colorHex,
          clusterAssortmentId: product.assortment.id,
          clusterAssortmentProductId: product.id
        } as ClusterChip
      } else {
        tag = {
          assortmentId: product.assortment.id,
          assortmentProductId: product.id,
          clusterId: product.assortment.cluster.id,
          clusterName: product.assortment.cluster.name,
          statusId: status ? status.status.id : null,
          statusName: status ? status.status.name : null,
          colorHex: product.assortment.cluster.colorHex
        }
      }

      tags.push(tag)
    }
  })
  return tags
}

/**
 * Get associated looks for product
 */
const getAssociatedLooks = product => {
  if (product && product.product && product.product.looks) {
    const looks = map(product.product.looks, 'name')
    return looks && looks.length ? looks.join() : 'NA'
  }
  return 'NA'
}

/**
 * Get substitutes in looks for product
 */
const getSubstitutesInLooks = product => {
  if (product && product.product && product.product.substitutedInLooks) {
    const substitutedInLooks = map(product.product.substitutedInLooks, 'name')
    return substitutedInLooks && substitutedInLooks.length
      ? substitutedInLooks.join()
      : 'NA'
  }
  return 'NA'
}

/**
 * Maps channels list in the format required by list view
 * @param channels Array of channels as returned in buying session product
 */
const getTagsMapForListView = memoize(
  (tags: Array<{ id: string; name: string }>, isZone: boolean = false): Array<Chip> => {
    return map(
      tags,
      (tag): Chip => ({
        id: tag.id,
        title: isZone ? ZoneDisplayNames[tag.name] || tag.name : tag.name
      })
    )
  },
  isEqual
)

/**
 * Method for evaluating number of weeks from sales period object of buying session product
 * @param salesPeriod Sales Period object for Buying Session Product
 */
const getNumberOfWeeksFromSalesPeriod = (salesPeriod: {
  startDate: string
  endDate: string
}) => {
  return salesPeriod
    ? `${getDateDiff(salesPeriod.endDate, salesPeriod.startDate)}W`
    : 'NA'
}

/**
 *
 */
const getActivityValue = finalFilterToApply => {
  if (finalFilterToApply['Status'] && finalFilterToApply['Status'].length === 1) {
    return finalFilterToApply['Status'][0].label !== 'Inactive'
  }

  return undefined
}

/**
 * Returns selected favourite value
 */
const getFavouriteValue = finalFilterToApply => {
  if (
    finalFilterToApply['Favourite Filter'] &&
    finalFilterToApply['Favourite Filter'].length === 1
  ) {
    return finalFilterToApply['Favourite Filter'][0].label === 'Favourite'
  }
  return undefined
}

/**
 * Return filter values ids
 */

const getFilterValues = (finalFilterToApply, filterConst) => {
  if (finalFilterToApply[filterConst] && finalFilterToApply[filterConst].length) {
    return finalFilterToApply[filterConst].map(({ value }) => value)
  }
  return undefined
}

/**
 * Returns rating value
 */
const getRatingValue = finalFilterToApply => {
  if (
    finalFilterToApply['Rating Filter'] &&
    finalFilterToApply['Rating Filter'].length === 1
  ) {
    return finalFilterToApply['Rating Filter'][0].label === 'Rated'
  }
  return undefined
}
/**
 *
 * @param object
 *
 */

const getProductStatusFilter = finalFilterToApply => {
  if (
    finalFilterToApply['Core Status'] &&
    finalFilterToApply['Core Status'].length === 1
  ) {
    return finalFilterToApply['Core Status'][0].label === 'Core' ? 'CORE' : 'NON_CORE'
  }
  return undefined
}

/**
 * Returns filter value needed for GraphQL Query
 */
const getFavouriteFilter = (favouriteValue, user) => {
  let favouriteFilterSome
  let favouriteFilterEvery

  if (typeof favouriteValue !== 'undefined') {
    if (favouriteValue) {
      favouriteFilterEvery = undefined
      favouriteFilterSome = { user: { id: user.id } }
    } else {
      favouriteFilterSome = undefined
      favouriteFilterEvery = { user: { id: null } }
    }
  }

  return {
    favouriteFilterSome,
    favouriteFilterEvery
  }
}

/**
 * Returns filter value needed for GraphQL Query
 */
const getRatingFilter = finalFilterToApply => {
  const ratingValue = getRatingValue(finalFilterToApply)
  let ratingFilterSome
  let ratingFilterNone

  if (typeof ratingValue !== 'undefined') {
    if (ratingValue) {
      ratingFilterNone = undefined
      ratingFilterSome = { value_gt: 0 }
    } else {
      ratingFilterNone = undefined
      ratingFilterNone = { value_gt: 0 }
    }
  }

  return {
    ratingFilterSome,
    ratingFilterNone
  }
}

const getSortedProducts = (products, sortFilter) => {
  // Need to add switch case if sort filters are many
  if (sortFilter === 'Color') {
    const sortedProduct = sortBy(products, [product => product.product.name])
    return sortedProduct
  }
  return products
}

const getZoneSellthrough = (rangePlanData, buyingSession, family, line) => {
  const activityDescription = buyingSession.activity
    ? buyingSession.activity.description
    : ''
  let hierarchy =
    ACTIVITY_HIERARCHY_MAP[activityDescription] || DEFAULT_ACTIVITY_HIERARCHY

  const compareBothFamilyAndLine = isEqual(hierarchy, ['family', 'line'])
  const compareOnlyLine = isEqual(hierarchy, ['line'])
  let filteredRangePlans =
    rangePlanData && rangePlanData.rangePlansForBuyingSessionProducts
      ? rangePlanData.rangePlansForBuyingSessionProducts.filter(rangePlan => {
          if (compareBothFamilyAndLine) {
            return (
              rangePlan.activity.id === buyingSession.activity.id &&
              rangePlan.collection.id === buyingSession.collection.id &&
              rangePlan.family &&
              rangePlan.family.description === family.value &&
              rangePlan.line &&
              rangePlan.line.description === line.value
            )
          } else if (compareOnlyLine) {
            return (
              rangePlan.activity.id === buyingSession.activity.id &&
              rangePlan.collection.id === buyingSession.collection.id &&
              rangePlan.line &&
              rangePlan.line.description === line.value
            )
          } else {
            return (
              rangePlan.activity.id === buyingSession.activity.id &&
              rangePlan.collection.id === buyingSession.collection.id &&
              rangePlan.family &&
              rangePlan.family.description === family.value
            )
          }
        })
      : []
  return filteredRangePlans
}

const getCollectionBetData = memoize(
  (betBuyData = { betBuyValues: [] }, product, markUpList, sellThroughList) => {
    const user = storage.getItem<User>('user')
    const role = user.roles[0].name
    let betData = []
    const { betBuyValues } = betBuyData
    let totalProductQuantities = 0
    let totalBetValue = 0
    let globalLevelValue = 0
    const {
      site: { globalCurrencyCode },
      assortment: { getRetailPrice }
    } = stores
    const {
      retailPrice: productRetailPrice,
      id,
      bet,
      buy,
      zoneAssortmentProducts,
      buyingSession,
      salesPeriod,
      storeCount,
      activity,
      family,
      line
    } = getProductDetails(product)

    // get family from buying session product
    const zoneMarkUp = markUpList.find(
      ({ description }) => description === activity.value
    )
    const weeks = salesPeriod
      ? getDateDiff(salesPeriod.endDate, salesPeriod.startDate)
      : 0
    const zoneSellThroughList = getZoneSellthrough(
      sellThroughList,
      buyingSession,
      family,
      line
    )

    let metadata = betBuyValues && getChildrenMetaData(activity, family, line)

    // Filter products by activity / family / line
    const filterProducts =
      betBuyValues && getFilterProductsByHierarchy(betBuyValues, metadata)

    let globalObject = null
    if (role !== UserRoles.REGIONAL_MERCHANDISER) {
      if (filterProducts) {
        filterProducts.forEach(
          ({ bet: productBet, assortmentProducts, retailPrice: productRetailPrice }) => {
            totalProductQuantities =
              totalProductQuantities + (productBet ? productBet.quantity : 0)
            //If product assortment is present roll up will be summation of value at zone level else consider value at buyings session product level
            if (assortmentProducts.length) {
              assortmentProducts.forEach(
                ({ zoneAssortment: { zone }, retailPrice, bet }) => {
                  if (bet && bet.quantity > 0) {
                    const markUp =
                      zoneMarkUp && zoneMarkUp.zones
                        ? zoneMarkUp.zones.find(({ id }) => id === zone.id)
                        : null
                    totalBetValue =
                      totalBetValue +
                      getZoneLevelProductValue(
                        retailPrice,
                        bet.quantity,
                        markUp,
                        role,
                        productRetailPrice
                      )
                  }
                }
              )
            } else {
              totalBetValue =
                totalBetValue +
                (productRetailPrice && productBet
                  ? productBet.quantity * productRetailPrice.price
                  : 0)
            }
          }
        )
      }

      // global calclulations
      const globalAws = 0 // bet && weeks ? parseFloat((bet.quantity/weeks).toFixed(2)) : null
      const globalMixValue =
        bet && productRetailPrice && totalBetValue > 0
          ? (bet.quantity * productRetailPrice.price) / totalBetValue
          : 0
      const globaleMixQuantity =
        bet && totalProductQuantities > 0 ? bet.quantity / totalProductQuantities : 0
      const globalDepth =
        bet && storeCount ? parseFloat((bet.quantity / storeCount).toFixed(2)) : 0
      const globalValue =
        bet && productRetailPrice
          ? parseFloat((bet.quantity * productRetailPrice.price).toFixed(2))
          : null
      const globalBuyValue =
        buy && productRetailPrice
          ? parseFloat((buy.quantity * productRetailPrice.price).toFixed(2))
          : null
      const globalSKU = 0 //globalAws && storeCount ? parseFloat((globalAws/storeCount).toFixed(2)) : null

      const globalBetQty = bet && bet.quantity ? bet.quantity : 0
      const globalBuyQty = buy ? buy.quantity : 0
      const globalPrice = productRetailPrice

      //At Zone - Sales QTY = QTYx(Sell-Through) At Global- Sales QTY = QTY x Average Sell-Through
      const globalSalesQty = 0
      //global calculations
      globalObject = {
        id,
        type: strings.global,
        betQty: globalBetQty,
        buyQty: globalBuyQty,
        zone: strings.global,
        aws: globalAws,
        mixValue: convertNumberToPercentage(globalMixValue),
        mixQuantity: convertNumberToPercentage(globaleMixQuantity),
        depth: globalDepth,
        value:
          productRetailPrice && productRetailPrice.priceList && globalValue
            ? convertToCurrencyLocaleString(globalCurrencyCode, globalValue)
            : globalValue,
        buyValueToSort: globalBuyValue || 0,
        sku: globalSKU,
        storeCount,
        retailPrice: getRetailPrice(globalPrice),
        salesQuantity: globalSalesQty,
        weeks: weeks,
        zoneWiseComments: []
      }
    }
    // zone level calculations
    let totalSellThrough = 0
    let zoneCount = 0
    let averageSellThrough = 1 // default 1
    if (zoneAssortmentProducts && zoneAssortmentProducts.length > 0) {
      zoneAssortmentProducts.forEach(
        ({
          id,
          bet,
          buy,
          retailPrice,
          storeCount,
          zoneAssortment: { zone },
          comments
        }) => {
          zoneCount = zoneCount + 1
          let zoneProductQuantities = 0
          let totalZoneBetValue = 0
          const markUp =
            zoneMarkUp && zoneMarkUp.zones
              ? zoneMarkUp.zones.find(({ id }) => id === zone.id)
              : null
          if (filterProducts && filterProducts.length) {
            filterProducts.forEach(product => {
              product.assortmentProducts.forEach(
                ({ zoneAssortment, retailPrice, bet }) => {
                  if (zoneAssortment.zone.id === zone.id && bet && bet.quantity > 0) {
                    zoneProductQuantities = zoneProductQuantities + bet.quantity
                    totalZoneBetValue =
                      totalZoneBetValue +
                      getZoneLevelProductValue(
                        retailPrice,
                        bet.quantity,
                        markUp,
                        role,
                        product.retailPrice
                      )
                  }
                }
              )
            })
          }
          const zoneLevelProductRetailPrice = retailPrice
          //const aws = bet && weeks ? parseFloat((bet.quantity/weeks).toFixed(2)) : null
          const value = bet
            ? parseFloat(
                getZoneLevelProductValue(
                  retailPrice,
                  bet.quantity,
                  markUp,
                  role,
                  productRetailPrice
                ).toFixed(2)
              )
            : null

          const buyValue = buy
            ? parseFloat(
                getZoneLevelProductValue(
                  retailPrice,
                  buy.quantity,
                  markUp,
                  role,
                  productRetailPrice
                ).toFixed(2)
              )
            : null
          globalLevelValue = globalLevelValue + value
          const mixValue = value && totalZoneBetValue > 0 ? value / totalZoneBetValue : 0
          const mixQuantity =
            bet && zoneProductQuantities > 0 ? bet.quantity / zoneProductQuantities : 0
          const depth =
            bet && storeCount ? parseFloat((bet.quantity / storeCount).toFixed(2)) : 0
          //const sku = aws && storeCount ? parseFloat((aws/storeCount).toFixed(2)) : null

          const betQty = bet && bet.quantity ? bet.quantity : 0
          const buyQty = buy && buy.quantity ? buy.quantity : 0
          //At Zone - Sales QTY = QTYx(Sell-Through) At Global- Sales QTY = QTY x Average Sell-Through
          const sellThroughRecord = zoneSellThroughList.find(
            ({ zone: { id } }) => id === zone.id
          )
          const sellThrough = sellThroughRecord ? sellThroughRecord.sellThrough : null
          totalSellThrough = sellThrough
            ? totalSellThrough + sellThrough
            : totalSellThrough
          const salesQty =
            betQty && sellThrough ? parseFloat((betQty * sellThrough).toFixed(2)) : betQty
          // new rule AWS = Sales QTY / Nb Weeks
          const aws = salesQty && weeks ? parseFloat((salesQty / weeks).toFixed(2)) : 0
          const sku = aws && storeCount ? parseFloat((aws / storeCount).toFixed(2)) : 0
          const assortmentProductValue =
            zoneLevelProductRetailPrice && zoneLevelProductRetailPrice.priceList && value
              ? convertToCurrencyLocaleString(
                  role === UserRoles.REGIONAL_MERCHANDISER
                    ? zoneLevelProductRetailPrice.priceList.currency.code
                    : globalCurrencyCode,
                  value
                )
              : value
          betData.push({
            id,
            type: strings.zone,
            betQty: betQty,
            buyQty: buyQty,
            zone: ZoneDisplayNames[zone.name] || zone.name,
            zoneNameToSort: zone.name,
            aws: aws,
            mixQuantity: convertNumberToPercentage(mixQuantity),
            mixValue: convertNumberToPercentage(mixValue),
            depth: depth,
            value: assortmentProductValue || 0,
            buyValueToSort: buyValue || 0,
            sku: sku,
            storeCount: storeCount,
            retailPrice: getRetailPrice(zoneLevelProductRetailPrice),
            salesQuantity: salesQty,
            weeks,
            sellThrough,
            zoneWiseComments: comments
          })
        }
      )
    }
    averageSellThrough =
      totalSellThrough && zoneCount ? totalSellThrough / zoneCount : averageSellThrough
    /**
     * For sort by zoneSortOrder
     */
    const zoneOrderList = getZoneOrder()
    betData = sortListUsingOrderList(betData, ['zoneNameToSort'], zoneOrderList)
    /******/

    if (globalObject) {
      const globalValue = globalLevelValue
        ? convertToCurrencyLocaleString(
            productRetailPrice &&
              productRetailPrice.priceList &&
              productRetailPrice.priceList.currency
              ? productRetailPrice.priceList.currency.code
              : globalCurrencyCode,
            globalLevelValue
          )
        : globalLevelValue

      // Set value for global object
      globalObject.value = (betData.length ? globalValue : globalObject.value) || 0
      betData.unshift(globalObject)
    }

    // Set sell through
    betData.forEach(data => {
      if (data.type === strings.global) {
        data.salesQuantity = parseFloat((data.betQty * averageSellThrough).toFixed(2))
        data.aws =
          data.salesQuantity && weeks
            ? parseFloat((data.salesQuantity / weeks).toFixed(2))
            : 0
        data.sku =
          data.aws && data.storeCount
            ? parseFloat((data.aws / data.storeCount).toFixed(2))
            : 0
        data.sellThrough = averageSellThrough
        if (globalLevelValue && totalBetValue) {
          data.mixValue = convertNumberToPercentage(globalLevelValue / totalBetValue)
        }
      }
    })

    return betData
  },
  isEqual
)

const getCollectionBuyData = memoize(
  (betBuyData = { betBuyValues: [] }, product, markUpList, rangePlanData) => {
    let buyData = []
    const { betBuyValues } = betBuyData
    const {
      site: { globalCurrencyCode },
      assortment: { getRetailPrice }
    } = stores
    let totalProductQuantities = 0
    let totalBuyValue = 0
    const user = storage.getItem<User>('user')
    const role = user.roles[0].name
    let globalLevelValue = 0

    const {
      retailPrice: productRetailPrice,
      id,
      bet,
      buy,
      salesPeriod,
      buyingSession,
      family,
      line,
      storeCount,
      zoneAssortmentProducts,
      activity
    } = getProductDetails(product)

    const zoneMarkUp = markUpList.find(
      ({ description }) => description === activity.value
    )
    const weeks = salesPeriod
      ? getDateDiff(salesPeriod.endDate, salesPeriod.startDate)
      : null
    const zoneSellThroughList = getZoneSellthrough(
      rangePlanData,
      buyingSession,
      family,
      line
    )

    let globalObject = null
    if (role !== UserRoles.REGIONAL_MERCHANDISER) {
      if (betBuyValues) {
        betBuyValues.forEach(({ buy }) => {
          if (buy) {
            totalProductQuantities = totalProductQuantities + buy.quantity
            totalBuyValue =
              totalBuyValue +
              (productRetailPrice ? buy.quantity * productRetailPrice.price : 0)
          }
        })
      }

      // global calclulations
      const globalAws = 0 //buy && weeks ? parseFloat((buy.quantity/weeks).toFixed(2)) : null
      const globalMixValue =
        buy && totalBuyValue > 0 && productRetailPrice
          ? (buy.quantity * productRetailPrice.price) / totalBuyValue
          : 0
      const globaleMixQuantity =
        buy && totalProductQuantities > 0 ? buy.quantity / totalProductQuantities : 0
      const globalDepth =
        buy && storeCount ? parseFloat((buy.quantity / storeCount).toFixed(2)) : 0
      const globalValue =
        buy && productRetailPrice
          ? parseFloat((buy.quantity * productRetailPrice.price).toFixed(2))
          : null
      const globalSKU = 0 //globalAws && storeCount ? parseFloat((globalAws/storeCount).toFixed(2)) : null
      //At Zone - Sales QTY = QTYx(Sell-Through) At Global- Sales QTY = QTY x Average Sell-Through
      const globalSalesQty = 0 //buy && globalAws ? buy.quantity *  globalAws : null
      const globalBuyQty = buy ? buy.quantity : 0
      const globalBetQty = bet ? bet.quantity : 0
      const globalPrice = productRetailPrice

      //global calculations
      globalObject = {
        id: id,
        type: strings.global,
        buyQty: globalBuyQty,
        betQty: globalBetQty,
        zone: strings.global,
        aws: globalAws,
        mixValue: convertNumberToPercentage(globalMixValue),
        mixQuantity: convertNumberToPercentage(globaleMixQuantity),
        depth: globalDepth,
        value:
          productRetailPrice && productRetailPrice.priceList && globalValue
            ? convertToCurrencyLocaleString(globalCurrencyCode, globalValue)
            : globalValue,
        buyValueToSort: globalValue ? globalValue : 0,
        sku: globalSKU,
        storeCount,
        retailPrice: getRetailPrice(globalPrice),
        salesQuantity: globalSalesQty,
        weeks,
        zoneWiseComments: []
      }
    }

    // zone level calculations
    let totalSellThrough = 0
    let zoneCount = 0
    let averageSellThrough = 1 // default 1
    if (zoneAssortmentProducts && zoneAssortmentProducts.length > 0) {
      zoneAssortmentProducts.forEach(
        ({
          id,
          bet,
          buy,
          storeCount,
          retailPrice,
          zoneAssortment: { zone },
          comments
        }) => {
          zoneCount = zoneCount + 1
          let zoneProductQuantities = 0
          let totalZoneBuyValue = 0
          const markUp =
            zoneMarkUp && zoneMarkUp.zones
              ? zoneMarkUp.zones.find(({ id }) => id === zone.id)
              : null

          if (betBuyValues && betBuyValues.length) {
            betBuyValues.forEach(product => {
              product.assortmentProducts.forEach(({ zoneAssortment, buy }) => {
                if (zoneAssortment.zone.id === zone.id && buy && buy.quantity > 0) {
                  zoneProductQuantities = zoneProductQuantities + buy.quantity
                  totalZoneBuyValue =
                    totalZoneBuyValue +
                    getZoneLevelProductValue(
                      retailPrice,
                      buy.quantity,
                      markUp,
                      role,
                      product.retailPrice
                    )
                }
              })
            })
          }

          const zoneLevelProductRetailPrice = retailPrice
          //const aws = buy && weeks ? parseFloat((buy.quantity/weeks).toFixed(2)) : null
          const value = buy
            ? parseFloat(
                getZoneLevelProductValue(
                  retailPrice,
                  buy.quantity,
                  markUp,
                  role,
                  productRetailPrice
                ).toFixed(2)
              )
            : null
          globalLevelValue = globalLevelValue + value
          const mixValue = value && totalZoneBuyValue > 0 ? value / totalZoneBuyValue : 0
          const mixQuantity =
            buy && zoneProductQuantities > 0 ? buy.quantity / zoneProductQuantities : 0
          const depth =
            buy && zoneProductQuantities && storeCount
              ? parseFloat((buy.quantity / storeCount).toFixed(2))
              : 0
          const buyQty = buy && buy.quantity ? buy.quantity : 0
          const betQty = bet && bet.quantity ? bet.quantity : 0
          //At Zone - Sales QTY = QTYx(Sell-Through) At Global- Sales QTY = QTY x Average Sell-Through
          const sellThroughRecord = zoneSellThroughList.find(
            ({ zone: { id } }) => id === zone.id
          )
          const sellThrough = sellThroughRecord ? sellThroughRecord.sellThrough : 0
          totalSellThrough = sellThrough
            ? totalSellThrough + sellThrough
            : totalSellThrough
          const salesQty =
            buyQty && sellThrough ? parseFloat((buyQty * sellThrough).toFixed(2)) : buyQty
          // new rule AWS = Sales QTY / Nb Weeks
          const aws = salesQty && weeks ? parseFloat((salesQty / weeks).toFixed(2)) : 0
          const sku = aws && storeCount ? parseFloat((aws / storeCount).toFixed(2)) : 0
          const assortmentProductValue =
            zoneLevelProductRetailPrice && zoneLevelProductRetailPrice.priceList && value
              ? convertToCurrencyLocaleString(
                  role === UserRoles.REGIONAL_MERCHANDISER
                    ? zoneLevelProductRetailPrice.priceList.currency.code
                    : globalCurrencyCode,
                  value
                )
              : value

          buyData.push({
            id,
            type: strings.zone,
            buyQty,
            betQty,
            zone: ZoneDisplayNames[zone.name] || zone.name,
            zoneNameToSort: zone.name,
            aws: aws,
            mixQuantity: convertNumberToPercentage(mixQuantity),
            mixValue: convertNumberToPercentage(mixValue),
            depth: depth,
            value: assortmentProductValue || 0,
            buyValueToSort: value ? value : 0,
            sku: sku,
            storeCount: storeCount,
            buyingSessionProductId: product.id,
            retailPrice: getRetailPrice(zoneLevelProductRetailPrice),
            salesQuantity: salesQty,
            weeks,
            sellThrough,
            zoneWiseComments: comments
          })
        }
      )
    }
    averageSellThrough =
      totalSellThrough && zoneCount ? totalSellThrough / zoneCount : averageSellThrough

    /**
     * For sort by zoneSortOrder
     */
    const zoneOrderList = getZoneOrder()
    buyData = sortListUsingOrderList(buyData, ['zoneNameToSort'], zoneOrderList)
    /******/

    if (globalObject) {
      const globalValue = globalLevelValue
        ? convertToCurrencyLocaleString(
            productRetailPrice &&
              productRetailPrice.priceList &&
              productRetailPrice.priceList.currency
              ? productRetailPrice.priceList.currency.code
              : globalCurrencyCode,
            globalLevelValue
          )
        : globalLevelValue

      globalObject.value = (buyData.length ? globalValue : globalObject.value) || 0
      buyData.unshift(globalObject)
    }

    buyData.forEach(data => {
      if (data.type === strings.global) {
        data.salesQuantity = parseFloat((data.buyQty * averageSellThrough).toFixed(2))
        data.aws =
          data.salesQuantity && weeks
            ? parseFloat((data.salesQuantity / weeks).toFixed(2))
            : 0
        data.sku =
          data.aws && data.storeCount
            ? parseFloat((data.aws / data.storeCount).toFixed(2))
            : 0
        data.sellThrough = averageSellThrough
      }
    })
    return buyData
  },
  isEqual
)

/** data: array of filters from database */
/**
 * Returns filters array
 */
const getProductFilters = data => {
  const {
    buyingSessionProductsFilter,
    assortmentProductsFilter,
    zoneAssortmentProductsFilter,
    storeAssortmentProductsFilter,
    addProductsToLookFilter
  } = data

  if (buyingSessionProductsFilter) {
    const globalFilter =
      buyingSessionProductsFilter &&
      buyingSessionProductsFilter.length &&
      buyingSessionProductsFilter.forEach(item => {
        if (item.name === GLOBAL_PRODUCT_ATTRIBUTES) {
          return item.data
        }
        if (item.name === LOOK_ATTRIBUTES) {
          return getLooksFilter(item)
        }

        if (item.name === BUYING_SESSION_PRODUCT_ATTRIBUTES) {
          return getBuyingSessionProductsFilter(item)
        }
        if (item.name === CLUSTER_STATUS) {
          return item.data
        }
      })
    return globalFilter && globalFilter.length ? globalFilter : []
  } else if (assortmentProductsFilter) {
    const globalFilter =
      assortmentProductsFilter &&
      assortmentProductsFilter.length &&
      assortmentProductsFilter.forEach(item => {
        if (item.name === GLOBAL_PRODUCT_ATTRIBUTES) {
          return item.data
        }
        if (item.name === LOOK_ATTRIBUTES) {
          return getLooksFilter(item)
        }
        if (item.name === BUYING_SESSION_PRODUCT_ATTRIBUTES) {
          return getBuyingSessionProductsFilter(item)
        }
        if (item.name === CLUSTER_STATUS) {
          return item.data
        }
      })
    return globalFilter ? globalFilter : []
  } else if (zoneAssortmentProductsFilter) {
    const globalFilter =
      zoneAssortmentProductsFilter &&
      zoneAssortmentProductsFilter.length &&
      zoneAssortmentProductsFilter.forEach(item => {
        if (item.name === GLOBAL_PRODUCT_ATTRIBUTES) {
          return item.data
        }
        if (item.name === LOOK_ATTRIBUTES) {
          return getLooksFilter(item)
        }
        if (item.name === BUYING_SESSION_PRODUCT_ATTRIBUTES) {
          return getBuyingSessionProductsFilter(item)
        }
        if (item.name === CLUSTER_STATUS) {
          return item.data
        }
      })
    return globalFilter ? globalFilter : []
  } else if (storeAssortmentProductsFilter) {
    const globalFilter =
      storeAssortmentProductsFilter &&
      storeAssortmentProductsFilter.length &&
      storeAssortmentProductsFilter.forEach(item => {
        if (item.name === GLOBAL_PRODUCT_ATTRIBUTES) {
          return item.data
        }
        if (item.name === LOOK_ATTRIBUTES) {
          return getLooksFilter(item)
        }
        if (item.name === BUYING_SESSION_PRODUCT_ATTRIBUTES) {
          return getBuyingSessionProductsFilter(item)
        }
        if (item.name === CLUSTER_STATUS) {
          return item.data
        }
      })
    return globalFilter ? globalFilter : []
  } else if (addProductsToLookFilter) {
    const globalFilter =
      addProductsToLookFilter &&
      addProductsToLookFilter.length &&
      addProductsToLookFilter.forEach(item => {
        if (item.name === GLOBAL_PRODUCT_ATTRIBUTES) {
          return item.data
        }
      })
    return globalFilter ? globalFilter : []
  }
  return []
}

const getLooksFilter = item =>
  item.data.map(currentData => {
    return {
      ...currentData,
      definition: {
        ...currentData.definition,
        // Earlier this was {name: 'name'}, so made it {name: 'lookName'}
        name: LOOK_NAME
      }
    }
  })

const getBuyingSessionProductsFilter = item =>
  item.data.map(currentData => {
    return currentData
  })

const getFilterChipsData = finalFilterToApply => {
  const chipsArrayToDisplay = []
  const chipsArrayInDropDown = []

  Object.keys(finalFilterToApply).forEach(filter => {
    finalFilterToApply[filter] &&
      finalFilterToApply[filter].forEach(item => {
        if (chipsArrayToDisplay.length < NO_OF_FILTER_TO_SHOW) {
          chipsArrayToDisplay.push({ ...item, filterKey: filter })
        } else {
          chipsArrayInDropDown.push({ ...item, filterKey: filter })
        }
      })
  })
  return {
    chipsArrayToDisplay: chipsArrayToDisplay || [],
    chipsArrayInDropDown: chipsArrayInDropDown || []
  }
}

const getSalesPeriodStartAndEndDate = salesPeriod => {
  return `${moment(salesPeriod.startDate).format('DD-MMM-YYYY')} To ${moment(
    salesPeriod.endDate
  ).format('DD-MMM-YYYY')}`
}

const isFlagRed = (tags, buyerClusters) => {
  let isRed = false

  if (tags && tags.length && !buyerClusters) {
    return true
  }

  if (!tags && buyerClusters && buyerClusters.length) {
    return true
  }

  if (tags && buyerClusters) {
    if (tags.length !== buyerClusters.length) {
      return true
    } else {
      const buyerClusterIds = map(buyerClusters, cluster => cluster.id)
      const cmClusterIds = map(tags, cluster => cluster.id)
      isRed = difference(buyerClusterIds, cmClusterIds).length > 0
    }
  } else {
    return true
  }

  return isRed
}

const tagsForRestrictProductByZone = memoize(
  (getAddedTags, zones, salesPeriodClusterChannels, freeTags) => {
    const {
      restrictProductByZoneModal: {
        selectedTabForRestricProduct,
        restrictedZones,
        bulkAddClusters,
        bulkAddChannel,
        bulkAddSalesPeriod,
        bulkAddFreeTags
      },
      product: { clusterStatuses }
    } = stores

    if (getAddedTags) {
      switch (selectedTabForRestricProduct) {
        case RESTRICT_PRODUCT_BY_ZONE_TABS[0]: {
          const addedZones = []
          zones &&
            zones.forEach(zone => {
              if (restrictedZones.length && restrictedZones.indexOf(zone.id) > -1) {
                addedZones.push(zone)
              }
            })
          return addedZones
        }
        case RESTRICT_PRODUCT_BY_ZONE_TABS[1]: {
          const addedClusters = []
          salesPeriodClusterChannels &&
            salesPeriodClusterChannels[strings.clusters] &&
            salesPeriodClusterChannels[strings.clusters].forEach(cluster => {
              const bulkAddCluster = bulkAddClusters.find(
                bulkAddCluster => bulkAddCluster.id === cluster.id
              )
              if (bulkAddClusters.length && bulkAddCluster) {
                const status = clusterStatuses.find(
                  ({ id }) => id === bulkAddCluster.statusId
                )
                const clusterTag = cloneDeep(cluster)
                if (status) {
                  clusterTag['statusId'] = status.id
                  clusterTag['statusName'] = status.name
                }
                addedClusters.push(clusterTag)
              }
            })
          return addedClusters
        }
        case RESTRICT_PRODUCT_BY_ZONE_TABS[2]: {
          const addedChannels = []
          salesPeriodClusterChannels &&
            salesPeriodClusterChannels[strings.channels] &&
            salesPeriodClusterChannels[strings.channels].forEach(channel => {
              if (bulkAddChannel.length && bulkAddChannel.indexOf(channel.id) > -1) {
                addedChannels.push(channel)
              }
            })
          return addedChannels
        }
        case RESTRICT_PRODUCT_BY_ZONE_TABS[3]: {
          return (
            salesPeriodClusterChannels &&
            salesPeriodClusterChannels[strings.salesPeriods] &&
            salesPeriodClusterChannels[strings.salesPeriods].filter(
              salesPeriod => salesPeriod.id === bulkAddSalesPeriod
            )
          )
        }
        case RESTRICT_PRODUCT_BY_ZONE_TABS[4]: {
          const addedFreeTags = []
          freeTags &&
            freeTags.forEach(freeTag => {
              if (bulkAddFreeTags.length && bulkAddFreeTags.indexOf(freeTag.id) > -1) {
                addedFreeTags.push(freeTag)
              }
            })
          return addedFreeTags
        }
        default: {
          const addedZones = []
          zones &&
            zones.forEach(zone => {
              if (
                restrictedZones &&
                restrictedZones.length &&
                restrictedZones.indexOf(zone.id) > -1
              ) {
                addedZones.push(zone)
              }
            })
          return addedZones
        }
      }
    } else {
      switch (selectedTabForRestricProduct) {
        case RESTRICT_PRODUCT_BY_ZONE_TABS[0]: {
          const addedZones = []
          if (restrictedZones.length) {
            zones &&
              zones.forEach(zone => {
                if (restrictedZones.indexOf(zone.id) === -1) {
                  addedZones.push(zone)
                }
              })
            return addedZones
          } else {
            return zones
          }
        }
        case RESTRICT_PRODUCT_BY_ZONE_TABS[1]: {
          const addedClusters = []
          if (bulkAddClusters.length) {
            salesPeriodClusterChannels &&
              salesPeriodClusterChannels[strings.clusters].forEach(cluster => {
                if (
                  !bulkAddClusters.find(
                    bulkAddCluster => bulkAddCluster.id === cluster.id
                  )
                ) {
                  addedClusters.push(cluster)
                }
              })
            return addedClusters
          } else {
            return salesPeriodClusterChannels[strings.clusters]
          }
        }
        case RESTRICT_PRODUCT_BY_ZONE_TABS[2]: {
          const addedChannelTags = []
          const channels = salesPeriodClusterChannels[strings.channels]
          if (bulkAddChannel.length) {
            channels &&
              channels.forEach(channel => {
                if (bulkAddChannel.indexOf(channel.id) === -1) {
                  addedChannelTags.push(channel)
                }
              })
            return addedChannelTags
          } else {
            return channels
          }
        }
        case RESTRICT_PRODUCT_BY_ZONE_TABS[3]: {
          if (
            bulkAddSalesPeriod &&
            salesPeriodClusterChannels &&
            salesPeriodClusterChannels[strings.salesPeriods].indexOf(
              bulkAddSalesPeriod
            ) === -1
          ) {
            return salesPeriodClusterChannels[strings.salesPeriods].filter(
              salesPeriod => salesPeriod.id !== bulkAddSalesPeriod
            )
          } else {
            return salesPeriodClusterChannels[strings.salesPeriods]
          }
        }
        case RESTRICT_PRODUCT_BY_ZONE_TABS[4]: {
          const addedFreeTags = []
          if (bulkAddFreeTags.length) {
            freeTags &&
              freeTags.forEach(freeTag => {
                if (bulkAddFreeTags.indexOf(freeTag.id) === -1) {
                  addedFreeTags.push(freeTag)
                }
              })
            return addedFreeTags
          } else {
            return freeTags
          }
        }
        default: {
          const addedZones = []
          if (restrictedZones.length) {
            zones &&
              zones.forEach(zone => {
                if (restrictedZones.indexOf(zone.id) === -1) {
                  addedZones.push(zone)
                }
              })
            return addedZones
          } else {
            return zones
          }
        }
      }
    }
  }
)

const getDraggbleSourceTittle = () => {
  const {
    restrictProductByZoneModal: { selectedTabForRestricProduct }
  } = stores

  switch (selectedTabForRestricProduct) {
    case RESTRICT_PRODUCT_BY_ZONE_TABS[0]: {
      return strings.zones
    }
    case RESTRICT_PRODUCT_BY_ZONE_TABS[1]: {
      return strings.clusters
    }
    case RESTRICT_PRODUCT_BY_ZONE_TABS[2]: {
      return `${strings.channels} tags`
    }
    case RESTRICT_PRODUCT_BY_ZONE_TABS[3]: {
      return `${strings.salesPeriods} tags`
    }
    case RESTRICT_PRODUCT_BY_ZONE_TABS[4]: {
      return strings.freeTags
    }
    default:
      return strings.zones
  }
}

const checkIfRetailChannelAdded = memoize((channels, bulkAddChannel) => {
  const retailChannel =
    channels && channels.find(channel => channel.name === strings.retailChannelName)
  return (
    retailChannel &&
    bulkAddChannel &&
    bulkAddChannel.length &&
    bulkAddChannel.find(channelId => retailChannel.id === channelId)
  )
})

const getFilteredAttributes = attributes => {
  const {
    collection: { selectedAttributes },
    product: { attributeDefinitions }
  } = stores
  const selectedCustomAttributes = filter(
    attributeDefinitions,
    defn => selectedAttributes[defn.name]
  )
  return selectedCustomAttributes.map(attributeDefn => {
    let attribute = find(
      attributes,
      attribute => attribute.definition.name === attributeDefn.name
    )
    let value = 'NA'
    switch (attributeDefn.type) {
      case 'String':
        value = attribute ? attribute.strVal || 'NA' : 'NA'
        break
      case 'Tag':
        value = attribute && attribute.tag ? attribute.tag.name : 'NA'
        break
      default:
        value = attribute ? attribute.strVal || 'NA' : 'NA'
    }

    return {
      definition: attributeDefn,
      value
    }
  })
}
export type { KPIRollUps }
export {
  retrieveStatusValue,
  filterLibraryItemsByAttributes,
  groupProductsBySegment,
  filterDataByLines,
  getColorValues,
  getAttributeValues,
  getProducts,
  getProductDetails,
  retrieveFilters,
  getProductTags,
  getActivityValue,
  getFavouriteValue,
  getSortedProducts,
  getFavouriteFilter,
  getRatingFilter,
  getCollectionBetData,
  getCollectionBuyData,
  getProductFilters,
  getProductStatusFilter,
  getFilterChipsData,
  getZoneLevelProductValue,
  getExchangeRate,
  getProductClustersWithStatus,
  getSalesPeriodStartAndEndDate,
  mapClustersWithStatus,
  isFlagRed,
  getZoneSellthrough,
  getModelDescription,
  tagsForRestrictProductByZone,
  getDraggbleSourceTittle,
  checkIfRetailChannelAdded,
  getFilteredAttributes,
  getFilterValues,
  getTagsMapForListView,
  getNumberOfWeeksFromSalesPeriod,
  getCurrencyCode,
  getAssociatedLooks,
  getSubstitutesInLooks
}
