/*
 * @Author: Raeesaa Metkari
 * @Date: 2019-11-20 16:56:23
 * @Last Modified by: Raeesaa Metkari
 * @Last Modified time: 2019-12-20 13:14:30
 */
import { QueryResult } from '@apollo/client/react'
import { Query } from '@apollo/client/react/components/Query'
import { AuthorizationContext } from '@components/Utils/Authorization'
import { ProductContainerRow } from '@modules/collection/components/ProductContainerRow'
import { CardSkeleton } from '@modules/common/component/CardSkeleton'
import { ListRowSkeleton } from '@modules/common/component/ListRowSkeleton'
import { ListViewColumnDefinition } from '@components/UI-Components/StandardTableView/models/ListviewColumnDefinition'
import Divider from '@material-ui/core/Divider'
import { IPermissions } from '@routes'
import { getEmptyArrayOfLength } from '@services/commonServices'
import { stores, strings } from '@stores'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import map from 'lodash/map'
import memoize from 'memoize-one'
import { observer } from 'mobx-react'
import React from 'react'

import { GET_COLLECTION } from '../graphql/collection'
import { ZONE_COUNTER_OFFER } from '@modules/retail/collection/graphql/zoneCounterOffer'
import * as css from './CollectionProductGrid.css'
import { ProductsCardGrid } from './ProductsCardGrid'

interface MyProps {
  style: any
  node: any
  stickyStyle?: any
  categoryKey?: string

  /**
   * NOTE: Following props will be set only in case of list view
   */
  headers?: Array<ListViewColumnDefinition>
  canTableFitInView?: boolean
  betBuyData?: Array<{}>
  rangePlans?: Array<{}>
}

@observer
export class CollectionProductRowWrapper extends React.Component<MyProps> {
  refNode
  constructor(props) {
    super(props)
    this.refNode = React.createRef()
  }

  /**
   * Method for processing data to be rendered in Distribution, BET and BUY tabs
   */
  processBuyingSessionProductsForKPIViews = memoize(
    (
      buyingSessionProducts,
      permissions: IPermissions,
      betBuyData,
      rangePlanData,
      selectedAssortmentTab
    ) => {
      const {
        collection: { getProcessedBuyingSessionProductsForListView }
      } = stores

      return getProcessedBuyingSessionProductsForListView(
        buyingSessionProducts,
        permissions,
        betBuyData,
        rangePlanData,
        selectedAssortmentTab
      )
    },
    (oldParams, newParams) => {
      return (
        isEqual(oldParams[0], newParams[0]) &&
        isEqual(oldParams[2], newParams[2]) &&
        isEqual(oldParams[3], newParams[3]) &&
        isEqual(oldParams[4], newParams[4])
      )
    }
  )

  /**
   * Method for processing product data so that it can rendered in counter offer tab of list view
   */
  processBuyingSessionProductsForCounterOffer = memoize(
    (buyingSessionProducts, zoneCounterOffer) => {
      const {
        collection: { getProcessedBuyingSessionsProductsForCounterOffer }
      } = stores

      return getProcessedBuyingSessionsProductsForCounterOffer(
        buyingSessionProducts,
        zoneCounterOffer
      )
    },
    isEqual
  )

  processBuyingSessionProductsForListView = ({
    buyingSessionProducts,
    permissions,
    betBuyData,
    rangePlanData,
    zoneCounterOffer
  }: {
    buyingSessionProducts: Array<any>
    permissions: IPermissions
    betBuyData: Array<any>
    rangePlanData: Array<any>
    zoneCounterOffer: Array<any>
  }) => {
    const [
      {
        props: { categoryKey }
      },
      {
        assortment: { selectedAssortmentTab },
        listView: { sortProductList },
        product: { isBuyMetricSort, sortBy }
      }
    ] = [this, stores]

    let products
    if (selectedAssortmentTab === 3) {
      products = this.processBuyingSessionProductsForCounterOffer(
        buyingSessionProducts,
        zoneCounterOffer
      )
    } else {
      products = this.processBuyingSessionProductsForKPIViews(
        buyingSessionProducts,
        permissions,
        betBuyData,
        rangePlanData,
        selectedAssortmentTab
      )
    }

    if (isBuyMetricSort) {
      products = sortProductList(products, sortBy, categoryKey)
    }

    return products
  }

  render() {
    const {
      props: {
        node,
        style,
        headers,
        canTableFitInView,
        stickyStyle,
        betBuyData,
        rangePlans
      },
      refNode
    } = this
    const {
      product: { whereQueryVariablesForCollection },
      nav: {
        params: { view, buyingSession }
      },
      site: { isGlobalMerchandiserOrAdmin },
      collection: { resetProductSelectionOnMouseDown },
      assortment: { selectedAssortmentTab },
      product: { handleListViewTagDrop }
    } = stores

    // FIXME: Handle query error
    const { category: activity, childrenMeta, childrenMetaValues } = node
    const variables = whereQueryVariablesForCollection(
      { name: activity },
      childrenMeta,
      childrenMetaValues
    )

    const buyingSessionIdToBeUsed = buyingSession

    return (
      <AuthorizationContext.Consumer>
        {value => (
          <Query
            query={GET_COLLECTION}
            variables={variables}
            fetchPolicy='cache-and-network'
          >
            {({ data, loading, error, refetch }: QueryResult) => {
              const mockProducts = getEmptyArrayOfLength(node.products)
              const isLoading = loading && isEmpty(data)
              const buyingSessionProducts =
                data && data.buyingSessionProductsConnection
                  ? data.buyingSessionProductsConnection.edges || []
                  : []
              return (
                <Query
                  query={ZONE_COUNTER_OFFER}
                  variables={{ buyingSessionId: buyingSessionIdToBeUsed }}
                  skip={
                    !buyingSessionIdToBeUsed ||
                    !isGlobalMerchandiserOrAdmin() ||
                    selectedAssortmentTab !== 3
                  }
                  fetchPolicy={'cache-and-network'}
                >
                  {({ loading, data: zoneCounterOfferData }: QueryResult) => {
                    const zoneCounterOffer =
                      zoneCounterOfferData && zoneCounterOfferData.zoneCounterOffer
                        ? zoneCounterOfferData.zoneCounterOffer
                        : []
                    return (
                      <div
                        ref={refNode}
                        style={style}
                        className={view === strings.grid ? css.root : null}
                        onMouseDown={resetProductSelectionOnMouseDown(refNode)}
                      >
                        {view === strings.grid ? (
                          isLoading ? (
                            map(mockProducts, (product, index) => (
                              <CardSkeleton key={index} />
                            ))
                          ) : (
                            <ProductsCardGrid
                              buyingSessionProducts={buyingSessionProducts}
                              refetch={refetch}
                              permissions={value.permissions as any}
                            />
                          )
                        ) : isLoading ? (
                          map(mockProducts, (product, index) => (
                            <>
                              <ListRowSkeleton key={index} />
                              <Divider />
                            </>
                          ))
                        ) : (
                          <ProductContainerRow
                            loading={isLoading}
                            products={
                              isLoading
                                ? []
                                : this.processBuyingSessionProductsForListView({
                                    buyingSessionProducts,
                                    permissions: value.permissions as any,
                                    betBuyData,
                                    rangePlanData: rangePlans,
                                    zoneCounterOffer
                                  })
                            }
                            kpiNode={node}
                            key={node.key}
                            headers={headers}
                            stickyStyle={stickyStyle}
                            canTableFitInView={canTableFitInView}
                            handleDrop={handleListViewTagDrop}
                          />
                        )}
                      </div>
                    )
                  }}
                </Query>
              )
            }}
          </Query>
        )}
      </AuthorizationContext.Consumer>
    )
  }
}
