import { ShowroomMenuEnum } from '@modules/common/models/enums'
import { isAdmin, isExternalClient, isZAM } from '@services/commonServices'
import { storage } from '@services/storageService'
import { nav } from '@stores/nav'
import { strings, stores } from '@stores'
import cloneDeep from 'lodash/cloneDeep'
import filter from 'lodash/filter'
import { action, computed, observable } from 'mobx'
import qs from 'query-string'

export class ShowroomHeaderStore {
  @observable isViewSelectorOpen = false
  @observable anchorViewSelectorEl = null

  @observable anchorForNav = null

  @action onCategoryClick = e => {
    this.anchorForNav = e.currentTarget
  }

  @action onCategoryClose = () => {
    this.anchorForNav = null
  }

  @action onViewChangeClick = e => {
    this.anchorViewSelectorEl = e.currentTarget
  }
  @computed get selectedView() {
    const {
      nav: {
        queryParams: { productLayout }
      }
    } = stores
    return productLayout || strings.listView
  }
  @action handleMenuSelect = menu => e => {
    const {
      nav: { updateQueryParams }
    } = stores
    updateQueryParams({ productLayout: menu })
    this.anchorViewSelectorEl = null
  }

  @action onViewSelectorClose = () => {
    this.anchorViewSelectorEl = null
  }

  @action openRoute = (url: string) => (event?) => {
    const [
      {
        history,
        queryParams: {
          buyingSessionGroupId,
          orderId,
          orderType,
          clientId,
          productLayout,
          campaignId,
          scrollToCategory,
          isAddProductToOrderModalOpen
        }
      },
      {
        showroomCollection: { invalidateOrderPageCache, invalidateCacheForAllOrders }
      }
    ] = [nav, stores]

    this.onMenuClose()

    // Evict cache if needed
    if (url.includes('/order')) {
      invalidateOrderPageCache()
    } else if (url.includes('/basketAndOrders')) {
      invalidateCacheForAllOrders()
    }

    //Basket and orders should not contain client order id
    history.push({
      pathname: url,
      search: url.includes('basketAndOrders')
        ? qs.stringify({
            clientId,
            buyingSessionGroupId,
            productLayout,
            orderType,
            campaignId,
            scrollToCategory
          })
        : qs.stringify({
            clientId,
            buyingSessionGroupId,
            orderId,
            orderType,
            productLayout,
            campaignId,
            scrollToCategory,
            isAddProductToOrderModalOpen
          })
    })
  }

  @observable menuConfig = [
    {
      title: ShowroomMenuEnum.clientSelection,
      url: `/showroom/clients`,
      enabled: true,
      backLink: null,
      forwardLink: null
    },
    {
      title: ShowroomMenuEnum.buyingSessions,
      url: `/showroom/clients/buyingSessionGroup`,
      enabled: true,
      backLink: {
        title: ShowroomMenuEnum.clientSelection,
        url: `/showroom/clients`,
        enabled: true,
        onClick: this.openRoute(`/showroom/clients`)
      },
      forwardLink: null
    },
    {
      title: ShowroomMenuEnum.lookBook,
      url: `/showroom/clients/buyingSessionGroup/lookbook`,
      enabled: true,
      backLink: null,
      forwardLink: null
    },
    {
      title: ShowroomMenuEnum.clientCollection,
      url: `/showroom/clients/buyingSessionGroup/activities/client-collection`,
      enabled: true,
      backLink: null,
      forwardLink: null
    },
    {
      title: ShowroomMenuEnum.clientWishlist,
      url: `/showroom/clients/buyingSessionGroup/activities/client-wishlist`,
      enabled: true,
      backLink: null,
      forwardLink: null
    },
    {
      title: ShowroomMenuEnum.presentation,
      url: `/showroom/clients/buyingSessionGroup/presentation`,
      enabled: true,
      backLink: {
        title: ShowroomMenuEnum.buyingSessions,
        url: `/showroom/clients/buyingSessionGroup`,
        enabled: true,
        onClick: this.openRoute(`/showroom/clients/buyingSessionGroup`)
      },
      forwardLink: {
        title: ShowroomMenuEnum.startBuy,
        url: `/showroom/clients/buyingSessionGroup/basketAndOrders`,
        enabled: true,
        onClick: this.openRoute(`/showroom/clients/buyingSessionGroup/basketAndOrders`)
      }
    },
    {
      title: ShowroomMenuEnum.buyingSessionActivities,
      url: `/showroom/clients/buyingSessionGroup/activities`,
      enabled: true,
      backLink: {
        title: ShowroomMenuEnum.basketAndOrders,
        url: `/showroom/clients/buyingSessionGroup/basketAndOrders`,
        enabled: true,
        onClick: this.openRoute(`/showroom/clients/buyingSessionGroup/basketAndOrders`)
      },
      forwardLink: {
        title: ShowroomMenuEnum.collection,
        url: `/showroom/clients/buyingSessionGroup/activities/collection`,
        enabled: false,
        onClick: this.openRoute(
          `/showroom/clients/buyingSessionGroup/activities/collection`
        )
      }
    },
    {
      title: ShowroomMenuEnum.collection,
      url: `/showroom/clients/buyingSessionGroup/activities/collection`,
      enabled: true,
      backLink: {
        title: ShowroomMenuEnum.basketAndOrders,
        url: `/showroom/clients/buyingSessionGroup/basketAndOrders`,
        enabled: true,
        onClick: this.openRoute(`/showroom/clients/buyingSessionGroup/basketAndOrders`)
      },
      forwardLink: {
        title: ShowroomMenuEnum.basket,
        url: `/showroom/clients/buyingSessionGroup/activities/order`,
        enabled: true,
        onClick: this.openRoute(`/showroom/clients/buyingSessionGroup/activities/order`)
      }
    },
    {
      title: ShowroomMenuEnum.order,
      url: `/showroom/clients/buyingSessionGroup/activities/order`,
      enabled: true,
      backLink: {
        title: ShowroomMenuEnum.collection,
        url: `/showroom/clients/buyingSessionGroup/activities/collection`,
        enabled: true,
        onClick: this.openRoute(
          `/showroom/clients/buyingSessionGroup/activities/collection`
        )
      },
      forwardLink: {
        title: ShowroomMenuEnum.basketAndOrders,
        enabled: true,
        url: `/showroom/clients/buyingSessionGroup/basketAndOrders`,
        onClick: this.openRoute(`/showroom/clients/buyingSessionGroup/basketAndOrders`)
      }
    },
    {
      title: ShowroomMenuEnum.clientNotFound,
      url: `/showroom`,
      enabled: true,
      backLink: null,
      forwardLink: null
    },
    {
      title: ShowroomMenuEnum.buyingHome,
      url: `/wholesale`,
      enabled: true
    },
    {
      title: ShowroomMenuEnum.buyingBoard,
      url: `/wholesale`,
      enabled: true
    }
  ]

  @observable anchorEl: HTMLElement = null

  getMenu = title => {
    let menus = cloneDeep(this.menuConfig)
    return menus.find(menu => menu.title === title)
  }

  @observable isBsgClosed = false

  @action setisBsgClosed = isClosed => {
    this.isBsgClosed = isClosed
  }

  getMenuForExternalClient = menus => {
    const {
      nav: {
        queryParams: { clientId }
      }
    } = stores
    if (!clientId) {
      return filter(menus, menu => menu.title === ShowroomMenuEnum.clientNotFound)
    } else {
      return this.getFilteredMenus(menus, [
        ShowroomMenuEnum.clientSelection,
        ShowroomMenuEnum.clientNotFound
      ])
    }
  }

  @action updateMenuConfig = totalCount => {
    let menus = cloneDeep(this.menuConfig)
    let currentRouteIndex = menus.findIndex(
      menu => menu.title === ShowroomMenuEnum.basket
    )
    menus[currentRouteIndex].forwardLink.enabled = totalCount ? true : false
    this.menuConfig = menus
  }

  getMenusOptions = (title, canCreateOrder: boolean = false) => {
    const {
      nav: {
        queryParams: { clientId }
      },
      showroomSidebarStore: { campaignId }
    } = stores

    let menus = cloneDeep(this.menuConfig)

    if (isExternalClient()) {
      menus = this.getMenuForExternalClient(menus)
    } else {
      menus = this.getFilteredMenus(menus, [ShowroomMenuEnum.clientNotFound])
    }
    // Filtering Buying Home (not getting used)
    menus = this.getFilteredMenus(menus, [ShowroomMenuEnum.buyingHome])
    if (!isZAM() && !isAdmin()) {
      menus = this.getFilteredMenus(menus, [ShowroomMenuEnum.buyingBoard])
    }

    if (!campaignId) {
      menus = this.getFilteredMenus(menus, [ShowroomMenuEnum.presentation])
    }

    let currentRouteIndex = menus.findIndex(menu => menu.title === title)
    // If external client logged in
    if (isExternalClient() && clientId) {
      let currentRouteIndexTobeupdated = menus.findIndex(
        menu => menu.title === ShowroomMenuEnum.buyingSessions
      )
      menus[currentRouteIndexTobeupdated].backLink = null
      if (title === ShowroomMenuEnum.presentation && currentRouteIndex > -1) {
        menus[currentRouteIndex].forwardLink.enabled = !this.isBsgClosed
      }
    }

    if (title === ShowroomMenuEnum.order) {
      let filteredMenus = filter(menus, menu => menu.title !== ShowroomMenuEnum.basket)
      return filteredMenus
    } else if (title === ShowroomMenuEnum.basket) {
      let filteredMenus = this.getFilteredMenus(menus, [ShowroomMenuEnum.order])
      //Disable all next link and forward link by default except buying home redirection menu
      filteredMenus.forEach((menu, index) => {
        if (currentRouteIndex < index && menu.title !== ShowroomMenuEnum.buyingBoard) {
          menu.enabled = false
        }
      })

      if (filteredMenus[currentRouteIndex].forwardLink) {
        filteredMenus[currentRouteIndex].forwardLink.enabled = canCreateOrder
      }
      return filteredMenus
    } else {
      //Disable all next link and forward link by default
      menus.forEach((menu, index) => {
        if (currentRouteIndex < index && menu.title !== ShowroomMenuEnum.buyingBoard) {
          menu.enabled = false
        }
      })
      let filteredMenus = this.getFilteredMenus(menus, [ShowroomMenuEnum.order])
      return filteredMenus
    }
  }

  getFilteredMenus = (menus, filterMenu) => {
    let filteredMenus = filter(menus, menu => !filterMenu.includes(menu.title))
    return filteredMenus
  }

  @action onMenuClick = event => {
    Object.assign(this, { anchorEl: event.currentTarget })
  }

  @action onMenuClose = () => {
    Object.assign(this, { anchorEl: null })
  }

  @action onLogoutClick = () => {
    const { history } = nav
    this.onMenuClose()
    storage.deleteItem('activities')
    history.push('/logout')
  }
}

export const showroomHeader = new ShowroomHeaderStore()
