/*
 * @Author: Raeesaa Metkari
 * @Date: 2019-11-20 11:56:40
 * @Last Modified by: Raeesaa Metkari
 * @Last Modified time: 2020-09-30 18:22:50
 */

import React, { memo } from 'react'
import { useObserver } from 'mobx-react-lite'
import { StandardTreeView } from '@components/UI-Components/StandardTreeView'
import { RootInfo } from '@components/UI-Components/StandardTreeView'
import { getProcessedKPI } from '@services/kpiTreeViewService'
import { stores } from '@stores'
import { useBuyingSessionGroupNameForChannel } from '@modules/retail/collection/graphql/hooks'
import { ShowroomMenuEnum } from '@modules/common/models/enums/ShowroomMenuEnum'
import { IVIPAllotment } from '@services/wholesale/productService'
import { WsAssortmentProductRowWrapper } from '@modules/wholesale/assortmentProductWrapper/container/WsAssortmentProductRowWrapper'
import { CollectionProductRowWrapper as BBCollectionProductRowWrapper } from '@modules/wholesale/buyingSessionGroupProductWrapper/containers/CollectionProductRowWrapper'
import { CategoryTreeRow } from '@modules/common/component/CategoryTreeRow'
import { TreeCategoryType } from '@modules/common/models/enums/TreeCategoryType'
import {
  RETAILRIGHTSIDENAVTOP,
  STANDARDRIGHTSIDENAVTOP
} from 'src/constant/ProductLayoutViews'

interface MyProps {
  hierarchyKPIs: any

  /**
   * Following props will only be set in case of assortment grid view
   */
  assortment?: {}
  buyingSession?: any
  isWholeSaleRequest?: boolean
  hideRightSideNav?: boolean
  showRoomGridView?: ShowroomMenuEnum
  getCustomLeafRowHeight?: Function
  isShowroomView?: boolean
  isLoading?: boolean
  clientAllotmentItems?: Array<IVIPAllotment>
}

// TODO: Move this to appriate module / create function at the top of file
const getLeafRowHeight = (node: any, availableWidth: number) => {
  let heightOfRow = 0
  if (node && availableWidth) {
    let widthOfProductCard = 224 // Card Width (204) + Margin (20)
    let heightOfProductCard = 730 // Card Height (710) + Margin (20)

    // Hack for calculating height for lower resolution screens
    if (window.innerWidth <= 1366) {
      widthOfProductCard = 224 // Card Width (204) + Margin (20)
      heightOfProductCard = 544 // Card Height (524) + Margin (20)
    }

    const totalProducts = node.products

    const productsPerRow = Math.floor(availableWidth / widthOfProductCard)
    if (productsPerRow > totalProducts) {
      heightOfRow = heightOfProductCard
    } else {
      heightOfRow = Math.ceil(node.products / productsPerRow) * heightOfProductCard
    }
  }
  return heightOfRow + 8 // Top Padding
}

const rootInfo: RootInfo = {
  root: {
    id: 'ROOTHEADER',
    height: 0,
    isSticky: true,
    stickTop: 0,
    zIndex: 30
  },
  renderRoot: false
}

export const ProductGridContainer: React.FunctionComponent<MyProps> = memo(
  ({
    assortment,
    buyingSession,
    hierarchyKPIs,
    isWholeSaleRequest,
    showRoomGridView,
    hideRightSideNav,
    getCustomLeafRowHeight,
    isShowroomView,
    clientAllotmentItems
  }) => {
    return useObserver(function useHook() {
      const {
        nav: {
          queryParams: { assortment: wholesaleAssortment },
          params: { assortment: assortmentId }
          // Querypasrams
        },
        showroomHeader: { onCategoryClick, anchorForNav, onCategoryClose }
      } = stores

      const { tableData, navData } = getProcessedKPI({
        kpiData: hierarchyKPIs,
        rootInfo: rootInfo
      })

      let buyingSessionGroupName = useBuyingSessionGroupNameForChannel(isWholeSaleRequest)

      // TODO: Update implementation for this and move to store / at the top of function
      const treeRowRender = ({ id, style }) => {
        const node = tableData[id]
        if (!node.children) {
          if (assortmentId || wholesaleAssortment) {
            return <WsAssortmentProductRowWrapper node={node} style={style} key={id} />
          } else {
            return (
              <BBCollectionProductRowWrapper node={node} key={node.key} style={style} />
            )
          }
        } else {
          return (
            <CategoryTreeRow
              kpiNode={node}
              key={node.key}
              style={style}
              onClick={onCategoryClick}
            />
          )
        }
      }

      return (
        <StandardTreeView
          navData={{
            type: isShowroomView && TreeCategoryType.DROPDOWN,
            anchorForNav: anchorForNav,
            onBackDrop: onCategoryClose,
            data: navData,
            viewName: buyingSessionGroupName,
            top: isWholeSaleRequest ? STANDARDRIGHTSIDENAVTOP : RETAILRIGHTSIDENAVTOP
          }}
          treeTable={{
            tableData: tableData,
            treeRowRender: treeRowRender,
            overScanCount: 1,
            stickyRowHeight: 25,
            leafRowHeight: getCustomLeafRowHeight
              ? getCustomLeafRowHeight
              : getLeafRowHeight,
            rootInfo
          }}
        />
      )
    })
  }
)
