import React from 'react'
import { useBuyingSeeionProductAttributeDefinition } from '@modules/retail/collection/graphql'
import { BuyingSessionProductSizeAttribute } from '@modules/retail/productDetail/component/BuyingSessionProductSizeAttribute'

interface GetBuyingSessionProductAttributesDefinitionProps {
  productAttributes: any
  active: boolean
  role: any
  buyingSession: any
  buyingSessionProductAttributes: any
  buyingSessionProductId: string
}

export const BuyingSessionProductSizeAttributesContainer: React.FunctionComponent<GetBuyingSessionProductAttributesDefinitionProps> =
  ({
    productAttributes,
    active,
    role,
    buyingSession,
    buyingSessionProductAttributes,
    buyingSessionProductId
  }) => {
    const { data: buyingSessionProductAttributesDefinitions, loading } =
      useBuyingSeeionProductAttributeDefinition()
    return (
      <BuyingSessionProductSizeAttribute
        loading={loading}
        active={active}
        role={role}
        buyingSession={buyingSession}
        buyingSessionProductId={buyingSessionProductId}
        productAttributes={productAttributes}
        buyingSessionProductAttributes={buyingSessionProductAttributes}
        buyingSessionProductAttributesDefinitions={
          buyingSessionProductAttributesDefinitions
        }
      />
    )
  }
