import { config } from '@config'

export const strings_en = {
  zone: 'zone',
  zones: 'zones',
  activity: 'activity',
  drop: 'drop',
  important: 'Important',
  zoneRestriction: 'zoneRestriction',
  cluster: 'cluster',
  clusters: 'clusters',
  Cluster: 'Cluster',
  store: 'store',
  global: 'global',
  channels: 'channels',
  clients: 'Markets',
  client: 'market',
  freeTags: 'free tags',
  freeTagsTitle: 'Free Tags',
  retailFreeTagsTitle: 'Retail Free Tags',
  wholesaleFreeTagsTitle: 'Wholesale Free Tags',
  salesPeriods: 'salesPeriods',
  sortBySalesPeriod: 'salesPeriod',
  sortByFavourites: 'favourites',
  sortByAttributes: 'attribute',
  sortByGlobalGroup: 'global',
  sortByProductAttributesGroup: 'product_attributes',
  sortByBuyValue: 'buyValue',
  sortByBuyQty: 'buyQuantity',
  coreStatus: 'CORE',
  seasonSync: 'seasonsync',
  activities: 'activities',
  departments: 'departments',
  order: 'ORDER',
  noRecordFound: 'No Record Found.',
  assortmentReviewTypes: {
    SUGGESTION: 'Review requested'
  },
  imageType: {
    activityImage: 'Activity Image',
    collectionImage: 'Collection Iamge'
  },
  assortmentReviewStatus: {
    OPEN: 'OPEN',
    CLOSED: 'CLOSED'
  },
  currencyCode: config.globalCurrency,
  buyingSessionPreBuying: 'PRE_BUYING',
  storesSettings: 'Stores',
  storeClusterSettings: 'Clustering',
  storeOTBSettings: 'OTB Weight',
  Admin: 'VIP Administrator Role',
  GlobalMerchaniser: 'Central Merchandiser Retail Role',
  RegionalMerchandiser: 'Regional Merchandiser Role',
  wholeSaleChannelName: 'Wholesale',
  wholeSaleChannelNameAvailable: 'Available',
  vipChannel: 'VIPChannel',
  vipChannelRetail: 'VIPChannel:Retail',
  retailChannelName: 'Retail',
  productStatus: {
    CORE: 'CORE',
    NON_CORE: 'NON_CORE'
  },
  restrictedProductWarning: 'Product Inaccessible',
  lookName: 'Look Name',
  lookFilter: 'Look Filter',
  lookComposition: 'Composition',
  lookColor: 'Color',
  salesPeriodName: 'Sales Period',
  salesPeriodFilter: 'Sales Period Filter',
  active: 'Active',
  inactive: 'Inactive',
  selectAll: 'Select All',
  deselectAll: 'Deselect All',
  recommended: 'Reco',
  booked: 'Booked',
  book: 'Book',
  bookUnbookAllWarningMessage: 'All quantities will be set to 0',
  bookAll: 'Book All',
  unbookAll: 'Un-book All',
  multiple: 'MULTIPLE',
  pointer: 'pointer',
  closed: 'CLOSED',
  sentToSAP: 'Sent to SAP',
  sentToERP: 'Sent to ERP',
  validationError: 'Validation Error',
  ok: 'Ok',
  warning: 'Warning',
  excelMimeType:
    'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',

  blanks: '(Blanks)',

  //Card labels
  composition: 'Composition',
  colorSpecification: 'COLOR',
  retailClusters: 'Retail clusters',
  rmRetailClusters: 'RM Retail clusters',
  salesPeriod: 'Sales Period',
  retailPrice: 'Retail Price',
  restrictedIn: 'Restricted In',
  associatedLooks: 'Associated Looks',
  substitutesInLooks: 'Substitutes In Looks',
  retailPriceInZone: 'Price In Zone',
  retailPriceInEUR: 'Price In EUR',
  retailPriceInUSD: 'Price In USD',
  retailCMClusters: 'CM Clusters',
  retailColor: 'Color',
  retailStatus: 'Retail Status',
  retailComposition: 'Composition',
  retailFloorDisplayWeek: 'Floor Display Week',

  //Attribute Selection labels
  channel: 'Availability',
  channelsForAttributeSelection: 'Channels',
  zonesForRestriction: 'Zones for restriction',

  maxAttributeMessage: 'Select all attributes to be shown on card (Max 10)',
  buyingSessionErrorMessage: 'Buying Session cannot be closed due to following errors',
  buyingSessionStatusChangeConfirmationMessage: `Are you sure you want to switch to $$NEW_STATUS$$? It won't be possible to go back to $$OLD_STATUS$$`,
  yes: 'Yes',
  no: 'No',
  confirm: 'Confirm',
  copyToClipBoard: 'Copy to clipboard',

  //channel
  removeChannel: 'Remove Availability',
  addChannel: 'Add Availability',
  removeChannelMessageForPreBuyingSession:
    'Removing retail channel will also remove product from cluster, zone and store assortments. Are you sure you want to continue?',
  removeChannelMessageForBuyingOrFrozenSession:
    'Removing retail channel will remove product from cluster assortment. Are you sure you want to continue?',
  bulkRemoveChannelMessageForPreBuyingSession:
    "<font color='#ffab07'>*Bulk removal of channel will not affect core products in buying session. </font><br><br>Removing retail channel will also remove product from cluster, zone and store assortments. Are you sure you want to continue?",
  bulkAddConfirmationModalHelpText:
    'Note : Bulk removal of channel will not affect core products in buying session',
  bulkAddChannelMessageForPreBuyingSession: 'Are you sure you want to continue?',

  // Notification priority
  notificationHigh: 'HIGH',
  notificationNormal: 'NORMAL',
  notificationLow: 'LOW',

  notifications: 'Notifications',
  markAsRead: 'Mark as read',
  markAllAsRead: 'Mark all as read',

  worldWide: 'Worldwide',
  worldWideReport: 'Worldwide Report',
  zoneReport: 'Zone Report',
  storeReport: 'Store Report',
  betReport: 'Bet Report',

  commentsHelpText: 'Click here to add comment',
  noSettingsMessage: 'No settings available',
  noSeasonsMessage: 'No seasons available',

  //Views
  cardView: 'CARD',
  listView: 'LIST',
  kpiView: 'KPIs',
  collection: 'COLLECTION',

  grid: 'grid',
  list: 'list',
  kpi: 'kpi',

  // PDF export
  exportToPDF: 'Export To PDF',

  // Tooltips
  toolTipToggleFilter: 'Toggle Filter',
  toolTipReviewAssortment: 'Review Assortment',
  toolTipRequestApproval: 'Request for Review',
  toolTipAddProduct: 'Add Product',
  toolTipSortProduct: 'Sort Product',
  toolTipGridView: 'Grid View',
  toolTipCardView: 'Card',
  toolTipListView: 'List',
  toolTipExportExcel: 'Export To Excel',
  toolTipRetailExportExcel: 'Export Retail Collection To Excel',
  toolTipManageFilters: 'Manage Filters',
  listViewInputField: 'list-view-input-field',

  rightNavigation: 'Right Navigation',
  manageAttributes: 'Manage Attributes',
  viewSelector: 'View Selector',
  viewReprt: 'View Report',
  splitQuantity: 'Split Quantity',

  //Buyingsession toolbar
  report: 'Report',
  search: 'Search',
  buyingOverview: 'Buying Overview',
  buyingDocuments: 'Buying Documents',
  uploadBriefDialogTitle: 'Upload Buying Documents PDF',
  errorMsgFailedToUploadDoc: 'Failed to upload document: Please select file.',

  // API errors
  errorMsgForFailedToUpdate: 'Failed to update',

  // API Error codes
  buyingSessionErrors: 'BUYING_SESSION_ERROR',
  associationChangeErrors: 'ASSOCIATION_CHANGE_FOR_SAME_ZONE_ERROR',

  // Default look attribute names
  lookProductColor: 'Color',
  lookProductRetailStatus: 'Retail Status',
  lookProductCategory: 'Category',
  lookProductSubCategory: 'Sub Category',
  lookProductLining: 'Type',

  // Cluster status
  noClusterStatus: 'No cluster statuses present',

  // Zone currency change confirmation msg
  zoneCurrencyChangeConfirmationMsg:
    'This change will update currency for selected combination. Once updated, it will not be possible to change currency for next one hour. Do you want to continue?',

  //Main Reporting CM Tabs label
  mainReportingWorldWideBuyTab: 'WORLDWIDE BUY',
  mainReportingZoneBuyTab: 'ZONE BUY',
  mainReportingBetTab: 'BET',

  //Main Reporting RM Tabs label
  mainReportingZoneTab: 'ZONE',
  mainReportingStoresTab: 'STORES',

  // Error messages
  authenticationError: 'User is not Authenticated',
  c8GraphQLSessionExpiredError: 'The client session has expired or is invalid.',

  centricCallSuccess: 'Successful',

  //Remove Last RM cluster conformation message
  removeLastRmClusterConfirmationMessage:
    'The action you are trying to perform will delete the product from zone assortment. Do you want to continue ?',

  //CommonErroMessage
  commonErrorMessage: 'Error in fetching data.',

  // Bulk Channel process popup messages
  retailChannelRemovalInProgressMessage:
    'Retail Channel is being removed from all the products, please wait..',
  retailChannelRemovalCompletedMessage:
    'Retail Channel has been removed from all the products. Please refresh the page or it will automatically refresh after 10 seconds.',
  retailChannelAddCompletedMessage:
    'Retail Channel has been added to all the products. Please refresh the page or it will automatically refresh after 10 seconds.',
  retailChannelRemovalPoupTitle: 'Remove Retail Channel',
  retailChannelAddPoupTitle: 'Add Retail Channel',
  refreshButtonTitle: 'Refresh',

  //Constants for Size attributes of BuyingSessionProduct
  buyingSessionProductSizeAttribute: {
    sizeFrom: 'sizeFrom',
    sizeTo: 'sizeTo'
  },

  add: 'Add',
  remove: 'Remove',

  // Collection view products error popup
  notAllowed: 'Not Allowed',
  cannotAddClusterToNonRetailProduct:
    'Cannot add Retail Cluster to product as it is not in Retail Channel',
  cannotAddRetailToInactiveProduct: 'Cannot add Retail channel on inactive product',

  buyingOverView: 'buying overview',
  buyingOverViewSettings: 'buying overview/ campaign image',
  addBuyingSession: 'add buying category',
  addBuyingSessionGroup: 'Add Session Group',
  buyingSessionSettings: 'buying session',
  buyingSessionproducts: 'Products',

  //Wholesale settings page menu
  images: 'IMAGES',
  buyingCampaign: 'BUYING CAMPAIGN',
  deliveryWindow: 'DELIVERY WINDOW',
  drops: 'DROPS',
  rtlSalesPeriod: 'SALES PERIOD',
  settings: 'Settings',

  // Create Wholesale Buying session / buying session group
  createBuyingSessionGroupTitle: 'Create New Buying Session Group',
  createBuyingSessionGroupShortTitle: 'Add Session Group',
  createBuyingSessionTitle: 'Add Buying Category',
  save: 'Save',
  next: 'Next',
  cancel: 'Cancel',
  back: 'Back',
  buyingSessionNameLabel: 'Enter Buying Session Name',

  // Buying session Group not present
  buyingSessionGroupIdNotPresent: {
    buyingSessionInitialMessage: 'Hello, there!',
    buyingSessionGroupInstruction:
      'Please create a buying session by selecting a season and selecting the create buying session group button'
  },
  // Group settings
  addDeliveryWindow: 'New Delivery Window',
  addDrop: 'New Drop',
  imageUploadHelperText: 'Drag & drop / Click here to upload',
  sortOrderForGenderName: ['MEN', 'WOMEN', 'UNISEX', 'KIDS'],

  // Wholesale settings title
  clientManagement: 'Client Settings',
  viewDoors: 'VIEW DOORS',

  // Wholesale cluster
  wholesaleClusterDeleteConfirmationDialogeMessage:
    'Cluster is in use, if you delete it all assortments for cluster as well as store that has assocation with this cluster will get deleted. Are you sure you want to delete?',
  commonConfirmatioinToDelete: 'Are you sure you want to delete?',
  deleteCluster: 'Delete Cluster',
  deleteClusterStatus: 'Delete Status',
  clusterStatus: 'Cluster Status',
  addCluster: 'Add Cluster',
  addStatus: 'Add Status',
  createClusterHeaders: ['Cluster Name', 'Color'],
  createClusterStatusHeaders: ['Cluster Statuses'],

  // Buying Session Status key for getting the Description
  description: 'Description',
  uploadDocuments: 'Upload Documents',

  //Coming Soon Component
  comingSoon: 'Coming Soon',
  underDevelopment: 'This page is under development',

  //wholesale Product Grid titles
  wholesalePrice: 'Wholesale Price',
  price: 'Price',
  suggestedRetailPrice: 'Suggested Retail Price',
  wholesaleClusters: 'Wholesale Clusters',
  carryover: 'carryover',

  defaultTabAttributeSelection: 'product_attributes',

  //Client Discount
  selectClient: 'Select Client',
  addClientDiscount: 'Add Discount',
  selectActivity: 'Select Activity',
  selectCategory: 'Select Category',
  categoryNotFound: 'Category Not Found',

  //Product Detail
  rating: 'RATING',
  details: 'DETAILS',
  looks: 'LOOKS',
  noComments: 'No comments found',
  closeIFrame: 'CLOSE',

  //price labels
  priceLabels: {
    srp: 'SRP',
    local_srp: 'Retail Price',
    euroPrice: 'Euro Price',
    sg_srp: 'SG SRP'
  },

  //Tag Types
  tagTypes: {
    channel: 'channel',
    cluster: 'cluster',
    drop: 'drop',
    freeTag: 'freeTag',
    wholesaleFreeTags: 'wholesaleFreeTags',
    retailFreeTags: 'retailFreeTags',
    wholesaleCluster: 'wholesaleCluster',
    retailCluster: 'retailCluster',
    salesPeriod: 'salesPeriod',
    clientExclusions: 'clientExclusions',
    zoneExclusions: 'zoneExclusions'
  },

  //Export order options
  exportAllOrders: 'Export All Orders',
  exportSelectedOrders: 'Export Selected Orders',

  //Wholesale messgaes
  cannotDragTagsNoChannelOnProduct:
    'Cannot add Cluster to product as it is not in Available',
  cannotDragRetailTagsNoChannelOnProduct:
    'Cannot add Retail Cluster to product as it is not in Retail Channel',
  cannotDragRetailSalesPeriodNoChannelOnProduct:
    'Cannot add Retail Sales Period to product as it is not in Retail Channel',
  cannotDragTagsInactiveProduct: 'Cannot assign tags to inactive product',
  cannotDragTagsClosedBuyingSession:
    'Cannot assign tags to product within closed buying session',
  cannotRemoveTagsInactiveProduct: 'Cannot remove tags from inactive product',
  cannotRemoveTagsClosedBuyingSession:
    'Cannot remove tags from product of closed buying session',
  channelRemovalConfirmationMessage:
    'Removing availability will also remove product from cluster, Market and store assortments. Are you sure you want to continue?',

  //Client available confirmation message
  clientAvailableConfirmMessage:
    'Are you sure you want to change availability of Market? Performing this action will delete Market and store assortments.',

  clusteringChangeConfirmationMsg:
    'This may delete certain store and Market assortments along with their assortment products. Do you want to proceed?',
  clusteringChangeConfirmationTitle: 'Clustering Change Confirmation',
  retailClusteringChangeConfirmationMsg:
    'This may delete certain store assortments along with their assortment products. Do you want to proceed?',

  mutationResultSuccess: 'SUCCESS',
  sapSuccess: 'Success',
  clusteringDisabledMessage: `Buying has either started or closed and therefore, cluster-store association cannot be updated.`,
  retailClusteringDisabledMessage: `Buying has either started or closed and therefore, cluster-store association cannot be updated.`,
  deleteDropConfirmationMessage: `Are you sure you want to remove this drop?`,
  removeDrop: `Remove Drop`,

  //Duplicate Free tag error message
  duplicateTag: 'Duplicate Tag',

  // Bulk Operation Popup section headers
  bulkOpDragSectionHeaders: {
    draggedSectionHeader: 'Apply to selected products',
    draggableSectionHeaders: {
      clusters: 'All clusters',
      channels: 'All availability tags',
      drops: 'All drops',
      retailFreeTags: 'All retail free tags',
      wholesaleFreeTags: 'All wholesale free tags',
      wholesaleClusters: 'All wholesale clusters',
      retailClusters: 'All retail clusters',
      salesPeriod: 'All Sales Period',
      clientExclusions: 'All clients',
      zoneExclusions: 'All zones'
    }
  },
  //List View column values
  CMClusters: 'CMClusters',
  FreeTags: 'FreeTags',
  WholesaleFreeTags: 'wholesaleFreeTags',
  RetailFreeTags: 'retailFreeTags',
  Drops: 'Drops',
  Channels: 'Availability',

  //left toolbar string for adding products to cluster assortment
  addProductsFromLibrary: 'Select Products from Library',

  // confirm message for delete cluster Assortment products
  confirmTitle: 'REMOVE PRODUCT',
  confirmMessage: 'Are you sure you want to remove this product?',

  //Sales Document Types
  salesDocTypes: 'Sales Document Types',
  salesDocTypesDisabledMessage:
    'Buying has either started or closed and therefore, sales document type cannot be updated.',

  //Delivery Window
  deliveryWindowDisabledMessage:
    'Buying has either started or closed and therefore, Delivery window cannot be created, updated or deleted.',

  //Showroom quantity types
  totalTargetQuantity: 'Total Draft Quantity',
  totalSizedQuantity: 'Total Sized Quantity',

  PORef: 'Market PO REF',
  Notes: 'NOTES',

  showroomHeaders: {
    showroomTitles: {
      collection: 'COLLECTION',
      list: ['LIST', 'CARD'],
      buyingSessions: 'BUYING SESSIONS DEPARTMENTS'
    },
    showroomBackTitles: {
      buyingSession: 'Buying Session'
    },
    showroomForwardTitles: {
      collectionPageForwardTitle: 'MY BASKET',
      listView: 'Collection'
    },
    showroomSubHeaderTitles: {
      quantity: 'QUANTITY',
      value: 'VALUE',
      totalQuantity: 'TOTAL QUANTITY',
      totalValue: 'TOTAL VALUE',
      totalSizedQuantity: 'TOTAL SIZED QUANTITY',
      totalSizedValue: 'TOTAL SIZED VALUE',
      totalDraftQuantity: 'TOTAL DRAFT QUANTITY',
      totalDraftValue: 'TOTAL DRAFT VALUE',
      allProducts: 'All Products',
      recommendedProducts: 'Reco Products',
      kpisBy: 'KPI BY',
      kpiRollupHead: 'ROLL UP KPI SETTINGS',
      totalACs: 'TOTAL ACs#'
    },
    showroomSubHeaderFilterValues: {
      allProducts: 'allProducts',
      recommendedProducts: 'recommendedProducts'
    }
  },

  //Showroom application confirmation message on un-check
  unbooked: 'Unbook Product',
  unbookedConfirmationMessage:
    'Are you sure you want to unbook this product? This action will remove draft and sized quantities.',
  removeProductFromBasket:
    'Are you sure you want to delete this product? This action will remove draft and sized quantities on all stores',
  buyingGroupClosedMessage: 'Cannot create order for closed buying session',
  excludedClientMessage: 'Cannot create order for closed market',
  buyingGroupClosedMessageForBasket: 'Cannot create basket for closed buying session',
  excludedClientMessageForBasket: 'Cannot create basket for closed market',
  orderStatusNotEditable: 'Cannot update order as it has been',
  targetQuantity: `Draft Quantity`,
  sizedQuantity: `Sized Quantity`,
  singleSizeProduct: 'Single Size Product',
  singleQuantity: 'Quantity',

  // Client Order Management Navbar
  ordersManagement: 'Order Management',
  clientOrders: 'Orders',
  wholesalelOrders: 'Wholesale Orders',
  retailOrders: 'Retail Orders',
  orders: 'orders',
  currentBasket: 'Current Basket',
  goToBasket: 'Go To Basket',
  notes: 'Notes',
  createNewBasket: 'CREATE NEW BASKET',
  deleteBasket: 'DELETE BASKET',
  saveAsOrder: 'SAVE AS AN ORDER',
  noOrderRecordFound: 'No order record found',
  basketCreateModal: {
    warning: 'ARE YOU SURE YOU WANT TO LEAVE THIS BASKET ?',
    WarningDetails:
      'TO SAVE YOUR WORK YOU MUST SAVE THE BASKET AS AN ORDER WHICH CAN BE DELETED OR MODIFIED LATER'
  },
  showFailedJobs: 'Show Failed Orders',
  edit: 'edit',
  // Order management
  applyToAll: 'Apply To All',

  // Status change warnings
  bulkStatusChange: {
    unbooked: 'Please put sizes on all Stores/ACs booked for following orders',
    ordersWithoutQty: 'Non booked ACs or booked ACs without quantity will be deleted'
  },
  statusChangeWarning: `Warning, non booked ACs and booked ACs without quantity will be deleted.`,
  bookedValidationError: `Please put sizes on all Stores/ACs booked`,
  delete: 'Delete',
  deleteOrder: 'Delete Order',
  deleteOrderConfirmationMsg: 'Are you sure you want to delete this order?',
  exportExcelError: {
    title: 'Export Excel Error',
    userLimit:
      'Currently Server is busy with many requests. Please try later or try exporting without images.',
    rowLimit: `Selected excel export crosses the max row limit of ${
      process.env.VIP_EXCEL_MAX_ROW_COUNT_ALLOWED_WITH_IMAGES || 20000
    }. Please try exporting without images.`
  },
  view: 'View',
  blankCellHelpText: {
    Notes: 'Add notes',
    PORef: 'Add Market PO Ref',
    DeliveryDate: 'Add Delivery Date',
    notes: 'Add notes',
    poRef: 'Add Client PO Ref',
    deliveryDate: 'Add Delivery Date'
  },
  genericError: 'Something went wrong',
  bulkChangeTitle: 'Bulk Change',
  // Send to sap errors
  exportFailedForInvalidOrders: `Either order is not validated or not a master order`,
  bulkExportFailedForInvalidOrders: `Failed to send following orders to SAP. They are either not validated or not master order: `,
  failedToSendSapOrders: `Failed to send following orders to SAP`,

  //Client setting bulk operation pop up
  clientBulkChangeTitle: 'Bulk Change',
  clientBulkChangeSubtitle: 'Apply to all visible',

  //order setting bulk operation pop up
  applyToSelected: 'Apply To Selected',
  orderManagementBulkSubtitle: 'Bulk status change',
  orderManagementInstruction: 'You must filter by a status for bulk operations',
  bulkStatusChangeTooltip:
    'Either filter is not applied or more than one status is selected in filter or selected status cannot be updated.',
  cannotBeConfirmed: `Cannot change status`,
  emptyOrderValidationErrorMessage: `Order status cannot be changed to "Confirmed" as it does not have any product.`,
  emptyOrderValidationErrorMessageForBulkUpdate: `Following orders do not have any products and therefore, their status cannot be changed to "Confirmed". Please unselect them and try again.`,
  bulkOrderValidationPopupDescription:
    'Status for below orders cannot be changed due to below errors',
  // Import excel
  importOrder: 'Import Orders',
  errorInImportingOrder: 'Error in importing orders',
  reasonsForCompleteOrderImportFail: [
    'Order status has been updated',
    'Order has been deleted',
    'Excel uploaded is for orders belonging to some other buying session group and / or market'
  ],
  reasonsForPartialOrderImportFail: [
    'Product has been removed from order',
    'Quantity entered is invalid',
    'Product is inactive',
    'Product is no longer booked',
    'Product is excluded for client/channel/cluster'
  ],
  isBuyingSessionGroupDateDisabledMessage:
    'Buying has either started or closed and therefore, end date of buying session group cannot be updated.',
  buyingSessionDate: 'Price Validity Date',
  buyingSessionDateErrorMessage: 'End of buying session date should not be empty',
  //SA Filter Modal
  filters: 'Filters',
  apply: 'Apply',
  clear: 'Clear',
  // SA Card Size
  cardSize: 'Card Size',
  cardSizeSelectorPopup: {
    lookbookCard: 'Look Cards',
    productCard: 'Product Cards'
  },

  // CM/ZAM filter
  unclustered: 'Unclustered',
  noChannels: 'No Availability',
  noFreeTags: 'No Free Tags',
  filterOptionsLabels: {
    salesQty: {
      with: 'With Qty',
      without: 'Without Qty'
    },
    status: {
      with: 'Active',
      without: 'Inactive'
    },
    favourite: {
      with: 'Favourite',
      without: 'Unfavourite'
    },
    rating: {
      with: 'Rated',
      without: 'Unrated'
    },
    productStatus: {
      with: 'Core',
      without: 'Non Core'
    },
    hasCounterOffer: {
      with: 'Flagged',
      without: 'Unflagged'
    },
    productOrderStatus: {
      with: 'Order Product',
      without: 'Non Order Product'
    },
    productComment: {
      with: 'CM',
      without: 'RM'
    }
  },

  // Reporting Page
  reportingPageTitle: 'Reporting',
  reportingWHL: 'Reporting - Wholesale',
  reportingRTL: 'Reporting - Retail',

  // Select all
  selectAllLimitMsg: 'Cannot select more than $$MAX_SELECTION_LIMIT$$ records.',

  // Order status change
  canNotChageOrderStatus:
    'Cannot change order status as either market / store is inactive',
  cannotChangeStatusAsProductsAreInactive:
    'Cannot change order status as it either has inactive product or deactivated size with quantity',

  // Send to SAP
  cannotSendOrderToSAPAsParentIsInactive:
    'Cannot send order to ERP as either market / store is inactive',
  cannotChangeSendOrderToSAPAsProductsAreInactive:
    'Cannot send order to ERP as it either has inactive product or deactivated size with quantity',

  typeToSearchProducts: 'Type to search products',

  //Basket creation message
  canNotCreateBasket: 'Cannot create basket as either market or store is inactive',
  basketCreationWarningMessage: `Basket won't contain order for stores $$DOOR_NAMES$$ as they are inactive. Are you sure you want to continue?`,

  changeorderStatus: 'Change Order Status',
  sendToSAP: 'Send to ERP',

  notificationHasBeenUpdated: 'New notifications',
  noCollectionMsg: 'There are no collections present in season',
  popupSelectActivity: 'Select Departments',
  noActivitiesMsg: 'There are no activities configured. Please contact administrator.',

  // offline error message,
  offlineToasterMessage: 'You are offline. Please check your internet connection.',
  refreshPage: 'Refresh Page',

  restoreNetwork: 'You are back online.',
  syncOrderData: 'Sync Order',
  syncBuyTabData: 'Sync Buy',
  SyncReport: 'Sync Report',
  //Door and Activity Selection PopOver
  createNewOrder: 'Create New Order',
  close: 'Close',
  confirmAndCreate: 'Confirm And Create ',
  doorAndActivitySelectionStepper: [
    '1 Select Departments',
    '2 Select Store',
    '3 Create Order'
  ],
  selectedDoors: 'Selected Store',
  selectedActivities: 'Selected Departments',

  // Client and Cluster Exclusion
  clientExclusions: 'Market Exclusions',
  whlClusterExclusions: 'Wholesale Cluster Exclusions',
  zoneExclusion: 'Zone Exclusion',
  rtlClusterExclusion: 'Retail Cluster Exclusions',

  externalClient: {
    externalClientInitialMessage: 'Hello, there!',
    externalClientNotFoundInstruction:
      'Looks like market is not associated to your username, please contact Administrator',
    externalClientUnauthorizedMessage:
      'You are not authorized to view this page. Looks like you dont have access for requested market.'
  },
  defaultErrorMessage: 'You are not authorized to view this page',
  newPresentation: 'New Presentation',
  savePresentation: 'Save Presentation',
  presentationCustomizedPage: 'Customized Page',
  presentationEmbeddedPage: 'Embedded Page',
  presentationLinkagaUrl: 'Linkage URL',
  preview: 'Preview',
  editPresentation: 'Stop Preview',
  presentationNameLabel: 'Presentation Name',
  clusterAssignmentLabel: 'Cluster Assignment',
  clientAssignmentLabel: 'Market Assignment',
  presentationNameRequired: 'Presentation name is required.',
  presentationLinkageUrlRequired: 'Linkage url is required.',
  presentationTableNameColumnLabel: 'NAME',
  presentationTableNameClusterLabel: 'CLUSTER',
  presentationTableNameClientLabel: 'MARKETS',
  presentationTableNameActionsLabel: 'ACTIONS',
  presentationTableNameDateLabel: 'DATE',
  excelExportWarningMessage: 'You have reached max limit of 40 orders to export excel',
  editPresentationTitle: 'Edit Presentation',
  deletePresentation: 'Delete Presentation',
  deletePresentationMessage: 'Are you sure you want to remove this presentation ?',
  maximumCharactersAllowed: 'Maximum of 40 characters are allowed',

  // sizes
  openSizes: 'Open Sizes',
  minimunCharactersNeeded: 'Atleast 3 char(s) are required',
  campaignEditorSaveConfirmTitle: 'Are you sure?',
  campaignEditorSaveConfirmMessage:
    'All changed progress will be lost... Do you really want to leave the page?',
  saveAndLeave: 'Save and leave',
  leave: 'Leave',
  presentationNameAlreadyTaken: 'Presentation Name already taken',

  // Customer Portal product detail
  sizes: 'Sizes',
  seeAllAttr: 'See All Product Attributes',
  comments: 'Comments',
  commentPlaceHolder: 'type comment here',
  ratingTitle: 'Rating',
  overallRatingTitle: 'Overall',
  overallRating: 'Overall Rating',
  buyingSessionsEntity: 'buyingSessions',
  notAvailable: '-',
  favouriteProperty: 'Favorites', //en-us spelling
  readMore: 'read more',
  showLess: 'show less',
  ellipsis: '...',

  // on forgot password
  emailSuccess: 'An email with reset password instructions has been sent',
  // Customer Portal product media
  mediaAlt: 'Product Media',
  vipJobStatus: {
    'VIPJobStatus:PENDING': 'Pending',
    'VIPJobStatus:IN_PROGRESS': 'In Progress',
    'VIPJobStatus:FAILED': 'Failed'
  },
  uploadTableStatusHeader: 'Status',
  uploadTableNameHeader: 'Name',
  currentUploads: 'Current Uploads',

  // sidebar
  logout: 'Logout',
  myProfile: 'My Profile',
  buyingBoard: 'Buying Board',

  //top bar
  exportToOrders: 'EXPORT TO ORDERS',
  confirmAndExport: 'CONFIRM AND EXPORT',
  exportToOrder: 'EXPORT TO ORDER',
  orderName: 'ORDER NAME',
  selectDoors: 'SELECT DOORS',
  productsSource: 'PRODUCTS SOURCE',
  select: 'SELECT',
  clearWishlist: 'CLEAR WISHLIST',
  hideHierarchy: 'Hide Hierarchy',
  showHierarchy: 'Show Hierarchy',
  productSourceOrderCreationOptions: [
    { id: 'empty', label: 'Empty order' },
    { id: 'recommended', label: 'Add CM recommended products automatically' }
  ],
  //top bar search
  searchCollection: 'Search Collection',
  searchWishlist: 'Search Wishlist',
  more: 'More',
  clearAll: 'Clear all',
  previous: 'Previous',
  filterOrSearchApplied: 'Filter or Search applied',

  //new order ui
  totalDraftQty: 'TOTAL DRAFT QTY',
  totalSizedQty: 'TOTAL SIZED QTY',
  door: 'DOOR',
  booking: 'BOOKING',
  bookUnbook: 'Book / unbook all',
  draftQty: 'DRAFT QTY',
  sizedQty: 'SIZED QTY',
  draft: 'DRAFT',
  sized: 'SIZED',
  doors: 'Doors',
  salesDocType: 'Sales Doc type',
  poRef: 'Market PO Ref',
  createdDate: 'Created Date',
  departmentsTitle: 'Departments',
  deliveryDate: 'Delivery Date',
  createdBy: 'Created By',
  import: 'Import',
  export: 'Export',
  orderNumber: 'Order #',
  total: 'Total',
  qty: 'QUANTITY',
  scrollMore: 'Scroll to view more',
  unbookAllConfirmTitle: 'UNBOOK/ UNBOOK ALL CONFIRMATION',
  unbookAllConfirmMessage:
    'Are you sure you would like to unbook the selected door(s)? This action will remove draft/sized quantities or remove product from order.',
  removeDoorConfirmTitle: 'REMOVE DOOR(s) CONFIRMATION',
  removeDoorConfirmMessage:
    'By removing this door / doors all product allocations and associated quantities will be removed and will not be saved. Are you sure you wish to remove the door/doors from this order?',
  kpiBySizedValueLabel: 'KPI BY SIZED VALUE',
  kpiByQtyValueLabel: 'KPI BY DRAFT VALUE',
  assortmentCreating: 'Assortment creation in progress',
  syncConfirmMessage:
    'Are you sure you want to perform this operation. Syncing data multiple time can cause slowness in the system',
  discountSyncFailed: 'Discount sync has failed, Please consider manual syncing',
  priceSyncFailed: 'Price Validity Date sync has failed, Please consider manual syncing',
  productNotAvailable: 'This product is not available for this door',
  selectChannels: 'Select Channels',
  sizedValue: 'Sized Value',
  value: 'Value',
  orderValidationErrorKeys: {
    inactiveProducts: 'Inactive Products',
    clientExcludedProducts: 'Client Excluded Products',
    zoneExcludedProducts: 'Zone Excluded Products',
    withoutWHLChannelProducts: 'Products without Wholesale Channel',
    withoutRTLChannelProducts: 'Products without Retail Channel',
    inactiveSizeProducts: 'Inactive Size Products',
    clusterExcludedProducts: 'Cluster Excluded Products'
  },
  selectCardSizeTooltip: 'Adjust cards size',

  // retail settings
  retailStoreModal: 'retailStoreModal'
}
