import { useQuery } from '@apollo/client/react/hooks/useQuery'
import { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions'
import { GET_CAMPAIGN_BY_ID } from '.'

export const useGetCampaign = (
  campaignId: string,
  fetchPolicy: WatchQueryFetchPolicy = 'cache-first',
  includeImages: boolean = false
) => {
  return useQuery(GET_CAMPAIGN_BY_ID, {
    fetchPolicy,
    variables: {
      campaignId,
      includeImages
    },
    skip: !campaignId
  })
}
