import { ProductCardAttributeEnum } from '@modules/common/models/enums'
import { WholeSaleProductItemConf } from '@modules/common/models/interfaces/WholesaleProductConf'
import { VIPChannel } from '@modules/common/models/enums/VIPChannel'
export const tags: Array<WholeSaleProductItemConf> = [
  {
    name: 'Market Exclusion',
    value: ProductCardAttributeEnum.clientExclusion,
    attributeName: ProductCardAttributeEnum.clientExclusion,
    isSelected: false,
    disabled: false,
    allowedForChannels: [VIPChannel.Wholesale]
  },
  {
    name: 'Zone Exclusion',
    value: ProductCardAttributeEnum.zoneExclusion,
    attributeName: ProductCardAttributeEnum.zoneExclusion,
    isSelected: false,
    disabled: false,
    allowedForChannels: [VIPChannel.Retail]
  },
  {
    name: 'Availability',
    value: ProductCardAttributeEnum.channels,
    attributeName: ProductCardAttributeEnum.channels,
    isSelected: true,
    disabled: false,
    allowedForChannels: []
  },
  {
    name: 'Wholesale Clusters',
    value: ProductCardAttributeEnum.wholesaleClusters,
    attributeName: ProductCardAttributeEnum.wholesaleClusters,
    isSelected: true,
    disabled: false,
    allowedForChannels: [VIPChannel.Wholesale]
  },
  {
    name: 'Retail Clusters',
    value: ProductCardAttributeEnum.retailClusters,
    attributeName: ProductCardAttributeEnum.retailClusters,
    isSelected: true,
    disabled: false,
    allowedForChannels: [VIPChannel.Retail]
  },
  {
    name: 'Drops',
    value: ProductCardAttributeEnum.drop,
    attributeName: ProductCardAttributeEnum.drop,
    isSelected: false,
    disabled: false,
    allowedForChannels: [VIPChannel.Wholesale]
  },
  {
    name: 'Retail Free Tags',
    value: ProductCardAttributeEnum.retailFreeTags,
    attributeName: ProductCardAttributeEnum.retailFreeTags,
    isSelected: false,
    disabled: false,
    allowedForChannels: [VIPChannel.Retail]
  },
  {
    name: 'Wholesale Free Tags',
    value: ProductCardAttributeEnum.wholesaleFreeTags,
    attributeName: ProductCardAttributeEnum.wholesaleFreeTags,
    isSelected: false,
    disabled: false,
    allowedForChannels: [VIPChannel.Wholesale]
  },
  {
    name: 'Wholesale Cluster Exclusion',
    value: ProductCardAttributeEnum.wholesaleClusterExclusion,
    attributeName: ProductCardAttributeEnum.wholesaleClusterExclusion,
    isSelected: false,
    disabled: false,
    allowedForChannels: [VIPChannel.Wholesale]
  },
  {
    name: 'Retail Cluster Exclusion',
    value: ProductCardAttributeEnum.retailClusterExclusion,
    attributeName: ProductCardAttributeEnum.retailClusterExclusion,
    isSelected: false,
    disabled: false,
    allowedForChannels: [VIPChannel.Retail]
  },
  {
    name: 'Retail Sales Period',
    value: ProductCardAttributeEnum.retailSalesPeriod,
    attributeName: ProductCardAttributeEnum.retailSalesPeriod,
    isSelected: false,
    disabled: false,
    allowedForChannels: [VIPChannel.Retail]
  }
]

// C & K change: GBB-4241: Wholesale Pice to Price
export const WholesaleAttributes: Array<WholeSaleProductItemConf> = [
  {
    name: 'Wholesale Price',
    value: ProductCardAttributeEnum.wholesalePrice,
    attributeName: ProductCardAttributeEnum.wholesalePrice,
    isSelected: true,
    disabled: false
  },
  {
    name: 'Retail Price',
    value: ProductCardAttributeEnum.retailPrice,
    attributeName: ProductCardAttributeEnum.retailPrice,
    isSelected: true,
    disabled: false
  },
  {
    name: 'SG SRP',
    value: ProductCardAttributeEnum.suggestedRetailPrice,
    attributeName: ProductCardAttributeEnum.suggestedRetailPrice,
    isSelected: false,
    disabled: false
  },
  {
    name: 'Actions',
    value: ProductCardAttributeEnum.actions,
    attributeName: ProductCardAttributeEnum.actions,
    isSelected: true,
    disabled: false
  }
]

export const WholesaleLookAttributes: Array<WholeSaleProductItemConf> = [
  {
    name: 'Associated Looks',
    value: ProductCardAttributeEnum.associatedLooks,
    attributeName: ProductCardAttributeEnum.associatedLooks,
    isSelected: false,
    disabled: false
  }
]
