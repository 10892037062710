import Chip from '@material-ui/core/Chip'
import Tooltip from '@material-ui/core/Tooltip'
import { ChipListEnum } from '@modules/common/models/enums'
import React, { memo } from 'react'
import styled from 'styled-components'
import { ChipLabel } from '../ChipList/ChipLabel'
import ColorCircle from '../ChipList/ColorCircle'
import { IChipList, IStyledChip } from '../ChipList/Interface'
import { CrossIcon } from '../svg'
import * as css from './StandardChipList.css'

const StyledChip = styled(({ ...props }) => (
  <Chip classes={{ label: 'label', deleteIcon: css.deleteIconCSS }} {...props} />
))((props: IStyledChip) => {
  return {
    '&&': {
      margin: 'var(--chip-margin)',
      borderRadius: 'var(--chip-border-radius)',
      backgroundColor: 'var(--chip-background-color)',
      height:
        props.chipsize === ChipListEnum.small
          ? 'var(--chip-size-small)'
          : 'var(--chip-size-normal)',
      '&& .label': {
        paddingRight: 5,
        flex: '1 0 auto'
      }
    }
  }
})

const StyledContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'hidden'
})

const StyledChipListWrapper = styled.div({
  height: 'var(--cell-height)',
  padding: 'var(--cell-padding)'
})

export const StandardChipList: React.FunctionComponent<IChipList> = memo(
  ({
    tags,
    type,
    product,
    chipsize = ChipListEnum.normal,
    handleClick,
    handleChipDelete
  }) => {
    const onScroll = event => {
      event.stopPropagation()
    }
    return (
      <StyledContainer className='standardScrollBar' onScroll={onScroll}>
        {tags &&
          tags.length > 0 &&
          tags.map(tag => {
            const { color, title, subtitle, id, tooltip } = tag
            const titleLabel = `${subtitle ? `${title} | ${subtitle}` : title}`
            return (
              <StyledChipListWrapper key={id}>
                <Tooltip title={tooltip || titleLabel}>
                  <div>
                    <StyledChip
                      chipsize={chipsize}
                      icon={color && <ColorCircle color={color} />}
                      label={<ChipLabel title={title} subtitle={subtitle} />}
                      onDelete={
                        handleChipDelete
                          ? () => handleChipDelete!(product, id, type)
                          : undefined
                      }
                      onClick={
                        handleClick ? e => handleClick!(product, id, e) : undefined
                      }
                      deleteIcon={<img src={CrossIcon} alt='' />}
                    />
                  </div>
                </Tooltip>
              </StyledChipListWrapper>
            )
          })}
      </StyledContainer>
    )
  }
)
