import React from 'react'
import { useObserver } from 'mobx-react-lite'
import { useGetFreeTags, useGetSalesPeriodClusterChannels } from '../graphql/hooks'
import { RestrictProductByZoneDragDropArea } from '../component/RestrictProductByZone/RestrictProductByZoneDragDropArea'
import { useZones } from '@modules/stores/graphql/hooks'

interface RestrictProductByZoneDragDropAreaContainerProps {}

export const RestrictProductByZoneDragDropAreaContainer: React.FunctionComponent<RestrictProductByZoneDragDropAreaContainerProps> =
  () => {
    return useObserver(function useHook() {
      let {
        data: salesPeriodClusterChannelsResponse,
        loading: loadingSalesPeriodClusterChannels
      } = useGetSalesPeriodClusterChannels()
      let { data: zoneResponse, loading: loadingZones } = useZones()
      let { data: freeTagsResponse, loading: loadingFreeTags } = useGetFreeTags()
      return (
        <div>
          <RestrictProductByZoneDragDropArea
            loading={loadingZones || loadingSalesPeriodClusterChannels || loadingFreeTags}
            zoneResponse={zoneResponse}
            salesPeriodClusterChannelsResponse={salesPeriodClusterChannelsResponse}
            freeTagsResponse={freeTagsResponse}
          />
        </div>
      )
    })
  }
