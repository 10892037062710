import { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions'
import { useQuery } from '@apollo/client/react/hooks/useQuery'
import { stores } from '@stores'
import { GET_BUYING_SESSION } from '.'
import CLIENT_ASSORTMENTS from './clientAssortment'
import DOOR_ASSORTMENTS from './doorAssortment'

export const useClientAssortments = () => {
  const {
    nav: {
      queryParams: { buyingSession }
    }
  } = stores
  const { data, loading, error } = useQuery(CLIENT_ASSORTMENTS, {
    skip: !buyingSession,
    variables: {
      buyingSessionId: buyingSession
    },
    fetchPolicy: 'cache-and-network'
  })
  return {
    items: data?.findVIPClientAssortment?.Items,
    loading,
    error
  }
}

export const useBuyingSession = (
  buyingSession: string,
  fetchPolicy: WatchQueryFetchPolicy = 'cache-first'
) => {
  return useQuery(GET_BUYING_SESSION, {
    skip: !buyingSession,
    variables: {
      id: buyingSession
    },
    fetchPolicy
  })
}

export const useDoorAssortments = () => {
  const {
    nav: {
      queryParams: { buyingSession }
    }
  } = stores
  const { data, loading, error } = useQuery(DOOR_ASSORTMENTS, {
    skip: !buyingSession,
    variables: {
      buyingSessionId: buyingSession
    },
    fetchPolicy: 'cache-and-network'
  })
  return { items: data?.findVIPDoorAssortment?.Items, loading, error }
}
