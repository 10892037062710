import '@styles/ProductOperation/style.css'
import React from 'react'
import ProductMedia, { IProductMedia } from '../ProductMedia'

export interface ProductOperationProps extends IProductMedia {
  bottomLeftIcon?: any
  bottomRightIcon?: any
  topLeftIcon?: any
  topRightIcon?: any
  bottomVideoIcon?: any
}

export const ProductOperation: React.FC<ProductOperationProps> = ({
  bottomLeftIcon,
  bottomRightIcon,
  topLeftIcon,
  topRightIcon,
  bottomVideoIcon,
  ...props
}) => {
  return (
    <div className='product-operation-container'>
      <div className='product-media-section'>
        <ProductMedia {...props} />
      </div>
      {bottomLeftIcon && (
        <div className={'product-operation-icon-bottom-left'}>{bottomLeftIcon}</div>
      )}
      {bottomVideoIcon && (
        <div
          className={
            bottomLeftIcon
              ? 'product-operation-video-icon'
              : 'product-operation-icon-bottom-left'
          }
        >
          {bottomVideoIcon}
        </div>
      )}
      {bottomRightIcon && (
        <div className={'product-operation-icon-bottom-right'}>{bottomRightIcon}</div>
      )}
      {topLeftIcon && (
        <div className={'product-operation-icon-top-left'}>{topLeftIcon}</div>
      )}
      {topRightIcon && (
        <div className={'product-operation-icon-top-right'}>{topRightIcon}</div>
      )}
    </div>
  )
}
