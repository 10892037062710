export enum ORDER_SERVICE_ENDPOINTS {
  GET_ORDER_PRODUCTS_HIERARCHY = 'order/product-hierarchy',
  GET_ORDER_COLLECTION_PRODUCTS_HIERARCHY = 'order/product-hierarchy',
  GET_PRODUCTS = 'order/products',
  GET_COLLECTION_PRODUCTS = 'order/collection',
  GET_DOOR_ORDER_KPI_BY_CATEGORY = 'getDoorOrderKpiByCategory',
  GET_CLIENT_ORDER_PRODUCTS = 'getClientOrderproducts',
  GET_DOOR_ORDER_PRODUCTS = 'getDoorOrderProducts',
  GET_CLIENT_ORDER_SUMMARY = 'order/summary/clientOrder',
  GET_DOOR_ORDER_SUMMARY = 'order/summary/doorOrder',
  UPDATE_SUMMARY_ORDER_PO_REF = 'order',
  UPDATE_SUMMARY_ORDER_NOTES = 'order',
  UPDATE_SUMMARY_ORDER_DELIVERY_DATE = 'order',
  GET_PRODUCT_DETAILS = 'products',
  GET_ORDER_PRODUCT_DETAILS = 'order-product',
  PATCH_DRAFT_SIZE_QTY = 'order-product/updateDoorOrderProductQty',
  PATCH_CLIENT_ORDER_QTY = 'order-product/clientOrder',
  GET_FILTER_ATTRIBUTES = 'order/listFilterAttributes',
  GET_COLLECTION_FILTER_ATTRIBUTES = 'order/collection/listFilterAttributes',
  ADD_REMOVE_DOORS = 'order/addOrRemoveDoorOrders',
  GET_ORDER_NAVIGATION_DATA = 'order/navigation-data',
  APPLY_TO_ALL_SUMMARY = 'order/applyToAllSummary/clientOrder',
  UPDATE_DOOR_ORDER_QUANTITY = 'order-product/updateDoorOrderProductQty',
  BOOK_UNBOOK_DOOR = 'order-product/clientOrder',
  UNBOOK_DOOR_FOR_DOOR_ORDER = 'order-product/doorOrder',
  BULK_UPDATE_PRODUCT_QUANTITY = 'order-product/bulkUpdateProductQty',
  GET_BUY_KPI_PRODUCTS_HIERARCHY = 'buyKPIs/retail',
  GET_FORECAST_KPI_PRODUCTS_HIERARCHY = 'forecast/kpis',
  DOWNLOAD_CLIENT_ORDERS = 'download/excel/retail-orders-download/',
  DOWNLOAD_WHOLESALE_ORDERS = 'download/excel/whl-orders/',
  DOWNLOAD_BB_WHOLESALE_ORDERS = 'download/cm-excel/',
  UPDATE_COMMENT = 'comments/product-comments-upsert'
}
