import { MULTI_SELECT_COLUMN_WIDTH } from '@components/UI-Components/StandardTableView/common/constants'
import {
  ListViewColumnDefinition,
  ListViewRowSelectionConf
} from '@components/UI-Components/StandardTableView/models/ListviewColumnDefinition'
import { StickyStyle } from '@modules/common/models'
import filter from 'lodash/filter'
import findLastIndex from 'lodash/findLastIndex'
import slice from 'lodash/slice'
import memoize from 'memoize-one'

export const getRowWidth = memoize(
  (
    colDef: Array<ListViewColumnDefinition>,
    rowSelectionConf?: ListViewRowSelectionConf,
    allowMultiSelect?: boolean
  ) => {
    const colWidth = colDef.reduce((acc, item) => {
      return acc + item.width
    }, rowSelectionConf?.width ?? 0)
    return colWidth + (allowMultiSelect ? MULTI_SELECT_COLUMN_WIDTH : 0)
  }
)

export const getStickyColumnsStyle = memoize(
  (
    colDef: Array<ListViewColumnDefinition>,
    allowMultiSelect: boolean = false
  ): Array<StickyStyle> => {
    const styles = []
    const lastFixedColIndex = findLastIndex(colDef, col => col.isFixed)
    slice(colDef, 0, lastFixedColIndex + 1).reduce(
      (leftPos, col, index) => {
        styles.push({
          left: leftPos,
          shouldApplyCellShadow: index === lastFixedColIndex ? true : false
        })
        return leftPos + col.width
      },
      allowMultiSelect ? MULTI_SELECT_COLUMN_WIDTH : 0
    )
    return styles
  }
)

export const getKPIColDef = memoize((cols: Array<ListViewColumnDefinition>) => {
  return filter(
    cols,
    col => col.isRootColumn || (col.category && col.category.key && col.category.showKPI)
  )
})
