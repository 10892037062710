/*
 * @Author: Raeesaa Metkari
 * @Date: 2020-03-25 13:28:15
 * @Last Modified by: Raeesaa Metkari
 * @Last Modified time: 2020-04-14 13:45:43
 */

import { useQuery } from '@apollo/client/react/hooks/useQuery'
import { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions'
import { GET_CLIENTS, TOGGLE_REORDER_FLAG_FOR_CLIENT } from '.'
import { useMutation } from '@apollo/client/react/hooks/useMutation'
import { CriteriaOperationType } from '@modules/models'
import { VIPClientType } from '@modules/common/models/enums/VIPClientType'
import GET_CLIENT_SUMMARY from './clientSummary'

interface IClientQueryVariables {
  __Parent__?: { Id?: { operation: CriteriaOperationType; value: string } }
  ClientType?: { operation: CriteriaOperationType; value: VIPClientType }
}

/**
 * Hook for querying clients
 * @param {IClientQueryVariables} variables
 * @param {WatchQueryFetchPolicy} fetchPolicy
 * @returns useQuery hook
 */
export const useClients = (
  variables?: IClientQueryVariables,
  fetchPolicy: WatchQueryFetchPolicy = 'cache-first'
) => {
  return useQuery(GET_CLIENTS, {
    variables: {
      whereInput: variables
    },
    fetchPolicy
  })
}

/**
 * Hook for querying single client
 * @param {IClientQueryVariables} variables
 * @param {WatchQueryFetchPolicy} fetchPolicy
 * @returns useQuery hook
 */
export const useClientSummary = (
  clientId: string,
  fetchPolicy: WatchQueryFetchPolicy = 'cache-first'
) => {
  const { data, loading } = useQuery(GET_CLIENT_SUMMARY, {
    variables: {
      id: clientId
    },
    skip: !clientId,
    fetchPolicy
  })

  return {
    summary: data?.getVIPClient,
    loading
  }
}

/**
 * Hook for toggle reorder flag mutation
 * @returns reference to mutation hook
 */
export const useToggleReorderFlagForClient = () => {
  const [ref] = useMutation(TOGGLE_REORDER_FLAG_FOR_CLIENT)
  return ref
}
