import { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions'
import { useMutation } from '@apollo/client/react/hooks/useMutation'
import { useQuery } from '@apollo/client/react/hooks/useQuery'
import GET_CLIENT_SUMMARY from '@modules/wholesale/clientSettings/graphql/clientSummary'
import { ADD_BUYING_SESSION_PRODUCT_COMMENT } from '../graphql/addBuyingSessionProductComment'
import { DELETE_BUYING_SESSION_PRODUCT_COMMENT } from '../graphql/deleteBuyingSessionProductComment'
import { EXCLUDE_CLIENTS_FROM_BUYING_SESSION_PRODUCT } from '../graphql/excludeClientsFromBuyingSessionProduct'
import { ADD_UPDATE_CLUSTER_EXCLUSION_LIST_FOR_BUYING_SESSION_PRODUCT } from '../graphql/excludeClustersFromBSP'
import { GET_BUYING_SESSION_PRODUCT_DETAILS } from '../graphql/findBuyingSessionProductDetails'
import { GET_BUYING_SESSION_PRODUCT_COMMENTS } from '../graphql/getBuyingSessionProductComments'
import { GET_BUYING_SESSION_PRODUCT_FAVOURITES } from '../graphql/getBuyingSessionProductFavourite'
import { GET_BUYING_SESSION_PRODUCT_RATING } from '../graphql/getBuyingSessionProductRating'
import { GET_RATING_TYPES } from '../graphql/getRatingTypes'
import { REMOVE_CLIENT_FROM_BUYING_SESSION_PRODUCT_EXCLUSION } from '../graphql/removeClientFromBuyingSessionProductExclusion'
import { UPDATE_BUYING_SESSION_PRODUCT_COMMENT } from '../graphql/updateBuyingSessionProductComment'
import { UPDATE_BUYING_SESSION_PRODUCT_RATING } from '../graphql/updateBuyingSessionProductRating'

export interface IProductRating {
  buyingSessionProduct?: string
  ratingType?: string
  ratingValue?: any
}

export interface IProductComment {
  buyingSessionProduct: string
  comment: string
}

export interface IUpdateProductComment {
  commentId: string
  commentText: string
}

export interface IDeleteProductComment {
  commentId: string
}

export interface IExcludeClientFromBuyingSessionProduct {
  buyingSessionProduct: string
  clients: any
}

export interface IRemoveClientFromBuyingSessionProductExclusion {
  buyingSessionProduct: string
  client: string
}

// Cluster Exclusion
export interface IExcludeClusterFromBSP {
  buyingSessionProduct: string
  clusters: any[]
}

/**
 * Hook for returning buying session product detail
 * @param buyingSessionProductID  - Buying session product id to be passed with query
 * @param {WatchQueryFetchPolicy} fetchPolicy - Fetch policy for query, defaults to cache-first
 */

export const useBuyingSessionProductDetail = (
  buyingSessionProductWhere,
  fetchPolicy: WatchQueryFetchPolicy = 'cache-first'
) => {
  return useQuery(GET_BUYING_SESSION_PRODUCT_DETAILS, {
    variables: {
      where: buyingSessionProductWhere
    },
    fetchPolicy,
    skip: !buyingSessionProductWhere
  })
}

export const useBuyingSessionProductFavourites = (
  buyingSessionProductWhere,
  skip = false,
  fetchPolicy: WatchQueryFetchPolicy = 'cache-first'
) => {
  return useQuery(GET_BUYING_SESSION_PRODUCT_FAVOURITES, {
    variables: {
      where: buyingSessionProductWhere
    },
    fetchPolicy,
    skip
  })
}

/**
 * Hook for returning rating types
 */

export const useRatingTypes = (fetchPolicy: WatchQueryFetchPolicy = 'cache-first') => {
  return useQuery(GET_RATING_TYPES, {
    fetchPolicy
  })
}

/**
 * Hook for returning buying session product rating
 */
export const useBuyingSessionProductRating = (
  userId,
  buyingSessionProductId,
  fetchPolicy: WatchQueryFetchPolicy = 'cache-first'
) => {
  return useQuery(GET_BUYING_SESSION_PRODUCT_RATING, {
    variables: {
      userId: userId,
      buyingSessionProductId: buyingSessionProductId
    },
    skip: !userId || !buyingSessionProductId,
    fetchPolicy
  })
}

export const useUpdateBuyingSessionProductRating = () => {
  const [ref] = useMutation(UPDATE_BUYING_SESSION_PRODUCT_RATING)
  return ref
}

/**
 * Hook for returning buying session product comments
 */
export const useBuyingSessionProductComments = (
  buyingSessionProductId,
  fetchPolicy: WatchQueryFetchPolicy = 'cache-first'
) => {
  return useQuery(GET_BUYING_SESSION_PRODUCT_COMMENTS, {
    variables: {
      buyingSessionProductId: buyingSessionProductId
    },
    skip: !buyingSessionProductId,
    fetchPolicy
  })
}

export const useAddBuyingSessionProductComment = () => {
  const [ref] = useMutation(ADD_BUYING_SESSION_PRODUCT_COMMENT)
  return ref
}

export const useUpdateBuyingSessionProductComment = () => {
  const [ref] = useMutation(UPDATE_BUYING_SESSION_PRODUCT_COMMENT)
  return ref
}

export const useDeleteBuyingSessionProductComment = () => {
  const [ref] = useMutation(DELETE_BUYING_SESSION_PRODUCT_COMMENT)
  return ref
}

export const useExcludeClientsFromBUyingSessionProduct = () => {
  const [ref] = useMutation(EXCLUDE_CLIENTS_FROM_BUYING_SESSION_PRODUCT)
  return ref
}

export const useRemoveClientFromBuyingSessionProductExclusion = () => {
  const [ref] = useMutation(REMOVE_CLIENT_FROM_BUYING_SESSION_PRODUCT_EXCLUSION)
  return ref
}

// Cluster Exclusion
export const useAddUpdateExcludedClustersForBSP = () => {
  const [ref] = useMutation(ADD_UPDATE_CLUSTER_EXCLUSION_LIST_FOR_BUYING_SESSION_PRODUCT)
  return ref
}

export const useClientPrices = (
  clientId: string,
  fetchPolicy: WatchQueryFetchPolicy = 'cache-first'
) => {
  return useQuery(GET_CLIENT_SUMMARY, {
    variables: {
      id: clientId
    },
    skip: !clientId,
    fetchPolicy
  })
}
