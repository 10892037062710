// export * from './clusterSetup.graphql'
import GET_ACTIVITIES from './getActivities.graphql'
import GET_CLUSTER_SETUP from './clusterSetup.graphql'
import UPDATE_ALLOTMENT_FOR_STORE from './updateAllotmentForStore.graphql'
import AFFECTS_STORE_ASSORTMENT_PRODUCTS from './affectsStoreAssortmentProducts.graphql'

export {
  GET_ACTIVITIES,
  GET_CLUSTER_SETUP,
  UPDATE_ALLOTMENT_FOR_STORE,
  AFFECTS_STORE_ASSORTMENT_PRODUCTS
}
