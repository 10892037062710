import { strings } from '@stores'
import { ViewTabs } from '@modules/common/models/enums'
import { VIPChannel } from '@modules/common/models/enums/VIPChannel'
import { PERMISSION_MAP } from '@services/userRoleService'
import { UserRoles } from '@modules/common/models/enums/UserRoles'

export interface TabViewConf {
  key?: string
  name: ViewTabs
  view?: string[]
  allowedForChannels?: VIPChannel[]
  excludedSubviews?: string[]
  allowedForUsers?: UserRoles[]
}

interface ViewMap {
  [key: string]: Array<TabViewConf>
}

export const ViewTabsMap: ViewMap = {
  [strings.list]: [
    {
      key: ViewTabs.DISTRIBUTION,
      name: ViewTabs.DISTRIBUTION,
      view: ['collection', 'client', 'cluster'],
      allowedForChannels: [VIPChannel.Wholesale]
    },
    {
      key: ViewTabs.DISTRIBUTION,
      name: ViewTabs.RTL_DISTRIBUTION,
      view: ['collection', 'client', 'door', 'cluster'],
      allowedForChannels: [VIPChannel.Retail]
    },
    {
      key: ViewTabs.FORECAST,
      name: ViewTabs.FORECAST,
      view: ['collection', 'client', 'door'],
      allowedForChannels: [VIPChannel.Retail]
    },
    {
      key: ViewTabs.BUY,
      name: ViewTabs.BUY,
      view: ['collection', 'client'],
      allowedForChannels: [VIPChannel.Wholesale]
    },
    {
      key: ViewTabs.BUY,
      name: ViewTabs.RTL_BUY,
      view: ['collection', 'client', 'door'],
      allowedForChannels: [VIPChannel.Retail]
    },
    {
      key: ViewTabs.COUNTER_OFFER,
      name: ViewTabs.COUNTER_OFFER,
      view: ['collection'],
      allowedForChannels: [VIPChannel.Retail],
      excludedSubviews: ['overview'],
      allowedForUsers: PERMISSION_MAP.find(ele => VIPChannel.Retail === ele.id)
        .allowedRoles
    },
    {
      key: ViewTabs.SPLIT,
      name: ViewTabs.SPLIT,
      view: ['client'],
      allowedForChannels: [VIPChannel.Retail]
    }
  ],
  [strings.kpi]: [
    {
      key: ViewTabs.FORECAST,
      name: ViewTabs.FORECAST,
      view: ['collection', 'client'],
      allowedForChannels: [VIPChannel.Retail]
    },
    {
      name: ViewTabs.BUY
    },
    {
      key: ViewTabs.RTL_BUY,
      name: ViewTabs.RTL_BUY,
      view: ['collection', 'client', 'door'],
      allowedForChannels: [VIPChannel.Retail]
    }
  ]
}
