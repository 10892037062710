import React from 'react'
import css from './ProductCardChip.css'
import { StandardChip } from './StandardChip'

interface IProductCardChip {
  content?: any
  onDelete?: any
  onClick?: any
  node?: any
}

export const ProductCardChip: React.FunctionComponent<IProductCardChip> = ({
  content,
  onDelete,
  onClick,
  node
}) => {
  return (
    <div className={css.root}>
      {content.length
        ? content.map(chip => (
            <StandardChip
              node={node}
              chip={chip}
              key={chip?.id}
              // note: I don't want undefined/null values; hence, explicitly comparing w/ false
              onDelete={
                onDelete && chip.canDelete !== false ? () => onDelete(chip) : null
              }
              onClick={onClick ? e => onClick(chip, e) : null}
            />
          ))
        : 'NA'}
    </div>
  )
}
