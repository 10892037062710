import React from 'react'
import Typography from '@components/std-components/Typography'
import { Chip } from '@components/std-components/Chip'
import { useSAProductDetailsContext } from '../../hook/SAProductDetails-Hook'
import { stores, strings } from '@stores'
import { TypoGraphy } from './SAProductDetailUIService'
import { VIPClientType } from '@modules/common/models/enums/VIPClientType'
import { getEnumValue } from '@utils/get-enum-value'
import * as css from './SAProductDetailTags.css'

interface ISAProductDetailTags {
  typographyVariant?: TypoGraphy
}

export const SAProductDetailTags: React.FC<ISAProductDetailTags> = props => {
  const {
    saProductDetailsStore: { product }
  } = useSAProductDetailsContext()
  const {
    typographyVariant: { labelTypography, valueTypography, labelColor }
  } = props
  const {
    nav: {
      queryParams: { clientType }
    }
  } = stores

  const isClientRetail = clientType === getEnumValue(VIPClientType.Internal)

  return (
    <>
      {!!product?.tags?.wholesaleFreeTags?.length && !isClientRetail && (
        <>
          <Typography variant={labelTypography || 'h2'} color={labelColor}>
            {strings.wholesaleFreeTagsTitle}
          </Typography>
          <div className={css.tags}>
            {product?.tags?.wholesaleFreeTags.map(tag => (
              <Chip tooltip='' key={tag} className={css.productDetailPopup}>
                <Typography
                  variant={valueTypography || 'caption2'}
                  classes={css.tagsText}
                >
                  {tag?.name}
                </Typography>
              </Chip>
            ))}
          </div>
        </>
      )}
      {!!product?.tags?.retailFreeTags?.length && isClientRetail && (
        <>
          <Typography variant={labelTypography || 'h2'} color={labelColor}>
            {strings.retailFreeTagsTitle}
          </Typography>
          <div className={css.tags}>
            {product?.tags?.retailFreeTags.map(tag => (
              <Chip tooltip='' key={tag} className={css.productDetailPopup}>
                <Typography
                  variant={valueTypography || 'caption2'}
                  classes={css.tagsText}
                >
                  {tag?.name}
                </Typography>
              </Chip>
            ))}
          </div>
        </>
      )}
      {product?.tags?.drop && product.tags.drop?.id && !isClientRetail && (
        <>
          <Typography variant={labelTypography || 'h2'} color={labelColor}>
            {strings.drops}
          </Typography>
          <div className={css.tags}>
            {product.tags.drop && (
              <Chip
                tooltip={product.tags.drop?.name}
                key={product.tags.drop?.id}
                className={css.productDetailPopup}
              >
                <Typography
                  variant={valueTypography || 'caption2'}
                  classes={css.tagsText}
                >
                  {product.tags.drop?.name}
                </Typography>
              </Chip>
            )}
          </div>
        </>
      )}
      {product?.tags?.retailSalesPeriod &&
        product.tags.retailSalesPeriod?.id &&
        isClientRetail && (
          <>
            <Typography variant={labelTypography || 'h2'} color={labelColor}>
              {strings.rtlSalesPeriod}
            </Typography>
            <div className={css.tags}>
              <Chip
                tooltip={product.tags.retailSalesPeriod?.name}
                key={product.tags.retailSalesPeriod.id}
                className={css.productDetailPopup}
              >
                <Typography
                  variant={valueTypography || 'caption2'}
                  classes={css.tagsText}
                >
                  {product.tags.retailSalesPeriod?.name}
                </Typography>
              </Chip>
            </div>
          </>
        )}
    </>
  )
}
