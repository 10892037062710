import styled from 'styled-components'

const ColorCircle = styled.div(props => {
  const { color } = props
  return {
    width: 'var(--chip-color-circle-width)',
    height: 'var(--chip-color-circle-height)',
    backgroundColor: color,
    borderRadius: 'var(--chip-color-circle-borderRadius)',
    flex: '0 0 auto'
  }
})

export default ColorCircle
