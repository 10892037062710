import { action, observable } from 'mobx'
import { config } from '@config'
import { downloadFile } from '@services/downloadFileService'
import { stores } from '@stores'
import { toast } from 'react-toastify'

export class RetailAssortmentStore {
  @observable isExcelExportInProgress = false

  displayError = errorMsg => {
    toast.error(errorMsg, {
      bodyClassName: 'error-toastr',
      progressClassName: 'error-progress-bar-toaster'
    })
  }

  @action onExcelExportClick = async () => {
    this.isExcelExportInProgress = true
    const {
      nav: {
        queryParams: { assortment, buyingSession }
      }
    } = stores

    try {
      const endpoint = assortment
        ? `${config.buyingBoardServiceRestEndpoint}download/excel/retail/assortment/clientAssortment/${assortment}`
        : `${config.buyingBoardServiceRestEndpoint}download/excel/retail/buyingSession/${buyingSession}`
      const downloadResponse = await downloadFile(endpoint)

      if (downloadResponse.error && downloadResponse.message) {
        this.displayError(downloadResponse.message)
      }
    } finally {
      this.isExcelExportInProgress = false
    }
  }
}

export const retailAssortmentStore = new RetailAssortmentStore()
