import { StyledProps } from '@components/3rd-party/mui'
import CircularProgress from '@material-ui/core/CircularProgress'
import classNames from 'classnames'
import React, { LegacyRef } from 'react'
import * as css from './LoadingIndicator.css'

interface MyProps extends StyledProps {
  size?: number | string
  ref?: LegacyRef<HTMLDivElement>
}

export class LoadingIndicator extends React.Component<MyProps> {
  render() {
    const { className, size, ref } = this.props

    return (
      <div
        data-type='LoadingIndicator'
        className={classNames(css.root, className)}
        ref={ref}
      >
        <CircularProgress size={size} />
      </div>
    )
  }
}
