export enum ProductCardAttributeEnum {
  wholesalePrice = 'wholesalePrice',
  associatedLooks = 'associatedLooks',
  substituteLooks = 'substituteLooks',
  suggestedRetailPrice = 'suggestedRetailPrice',
  retailPrice = 'retailPrice',
  actions = 'actions',
  freeTags = 'freeTags',
  retailFreeTags = 'retailFreeTags',
  wholesaleFreeTags = 'wholesaleFreeTags',
  drop = 'drop',
  clientExclusion = 'clientExclusion',
  wholesaleClusters = 'wholesaleClusters',
  clusters = 'clusters',
  retailClusters = 'retailClusters',
  channels = 'channels',
  clusterAssortmentsProduct = 'clusterAssortmentsProduct',
  assortment = 'assortment',
  wholesaleClusterExclusion = 'wholesaleClusterExclusion',
  retailClusterExclusion = 'retailClusterExclusion',
  retailSalesPeriod = 'retailSalesPeriod',
  zoneExclusion = 'zoneExclusion'
}

export enum ProductAttributeSelectorEnum {
  isDefaultSelected = 'isDefaultSelected',
  renderInSADetailsView = 'renderInSADetailsView',
  renderInNewSADetailsView = 'renderInNewSADetailsView',
  excludeFromNewSADetailsViewAttr = 'excludeFromNewSADetailsViewAttr',
  excludeFromCMDetailsViewAttr = 'excludeFromCMDetailsViewAttr',
  excludeFromAttributeSelector = 'excludeFromAttributeSelector'
}
