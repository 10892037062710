import CoreIcon from '@components/std-components/CoreIcon'
import { DropTarget } from '@components/Utils/DropTarget'
import { ChannelSubSection, ProductCardAttributeEnum } from '@modules/common/models/enums'
import { IGridViewProduct, ISelectedAttributes } from '@modules/common/models/interfaces'
import {
  useBuyingSessionProductRating,
  useRatingTypes
} from '@modules/wholesale/productDetail/graphql/hooks'
import { defaultProductCardImage } from '@resources/images'
import { getAverageRating } from '@services/showroom/SAProductDetailService'
import { storage } from '@services/storageService'
import { getWsProductRatings } from '@services/wholesale/getWholesaleProductRatingService'
import { stores, strings } from '@stores'
import classNames from 'classnames'
import { useObserver } from 'mobx-react-lite'
import React, { memo } from 'react'
import { ProductCardActionsIcon } from './ProductCardActionsIcon'
import { ProductActionItem, ProductActions } from './ProductCardOld/ProductActions'
import { ProductCard } from './ProductCardOld/ProductCard'
import { ProductDetail } from './ProductCardOld/ProductDetails'
import { ProductInfoItem } from './ProductCardOld/ProductInfoItem'
import { ProductMedia } from './ProductCardOld/ProductMedia'
import { ProductRecommended } from './ProductCardOld/ProductRecommended'
import { ProductStatus } from './ProductCardOld/ProductStatus'
import { ProductInfoItemWithColorBox } from './ProductInfoItemWithColorBox'
import { CommentIcon, FilledCommentIcon, FilledStarIcon, StarIcon } from './svg'
import * as css from './WholesaleGridCard.css'
import { isBuyingSessionRestrictedForTag } from '@modules/wholesale/wsNavigations/constants/Tags'
import { VIPChannel } from '@modules/common/models/enums/VIPChannel'
import upperFirst from 'lodash/upperFirst'

type CardSize = 'normal' | 'small'

interface GridCardProps {
  product?: IGridViewProduct
  selectedAttributes?: Array<ISelectedAttributes>
  hasActions?: boolean
  isSelectedForBulkOp?: boolean
  showAllAttributes?: boolean
  hideActions?: boolean
  isRecommended?: boolean
  size?: CardSize
  isZAMUserInPrebuyingSession?: boolean
  handleTagDrop?: (product: any, type: any, id: any) => any
  mouseDownHandler?: (id: any) => any
  rightClickHandler?: (event: any) => any
  clickHandler?: any
  favoriteClickHandler?: (event: any) => any
  mediaTopRightIcon?: any
  mediaTopRightClick?: any
  mediaBottomRightIcon?: any
  mediaBottomRightClick?: any
  mediaTopLeftIcon?: any
  mediaTopLeftClick?: any
  mediaBottomLeftIcon?: any
  mediaBottomLeftClick?: any
  mediaWidth?: number
  mediaHeight?: number
}

export const WholesaleGridCard: React.FunctionComponent<GridCardProps> = memo(
  (props: GridCardProps) => {
    return useObserver(function useHook() {
      const {
        isRecommended,
        product,
        handleTagDrop,
        isSelectedForBulkOp,
        mouseDownHandler,
        rightClickHandler,
        clickHandler,
        showAllAttributes,
        hideActions,
        size,
        mediaTopRightIcon,
        mediaTopRightClick,
        mediaBottomRightIcon,
        mediaBottomRightClick,
        mediaBottomLeftIcon,
        mediaBottomLeftClick,
        mediaTopLeftClick,
        mediaTopLeftIcon,
        mediaWidth,
        mediaHeight
      } = props
      const {
        wholesaleAttributeSelectorStore: { selectedAttributes },
        nav: {
          queryParams: { subSection }
        },
        wsBuyingSessionProduct: { handleTagDelete, handleTagClick }
      } = stores
      const productDetails = hideActions
        ? size === 'small'
          ? css.productDetailsContainerWithoutOverflowAndAction_small
          : css.productDetailsContainerWithoutOverflowAndAction
        : size === 'small'
        ? css.productDetailsContainerWithoutOverflow_small
        : css.productDetailsContainerWithoutOverflow

      const {
        productId,
        isActive,
        status,
        imageURL,
        nodeInfo,
        attributes,
        hasComments,
        averageRating,
        onDelete,
        buyingSession
      } = product

      const currentUser: { id: string } = storage.getItem('user')
      const [{ data: ratingType }, { data: productRatingData }] = [
        useRatingTypes(),
        useBuyingSessionProductRating(currentUser.id, productId)
      ]
      const ratingTypes = ratingType?.findVIPRatingType?.Items ?? []
      const productRatings = getWsProductRatings(
        productRatingData?.findVIPRating?.Items ?? [],
        ratingTypes
      )
      const calculatedAvgRating = Number(getAverageRating(productRatings))

      const isRtlBuyingSessionRestricted = isBuyingSessionRestrictedForTag(
        buyingSession,
        strings.tagTypes.channel,
        VIPChannel.Retail
      )
      const isWhlBuyingSessionRestricted = isBuyingSessionRestrictedForTag(
        buyingSession,
        strings.tagTypes.channel,
        VIPChannel.Wholesale
      )
      const canDeleteProduct =
        subSection === ChannelSubSection.Retail
          ? isRtlBuyingSessionRestricted
            ? null
            : onDelete
          : isWhlBuyingSessionRestricted
          ? null
          : onDelete

      /**
       * Note: Please use selectedProduct and productCardWithoutBorder classes conditionally
       * while implementing product select. These classes are contributing in card width
       * which needs to be maintained. Also, We should rename these classes so they don't
       * appears as product specific.
       */

      return (
        <div
          className={classNames(
            css.productCard,
            isSelectedForBulkOp ? css.selectedProduct : css.productCardWithoutBorder
          )}
          onClick={clickHandler ? clickHandler : null}
          onMouseDown={mouseDownHandler}
          onContextMenu={rightClickHandler}
        >
          <DropTarget
            className={size === 'small' ? css.dropTargetClass_small : css.dropTargetClass}
            canDrop={!!handleTagDrop}
            showDropBackground
            handleDrop={({ id, type }) => handleTagDrop(product, type, id)}
          >
            <ProductCard
              active={isActive}
              isRestricted={false}
              tagAction={e => {
                e.stopPropagation()
                onDelete(product, productId)
              }}
              removeTag={product?.isCore ? null : canDeleteProduct}
            >
              {status && <ProductStatus status={status} />}
              {isRecommended ? <ProductRecommended /> : null}
              {product?.isCore ? (
                <CoreIcon isInactiveFlagPresent={!product?.isActive} />
              ) : null}
              <ProductMedia
                image={imageURL ? imageURL : defaultProductCardImage}
                fitStyle={imageURL ? null : 'cover'}
                bottomRightIcon={mediaBottomRightIcon}
                onBottomRightIconClick={mediaBottomRightClick}
                topRightIcon={mediaTopRightIcon}
                onTopRightIconClick={mediaTopRightClick ? mediaTopRightClick : null}
                topLeftIcon={mediaTopLeftIcon ? mediaTopLeftIcon : null}
                onTopLeftIconClick={mediaTopLeftClick ? mediaTopLeftClick : null}
                bottomLeftIcon={mediaBottomLeftIcon ? mediaBottomLeftIcon : null}
                onBottomLeftIconClick={mediaBottomLeftClick ? mediaBottomLeftIcon : null}
                width={mediaWidth}
                imgWidth={imageURL ? null : 'fit-content'}
                height={mediaHeight}
                iconWidth={32}
                isRecommended={isRecommended}
              />
              <ProductDetail className={productDetails}>
                <ProductInfoItem
                  className={css.productInfoItem}
                  title={nodeInfo.legend}
                  content={nodeInfo.title}
                  sku={nodeInfo.subtitle}
                />
                {attributes.map((attribute, index) => {
                  if (showAllAttributes || selectedAttributes[attribute.name]) {
                    return (
                      <ProductInfoItemWithColorBox
                        key={attribute.name}
                        className={css.productInfoItem}
                        title={attribute.label}
                        content={attribute.value}
                        color={attribute.color}
                        isChip={attribute.isChip}
                        onDelete={
                          product[`canDelete${upperFirst(attribute.name)}`]
                            ? chip => handleTagDelete(product, chip.id, attribute.name)
                            : null
                        }
                        onClick={
                          product[`canEdit${upperFirst(attribute.name)}`]
                            ? (chip, e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                handleTagClick(product, chip.id, attribute.name, e)
                              }
                            : null
                        }
                        node={product}
                      />
                    )
                  } else {
                    return null
                  }
                })}
                {!hideActions && selectedAttributes[ProductCardAttributeEnum.actions] ? (
                  <div className={css.productActions}>
                    <ProductActions>
                      {/* <ProductActionItem>
                        <ProductCardActionsIcon
                          label={favorite.count}
                          iconName={
                            <img
                              src={favorite.likedByUser ? FilledHeartIcon : HeartIcon}
                              className={css.cursor}
                              alt=''
                            />
                          }
                          successorColor={'red'}
                          status={Boolean(favorite.likedByUser)}
                          onClick={
                            isActive &&
                            !isBuyingSessionClosedStatus &&
                            !isZAMUserInPrebuyingSession
                              ? favoriteClickHandler
                              : null
                          }
                        />
                      </ProductActionItem> */}
                      <ProductActionItem>
                        <ProductCardActionsIcon
                          label={averageRating || calculatedAvgRating}
                          iconName={
                            <img
                              src={
                                averageRating || calculatedAvgRating
                                  ? FilledStarIcon
                                  : StarIcon
                              }
                              alt=''
                            />
                          }
                          successorColor='#FF9800'
                          status={Boolean(averageRating || calculatedAvgRating)}
                        />
                      </ProductActionItem>
                      <ProductActionItem>
                        <ProductCardActionsIcon
                          status={hasComments}
                          iconName={
                            <img
                              src={hasComments ? FilledCommentIcon : CommentIcon}
                              alt=''
                            />
                          }
                          successorColor='#FF9800'
                        />
                      </ProductActionItem>
                    </ProductActions>
                  </div>
                ) : null}
              </ProductDetail>
            </ProductCard>
          </DropTarget>
        </div>
      )
    })
  }
)
