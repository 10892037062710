import { observable, action } from 'mobx'

export class ClusterStore {
  @observable value = 0
  @observable addCluster = false
  @observable addClusterStatus = false
  @observable openDeleteDialog = false
  @observable selectedId = null
  @observable deleteInput = null

  @action
  handleTabChange = (event, value) => {
    Object.assign(this, { value: value })
  }

  @action
  handleAddCluster = () => {
    Object.assign(this, { addCluster: !this.addCluster })
  }

  @action
  handleAddClusterStatus = () => {
    Object.assign(this, { addClusterStatus: !this.addClusterStatus })
  }

  @action
  handleDeleteDialogOpen = (selectedId, deleteInput) => {
    Object.assign(this, {
      selectedId: selectedId,
      openDeleteDialog: true,
      deleteInput: deleteInput
    })
  }

  @action
  handleDeleteDialogClose = () => {
    Object.assign(this, { openDeleteDialog: !this.openDeleteDialog })
  }
}
