import { QueryResult } from '@apollo/client/react'
import { Query } from '@apollo/client/react/components/Query'
import { CircularLoading } from '@components/UI-Components/CircularLoading'
import { PrimaryButton } from '@components/UI-Components/PrimaryButton'
import Typography from '@material-ui/core/Typography'
import { stores } from '@stores'
import { observer } from 'mobx-react'
import React from 'react'
import { CreateStoreDialog } from '../component/CreateStoreDialog'
import { Store } from '../component/Store'
import { GET_STORES, GET_ZONES_COUNTRIES_CATEGORIES } from '../graphql'
import * as css from './Stores.css'

const unit = 8

const buttonStyle = {
  margin: `${unit * 2}px 0 ${unit}px ${unit * 3}px`
}

interface IStore {
  storeImportStore?: any
}

@observer
export class StoreContainer extends React.Component<IStore> {
  render() {
    const {
      storeImport: { toggleCreateStoreDialogOpen, isCreateStoreDialogOpen }
    } = stores

    return (
      <div className={css.root}>
        <PrimaryButton style={buttonStyle} onClick={toggleCreateStoreDialogOpen}>
          Create New Store
        </PrimaryButton>

        <Query query={GET_ZONES_COUNTRIES_CATEGORIES}>
          {({ data: Data = { zones: undefined, categories: undefined } }) => {
            return (
              <>
                <Query query={GET_STORES}>
                  {({
                    loading,
                    error,
                    data: storeData = { stores: [] }
                  }: QueryResult) => {
                    if (loading) return <CircularLoading />
                    if (error) return null
                    if (storeData && storeData.stores.length) {
                      return <Store stores={storeData.stores} />
                    } else {
                      return (
                        <div
                          style={{
                            margin: 24
                          }}
                        >
                          <Typography variant='body1'>
                            NO STORE DATA AVAILABLE.
                          </Typography>
                        </div>
                      )
                    }
                  }}
                </Query>
                {isCreateStoreDialogOpen && (
                  <CreateStoreDialog zones={Data.zones} categories={Data.categories} />
                )}
              </>
            )
          }}
        </Query>
      </div>
    )
  }
}
