import { config } from '@config'
import { HttpService } from '@services/HttpService'
import { storage } from '@services/storageService'
import isEmpty from 'lodash/isEmpty'
import { action, observable } from 'mobx'
import { computedFn } from 'mobx-utils'
import { LookCardSizes } from 'src/constant/LookCardSizes'
import { ProductCardSizes } from 'src/constant/ProductCardSizes'
import { RestServiceEndpoints } from 'src/constant/RestEndPoints'

export enum UserPreference {
  LOOK_CARD_PREFERENCE = 'lookbookCard',
  PRODUCT_CARD_PREFERENCE = 'productCard',
  ORDER_PREFERENCES = 'orders'
}

const DEFAULT_PREFERENCES = {
  [UserPreference.LOOK_CARD_PREFERENCE]: {
    size: LookCardSizes.Large
  },
  [UserPreference.PRODUCT_CARD_PREFERENCE]: {
    size: ProductCardSizes.Medium
  }
}

export class UserPreferencesStore {
  @observable.struct preferences: any = {}
  @observable isLoading = false

  private httpService = new HttpService()
  private preferenceReq

  @action fetchPreferences = () => {
    if (isEmpty(this.preferences) && !this.isLoading) {
      this.isLoading = true
      const accessToken: string = storage.getItem('access_token')
      this.preferenceReq = this.httpService
        .get(
          `${config.buyingBoardServiceRestEndpoint}${RestServiceEndpoints.USER_PREFERENCES}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              Accept: 'application/json',
              'Content-Type': 'application/json'
            }
          }
        )
        .then(res => {
          this.isLoading = false
          this.preferences = res.data
        })
        .catch(e => {
          this.isLoading = false
        })
    }
  }

  @action savePreferences = async pref => {
    this.preferences = { ...this.preferences, ...pref }
    const accessToken: string = storage.getItem('access_token')
    this.httpService.put(
      `${config.buyingBoardServiceRestEndpoint}${RestServiceEndpoints.USER_PREFERENCES}`,
      this.preferences,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
    )
  }

  getPreferenceByKey = computedFn((key: UserPreference) => {
    return this.preferences[key] || DEFAULT_PREFERENCES[key]
  })

  @action clear = () => {
    this.preferences = {}
    this.preferenceReq?.cancel?.()
    this.isLoading = false
  }
}

export const userPreferencesStore = new UserPreferencesStore()
