import React from 'react'
import styled from 'styled-components'
import { TextAlignment } from '@modules/common/models/enums'
import { StyledTypographyComponent } from '../AverageDepth/AvgDepthTextFieldList'
import Tooltip from '@material-ui/core/Tooltip'

interface IProps {
  alignment?: TextAlignment
  font?: string
  cellPadding?: string
  value?: string
  onClick?: any
  cursor?: string
  verticalAlign?: string
  fontWeight?: any
}

const Root = styled.div<IProps>((props: any) => {
  return {
    padding: props.cellPadding ? props.cellPadding : 'var(--cell-padding)',
    display: 'flex',
    alignItems: props.verticalAlign ? props.verticalAlign : 'center',
    cursor: props.cursor ? props.cursor : null,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }
})

const LabelValue = styled.span<IProps>(props => {
  return {
    fontWeight: props.fontWeight || 'normal'
  }
})

export const StandardListViewLabel: React.FunctionComponent<IProps> = (props: IProps) => {
  const {
    alignment,
    value,
    cellPadding,
    font,
    onClick,
    cursor,
    fontWeight,
    verticalAlign
  } = props
  return (
    <Root
      cellPadding={cellPadding}
      cursor={cursor}
      onClick={onClick}
      verticalAlign={verticalAlign}
    >
      <StyledTypographyComponent font={font} alignment={alignment}>
        <Tooltip title={value || ''}>
          <LabelValue fontWeight={fontWeight}>{value}</LabelValue>
        </Tooltip>
      </StyledTypographyComponent>
    </Root>
  )
}
