import {
  IProduct360,
  Product360
} from '@components/std-components/ProductMedia/Product360'
import {
  IProductImage,
  ProductImage
} from '@components/std-components/ProductMedia/ProductImage'
import {
  IProductVideo,
  ProductVideo
} from '@components/std-components/ProductMedia/ProductVideo'
import React from 'react'

export enum ProductMediaType {
  staticImage = 'Static Image',
  threeSixty = 'Three Sixty',
  video = 'Video'
}

export interface ISwitchMedia {
  media?: IProductImage | IProduct360 | IProductVideo
  type?: ProductMediaType
}

export const SwitchMedia: React.FC<ISwitchMedia> = ({ media, type }) => {
  switch (type) {
    case ProductMediaType.staticImage:
      return <ProductImage {...(media as IProductImage)} />
    case ProductMediaType.threeSixty:
      return <Product360 {...(media as IProduct360)} />
    case ProductMediaType.video:
      return <ProductVideo {...(media as IProductVideo)} />
    default:
      return null
  }
}
